import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as S from './style';
import { useGetUrlChangePassword } from '../../services/hooks/userHooks';
import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { ButtonApp } from '../../components/ButtonApp';
import  SpinnerLoading from '../../components/SpinnerLoading';

export function ResetPassword() {
    const { data: urlChangePassword, mutate: handleGetUrl, isLoading } = useGetUrlChangePassword();

    const { user } = useAuth();

    const email = user?.credentials?.email;
    const user_id = user?.user_id;

    useEffect(() => {
        const body = {
            email: email,
            user_id: user_id
        }
        handleGetUrl(body)
    },[])
    

    function handleRedirectToChangePassword() {
        window.open(urlChangePassword?.data.data, '_blank')
    }

    return (
        isLoading ? <SpinnerLoading /> : (
            <S.Container>
                <S.Header>
                    <S.Title>Deseja redefinir sua senha?</S.Title>
                    <S.Subtitle>Nós definimos uma senha aleatória para sua conta, deseja redefini-la?</S.Subtitle>
                </S.Header>
                <S.ButtonArea>
                    <S.Button onClick={handleRedirectToChangePassword}>
                        <LockOutlinedIcon sx={{ width: '20px', height: '20px' }} /> Redefinir senha
                    </S.Button>
                </S.ButtonArea>
                <S.Line></S.Line>
                
                <S.ButtonArea>
                <S.ButtonText onClick={() => window.location.href = '/onboarding'}>Não, quero prosseguir sem redefinir minha senha</S.ButtonText>
                <ButtonApp border='' text="Próximo" width="9.5rem" height="2.5rem" background="#19c27e" icon={""} color="white" onClick={() => window.location.href = '/onboarding'}  />
                </S.ButtonArea>
            </S.Container>
        )
    );
}
