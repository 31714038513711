import { ModalLineProductsCostsProps } from '../../../../../@types';
import Modal from '../../../../../components/Modal';
import { LineModalProductsCosts } from './LineModalProducsCosts';
import { InputWithTypeMoney } from '../../../../../components/InputWithTypeMoney';
import * as S from './style';
import { useEffect, useState } from 'react';
import ModalSaveCosts from '../../../ModalSaveCosts';

export function ModalLineProductsCosts({ product, setShowModal, dataProductCosts, setDataProductCosts, editedVariants, setEditedVariants, products, setProducts }: ModalLineProductsCostsProps) {
    const [sameValueForAll, setSameValueForAll] = useState({'value': '0,00', 'currency': 'BRL'});

    //SETA O individual value para cada variante com currency e value
    const [individualsValue, setIndividualsValue] = useState(
        product?.variants?.reduce((acc: any, object: any) => {
            return { ...acc, [object.variant_id]: { value: object.cost, currency: object.currency } };
        }, {})
    );

    // Atualize individualsValue sempre que products mudar
    useEffect(() => {
        const new_product = products.find((p: any) => p.product_id === product.product_id);
        const newIndividualsValue = new_product?.variants?.reduce((acc: any, variant: any) => ({
            ...acc, 
            [variant.variant_id]: { value: variant.cost, currency: variant.currency }
        }), {});

        setIndividualsValue(newIndividualsValue);
    }, [products]); // Dependências do useEffect, nesse caso, products

    const [checkBox, setCheckBox] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [openSaveCostsModal, setOpenSaveCostsModal] = useState(false);

    const handleInputChange = (value: string, variant_id: string, currency:string) => {
        setIndividualsValue((prevValores: any) => ({
            ...prevValores,
            [variant_id]: { value, currency },
        }));        
        setIsEdited(true); // Indica que houve uma edição
    };

    const handleInputForAll = (value: string, variant_id?: string, currency?: string ) => {
        //console.log(value, currency, variant_id);
        setSameValueForAll({ 
            value : value,
            currency: currency ? currency : 'BRL'
        });
        setIsEdited(true); // Indica que houve uma edição
    }

    const handleCheckboxChange = () => {
        setCheckBox((prev) => !prev);
        setIsEdited(true); // Indica que houve uma edição
    };
 
    const handleSaveChanges = () => {
        // muda o estado editedVariants para os novos valores
        const newEditedVariants = { ...editedVariants };

        // se o checkbox estiver marcado, todos os valores serão iguais
        // se não, os valores serão os que estão no estado individualsValue
        // se o valor tiver igual ao valor original, ele será removido do estado editedVariants
        product?.variants?.forEach((variant: any) => {
            if (checkBox) {
                newEditedVariants[variant.variant_id] = {
                    ...(newEditedVariants[variant.variant_id] || {}),
                    variant_cost: parseFloat(sameValueForAll.value.replace('.', '').replace(',', '.')),
                    product_id: product.product_id,
                    variant_id: variant.variant_id,
                    currency: sameValueForAll.currency,
                    store_id: product.store_id,
                    account_id: product.account_id,
                };
            } else {
                if (individualsValue[variant.variant_id].value === variant.cost) {
                    delete newEditedVariants[variant.variant_id];
                } else {
                    newEditedVariants[variant.variant_id] = {
                        ...(newEditedVariants[variant.variant_id] || {}),
                        variant_cost: parseFloat(individualsValue[variant.variant_id].value.replace('.', '').replace(',', '.')),
                        product_id: product.product_id,
                        variant_id: variant.variant_id,
                        currency: individualsValue[variant.variant_id].currency,
                        store_id: product.store_id,
                        account_id: product.account_id,
                    };
                }
            }
        });

        setEditedVariants(newEditedVariants);
        setOpenSaveCostsModal(true);
        setIsEdited(false);
    };

    return (
        
        <Modal onClose={() => setShowModal(false)}>
            {openSaveCostsModal && <ModalSaveCosts onClose={() => setOpenSaveCostsModal(false)} editedVariants={editedVariants} setEditedVariants={setEditedVariants} products={products} setProducts={setProducts} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} />}
            <S.Container>
                <S.InnerContainer>
                    <S.TitleSection>
                        <S.Title>PRODUTO</S.Title>
                        <S.ProductName>
                            {product?.product_title && product?.product_title}
                        </S.ProductName>
                    </S.TitleSection>
                    <S.Line></S.Line>
                    <S.InputCheckbox>
                        <label>
                            <input type="checkbox" onChange={() => handleCheckboxChange()} />
                            <span></span>
                            Definir custo padrão para todas as variantes.
                        </label>
                    </S.InputCheckbox>
                    <S.InputSection>
                        <InputWithTypeMoney disabled={!checkBox} value={sameValueForAll.value} setValue={handleInputForAll} currency={'BRL'}/>
                    </S.InputSection>
                </S.InnerContainer>
                `{' '}
                <S.Header>
                    <S.Image></S.Image>
                    <S.ProductVariant>Variante</S.ProductVariant>
                    <S.ProductPrice>Preço</S.ProductPrice>
                    <S.IndividualCost>Custo Individual</S.IndividualCost>
                    <S.Action>Ação</S.Action>
                </S.Header>
                <S.Lines>
                    {product?.variants &&
                        product?.variants.map((variant: any) => {
                            return <LineModalProductsCosts disabled={checkBox} individualsValue={individualsValue} handleInputChange={handleInputChange} key={variant?.variant_id} variant={variant} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} />;
                        })}
                </S.Lines>
                <S.SectionSaveChanges>
                <S.SaveChanges 
                    onClick={() => isEdited && handleSaveChanges()} 
                    style={{ backgroundColor: isEdited ? '#19c380' : '#cdcdcd', cursor: isEdited ? 'pointer' : 'not-allowed', color: isEdited ? 'white' : '#000'}}
                >
                    Salvar alterações
                </S.SaveChanges>
                </S.SectionSaveChanges>
            </S.Container>
        </Modal>
    );
}
