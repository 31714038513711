import * as S from './style';
import { StepperOnboarding } from '../StepperOnboarding';
import useAuth from '../../../hooks/useAuth';
import { trackEvent } from '../../../services/segment/sendEvent';
import { storagePlaceholderCompanySave } from '../../../storage/storageCompanyToOnboard';
import { useNavigate } from 'react-router-dom';
import { StorageAuthTokenProps, userDTO } from '../../../@types';
import { storageOnboardingRemoveAll } from '../../../storage/storageOnboarding';
import StoreIcon from '@mui/icons-material/Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export function SideBarOnboarding({ step, getData }: { step: number, getData: any }) {
    
    const { user, currentCompany } = useAuth();
    const { setUserContext, setTokenContext, setCurrentCompanyContext } = useAuth();
    const navigate = useNavigate();

    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success");
        }).catch((err) => {
            //console.log(err);
        });

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        storageOnboardingRemoveAll();
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        navigate('/login');
    }

    const handleClickLojas = () => {        
        navigate(`/lojas`);
    }

    const handleClickSubscription = () => {        
        navigate(`/subscription`);
    }

    return (
    <S.SideBar>
        <S.Container>
        <S.LogoutButton >
            <S.ButtonItem type={'logout'} onClick={signOut}>
                <S.LogoutIconStyled />
                Sair
            </S.ButtonItem>

        <S.Flex>
            <S.ButtonItem type={'store'} onClick={handleClickLojas}>    
                <StoreIcon fontSize='small' />
                Minhas lojas
            </S.ButtonItem>  

            <S.ButtonItem type={'subscriptions'} onClick={handleClickSubscription}>    
                <CreditCardIcon fontSize='small' />
                Assinatura
            </S.ButtonItem>  
        </S.Flex>
            </S.LogoutButton>
            <h2>Bem vindo, {user.name}<br></br>
            Vamos começar.
            </h2>
            <StepperOnboarding step={step} getData={getData} />
        </S.Container>  
    </S.SideBar>
    )
}