import { TemplateModal } from '../../';
import { ButtonApp } from '../../../../../components/ButtonApp';
import useAuth from '../../../../../hooks/useAuth';
import { CardAccount } from './CardAccount';
import * as S from './style';
import { useGetUrlGoogleAdsAuth, useRemoveGoogleAdsAccount, useRemoveGoogleAdsProfile, useSyncGoogleAdsAccounts } from '../../../../../services/hooks/integrationHooks';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import useAlert from '../../../../../hooks/useAlert';
import { useEffect, useState } from 'react';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';
import { use } from 'echarts';
import { userDTO } from '../../../../../@types';
import {Tooltip} from '@mui/material';
import { Search } from '../../../../../components/Search';
import { TablePlaceholder } from '../../../../../components/TablePlaceholder';

export function ModalGoogleAds() {
    const urlLogo = 'https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png';
    const baseColor = '#fe2c55';

    const [open, setOpen] = useState(false);

    const { user, setUserContext, currentCompany, updateUser } = useAuth();

    const { data: linkGoogle, isLoading: isLoadingLinkGoogle } = useGetUrlGoogleAdsAuth(user?.user_id, currentCompany, 'home');

    const { mutate: handleSyncGoogleAccount, isLoading} = useSyncGoogleAdsAccounts();

    const { showAlert } = useAlert();
    
    const [filter, setFilter] = useState('');

    const [selectedAccountId, setSelectedAccountId] = useState('')
    const [selectedProfileId, setSelectedProfileId] = useState('')
    const [showConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupAccount] = useState(false)
    const [showConfirmDisconnectPopupProfile, setShowConfirmDisconnectPopupProfile] = useState(false)
    const [googleStatusInactive, setGoogleStatusInactive] = useState({ inactive: false, account_id: '' });

    const {mutate: handleRemoveGoogleAccount, isLoading: isLoadingRemoveGoogleAccount} = useRemoveGoogleAdsAccount();
    const {mutate: handleRemoveGoogleProfile, isLoading: isLoadingRemoveGoogleProfile} = useRemoveGoogleAdsProfile();

    const googleAdsInfo: any[] = user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles?.filter((profile) => profile?.profile_id !== 'default-profile');

    const hasGoogleAdsInfo = googleAdsInfo?.length > 0;

    const [showPopupGoogleGateway, setShowPopupGoogleGateway] = useState(false)

    function handleLinkGoogle() {
        setOpen(true);
        
        window.open(linkGoogle?.data, '_blank');
        setOpen(false);
    }

    useEffect(() => {
        if(location.pathname === '/integracao/google'){
            handleSyncGoogleAccount(currentCompany,{
                onSuccess: ({data}) => {
                    const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, google_ads: {profiles: data?.data?.profiles}} } } }  
                    setUserContext(newUser)
                    updateUser()
                }
            });
        }

    },[location.pathname])

    const handleMessage = (event: any) => {
        // Verifica se a origem da mensagem compartilha o mesmo domínio base da página atual
        if (new URL(event.origin).origin !== window.location.origin) {
          return; // Ignora a mensagem se a origem não for a mesma
        }
      
        if (event.data.type === 'GoogleSyncComplete') {
            // Lida com a sincronização, usando os dados recebidos
            // quero que feche o popup de prosseguir
            setShowPopupGoogleGateway(false)
            
            // quero que atualize a lista de contas (com o loading)
            handleSyncGoogleAccount(currentCompany,{
                onSuccess: ({data}) => {
                    const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, google_ads: {profiles: data?.data?.profiles}} } } }  
                    setUserContext(newUser)
                    updateUser()
                }
            });
          
        }
    };
      
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []); 

    function syncGoogle(){
        handleSyncGoogleAccount(currentCompany,{
            onSuccess: ({data}) => {
                const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, google_ads: {profiles: data?.data?.profiles}} } } }  
                setUserContext(newUser)
                updateUser()
            }
        });
    }

    function handleDesintegrateProfile(){
        
        const body = {
            company_id: currentCompany,
            user: user,
            profile_id: selectedProfileId,
            truncate: true,
        }

        showAlert('Desconectando perfil... Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 80000, true);
        setShowConfirmDisconnectPopupProfile(false)
                
        handleRemoveGoogleProfile(body, {
            onSuccess: ({data}) => {
                    syncGoogle();
                }
            })
    }
    

    function handleDesintegrateAccount(){
        const body = {
            company_id: currentCompany,
            profile_id: selectedProfileId,
            account_id: selectedAccountId,
            user: user
        }
    
        showAlert('Conta desconectada! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setGoogleStatusInactive({inactive: true, account_id: selectedAccountId})
        setShowConfirmDisconnectPopupAccount(false)
        
        handleRemoveGoogleAccount(body, {
            onSuccess: ({data}) => {
            }
        })
    }   

    return (
        <S.Container showPopupGoogleGateway={showPopupGoogleGateway}>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Google Ads'}
                title={'Google Ads'}
                subtitle={'Integre sua loja com o Google Ads e automatize seu processo de dropshipping.'}
                statusConnection={googleAdsInfo?.some((info) => info.status === 'active') ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>


                {hasGoogleAdsInfo && googleAdsInfo?.map((info, index) => {
                    return(
                        <>
                            <S.SyncArea>
                            <div style={{marginLeft: '-0.5rem'}}>
                                <Search placeholder="Pesquisar contas de anúncios." width="14rem" height='2.5rem' value={filter} setValue={setFilter} />
                            </div>
                            <Tooltip 
                                title="Atualizar novas contas de anúncios do perfil."
                                placement="top"
                                componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                                <S.SyncContainer onClick={syncGoogle} disabled={isLoading}>
                                    <S.SyncIcon>
                                        <SyncIcon />
                                    </S.SyncIcon>
                                    <S.SyncText>
                                        Sincronizar Contas
                                    </S.SyncText>
                                </S.SyncContainer>
                                </Tooltip>
                            </S.SyncArea>
                {hasGoogleAdsInfo && googleAdsInfo?.map((info, index) => {
                // Filtrar contas de anúncio por nome ou ID
                const filteredAccounts = info.accounts?.filter(account =>
                    account?.account_id.includes(filter)
                );

                if (filteredAccounts.length > 0) {
                return (
                    <>
                        <CardAccount 
                            setGoogleStatusInactive={setGoogleStatusInactive} 
                            googleStatusInactive={googleStatusInactive} 
                            setSelectedProfileId={setSelectedProfileId} 
                            setSelectedAccountId={setSelectedAccountId} 
                            setShowConfirmDisconnectPopupAccount={setShowConfirmDisconnectPopupAccount}  
                            setShowConfirmDisconnectPopupProfile={setShowConfirmDisconnectPopupProfile}  
                            googleAdsInfo={{ ...info, accounts: filteredAccounts }} // Passa as contas filtradas para o CardAccount
                            isLoadingOutside={isLoading} 
                            isFirst={index === 0}
                        />
                    </>
                    );
                } else {
                    return (
                        <S.PlaceholderArea>
                            <TablePlaceholder size='small' showSubtitle={true} subtitleText='Nenhuma conta de anúncios encontrada.' />
                        </S.PlaceholderArea>
                        ); // Não renderizar CardAccount se não houver contas correspondentes
                }
                })}
            </>
            )
        })}

                <S.ContainerGoogleGateway showPopupGoogleGateway={showPopupGoogleGateway}>
                    <S.GoogleGatewayTitle>Importante</S.GoogleGatewayTitle>
                    <S.GoogleGatewaySubtitle>Não se esqueça de ativar todas as permissões para que a integração com o Google Ads funcione corretamente</S.GoogleGatewaySubtitle>
                    <S.ButtonGoogleGateway onClick={handleLinkGoogle}>
                        Prosseguir <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                    </S.ButtonGoogleGateway>
                </S.ContainerGoogleGateway>
            </S.InnerContainer>

            <Tooltip 
                title="Adicionar um novo perfil do Google Ads."
                placement="top"
                componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                <S.ButtonContainer>
                    <ButtonApp
                        border=''
                        width="13rem"
                        height="2rem"
                        color="#fff"
                        background="#ff0400"
                        icon={<img src="https://www.pngfind.com/pngs/b/34-344426_google-icon-logo-black-and-white-johns-hopkins.png" />}
                        onClick={() => setShowPopupGoogleGateway(true)}
                        text={!hasGoogleAdsInfo ? 'Continuar com o Google' : 'Adicionar perfil do Google'}
                        isLoading={open && isLoadingLinkGoogle}
                    />
                </S.ButtonContainer>
            </Tooltip>

            {showConfirmDisconnectPopupAccount && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupAccount}
                        onClose={() => setShowConfirmDisconnectPopupAccount(false)}
                        onConfirm={handleDesintegrateAccount}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveGoogleAccount}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por esta conta na Solomon. Tem certeza que deseja continuar?
                              <br />
                              <br />
                              <strong>(Esta conta permanecerá na lista de contas.)</strong>
                            </>
                          }
                          
                    />
                )}
                {showConfirmDisconnectPopupProfile && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupProfile}
                        onClose={() => setShowConfirmDisconnectPopupProfile(false)}
                        onConfirm={handleDesintegrateProfile}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveGoogleProfile}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por este perfil na Solomon. Tem certeza que deseja continuar?
                            </>
                          }
                    />
                )}
        </S.Container>
    );
}
function SyncIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z"/>
    </svg>
    )
}