import * as S from './style';
import { LineTableTrackingAdsSet } from './LineTableTrackingAdsSet';
import { calculateFinalOrders, calculateFinalRevenue, getAdsSetData, getTotalLineByAds } from '../utils';
import { useState, useEffect } from 'react';
import { getSalesDTO } from '../../../@types';
import { useCheckboxTracking } from '../../../hooks/useCheckboxTracking';
import { useMemo } from 'react';
import React from 'react';
import { useColumnsTracking } from '../../../hooks/useColumnsTracking';
import { useResizableColumn } from '../../../hooks/useResizableColumn';
import { useIntercom } from 'react-use-intercom';
import { useCurrencyType } from '../../../hooks/useCurrencyType';
import ColumnItem from '../ColumnItem';
import TotalRowComponent from '../TotalRowComponent';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import { useSorting } from '../../../contexts/SortingContext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export function TableTrackingAdsSet({
    type,
    campaignId,
    paymentMethod,
    columnSolomon,
    handleSelectGraphicData,
    handleScrolltoGraphic,
    dataMetrics,
    filtred,
    openSalesModal,
    date,
    filteredBySelect,
    dataCatalogList,
    loadingCatalog,
}: {
    filteredBySelect?: any;
    handleSelectGraphicData: any;
    handleScrolltoGraphic: () => void;
    columnSolomon: string;
    paymentMethod?: any;
    type: string;
    campaignSelect?: string;
    campaignId?: string;
    dataMetrics: any;
    filtred: string;
    openSalesModal: (value: getSalesDTO, metrics: any, source: string, catalogCampaign: boolean, ordersSolomon?: any, revenueSolomon?: any) => void;
    date: string[];
    dataCatalogList: any;
    loadingCatalog?: any;
}) {

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);
    const { typeFiltred, setTypeFiltred, isStatusAsc, setIsStatusAsc } = useSorting();

    useEffect(() => {
        if (paymentMethod) {
    
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect
    const {currencySymbol} = useCurrencyType();
    const { showArticle } = useIntercom();
    const { columnWidths, startResizing } = useResizableColumn();
    const { selectedCampaignIds } = useCheckboxTracking();
    const { columns } = useColumnsTracking();    
    // Preparar um array dos objetos de campanhas ativos para passar para a função
    const activeCampaigns = selectedCampaignIds.map(({ id, type }) => ({ id, type }));

    const memoizedGetAdsData = useMemo(() => {
        return getAdsSetData;
    }, []);
    
    
    // Chama a função com os objetos de campanha ativos
    const filtredAdsSetData = useMemo(() => memoizedGetAdsData(dataMetrics, type, campaignId, activeCampaigns, dataCatalogList), [dataMetrics, type, campaignId, activeCampaigns, dataCatalogList]);

    
    // const [typeFiltred, setTypeFiltred] = useState('spendBySource');
    const [filterDec, setFilterDec] = useState(true);

    function normalizeValue(value) {
        // Converte undefined ou null para 0

        if (value === undefined || value === null || value === Infinity) {
            return 0;
        }

        return value;
    }

    const newFiltredAdsSetData = useMemo(() => {
        const filteredData = filtredAdsSetData
            ?.filter((adsSet: any) => 
                adsSet?.adsSetName?.toLowerCase().includes(filtred.toLowerCase()) &&
                (filteredBySelect.length > 0 ? filteredBySelect.includes(adsSet?.adsSetId) : true)
            );
    
        // Ordenar pelo status
        if (typeFiltred === 'status') {
            filteredData.sort((a: any, b: any) => {
                if (isStatusAsc) {
                    return a?.adsSetStatus === 'ACTIVE' ? -1 : 1;
                } else {
                    return a?.adsSetStatus === 'ACTIVE' ? 1 : -1;
                }
            });
        } else {
            filteredData.sort((a: any, b: any) => {
                if (filterDec) {
                    return b?.[typeFiltred] - a?.[typeFiltred];
                } else {
                    return a?.[typeFiltred] - b?.[typeFiltred];
                }
            });
        }
    
        return filteredData;
    }, [filtredAdsSetData, filtred, filteredBySelect, typeFiltred, filterDec, isStatusAsc]);

    // const newFiltredAdsSetData = filtredAdsSetData    
    // ?.filter((adsSet: any) => 
    //     adsSet?.adsSetName?.toLowerCase().includes(filtred.toLowerCase()) &&
    //     (filteredBySelect.length > 0 ? filteredBySelect.includes(adsSet?.adsSetId) : true)
    // )
    // ?.sort((a: any, b: any) => {
    //     const aValue = normalizeValue(a[typeFiltred]);
    //     const bValue = normalizeValue(b[typeFiltred]);
        
    //     return filterDec ? bValue - aValue : aValue - bValue;
    // });


    const totalLine = useMemo(() => getTotalLineByAds(newFiltredAdsSetData, type), [newFiltredAdsSetData, type]);


    const revenueSolomon = calculateFinalRevenue(columnSolomon, totalLine, activePaymentMethods);
    const ordersSolomon = calculateFinalOrders(columnSolomon, totalLine, activePaymentMethods);

    const uniquePlatforms = new Set();

    // Supondo que 'campaignsData' seja sua lista de campanhas
    newFiltredAdsSetData.forEach(adsSet => {
        uniquePlatforms.add(adsSet?.sourceType); // Adiciona o tipo de plataforma ao Set
    });
    const uniquePlatformsArray = Array.from(uniquePlatforms); // Converte Set para Array
    // //console.log('lalala ads', newFiltredAdsSetData.length)



    return (
        <S.Container>
            <S.TableHeader>
            <S.MainColumn style={{ width: `${columnWidths}px` }}>
                    <S.CheckboxColumn></S.CheckboxColumn>
                    <S.StatusColumn onClick={() => {
                    setTypeFiltred('status');
                    setIsStatusAsc(!isStatusAsc);
                }}>
                    {typeFiltred === 'status' && (
                        isStatusAsc ? (
                            <ArrowUpwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        ) : (
                            <ArrowDownwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        )
                    )}
                    <>
                    <Tooltip title="Recomendamos que priorize atualizar o status pela Solomon para uma otimização mais precisa. Caso a campanha seja ativada/desativada pelo gerenciador do Facebook, precisaremos de alguns minutos para atualizá-la na Solomon." placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '10px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } } }}>
                    <span>Status</span>
                    </Tooltip>
                    </>
                </S.StatusColumn>
                    <p>Nome do conjunto de anúncios</p>
                    <S.DragDiv
                        style={{
                            cursor: 'col-resize',
                            backgroundColor: '#ccc',
                            width: '7px',
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}
                        onMouseDown={startResizing}
                    />
                </S.MainColumn>

                {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
                    <React.Fragment key={column.id}>
                        <ColumnItem column={column} setFilterDec={setFilterDec} setTypeFiltred={setTypeFiltred} typeFiltred={typeFiltred} filterDec={filterDec} showArticle={showArticle} columnSolomon={columnSolomon} uniquePlatformsArray={uniquePlatformsArray}/>
                    </React.Fragment>
                ))}
            </S.TableHeader>

            <S.Lines>
                {

                    newFiltredAdsSetData?.length > 0 &&
                    newFiltredAdsSetData?.map((adsSet: any) => {
                        return (
                            <LineTableTrackingAdsSet
                                handleSelectGraphicData={handleSelectGraphicData}
                                handleScrolltoGraphic={handleScrolltoGraphic}
                                columnSolomon={columnSolomon}
                                openSalesModal={openSalesModal}
                                date={date}
                                paymentMethod={paymentMethod}
                                key={adsSet?.adsSetId}
                                adsSet={adsSet}
                                type={type === 'allSources' ? [type, adsSet.sourceType, adsSet.adsSetSourceIcon] : type}
                                dataMetric={dataMetrics}
                                columnWidths={columnWidths}
                                dataCatalogList={dataCatalogList}
                                loadingCatalog={loadingCatalog}
                            />
                        );
                    })}
            </S.Lines>
            <TotalRowComponent columnWidths={columnWidths} name={'Conjuntos de anúncios'} quantity={newFiltredAdsSetData?.length || '0'} columns={columns} currencySymbol={currencySymbol}  validator={1} totalLine={totalLine} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon}/>

        </S.Container>
    );
}