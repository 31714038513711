import styled from 'styled-components';

type PropsContainer = {
    type: string
}

export const Container = styled.div<PropsContainer>`
    
    ${props => {
        switch (props.type) {

            case 'shipping':
                return 'width: 60rem;';
            default:
                return 'width: 37.5rem;';
        }
    }}
    min-height: 17.5rem;
    height: auto;

    background-color: #fff;
    border-radius: 20px;
`;

export const Logo = styled.div`
    img {
        width: 1.4375rem;
        height: 1.4375rem;
    }
`;

export const Logo2 = styled.div`
    svg {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
        background-color: #19c273; 
        border-radius: 50%; 
        padding: 0.5rem; 
        box-sizing: border-box; 
    }
`;

type TitleProps = {
    baseColor: string;
};

export const Title = styled.div<TitleProps>`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--black);
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin-top: 1rem;
    span {
        color: ${(props) => props.baseColor};
    }
`;

export const Title2 = styled.div<TitleProps>`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--black);
    display: flex;
    width: 97%;
    gap: 0.4rem;
    // justify-content: space-between;
    margin-top: 1rem;
    span {
        color: ${(props) => props.baseColor};
    }
`;

export const Subtitle = styled.div`
    margin-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.125rem;
    color: var(--gray-700);
    margin-bottom: 1.5rem;
`;

export const Line = styled.div`
    height: 1px;
    margin-bottom: 1.5rem;
    width: 100%;
    background-color: #ebebeb;
`;

export const Line2 = styled.div`
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin-bottom: 1rem;
`;

export const ConnectionContainer = styled.div``;
type ConnectionContainerProps = {
    isConnected: boolean;
};

export const StatusConnection = styled.div<ConnectionContainerProps>`
    font-weight: 600;
    font-size: 0.75rem;
    // line-height: 1.125rem;
    
    display: flex;
    align-items: center;
    color: var(--gray-800);
    justify-content: space-between;
    // margin-top: 1.5rem;
    // margin-bottom: 1.5rem;
    span {
        display: block;
        background-color: ${(props) => (props.isConnected ? 'var(--green-100)' : 'var(--red-100)')};
        width: 5.625rem;
        height: 1.25rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${(props) => (props.isConnected ? '#087c4d' : 'var(--red-800)')};
        font-size: 0.65625rem;
        line-height: 0.984375rem;
    }
`;

type LastConnectionProps = {
    hasLastUpdated: boolean;
};

export const LastConnection = styled.div<LastConnectionProps>`
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    span {
        display: block;

        background-color: ${(props) => (props.hasLastUpdated ? 'var(--purple-100)' : 'var(--red-100)')};
        width: 8.75rem;
        height: 1.25rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${(props) => (props.hasLastUpdated ? 'var(--purple-800)' : 'var(--red-800)')};
        font-size: 0.65625rem;
        line-height: 0.984375rem;
    }
`;
export const InnerContainerShopify = styled.div`
    margin-top: 2rem;
    border: 1px solid var(--gray-120);
    border-radius: 10px;
    width: 100%;
    height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }

    input {
        height: 2.5rem;
        width: 33.375rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #f9f9f9;
        color: var(--black);
        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #f9f9f9;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: var(--green);
            border-bottom: 1px solid var(--green);
        }
    }
`;

export const VisibilityButtonDomain = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 2.8rem;
    right: 2rem;
    cursor: pointer;
`;

export const VisibilityButtonToken = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 7.7rem;
    right: 2rem;
    cursor: pointer;
`;

export const InfoContainerShopify = styled.div`
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-left: 0.8rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;
