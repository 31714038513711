import styled from 'styled-components';

export const Container = styled.div`
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

export const TableHeader = styled.div`
    display: flex;
    color: var(--gray-700);
    background-color: var(--gray-115);
    height: 3.5rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    span {
        display: flex;
        flex: 1;
    }
    margin-top: 2rem;
`;

export const Image = styled.div`
    width: 7.2rem;
`;



export const Price = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Cost = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const EditVariants = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const ProductName = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`;

export const Action = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`;

export const LastSale = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 22%;
`;

export const Lines = styled.div`
    > div:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        margin-bottom: 2rem;
    }

    > div:nth-child(2n) {
        background-color: var(--gray-115);
    }
`;

export const ContainerPagination = styled.div`
    height: 4rem;
    margin-top: -1.7rem;
    display: flex;
    padding-right: 2.93rem;
    align-items: center;
    justify-content: end;

    .MuiPaginationItem-root.Mui-selected {
        background-color: var(--green);
    }

    .MuiPaginationItem-root.Mui-selected:hover {
        background-color: var(--green);
    }
`;
