import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getShopifySubscriptionUserData(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/billing/${company_id}/subscription/shopify`);
    return data;
}

export async function deleteShopifySubscriptionUserData(company_id: string){
    const data: AxiosResponse = await api.delete(`${BASE_URL}/billing/${company_id}/subscription/shopify`);
    return data;
}
