import { useEffect, useState, useRef, useMemo } from 'react';
import useAuth from '../../hooks/useAuth';
import { useDashboardData, useTaxesRate, useGetShipping } from '../../services/hooks/dashHooks';
import { useProductsAnalyticsList } from '../../services/hooks/productsHooks';
import { CardsDefault } from './Graphics/CardsDefault';
import { Conversions } from './Graphics/Conversions';
import { FinancialMetrics } from './Graphics/FinancialMetrics';
import { OverviewChannel } from './Graphics/OverviewChannel';
import { Payments } from './Graphics/Payments';
import { Products } from './Graphics/Products';
import { Profit } from './Graphics/Profit';
import { RegionAnalysis } from './Graphics/RegionAnalysis';
import { Revenue } from './Graphics/Revenue';
import { TimeAnalysis } from './Graphics/TimeAnalysis';
import * as S from './style';
import { format, subDays } from 'date-fns';
import { Meta } from './Meta';
import { storageCurrentUserMetaGet } from '../../storage/storageCurrentUser';
import { getMetrics, calculateDateToSeeMore, getSubMetrics, getMetricsShipping } from './utils';
import Calendar from '../../components/Calendar';
import { useIntercom } from 'react-use-intercom';
import { trackEvent } from '../../services/segment/sendEvent';
import { calculateValueOccurrences } from './utils';
import { useRefreshContext } from '../../contexts/RefeshContext';
import { useOrders } from '../../services/hooks/ordersHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingRemoveAll } from '../../storage/storageOnboarding';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalCongratulations from '../Dashboard/CongratulationsModal/index'
import ProductSelector from './ProductFilter';

export function Dashboard() {
    const {user, currentCompany} = useAuth()
    const { refreshCounter } = useRefreshContext();
    const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
    const location = useLocation();
    const prevLocation = useRef(location.pathname);
    const [metaProfit2, setMetaProfit] = useState([]);
    // Em seu componente Dashboard ou similar
    useEffect(() => {

    const pathCameFrom = prevLocation.current; // Armazena o caminho anterior

    if (localStorage.getItem(`onboardingCompleted_${currentCompany}`) === 'true') {
            setOpenCongratulationModal(true); // Você precisaria definir esta função ou usar algum estado para abrir o modal
            localStorage.removeItem(`onboardingCompleted_${currentCompany}`); // Opcional: limpa a flag para não abrir novamente
        }
    }, []);


    const shopifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length || 0;
    const kiwifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length || 0;

    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetchDashboardData();
            refetchDataGraphics();
            refetchTaxesData();
            DataSeeMore();
            refetchDashboardMeta();
            refetchShippingCostData();
            refetchOrders();
        }
      }, [refreshCounter]);

    const { boot } = useIntercom();

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'summary'
        });
    }, [])

    useEffect(() => {
        boot({
            name: user.name,
            userId: user.user_id,
            email: user.credentials?.email,
            phone: user.phone,
            createdAt: user.created_at
         });
      }, [boot]);
    
    // pega a data do datepicker 
    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);

    //pega a data do datepicker (a mesma data do de cima) e analisa o calculateDateToSeeMore
    const [dateToSeeMore, setDateToSeeMore] = useState(calculateDateToSeeMore(date[0], date[1]));
    useEffect(() => {
        setDateToSeeMore(calculateDateToSeeMore(date[0], date[1]));
        setCostOperationDate((prevState: any) => {
            return {
                ...prevState!,
                startDate: date[0],
                endDate: date[1],
            };
        });
    }, [date]);

    const { data: dataProductList, isLoading: isLoadingProductList, refetch: refetchProductList } = useProductsAnalyticsList(currentCompany);

    const [productIds, setProductIds] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (!isLoadingProductList && dataProductList && dataProductList.data && dataProductList.data.data) {
            const productsData = dataProductList.data.data.map(item => ({
                product_id: item.product_id,
                product_name: item.product_name
            }));
            setProducts(productsData);
            const ids = productsData.map(item => item.product_id);
            setProductIds(ids);

            if (shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1){
                setProductIds([]);
            }
        }
    }, [isLoadingProductList, dataProductList]);

    const { data: dataDashboard, isLoading: isLoadingDashboard, refetch: refetchDashboardData } = useDashboardData(date[0], date[1], currentCompany, productIds);

    const {data: dataGraphics, isLoading: isLoadingGraphics, refetch: refetchDataGraphics} = useDashboardData( format(subDays(new Date(date[1]), 5),'yyyy-MM-dd'), date[1], currentCompany, productIds);
    
    const { data: dataTaxesRate, isLoading: isLoadingTaxesRate, refetch: refetchTaxesData } = useTaxesRate(currentCompany);
    
    const { data: dataShippingCost, isLoading: isLoadingShippingCost, refetch: refetchShippingCostData } = useGetShipping(currentCompany);
        
    const {data: dataSeeMore, refetch: DataSeeMore} = useDashboardData(dateToSeeMore[0], dateToSeeMore[1], currentCompany, productIds);
    
    const meta = storageCurrentUserMetaGet(currentCompany);
    
    const {data: dataGraphicsMeta, isLoading: isLoadingGraphicsMeta, refetch: refetchDataGraphicsMeta} = useDashboardData(meta?.start, meta?.end, currentCompany, productIds);
    
    const { data: dataDashboardMeta, isLoading: isLoadingDashboardMeta, refetch: refetchDashboardMeta } = useDashboardData(meta?.start, meta?.end, currentCompany, productIds);
    
    const { isLoading: ordersLoading, data: ordersData, refetch: refetchOrders } = useOrders(date[0], date[1], currentCompany);


    const { isLoading: ordersLoadingGraphics, data: ordersGraphics, refetch: refetchOrdersGraphics } = useOrders(format(subDays(new Date(date[1]), 5),'yyyy-MM-dd'), date[1], currentCompany);

    const { isLoading: ordersMetaLoadingGraphics, data: ordersMetaGraphics, refetch: refetchOrdersMetaGraphics } = useOrders(meta.start, meta.end, currentCompany);
    
    const { metricTax, metricTaxAndFees } = getSubMetrics(dataDashboardMeta?.data, dataTaxesRate?.data?.data, ordersGraphics?.data?.data, dataShippingCost?.data?.data);
    const { shipping_cost } = getMetricsShipping(ordersData?.data?.data, dataShippingCost?.data?.data);  // Calcula o custo de frete
    
    const metaProfit = useMemo(() => {
        if (dataDashboardMeta?.data) {

            const approvedRevenueMetric = dataDashboardMeta?.data?.approved_revenue_metric?.approved;
            const marketingCostMetric = dataDashboardMeta?.data?.marketing_cost_metric?.total;
            const productsCostMetric = dataDashboardMeta?.data?.products_cost_metric + shipping_cost;
            const operationCostMetric = dataDashboardMeta?.data?.additional_cost_metric;
            const taxesAndFeesMetric = metricTaxAndFees;

            const realProfit = approvedRevenueMetric - (marketingCostMetric + productsCostMetric + operationCostMetric + taxesAndFeesMetric);

            console.log('realprofit', realProfit, 'approvedRevenueMetric', approvedRevenueMetric, 'marketingCostMetric', marketingCostMetric, 'productsCostMetric', productsCostMetric, 'operationCostMetric', operationCostMetric, 'taxesAndFeesMetric', taxesAndFeesMetric);

            return realProfit;
        }
        return 0;
    }, [dataDashboardMeta?.data, metricTax, meta.start, meta.end, date, shipping_cost, metricTaxAndFees]);

    
    const approvedRevenueChart = dataDashboardMeta?.data?.approved_revenue_chart.approved['y_axis'];
    const approvedRevenue = approvedRevenueChart?.reduce((acumulador: any, elemento: any) => acumulador + elemento, 0);
    

    const isLoading = ordersLoading || isLoadingShippingCost || isLoadingDashboard || isLoadingTaxesRate || isLoadingDashboardMeta || isLoadingGraphics || Object.keys(user?.companies).includes(currentCompany) === false;

    const [costOperationDate, setCostOperationDate] = useState({
        value: '',
        frequency: 'monthly',
        date: '',
        startDate: date[0],
        endDate: date[1],
    });
    
    let costMarketingValue = calculateValueOccurrences(costOperationDate?.startDate, costOperationDate?.endDate, costOperationDate?.frequency, costOperationDate?.date, costOperationDate?.value);

    return (
        <S.Container>
            <S.InnerContent>
                <S.Section>
                    <S.MetaSection>
                        <Meta isLoading={isLoading} profit={metaProfit} approvedRevenue={approvedRevenue} />
                    </S.MetaSection>
                    {(shopifyIntegratedAccounts <= 1 && kiwifyIntegratedAccounts > 1) && (
                        <ProductSelector products={products} setProductIds={setProductIds} />
                    )}
                    <S.CalendarContainer>
                        <span></span>
                        <Calendar date={date} setDate={setDate} dashboard={true} />
                    </S.CalendarContainer>
                </S.Section>
                <S.ContentSection>
                    <Profit setMetaProfit={setMetaProfit} date={date} isLoading={isLoading} data={dataDashboard?.data} dataGraphic={dataGraphics?.data} taxes={dataTaxesRate?.data?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} ordersGraphics={ordersGraphics?.data?.data} />
                    <FinancialMetrics isLoading={isLoading} data={dataDashboard?.data} dataGraphic={dataGraphics?.data} taxes={dataTaxesRate?.data?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} ordersGraphics={ordersGraphics?.data?.data} />
                </S.ContentSection>
                <S.ContentSection>
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="approvedRevenue" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} setCostOperationDate={setCostOperationDate} costMarketingValue={costMarketingValue} type="marketingCost" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="productsCost" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="taxChart" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="operationCost" />
                </S.ContentSection>
                <S.ContentSection>
                    <Revenue isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                    <Payments isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                    <Conversions isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                </S.ContentSection>
                <S.ContentSection>
                    <Products isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                    <OverviewChannel isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                </S.ContentSection>
                <S.ContentSection>
                    {(shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1) && (
                        <RegionAnalysis isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                    )}
                    <TimeAnalysis isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} />
                </S.ContentSection>
            </S.InnerContent>

            {openCongratulationModal && (
            
                <ModalCongratulations onClose={setOpenCongratulationModal}/>

                ) 
            }

        </S.Container>
    );
}
