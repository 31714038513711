import { TemplateModal } from '../index';
import * as S from './style';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ButtonApp } from '../../../../components/ButtonApp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShareIcon from '@mui/icons-material/Share';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { ModalGenericGatewayProps, userDTO } from '../../../../@types';
import { format } from 'date-fns';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { storageOnboardingIntegrateAppmaxSave, storageOnboardingIntegrateMercadoPagoGatewaySave, storageOnboardingIntegrateOutrosGatewaySave, storageOnboardingIntegrateYeverGatewaySave } from '../../../../storage/storageOnboarding';
import { useCreateIntegrationGateway } from '../../../../services/hooks/integrationHooks';
import { storageOnboardingCurrentCompanyGet} from '../../../../storage/storageOnboarding';
import PixRoundedIcon from '@mui/icons-material/PixRounded';
import useAuth from '../../../../hooks/useAuth';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Modal from '../../../../components/Modal';
import { TemplateModal2 } from '../../../Integration/ModalIntegration';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';


export function ModalGenericGateway({ setShowPopup, setIntegrate, platform }: ModalGenericGatewayProps) {
    const urlLogo = urlInfos[platform]?.url;
    const baseColor = urlInfos[platform]?.color;
    const baseColor2 = '#19c380';

    let currencySymbol = 'R$';
    const currentCompany = storageOnboardingCurrentCompanyGet();

    const { mutate: handleCreateIntegration, isLoading, isError } = useCreateIntegrationGateway();

    const defaultTax = urlInfos[platform]?.defaultTax;
    

    const { user, setUserContext } = useAuth();
    const [isChecked, setIsChecked] = useState(true);
    const [info, setInfo] = useState(defaultTax);
    const [showParcelamentoModal, setShowParcelamentoModal] = useState(false);

    useEffect(() => {
        const platformMaiuscula = platform.charAt(0).toUpperCase() + platform.slice(1);
        const storedInfo = localStorage.getItem(`info_only${platformMaiuscula}_${storageOnboardingCurrentCompanyGet()}`);
        if (storedInfo) {
            setInfo(JSON.parse(storedInfo));
        }
    }, []);

    function handleIntegrate() {
        const platformMaiuscula = platform.charAt(0).toUpperCase() + platform.slice(1);
        const body = {
            credit_card: {
                variable_fee: formatStringToNumber(info.gateway_credit_card_rate),
                fixed_fee: formatStringToNumber(info.gateway_credit_card_fixed),
                x2_fee: formatStringToNumber(info.gateway_credit_card_rate_x2),
                x3_fee: formatStringToNumber(info.gateway_credit_card_rate_x3),
                x4_fee: formatStringToNumber(info.gateway_credit_card_rate_x4),
                x5_fee: formatStringToNumber(info.gateway_credit_card_rate_x5),
                x6_fee: formatStringToNumber(info.gateway_credit_card_rate_x6),
                x7_fee: formatStringToNumber(info.gateway_credit_card_rate_x7),
                x8_fee: formatStringToNumber(info.gateway_credit_card_rate_x8),
                x9_fee: formatStringToNumber(info.gateway_credit_card_rate_x9),
                x10_fee: formatStringToNumber(info.gateway_credit_card_rate_x10),
                x11_fee: formatStringToNumber(info.gateway_credit_card_rate_x11),
                x12_fee: formatStringToNumber(info.gateway_credit_card_rate_x12),
                installment_limit: formatStringToNumber(info.gateway_installment_limit),
            },
            billet: {
                fixed_fee: formatStringToNumber(info.gateway_boleto_fixed),
                variable_fee: formatStringToNumber(info.gateway_boleto_rate),
            },
            pix: {
                variable_fee: formatStringToNumber(info.gateway_pix_rate),
                fixed_fee: formatStringToNumber(info.gateway_pix_fixed),
            },
            company_id: currentCompany,
            source: 'manual',
            last_update: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            platform: platform,
            user: user
        };

        localStorage.setItem(`info_gateway_${storageOnboardingCurrentCompanyGet()}`, JSON.stringify(info));
        localStorage.setItem(`info_only${platformMaiuscula}_${storageOnboardingCurrentCompanyGet()}`, JSON.stringify(info));

        let newManualGateway = info;
        // newManualGateway.push(info);
        
        //console.log('info', info)

        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    manual_inputs: {
                        ...user?.companies?.[currentCompany]?.manual_inputs,
                        costs_rates: newManualGateway,
                    },
                },
            },
        };

        setUserContext(newUser as userDTO);

        handleCreateIntegration(body, {
            onSuccess: () => {
               
            },
        });
        if (setIntegrate){
            setIntegrate(true);
        }
        if (platform === 'mercadoPagoGateway'){
            storageOnboardingIntegrateMercadoPagoGatewaySave();
        }else if (platform === 'yeverGateway'){
            storageOnboardingIntegrateYeverGatewaySave();
        }else if (platform === 'appmaxGateway'){
            storageOnboardingIntegrateAppmaxSave();
        }else {
            storageOnboardingIntegrateOutrosGatewaySave();
        }
        
        // showAlert('Taxa adicionada! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setShowPopup(false);
    }

    function handleCheckbox() {
        if (!isChecked) {
            setInfo(defaultTax);
        }
        setIsChecked((prev) => !prev);
    }

    function handleChangeinfo(e: any) {
        if (isChecked) {
            setIsChecked(false);
        }

        setInfo((prev: any) => {
            let value = e.target.value;
            if (e.target.id.includes('fixed')) {
                value = formatValue(value);
            } else if (e.target.id.includes('rate')) {
                value = formatPercentage(value);
            }
            return {
                ...prev,
                [e.target.id]: value,
            };
        });
    }

    function formatValue(valueInput: string) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return `${currencySymbol} ` + newValue;
    }

    function handleKeyDown(e) {
        const value = e.target.value;
        if (e.key === 'Backspace' && e.target.selectionStart === value.length) {
            e.preventDefault();
            e.target.setSelectionRange(value.length - 1, value.length - 1);
        }
    }

    function formatPercentage(valueInput: string) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return newValue + '%';
    }

    function formatStringToNumber(text: string) {
        text = text.replace(/\$|R\$|€|%/g, '');
        text = text.replace(/R\$/g, '').replace(/ {2}/g, '').replace(/%/g, '');
        text = text.replace(/\./g, '');
        text = text.replace(/,/g, '.');
        return Number(text);
    }

    function handleModalParcelamento() {
        setShowParcelamentoModal(true)
    }

    function handleCloseModal () {
        setShowParcelamentoModal(false)
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={ urlInfos[platform]?.alt}
                title={urlInfos[platform]?.alt}
                subtitle={'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.'}
                statusConnection={'Conectado'}
                lastConnection={'Última conexão: 10/09/2021'}
            />

            <S.Line/>
            <S.CheckboxSection onClick={handleCheckbox}>
                <Checkbox
                    size="small"
                    checked={isChecked}
                    sx={{
                        '&.Mui-checked': {
                            color: '#19c273',
                        },
                    }}
                />{' '}
                Definir como valores padrão do {urlInfos[platform]?.alt}
            </S.CheckboxSection>
            <S.Line/>

            <S.ContentPaymentInformation>

                <S.LineContainer>

                <S.TypePaymentInformation> 

                    <S.HeaderTYpePaymentInformation>
                        <ReceiptIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} /> Boleto
                    </S.HeaderTYpePaymentInformation>
                    <S.ContentTypePaymentInformation>
                        <div>
                            <label>Taxa percentual</label>
                            <input onKeyDown={handleKeyDown} value={info.gateway_boleto_rate} onChange={handleChangeinfo} id="gateway_boleto_rate" type="text" placeholder=" %" />
                        </div>
                        <S.VerticalLine />
                        <div>
                            <label>Taxa fixa</label>
                            <input value={info.gateway_boleto_fixed} onChange={handleChangeinfo} id="gateway_boleto_fixed" type="text" placeholder={` ${currencySymbol}`} />
                        </div>
                    </S.ContentTypePaymentInformation>
                </S.TypePaymentInformation>
                </S.LineContainer>

                <S.LineContainer>
                    <S.HeaderTYpePaymentInformation>
                        <PixRoundedIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} />Pix
                    </S.HeaderTYpePaymentInformation>
                    <S.ContentTypePaymentInformation>
                        <div>
                            <label>Taxa percentual</label>
                            <input onKeyDown={handleKeyDown} value={info.gateway_pix_rate} onChange={handleChangeinfo} id="gateway_pix_rate" type="text" placeholder=" %" />
                        </div>
                        <S.VerticalLine />
                        <div>
                            <label>Taxa fixa</label>
                            <input value={info.gateway_pix_fixed} onChange={handleChangeinfo} id="gateway_pix_fixed" type="text" placeholder={` ${currencySymbol}`} />
                        </div>
                    </S.ContentTypePaymentInformation>
                </S.LineContainer>

                <S.LineContainer>
                    <S.HeaderTYpePaymentInformation>
                        <CreditCardIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} /> Cartão
                    </S.HeaderTYpePaymentInformation> 
                    <S.ButtonContentTypePaymentInformation>
                        <ButtonApp
                            border=''
                            borderRadius='8px'
                            width="10rem"
                            height="1.8rem"
                            color="#fff"
                            background="#19c380"
                            icon={<SettingsRoundedIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleModalParcelamento}
                            text="Configurar Taxas"
                            isLoading={isLoading}
                        />
                    </S.ButtonContentTypePaymentInformation>
                </S.LineContainer>
                {showParcelamentoModal && (
                    <Modal onClose={() => setShowParcelamentoModal(false)}>
                        <S.ContainerPopup>
                            <TemplateModal2
                                baseColor={baseColor2}
                                type={'Parcelamento'}
                                title={'Parcelamento'}
                                subtitle={'Configure as taxas de parcelamento para pagamentos com cartão de crédito.'}
                            />
                            <S.ContainerInformation>
                                <S.LineContainer>
                                    <S.HeaderTYpePaymentInformation>
                                        <CreditScoreRoundedIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} />À vista
                                    </S.HeaderTYpePaymentInformation>
                                    <S.ContentTypePaymentInformation>
                                        <div>
                                            <label>Taxa percentual</label>
                                            <input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate} onChange={handleChangeinfo} id="gateway_credit_card_rate" type="text" placeholder=" %" />
                                        </div>
                                        <S.VerticalLine />
                                        <div>
                                            <label>Taxa fixa</label>
                                            <input value={info.gateway_credit_card_fixed} onChange={handleChangeinfo} id="gateway_credit_card_fixed" type="text" placeholder={` ${currencySymbol}`} />
                                        </div>
                                    </S.ContentTypePaymentInformation>
                                </S.LineContainer>

                                <S.HeaderAbsorve2 style={{marginTop: '1.5rem', marginBottom: '0rem'}}>Taxa por parcelas</S.HeaderAbsorve2>

                                <S.ScrollableContainer>
                                    <S.HeaderScrollableContainer>
                                        <div>Parcelamento</div>
                                        <div>Taxa percentual</div>
                                    </S.HeaderScrollableContainer>
                                    <S.GridWrapper>
                                        <div>2x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x2} onChange={handleChangeinfo} id="gateway_credit_card_rate_x2" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>3x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x3} onChange={handleChangeinfo} id="gateway_credit_card_rate_x3" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>4x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x4} onChange={handleChangeinfo} id="gateway_credit_card_rate_x4" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>5x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x5} onChange={handleChangeinfo} id="gateway_credit_card_rate_x5" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>6x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x6} onChange={handleChangeinfo} id="gateway_credit_card_rate_x6" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>7x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x7} onChange={handleChangeinfo} id="gateway_credit_card_rate_x7" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>8x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x8} onChange={handleChangeinfo} id="gateway_credit_card_rate_x8" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>9x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x9} onChange={handleChangeinfo} id="gateway_credit_card_rate_x9" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>10x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x10} onChange={handleChangeinfo} id="gateway_credit_card_rate_x10" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>11x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x11} onChange={handleChangeinfo} id="gateway_credit_card_rate_x11" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>12x</div>
                                        <div><input onKeyDown={handleKeyDown} value={info.gateway_credit_card_rate_x12} onChange={handleChangeinfo} id="gateway_credit_card_rate_x12" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                </S.ScrollableContainer>

                                <S.LineContainer>
                                <S.HeaderAbsorve>
                                    Absorver parcelas? 
                                    <HelpRoundedIcon sx={{ color: 'grey', width: '12px', height: '12px' }} />
                                    <div className="tooltip">Assumir o ônus da taxa ao invés de repassar para o cliente.</div>
                                </S.HeaderAbsorve>
                                    <S.NumberAbsorve>
                                        Quantidade de parcelas:
                                        <select value={info.gateway_installment_limit} id="gateway_installment_limit" onChange={handleChangeinfo}>
                                            <option value="1">0</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </S.NumberAbsorve>
                                </S.LineContainer>
                            </S.ContainerInformation>
                            <S.Line />
                            <S.DisconnectContainer>
                            Finalizar configurações do cartão:{' '}
                                <ButtonApp
                                    border=''
                                    width="7.75rem"
                                    height="2.25rem"
                                    color="#fff"
                                    background="var(--black)"
                                    icon={<CheckRoundedIcon sx={{ width: '16px', height: '16px' }} />}
                                    onClick={handleCloseModal}
                                    text="Continuar"
                                    isLoading={isLoading}
                                />
                            </S.DisconnectContainer>
                        </S.ContainerPopup>
                    </Modal>
                )}
            </S.ContentPaymentInformation>
            {isError && (
                <S.ErrorContainer>
                    <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                    <S.ErrorText>
                        Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                    </S.ErrorText>
                </S.ErrorContainer>
            )}

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    text="Salvar"
                    isLoading={isLoading}
                    borderRadius='8px'
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}

const parcelamento = {
    gateway_credit_card_rate_x2: '0,00%',
    gateway_credit_card_rate_x3: '0,00%',
    gateway_credit_card_rate_x4: '0,00%',
    gateway_credit_card_rate_x5: '0,00%',
    gateway_credit_card_rate_x6: '0,00%',
    gateway_credit_card_rate_x7: '0,00%',
    gateway_credit_card_rate_x8: '0,00%',
    gateway_credit_card_rate_x9: '0,00%',
    gateway_credit_card_rate_x10: '0,00%',
    gateway_credit_card_rate_x11: '0,00%',
    gateway_credit_card_rate_x12: '0,00%',
    gateway_installment_limit: '1',
}

const urlInfos = {
    mercadoPagoGateway : {url: 'https://storage.googleapis.com/static-images-source/mercadopago%20icon.png', color: 'var(--blue)', alt: 'Mercado Pago', defaultTax: {
            platform: 'mercadoPagoGateway',
            gateway_credit_card_rate: '3,98%',
            gateway_credit_card_fixed: `${'R$'} 0,00`,
            gateway_boleto_fixed: `${'R$'} 3,49`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_rate: '0,99%',
            gateway_pix_fixed: `${'R$'} 0,00`,
            ... parcelamento
        },
    },
    yeverGateway : {url: 'https://storage.googleapis.com/static-images-source/yever%20icon.png', color: '#ffda56', alt: 'Yever', defaultTax: {
            platform: 'yeverGateway',
            gateway_credit_card_rate: '5,99%',
            gateway_credit_card_fixed: `${'R$'} 0,00`,
            gateway_boleto_fixed: `${'R$'} 0,00`,
            gateway_boleto_rate: '5,99%',
            gateway_pix_rate: '5,99%',
            gateway_pix_fixed: `${'R$'} 0,00`,
            ... parcelamento
        },
    },
    appmaxGateway : {url: 'https://framerusercontent.com/images/ZvKI0Bp6qyMy3u5Ei06pQe9nYA.png', color: '#9b6afa', alt: 'Appmax', defaultTax: {
            platform: 'appmaxGateway',
            gateway_credit_card_rate: '4,99%',
            gateway_credit_card_fixed: `${'R$'} 0,99`,
            gateway_boleto_fixed: `${'R$'} 3,49`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_rate: '0,99%',
            gateway_pix_fixed: `${'R$'} 0,00`,
            ... parcelamento
        },
    },
    outrosGateway : { url : 'https://storage.googleapis.com/static-images-source/gateway-icon.png', color: 'var(--black)', alt: 'plataforma não listada', defaultTax: {
            platform: 'outrosGateway',
            gateway_credit_card_rate: '0,00%',
            gateway_credit_card_fixed: `${'R$'} 0,00`,
            gateway_boleto_fixed: `${'R$'} 0,00`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_rate: '0,00%',
            gateway_pix_fixed: `${'R$'} 0,00`,
            ... parcelamento
        },
    },

}