import { createContext, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { EditColumnsTrackingProviderProps, EditColumnsTrackingContextProps, Column } from '../@types';

export const EditColumnsTrackingContext = createContext<EditColumnsTrackingContextProps>({
    columns: [],
    setColumns: () => {},
});

export function EditColumnsProvider({ children }: EditColumnsTrackingProviderProps) {
    const { currentCompany } = useAuth();

    useEffect(() => {
        // Carregar dados do localStorage ao montar o componente
        const savedColumns = localStorage.getItem(`columns_${currentCompany}`);
        if (savedColumns) {
            setColumns(JSON.parse(savedColumns));
        }
    }, []); // Executar apenas uma vez ao montar o componente

    const [columns, setColumns] = useState<Column[]>([
        { id: 'spend', name: 'Gasto', visible: true, order: 1 },
        { id: 'profit', name: 'Lucro', visible: false, order: 2 },
        { id: 'margin', name: 'Margem', visible: true, order: 3 },
        { id: 'productCostUtm', name: 'Custo de produto', visible: true, order: 4 },
        { id: 'sales', name: 'Vendas', visible: true, order: 5 },
        { id: 'salesUtm', name: 'Vendas', visible: true, order: 6 },
        { id: 'revenue', name: 'Receita', visible: true, order: 7 },
        { id: 'revenueUtm', name: 'Receita', visible: true, order: 8 },
        { id: 'roas', name: 'ROAS', visible: true, order: 9 },
        { id: 'roasUtm', name: 'ROAS', visible: true, order: 10 },
        { id: 'cpa', name: 'CPA', visible: true, order: 11 },
        { id: 'cpaUtm', name: 'CPA', visible: true, order: 12 },
        { id: 'cpc', name: 'CPC', visible: true, order: 13 },
        { id: 'cpm', name: 'CPM', visible: true, order: 14 },
        { id: 'ticket', name: 'Ticket Médio', visible: true, order: 15 },
        { id: 'ticketUtm', name: 'Ticket Médio', visible: true, order: 16 },
        { id: 'impressions', name: 'Impressões', visible: true, order: 17 },
        { id: 'clicks', name: 'Clicks', visible: true, order: 18 },
        { id: 'viewContent', name: 'Viu conteúdo', visible: true, order: 19 },
        { id: 'addToCart', name: 'Adicionou ao carrinho', visible: true, order: 20 },
        { id: 'initiateCheckout', name: 'Iniciou Checkout', visible: true, order: 21 }
    
    ]);

    return (
        <EditColumnsTrackingContext.Provider value={{columns, setColumns}}>
            {children}
        </EditColumnsTrackingContext.Provider>
    );
}