import styled from 'styled-components';

type ContainerProps = {
    showSelect: boolean;
};

export const NotificationContainer = styled.div<ContainerProps>`
display: flex;
align-items: flex-start;
flex-direction: column;
padding: 8px;
width: auto;
max-width: 280px;
cursor: default;
height: auto;
position: absolute;
z-index: 500;
top: 60px;
right: 245px;
flex-shrink: 0;
border-radius: 7.458px;
border: 0.932px solid #eaeaea;
background: white;
filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.04));
justify-content: space-around;
transition: all 250ms;
transform: translateY(-5%);
opacity: 0;

pointer-events: none;

${(props) =>
    props.showSelect
        ? `
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        `
        : `
            opacity: 0;
            visibility: hidden;
            transition: all 250ms;
            `}
`;  

export const HeaderNotifications = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem 0 0 0.5rem;
    font-size: 18px;
    font-weight: 600;
`;

export const CloseButton = styled.i`
    color: #989c97;
    margin-right: 16px;
    cursor: pointer;

    &:hover {
        position: relative;
        color: #192324;
        border-radius: 3px;
        cursor: pointer;
    }
`;

export const NotificationsHr = styled.span`
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const NotificationContent = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: row;
    gap: 13px;
    height: 58px;
    transition: height 300ms ease;

    &:hover {
        padding: 5px 5px 5px 5px;
        background-color: #fafafa;
        border-radius: 5px;
        height: 320px;
        transition: height 300ms ease;
    }
`;

export const NotificationIcon = styled.div`
    background-color: #aef1d6;
    color: #31aa76;
    height: 18px;
    font-size: 17px;
    padding: 10px;
    border-radius: 50px;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: -6px;
`;

export const Title = styled.div`
    margin: 0;
    text-align: start;
    font-size: 16px;
    margin-bottom: 6px;
    font-weight: 500;
    overflow: hidden;
    max-width: 90%;
`;

export const Text = styled.div`
    width: 100%;
    color: #989c97;
    font-weight: 500;
    max-width: 200px;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    overflow: hidden;
    /* -webkit-box-orient: vertical; */
    margin: 0;
    text-align: start;
    font-size: 11px;
    margin-bottom: 6px;
`;

export const Strong = styled.strong`
    font-weight: bold;
`;

export const ClickableText = styled.a`
    color: #989c97;
    // cursor: pointer;

    &:hover {
        color: #000000;
    }
`;
