import styled from 'styled-components';

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
`;

export const SwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

type SliderProps = {
    isChecked: boolean;
};

export const Slider = styled.span<SliderProps>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.isChecked ? 'var(--green)' : 'var(--gray-100)')};
    transition: 0.4s;
    border-radius: 34px;
`;

export const SliderBall = styled.div<SliderProps>`
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    ${(props) => (props.isChecked ? 'right: 4px;' : 'left: 4px;')}
    bottom: 3px;
    cursor: pointer;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
`;
