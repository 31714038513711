import React from 'react';
import GraphModal from '../../../components/GraphModal';
import { format, subDays } from 'date-fns';
import { ProductsCostDTO } from '../../../@types';
import { useNavigate } from 'react-router-dom';
import { ButtonApp } from '../../../components/ButtonApp';


interface ModalProductHistoryCostsProps {
    onClose: () => void;
    variantIds : string[];
    product: any;
    dataVariantCosts: ProductsCostDTO;
}


const ModalProductHistoryCosts : React.FC<ModalProductHistoryCostsProps> = ({ onClose, variantIds, product, dataVariantCosts }) => {    

    const {xAxisData, yAxisDatasets} = getGraphData((dataVariantCosts ? dataVariantCosts.data?.data : []), variantIds, product);
    //console.log('xAxisData', xAxisData);
    const navigate = useNavigate();
    
    //console.log('data1', dataVariantCosts);
    const handleRedirectVariant = () => {
        //console.log('product', product);
        navigate(`/custo/variantes/${product.product_id}`);
    }

    // //console.log('yAxisDatasets', yAxisDatasets)

    return (
        <GraphModal
            isOpen={true}
            onClose={onClose}
            xAxisData={xAxisData}
            yAxisDatasets={yAxisDatasets}
            intervalData={5}
            title="Histórico de custos"
            subTitle="Variação de custo e preço ao longo do tempo"
            footerContent={<ButtonApp border='' width="9.5rem" height="2.5rem" background="#19c27e" text={'Analisar Variantes'} color="#fff" onClick={handleRedirectVariant} />}
        />
    );

};


function getGraphData(data: any, variantIds: string[], product: any) {

    //console.log('data', data)

    const numDays = 30;
    const today = new Date();
    const xAxisData: string[] = [];
    const yAxisDatasets: { name: string; data: number[]; currency: string }[] = variantIds.map(variantId => {
        const variant = product.variants.find((v: any) => v.variant_id === variantId);
        return {
            name: variantId,
            data: [],
            currency: variant?.currency || 'USD' // Assume USD como padrão
        };
    });

    for (let i = 0; i < numDays; i++) {
        const date = subDays(today, i);
        xAxisData.unshift(format(date, 'yyyy-MM-dd')); // Adiciona data formatada ao início do array para ordem cronológica

        variantIds.forEach((variantId, index) => {
        const costsForVariant = data.filter((item: any) =>
            item.variant_id === variantId &&
            new Date(item.period_start) <= date &&
            (!item.period_end || new Date(item.period_end) >= date)
        );

        // Escolhe o custo mais recente se houver múltiplos
        const mostRecentCost = costsForVariant.reduce((mostRecent: any, item: any) => {
            if (!mostRecent) return item;
            return new Date(item.updated_cost_at) > new Date(mostRecent.updated_cost_at) ? item : mostRecent;
        }, null);

        yAxisDatasets[index].data.unshift(mostRecentCost ? parseFloat(mostRecentCost.variant_cost) : 0); // Assume 0 se nenhum custo for encontrado
        });
    }

    // troca p index para o nome do produto
    yAxisDatasets.forEach((dataset, index) => {
        const variant = product.variants.find((item: any) => item.variant_id === variantIds[index]);
        dataset.name = variant?.variant_title || variant?.variant_id;
    });

    return { xAxisData, yAxisDatasets }; 

}


export default ModalProductHistoryCosts;