import { TemplateModal } from '../../index';
import ShareIcon from '@mui/icons-material/Share';
import * as S from './style';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useEffect, useState } from 'react';
import {  ModalManualCheckoutProps, userDTO } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import { useSubmitManualCheckout, useDeleteManualCheckout, useGetManualCheckout } from '../../../../../services/hooks/manualCheckoutsHooks';
import { useIntercom } from 'react-use-intercom';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import { storageOnboardingIntegrateAdooreiRemove, storageOnboardingIntegrateAdooreiSave, storageOnboardingIntegrateAdooreiGet, storageOnboardingIntegrateUnicoPagSave, storageOnboardingIntegrateUnicoPagGet, storageOnboardingIntegrateUnicoPagRemove, storageOnboardingIntegrateShopifyCheckoutSave, storageOnboardingIntegrateShopifyCheckoutGet, storageOnboardingIntegrateShopifyCheckoutRemove, manualCheckoutGeneralLoadingGet} from '../../../../../storage/storageOnboarding';
import { formatPercentageInput } from '../../../../../utils';
import useAlert from '../../../../../hooks/useAlert';
import { set } from 'date-fns';
import { Tooltip } from '@mui/material';

export function ModalManualCheckout({hasAdooreiCheckout, hasUnicoPagCheckout, hasShopifyCheckout, setHasAdooreiCheckout, setHasUnicoPagCheckout, setHasShopifyCheckout, setShowPopup, platform}: ModalManualCheckoutProps) {
    const urlLogo = infoDict?.[platform]?.url;
    const baseColor = infoDict?.[platform]?.color;

    const { user, currentCompany, setUserContext, updateUser } = useAuth();

    const { currencySymbol } = useCurrencyType();

    const { mutate: handleCreateIntegration, isLoading: isLoadingCreateIntegration } = useSubmitManualCheckout(platform, setHasUnicoPagCheckout, setHasAdooreiCheckout, setHasShopifyCheckout, user, currentCompany, setUserContext);
    const { mutate: handleDeleteIntegration, isLoading: isLoadingDeleteIntegration } = useDeleteManualCheckout(platform, setHasUnicoPagCheckout, setHasAdooreiCheckout, setHasShopifyCheckout, user, currentCompany, setUserContext);

    let manualCheckout: any;
    let flatTaxGet: number;
    let percentualTaxGet: number;

   
    useEffect(() => {
        // acha o checkout manual  na lista de checkouts manuais
        const manualCheckouts = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts; 
        //console.log('manualCheckouts', manualCheckouts);
        if (manualCheckouts) {
            manualCheckout = manualCheckouts.find((checkout: any) => checkout.checkout === platform);
            flatTaxGet = manualCheckout?.flatTax
            percentualTaxGet = manualCheckout?.percentualTax;
            const getDataManualCheckout =  {
                flatTax: `${currencySymbol} ${flatTaxGet ? flatTaxGet : 0 }`,
                percentualTax: `${percentualTaxGet ? percentualTaxGet : '0,00' }`,
                checkout: platform,
            };
            setInfo(getDataManualCheckout);
        }

    }, []);

    const [info, setInfo] = useState({
        flatTax: `${currencySymbol} ${flatTaxGet ? flatTaxGet : 0}`,
        percentualTax: `${percentualTaxGet ? percentualTaxGet : 0}%`,
        checkout: platform,
    });
    

    function handleIntegrate() {
        const body = {
            company_id: currentCompany,
            checkout: platform,
            flatTax: formatStringToNumber(info.flatTax) ,
            percentualTax: formatStringToNumber(info.percentualTax),
            user: user,
        };

        handleCreateIntegration(body, {
        });            

        setShowPopup(false);

        
    }

    function handleDesintegrate(){
        const body = {
            company_id: currentCompany,
            checkout: platform,
        };
        setInfo(
            {
                flatTax: `${currencySymbol} 0`,
                percentualTax: `0%`,
                checkout: platform,
            }
        )
        
        
        setShowPopup(false);

        handleDeleteIntegration(body, {
            onSuccess: () => {                
            },
        });
    }

    function formatStringToNumber(text: string) {
        text = text.replace(/\$|R\$|€|%/g, '');
        text = text.replace(/R\$/g, '').replace(/ {2}/g, '').replace(/%/g, '');
        text = text.replace(/\./g, '');
        text = text.replace(/,/g, '.');
        return Number(text);
    }
    
    function formatValue(valueInput: string) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return `${currencySymbol} ` + newValue;
    }

    function formatPercentage(e: any) {
        const formattedValue = formatPercentageInput(e);

        return formattedValue;
    }

    function handleChangeinfo(e: any) {
        setInfo((prev: any) => {
            let value = e.target.value;
            if (e.target.id.includes('flatTax')) {
                value = formatValue(value);
            } else if (e.target.id.includes('percentualTax')) {
                value = formatPercentage(e);
            }
            return {
                ...prev,
                [e.target.id]: value,
            };
        });
    }

    const hasinfo = flatTaxGet !== 0 || percentualTaxGet !== 0;

    let isIntegrate = false;

    if (platform === 'Adoorei') {
      isIntegrate = hasAdooreiCheckout || storageOnboardingIntegrateAdooreiGet();
    } else if (platform === 'UnicoPag') {
      isIntegrate = hasUnicoPagCheckout || storageOnboardingIntegrateUnicoPagGet();
    } else if (platform === 'Shopify Checkout') {
      isIntegrate = hasShopifyCheckout || storageOnboardingIntegrateShopifyCheckoutGet();
    }
    

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={platform}
                title={platform}
                subtitle={'Integre sua loja com a ' + platform + ' e automatize seu processo de dropshipping.'}
                statusConnection={isIntegrate}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
            <InputIntegration value={info.flatTax} setValue={handleChangeinfo} inputName="Taxa fixa mensal:" inputId="flatTax" placeholder="0,00" />
                <InputIntegration
                    value={info.percentualTax}
                    setValue={handleChangeinfo}
                    inputName="Taxa percentual:"
                    inputId="percentualTax"
                    placeholder="0,00"
                    percentage={true}
                />      
            </S.InnerContainer>

            <Tooltip title={manualCheckoutGeneralLoadingGet(currentCompany) ? "Há uma plataforma de checkout em processo de integração ou desativação. Por favor, aguarde até a finalização." : ''} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {left: '13rem', bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '7px',padding: '5px 10px',color: 'white',textWrap: 'wrap',zIndex: 999999}},}}>
                <S.DisconnectContainer>
                    {!isIntegrate && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={handleIntegrate}
                                text="Integrar"
                                disabled={manualCheckoutGeneralLoadingGet(currentCompany)}
                                isLoading={isLoadingCreateIntegration}
                            />
                        </>
                    )}
                    {isIntegrate && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={handleDesintegrate}
                                isLoading={isLoadingDeleteIntegration}
                                disabled={manualCheckoutGeneralLoadingGet(currentCompany)}
                                text="Desconectar"
                            />
                        </>
                    )}
                </S.DisconnectContainer>
            </Tooltip>
        </S.Container>
    );
}


const infoDict = {
    "Adoorei": { url: 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg', color: '#ff0080' },
    "UnicoPag": { url: 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png', color: '#b60001' },
    "Shopify Checkout": { url: 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg', color: '#19c27e' },
}