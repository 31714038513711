import styled from 'styled-components';

export const Container = styled.div`
    // border-radius: 10px;
    margin-top: 5rem;
    position: relative;
    border-right: 1px solid #e6e6e6;
    // border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-x: auto;
    height: auto;
    max-height: calc(100vh - 263px);
    border-collapse: collapse;
//     &::-webkit-scrollbar {
//     height: 10px; /* Altura da barra de rolagem */
// }


`;

export const CheckBoxArea = styled.div`
    width: 2.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #dedede;
    cursor: pointer;
    margin-left: -0.5rem;
    margin-right: 1.5rem;
    padding-right: 8px;
`;

export const TableHeader = styled.div`
    display: flex;
    // border-top-right-radius: 10px;
    // border-top-left-radius: 10px;
    min-width: 220rem;
    background-color: var(--white);
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;

    position: sticky;
    top: 0;
    z-index: 20;
`;

export const Lines = styled.div`

    > div:nth-child(odd) {
        background-color: var(--white) !important;
    }

    > div:nth-child(even) {
        background-color: #f5f6f7 !important;
    }
`;

export const MainColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--black);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    // border-top-left-radius: 9.5px;
    padding-left: 1rem;
    padding-right: 13.5rem;
    width: 29.125rem;
    height: 2.8rem;
    position: sticky;
    left: 0;
    z-index: 5;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    // border-top: 1px solid #e6e6e6;
    background-color: inherit;

    div {
        transition: all 0.2s ease-in-out;
        &:hover {
            transition: all 0.2s ease-in-out;
            width: 5px !important;
            background-color: #999 !important;
        }
        &:active {
            transition: all 0.2s ease-in-out;
            background-color: #19c27e !important;
        }
    }
    
`;

export const DefaultColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--black);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 1rem;
    text-wrap: nowrap;
    width: 10.25rem;
    border-right: 1px solid #e6e6e6;
    
    &:last-child {
        border-right: none;
    }

    div {
        margin-top: 2px;
        gap: 0.25rem;
        display: flex;
    }

    img {
        width: 0.75rem;
        height: 0.75rem;
    }
`;


export const DefaultColumnSolomon = styled(DefaultColumn)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &:hover {
        background: linear-gradient(400deg, rgba(25, 194, 126, 0.17130602240896353) 0%, rgba(255, 255, 255, 1) 40%);
        border-radius: 5px;
        transition: transform 0.2s ease-in-out;
    }
    cursor: pointer;
    img {
        width: 0.75rem;
        height: 0.75rem;
    }
    span {
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        font-size: 0.7rem;
        font-weight: 700;
        color: #399875 !important;
    }
`;

export const TotalRow = styled.div`
    display: flex;
    min-width: 220rem;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
    font-size: 0.875rem;
    background-color: var(--white);
    position: sticky;
    z-index: 20;
    bottom: -3px;
`;

export const TotalMainColumn = styled.div`
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 3rem;
    width: 29.125rem;
    height: 3.3rem;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    // border-bottom: 1px solid #e6e6e6;
    border-bottom-left-radius: 10px;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    background-color: var(--white);
`;

export const TotalDefaultColumn = styled.div`
    color: var(--black);
    // border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
    background-color: var(--white);
`;
