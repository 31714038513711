import * as S from './style'

type TablePlaceholderProps = {
    size: 'small' | 'medium' | 'large';
    showTitle?: boolean;
    showSubtitle?: boolean;
    showImage?: boolean;
    subtitleText?: string;
}

export function TablePlaceholder({ size, showTitle = true, showSubtitle = true, showImage = true, subtitleText }: TablePlaceholderProps) {
    const imageSize = size === 'small' ? '150px' : size === 'medium' ? '250px' : '350px';
    const fontSizeTitle = size === 'small' ? '18px' : size === 'medium' ? '18px' : '24px';
    const fontSizeSubtitle = size === 'small' ? '12px' : size === 'medium' ? '16px' : '20px';

    return(
        <S.Container>
            {showImage && <img src="https://solomon.com.br/wp-content/uploads/2023/10/vazio.psd.png" alt="Nenhum produto encontrado" style={{ width: imageSize, height: 'auto' }} />}
            {showTitle && <S.Title style={{ fontSize: fontSizeTitle }}>Oops!</S.Title>}
            {showSubtitle && <S.Subtitle style={{ fontSize: fontSizeSubtitle }}>{subtitleText ?? 'Sem dados no período selecionado. Por favor, escolha um novo período.'}</S.Subtitle>}
        </S.Container>
    )
}