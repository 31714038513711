import styled from 'styled-components';


export const TotalRow = styled.div`
    display: flex;
    min-width: 220rem;
    border-top: 1px solid var(--gray-150);
    border-bottom: 1px solid var(--gray-150);

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
    font-size: 0.875rem;
        background-color: var(--white);
    position: sticky;
    z-index: 20;
    bottom: -3px;
`;

export const TotalMainColumn = styled.div`
    color: var(--black);
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 13.5rem;
    width: 29.125rem;
    height: 3.3rem;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    // border-bottom: 1px solid var(--gray-150);
    border-bottom-left-radius: 10px;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    background-color: var(--white);
`;

export const TotalDefaultColumn = styled.div`
    color: var(--black);
    // border-bottom: 1px solid var(--gray-150);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;
