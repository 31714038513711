import * as S from './style';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Tooltip } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export function SelectAttribution({
    showSelectAttribution,
    setShowSelectAttribution,
    setColumnAttribution,
    columnAttribution,
}: {
    showSelectAttribution: boolean;
    setShowSelectAttribution: (value: any) => void;
    setColumnAttribution: (value: string) => void;
    columnAttribution: string;
}) {
    function handleSelect(value: string) {
        setColumnAttribution(value);
        setShowSelectAttribution(false);
    }

    return (
        <S.Container onClick={() => setShowSelectAttribution((prev: any) => !prev)}>
            <S.Select>
                <S.FlexSelectTwo>
                    <img style={{boxShadow: ''}} src="https://storage.googleapis.com/static-images-source/attribution-icon2.png" />
                    {' '}
                    {columnAttribution === 'last_click'
                        ? 'LastClick Pago'
                        : columnAttribution === 'first_click'
                        ? 'FirstClick Pago'
                        : ''}
                </S.FlexSelectTwo>
                <ExpandMoreRoundedIcon style={{ transform: showSelectAttribution ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>

                <S.OptionsContainer showSelectAttribution={showSelectAttribution} onClick={(e) => e.stopPropagation()}>
                    <S.Option onClick={() => handleSelect('last_click')}>
                        <div style={{display: 'flex', alignItems: 'center'}}><img src="https://storage.googleapis.com/static-images-source/attribution-icon2.png" /> LastClick Pago</div>
                        <Tooltip title="A conversão é atribuída ao último ponto de contato de mídia paga que levou o cliente ao site. Por exemplo, se o cliente acessou o site através de um anúncio do Facebook Ads e efetuou a compra após uma ação de recuperação de carrinho, a conversão será creditada ao Facebook Ads." placement="top" componentsProps={{
                            tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '10px', padding: '5px 10px', color: 'white', textWrap: 'wrap', zIndex: '9999' } },
                        }}>
                            <div>
                                <InfoRoundedIcon sx={{ width: '32px', marginLeft: '0rem', fill: '#c3cece', right: '0' }} />
                            </div>
                        </Tooltip>
                    </S.Option>
            
                    <S.Option onClick={() => handleSelect('first_click')}>
                    <div style={{display: 'flex', alignItems: 'center'}}><img src="https://storage.googleapis.com/static-images-source/attribution-icon2.png" /> FirstClick Pago</div>
                        <Tooltip title="A conversão é atribuída ao primeiro ponto de contato de mídia paga pelo qual o cliente acessou o site. Por exemplo, se um cliente inicialmente visitou o site por meio de um anúncio do Google Ads e, posteriormente, completou uma compra após várias interações, a conversão será creditada ao Google Ads." placement="top" componentsProps={{
                            tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '10px', padding: '5px 10px', color: 'white', textWrap: 'wrap', zIndex: '9999' } },
                        }}>
                            <div>
                                <InfoRoundedIcon sx={{ width: '32px', marginLeft: '0rem', fill: '#c3cece', right: '0' }} />
                            </div>
                        </Tooltip>
                    </S.Option>
                    
                </S.OptionsContainer>
            </S.Select>
        </S.Container>
    );
}