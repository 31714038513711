// import { BackButton } from './../Terms/style';
import styled, { keyframes } from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media(max-width: 1080px){
        transform: scale(0.7);
        margin-top: -6rem;
        margin-left: -4rem;
        width: 26rem;
    }
`;

export const LogoutIconStyled = styled(LogoutIcon)`
    width: 0.7em !important;
`;

export const LogoutButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-left: -0.4rem;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding: 0rem 0.5rem;
    color: var(--gray-700);
    font-size: 0.8rem;
    transition: all 250ms;
    svg {
        margin-right: 0.5rem;
    }
    // &:hover {
    //     border-radius: 50rem;
    //     transition: all 250ms;
    //     transform: translateX(0.3rem);
    //     background-color: var(--white);
    //     color: var(--red-500);
    // }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;


export const SideBar = styled.div`
background: linear-gradient(274deg, rgba(238,238,241,0.7) 0%, #fbfbfb 59%);
  background-size: 200% 200%;  // Torna o gradiente largo o suficiente para suportar o movimento
  animation: ${gradientAnimation} 3s ease infinite;  // Aplica a animaçãodisplay: flex;
flex-direction: column;
height: 100vh;
gap: 1rem;
overflow-y: auto;
width: 42rem;
padding: 2rem;
// box-shadow: 0px 0px 55px -31px rgba(0, 0, 0, 0.3);
h2 {
    @media(min-width: 1800px){
    width: 50rem;
    font-size: 1.8rem;
}
    font-size: 1.4rem;
    font-weight: 550;
    margin-top: -0.5rem;
}

@media(max-width: 1080px){
    width: 30rem;
}

@media(min-width: 1800px){
    width: 50rem;
    
}
`;


export const VLine = styled.div`
height: 10rem;
width: 1px;
background-color: #989c97;
margin-top: 5rem;
@media(max-width: 1280px){
    display: none;
}
`;

export const InnerInfoContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
@media(max-width: 1240px){
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

`

export const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.6rem;
    font-weight: 450;
    color: #989c97;
    margin-bottom: 5rem;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: flex-end;
    transition: 0.3s;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 56.4375rem;
    @media(max-width: 1600px){
        width: 70%;
    }
    @media(max-width: 1280px){
        width: 50%;
    }
`;

export const Button = styled.button`
    width: 17.875rem;
    height: 3.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 450;
    color: whitesmoke;
    background-color: var(--green);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #989c97;
        &:hover {
            box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
            transform: translateY(-7px);
        }
    }
`;

const floating = keyframes`
0%, 100% {
    transform: translateY(0px);
}
50% {
    transform: translateY(-10px);
}
`

export const ImageContainer = styled.div`
    position: relative;
    animation: ${floating} 4s ease-in-out infinite;;
    width: 16.625rem;
    img {
        width: 16.625rem;
        height: 37rem;
    }
`;

export const ContentSection = styled.div`
    width: 56.4375rem;
    @media(max-width: 1600px){
        width: 100%;
    }
`;

export const ContainerModal = styled.div`
    width: 37.5rem;
    min-height: 20rem;
    height: auto;

    background-color: #fff;
    border-radius: 20px;
`;

export const ContainerIntegrationLoadingOnboarding = styled.div`
    position: absolute;
    top: 5.5rem;
    left: 1.5rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media(max-width: 1240px){
        flex-direction: column;
        align-items: center;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media(max-width: 1240px){
         width: 100%;
         &:last-child{
            margin-top: 1rem;
         }
    }
   
`;

export const InfoContainerText = styled.div`
    font-size: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    color: var(--green);
    text-align: center;
    @media(max-width: 1240px){
        text-align: left;
        padding-left: 5rem;
    }
`;

export const BackButton = styled.div`

    font-size: 0.9rem;
    margin-right: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    flex-direction: column;
    justify-content: center;
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        text-decoration: underline;
    }
    @media(max-width: 1240px){
        padding-left: 5rem;
    }
`;

export const NextButton = styled.div`

    font-size: 0.9rem;
    margin-left: 1rem;
    font-weight: 550;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        text-decoration: underline;
    }
    @media(max-width: 1240px){
        padding-left: 5rem;
    }
`;




export const ButtonItem = styled.div<{type: string}>`
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: start;
    // position: absolute;
    // left: 0.6rem;
    // top: 0;
    margin-left: -0.4rem;
    margin-top: -0.5rem;
    padding: 0rem 0.5rem;
    color: var(--gray-700);
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 250ms;
    svg {
        margin-right: 0.5rem;
    }
    &:hover {
        border-radius: 50rem;
        transition: all 250ms;
        transform: translateX(0.3rem);
        color: ${props => props.type === 'logout' ? 'var(--red-500)' : 'var(--black)'};
    }

`

export const Flex = styled.div`
    display: flex;
    gap: 0.5rem;
`