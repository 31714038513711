import styled from 'styled-components';
import seta from './../../../../assets/images/seta.png';

export const Container = styled.div`
    padding: 1rem;
`;

export const InnerContainer = styled.div`
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: -0.5rem;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }
    select {
        height: 2.5rem;
        width: 13rem;
        padding: 0 1rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);

        font-weight: 600;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(${seta}) no-repeat center right #fff;
        background-size: 1rem;
        background-position: 11rem 0.75rem;

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            /* border: 1px solid var(--green); */
            /* outline: none; */
            /* border-color: rgba(76, 234, 134, 0.4); */
            /* background-color: #fff; */
            /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
            /* border: 1px solid var(--green); */
        }
    }
`;

export const CheckboxSection = styled.div`
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -1rem auto 1.5rem;
    cursor: pointer;
`;

export const ContentPaymentInformation = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`;

export const TypePaymentInformation = styled.div`
    border: 1px solid #ccc;
    width: 13.75rem;
    height: 15rem;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const ErrorContainer = styled.div`
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
    margin-top: 1rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0;
    span {
        font-weight: 550;
    }
`;

export const HeaderTYpePaymentInformation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5rem;
    width: 100%;
    color: #1e1e1e;
    margin-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-bottom: 1px solid #ccc;
    svg {
        margin-right: 0.5rem;
    }
`;
export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;


export const ContentTypePaymentInformation = styled.div`
    padding: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--black);
    // margin-left: 0.8rem;
    text-align: left;
    width: 100%;
}

input {
    height: 2.5rem;
    width: 30.7rem;
    padding: 0 1rem;
    border-radius: 8px;
    // margin-left: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid var(--gray-120);
    background-color: #fff;
    color: var(--black);

    &::placeholder {
        color: var(--gray-700);
        font-weight: 500;
    }

    &:focus,
    &:hover {
        border: 1px solid var(--green);
        /* outline: none; */
        /* border-color: rgba(76, 234, 134, 0.4); */
        /* background-color: #fff; */
        /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
        /* border: 1px solid var(--green); */
    }
}
`;
