import { ButtonApp } from '../ButtonApp'
import * as S from './style'

export function TryAgainGoogle() {
    return(
        <S.Container>
            <S.InnerContainer>
                <S.IconContainer>
                    <Warning />
                </S.IconContainer>
                <S.Title>
                    Ocorreu um erro na integração
                </S.Title>
                <S.Subtitle>
                    Por favor, tente integrar novamente e certifique-se de marcar o checkbox de escopo.
                </S.Subtitle>
                <S.Image>
                    <img src='https://storage.googleapis.com/static-images-source/Group%201102.png'/>
                </S.Image>
                <S.Button>
                <ButtonApp  border=''
                            width="9.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<></>}
                            onClick={() => {}}
                            text="Tentar Novamente"

                        />
                        </S.Button>
            </S.InnerContainer>
        </S.Container>
    )
}

function Warning() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/>
        </svg>
    )
}