import React, { useEffect, useState } from 'react';
import * as S from './style';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateAdooreiGet, storageOnboardingIntegrateCartpandaGet, storageOnboardingIntegrateDomPagamentosGet, storageOnboardingIntegrateUnicoPagGet, storageOnboardingIntegrateShopifyCheckoutGet, storageOnboardingIntegrateYampiGet, storageOnboardingIntegrateYeverGet } from '../../storage/storageOnboarding';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface CustomDropdownProps {
    options: { id: string; label: string; image: string; imgWidth: string; imgHeight: string; textColor: string }[];
    defaultOptionId: string;
    onOptionSelected: (optionId: string) => void;
    popupSetters: { [key: string]: (show: boolean) => void };
}

const CustomDropdown = ({ options, defaultOptionId, onOptionSelected, popupSetters }: CustomDropdownProps) => {
    const [showSelectDropdown, setShowSelectDropdown] = useState(false);
    const [selectedOptionId, setSelectedOptionId] = useState(defaultOptionId);
    const [gatewayPlatformSelected, setGatewayPlatformSelected] = useState('');
    

    const gatewayStoredUpdate = localStorage.getItem(`info_gateway_${storageOnboardingCurrentCompanyGet()}`);
    const gatewayStoredParsedUpdate = gatewayStoredUpdate ? JSON.parse(gatewayStoredUpdate) : {};
    const gatewayInfoPlatformUpdate = gatewayStoredParsedUpdate?.platform;
    
    useEffect(() => {
        const gatewayStoredInfo = localStorage.getItem(`info_gateway_${storageOnboardingCurrentCompanyGet()}`);
        console.log('gateway local', gatewayStoredInfo);
        if (gatewayStoredInfo) {
            setGatewayPlatformSelected(JSON.parse(gatewayStoredInfo).platform);
        }
    }, [gatewayInfoPlatformUpdate]);

    const isIntegrateValues = {
        cartpanda: storageOnboardingIntegrateCartpandaGet(),
        yampi: storageOnboardingIntegrateYampiGet(),
        yever: storageOnboardingIntegrateYeverGet(),
        dom_pagamentos: storageOnboardingIntegrateDomPagamentosGet(),
        adoorei: storageOnboardingIntegrateAdooreiGet(),
        unicopag: storageOnboardingIntegrateUnicoPagGet(),
        shopify_checkout: storageOnboardingIntegrateShopifyCheckoutGet(),
    };

    // const includes = gatewayInfoPlatform.includes(gatewayPlatforms);
    console.log('includes', gatewayPlatformSelected === selectedOptionId, selectedOptionId, gatewayPlatformSelected)

    const currentIsIntegrate = isIntegrateValues[selectedOptionId];
    const currentIsIntegrateGateway = gatewayPlatformSelected === selectedOptionId;

    const handleOpenPopup = () => {
        setShowSelectDropdown(!showSelectDropdown);
    };

    const handleSelect = (optionId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setSelectedOptionId(optionId);
        onOptionSelected(optionId);
        const setShowPopup = popupSetters[optionId];
        if (setShowPopup) {
            setShowPopup(true);
        }
        setShowSelectDropdown(false);
    };
    

    const selectedOption = options.find(option => option.id === selectedOptionId);
    // const selectedLabel = options.map(option => option.label);

    return (
      <S.Select isIntegrateGatewayProps={currentIsIntegrateGateway} isIntegrateProps={currentIsIntegrate} onClick={handleOpenPopup}>
        {selectedOption && (
          <>
            <S.OptionImage src={selectedOption.image} style={{ width: selectedOption.imgWidth, height: selectedOption.imgHeight }} />
            <span style={{ color: selectedOption.textColor }}>{selectedOption.label}</span>
            <ExpandMoreRoundedIcon/>
          </>
        )}
        <S.OptionsContainer showSelectDropdown={showSelectDropdown}>
                {options.map(option => {
                const isOptionIntegrate = isIntegrateValues[option.id];
                const isOptionIntegrateGateway = gatewayPlatformSelected === option.id;

                return (
                    <S.Option key={option.id} onClick={(event) => handleSelect(option.id, event)}>
                        <S.OptionImage src={option.image} style={{ width: option.imgWidth, height: option.imgHeight }} />
                        {option.label}
                        
                        <S.IntegrationCircle isIntegrateGatewayProps={isOptionIntegrateGateway} isIntegrateProps={isOptionIntegrate}></S.IntegrationCircle>
                    </S.Option>
                );
            })}
        </S.OptionsContainer>
      </S.Select>
    );
};

export default CustomDropdown;
