import * as S from './style';
import { toBrazilianFormat } from '../../../utils';
import { calculateFinalOrders, calculateFinalRevenue, getAdsData, getTotalLineByAds, platformIcons } from '../utils';
import { LineTableTrackingAds } from './LineTableTrackingAds';
import { useState, useMemo } from 'react';
import { getSalesDTO } from '../../../@types';
import { useCheckboxTracking } from '../../../hooks/useCheckboxTracking';
import React, {useEffect} from 'react';
import { useColumnsTracking } from '../../../hooks/useColumnsTracking';
import { useResizableColumn } from '../../../hooks/useResizableColumn';
import { useIntercom } from 'react-use-intercom';
import { useCurrencyType } from '../../../hooks/useCurrencyType';
import ColumnItem from '../ColumnItem';
import TotalRowComponent from '../TotalRowComponent';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import { useSorting } from '../../../contexts/SortingContext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
export function TableTrackingAds({
    adsSetId,
    filteredBySelect,
    paymentMethod,
    type,
    columnSolomon,
    handleSelectGraphicData,
    handleScrolltoGraphic,
    dataMetrics,
    filtred,
    openSalesModal,
    date,
    dataCatalogList,
    loadingCatalog
}: {
    handleSelectGraphicData: any;
    filteredBySelect: any;
    paymentMethod: any;
    handleScrolltoGraphic: () => void;
    columnSolomon: string;
    type: string;
    adsSetId?: string;
    dataMetrics: any;
    filtred: string;
    openSalesModal: (value: getSalesDTO, metrics: any, source: string, catalogCampaign: boolean, ordersSolomon?: any, revenueSolomon?: any) => void;
    date: string[]
    dataCatalogList: any;
    loadingCatalog?: any;
}) {

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);
    const { typeFiltred, setTypeFiltred, isStatusAsc, setIsStatusAsc } = useSorting();

    useEffect(() => {
        if (paymentMethod) {    
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect
    const { currencySymbol } = useCurrencyType();
    const { showArticle } = useIntercom();
    const { columnWidths, startResizing } = useResizableColumn();
    const { selectedAdSetIds } = useCheckboxTracking();
    const { columns } = useColumnsTracking();

    // Preparar um array dos objetos de ads sets ativos para passar para a função
    const activeAdsSets = selectedAdSetIds.map(({ id, type }) => ({ id, type }));

    const memoizedGetAdsData = useMemo(() => {
        return getAdsData;
    }, []);

    const filtredAdsData = useMemo(() => memoizedGetAdsData(dataMetrics, type, adsSetId, activeAdsSets, dataCatalogList), [dataMetrics, type, adsSetId, activeAdsSets, dataCatalogList]);

    const [filterDec, setFilterDec] = useState(true);   

    // //console.log('filtered by', filteredBySelect)

    const newFiltredAdsData = useMemo(() => {
        const filteredData = filtredAdsData
            ?.filter((ad: any) => 
                ad?.adName?.toLowerCase().includes(filtred.toLowerCase()) &&
                (filteredBySelect.length > 0 ? filteredBySelect.includes(ad?.adInfo) : true)
            );
    
        // Ordenar pelo status
        if (typeFiltred === 'status') {
            filteredData.sort((a: any, b: any) => {
                if (isStatusAsc) {
                    return a?.adStatus === 'ACTIVE' ? -1 : 1;
                } else {
                    return a?.adStatus === 'ACTIVE' ? 1 : -1;
                }
            });
        } else {
            filteredData.sort((a: any, b: any) => {
                if (filterDec) {
                    return b?.[typeFiltred] - a?.[typeFiltred];
                } else {
                    return a?.[typeFiltred] - b?.[typeFiltred];
                }
            });
        }
    
        return filteredData;
    }, [filtredAdsData, filtred, filteredBySelect, typeFiltred, filterDec, isStatusAsc]);


    // const newFiltredAdsData = filtredAdsData
    // ?.filter((ad: any) => 
    //     ad?.adName?.toLowerCase().includes(filtred.toLowerCase()) &&
    //     (filteredBySelect.length > 0 ? filteredBySelect.includes(ad?.adInfo) : true)
    // )
    // ?.sort((a: any, b: any) => {
    //     if (filterDec) {
    //         return b?.[typeFiltred] - a?.[typeFiltred];
    //     } else {
    //         return a?.[typeFiltred] - b?.[typeFiltred];
    //     }
    // });


    const totalLine = getTotalLineByAds(newFiltredAdsData, type);  

    const revenueSolomon = calculateFinalRevenue(columnSolomon, totalLine, activePaymentMethods);
    const ordersSolomon = calculateFinalOrders(columnSolomon, totalLine, activePaymentMethods);

    const uniquePlatforms = new Set();

    // Supondo que 'campaignsData' seja sua lista de campanhas
    newFiltredAdsData.forEach(ads => {
        uniquePlatforms.add(ads?.sourceType); // Adiciona o tipo de plataforma ao Set
    }); 
    const uniquePlatformsArray = Array.from(uniquePlatforms); // Converte Set para Array
    
    return (
        <S.Container>
            <S.TableHeader>
                <S.MainColumn style={{ width: `${columnWidths}px` }}>
                <S.CheckboxColumn></S.CheckboxColumn>
                <S.StatusColumn onClick={() => {
                    setTypeFiltred('status');
                    setIsStatusAsc(!isStatusAsc);
                }}>
                    {typeFiltred === 'status' && (
                        isStatusAsc ? (
                            <ArrowUpwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        ) : (
                            <ArrowDownwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        )
                    )}
                    <>
                    <Tooltip title="Recomendamos que priorize atualizar o status pela Solomon para uma otimização mais precisa. Caso a campanha seja ativada/desativada pelo gerenciador do Facebook, precisaremos de alguns minutos para atualizá-la na Solomon." placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '10px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } } }}>
                    <span>Status</span>
                    </Tooltip>
                    </>
                </S.StatusColumn>
                    <p>Nome do anúncio</p>
                    <S.DragDiv
                        style={{
                            cursor: 'col-resize',
                            backgroundColor: '#ccc',
                            width: '7px',
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}
                        onMouseDown={startResizing}
                    />
                </S.MainColumn>

                {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
                    <React.Fragment key={column.id}>
                        <ColumnItem column={column} typeFiltred={typeFiltred} filterDec={filterDec} showArticle={showArticle} columnSolomon={columnSolomon} setFilterDec={setFilterDec} setTypeFiltred={setTypeFiltred} uniquePlatformsArray={uniquePlatformsArray}/>
                    </React.Fragment>
                ))}
            </S.TableHeader>

            <S.Lines>
                {
                    newFiltredAdsData.length > 0 &&
                    newFiltredAdsData?.map((ad: any) => {
                        return <LineTableTrackingAds dataCatalogList={dataCatalogList} paymentMethod={paymentMethod} handleSelectGraphicData={handleSelectGraphicData} handleScrolltoGraphic={handleScrolltoGraphic} columnSolomon={columnSolomon} type={type === 'allSources' ? [type, ad.sourceType, ad.adsSourceIcon] : type} ads={ad} key={ad?.adInfo} dataMetrics={dataMetrics} openSalesModal={openSalesModal} date={date} columnWidths={columnWidths} loadingCatalog={loadingCatalog} />;
                    })}
            </S.Lines>
            <TotalRowComponent columnWidths={columnWidths} name={'Conjuntos de anúncios'} quantity={newFiltredAdsData?.length || '0'} columns={columns} currencySymbol={currencySymbol}  validator={1} totalLine={totalLine} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon}/>

        </S.Container>
    );
}


