import styled, { keyframes } from "styled-components";
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';


const rotate = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(360deg) scaleX(-1);
  }
`;

// Criando um componente icon estilizado que aplica a animação
export const RotatingSettingsIcon = styled(SettingsRoundedIcon)`
  animation: ${rotate} 2s linear infinite;
`;
  

const rotateAntiClockwise = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(360deg) scaleX(-1);
  }
`;

const shake = keyframes`
    0%, 20% { /* 0.8s de 4s = 20% */
    transform: translateX(0);
    }

    2.5%, 7.5%, 12.5%, 17.5% { /* Intervalos durante os 0.8s de atividade */
    transform: translateX(-1.5px);
    }

    5%, 10%, 15% { /* Intervalos também durante a atividade */
    transform: translateX(1.5px);
    }
    21%, 100% {
    transform: translateX(0);
    }
`;

const shine = keyframes`
    to {
        background-position: 200% center;
    }
`;

export const Line = styled.div`
    margin-top: 26px; 
    margin-bottom: 35px;
    height: 1px;
    width: 94%;
    margin-left: -1rem;
    background-color: var(--gray-100);
`;

export const ContainerBackground = styled.div`
    background-color: white;
    height: 115vh;
    padding: 1rem 2rem 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    // padding-left: 36px;
    margin-top: 24px;
    align-items: center;
    margin-bottom: 56px;
    // align-items: flex-end;
    flex-direction: column;
    gap: 42px;
`;

export const TabContent = styled.div`
    display: flex;
    height: 25px;
    border-left: 1px solid #ccc;
    gap: 8px;
    align-items: center;
    padding-left: 42px;
`;

export const TabContentF = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    height: 25px;
    gap: 50px;
    margin-top: 16px;
    border-bottom: 1px solid #ccc;
    align-items: center;
`;

type TabButtonProps = {
    showHover: boolean;
    platformDisconnected?: boolean;
};

export const TabButton = styled.button<TabButtonProps>`
    background-color: transparent;
    color: #9ca3ab;
    padding: 0px 7px;
    text-wrap: nowrap;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 550;
    transition: all 250ms ease-in-out;
    transform 250ms ease-in-out;


    ${(props) =>
        props.showHover
            ? `
                color: #19c27e !important;
               
            `
            : `
            `}

`;

export const TabArea = styled.div<TabButtonProps>`
    padding: 3px 8px 12px 8px;
    display: flex;
    gap: 8px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    margin-bottom: 9px;

        ${(props) => props.platformDisconnected ? `filter: blur(2.5px);` : ``}


       ${(props) =>
        props.showHover
            ? `
                border-bottom: 3px solid #19c27e;
                transition: all 250ms ease-in-out;
                transform: translateY(-3px);
            `
            : `
            `}

              &:hover {
        color: #19c27e;
        border-color: #19c27e;
        transition: all 250ms ease-in-out;
        transform: translateY(-3px);
    }
;`
export const TabButtonTiktok = styled.button<TabButtonProps>`
    background-color: transparent;
    color: #9ca3ab;
    padding: 0px 7px;
    cursor: pointer;
    font-size: 21px;
    font-weight: 500;
    border-bottom: 1px solid #19c27e;
    border-bottom: 0;
    transition: all 250ms ease-in-out;
    transform 250ms ease-in-out;

    ${(props) =>
        props.showHover
            ? `
                color: #19c27e;
                border-bottom: 1px solid #19c27e;
                transform: translateY(-3px);
            `
            : `
            `}

    &:hover {
        color: #19c27e;
        border-bottom: 1px solid #19c27e;
        cursor: not-allowed;
        transform: translateY(-3px);
    }

    span {
        font-size: 9px;
    }
`;

export const FlexStatusContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ConectWarning = styled.div`

    svg {
    width: 16px;
    height: 16px;
    }

    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgb(180, 237, 214);
    border-radius: 50px;
    cursor: default;
    transition: all ease-in-out 250ms;
    font-size: 10px;
    color: rgb(8, 124, 77);
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    &:hover {
        // filter: brightness(0.9) contrast(1);
        transition: all ease-in-out 250ms,;
    }
`;

export const ConectWarningRef = styled.div`
    svg {
        width: 16px;
        height: 16px;
        fill: var(--gray-800);
    }
    display: flex;
    padding: 4px;
    align-items: center;
    // width: 100px;
    justify-content: center;
    gap: 4px;
    background: var(--gray-100);
    border-radius: 50px;
    cursor: default;
    font-size: 10px;
    color: black;
    font-weight: 500;
    margin: 0;

`;

export const UTMstWarning = styled.div`
   
    svg {
    width: 16px;
    height: 16px;
    }

    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgb(237, 180, 180);
    border-radius: 50px;
    cursor: default;
    transition: all ease-in-out 250ms;
    font-size: 10px;
    color: rgb(124, 8, 8);
    font-weight: 500;
    margin: 0;
    animation: ${shake} 4s infinite; /* Apply the shake animation */
    animation-timing-function: ease-in-out;

    // &:hover {
    //     padding: 1px 0px 1px 12px;
    //     filter: brightness(0.9) contrast(1);
    //     transition: all ease-in-out 250ms;
    // }
`;

export const NotConectWarning = styled.div`
    display: flex;
    padding: 1px 6px;
    align-items: center;
    width: 100px;
    justify-content: center;
    gap: 4px;
    background: rgb(237, 180, 180);
    border-radius: 50px;
    cursor: default;
    transition: all ease-in-out 250ms;
    font-size: 10px;
    color: rgb(124, 8, 8);
    font-weight: 500;
    margin: 0;

    // &:hover {
    //     padding: 1px 0px 1px 12px;
    //     filter: brightness(0.9) contrast(1);
    //     transition: all ease-in-out 250ms,;
    // }
`;

export const HeaderContainer = styled.div`
    display: flex;
    padding: 0;
    gap: 2.8rem;
    width: 100%;
    margin-bottom: 32px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
`;

export const FlexInfo = styled.div`
 display: flex;
    background: white;
    gap: 43px;
    box-shadow: 1px 1px 2px -1px #0000001c;
    border-radius: 8px;
    /* margin-right: 2%; */
    padding: 24px;
    width: 100%;
    /* max-width: 1300px; */
    justify-content: start;
`;

export const UtmInfo = styled.div`
    // /* padding: 16px; */
    // /* background: #f8f8f8; */
    // flex-wrap: wrap;
    // /* border: 1px solid #e1e1e1; */
    // /* width: 47%; */
    // gap: 12px;
    // border-radius: 8px;
    // max-width: 705px;

    // p {
    //     font-size: 14px; #E90E0E
    //     margin: 28px 0 10px 0;
    //     color: #727272;
    // }
    // span {
    //     font-weight: 550;
    //     color: #19c27e;
    //     cursor: pointer;
    //     text-decoration: underline;
    // }
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
type UtmProps = {
    clickable: boolean;
};

export const UtmLoaded = styled.div<UtmProps>`
    display: flex;
    align-items: center;
    // margin-bottom: 24px;
    border: 1px solid #E90E0E;
    /* margin: 0 auto; */
    border-radius: 50px;
    padding: 4px 16px;
    width: fit-content;
    color: #E90E0E;


    h2 {
        margin-left: 6px;
        font-size: 15px;
        font-weight: 500;
            text-wrap: nowrap;
    }

    svg {
        font-size: 18px;
        fill: #E90E0E;
    }

    ${(props) => props.clickable && `
        cursor: pointer;    
    `}

`;
export const UtmLoadedSuccess = styled.div<UtmProps>`
    display: flex;
    align-items: center;
    // margin-bottom: 24px;
    border: 1px solid var(--green);
    /* margin: 0 auto; */
    // margin-bottom: 24px;
    border-radius: 50px;
    padding: 4px 16px;
    width: fit-content;
    color: var(--green);

    h2 {
        margin-left: 6px;
        font-size: 15px;
            text-wrap: nowrap;
        font-weight: 500;
    }

    svg {
        font-size: 18px;
        fill: var(--green);
    }
    ${(props) => props.clickable && `
        cursor: pointer;    
    `}
`;

export const UtmLoadedRef = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-800);
    border-radius: 50px;
    padding: 4px 16px;
    width: fit-content;
    color: var(--gray-800);

    h2 {
        margin-left: 6px;
        font-size: 15px;
        font-weight: 500;
        text-wrap: nowrap;
    }

    svg {
        font-size: 18px;
        fill: var(--gray-800);
    }
`;

export const RotatingIcon = styled(SyncRoundedIcon)`
  animation: ${rotateAntiClockwise} 1s linear infinite;
`;

export const CopyBox = styled.div`
    display: flex;
    gap: 0.6rem;
    align-items: center;
    margin-top: 10px;
`;

export const CopyText = styled.div`
    background: #f5f5f5;
    width: 42.1rem;
    outline: none;
    border: none;
    white-space: nowrap; 
    overflow-x: hidden; 
    overflow-y: hidden; 
    border-radius: 8px;
    padding: 14px;
    font-size: 0.7rem;
    font-family: Montserrat;
    font-weight: 500;
    color: #1e1e1e;

`;

export const CopyButton = styled.div`
    color: var(--gray-800) !important;
    border: none;
    border-radius: 8px;
    background: var(--gray-200) !important;
    cursor: pointer;
    background: whitesmoke;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 10px;
    height: 20px;
    font-family: Montserrat;
    transition: all ease-in-out 0.2s;

    p {
        font-size: 0.8rem !important;
        font-weight: 500;
        // color: var(--gray-800) !important;
    }

    svg {
        font-size: 14px;
        margin-top: 3px;
    }

    &:hover {
        background-color: var(--black) !important;
        transform: translateX(-3px);
        color: white !important;
        transition: all ease-in-out 0.2s;
    }
`;

type CopyButtonProps = {
    show: boolean;
};

export const CopiedPopup = styled.div<CopyButtonProps>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 20px; /* Rounded border */
    font-size: 14px;
    z-index: 9999;
    opacity: ${props => props.show ? 1 : 0}; /* Start with opacity 0 */
    transition: opacity 0.5s, transform 0.5s ease-out; /* Transition for opacity and transform */
    transform: translateY(${props => props.show ? 0 : 20}px); /* Start from bottom, then move up */
`;

export const UtmTutorial = styled.div`
    margin-top: 9px;
    width: 307px;

    p {
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #4b96ed;
        font-size: 13px;
        /* text-decoration: underline; */
    }

    &:hover {
        color: #3572b7;
        text-decoration: underline;
    }

    svg {
        margin: 0;
        color: #4b96ed;
        font-size: 13px;
        /* text-decoration: underline; */
    }
`;

export const LinearWipe = styled.div`
    text-align: center;
    font-weight: 550;
    background: linear-gradient(to right, #0e9fb5 0%, #1f8463 100%);
    background-size: 200% auto;

    color: #000;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: ${shine} 3s linear infinite;
    
    &:hover {
        color: #3572b7;
        text-decoration: underline;
    }
`;

export const OtherUtmInfo = styled.div`
    display: flex;
    align-items: center;
    border: none;
    max-width: 641px;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;

    p {
        color: #6c757d;
        text-align: center;
        font-weight: 500;
        width: 200px;
        font-size: 12px;
    }
`;

type TutorialButtonProps = {
    disabled: boolean;
};

export const TutorialButton = styled.div<TutorialButtonProps>`
    background-color: #0c1612; /* Cor primária da marca */
    color: white;
    border: none;
    padding: 9px 0px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    margin-top: 0;
    display: flex;
    transition: all 250ms ease-in-out;
    margin-top: 0;
    display: flex;
    gap: 6px;
    width: 242px;
    align-items: center;
    justify-content: center;

    ${(props) => !props.disabled && `
        &:hover {
            transform: translateY(3px);
            transition: all 250ms ease-in-out;
        }
    `}

    ${(props) =>
        props.disabled &&
        `
            cursor: not-allowed;
            background-color: #ccc;
            color: #a1a1a1;
        `}

    svg {
        font-size: 13px;
    }
`;

export const Table = styled.div`
    padding-bottom: 50px;
    max-width: 100rem;
    width: 100%;
`;

type ContainerConfigurePopupProps = {
    showConfigurePopup: boolean
}

export const ContainerPopup = styled.div<ContainerConfigurePopupProps>`
    display: none;
    bottom: 100%;
    

    ${(props) =>
        props.showConfigurePopup
            ? `
            display: flex;
            margin: 1rem auto 0;
            opacity: 1;
            height: 4.75rem;
            visibility: visible;
            transition: opacity 0.3s, visibility 0.5s;
    
    `
            : `
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.5s;

    `}
    
    width: 17.5rem;
    bottom: 120%;
    right: -7%;
    margin-left: 214px;
    position: absolute;
    padding: 0;
    background-color: #0e1819;
    color: #0e1819;
    text-align: center;
    border-radius: 11px;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;

    @media screen and (max-width: 1372px){
        top: -108px !important;
        margin-left: 0px !important;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    }
`;

export const PopupTitle = styled.div`
    font-size: 0.7125rem;
    color: var(--white);
    margin: 0 auto;
    font-weight: 550;
    margin-top: 0.3rem;
`;

export const PopupSubtitle = styled.div`
    font-size: 0.6rem;
    font-weight: 550;
    color: #989c97;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;

export const ButtonPopup = styled.button`
    padding: 0;
    font-size: 0.7rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin-top: 0.75rem;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 0 auto;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }
`;


export const FlexSettings = styled.div`
    display: flex;
    justify-content: center;


    p {
        margin: 0;
        width: 82%;
        font-size: 14px;
        display: flex;
        margin-top: 15px;
        margin-bottom: 8px;
    }

     i {
        font-size: 16px;
        color: #989c97;
        margin-right: -26px;
        margin-left: 17px;
    }

    i:hover {
        font-size: 16px;
        color: #0c1612;
        cursor: pointer;
    }
`;

export const SuccessPopup = styled.div`
    top: 100%;
    display: flex;
    margin: 1rem auto 0;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;
    z-index: 1;
    text-align: center;
    margin-bottom: 0.3rem;
    margin-top: -7rem;
    position: absolute;
    margin-left: -5rem;
    display: flex;
    height: auto;
    /* width: 300px !important; */
    background-color: white;
    border: 1px solid #e3e3e3;
    gap: 6px;
    flex-direction: column;
    padding: 10px;
    color: #656565 !important;
    font-weight: 500 !important;
    border-radius: 7px;
    font-size: 1vw;
    animation: slideIn 0.5s forwards;

    @media screen and (max-width:  1372px){
        // top: 364px;
        // margin-left: 0px; 
    }


    .out {
        animation: slideOut 0.5s forwards;
    }

`

export const InnerSuccessPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AccountsList = styled.div`
    display: flex;
    flex-ditection: row;
    gap: 5px;
    align-self: center;
    align-items: center;
`

export const Vline = styled.div`
    height: 15px;
    width: 1px;
    background-color: #ccc;
    margin-left: 13px;
    margin-right: -16px;
`

export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    line-height: 1.95rem;
    font-weight: 500;
    height: 2rem;
    // margin-bottom: 1rem;
    }

    p {
       font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`

export const ContentArea = styled.div`
    display: flex;
    gap: 8px;
    width: 50rem;
    align-items: center;

    p {
            font-size: 0.75rem;
            // color: #6f878a; 
            width: fit-content;
            text-wrap: nowrap;
        
        }
`

export const ContentTitle = styled.div`
        font-size: 1rem;
        font-weight: 550;
        color: var(--black);
        display: flex;
        flex-direction: column;
        width: fit-content; 
        // text-wrap: nowrap;  
        margin-right: 1rem;

        span {
            font-size: 0.8rem;
            width: 17rem;
            font-weight: 500;
            color: var(--gray-700);
    }
`

export const TutorialArea = styled.div`
    display: flex;
    gap: 1rem;
    // margin-left: 1.8rem;
`

export const UpdatedAt = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: var(--gray-800);
    
    svg {
        fill: var(--gray-700);
        margin-left: -0.5rem;
        width: 16px;
        height: 16px;
        
        &:hover {
            fill: var(--blue);
            }
        }
    `;

    export const CheckboxArea = styled.div`
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    padding: 5px 1rem;
    border-radius: 80px;
    `