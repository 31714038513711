import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderAccount = styled.div`
    svg {
        cursor: pointer;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    display: flex;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    height: 4rem;
    border: 1px solid #e8e7e7;
    border-radius: 7px;
    align-items: center;
`;

export const TitleAccount = styled.div`
    width: 100%;
    font-size: 0.9375rem;
    color: var(--black);
    font-weight: 600;
    line-height: 1.125rem;
`;

export const Lines = styled.div``;
