import * as S from './style';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PixOutlinedIcon from '@mui/icons-material/PixOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { GraphProps } from '../../../../@types';
import { getMetricsOrdersByPayments } from '../../utils';
import { InfoTooltip } from '../InfoTooltip';
import Tooltip from '@mui/material/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export function Payments({ isLoading, data }: GraphProps) {
    const { cardPaymentApprovedOrders, pixPaymentApprovedOrders, billetPaymentApprovedOrders, totalApprovedOrders } = getMetricsOrdersByPayments(data);

    const cardPaymentPercentage = totalApprovedOrders == 0 ? '0' : ((cardPaymentApprovedOrders / totalApprovedOrders) * 100).toFixed(0);

    const pixPaymentPercentage = totalApprovedOrders == 0 ? '0' : ((pixPaymentApprovedOrders / totalApprovedOrders) * 100).toFixed(0);

    const billetPaymentPercentage = totalApprovedOrders == 0 ? '0' : ((billetPaymentApprovedOrders / totalApprovedOrders) * 100).toFixed(0);

    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />  
            </S.ContainerLoading>
        );
    }

    return (
        <S.Container>
            <S.Title>
                Pagamentos
                 <Tooltip title="Pagamentos de pedidos aprovados" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <InfoRoundedIcon sx={{width: '15px', marginLeft: '0px', fill: '#7b9898'}}/>
                </Tooltip>
            </S.Title>

            <S.InnerContainer>
                <div>
                    <S.TypePaymentsContainer>
                        <S.TypePayments>
                            <CreditCardOutlinedIcon sx={{ width: '12px', height: '12px', color: '#999' }} />
                            Cartão
                        </S.TypePayments>
                        <S.PaymentsValue color="#c24ce0">
                            <span>{cardPaymentPercentage}</span>%
                        </S.PaymentsValue>
                    </S.TypePaymentsContainer>
                    <S.Line color="" />
                    <S.TypePaymentsContainer>
                        <S.TypePayments>
                            <PixOutlinedIcon sx={{ width: '12px', height: '12px', color: '#999' }} />
                            Pix
                        </S.TypePayments>
                        <S.PaymentsValue color="#19c27e">
                            <span>{pixPaymentPercentage}</span>%
                        </S.PaymentsValue>
                    </S.TypePaymentsContainer>
                    <S.Line color="" />
                    <S.TypePaymentsContainer>
                        <S.TypePayments>
                            <ReceiptOutlinedIcon sx={{ width: '12px', height: '12px', color: '#999' }} />
                            Boleto
                        </S.TypePayments>
                        <S.PaymentsValue color="#195dc2">
                            <span>{billetPaymentPercentage}</span>%
                        </S.PaymentsValue>
                    </S.TypePaymentsContainer>
                </div>

                <S.BarContainer>
                    <S.Bar percentage={cardPaymentPercentage} totalApprovedOrders={totalApprovedOrders} color="#c24ce0" />
                    <S.Bar percentage={pixPaymentPercentage} totalApprovedOrders={totalApprovedOrders} color="#19c27e" />
                    <S.Bar percentage={billetPaymentPercentage} totalApprovedOrders={totalApprovedOrders} color="#195dc2" />
                </S.BarContainer>
            </S.InnerContainer>
        </S.Container>
    );
}
