import { TemplateModal } from '../../index';
import * as S from './styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../../components/ButtonApp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useState } from 'react';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { ModalDomProps, userDTO } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import { useCreateIntegrationDom, useDeleteIntegrationDom } from '../../../../../services/hooks/integrationHooks';
import { useIntercom } from 'react-use-intercom';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';
import useAlert
 from '../../../../../hooks/useAlert';
export function ModalDom({ setShowPopupDom }: ModalDomProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/dom%20icon.png';
    const baseColor = '#64B32F';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();
    const [showPopupDomGateway, setShowPopupDomGateway] = useState(false);

    const { showArticle } = useIntercom();

    
    const { showAlert } = useAlert();
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);

    const domInformation = user?.companies?.[currentCompany]?.integrations?.dom?.accounts[user?.companies?.[currentCompany]?.integrations?.dom?.accounts?.length - 1];

    const hasDomInfo = domInformation && domInformation?.account_id !== 'default-account';

    const { mutate: handleCreateIntegrationDom, isLoading: isLoadingCreateIntegration, isError } = useCreateIntegrationDom();
    const { mutate: handleDeleteIntegrationDom, isLoading: isLoadingDeleteIntegration } = useDeleteIntegrationDom();

    const [domInfo, setDomInfo] = useState({
        accessTokenDom: domInformation?.account_id  !== 'default-account' ? domInformation?.account_id : '',
    });

    
    function handleChangeDomInfo(e: any) {
        setDomInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    function handleIntegrate() {
        setShowPopupDomGateway(true);
        const body = {
            token: domInfo.accessTokenDom,
            company_id: currentCompany,
            user: user
        };

        handleCreateIntegrationDom(body, {
            onSuccess: () => {
                setShowPopupDom(false);
                const active = {
                    account_id: domInfo.accessTokenDom,
                    assets:{token: domInfo.accessTokenDom},
                    last_update: '',
                    status: 'active',
                };
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            integrations: {
                                ...user?.companies?.[currentCompany]?.integrations,
                                dom: {
                                    ...user?.companies?.[currentCompany]?.integrations?.dom,
                                    accounts: [active],
                                },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    function handleClosePopup() {
        if(showPopupDomGateway){
            setShowPopupDomGateway(false);}
    }


    function handleDesintegrate() {
        const body = {
            account_id: domInfo.accessTokenDom,
            company_id: currentCompany,
            truncate: true,
            user: user
        };

        setShowPopupDom(false);
        setShowConfirmDisconnectPopup(false);
        showAlert('Desconectando Dom Pagamentos... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);       
        const inactive = {
            account_id: 'default-account',
            assets: {},
            last_update: '',
            status: 'inactive',
        };
        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    integrations: {
                        ...user?.companies?.[currentCompany]?.integrations,
                        dom: {
                            ...user?.companies?.[currentCompany]?.integrations?.dom,
                            accounts: [inactive],
                        },
                    },
                },
            },
        };
        setUserContext(newUser as userDTO);
        updateUser();
        handleDeleteIntegrationDom(body, {
            onSuccess: () => {
            },
        });
    }

    return (
        <S.Container onClick={handleClosePopup}>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Dom'}
                title={'Dom'}
                subtitle={'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.'}
                statusConnection={domInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration
                    showPassword={false}
                    value={domInfo.accessTokenDom}
                    setValue={handleChangeDomInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenDom"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se seu <span>token de acesso</span> está correto e tente novamente.</S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => {showArticle(9326730)}}>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
                {hasDomInfo && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp 
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowConfirmDisconnectPopup(true)}
                                text="Desconectar"
                            />
                        </>
                    )}
                    {!hasDomInfo && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowPopupDomGateway(true)}
                                text="Integrar"
                                isLoading={isLoadingCreateIntegration}
                                disabled={domInfo?.accessTokenDom?.length === 0}
                            />
                        </>
                    )}
                    <S.ContainerYeverGateway showPopupYeverGateway={showPopupDomGateway}>
                    <S.YeverGatewayTitle>Importante</S.YeverGatewayTitle>
                    <S.YeverGatewaySubtitle>Você não deve adicionar as taxas manuais na integração do gateway.</S.YeverGatewaySubtitle>
                    <S.ButtonYeverGateway onClick={handleIntegrate}>
                        Prosseguir <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                    </S.ButtonYeverGateway>
                </S.ContainerYeverGateway>
            </S.DisconnectContainer>

            {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDesintegrate}
                title="Confirmação necessária"
                isLoading={isLoadingDeleteIntegration}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )}
            
        </S.Container>
    );
}
