import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    color: var(--black);
    height: 4.375rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    > span {
        display: flex;
        /* width: 4rem; */
        align-items: center;
        justify-content: center;
        /* margin-left: -4rem; */
    }
`;

export const Image = styled.div`
    width: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 5px;
    }
`;

export const ProductName = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 5rem;
    // padding-right: 2rem;
    flex: 1;
    // padding-left: 1rem;

    p {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 8rem;
    
    }
`;

type ColorProps = {
    color?: boolean;
};

export const DefaultColumn = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 12%;
    /* padding-left: 1rem; */
    flex: 1;
    span {
        ${(props) =>
            props.color
                ? `
                color: var(--green);
                `
                : `
                color: red;
        `}
        margin-left: 0.5rem;
    }
`;

export const DefaultColumnLucro = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
    /* padding-left: 1rem; */
    flex: 1;
    span {
        ${(props) =>
            props.color
                ? `
                color: var(--green);
                `
                : `
                color: red;
        `}
    }
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    /* padding-left: 1rem; */
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    /* padding-left: 1rem; */
`;

export const Plus = styled.div`
    width: 1.375rem;
    height: 1.375rem;
    background-color: #b3cbc1;
    border-radius: 50%;
    cursor: pointer;
    color: var(--white);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--green);
    }
`;

export const OptionBody = styled.div`
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    /* margin-top: 15px; */
    padding: 20px 20px 20px 20px;
    width: 28%;
    max-width: 600px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    flex: 1 1 auto;
`;

export const OptionContent = styled.div`
    box-shadow: none;
    border: none;
`;

export const OptionHeader = styled.div`
    margin-botton: 0.5rem;
    font-size: 1.3rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherir;
`;

export const OptionButton = styled.div`
    background: white;
    --hover-color: #19c380;
    --arrow-width: 10px;
    --arrow-stroke: 2px;
    box-sizing: border-box;
    color: #19c380;
    border: 1px solid #19c380;
    border-radius: 20px;
    padding: 1em 1.8em;
    display: flex;
    transition: 0.2s;
    margin-top: 16px;
    align-items: center;
    gap: 0.6em;
    font-weight: 550;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #19c27e;
        color: white;
    }
`;