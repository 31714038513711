import { ProductDTO } from '../../../@types';

export function groupProductsByProductId(productList: ProductDTO[]) {
    const groupedProducts: { [key: string]: any } = {};

    productList?.forEach(function (product) {
        const productId = product?.product_id;

        if (!groupedProducts?.[productId]) {
            groupedProducts[productId] = {
                product_id: product?.product_id,
                product_title: product?.product_title,
                company_id: product?.company_id,
                store_id: product?.store_id,
                account_id: product?.account_id,
                latest_order_date: product?.latest_order_date,
                product_image_src: product?.product_image_src,
                variants: [
                    {
                        variant_id: product?.variant_id,
                        variant_sku: product?.sku,
                        variant_title: product?.variant_title,
                        price: product?.variant_price,
                        cost: product?.variant_cost,
                        variant_image_src: product?.variant_image_src,
                        currency: product?.currency,
                        latest_order_date: product?.latest_order_date,
                    },
                ],
            };
        } else {
            groupedProducts?.[productId]?.variants?.push({
                variant_id: product?.variant_id,
                variant_sku: product?.sku,
                variant_title: product?.variant_title,
                price: product?.variant_price,
                cost: product?.variant_cost,
                variant_image_src: product?.variant_image_src,
                currency: product?.currency,
                latest_order_date: product?.latest_order_date,
            });
            if (product?.latest_order_date) {
                const newDate = new Date(product?.latest_order_date);
                const existingDate = groupedProducts[productId]?.latest_order_date ? new Date(groupedProducts[productId]?.latest_order_date) : null;

                if (!existingDate || newDate > existingDate) {
                    groupedProducts[productId].latest_order_date = product?.latest_order_date;
                }
            }
        }
    });
    return Object.values(groupedProducts).sort((a: any, b: any) => new Date(b?.latest_order_date).getTime() - new Date(a?.latest_order_date).getTime());
   
}
export function formatValue(input: any) {
    // Remove tudo que não é dígito
    let value = input.value.replace(/\D/g, "");

    // Adiciona o ponto decimal
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");

    // Adiciona os milhares
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    // Adiciona o R$ na frente
    value = value;

    // Atualiza o valor do campo
    input.value = value;

    return value;
}