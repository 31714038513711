import styled from "styled-components";

export const Container = styled.div<{isCatalogCampaign?: boolean, totalRow?: boolean}>`
display: flex;
min-width: ${props => props.isCatalogCampaign ? '234rem' : '122rem'};
position: ${props => props.totalRow ? 'sticky' : 'relative'};
bottom: ${props => props.totalRow ? '-2px' : 'auto'};
border-top: ${props => props.totalRow ? '1px solid #ccc' : 'none'};
height: 4rem;
z-index: 9;
background-color: #fff;

`

export const MainColumn = styled.div<{isVisible?: boolean, isFixedRow?: boolean}>`
width: 17.8rem;
    max-width: 17.8rem;
    min-width: 17.8rem;
    gap: 0.5rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 1rem;
font-size: 0.8364375rem;
font-weight: ${props => props.isFixedRow ? '550' : '500'};
color: ${props => props.isFixedRow ? '#19c' : '#1e1e1e'};
position: sticky;
left: 0;
z-index: 9;
background-color: inherit;
border-right: 1px solid #eee;

img {
border-radius: 12px;
margin-left:  ${props => props.isFixedRow ? '0.7rem' : '0'};
height: ${props => props.isFixedRow ? '1.5rem' : '2.5rem'};
width: ${props => props.isFixedRow ? '1.5rem' : '2.5rem'};
// opacity: 0.4;
}

p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 19.4rem;
    
    }
`
export const Pending = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Cancelled = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;


export const Approved = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const MetricsButton = styled.div`

  width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--green);
    }
`

export const DefaultColumn = styled.div<{isFixedRow?: boolean, totalRow?: boolean}>`
max-width: 10.25rem;
    min-width: 10.25rem;
display: flex;
text-align: center;
// border-top: ${props => props.totalRow ? '1px solid #ccc' : 'none'};
align-items: center;
justify-content: center;
// margin-left: 1rem;
font-size: 0.8rem;
position: ${props => props.isFixedRow ? 'sticky' : 'relative'};
top: ${props => props.isFixedRow ? '55px' : 'auto'};
font-weight: ${props => props.totalRow || props.isFixedRow ? '550' : '500'};
color: #1c1e21;
text-wrap: nowrap;
    // padding-left: 1rem;

img {
        width: 0.75rem;
        margin-right: 0.3rem;
    }
`


