import { useMutation, useQuery } from 'react-query';
import {createAccessGroup, createInvite, getAccessGroups, getAccessControl, deleteAccessControl } from '../api/api-access'
import useAlert from '../../hooks/useAlert';

export function useCreateAccessGroup() {
    return useMutation(createAccessGroup);
}

export function useCreateInvite() {
    return useMutation(createInvite);
}


export function useGetAccessGroups(company_id: string) {
    return useQuery(['accessGroups', company_id], () => getAccessGroups(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetAccessControl(company_id: string) {
    return useQuery(['accessControl', company_id], () => getAccessControl(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}


export function useDeleteAccessControl() {
    const { showAlert } = useAlert();

    return useMutation(deleteAccessControl, {

        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Membro removido com sucesso.', 'success', 5000);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Ocorreu um erro ao remover o membro, por favor, tente novamente.', 'error', 5000);
        },
    });
}
