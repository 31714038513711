import { LineProductsCosts } from './LineEditByVariant';
import * as S from './style';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import { LineProductLoading } from '../TableProductsCosts/LineProductLoading';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

export function TableEditByVariant({currentPage, setCurrentPage, isLoading, openedProduct,setOpenedProduct, products, setProducts, filtred, editedVariants, setEditedVariants, dataProductCosts, setDataProductCosts}: { 
    currentPage: number, // Defina o tipo corretamente
    setCurrentPage: (page: number) => void // Defina o tipo corretamente
    isLoading: any,
    openedProduct: string,
    setOpenedProduct: (value: string) => void,
    products: any[], 
    setProducts: (value: any[]) => void, // Add the missing type for setProducts
    filtred: string, 
    editedVariants: Record<string, any>,  
    setEditedVariants: (value: Record<string, any>) => void,
    dataProductCosts: any
    setDataProductCosts: (value: any) => void
}) {

    const filteredProducts = products.filter((product: any) => {
        const titleIncludes = product?.product_title?.toLowerCase().includes(filtred.toLowerCase());
        
        if (titleIncludes) {
          return true; 
        }
      
        const variantSKUs = Object.values(product?.variants || {})
          .map((variant: any) => variant?.variant_sku?.toLowerCase())
          .filter((sku: any) => sku);
        
        return variantSKUs.some((sku: any) => sku.includes(filtred.toLowerCase()));
      });
    const totalElements = filteredProducts?.length;
    const itemPorPage = 6;
    const totalPages = Math.ceil(totalElements / itemPorPage);
    const startIndex = (currentPage - 1) * itemPorPage;
    const endIndex = Math.min(startIndex + itemPorPage - 1, totalElements - 1);
   
    const currentItems = filteredProducts.slice(startIndex, endIndex + 1);

    //@ts-ignore
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    //console.log('PRODUTOS AQUI2');
    //console.log(products);

    return (
        <S.Container>
            <S.TableHeader>
                <S.Image></S.Image>
                <S.ProductName>Produto</S.ProductName>
                <S.Price>Preço</S.Price>
                <S.Cost>Custo
                <Tooltip title="Caso configure os custos deste produto individualmente por variante na aba 'Edição por variante' exibiremos a média dos valores de custo de todas as variantes." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <InfoRoundedIcon sx={{width: '15px', marginLeft: '8px', fill: '#7b9898'}}/>
                </Tooltip>
                </S.Cost>
                <S.LastSale>Última venda em:</S.LastSale>
                <S.EditVariants>Editar Variantes</S.EditVariants>
            </S.TableHeader>
            <S.Lines>
            {!isLoading &&
                    currentItems?.length > 0 &&
                    currentItems?.map((_: any, index: any) => {
                        return <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[index]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts} />;
                    })}
                {isLoading && Array.from({ length: 8 }).map((_, index) => <LineProductLoading key={index} />)}


                {/* <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[0]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/>
                <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[1]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/>
                <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[2]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/>
                <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[3]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/>
                <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[4]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/>
                <LineProductsCosts openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} product={currentItems[5]} editedVariants={editedVariants} setEditedVariants={setEditedVariants} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts}/> */}
            </S.Lines>

            <S.ContainerPagination>
                <Stack spacing={2}>
                    <Pagination
                        renderItem={(item) => <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />}
                        color="primary"
                        count={totalPages}
                        defaultPage={1}
                        page={currentPage}
                        onChange={handleChange}
                    />
                </Stack>
            </S.ContainerPagination>
        </S.Container>
    );
}
