import * as S from './style';
import { InputWithTypeMoney } from '../../../../../../components/InputWithTypeMoney';
import { useState } from 'react';
import  {ModalVariantHistoryCosts}  from '../../../ModalVariantHistoryCosts';
import useVisibility from '../../../../../../hooks/useVisibilityChange';
import { ButtonApp } from '../../../../../../components/ButtonApp';
import { HistoryIconStyled } from './style';
import { useCurrencyType } from '../../../../../../hooks/useCurrencyType';

export function LineModalProductsCosts({ variant, individualsValue, handleInputChange, disabled, dataProductCosts, setDataProductCosts }: any) {
    const { isVisible } = useVisibility();
    const [showModal, setShowModal] = useState(false);
    const { currencySymbol } = useCurrencyType();

    return (
        <S.Container>
            {showModal && <ModalVariantHistoryCosts variant={variant} onClose={() => setShowModal(false)} dataVariantCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} />}
            <S.Image>{variant?.variant_image_src && <img src={isVisible ? variant?.variant_image_src : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt="product_image" />}</S.Image>
            <S.ProductVariant>
                {isVisible ? variant?.variant_title && variant?.variant_title : '* * * * * * * * * *'}
                <span>
                    {isVisible ? (variant?.variant_sku ? 
                        (variant.variant_sku.length > 55 ? 
                            'SKU: ' + variant.variant_sku.substring(0, 25) + '...' + variant.variant_sku.substring(variant.variant_sku.length - 27) : 
                            'SKU: ' + variant.variant_sku) : 
                        'Não há SKU') : '* * * * * * * * * *'}
                </span>
            </S.ProductVariant>
            <S.ProductPrice>{variant?.price && `${currencySymbol} ${Number(variant?.price).toLocaleString('pt-Br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</S.ProductPrice>
            <S.IndividualCost>
                <InputWithTypeMoney disabled={disabled} width="8rem" variantId={variant?.variant_id} value={individualsValue?.[variant?.variant_id]?.value || ''} setValue={handleInputChange} currency={individualsValue?.[variant?.variant_id]?.currency || 'BRL'} />
            </S.IndividualCost>
            <S.Historic>
            <ButtonApp border='' width='130px' height='30px' background='var(--black)' color='white' icon={<HistoryIconStyled />} text='Histórico'  onClick={() => setShowModal(true)} />
            </S.Historic>
        </S.Container>
    );
}
