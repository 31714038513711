import styled from "styled-components";

export const Container = styled.div`
display: flex;
min-width: 89.4375rem;
position: relative;
height: 2.5rem;
background-color: #fff;

`

export const MainColumn = styled.div`
width: 16.6875rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 1rem;
font-size: 0.8364375rem;
font-weight: 500;
color: var(--green);
position: sticky;
left: 0;
z-index: 9;
background-color: inherit;
border-right: 1px solid #eee;
`
export const Pending = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Cancelled = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;


export const Approved = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const MetricsButton = styled.div`

  width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--green);
    }
`

export const DefaultColumn = styled.div`
width: 9.25rem;
display: flex;
align-items: center;
justify-content: flex-start;
margin-left: 1rem;
font-size: 0.8rem;
font-weight: 500;
color: #1c1e21;
position: relative;
img {
        width: 0.75rem;
        margin-right: 0.3rem;
    }
`


