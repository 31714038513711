import { CURRENT_USER_STORAGE } from './storageConfig';

export function storageCurrentUserSave(user: string) {
    localStorage.setItem(CURRENT_USER_STORAGE, user);
}

export function storageCurrentUserGet() {
    const storage = localStorage.getItem(CURRENT_USER_STORAGE);

    return storage;
}

export function storageCurrentUserRemove() {
    localStorage.removeItem(CURRENT_USER_STORAGE);
}

export function storageCurrentUserMetaSave(meta: object, currentCompany: string) {
    const newMeta = JSON.stringify(meta);

    localStorage.setItem(`${CURRENT_USER_STORAGE}-meta-${currentCompany}`, newMeta);
}

export function storageCurrentUserMetaGet(currentCompany: string) {
    const storage = localStorage.getItem(`${CURRENT_USER_STORAGE}-meta-${currentCompany}`);

    const meta = storage ? JSON.parse(storage) : {};

    return meta;
}
