import { useEffect, useState } from 'react';
import { PermissionList } from './PermissionList';
import { trackEvent } from '../../services/segment/sendEvent'
import * as S from './style';
import useAuth from '../../hooks/useAuth';
import { useGetAccessControl, useGetAccessGroups } from '../../services/hooks/accessHooks';


export function Permission() {

    const { user, currentCompany } = useAuth();
    const { data:DataAccessGroups, isLoading: isLoadingAccessGroups} = useGetAccessGroups(currentCompany);   
    const { data: DataAccessControl, isLoading: isLoadingAccessControl, refetch: refetchDataAccessControl } = useGetAccessControl(currentCompany);

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'permissions'
        });
      }, [])

    const isLoading = isLoadingAccessGroups || isLoadingAccessControl;

    return (
        <S.Container>
            <S.Title>Gerenciamento de equipe</S.Title>
            <S.Subtitle>Convide novos membros da sua equipe para gerenciar sua loja.</S.Subtitle>
            <PermissionList refetchDataAccessControl={refetchDataAccessControl} isLoadingSkeleton={isLoading} groups={DataAccessGroups?.data?.data} groupName="Grupos" buttonText="Criar novo grupo" />
            <PermissionList refetchDataAccessControl={refetchDataAccessControl} isLoadingSkeleton={isLoading} groups={DataAccessGroups?.data?.data} members={DataAccessControl?.data?.data} groupName="Membros" buttonText="Convidar novo membro" />
        </S.Container>
    );
}
