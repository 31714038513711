import styled from 'styled-components';

export const Container = styled.div`
    min-width: 220rem;
    display: flex;
    position: relative;
`;

type MainColumnProps = {
    type: string;
    isMainSource: boolean;
    isWithoutAttribution?: boolean;
};

export const AnalyticsButton = styled.div`
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: not-allowed;
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const MainColumn = styled.div<MainColumnProps>`

    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-wrap: nowrap;
    font-weight: 550;
    color: var(--black);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4rem;
    width: 29.125rem;
    height: 3.1rem;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    gap: 0.5rem;
    img {
         width: 0.950rem;
        height: 0.9rem;

        ${(props) => !props.isMainSource && 'margin-left: 0.5rem; width: 1.2rem; height: 1.2rem;'}
    }
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
`;
export const CheckBoxArea = styled.div`
    width: 2.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #dedede;
    cursor: pointer;
    margin-left: -3.5rem;
    margin-right: 1.5rem;
    padding-right: 8px;
    cursor: not-allowed;
`;

export const DefaultColumn = styled.div`
    font-size: 0.835rem;
    font-weight: 500;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.1rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;

export const ContainerAccount = styled(Container)``;

export const MainColumnAccount = styled(MainColumn)`
    padding-left: 4rem;
    width: 29.125rem;

    img {
        height: 1rem;
        width: auto;
        margin-left: 3rem;
    }
    span {
        &:first-child {
            height: 100%;
            width: 1px;
            background-color: var(--gray-150);
            margin-left: -2rem;
            ${(props) => props.isWithoutAttribution && 'margin-right: 1.8rem;'}
        }
    }
`;

export const UrlLinkClick = styled.span``;

export const AnalyticsButtonAccount = styled.div`
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 1.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const AdsAccountsTitle = styled.div`
    background-color: #e6e6e6;
    color: #527566;
    width: 6.8rem;
    height: 0.96875rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    font-weight: 600;
`;