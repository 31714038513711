import styled from 'styled-components';
import HistoryIcon from '@mui/icons-material/History';


export const Container = styled.div`
    display: flex;
    color: var(--black);
    height: 5.375rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    > span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
`;
export const Image = styled.div`
    // width: 26%;
    img {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 5px;
        margin-top: 1.2rem;
        margin-left: 1.5rem;
    }
`;

export const Edit = styled.div`
display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    // width: 26%;
`

export const CheckboxSection = styled.div`

`

export const ProductName = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;

    p {
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 11rem;}
`;

export const Price = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Cost = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Historic = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Action = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`;

export const LastSale = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`;

export const HistoricButton = styled.button`
width: 7rem;
height: 1.5rem;
color: #979797;
background-color: #FFFFFF;
border: 1px solid #979797;
border-radius: 5px;
font-size: 0.75rem;
line-height: 1.125rem;
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
svg{
    fill: #979797;
    width: 0.75rem;
    height: 0.75rem;
}
`;

export const HistoryIconStyled = styled(HistoryIcon)`
    width: 0.7em !important;
    fill: var(--black);
`;