import * as S from './style';
import useAuth from '../../hooks/useAuth';
import { useState, useEffect, useRef } from 'react';
import { format, subMinutes, differenceInMinutes } from 'date-fns';
import { useTrackingMetrics, useConfigureUtm } from '../../services/hooks/trackingHooks';
import { TableConfigurationUtm } from './TableConfigurationUtm';
import { getWrongUtms } from './utils';
import { useNavigate } from 'react-router-dom';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TutorialCheckbox from './TutorialCheckbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Close from '@mui/icons-material/Close';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useIntercom } from 'react-use-intercom';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { useRefreshContext } from '../../contexts/RefeshContext';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { ButtonApp } from '../../components/ButtonApp';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import WifiTetheringRoundedIcon from '@mui/icons-material/WifiTetheringRounded';
import { Tooltip } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { getIntervalString } from '../_layouts/AppLayout/Header/Refresh/utils';
import { useGetLastUpdated, useGetRefreshProgress } from '../../services/hooks/getData';
import { storageGroupIdGet, storageGroupIdRemove, storageInitRefreshAllGetDataGet, storageInitRefreshAllGetDataRemove, storageLastSyncGet } from '../../storage/storageGetData';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { useProductsAnalyticsList } from '../../services/hooks/productsHooks';

export function Configuration() {

    const { user, currentCompany } = useAuth();
    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);
    const { data: dataProductList, isLoading: isLoadingProductList, refetch: refetchProductList } = useProductsAnalyticsList(currentCompany);
    const [productIds, setProductIds] = useState([]);

    const shopifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length || 0;
    const kiwifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length || 0;

    useEffect(() => {
        if (!isLoadingProductList && dataProductList && dataProductList.data && dataProductList.data.data) {
            const productsData = dataProductList.data.data.map(item => ({
                product_id: item.product_id
            }));
            const ids = productsData.map(item => item.product_id);
            setProductIds(ids);

            if (shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1){
                setProductIds([]);
            }
        }
    }, [isLoadingProductList, dataProductList]);

    const { isLoading, data: dataMetrics, refetch: refetchTrackingMetrics } = useTrackingMetrics(currentCompany, date[0], date[1], productIds, 'last_click');
    const { mutate: configureUtm } = useConfigureUtm();
    const { showArticle } = useIntercom();
    const [showConfigurePopup, setShowConfigurePopup] = useState(false);
    const [buttonGoogleDisabled, setButtonGoogleDisabled] = useState(false);
    const [configuringGoogle, setConfiguringGoogle] = useState(false);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [accountsConfigured, setAccountsConfigured] = useState([] as string[]);
    const [failedAccounts, setFailedAccounts] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [updatedAtFbTtk, setUpdatedAtFbTtk] = useState('');
    const [updatedAtGoogle, setUpdatedAtGoogle] = useState('');
    const [activeGetLastUpdated, setActiveGetLastUpdated] = useState(true);
    const [intervalString, setIntervalString] = useState('');
    const [activeGetRefreshProgress, setActiveGetRefreshProgress] = useState(storageInitRefreshAllGetDataGet());
    const [groupId, setGroupId] = useState(storageGroupIdGet())
    const [lastSync, setLastSync] = useState(storageLastSyncGet());

    const { isLoading: isLoadingGetLastUpdated, refetch: refetchLastUpdated} = useGetLastUpdated(currentCompany, activeGetLastUpdated); 
    const {data: dataRefreshProgress } = useGetRefreshProgress(currentCompany, groupId, activeGetRefreshProgress);
    const { refreshData } = useRefreshContext();  
    
    
    useEffect(() => {
           refetchLastUpdated().then((data) => {
            const lastUpdatedDate = new Date(data?.data?.data?.data);
            const now = new Date();
    
            // Calcula a diferença em minutos entre a data atual e a última data de atualização
            const minutesSinceUpdate = differenceInMinutes(now, lastUpdatedDate);
    
            // Verifica se o intervalo já passou (mais de 10 minutos desde a última atualização)
            let minutesUntilNextUpdate;
            if (minutesSinceUpdate >= 10) {
                // A próxima atualização já está atrasada se passou dos 10 minutos
                minutesUntilNextUpdate = 0;
            } else {
                // Calcula quantos minutos faltam até a próxima atualização
                minutesUntilNextUpdate = 10 - minutesSinceUpdate;
            }

            // Formata a string para exibir o tempo restante
            const updateMessage = minutesUntilNextUpdate > 0
                ? `Atualiza em ${minutesUntilNextUpdate} minutos`
                : "Atualiza em breve";
            
            console.log(updateMessage);
            setUpdatedAtFbTtk(updateMessage);
           });
   
       },[location.pathname])

       useEffect(() => {
        if(dataRefreshProgress?.data?.data === 100){
            setActiveGetRefreshProgress(false);
            setActiveGetLastUpdated(true);
            setActiveGetLastUpdated(false);
            storageInitRefreshAllGetDataRemove()
            storageGroupIdRemove();
            refreshData();
            refetchLastUpdated().then((data) => {
                const lastUpdatedDate = new Date(data?.data?.data?.data);
                const now = new Date();
        
                // Calcula a diferença em minutos entre a data atual e a última data de atualização
                const minutesSinceUpdate = differenceInMinutes(now, lastUpdatedDate);
        
                // Verifica se o intervalo já passou (mais de 10 minutos desde a última atualização)
                let minutesUntilNextUpdate;
                if (minutesSinceUpdate >= 10) {
                    // A próxima atualização já está atrasada se passou dos 10 minutos
                    minutesUntilNextUpdate = 0;
                } else {
                    // Calcula quantos minutos faltam até a próxima atualização
                    minutesUntilNextUpdate = 10 - minutesSinceUpdate;
                }
        
                // Formata a string para exibir o tempo restante
                const updateMessage = minutesUntilNextUpdate > 0
                    ? `Atualiza em ${minutesUntilNextUpdate} minutos`
                    : "Atualiza em breve";
                
                console.log(updateMessage);
                setUpdatedAtFbTtk(updateMessage);
            });
        }
    },[dataRefreshProgress?.data?.data])
    

    useEffect(() => {
        const completionDate = localStorage.getItem('completionDate');
        const today = new Date().toISOString().slice(0, 10);
        if (completionDate === today) {
            setIsChecked(true);
        }
    }, []);

    const { refreshCounter } = useRefreshContext();

    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetchTrackingMetrics();
        }
      }, [refreshCounter]);

    useEffect(() => {
        const calculateTimeUntilNextUpdate = () => {
            const now = new Date();
            const minutes = now.getMinutes();
            const minutesPastHour = minutes % 30;
            let minutesUntilNextUpdate;

            if (minutesPastHour < 5) {
                minutesUntilNextUpdate = 5 - minutesPastHour;
            } else if (minutesPastHour < 35) {
                minutesUntilNextUpdate = 35 - minutesPastHour;
            } else {
                minutesUntilNextUpdate = 65 - minutesPastHour; // 30 + 35 = 65, porque precisamos ir até a próxima hora e adicionar 5 minutos.
            }

            setUpdatedAtGoogle(`Atualiza em ${minutesUntilNextUpdate} minutos`);
            console.log('Atualiza em', minutesUntilNextUpdate, 'minutos Google');
        };

        calculateTimeUntilNextUpdate();
        const intervalId = setInterval(calculateTimeUntilNextUpdate, 60000); // Atualiza a contagem a cada minuto

        return () => clearInterval(intervalId); // Limpeza do intervalo ao desmontar
    }, []);

    const today = format(new Date(), 'yyyy-MM-dd');

    useEffect(() => {
        setDate([today, today]);
    }, [today]);

    const [showStatusFacebook, setShowStatusFacebook] = useState(false);
    const [showStatusGoogle, setShowStatusGoogle] = useState(false);
    const [showStatusTiktok, setShowStatusTiktok] = useState(false);
    const [showUTMsFacebook, setShowUTMsFacebook] = useState(false);
    const [showUTMsGoogle, setShowUTMsGoogle] = useState(false);
    const [showUTMsTiktok, setShowUTMsTiktok] = useState(false);
    const navigate = useNavigate();
    function triggerRefreshEvent() {
        const event = new Event('triggerRefresh');
        window.dispatchEvent(event);
    }
    

    useEffect(() => {
        if (user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.length - 1]?.status === 'active') {
            setShowStatusFacebook(true);
        }
    
        if (user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles?.length - 1]?.status === 'active') {
            setShowStatusGoogle(true);
        }

        if (user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles?.length - 1]?.status === 'active') {
            setShowStatusTiktok(true);
        }
    }, [currentCompany, user]);
    
    const getDefaultTab = () => {
        const hash = window.location.hash.replace('#', '');
        const validTabs = ['Google', 'Facebook', 'Tiktok'];

        if (validTabs.includes(hash)) {
            return hash;
        }
        
        if (!showStatusFacebook) {
            if (!showStatusGoogle) {
                if (!showStatusTiktok) {
                    return 'Empty';
                }
                return 'Empty';
            }
            return 'Merchant';
        }
        return 'Facebook';
    };

    const [tabSelected, setTabSelected] = useState(getDefaultTab); // Inicializa o estado com a função getDefaultTab

    useEffect(() => {
        setTabSelected(getDefaultTab());
    }, [showStatusFacebook, window.location.hash]); // Dependências no useEffect

    const metricsByAds = getWrongUtms( dataMetrics?.data?.data, tabSelected.toLowerCase());
    const [copyText, setCopyText] = useState('');
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const [showSagWarning, setShowSagWarning] = useState(false);

    useEffect(() => {
        if (dataMetrics?.data?.data?.utm_metrics?.utm_metrics?.orders?.source?.google?.campaign?.sag_organic ) {
            setShowSagWarning(true);
        }
    }, [dataMetrics]);

    useEffect(() => {
        if (tabSelected === 'Facebook' || tabSelected === 'Tiktok') {
            setButtonDisabled(true);
        }
        else if (tabSelected === 'Google') {
            setButtonDisabled(buttonGoogleDisabled);
        }
    }, [tabSelected, buttonGoogleDisabled]);
            
    const endOfPageRef = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        endOfPageRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    const handleCopy = () => {
        navigator.clipboard.writeText(copyText)
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000);
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    useEffect(() => {
        if (tabSelected === 'Facebook') {
            setCopyText('utm_source={{site_source_name}}&utm_medium={{placement}}&utm_campaign={{campaign.id}}&utm_content={{ad.id}}');
        } else if (tabSelected === 'Google') {
            setCopyText('{lpurl}?utm_source=google&utm_content={creative}&utm_campaign={campaignid}');
        } else if (tabSelected === 'Tiktok') {
            setCopyText('?utm_source=tiktok&utm_medium=tiktok_ads&utm_campaign=__CAMPAIGN_ID__&utm_content=__CID__');
        }
    }, [tabSelected]);


    useEffect(() => {
        // console.log("entrou 1", showSagWarning, 'isChecked', isChecked);
        if (dataMetrics && dataMetrics.data && dataMetrics.data.data) {
            const metricsFacebook = getWrongUtms(dataMetrics.data.data, 'facebook');
            const metricsGoogle = getWrongUtms(dataMetrics.data.data, 'google');
            const metricsTiktok = getWrongUtms(dataMetrics.data.data, 'tiktok');
            
            if (Object.keys(metricsFacebook).length === 0) {
                setShowUTMsFacebook(true);
            } 
    
            if (Object.keys(metricsGoogle).length === 0 && (!showSagWarning || isChecked)) { 
                setShowUTMsGoogle(true);
            }else {
                setShowUTMsGoogle(false);
            }

            if (Object.keys(metricsTiktok).length === 0) {
                setShowUTMsTiktok(true);
            }
        }
    }, [dataMetrics ]); // showSagWarning, isChecked

    console.log('showStatusFacebook', showStatusFacebook === false, 'showStatusGoogle', showStatusGoogle, 'showStatusTiktok', showStatusTiktok);

    useEffect(() => {
        const startConfigTime = localStorage.getItem('startConfigTime');
        const currentTime = new Date().getTime();
    
        if (startConfigTime) {
            const timeElapsed = currentTime - parseInt(startConfigTime);
    
            if (timeElapsed < 240000 ) {
                setConfiguringGoogle(true);
    
                const timer = setTimeout(() => {
                    setConfiguringGoogle(false);
                    localStorage.removeItem('startConfigTime'); 
                }, 240000  - timeElapsed);
    
                return () => clearTimeout(timer);
            } else {
                localStorage.removeItem('startConfigTime');
            }
        }
    }, []);
    
    const handleUtmConfiguration = () => {
        if (tabSelected === 'Google') {      
            setConfiguringGoogle(true);      
            configureUtm({company_id: currentCompany, platform: 'google'}, {
                onSuccess: (data) => {
                    setShowConfigurePopup(false);
                    setConfiguringGoogle(false); 
                    setShowSuccessPopup(true);
                    console.log(data);
                    setAccountsConfigured(data?.data?.data);
                    setFailedAccounts(data?.data?.failed || []);
                },
                onError: (error) => {
                    setShowConfigurePopup(false);
                    setConfiguringGoogle(false); 
                    console.log(error);
                    setShowErrorPopup(true);
                }
            });

        }
    };
    
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setShowConfigurePopup(false);
            }
        }
        if (showConfigurePopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showConfigurePopup]);

    const allUtmsOk = Object.keys(metricsByAds).length === 0;


    
    return (
        <S.ContainerBackground id="body">

            <S.TabsContainer>
                
                <S.Title><h2>Configurações das UTMs</h2>
                <p>Saiba todos os problemas que estão ocorrendo nas suas configurações de UTMs<br></br> para extrair o melhor do traqueamento da Solomon.</p>
                </S.Title>
                
                <S.TabContentF>
                <Tooltip title={!showStatusFacebook ? "Clique para integrar o Facebook Ads." : ''} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                    }}>
                    <S.TabArea onClick={() => (!showStatusFacebook ? navigate('/integracao/facebook') : setTabSelected('Facebook'))} showHover={tabSelected === 'Facebook'} platformDisconnected={!showStatusFacebook}>
                        <S.TabButton showHover={tabSelected === 'Facebook'}>Facebook</S.TabButton>
                        <S.FlexStatusContainer>
                            {(!isLoading && showUTMsFacebook) && (
                                <S.FlexStatusContainer>
                                    <Tooltip title={!showStatusFacebook ? "" : 'UTMs configuradas corretamente.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                            }}>
                                    <S.ConectWarning><WifiTetheringRoundedIcon/></S.ConectWarning>
                                    </Tooltip>
                                </S.FlexStatusContainer>
                            )}
                            {(!isLoading && !showUTMsFacebook) && (
                                <S.FlexStatusContainer>
                                    <Tooltip title={!showStatusFacebook ? "" : 'Problemas nas UTMs do Facebook.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <S.UTMstWarning onClick={()=> (setTabSelected('Facebook'), handleClick())}><WifiTetheringRoundedIcon/></S.UTMstWarning>
                                    </Tooltip>
                                </S.FlexStatusContainer>
                            )}
                            {isLoading && (
                                <S.FlexStatusContainer>
                                    <S.ConectWarningRef>
                                        <S.RotatingIcon/>
                                    </S.ConectWarningRef>
                                </S.FlexStatusContainer>
                            )}
                        </S.FlexStatusContainer>
                    </S.TabArea>
                    </Tooltip>

                    <Tooltip title={!showStatusGoogle ? "Clique para integrar o Google Ads." : ''} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                    }}>
                    <S.TabArea onClick={() => (!showStatusGoogle ? navigate('/integracao/google') : setTabSelected('Google'))} showHover={tabSelected === 'Google'} platformDisconnected={!showStatusGoogle}>
                        <S.TabButton showHover={tabSelected === 'Google'}>Google</S.TabButton>
                        <S.FlexStatusContainer>
                            {(!isLoading &&  showUTMsGoogle) && (
                                 <S.FlexStatusContainer>
                                 <Tooltip title={!showStatusGoogle ? "" : 'UTMs configuradas corretamente.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                         }}>
                                 <S.ConectWarning><WifiTetheringRoundedIcon/></S.ConectWarning>
                                 </Tooltip>
                             </S.FlexStatusContainer>
                            )}
                            {(!isLoading && !showUTMsGoogle) && (
                            <S.FlexStatusContainer>
                                 <Tooltip title={!showStatusGoogle ? "" : 'Problemas nas UTMs do Google.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                }}>
                                <S.UTMstWarning onClick={()=> (setTabSelected('Google'), handleClick())}><WifiTetheringRoundedIcon/></S.UTMstWarning>
                                </Tooltip>
                            </S.FlexStatusContainer>
                            )}
                          
                            {isLoading && (
                                <S.FlexStatusContainer>
                                    <S.ConectWarningRef>
                                        <S.RotatingIcon/>
                                    </S.ConectWarningRef>
                                </S.FlexStatusContainer>
                            )}
                        </S.FlexStatusContainer>
                    </S.TabArea>
                    </Tooltip>

                    <Tooltip title={!showStatusTiktok ? "Clique para integrar o Tiktok Ads." : ''} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                    }}>
                    <S.TabArea onClick={() => (!showStatusTiktok ? navigate('/integracao/tiktok') : setTabSelected('Tiktok'))} showHover={tabSelected === 'Tiktok'} platformDisconnected={!showStatusTiktok}>
                        <S.TabButton showHover={tabSelected === 'Tiktok'}>Tiktok</S.TabButton>
                        <S.FlexStatusContainer>
                            {(!isLoading && showUTMsTiktok) && (
                                <S.FlexStatusContainer>
                                <Tooltip title={!showStatusTiktok ? "" : 'UTMs configuradas corretamente.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                        }}>
                                <S.ConectWarning><WifiTetheringRoundedIcon/></S.ConectWarning>
                                </Tooltip>
                            </S.FlexStatusContainer>
                            )}
                            {(!isLoading && !showUTMsTiktok) && (
                                <S.FlexStatusContainer>
                                    <Tooltip title={!showStatusTiktok ? "" : 'Problemas nas UTMs do Tiktok.'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                <S.UTMstWarning onClick={()=> (setTabSelected('Tiktok'), handleClick())}><WifiTetheringRoundedIcon/></S.UTMstWarning>
                                </Tooltip>
                            </S.FlexStatusContainer>
                            )}
                            
                            {isLoading && (
                                <S.FlexStatusContainer>
                                    <S.ConectWarningRef>
                                        <S.RotatingIcon/>
                                    </S.ConectWarningRef>
                             
                                </S.FlexStatusContainer>
                            )}
                        </S.FlexStatusContainer>
                    </S.TabArea>
                    </Tooltip>
                {showStatusGoogle && (
                    <S.TabArea onClick={() => setTabSelected('Merchant')} showHover={tabSelected === 'Merchant'}>
                        <S.TabButton showHover={tabSelected === 'Merchant'}>Google Merchant</S.TabButton>
                        <S.FlexStatusContainer>
                            {(!isLoading &&  !showSagWarning) && (
                               <S.FlexStatusContainer>
                               <Tooltip title="Merchant configurado corretamente." placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                }}>
                               <S.ConectWarning><WifiTetheringRoundedIcon/></S.ConectWarning>
                               </Tooltip>
                           </S.FlexStatusContainer>
                            )}
                            {(!isLoading && showSagWarning) && (
                                <S.FlexStatusContainer>
                                    <Tooltip title="Problemas nas configurações do Merchant." placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <S.UTMstWarning onClick={()=> (setTabSelected('Merchant'), handleClick())}><WifiTetheringRoundedIcon/></S.UTMstWarning>
                                    </Tooltip>
                               </S.FlexStatusContainer>
                            )}
                            {isLoading && (
                                <S.FlexStatusContainer>
                                    <S.ConectWarningRef>
                                        <S.RotatingIcon/>
                                    </S.ConectWarningRef>
                                </S.FlexStatusContainer>
                            )}
                        </S.FlexStatusContainer>
                    </S.TabArea>
                )}
                    
                </S.TabContentF>
            </S.TabsContainer>

            <S.HeaderContainer>
                    <S.InfosContainer>
                        <S.ContentArea>
                            <S.ContentTitle>Status {tabSelected === 'Merchant' ? 'Merchant' : 'UTM'}:
                                <span>Veja detalhes do status {tabSelected === 'Merchant' ? 'do seu Merchant.' : 'das suas UTMs.'}</span>
                            </S.ContentTitle>
                            {!isLoading && tabSelected !== 'Merchant' && (
                                allUtmsOk ? (
                                    <S.UtmLoadedSuccess clickable={false}>
                                        <ThumbUpAltRoundedIcon/>
                                        <h2>Suas UTMs estão configuradas corretamente.</h2>
                                    </S.UtmLoadedSuccess>
                                ) : (
                                    (tabSelected === 'Facebook' && showStatusFacebook) || 
                                    (tabSelected === 'Google' && showStatusGoogle) || 
                                    (tabSelected === 'Tiktok' && showStatusTiktok) ? (
                                        <S.UtmLoaded clickable={false}>
                                            <WarningRoundedIcon/>
                                            <h2>Você possui {Object.keys(metricsByAds).length} anúncios ativos com UTMs incorretas</h2>
                                        </S.UtmLoaded>
                                    ) : null
                                )
                            )}

                            {!isLoading && (tabSelected === 'Merchant') && (
                                ((!isChecked) && showSagWarning) ? (
                                    <>
                                    <S.UtmLoaded clickable={false} >
                                        <WarningRoundedIcon/>
                                        <h2  onClick={() => showArticle(9524623)}>Google Merchant com pendências, <span style={{textDecoration: 'underline', cursor: 'pointer'}}>assista ao tutorial</span>.</h2>
                                    </S.UtmLoaded>
                                    {!isLoading && (tabSelected === 'Merchant') && (
                                        showSagWarning && (!isChecked) && (
                                            <S.CheckboxArea>
                                                <TutorialCheckbox isChecked={isChecked} setIsChecked={setIsChecked}/>
                                            </S.CheckboxArea>
                                        ) 
                                    )}
                                    </>
                                ) : 
                                (
                                    <S.UtmLoadedSuccess clickable={false}>
                                        <ThumbUpAltRoundedIcon/>
                                        <h2>Seu Google Merchant está configurado corretamente.</h2>
                                    </S.UtmLoadedSuccess>
                                )
                            )}

                    {isLoading && (
                        <S.UtmLoadedRef>
                            <S.RotatingIcon/>
                            <h2>Sincronizando UTMs</h2>
                        </S.UtmLoadedRef>
                    )}
                            
                            <S.UpdatedAt>

                                {tabSelected !== 'Merchant' ? (
                                    <>
                                        <SyncRoundedIcon style={{position: 'relative', left: '10px'}} />
                                        <p>
                                            {tabSelected === 'Google' ? updatedAtGoogle :
                                            tabSelected === 'Merchant' ? 'Atualiza em breve' :
                                            updatedAtFbTtk}
                                        </p>

                                        {tabSelected === 'Merchant' && 
                                        <Tooltip title="Após a atualização do Merchant devemos receber uma nova venda de campanhas Google Shopping ou Performance Max para verificar o status da configuração." placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '9px',padding: '11px',color: 'white',textWrap: 'wrap',zIndex: 999999}},
                                        }}>
                                            <InfoRoundedIcon />
                                        </Tooltip>
                                        }
                                    </>
                                ) :
                                 
                                <>
                                {showSagWarning ? ( null ) : ( <SyncRoundedIcon style={{position: 'relative', left: '10px'}} /> )}
                                <p>
                                    {showSagWarning ? '' : 'Atualiza em breve'}                            
                                </p>

                                {tabSelected === 'Merchant' && !showSagWarning && 
                                <Tooltip title="Após a atualização do Merchant devemos receber uma nova venda de campanhas Google Shopping ou Performance Max para verificar o status da configuração." placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '9px',padding: '11px',color: 'white',textWrap: 'wrap',zIndex: 999999}},
                                }}>
                                    <InfoRoundedIcon />
                                </Tooltip>
                                }
                            </>
                                }
                            </S.UpdatedAt>

                        </S.ContentArea>

                    </S.InfosContainer>
                    <S.ContentArea>

                        <S.ContentTitle>Tutoriais
                        <span>Aprenda a configurar {tabSelected === 'Merchant' ? 'seu Merchant' : 'suas UTMs'}.</span>

                        </S.ContentTitle>

                        {tabSelected === 'Merchant' || tabSelected === 'Facebook' || tabSelected === 'Tiktok' ? (
                             <S.TutorialArea>
                             <ButtonApp  
                                         border=''
                                         width="16rem"
                                         height="1.5rem"
                                         borderRadius='50px'
                                         color="#1B626A"
                                         iconWidth='14px'
                                         background="#CDDDDF"
                                         icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                                         onClick={() => window.open('https://youtu.be/qsvFFXQZbck?feature=shared', '_blank')}
                                         text="Como otimizar minhas campanhas?"
     
                                     />
                                         <ButtonApp  
                                         border=''
                                         width={tabSelected === 'Merchant' ?  "18rem" : "14.5rem"}
                                         height="1.5rem"
                                         borderRadius='50px'
                                         iconWidth='14px'
                                         color="#1B626A"
                                         background="#CDDDDF"
                                         icon={<HelpRoundedIcon  style={{width: '16px'}}/>}
                                         onClick={() => {showArticle(tabSelected === 'Merchant' ? 9524623 : 9062698)}}
                                         text={tabSelected === 'Merchant' ?  "Como configurar meu Google Merchant?" : "Como configurar minhas UTMs?"}
     
                                     />
                             </S.TutorialArea>
                        ) : (
                            <>
                        <S.TutorialArea>
                        <ButtonApp  
                                    border=''
                                    width="16rem"
                                    height="1.5rem"
                                    borderRadius='50px'
                                    color="#1B626A"
                                    iconWidth='14px'
                                    background="#CDDDDF"
                                    icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                                    onClick={() => window.open('https://youtu.be/qsvFFXQZbck?feature=shared', '_blank')}
                                    text="Como otimizar minhas campanhas?"

                                />
                                    <ButtonApp  
                                    border=''
                                    width="14.5rem"
                                    height="1.5rem"
                                    borderRadius='50px'
                                    iconWidth='14px'
                                    color="#1B626A"
                                    background="#CDDDDF"
                                    icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                                    onClick={() => {showArticle(9062698)}}
                                    text="Como configurar minhas UTMs?"

                                />
                        </S.TutorialArea>

                        <p>Ou clique no botão para</p>

                        <S.OtherUtmInfo>
                        <div style={{ position: 'relative' }}>
                            <S.ContainerPopup showConfigurePopup={showConfigurePopup} ref={popupRef}>
                                <S.PopupTitle>Importante</S.PopupTitle>
                                <S.PopupSubtitle>Esta configuração será aplicada para todas as contas de anúncios ativas do Google ads.</S.PopupSubtitle>
                                <S.ButtonPopup onClick={handleUtmConfiguration}>
                                    Prosseguir <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                                </S.ButtonPopup>
                            </S.ContainerPopup>
                            {showSuccessPopup && (
                                <S.SuccessPopup>
                                    {accountsConfigured.length > 0 && (
                                        <S.InnerSuccessPopup>
                                        <S.FlexSettings>
                                            <p>As UTMs foram configuradas com sucesso para as contas: </p> 
                                            <Close sx={{ width: '12px', height: '12px', color: '#1e1e1e', cursor: 'pointer' }} onClick={() => {setShowSuccessPopup(false)}}/>  
                                        </S.FlexSettings>
                                        <S.AccountsList>    
                                            {accountsConfigured.map((account, index) => (
                                                <p key={index} style={{ color: '#19c27e', fontSize: '14px', margin: 0, width: 'fit-content' }}>{account}</p>
                                            ))}  
                                        </S.AccountsList> 
                                        </S.InnerSuccessPopup>
                                    )}

                                    {failedAccounts.length > 0 && (
                                        <S.InnerSuccessPopup>
                                        <S.FlexSettings>
                                        <p>Algumas contas não puderam ser configuradas automaticamente:</p>
                                        </S.FlexSettings>
                                        <S.AccountsList>
                                            {failedAccounts.map((account, index) => (
                                            <p key={index} style={{ color: '#e74c3c', fontSize: '14px', margin: 0, width: 'fit-content' }}>{account}</p>
                                            ))}
                                        </S.AccountsList>
                                        <p>Siga o <a href="#" onClick={() => showArticle(9062698)}>tutorial manual</a> ou contate o suporte.</p>
                                        </S.InnerSuccessPopup>
                                    )}
                                </S.SuccessPopup>
                            )}
                            {showErrorPopup && (
                                <S.SuccessPopup>
                                    <S.FlexSettings>
                                    <p>As UTMs não puderam ser configuradas automaticamente</p>
                                    <Close
                                        sx={{ width: '12px', height: '12px', color: '#1e1e1e', cursor: 'pointer' }}
                                        onClick={() => {
                                        setShowErrorPopup(false);
                                        }}
                                    />
                                    </S.FlexSettings>
                                    <S.AccountsList>   
                                    <p>
                                        Siga o <a href="#" onClick={() => showArticle(9062698)}>tutorial manual</a> ou contate o suporte
                                    </p>    
                                    </S.AccountsList>
                                </S.SuccessPopup>
                            )}
                            <S.TutorialButton disabled={buttonDisabled || configuringGoogle} onClick={() => {if(!buttonDisabled && !configuringGoogle) setShowConfigurePopup(true)}}>
                                {configuringGoogle && tabSelected === 'Google' ? (
                                    <S.RotatingSettingsIcon />
                                ) : (
                                    <SettingsRoundedIcon />
                                )}
                                {configuringGoogle && tabSelected === 'Google' ? 'Configurando...' : 'Configurar automaticamente'}
                            </S.TutorialButton>
                        </div>
                        </S.OtherUtmInfo>
</>
                        )}
                    </S.ContentArea>

                    {tabSelected === 'Merchant' ? (
                        null
                    ): (

                    <S.ContentArea>

                        <S.ContentTitle>Código UTM
                        <span>Copie o código e aplique nos anúncios com problemas.</span>

                        </S.ContentTitle>

                        {/* <p>Copie o código abaixo e aplique nos <span onClick={()=> handleClick()}>anúncios com problemas</span></p> */}
                        <S.CopyBox>
                            <S.CopyText>{copyText}</S.CopyText>
                            <S.CopyButton onClick={handleCopy}><ContentCopyIcon/><p>Copiar</p></S.CopyButton>
                            {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}
                        </S.CopyBox>
                        {/* <S.UtmTutorial onClick={() => showArticle(9062698)}>
                            <S.LinearWipe>
                                <p> <HelpOutlineRoundedIcon/> Como inserir o código nos seus anúncios</p>
                            </S.LinearWipe>
                        </S.UtmTutorial> */}
                    </S.ContentArea>

                    )}

                    
            </S.HeaderContainer>
            {/* <S.Line></S.Line> */}


            <S.Table ref={endOfPageRef}>
                <TableConfigurationUtm 
                    tabSelected={tabSelected}
                    data={dataMetrics?.data?.data}
                    isLoading={isLoading}
                />
            </S.Table>
        </S.ContainerBackground>
    );
}
