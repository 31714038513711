import { useEffect, useState } from 'react';
import { Banner } from '../../components/Banner';
import { HeaderCostOperation } from './HeaderCostOperation';

import * as S from './style';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useCreateCost, useGetCosts, useGetHistoricalCosts } from '../../services/hooks/costHooks';
import useAuth from '../../hooks/useAuth';
import { LineCostOperation } from './LineCostOperation';
import { LineCostOperationProps } from '../../@types';
import {  getCurrentCostArray, getHistoricalCostArray } from './utils';
import { createCostSchema } from '../../schemas/costSchema';
import { LineCostOperationLoading } from './LineCostOperationLoading';
import {useIntercom} from 'react-use-intercom';
import MarketingCostModal from './OperationCostModal'; 
import { uuidv4 } from '../../utils';
import { parseISO, format, compareDesc } from 'date-fns';
import { ButtonApp } from '../../components/ButtonApp';
import { trackEvent } from '../../services/segment/sendEvent'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import useAlert from '../../hooks/useAlert';
import { useCurrencyType } from '../../hooks/useCurrencyType';
import { TablePlaceholder } from '../../components/TablePlaceholder';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

export function CostOperation() {
    const [showOperationCostsModal, setOperationCostsModal] = useState<boolean>(false);
    const { user, currentCompany } = useAuth();
    const [currentTab, setCurrentTab] = useState('Entrada');
    const { currencySymbol } = useCurrencyType();
    const [costOperation, setCostOperation] = useState({
        name: '',
        type: 'aluguel',
        value: '',
        frequency: 'monthly',
        date: '',
    });

    const frequencyInitialTranslation: { [key: string]: string } = {
        monthly: 'Mensal',
        weekly: 'Semanal',
        daily: 'Diária',
        specific: 'Pontual',
    };

    const { data: historicalDataCost, isLoading: isLoadingHistoricalCost, refetch: refetchHistoricalCosts } = useGetHistoricalCosts(currentCompany);
    const { data: currentDataCost, isLoading: isLoadingCurrentData, refetch: refetchGetCosts } = useGetCosts(currentCompany);
    const { mutate: createCost, isLoading } = useCreateCost();

    const isLoadingGetData = isLoadingHistoricalCost || isLoadingCurrentData;
    const { showArticle } = useIntercom();

    const currentDataCostArray = currentDataCost?.data?.data;
    const historicalDataCostArray = historicalDataCost?.data?.data;
    //console.log('getCurrentCostArray(currentDataCostArray)',getCurrentCostArray(currentDataCostArray));
    const [currentCostArray, setCurrentCostArray] = useState<LineCostOperationProps[]>([]);
    const [historicalCostArray, setHistoricalCostArray] = useState<LineCostOperationProps[]>([]);
    const [costsDeleted, setCostsDeleted] = useState<string[]>([]);
    const { showAlert } = useAlert();

    useEffect(() => {
        trackEvent('pageview', { 
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'operational_costs'
        });
      }, [])
    

    useEffect(() => {
        if (currentDataCostArray && historicalDataCostArray) {
            const filteredCurrent = getCurrentCostArray(currentDataCostArray).filter((cost: LineCostOperationProps) => ['plataformas', 'aluguel', 'outros', 'marketing', 'alimentacao', 'contabilidade', 'funcionario', 'retirada', 'trafego', 'saque'].includes(cost.type) && !costsDeleted.includes(cost.cost_id));
            const filteredHistorical = getHistoricalCostArray(historicalDataCostArray).filter((cost: LineCostOperationProps) => ['plataformas', 'aluguel', 'outros', 'marketing', 'alimentacao', 'contabilidade', 'funcionario', 'retirada', 'trafego', 'saque'].includes(cost.type) && !costsDeleted.includes(cost.cost_id) && cost.value !== 0);
            
            setCurrentCostArray(filteredCurrent);
            setHistoricalCostArray(filteredHistorical);
        }
      }, [currentDataCostArray, historicalDataCostArray]); // Dependências do useEffect
      

    function formatValue(value: string) {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        newValue = `${currencySymbol}` + newValue;
        setCostOperation({ ...costOperation, value: newValue });
    }

    const handleDeleteCost = (cost_id: string) => {
        setCostsDeleted([...costsDeleted, cost_id]);
        setCurrentCostArray(currentCostArray.filter((cost) => cost.cost_id !== cost_id));
        setHistoricalCostArray(historicalCostArray.filter((cost) => cost.cost_id !== cost_id));
        //console.log('costsDeleted', costsDeleted);
    };

    function onUpdate(body: LineCostOperationProps) {
        const { name, value, type, frequency, date, cost_id, status } = body;
        const updatedCostArray = currentCostArray.map((cost) => {
            if (cost.cost_id === cost_id) {
                return { name, value, type, frequency, date, cost_id, status };
            }
            return cost;
        });
        const updatedHistoricalCostArray = historicalCostArray.map((cost) => {
            if (cost.cost_id === cost_id) {
                return { name, value, type, frequency, date, cost_id, status };
            }
            return cost;
        });
        setCurrentCostArray(updatedCostArray);
        setHistoricalCostArray(updatedHistoricalCostArray);
    }

    return (
        <S.Container>
             { showOperationCostsModal && (
                <MarketingCostModal
                    onClose={() => setOperationCostsModal(false)}
                    currentCostArray={currentCostArray}
                    historicalCostArray={historicalCostArray}
                    setCurrentCostArray={setCurrentCostArray}
                    setHistoricalCostArray={setHistoricalCostArray}
                />
            )}

            <S.Title><h2>Custos de operação</h2>
                <p>Atualize e gerencie facilmente os custos da sua operação.</p>
            </S.Title>

             <ButtonApp  
                border=''
                width="20rem"
                height="1.5rem"
                borderRadius='50px'
                iconWidth='14px'
                fontSize='0.8rem'
                color="#1B626A"
                background="#CDDDDF"
                icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                onClick={() => {showArticle(8726303)}}
                text="Como funcionam os custos de operação?"
            />
            {/* <Banner
                title="Custos Adicionais"
                subtitle={['Atualmente os custos listados provém da sua plataforma de venda.', 'Caso seja necessário, você pode editar manualmente abaixo.']}
                textButton="Como funciona?"
                iconButton={<PlayCircleOutlineIcon sx={{ width: '22px', height: '22px' }} />}
                onClick={() => {showArticle(8726303)}}
            /> */}
            <S.InnerContainer>

                <S.CostContainer>
                    <S.HeaderTable>
                    <div>
                        <S.EntryTab active={currentTab === 'Entrada'} onClick={() => setCurrentTab('Entrada')}>
                            Entrada
                        </S.EntryTab>
                        <S.HistoricTab active={currentTab === 'Histórico'} onClick={() => setCurrentTab('Histórico')}>
                            Histórico
                        </S.HistoricTab>
                    </div>
                    <ButtonApp
                        border=''
                        width="12rem"
                        height="3rem"
                        color="#fff"
                        background="#19c380"
                        icon={<AddCircleRoundedIcon style={{ fontSize: 20, marginRight: 10 }}/> }
                        text="Adicionar custo"
                        fontSize='1rem'
                        isLoading={isLoading}
                        onClick={() => setOperationCostsModal(true)}/>
                        </S.HeaderTable>
                    <S.CostEntry>
                        <HeaderCostOperation tab={currentTab} />
                    </S.CostEntry>
                    {!isLoadingGetData && currentTab === 'Entrada' && (
                        <S.CostHistoric>
                          {currentCostArray && currentCostArray.length > 0 ? (
                                currentCostArray.sort((a, b) => {
                                    const dateA = parseISO(format(new Date(a.date), 'yyyy-MM-dd'));
                                    const dateB = parseISO(format(new Date(b.date), 'yyyy-MM-dd'));
                                    // Compara as datas
                                    return compareDesc(dateA, dateB);
                                }).map((currentCost: LineCostOperationProps, index: number) => {
                                    return (
                                        <LineCostOperation
                                            tab="Entrada"
                                            key={index}
                                            cost_id={currentCost?.cost_id}
                                            frequency={currentCost?.frequency}
                                            date={currentCost?.date}
                                            name={currentCost?.name}
                                            type={currentCost?.type}
                                            value={currentCost?.value}
                                            status={currentCost?.status}
                                            onDelete={handleDeleteCost}
                                            onUpdate={onUpdate}
                                        />
                                    );
                                })
                            ) : (
                            <S.ContainerPlaceholder>
                                <TablePlaceholder size='medium' showSubtitle={true} />
                            </S.ContainerPlaceholder>
                            )}
                        </S.CostHistoric>
                        
                    )}
                    {!isLoadingGetData && currentTab === 'Histórico' && (
                        <S.CostHistoric>
                            {historicalCostArray && historicalCostArray.length > 0 ? (
                                historicalCostArray?.sort((a, b) => {
                                    const dateA = parseISO(format(new Date(a.date), 'yyyy-MM-dd'));
                                    const dateB = parseISO(format(new Date(b.date), 'yyyy-MM-dd'));
                                    // Compara as datas
                                    return compareDesc(dateA, dateB);
                                  }).map((currentCost: LineCostOperationProps, index: number) => {
                                    return (
                                        <LineCostOperation
                                            tab="Histórico"
                                            key={index}
                                            cost_id={currentCost?.cost_id}
                                            frequency={currentCost?.frequency}
                                            date={currentCost?.date}
                                            name={currentCost?.name}
                                            type={currentCost?.type}
                                            value={currentCost?.value}
                                            status={currentCost?.status}
                                        />
                                    );
                                })
                                
                                ) : (
                            <S.ContainerPlaceholder>
                                <TablePlaceholder size='medium' showSubtitle={true} />
                            </S.ContainerPlaceholder>
                            )}
                        </S.CostHistoric>
                    )}
                    {isLoadingGetData && (
                        <S.CostHistoric>
                            {Array.from({ length: 8 }).map((_, index) => (
                                <LineCostOperationLoading tab={currentTab} key={index} />
                            ))}
                        </S.CostHistoric>
                    )}
                </S.CostContainer>
            </S.InnerContainer>
        </S.Container>
    );
}
