import styled from 'styled-components';

export const Container = styled.div`
    width: 27.375rem;
    height: 2.5rem;
    padding: 1rem;
    border: 1px solid var(--gray-125);
    border-radius: 7px;
    position: relative;
    margin: 0.5rem auto;
    &:hover {
        cursor: pointer;
        transition: all 0.25s ease 0s;
        transform: none;
        background-color: var(--gray-125);
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    
    @media (max-width: 1050px) {
        width: 78vw;
    }
`;

export const InfoStore = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    gap: 1rem;
    &:hover {
        cursor: pointer;
        transition: all 0.25s ease 0s;
        transform: none;
        background-color: var(--gray-125);
    }
`;

export const UserAvatar = styled.div`
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    background-color: var(--gray-175);
    display: flex;
    justify-content: center;
    align-items: center;
    .svg {
        color: var(--green);
    }
`;
export const NameStore = styled.div`
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--black);
`;

export const DataStore = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const EmailUser = styled.div`
    color: var(--gray-700);
    font-weight: 550;
    font-size: 0.6875rem;
    margin-top: 0.2rem;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 1.6rem;
    right: 1.5rem;
`;
