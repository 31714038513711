import * as S from './style';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Checkbox } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


export function LineTableTrackingLoading({ type, columnWidths }: { type: string, columnWidths: any}) {
    const isMainSource = ['facebook', 'google', 'tiktok'].includes(type);

    const nameAndUrl: { [key: string]: { name: string; url: string } } = {
        facebook: {
            name: 'Facebook Ads',
            url: 'https://storage.googleapis.com/static-images-source/facebook-icon-tracking.webp',
        },
        google: {
            name: 'Google Ads',
            url: 'https://storage.googleapis.com/static-images-source/google-icon.png',
        },
        tiktok: {
            name: 'TikTok Ads',
            url: 'https://storage.googleapis.com/static-images-source/tiktok-icon-tracking.png',
        },
        // outros: {
        //     name: 'Orgânico / Outros',
        //     url: 'https://storage.googleapis.com/static-images-source/other-source-icon.png',
        // },
        // sem_atribuicao: {
        //     name: 'Sem atribuição',
        //     url: 'https://storage.googleapis.com/static-images-source/noatribuittion-icon.png',
        // },
    };

    return (
        <>
            <S.Container>
                <S.MainColumn style={{ width: `${columnWidths}px` }} type={type} isMainSource={isMainSource}>
                <S.CheckBoxArea>                
                <Checkbox
                        checked={ false}
                        onChange={() => {}}
                        size="small"
                        sx={{
                            '&.Mui-checked': {
                                color: '#19c273',
                            },
                            cursor: 'not-allowed'
                        }}
                        disabled={true}
                    />                    
                </S.CheckBoxArea>
                    {/* {isMainSource && (
                        <S.AnalyticsButton>
                            <QueryStatsIcon sx={{ width: '16px', height: '16px' }} />
                        </S.AnalyticsButton>
                    )} */}
                    <img src={nameAndUrl[type]?.url} alt="Logo" /> <S.UrlLinkClick>{nameAndUrl[type]?.name}</S.UrlLinkClick>
                </S.MainColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                {/* <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn> */}
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
                <S.DefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.DefaultColumn>
            </S.Container>
        </>
    );
}
