import { useState, useEffect } from 'react';
import * as S from './style';
import Lottie from 'lottie-react';
import loadingSolomon from '../../assets/images/solomon-loading.json';
import loadingSpin from '../../assets/images/solomon-spin.json';

export function Loading() {
    // const [isSolomonLoadingComplete, setIsSolomonLoadingComplete] = useState(false);

    // useEffect(() => {
    //     // Defina o tempo de duração da animação `loadingSolomon` aqui
    //     // Substitua o valor 3000 (3 segundos) pela duração real da animação
    //     const animationDuration = 2000; // Duração em milissegundos

    //     const timer = setTimeout(() => {
    //         setIsSolomonLoadingComplete(true);
    //     }, animationDuration);

    //     return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
    // }, []);

    return (
        <S.Container>
                <Lottie style={{width: '22rem'}}  animationData={loadingSpin} loop={true} />
            <S.Text>Carregando...</S.Text>
        </S.Container>
    );
}
