import { TemplateModal } from '../index';
import * as S from './style';
import { ButtonApp } from '../../../../components/ButtonApp';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import { ModalLpFormProps } from '../../../../@types';
import { storageOnboardingCurrentCompanyGet} from '../../../../storage/storageOnboarding';
import { useSubmitLandingPage } from '../../../../services/hooks/landingPageHooks';
import useAuth from '../../../../hooks/useAuth';
import { storageOnboardingIntegrateLpFormSave } from '../../../../storage/storageOnboarding';
import useAlert from '../../../../hooks/useAlert';

export function ModalLpForm({ setShowPopupLpForm }: ModalLpFormProps) {
    const urlLogo = 'https://cdn-icons-png.flaticon.com/512/5339/5339184.png';
    const baseColor = 'var(--blue)';
    const { mutate: SubmitLandingPage, isLoading } = useSubmitLandingPage();
    const [platform, setPlatform] = useState('');
    const [lpUrl, setLpUrl] = useState('');
    const { showAlert } = useAlert();
    

    const handleIntegrate = () => {
        //console.log('Integrar', platform, lpUrl)
        const body = {
            company_id: storageOnboardingCurrentCompanyGet(),
            have_lp: true,
            lp_platform: platform,
            lp_url: lpUrl,
        };
        
        SubmitLandingPage(body, {
            onSuccess: () => {
            showAlert('Landing Page adicionada com sucesso!', 'success', 5000, true);
            storageOnboardingIntegrateLpFormSave();
            setShowPopupLpForm(false);
            },
            onError: (error) => {
                // Ação após erro, e.g., mostrar mensagem de erro
                console.error(error);
            }
        });
    };

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Dom Pagamentos'}
                title={'Landing Page'}
                subtitle={'Precisamos de algumas informações sobre sua landing page.'}
                statusConnection={'Conectado'}
                lastConnection={'Última conexão: 10/09/2021'}
            />
            
                    <S.ContentTypePaymentInformation>
                        <label>Informe a plataforma:</label>
                        <input
                            id='{inputId}'
                            name='{inputId}'
                            placeholder='Wordpress, etc..'
                            type='text'
                            autoComplete="off"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                        />
                            <label>Nos dê um exemplo da sua Landing Page:</label>
                            <input
                                id='{inputId}'
                                name='{inputId}'
                                placeholder='https://lp,lojateste.com.br/'
                                type='text'
                                autoComplete="off"
                                value={lpUrl}
                                onChange={(e) => setLpUrl(e.target.value)}
                            />
                    </S.ContentTypePaymentInformation>
               

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    text="Salvar"
                    borderRadius='8px'
                    isLoading={isLoading}
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
