import * as S from './style';
import CircleIcon from '@mui/icons-material/Circle';
import { useGetCheckoutURL } from '../../../services/hooks/integrationHooks';
import { storagePlaceholderCompanyGet } from '../../../storage/storageCompanyToOnboard';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
// import { useCurrencyType } from '../../../hooks/useCurrencyType';

export function CardPlan({type, placeholderExist, planContext}: {type: string, placeholderExist: boolean, planContext: string}){

    const [redirect, setRedirect] = useState(false);
    const [urlMonthly, setUrlMonthly] = useState('');
    const [urlSemiannual, setUrlSemiannual] = useState('');
    const [urlMonthlyRenew, setUrlMonthlyRenew] = useState('');
    const [urlSemiannualRenew, setUrlSemiannualRenew] = useState('');
    const {currentCompany} = useAuth();
    // const {currencySymbol} = useCurrencyType();

    // Utiliza o hook useGetCheckoutURL com a opção enabled para controlar a execução baseada na existência do placeholder
    const {
        data: dataMonthly,
        error: errorMonthly,
        isLoading: isLoadingMonthly,
    } = useGetCheckoutURL(storagePlaceholderCompanyGet(), 'monthly', placeholderExist);

    const {
        data: dataSemiannual,
        error: errorSemiannual,
        isLoading: isLoadingSemiannual,
    } = useGetCheckoutURL(storagePlaceholderCompanyGet(), 'semiannual', placeholderExist);

    //context=renew 
    const {
        data: dataMonthlyRenew,
        error: errorMonthlyRenew,
        isLoading: isLoadingMonthlyRenew,
    } = useGetCheckoutURL(currentCompany, 'monthly', (planContext === 'renew'), 'renew');

    const {
        data: dataSemiannualRenew,
        error: errorSemiannualRenew,
        isLoading: isLoadingSemiannualRenew,
    } = useGetCheckoutURL(currentCompany, 'semiannual', (planContext === 'renew'), 'renew');

    useEffect(() => {
        // Certifica-se de que as buscas estão completas e não têm erros antes de setar as URLs
        if (!isLoadingMonthly && !errorMonthly && dataMonthly) {
            setUrlMonthly(dataMonthly?.data?.data);
        }
        if (!isLoadingSemiannual && !errorSemiannual && dataSemiannual) {
            setUrlSemiannual(dataSemiannual?.data?.data);
        }
    }, [dataMonthly, dataSemiannual, errorMonthly, errorSemiannual, isLoadingMonthly, isLoadingSemiannual]);

    useEffect(() => {
        // Certifica-se de que as buscas estão completas e não têm erros antes de setar as URLs
        if (!isLoadingMonthlyRenew && !errorMonthlyRenew  && dataMonthlyRenew ) {
            setUrlMonthlyRenew (dataMonthlyRenew ?.data?.data);
        }
        if (!isLoadingSemiannualRenew  && !errorSemiannualRenew  && dataSemiannualRenew ) {
            setUrlSemiannualRenew (dataSemiannualRenew ?.data?.data);
        }
    }, [dataMonthlyRenew , dataSemiannualRenew , errorMonthlyRenew , errorSemiannualRenew , isLoadingMonthlyRenew , isLoadingSemiannualRenew ]);
    

    
    useEffect(() => {
        if (redirect) {
            //console.log('redirect');
            if (type === 'mensal') {
                //console.log('mensal');
                if(planContext === 'renew' && urlMonthlyRenew){
                    window.location.href = urlMonthlyRenew;
                }else if(urlMonthly){
                    window.location.href = urlMonthly;
                }
            } else if (type === 'semestral') {
                //console.log('semestral');
                if(planContext === 'renew' && urlSemiannualRenew){
                    window.location.href = urlSemiannualRenew;
                }else if(urlSemiannual){
                    window.location.href = urlSemiannual;
                }
            }
        }
    }, [redirect, type, urlMonthly, urlSemiannual,urlMonthlyRenew, urlSemiannualRenew ]); // Dependências do useEffect
    
            
    return(
        <S.Container type={type}>
            {type !== 'mensal' && <S.Ballon>
                {'Maior desconto'}
            </S.Ballon>}
            <S.Title>
                Plano {type === 'mensal' ? 'Mensal' : 'Semestral'}
            </S.Title>
            <S.Price>
            R$ <span>{type === 'mensal' ? '197' : '147'}</span>/mês
            </S.Price>
            {type !== 'mensal' && <S.PriceDescount><PriceIcon />{'25%'} OFF <span>todo mês da assinatura</span></S.PriceDescount>}
            <S.Description type={type}>
                <span><Icon />Traqueamento:</span>
                <S.Item><CircleIcon />Faceook</S.Item>
                <S.Item><CircleIcon />Tiktok</S.Item>
                <S.Item><CircleIcon />Google</S.Item>
                <span><Icon />Dashboard Financeiro</span>
                <span><Icon />Suporte 24/7</span>
                <span><Icon />Análise de produtos</span>
                <span><Icon />Análise de clientes</span>
            </S.Description>

            <S.Button onClick={() => {setRedirect(true)}}>
                {redirect && <span>Redirecionando...</span>}
                {!redirect && <span>Assinar</span>}
            </S.Button>
        
        </S.Container>
    )
}

function Icon(){
    return(
<svg  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>

    )
}

function PriceIcon(){
    return(
        <svg   viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"></path></svg>
    )
}

