import styled, { keyframes, css } from 'styled-components';


type ContainerProps = {
    border: string;
    borderRadius?: any;
    width: string;
    height: string;
    background: string;
    hoverBackground?: string;
    isLoading?: boolean;
    disabled?: boolean;
};

type ContentProps = {
    color: string;
    iconWidth?: string;
    isLoading?: boolean;
};

const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(-1);
    }
    to {
        transform: rotate(360deg) scaleX(-1);
    }
`;

export const Container = styled.button<ContainerProps>`
    // box-shadow: rgb(12, 23, 24, 0.2) 0px 9px 25px -9px;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: ${(props) => props.borderRadius || '12px'};
    overflow: hidden;
    border: ${(props) => props.border};
    cursor: pointer;
    background-color: ${(props) => props.background};
    transition: all 250ms;
    &:disabled{
        background-color: var(--gray-250);
        cursor: not-allowed;
    }
    &:hover {
        box-shadow: rgb(12, 23, 24, 0.05) 0px 9px 25px -9px;
        background-color: ${(props) => props.hoverBackground};
        transform: translateY(3px);
        transition: all 250ms;
    }
    
    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;

export const Content = styled.div<ContentProps>`
    color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    img {
        width: 1.1rem;
        height: 1.1rem;
    }
    svg {
        fill: ${(props) => props.color};
        ${(props) => (props.isLoading ? animationStyles : '')};
    }
`;

type TextProps = {
    fontSize: string;
    lineHeight: string;
    fontWeight: string;
};

export const Text = styled.div<TextProps>`
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
    line-height: ${(props) => props.lineHeight};
`;

