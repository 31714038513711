// AlertComponent.tsx
import useAlert from '../../hooks/useAlert';
import * as S from './style';

export function AlertComponent() {
    const { alert, showAlert } = useAlert();

    if (!alert.open) return null;

    const handleClose = () => showAlert('', 'success', 1); // Fecha o alerta (1ms)

    return (
        <S.AlertContainer alertType={alert.alertType}>            
            {alert.alertType === 'loading' && <S.LoadingIcon />}
            {alert.alertType === 'success' && <S.SuccessIcon />}
            {alert.alertType === 'neutral' && <S.SuccessIcon />}
            {alert.alertType === 'error' && <S.ErrorIcon />}
            <S.Message>{alert.message}</S.Message>
            {alert.showCloseButton && (
                <S.CloseButton onClick={handleClose}>
                    ×
                </S.CloseButton>
            )}
        </S.AlertContainer>
    );
};
