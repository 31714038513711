import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 5rem;
  padding: 16px 16px 0px 16px;
  max-width: 750px;
  font-weight: 500;
  background-color: white;
  font-family: Arial, sans-serif;
  `;

export const Section = styled.div`
  background: #F4F4F4;
  border-radius: 10px;
  padding: 12px;
  max-width: 500px;
  margin-bottom: 0.5rem;
`;

export const SectionHeader = styled.h3`
  font-size: 0.8rem;
  font-weight: 550;
  margin-bottom: 16px;
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 14px;
  

   svg {
    fill: #19c27e;
    padding: 4px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 500px;
    }
`;

export const InputGroup = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

export const InputGroupMarkup = styled.div`
display: flex;
margin-top: -10px;
flex-direction: row;
justify-content: space-between;
align-items: center;

svg {
    width: 12px;
    
    }
`;

export const InputGroupOperation = styled.div`
display: flex;
margin-top: -10px;
margin-bottom: 16px;
flex-direction: row;
align-items: center;
gap: 16px;
justify-content: flex-start;

P {
  margin: 0;
  font-size: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50px;
}

svg {
    width: 14px;
    
    }
`;

export const InputGroupProduct = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0.2rem;
svg {
    width: 12px;
    
    }
`;

export const InputLabelProduct = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #647577;
  font-size: 0.7rem;

`;

export const InputLabelMarkup = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #647577;
  font-size: 0.7rem;
`;

export const InputLabel = styled.label`
  display: block;
  color: #647577;
  font-size: 0.7rem;
`;

export const InputProduct = styled.div`
    font-size: 1.3rem;
    color: var(--green);
    font-weight: 550;
    transition: all ease-in-out 250ms;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;

    span {
    font-size: 0.8rem;
    }
`;

export const Input = styled.input`
        font-size: 1rem;
        color: var(--black);
        font-weight: 550;
        background-color: white;
        width: 6.5rem;
        height: 1.5rem;
        border-radius: 8px;
        margin-top: 5px;
        padding: 0.3rem;
        transition: all ease-in-out 250ms;
        border: 1px solid white;

        &:focus,
        &:hover {
            background-color: white;
            border: 1px solid #C2E1D5;
            outline: none;
            transition: all ease-in-out 250ms;
        }
`;

export const ResultContainer = styled.div`
  background: white;
  border-radius: 10px;
`;

export const ResultHeader = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #647577;
  font-weight: 500;
`;

export const ResultItemProfit = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 0px;
  gap: 1rem;
  max-width: 310px;
  border: 1px solid #19c27e;
  padding: 8px;
  width: 100%;
  border-radius: 10px;

  svg {
    fill: white;
    padding: 8px;
    background-color: #19C27E;
    border-radius: 50%;
    }
`;

export const ResultLabelProfit = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  p {
    font-size: 0.8rem;
    color: #647577;
    margin: 0;
    }

`

export const ResultValueProfit = styled.div`
  display: flex;
   align-items: baseline;

h2 {
  font-size: 2rem;
   font-weight: 550;
   color: #19c27e;
   gap: 0.4rem;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   max-width: 220px;
   text-wrap: nowrap;
}
  p {
    font-size: 1rem;
    color: #19c27e;
    margin: 0;
    
  }
`;

export const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:start;
  align-items: start;
`;

export const ResultLabel = styled.span`
  font-size: 0.8rem;
  text-wrap: nowrap;
  color: #647577;
`;

export const ResultValue = styled.div`
  display: flex;
  align-items: baseline;

  h2 {
    font-size: 1.5rem;
    color: var(--black);
    font-weight: 550;
    max-width: 100px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  p {
    font-weight: 550;
    color: var(--black);
    font-size: 1.2rem;
    }
`;

export const SimulationContainer = styled.div`
  background: white;
  margin-top: 1rem;
`;

export const SimulationHeader = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0px;
  display: flex;
  color: #647577;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

export const SimulationItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  `;

export const SimulationLabel = styled.span`
  font-size: 1.5rem;
  font-weight: 550;
  color: var(--black);

  p {
    font-size: 0.7rem;
    margin: 0;
    padding-left: 1.8rem;
    padding-right: 0.4rem;
    padding-top: 2rem;
    padding-bottom: 0.8rem;
  }

  span {
    font-size: 0.8rem;
    font-weight: 500;
    color: #647577;
    }
`;

export const SimulationValue = styled.span`
  font-size: 1.5rem;
  color: #19c27e;
  font-weight: 550;
  max-width: 155px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


   span {
    font-size: 0.8rem;
    font-weight: 500;
    color: #19c27e;
    }
`;


export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
`

export const FlexInputs = styled.div`
    display: flex;
    align-items: center;
    gap: 3rem;
`

export const ProductPriceContainer = styled.div`
    display: flex;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #19c27e;
    margin-bottom: 0.5rem;
`

export const Line = styled.div`
  height: 30px;
  width: 1px;
  background-color: #ccc;
`

export const HLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #E7E7E7;
  margin-top: 1rem;

`

export const FlexSimulation = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
`