import { AdSyncInformation } from "../../../../../../@types";
import { isNull } from "../../../../../../utils";
import { OrderedMap } from 'immutable';
import { AdLineInfo } from "../AdModalTable/LineAdModal";

export function getSyncCampaign (data: any, product_id: string) {
    
    const metricsByAds = [];
        
    for (const campaign in data) {
        const campaign_id = data?.[campaign]?.campaign_id;
        const campaign_name = data?.[campaign]?.campaign_name;
        const channel = data?.[campaign]?.channel;
        const company_id = data?.[campaign]?.company_id;
        const linked_product_id = data?.[campaign]?.linked_product_id;
        const linked_product_name = data?.[campaign]?.linked_product_name;
        const rightCheck = (data?.[campaign]?.linked_product_id !== null && data?.[campaign]?.linked_product_name !== null) && (data?.[campaign]?.linked_product_id !== '' && data?.[campaign]?.linked_product_name !== '') && data?.[campaign]?.linked_product_id !== product_id 
        
        if ((data?.[campaign]?.linked_product_id !== null && data?.[campaign]?.linked_product_name !== null) && (data?.[campaign]?.linked_product_id !== '' && data?.[campaign]?.linked_product_name !== '') && data?.[campaign]?.linked_product_id !== product_id ) {
            const leftCheck = true;
            metricsByAds.push({
                campaign_id,
                campaign_name,
                channel,
                company_id,
                linked_product_id,
                linked_product_name,
                leftCheck,
                rightCheck,
            });
        } else {
            const leftCheck = false;
            metricsByAds.push({
                campaign_id,
                campaign_name,
                channel,
                company_id,
                linked_product_id,
                linked_product_name,
                leftCheck,
                rightCheck,
            });
        }
    }
    
    return metricsByAds;
}

export function getSyncAds (data: any, product_id: string): OrderedMap<string, AdLineInfo> {

    let ads = OrderedMap<string, AdLineInfo>();
        
    for (const ad in data) {
        const ad_id = data?.[ad]?.ad_id;
        const ad_name = data?.[ad]?.ad_name;
        const adset_id = data?.[ad]?.adset_id;
        const adset_name = data?.[ad]?.adset_name;
        const campaign_id = data?.[ad]?.campaign_id;
        const campaign_name = data?.[ad]?.campaign_name;
        const channel = data?.[ad]?.channel;

        const sync = !isNull(data?.[ad]?.linked_product_id) 
            && !isNull(data?.[ad]?.linked_product_name)
            && data?.[ad]?.linked_product_id === product_id;

        const info: AdLineInfo = {
            ad_id,
            ad_name,
            adset_id,
            adset_name,
            campaign_id,
            campaign_name,
            channel,
            sync,
            selected: sync,
        };

        ads = ads.set(ad_id, info);
        
    }
    
    return ads;
}

export function toApiFormat(ad: AdLineInfo, product_id: string, product_name: string, company_id: string) : AdSyncInformation {
    return {
        ad_id: ad.ad_id,
        ad_name: ad.ad_name,
        adset_id: ad.adset_id,
        adset_name: ad.adset_name,
        campaign_id: ad.campaign_id,
        campaign_name: ad.campaign_name,
        product_id,
        product_name,
        company_id,
    };
}
