import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
`;


export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.5rem;
    span {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

export const Table = styled.div`
    overflow-x: auto;
    border: 1px solid var(--gray-150);
    margin-top: 2rem;
    border-radius: 7px;
`;

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
`;

export const FlexHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

`;

export const ContainerImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: 1.5rem auto 0;
    animation: ${floating} 2s ease-in-out infinite;
    img {
        width: 18.75rem;
        height: auto;
    }
`;

// export const Title = styled.div`
//     text-align: center;
//     font-size: 1.5rem;
//     font-weight: 500;
//     line-height: 1.8rem;
//     color: var(--green);
// `;

export const Subtitle = styled.div`
    font-size: 1rem;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
`;


export const ModalContent = styled.div`
background-color: white;
padding: 8px;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;

 h2 {
    font-size: 24px;
    color: #0e1819;
    margin-bottom: 0px;
    font-weight: 500;
 }

 p {
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 32px;
 }

 
`;


export const ModalHeader = styled.div`
display: flex;
flex-direction: column;
width: 100%;
svg {
    fill: white;
    padding: 8px;
    background-color: #19c27e;
    border-radius: 50%;
}

h2 {
   font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--black);
    margin-top: 1rem;
    span {
        color: #19c27e;
    }}

p {
    margin-top: 6px;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.125rem;
    color: var(--gray-700);
    margin-bottom: 16px;}
`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
`