// GraphModal.tsx
import React from 'react';
import Modal from '../../../../../components/Modal/index.tsx';
import * as CS from '../../../../CostOperation/style.ts'; 
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../../../hooks/useAuth.tsx';

import { Search } from '../../../../../components/Search';
import { useGetProductsSyncCampaign } from '../../../../../services/hooks/productsHooks.ts';
import { LineCampaignModal } from './LineCampaignModal/index.tsx';
import { getSyncCampaign } from './utils/index.ts';
import { useUpdateProductSyncCampaign } from '../../../../../services/hooks/productsHooks.ts';
import { LineCampaignModalLoading } from './LineCampaignModalLoading/index.tsx';
import useAlert from '../../../../../hooks/useAlert';
import { ButtonApp } from '../../../../../components/ButtonApp/index.tsx';

interface SyncPost {
    campaign_id: string;
    campaign_name: string;
    product_name: string;
    product_id: string;
    company_id: string;
}

interface MarketingModalProps {
    onClose: () => void;
    setShowCampaign: (value: boolean) => void;
    product_id: string;
    product_name: string;
}

const CampaignModal : React.FC<MarketingModalProps> = ({ onClose, setShowCampaign, product_id, product_name }) => {

    const { currentCompany } = useAuth();
    const { showAlert } = useAlert();

    const [filter, setFilter] = useState('');
    const [syncPostList, setSyncPostList] = useState<SyncPost[]>([]);

    const data = useGetProductsSyncCampaign(currentCompany);
    const isLoadingSyncCampaign = data.isLoading;
    const syncCampaign = getSyncCampaign(data?.data?.data?.data, product_id);
    const { mutate: updateProductSyncCampaignMutate, isLoading: isLoadingUpdateProductMarketingCost } = useUpdateProductSyncCampaign();

    function handleSyncCampaign() {
        try {
            if (syncPostList.length > 0) {
                updateProductSyncCampaignMutate({
                    campaigns: syncPostList, company_id: currentCompany
                });
                //console.log('Custo atualizado com sucesso!');
                showAlert('Campanha adicionada com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 30000, true);
            }
            setShowCampaign(false);
        } catch (error) {
            console.error('Erro ao atualizar custos:', error);
            setShowCampaign(false);
        }
    }

    const filtredSyncCampaign = syncCampaign.filter((syncCampaign) => syncCampaign?.campaign_name?.toLowerCase().includes(filter.toLowerCase()));




    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage
                    src="https://storage.googleapis.com/static-images-source/management-cost.png"
                    alt="Shopify"
                />
                <S.MarketingTitle>
                    Sincronize seu produto à <S.MarketingHighlight>Campanhas Específicas</S.MarketingHighlight> 
                    <S.MarketingParagraph>Vincule seus produtos a campanhas específicas, assegurando uma direção eficiente e eficaz de suas ofertas.</S.MarketingParagraph>
                </S.MarketingTitle>
                <S.MarketingLine/>
                <S.MarketingTitle>
                    <S.MarketingHighlight>{product_name}</S.MarketingHighlight>
                </S.MarketingTitle>

                <S.Section>
                    <Search width="20rem" placeholder="Buscar campanha..." value={filter} setValue={setFilter} />
                </S.Section>

                <S.InnerContainer>
                    <S.Header>
                        <S.DefaultColumnCheck>
                            Selecionar
                        </S.DefaultColumnCheck>
                        <S.DefaultColumnCheck>
                            Nome do Canal
                        </S.DefaultColumnCheck>
                        <S.DefaultColumn>
                            Campanha
                        </S.DefaultColumn>
                        <S.DefaultColumnCheck>
                            Já Sincronizada
                        </S.DefaultColumnCheck>
                    </S.Header>

                    <S.Lines>
                        {!isLoadingSyncCampaign && filtredSyncCampaign && filtredSyncCampaign.length > 0 && filtredSyncCampaign.map((product, index) => {
                            return <LineCampaignModal key={index} syncCampaign={product} product_id={product_id} product_name={product_name} syncPost={syncPostList} setSyncPost={setSyncPostList} />;
                        })}
                        {isLoadingSyncCampaign && Array.from({ length: 5 }).map((_, index) => <LineCampaignModalLoading key={index}/> )}
                    </S.Lines>

                </S.InnerContainer>
                
                <CS.ButtonContainer>
                    <ButtonApp border='' width="9.5rem" height="2.5rem" background="#19c27e"   color="#fff"   onClick={handleSyncCampaign} isLoading={isLoadingUpdateProductMarketingCost} text="Salvar alterações"/>
                </CS.ButtonContainer>
                
            </S.ModalContent>
        </Modal>
    );
};

export default CampaignModal;
