import { calculateTaxValue, subArrays, sumArrays } from '../../../utils';
import { parseISO, startOfDay, addDays, differenceInCalendarDays, format } from 'date-fns';

export function getMetrics(responseData: any, taxesRate: any, orders?: any, shipping?: any, rootTaxMetric?: any) {
    rootTaxMetric = rootTaxMetric || 0;
    const xAxis = responseData?.additional_cost_chart['x_axis'];
    const { totalMarketingCostChart, faceMarketingCostChart, tiktokMarketingCostChart, googleMarketingCostChart, manualMarketingCostChart } = getChartMetricsMarketing(responseData);
    const { approvedRevenueChart } = getChartMetricsOrdersAndRevenue(responseData);
    const { shipping_cost } = getMetricsShipping(orders, shipping);  // Calcula o custo de frete
    const { totalCostChart, gatewayValueChart, checkoutValueChart, totalProductCostChart } = getChartMetricsCosts(responseData, rootTaxMetric);
    
    const profitChart = subArrays(approvedRevenueChart, totalCostChart);

    totalCostChart ? totalCostChart : [];
    profitChart ? profitChart : [];
    shipping_cost ? shipping_cost : 0;

    const profitChartAdjustedForShipping = profitChart.map((profit, index) => profit - shipping_cost); // Ajusta o lucro pelo custo de frete

    const taxSeriesChart = [];

    const calculationRule = taxesRate?.calculation_rule;
    const aliquoteRate = taxesRate && !isNaN(Number(taxesRate?.aliquote_rate)) ? taxesRate?.aliquote_rate : 0;

    for (let i = 0; i < approvedRevenueChart?.length; i++) {
        const fat = profitChartAdjustedForShipping[i] - checkoutValueChart[i] - gatewayValueChart[i];  // Usa o lucro ajustado
        const tax_value = calculateTaxValue(calculationRule, aliquoteRate, fat, totalProductCostChart[i], totalMarketingCostChart[i]);

        taxSeriesChart.push(tax_value);
    }

    const profitLiquidChart = subArrays(profitChartAdjustedForShipping, taxSeriesChart);
    const taxAndFeesChart = sumArrays(taxSeriesChart, gatewayValueChart, checkoutValueChart);

    // Inclui o custo do frete no totalCostChart para o cálculo do totalSpendChartData
    const totalCostChartWithShipping = totalCostChart.map((cost, index) => cost + shipping_cost);

    // Atualiza o totalSpendChartData para incluir o custo do frete
    //console.log('totalCostChartWithShipping', totalCostChartWithShipping, 'taxSeriesChart', taxSeriesChart);

    const totalSpendChartData = sumArrays(totalCostChartWithShipping, taxSeriesChart);


    return { xAxis, taxSeriesChart, profitLiquidChart, totalMarketingCostChart, taxAndFeesChart, totalSpendChartData, faceMarketingCostChart, tiktokMarketingCostChart, googleMarketingCostChart, manualMarketingCostChart };
}


export function getSubMetrics(responseData: any, taxesRate: any, orders: any, shipping: any) {

    // a partir do metric e do sub_metric absolut de cada uma das métricas calcula o profit atual e o profit anterior
    const approvedRevenue = responseData?.approved_revenue_metric?.approved;
    const approvedRevenuePrevious = responseData?.approved_revenue_metric?.approved - responseData?.approved_revenue_sub_metrics?.absolut?.approved;

    const marketingCost = responseData?.marketing_cost_metric?.total;
    const marketingCostPrevious = responseData?.marketing_cost_metric?.total - responseData?.marketing_cost_sub_metrics?.absolut?.total;
    const productsCost = responseData?.products_cost_metric;
    const productsCostPrevious = responseData?.products_cost_metric - responseData?.products_cost_sub_metrics?.absolut;
    const { shipping_cost } = getMetricsShipping(orders, shipping);

    const gatewayCost = responseData?.gateway_cost_metric;
    const gatewayCostPrevious = responseData?.gateway_cost_metric - responseData?.gateway_cost_sub_metrics?.absolut;

    const checkoutCost = responseData?.checkout_cost_metric;
    const checkoutCostPrevious = responseData?.checkout_cost_metric - responseData?.checkout_cost_sub_metrics?.absolut;

    const calculationRule = taxesRate?.calculation_rule;
    const aliquoteRate = taxesRate && !isNaN(Number(taxesRate?.aliquote_rate)) ? taxesRate?.aliquote_rate : 0;
    // //console.log('calculationRule', calculationRule);
    // //console.log('aliquoteRate', aliquoteRate);
    const taxCost = calculateTaxValue(calculationRule, aliquoteRate, approvedRevenue - checkoutCost - gatewayCost, productsCost+shipping_cost, marketingCost);
    // //console.log('taxCost', taxCost);
    const taxCostPrevious = calculateTaxValue(calculationRule, aliquoteRate, approvedRevenuePrevious - checkoutCostPrevious - gatewayCostPrevious, productsCostPrevious, marketingCostPrevious);

    const subMetricsTax = taxCostPrevious ? (taxCost - taxCostPrevious) / taxCostPrevious * 100 : 0;
    const subMetricsMarketingCost = (marketingCost - marketingCostPrevious) / marketingCostPrevious * 100;

    const subMetricsTaxAndFees = (taxCostPrevious + gatewayCostPrevious + checkoutCostPrevious) ? ((taxCost + gatewayCost + checkoutCost) - (taxCostPrevious + gatewayCostPrevious + checkoutCostPrevious)) / (taxCostPrevious + gatewayCostPrevious + checkoutCostPrevious) * 100 : 0; 

    // //console.log('taxxxxx',taxCost, gatewayCost, checkoutCost);
    return { subMetricsTax, subMetricsMarketingCost, metricTax: taxCost, metricGatewayCost: gatewayCost, metricCheckoutCost: checkoutCost,  subMetricsTaxAndFees, metricTaxAndFees : (taxCost + gatewayCost + checkoutCost)};
}


export function getChartMetricsMarketing(responseData: any) {
    const faceMarketingCostChart = responseData?.marketing_cost_chart['facebook_ads']['y_axis'];
    const tiktokMarketingCostChart = responseData?.marketing_cost_chart['tiktok_ads']['y_axis'];
    const googleMarketingCostChart = responseData?.marketing_cost_chart['google_ads']['y_axis'];
    const manualMarketingCostChart = responseData?.marketing_cost_chart['manual_ads']['y_axis'];

    const totalMarketingCostChart = sumArrays(faceMarketingCostChart, tiktokMarketingCostChart, googleMarketingCostChart, manualMarketingCostChart);

    return { totalMarketingCostChart, faceMarketingCostChart, tiktokMarketingCostChart, googleMarketingCostChart, manualMarketingCostChart };
}

export function getChartMetricsCosts(responseData: any, rootTaxMetric?: any) {

    const { totalMarketingCostChart } = getChartMetricsMarketing(responseData);
    const totalProductCostChartRoot = responseData?.products_cost_chart['y_axis'] || [];
    const totalProductCostChart =  totalProductCostChartRoot.map((cost, index) => cost + rootTaxMetric);
    const gatewayValueChart = responseData?.gateway_cost_chart['y_axis'];
    const checkoutValueChart = responseData?.checkout_cost_chart['y_axis'];
    const additionalCostChart = responseData?.additional_cost_chart['y_axis'];
    
    const totalCostChart = sumArrays(totalMarketingCostChart, totalProductCostChart, gatewayValueChart, checkoutValueChart, additionalCostChart);

    return {
        totalCostChart,
        totalMarketingCostChart,
        totalProductCostChart,
        gatewayValueChart,
        checkoutValueChart,
        additionalCostChart,
    };
}

export function getChartMetricsOrdersAndRevenue(responseData: any) {
    const approvedRevenueChart = responseData?.approved_revenue_chart?.['approved']?.['y_axis'];
    const pendingRevenueChart = responseData?.approved_revenue_chart?.['pending']?.['y_axis'];
    const cancelledRevenueChart = responseData?.approved_revenue_chart?.['cancelled']?.['y_axis'];

    const totalRevenueChart = sumArrays(approvedRevenueChart, pendingRevenueChart, cancelledRevenueChart);

    const approvedOrdersChart = responseData?.orders_chart?.['approved']?.['y_axis'];
    const pendingOrdersChart = responseData?.orders_chart?.['pending']?.['y_axis'];
    const cancelledOrdersChart = responseData?.orders_chart?.['cancelled']?.['y_axis'];

    const totalOrdersChart = sumArrays(approvedOrdersChart, pendingOrdersChart, cancelledOrdersChart);

    return {
        approvedRevenueChart,
        pendingRevenueChart,
        cancelledRevenueChart,
        totalRevenueChart,
        approvedOrdersChart,
        pendingOrdersChart,
        cancelledOrdersChart,
        totalOrdersChart,
    };
}

export function getMetricsOrdersAndRevenue(responseData: any) {
    const approvedRevenue = Number(responseData?.approved_revenue_metric?.approved);
    const pendingRevenue = Number(responseData?.approved_revenue_metric?.pending);
    const cancelledRevenue = Number(responseData?.approved_revenue_metric?.cancelled);

    const totalRevenue = approvedRevenue + pendingRevenue + cancelledRevenue;

    const approvedOrders = responseData?.orders_metric?.approved;
    const pendingOrders = responseData?.orders_metric?.pending;
    const cancelledOrders = responseData?.orders_metric?.cancelled;

    const totalOrders = approvedOrders + pendingOrders + cancelledOrders;

    return {
        approvedRevenue,
        pendingRevenue,
        cancelledRevenue,
        totalRevenue,
        approvedOrders,
        pendingOrders,
        cancelledOrders,
        totalOrders,
    };
}

export function getMetricsCosts(responseData: any) {
    const totalMarketingCost = responseData?.marketing_cost_metric?.total;

    return { totalMarketingCost };
}

export function getMetricsOrdersByPayments(responseData: any) {
    const cardPaymentApprovedOrders = responseData?.revenue_per_payment_approved_orders?.metric?.credit_card ?? 0;
    const cardPaymentPendingOrders = responseData?.revenue_per_payment_pending_orders?.metric?.credit_card ?? 0;
    const cardPaymentCancelledOrders = responseData?.revenue_per_payment_cancelled_orders?.metric?.credit_card ?? 0;

    const pixPaymentApprovedOrders = responseData?.revenue_per_payment_approved_orders?.metric?.pix ?? 0;
    const pixPaymentPendingOrders = responseData?.revenue_per_payment_pending_orders?.metric?.pix ?? 0;
    const pixPaymentCancelledOrders = responseData?.revenue_per_payment_cancelled_orders?.metric?.pix ?? 0;

    const billetPaymentApprovedOrders = responseData?.revenue_per_payment_approved_orders?.metric?.billet ?? 0;
    const billetPaymentPendingOrders = responseData?.revenue_per_payment_pending_orders?.metric?.billet ?? 0;
    const billetPaymentCancelledOrders = responseData?.revenue_per_payment_cancelled_orders?.metric?.billet ?? 0;

    const totalApprovedOrders = cardPaymentApprovedOrders + pixPaymentApprovedOrders + billetPaymentApprovedOrders;

    return {
        cardPaymentApprovedOrders,
        cardPaymentPendingOrders,
        cardPaymentCancelledOrders,
        pixPaymentApprovedOrders,
        pixPaymentPendingOrders,
        pixPaymentCancelledOrders,
        billetPaymentApprovedOrders,
        billetPaymentPendingOrders,
        billetPaymentCancelledOrders,
        totalApprovedOrders,
    };
}

export function getMetricsRevenueByPayments(responseData: any) {
    const cardPaymentApprovedRevenue = responseData?.revenue_per_payment_approved_revenue?.metric?.credit_card ?? 0;
    const cardPaymentPendingRevenue = responseData?.revenue_per_payment_pending_revenue?.metric?.credit_card ?? 0;
    const cardPaymentCancelledRevenue = responseData?.revenue_per_payment_cancelled_revenue?.metric?.credit_card ?? 0;

    const pixPaymentApprovedRevenue = responseData?.revenue_per_payment_approved_revenue?.metric?.pix ?? 0;
    const pixPaymentPendingRevenue = responseData?.revenue_per_payment_pending_revenue?.metric?.pix ?? 0;
    const pixPaymentCancelledRevenue = responseData?.revenue_per_payment_cancelled_revenue?.metric?.pix ?? 0;

    const billetPaymentApprovedRevenue = responseData?.revenue_per_payment_approved_revenue?.metric?.billet ?? 0;
    const billetPaymentPendingRevenue = responseData?.revenue_per_payment_pending_revenue?.metric?.billet ?? 0;
    const billetPaymentCancelledRevenue = responseData?.revenue_per_payment_cancelled_revenue?.metric?.billet ?? 0;

    const totalApprovedRevenue = cardPaymentApprovedRevenue + pixPaymentApprovedRevenue + billetPaymentApprovedRevenue;
    const totalPendingRevenue = cardPaymentPendingRevenue + pixPaymentPendingRevenue + billetPaymentPendingRevenue;
    const totalCancelledRevenue = cardPaymentCancelledRevenue + pixPaymentCancelledRevenue + billetPaymentCancelledRevenue;

    const totalRevenue = totalApprovedRevenue + totalPendingRevenue + totalCancelledRevenue;

    return {
        cardPaymentApprovedRevenue,
        cardPaymentPendingRevenue,
        cardPaymentCancelledRevenue,
        pixPaymentApprovedRevenue,
        pixPaymentPendingRevenue,
        pixPaymentCancelledRevenue,
        billetPaymentApprovedRevenue,
        billetPaymentPendingRevenue,
        billetPaymentCancelledRevenue,
        totalApprovedRevenue,
        totalPendingRevenue,
        totalCancelledRevenue,
        totalRevenue,
    };
}

export function getMetricsProducts(responseData: any) {
    const productsArray = [];
    const addedProductIds = new Set();

    for (const productId in responseData?.revenue_per_product_approved_orders?.metric) {
        if (responseData?.revenue_per_product_approved_orders?.metric?.hasOwnProperty(productId)) {
            const productName = responseData?.revenue_per_product_aux.names_map[productId];
            if (productName === '') continue;
            const productRevenue = responseData?.revenue_per_product_approved_revenue.metric[productId];
            if (productRevenue === 0) continue;
            if (!addedProductIds.has(productId)) {
                productsArray.push({
                    id: productId,
                    name: productName,
                    productCost: responseData?.revenue_per_product_approved_cost.metric[productId],
                    image: responseData?.revenue_per_product_aux.images_map[productId],
                    revenue: productRevenue,
                    quantity: responseData?.revenue_per_product_approved_orders.metric[productId],
                });

                addedProductIds.add(productId);
            }
        }
    }

    productsArray.sort((a, b) => b.revenue - a.revenue);

    return { products: productsArray };
}

export function getMetricsByPlatform(responseData: any) {
    const platformTypes = [
        {
            platformName: 'Facebook',
            platformMetric: 'facebook',
            platformException: 'facebook_ads',
        },
        {
            platformName: 'Google',
            platformMetric: 'google',
            platformException: 'google_ads',
        },
        {
            platformName: 'Tiktok',
            platformMetric: 'tiktok',
            platformException: 'tiktok_ads',
        },
        {
            platformName: 'Outros',
            platformMetric: 'others',
            platformException: '',
        },
        // {
        //     platformName: 'Sem atribuição',
        //     platformMetric: 'sem_atribuicao',
        //     platformException: '',
        // },
    ];

    let othersRevenueTotal = 0;

    for (const source in responseData?.utm_metrics_utm_metrics?.approved_revenue.source) {
        if (responseData?.utm_metrics_utm_metrics?.approved_revenue?.source?.hasOwnProperty(source) && !['facebook', 'google', 'tiktok', 'sem_atribuicao'].includes(source)) {
            othersRevenueTotal += responseData?.utm_metrics_utm_metrics?.approved_revenue.source[source].metrics;
        }
    }

    let othersSalesTotal = 0;

    for (const source in responseData?.utm_metrics_utm_metrics?.approved_orders.source) {
        if (responseData?.utm_metrics_utm_metrics?.approved_orders?.source?.hasOwnProperty(source) && !['facebook', 'google', 'tiktok', 'sem_atribuicao'].includes(source)) {
            othersSalesTotal += responseData?.utm_metrics_utm_metrics?.approved_orders?.source[source]?.metrics;
        }
    }

    let totalSales = 0;
    for (const platform of platformTypes) {
        if (responseData?.utm_metrics_utm_metrics?.approved_orders?.source[platform.platformMetric]) {
            totalSales += responseData?.utm_metrics_utm_metrics.approved_orders.source[platform.platformMetric].metrics;
        }
    }

    const platformsData = platformTypes.map((platforms) => {
        const platformRevenue = platforms.platformMetric === 'others' ? othersRevenueTotal : responseData?.utm_metrics_utm_metrics.approved_revenue.source[platforms.platformMetric]?.metrics || 0;
        const platformSales = platforms.platformMetric === 'others' ? othersSalesTotal : responseData?.utm_metrics_utm_metrics.approved_orders.source[platforms.platformMetric]?.metrics || 0;
        const platformSpend = responseData?.marketing_cost_metric[platforms.platformException] || 0;
        const platformCpa = platformSales > 0 ? platformSpend / platformSales : 0;

        return {
            ...platforms,
            revenue: platformRevenue,
            sales: platformSales,
            spend: platformSpend,
            cpa: platformCpa,
        };
    });

    platformsData.sort((a, b) => b.revenue - a.revenue);

    return {
        othersSalesTotal,
        othersRevenueTotal,
        totalSales,
        platformsData,
    };
}

export function getMetricsByRegion(responseData: any) {
    let totalSales = 0;
    const approvedOrdersMetrics = responseData?.revenue_per_province_approved_orders?.metric;
    const provincesData = [];

    if (approvedOrdersMetrics) {
        for (const province in approvedOrdersMetrics) {
            if (approvedOrdersMetrics.hasOwnProperty(province)) {
                totalSales += approvedOrdersMetrics[province];
            }
        }

        for (const province in approvedOrdersMetrics) {
            if (approvedOrdersMetrics.hasOwnProperty(province)) {
                const provinceRevenue = responseData?.revenue_per_province_approved_revenue?.metric?.[province] ?? 0;
                if (provinceRevenue === 0) continue;

                const provinceOrders = approvedOrdersMetrics[province];
                const provinceShare = totalSales > 0 ? ((provinceOrders / totalSales) * 100).toFixed(2) : 0;

                provincesData.push({
                    name: province,
                    revenue: provinceRevenue,
                    orders: provinceOrders,
                    share: provinceShare,
                });
            }
        }

        provincesData.sort((a, b) => b.revenue - a.revenue);
    }

    return {
        provincesData,
    };
}

export function getMetricsByHourOrWeek(responseData: any) {
    const hours = [
        '0-1',
        '1-2',
        '2-3',
        '4-5',
        '5-6',
        '6-7',
        '7-8',
        '8-9',
        '9-10',
        '10-11',
        '11-12',
        '12-13',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-24',
    ];

    const formattedHours = [
        '0-1h',
        '1-2h',
        '2-3h',
        '4-5h',
        '5-6h',
        '6-7h',
        '7-8h',
        '8-9h',
        '9-10h',
        '10-11h',
        '11-12h',
        '12-13h',
        '13-14h',
        '14-15h',
        '15-16h',
        '16-17h',
        '17-18h',
        '18-19h',
        '19-20h',
        '20-21h',
        '21-22h',
        '22-23h',
        '23-24h',
    ];

    const xAxisHour = formattedHours;

    const totalHourlyRevenue = hours.map((hour) => {
        const hourMetric = responseData?.revenue_per_hour_approved_revenue?.metric?.[hour];
        if (hourMetric) {
            return hourMetric;
        }
        return 0;
    });

    const xAxisDay = ['friday', 'monday', 'saturday', 'sunday', 'thursday', 'tuesday', 'wednesday'];

    const dayMapping: { [key: string]: string } = {
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
        sunday: 'Domingo',
    };

    const translatedXAxisData = xAxisDay?.map((day: string) => dayMapping[day]);

    const extractDataForDay = (day: any) => {
        const dayData = responseData?.revenue_per_weekday_approved_revenue?.metric?.[day];
        return dayData || 0;
    };

    const totalWeekApprovedRevenue = xAxisDay?.map((day: string) => extractDataForDay(day));

    return {
        xAxisHour,
        yAxisHour: totalHourlyRevenue,
        intervalHour: 2,
        xAxisDay: translatedXAxisData,
        yAxisDay: totalWeekApprovedRevenue,
        intervalDay: 0,
    };
}

// Esta função cria uma data no início do dia para evitar problemas com fuso horário
function createDateAsUTC(dateString:string) {
    const date = parseISO(dateString);
    return startOfDay(date);
}

export function calculateDateToSeeMore(startDate: string, endDate: string) {
    const minimumDays = 7;
    let start = createDateAsUTC(startDate);
    let end = createDateAsUTC(endDate);

    // Se a diferença entre as datas for menor que 7 dias, ajuste a data de início
    const daysDifference = differenceInCalendarDays(end, start);
    if (daysDifference < minimumDays) {
        start = addDays(start, -minimumDays + 1);
    }

    return [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')];
}

export function createMiddayDate(dateString: any) {
    var parts = dateString.split("-");
    // Atenção: o construtor Date no JavaScript usa 0-index para os meses, então subtraia 1 do mês
    var date = new Date(
        Date.UTC(parts[0], parts[1] - 1, parts[2], 12, 0, 0)
    );
    return date;
}
  
export function calculateValueOccurrences( startDate: any, endDate: any, frequency: any, costStartDate: any, value: any) {
        var occurrences = 0;
        var day = 1000 * 60 * 60 * 24; // milliseconds in a day
        startDate = createMiddayDate(startDate);
        endDate = createMiddayDate(endDate);
        costStartDate = createMiddayDate(costStartDate);

        switch (frequency) {
            case "daily":
                if (costStartDate < startDate) {
                    occurrences = Math.ceil((endDate - startDate) / day) + 1;
                } else if (
                    costStartDate >= startDate &&
                    costStartDate <= endDate
                ) {
                    occurrences =
                        Math.ceil((endDate - costStartDate) / day) + 1;
                } else {
                    occurrences = 0;
                }
                

                break;
            case "weekly":
                // One week after cost start date
                var nextDate = costStartDate;

                while (nextDate <= endDate) {
                    if (nextDate >= startDate) {
                        occurrences++;
                    }
                    // Add a week to the next date
                    nextDate = new Date(nextDate.getTime() + 7 * day);
                }
                break;
            case "monthly":
                // One month after cost start date
                nextDate = costStartDate;
                while (nextDate <= endDate) {
                    if (nextDate >= startDate) {
                        occurrences++;
                    }
                    // Add a month to the next date
                    nextDate = new Date(
                        nextDate.setMonth(nextDate.getMonth() + 1)
                    );
                }
                break;
            case "specific":
            if (costStartDate >= startDate && costStartDate <= endDate) {
                occurrences = 1;
            } else {
                occurrences = 0;
            }
            break;
        }

    return occurrences*value;
}

export function getMetricsShipping(orders: any, shipping: any) {
    let shipping_cost = 0;
    let shipping_revenue = 0;

    if (Array.isArray(orders)) {
        for (const order of orders) {
            if (order?.status_name === "approved") {
                shipping_revenue += parseFloat(order?.shipping_fee) || 0;
            }
        }

        if (shipping?.calculation_rule === 'custo_nenhum') {
            shipping_cost = 0;
        } else if (shipping?.calculation_rule === 'custo_do_checkout') {
            for (const order of orders) {
                if (order?.status_name === "approved") {
                    shipping_cost += parseFloat(order?.shipping_fee) || 0;
                }
            }
        } else if (shipping?.calculation_rule === 'custo_fixo') {
            for (const order of orders) {
                if (order?.status_name === "approved") {
                    shipping_cost += parseFloat(shipping?.shipping_cost) || 0;
                }
            }
        } else if (shipping?.calculation_rule === 'custo_variavel') {
            for (const order of orders) {
                if (order?.status_name === "approved" && order?.product_price < parseFloat(shipping?.top_order_price) || 0) {
                    shipping_cost += parseFloat(shipping?.shipping_cost) || 0;
                }
            }
        }
    } else {
        // console.error("Orders is not an array.");
    }

    return {
        shipping_cost, shipping_revenue
    };
}