import styled from 'styled-components';


export const Container = styled.div`
    padding: 2rem 2rem 3rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Title = styled.div`
    font-size: 1.8rem;
    color: var(--black);
    line-height: 1.95rem;
    font-weight: 500;
    height: 2rem;
    margin-bottom: 0.5rem;
`;

export const TabsContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
    // padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--gray-200);
`;


export const TabButton = styled.div<{ active?: boolean }>`
    font-size: 1.1rem;
    font-weight: 550;
    border-bottom: 3px solid ${(props) => props.active ? 'var(--green)' : 'transparent'};
    color: ${(props) => props.active ? 'var(--green)' : 'var(--gray-700)'};
    cursor: pointer;
    transition: ease-in-out 250ms all;
    padding-bottom: 7px;

    &:hover {
        color: var(--green);
        transition: ease-in-out 250ms all;
    }

    &:active {
        color: var(--green);
        font-weight: 600;
    }
`;

export const Subtitle = styled.div<{ special?: boolean }>`
    font-size: 1rem;
    margin-top: -0.5rem;
    color: var(--gray-700);

    ${({ special }) => special && `
        font-size: 0.9rem;
    `}
    `;

export const Header = styled.div`
width: -webkit-fill-available;
max-width: 1120px;
`;

    
export const PlanArea = styled.div<{ special?: boolean }>`
    display: flex;
    padding: 1.5rem;
    width: 50%;
    max-width: 50%;
    align-items: center;
    margin-top: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 7px;
    justify-content: space-between;

    ${({ special }) => special && `
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
`}
`;

export const PlanStatus = styled.div<{ special?: boolean }>`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--green800);
    background-color: var(--green-100);
    padding: 0.2rem 0.5rem;
    font-size: 0.85rem;
    border-radius: 50px;

    ${({ special }) => special && `
        color: var(--red-800);
        background-color: var(--red-100);
        font-size: 1rem;
        margin-bottom: -8px;
        font-weight: 500;
    `}  
`;

export const PlanName = styled.div<{ special?: boolean }>`
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    ${({ special }) => special && `
        margin-top: 0.8rem;
    `}
    `;

export const PlanPriceArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    
    p {
        font-size: 3rem;
        color: var(--black);
        font-weight: 550;
    }

    span {
        font-size: 0.90rem;
        color: var(--gray-700);
    }
`;

export const FlexTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
        fill: var(--green);
        width: 1.2rem;
    }
`;

export const OrdersProgressBarFill = styled.div<{ showWarning:boolean, percentage: number }>`
    width: ${props => props.percentage}%;
    max-width: 100%;
    background-color: var(--green);
    height: 100%;
    border-radius: 70px;
`

export const PlanTitleArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: -0.5rem;

    p {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: 550;
    }

    span {
        font-size: 0.90rem; 
        color: var(--gray-800);
    }
`;

export const OrdersProgress = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem
`;


export const OrdersTop = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const OrdersTitle = styled.div`
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    color: var(--black);
    font-weight: 500;
    border-radius:70px;
    border: 1px solid var(--green);
`

export const OrdersCount = styled.div`
    font-size: 1.45rem;
    color: var(--black);
    font-weight: 550;

    span {
        font-size: 1rem;
        color: var(--gray-700);
    }
`

export const OrdersProgressBar = styled.div`
    background-color: var(--gray-200);
    height: 0.5rem;
    width: 100%;
    border-radius: 70px;
`;

export const TopPlanArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`

export const ActivePlanArea = styled.div`
    display: flex;
    width: 80%;
    padding: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    height: 100%;
    gap:1rem;
    flex-direction: column; 
`;

export const PaymentMethodArea = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 12px;
    justify-content: space-between;
    height: 100%;
    gap:1rem;
    flex-direction: row; 

`;

export const PaymentInfoText = styled.div`
    font-size: 1.1rem;
    color: var(--black);
    font-weight: 550;
    margin-bottom: 0.3rem;
    `

export const PaymentMethodInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 230px;

    h2 {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: 500;
    }

    p {
        font-size: 1rem;
        color: var(--gray-800);
    }
    
    span {
        background-color: var(--black);
        padding: 0.05rem 0.8rem;
        color: white;
        font-size: 0.8rem;
        border-radius: 50px;
    }
`

export const PaymentMethodIcon = styled.div`
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid var(--gray-200);
    
    img {
        width: 3rem;
    
    }   
`;

export const PaymentMethodInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0rem;
    
`;



export const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;

    span {
        font-size: 0.8rem;
        width: 100%;
        color: var(--gray-700);
    }

    p {
        font-size: 1.2rem;
        font-weight: 550;
        color: var(--black);
    }
`;

export const MainText = styled.div`
    p {
        font-size: 1.1rem;
        color: var(--black);
        font-weight: 500;
        margin-bottom: 0.5rem;

        span {
            font-weight: 550;
            padding: 0.15rem 1rem;
            // background: #e7e7e7;
            border: 1px solid #19c27e;
            border-radius: 50px;
            color: var(--green);
            font-size: 1.1rem;
        }
    }

    span {
        font-size: 0.9rem;
        color: var(--gray-700);
    }
`;

export const Table = styled.div`
`;

export const TransactionsInner = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContentArea = styled.div`
    display: flex;
    margin-top: 3rem;
    flex-direction: row;
    gap: 2rem;
    
`;

export const PlanPrice = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

export const PriceValue = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--black);

    span {
        font-size: 0.85rem;
    }
`;

export const PlanSubtitle = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0;
    color: var(--gray-300);
`;


export const PlanDate = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--gray-300);
`;

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

export const BottomArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    p{
        color: var(--gray-800);
        font-size: 0.8rem;
        font-weight: 500;
        span {
            color: var(--black);
            font-size: 0.9rem;
            font-weight: 550;
        }
    }
`

export const ButtonsArea = styled.div`
    display: flex;
`

export const ButtonText = styled.div<{ isDefault?: boolean, isEditing?: boolean }>`
    background-color: ${(props) => (props.isEditing ? '#19c27e' : 'transparent')};
    border: none;
    border-radius: 312px;
    color: ${(props) => (props.isEditing ? 'white' : (props.isDefault ? 'var(--gray-700)' : '#bf1f1f'))};
    padding: 9px 16px 9px 16px;
    font-weight: 500;
    margin-left: -14px;
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out 250ms all;

    &:hover {
        background-color: ${(props) => (props.isEditing ? '#19c27e' : (props.isDefault ? 'transparent' : '#bf1f1f'))};
        // background-color: ${(props) => (props.isEditing ? '#19c27e' : '#bf1f1f')};
        color: ${(props) => (props.isDefault ? '#bf1f1f' : 'white')};
        transform: translateY(3px);
        transition: ease-in-out 250ms all;
    }
`;

export const ButtonTextCancelamento = styled.div<{ isDefault?: boolean, isEditing?: boolean }>`
    background-color: ${(props) => (props.isEditing ? 'transparent' : '#bf1f1f')};
    border: none;
    border-radius: 312px;
    color: ${(props) => (props.isEditing ? '#bf1f1f' : (props.isDefault ? 'var(--gray-700)' : '#bf1f1f'))};
    padding: 9px 16px 9px 16px;
    font-weight: 500;
    margin-left: -14px; 
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out 250ms all;

    &:hover {
        background-color: ${(props) => (props.isEditing ? '#bf1f1f' : (props.isDefault ? 'transparent' : '#bf1f1f'))};
        // background-color: ${(props) => (props.isEditing ? '#19c27e' : '#bf1f1f')};
        color: ${(props) => (props.isDefault ? '#bf1f1f' : 'white')};
        transform: translateY(3px);
        transition: ease-in-out 250ms all;
    }
`;

export const PlanInfoArea = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--gray-200);
`;  

export const VerticalLine = styled.div`
    height: 100px;
    width: 1px;
    background-color: var(--gray-200);
`;

export const ModalContent = styled.div`
background-color: white;
padding: 20px 20px 14px 20px;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;

 h2 {
    font-size: 24px;
    color: #0e1819;
    margin-bottom: 0px;
    font-weight: 500;
 }

 p {
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 32px;
 }

 
`;


export const Warning = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
`;

export const FormWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
`;

export const QuestionWrapper = styled.div`
  margin-bottom: 20px;
`;

export const QuestionTitle = styled.h3`
  margin-bottom: 10px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;