import { TemplateModal } from '../index';
import * as S from './styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import { useState } from 'react';
import { ModalYeverProps, userDTO } from '../../../../@types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuth from '../../../../hooks/useAuth';
import { useCreateIntegrationYever } from '../../../../services/hooks/integrationHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateYeverSave } from '../../../../storage/storageOnboarding';



export function ModalYever({ setShowPopupYever, setIntegrateYever }: ModalYeverProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/yever%20checkout.png';
    const baseColor = '#ffda57';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();

    const yeverInformation = user?.companies?.[currentCompany]?.integrations?.yever?.accounts[user?.companies?.[currentCompany]?.integrations?.yever?.accounts?.length - 1];

    // const hasYeverInfo = yeverInformation?.account_id !== 'default-account';

    const { mutate: handleCreateIntegrationYever, isLoading: isLoadingCreateIntegration, isError } = useCreateIntegrationYever();

    const [yeverInfo, setYeverInfo] = useState({
        accessTokenYever: yeverInformation?.account_id  !== 'default-account' ? yeverInformation?.account_id : '',
    });

    // const [yeverInfo, setYeverInfo] = useState({
    //     accessTokenYever: '',
    // });

    function handleChangeYeverInfo(e: any) {
        setYeverInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    function handleIntegrate() {
        const body = {
            token: yeverInfo.accessTokenYever,
            company_id: storageOnboardingCurrentCompanyGet(),
            user: user
        };

        handleCreateIntegrationYever(body, {
            onSuccess: () => {
                storageOnboardingIntegrateYeverSave();
                if(setIntegrateYever){
                    setIntegrateYever(true);
                }
                setShowPopupYever(false);
                const active = {
                    account_id: yeverInfo.accessTokenYever,
                    assets:{token: yeverInfo.accessTokenYever},
                    last_update: '',
                    status: 'active',
                };
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            integrations: {
                                ...user?.companies?.[currentCompany]?.integrations,
                                yever: {
                                    ...user?.companies?.[currentCompany]?.integrations?.yever,
                                    accounts: [active],
                                },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Yever'}
                title={'Yever'}
                subtitle={'Agência digital especializada em soluções de e-commerce e marketing online.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration
                    value={yeverInfo.accessTokenYever}
                    setValue={handleChangeYeverInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenYever"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
            </S.InnerContainer>
            {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            {/* <S.InfoContainer>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meu domínio e token de acesso?
            </S.InfoContainer> */}

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp border='' width="7.75rem" height="2.25rem" color="#fff" background="#19c380" icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />} onClick={handleIntegrate} text="Integrar" isLoading={isLoadingCreateIntegration} />
            </S.DisconnectContainer>
        </S.Container>
    );
}
