import { createContext, useState } from 'react';
import { DataIntegration, DataIntegrationResult, GetDataContextProps, GetDataContextProviderProps, Progress } from '../@types';
import { useGetData } from '../services/hooks/getData';
import { storageGetDataGet } from '../storage/storageGetData';
import { storageOnboardingCurrentCompanyGet } from '../storage/storageOnboarding';

export const GetDataContext = createContext({} as GetDataContextProps);

export const GetDataProvider = ({ children }: GetDataContextProviderProps) => {
    const [getDataIsEnabled, setGetDataIsEnabled] = useState(storageGetDataGet());

    const { data: dataFromGetData } = useGetData(storageOnboardingCurrentCompanyGet(), getDataIsEnabled);

    function process_response({ data }: any) {
        const integration_ids = ['facebook_ads_insights', 'tiktok_ads_insights', 'shopify_orders', 'yampi_checkout', 'cartpanda_checkout', 'google_ads', 'yever_checkout', 'dom_gateway'];
        const data_processed: any = {};
        if (data?.length > 0) {
            for (const status of data) {
                if (integration_ids?.includes(status?.integration_id)) {
                    if (data_processed?.[status?.integration_id]) {
                        if (data_processed[status?.integration_id]?.flag !== 'modeling' && new Date(status?.timestamp) > new Date(data_processed?.[status?.integration_id]?.timestamp)) {
                            data_processed[status?.integration_id] = status;
                        }
                    } else {
                        data_processed[status?.integration_id] = status;
                    }
                }
            }
        } else {
            return data_processed;
        }
        return data_processed;
    }

    function set_percentage(data_integration: DataIntegration) {
        if (!data_integration) {
            return { percentage: 0, status: 'not_connected', flag: 'not_connected', done: false };
        }

        const ret: DataIntegrationResult = {
            status: data_integration?.status,
            flag: data_integration?.flag,
            done: false
        };

        if (data_integration?.flag === 'extraction') {
            if (data_integration?.status === 'initialized') {
                ret.percentage = 45;
            } else if (data_integration?.status === 'finalized') {
                ret.percentage = 65;
            }
        } else if (data_integration?.flag === 'transformation') {
            ret.percentage = 85;
        } else if (data_integration?.flag === 'loading' || data_integration?.flag === 'modeling') {
            ret.percentage = 100;
            ret.done = true;
        }
        return ret;
    }

    function create_progress(data: any) {
        const dict_integrations: { [key: string]: string } = {
            tiktok_ads: 'tiktok_ads_insights',
            facebook_ads: 'facebook_ads_insights',
            shopify: 'shopify_orders',
            yampi: 'yampi_checkout',
            cartpanda: 'cartpanda_checkout',
            google: 'google_ads',
            yever: 'yever_checkout',
            dom: 'dom_gateway'
        };

        const progress: { [key: string]: any } = {};

        for (const integration of Object.keys(dict_integrations)) {
            const data_integration = data?.[dict_integrations[integration]];
            progress[integration] = set_percentage(data_integration);
        }
        return progress;
    }

    
const data_processed = process_response(dataFromGetData?.data ?? []);


const data_with_progress = Object.keys(data_processed).reduce((acc: Progress, key) => {
    acc[key] = set_percentage(data_processed[key]);
    return acc;
}, {} as Progress); // Inicializa 'acc' como um objeto do tipo 'Progress'


const data = create_progress(data_with_progress);

return <GetDataContext.Provider value={{ getDataIsEnabled, setGetDataIsEnabled, data }}>{children}</GetDataContext.Provider>;
};
