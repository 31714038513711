// import React, { createContext, useState, useEffect, ReactNode } from 'react';
// import { useGetCompanyCurrency } from '../services/hooks/companyCurrencyHooks';
// import useAuth from '../hooks/useAuth';

// interface CurrencyTypeContextProps {
//   currencySymbol: string;
// }

// interface ProviderProps {
//   children: ReactNode;
// }

// const CurrencyTypeContext = createContext<CurrencyTypeContextProps>({ currencySymbol: 'R$' });

// const currencySymbols = {
//   USD: '$',
//   BRL: 'R$',
//   EUR: '€'
// };

// export const CurrencyTypeProvider = ({ children }: ProviderProps) => {
//   const [currencyType, setCurrencyType] = useState('BRL');
//   const { currentCompany } = useAuth();
//   // const { data: companyCurrency } = useGetCompanyCurrency(currentCompany, window.location.pathname !== '/payment' && window.location.pathname !== '/lojas'  && window.location.pathname !== '/login' && window.location.pathname !== '/onboarding' && window.location.pathname !== '/congratulations' && window.location.pathname !== '/loading' && window.location.pathname !== '/terms');
  
//   // useEffect(() => {
//   //   if (companyCurrency) {
//       setCurrencyType('BRL');
//   //   }
//   // }, [companyCurrency]);

//   const currencySymbol = currencySymbols[currencyType];

//   return (
//     <CurrencyTypeContext.Provider value={{ currencySymbol }}>
//       {children}
//     </CurrencyTypeContext.Provider>
//   );
// };

// export default CurrencyTypeContext;


import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useGetCompanyCurrency } from '../services/hooks/companyCurrencyHooks';
import useAuth from '../hooks/useAuth';

interface CurrencyTypeContextProps {
  currencySymbol: string;
}

interface ProviderProps {
  children: ReactNode;
}

const CurrencyTypeContext = createContext<CurrencyTypeContextProps>({ currencySymbol: 'R$' });

export const CurrencyTypeProvider = ({ children }: ProviderProps) => {
  // Se você quiser manter a lógica de currencyType para uso futuro, pode deixar
  const [currencyType, setCurrencyType] = useState('BRL');
  const [currencySymbol, setCurrencySymbol] = useState('R$');
  const { currentCompany } = useAuth();
  const { data: companyCurrency } = useGetCompanyCurrency(currentCompany, window.location.pathname !== '/payment' && window.location.pathname !== '/lojas'  && window.location.pathname !== '/login' && window.location.pathname !== '/onboarding' && window.location.pathname !== '/congratulations' && window.location.pathname !== '/loading' && window.location.pathname !== '/terms');
  
  useEffect(() => {
    if (companyCurrency) {
      setCurrencyType(companyCurrency?.data?.data.currency);
    }
  }, [companyCurrency]);

  // Sempre retorna 'R$'
  // const currencySymbol = 'R$';

  useEffect(() => {
    switch (currencyType) {
      case 'BRL':
        setCurrencySymbol('R$');
        break;
      case 'USD':
        setCurrencySymbol('$');
        break;
      case 'EUR':
        setCurrencySymbol('€');
        break;
      case 'AUD':
        setCurrencySymbol('AU$');
        break;
      case 'GBP':
        setCurrencySymbol('£');
        break;
      case 'COP':
        setCurrencySymbol('COP');
        break;
      case 'ARS':
        setCurrencySymbol('ARS');
        break;
      case 'CAD':
        setCurrencySymbol('C$');
        break;
      default:
        setCurrencySymbol('R$');
    }
  }, [currencyType]);

  return (
    <CurrencyTypeContext.Provider value={{ currencySymbol }}>
      {children}
    </CurrencyTypeContext.Provider>
  );
};

export default CurrencyTypeContext;
