import * as S from './style';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import SwitchAccount from '../../../../../../components/SwitchAccount';
import Skeleton from '@mui/material/Skeleton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Tooltip } from '@mui/material';

export function CardAccount({facebookStatusInactive, setFacebookStatusInactive, setSelectedAccountId, setSelectedProfileId, setShowConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupProfile, facebookInfo, isLoading }: {facebookStatusInactive: any, setFacebookStatusInactive: any ,setSelectedAccountId: any, setSelectedProfileId: any, setShowConfirmDisconnectPopupAccount: any, setShowConfirmDisconnectPopupProfile: any, facebookInfo : any, isLoading: boolean}) {
    const [showMoreAccounts, setShowMoreAccounts] = useState(true);

    return (
        <S.Container>
            {!isLoading && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.FlexTitle>
                    <S.AvatarContainer>
                        <PersonIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} />
                    </S.AvatarContainer>
                    <p>{facebookInfo?.assets?.profile_name}</p> <span>- {facebookInfo?.profile_id}</span>
                </S.FlexTitle>
                <S.ActionAreaProfile>
                    <Tooltip title="Excluir perfil" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                            }}>
                                
                            <DeleteForeverRoundedIcon onClick={()=> {
                                setShowConfirmDisconnectPopupProfile(true)
                                setSelectedProfileId(facebookInfo?.profile_id)
                            }}/>
                            
                    </Tooltip>
                    <S.SelectButton>
                        {showMoreAccounts && <KeyboardArrowUpIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(false)} />}
                        {!showMoreAccounts && <KeyboardArrowDownIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(true)} />}
                    </S.SelectButton>
                </S.ActionAreaProfile>
            </S.InnerContainer>
            }
            {isLoading && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.AvatarContainerLoading>
                    <Skeleton variant="circular" sx={{ width: '2rem', height: '2rem', marginRight: '0.5rem'}} />
                </S.AvatarContainerLoading>
                <Skeleton sx={{ width: '12rem', height: '2rem', fontSize: '1rem' }} /> <span>-</span><Skeleton sx={{ width: '8rem', height: '1rem', fontSize: '1rem' }} />
                
                </S.InnerContainer>}
            {!isLoading && showMoreAccounts &&
                facebookInfo?.accounts?.map((account: any) => {
                    return (
                        <S.Account key={account?.account_id}>
                            <div>
                                <S.AccountTitle>NOME DA CONTA</S.AccountTitle>
                                <S.AccountName>
                                <p>
                                {account?.assets?.account_name}
                                </p>
                                 <span>- {account?.account_id}</span>
                                </S.AccountName>
                            </div>
                            <S.ActionArea>
                                <Tooltip title="Ativar/Desativar conta" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <div>
                                        <SwitchAccount facebookStatusInactive={facebookStatusInactive} setFacebookStatusInactive={setFacebookStatusInactive} account={account} profileId={facebookInfo?.profile_id} type='facebook'/>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Excluir dados da conta" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <DeleteForeverRoundedIcon onClick={()=> {
                                        setShowConfirmDisconnectPopupAccount(true)
                                        setSelectedAccountId(account?.account_id)
                                        setSelectedProfileId(facebookInfo?.profile_id)
                                    }}/>
                                </Tooltip>
                            </S.ActionArea>
                        </S.Account>
                    );
                })}
        </S.Container>
    );
}
