
import styled, { keyframes } from 'styled-components';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

export const Container = styled.div`
    padding: 1rem 2rem 0;
`;


export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`



export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.46rem;
    span {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

export const Table = styled.div``;

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
`;

export const ContainerImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: 1.5rem auto 0;
    animation: ${floating} 2s ease-in-out infinite;
    img {
        width: 18.75rem;
        height: auto;
    }
`;

export const Title2 = styled.div`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: var(--green);
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
`;

type SaveProps = {
    disabled: boolean;
};

export const saveEditOrder = styled.div<SaveProps>`
    width: fit-content;
    border: none;
    gap: 5px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    background-color: #19c27e;
    color: white;
    padding: 10px 32px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    margin: 1rem 1rem 0rem 0rem;

    ${(props) => !props.disabled && `
        transition: all ease-in-out 0.2s;
        &:hover {
            background-color: #13925f;
            color: white;
            backface-visibility: hidden;
            transform: translateY(3px);
            box-shadow: 6.5227px 6.5227px 17px -2.1605px rgba(71, 118, 99, 0.08);
        }
    `}

    ${(props) =>
        props.disabled &&
        `
            cursor: not-allowed;
            background-color: #ccc;
            color: #a1a1a1;
        `}

    font-weight: 600;
    line-height: 1.125rem;
    display: flex;
    span {
        margin-left: 0.5rem;
        scale: 0.7;
    }
    margin-right: 0.5rem;
`;

export const ContainerPopup = styled.div`
    width: 28rem;
    height: 14rem;
    border-radius: 20px;
    background-color: var(--white);
`;

export const IconPopup = styled.div`
    color: var(--green);
    font-size: 30px;
    margin-left: 1.2rem;
    margin-top: 1rem;
    span {
        color: #19c380;
    }
`;

export const TitlePopup = styled.div`
    color: #1e1e1e;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1.2rem;
    margin-top: 1rem;
    span {
        color: #19c380;
    }
`;

export const SubtitlePopup = styled.div`
    color: var(--gray, #7c7c7c);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 0 1.2rem;
    margin-top: 0.5rem;
`;

export const Line = styled.div`
    background-color: #d9dbd9;
    height: 1px;
    display: flex;
    flex: 1;
    margin: 1rem 1rem 0rem 1rem;
    
`;

export const SubsubtitlePopup = styled.div`
    font-size: 0.7rem;
    line-height: 1.125rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem 0 1rem; /* Adjusted margin */
`;

export const NumberInput = styled.input`
    height: 2rem;
    width: 31rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
    font-size: 12px;
    font-weight: 400;
    margin: 0.5rem 1rem 0rem 1rem; /* Adjusted margin */
`;


export const Label = styled.label`
    color: #000;
    font-size: 1rem; 
    
`;

export const Save = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9em;
    width: 25%;
    margin: 0 auto;
    font-size: 13px;
    left: 0;
    color: white !important;
    margin-top: 5px;
    margin-left: 0rem;
    background-color: #19c27e;
    border: none;
    border-radius: 7px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;  
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        color: whitesmoke;
        transform: translateY(+7px);
    }
`;

export const KeepEdit = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9em;
    margin: 0 auto;
    font-size: 13px;
    left: 0;
    color: gray;
    background: none;
    border: none;
    border-radius: 7px;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        color: #0c2216;
    }
`;


export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const slideIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(20px);
    opacity: 0;
  }
`;

export const UpdatePopupContainer = styled.div`
    position: fixed;
    bottom: 40px;
    display: flex;
    align-items: center;
    left: 20px;
    gap: 6px;
    border-radius: 8px;
    padding: 10px;
    background-color: #1bc34f;
    color: white;
    font-weight: 500;
    font-size: 11px;
    z-index: 1000;
    animation: ${slideIn} 0.5s forwards;

    &.out {
        animation: ${slideOut} 0.5s forwards;
    }

    i {
        font-size: 16px;
    }

    p {
        margin: 0;
    }
`;

const rotateAntiClockwise = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(360deg) scaleX(-1);
  }
`;

export const RotatingIcon = styled(CachedRoundedIcon)`
  animation: ${rotateAntiClockwise} 1s linear infinite;
`;

export const SectionFS = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const Filter = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    width: 6rem !important;
    box-sizing: border-box;
    width: var(--button-diameter);
    height: var(--button-diameter);
    border-radius: var(--button-border-radius);
    color: #555353;
    border: none;
    background: #fcfcfc;
    border: 1px solid #d9dbd9;
    cursor: pointer;
    position: relative;
    outline: none;
    --button-bg: #353434;
    --button-hover-bg: #464646;
    --button-text-color: #cccccc;
    --button-hover-text-color: #8bb9fe;
    --button-border-radius: 8px;
    --button-diameter: 45px;
    margin-left: 15px;
    --button-outline-width: 1px;
    --button-outline-color: rgb(141, 141, 141);
    align-items: center;
    transition: all 200ms ease;
    &:hover {
        outline: none !important;
        border-color: rgba(76, 234, 134, 0.4) !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1) !important;
        transition: all 200ms ease;
    }
`;

type FilterProps = {
    showFilter: boolean;
};

export const FilterContainer = styled.div<FilterProps>`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
    padding: 16px;
    width: 240px; /* Ajuste a largura conforme necessário */
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0; /* Inicia com opacidade zero */
    max-height: 0;
    position: absolute;
    z-index: 50;

    ${(props) =>
        props.showFilter
            ? `
                max-height: 500px;
                position: absolute;
                z-index: 50;
                top: 3.5rem;
                left: -19rem;
                opacity: 1;
            `
            : `
            `}
`;

export const FilterOutContainer = styled.div`
        display: flex;
        flex-direction: row;
`

export const FilterContentFixed = styled.div `
        position: relative;

`

export const HeaderFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
`;

export const CloseButton = styled.i`
    display: flex;
    color: #989c97;
    margin-right: 16px;
    cursor: pointer;

    &:hover {
        position: relative;
        color: #192324;
        border-radius: 3px;
        cursor: pointer;
    }
`;

export const Lines = styled.span`
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 16px;
`;

export const FilterSection = styled.div`
    margin-bottom: 20px;
`;

export const FlexTitleReset = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    font-size: 11px;
    color: #979797;
    font-weight: 500;
`;

export const ResetButton = styled.button`
    background: none;
    border: none;
    color: #19c27e;
    cursor: pointer;
    font-size: 11px;
`;

export const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: center;
    font-size: 11px;
    color: #555353;
    font-weight: 500;
`;


export const ApplyButton = styled.button`
    display: block;
    width: 100%;
    background-color: #19c27e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 200ms ease;
    &:hover {
        transform: translateY(2px);
    }
`;

export const Filters = styled.div`
    displey: flex;
`;

export const Payment = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #e5e5e5;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-top: 0.3rem;
    margin-left: 1rem;
    img {
        margin-left: 1rem;
        width: 0.75rem;
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Approved = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Pending = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Cancelled = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Image = styled.div`
    display: flex;
    margin-left: 1rem;
`;