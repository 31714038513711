import styled from "styled-components";

export const Container = styled.div`
display: flex;
border-bottom: 1px solid rgb(0,0,0,0.1);
margin-top: 0.3rem;
&:last-child{
    border-bottom: none;
}
`

export const ProductImage = styled.div`
 width: 15%;
 height: 4rem;

    display: flex;
    align-items: center;
    img {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 5px;

        margin-left: 1.5rem;
    }
`

export const ProductTitle = styled.div`
  display: flex;
  height: 4rem;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 85%;
        font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
`