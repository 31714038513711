import { useEffect, useState } from 'react';
import { FormsLoginProps, StorageAuthTokenProps, userDTO, userLoginDTO } from '../../../@types';
import { InputLogin } from '../../../components/InputLogin';
import * as S from './style';
import { useLoginUser } from '../../../services/hooks/authHooks';
import { signInCurrentUserSchemaLocalStorage, signInSchema, signInTokenSchemaLocalStorage, signInUserSchemaLocalStorage } from '../../../schemas/authSchema';
import { Loading } from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { storageAuthTokenGet } from '../../../storage/storageAuthToken';
import { storageUserGet } from '../../../storage/storageUser';
import useAuth from '../../../hooks/useAuth';
import { storageCurrentUserGet } from '../../../storage/storageCurrentUser';
import { jwtDecode } from 'jwt-decode';
import { storageShExternalIdRemove } from '../../../storage/storageCompanyToOnboard';
import useIsMobile from '../../../hooks/useIsMobile';
import Modal from '../../../components/Modal';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import { useSubmitResetPassword } from '../../../services/hooks/resetPasswordHooks';
import useAlert from '../../../hooks/useAlert';

export function FormsLogin({ showLogin, setShowBackgroundImage }: FormsLoginProps) {
    const [userForLogin, setUserForLogin] = useState({} as userLoginDTO);
    const [showInvalidError, setShowInvalidError] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const { setUserContext, setTokenContext, setCurrentCompanyContext } = useAuth();
    const { mutate: handleLogin, isLoading } = useLoginUser();
    const { mutate: resetPassword, } = useSubmitResetPassword();
    const [isLoadingPassword, setIsLoadingPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isMobile = useIsMobile();
    const { showAlert } = useAlert();

    useEffect(() => {
        const localUser = storageUserGet();
        const localToken = storageAuthTokenGet();
        const localCurrentUser = storageCurrentUserGet();

        const isValidLocalUser = signInUserSchemaLocalStorage.safeParse(localUser);
        const isValidLocalToken = signInTokenSchemaLocalStorage.safeParse(localToken);
        const isValidLocalCurrentUser = signInCurrentUserSchemaLocalStorage.safeParse(localCurrentUser);

        if (isValidLocalCurrentUser?.success && isValidLocalToken?.success && isValidLocalUser?.success) {
            try {
                jwtDecode(localToken.session_token);
                setUserContext(localUser);
                setTokenContext(localToken);
                setCurrentCompanyContext(localCurrentUser ?? '');
                // verifica no login se tem o external_id na url 
                const shop = new URLSearchParams(location.search).get('shop');
                if(shop && shop !== ''){
                    // verifica se o usuário ja tem uma company cujo account_id da shopify é igual ao shop
                    if (localUser?.companies) {
                        let returnFlag = false;
                        Object.keys(localUser.companies).forEach((company_id) => {
                            const company = localUser.companies[company_id];
                            // itera sobre as accounts da shopify da company  e verifica se tem uma com o account_id igual ao shop
                            company.integrations.shopify.accounts.forEach((account) => {
                                if (account.account_id === shop) {
                                    // se tiver, redireciona para a loja
                                    // //console.log('/lojas')
                                    navigate(`/lojas?company_id=${company_id}`);
                                    returnFlag = true;
                                    storageShExternalIdRemove();                                    
                                }
                            });
                        });
                        if(returnFlag){
                            return;
                        }
                    }
                    //console.log('/shopify/payment');
                    navigate('/shopify/payment');
                    return;                      
                }else {
                    if(localUser?.companies[localCurrentUser ?? '']?.integrations?.shopify?.accounts?.length > 1 && !isMobile){
                        navigate('/dashboard');
                        // //console.log('/dashboard');
                    }else if (localUser?.companies[localCurrentUser ?? '']?.integrations?.shopify?.accounts?.length > 1 && isMobile){
                        navigate('/dashboardMobile');
                    }else{
                        throw new Error('Onboarding não finalizado.');
                    }
                }               
            } catch (error) {
                console.error(error);
                localStorage.removeItem('user');
                localStorage.removeItem('authToken');
                localStorage.removeItem('currentCompany');
            }
        }
    }, []);

    const [previousEmails, setPreviousEmails] = useState<string[]>(() => {
        // Tentar recuperar os e-mails do armazenamento local
        const savedEmails = localStorage.getItem('previousEmails');
        // Parse do JSON para um array, se existir; senão, retorna um array vazio
        return savedEmails ? JSON.parse(savedEmails) : [];
      });

    function saveAndUpdateUserAndToken(user: userDTO, { access_token, session_token }: StorageAuthTokenProps) {
        setUserContext(user);
        setTokenContext({ access_token, session_token });
        setCurrentCompanyContext(Object.keys(user.companies)[0]);

        if (!previousEmails.includes(user.credentials.email)) {
            const updatedEmails = [...previousEmails, user.credentials.email];
            setPreviousEmails(updatedEmails);
            // Atualiza o armazenamento local
            localStorage.setItem('previousEmails', JSON.stringify(updatedEmails));
          }
    }

    const navigate = useNavigate();

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const isValidInformation = signInSchema.safeParse(userForLogin);

        if (!isValidInformation?.success) {
            setShowInvalidError(true);
            showAlert('Por favor, preencha os campos corretamente.', 'error', 5000, true);
            return;
        }

        handleLogin(userForLogin, {
            onSuccess: ({ data }) => {
                saveAndUpdateUserAndToken(data?.data?.user, {
                    access_token: data?.data?.data?.access_token?.access_token,
                    session_token: data?.data?.data?.cache?.session_token,
                });
        
                const shop = new URLSearchParams(location.search).get('shop');
                const companies = data?.data?.user?.companies;
        
                if (shop && shop !== '') {
                    // verifica se o usuário já tem uma company cujo account_id da shopify é igual ao shop
                    if (companies) {
                        let returnFlag = false;
                        Object.keys(companies).forEach((company_id) => {
                            const company = companies[company_id];
                            // itera sobre as accounts da shopify da company e verifica se tem uma com o account_id igual ao shop
                            company.integrations.shopify.accounts.forEach((account) => {
                                if (account.account_id === shop) {
                                    // se tiver, redireciona para a loja
                                    navigate(`/lojas?company_id=${company_id}`);
                                    returnFlag = true;
                                    storageShExternalIdRemove();
                                }
                            });
                        });
                        if (returnFlag) {
                            return;
                        }
                    }
                    navigate('/shopify/payment');
                    return;
                } else {
                    const companyIds = Object.keys(companies ?? {});
                    if (companyIds.length === 0) {
                        navigate('/payment');
                    } else if (companyIds.length === 1) {
                        const company = companies[companyIds[0]];
                        const billingStatus = company?.billing?.status;
        
                        if (billingStatus === 'inactive') {
                            if (company.billing?.subscription !== ''){
                                navigate(`/payment?company_id=${companyIds[0] || 'placeholder'}&context=renew&add_store=true`);
                            }else{
                                navigate(`/payment?company_id=${companyIds[0] || 'placeholder'}`);
                            }

                        } else {
                            if (!isMobile) {
                                const shopifyIntegratedAccounts = company?.integrations?.shopify?.accounts?.length || 0;
                                const kiwifyIntegratedAccounts = company?.integrations?.kiwify?.accounts?.length || 0;
                                if (shopifyIntegratedAccounts <= 1 && kiwifyIntegratedAccounts <= 1) {
                                    navigate('/onboarding');
                                } else {
                                    navigate('/dashboard');
                                }
                            } else {
                                navigate('/dashboardMobile');
                            }
                        }
                    } else {
                        navigate('/lojas');
                    }
                }
            },
            onError: (error) => {
                console.error(error);
                setShowLoginError(true);
                showAlert('Usuário ou senha inválida, tente novamente.', 'error', 5000, true);
                setShowBackgroundImage(true);
            },
        });
        
    };

    if (isLoading) {
        setShowBackgroundImage(false);
        return <Loading />;
    }

    function handleOpenForgotPassword() {
        setIsModalOpen(true);
    }

    const handleSubmitForgotPassword = (e: any) => {
        e.preventDefault();

        const bodyForgotPassword = {
            email: userForLogin.email,
        }

        //console.log('body', userForLogin.email)
        setIsLoadingPassword(true)
        resetPassword(bodyForgotPassword, {
            onSuccess: ({ data }) => {
                setIsModalOpen(false);
                setIsLoadingPassword(false)
                showAlert('Enviado com sucesso! verifique seu email.', 'success', 5000, true);
            },
            onError: (error) => {
                setIsLoadingPassword(false)
                showAlert('Falha ao enviar email, tente novamente.', 'error', 5000, true);
            },
        });
        
    };

    return (
        <S.Container showLogin={showLogin}>
            <S.Logo>
                <img src="https://storage.googleapis.com/static-images-source/horizontal-logo.png" alt="Solomon" />
            </S.Logo>
            <S.Title>Bem vindo de volta!</S.Title>
            <S.SubtitleSection>
                <S.Subtitle>Estamos felizes em te ver de novo! 👋</S.Subtitle>
                <S.Subtitle>Entre na sua conta abaixo.</S.Subtitle>
            </S.SubtitleSection>
            <form onSubmit={handleSubmit}>
                <InputLogin inputId="emailLogin" inputName="email" inputLabel="E-mail" inputType="text" width={isMobile ? '80vw' : '20.75rem'} setInputInformation={setUserForLogin} emailSuggestions={previousEmails} />
                <InputLogin inputId="passwordLogin" inputName="password" inputType="password" inputLabel="Senha" width={isMobile ? '80vw' : '20.75rem'} setInputInformation={setUserForLogin} />

                <S.ForgotPassword onClick={handleOpenForgotPassword}>Esqueceu sua senha?</S.ForgotPassword>
                <S.ButtonSection>
                    <S.Button type="submit">Entrar</S.Button>
                </S.ButtonSection>
            </form>
            {/* {showInvalidError && <S.SectionError>Preencha os campos corretamente.</S.SectionError>} */}
            {/* {showLoginError && <S.SectionError>Usuário ou Senha inválida.</S.SectionError>} */}

            {isModalOpen && ( 
            <Modal onClose={() => (setIsModalOpen(false))}>
                <S.ModalContent>
                    <LiveHelpRoundedIcon/>
                    <h2>Esqueceu sua senha?</h2>
                    <p>Por favor, insira seu e-mail abaixo para que possamos enviar<br/> as instruções para alterar sua senha.</p>

                    <S.InputSection>
                        <InputLogin inputId="emailLogin" inputName="email" inputLabel="E-mail" inputType="text" width={isMobile ? '80vw' : '25.75rem'} setInputInformation={setUserForLogin} emailSuggestions={previousEmails} />
                    </S.InputSection>

                    <S.ButtonEmail disabled={isLoadingPassword} style={isLoadingPassword ? {background: '#ccc', cursor: 'not-allowed' } : {}} onClick={handleSubmitForgotPassword}>
                        {isLoadingPassword ? 'Enviando...' : 'Enviar'}
                        {/* Enviar email */}
                        
                        </S.ButtonEmail>
                </S.ModalContent>
            </Modal>
        )}
        </S.Container>
    );
}
