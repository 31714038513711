import * as S from './style';
import { LineProductsCostsPropsInMass } from '../../../../@types';
import { toBrazilianFormat } from '../../../../utils';
import { format } from 'date-fns';
import { Checkbox, Tooltip } from '@mui/material';
import ModalProductHistoryCosts  from '../../ModalProductHistoryCosts';
import { useState } from 'react';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { HistoryIconStyled } from './style';
import { ButtonApp } from '../../../../components/ButtonApp';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
export function LineProductsCostsInMass({ product, selected, setSelected, dataProductCosts }: LineProductsCostsPropsInMass) {
    const { isVisible } = useVisibility();
    const { currencySymbol } = useCurrencyType();

    if(product === undefined){
        return(
            <S.Container>
            <S.Edit>
            </S.Edit>
            <S.Image></S.Image>
            <S.ProductName></S.ProductName>
            <S.Price></S.Price>
            <S.Cost></S.Cost>
            <S.LastSale></S.LastSale>
            <S.Historic>
            </S.Historic>
        </S.Container>
        )
    }
    const [showModal, setShowModal] = useState(false);

    const meanNonZero = (values: number[]) => {
        const nonZeroValues = values.filter(value => value !== 0);
        if (nonZeroValues.length === 0) return 0; // Handle the case where there are no non-zero values
        const sum = nonZeroValues.reduce((acc, val) => acc + val, 0);
        return sum / nonZeroValues.length;
    };
    
    const productPrices = product?.variants?.map((variant: any) => Number(variant?.price)) || [];
    const productCosts = product?.variants?.map((variant: any) => Number(variant?.cost)) || [];
    
    const meanProductPrice = meanNonZero(productPrices);
    const meanProductCost = meanNonZero(productCosts);

    const lastDate = !!product?.latest_order_date ? format(new Date(product?.latest_order_date), 'dd/MM/yyyy') : '-';

    function handleSelect() {
        if (selected.includes(product?.product_id)) {
            setSelected(selected.filter((id) => id !== product?.product_id));
        } else {
            setSelected([...selected, product?.product_id]);
        }
        
    }


    //console.log("currency", product?.variants[0]?.currency)

    let currencyTypeVariants = 'R$';

    if (product?.variants[0]?.currency === 'USD') {
        currencyTypeVariants = '$';
    } else if (product?.variants[0]?.currency === 'BRL'){
        currencyTypeVariants = 'R$';
    } 
    
    const handleImageError = (event) => {
        event.target.src = 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png';
    };

    return (
        <S.Container>
            {showModal && <ModalProductHistoryCosts variantIds={ product?.variants.map((variant:any) => variant.variant_id)} onClose={() => setShowModal(false)} product={product} dataVariantCosts={dataProductCosts}/>}
            <S.Edit>
                <S.CheckboxSection onClick={handleSelect}>
            <Checkbox
                    size="small"
                    checked={selected.includes(product?.product_id)}
                    sx={{
                        '&.Mui-checked': {
                            color: '#19c273',
                        },
                    }}
                />
            </S.CheckboxSection>
            </S.Edit>
            <S.Image>{product?.product_image_src?.length > 0 && <img src={isVisible ? product?.product_image_src : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt="product_image" onError={handleImageError} />}</S.Image>
            <S.ProductName>
            <Tooltip title={isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * * * *'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'nowrap',zIndex: 999999, lineHeight: '17px'}},}}>
                <p>
                    {isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * *'}
                </p>
            </Tooltip>
            </S.ProductName>
            <S.Price>{meanProductPrice === 0 ? 'R$ 0,00' : `${currencySymbol} ${toBrazilianFormat(meanProductPrice)}`}</S.Price>
            <S.Cost>{meanProductCost === 0 ? 'R$ 0,00' : 
            currencyTypeVariants  + `${toBrazilianFormat(meanProductCost)}`
            }</S.Cost>
            <S.LastSale>{product?.product_title?.length > 0 ? lastDate : ''}</S.LastSale>
            <S.Historic>
            <ButtonApp border='' width='130px' height='30px' background='var(--black)' color='white' icon={<HistoryIconStyled />} text='Histórico'  onClick={() => setShowModal(true)} />
            </S.Historic>
        </S.Container>
    );
}

