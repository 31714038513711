import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
`;

export const Container = styled.div`
width: 100%;
   ::-webkit-scrollbar {
        width: 5px;
    }
    
    height: 100vh;
    background: linear-gradient(-45deg, #0e1819, #0e1819, #204039, #265449);
    background-size: 400% 400%;
    animation: jJxjuT 15s ease infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 9%; */
    overflow-y: auto;
    justify-content: center;

    
`;

export const Content = styled.div`
    background-color: var(--white);
    width: 31.25rem;
    max-height: 100vh;
    padding: 1.25rem;
    border-radius: 8px;
  
    @media (max-width: 1050px) {
        width: 88vw;
    }
`;

export const InfoStore = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    padding: 0.25rem;
    border-radius: 8px;
    gap: 1rem;
        transition: all 0.25s ease 0s;
    padding: 0.5rem 0rem;
    &:hover {
        padding: 0.5rem 0.8rem;
        cursor: pointer;
        transition: all 0.25s ease 0s;
        transform: none;
        background-color: #f9f9f9;
    }
  
`;

export const UserAvatar = styled.div`
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    .svg {
        color: var(--green);
    }
`;

export const NameStore = styled.div`
    font-weight: 500;
    font-size: 1rem;
    color: var(--black);
`;

export const Status = styled.div`
    color: var(--green-800);
    background-color: var(--green100);
    height: 1.2rem;
    width: 5rem;
    font-size: 0.6875rem;
    padding: 0 0.1rem;
    border-radius: 22.17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

`;

export const DataStore = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const EmailUser = styled.div`
    color: var(--gray-250);
    font-weight: 500;
    font-size: 0.8125rem;
    height: 1rem;
    margin-top: 0.2rem;
`;

export const InnerContainer = styled.div``;

export const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    height: 1.875rem;
    margin-top: 2rem;
    color: var(--black);
    span {
        color: var(--green);
    }
`;

export const Subtitle = styled.div`
    font-size: 0.875rem;
    color: var(--gray-700);
    font-weight: 550;
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;

`;

export const ContainerButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:  1rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 95%;
    background-color: var(--gray-200);
    margin-top: 1.5rem;
`;

export const StoreList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 23rem);
    overflow-y: scroll;
`;
