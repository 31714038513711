// import { BackButton } from './../Terms/style';
import styled, { keyframes } from 'styled-components';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

const rotate = keyframes`
from {
  transform: rotate(0deg) scaleX(-1);
}
to {
  transform: rotate(360deg) scaleX(-1);
}
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // padding: 2rem 0rem 1rem 4rem;
    min-height: 100vh;
    
    height: auto;
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`;

// Criando um componente icon estilizado que aplica a animação
export const RotatingSettingsIcon = styled(SettingsRoundedIcon)`
  animation: ${rotate} 2s linear infinite;
`;

type ContainerConfigurePopupProps = {
    showConfigurePopup: boolean
}

export const ContainerPopup = styled.div<ContainerConfigurePopupProps>`
    display: none;    

    ${(props) =>
        props.showConfigurePopup
            ? `
            display: flex;
            margin: 1rem auto 0;
            opacity: 1;
            height: 5.5rem;
            visibility: visible;
            transition: opacity 0.3s, visibility 0.5s;
    
    `
            : `
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.5s;

    `}
    
        width: 22.5rem;
        position: absolute;
        padding: 0;
        background-color: #0e1819;
        color: #0e1819;
        text-align: center;
        border-radius: 11px;
        flex-direction: column;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
        align-items: center;
        margin-top: -4.5rem;
        margin-left: -3.5rem;
    

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    }
`;


export const PopupTitle = styled.div`
    font-size: 0.7125rem;
    color: var(--white);
    margin: 0 auto;
    font-weight: 550;
    margin-bottom: 8px;
    margin-top: 0.3rem;
`;

export const PopupSubtitle = styled.div`
    font-size: 0.8rem;
    font-weight: 550;
    color: #989c97;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;

export const ButtonPopup = styled.button`
    padding: 0;
    font-size: 0.8rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin-top: 0.75rem;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 0 auto;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }

`;


type CopyButtonProps = {
    show: boolean;
};

export const CopiedPopup = styled.div<CopyButtonProps>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 20px; /* Rounded border */
    font-size: 14px;
    z-index: 9999;
    opacity: ${props => props.show ? 1 : 0}; /* Start with opacity 0 */
    transition: opacity 0.5s, transform 0.5s ease-out; /* Transition for opacity and transform */
    transform: translateY(${props => props.show ? 0 : 20}px); /* Start from bottom, then move up */
`;

export const MainTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
   
    img {
        width: 7rem;
        height: 7rem;
        padding-bottom: 1rem;
        // margin-bottom: 2rem;

        // @media(max-width: 1440px){
        //     display: none;
        // }
    }

    h1 {
        font-weight: 500;
        color: var(--black);
        font-size: 1.6rem;
    }

    p {
        margin-top: 0.2rem;
        color: #989c97;
        text-align: center;
        width: 70%;
        font-size: 0.9rem;
    }

`

export const StepContent = styled.div`
display: flex;
flex-direction: column;
gap: 2rem;
align-items: center;
justify-content: center;
padding: 2rem 0;

@media(max-width: 1280px){
    transform: scale(0.9);
}

@media(min-width: 1800px){
    transform: scale(1.4);
}

`

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
`

export const QuestionContainer = styled.div`
    margin-top: 2rem;
    color: var(--black);
    font-size: 1.1rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    p{
        margin-bottom: 1rem;
        font-size: 1rem;
        color: #6d8080;
    }
`

export const PaymentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4rem;
    // margin-bottom: 4rem;
`

export const CardsContainerPayment = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--gray-700);
    font-weight: 500;
    gap: 1rem;

    p {
        font-size: 0.9rem;
    }
`

export const SalesContainer = styled.div`
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    width: 100%;
`

export const CurrencyArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;   
    align-items: center;
    justify-content: center;

    p {
        font-size: 0.9rem;
        color: #989c96;
        font-weight: 500;
    }
`

export const CardsContainer = styled.div`
    display: flex;
   flex-wrap: wrap;
   position: relative;
   width: 79%;
   align-items: center;
   justify-content: center;
   gap: 1.5rem;
`

export const InputArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 2rem;
margin-top: 3rem;

input {
    width: 90%;
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid #CCCCCC;
}
`


export const SideBar = styled.div`
background-color: #F5F8F7;
display: flex;
flex-direction: column;
height: 100vh;
gap: 1.5rem;
width: 23rem;
padding: 2rem;
h2 {
    font-size: 1.5rem;
    font-weight: 550;
}
`;


export const StepperContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0.2rem;
margin-bottom: 2rem;
    div {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            color: #989c97;
        }

        span {
            font-size: 0.9rem;
            color: #19c27e;
        }
    }
`;

export const Stepper = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 50px;
    background-color: #989c97;
`;

export const StepCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StepCard = styled.div`
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 8px;
    transition: all 250ms;
    align-items: center;
    h3 {
        font-size: 1rem;
        font-weight: 500;
        color: var(--black);
        margin-bottom: 0.2rem;
    }

    span {
        font-size: 0.8rem;
        color: #989c97;
    }
    
    
    &:hover {
        background-color: #FAFFFD;
        transition: all 250ms;

    }
  
`

export const UtmWarningContainer = styled.div`
    display: flex;
    font-size: 0.7rem;
    font-weight: 500;
    margin-top: 0.7rem;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    svg {
        fill: var(--red-500);
        width: 1rem;
    }
`

export const SectionInput = styled.div`
    position: relative;
    display: flex;
    gap: 1rem;    
    width: 78%;

    

    > svg {
            fill:#647577;
            cursor: pointer;
            width: 1.2rem;
    
            &:hover {
                fill: var(--black);
            }
      
        position: absolute;
        right: 1rem;
        bottom: 0.5rem;
    }

    input {
        border-radius: 7px;
        width: 100%;
        background-color: #F3F3F3;
        padding: 1rem 2.7rem 1rem 1rem;
        height: 10px;
    }
`;


export const ChangeContentArea = styled.div`
    // margin-top: 2rem;
    // width: 70%;
`

export const SectionInstructionsUtmContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    p {
        width: 100%;
        margin-top: 1rem;
        // margin-left: -1rem;
        text-align: start;
        font-size: 0.8rem;
        color: #989c97;
    }

    span{
        font-size: 0.8rem;
        color: var(--blue);
        // margin-left: -1rem;
        font-weight: 500;
        cursor: pointer;
        text-decoration: underline;
        text-align: start;
    }
`


export const CheckboxSection = styled.div`
font-size: 0.75rem;
line-height: 2rem;
font-weight: 600;
width: 100%;
display: flex;
align-items: center;
justify-content: start;
margin: 1.5rem 2rem 0rem 1rem;
cursor: pointer;
`;



export const SectionInstructionsAutomatic = styled.div`

display: flex;
flex-direction: column;
gap: 0.8rem;
width: 100%;
align-items: start;
margin-bottom: 1rem;

h5 {
    font-weight: 500;
    text-align: start;
}

`

export const SectionInputContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;

`

export const DropDownUtmContainer = styled.div<{ isActive: boolean, isIntegrated: boolean, isPlatformIntegrated: boolean }>`
    display: flex;
    padding: 0.7rem;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    transition: all 250ms;
    pointer-events:  ${props => props.isPlatformIntegrated ? 'all' : 'none'};
    cursor:  ${props => props.isPlatformIntegrated ? '' : 'not-allowed'};
    opacity:  ${props => props.isPlatformIntegrated ? '1' : '0.4'};
    border:  ${props => props.isIntegrated ? '1px solid var(--green)' : '1px solid #f1f1f1'};

    &:hover {
        border:  ${props => props.isIntegrated ? '1px solid var(--green)' : '1px solid #cecece'};
        transition: all 250ms;
    }

`

export const LeftArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    text-align: start;

    img {
        width: 1.7rem;
        height: 1.5rem;
    }
`

export const TitleFlex = styled.div`
    p{
        font-size: 1rem;
        font-weight: 550;
        color: var(--black);
        margin-bottom: -0.2rem;
    }

    span {
        font-size: 0.8rem;
        color: #989c97;    
    }
`

export const CardStep = styled.div`
display: flex;
align-items: center;
justify-content: center;
span {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--black);
}
width: 0.9rem;
height: 0.9rem;
padding: 0.9rem;
border-radius: 7px;
border: 1px solid #989c97;
display: flex;
`

export const TopArea = styled.div`
   display: flex;
   justify-content: space-between;
   width: 100%;
   cursor: pointer;
   align-items: center;
   svg {
    width: 1.2rem;
    fill: var(--gray-700);

    &:hover {
        cursor: pointer;
        fill: var(--black);
    }
}
`

export const BottomArea = styled.div`
margin-top: 1rem;
width: 100%;
flex-direction: column;
display: flex;
align-items: center;
justify-content: space-between;

`

export const UtmInstallContainer = styled.div`
   margin-top: 0rem;
   margin-bottom: 2rem;
   display: flex;
   width: 100%;
   flex-direction: column;
   margin-bottom: 2rem;
   gap: 1rem;
`

export const CardInfos = styled.div`
    display: flex;
    flex-direction: column;
`

export const MainText = styled.div`
    font-size: 2.1875rem;
    font-weight: 450;
    line-height: 2.625rem;
    margin-top: 3rem;
    color: #989c97;
`;

export const SecondaryText = styled.div`
    font-size: 2.1875rem;
    line-height: 2.625rem;

    font-weight: 600;
    color: var(--black);
`;

export const ContainerCardsSection = styled.div`
    position: relative;
    display: flex;
    margin-right: 4rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media(max-width: 1225px){
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;

export const VLine = styled.div`
height: 10rem;
width: 1px;
background-color: #989c97;
margin-top: 5rem;
@media(max-width: 1280px){
    display: none;
}
`;

export const InnerInfoContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
@media(max-width: 1240px){
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

`

export const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.6rem;
    font-weight: 450;
    color: #989c97;
    margin-bottom: 5rem;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    transition: 0.3s;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 56.4375rem;
    @media(max-width: 1600px){
        width: 70%;
    }
    @media(max-width: 1280px){
        width: 50%;
    }
`;

export const Button = styled.button`
    width: 17.875rem;
    height: 3.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 450;
    color: whitesmoke;
    background-color: var(--green);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #989c97;
        &:hover {
            box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
            transform: translateY(-7px);
        }
    }
`;

const floating = keyframes`
0%, 100% {
    transform: translateY(0px);
}
50% {
    transform: translateY(-10px);
}
`

export const ImageContainer = styled.div`
    position: relative;
    animation: ${floating} 4s ease-in-out infinite;;
    width: 16.625rem;
    img {
        width: 16.625rem;
        height: 37rem;
    }
`;

export const ContentSection = styled.div`
    width: 56.4375rem;
    @media(max-width: 1600px){
        width: 100%;
    }
`;

export const ContainerModal = styled.div`
    width: 37.5rem;
    min-height: 20rem;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
`;

export const ContainerIntegrationLoadingOnboarding = styled.div`
    position: absolute;
    top: 5.5rem;
    left: 1.5rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media(max-width: 1240px){
        flex-direction: column;
        align-items: center;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media(max-width: 1240px){
         width: 100%;
         &:last-child{
            margin-top: 1rem;
         }
    }
   
`;

export const InfoContainerText = styled.div`
    font-size: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    color: var(--green);
    text-align: center;
    @media(max-width: 1240px){
        text-align: left;
        padding-left: 5rem;
    }
`;

export const BackButton = styled.div`

    font-size: 0.75rem;
    margin-right: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    flex-direction: column;
    justify-content: center;
    color: var(--gray-700);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    svg {
        width: 1.3rem;
    }

    &:hover {
        text-decoration: underline;
        color: var(--green);
    }
    // @media(max-width: 1240px){
    //     padding-left: 5rem;
    // }
`;

export const NextButton = styled.div`

font-size: 0.75rem;
margin-left: 1rem;
    font-weight: 550;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;
    color: var(--gray-700);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    svg {
        width: 1.3rem;
    }
    &:hover {
        text-decoration: underline;
        color: var(--green);
    }
    // @media(max-width: 1240px){
    //     padding-left: 5rem;
    // }
`;



export const InnerSelect = styled.div`

    display: flex;
    flex-direction: column;
`;

export const SelectContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
gap: 2rem;
width: 97%;
margin: 2rem 0;
flex-direction: row;

    label {
        color: var(--gray-800);
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
    }
`;


export const TutorialContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: start;
    gap: 0.5rem;
    margin-bottom: 1rem;
    p {
        font-size: 0.9rem;
        color: #1e1e1e;
        font-weight: 550;
    }

    span{
        font-size: 0.8rem;
        color: var(--blue);
        // margin-left: -1rem;
        font-weight: 500;
        cursor: pointer;
        text-decoration: underline;
        text-align: start;
    }
`

export const NichoArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;   
    align-items: center;
    justify-content: center;

    p {
        font-size: 0.9rem;
        color: #989c96;
        font-weight: 500;
    }
`