import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getShopifyAuthorizationUrl } from '../../services/api/api-shopifyoauth';

export function ShopifyInstall() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    useEffect(() => {
        const shopParam = searchParams.get('shop');
        if (shopParam) {
            let shop = shopParam;
            const dots = shop.match(/\./g) || [];
            const slashes = shop.match(/\//g) || [];
            shop = dots.length ? shop.split('.')[0] : shop;
            shop = slashes.length ? shop.split('/')[-1] : shop;
            shop = shop.replace(' ', '');

            try {
                // Chama a função assíncrona e aguarda pela URL de autorização e redireciona para ela
                getShopifyAuthorizationUrl(shop).then((url) => {
                    window.location.href = url;
                    // //console.log('URL de autorização do Shopify:', url);
                });                
                
            } catch (error) {
                console.error('Error fetching Shopify authorization URL:', error);
                navigate('/error', { state: { message: "Error: could not fetch Shopify authorization URL." } });
            }
        } else {
            // Tratar o caso onde o parâmetro 'shop' não é encontrado
            navigate('/error', { state: { message: "Error: query parameter 'shop' not found. You must access /shopify/install?shop=yourshop.myshopify.com" } });
        }
    }, [navigate, searchParams]);

    // Como você mencionou que não quer renderizar nada, pode simplesmente retornar null
    // Ou algum componente de loading enquanto o redirecionamento não é completado
    return null;
};

