import { TemplateModal } from '../../index';
import * as S from './style';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { InputIntegration } from '../../../../../components/InputIntegration';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from 'react';
import { useCreateIntegrationShopify, useDeleteIntegrationShopify } from '../../../../../services/hooks/integrationHooks';
import useAuth from '../../../../../hooks/useAuth';
import { ModalShopifyOnboarding, userDTO } from '../../../../../@types';
import { useIntercom } from 'react-use-intercom';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';
import useAlert from '../../../../../hooks/useAlert';

export function ModalShopify({  setShowPopupShopify }: ModalShopifyOnboarding) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';
    const baseColor = '#19c380';
    const { user, currentCompany, updateUser, setUserContext } = useAuth();
    const { showAlert } = useAlert();
    const shopifyInformation = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts[user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length - 1];
    const { showArticle } = useIntercom();
    const hasShopifyInfo = shopifyInformation?.account_id !== 'default-account';
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);
    const [ missingScopes, setMissingScopes ] = useState("")

    //console.log('shopifyInformation', shopifyInformation)

    const [shopifyInfo, setShopifyInfo] = useState({
        dominioShopify: shopifyInformation?.account_id !== 'default-account' ? shopifyInformation?.account_id : '',
        accessTokenShopify: shopifyInformation?.assets?.token || '',
    });

    const { mutate: createIntegrationShopify, isError, isLoading: isLoadingIntegrate } = useCreateIntegrationShopify();
    const { mutate: deleteIntegrationShopify, isLoading: isLoadingDesintagrate } = useDeleteIntegrationShopify();

    function handleDisconnectShopify() {
        const body = {
            account_id: shopifyInfo.dominioShopify,
            company_id: currentCompany,
            truncate: true,
            user: user
        };

        showAlert('Desconectando Shopify... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);
        const inactive = {
            account_id: "default-account",
            assets: {},
            last_update: "",
            status: "inactive"
        }
        const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, shopify: { ...user?.companies?.[currentCompany]?.integrations?.shopify, accounts: [inactive] } } } } };
        setUserContext(newUser as userDTO);
        updateUser();
        setShowPopupShopify(false);
        setShowConfirmDisconnectPopup(false);
        
        deleteIntegrationShopify(body, {
            onSuccess: () => {
            
            },
        });
    }

    function handleCreateIntegrationShopify() {
        const body = {
            account_id: shopifyInfo.dominioShopify,
            access_token: shopifyInfo.accessTokenShopify,
            company_id: currentCompany,
            user: user,
        };
        createIntegrationShopify(body, {
            onSuccess: () => {
                setShowPopupShopify(false);
                const active = {
                    account_id: shopifyInfo.dominioShopify,
                    assets: {
                        token: shopifyInfo.accessTokenShopify,
                    },
                    last_update: "",
                    status: "active"
                }
                const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, shopify: { ...user?.companies?.[currentCompany]?.integrations?.shopify, accounts: [...user?.companies?.[currentCompany]?.integrations?.shopify?.accounts, active] } } } } };
      
                setUserContext(newUser as userDTO);
                updateUser();
            },
            onError(error: any) {
                if (error.message.includes('Missing required scopes')) {
                    const regex = /\[(.*?)\]/;
                    const message: string = error.message
                    const match = message.match(regex);

                    if (match) {
                        const items = match[1].split(',').map(item => item.trim().replace(/'/g, ''));
                        setMissingScopes(items.join(", "))
                    } 
                }
            },
        });
    }

    function handleChangeShopifyInfo(e: any) {
        setShopifyInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Shopify'}
                title={'Shopify'}
                subtitle={'Integre sua loja Shopify e acesse todos os seus dados de e-commerce diretamente em nossa plataforma.'}
                statusConnection={shopifyInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration
                    showPassword={false}
                    value={shopifyInfo.dominioShopify}
                    setValue={handleChangeShopifyInfo}
                    inputName="Dominio:"
                    inputId="dominioShopify"
                    placeholder="https://nomedaloja.myshopify.com"
                />
                <InputIntegration
                    showPassword={false}
                    value={shopifyInfo.accessTokenShopify}
                    setValue={handleChangeShopifyInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenShopify"
                    placeholder="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"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        {missingScopes != "" ? (
                            <S.ErrorText>
                                Falha na integração, você não garantiu acesso a todos os escopos necessários ao gerar o token. Os seguintes escopos estão faltando: <span>{missingScopes}</span>.
                            </S.ErrorText>
                        ) : (
                            <S.ErrorText>
                                Falha na integração, verifique se seu <span>domínio</span> ou <span>token de acesso</span> estão corretos e tente novamente.
                            </S.ErrorText>
                        )}
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() =>  showArticle(8726295)}>
                    <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meu domínio e token de acesso?
            </S.InfoContainer>

            {
                <S.DisconnectContainer>
                    {hasShopifyInfo && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowConfirmDisconnectPopup(true)}
                                text="Desconectar"
                                // isLoading={isLoadingDesintagrate}
                            />
                        </>
                    )}
                    {!hasShopifyInfo && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={handleCreateIntegrationShopify}
                                text="Integrar"
                                isLoading={isLoadingIntegrate}
                                disabled={shopifyInfo?.accessTokenShopify?.length === 0 || shopifyInfo?.dominioShopify?.length === 0}
                            />
                        </>
                    )}
                </S.DisconnectContainer>
            }

        {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDisconnectShopify}
                title="Confirmação necessária"
                isLoading={isLoadingDesintagrate}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )}
        </S.Container>
    );
}
