import * as S from './style'
import { Skeleton } from '@mui/material';


export function LineSalesModalLoadingOrders(isCustomers?: any) {


    return(
        <S.Container>
            <S.MainColumn><Skeleton sx={isCustomers ? { fontSize: '1rem', width: '12.1rem', height: '2rem'} : { fontSize: '1rem', width: '5rem', height: '2rem'}} /></S.MainColumn>
            <S.DefaultColumn>
                <S.Approved>
                    <Skeleton sx={{ fontSize: '1rem', width: '100%', height: '2rem', borderRadius: '50px' }} />
                </S.Approved>
            </S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} />

            </S.DefaultColumn>
            <S.DefaultColumn>

            <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} />
            
            </S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>
            <S.DefaultColumn><Skeleton sx={{ fontSize: '1rem', width: '3rem', height: '2rem', marginLeft: '0.8rem' }} /></S.DefaultColumn>

        


        </S.Container>
    )
}

