import { TemplateModal } from './../../index';
import * as S from './style';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateIntegrationYampi, useDeleteIntegrationYampi } from '../../../../../services/hooks/integrationHooks';
import useAuth from '../../../../../hooks/useAuth';
import { ModalYampiProps, userDTO } from '../../../../../@types';
import { useIntercom } from 'react-use-intercom';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';

export function ModalYampi({ setShowPopupYampi }: ModalYampiProps) {
    const urlLogo = 'https://i.ibb.co/vB7Hhnk/yampi.png';
    const baseColor = '#dd2385';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();

    const yampiInformation = user?.companies?.[currentCompany]?.integrations?.yampi?.accounts[user?.companies?.[currentCompany]?.integrations?.yampi?.accounts?.length - 1];

    const hasYampiInfo = yampiInformation?.account_id !== 'default-account';

    const { showAlert } = useAlert();
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);

    const { showArticle } = useIntercom();
    const { mutate: handleCreateIntegrationYampi, isLoading: isLoadingIntegrate, isError } = useCreateIntegrationYampi();
    const { mutate: handleDisconnectYampi, isLoading: isLoadingDesintagrate } = useDeleteIntegrationYampi();

    const [yampiInfo, setYampiInfo] = useState({
        aliasYampi: yampiInformation?.account_id !== 'default-account' ? yampiInformation?.account_id : '',
        accessTokenYampi: yampiInformation?.assets?.token || '',
        secretKeyYampi: yampiInformation?.assets?.secret_key || '',
    });

    function handleIntegrateIntegration() {
        const body = {
            account_id: yampiInfo.aliasYampi,
            token: yampiInfo.accessTokenYampi,
            secret_key: yampiInfo.secretKeyYampi,
            company_id: currentCompany,
            user: user
        };

        handleCreateIntegrationYampi(body, {
            onSuccess: () => {
                setShowPopupYampi(false);
                const active = {
                    account_id: yampiInformation?.account_id,
                    assets: {
                        token: yampiInformation?.assets?.token,
                        amount: 0,
                        plan: 'Basic',
                        secret_key: yampiInformation?.assets?.secret_key,
                        transaction_fee: 2.5,
                    },
                    last_update: "",
                    status: "active"
                }
                const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, yampi: { ...user?.companies?.[currentCompany]?.integrations?.yampi, accounts: [active] } } } } };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    function handleDisconnectIntegrationYampi() {
        const body = {
            account_id: yampiInfo.aliasYampi,
            company_id: currentCompany,
            truncate: true,
            user: user
        };

        showAlert('Desconectando Yampi... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);

        const inactive = {
            account_id: "default-account",
            assets: {},
            last_update: "",
            status: "inactive"
        }

        const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, yampi: { ...user?.companies?.[currentCompany]?.integrations?.yampi, accounts: [inactive] } } } } };
        setUserContext(newUser as userDTO);
        updateUser();
        setShowPopupYampi(false);

        setShowConfirmDisconnectPopup(false);

        handleDisconnectYampi(body, {
            onSuccess: () => {
            },
        });
    }

    function handleChangeYampiInfo(e: any) {
        setYampiInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Yampi'}
                title={'Yampi'}
                subtitle={'Integre sua loja com a Yampi e automatize seu processo de dropshipping.'}
                statusConnection={yampiInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration showPassword={false} value={yampiInfo.aliasYampi} setValue={handleChangeYampiInfo} inputName="Alias:" inputId="aliasYampi" placeholder="Solomon" />
                <InputIntegration
                    showPassword={false}
                    value={yampiInfo.accessTokenYampi}
                    setValue={handleChangeYampiInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenYampi"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                <InputIntegration
                    showPassword={false}
                    value={yampiInfo.secretKeyYampi}
                    setValue={handleChangeYampiInfo}
                    inputName="Chave secreta:"
                    inputId="secretKeyYampi"
                    placeholder="kh_ZjybughBUBYBUNjgygyutBybnbcJI4Gu337JV"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => {showArticle(8726310)}}>
                <HelpOutlineOutlinedIcon  sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
            {hasYampiInfo && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowConfirmDisconnectPopup(true)}
                                text="Desconectar"
                            />
                        </>
                    )}
                    {!hasYampiInfo && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={handleIntegrateIntegration}
                                text="Integrar"
                                isLoading={isLoadingIntegrate}
                                disabled={yampiInfo.aliasYampi?.length === 0 || yampiInfo.accessTokenYampi?.length === 0 || yampiInfo.secretKeyYampi?.length === 0}
                            />
                        </>
                    )}
            </S.DisconnectContainer>

            {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDisconnectIntegrationYampi}
                title="Confirmação necessária"
                isLoading={isLoadingDesintagrate}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )}
        </S.Container>
    );
}


