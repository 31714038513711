// GraphModal.tsx
import React from 'react';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { TooltipFormatterCallback } from 'echarts/types/dist/shared';
import { useCurrencyType } from '../../hooks/useCurrencyType';


type EChartsSeriesType = 'line' | 'bar' | 'scatter' | 'pie' | 'radar' | 'map' | 'tree' | 'treemap' | 'graph' | 'gauge' | 'funnel' | 'parallel' | 'sankey' | 'boxplot' | 'candlestick' | 'effectScatter' | 'pictorialBar' | 'themeRiver' | 'custom';

interface GraphData {
    xAxisData: string[];
    yAxisDatasets: { name: string; data: number[] }[];
    intervalData: number;
    graphType?: EChartsSeriesType; // Ajustado para incluir todos os tipos
    isMobile?: boolean;
}

function formatCurrency(value, currencySymbol) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }).format(value).replace('US$', currencySymbol);
}

function tooltipFormatter(data, currencySymbol) {
    return function (params) {
        let result = `${params[0].name}<br/>`;
        params.forEach(item => {
            const valueFormatted = formatCurrency(item.value, currencySymbol);
            result += `${item.marker}${item.seriesName}: ${valueFormatted}<br/>`;
        });
        return result;
    };
}


  const Graph: React.FC<GraphData> = ({   
    xAxisData,
    yAxisDatasets,
    intervalData,
    graphType,
    isMobile
}) => {
    
    const ref = React.useRef<HTMLDivElement>(null);
    const chartInstance = React.useRef<echarts.ECharts | null>(null);
    const { currencySymbol } = useCurrencyType();

    console.log('currencySymbol', currencySymbol);

    const seriesColors = [
        '#19c27e', '#5bc0de', '#3f51b5', '#317d9c', '#1f5a7a', 
        '#84B398', '#6E8075', '#324D3E', '#2B4D41', '#1aa58c', 
        '#1dd3c1', '#2ecc71', '#27ae60', '#3498db', '#2980b9', 
        '#16a085', '#34495e', '#2c3e50', '#1abc9c', '#45aaf2',
        '#0abde3', '#0a3d62', '#38ada9', '#3c6382', '#3b3b3b',
        '#2d3436', '#1e272e', '#40739e', '#4cd137', '#009432'
    ];
    
    
    // o boundarygap (false) faz com que o primeiro e último ponto da série fiquem na borda do gráfico, com o
    // grafico de barras, deve ser (true) pra que as barras fiquem centralizadas
    let boundaryGap = false;

    if (graphType == 'bar') {
        boundaryGap = true;
    }

    React.useEffect(() => {
        if (ref.current) {
            chartInstance.current = echarts.init(ref.current);
            
            
            let option: EChartsOption = {
                backgroundColor: "transparent", // Sem fundo
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985',
                        },
                    },
                    formatter: function (params) {
                        let content = `${params[0].name}<br/>`; // Adiciona o nome do eixo X (ou o que estiver sendo utilizado) no início
                        const maxItemsToShow = 10; // Número máximo de itens a exibir
                        const totalItems = params.length;
                
                        params.slice(0, maxItemsToShow).forEach(param => {
                            const valueFormatted = formatCurrency(param.value, currencySymbol); // Formatação monetária
                            content += `${param.marker}${param.seriesName}: ${valueFormatted}<br/>`;
                        });
                
                        if (totalItems > maxItemsToShow) {
                            const hiddenItemsCount = totalItems - maxItemsToShow;
                            content += `<br/><strong>+ ${hiddenItemsCount} ite${hiddenItemsCount > 1 ? 'ns' : 'm'}...</strong>`;
                        }
                
                        return content;
                    }
                },
                
                
                grid: {
                    left: '2%',
                    right: '5%',
                    top: isMobile ? '20%' : '15%',
                    bottom: isMobile ? "0%" : "3%",
                    containLabel: true,  
                },
                xAxis: {
                    type: "category",
                    boundaryGap: boundaryGap,
                    data: xAxisData, // Cria uma série de 10 pontos
                    axisLine: {
                        show: false, // Esconde a linha do eixo
                    },
                    axisTick: {
                        show: false, // Esconde os ticks do eixo
                    },
                    axisLabel: {
                        padding: isMobile ? [3,3,3,3] : [10, 10, 10, 10],
                        fontSize: isMobile ? 10 : 14,
                        show: true, // Mostra os rótulos do eixo X
                        interval: intervalData, // Define o intervalo entre os rótulos como 0 (mostrar todos os rótulos)
                        formatter: function (value) {
                            // Mapeia os nomes dos meses
                            const monthNames = [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai",
                                "Jun",
                                "Jul",
                                "Ago",
                                "Set",
                                "Out",
                                "Nov",
                                "Dez",
                            ];
    
                            // Divide a data em partes (ano, mês, dia)
                            const parts = value.split("-");
                            const day = parts[2];
                            const month = monthNames[parseInt(parts[1]) - 1]; // Subtrai 1 para obter o índice correto do array
                            return `${day}/${month}`;
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: false, // Esconde a linha do eixo
                    },
                    axisTick: {
                        show: false, // Esconde os ticks do eixo
                    },
                    axisLabel: {
                        formatter: function (value) {
                            return `${currencySymbol}${value.toFixed(2)}`;
                        },
                        fontFamily: 'Montserrat',
                        fontSize: isMobile ? 9 : 13,
                        color: 'gray',
                    },
                    splitLine: {
                        show: true, // Esconde as linhas da grade
                    },
                    splitNumber: 2,
                },
                series: yAxisDatasets.map((dataset, index) => ({
                    name: dataset.name,
                    data: dataset.data,
                    type: 'line', // Tipo de gráfico padrão é linha
                    smooth: false,
                    symbol: 'circle', 
                    symbolSize: 5,
                    areaStyle: {
                        // Utilizando um gradiente linear que começa com a cor definida para a série
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                { offset: 0, color: seriesColors[index % seriesColors.length] }, // Cor baseada no índice
                                { offset: 1, color: 'white' }, // Fundo branco no final do gradiente
                            ]
                        ),
                        // shadowColor: 'rgba(0, 0, 0, 0.1)',
                        // shadowBlur: 10,
                    },
                    itemStyle: {
                        color: seriesColors[index % seriesColors.length], // Cor do item baseada no índice
                        borderColor: seriesColors[index % seriesColors.length],
                        borderWidth: 3,
                        borderRadius: 5,
                    },
                })),                
            };

            if (graphType == 'bar' ) {
                option = {
                    backgroundColor: "transparent", // Sem fundo
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            label: {
                                backgroundColor: "#6a7985",
                            },
                        },
                        formatter: tooltipFormatter(yAxisDatasets, currencySymbol), // Passando o currencySymbol como argumento
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: boundaryGap,
                        data: xAxisData, // Cria uma série de 10 pontos
                        axisLine: {
                            show: true, // Esconde a linha do eixo
                        },
                        axisTick: {
                            show: false, // Esconde os ticks do eixo
                        },
                        axisLabel: {
                            show: true, // Mostra os rótulos do eixo X
                            interval: intervalData, // Define o intervalo entre os rótulos como 0 (mostrar todos os rótulos)
                            formatter: function (value) {
                                // Mapeia os nomes dos meses
                                const monthNames = [
                                    "Jan",
                                    "Fev",
                                    "Mar",
                                    "Abr",
                                    "Mai",
                                    "Jun",
                                    "Jul",
                                    "Ago",
                                    "Set",
                                    "Out",
                                    "Nov",
                                    "Dez",
                                ];
        
                                // Divide a data em partes (ano, mês, dia)
                                const parts = value.split("-");
                                const day = parts[2];
                                const month = monthNames[parseInt(parts[1]) - 1]; // Subtrai 1 para obter o índice correto do array
                                return `${day}/${month}`;
                            },
                        },
                    },
                    yAxis: {
                        type: "value",
                        axisLine: {
                            show: true, // Esconde a linha do eixo
                        },
                        axisTick: {
                            show: false, // Esconde os ticks do eixo
                        },
                        axisLabel: {
                            show: true, // Esconde os labels do eixo
                        },
                        splitLine: {
                            show: true, // Esconde as linhas da grade
                        },
                        splitNumber: 2,
                    },
                    series: yAxisDatasets.map((dataset, index) => ({
                        name: dataset.name,
                        data: dataset.data,
                        type: 'bar', // Tipo de gráfico padrão é linha
                        smooth: false,
                        symbol: 'circle', 
                        symbolSize: 5,
                        areaStyle: {
                            // Utilizando um gradiente linear que começa com a cor definida para a série
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: seriesColors[index % seriesColors.length] }, // Cor baseada no índice
                                    { offset: 1, color: 'white' }, // Fundo branco no final do gradiente
                                ]
                            ),
                            // shadowColor: 'rgba(0, 0, 0, 0.1)',
                            // shadowBlur: 10,
                        },
                        itemStyle: {
                            color: seriesColors[index % seriesColors.length], // Cor do item baseada no índice
                            borderColor: seriesColors[index % seriesColors.length],
                            borderWidth: 3,
                            borderRadius: 5,
                            fontSize: isMobile ? 5 : 16,
                        },
                    })),                
                };
            }
            chartInstance.current.setOption(option);

            return () => {
                chartInstance.current?.dispose();
            };
        }
    }, [xAxisData, yAxisDatasets, intervalData, graphType]);

    React.useEffect(() => {
        const handleResize = () => {
            chartInstance.current?.resize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="mainCardChart" ref={ref} style={{ width: '100%', height: '100%' }} />
    );
};

export default Graph;