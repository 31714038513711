import { useState, useEffect } from 'react';
import { Search } from '../../components/Search';
import { CardIntegration } from './CardIntegration';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ModalIntegration } from './ModalIntegration';
import { trackEvent } from '../../services/segment/sendEvent';
import * as S from './style';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { manualCheckoutGeneralLoadingRemove, storageOnboardingIntegrateUnicoPagGet } from '../../storage/storageOnboarding';
import { storageOnboardingIntegrateShopifyCheckoutGet } from '../../storage/storageOnboarding';
import { storageOnboardingIntegrateAdooreiGet } from '../../storage/storageOnboarding';
import { set } from 'date-fns';

// Função para extrair o tipo de integração da URL
const extractIntegrationTypeFromPath = (path: string) => {
    const segments = path.split('/');
    const integrationIndex = segments.findIndex(segment => segment === 'integracao');
    // Verifica se encontrou 'integracao' e se existe um segmento após 'integracao'
    if (integrationIndex !== -1 && segments.length > integrationIndex + 1) {
      return segments[integrationIndex + 1];
    }
    return ''; // Retorna string vazia se não encontrar um tipo válido
  };

export function Integration() {
    const location = useLocation();
    const integrationType = extractIntegrationTypeFromPath(location.pathname);
    const [onlySeeActive, setOnlySeeActive] = useState(false);    
    const [typeModal, setTypeModal] = useState(integrationType);
    const [showModal, setShowModal] = useState((integrationType !== '') ? true : false);
    const { user, currentCompany, updateUser } = useAuth();   
    const [hasShippingTax, setHasShippingTax] = useState(false);
    const [hasTaxConnected, setHasTaxConnected] = useState(false);
    
    const [filter, setFilter] = useState('');

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'integrations'
        });
    }, [])

    function handleModal(type: string) {
        setTypeModal(type);
        setShowModal(true);
    }

    const [isGatewayActive, setIsGatewayActive] = useState(false);
    const [hasAdooreiCheckout, setHasAdooreiCheckout] = useState(false);
    const [hasUnicoPagCheckout, setHasUnicoPagCheckout] = useState(false);
    const [hasShopifyCheckout, setHasShopifyCheckout] = useState(false);

    useEffect(() => {
        const costsRates = user?.companies[currentCompany]?.manual_inputs?.costs_rates;
        const manualCheckouts = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts; 
        const tax = user?.companies?.[currentCompany]?.manual_inputs?.tax; 
        const shippingTax = user?.companies?.[currentCompany]?.manual_inputs?.shipping?.calculation_rule; 

        
        if (manualCheckouts?.length < 2) {
            setHasAdooreiCheckout(false);
            setHasUnicoPagCheckout(false);
            setHasShopifyCheckout(false);
            manualCheckoutGeneralLoadingRemove(currentCompany);
            updateUser();
        }

        if (costsRates) {
            setIsGatewayActive(true);
        } else {
            setIsGatewayActive(false);
        }

        const hasAdooreiCheckoutConst = manualCheckouts?.some(checkout => 
            checkout.checkout === "Adoorei"
        );
    
        if (hasAdooreiCheckoutConst) {
            setHasAdooreiCheckout(true);
        }
    
        const hasUnicoPagCheckoutConst = manualCheckouts?.some(checkout => 
            checkout.checkout === "UnicoPag"
        );
    
        if (hasUnicoPagCheckoutConst) {
            setHasUnicoPagCheckout(true);
            // Execute a ação desejada aqui
        }

        const hasShopifyCheckoutConst = manualCheckouts?.some(checkout => 
            checkout.checkout === "Shopify Checkout"
        );
    
        if (hasShopifyCheckoutConst) {
            setHasShopifyCheckout(true);
            // Execute a ação desejada aqui
        } else {
            setHasShopifyCheckout(false);
        }


        if (shippingTax !== 'custo_nenhum') {
            setHasShippingTax(true);
            // Execute a ação desejada aqui
        }

        const hasTaxConnected = tax?.calculation_rule !== '' && tax?.aliquote_rate !== 0;

        if (hasTaxConnected) {
            setHasTaxConnected(true);
            // Execute a ação desejada aqui
        }

    }, [user?.companies, currentCompany, user?.companies[currentCompany]?.manual_inputs]);


    return (
        <S.Container>
            {showModal && <ModalIntegration hasShippingTax={hasShippingTax} setHasShippingTax={setHasShippingTax} hasTaxConnected={hasTaxConnected} setHasTaxConnected={setHasTaxConnected} hasAdooreiCheckout={hasAdooreiCheckout} hasUnicoPagCheckout={hasUnicoPagCheckout} hasShopifyCheckout={hasShopifyCheckout} setHasAdooreiCheckout={setHasAdooreiCheckout} setHasUnicoPagCheckout={setHasUnicoPagCheckout} setHasShopifyCheckout={setHasShopifyCheckout} type={typeModal} setShowModal={setShowModal} />}

            <S.Title><h2>Integrações</h2>
                <p>Explore e gerencie suas integrações para otimizar seu fluxo de trabalho e elevar a eficiência do seu ecommerce.</p>
            </S.Title>

            <S.SearchContainer>
                <Search placeholder="Pesquisar integração" width="25rem" value={filter} setValue={setFilter} />
                <S.FilterContainer>
                    <S.FilterOption isActive={!onlySeeActive} type={'Todos'} onClick={() => setOnlySeeActive(false)}>
                        <FiberManualRecordIcon
                            sx={{
                                width: '12px',
                                height: '12px',
                                color: '#d9dbd9',
                            }}
                        />{' '}
                        Todos
                    </S.FilterOption>
                    <S.FilterOption isActive={onlySeeActive} type={'Ativos'} onClick={() => setOnlySeeActive(true)}>
                        <FiberManualRecordIcon
                            sx={{
                                width: '12px',
                                height: '12px',
                                color: '#d9dbd9',
                            }}
                        />{' '}
                        Ativos
                    </S.FilterOption>
                </S.FilterContainer>
            </S.SearchContainer>

            <S.LineContainer>
                <S.Line></S.Line>
            </S.LineContainer>

            {(filter === '' || 'shopify'.startsWith(filter.toLowerCase())) && (                
                <S.Content>
                    <S.Section>Vendas</S.Section>
                    <S.InnerContent>
                        {(!filter || 'shopify'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.shopify?.accounts[user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="shopify"
                                    handleModal={() => handleModal('shopify')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.shopify?.accounts[user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                        {/* {(!filter || 'kiwify'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts[user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="kiwify"
                                    handleModal={() => handleModal('kiwify')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts[user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )} */}
                    </S.InnerContent>
                </S.Content>
            )}

            {(filter === '' || ('facebook'.startsWith(filter.toLowerCase()) || 'tiktok'.startsWith(filter.toLowerCase()) || 'google'.startsWith(filter.toLowerCase()))) && (
                <S.Content>
                    <S.Section>Marketing</S.Section>
                    <S.InnerContent>
                        {(!filter || 'facebook'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="facebook"
                                    handleModal={() => handleModal('facebook')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                        {(!filter || 'tiktok'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="tiktok"
                                    handleModal={() => handleModal('tiktok')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                    <S.InnerContent>
                        {(!filter || 'google'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="google"
                                    handleModal={() => handleModal('google')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.google_ads?.profiles?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                </S.Content>
            )}

            {(filter === '' || ('gateway'.startsWith(filter.toLowerCase()) || 'yampi'.startsWith(filter.toLowerCase()) || 'cartpanda'.startsWith(filter.toLowerCase()) || 'yever'.startsWith(filter.toLowerCase())) || 'dom'.startsWith(filter.toLowerCase())) && (
                <S.Content>
                    <S.Section>Pagamento</S.Section>
                    <S.InnerContent>
                        {(!filter || 'gateway'.startsWith(filter.toLowerCase())) && (
                            (isGatewayActive || !onlySeeActive) ? (
                                <CardIntegration
                                    source="gateway"
                                    handleModal={() => handleModal('gateway')}
                                    type={
                                        isGatewayActive ? 'active' : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                        
                        {(!filter || 'yampi'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.yampi?.accounts[user?.companies?.[currentCompany]?.integrations?.yampi?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="yampi"
                                    handleModal={() => handleModal('yampi')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.yampi?.accounts[user?.companies?.[currentCompany]?.integrations?.yampi?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                    <S.InnerContent>
                    {(!filter || 'cartpanda'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts[user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="cartpanda"
                                    handleModal={() => handleModal('cartpanda')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts[user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                        {(!filter || 'yever'.startsWith(filter.toLowerCase())) && (    
                            user?.companies?.[currentCompany]?.integrations?.yever?.accounts[user?.companies?.[currentCompany]?.integrations?.yever?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration 
                                    source="yever" 
                                    handleModal={() => handleModal('yever')} 
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.yever?.accounts[user?.companies?.[currentCompany]?.integrations?.yever?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                    <S.InnerContent>
                        {(!filter || 'dom'.startsWith(filter.toLowerCase())) && (
                            user?.companies?.[currentCompany]?.integrations?.dom?.accounts[user?.companies?.[currentCompany]?.integrations?.dom?.accounts?.length - 1]?.status === 'active' || !onlySeeActive ? (
                                <CardIntegration
                                    source="dom"
                                    handleModal={() => handleModal('dom')}
                                    type={
                                        user?.companies?.[currentCompany]?.integrations?.dom?.accounts[user?.companies?.[currentCompany]?.integrations?.dom?.accounts?.length - 1]?.status === 'active'
                                            ? 'active'
                                            : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                        {(!filter || 'adoorei'.startsWith(filter.toLowerCase())) && (
                            hasAdooreiCheckout === true || !onlySeeActive ? (
                                <CardIntegration
                                    source="adoorei"
                                    handleModal={() => handleModal('adoorei')}
                                    type={ hasAdooreiCheckout ? 'active' : 'inactive' }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                    <S.InnerContent>
                        {(!filter || 'unicopag'.startsWith(filter.toLowerCase())) && (
                           hasUnicoPagCheckout === true || !onlySeeActive ? (
                                <CardIntegration
                                    source="unicopag"
                                    handleModal={() => handleModal('unicopag')}
                                    type={
                                        hasUnicoPagCheckout ? 'active' : 'inactive'
                                    }
                                />
                            ) : null
                        )}

                        {(!filter || 'shopify_checkout'.startsWith(filter.toLowerCase())) && (
                           hasShopifyCheckout === true || !onlySeeActive ? (
                                <CardIntegration
                                    source="shopify_checkout"
                                    handleModal={() => handleModal('shopify_checkout')}
                                    type={
                                        hasShopifyCheckout ? 'active' : 'inactive'
                                    }
                                />
                            ) : null
                        )}
                    </S.InnerContent>
                </S.Content>
            )}

            {(filter === '' || 'impostos'.startsWith(filter.toLowerCase())) && ( 
                <S.Content>
                    <S.Section>Impostos</S.Section>
                    <S.InnerContent>
                        {(!filter || 'impostos'.startsWith(filter.toLowerCase())) && (
                            hasTaxConnected === true || !onlySeeActive ? (
                                <CardIntegration type={hasTaxConnected ? 'active' : 'inactive'} source="tax" handleModal={() => handleModal('tax')} />
                            ) : null
                        )}
                    </S.InnerContent>
                </S.Content>
            )}

            {(filter === '' || 'frete'.startsWith(filter.toLowerCase())) && ( 
                <S.Content>
                    <S.Section>Frete</S.Section>
                    <S.InnerContent>
                        {(!filter || 'frete'.startsWith(filter.toLowerCase())) && (
                            hasShippingTax === true || !onlySeeActive ? (
                                <CardIntegration type={hasShippingTax ? 'active' : 'inactive'} source="shipping" handleModal={() => handleModal('shipping')} />
                            ) : null
                        )}
                    </S.InnerContent>
                </S.Content>
            )}
        </S.Container>
    );
}
