import { useState, useEffect } from 'react';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useAuth from '../../hooks/useAuth';
import { useShopifySubscriptionData, useDeleteSubscriptionShopify } from '../../services/hooks/shopifySubscriptionHooks';
import { trackEvent } from '../../services/segment/sendEvent'
import * as S from './style';
import Modal from '../../components/Modal'; // Ajuste o caminho de importação conforme necessário
import {ButtonApp} from '../../components/ButtonApp'; // Ajuste o caminho de importação conforme necessário
import  SpinnerLoading from '../../components/SpinnerLoading';
import { formatDate, formatValue } from '../../utils/index.tsx';
import { getCheckoutURL } from '../../services/api/api-integration.ts';

export function ShopifySubscription() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const { user, currentCompany } = useAuth();
  const { data: shopifySubscriptionData, isLoading: isLoadingShopifyData } = useShopifySubscriptionData(currentCompany);

  useEffect(() => {
    trackEvent('pageview', {
        userId: user.user_id,
        timestamp: new Date().toISOString(),
        company_id: currentCompany,
        company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
        page: 'subscription'
    });
  }, [])

  const shopifyData = shopifySubscriptionData?.data.data;
  const { price, billing_on, id, name } = shopifyData || { status: '', price: '', next_billing_date: '' };
  const deleteShopifySubscription = useDeleteSubscriptionShopify();

  const handleDeleteSubscription = () => {
    setIsDeleting(true);
    deleteShopifySubscription.mutate(currentCompany, {
      onSuccess: () => {
        //console.log('Assinatura cancelada');
        setIsDeleting(false);
        handleCloseModal();
        window.location.reload();
      },
      onError: (error) => {
        setIsDeleting(false);
        console.error('Erro ao cancelar assinatura', error);
      },    
    });
  };

  const [isLoading, setIsLoading] = useState(false);


  const handleShopifyUrl = async () => {
    setIsLoading(true);
    const response = await getCheckoutURL(currentCompany, 'monthly', 'renew');
    const realUrl = response?.data.data;
    setIsLoading(false);
    //console.log("realUrl", realUrl)
    if (realUrl) {
        window.open(realUrl, '_blank');
    } else {
        console.error("URL is undefined");
    }
  };

  return (
    isLoadingShopifyData ?  <SpinnerLoading /> : (
    <S.Container>
      <S.Header>
        <S.Title>Assinatura</S.Title>
        <S.Subtitle>Veja detalhes e gerencie sua assinatura.</S.Subtitle>
      </S.Header>
      {shopifySubscriptionData ? (

      <S.PlanArea>
        <S.ActivePlanArea>
          <S.PlanStatus>Plano ativo</S.PlanStatus>
          <S.PlanName>
            <CardMembershipIcon fontSize="small" />
            {name}
          </S.PlanName>
          <S.PlanPrice>
            <S.PlanSubtitle>Valor total:</S.PlanSubtitle>
            <S.PriceValue>R${formatValue(price)}<span>/mês</span></S.PriceValue>
          </S.PlanPrice>
          <S.ButtonArea>
            <S.ButtonText onClick={handleOpenModal}>Cancelar assinatura</S.ButtonText>
          </S.ButtonArea>
        </S.ActivePlanArea>

        <S.VerticalLine />

        <S.PlanInfoArea>
          <S.PlanName>Período de pagamento</S.PlanName>
          <S.Subtitle>Pago mensalmente</S.Subtitle>
          <S.Line />
          <S.PlanName special>
            Renovação
            <span>({formatDate(billing_on)})</span>
          </S.PlanName>
          <S.Subtitle special>ID de pagamento: <span>#{id}</span></S.Subtitle>
        </S.PlanInfoArea>
      </S.PlanArea>
      ) : (
        <S.PlanArea special>
          <CardMembershipIcon fontSize="large" />
          <S.PlanStatus special>Você não possui um plano ativo</S.PlanStatus> 
          <S.PlanSubtitle>Para aproveitar todos os recursos, considere adquirir um plano.</S.PlanSubtitle>
          <ButtonApp border='' text="Ver planos" width="9.5rem" height="2.5rem" background='#19c27e' icon={""} color="white" onClick={handleShopifyUrl} isLoading={isLoading}/>
        </S.PlanArea>
      )}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <S.ModalContent>
            <h2>Cancelar assinatura</h2>
            <p>Tem certeza que deseja cancelar sua assinatura?</p>
            <ButtonApp border='' text="Sim, cancelar" width="9.5rem" height="2.5rem" background='#bf1f1f' icon={""} color="white" onClick={handleDeleteSubscription} isLoading={isDeleting} />
            <S.ButtonText special onClick={handleCloseModal}><ArrowBack fontSize='small'/>Não, voltar</S.ButtonText>
          </S.ModalContent>
        </Modal>
      )}
    </S.Container>
    )
  );
}