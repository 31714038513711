import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    
    height: calc(100vh - 3.5rem);
    margin-top: 4.5rem;
    display: flex;
    position: fixed;
    z-index: 1;
    justify-content: flex-start;
    width: 100vw;
`;


export const InnerContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;

    width: 100%;
    height: 100%;
    background-color: rgba(63, 63, 63, 0.66);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(4px);
        /* backdrop-filter: blur(4px); */
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

export const InnerContent = styled.div`


`


export const Content = styled.div`
background-color: #fff;
width: 24.125rem;
height: 34.4375rem;
padding: 0 1rem;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
`

export const LogoSolomon = styled.div`
img{
    width: 4.5625rem;
    height: auto;
}
`;

export const Image = styled.div`
img{
    width: 17.8125rem;
    height: auto;
}
`

export const Title = styled.div`
font-size: 1.125rem;
font-weight: 550;
line-height: 1.5rem;
text-align: center;
color: #0c1612;
span{
    color: var(--green);
}

`

export const Subtitle = styled.div`
text-align: center;
font-size: 0.75rem;
line-height: 1.125rem;
margin-top: 0.7rem;
color: #646464;
span{
    font-weight: 550;
}
`

const AnimationName = keyframes`
0% {
    background-position: 0% 50%;
}

50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}`

export const Button = styled.button`
width: calc(100% - 3rem);
height: 2.5rem;
margin: 0 auto;
    background: linear-gradient(259deg, #19c27e, #336a60);
    background-size: 400% 400%;
    animation: ${AnimationName} 23s ease infinite;
    border-radius: 7px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    margin-top: 0.7rem;
    cursor: pointer;
    svg{
        fill: #fff;
    }
    &:hover{
        transform: translateY(3px);
        transition: 0.2s;
    }

`
export const ButtonText = styled.div`
font-size: 0.75rem;
line-height: 1.125rem;
color: #646464;
font-weight: 400;
margin-top: 0.7rem;
&:hover{
    text-decoration: underline;
    cursor: pointer;
    color: var(--green);
}
`