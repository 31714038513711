import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import { trackEvent } from '../segment/sendEvent'

import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

//Create Company
export async function createCompanyOnboarding(body: DTO.createCompanyOnboardingDTO): Promise<AxiosResponse> {
    const { user_id, referred, company, email, mask_id, shopify_id } = body;
    const newBody = { referred, company, email, mask_id, shopify_id };
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/users/${user_id}/companies`, newBody);
    return data;
}

export async function renameCompanyOnboarding(body: DTO.renameCompanyOnboardingDTO): Promise<AxiosResponse> {
    const { user_id, company_id, company } = body;
    const newBody = { company, company_id };
    const data: AxiosResponse = await api.put(`${BASE_URL}/auth/users/${user_id}/companies`, newBody);
    return data;
}

export async function getBillingAccount(){
    const data: AxiosResponse = await api.get(`${BASE_URL}/billing/accounts`)
    return data
}

export async function createBillingAccount(){
    const body = {referred: false}
    const data: AxiosResponse = await api.post(`${BASE_URL}/billing/accounts`, body)
    return data
}

//Billing
export async function getCheckoutURL(company_id: string, plan: string, context?: string){
    const url = context ? `${BASE_URL}/billing/checkout?company_id=${company_id}&plan=${plan}&context=${context}` : `${BASE_URL}/billing/checkout?company_id=${company_id}&plan=${plan}`
    const data: AxiosResponse = await api.get(url)
    return data
}

//Shopify

export async function createIntegrationShopify(body: DTO.createIntegrationShopifyDTO): Promise<AxiosResponse> {
    const { company_id, account_id, access_token, user } = body;
    const newBody = { company_id, account_id, access_token };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/shopify`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'shopify',
        grid: 'ecommerce'
    });

    return data;
}

export async function updateIntegrationShopify(body: DTO.updateIntegrationShopifyDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/integrations/shopify`, body);
    return data;
}

export async function deleteIntegrationShopify(body: DTO.deleteIntegrationShopifyDTO): Promise<AxiosResponse> {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/shopify`, { data: newBody });

    await trackEvent('disconnect-platform', {   
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'shopify',
        grid: 'ecommerce'
    });
    
    console.log('dataDelete', data)
    return data;
}

//Shopify Beta

export async function createIntegrationShopifyBeta(body: DTO.createIntegrationShopifyBetaDTO): Promise<AxiosResponse> {
    const { compnay_id, account_id, auth_code, user } = body
    const newBody = { compnay_id, account_id, auth_code }
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/shopify/beta`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.compnay_id,
        company_name: user?.companies[body.compnay_id]?.company,
        platform: 'shopify',
        grid: 'ecommerce'
    })

    return data;
}

//NuvemShop

export async function createIntegrationNuvemShop(body: DTO.createIntegrationNuvemShopDTO): Promise<AxiosResponse> {
    const { company_id, auth_code, account_id, access_token, user } = body;
    const newBody = { company_id, account_id, auth_code, access_token };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/nuvemshop`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'nuvemshop',
        grid: 'ecommerce'
    });

    return data;
}

//Tiktok Ads

export async function getUrlTiktokAdsAuth(user_id: string, company_id: string, context:string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/integration/tiktok/url?user_id=${user_id}&context=${context}&company_id=${company_id}`);
    return data;
}

export async function getAccountsTiktokAds(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/integrations/tiktok-ads/profiles?company_id=${company_id}`);
    return data;
}

export async function updateAccountsTiktokAds(body: DTO.updateAccountsTiktokAdsDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/integrations/tiktok-ads/accounts`, body);
    return data;
}

export async function createIntegrationTiktokAdsAccount(body: DTO.createIntegrationTiktokAdsAccountDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/tiktok-ads/accounts`, body);
    return data;
}

export async function createIntegrationTiktokAdsProfiles(body: DTO.createIntegrationTiktokAdsProfilesDTO) {
    const { company_id, auth_code, user } = body;
    const newBody = { company_id, auth_code };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/tiktok-ads/profiles`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'tiktok',
        grid: 'marketing'
    });

    return data;
}

export async function syncTiktokAdsAccounts(company_id: string): Promise<AxiosResponse> {
    const body = {company_id}
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/tiktok-ads/sync`, body);
    return data;
}

export async function deleteAccountIntegrationTiktokAds(body: DTO.deleteIntegrationTiktokAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user } = body;
    const newBody = { company_id, profile_id,  account_id }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/tiktok-ads/accounts`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'tiktok_ads',
        grid: 'marketing'
    });

    return data;
}

export async function deleteProfileIntegrationTiktokAds(body: DTO.deleteIntegrationTiktokAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user, truncate } = body;
    const newBody = { company_id, profile_id,  account_id, truncate }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/tiktok-ads/profiles`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'tiktok_ads',
        grid: 'marketing'
    });

    return data;
}

//Facebook Ads



export async function getAccountsFacebookAds(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/integrations/facebook-ads/profiles?company_id=${company_id}`);
    return data;
}

export async function updateAccountsFacebookAds(body: DTO.updateAccountsFacebookAdsDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/integrations/facebook-ads/accounts`, body);
    return data;
}

export async function getUrlFacebookAdsAuthOnboarding(user_id: string, token: string, company_id: string, context: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/integration/facebook/url?user_id=${user_id}&context=${context}&token=${token}&company_id=${company_id}`);
    return data;
}

export async function getUrlFacebookAdsAuthLink(user_id: string, token: string, company_id:string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/integration/facebook/url?user_id=${user_id}&context=link&token=${token}&company_id=${company_id}`);
    return data;
}

export async function getUrlFacebookAdsAuthRevalidate(user_id: string, token: string, company_id:string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/integration/facebook/url?user_id=${user_id}&context=link&token=${token}&company_id=${company_id}`);
    return data;
}

export async function syncFacebookAdsAccounts(company_id: string): Promise<AxiosResponse> {
    const body = {company_id}
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/facebook-ads/sync`, body);
    return data;
}

export async function deleteAccountIntegrationFacebookAds(body: DTO.deleteIntegrationFacebookAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user } = body;
    const newBody = { company_id, profile_id,  account_id }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/facebook-ads/accounts`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'facebook_ads',
        grid: 'marketing'
    });

    return data;
}

export async function deleteProfileIntegrationFacebookAds(body: DTO.deleteIntegrationFacebookAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user, truncate } = body;
    const newBody = { company_id, profile_id,  account_id, truncate }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/facebook-ads/profiles`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'facebook_ads',
        grid: 'marketing'
    });

    return data;
}

export async function createIntegrationFacebookAdsAccount(body: DTO.createIntegrationFacebookAdsAccountDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/facebook-ads/accounts`, body);
    return data;
}

export async function createIntegrationFacebookAdsProfiles(body: DTO.createIntegrationFacebookAdsProfilesDTO): Promise<AxiosResponse> {
    const { auth_code, company_id, user } = body;
    const newBody = { auth_code, company_id}
    const headers = { 'User-Id' : body["User-Id"], Authorization: body['Authorization']}
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/facebook-ads/profiles`, newBody, {headers});

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'facebook_ads',
        grid: 'marketing'
    });

    return data;
}

//Google Analytics

export async function createIntegrationGoogleAnalyticsAccount(body: DTO.createIntegrationGoogleAnalyticsAccountDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/google-analytics/accounts`, body);
    return data;
}

export async function createIntegrationGoogleAnalyticsProfiles(body: DTO.createIntegrationGoogleAnalyticsProfilesDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/google-analytics/profiles`, body);
    return data;
}

//Google Ads

export async function syncGoogleAdsAccounts(company_id: string): Promise<AxiosResponse> {
    const body = {company_id}
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/google-ads/sync`, body);
    return data;
}

export async function createIntegrationGoogleAdsAccount(body: DTO.createIntegrationGoogleAdsAccountDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/google-ads/accounts`, body);
    return data;
}

export async function createIntegrationGoogleAdsProfiles(body: DTO.createIntegrationGoogleAdsProfilesDTO): Promise<AxiosResponse> {
    const { company_id, auth_code, user } = body;    
    const newBody = { company_id, auth_code };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/google-ads/profiles`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'google_ads',
        grid: 'marketing'
    });

    return data;
}

export async function getAccountsGoogleAds(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/integrations/google-ads/profiles?company_id=${company_id}`);
    return data;
}

export async function getUrlGoogleAdsAuth(user_id: string, company_id: string, context: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/integration/google/url?user_id=${user_id}&context=${context}&company_id=${company_id}`);
    return data;
}

export async function updateAccountsGoogleAds(body: DTO.updateAccountsGoogleAdsDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/integrations/google-ads/accounts`, body);
    return data;
}


export async function deleteAccountIntegrationGoogleAds(body: DTO.deleteIntegrationGoogleAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user } = body;
    const newBody = { company_id, profile_id,  account_id }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/google-ads/accounts`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'google_ads',
        grid: 'marketing'
    });

    return data;
}

export async function deleteProfileIntegrationGoogleAds(body: DTO.deleteIntegrationGoogleAdsDTO): Promise<AxiosResponse> {
    const { company_id, profile_id, account_id, user, truncate } = body;
    const newBody = { company_id, profile_id,  account_id, truncate }
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/google-ads/profiles`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'google_ads',
        grid: 'marketing'
    });

    return data;
}

//Cartpanda

export async function createIntegrationCartpanda(body: DTO.createIntegrationCartpandaDTO): Promise<AxiosResponse> {
    const { company_id, account_id, auth_token, plan, user } = body;
    const newBody = { company_id, account_id, auth_token, plan };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/cartpanda`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id].company,
        platform: 'cartpanda',
        grid: 'checkout'
    });

    return data;
}

export async function deleteIntegrationCartpanda(body: DTO.deleteIntegrationCartpandaDTO): Promise<AxiosResponse> {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/cartpanda`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'cartpanda',
        grid: 'checkout'
    });

    return data;

}

//Aliexpress

export async function createIntegrationAliexpress(body: DTO.createIntegrationAliexpressDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/aliexpress`, body);
    return data;
}

//Dsers

export async function createIntegrationDsers(body: DTO.createIntegrationDsersDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/dsers`, body);
    return data;
}

//Yampi

export async function createIntegrationYampi(body: DTO.createIntegrationYampiDTO): Promise<AxiosResponse> {
    const { company_id, account_id, secret_key, token, user } = body;
    const newBody = { company_id, account_id, secret_key, token };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/yampi`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'yampi',
        grid: 'checkout'
    });

    return data;
}


export async function deleteIntegrationYampi(body: DTO.deleteIntegrationYampiDTO): Promise<AxiosResponse> {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/yampi`, {data : newBody});

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'yampi',
        grid: 'checkout'
    });

    return data;
}

//Kiwify

export async function createIntegrationKiwify(body: DTO.createIntegrationKiwifyDTO): Promise<AxiosResponse> {
    const { company_id, account_id, client_secret, client_id, user } = body;
    const newBody = { company_id, account_id, client_secret, client_id };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/kiwify`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'kiwify',
        grid: 'ecommerce'
    });

    return data;
}


export async function deleteIntegrationKiwify(body: DTO.deleteIntegrationKiwifyDTO): Promise<AxiosResponse> {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/integrations/kiwify`, {data : newBody});

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'kiwify',
        grid: 'checkout'
    });

    return data;
}

//Geteway

export async function createIntegrationGateway(body: DTO.createIntegrationGatewayDTO): Promise<AxiosResponse> {
    const { credit_card, billet, pix, company_id, source, platform, last_update, user} = body;
    const newBody = {credit_card, billet, pix, company_id, source, platform, last_update};
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/gateway`, newBody);
    
    await trackEvent('configure-gateway', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id].company,
        platform: body.source
    });
    
    // console.log("entrou no gateway data", data)
    return data;
}

export async function getIntegrationGateway(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/gateway?company_id=${company_id}`);
    return data;
}


//Yever
export async function createIntegrationYever(body: DTO.createIntegrationYeverDTO): Promise<AxiosResponse> {
    const { company_id, token, user } = body;
    const newBody = { company_id, token };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/yever`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'yever',
        grid: 'checkout'
    });

    return data;
}

export async function deleteIntegrationYever(body: DTO.deleteIntegrationYeverDTO) {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data = await api.delete(`${BASE_URL}/client-services/integrations/yever`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'yever',
        grid: 'checkout'
    });

    return data;
}

// Dom

export async function createIntegrationDom(body: DTO.createIntegrationDomDTO): Promise<AxiosResponse> {
    const { company_id, token, user } = body;
    const newBody = { company_id, token };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/dom`, newBody);

    await trackEvent('connect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'dom',
        grid: 'gateway'
    });

    return data;
}

export async function deleteIntegrationDom(body: DTO.deleteIntegrationDomDTO) {
    const { company_id, account_id, truncate, user } = body;
    const newBody = { company_id, account_id, truncate };
    const data = await api.delete(`${BASE_URL}/client-services/integrations/dom`, { data: newBody });

    await trackEvent('disconnect-platform', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        platform: 'dom',
        grid: 'gateway'
    });

    return data;
}