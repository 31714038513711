const AUTH_STORAGE = '@solomon:token';
const USER_STORAGE = '@solomon:user';
const CURRENT_USER_STORAGE = '@solomon:current_user';
const STORE_ONBOARDING_STORAGE = '@solomon:onboarding';
const STORAGE_GET_DATA = '@solomon:get_data';
const STORAGE_INTEGRATION = '@solomon:integration';
const STORE_PLACEHOLDER_STORAGE = '@solomon:placeholder';
const SH_EXTERNAL_ID_STORAGE = '@solomon:sh_external_id';
const STORAGE_SHOP = '@solomon:shopify_company';

export { AUTH_STORAGE, USER_STORAGE, CURRENT_USER_STORAGE, STORE_ONBOARDING_STORAGE, STORAGE_GET_DATA, STORAGE_INTEGRATION, STORE_PLACEHOLDER_STORAGE, SH_EXTERNAL_ID_STORAGE, STORAGE_SHOP};
