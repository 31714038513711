import { useMutation, useQuery } from 'react-query';
import { getProducts, getProductsAnalytics, updateProductCost, getProductsCost, deleteProductCost, updateProductMarketingCost, updateProductTag, getProductsSyncCampaign, updateProductSyncCampaign, getProductsAnalyticsList, getProductsSyncAd, updateProductSyncAd } from '../api/api-products';
import useAlert from '../../hooks/useAlert';

export function useProductsAnalytics(start_date: string, end_date: string, company_id: string) {
    return useQuery(['productsAnalytics', start_date, end_date, company_id], () => getProductsAnalytics(start_date, end_date, company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useProducts(company_id: string) {
    return useQuery(['products', company_id], () => getProducts(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useProductsAnalyticsList(company_id: string) {
    return useQuery(['products', company_id], () => getProductsAnalyticsList(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useUpdateProductCost() {
    return useMutation(updateProductCost);
}

export function useDeleteProductCost() {
    return useMutation(deleteProductCost);
}

export function useGetProductCosts(company_id: string) {
    return useQuery(['productCosts', company_id], () => getProductsCost(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useUpdateProductMarketingCost() {
    const { showAlert } = useAlert();

    return useMutation(updateProductMarketingCost, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Custo atualizado com sucesso!', 'success', 5000, true);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao atualizar o custo. Tente novamente.', 'error', 5000, true);
        },
    });
}

export function useUpdateProductTag() {
    const { showAlert } = useAlert();

    return useMutation(updateProductTag, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Custo atualizado com sucesso!', 'success', 5000, true);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao atualizar o custo. Tente novamente.', 'error', 5000, true);
        },
    });
}

export function useGetProductsSyncCampaign(company_id: string) {
    return useQuery(['company_id', company_id], () => getProductsSyncCampaign(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useUpdateProductSyncCampaign() {
    const { showAlert } = useAlert();

    return useMutation(updateProductSyncCampaign, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Custo atualizado com sucesso!', 'success', 5000, true);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao atualizar o custo. Tente novamente.', 'error', 5000, true);
        },
    });
}

// Ads

export function useGetProductsSyncAd(company_id: string) {
    return useQuery(['company_id', company_id], () => getProductsSyncAd(company_id), {
        cacheTime: 0.0,
        refetchOnWindowFocus: true,
    });
}

export function useUpdateProductSyncAd(callback) {
    const { showAlert } = useAlert();

    return useMutation(updateProductSyncAd, {
        onSuccess: (data) => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Custo atualizado com sucesso!', 'success', 5000, true);
            // Atualiza os ads no modal
            callback(data.data.data);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao atualizar o custo. Tente novamente.', 'error', 5000, true);
        },
    });
}
