import React from 'react';
import * as S from './style'; // Certifique-se de ajustar o caminho conforme necessário
import FmdBad from '@mui/icons-material/FmdBad';
import Modal from '../Modal'; // Certifique-se de ajustar o caminho conforme necessário
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { ButtonApp } from '../ButtonApp';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

interface ConfirmDisconnectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  subtitle?: any;
  isLoading: boolean;
}

const ConfirmDisconnectModal: React.FC<ConfirmDisconnectModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Você tem certeza?',
  subtitle = 'Texto aqui.',
  isLoading,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <S.ContainerPopup>
        <S.IconPopup>
          <FmdBad sx={{ width: '35px', height: '35px' }} />
        </S.IconPopup>
        <S.TitlePopup>{title}</S.TitlePopup>
        <S.SubtitlePopup>{subtitle}</S.SubtitlePopup>
        <S.Line />
        <S.ButtonsContainer>
          <S.CancelButton onClick={onClose}> <ArrowBackRoundedIcon style={{width: '1.2rem', marginRight: '8px'}} /> Não, voltar</S.CancelButton>
          <ButtonApp
              border=''
              width="13.75rem"
              height="2.8rem"
              color="#fff"
              fontSize='0.8rem'
              background="#F41B19"
              iconWidth='20px'
              icon={<DeleteForeverRoundedIcon />}
              onClick={onConfirm}
              text="Sim, quero desconectar."
              isLoading={isLoading}
          />
        </S.ButtonsContainer>
      </S.ContainerPopup>
    </Modal>
  );
};

export default ConfirmDisconnectModal;
