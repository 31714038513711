import * as S from './style';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import { InputIntegrationProps } from '../../@types';

export function InputIntegration({ inputId, inputName, required = false, disabled = false, placeholder, value, setValue, showPassword = true, percentage }: InputIntegrationProps) {
    const [showText, setShowText] = useState(showPassword);

    return (
        <S.Container percentage={percentage}>
            <label htmlFor={inputId}>{inputName}</label>
            <input
                id={inputId}
                name={inputId}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                type={showText ? 'text' : 'password'}
                onChange={(e) => setValue(e)}
                value={value}
                autoComplete="off"
            />

        {percentage && 
            <S.InputIcon>
                <span>%</span><span>|</span>
            </S.InputIcon>
        }

            <S.VisibilityButton percentage={percentage} onClick={() => setShowText((prev) => !prev)}>
                {!showText ? <VisibilityOffOutlinedIcon sx={{ width: '16px', height: '16px' }} /> : <VisibilityOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
            </S.VisibilityButton>
        </S.Container>
    );
}
