
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import * as S from './style.ts';

export type AdLineInfo = {
    ad_id: string;
    ad_name: string;
    adset_id: string;
    adset_name: string;
    campaign_id: string;
    campaign_name: string;
    channel: string;
    sync: boolean;     // already synchronised
    selected: boolean; // selected to be synchronised
};

export type LineAdModalProps = {
    ad: AdLineInfo;
    setSyncAd: (checked: boolean, ad_id: any) => void;
};

export function LineAdModal ({ ad, setSyncAd }: LineAdModalProps) {
    return (
        <S.Container>
            <S.DefaultColumnCheck>
                <input 
                    type="checkbox" 
                    checked={ad.selected} 
                    onChange={e => setSyncAd(e.target.checked, ad.ad_id)} 
                />
            </S.DefaultColumnCheck>
            <S.DefaultColumnCheck>
                {ad?.channel.charAt(0).toUpperCase() + ad?.channel.slice(1)}
            </S.DefaultColumnCheck>
            <S.DefaultColumn>
                {ad?.campaign_name}
            </S.DefaultColumn>
            <S.DefaultColumn>
                {ad?.adset_name}
            </S.DefaultColumn>
            <S.DefaultColumn>
                {ad?.ad_name}
            </S.DefaultColumn>
            <S.DefaultColumnSync>
                {
                    ad.sync
                        ? <FiberManualRecordIcon sx={{ width: '12px', height: '12px', color: '#18C37F' }} />
                        : <FiberManualRecordIcon sx={{ width: '12px', height: '12px', color: '#d9dbd9' }} />
                }
            </S.DefaultColumnSync>
        </S.Container>
    );
}
