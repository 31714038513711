import { Banner } from '../../components/Banner';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import * as S from './style';
import { Search } from '../../components/Search';
import { TableProducts } from './TableProducts';
import { useProductsAnalytics } from '../../services/hooks/productsHooks';
import useAuth from '../../hooks/useAuth';
import { useState, useEffect } from 'react';
import Calendar from '../../components/Calendar';
import { format } from 'date-fns';
import { useIntercom } from 'react-use-intercom';
import { trackEvent } from '../../services/segment/sendEvent'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { calculateValueOccurrences } from './utils';
import { useRefreshContext } from '../../contexts/RefeshContext';
import { ButtonApp } from '../../components/ButtonApp';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import Modal from '../../components/Modal';
import PricingCalculator from './PricingCalculator';
export function Products() {
    const { user, currentCompany } = useAuth();
    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);
    const { data, isLoading, refetch } = useProductsAnalytics(date[0], date[1], currentCompany);
    const { refreshCounter } = useRefreshContext();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };

    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetch();
        }
      }, [refreshCounter]);

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'products'
        });
      }, [])
    

    useEffect(() => {
        setCostOperationDate((prevState: any) => {
            return {
                ...prevState!,
                startDate: date[0],
                endDate: date[1],
            };
        });
    }, [date]);

    const [filter, setFilter] = useState('');

    const products = data?.data?.data ?? [];
    const filtredProducts = products.filter((product) => product?.product_name?.toLowerCase().includes(filter.toLowerCase()));
    //console.log('filtredProducts', filtredProducts);

    const { showArticle } = useIntercom();

    const [costOperationDate, setCostOperationDate] = useState({
        value: '',
        frequency: 'monthly',
        date: '',
        startDate: date[0],
        endDate: date[1],
        product_id: '',
    });
    
    let costMarketingValue = [calculateValueOccurrences(costOperationDate?.startDate, costOperationDate?.endDate, costOperationDate?.frequency, costOperationDate?.date, costOperationDate?.value), costOperationDate?.product_id];

    return (
        <S.Container>
            
            <S.Title><h2>Produtos</h2>
                <p>Aprimore suas análises e entenda quais são seus produtos mais lucrativos</p>
            </S.Title>
            <div style={{display: 'flex', gap: '0.8rem', marginBottom: '-2.5rem', marginTop: '2rem'}}>
            <ButtonApp  
                border=''
                width="17rem"
                height="1.5rem"
                borderRadius='50px'
                iconWidth='14px'
                fontSize='0.9rem'
                color="#1B626A"
                background="#CDDDDF"
                icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                onClick={() => {showArticle(8726305)}}
                text="Como funcionam os Produtos?"
            />
       
            <ButtonApp  
                border=''
                width="16rem"
                height="1.5rem"
                borderRadius='50px'
                iconWidth='14px'
                fontSize='0.9rem'
                color="#1a6844"
                background="#cae1ca"
                icon={<CalculateRoundedIcon style={{width: '16px'}}/>}
                onClick={handleOpenModal}
                text="Calculadora de precificação"
            />
            </div>
                {/* <ButtonApp border='' width="14rem" height="2.8rem" background="var(--green)" text={'Calculadora de precificação'} icon={<CalculateRoundedIcon/>} iconWidth='20px' color="#fff" onClick={handleOpenModal} /> */}

            <S.Section>
                <S.FlexHeader>
                <Search width="30.5rem" placeholder="Buscar produto..." value={filter} setValue={setFilter} />
                </S.FlexHeader>
                <span>
                    <Calendar date={date} setDate={setDate} dashboard={false}/>
                </span>
            </S.Section>

            <S.Table>
                <TableProducts data={filtredProducts} isLoading={isLoading} costMarketingValue={costMarketingValue} setCostOperationDate={setCostOperationDate} />
            </S.Table>

            {!isLoading && filtredProducts?.length === 0 && (
                <S.ContainerImage>
                    <img src="https://solomon.com.br/wp-content/uploads/2023/10/vazio.psd.png" alt="Nenhum produto encontrado" />
                    <S.Title>Oops!</S.Title>
                    <S.Subtitle>Sem dados no período selecionado.</S.Subtitle>
                    <S.Subtitle>Por favor, escolha um novo período.</S.Subtitle>
                </S.ContainerImage>
            )}

    {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <S.ModalContent>
            <S.ModalHeader>
                <CalculateRoundedIcon/>
                <h2>Calculadora de <span>precificação</span></h2>
                <p>Defina o preço ideal dos seus produtos, considerando custos do fornecedor, markup e custos de operação.</p>

                <S.Line/>
            </S.ModalHeader>
            <PricingCalculator/>
          </S.ModalContent>
        </Modal>
      )}
        </S.Container>
    );
}
