import styled, { keyframes } from 'styled-components';


export const Container = styled.div`
    background-color: #f6f6f6;
    padding: 1rem 2rem 0;
    // height: 100vh;
    display: flex;
    justify-content: center;
    min-width: 74rem;

    ::-webkit-scrollbar {
        width: 5px !important;
    }
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Section = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.8rem;
    span {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

export const InnerContent = styled.div`
    min-width: 75.5625rem;
    max-width: 75.5625rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 0.8rem;
    margin-bottom: 5rem;
`;

export const ContentSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.0625rem;
    width: 100%;
`;

export const MetaSection = styled.div``;

export const CalendarContainer = styled.div`
  .flatpickr-input {
    background-color: rgb(255, 255, 255) !important;
  }, 
  display: flex;
    align-items: center;
    gap: 1rem;
`;
