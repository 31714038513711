import React, { useState, useRef } from 'react';
import * as S from './style';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { toBrazilianFormat } from '../../../utils';
import Tooltip from '@mui/material/Tooltip';
import { usePercentageInput } from '../../../utils'

const Calculator = () => {
    const [productCost, setProductCost] = useState('R$0');
    const [freightCost, setFreightCost] = useState('R$0');
    const [markup, setMarkup] = useState(2.5);
    const [operationCost, setOperationCost] = useState('R$0');
    const [icmsFee, setIcmsFee] = useState('0%');
    const [gatewayFee, setGatewayFee] = useState('0%');
    const [taxFee, setTaxFee] = useState('0%');
    const [gatewayRef, gatewayKeyDown] = usePercentageInput(setGatewayFee);
    const [taxRef, taxKeyDown] = usePercentageInput(setTaxFee);
    const [icmsRef, icmsKeyDown] = usePercentageInput(setIcmsFee);


    function formatValue(valueInput) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return 'R$' + newValue;
    }

    function formatPercentage(valueInput) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return newValue + '%';
    }


    function formatStringToNumber(text) {
        if (!text) return 0;
        text = text.replace(/R\$|\s|%/g, '');
        text = text.replace(/\./g, '');
        text = text.replace(/,/g, '.');
        const number = Number(text);
        return isNaN(number) ? 0 : number;
    }

    function handleChangeProductCost(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setProductCost(formatValue(value));
        }
    }

    function handleChangeFreightCost(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setFreightCost(formatValue(value));
        }
    }

    function handleChangeOperationCost(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setOperationCost(formatValue(value));
        }
    }

    function handleChangeIcmsFee(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setIcmsFee(formatPercentage(value));
        }
    }

    function handleChangeGatewayFee(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setGatewayFee(formatPercentage(value));
        }
    }

    function handleChangeTaxFee(e) {
        const value = e.target.value;
        const numericValue = formatStringToNumber(value);
        if (!isNaN(numericValue)) {
            setTaxFee(formatPercentage(value));
        }
    }

    function handleFocusValue(e) {
        const value = e.target.value;

        if (value === 0 || value === '0' || value === 'R$0') {
            e.target.setSelectionRange(0, value.length);
        }
    }
    

    function handleFocus(e) {
        const value = e.target.value;

        if (value === 0 || value === '0' || value === '0%') {
            e.target.setSelectionRange(0, value.length);
        }
    }

    const handleChangeMarkup = (e) => {
        let value = parseFloat(e.target.value);
        if (isNaN(value) || value < 1) {
            value = 1;
        }
        setMarkup(value);
    };

    const sellingPrice = (formatStringToNumber(productCost) + formatStringToNumber(freightCost)) * markup;
    const gatewayFeeCost = sellingPrice  * (formatStringToNumber(gatewayFee) / 100) ;
    const taxFeeCost = sellingPrice  * (formatStringToNumber(taxFee) / 100) ;
    const icmsFeeCost = sellingPrice  * (formatStringToNumber(icmsFee) / 100) ;
    const operationCostValue = formatStringToNumber(operationCost) ;

    const netProfit = sellingPrice - formatStringToNumber(productCost)  - formatStringToNumber(freightCost)  - operationCostValue  - gatewayFeeCost  - taxFeeCost  - icmsFeeCost ;

    const marginContributionCalc = (((sellingPrice  - formatStringToNumber(productCost)  - formatStringToNumber(freightCost)  - gatewayFeeCost  - taxFeeCost  - icmsFeeCost ) / sellingPrice ) * 100).toFixed(2);
    const marginContribution = marginContributionCalc === 'NaN' ? '0.00' : marginContributionCalc;
    
  return (
    <S.Container>
    <S.LeftSection>
        <S.Section>
            <S.SectionHeader>
                <InventoryRoundedIcon />
                Custos do fornecedor
            </S.SectionHeader>
            <S.FlexInputs>
                <S.InputGroup>
                    <S.InputLabel>Custo do produto</S.InputLabel>
                    <S.Input type="text" value={productCost} onChange={handleChangeProductCost} onFocus={handleFocusValue} />
                </S.InputGroup>
                <S.Line style={{marginTop: '1rem'}} />
                <S.InputGroup>
                    <S.InputLabel>Custo do frete</S.InputLabel>
                    <S.Input type="text" value={freightCost} onChange={handleChangeFreightCost} onFocus={handleFocusValue}/>
                </S.InputGroup>
            </S.FlexInputs>
        </S.Section>

        <S.Section>
            <S.SectionHeader>
                <FunctionsRoundedIcon />
                Definição do markup
            </S.SectionHeader>
            <S.InputGroupMarkup>
                <S.InputLabelMarkup>Markup
                    <Tooltip title="Markup é o fator usado para calcular o preço de venda de um produto com base no seu custo. Ele representa a margem de lucro desejada." placement='top'>
                    <HelpRoundedIcon/>
                    </Tooltip>
                    :</S.InputLabelMarkup>
                <S.Input type="number" step="0.5" value={markup} onChange={handleChangeMarkup} />
            </S.InputGroupMarkup>
        </S.Section>

        <S.ProductPriceContainer>
            <S.InputGroupProduct>
                <S.InputLabelProduct>Preço de venda do produto</S.InputLabelProduct>
                <Tooltip title={'R$' + toBrazilianFormat(sellingPrice || 0)} placement="top">
                <S.InputProduct><span>R$ </span>{toBrazilianFormat(sellingPrice || 0)}</S.InputProduct>
                </Tooltip>
            </S.InputGroupProduct>
        </S.ProductPriceContainer>

        <S.Section>
            <S.SectionHeader>
                <SettingsApplicationsRoundedIcon />
                Custos da operação
            </S.SectionHeader>
            <S.InputGroupOperation>
                <S.InputLabel>Custo médio tráfego:</S.InputLabel>
                <S.Input type="text" value={operationCost} onChange={handleChangeOperationCost}  onFocus={handleFocusValue} />
                <p style={{opacity: '0'}}>(R$ {'4,55'})</p>
            </S.InputGroupOperation>
            <S.InputGroupOperation>
                <S.InputLabel>Taxa média gateway:</S.InputLabel>
                <S.Input ref={gatewayRef} onKeyDown={gatewayKeyDown} type="text" value={gatewayFee} onChange={handleChangeGatewayFee} onFocus={handleFocus} />
                <Tooltip title={'R$' + toBrazilianFormat(gatewayFeeCost || 0)} placement="top">
               <p>(R$ {toBrazilianFormat(gatewayFeeCost || 0)})</p>
               </Tooltip>
                </S.InputGroupOperation>
            <S.InputGroupOperation >
                <S.InputLabel>Taxa média imposto:</S.InputLabel>
                <S.Input ref={taxRef} onKeyDown={taxKeyDown} type="text" value={taxFee} onChange={handleChangeTaxFee} onFocus={handleFocus} />
                <Tooltip title={'R$' + toBrazilianFormat(taxFeeCost || 0)} placement="top">
                <p>(R$ {toBrazilianFormat(taxFeeCost || 0)})</p>
                </Tooltip>
            </S.InputGroupOperation>
            <S.InputGroupOperation style={{ marginBottom: '0'}}>
                <S.InputLabel style={{marginRight: '3.5rem'}}>Taxa ICMS:</S.InputLabel>
                <S.Input ref={icmsRef} onKeyDown={icmsKeyDown} type="text" value={icmsFee} onChange={handleChangeIcmsFee} onFocus={handleFocus} />
                <Tooltip title={'R$' + toBrazilianFormat(icmsFeeCost || 0)} placement="top">
                <p>(R$ {toBrazilianFormat(icmsFeeCost || 0)})</p>
                </Tooltip>
                </S.InputGroupOperation>
        </S.Section>
    </S.LeftSection>

    <S.RightSection>
        <S.ResultContainer>
            <S.ResultHeader>Resultados</S.ResultHeader>
            <S.ResultItemProfit  style={{ borderColor: netProfit < 0 ? '#bf1f1f' : '#19c27e' }}>
                <AttachMoneyRoundedIcon style={{ backgroundColor: netProfit < 0 ? '#bf1f1f' : '#19c27e' }} />
                <S.ResultLabelProfit>
                    <p>Lucro Líquido</p>
                    <Tooltip title={'R$' + toBrazilianFormat(netProfit ? netProfit : 0)} placement="top">
                        <S.ResultValueProfit>
                            <p style={{ color: netProfit < 0 ? '#bf1f1f' : '#19c27e' }}>R$</p>
                            <h2 style={{ color: netProfit < 0 ? '#bf1f1f' : '#19c27e' }}>
                                {toBrazilianFormat(netProfit ? netProfit : 0)}
                            </h2>                        
                        </S.ResultValueProfit>
                    </Tooltip>
                </S.ResultLabelProfit>
            </S.ResultItemProfit>
            <S.FlexInputs style={{marginTop: '2rem', gap: '2rem'}}>
                <S.ResultItem>
                    <S.ResultLabel>Margem Líquida</S.ResultLabel>
                    <Tooltip title={isNaN(netProfit) || isNaN(sellingPrice) || sellingPrice === 0 ? '0.00%' : ((netProfit / sellingPrice) * 100).toFixed(2) + '%'} placement="top">
                    <S.ResultValue><h2>{isNaN(netProfit) || isNaN(sellingPrice) || sellingPrice === 0 ? '0.00' : ((netProfit / sellingPrice) * 100).toFixed(2)}</h2>
                    <p>%</p></S.ResultValue>
                    </Tooltip>
                </S.ResultItem>
                <div style={{opacity: '0'}}>
                <S.Line style={{marginTop: '-2rem'}}/>
                <S.ResultItem>
                    <S.ResultLabel>Margem Contribuição</S.ResultLabel>
                    <S.ResultValue><h2>{marginContribution || 0}</h2><p>%</p></S.ResultValue>
                </S.ResultItem>
                </div>
            </S.FlexInputs>
        </S.ResultContainer>

        <S.SimulationContainer>
            <S.SimulationHeader>Simulação <S.HLine style={{width: '55%', marginTop: '0px'}}/></S.SimulationHeader>
            <S.FlexSimulation>
                <S.SimulationLabel><p>Vendas</p></S.SimulationLabel>
                <S.SimulationLabel><p>Lucro</p></S.SimulationLabel>
            </S.FlexSimulation>
            {[5, 15, 30].map((sales) => {
                const profit = netProfit * sales;
                const formattedProfit = isNaN(profit) ? '0,00' : toBrazilianFormat(profit);
                
                return (
                    <S.SimulationItem key={sales}>
                        <S.FlexSimulation>
                            <S.SimulationLabel>{sales} <span>vendas</span></S.SimulationLabel>
                            <Tooltip title={'R$' + formattedProfit} placement="top">
                                <S.SimulationValue  style={{ color: profit < 0 ? '#bf1f1f' : '#19c27e' }}><span  style={{ color: profit < 0 ? '#bf1f1f' : '#19c27e' }}>R$</span> {formattedProfit}</S.SimulationValue>
                            </Tooltip>
                        </S.FlexSimulation>
                        <S.HLine style={{opacity: '1'}}/>
                    </S.SimulationItem>
                );
            })}
        </S.SimulationContainer>
    </S.RightSection>
</S.Container>
  );
};

export default Calculator;
