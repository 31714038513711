import styled from 'styled-components';

type ContainerProps = {
    width: string;
    canShowPowerPassword: boolean;
};

type PowerPasswordProps = {
    powerPassword: string;
};

export const DefaultPowerPassword = styled.div<PowerPasswordProps>`
    position: absolute;
    bottom: 0.8px;
    right: 0;
    height: 5px;
    border-bottom-right-radius: 15px;
    background-color: #e7e7e7;  // Cor padrão neutra

    ${(props) => props.powerPassword === 'default' ? `width: 100%;` : ''}  // Quando default, preenche totalmente a barra com a cor neutra
    ${(props) =>
        props.powerPassword && props.powerPassword === 'weak' ? `width: 67%; ` :
        props.powerPassword === 'medium' ? `width: 34%;` :
        'width: 0%;'}  // Outros estados ajustam a largura conforme a força da senha
`;

export const PowerPassword = styled.div<PowerPasswordProps>`
    position: absolute;
    bottom: 0.8px;
    left: 0;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    ${(props) =>
        props.powerPassword === 'default' ? `
            width: 100%;
            background-color: #e7e7e7;  // Cor neutra para o estado default
            ` :
        props.powerPassword === 'weak' ? `
            width: 33%;
            background-color: red;
            ` :
        props.powerPassword === 'medium' ? `
            width: 66%;
            background-color: yellow;
            ` :
        `width: 100%;
         background-color: #01FF00;  // Verde forte para senha forte
        `}
`;

export const Container = styled.div<ContainerProps>`
    position: relative;
    max-width: 100vw;

    /* ${(props) =>
        props.canShowPowerPassword &&
        `
border-bottom: 4px solid #e7e7e7;
border-radius: 10px;
`} */

    input {
        height: 2.2rem;
        border: 1px solid #e7e7e7;
        border-radius: 7px;
        background: none;
        padding: 0 0.5rem 0.25rem;
        font-size: 1rem;
        width: ${(props) => props.width};
        // max-width: 35rem;
        transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.75rem;
        line-height: 1.0625rem;

        &:not(:placeholder-shown) ~ label {
            transform: translateY(-50%) scale(0.8);
            background-color: #fff;
            padding: 0 0.2rem;
            color: var(--green);
        }

        &:not(:placeholder-shown) {
            ${(props) =>
                props.canShowPowerPassword
                    ? `
  border-top: 1.5px solid var(--green);
  border-left: 1.5px solid var(--green);
  border-right: 1.5px solid var(--green);
  `
                    : `
  border: 1.5px solid var(--green);`}
        }
        

    }

    label {
        position: absolute;
        left: 15px;
        color: #989c96;
        pointer-events: none;
        transform: translateY(0.9rem);
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.65rem;
        font-weight: 500;
    }

    input:focus {
        outline: none;
        ${(props) =>
            props.canShowPowerPassword
                ? `
  border-top: 1.5px solid var(--green);
  border-left: 1.5px solid var(--green);
  border-right: 1.5px solid var(--green);
  `
                : `
  border: 1.5px solid var(--green);`}
    }

    input:focus ~ label {
        transform: translateY(-50%) scale(0.8);
        background-color: #fff;
        padding: 0 0.2rem;
        color: var(--green);
    }
`;

export const VisibilityButton = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 0.8rem;
    right: 1.2rem;
    cursor: pointer;
`;

export const Line = styled.div``;
