import { formatPercentage, reduceString, toBrazilianFormat } from '../../../../../utils';
import * as S from './style'
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import Tooltip from '@mui/material/Tooltip';
import useVisibility from '../../../../../hooks/useVisibilityChange';
import { useColumnsTracking } from '../../../../../hooks/useColumnsTracking';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export function LineProductsModal( {metricsBySource, columnSolomon, totalRow, product, isCatalogCampaign, isFixedRow = false } : {metricsBySource?: any, columnSolomon?: any, totalRow?: any, product: any, isCatalogCampaign?: boolean, isFixedRow?: any  }) {
    const {currencySymbol} = useCurrencyType();
    const { isVisible } = useVisibility();
    const {columns} = useColumnsTracking();

    let revenueSolomon = 0;
    let salesSolomon = 0;

    if (columnSolomon === 'totalSolomon') {
        revenueSolomon = Number(product?.revenue) + Number(product?.pending_revenue) + Number(product?.cancelled_revenue);
        salesSolomon = Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales);
    } else if (columnSolomon === 'approvedSolomon') {
        revenueSolomon = Number(product?.revenue);
        salesSolomon = Number(product?.number_sales);
    } else if (columnSolomon === 'pendingSolomon') {
        revenueSolomon = Number(product?.pending_revenue);
        salesSolomon = Number(product?.pending_number_sales);
    } else if (columnSolomon === 'cancelledSolomon') {
        revenueSolomon = Number(product?.cancelled_revenue);
        salesSolomon = Number(product?.cancelled_number_sales);
    } 

    const handleImageError = (event) => {
        event.target.src = 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png';
    };

    const safeNumber = (value, defaultValue = 0) => {
    return isNaN(value) || !isFinite(value) ? defaultValue : value;
    };

    const calculateTotalRevenue = (product) => {
        return safeNumber(Number(product?.revenue)) + 
            safeNumber(Number(product?.pending_revenue)) + 
            safeNumber(Number(product?.cancelled_revenue)) 
    };

    const calculateCost = (product) => {
        return safeNumber(Number(product?.midia_spend));
    };

    const calculateRoas = (revenue, product) => {
        const cost = calculateCost(product);
        return cost !== 0 ? safeNumber(revenue / cost) : 0;
    };

    const calculateCpa = (revenue, product) => {
        const cost = calculateCost(product);
        return revenue !== 0 ? safeNumber(cost / revenue) : 0;
    };
    
    const productsRowData = {
        salesNotCatalog: Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales),
        salesQtd: Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales),
        totalRevenue: calculateTotalRevenue(product),
        approvedRevenue: Number(product?.revenue),
        pendingRevenue: Number(product?.pending_revenue),
        cancelledRevenue: Number(product?.cancelled_revenue),
        productCostRoot: Number(product?.cost),
        Tax: Number(product?.checkout_fee) + Number(product?.gateway_fee),
        spendBySource: calculateCost(product),
        Gateway: Number(product?.gateway_fee),
        Checkout: Number(product?.checkout_fee),
        campaignName: product?.product_id === "0" ? "Não identificado" : product?.product_name,
        productImg: product?.product_image,
        ProductPrice: Number(product?.cost),
        margin: ((Number(product?.profit) - calculateCost(product)) / Number(product?.revenue)) * 100,
        accountId: '-',
        addToCartsBySource: '-',
        approved_orders_boleto: '-',
        approved_orders_cc: '-',
        approved_orders_pix: '-',
        approved_revenue_boleto: '-',
        approved_revenue_cc: '-',
        approved_revenue_pix: '-',
        campaignId: '-',
        campaignSourceIcon: '-',
        cancelledOrders: Number(product?.cancelled_number_sales),
        cancelled_orders_boleto: '-',
        cancelled_orders_cc: '-',
        cancelled_orders_pix: '-',
        cancelled_revenue_boleto: '-',
        cancelled_revenue_cc: '-',
        cancelled_revenue_pix: '-',
        channelSelected: '-',
        checkoutBySource: Number(product?.checkout_fee),
        clicksBySource: Number(product?.link_clicks),
        cpaApproved: calculateCpa(Number(product?.revenue), product),
        cpaPending: calculateCpa(Number(product?.pending_revenue), product),
        cpaCancelled: calculateCpa(Number(product?.cancelled_revenue), product),
        cpaTotal: calculateCpa(calculateTotalRevenue(product), product),
        cpcBySource: calculateCost(product) / Number(product?.link_clicks) || 0,
        cpmBySource: calculateCost(product) / Number(product?.impressions) || 0,
        impressionsBySource: Number(product?.impressions),
        ordersBySource: Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales),
        orders_boleto: '-',
        orders_cc: '-',
        orders_pix: '-',
        pageViewsBySource: '-',
        pendingOrders: Number(product?.pending_number_sales),
        pending_orders_boleto: '-',
        pending_orders_cc: '-',
        pending_orders_pix: '-',
        pending_revenue_boleto: '-',
        pending_revenue_cc: '-',
        pending_revenue_pix: '-',
        productCostByUtmApproved: Number(product?.cost),
        profit: Number(product?.profit),
        revenueApproved: Number(product?.revenue),
        revenueCancelled: Number(product?.cancelled_revenue) + Number(product?.cancelled_shipping_fee),
        revenuePending: Number(product?.pending_revenue) + Number(product?.pending_shipping_fee),
        revenueTotal: calculateTotalRevenue(product),
        revenue_boleto: '-',
        revenue_cc: '-',
        revenue_pix: '-',
        roasApproved: calculateRoas(Number(product?.revenue), product),
        roasPending: calculateRoas(Number(product?.pending_revenue), product),
        roasTotal: calculateRoas(calculateTotalRevenue(product), product),
        roasCancelled: calculateRoas(Number(product?.cancelled_revenue) + Number(product?.cancelled_shipping_fee), product),
        totalOrders: Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales),
        salesUtm: columnSolomon === 'totalSolomon' ? Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales) : columnSolomon === 'approvedSolomon' ? Number(product?.number_sales) : columnSolomon === 'pendingSolomon' ? Number(product?.pending_number_sales) : columnSolomon === 'cancelledSolomon' ? Number(product?.cancelled_number_sales) : 0,
        revenueUtm: revenueSolomon,
        ticket: Number(product?.revenue) / (Number(product?.number_sales) + Number(product?.pending_number_sales) + Number(product?.cancelled_number_sales)),
        ticketUtm: revenueSolomon / salesSolomon,
        roasUtm: calculateRoas(revenueSolomon, product),
    };
    

    const columnData = {
        salesNotCatalog: isFixedRow ? metricsBySource?.salesNotCatalog : productsRowData?.salesNotCatalog,
        sales: isFixedRow ? metricsBySource?.salesQtd : '-',
        salesUtm: isFixedRow ? metricsBySource?.salesUtm : productsRowData?.salesUtm,
        revenueUtm: isFixedRow ? metricsBySource?.revenueUtm : productsRowData?.revenueUtm,
        revenue: isFixedRow ? metricsBySource?.revenue : '-',
        totalRevenue: isFixedRow ? metricsBySource?.totalRevenue : productsRowData?.totalRevenue,
        approvedRevenue: isFixedRow ? metricsBySource?.approvedRevenue : productsRowData?.approvedRevenue,
        pendingRevenue: isFixedRow ? metricsBySource?.pendingRevenue : productsRowData?.pendingRevenue,
        cancelledRevenue: isFixedRow ? metricsBySource?.cancelledRevenue : productsRowData?.cancelledRevenue,
        productCostRoot: isFixedRow ? metricsBySource?.productCostRoot : productsRowData?.productCostRoot,
        Tax: isFixedRow ? metricsBySource?.Tax : productsRowData?.Tax,
        spend: isFixedRow ? metricsBySource?.spendBySource : productsRowData?.spendBySource,
        margin: isFixedRow ? metricsBySource?.margin : '-',
        Gateway: isFixedRow ? metricsBySource?.Gateway : productsRowData?.Gateway,
        Checkout: isFixedRow ? metricsBySource?.Checkout : productsRowData?.Checkout,
        campaignName: isFixedRow ? metricsBySource?.campaignName : productsRowData?.campaignName,
        productImg: isFixedRow ? metricsBySource?.productImg : productsRowData?.productImg,
        ProductPrice: isFixedRow ? metricsBySource?.ProductPrice : productsRowData?.ProductPrice,
        accountId: isFixedRow ? metricsBySource?.accountId : productsRowData?.accountId,
        addToCartsBySource: isFixedRow ? metricsBySource?.addToCartsBySource : productsRowData?.addToCartsBySource,
        approved_orders_boleto: isFixedRow ? metricsBySource?.approved_orders_boleto : productsRowData?.approved_orders_boleto,
        approved_orders_cc: isFixedRow ? metricsBySource?.approved_orders_cc : productsRowData?.approved_orders_cc,
        approved_orders_pix: isFixedRow ? metricsBySource?.approved_orders_pix : productsRowData?.approved_orders_pix,
        approved_revenue_boleto: isFixedRow ? metricsBySource?.approved_revenue_boleto : productsRowData?.approved_revenue_boleto,
        approved_revenue_cc: isFixedRow ? metricsBySource?.approved_revenue_cc : productsRowData?.approved_revenue_cc,
        approved_revenue_pix: isFixedRow ? metricsBySource?.approved_revenue_pix : productsRowData?.approved_revenue_pix,
        campaignId: isFixedRow ? metricsBySource?.campaignId : productsRowData?.campaignId,
        campaignSourceIcon: isFixedRow ? metricsBySource?.campaignSourceIcon : productsRowData?.campaignSourceIcon,
        cancelledOrders: isFixedRow ? metricsBySource?.cancelledOrders : productsRowData?.cancelledOrders,
        cancelled_orders_boleto: isFixedRow ? metricsBySource?.cancelled_orders_boleto : productsRowData?.cancelled_orders_boleto,
        cancelled_orders_cc: isFixedRow ? metricsBySource?.cancelled_orders_cc : productsRowData?.cancelled_orders_cc,
        cancelled_orders_pix: isFixedRow ? metricsBySource?.cancelled_orders_pix : productsRowData?.cancelled_orders_pix,
        cancelled_revenue_boleto: isFixedRow ? metricsBySource?.cancelled_revenue_boleto : productsRowData?.cancelled_revenue_boleto,
        cancelled_revenue_cc: isFixedRow ? metricsBySource?.cancelled_revenue_cc : productsRowData?.cancelled_revenue_cc,
        cancelled_revenue_pix: isFixedRow ? metricsBySource?.cancelled_revenue_pix : productsRowData?.cancelled_revenue_pix,
        channelSelected: isFixedRow ? metricsBySource?.channelSelected : productsRowData?.channelSelected,
        checkoutBySource: isFixedRow ? metricsBySource?.checkoutBySource : productsRowData?.checkoutBySource,
        clicksBySource: isFixedRow ? metricsBySource?.clicksBySource : productsRowData?.clicksBySource,
        cpaApproved: isFixedRow ? metricsBySource?.cpaApproved : productsRowData?.cpaApproved,
        cpaPending: isFixedRow ? metricsBySource?.cpaPending : productsRowData?.cpaPending,
        cpaTotal: isFixedRow ? metricsBySource?.cpaTotal : '-',
        cpcBySource: isFixedRow ? metricsBySource?.cpcBySource : productsRowData?.cpcBySource,
        cpaCancelled: isFixedRow ? metricsBySource?.cpaCancelled : productsRowData?.cpaCancelled,
        cpmBySource: isFixedRow ? metricsBySource?.cpmBySource : productsRowData?.cpmBySource,
        impressionsBySource: isFixedRow ? metricsBySource?.impressionsBySource : productsRowData?.impressionsBySource,
        ordersBySource: isFixedRow ? metricsBySource?.ordersBySource : productsRowData?.ordersBySource,
        orders_boleto: isFixedRow ? metricsBySource?.orders_boleto : productsRowData?.orders_boleto,
        orders_cc: isFixedRow ? metricsBySource?.orders_cc : productsRowData?.orders_cc,
        orders_pix: isFixedRow ? metricsBySource?.orders_pix : productsRowData?.orders_pix,
        pageViewsBySource: isFixedRow ? metricsBySource?.pageViewsBySource : productsRowData?.pageViewsBySource,
        pendingOrders: isFixedRow ? metricsBySource?.pendingOrders : productsRowData?.pendingOrders,
        pending_orders_boleto: isFixedRow ? metricsBySource?.pending_orders_boleto : productsRowData?.pending_orders_boleto,
        pending_orders_cc: isFixedRow ? metricsBySource?.pending_orders_cc : productsRowData?.pending_orders_cc,
        pending_orders_pix: isFixedRow ? metricsBySource?.pending_orders_pix : productsRowData?.pending_orders_pix,
        pending_revenue_boleto: isFixedRow ? metricsBySource?.pending_revenue_boleto : productsRowData?.pending_revenue_boleto,
        pending_revenue_cc: isFixedRow ? metricsBySource?.pending_revenue_cc : productsRowData?.pending_revenue_cc,
        pending_revenue_pix: isFixedRow ? metricsBySource?.pending_revenue_pix : productsRowData?.pending_revenue_pix,
        productCostUtm: isFixedRow ? metricsBySource?.productCostByUtmApproved : productsRowData?.productCostByUtmApproved,
        profit: isFixedRow ? metricsBySource?.profit : productsRowData?.profit,
        revenueApproved: isFixedRow ? metricsBySource?.revenueApproved : productsRowData?.revenueApproved,
        revenueCancelled: isFixedRow ? metricsBySource?.revenueCancelled : productsRowData?.revenueCancelled,
        revenuePending: isFixedRow ? metricsBySource?.revenuePending : productsRowData?.revenuePending,
        revenueTotal: isFixedRow ? metricsBySource?.revenueTotal : productsRowData?.revenueTotal,
        revenue_boleto: isFixedRow ? metricsBySource?.revenue_boleto : productsRowData?.revenue_boleto,
        revenue_cc: isFixedRow ? metricsBySource?.revenue_cc : productsRowData?.revenue_cc,
        revenue_pix: isFixedRow ? metricsBySource?.revenue_pix : productsRowData?.revenue_pix,
        roasApproved: isFixedRow ? metricsBySource?.roasApproved : productsRowData?.roasApproved,
        roasPending: isFixedRow ? metricsBySource?.roasPending : productsRowData?.roasPending,
        roasTotal: isFixedRow ? metricsBySource?.roasTotal : productsRowData?.roasTotal,
        roasCancelled: isFixedRow ? metricsBySource?.roasCancelled : productsRowData?.roasCancelled,
        totalOrders: isFixedRow ? metricsBySource?.totalOrders : productsRowData?.totalOrders,
        roas: isFixedRow ? metricsBySource?.roasBySource : '-',
        roasUtm: isFixedRow ? metricsBySource?.roasUtm : productsRowData?.roasUtm,
        cpa: isFixedRow ? metricsBySource?.cpaTotal : '-',
        cpaUtm: isFixedRow ? metricsBySource?.cpaUtm : columnSolomon === 'totalSolomon' ? productsRowData?.cpaTotal : columnSolomon === 'approvedSolomon' ? productsRowData?.cpaApproved : columnSolomon === 'pendingSolomon' ? productsRowData?.cpaPending : columnSolomon === 'cancelledSolomon' ? productsRowData?.cpaCancelled : 0,
        cpc: safeNumber(isFixedRow ? metricsBySource?.cpcBySource : productsRowData?.cpcBySource),
        cpm: safeNumber(isFixedRow ? metricsBySource?.cpmBySource : productsRowData?.cpmBySource),
        impressions: isFixedRow ? metricsBySource?.impressionsBySource : productsRowData?.impressionsBySource,
        clicks: isFixedRow ? metricsBySource?.clicksBySource : productsRowData?.clicksBySource,
        viewContent: isFixedRow ? metricsBySource?.pageViewsBySource : '-',
        addToCart: isFixedRow ? metricsBySource?.addToCartsBySource : '-',
        initiateCheckout: isFixedRow ? metricsBySource?.checkoutsBySource : '-',
        ticket: isFixedRow ? metricsBySource?.ticket : '-',
        ticketUtm: isFixedRow ? metricsBySource?.ticketUtm : productsRowData?.ticketUtm,
    };

    const monetaryFields = ['ticketUtm','ticket', 'cpaUtm', 'cpc', 'cpm', 'revenueUtm', 'totalRevenue', 'approvedRevenue', 'pendingRevenue',  'cancelledRevenue', 'ProductPrice', 'productCostRoot', 'Tax', 'Gateway', 'Checkout', 'spend', 'productCostUtm', 'profit', 'revenueApproved', 'revenueCancelled', 'revenuePending', 'revenueTotal', 'revenue_boleto', 'revenue_cc', 'revenue_pix'];

    const percentageFields = ['margin'];
    
    const roasFields = ['roasApproved', 'roasPending', 'roasTotal', 'roasCancelled', 'roasUtm'];

    const formatFunctions = {
        default: (value) => value.toLocaleString('pt-BR'),
        monetary: (value) => `${currencySymbol} ${!isNaN(value) ? toBrazilianFormat(value) : 0}`,
        percentage: (value) => `${!isNaN(value) ? formatPercentage(value) + '%' : '0%'}`,
        roas: (value) => `${!isNaN(value) ? formatPercentage(value) + 'x' : '0,00x'}`,
    };

    const getFormatFunction = (columnId) => {
        if (monetaryFields.includes(columnId)) return formatFunctions.monetary;
        if (percentageFields.includes(columnId)) return formatFunctions.percentage
        if (roasFields.includes(columnId)) return formatFunctions.roas;
        // Adicione outras verificações para diferentes tipos de formatação
        return formatFunctions.default;
    };

    if (isCatalogCampaign) {
        return (
            <S.Container isCatalogCampaign={isCatalogCampaign} totalRow={totalRow} style={(isFixedRow ? { backgroundColor: 'white', fontWeight: 'bold', borderBottom: '2px solid #ccc', height:' 4rem', zIndex:'99', boxShadow: '1px 5px 6px 0px rgba(0,0,0, 0.05)', position: 'sticky', top: '55px' } : {})}>
                <>
                {totalRow === true ? (
                    <S.MainColumn style={{borderTop: '1px solid #ccc', bottom: '-2px'}} isFixedRow={isFixedRow}>
                            <p style={{marginLeft: '1rem'}}>Total</p>
                    </S.MainColumn>

                    ) : (

                    <S.MainColumn isVisible={isVisible} isFixedRow={isFixedRow}>
                        <img src={isVisible ? (columnData?.productImg ? columnData?.productImg : 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png') : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt={columnData?.productImg} onError={handleImageError} />
                        <Tooltip title={isVisible ? columnData?.campaignName : '* * * * * * * * * * * *'} placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '7px', padding: '10px 10px', color: 'white', textWrap: 'wrap', zIndex: 999999 } } }}>
                            <p>{isVisible ? columnData?.campaignName : '* * * * * * * * * * * *'}</p>
                        </Tooltip>
                    </S.MainColumn>
                )}
                </>
                {columns.map((column) => {
                    const formatFunction = getFormatFunction(column.id);
                    return (
                        <>
                            {column.id === 'profit' || column.id === 'margin' ? (
                                <S.DefaultColumn isFixedRow={isFixedRow} totalRow={totalRow} style={(columnData?.profit < 0 ? { color: 'var(--red-500)' } : {color: 'var(--green)'})} key={column.id}>
                                    {columnData[column.id] !== undefined ? formatFunction(columnData[column.id]) : 'N/A'}
                                </S.DefaultColumn>

                            ) : (
                                <S.DefaultColumn isFixedRow={isFixedRow} totalRow={totalRow} key={column?.id}>
                                    {columnData[column.id] !== undefined ? formatFunction(columnData[column?.id]) : 'N/A'}
                                    {isFixedRow && (column.id === 'salesUtm' || column.id === 'revenueUtm') && (
                                        <Tooltip title={column.id === 'revenueUtm' ? 'Os números abaixo, nas quebras por produto, representam as unidades vendidas. Em pedidos com mais de um produto, os valores podem não coincidir devido à inclusão do frete.' : 'Os números abaixo, nas quebras por produto, representam as unidades vendidas. Em casos de pedidos com mais de um produto, esses valores podem não coincidir.'} placement="top" arrow componentsProps={{ arrow: { sx: { color: 'var(--black)' } }, tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '13px', fontFamily: 'Montserrat', borderRadius: '9px', padding: '11px', color: 'white', textWrap: 'wrap', zIndex: 999999 } } }}>
                                            <InfoRoundedIcon sx={{ width: '15px !important', cursor: 'default !important', marginLeft: '0.5rem', fill: '#7b9898 !important' }} />
                                        </Tooltip>
                                    )}                                
                                </S.DefaultColumn>

                            )}
                        </>
                    );
                })}
            </S.Container>
        );
    }

    return(
        <S.Container totalRow={totalRow} style={(totalRow ? { backgroundColor: 'white', fontWeight: 'bold', borderTop: '1px solid #ccc'} : {})}>
             <>
                {totalRow === true ? (
                    <S.MainColumn isFixedRow={isFixedRow}>
                            <p style={{marginLeft: '1rem', color: 'var(--black) !important'}}>Total</p>
                    </S.MainColumn>

                    ) : (

                    <S.MainColumn>
                    <img src={ isVisible ? (columnData?.productImg ? columnData?.productImg : 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png' ) : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt={columnData?.campaignName} onError={handleImageError} />
                    <Tooltip title={isVisible ? columnData?.campaignName :'* * * * * * * * * * * *'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '7px',padding: '10px 10px',color: 'white',textWrap: 'wrap',zIndex: 999999}},
                    }}>                
                        <p>{isVisible ? columnData?.campaignName :'* * * * * * * * * * * *'}</p>
                    </Tooltip>
                    </S.MainColumn>
                )}
                </>
            
            <S.DefaultColumn totalRow={totalRow}>{columnData?.salesNotCatalog}</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.totalRevenue) ? toBrazilianFormat(columnData?.totalRevenue) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.approvedRevenue) ? toBrazilianFormat(columnData?.approvedRevenue) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.pendingRevenue) ? toBrazilianFormat(columnData?.pendingRevenue) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.cancelledRevenue) ? toBrazilianFormat(columnData?.cancelledRevenue) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.productCostRoot) ? toBrazilianFormat(columnData?.productCostRoot) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.Tax) ? toBrazilianFormat(columnData?.Tax) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.Gateway) ? toBrazilianFormat(columnData?.Gateway) : 0 }</S.DefaultColumn>
            <S.DefaultColumn totalRow={totalRow}>{currencySymbol} { !isNaN(columnData?.Checkout) ? toBrazilianFormat(columnData?.Checkout) : 0 }</S.DefaultColumn>
        </S.Container>
    )
}
