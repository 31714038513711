import { createContext, useState } from 'react';
import { ProviderProps, ToggleThemeContextProps } from '../@types';
import light from '../styles/themes/light';
import dark from '../styles/themes/dark';

const ToggleThemeContext = createContext<ToggleThemeContextProps>({
    theme: light,
    toggleTheme: () => {},
});

export const ToggleThemeProvider = ({ children }: ProviderProps) => {
    const [theme, setTheme] = useState(light);

    const toggleTheme = () => {
        setTheme(theme.title === 'light' ? dark : light);
    };

    return (
        <ToggleThemeContext.Provider
            value={{
                theme,
                toggleTheme,
            }}
        >
            {children}
        </ToggleThemeContext.Provider>
    );
};

export default ToggleThemeContext;
