import { LineTableTrackingAdsSetProps } from '../../../../@types';
import * as S from './style';
import { reduceString, toBrazilianFormat } from '../../../../utils';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAuth from '../../../../hooks/useAuth';
import { calculateFinalOrders, calculateFinalRevenue, checkUtm, getAdsIdByAdsSetId } from '../../utils';
import useVisibility from '../../../../hooks/useVisibilityChange';
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect, useMemo } from 'react';
import { getAdsDataWarning } from '../../utils';
import { getIconForPlatform } from '../../utils';
import { Checkbox } from '@mui/material';
import { useCheckboxTracking } from '../../../../hooks/useCheckboxTracking';
import { useColumnsTracking } from '../../../../hooks/useColumnsTracking';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import LinesItem from '../../LinesItem';
import StatusToggle from '../../StatusToggle';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';

export function LineTableTrackingAdsSet({ type, paymentMethod, adsSet, columnSolomon, handleSelectGraphicData, handleScrolltoGraphic, openSalesModal, date, dataMetric, columnWidths, dataCatalogList, loadingCatalog }: LineTableTrackingAdsSetProps) {
    const isMainSource = ['facebook', 'tiktok', 'google', 'allSources'].includes(Array.isArray(type) ? type[0] : type);

    const {currencySymbol} = useCurrencyType();

    const { currentCompany, user } = useAuth();

    const { columns } = useColumnsTracking();

    const isTypeArray = Array.isArray(type);
    const [_, sourceName, sourceUrl] = type;

    const sourceUrlIcon = useMemo(() => getIconForPlatform(isTypeArray ? sourceName : type), [isTypeArray ? sourceName : type]);

    const { selectedAdSetIds, toggleAdSetId } = useCheckboxTracking();

    const { isVisible } = useVisibility();

    const adsString = useMemo(() => getAdsIdByAdsSetId(dataMetric, adsSet?.adsSetId, isTypeArray ? sourceName : type, dataCatalogList), [dataMetric, adsSet?.adsSetId, isTypeArray ? sourceName : type, dataCatalogList]);
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(adsSet?.adsSetName)
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000); // Hide popup after 2 seconds
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    const filteredAdsData = useMemo(() => getAdsDataWarning(dataMetric, isTypeArray ? sourceName : type), [dataMetric, isTypeArray ? sourceName : type]);
    const [showWarning, setShowWarning] = useState({
        alert: false,
        popup: false
    });

    
    function utm_is_right(type: any, filteredAdsData: any, adsString: string) {
        type = type.split('_')?.[0];
        const adsStrings = adsString.split(',').map(str => str.trim());
        let isUtmCorrect = true;

        
        // Usando um loop for tradicional que permite o uso de 'break'
        for (const adString of adsStrings) {
            let AdsData = filteredAdsData[adString];
            if (!AdsData) {
                continue;  
            }

            if (!AdsData) {
                continue;  
            }
            if (!checkUtm(type, AdsData.utm_source, AdsData.utm_medium, AdsData.utm_campaign, AdsData.utm_content)) {                
                isUtmCorrect = false;
                break; // Isso agora funciona porque estamos dentro de um loop for
            }
        }
        if (!isUtmCorrect) {
            setShowWarning(prevState => ({
                ...prevState,
                alert: true // Assuming you meant to set true if an incorrect UTM is found
            }));
        }
    }

    useEffect(() => {
        utm_is_right(isTypeArray ? sourceName : type, filteredAdsData, adsString);
    }, [adsString, filteredAdsData, isTypeArray ? sourceName : type]);


    

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);

    useEffect(() => {
        if (paymentMethod) {
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect
    
    const revenueSolomon = calculateFinalRevenue( columnSolomon, adsSet, activePaymentMethods);
    const ordersSolomon = calculateFinalOrders( columnSolomon, adsSet, activePaymentMethods);   


    const adsSetNameHasCatalogPlaceholder = adsSet?.isCatalog === true;

    // //console.log('adsset', adsSet?.adsSetName);

    const handleCatalogClick = () => {
        openSalesModal({
            source: 'facebook',
            company_id: currentCompany,
            ads_ids: adsString ?? '',
            level: 'adgroup',
            start_date: date[0],
            end_date: date[1],
            name: adsSet?.adsSetName
        }, adsSet, 'adsSet', true, ordersSolomon, revenueSolomon )
    };

    
    return (
        <>
            <S.Container>
                <S.MainColumn style={{ width: `${columnWidths}px` }} type={isTypeArray ? sourceName : type}>
                    <S.CheckBoxArea>
                        <Checkbox
                            checked={selectedAdSetIds.some(item =>
                                item.id === adsSet?.adsSetId &&
                                item.type === (isTypeArray ? sourceName : type)
                            )}
                            onChange={() => toggleAdSetId(adsSet?.adsSetId, isTypeArray ? sourceName : type)}
                            size="small"
                            sx={{
                                '&.Mui-checked': {
                                    color: '#19c273',
                                },
                            }}
                        />
                    </S.CheckBoxArea>

                    <S.StatusArea>
                        {adsSet?.sourceType === 'facebook' && (
                            <StatusToggle
                                initialStateData={adsSet?.adsSetStatus}
                                objectId={adsSet?.adsSetId}
                                objectType={'adsets'}
                                data={adsSet}
                                currentCompany={currentCompany}
                                user={user}
                            />
                        )}
                      
                    </S.StatusArea>
                    <img src={Array.isArray(type) ? sourceUrl : sourceUrlIcon} alt={""} />
                    <S.CampaignTitleContainer>
                        <S.UrlLinkClick onClick={() => toggleAdSetId(adsSet?.adsSetId, isTypeArray ? sourceName : type, 'ad')}>{isVisible ? adsSet?.adsSetName : '* * * * * * * * * * * * *'}</S.UrlLinkClick>
                        <S.ChannelSelectedContainer hasCatalog={adsSetNameHasCatalogPlaceholder}>
                            <Tooltip title={sourceName === 'outros' ? 'Orgânico / Outros' : adsSet?.campaignName || ''} placement="bottom">
                                <p>{isVisible ? adsSet.campaignName : '* * * * * * * * *'}</p>
                            </Tooltip>
                            {adsSetNameHasCatalogPlaceholder && (
                                <S.CatalogIcon onClick={handleCatalogClick}> <ViewModuleRoundedIcon /> Catálogo</S.CatalogIcon>
                            )}
                        </S.ChannelSelectedContainer>
                    </S.CampaignTitleContainer>
                    <S.ActionArea>
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ width: '16px', height: '16px' }} />
                    <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                borderColor: '#E90E0E',
                                fontWeight: '550',
                                fontSize: '13px',
                                fontFamily: 'Montserrat',
                                borderRadius: '7px',
                                border: 1,
                                padding: '10px',
                                zIndex: 0,
                                color: '#E90E0E',
                            }
                        },
                    }}
                    title={'Existem anúncios no período selecionado com UTMs incorretas'} placement="right">
                        <S.ProductNameContainer style={showWarning?.alert ? {} : {display: 'none'}}>{showWarning?.alert && <WarningIcon />}</S.ProductNameContainer>
                    </Tooltip>

                    {/* { adsSetNameHasCatalogPlaceholder && (
                        <S.CatalogIcon onClick={handleCatalogClick}> <ViewModuleRoundedIcon /> Ver produtos </S.CatalogIcon>

                    )} */}
                    </S.ActionArea>
                </S.MainColumn>
                {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}


                <LinesItem HasCatalog={adsSetNameHasCatalogPlaceholder} handleCatalogClick={handleCatalogClick} columns={columns} currencySymbol={currencySymbol} ads={adsSet} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} type={'adsSet'} openSalesModal={openSalesModal}  currentCompany={currentCompany} date={date} adsString={adsString} source={isTypeArray ? sourceName : type} user={user} loadingCatalog={loadingCatalog}/>

            </S.Container>
        </>
    );
}

function WarningIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
        </svg>
    )
}
