import * as S from './style';
import  { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';

export function Terms() {
    const [selectedTab, setSelectedTab] = useState('terms');
    const isMobile = useIsMobile();

    const handleTermosBtn = () => {
        setSelectedTab('terms');
    };

    const handlePrivBtn = () => {
        setSelectedTab('privacy');
    };

    const navigate = useNavigate();

    

    const handleNavigate = () => {
        if (!isMobile){
            navigate('/dashboard');
        } else if (isMobile){
            navigate('/dashboardMobile');
        }
    }

    return (
        <S.Container>
            <S.Logos>
                    <S.BackButton onClick={() => handleNavigate()}>
                        <h2>← Voltar</h2>
                    </S.BackButton>
                <S.LogoImg
                    src="https://solomon.com.br/wp-content/uploads/2023/06/favicon-solomon.png"
                />
            </S.Logos>

            <S.FlexContainer>
                <S.Button onClick={handleTermosBtn} selected={selectedTab === 'terms'}>
                    <h2>Termos e condições</h2>
                </S.Button>

                <S.Button onClick={handlePrivBtn} selected={selectedTab === 'privacy'}>
                    <h2>Política de privacidade</h2>
                </S.Button>
            </S.FlexContainer>

            <S.TermsPolicy style={{ display: selectedTab === 'terms' ? 'block' : 'none' }}>
                <S.Title>Termos e condições gerais de uso do aplicativo</S.Title>
                <S.SectionCont>
                    Os serviços da Solomon são fornecidos pela pessoa jurídica com a
                    seguinte Razão Social: SOLOMON BRASIL LTDA com nome fantasia
                    SOLOMON BRASIL, inscrito no CNPJ sob o nº 50.951.561/0001-44,
                    titular da propriedade intelectual sobre software, website,
                    aplicativos, conteúdos e demais ativos relacionados à plataforma
                    solomon.com.br.
                </S.SectionCont>
                <S.Section>
                    <S.SectionTitle>1. Do objeto</S.SectionTitle>
                    <S.SectionContent>
                        A plataforma visa licenciar o uso de seu software, website,
                        aplicativos e demais ativos de propriedade intelectual,
                        fornecendo ferramentas para auxiliar e dinamizar o dia a dia
                        dos seus usuários. A plataforma caracteriza-se pela
                        prestação do seguinte serviço: automatização do processo de
                        integração, extração, processamento, modelagem e
                        disponibilização de visualizações dos dados de plataformas
                        de e-commerces.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>2. Da aceitação</S.SectionTitle>
                    <S.SectionContent>
                        O presente Termo estabelece obrigações contratadas de livre
                        e espontânea vontade, por tempo indeterminado, entre a
                        plataforma e as pessoas físicas ou jurídicas, usuárias do
                        aplicativo. Ao utilizar a plataforma o usuário aceita
                        integralmente as presentes normas e compromete-se a
                        observá-las, sob o risco de aplicação das penalidade
                        cabíveis. A aceitação do presente instrumento é
                        imprescindível para o acesso e para a utilização de
                        quaisquer serviços fornecidos pela empresa. Caso não
                        concorde com as disposições deste instrumento, o usuário não
                        deve utilizá-los.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>3. Do acesso dos usuários</S.SectionTitle>
                    <S.SectionContent>
                        Serão utilizadas todas as soluções técnicas à disposição do
                        responsável pela plataforma para permitir o acesso ao
                        serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por
                        semana. No entanto, a navegação na plataforma ou em alguma
                        de suas páginas poderá ser interrompida, limitada ou
                        suspensa para atualizações, modificações ou qualquer ação
                        necessária ao seu bom funcionamento.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>4. Do cadastro</S.SectionTitle>
                    <S.SectionContent>
                        O acesso às funcionalidades da plataforma exigirá a
                        realização de um cadastro prévio para acesso a qualquer tipo
                        de serviço. Ao se cadastrar o usuário deverá informar dados
                        completos, recentes e válidos, sendo de sua exclusiva
                        responsabilidade manter referidos dados atualizados, bem
                        como o usuário se compromete com a veracidade dos dados
                        fornecidos. O usuário se compromete a não informar seus
                        dados cadastrais e/ou de acesso à plataforma a terceiros,
                        responsabilizando-se integralmente pelo uso que deles seja
                        feito. Menores de 18 anos e aqueles que não possuírem plena
                        capacidade civil deverão obter previamente o consentimento
                        expresso de seus responsáveis legais para utilização da
                        plataforma e dos serviços ou produtos, sendo de
                        responsabilidade exclusiva dos mesmos o eventual acesso por
                        menores de idade e por aqueles que não possuem plena
                        capacidade civil sem a prévia autorização. Mediante a
                        realização do cadastro o usuário declara e garante
                        expressamente ser plenamente capaz, podendo exercer e
                        usufruir livremente dos serviços e produtos. O usuário
                        deverá fornecer um endereço de e-mail válido, através do
                        qual o aplicativo realizará todas comunicações necessárias.
                        Após a confirmação do cadastro, o usuário possuirá um login
                        e uma senha pessoal, a qual assegura ao usuário o acesso
                        individual à mesma. Desta forma, compete ao usuário
                        exclusivamente a manutenção de referida senha de maneira
                        confidencial e segura, evitando o acesso indevido às
                        informações pessoais. Toda e qualquer atividade realizada
                        com o uso da senha será de responsabilidade do usuário, que
                        deverá informar prontamente a plataforma em caso de uso
                        indevido da respectiva senha. Não será permitido ceder,
                        vender, alugar ou transferir, de qualquer forma, a conta,
                        que é pessoal e intransferível. Caberá ao usuário assegurar
                        que o seu equipamento seja compatível com as características
                        técnicas que viabilize a utilização da plataforma e dos
                        serviços ou produtos. O usuário poderá, a qualquer tempo,
                        requerer o cancelamento de seu cadastro junto ao aplicativo.
                        O seu descadastramento será realizado o mais rapidamente
                        possível, desde que não sejam verificados débitos em aberto.
                        O usuário, ao aceitar os Termos e Política de Privacidade,
                        autoriza expressamente a plataforma a coletar, usar,
                        armazenar, tratar, ceder ou utilizar as informações
                        derivadas do uso dos serviços, do aplicativo e quaisquer
                        plataformas, incluindo todas as informações preenchidas pelo
                        usuário no momento em que realizar ou atualizar seu
                        cadastro, além de outras expressamente descritas na Política
                        de Privacidade que deverá ser autorizada pelo usuário.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>5. Da assinatura</S.SectionTitle>
                    <S.SectionContent>
                        A contratação dos serviços será renovada automaticamente
                        pela plataforma, independentemente de comunicação ao
                        usuário, mediante cobrança periódica da mesma forma de
                        pagamento indicada pelo usuário quando da contratação do
                        serviço.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>6. Do cancelamento</S.SectionTitle>
                    <S.SectionContent>
                        O usuário poderá cancelar a contratação dos serviços de
                        acordo com os termos que forem definidos no momento de sua
                        contratação. Ainda, o usuário também poderá cancelar os
                        serviços em até 7 (sete) dias após a contratação, mediante
                        contato com hello@solomon.com.br, de acordo com o Código de
                        Defesa do Consumidor (Lei no. 8.078/90). O serviço poderá
                        ser cancelado por: a. parte do usuário: nessas condições os
                        serviços somente cessarão quando concluído o ciclo vigente
                        ao tempo do cancelamento; b. violação dos Termos de Uso: os
                        serviços serão cessados imediatamente. Em caso de
                        arrependimento, o usuário poderá devolver o produto em até 7
                        (sete) dias após o seu recebimento, mediante contato com
                        hello@solomon.com.br, de acordo com o Código de Defesa do
                        Consumidor (Lei nº 8.078/90).
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>7. Do suporte</S.SectionTitle>
                    <S.SectionContent>
                    Em caso de qualquer dúvida, sugestão ou problema com a
                    utilização da plataforma, o usuário poderá entrar em contato
                    com o suporte, através do email
                    daniel.soares@solomon.com.br. Estes serviços de atendimento
                    ao usuário estarão disponíveis nos seguintes dias e
                    horários: Segunda-feira a Sexta-feira, de 8h até 17h.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>8. Das responsabilidades</S.SectionTitle>
                    <S.SectionContent>
                    É de responsabilidade do usuário: a. defeitos ou vícios
                    técnicos originados no próprio sistema do usuário; b. pelo
                    cumprimento e respeito ao conjunto de regras disposto nesse
                    Termo de Condições Geral de Uso, na respectiva Política de
                    Privacidade e na legislação nacional e internacional; c.
                    pela proteção aos dados de acesso à sua conta (login e
                    senha). A plataforma se responsabiliza por links externos
                    contidos em seu sistema que possam redirecionar o usuário à
                    ambiente externo a sua rede.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>9. Dos direitos autorais</S.SectionTitle>
                    <S.SectionContent>
                    O presente Termo de Uso concede aos usuários uma licença não
                    exclusiva, não transferível e não sublicenciável, para
                    acessar e fazer uso da plataforma e dos serviços e produtos
                    por ela disponibilizados. A estrutura do aplicativo, as
                    marcas, logotipos, nomes comerciais, layouts, gráficos e
                    design de interface, imagens, ilustrações, fotografias,
                    apresentações, vídeos, conteúdos escritos e de som e áudio,
                    programas de computador, banco de dados, arquivos de
                    transmissão e quaisquer outras informações e direitos de
                    propriedade intelectual da razão social SOLOMON BRASIL LTDA,
                    observados os termos da Lei da Propriedade Industrial (Lei
                    nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e
                    Lei do Software (Lei nº 9.609/98), estão devidamente
                    reservados. Este Termos de Uso não cede ou transfere ao
                    usuário qualquer direito, de modo que o acesso não gera
                    qualquer direito de propriedade intelectual ao usuário,
                    exceto pela licença limitada ora concedida. O uso da
                    plataforma pelo usuário é pessoal, individual e
                    intransferível, sendo vedado qualquer uso não autorizado,
                    comercial ou não-comercial. Tais usos consistirão em
                    violação dos direitos de propriedade intelectual da razão
                    social SOLOMON BRASIL LTDA, puníveis nos termos da
                    legislação aplicável.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>10. Das sanções</S.SectionTitle>
                    <S.SectionContent>
                    Sem prejuízo das demais medidas legais cabíveis, a razão
                    social SOLOMON BRASIL LTDA poderá, a qualquer momento,
                    advertir, suspender ou cancelar a conta do usuário: a. que
                    violar qualquer dispositivo do presente Termo; b. que
                    descumprir os seus deveres de usuário; c. que tiver qualquer
                    comportamento fraudulento, doloso ou que ofenda a terceiros.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>11. Da rescisão</S.SectionTitle>
                    <S.SectionContent>
                    A não observância das obrigações pactuadas neste Termo de
                    Uso ou da legislação aplicável poderá, sem prévio aviso,
                    ensejar a imediata rescisão unilateral por parte da razão
                    social SOLOMON BRASIL LTDA e o bloqueio de todos os serviços
                    prestados ao usuário.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>12. Das alterações</S.SectionTitle>
                    <S.SectionContent>
                    Os itens descritos no presente instrumento poderão sofrer
                    alterações, unilateralmente e a qualquer tempo, por parte da
                    Solomon, para adequar ou modificar os serviços, bem como
                    para atender novas exigências legais. As alterações serão
                    veiculadas OU pelo aplicativo e o usuário poderá optar por
                    aceitar o novo conteúdo ou por cancelar o uso dos serviços,
                    caso seja assinante de algum serviço. Os serviços oferecidos
                    podem, a qualquer tempo e unilateralmente, e sem qualquer
                    aviso prévio, ser deixados de fornecer, alterados em suas
                    características, bem como restringido para o uso ou acesso.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>13. Da política de privacidade</S.SectionTitle>
                    <S.SectionContent>
                    Além do presente Termo, o usuário deverá consentir com as
                    disposições contidas na respectiva Política de Privacidade a
                    ser apresentada a todos os interessados dentro da interface
                    da plataforma.
                    </S.SectionContent>
                </S.Section>
                
            </S.TermsPolicy>

            <S.TermsPolicy style={{ display: selectedTab === 'privacy' ? 'block' : 'none' }}>
                <S.Title>Política de Privacidade</S.Title>
                <S.Section>
                    <S.SectionTitle>1. Informações Gerais</S.SectionTitle>
                    <S.SectionContent>
                    A presente Política de Privacidade contém informações sobre
                    coleta, uso, armazenamento, tratamento e proteção dos dados
                    pessoais dos usuários do aplicativo
                    analytics.solomon.com.br, com a finalidade de demonstrar
                    absoluta transparência quanto ao assunto e esclarecer a
                    todos interessados sobre os tipos de dados que são
                    coletados, os motivos da coleta e a forma como os usuários
                    podem gerenciar ou excluir as suas informações pessoais.
                    Esta Política de Privacidade aplica-se a todos os usuários
                    do aplicativo e integra os Termos e Condições Gerais de Uso
                    do aplicativo, devidamente inscrito no CNPJ sob o nº
                    50.951.561/0001-44, situado em Praça Chuí 35, Jardim Fátima,
                    São José dos Campos - SP, doravante nominada SOLOMON BRASIL
                    LTDA. O presente documento foi elaborado em conformidade com
                    a Lei Geral de Proteçâo de Dados Pessoais (Lei 13.709/18), o
                    Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da
                    UE n. 2016/6790). Ainda, o documento poderá ser atualizado
                    em decorrência de eventual atualização normativa, razão pela
                    qual se convida o usuário a consultar periodicamente esta
                    seção.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>2. Como recolhemos os dados pessoais do usuário?</S.SectionTitle>
                    <S.SectionContent>
                    Os dados pessoais do usuário são recolhidos pela plataforma
                    da seguinte forma: Quando o usuário cria uma conta/perfil na
                    plataforma: esses dados são os dados de identificação
                    básicos, como email, nome do usuário e nome da empresa. A
                    partir deles, podemos identificar o usuário, além de
                    garantir uma maior segurança e bem-estar às suas
                    necessidade. Ficam cientes os usuários de que seu perfil na
                    plataforma não estará acessível a demais usuários. Quando um
                    usuário acessa páginas do aplicativo: as informações sobre
                    interação e acesso são coletadas pela empresa para garantir
                    uma melhor experiência ao usuário. Estes dados podem tratar
                    sobre todo processo de navegação e de interação do usuário o
                    aplicativo. Quando um usuário realiza uma integração: são
                    armazenadas de forma segura todas as informações de
                    integração para que os nossos serviços possam ter acesso aos
                    dados de vendas, marketing, etc. Essas informações incluem
                    identificações de conta, loja ou perfil, tokens de acesso e
                    chaves secretas inseridas pelo próprio usuário nos campos de
                    integração, ou obtidas com o seu consetimento através das
                    telas de autenticação das próprias plataformas. Quando um
                    usuário gera um relatório: são coletados e armazenados com
                    segurança também os dados vindos das plataformas integradas,
                    os quais são exibidos para o usuário quando ele gera um
                    relatório. Esses dados incluem dados de pedido e de vendas,
                    dados de campanhas e de anúncios, entre outros.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>3. Quais dados pessoais recolhemos sobre o usuário?</S.SectionTitle>
                    <S.SectionContent>
                    Os dados pessoais do usuário recolhidos são os seguintes:
                    Dados para a criação da conta/perfil na plataforma: email,
                    nome do usuário e nome da loja. Dados para otimização da
                    navegação: acesso a páginas, integrações realizadas,
                    relatórios gerados, logins e logouts. Dados de integração:
                    identificador da loja, perfil ou conta, tokens de acesso,
                    tokens de atualização e chaves secretas. Dados sensíveis: a
                    plataforma poderá coletar os seguintes dados sensíveis do
                    usuário – dados de campanhas, anúncios e métricas (Facebook
                    Ads, Tiktok Ads e Google Ads), dados de pedidos, produtos,
                    clientes e estoque (plataformas de vendas), além de dados
                    inseridos manualmente, como taxas de transação e impostos.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>4. Para que finalidades utilizamos os dados pessoais do usuário?</S.SectionTitle>
                    <S.SectionContent>
                    Os dados pessoais do usuário coletados e armazenados pela
                    plataforma tem por finalidade: Bem-estar do usuário:
                    aprimorar o produto, facilitar, agilizar e cumprir os
                    compromissos estabelecidos entre o usuário e a empresa,
                    melhorar a experiência dos usuários e fornecer
                    funcionalidades específicas a depender das características
                    básicas do usuário. Melhorias da plataforma: compreender
                    como o usuário utiliza os serviços da plataforma, para
                    ajudar no desenvolvimento de negócios e técnicas. Anúncios:
                    apresentar anúncios personalizados para o usuário com base
                    nos dados fornecidos. Previsão do perfil do usuário:
                    tratamento automatizados de dados pessoais para avaliar o
                    uso na plataforma. Dados de cadastro: para permitir o acesso
                    do usuário a determinados conteúdos da plataforma, exclusivo
                    para usuários cadastrados Dados de integrações: chaves,
                    tokens e ids utilizados internamente para coletar os dados
                    específicos e respectivos de cada integração, de acordo com
                    as especificações de autenticação estabelecidas por cada
                    plataforma. Dados sensíveis: dados gerados para produzir
                    visualizações, modelos e insights, que formam os relatórios
                    fornecidos pela Solomon aos usuários, que é o objetivo final
                    do produto. O tratamento de dados pessoais para finalidades
                    não previstas nesta Política de Privacidade somente ocorrerá
                    mediante comunicação prévia ao usuário, de modo que os
                    direitos e obrigações aqui previstos permanecem aplicáveis.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>5. Por quanto tempo os dados pessoais ficam armazenados?</S.SectionTitle>
                    <S.SectionContent>
                    Os dados pessoais do usuário são armazenados pela plataforma
                    durante o período necessário para a prestação do serviço ou
                    o cumprimento das finalidades previstas no presente
                    documento, conforme o disposto no inciso I do artigo 15 da
                    Lei 13.709/18. Os dados podem ser removidos ou anonimizados
                    a pedido do usuário, excetuando os casos em que a lei
                    oferecer outro tratamento. Ainda, os dados pessoais dos
                    usuários apenas podem ser conservados após o término de seu
                    tratamento nas seguintes hipóteses previstas no artigo 16 da
                    referida lei: I - cumprimento de obrigação legal ou
                    regulatória pelo controlador; II - estudo por órgão de
                    pesquisa, garantida, sempre que possível, a anonimização dos
                    dados pessoais; III - transferência a terceiro, desde que
                    respeitados os requisitos de tratamento de dados dispostos
                    nesta Lei; IV - uso exclusivo do controlador, vedado seu
                    acesso por terceiro, e desde que anonimizados os dados.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>6. Seguranmça dos dados pessoais armazenados</S.SectionTitle>
                    <S.SectionContent>
                    A plataforma se compromete a aplicar as medidas técnicas e
                    organizativas aptas a proteger os dados pessoais de acessos
                    não autorizados e de situações de destruição, perda,
                    alteração, comunicação ou difusão de tais dados. A
                    plataforma não se exime de responsabilidade por culpa
                    exclusiva de terceiro, como em caso de ataque de hackers ou
                    crackers, ou culpa exclusiva do usuário, como no caso em que
                    ele mesmo transfere seus dados a terceiros. O aplicativo se
                    compromete a comunicar o usuário em caso de alguma violação
                    de segurança dos seus dados pessoais. Os dados pessoais
                    armazenados são tratados com confidencialidade, dentro dos
                    limites legais. No entanto, podemos divulgar suas
                    informações pessoais caso sejamos obrigados pela lei para
                    fazê-lo ou se você violar nossos Termos de Serviço.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>7. Compartilhamento dos dados</S.SectionTitle>
                    <S.SectionContent>
                    Os dados do usuário não são compartilhados com terceiros e
                    são acessíveis apenas ao usuário mediante a sua autenticação
                    na plataforma.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>8. Os dados pessoais armazenados serão transferidos a terceiros?</S.SectionTitle>
                    <S.SectionContent>
                    Os dados pessoais não podem ser compartilhados com
                    terceiros.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>9. Cookies ou dados de navegação</S.SectionTitle>
                    <S.SectionContent>
                    Os cookies referem-se a arquivos de texto enviados pela
                    plataforma ao computador do usuário e que nele ficam
                    armazenados, com informações relacionadas à navegação no
                    aplicativo. Tais informações são relacionadas aos dados de
                    acesso como local e horário de acesso e são armazenadas pelo
                    navegador do usuário para que o servidor da plataforma possa
                    lê-las posteriormente a fim de personalizar os serviços da
                    plataforma. O usuário da plataforma Solomon manifesta
                    conhecer e aceitar que pode ser utilizado um sistema de
                    coleta de dados de navegação mediante à utilização de
                    cookies. O cookie persistente permanece no disco rígido do
                    usuário depois que o navegador é fechado e será usado pelo
                    navegador em visitas subsequentes ao aplicativo. Os cookies
                    persistentes podem ser removidos seguindo as instruções do
                    seu navegador. Já o cookie de sessão é temporário e
                    desaparece depois que o navegador é fechado. É possível
                    redefinir seu navegador da web para recusar todos os
                    cookies, porém alguns recursos da plataforma podem não
                    funcionar corretamente se a capacidade de aceitar cookies
                    estiver desabilitada.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>10. Consentimento</S.SectionTitle>
                    <S.SectionContent>
                    Ao utilizar os serviços e fornecer as informações pessoais
                    na plataforma, o usuário está consentindo com a presente
                    Política de Privacidade. O usuário, ao cadastrar-se,
                    manifesta conhecer e pode exercitar seus direitos de
                    cancelar seu cadastro, acessar e atualizar seus dados
                    pessoais e garante a veracidade das informações por ele
                    disponibilizadas. O usuário tem direito de retirar o seu
                    consentimento a qualquer tempo, para tanto deve entrar em
                    contato através do e-mail daniel.soares@solomon.com.br.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>11. Alterações para essa política de privacidade</S.SectionTitle>
                    <S.SectionContent>
                    Reservamos o direito de modificar essa Política de
                    Privacidade a qualquer momento, então, é recomendável que o
                    usuário revise-a com frequência. As alterações e
                    esclarecimentos vão surtir efeito imediatamente após sua
                    publicação na plataforma. Quando realizadas alterações os
                    usuários serão notificados. Ao utilizar o serviço ou
                    fornecer informações pessoais após eventuais modificações, o
                    usuário demonstra sua concordância com as novas normas.
                    Diante da fusão ou venda da plataforma à outra empresa os
                    dados dos usuários podem ser transferidas para os novos
                    proprietários para que a permanência dos serviços
                    oferecidos.
                    </S.SectionContent>
                </S.Section>
                <S.Section>
                    <S.SectionTitle>12. Jurisdição para resolução de conflitos</S.SectionTitle>
                    <S.SectionContent>
                    Para a solução de controvérsias decorrentes do presente
                    instrumento será aplicado integralmente o Direito
                    brasileiro. Os eventuais litígios deverão ser apresentados
                    no foro da comarca em que se encontra a sede da empresa.
                    </S.SectionContent>
                </S.Section>
                
            </S.TermsPolicy>
            
            <S.End>© 2023 Solomon. All rights reserved</S.End>
            
        </S.Container>
    );
}