import React from 'react';
import { styled } from '@mui/material/styles';


const CustomerIcon = ({ fontColor, fontSize, name }) => {
  const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part.charAt(0)).join('');
    return initials.substring(0, 2).toUpperCase();
  };

  return (
    <div style={{color: fontColor, fontWeight: '550', fontSize: fontSize}}>
      {getInitials(name)}
    </div>
  );
};

export default CustomerIcon;
