import * as S from './style';
import { useEffect, useState } from 'react';
import { LineProductsCostsPropsVariant } from '../../../../@types';
import { toBrazilianFormat } from '../../../../utils';
import { format } from 'date-fns';
import { ButtonApp } from '../../../../components/ButtonApp';
import { ModalLineProductsCosts } from './ModalLineProductsCosts';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { EditIconStyled } from './style';
import { useNavigate } from 'react-router-dom';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import { Tooltip } from '@mui/material';

export function LineProductsCosts({openedProduct,setOpenedProduct, product, editedVariants, setEditedVariants, dataProductCosts,setDataProductCosts, products, setProducts }: LineProductsCostsPropsVariant) {

    const { isVisible } = useVisibility();
    const navigate = useNavigate();
    const {currencySymbol} = useCurrencyType();
    
    // Isso agora é sempre chamado, garantindo a consistência na quantidade de hooks
    const [showEditModal, setShowEditModal] = useState(false);

    // Use useEffect para tratar efeitos colaterais como navegação e atualização de estados
    useEffect(() => {
        if(openedProduct === product?.product_id){
            setShowEditModal(true); // Abre o modal baseado na condição
            setOpenedProduct(''); // Limpa o produto aberto para evitar reaberturas indesejadas
            navigate('/custo', { replace: true }); // Atualiza a URL sem adicionar ao histórico
        }
    }, [openedProduct, product?.product_id, navigate, setOpenedProduct]);

    if(product === undefined){
        return(
            <S.Container>
            <S.Image></S.Image>
            <S.ProductName></S.ProductName>
            <S.Price></S.Price>
            <S.Cost></S.Cost>
            <S.LastSale></S.LastSale>
           
        </S.Container>
        )
    }

    const meanNonZero = (values: number[]) => {
        const nonZeroValues = values.filter(value => value !== 0);
        if (nonZeroValues.length === 0) return 0; // Handle the case where there are no non-zero values
        const sum = nonZeroValues.reduce((acc, val) => acc + val, 0);
        return sum / nonZeroValues.length;
    };
    
    const productPrices = product?.variants?.map((variant: any) => Number(variant?.price)) || [];
    const productCosts = product?.variants?.map((variant: any) => Number(variant?.cost)) || [];
    
    const meanProductPrice = meanNonZero(productPrices);
    const meanProductCost = meanNonZero(productCosts);

    const lastDate = !!product?.latest_order_date ? format(new Date(product?.latest_order_date), 'dd/MM/yyyy') : '-';

    let currencyTypeVariants = 'R$';

    if (product?.variants[0]?.currency === 'USD') {
        currencyTypeVariants = '$';
    } else if (product?.variants[0]?.currency === 'BRL'){
        currencyTypeVariants = 'R$';
    }

    let isVariantsLoading = false;

    if(!product?.variants){
        isVariantsLoading = true;
    }

    const handleImageError = (event) => {
        event.target.src = 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png';
    };

    return (
        <S.Container>
            {showEditModal && <ModalLineProductsCosts product={product} setShowModal={setShowEditModal} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} products={products} setProducts={setProducts} editedVariants={editedVariants} setEditedVariants={setEditedVariants}/>}
            <S.Image>{product?.product_image_src?.length > 0 && <img src={isVisible ? product?.product_image_src : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt="product_image" onError={handleImageError} />}</S.Image>
            
            <S.ProductName>
            <Tooltip title={isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * * * *'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor:'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'nowrap',zIndex: 999999, lineHeight: '17px'}},}}>

                <p>{isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * * * '}</p>
                </Tooltip>
            </S.ProductName>
            <S.Price>{meanProductPrice === 0 ? `${currencySymbol} 0,00` : `${currencySymbol} ${toBrazilianFormat(meanProductPrice)}`}</S.Price>
           
           
            <S.Cost>{
            meanProductCost === 0 ? `R$ 0,00` : 
            currencyTypeVariants  + `${toBrazilianFormat(meanProductCost)}`
            }</S.Cost>
           
            <S.LastSale>{product?.product_title?.length > 0 ? lastDate : ''}</S.LastSale>

            <S.EditVariants>
            <ButtonApp  border='' width='130px' height='30px' background='var(--black)' color='white' icon={<EditIconStyled />} text='Editar'  onClick={() => setShowEditModal(true)} />
  
            </S.EditVariants>
        </S.Container>
    );
}
