import React, { useEffect } from 'react';
import Modal from '../../../../../components/Modal/index.tsx';
import * as CS from '../../../../CostOperation/style.ts'; 
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../../../hooks/useAuth.tsx';

import { Search } from '../../../../../components/Search';
import { useGetProductsSyncAd } from '../../../../../services/hooks/productsHooks.ts';
import { AdLineInfo } from './AdModalTable/LineAdModal/index.tsx';
import { getSyncAds, toApiFormat } from './utils/index.ts';
import { useUpdateProductSyncAd } from '../../../../../services/hooks/productsHooks.ts';
import useAlert from '../../../../../hooks/useAlert';
import { ButtonApp } from '../../../../../components/ButtonApp/index.tsx';
import AdModalTable from './AdModalTable/index.tsx';

interface AdModalProps {
    onClose: () => void;
    setShowAd: (value: boolean) => void;
    product_id: string;
    product_name: string;
}

const AdModal : React.FC<AdModalProps> = ({ onClose, setShowAd, product_id, product_name }) => {

    const { currentCompany } = useAuth();
    const { showAlert } = useAlert();

    const [filter, setFilter] = useState('');

    const data = useGetProductsSyncAd(currentCompany);
    const isLoadingSyncAd = data.isLoading;

    const [ads, setAds] = useState(
        getSyncAds(data?.data?.data?.data, product_id)
    );

    useEffect(() => {
        setAds(getSyncAds(data?.data?.data?.data, product_id));
    }, [data.isLoading]);

    const { mutate: updateProductSyncAdMutate, isLoading: isLoadingUpdateProductMarketingCost } = useUpdateProductSyncAd((data) => {
        setAds(getSyncAds(data?.data?.data?.data, product_id));
    });

    const handleSyncAd = () => {
        const changes = ads.filter(ad => {
            return ad.selected != ad.sync;
        }).valueSeq();

        try {
            if (changes.size > 0) {
                updateProductSyncAdMutate({
                    sync_ads: changes.filter(ad => ad.selected).map((ad) => toApiFormat(ad, product_id, product_name, currentCompany)).toArray(),
                    unsync_ads: changes.filter(ad => !ad.selected).map((ad) => toApiFormat(ad, product_id, product_name, currentCompany)).toArray(),
                    company_id: currentCompany,
                    product_id: product_id,
                    product_name: product_name
                });
                showAlert('Campanha adicionada com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 30000, true);
            }
            setShowAd(false);
        } catch (error) {
            console.error('Erro ao atualizar custos:', error);
            setShowAd(false);
        }
    }

    const filteredAds = ads.filter(ad => {
        if (filter.length > 0)
            return ad.ad_name?.toLowerCase().includes(filter.toLowerCase());
        else
            return true;
    });

    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage
                    src="https://storage.googleapis.com/static-images-source/management-cost.png"
                    alt="Shopify"
                />
                <S.MarketingTitle>
                    Sincronize seu produto a <S.MarketingHighlight>Anúncios Específicos</S.MarketingHighlight> 
                    <S.MarketingParagraph>Vincule seus produtos a anúncios específicos, assegurando uma direção eficiente e eficaz de suas ofertas.</S.MarketingParagraph>
                </S.MarketingTitle>
                <S.MarketingLine/>
                <S.MarketingTitle>
                    <S.MarketingHighlight>{product_name}</S.MarketingHighlight>
                </S.MarketingTitle>

                <S.Section>
                    <Search width="20rem" placeholder="Buscar anúncio..." value={filter} setValue={setFilter} />
                </S.Section>

                <AdModalTable 
                    ads={filteredAds}
                    isLoading={isLoadingSyncAd || isLoadingUpdateProductMarketingCost}
                    setSyncAd={(checked: boolean, ad_id: any) => {
                        const newInfo = {...ads.get(ad_id)};
                        newInfo.selected = checked;
                        setAds(ads.set(ad_id, newInfo));
                    }}
                />
                
                <CS.ButtonContainer>
                    <ButtonApp border='' width="9.5rem" height="2.5rem" background="#19c27e"   color="#fff"   onClick={handleSyncAd} isLoading={isLoadingUpdateProductMarketingCost} text="Salvar alterações"/>
                </CS.ButtonContainer>
                
            </S.ModalContent>
        </Modal>
    );
};

export default AdModal;
