import React, { useState, useEffect } from 'react';
import * as S from './style';
import { signUpSchema } from '../../schemas/authSchema';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { storagePlaceholderCompanyRemove, storagePlaceholderCompanySave, storageShExternalIdGet, storageShExternalIdRemove } from '../../storage/storageCompanyToOnboard';
import { SideBarPayment } from './SidebarPayment';
import { analyticsWeb } from '../../services/segment/sendEvent';
import { useSubmitBillingAccount } from '../../services/hooks/billingAccountHooks';
import { storageOnboardingRemoveAll, storageOnboardingCurrentCompanyGet, storageOnboardingCurrentCompanyRemove, storageOnboardingCurrentCompanySave, storageOnboardingIntegrationShopifySave, storageOnboardingNameGet,storageOnboardingNameRemove,storageOnboardingNameSave } from '../../storage/storageOnboarding';
import { useCreateBillingAccount, useCreateCompanyOnboarding, useRenameCompanyOnboarding } from '../../services/hooks/integrationHooks';
import {  useSubmitSubscription, useUpgradeSubscription, useReactivateSubscription } from '../../services/hooks/subscriptionHooks';
import Step1 from './FormFirstStep';
import Step2 from './FormSecondStep';
import { getAddressInfo, AddressData } from '../../services/api/api-ip'
import useAlert from '../../hooks/useAlert';
import { getCoupon } from '../../services/api/api-subscription';
import Modal from '../../components/Modal';
import { userDTO, StorageAuthTokenProps } from '../../@types';
import { add } from 'date-fns';
import { getPlan } from '../../services/api/api-subscription';
import LogoutIcon from '@mui/icons-material/Logout';
import { trackEvent } from '../../services/segment/sendEvent';
import { useIntercom } from 'react-use-intercom';
import { initFacebookPixel, fbq } from '../../services/facebook-pixel/facebook-pixel';

function getCookieByDomain(cookieName, domain) {
    const cookies = document.cookie.split(';');
    
    for (let cookie of cookies) {
        cookie = cookie.trim();
        
        if (cookie.startsWith(cookieName + '=')) {
            document.cookie = `${cookieName}=${cookie.split('=')[1]}; domain=${domain}; path=/`;
            if (document.cookie.includes(cookieName)) {
                return cookie.split('=')[1];
            }
        }
    }
    return null;
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=.solomon.com.br";
}

export function Payment() {
    //@ts-ignore
    const navigate = useNavigate();
    const { setUserContext, setTokenContext, setCurrentCompanyContext, user, currentCompany, updateUser } = useAuth();
    const userName = user?.name;
    const { showAlert } = useAlert();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [storeName, setStoreName] = useState(storageOnboardingNameGet());
    const [billingCpfCnpj, setBillingCpfCnpj] = useState('');
    const [subscriptionName, setSubscriptionName] = useState();
    const [subscriptionCardNumber, setSubscriptionCardNumber] = useState('');
    const [subscriptionExpirationDate, setSubscriptionExpirationDate] = useState('');
    const [subscriptionExpiryYear, setSubscriptionExpiryYear] = useState('');
    const [subscriptionExpiryMonth, setSubscriptionExpiryMonth] = useState('');
    const [subscriptionCcv, setSubscriptionCcv] = useState('');
    const [subscriptionCoupon, setSubscriptionCoupon] = useState('');
    const [submitCoupon, setSubmitCoupon ] = useState(false);
    const [couponError, setCouponError] = useState(false);
    const [subscriptionPostalCode, setSubscriptionPostalCode] = useState('');
    const { mutate: submitSubscription } = useSubmitSubscription();
    const { mutate: upgradeSubscription } = useUpgradeSubscription();
    const { mutate: reactivateSubscription } = useReactivateSubscription();
    const [isFirstButtonEnabled, setIsFirstButtonEnabled] = useState(false);
    const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cardToken, setCardToken] = useState(null);
    const [updatedPlans, setPlansUpdated] = useState({});
    const { boot } = useIntercom();

    useEffect(() => {
        boot({
            name: user.name,
            userId: user.user_id,
            email: user.credentials?.email,
            phone: user.phone,
            createdAt: user.created_at
         });
    }, [boot]);

    useEffect(() => {
        if (!user.user_id) {
            return
        }

        let fbp = user.facebook?.fbp || getCookieByDomain("_fbp", ".solomon.com.br");
        if (!fbp) {
            fbp = `fb.2.${new Date().getTime()}.${Math.floor(1e8 + 9e8 * Math.random())}`
            setCookie("_fbp", fbp, 365)
        }

        let fbc = user.facebook?.fbc || getCookieByDomain("_fbc", ".solomon.com.br");
        const fbclid = new URLSearchParams(window.location.search).get('fbclid');
        if (!fbc && fbclid) {
            fbc = `fb.2.${new Date().getTime()}.${fbclid};`
            setCookie('_fbc', fbc, 365);
        }        

        initFacebookPixel("776084714023352", fbp, fbc)
    }, [user])

    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success");
        }).catch((err) => {
            //console.log(err);
        });

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        storageOnboardingRemoveAll();
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        navigate('/login');
    }

    useEffect(() => {
        if (subscriptionExpirationDate) {
            const [month, year] = subscriptionExpirationDate.split('/');
            setSubscriptionExpiryMonth(month);
            setSubscriptionExpiryYear(year);
        }
    }, [subscriptionExpirationDate]);

    const { mutate: renameCompanyOnboarding } = useRenameCompanyOnboarding();
    const [onbCompany, setOnbCompany] = useState('');
    const { mutate: createCompanyOnboarding } = useCreateCompanyOnboarding();
    const [companyCreating, setCompanyCreating] = useState(false);;
    const [updatePlan, setUpdatePlan] = useState('');
    const [calculatedPlanPrice, setCalculatedPlanPrice] = useState(null);
    const [addStore, setAddStore] = useState('');
    const [contextRenew, setContextRenew] = useState('');
    const [selectedCard, setSelectedCard] = useState(false);
    const [disabledStoreInput, setDisableStoreInput] = useState(false);

    const [cepError, setCepError] = useState(false);

    const [ initiateCheckoutSent, setInitiateCheckoutSent ] = useState(false);
    const [ pageviewSent, setPageviewSent ] = useState(false);
    const { mutate: submitBillingAccount } = useSubmitBillingAccount();

    const [userForPayment, setUserForPayment] = useState({
        name: user?.name || '',
        email: '',
        phone: '',
        password: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
    });

    const removeLocalStoragePayment = () => {
        localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)
        
    }

    const [storagePlansTrue, setStoragePlans] = useState(false)

    //console.log('storageplan', storagePlansTrue)


    let storedPlans = getStoredPlans();
    function getStoredPlans() {
        const storedPlans = localStorage.getItem(`plansWithCouponArray_${user?.user_id}`);
        return storedPlans ? JSON.parse(storedPlans) : null ;
    };
    
  useEffect(() => {
    const interval = setInterval(() => {
    storedPlans = getStoredPlans();
      if (storedPlans) {
        //console.log('stored plans updated:', storedPlans);
        setDefaultPlans(storedPlans);
      }
    }, 1000); // Atualiza a cada 5 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, [user]); // O array de dependências vazio garante que isso rode apenas uma vez ao montar


    const [plansData, setPlansData] = useState({
        Plano1: { price: '', limit_orders: '', var_price: '' },
        Plano2: { price: '', limit_orders: '', var_price: '' },
        Plano3: { price: '', limit_orders: '', var_price: '' },
        Plano4: { price: '', limit_orders: '', var_price: '' },
    });

    const [isLoadingPlans, setIsLoadingPlans] = useState(false);

    useEffect(() => {
    const fetchPlans = async () => {
        const planIds = ['Plano 1', 'Plano 2', 'Plano 3', 'Plano 8'];
        try {
        setIsLoadingPlans(true);
        const fetchedPlans = await Promise.all(planIds.map(planId => getPlan(planId)));
        const plansData = fetchedPlans.reduce((acc, planData, index) => {
            acc[`Plano${index + 1}`] = {
            price: planData.price,
            limit_orders: planData.limit_orders,
            var_price: planData.var_price,
            };
            return acc;
        }, {});
        setPlansData(plansData);
        } catch (err) {
        console.error('Error fetching plans:', err);
        } finally {
        setIsLoadingPlans(false);
        }
    };
    
    
    fetchPlans();
    }, []);

    useEffect(() => {
        if (storedPlans) {
        //console.log('stored plans entrou no if', storedPlans)
        setDefaultPlans(storedPlans);
        //console.log('definiu no IF', storedPlans)
        } else{
        //console.log('stored plans entrou no else', storagePlansTrue, plansData)
          setDefaultPlans({
            basic: {
              id: 'Plano 1',
              planName: 'Plano Essencial',
              price: plansData.Plano1.price,
              maxOrders: plansData.Plano1.limit_orders,
              pricePerExtraOrder: plansData.Plano1.var_price,
              savings: '',
              description: '',
            },
            advanced: {
              id: 'Plano 2',
              planName: 'Plano Premium',
              price: plansData.Plano2.price,
              maxOrders: plansData.Plano2.limit_orders,
              pricePerExtraOrder: plansData.Plano2.var_price,
              savings: '',
              description: '',
            },
            ultra: {
              id: 'Plano 3',
              planName: 'Plano Avançado',
              price: plansData.Plano3.price,
              maxOrders: plansData.Plano3.limit_orders,
              pricePerExtraOrder: plansData.Plano3.var_price,
              savings: '',
              description: '',
            },
            premium: {
              id: 'Plano 8',
              planName: 'Plano Platinum',
              price: plansData.Plano4.price,
              maxOrders: plansData.Plano4.limit_orders,
              pricePerExtraOrder: plansData.Plano4.var_price,
              savings: '',
              description: '',
            },
          });
          //console.log('definiu no else', plansData)
        }
      }, [plansData,storagePlansTrue, storedPlans]);
    
    
    const [defaultPlans, setDefaultPlans] = useState({});
    
      //console.log('default plans', defaultPlans)
      
    const [selectedPlan, setSelectedPlan] = useState(() => {
        const savedPlanUpdated = localStorage.getItem(`selectedPlan_${user?.user_id}`);
        const savedPlan = savedPlanUpdated ? savedPlanUpdated : localStorage.getItem(`selectedPlanSignUp`);
        //console.log('savedplan', defaultPlans[savedPlan] || 'basic')
        return defaultPlans[savedPlan] || 'basic';  // Inicia com o plano do localStorage ou o plano básico
    });

    const handlePageview = async () => {
        const ip = localStorage.getItem('@solomon:_ip');

        const nameParts = user.name.trim().split(/\s+/);
        const first_name = nameParts[0]
        const last_name = nameParts.slice(1).join(' ');

        const plansValues = {
            basic: 97,
            advanced: 197,
            ultra: 397,
            premium: 497
        };

        const eventSelectedPlan = localStorage.getItem(`selectedPlanSignUp`) ?? localStorage.getItem(`selectedPlan_${user?.user_id}`) ?? 'basic'
        
        const test = window.location.hostname !== "analytics.solomon.com.br" || user.credentials.email.includes("teste") || user.credentials.email.includes("asaas") || ip ===  "200.210.101.90"
        const eventCtx = await analyticsWeb.track('checkout-pageview', {
            user_agent: navigator.userAgent,
            ip: ip,
            fbp: user.facebook?.fbp || getCookieByDomain("_fbp", ".solomon.com.br"),
            fbc: user.facebook?.fbc || getCookieByDomain("_fbc", ".solomon.com.br"),
            utm_source: user.utms.utm_source || getCookieByDomain("utm_source", ".solomon.com.br"),
            utm_medium: user.utms.utm_medium || getCookieByDomain("utm_medium", ".solomon.com.br"),
            utm_campaign: user.utms.utm_campaign || getCookieByDomain("utm_campaign", ".solomon.com.br"),
            utm_content: user.utms.utm_content || getCookieByDomain("utm_content", ".solomon.com.br"),
            userId: user.user_id,
            first_name: first_name,
            last_name: last_name,
            email: user.credentials.email,
            quantity: 1,
            phone: `55${user.phone.replace(/\D/g, '')}`,
            plan: eventSelectedPlan,
            value: plansValues[eventSelectedPlan],
            url: window.location.href,
            currency: 'BRL',
            test: test
        });

        if (!test) {
            fbq('track', 'AddToCart', {}, {eventID: eventCtx.event.messageId});
        }
    }

    useEffect(() => {
        if (!user?.name || pageviewSent) {
            return
        };

        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get("add_store") === "true" || urlParams.get("context") === "renew" || urlParams.get("update_plan") === "true" || urlParams.get("company_id")) {
            return
        }

        handlePageview();
        setPageviewSent(true);
    }, [user?.name]);

    useEffect(() => {
        setUserForPayment(prev => ({
            ...prev,
            name: user?.name || prev.name // Atualiza o nome apenas se existir um novo valor
        }));
    }, [user?.name]);
    

    useEffect(() => { 
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const companyOnUrl = urlParams.get('company_id');
        const companyName = user?.companies?.[companyOnUrl]?.company;

        if (companyName) {
            storageOnboardingNameSave(companyName);
            setStoreName(companyName);
            if (user?.companies?.[companyOnUrl]?.billing?.subscription !== ''){
                setDisableStoreInput(true);
            }
        }

        if (companyOnUrl) {
            setOnbCompany(companyOnUrl);
            storageOnboardingCurrentCompanySave(companyOnUrl);
        }

        if (contextRenew == 'renew') {
            const companyName = user?.companies?.[currentCompany]?.company;
            storageOnboardingCurrentCompanySave(currentCompany)
            setStoreName(companyName)
            setDisableStoreInput(true)

            const newUser = {...user,companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies[currentCompany],
                    billing: {
                        ...user?.companies[currentCompany]?.billing,
                        status: 'inactive'
                    }
                }
            },}
            setUserContext(newUser as userDTO)
            
        }
    }, [user?.user_id]);

    useEffect(() => {
        // Assumindo que você está usando window.location.search para obter a query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const updatePlanUrl = urlParams.get('update_plan');
        setUpdatePlan(updatePlanUrl);
        const addStore = urlParams.get('add_store');
        setAddStore(addStore);
        const companyOnUrl = urlParams.get('company_id');
        const companyName = user?.companies?.[companyOnUrl]?.company;

        if (addStore === 'true') {
            if (!companyName) {
                storageOnboardingNameRemove();
                setStoreName(storageOnboardingNameGet());
            }            
            // storageOnboardingCurrentCompanyRemove();
            // localStorage.removeItem(`plansWithCouponArray_${user.user_id}`);
            localStorage.removeItem(`savedCoupon_${user.user_id}`);
            localStorage.removeItem(`selectedPlan_${user.user_id}`);
            localStorage.removeItem(`currentStep_${user.user_id}`);
            localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
            localStorage.removeItem('subscriptionPostalCode');
            localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        }

        const contextRenew = urlParams.get('context');
        setContextRenew(contextRenew);

        const selectedPlanUrl = urlParams.get('selected_plan');
        
        if (user?.user_id == undefined) {
            return;
        }

        localStorage.setItem(`selectedPlan_${user?.user_id}`, selectedPlanUrl);

        if (selectedPlanUrl) {
            setSelectedPlan(localStorage.getItem(`selectedPlan_${user?.user_id}`))
        }

        const utm_source = urlParams.get('utm_source') || '';
        const utm_medium = urlParams.get('utm_medium') || '';
        const utm_campaign = urlParams.get('utm_campaign') || '';
        const utm_term = urlParams.get('utm_term') || '';
        const utm_content = urlParams.get('utm_content') || '';
    
        // Atualiza o estado com os valores das UTMs
        setUserForPayment(prevState => ({
          ...prevState,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content
        }));
      }, []); // O array vazio garante que o efeito seja executado apenas uma vez após o componente montar

    const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 768);
    
    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }
    }, []);

    // //console.log('is mobile?', isMobile)

    function formatString(value: string) {
        return value.replace(/\s+/g, '').replace(/[^0-9a-zA-Z]/g, '').replace(/-/g, '');
    }

    const [subscriptionId, setSubscriptionId] = useState('');
    const [zipcode, setZipcode] = useState('');


    const handleSendPayment = async (subscriptionId:string, addressData: AddressData) => {
        const ip = localStorage.getItem('@solomon:_ip');
        
        const nameParts = user.name.trim().split(/\s+/);
        const first_name = nameParts[0]
        const last_name = nameParts.slice(1).join(' ');

        const plansValues = {
            basic: 97,
            advanced: 197,
            ultra: 397,
            premium: 497
        };

        const test = window.location.hostname !== "analytics.solomon.com.br" || user.credentials.email.includes("teste") || user.credentials.email.includes("asaas") || ip ===  "200.210.101.90";
        
        const props = {
            user_agent: navigator.userAgent,
            ip: ip,
            fbp: user.facebook?.fbp || getCookieByDomain("_fbp", ".solomon.com.br"),
            fbc: user.facebook?.fbc || getCookieByDomain("_fbc", ".solomon.com.br"),
            utm_source: user.utms.utm_source || getCookieByDomain("utm_source", ".solomon.com.br"),
            utm_medium: user.utms.utm_medium || getCookieByDomain("utm_medium", ".solomon.com.br"),
            utm_campaign: user.utms.utm_campaign || getCookieByDomain("utm_campaign", ".solomon.com.br"),
            utm_content: user.utms.utm_content || getCookieByDomain("utm_content", ".solomon.com.br"),
            userId: user.user_id,
            first_name: first_name,
            last_name: last_name,
            email: user.credentials.email,
            phone: `55${user.phone.replace(/\D/g, '')}`,
            zip: formatString(addressData.cep),
            city: addressData.localidade,
            state: addressData.uf,
            country: 'Brazil',
            url: window.location.href,
            quantity: 1,
            plan: localStorage.getItem(`selectedPlan_${user?.user_id}`),
            value: plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
            currency: 'BRL',
            subscription_id: subscriptionId,
            test: test
        }

        if (contextRenew === 'renew') {
           const eventCtx = await analyticsWeb.track('purchase', props)

           if (!test) {
                fbq('track', 'Purchase', {
                    'value': plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
                    'currency': 'BRL'
                }, {'eventID': eventCtx.event.messageId});
            }
        } else {
            const purchaseCtx = await analyticsWeb.track('purchase', props);
            const subscriptionCtx = await analyticsWeb.track('subscription', props);

            if (!test) {
                fbq('track', 'Purchase', {
                    'value': plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
                    'currency': 'BRL'
                }, {'eventID': purchaseCtx.event.messageId});

                fbq('track', 'Subscribe', {
                    'value': plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
                    'predicted_ltv': plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
                    'currency': 'BRL'
                }, {'eventID': subscriptionCtx.event.messageId});
            }
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setIsLoading(true)

        const getPostalCode = localStorage.getItem(`subscriptionPostalCode_${user?.user_id}`);
        const formattedPostalCode = formatString(getPostalCode);
        const formattedCardNumber = formatString(subscriptionCardNumber);
        const formattedExpiryYear = formatString(subscriptionExpiryYear);
        const formattedCpfCnpj = formatString(billingCpfCnpj);
        const formattedPhone = formatString(user?.phone || '');
        const selectedPlan = localStorage.getItem(`selectedPlan_${user?.user_id}`);
        const selectedPlanFormatted = defaultPlans[selectedPlan]?.id || '';

        console.log('PLAN1', selectedPlanFormatted);

        setZipcode(formattedPostalCode);
        const formattedAddressNumber = '277'

        const storageCompany = storageOnboardingCurrentCompanyGet();
        const companySubscription = user?.companies?.[storageCompany]?.billing.status;
        const hasSubscription = companySubscription === 'active' || companySubscription === 'ACTIVE';
        //console.log('has subscription', hasSubscription, companySubscription, storageCompany)
        //console.log('contextRenew', contextRenew)

        if (contextRenew) {
            //console.log('card token renew', cardToken)
            let reactivateSubscriptionBody;

            const couponStorage = localStorage.getItem(`savedCoupon_${user?.user_id}`);
            if (subscriptionCoupon || couponStorage) {
                const formattedCouponUppercase = subscriptionCoupon.toUpperCase();
                const formattedCouponStorageUppercase = couponStorage.toUpperCase();
                reactivateSubscriptionBody = {
                    cupom: formattedCouponUppercase || formattedCouponStorageUppercase
                }
            }

            if (cardToken) {
                // Se cardToken existir, criar um objeto apenas com estas três propriedades
                reactivateSubscriptionBody = {
                    ...reactivateSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCardToken: cardToken
                };

            } else {
                reactivateSubscriptionBody = {
                    ...reactivateSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCard: {
                        holderName: subscriptionName,
                        number: formattedCardNumber,
                        expiryMonth: subscriptionExpiryMonth,
                        expiryYear: '20' + formattedExpiryYear,
                        ccv: subscriptionCcv
                    },
                    creditCardHolderInfo: {
                        name: subscriptionName,
                        email: user?.credentials.email,
                        postalCode: subscriptionPostalCode || formattedPostalCode,
                        addressNumber: formattedAddressNumber,
                        cpfCnpj: formattedCpfCnpj,
                        phone: formattedPhone
                    }
                }
            }
            //console.log('reactivateSubscriptionBody', reactivateSubscriptionBody);

            reactivateSubscription(reactivateSubscriptionBody, {
                onSuccess: () => {
                    setIsLoading(false)
                    showAlert('Plano reativado com sucesso!', 'success', 5000, true);
                    removeLocalStoragePayment();
                    storageOnboardingRemoveAll();
                    updateUser();
                    //console.log('reactivation updated successfully');
                    navigate('/dashboard');
                },
                onError: (error) => {
                    showAlert('Não foi possível reativar seu plano, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
                    setIsLoading(false)
                    //console.log('Error updating subscription', (error as Error).message);
                }
            });
        
        } else if (hasSubscription) {
            //console.log('card token', cardToken)
            let upgradeSubscriptionBody;
            const couponStorage = localStorage.getItem(`savedCoupon_${user?.user_id}`);
            if (subscriptionCoupon || couponStorage) {
                const formattedCouponUppercase = subscriptionCoupon.toUpperCase();
                const formattedCouponStorageUppercase = couponStorage.toUpperCase();
              
                upgradeSubscriptionBody = {
                    cupom: formattedCouponUppercase || formattedCouponStorageUppercase
                }
            }
            
            if (cardToken) {
                // Se cardToken existir, criar um objeto apenas com estas três propriedades
                upgradeSubscriptionBody = {
                    ...upgradeSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCardToken: cardToken
                };
                //console.log('updateSubscriptionBodycardToken', upgradeSubscriptionBody);

            } else {
                upgradeSubscriptionBody = {
                    ...upgradeSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCard: {
                        holderName: subscriptionName,
                        number: formattedCardNumber,
                        expiryMonth: subscriptionExpiryMonth,
                        expiryYear: '20' + formattedExpiryYear,
                        ccv: subscriptionCcv
                    },
                    creditCardHolderInfo: {
                        name: subscriptionName,
                        email: user?.credentials.email,
                        postalCode: subscriptionPostalCode || formattedPostalCode,
                        addressNumber: formattedAddressNumber,
                        cpfCnpj: formattedCpfCnpj,
                        phone: formattedPhone
                    }
                }
            }
            //console.log('updateSubscriptionBody', upgradeSubscriptionBody);

            upgradeSubscription(upgradeSubscriptionBody, {
                onSuccess: () => {
                    setIsLoading(false)
                    showAlert('Plano atualizado com sucesso!', 'success', 5000, true);
                    localStorage.removeItem(`currentStep_${user.user_id}`);
                    removeLocalStoragePayment();
                    storageOnboardingRemoveAll();
                    updateUser();
                    //console.log('Subscription updated successfully');
                    navigate('/dashboard');
                },
                onError: (error) => {
                    showAlert('Não foi possível alterar seu plano, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
                    setIsLoading(false)
                    //console.log('Error updating subscription', (error as Error).message);
                }
            });
        
        } else {
            
            let createSubscriptionBody = {};
            const couponStorage = localStorage.getItem(`savedCoupon_${user?.user_id}`);
            if (subscriptionCoupon || couponStorage) {
                const formattedCouponUppercase = subscriptionCoupon.toUpperCase();
                const formattedCouponStorageUppercase = couponStorage.toUpperCase();

                //console.log('subscription coupon formatted', formattedCouponStorageUppercase, formattedCouponUppercase)
                
                createSubscriptionBody = {
                    cupom: formattedCouponUppercase || formattedCouponStorageUppercase
                }
            }
            if (cardToken) {
                // Se cardToken existir, criar um objeto apenas com estas três propriedades
                createSubscriptionBody = {
                    ...createSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCardToken: cardToken
                };
                
                console.log('success cardtoken', createSubscriptionBody);
            } else {
                // Se cardToken não existir, incluir todas as outras informações
                createSubscriptionBody = {
                    ...createSubscriptionBody,
                    company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
                    description: selectedPlanFormatted,
                    creditCard: {
                        holderName: subscriptionName,
                        number: formattedCardNumber,
                        expiryMonth: subscriptionExpiryMonth,
                        expiryYear: '20' + formattedExpiryYear,
                        ccv: subscriptionCcv
                    },
                    creditCardHolderInfo: {
                        name: subscriptionName,
                        email: user?.credentials.email,
                        postalCode: subscriptionPostalCode || formattedPostalCode,
                        addressNumber: "277",
                        cpfCnpj: formattedCpfCnpj,
                        phone: formattedPhone
                    },
                };
            }

            console.log('success createsubscription', createSubscriptionBody);


            submitSubscription(createSubscriptionBody, {
                onSuccess: (response) => {
                    setIsLoading(false)
                    updateUser();
                    console.log('success createsubscription', createSubscriptionBody);
                    //console.log('success createsubscription');
                    const addressData = JSON.parse(localStorage.getItem(`subscriptionAddressData_${user?.user_id}`));
                                        
                    handleSendPayment(response.data.subscription_id, addressData).then(() => {
                        removeLocalStoragePayment();
                        if (isMobile) {
                            setIsModalOpen(true);
                        } else {
                            showAlert('Pagamento confirmado com sucesso!', 'success', 5000, true);
                            navigate('/onboarding');
                        }
                    });                
        
                    
                    },
                    onError: (error) => {
                        setIsLoading(false)
                        console.log('error createsubscription', createSubscriptionBody);
                        showAlert('Não foi possível confirmar seu pagamento, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
                        //console.log('error', (error as Error).message);
                    }
                });
        
        }

        const body = { ...userForPayment};

        const isInformationValid = signUpSchema.safeParse(body);

        if (!isInformationValid.success) {
            return;
        }
    };
   
 
    useEffect(() => {
        if (user?.user_id == undefined) {
            return;
        }

        localStorage.setItem(`selectedPlan_${user?.user_id}`, selectedPlan);
    }, [ selectedPlan]);

    let currentSelectedPlan = '';

    const handleFirstInteraction = async () => {
        if (!initiateCheckoutSent && currentSelectedPlan !== localStorage.getItem(`selectedPlan_${user?.user_id}`)) {
            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get("add_store") === "true" || urlParams.get("context") === "renew" || urlParams.get("update_plan") === "true" || urlParams.get("company_id")) {
                return
            }    

            const ip = localStorage.getItem('@solomon:_ip');
            
            const nameParts = user.name.trim().split(/\s+/);
            const first_name = nameParts[0]
            const last_name = nameParts.slice(1).join(' ');

            const plansValues = {
                basic: 97,
                advanced: 197,
                ultra: 397,
                premium: 497
            };

            const test = window.location.hostname !== "analytics.solomon.com.br" || user.credentials.email.includes("teste") || user.credentials.email.includes("asaas") || ip ===  "200.210.101.90";

            const eventCtx = await analyticsWeb.track('initiate-checkout', {
                user_agent: navigator.userAgent,
                ip: ip,
                fbp: user.facebook?.fbp || getCookieByDomain("_fbp", ".solomon.com.br"),
                fbc: user.facebook?.fbc || getCookieByDomain("_fbc", ".solomon.com.br"),
                utm_source: user.utms.utm_source || getCookieByDomain("utm_source", ".solomon.com.br"),
                utm_medium: user.utms.utm_medium || getCookieByDomain("utm_medium", ".solomon.com.br"),
                utm_campaign: user.utms.utm_campaign || getCookieByDomain("utm_campaign", ".solomon.com.br"),
                utm_content: user.utms.utm_content || getCookieByDomain("utm_content", ".solomon.com.br"),
                userId: user.user_id,
                first_name: first_name,
                last_name: last_name,
                email: user.credentials.email,
                phone: `55${user.phone.replace(/\D/g, '')}`,
                url: window.location.href,
                quantity: 1,
                plan: localStorage.getItem(`selectedPlan_${user?.user_id}`),
                value: plansValues[localStorage.getItem(`selectedPlan_${user?.user_id}`)],
                currency: 'BRL',
                test: test
            });

            if(!test) {
                fbq('track', 'InitiateCheckout', {}, {eventID: eventCtx.event.messageId});
            }

            currentSelectedPlan = localStorage.getItem(`selectedPlan_${user?.user_id}`);
            setInitiateCheckoutSent(true);
        };
    };

    const getStoredStep = () => {
        const storedStep = localStorage.getItem('currentStep');
        return storedStep ? JSON.parse(storedStep) : 1;
      };

    const [currentStep, setCurrentStep] = useState(getStoredStep);

    useEffect(() => {
        if (user?.user_id == undefined) {
            return;
        }

        localStorage.setItem('currentStep', JSON.stringify(currentStep));
    }, [currentStep]);

    
     // Efeito para pular o Step1 e ir direto para o Step2 se updatePlan for true
    useEffect(() => {
        if (updatePlan === 'true') {
        setCurrentStep(1);
        setDisableStoreInput(true)
        localStorage.setItem('currentStep', JSON.stringify(2));
        }
    }, [updatePlan]);

    useEffect(() => {
        const savedIsFirstButtonEnabled = localStorage.getItem(`isFirstButtonEnabled_${user?.user_id}`);
        if (savedIsFirstButtonEnabled === 'true') {
        setIsFirstButtonEnabled(true);
        }
    }, []);


    const fetchAddressFunction = async (zipcode : string) => {
        const data = await getAddressInfo(zipcode);
        //console.log('data', data.data.erro)
        if (data.data.erro) {
            setCepError(true);
            showAlert('CEP inválido, por favor, insira um CEP válido.', 'error', 5000, true);
            setCurrentStep(1);
            localStorage.setItem('currentStep', JSON.stringify(1));
            return;
        } else {
            //console.log('data success', data.data)
            localStorage.setItem(`subscriptionAddressData_${user?.user_id}`, JSON.stringify(data.data)); 
        }

        fbq('init', '776084714023352', {
            ct: data.data.localidade, 
            st: data.data.uf, 
            zp: formatString(data.data.cep),
            country: 'Brazil'
        });
    };


    const handleNextStep = () => {

        fetchAddressFunction(subscriptionPostalCode);

        if (disabledStoreInput) {
            setCurrentStep(2);
            localStorage.setItem('currentStep', JSON.stringify(2));
            localStorage.setItem(`subscriptionPostalCode_${user?.user_id}`, subscriptionPostalCode); 

            setIsFirstButtonEnabled(true);
            localStorage.setItem(`isFirstButtonEnabled_${user?.user_id}`, 'true');

            return; // Se disableStoreInput for true, sai imediatamente da função
        }
        if (currentStep < 2) {  // Assumindo que há apenas 2 passos
            setCurrentStep(2);
            localStorage.setItem('currentStep', JSON.stringify(2));
            localStorage.setItem(`subscriptionPostalCode_${user?.user_id}`, subscriptionPostalCode); 

            setIsFirstButtonEnabled(true);
            localStorage.setItem(`isFirstButtonEnabled_${user?.user_id}`, 'true');


            const companyBody = {
                email: user?.credentials?.email,
                company: storeName,
                mask_id: user?.mask_id,
                referred: false,
                user_id: user?.user_id,
            };

            const BillingAccountBody = {
                name: user?.name,
                email: user?.credentials.email,
                mobilePhone: user?.phone
            };

            const companyRenameBody = {
                company: storeName,
                user_id: user?.user_id,
                company_id: storageOnboardingCurrentCompanyGet() ?? onbCompany,
            };

            submitBillingAccount(BillingAccountBody, {
                onSuccess: () => {
                    createOrUpdateCompany();
                    //console.log('success');
},
                onError: (error) => {
                    //console.log('error', (error as Error).message);
                    if ((error as Error).message === 'Each user must have exclusively one billing account') {
                        createOrUpdateCompany();
                    }
                }
            });

            function createOrUpdateCompany() {
                setCompanyCreating(true);

                if (storageOnboardingCurrentCompanyGet() || onbCompany) {
                    //console.log('Updating company');
                    renameCompanyOnboarding(companyRenameBody, {
                        onSuccess: () => {
                            //console.log('Company updated');
                            storageOnboardingNameSave(storeName);
                            setCompanyCreating(false);
                            storagePlaceholderCompanyRemove();
                            storageShExternalIdRemove();
                        },
                    });
                } else {
                    createCompanyOnboarding(companyBody, {
                        onSuccess: (response) => {
                            const companyId = response.data.data.company_id;
                            updateUser();
                            setCurrentCompanyContext(companyId);
                            //console.log('Company created',response, companyId);
                            storageOnboardingCurrentCompanySave(companyId);
                            storageOnboardingNameSave(storeName);
                            setCompanyCreating(false);
                            storagePlaceholderCompanyRemove();
                            storageShExternalIdRemove();
                        },
                        onError: (error) => {
                            showAlert('Não foi possível criar sua conta, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
                            setCompanyCreating(false);
                            setCurrentStep(1);
                            localStorage.setItem('currentStep', JSON.stringify(1));
                        }
                    });
                }
            }
            
        } 
        
    };

    useEffect(() => {
        const checkIfAllFieldsFilled = () => {
            const savedIsFirstButtonEnabled = localStorage.getItem(`isFirstButtonEnabled_${user?.user_id}`);

            if (savedIsFirstButtonEnabled === 'true') {
                setIsFirstButtonEnabled(true);
            } else {
               
                    if (storeName && subscriptionPostalCode) {
                        setIsFirstButtonEnabled(true);
                    } else {
                        setIsFirstButtonEnabled(false);
                    }
                
            }
        };

        checkIfAllFieldsFilled();
    }, [storeName, subscriptionPostalCode]);

    useEffect(() => {
        const checkIfAllFieldsFilled = () => {
    
          if (addStore === 'true') {
            if (selectedCard || (subscriptionCardNumber && subscriptionName && subscriptionExpirationDate && subscriptionCcv && subscriptionPostalCode && billingCpfCnpj)) {
                setSubmitButtonEnabled(true);
              return;
            } else {
              setSubmitButtonEnabled(false);
              return;
            }
          }
    
          if (updatePlan === 'true') {
            if (selectedCard) {
              setSubmitButtonEnabled(true);
              return;
            }
    
            if (
              subscriptionCardNumber &&
              subscriptionName &&
              subscriptionExpirationDate &&
              subscriptionCcv &&
              subscriptionPostalCode &&
              billingCpfCnpj
            ) {
              //console.log('tá aqui?');
              setSubmitButtonEnabled(true);
              return;
            } else {
              setSubmitButtonEnabled(false);
              return;
            }
          }
    
          if (
            subscriptionCardNumber &&
            subscriptionName &&
            subscriptionExpirationDate &&
            subscriptionCcv &&
            billingCpfCnpj
          ) {
            setSubmitButtonEnabled(true);
          } else {
            setSubmitButtonEnabled(false);
          }
        };
    
        checkIfAllFieldsFilled();
      }, [
        addStore,
        updatePlan,
        subscriptionCardNumber,
        subscriptionPostalCode,
        subscriptionName,
        subscriptionExpirationDate,
        subscriptionCcv,
        selectedCard,
        billingCpfCnpj,
        setSubmitButtonEnabled
      ]);
      useEffect(() => {
        if (submitCoupon) {
          const fetchCoupon = async () => {
            try {
              setIsLoading(true);
              const formattedCoupon = subscriptionCoupon.trim().toUpperCase();
              //console.log('submitcoupon fetch', subscriptionCoupon, formattedCoupon)
              const data = await getCoupon(formattedCoupon);
              //console.log('getCouponData', data);
              handleCalculateCoupon(data);
              localStorage.setItem(`savedCoupon_${user?.user_id}`, formattedCoupon);
            } catch (err) {
              showAlert('Cupom inválido ou expirado!', 'error', 5000, true);
              console.error('Error fetching coupon:', err);
              setCouponError(true);
              setSubmitCoupon(false);
            } finally {
              setIsLoading(false);
            }
          };
      
          fetchCoupon();
        }
      }, [submitCoupon]);

      function handleCalculateCoupon(getCoupon) {
        const plansArray = defaultPlans;
        const selectedDiscountCalculate = getCoupon?.discount;
      
        if (plansArray && selectedDiscountCalculate) {
          const updatedPlans = Object.keys(plansArray).reduce((acc, key) => {
            const selectedPlan = plansArray[key];
            const selectedPlanPrice = selectedPlan.price;
            const discountAmount = selectedPlanPrice * (selectedDiscountCalculate / 100);
            const finalPrice = selectedPlanPrice - discountAmount;
            //console.log('final price', finalPrice)
      
            acc[key] = {
              ...selectedPlan,
              price: finalPrice
            };
      
            //console.log('acc', acc)
            return acc;
          }, {});

      
          localStorage.setItem(`plansWithCouponArray_${user?.user_id}`, JSON.stringify(updatedPlans));
          showAlert('Cupom aplicado com sucesso!', 'success', 5000, true);
          setSubmitCoupon(false);   
        } else {
          console.error('Plans array or discount not found.');
          showAlert('Erro ao aplicar cupom!', 'error', 5000, true);
        }
      }

    return (
        <S.Container>
            <SideBarPayment updatePlan={updatePlan} isLoadingPlans={isLoadingPlans} setStoragePlans={setStoragePlans} contextRenew={contextRenew} plans={defaultPlans} setPlans={setDefaultPlans} calculatedPlanPrice={calculatedPlanPrice} setCouponError={setCouponError} couponError={couponError} setSubmitCoupon={setSubmitCoupon} addStore={addStore} subscriptionCoupon={subscriptionCoupon} setSubscriptionCoupon={setSubscriptionCoupon} handleFirstInteraction={handleFirstInteraction} />
        <S.ContainerContent isMobile={isMobile}>
           <S.MainTitle>
                 <S.Title>Confirme seu pagamento</S.Title>
                <S.SubtitleSection>
                    <S.Subtitle>Estamos quase lá! Complete seu pagamento e comece sua jornada conosco!</S.Subtitle>
                </S.SubtitleSection>
            </S.MainTitle>

        <S.SidesContainer>
            <S.RightColumn>

            <S.InputSection>
                </S.InputSection>
                {currentStep === 1 ? (
                        <Step1
                        disabledStoreInput={disabledStoreInput}
                        isMobile={isMobile}
                        storeName={storeName}
                        setStoreName={setStoreName}
                        subscriptionPostalCode={subscriptionPostalCode}
                        setSubscriptionPostalCode={setSubscriptionPostalCode}
                        addStore={addStore}
                        handleFirstInteraction={handleFirstInteraction}
                        isFirstButtonEnabled={isFirstButtonEnabled}
                        handleNextStep={handleNextStep}
                        />
                    ) : (
                        <Step2
                        setCardToken={setCardToken}
                        companyCreating={companyCreating}
                        setSubmitButtonEnabled={setSubmitButtonEnabled}
                        isLoadingPayment={isLoading}
                        setIsLoading={setIsLoading}
                        updatePlan={updatePlan}
                        isMobile={isMobile}
                        subscriptionCardNumber={subscriptionCardNumber}
                        setSubscriptionCardNumber={setSubscriptionCardNumber}
                        subscriptionName={subscriptionName}
                        setSubscriptionName={setSubscriptionName}
                        subscriptionExpirationDate={subscriptionExpirationDate}
                        setSubscriptionExpirationDate={setSubscriptionExpirationDate}
                        subscriptionCcv={subscriptionCcv}
                        handleFirstInteraction={handleFirstInteraction}
                        setSubscriptionCcv={setSubscriptionCcv}
                        isSubmitButtonEnabled={isSubmitButtonEnabled}
                        subscriptionPostalCode={subscriptionPostalCode}
                        setSubscriptionPostalCode={setSubscriptionPostalCode}
                        handleSubmit={handleSubmit}
                        setCurrentStep={setCurrentStep}
                        addStore={addStore}
                        setAddStore={setAddStore}
                        setSelectedCard={setSelectedCard}
                        billingCpfCnpj={billingCpfCnpj}
                        setBillingCpfCnpj={setBillingCpfCnpj}
                        />
                    )}  
            <S.FinalText isMobile={isMobile}>
            Todos os <span>seus dados de pagamento</span> são processados com <span>segurança através de tecnologia de criptografia avançada,</span> garantindo a máxima proteção.
            </S.FinalText>
            <S.AlreadyHaveAccount>
            </S.AlreadyHaveAccount>
            </S.RightColumn>
        </S.SidesContainer>
        </S.ContainerContent>
        {isModalOpen && ( 
            <Modal
            onClose={() => ('')}
            >
                <S.ModalContent>
                    <VerifiedIcon/>
                    <h2>Compra realizada com sucesso!</h2>
                    <p>Por favor, verifique seu email e continue o processo pelo seu computador ou notebook.</p>
                    <S.Button isSignOut={true} onClick={signOut}><LogoutIcon/> <p>Sair</p></S.Button>
                </S.ModalContent>
            </Modal>
        )}
        </S.Container>
    );
}

function VerifiedIcon() {
    return (  
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm94-278 226-226-56-58-170 170-86-84-56 56 142 142Z"/>
        </svg>
    );
}