import { CardConversionsProps } from '../../../../../@types';
import CheckIcon from '@mui/icons-material/Check';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as S from './style';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import { Tooltip } from '@mui/material';

export function CardConversions({ color, Icon, type, approvedOrders, pendingOrders, cancelledOrders, approvedRevenue, pendingRevenue, cancelledRevenue }: CardConversionsProps) {
    const totalRevenue = Number(approvedRevenue) + Number(pendingRevenue) + Number(cancelledRevenue);
    const percentage = approvedRevenue && totalRevenue ? ((Number(approvedRevenue) / totalRevenue) * 100).toFixed(0) : 0;

    const complementar = 1 - Number(percentage) / 100;
    const dashoffset = 339.2920065877 * complementar;
    const { currencySymbol } = useCurrencyType();

    return (
        <S.Container>
            <S.Graphic>
                <S.TypePaymentsContainer>
                    <S.PaymentsValue color={color}>
                        <span>{percentage}</span>%
                    </S.PaymentsValue>
                    <S.TypePayments>
                        <Icon sx={{ width: '10px', height: '10px', color: '#b3b3b3' }} />
                        {type}
                    </S.TypePayments>
                </S.TypePaymentsContainer>
                <svg width="120" height="120">
                    <circle cx="60" cy="60" r="54" stroke="#e6e6e6" stroke-width="12" fill="none" stroke-linecap="round" />
                    <circle cx="60" cy="60" r="54" stroke={color} stroke-width="12" fill="none" stroke-dasharray="339.2920065877" stroke-dashoffset={dashoffset} stroke-linecap="round" />
                </svg>
            </S.Graphic>
            <S.Information>
                <CheckIcon sx={{ width: '12px', height: '12px', color: '#27b927' }} />
                <span>Aprov.</span>
                <S.ValueInformation>
                    <p>
                    {approvedRevenue == '0'
                        ? '0,00'
                        : Number(approvedRevenue).toLocaleString('pt-BR', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                          })}{' '}
                    </p>
                    <Tooltip title={approvedOrders} placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '5px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } }, }}>

                    <span>({approvedOrders})</span>
                    </Tooltip>
                </S.ValueInformation>
            </S.Information>
            <S.Information>
                <PendingOutlinedIcon sx={{ width: '12px', height: '12px', color: '#f8c030' }} />
                <span>Pend.</span>
                <S.ValueInformation>
                    <p>
                    {pendingRevenue == '0'
                        ? '0,00'
                        : Number(pendingRevenue).toLocaleString('pt-BR', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                          })}{' '}
                    </p>
                    <Tooltip title={pendingOrders} placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '5px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } }, }}>

                    <span>({pendingOrders})</span>
                    </Tooltip>
                </S.ValueInformation>
            </S.Information>
            <S.Information>
                <CloseOutlinedIcon sx={{ width: '12px', height: '12px', color: '#f35757' }} />
                <span>Canc.</span>
                <S.ValueInformation>
                    <p>
                    {cancelledRevenue == '0'
                        ? '0,00'
                        : Number(cancelledRevenue).toLocaleString('pt-BR', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                          })}{' '}
                    </p>
                    <Tooltip title={cancelledOrders} placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '5px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } }, }}>
                    <span>({cancelledOrders})</span>
                    </Tooltip>
                </S.ValueInformation>
            </S.Information>
        </S.Container>
    );
}
