import styled from 'styled-components/';

type ContainerProps = {
    isIntegrate: boolean;
    type?: string;
};

export const Container = styled.div<ContainerProps>`
    ${(props) => (props.type === 'cartpanda' ? 'img{width: 9rem;}' : props.type === 'yever' ? 'img {width: 4.3rem;}' : props.type === 'facebook' ? 'img {width: 7rem;}' : props.type === 'google' ? 'img {width: 7em;}' : props.type === 'gateway' ? 'img { width: 1.5rem; }' :  'img {width: 6rem;}')}
    span {
        font-size: 1.5rem;
        color: #8361c6;
        font-weight: 600;
        line-height: 1.8rem;
        margin-left: 0.5rem;
    }

    width: 16rem;
    height: 3.4rem;
    border-radius: 13px;
    ${(props) => (props.isIntegrate ? 'border: 1px solid #18C37F;' : 'border: 1px solid #989c97;')}
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
`;
