import { useQuery, useMutation } from 'react-query';
import { deleteShopifySubscriptionUserData, getShopifySubscriptionUserData } from '../api/api-shopify-subscription';


export function useShopifySubscriptionData(company_id: string) {
    return useQuery(['shopify_subscription', company_id], () => getShopifySubscriptionUserData(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useDeleteSubscriptionShopify() {
    return useMutation(deleteShopifySubscriptionUserData);
}