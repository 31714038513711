import { TemplateModal } from '../../';
import * as S from './styles';
import { ButtonApp } from '../../../../../components/ButtonApp';
import GroupIcon from '@mui/icons-material/Group';
import useAuth from '../../../../../hooks/useAuth';
import { CardAccount } from './CardAccount';
import { useGetUrlTiktokAdsAuth, useRemoveTiktokAdsAccount, useRemoveTiktokAdsProfile, useSyncTiktokAdsAccounts } from '../../../../../services/hooks/integrationHooks';
import { useEffect, useState } from 'react';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';
import { Tooltip } from '@mui/material';
import { Search } from '../../../../../components/Search';
import { TablePlaceholder } from '../../../../../components/TablePlaceholder';

export function ModalTiktok() {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';
    const baseColor = '#fe2c55';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();

    const tiktokInfo = user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles[user?.companies?.[currentCompany]?.integrations?.tiktok_ads?.profiles?.length - 1];

    const hasTiktokInfo = tiktokInfo?.profile_id !== 'default-profile';

    const { showAlert } = useAlert();

    const [filter, setFilter] = useState('')

    const [selectedAccountId, setSelectedAccountId] = useState('')
    const [selectedProfileId, setSelectedProfileId] = useState('')
    const [showConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupAccount] = useState(false)
    const [showConfirmDisconnectPopupProfile, setShowConfirmDisconnectPopupProfile] = useState(false)
    const [tiktokStatusInactive, setTiktokStatusInactive] = useState({ inactive: false, account_id: '' })

    const {mutate: handleRemoveTiktokAccount, isLoading: isLoadingRemoveTiktokAccount} = useRemoveTiktokAdsAccount();
    const {mutate: handleRemoveTiktokProfile, isLoading: isLoadingRemoveTiktokProfile} = useRemoveTiktokAdsProfile();

    const { mutate: handleSyncTiktokAccount, isLoading} = useSyncTiktokAdsAccounts();

    const { data: linkTiktok } = useGetUrlTiktokAdsAuth(user?.user_id, currentCompany, 'home');

    function handleLinkTiktok() {
        window.open(linkTiktok?.data, '_blank');
    }
    function syncTiktok(){
        handleSyncTiktokAccount(currentCompany,{
            onSuccess: ({data}) => {
                const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, tiktok_ads: {profiles: data?.data?.profiles}} } } }  
                setUserContext(newUser)
                updateUser()
            }
        });
    }

    const handleMessage = (event: any) => {
        // Verifica se a origem da mensagem compartilha o mesmo domínio base da página atual
        if (new URL(event.origin).origin !== window.location.origin) {
          return; // Ignora a mensagem se a origem não for a mesma
        }
      
        if (event.data.type === 'TiktokSyncComplete') {            
            // quero que atualize a lista de contas (com o loading)
            handleSyncTiktokAccount(currentCompany,{
                onSuccess: ({data}) => {
                    const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, google_ads: {profiles: data?.data?.profiles}} } } }  
                    setUserContext(newUser)
                    updateUser()
                }
            });
          
        }
    };
    
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []); 


    function handleDesintegrateProfile(){
    
    const body = {
        company_id: currentCompany,
        user: user,
        profile_id: selectedProfileId,
        truncate: true,
    }

    showAlert('Desconectando perfil... Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
    setShowConfirmDisconnectPopupProfile(false)
        
    handleRemoveTiktokProfile(body, {
        onSuccess: ({data}) => {
                syncTiktok();
            }
        })
    }
    
    function handleDesintegrateAccount(){
        const body = {
            company_id: currentCompany,
            profile_id: selectedProfileId,
            account_id: selectedAccountId,
            user: user
        }

        setTiktokStatusInactive({inactive: true, account_id: selectedAccountId})
        showAlert('Conta desconectada! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setShowConfirmDisconnectPopupAccount(false)
        
        handleRemoveTiktokAccount(body, {
            onSuccess: ({data}) => {
            }
        })
    }   

    const tiktokInfos = [tiktokInfo]; // Transformando o objeto único em um array


    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Tiktok'}
                title={'Tiktok Ads'}
                subtitle={'Integre sua loja com o Tiktok e automatize seu processo de dropshipping.'}
                statusConnection={tiktokInfo?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
                {hasTiktokInfo && 
                <>
                <S.SyncArea>
                <div style={{marginLeft: '-0.5rem'}}>
                    <Search placeholder="Pesquisar contas de anúncios." width="14rem" height='2.5rem' value={filter} setValue={setFilter} />
                </div>
                <Tooltip 
                    title="Atualizar novas contas de anúncios do perfil."
                    placement="top"
                    componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                    <S.SyncContainer onClick={syncTiktok} disabled={isLoading}>
                        <S.SyncIcon>
                            <SyncIcon />
                        </S.SyncIcon>
                        <S.SyncText>
                            Sincronizar Contas
                        </S.SyncText>
                    </S.SyncContainer>
                    </Tooltip>
                </S.SyncArea>
                    {tiktokInfos.map((info) => {
                    // Filtrar contas de anúncio por nome ou ID
                    const filteredAccounts = info.accounts.filter(account =>
                        account.assets.advertiser_name.toLowerCase().includes(filter.toLowerCase()) ||
                        account.account_id.includes(filter)
                    );

                    if (filteredAccounts.length > 0) {
                            return (
                                <CardAccount 
                                setTiktokStatusInactive={setTiktokStatusInactive} 
                                tiktokStatusInactive={tiktokStatusInactive} 
                                setSelectedProfileId={setSelectedProfileId} 
                                setSelectedAccountId={setSelectedAccountId} 
                                setShowConfirmDisconnectPopupAccount={setShowConfirmDisconnectPopupAccount} 
                                setShowConfirmDisconnectPopupProfile={setShowConfirmDisconnectPopupProfile} 
                                tiktokInfo={{ ...info, accounts: filteredAccounts }} 
                                isLoadingOutside={isLoading}
                            />
                        );
                        } else {
                            return (
                            <S.PlaceholderArea>
                                <TablePlaceholder size='small' showSubtitle={true} subtitleText='Nenhuma conta de anúncios encontrada.' />
                            </S.PlaceholderArea>
                            ); // Não renderizar CardAccount se não houver contas correspondentes
                        }
                    })}
                </>}
                  
            </S.InnerContainer>
                
            <Tooltip 
                title="Adicionar um novo perfil do Tiktok Ads."
                placement="top"
                componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                <S.ButtonContainer>
                    <ButtonApp
                        border=''
                        width="13rem"
                        height="2rem"
                        color="#fff"
                        background="var(--black)"
                        icon={<img src="https://cdn.icon-icons.com/icons2/2429/PNG/512/tik_tok_logo_icon_147226.png" />}
                        onClick={() => {handleLinkTiktok()}}
                        text={!hasTiktokInfo ? 'Continuar com Tiktok' : 'Adicionar perfil do Tiktok'}
                    />
                </S.ButtonContainer>
            </Tooltip>
             

                {showConfirmDisconnectPopupAccount && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupAccount}
                        onClose={() => setShowConfirmDisconnectPopupAccount(false)}
                        onConfirm={handleDesintegrateAccount}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveTiktokAccount}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por esta conta na Solomon. Tem certeza que deseja continuar?
                              <br />
                              <br />
                              <strong>(Esta conta permanecerá na lista de contas.)</strong>
                            </>
                          }
                          
                    />
                )}
                {showConfirmDisconnectPopupProfile && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupProfile}
                        onClose={() => setShowConfirmDisconnectPopupProfile(false)}
                        onConfirm={handleDesintegrateProfile}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveTiktokProfile}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por este perfil na Solomon. Tem certeza que deseja continuar?
                            </>
                          }
                    />
                )}
        </S.Container>
    );
}


function SyncIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z"/>
    </svg>
    )
}