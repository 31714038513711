import { TemplateModal } from '../../index';
import * as S from './style';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateIntegrationKiwify, useDeleteIntegrationKiwify } from '../../../../../services/hooks/integrationHooks';
import useAuth from '../../../../../hooks/useAuth';
import { ModalKiwifyProps, userDTO } from '../../../../../@types';
import { useIntercom } from 'react-use-intercom';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';

export function ModalKiwify({ setShowPopupKiwify }: ModalKiwifyProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/kiwify-icon.png';
    const baseColor = '#19c380';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();

    const kiwifyInformation = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts[user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length - 1];

    const hasKiwifyInfo = kiwifyInformation?.account_id !== 'default-account';

    const { showAlert } = useAlert();
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);

    const { showArticle } = useIntercom();
    const { mutate: handleCreateIntegrationKiwify, isLoading: isLoadingIntegrate, isError } = useCreateIntegrationKiwify();
    // const { mutate: handleDisconnectKiwify, isLoading: isLoadingDesintagrate } = useDeleteIntegrationKiwify();

    const [kiwifyInfo, setKiwifyInfo] = useState({
        aliasKiwify: kiwifyInformation?.account_id !== 'default-account' ? kiwifyInformation?.account_id : '',
        accessCliendIdKiwify: kiwifyInformation?.assets?.client_id || '',
        clientSecretKiwify: kiwifyInformation?.assets?.client_secret || '',
    });

    function handleIntegrateIntegration() {
        const body = {
            account_id: kiwifyInfo.aliasKiwify,
            client_id: kiwifyInfo.accessCliendIdKiwify,
            client_secret: kiwifyInfo.clientSecretKiwify,
            company_id: currentCompany,
            user: user
        };

        handleCreateIntegrationKiwify(body, {
            onSuccess: () => {
                setShowPopupKiwify(false);
                const active = {
                    account_id: kiwifyInformation?.account_id,
                    assets: {
                        client_id: kiwifyInformation?.assets?.client_id,
                        client_secret: kiwifyInformation?.assets?.client_secret,
                    },
                    last_update: "",
                    status: "active"
                }
                const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, kiwify: { ...user?.companies?.[currentCompany]?.integrations?.kiwify, accounts: [active] } } } } };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    // function handleDisconnectIntegrationKiwify() {
    //     const body = {
    //         account_id: kiwifyInfo.aliasKiwify,
    //         company_id: currentCompany,
    //         truncate: true,
    //         user: user
    //     };

    //     showAlert('Desconectando Kiwify... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);

    //     const inactive = {
    //         account_id: "default-account",
    //         assets: {},
    //         last_update: "",
    //         status: "inactive"
    //     }

    //     const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, kiwify: { ...user?.companies?.[currentCompany]?.integrations?.kiwify, accounts: [inactive] } } } } };
    //     setUserContext(newUser as userDTO);
    //     updateUser();
    //     setShowPopupKiwify(false);

    //     setShowConfirmDisconnectPopup(false);

    //     handleDisconnectKiwify(body, {
    //         onSuccess: () => {
    //         },
    //     });
    // }

    function handleChangeKiwifyInfo(e: any) {
        setKiwifyInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Kiwify'}
                title={'Kiwify'}
                subtitle={'Integre sua loja com a Kiwify e automatize seu processo de infoproduto.'}
                statusConnection={kiwifyInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration showPassword={false} value={kiwifyInfo.aliasKiwify} setValue={handleChangeKiwifyInfo} inputName="Account ID:" inputId="aliasKiwify" placeholder="Solomon" />
                <InputIntegration
                    showPassword={false}
                    value={kiwifyInfo.accessCliendIdKiwify}
                    setValue={handleChangeKiwifyInfo}
                    inputName="Client ID de acesso:"
                    inputId="accessCliendIdKiwify"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                <InputIntegration
                    showPassword={false}
                    value={kiwifyInfo.clientSecretKiwify}
                    setValue={handleChangeKiwifyInfo}
                    inputName="Client Secret:"
                    inputId="clientSecretKiwify"
                    placeholder="kh_ZjybughBUBYBUNjgygyutBybnbcJI4Gu337JV"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => {showArticle(8726310)}}>
                <HelpOutlineOutlinedIcon  sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
            {hasKiwifyInfo && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowConfirmDisconnectPopup(true)}
                                text="Desconectar"
                            />
                        </>
                    )}
                    {!hasKiwifyInfo && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={handleIntegrateIntegration}
                                text="Integrar"
                                isLoading={isLoadingIntegrate}
                                disabled={kiwifyInfo.aliasKiwify?.length === 0 || kiwifyInfo.accessCliendIdKiwify?.length === 0 || kiwifyInfo.clientSecretKiwify?.length === 0}
                            />
                        </>
                    )}
            </S.DisconnectContainer>

            {/* {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDisconnectIntegrationKiwify}
                title="Confirmação necessária"
                isLoading={isLoadingDesintagrate}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )} */}
        </S.Container>
    );
}


