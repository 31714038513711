import { LineTableTrackingAdsProps } from '../../../../@types';
import * as S from './style';
import { toBrazilianFormat } from '../../../../utils';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAuth from '../../../../hooks/useAuth';
import useVisibility from '../../../../hooks/useVisibilityChange';
import Tooltip from '@mui/material/Tooltip';
import { calculateFinalOrders, calculateFinalRevenue, calculateSumOfOrders, capitalizeFirstLetter, checkUtm, getAdsDataWarning } from '../../utils';
import { useState, useEffect, useMemo } from 'react';
import { getIconForPlatform } from '../../utils';
import { useColumnsTracking } from '../../../../hooks/useColumnsTracking';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import LinesItem from '../../LinesItem';
import StatusToggle from '../../StatusToggle';
import { Checkbox } from '@mui/material';
import { useCheckboxTracking } from '../../../../hooks/useCheckboxTracking';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';

export function LineTableTrackingAds({ type, paymentMethod, ads, dataMetrics, columnSolomon, handleSelectGraphicData, handleScrolltoGraphic, openSalesModal, date, columnWidths, dataCatalogList, loadingCatalog }: LineTableTrackingAdsProps) {
    const {currencySymbol} = useCurrencyType();

    const isMainSource = ['facebook', 'tiktok', 'google', 'allSources'].includes(Array.isArray(type) ? type[0] : type);

    const [showWarning, setShowWarning] = useState({
        alert: false
    });

    const { columns } = useColumnsTracking();

    const { currentCompany, user } = useAuth();

    const { selectedAdsIds, toggleAdId } = useCheckboxTracking();

    const isTypeArray = Array.isArray(type)
    const [_, sourceName, sourceUrl] = type;

    const sourceUrlIcon = useMemo(() => getIconForPlatform(isTypeArray ? sourceName : type), [type]);

    const { isVisible } = useVisibility();

    const adsString = ads?.adInfo;

    const [showCopiedPopup, setShowCopiedPopup] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(ads?.adName)
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000); // Hide popup after 2 seconds
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    const memoizedGetAdsData = useMemo(() => {
        return getAdsDataWarning;
    }, []);
    

    const filteredAdsData = useMemo(() => memoizedGetAdsData(dataMetrics, isTypeArray ? sourceName : type), [dataMetrics, isTypeArray ? sourceName : type]);

    useEffect(() => {
        utm_is_right((isTypeArray ? sourceName : type), filteredAdsData);
    }, [adsString, filteredAdsData, type]);


    function utm_is_right(type: string, filteredAdsData: any) {
        let AdsData = filteredAdsData[adsString];
        if (!AdsData) {
            return;  //Sair da função se não houver dados
        }
        if (!checkUtm(type, AdsData.utm_source,AdsData.utm_medium,  AdsData.utm_campaign, AdsData.utm_content)) {
            setShowWarning(prevState => ({
                ...prevState,
                alert: true
            }));
        }
    }

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);

    useEffect(() => {
        if (paymentMethod) {
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect

     
    const ordersSolomon = calculateFinalOrders(columnSolomon, ads, activePaymentMethods);
    const revenueSolomon = calculateFinalRevenue(columnSolomon, ads, activePaymentMethods);
    
    //console.log('adsSETT', ads);

    const adsNameHasCatalogPlaceholder = ads?.isCatalog === true;

    const handleCatalogClick = () => {
        openSalesModal({
            source: 'facebook',
            company_id: currentCompany,
            ads_ids: adsString ?? '',
            level: 'ad',
            start_date: date[0],
            end_date: date[1],
            name: ads?.adName
        }, ads, 'ads', true, ordersSolomon, revenueSolomon )
    };

    return (
        <>
            <S.Container>
                <S.MainColumn style={{ width: `${columnWidths}px` }} type={isTypeArray ? sourceName : type}>
                <S.CheckBoxArea>
                        <Checkbox
                            checked={selectedAdsIds.some(item =>
                                item.id === ads?.adInfo &&
                                item.type === (isTypeArray ? sourceName : type)
                            )}
                            onChange={() => toggleAdId(ads?.adInfo, isTypeArray ? sourceName : type)}
                            size="small"
                            sx={{
                                '&.Mui-checked': {
                                    color: '#19c273',
                                },
                            }}
                        />
                    </S.CheckBoxArea>
                <S.StatusArea>
                    {ads?.sourceType === 'facebook' && (

                        <StatusToggle
                            initialStateData={ads?.adStatus}
                            objectId={ads?.adInfo}
                            objectType={'ads'}
                            data={ads}
                            currentCompany={currentCompany}
                            user={user}
                        />
    
                    )}
                </S.StatusArea>
                    <img src={Array.isArray(type) ? sourceUrl : sourceUrlIcon} alt={""} />

                    <S.CampaignTitleContainer>
                        <S.UrlLinkClick>{isVisible ? ads?.adName : '* * * * * * * * * *'}</S.UrlLinkClick>
                        <S.ChannelSelectedContainer hasCatalog={adsNameHasCatalogPlaceholder}>
                            <Tooltip title={sourceName === 'outros' ? 'Orgânico / Outros' : ads?.adGroupName || ''} placement="bottom">
                                <p>{ isVisible ? sourceName === 'outros' ? 'Orgânico / Outros' : ads?.adGroupName : '* * * * * * * *'}</p>
                            </Tooltip>
                            {adsNameHasCatalogPlaceholder && (
                                <S.CatalogIcon onClick={handleCatalogClick}> <ViewModuleRoundedIcon /> Catálogo</S.CatalogIcon>
                            )}
                        </S.ChannelSelectedContainer>
                    </S.CampaignTitleContainer>

                    <S.ActionArea>
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ width: '16px', height: '16px' }} />
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'white',
                                    borderColor: '#E90E0E',
                                    fontWeight: '550',
                                    fontSize: '13px',
                                    fontFamily: 'Montserrat',
                                    borderRadius: '7px',
                                    border: 1,
                                    padding: '10px',
                                    zIndex: 0,
                                    color: '#E90E0E',
                                }
                            },
                        }}
                        title={'UTM incorreta no período selecionado'} placement="right">
                        <S.ProductNameContainer style={showWarning.alert ? {} : {display: 'none'}}>{showWarning.alert && <WarningIcon />}</S.ProductNameContainer>
                    </Tooltip>

                    {/* { adsNameHasCatalogPlaceholder && (
                        <S.CatalogIcon onClick={handleCatalogClick}> <ViewModuleRoundedIcon /> Ver produtos </S.CatalogIcon>

                    )} */}
                    </S.ActionArea>
                </S.MainColumn>

                {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}


                <LinesItem HasCatalog={adsNameHasCatalogPlaceholder} handleCatalogClick={handleCatalogClick} columns={columns} currencySymbol={currencySymbol} ads={ads} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} type={'ads'} openSalesModal={openSalesModal} currentCompany={currentCompany} date={date} adsString={adsString} source={Array.isArray(type) ? sourceName : type} user={user} loadingCatalog={loadingCatalog}/>


            </S.Container>
        </>
    );
}


function WarningIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
        </svg>
    )
}