import * as S from './style';

export function HeaderCostOperation({ tab }: { tab: string }) {
    return (
        <S.Container>
            <S.DefaultColumn>Nome</S.DefaultColumn>
            <S.DefaultColumn>Tipo</S.DefaultColumn>
            <S.DefaultColumn>Valor</S.DefaultColumn>
            <S.DefaultColumn>Frequência</S.DefaultColumn>
            <S.DefaultColumn>Data</S.DefaultColumn>
            {tab === 'Entrada' && <S.DefaultColumn>Ações</S.DefaultColumn>}
        </S.Container>
    );
}
