import styled from 'styled-components';

export const Container = styled.div`
    height: 3.09375rem;
    display: flex;
    color: var(--black);
    font-size: 0.83125rem;
    justify-content: space-around;
    font-weight: 500;
`;

export const OrderId = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1rem;
    justify-content: space-between;
    width: 23.625rem;
    border-right: 1px solid var(--gray-180);
    color: var(--green);
    font-weight: 500;
    font-size: 0.875rem;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    span {
        justify-content: flex-end;
        margin-right: 1rem;
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Date = styled.div`
    display: flex;
    align-items: center;
    font-weight: 550;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Payment = styled.div`
    display: flex;
    color: var(--green);
    font-weight: 550;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
    gap: 0.5rem;
    img {
        width: 0.75rem;
    }
`;

export const PendingTag = styled.span`
  background-color: #f5a717;
  color: white;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 50px;
  font-size: 12px;
`;

export const SuccessTag = styled.span`
  background-color: var(--green);
  color: white;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 50px;
  font-size: 12px;
`;

export const RefundTag = styled.span`
  background-color: #376cbb;
  color: white;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 50px;
  font-size: 12px;
`;

export const ExtraTag = styled.div`
    padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    text-wrap: nowrap;
    font-size: 0.7rem;
    gap: 0.3rem;
    color: #6b6b6b;
    background-color: #e7e7e7;

    svg {
        color: #47514d;
        font-size: 1rem;
    }
`;

export const Value = styled.div`
    display: flex;
    font-weight: 550;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Approved = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Pending = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Cancelled = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Plus = styled.div`
    border: none;
    gap: 5px;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    color: #7c7c7c;
    padding: 5px 16px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    margin-left: 1rem;
    transition: ease-in-out 0.1s;
    &:hover {
        transition: transform ease-in-out 0.2s;
        background-color: #0c1612;
        color: white;
        backface-visibility: hidden;
        transform: translateY(-2px);
        box-shadow: 6.5227px 6.5227px 17px -2.1605px rgba(71, 118, 99, 0.08);
    }
`;

export const notSave = styled.div`
    border: none;
    gap: 5px;
    display: flex;
    align-items: center;
    color: rgb(202, 41, 41);
    padding: 5px 16px;
    border: none;
    border-radius: 7px;
    font-weight: 500;
    font-size: 12px;
    transition: ease-in-out 0.1s;
`;


export const ContainerPopup = styled.div`
    width: 33rem;
    height: 26rem;
    border-radius: 20px;
    background-color: var(--white);
`;

type ImagePopupProps = {
    type: string;
};

export const ImagePopup = styled.div<ImagePopupProps>`
    img {
        margin-top: 1rem;
        margin-left: 1.2rem;
        width: 2rem;
    }
`;

export const ModalPopup = styled.div`
    width: 50%;
    height: 50%;
    background-color: white;
`

export const TitlePopup = styled.div`
    color: #1e1e1e;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1.2rem;
    margin-top: 1rem;
    span {
        color: #19c380;
    }
`;

export const SubtitlePopup = styled.div`
    color: var(--cinza, #989c97);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 0 1.2rem;
    margin-top: 0.5rem;
`;

export const Line = styled.div`
    background-color: #d9dbd9;
    height: 1px;
    display: flex;
    flex: 1;
    margin: 0.5rem 1rem 2.5rem 1rem;
    
`;

export const ContainerInput = styled.div`
width: 20rem;
    margin-left: 14px;
    padding: 5px;

input {
    padding: 5px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: var(--allblack);
    background-color: #f8f8f8;
    height: 2.3rem;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
    /* border: none; */
    outline: none;
    &::placeholder {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.125rem;
&:hover{
cursor: not-allowed;
}
}
`

export const SubsubtitlePopup = styled.div`
    font-size: 0.7rem;
    line-height: 1.125rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem 0 1rem; /* Adjusted margin */
`;

export const NumberInput = styled.input`
    height: 2rem;
    width: 31rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
    font-size: 12px;
    font-weight: 400;
    margin: 0.5rem 1rem 0rem 1rem; /* Adjusted margin */
`;


export const Label = styled.label`
    color: #000;
    font-size: 1rem; 
`;

export const KeepEdit = styled.label`
    display: flex;
    justify-content: center;
    padding: 0.9em;
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    left: 0;
    color: white !important;
    margin-top: 24px;
    /* align-self: center; */
    background-color: #19c27e;
    border: none;
    border-radius: 7px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;  
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        color: whitesmoke;
        transform: translateY(-7px);
    }
`;