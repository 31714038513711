import { useEffect, useState } from 'react';
import * as S from './style';
import { ButtonApp } from '../../../../../components/ButtonApp';
import ShareIcon from '@mui/icons-material/Share';
import { formatCurrencyInput } from '../../../../../utils';
import { useChangeShipping } from '../../../../../services/hooks/shippingHooks';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import useAuth from '../../../../../hooks/useAuth';
import { ModalShippingProps, userDTO } from '../../../../../@types';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import useAlert from '../../../../../hooks/useAlert';

export function ModalShipping({hasShippingTax, setHasShippingTax, setShowPopupShipping } : ModalShippingProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/frete%20icon.png';
    const baseColor = '#19c380';

    const { currentCompany, user, setUserContext } = useAuth();
    
    const [calculationRule, setCalculationRule] = useState('custo_nenhum');
    const [shipping_cost, setShipping_cost] = useState('');
    const [top_order_price, setTop_order_price] = useState('');

    const { mutate: changeShipping, isLoading } = useChangeShipping(calculationRule);
    const {currencySymbol} = useCurrencyType();
    const { showAlert } = useAlert();

    const handleFormatShippingCostValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCurrencyInput(event);
        setShipping_cost(formattedValue);
    };

    const handleFormatOrderPriceValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCurrencyInput(event);
        setTop_order_price(formattedValue);
    };


    useEffect(() => {
        const shippingCost = user?.companies?.[currentCompany]?.manual_inputs?.shipping?.shipping_cost; 
        const topOrderPrice = user?.companies?.[currentCompany]?.manual_inputs?.shipping?.top_order_price;
        const calculationRule = user?.companies?.[currentCompany]?.manual_inputs?.shipping?.calculation_rule;
        
        if (calculationRule) {
            setCalculationRule(calculationRule);
            setShipping_cost(shippingCost?.toString());
            setTop_order_price(topOrderPrice?.toString());
        }

    }, [user?.companies?.[currentCompany]?.manual_inputs?.shipping]);
    
    const handleIntegrate = () => {
        const formattedShippingCost = parseFloat(shipping_cost.replace(',', '.'));
        const formattedOrderPrice = parseFloat(top_order_price.replace(',', '.'));      
    
        const body = {
            calculation_rule: calculationRule,
            shipping_cost: formattedShippingCost,
            top_order_price: formattedOrderPrice,
            company_id: currentCompany
        };

        const newShippingTax = {calculation_rule: calculationRule, shipping_cost: formattedShippingCost, top_order_price: formattedOrderPrice};

        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    manual_inputs: {
                        ...user?.companies?.[currentCompany]?.manual_inputs,
                        shipping: newShippingTax,
                    },
                },
            },
        };

        setUserContext(newUser as userDTO);

        
        
        changeShipping(body, {
            onSuccess: () => {
            },
            
            onError: (error) => {
                // Ação após erro, e.g., mostrar mensagem de erro
                console.error(error);
            }
        });
        if (calculationRule === 'custo_nenhum') {
            setHasShippingTax(false);
            showAlert('Limpando custos de frete, por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        } else {
            showAlert('Frete configurado! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        }
        setShowPopupShipping(false);
    };


    const showInputValue1 = calculationRule === 'custo_fixo';
    const showInputValue2 = calculationRule === 'custo_variavel';

    const isIntegrate = hasShippingTax;

    return (
        <S.Container>
            <S.Logo>
                <img src={urlLogo} alt={`Logo do Frete`} />
            </S.Logo>
            <S.Title baseColor={baseColor}>
                Configurações do <span>Frete</span>
            </S.Title>
            <S.Subtitle>Defina e personalize os custos de frete para ajustar à sua operação.</S.Subtitle>
            <S.Line></S.Line>
            <S.SelectTitle>
            </S.SelectTitle>
            <S.SelectType>
                <S.SelectOption
                    selected={calculationRule === 'custo_nenhum'}
                    onClick={() => (setCalculationRule('custo_nenhum'), setShipping_cost('0'), setTop_order_price('0'))}
                    className={calculationRule === 'custo_nenhum' ? 'selected' : ''}
                >
                    <S.CheckCircle selected={calculationRule === 'custo_nenhum'} />
                    <S.SelectText>Não desejo configurar custo de Frete</S.SelectText>
                </S.SelectOption>
                <S.SelectOption
                    selected={calculationRule === 'custo_do_checkout'} 
                    onClick={() => (setCalculationRule('custo_do_checkout'), setShipping_cost('0'), setTop_order_price('0'))}
                    className={calculationRule === 'custo_do_checkout' ? 'selected' : ''}
                >
                    <S.CheckCircle selected={calculationRule === 'custo_do_checkout'} />
                    <S.SelectText>Aplicar frete pago pelo cliente no checkout</S.SelectText>
                </S.SelectOption>
                <S.SelectOption
                    selected={calculationRule === 'custo_fixo'}
                    onClick={() => (setCalculationRule('custo_fixo'), setTop_order_price('0'))}
                    className={calculationRule === 'custo_fixo' ? 'selected' : ''}
                >
                    <S.CheckCircle selected={calculationRule === 'custo_fixo'} />
                    <S.SelectText>Aplicar frete fixo por pedido</S.SelectText>
                </S.SelectOption>
                {showInputValue1 && (
                    <S.InputValue>
                        <input placeholder="0,00" value={shipping_cost} onChange={handleFormatShippingCostValue} />
                        <S.InputIcon>
                            <span>{currencySymbol}</span><span>|</span>
                        </S.InputIcon>
                        <p>por pedido.</p> 
                    </S.InputValue>
                )}
                <S.SelectOption
                    selected={calculationRule === 'custo_variavel'}
                    onClick={() => setCalculationRule('custo_variavel')}
                    className={calculationRule === 'custo_variavel' ? 'selected' : ''}
                >
                    <S.CheckCircle selected={calculationRule === 'custo_variavel'} />
                    <S.SelectText>Aplicar frete variável por pedido</S.SelectText>
                </S.SelectOption>
                {showInputValue2 && (
                    <S.SelectType2>
                        <S.InputValue2>
                            <p>para pedidos menores que:</p> 
                            <input placeholder="0,00" value={top_order_price} onChange={handleFormatOrderPriceValue} />
                            <S.InputIcon2>
                                <span>{currencySymbol}</span><span>|</span>
                            </S.InputIcon2>
                        </S.InputValue2>
                        <S.InputValue3>
                            <p>aplicar:</p> 
                            <input placeholder="0,00" value={shipping_cost} onChange={handleFormatShippingCostValue} />
                            <S.InputIcon3>
                                <span>{currencySymbol}</span><span>|</span>
                            </S.InputIcon3>
                            <p>por pedido.</p> 
                        </S.InputValue3>
                    </S.SelectType2>
                )}
            </S.SelectType>
            <S.InfoContainer onClick={() => window.open('https://youtu.be/QtQl51rPzjI', '_blank')}>
                    <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Como configuro meus custos de frete?
            </S.InfoContainer>
            
            <S.DisconnectContainer>
                {!isIntegrate && (
                    <>
                        Salvar configurações:{' '}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleIntegrate}
                            text="Salvar"
                        />
                    </>
                )}
                {isIntegrate && (
                    <>
                        Salvar configurações:{' '}
                        <S.ButtonArea>
                        {/* <ButtonApp
                            border=''
                            width="5.75rem"
                            height="1.25rem"
                            color="#F41B19"
                            background="transparent"
                            onClick={handleDesintegrate}
                            text="Desconectar"
                        /> */}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleIntegrate}
                            text="Salvar"
                        />
                        </S.ButtonArea>
                    </>
                )}
            </S.DisconnectContainer>
            {/* <S.FinalContainer>
                <S.FinalText>
                Salvar configurações:
                </S.FinalText>
                <S.FinalButton>
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleSaveClick}
                    isLoading={isLoading}
                    text="Salvar"

                />
                </S.FinalButton>
            </S.FinalContainer> */}
        </S.Container>
    );
}