declare global {
    interface Window {
        fbq?: (...args: any[]) => void;
        _fbq?: (...args: any[]) => void;
    }
}
  
export const initFacebookPixel = (pixelId: string, fbp: string, fbc: string) => {
    (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = 'https://connect.facebook.net/en_US/fbevents.js';
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, 'script');

    console.log("initial fbp", fbp)
    console.log("initial fbc", fbc)
    
    fbq('set', 'autoConfig', false, pixelId);
    fbq('init', pixelId, { $fbp: fbp });

    if (fbc) {
        fbq('init', pixelId, { $fbc: fbc })
    }
};
  
export const fbq = (...args) => {
    if (window.fbq) {
        window.fbq(...args);
    } else {
        console.error('Facebook Pixel não foi inicializado corretamente.');
    }
  };