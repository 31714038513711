import { useState, useEffect, useRef } from 'react';
import * as S from './style';
import { InputWithMoneyProps } from '../../@types';

export function InputWithTypeMoney({ disabled = false, defaultMoney = 'BRL', value, setValue, width = '11.25rem', variantId = '' , currency, withCurrency=true}: InputWithMoneyProps) {
    const [type, setType] = useState(currency || defaultMoney);
    const [valueFormatted, setValueFormatted] = useState(value);
    const [showOptions, setShowOptions] = useState(false);
    const optionsRef = useRef<HTMLDivElement>(null);

    function formatValue(valueInput: string) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        setValue(newValue, variantId, type);
        setValueFormatted(newValue);
        return newValue;
    }

    useEffect(() => {
        if (type !== (currency || defaultMoney)){
            // console.log("entrou no useEffect", type, currency, defaultMoney);
            setValue(valueFormatted, variantId, type);
        }        
    }, [type]);


    function handleSelectTypeMoney(value: string) {
        setType(value);
        setShowOptions(false);
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
                setShowOptions(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (disabled)
        return (
            <S.Container width={width} disabled={disabled}>
                <S.Money disabled={disabled}>
                    {type} <span>▼</span>
                </S.Money>{' '}
                <input type="text" placeholder={value ? undefined : "0,00"} value={value} disabled />
            </S.Container>
        );

    if (!withCurrency){
        return (
            <S.Container width={width} disabled={disabled}>
                <S.Money disabled={true} onClick={() => {}}>
                    {'R$'} 
                </S.Money>{' '}
                <input type="text" placeholder={value ? undefined : "0,00"} value={formatValue(value)} autoFocus onChange={(e) => formatValue(e.target?.value)} />
            </S.Container>
        );
    }

    return (
        <S.Container width={width} disabled={disabled}>
            <S.Money disabled={disabled} onClick={() => setShowOptions(true)}>
                {type} <span>▼</span>
            </S.Money>{' '}
            <input type="text" placeholder={value ? undefined : "0,00"} value={value} autoFocus onChange={(e) => formatValue(e.target?.value)} />
            <S.MoneyTypes ref={optionsRef} showOptions={showOptions}>
                <S.MoneyTypesOption onClick={() => handleSelectTypeMoney('BRL')}>BRL</S.MoneyTypesOption>
                <S.MoneyTypesOption onClick={() => handleSelectTypeMoney('USD')}>USD</S.MoneyTypesOption>
                {/* <S.MoneyTypesOption onClick={() => handleSelectTypeMoney('EUR')}>EUR</S.MoneyTypesOption> */}
            </S.MoneyTypes>
        </S.Container>
    );
}
