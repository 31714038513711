import * as S from './style';
import Skeleton from '@mui/material/Skeleton';

export function LineProductLoading() {
    return (
        <S.Container>
            <S.Image>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '5rem' }} />
            </S.Image>
            <S.ProductName>
                <Skeleton sx={{ fontSize: '1rem', width: '10rem', height: '2rem' }} />
            </S.ProductName>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.Costs>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Costs>
            <span>
                <S.Plus>
                    <Skeleton sx={{ width: '1.375rem', height: '2.5rem', borderRadius: '50%' }} />
                </S.Plus>
            </span>
            <S.Costs>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Costs>
            <S.Tax>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Tax>
        </S.Container>
    );
}
