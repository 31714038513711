import React, {useState, useEffect} from 'react';
import * as S from './style';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import { InputPayment } from '../../../Payment/InputPayment';
import { PlanCardPaymentProps } from '../../../../@types';
import { formatDate, formatValue } from '../../../../utils';
import { set } from 'date-fns';
import useAuth from '../../../../hooks/useAuth';
import Skeleton from '@mui/material/Skeleton';


export function PlanCardSignUp({disabled, isLoadingPlans, handleFirstInteraction, isMobile, plans, onSelect, isSelected, savings }: PlanCardPaymentProps) {
  const { user } = useAuth();
  
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem(`selectedPlanSignUp`);
    return plans?.[savedPlan] || plans?.basic;  // Inicia com o plano do localStorage ou o plano básico
  });

  const [showSelect, setShowSelect] = useState(false);  // Controla a visibilidade do dropdown

  useEffect(() => {
    const planFromStorage = localStorage.getItem(`selectedPlanSignUp`);
    if (planFromStorage && plans?.[planFromStorage]) {
      setSelectedPlan(plans?.[planFromStorage]);
    }
  }, [plans]); // Atualiza quando a lista de planos muda

  const handleSelect = (planKey) => {
    setSelectedPlan(plans[planKey]);
    localStorage.setItem(`selectedPlanSignUp`, planKey); // Salva a escolha no localStorage
    setShowSelect(false);  // Fecha o dropdown após a seleção
    onSelect(planKey);  // Chama a função onSelect passada como prop
  };
  

  return (
    <S.CardContainer disabled={disabled} isMobile={isMobile} selected={isSelected}>
        <S.InnerContainer onClick={() => setShowSelect(!showSelect)}>
        {isLoadingPlans ? <Skeleton variant="rounded" width={'100%'} height={60} /> : (
            <S.Select isMobile={isMobile}>
                <S.LeftSide>
                    <S.PlanName>{selectedPlan.planName}<CheckRoundedIcon/></S.PlanName>
                    <p>{selectedPlan.description}</p>
                </S.LeftSide>
                <S.RightSide>
                    <S.Price isMobile={isMobile}><span>R$</span>{formatValue(selectedPlan.price)}</S.Price>
                    <S.Description isMobile={isMobile}>
                    {selectedPlan?.maxOrders == 0 ? 'p/ mês, pedidos ilimitados' : `p/ mês até ${selectedPlan?.maxOrders} pedidos`}
                    </S.Description>
                </S.RightSide>
                <ExpandMoreRoundedIcon style={{ transform: showSelect ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>
                {showSelect && (
                    <S.OptionsContainer>
                        {Object.keys(plans).map(key => (
                            <S.Option key={key} onClick={() => handleSelect(key)}>
                                <S.LeftSide>
                                    <S.PlanNameOption>{plans[key].planName}</S.PlanNameOption>
                                </S.LeftSide>
                                <S.RightSide>
                                    <S.PriceOption><span>R$</span> {formatValue(plans[key].price)}</S.PriceOption>
                                    <S.DescriptionOption>
                                      {plans[key].maxOrders == 0 ? 'p/ mês, pedidos ilimitados' : `p/ mês até ${plans[key].maxOrders} pedidos`}
                                    </S.DescriptionOption>
                                </S.RightSide>
                            </S.Option>
                        ))}
                    </S.OptionsContainer>
                )}
            </S.Select>
          )
        }
        </S.InnerContainer>
  
      <S.Savings isMobile={isMobile} onClick={onSelect} selected={isSelected}>
       
      <S.BottomArea isMobile={isMobile} onClick={onSelect} selected={isSelected}>
            <h1>Vantagens</h1>
          <S.AdvantageColumnArea>
            <S.AdvantageColumn>
              <S.Advantage><CheckCircleRoundedIcon/>
              {selectedPlan?.planName == 'Plano Essencial' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook)</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook, Google)</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook, Google, Tiktok)</strong>
                </p>
              )}
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/>
                {selectedPlan?.planName == 'Plano Essencial' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 3</strong> Contas de anúncio Facebook
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 10</strong> Contas de anúncio Facebook
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Contas de anúncio Facebook <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitadas</strong>
                </p>
              )}
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/>
              {selectedPlan?.planName == 'Plano Essencial' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 2</strong> Usuários
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 3</strong> Usuários
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Usuários <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitados</strong>
                </p>
              )}
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/><p>Dashboard Financeiro</p></S.Advantage>
            </S.AdvantageColumn>
            <S.AdvantageColumn>
              <S.Advantage><CheckCircleRoundedIcon/><p>Análise de produtos</p></S.Advantage>
              <S.Advantage>
              {selectedPlan?.planName == 'Plano Essencial' && (
                null
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
               null
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <>
                  <CheckCircleRoundedIcon/>
                  <p>Análise de clientes
                  </p>
                </>
              )}
              </S.Advantage>
              <S.Advantage>
              {selectedPlan?.planName == 'Plano Essencial' && (
                null
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <>
                <CheckCircleRoundedIcon/>
                <p>Suporte <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}>VIP</strong>
                </p>
                </>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                  <>
                  <CheckCircleRoundedIcon/>
                  <p>Suporte <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Exclusivo</strong>
                  </p>
                  </>
              )}
              </S.Advantage>
            </S.AdvantageColumn>
          </S.AdvantageColumnArea>
        </S.BottomArea>
        <S.ExtraContainer>
          <PaidRoundedIcon/>
          {selectedPlan?.pricePerExtraOrder == 0 ? 'Pedidos extras gratuitos' : `R$${formatValue(selectedPlan?.pricePerExtraOrder)} por pedido extra`}
        </S.ExtraContainer>

      </S.Savings>

    </S.CardContainer>
  );
};
