import { CardClientsProps } from '../../../@types';
import { toBrazilianFormat } from '../../../utils';
import Skeleton from '@mui/material/Skeleton';
import * as S from './style';
import { useCurrencyType } from '../../../hooks/useCurrencyType';

export function CardClients({ title, content, icon, information, titleInformation, type, isLoading }: CardClientsProps) {

    const { currencySymbol } = useCurrencyType();
    
    return (
        <S.Container>
            <S.Title>
                {title}
                {!!titleInformation && (
                    <S.TitleInformation>
                        {!!information && <S.ContentInformation>{information}</S.ContentInformation>}
                        {titleInformation}
                    </S.TitleInformation>
                )}
                {icon}
            </S.Title>
            {isLoading && (<Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.8rem' }} />)}



            {!isLoading && type === 'ltv' && (
                <S.Content>
                    {currencySymbol}{' '}
                    {content === 0
                        ? '0,00'
                        : toBrazilianFormat(content)}
                </S.Content>
            )}
            {!isLoading && type === 'tempo' && <S.Content>{content === 0 ? '0' : Math.floor(content)} Dias</S.Content>}
            {!isLoading && (type === 'recompra' || type === 'clients') && <S.Content>{content}</S.Content>}
        </S.Container>
    );
}
