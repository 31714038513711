import * as S from './style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LineClientsProps } from '../../../../@types';
import { DownloadClients } from '../../../../@types';
import { toBrazilianFormat } from '../../../../utils';
import Skeleton from '@mui/material/Skeleton';
import useAuth from '../../../../hooks/useAuth';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import { useState, useEffect, useRef } from 'react';
import { getClientsInformationDownload } from '../../../../services/api/api-clients';

export function LineClients({ type, data, setDataPopup, setShowPopup, isLoading, segmentation}: LineClientsProps) {
    const name = type;

    const { currencySymbol } = useCurrencyType();

    const imageUrlClients = 'https://i.ibb.co/S5xpykL/trophy-3113025.png';
    const imageUrlWarning = 'https://i.ibb.co/Vw3Vsv6/warning-4201973.png';

    const isLostClients = name === 'Perdidos';
    const {currentCompany} = useAuth();

    function handleShowPopup() {
        setDataPopup({ ...data, type });
        setShowPopup(true);
    }

    const [showOption, setShowOption] = useState(false);
    const optionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (optionRef.current && !optionRef.current.contains(event.target as Node)) {
                setShowOption(false);
            }
        };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [optionRef]);

    const [downloadParams, setDownloadParams] = useState<DownloadClients | undefined | null>(null);

    useEffect(() => {
        if (downloadParams) {
            const { company_id, segmentation, filetype } = downloadParams;
            getClientsInformationDownload(company_id, segmentation, filetype)
                .then((data) => {
                    //console.log('Download data:', data);
                })
                .catch((error) => {
                    console.error('Error downloading clients information:', error);
                })
                .finally(() => {
                    setDownloadParams(null);
                });
        }
    }, [downloadParams]);

    function handleHook(segmentation: string, filetype: string) {
        setDownloadParams({ company_id: currentCompany, segmentation: segmentation, filetype: filetype });
    }

    return (
        <S.Container>
            <S.MainLine type={type} onClick={handleShowPopup}>
                <img src={`${isLostClients ? imageUrlWarning : imageUrlClients}`} /> {name} <InfoOutlinedIcon sx={{ width: '13px', height: '13px', color: '#5488dc' }} />
            </S.MainLine>

            {isLoading && Array.from({ length: 3 }).map((_, index) => {
                return (
                    <S.SecondaryLine key={index}> <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem', marginLeft: '0.5rem' }} /></S.SecondaryLine>
                )
            })}
            {isLoading && <S.SecondaryLine> <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '4rem', marginLeft: '0.5rem' }} /></S.SecondaryLine>}

            {!isLoading && <S.SecondaryLine>{data?.base_size ?? 0}</S.SecondaryLine>}
            {!isLoading && <S.SecondaryLine>{data?.purchases ?? 0}</S.SecondaryLine>}
            {!isLoading && <S.SecondaryLine>
                {currencySymbol}{' '}
                {data?.revenue === 0 || !data?.revenue
                    ? '0,00'
                    : toBrazilianFormat(data?.revenue)}
            </S.SecondaryLine>}
            {!isLoading && <S.SecondaryLine>
                <S.Button onClick = {() => setShowOption(true)}>
                    <FileDownloadOutlinedIcon sx={{ width: '16px', height: '16px', color: '#1e1e1e' }} />
                </S.Button>
                {showOption && (
                    <S.Option showOption={showOption} ref={optionRef}>
                        <S.OptionBtn onClick = {() => handleHook(segmentation, 'crm')}>CRM</S.OptionBtn>
                        <S.OptionBtn onClick = {() => handleHook(segmentation, 'fb')}>Facebook Ads</S.OptionBtn>
                    </S.Option>
                )}
            </S.SecondaryLine>}
        </S.Container>
    );
}
