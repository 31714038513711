import styled, {keyframes, css} from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(-1);
    }
    to {
        transform: rotate(360deg) scaleX(-1);
    }
`;

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;

export const Container = styled.div`
    padding: 1rem 1rem 0rem 1rem;

     ::-webkit-scrollbar {
        width: 8px !important; /* Altura da barra de rolagem */
    }
`;

export const InnerContainer = styled.div`
    max-height: 50vh;
    height: auto;
    margin-top: 4rem;
    padding: 0 1rem 0;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
`;

export const SyncContainer = styled.button`
background-color: #ECECEC;
border-radius: 50px;
display: flex;
align-items: center;
gap: 0.1rem;
justify-content: center;
transition: 0.3s;
cursor: pointer;
// margin-bottom: 1rem;
&:hover{
    background-color: #212529;
    transform: translateY(2px);
    color: #fff;
    svg{
        transition: 0.3s;
        fill: #fff;
    }
}
width: 11.9rem;
height: 1.875rem;
&:disabled{
    svg{
        ${animationStyles}
    }
    
}
`

export const SyncIcon = styled.div`
svg{
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: -0.2rem;
}
`

export const SyncText = styled.div`
font-size: 0.875rem;
font-weight: 500;
line-height: 1.3125rem;

`

export const ButtonContainer = styled.div`
    display: flex;
    width: fit-content;
    margin: 2rem auto 0rem auto;
    justify-content: center;
`;


export const SyncArea = styled.div`
  
     position: absolute;
    display: flex;
    width: 34rem;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    margin-top: -2.8rem;
`


export const PlaceholderArea = styled.div`
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 2rem 0;
`