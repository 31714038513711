import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useCurrencyType } from '../../hooks/useCurrencyType';

const MarketingGraph = ({ xAxisData, yAxisDatasets, intervalData, isMobile }) => {
    
    const chartRef = useRef(null);

    const yAxis = yAxisDatasets[0]?.data;

    console.log('MarketingGraph', xAxisData, yAxis);

    const { currencySymbol } = useCurrencyType();

    // Função para mapear as cores das séries com base no nome
    const getColorForSeries = (name) => {
        switch (name) {
            case 'Total':
                return '#19c27e';
            case 'Facebook':
                return '#3d77ec';
            case 'Google':
                return '#b60001';
            case 'TikTok':
                return 'black';
            case 'Manual':
                return 'grey';
            case 'Gateway':
                return '#F54F';
            case 'Checkout':
                return 'purple';
            case 'Impostos':
                return '#2D5557';
            default:
                return 'grey'; // Cor padrão caso necessário
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = echarts.init(chartRef.current);
            console.log('yaxis', yAxisDatasets);

             // Supondo que yAxisDatasets seja uma array de objetos, onde cada objeto tem a propriedade 'data' que é um array de séries
             const series = yAxisDatasets[0].data.map((dataset) => ({
                name: dataset.name,
                type: 'line',
                data: dataset.data,
                smooth: false,
                lineStyle: {
                    width: 3,
                    color: getColorForSeries(dataset.name)
                },
                itemStyle: {
                    color: getColorForSeries(dataset.name)
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: getColorForSeries(dataset.name) },
                        { offset: 1, color: 'white' }
                    ])
                }
            }));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        let result = params[0].axisValueLabel + '<br/>';
                        params.forEach(function (item) {
                            result += `${item.marker} ${item.seriesName}: ${currencySymbol}${item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {
                    data:  yAxisDatasets[0].data.map((dataset) => dataset.name),
                    right: '60',
                    icon: 'roundRect',
                    itemWidth: 15,
                    itemGap: 20,
                    itemHeight: 15,
                    textStyle: {
                        padding: isMobile ? [3,3,3,3] : [10, 10, 10, 10],
                        fontFamily: 'Montserrat',
                        fontSize: isMobile ? 10 : 16,
                        color: 'gray',
                        fontWeight: 500,
                    },
                },
                grid: {
                    left: '2%',
                    right: '5%',
                    top: isMobile ? '20%' : '15%',
                    bottom: isMobile ? "0%" : "15%",
                    containLabel: true,  
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xAxisData,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: isMobile ? 10 : 14,
                        padding: isMobile ? [3,3,3,3] : [10, 10, 10, 10],
                        show: true,
                        interval: intervalData,
                        formatter: function (value) {
                            const monthNames = [
                                "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
                                "Jul", "Ago", "Set", "Out", "Nov", "Dez",
                            ];
                            const parts = value.split("-");
                            const day = parts[2];
                            const month = monthNames[parseInt(parts[1]) - 1];
                            return `${day}/${month}`;
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: function (value) {
                            return `${currencySymbol}${value.toFixed(2)}`;
                        },
                        fontFamily: 'Montserrat',
                        fontSize: isMobile ? 9 : 13,
                        color: 'gray',
                    }
                },
                series: series,
            };

            chartInstance.setOption(option);

            // Handle window resize
            const handleResize = () => {
                chartInstance.resize();
            };

            window.addEventListener('resize', handleResize);

            // Clean up on unmount
            return () => {
                window.removeEventListener('resize', handleResize);
                chartInstance.dispose();
            };
        }
    }, [yAxisDatasets]);

    return <div ref={chartRef} style={isMobile ? {width: '100%', height: '200px'} : { width: '100%', height: '400px' }} />;
};

export default MarketingGraph;
