import { CardMyStoreProps } from '../../../@types';
import * as S from './style';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomerIcon from '../../../components/CustomerIcon';
export function CardMyStore({ name, email, onClick }: CardMyStoreProps) {

    return (
        <S.Container onClick={onClick}>
            <S.InfoStore>
                <S.UserAvatar>
                <CustomerIcon fontColor={'#999'} fontSize={'1rem'} name={name} />

                </S.UserAvatar>
                <div>
                    <S.DataStore>
                        <S.NameStore>{name}</S.NameStore>
                    </S.DataStore>
                    <S.EmailUser>{email}</S.EmailUser>
                </div>
            </S.InfoStore>
            <S.Arrow>
                <ArrowForwardIosIcon sx={{ width: '13px', height: '13px', color: '#0e1819' }} />
            </S.Arrow>
        </S.Container>
    );
}
