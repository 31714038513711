import { IntegrationLoadingOnboardingProps } from '../../../@types';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useEffect, useState } from 'react';
import * as S from './style';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';
import { CustomCheckCircleOutlineRoundedIcon } from './style';
import { AnimatedProgress } from './style';


export function IntegrationLoadingOnboardingV2({ type, percentage }: IntegrationLoadingOnboardingProps) {


    let url = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';

    switch (type) {
        case 'Shopify':
            url = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';
            break;

        case 'Facebook':
            url = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
            break;

        case 'Tiktok':
            url = 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';
            break;

        case 'Google':
            url = 'https://storage.googleapis.com/static-images-source/google-icon.png';
            break;

        case 'Appmax (Gateway)':
            url = 'https://framerusercontent.com/images/ZvKI0Bp6qyMy3u5Ei06pQe9nYA.png';
            break;

        case 'Dom Pagamentos (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/dom%20icon.png';
            break;
                
        case 'Yampi (Checkout)':
            url = 'https://i.ibb.co/vB7Hhnk/yampi.png';
            break;

        case 'Cartpanda (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/cartpanda-icon.png';
            break;
      
        case 'Yever (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/yever%20icon%202.png';
            break;
      
        case 'Yever (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/yever%20checkout.png';
            break;
      
        case 'Adoorei (Checkout)':
            url = 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg';
            break;
      
        case 'Unico Pag (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png';
            break;
      
        case 'Shopify (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg';
            break;
      
        case 'Outro (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/gateway-icon.png';
            break;
       
        case 'Mercado Pago (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/mercadopago%20icon.png';
            break;

        
    }

    const [displayedPercentage, setDisplayedPercentage] = useState(0);
    const isCompleted = percentage === 100;
    
    // Função para calcular o valor visual do progresso
    const getVisualPercentage = (realPercentage) => {
        if (realPercentage < 45) {
            // Proporcional até 45%
            return realPercentage * (80 / 45);
        } else if (realPercentage < 100) {
            // De 45% até 100%, mapeia de 80% até 100%
            return 85 + (realPercentage - 45) * (20 / 55);
        }
        return 100; // No caso de estar completo
    };
    
    useEffect(() => {
        let intervalId;
        const increment = 1; // Ajuste o valor de incremento para controlar a velocidade da animação
        const updateFrequency = isCompleted ? 30 : 800; // Velocidade mais rápida quando está completando
    
        const updateProgress = () => {
            setDisplayedPercentage(prev => {
                const visualPercentage = getVisualPercentage(percentage);
                if (prev < visualPercentage) {
                    return Math.min(prev + increment, visualPercentage);
                } else {
                    clearInterval(intervalId);
                    return prev;
                }
            });
        };
    
        intervalId = setInterval(updateProgress, updateFrequency);
    
        return () => clearInterval(intervalId);
    }, [percentage, isCompleted]);



    return (
        <S.Container>
            <Tooltip title={type} placement="top">
                <S.LoadIntegrationContainer>
                {!isCompleted ? (
                        <>
                            <AnimatedProgress variant="determinate" value={displayedPercentage} size={26} />
                            <img src={url} alt={type + " logo"} style={type === 'Mercado Pago (Gateway)' ? {width: '1rem', height: '0.7rem'} : {}} />
                        </>
                    ) : (
                        <CustomCheckCircleOutlineRoundedIcon />
                    )}
                </S.LoadIntegrationContainer>
            </Tooltip>
        </S.Container>
    );
}
