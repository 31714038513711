import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import * as S from './style';

export function BannerText() {
    const texts = [
        'Empresas que usam dados têm 23 vezes mais chances de adquirir clientes.',
        'Manter um cliente custa 5 vezes menos do que conquistar um novo.',
        'Com o traqueamento Solomon, você ganha insights detalhados sobre a performance das suas campanhas. Permitindo ajustes precisos em tempo real, otimizando o ROI e garantindo que cada centavo do seu orçamento seja usado de maneira mais eficiente.',
        'Usar dados de clientes para segmentar seu público por comportamento de compra e preferências aumenta a eficácia do marketing e as conversões.',
    ];

    const [currentText, setCurrentText] = useState(0);

    const interval = 6000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentText((prev) => (prev + 1) % texts.length);
        }, interval);

        return () => clearInterval(intervalId);
    }, [texts.length, interval]);

    const handleBackClick = () => {
        setCurrentText((prev) => (prev - 1 + texts.length) % texts.length);
    };

    const handleForwardClick = () => {
        setCurrentText((prev) => (prev + 1) % texts.length);
    };

    return (
        <S.Container>
            
            <S.CircleLeft onClick={handleBackClick}>
                <ArrowBackIcon sx={{ width: '10px', height: '10px' }} />
            </S.CircleLeft>
            <S.CircleRight onClick={handleForwardClick}>
                <ArrowForwardIcon sx={{ width: '10px', height: '10px' }} />
            </S.CircleRight>

            <S.Title>VOCÊ SABIA?</S.Title>
            <S.Text>{texts[currentText]}</S.Text>
            <S.LineSection>
                <S.Line isActive={currentText === 0} />
                <S.Line isActive={currentText === 1} />
                <S.Line isActive={currentText === 2} />
                <S.Line isActive={currentText === 3} />
            </S.LineSection>
        </S.Container>
    );
}