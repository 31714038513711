import { Banner } from '../../components/Banner';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import * as S from './style';
import { CardClients } from './CardClients';
import { TableClients } from './TableClients';
import { useClientsInformation } from '../../services/hooks/clientsHooks';
import { DataClientsPopup, ModalLineClientsProps, clientsInformationDTO } from '../../@types';
import { trackEvent } from '../../services/segment/sendEvent'
import useAuth from '../../hooks/useAuth';
import { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import {useIntercom} from 'react-use-intercom';
import { useCurrencyType } from '../../hooks/useCurrencyType';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { ButtonApp } from '../../components/ButtonApp';
export function Clients() {
    const { user, currentCompany } = useAuth();
    const { data, isLoading } = useClientsInformation(currentCompany);
    const [showPopup, setShowPopup] = useState(false);
    const [dataPopup, setDataPopup] = useState({} as DataClientsPopup);
    const { showArticle } = useIntercom();

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'customers'
        });
    }, [])


    return (
        <S.Container>
            {showPopup && <ModalLineClients dataPopup={dataPopup} setShowPopup={setShowPopup} />}
            <S.Title><h2>Análise de clientes</h2>
                <p>Monitoramento e análise em tempo real da sua base de clientes.</p>
            </S.Title>
            <ButtonApp  
                border=''
                width="18.2rem"
                height="1.5rem"
                borderRadius='50px'
                iconWidth='14px'
                fontSize='0.8rem'
                color="#1B626A"
                background="#CDDDDF"
                icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                onClick={() => {showArticle(8726300)}}
                text="Como funciona a análise de clientes?"
            />
            {/* <Banner
                title="Segmentação de clientes"
                subtitle={['Monitoramento em Tempo Real da Sua Base de Clientes:', 'Uma Visão Detalhada de Clientes Fiéis, Novatos, em Risco e Perdidos']}
                textButton="Como funciona?"
                iconButton={<PlayCircleOutlineIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />}
                onClick={() => {showArticle(8726300)}}
            /> */}

            <S.CardsContainer>
                <CardClients
                    title="BASE CLIENTES"
                    type="clients"
                    content={data?.data?.total_base ?? 0}
                    isLoading={isLoading}
                    icon={
                        <GroupIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#b1ccc1',
                            }}
                        />
                    }
                />

                <CardClients
                    title="BASE RECOMPRA"
                    type="recompra"
                    content={data?.data?.recurrent_base ?? 0}
                    isLoading={isLoading}
                    icon={
                        <GroupIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#b1ccc1',
                            }}
                        />
                    }
                />

                <CardClients
                    title="TEMPO MËDIO ENTRE COMPRAS"
                    type="tempo"
                    content={data?.data?.interval_between_orders ?? 0}
                    isLoading={isLoading}
                    icon={
                        <GroupIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#b1ccc1',
                            }}
                        />
                    }
                />

                <CardClients
                    title="LTV"
                    type="ltv"
                    content={data?.data?.lifetime_value ?? 0}
                    isLoading={isLoading}
                    titleInformation="(?)"
                    information="LTV mede o lucro gerado por um cliente durante seu relacionamento com a empresa."
                    icon={
                        <GroupIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#b1ccc1',
                            }}
                        />
                    }
                />
            </S.CardsContainer>

            <TableClients isLoading={isLoading} setDataPopup={setDataPopup} setShowPopup={setShowPopup} data={data ?? ({} as clientsInformationDTO)} />
        </S.Container>
    );
}

function ModalLineClients({ setShowPopup, dataPopup }: ModalLineClientsProps) {

    const { currencySymbol } = useCurrencyType();

    const name = dataPopup?.type;

    const imageUrlClients = 'https://i.ibb.co/S5xpykL/trophy-3113025.png';
    const imageUrlWarning = 'https://i.ibb.co/Vw3Vsv6/warning-4201973.png';

    const subtitles: { [key: string]: string } = {
        Fiéis: 'A segmentação de leais inclui todos os clientes que compraram recentemente e que possuem um volume de compras acima da média no seu ciclo de vida.',
        Novatos: 'A segmentação de novatos inclui todos os clientes que compraram recentemente uma ou poucas vezes.',
        'Em risco': 'A segmentação de clientes em risco inclui todos os clientes que já não compram há um tempo significativo e podem estar em risco de não se lembrarem mais da sua marca.',
        Perdidos: 'A segmentação de clientes perdidos inclui todos os clientes que consideramos que deixaram de comprar na sua loja e que possuem uma baixa taxa de lembrança da sua marca.',
    };

    const isLostClients = name === 'Perdidos';

    return (
        <Modal
            onClose={() => {
                setShowPopup(false);
            }}
        >
            <S.ContainerPopup>
                <S.ImagePopup type={name}>
                    <img src={`${isLostClients ? imageUrlWarning : imageUrlClients}`} />
                </S.ImagePopup>
                <S.TitlePopup>{name}</S.TitlePopup>
                <S.SubtitlePopup>{subtitles[name]}</S.SubtitlePopup>
                <S.InformartionContainerPopup>
                    <S.InformationContainerTitlePopup>
                        Dados gerais <S.Line />
                    </S.InformationContainerTitlePopup>
                    <S.InformationInnerContainerPopup>
                        <S.InformationSectionPopup>
                            <S.InformationOptionPopup>Recência média:</S.InformationOptionPopup>
                            <S.InformationValuePopup>{dataPopup?.avg_recency === 0 ? 0 : Number(dataPopup?.avg_recency.toFixed(0))}</S.InformationValuePopup>
                        </S.InformationSectionPopup>

                        <S.InformationSectionPopup>
                            <S.InformationOptionPopup>Frequência média:</S.InformationOptionPopup>
                            <S.InformationValuePopup>{dataPopup?.avg_frequency === 0 ? 0 : Number(dataPopup?.avg_frequency.toFixed(0))}</S.InformationValuePopup>
                        </S.InformationSectionPopup>

                        <S.InformationSectionPopup>
                            <S.InformationOptionPopup>Valor monetário médio:</S.InformationOptionPopup>
                            <S.InformationValuePopup>
                                {currencySymbol} {' '}
                                {dataPopup?.avg_monetary === 0
                                    ? '0,00'
                                    : dataPopup?.avg_monetary.toLocaleString('pt-BR', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                      })}
                            </S.InformationValuePopup>
                        </S.InformationSectionPopup>
                    </S.InformationInnerContainerPopup>
                </S.InformartionContainerPopup>
            </S.ContainerPopup>
        </Modal>
    );
}
