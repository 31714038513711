import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem;
`;

export const InnerContainer = styled.div`
    margin-top: 2rem;
    border: 1px solid var(--gray-120);
    border-radius: 10px;
    width: 100%;
    padding-top: 1rem;
    max-height: 16rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }

    input {
        height: 2.5rem;
        width: 30.7rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);
        &:focus,
        &:hover {
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4);
            background-color: #f9f9f9;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: var(--green); */
            border-bottom: 1px solid var(--green);
        }
    }
`;

export const VisibilityButtonDomain = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 2.8rem;
    right: 2rem;
    cursor: pointer;
`;

export const ErrorContainer = styled.div`
    width: 33.375rem;
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
    margin: 0 1rem 1rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0;
    span {
        font-weight: 550;
    }
`;

export const VisibilityButtonToken = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 7.7rem;
    right: 2rem;
    cursor: pointer;
`;
export const InfoContainer = styled.div`
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-left: 0.8rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--gray-700);
`;

export const DisconnectContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;

type ContainerYeverGatewayProps = {
    showPopupYeverGateway: boolean
}


export const ContainerYeverGateway = styled.div<ContainerYeverGatewayProps>`
    position: absolute;
    display: flex;
    bottom: 100%;

    ${(props) =>
        props.showPopupYeverGateway
            ? `
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;
    
    `
            : `
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    height: 5.75rem;
    width: 17.5rem;
    top: -6rem;
    right: -4.5rem;
    padding: 0;
    background-color: #0e1819;
    color: #0e1819;
    text-align: center;
    border-radius: 11px;
    z-index: 20;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    }
`;

export const YeverGatewayTitle = styled.div`
    font-size: 0.7125rem;
    color: var(--white);
    margin: 0 auto;
    font-weight: 550;
    margin-top: 0.3rem;
`;

export const YeverGatewaySubtitle = styled.div`
    font-size: 0.6rem;
    font-weight: 550;
    color: #989c97;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;

export const ButtonYeverGateway = styled.button`
    padding: 0;
    font-size: 0.7rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin-top: 0.75rem;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 0 auto;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }
`;
