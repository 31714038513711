import { submitCompanyNicho, getCompanyNicho } from '../api/api-nicho';
import { useQuery, useMutation } from 'react-query';

export function useSubmitCompanyNicho() {
    // console.log('useSubmitCompanyCurrency');
    return useMutation(submitCompanyNicho);
}

export function useGetCompanyNicho(company_id: string, enabled: boolean) {
    return useQuery(['getCompanyNicho', company_id], () => getCompanyNicho(company_id), {
        enabled, // Passando o parâmetro enabled aqui
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
