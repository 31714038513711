import styled from 'styled-components';

export const Container = styled.div`
    border: 2px solid var(--gray-180);
    border-radius: 10px;
    margin-top: 2rem;
    height: calc(100vh - 20rem);
    overflow-x: auto;
    position: relative;
`;

export const TableHeader = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    color: var(--black);
    display: flex;
    height: 3rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    min-width: 105rem;
    position: sticky;
    z-index: 8;
    top: 0;
    background-color: var(--white);
`;

export const OrderId = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    width: 23.625rem;
    min-width: 8.825rem;
    border-right: 1px solid var(--gray-180);
    position: sticky;
    left: 0;
    background-color: var(--white);
    z-index: 999;
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 11.825rem;
        min-width: 8.825rem;

    z-index: 5;
`;

export const Date = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 1rem;
    width: 11.825rem;
    min-width: 8.825rem;

    z-index: 5;
`;

export const Payment = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 11.825rem;
        min-width: 8.825rem;

    z-index: 5;
`;

export const Source = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 15rem;
    
    z-index: 5;
`;

export const Value = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;    margin-left: 1rem;
    width: 11.825rem;
    min-width: 8.825rem;
    z-index: 5;
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;  
    margin-left: 1rem;
    width: 11.825rem;
    // min-width: 8.825rem;

    z-index: 5;
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 8.825rem;
        min-width: 8.825rem;

    z-index: 5;
`;

export const Lines = styled.div`
    position: relative;
    min-width: 75rem;
    max-height: 30rem;

    > div:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    > div:nth-child(even) {
        background-color: var(--white) !important;
    }

    > div:nth-child(odd) {
        background-color: var(--gray-110) !important;
    }
`;

type ArrowIconProps = {
    isActive?: boolean;
    isDesc?: boolean;
};

export const ArrowIcon = styled.div<ArrowIconProps>`
    svg:hover path {
        fill: var(--green);
    }
    svg {
        ${(props) =>
            props.isActive
                ? `
  
    fill: var(--green);
    ${props.isDesc ? '  transform: scaleY(-1);' : 'transform: scaleY(1);'}
    `
                : `
    transform: scaleY(1);
    `}

        margin-top: 0.2rem;
        cursor: pointer;
        height: 0.8125rem;
        width: auto;
        color: red;
        margin-right: 1rem;
    }
`;
