import { TemplateModal } from '../../';
import useAuth from '../../../../../hooks/useAuth';
import { CardAccount } from './CardAccount';
import * as S from './styles';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { useEffect, useState } from 'react';
import Modal from '../../../../../components/Modal';
import { useGetUrlFacebokAdsAuthRevalidate, useRemoveFacebookAdsAccount, useRemoveFacebookAdsProfile, useSyncFacebookAdsAccounts } from '../../../../../services/hooks/integrationHooks';
import { ModalFacebookUrl } from './ModalFacebookUrl';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';
import { userDTO } from '../../../../../@types';
import { Tooltip } from '@mui/material';
import { Search } from '../../../../../components/Search';
import { TablePlaceholder } from '../../../../../components/TablePlaceholder';

export function ModalFacebook({}: {setShowPopupFacebook: (value: boolean) => void}) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
    const { user, setUserContext, currentCompany, updateUser, token } = useAuth();
    const { mutate: handleSyncFacebookAccount, isLoading} = useSyncFacebookAdsAccounts();
    const baseColor = '#1977f3';

    const facebookInfo: any[] = user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.filter((profile) => profile.profile_id !== 'default-profile');

    const { showAlert } = useAlert();

    const [filter, setFilter] = useState('');

    const hasFacebookInfo = facebookInfo?.length > 0;

    const [showModalFacebookUrl, setShowModalFacebookUrl] = useState(false);

    const [showConfirmDisconnectPopupProfile, setShowConfirmDisconnectPopupProfile] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState('');
    const [selectedProfileId, setSelectedProfileId] = useState('');
    const [showConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupAccount] = useState(false);
    const [facebookStatusInactive, setFacebookStatusInactive] = useState({ inactive: false, account_id: '' });

    const showCardAccount = location.pathname.includes('integracao/facebook') ? true : false;

    const { data: urlFacebookData, isLoading: isLoadingGetUrlFacebook } = useGetUrlFacebokAdsAuthRevalidate(user?.user_id, token?.session_token, currentCompany, showModalFacebookUrl);
    const {mutate: handleRemoveFacebookAccount, isLoading: isLoadingRemoveFacebookAccount} = useRemoveFacebookAdsAccount();
    const {mutate: handleRemoveFacebookProfile, isLoading: isLoadingRemoveFacebookProfile} = useRemoveFacebookAdsProfile();

    function handleGoToIntegration() {
        setShowModalFacebookUrl(true)
    }

    function syncFacebook(){
        handleSyncFacebookAccount(currentCompany,{
            onSuccess: ({data}) => {
                const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, facebook_ads: {profiles: data?.data?.profiles}} } } }  
                setUserContext(newUser)
                updateUser()
            }
        });
    }

    useEffect(() => {
        if(location.pathname === '/integracao/facebook'){
            handleSyncFacebookAccount(currentCompany,{
                onSuccess: ({data}) => {
                    const newUser = {...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies[currentCompany], integrations: { ...user?.companies[currentCompany]?.integrations, facebook_ads: {profiles: data?.data?.profiles}} } } }  
                    setUserContext(newUser)
                    updateUser()
                }
            });
        }

    },[location.pathname])
    

    function handleDesintegrateProfile(){
        
        const body = {
            company_id: currentCompany,
            user: user,
            profile_id: selectedProfileId,
            truncate: true,
        }

        showAlert('Desconectando perfil... Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setShowConfirmDisconnectPopupProfile(false)
        
        handleRemoveFacebookProfile(body, {
            onSuccess: ({data}) => {
                syncFacebook();
            }
        })
    }
    

    function handleDesintegrateAccount(){
        const body = {
            company_id: currentCompany,
            profile_id: selectedProfileId,
            account_id: selectedAccountId,
            user: user
        }

        setFacebookStatusInactive({inactive: true, account_id: selectedAccountId})

        showAlert('Conta desconectada! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setShowConfirmDisconnectPopupAccount(false)

        handleRemoveFacebookAccount(body, {
            onSuccess: ({data}) => {
            }
        })
    }


    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Facebook'}
                title={'Facebook Ads'}
                subtitle={'Promova seu dropshipping através de anúncios criativos na plataforma Facebook Ads.'}
                statusConnection={facebookInfo?.some((info) => info.status === 'active') ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
                {(hasFacebookInfo || showCardAccount) && <>
                    <S.SyncArea>
                    <div style={{marginLeft: '-0.5rem'}}>
                        <Search placeholder="Pesquisar contas de anúncios." width="14rem" height='2.5rem' value={filter} setValue={setFilter} />
                    </div>
                    <Tooltip 
                    title="Atualizar novas contas de anúncios do perfil."
                    placement="top"
                    componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                        <S.SyncContainer onClick={syncFacebook} disabled={isLoading}>
                            <S.SyncIcon>
                                <SyncIcon />
                            </S.SyncIcon>
                            <S.SyncText>
                                Sincronizar Contas
                            </S.SyncText>
                        </S.SyncContainer>
                    </Tooltip>
                    </S.SyncArea>
                    {hasFacebookInfo && facebookInfo?.map((info) => {
                        // Filtrar contas de anúncio por nome ou ID
                        const filteredAccounts = info.accounts?.filter(account =>
                            account?.assets?.account_name.toLowerCase().includes(filter.toLowerCase()) ||
                            account?.account_id.includes(filter)
                        );

                        if (filteredAccounts.length > 0) {
                            return (
                                <CardAccount 
                                    facebookStatusInactive={facebookStatusInactive} 
                                    setFacebookStatusInactive={setFacebookStatusInactive} 
                                    setSelectedProfileId={setSelectedProfileId} 
                                    setSelectedAccountId={setSelectedAccountId} 
                                    setShowConfirmDisconnectPopupAccount={setShowConfirmDisconnectPopupAccount}  
                                    setShowConfirmDisconnectPopupProfile={setShowConfirmDisconnectPopupProfile} 
                                    facebookInfo={{ ...info, accounts: filteredAccounts }} // Pass filtered accounts to CardAccount
                                    isLoading={isLoading} 
                                />
                            );
                        } else {
                            return (
                            <S.PlaceholderArea>
                                <TablePlaceholder size='small' showSubtitle={true} subtitleText='Nenhuma conta de anúncios encontrada.' />
                            </S.PlaceholderArea>
                            ); // Não renderizar CardAccount se não houver contas correspondentes
                        }
                    })}
                </>}
                  
            </S.InnerContainer>
            <Tooltip 
                    title="Adicionar um novo perfil do Facebook Ads."
                    placement="top"
                    componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '13px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white'}}}}>
                    <S.ButtonContainer>
                        <ButtonApp
                            border=''
                            width={!hasFacebookInfo ? '13rem' : '14rem'}
                            height="2rem"
                            color="#fff"
                            background="#1977F2"
                            icon={<img src="https://solomon.com.br/wp-content/uploads/2023/10/Facebook_Logo_Secondary.png" />}
                            onClick={handleGoToIntegration}
                            isLoading={isLoadingGetUrlFacebook}
                            text={!hasFacebookInfo ? 'Continuar com o Facebook' : 'Adicionar perfil do Facebook'}
                        />
                    </S.ButtonContainer>
                    </Tooltip>
                {!isLoadingGetUrlFacebook && showModalFacebookUrl && <Modal onClose={() => setShowModalFacebookUrl(false)}>
                    <ModalFacebookUrl setShowPopupFacebook={setShowModalFacebookUrl} url={urlFacebookData?.data}/>
                </Modal>}

                {showConfirmDisconnectPopupAccount && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupAccount}
                        onClose={() => setShowConfirmDisconnectPopupAccount(false)}
                        onConfirm={handleDesintegrateAccount}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveFacebookAccount}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por esta conta na Solomon. Tem certeza que deseja continuar?
                              <br />
                              <br />
                              <strong>(Esta conta permanecerá na lista de contas.)</strong>
                            </>
                          }
                          
                    />
                )}
                {showConfirmDisconnectPopupProfile && (
                    <ConfirmDisconnectModal
                        isOpen={showConfirmDisconnectPopupProfile}
                        onClose={() => setShowConfirmDisconnectPopupProfile(false)}
                        onConfirm={handleDesintegrateProfile}
                        title="Confirmação necessária"
                        isLoading={isLoadingRemoveFacebookProfile}
                        subtitle={
                            <>
                              Esta ação irá excluir permanentemente todos os dados coletados anteriormente por este perfil na Solomon. Tem certeza que deseja continuar?
                            </>
                          }
                    />
                )}
        </S.Container>
    );
}



function SyncIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z"/>
    </svg>
    )
}