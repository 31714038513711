import { useState } from 'react';
import * as S from './style';
import { InputPermission } from '../InputPermission';
import Checkbox from '@mui/material/Checkbox';
import VerifiedIcon from '@mui/icons-material/Verified';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAuth from '../../../hooks/useAuth';
import { useCreateInvite } from '../../../services/hooks/accessHooks';
import { queryClient } from '../../../services/queryClient';    
import useVisibility from '../../../hooks/useVisibilityChange';
import { ButtonApp } from '../../../components/ButtonApp';
import { TablePlaceholder } from '../../../components/TablePlaceholder';
import useAlert from '../../../hooks/useAlert';

export function ModalNewMember({refetchDataAccessControl, groups, setShowModalNewMember }: { refetchDataAccessControl: () => void, groups: any, setShowModalNewMember: (value: boolean) => void}) {
    const [email, setEmail] = useState('');
    const [groupSelected, setGroupSelected] = useState('');
    const { currentCompany } = useAuth();
    const [invite, setInvite] = useState('');
    const [showInvite, setShowInvite] = useState(false);
    const [memberAlreadyExists, setMemberAlreadyExists] = useState(false);
    const { isVisible } = useVisibility();
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const { showAlert } = useAlert();

    const { mutate: handleCreateInvite, isLoading } = useCreateInvite();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(invite);
        setShowCopiedPopup(true);
    };


    function handleChangeEmail(e: any) {
        setEmail(e.target.value);
    }

    function handleAddMemberToCompany() {

        const sanitizedEmail = email.replace(/\s/g, '');

        // Verifica se o email segue os padrões comuns
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(sanitizedEmail)) {
            showAlert('O email inserido não é válido, tente novamente.', 'error', 5000);
            return; // Para a execução se o email não for válido
        }

        const body = {
            company_id: currentCompany,
            email: sanitizedEmail,
            group_id: groupSelected
        }
        handleCreateInvite(body,{
            onSuccess: (data) => {
                queryClient.invalidateQueries('accessGroups');
                if(data.data.url === undefined){
                    setMemberAlreadyExists(true)
                    refetchDataAccessControl();
                }else{
                    setInvite(data?.data?.url)
                    setShowInvite(true)
                }
            },
            onError: (error) => {
                console.error(error)
                setShowModalNewMember(false)
            }
        });
    }

    if (memberAlreadyExists) {
        return (
            <S.ContainerMemberAlreadyExists>
                <S.VerifiedIcon>
                    <VerifiedIcon />
                </S.VerifiedIcon>
                <S.VerifiedTitle>
                    Membro <span>adicionado</span>!
                </S.VerifiedTitle>
                <S.VerifiedText>Este membro agora tem permissão para acessar sua loja.</S.VerifiedText>
            </S.ContainerMemberAlreadyExists>
        );
    }

    if (showInvite) {
        return (
            <S.ContainerCreateInvite>
                <S.VerifiedIcon>
                    <VerifiedIcon />
                </S.VerifiedIcon>

                <S.VerifiedTitle>
                    Convite <span>criado</span>!
                </S.VerifiedTitle>

                <S.VerifiedText>
                    Compartilhe este <span>link de convite</span> com o membro da sua equipe para que ele possa se registrar na plataforma.
                </S.VerifiedText>

                <S.VerifiedText>Ao utilizar este link, o membro terá acesso às áreas e funcionalidades específicas configuradas no grupo de acesso selecionado.</S.VerifiedText>

                <S.SectionInput>
                    <input value={invite} />
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ width: '16px', height: '16px' }} />
                    {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}

                </S.SectionInput>
            </S.ContainerCreateInvite>
        );
    }

    return (
        <S.Container>
            <GroupAddIcon />
            <S.Title>
                Convide um <span>novo membro</span>
            </S.Title>
            <S.Subtitle>Convide membros de sua equipe para administrar sua loja.</S.Subtitle>
            <S.Line />
            <InputPermission inputId="" inputName="Email:" placeholder="solomonparceiro@email.com" value={email} setValue={handleChangeEmail} />
            <S.Line />
            <S.Text>Grupos de acessos</S.Text>
            <S.Subtext>Escolha um grupo para definir quais áreas e funções este membro poderá acessar.</S.Subtext>
            <S.OptionSection>
                
            {groups?.length < 0 && (
                <S.InnerContainer>
                    <TablePlaceholder size='small' subtitleText='Nenhum grupo de acesso foi criado.' />
                </S.InnerContainer>
            )}  
            
            {groups?.map((group: any) => {
                return (
                    <S.Option key={group?.group_id}>
                        <S.Checkbox>
                            <Checkbox
                                size="small"
                                checked={groupSelected === group?.group_id}
                                onClick={() => setGroupSelected(group?.group_id)}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#19c273',
                                    },
                                }}
                            />
                        </S.Checkbox>
                        {isVisible ? group?.name : '* * * * * * *'}
                    </S.Option>
                    
                );
            })}
            </S.OptionSection>
            <S.ButtonSection>
                <ButtonApp
                border=''
                width="13rem"
                height="2.5rem"
                color="#fff"
                background="#19c380"
                text='Convidar novo membro' 
                icon={<FillGroupAddIcon />} 
                isLoading={isLoading} onClick={handleAddMemberToCompany} 
                disabled={email === '' || groupSelected === ''}
             />
             
            </S.ButtonSection>
        </S.Container>
    );
}

function GroupAddIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Zm320-400q33 0 56.5-23.5T400-640q0-33-23.5-56.5T320-720q-33 0-56.5 23.5T240-640q0 33 23.5 56.5T320-560ZM80-240h480v-32q0-11-5.5-20T540-306q-54-27-109-40.5T320-360q-56 0-111 13.5T100-306q-9 5-14.5 14T80-272v32Zm240-400Zm0 400Z" />
        </svg>
    );
}

function FillGroupAddIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Z" />
        </svg>
    );
}
