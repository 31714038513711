import React, { useState, useRef, useEffect } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as S from './style';
import { ProductSelectorProps } from '../../../@types';

function ProductSelector({ products, selectedProducts, tempSelectedProducts, update, setProductIds, setSelectedProducts, setTempSelectedProducts, setUpdate }: ProductSelectorProps) {
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    // Inicializar o estado dos produtos selecionados para todos os produtos
    useEffect(() => {
        if (update === true) {
            const initialSelection = products.reduce((acc, product) => {
                acc[product.product_name] = true;
                return acc;
            }, {});
            setSelectedProducts(initialSelection);
            setTempSelectedProducts(initialSelection);
            setProductIds(products.map(product => product.product_id));
            setUpdate(false);
        }
    }, [ ]);

    function handleSelect(productName) {
        setTempSelectedProducts(prev => ({ ...prev, [productName]: !prev[productName] }));
    }

    function handleSelectAll() {
        const allSelected = Object.values(tempSelectedProducts).every(Boolean);
        const newSelection = products.reduce((acc, product) => {
            acc[product.product_name] = !allSelected;
            return acc;
        }, {});
        setTempSelectedProducts(newSelection);
    }

    function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    }

    useEffect(() => {
        // //console.log('13');
        if (!showDropdown) {
            setSelectedProducts(tempSelectedProducts);

            const selectedProductIds = products
                .filter(product => tempSelectedProducts[product.product_name])
                .map(product => product.product_id);
            setProductIds(selectedProductIds); // Atualizar o estado externo apenas quando o dropdown for fechado
        }
    }, [showDropdown, tempSelectedProducts ]);

    const countSelectedProducts = () => {
        return Object.values(tempSelectedProducts).filter(Boolean).length;
    }

    const productSummary = () => {
        const count = countSelectedProducts();
        if (count < products.length) {
            return `${count}`;
        } else {
            return 'Todos';
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tempSelectedProducts]);

    const allSelected = Object.values(tempSelectedProducts).every(Boolean);

    return (
        <div ref={ref}>
            <S.FilterPaymentArea onClick={() => setShowDropdown(!showDropdown)}>
                <S.FlexSelect>
                    <span>Produtos</span>
                </S.FlexSelect>
                <S.MethodSelected>{productSummary()}</S.MethodSelected>
                <ExpandMoreRoundedIcon style={{ transform: showDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>
            </S.FilterPaymentArea>
            <S.Dropdown show={showDropdown}>
                <S.DropdownItem onClick={handleSelectAll}>
                    {allSelected ? <CheckBoxIcon style={{color: '#19c27e'}} /> : <CheckBoxOutlineBlankIcon  style={{color: '#999'}} />}
                    <span style={{ marginLeft: '10px' }}>Selecionar todos</span>
                </S.DropdownItem>
                {products.map(product => (
                    <S.DropdownItem key={product.product_id} onClick={() => handleSelect(product.product_name)}>
                        {tempSelectedProducts[product.product_name] ? <CheckBoxIcon style={{color: '#19c27e'}} /> : <CheckBoxOutlineBlankIcon  style={{color: '#999'}} />}
                        <span style={{ marginLeft: '10px' }}>{product.product_name}</span>
                    </S.DropdownItem>
                ))}
            </S.Dropdown>
        </div>
    );
}

export default React.memo(ProductSelector);
