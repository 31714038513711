import * as S from './style';
import { SelectHeaderProps } from '../../../../../@types';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export function Notification({ showSelect, setShowSelect }: SelectHeaderProps) {
    const [showFullContent, setShowFullContent] = useState(false);

    return (
        <S.NotificationContainer showSelect={showSelect} onClick={(e) => e.stopPropagation()}>
            <S.HeaderNotifications>
                Notificações
                <S.CloseButton onClick={() => setShowSelect(false)}><CloseIcon sx={{ width: '18px', height: '18px' }}/></S.CloseButton>
            </S.HeaderNotifications>

            <S.NotificationsHr />

            <S.NotificationContent onMouseOver={() => setShowFullContent(true)} onMouseLeave={() => setShowFullContent(false)} >
                <S.NotificationIcon><NotificationsNoneIcon sx={{ width: '18px', height: '18px' }}/></S.NotificationIcon>
                <S.FlexContainer>
                    <S.Title>Bem-vindo(a) à Solomon! 🌟</S.Title>
                    {showFullContent && (
                        <S.Text>
                            Olá!
                            <br />
                            <br />
                            É um prazer recebê-lo(a) em nossa plataforma. Aqui na Solomon, você encontrará ferramentas intuitivas e análises avançadas para impulsionar seus negócios.
                            <br /><br />
                            Confira nossos tutoriais para começar e, se precisar de ajuda, nosso suporte está sempre à disposição.
                            <br /><br />
                            Obrigado por escolher a Solomon. Desejamos muito sucesso para você!
                            <br /><br />
                            Atenciosamente,
                            <br />
                            <S.Strong>A Equipe Solomon</S.Strong>
                        </S.Text>
                    )}
                </S.FlexContainer>
            </S.NotificationContent>
        </S.NotificationContainer>
    );
}
