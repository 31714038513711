import {  useQuery } from 'react-query';
import * as API from '../api/api-ip';

export function useGetIpAddress(){
    return useQuery('getIpAddress', () => API.getIpAddress(),{
        refetchOnWindowFocus: false,
    })
}

export function useGetAddressInfo(zipcode: string) {
    return useQuery('getAddressInfo', () => API.getAddressInfo(zipcode), {
        refetchOnWindowFocus: false,
        enabled: !!zipcode,
        retry: false,
    })
}