import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f8f8f8;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
        border-bottom: none;
    }
`;

export const AccountId = styled.div``;

export const AccountIdText = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #95a496;
`;

export const AccountIdValue = styled.div`
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 0.975rem;
    color: #6b6b6b;
`;

export const AccountName = styled.div``;

export const AccountNameText = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #95a496;
`;

export const AccountNameValue = styled.div`
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 0.975rem;
    color: #6b6b6b;
`;

type StatusProps = {
    isActive: boolean;
};

export const Status = styled.div<StatusProps>`
    height: 1.375rem;
    border-radius: 50px;

    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.isActive
            ? `
width: 4.9rem;
background-color: var(--green);
`
            : `
width: 4.9rem;
background-color: #dc143c;

`}
`;
export const StatusText = styled.div``;

export const StatusValue = styled.div``;

export const Switch = styled.div``;

export const SwitchValue = styled.div``;
