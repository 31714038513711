import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as S from './style';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import useAuth from '../../../hooks/useAuth';
import  CpfCnpj  from './CpfCnpj';

export function InputPayment({ onFocus, inputId, inputName, inputType, required = false, width, inputLabel, setInputInformation, inputInformation }) {
    const [showText, setShowText] = useState(false);
    const [expirationMonth, setExpirationMonth] = useState('');
    const [expirationYear, setExpirationYear] = useState('');

    function getCardLogo(brand) {
        const logos = {
            visa: { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/1200px-Visa_Inc._logo.svg.png', width: '30px', height: '10px' },
            mastercard: { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png', width: '20px', height: '13px' },
            amex: { src: 'https://cdn-icons-png.flaticon.com/512/179/179431.png', width: '25px', height: '24px' },
            discover: { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Discover_Card_logo.svg/1200px-Discover_Card_logo.svg.png', width: '40px', height: '12px' },
            diners_club: { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC8VkGhclM58I3f5zxRf69aOR4oY81nZj80helR7xI&s', width: '40px', height: '12px' },
            jcb: { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/640px-JCB_logo.svg.png', width: '20px', height: '15px' }
        };
    
        return logos[brand] || { src: '', width: '0px', height: '0px' };  // Retorna dimensões padrão se não identificado
    }

    function detectCardType(number) {
        const re = {
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$/,
            amex: /^3[47][0-9]{13}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}$/
        };
    
        for (let card in re) {
            if (re[card].test(number)) {
                return card;
            }
        }
    
        return 'unknown';
    }

    const [cardBrand, setCardBrand] = useState('');
    const { user } = useAuth();

    const handleInputChange = (e) => {
        let newValue = e.target.value ?? '';
        ////console.log('Input change detected:', newValue);
    
        // Defina a máscara padrão usada
        const defaultMask = '_____-___';
    
        // Remover os caracteres de máscara da entrada
        const cleanedValue = newValue.replace(/_/g, '').replace('-', '');
    
        // Verifique se o valor limpo não é uma string vazia
        if (cleanedValue !== '') {
            setInputInformation(newValue);
        } else {
            setInputInformation('');
        }
    
        if (inputName === 'cardNumber') {
            const cardNumberCleanedValue = newValue.replace(/\D/g, '');  // Remove non-digits
            const brand = detectCardType(cardNumberCleanedValue);
            setCardBrand(brand);
            ////console.log('Detected card brand:', brand);
        }
    };
    

    useEffect(() => {
        if (inputType === 'expirationDate' && inputInformation) {
            const [month, year] = inputInformation.split('/');
            setExpirationMonth(month);
            setExpirationYear(year);
        }
    }, [inputInformation, inputType]);

    let InputComponent: any = 'input';
    let inputProps = {
        id: inputId,
        type: inputType === 'password' ? (showText ? 'text' : 'password') : 'text',
        name: inputName,
        value: inputInformation || '',
        autoComplete: "off",
        placeholder: " ",
        onChange: handleInputChange,
        onFocus: onFocus,
        required,
        style: { width },
        mask: '',
    };

    if (inputType === 'cardNumber') {
        InputComponent = InputMask;
        inputProps = { ...inputProps, mask: '9999 9999 9999 9999' };
    } else if (inputType === 'cpfCnpj') {
        InputComponent = CpfCnpj;
        inputProps = { ...inputProps};

    } else if (inputType === 'expirationDate') {
        InputComponent = InputMask;
        inputProps = { ...inputProps, mask: '99/99' };
    } else if (inputType === 'cvv') {
        InputComponent = InputMask;
        inputProps = { ...inputProps, mask: '999' };
    }  else if (inputType === 'postalCode') {
        InputComponent = InputMask;
        inputProps = { ...inputProps, mask: '99999-999' };
    }

    return (
        <S.Container width={width}>
            <InputComponent {...inputProps} />
            <label htmlFor={inputId}>{inputLabel}</label>
            {inputType === 'password' && (
                <button type="button" onClick={() => setShowText(!showText)}>
                    {showText ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </button>
            )}
            {inputType === 'cardNumber' && cardBrand !== 'unknown' && (
                <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                   {cardBrand ? (
                       <img 
                           src={getCardLogo(cardBrand).src} 
                           alt={`${cardBrand} logo`} 
                           style={{ width: getCardLogo(cardBrand).width, height: getCardLogo(cardBrand).height }}
                       />
                   ) : null}
                </div>
            )}
        </S.Container>
    );
}
