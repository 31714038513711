import React, { useState, useEffect } from 'react';
import { FormsSignUpProps, StorageAuthTokenProps, userCreateDTO, userDTO } from '../../../@types';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputLogin } from '../../../components/InputLogin';
import * as S from './style';
import { useCreateUser } from '../../../services/hooks/authHooks';
import { useGetIpAddress } from '../../../services/hooks/ipHooks'
import { signUpSchema } from '../../../schemas/authSchema';
import { useNavigate } from 'react-router-dom';
import { storageAuthTokenSave } from '../../../storage/storageAuthToken';
import { storageUserSave } from '../../../storage/storageUser';
import { storageCurrentUserSave } from '../../../storage/storageCurrentUser';
import useAuth from '../../../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { Loading } from '../../../components/Loading';
import { storageShExternalIdGet } from '../../../storage/storageCompanyToOnboard';
import {PlanCardSignUp} from './PlanCardSignUp';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import Radio from '@mui/material/Radio';
import { isIP } from 'net';
import { getPlan } from '../../../services/api/api-subscription';
import useAlert from '../../../hooks/useAlert';
import { analyticsWeb } from '../../../services/segment/sendEvent';
import { initFacebookPixel, fbq } from '../../../services/facebook-pixel/facebook-pixel';

function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function getCookieByDomain(cookieName, domain) {
    const cookies = document.cookie.split(';');
    
    for (let cookie of cookies) {
        cookie = cookie.trim();
        
        if (cookie.startsWith(cookieName + '=')) {
            document.cookie = `${cookieName}=${cookie.split('=')[1]}; domain=${domain}; path=/`;
            if (document.cookie.includes(cookieName)) {
                return cookie.split('=')[1];
            }
        }
    }
    return null;
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=.solomon.com.br";
}
  
export function FormsSignUpV2({ isMobile, showSignUp, setShowLogin, setShowBackgroundImage, guest, invite, token }: FormsSignUpProps) {
    //@ts-ignore
    const email = token !== '' ? jwtDecode(token).email : '';
    const { setUserContext, setTokenContext, setCurrentCompanyContext, user } = useAuth();
    const { isLoading: isIpLoading, data: ipData } = useGetIpAddress();
    const userName = user.name;
    const { showAlert } = useAlert();
    const [ pageviewSent, setPageviewSent ] = useState(false);

    const [userForSignUp, setUserForSignUp] = useState({
        name: userName,
        email: email,
        phone: '',
        password: '',
        is_guest: guest !== '' ? true : false,
        invite_token: token,
        invite_id: invite,
        guest_token: guest,
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
        fbp: '',
        fbc: ''
    } as userCreateDTO);



    useEffect(() => {
        let fbp = getCookieByDomain("_fbp", ".solomon.com.br");
        if (!fbp) {
            fbp = `fb.2.${new Date().getTime()}.${Math.floor(1e8 + 9e8 * Math.random())}`
            setCookie("_fbp", fbp, 365)
        }

        let fbc = getCookieByDomain("_fbc", ".solomon.com.br");
        const fbclid = new URLSearchParams(window.location.search).get('fbclid');
        if (!fbc && fbclid) {
            fbc = `fb.2.${new Date().getTime()}.${fbclid};`
            setCookie('_fbc', fbc, 365);
        }        

        initFacebookPixel("776084714023352", fbp, fbc)
    }, [])

    useEffect(() => {
        if (isIpLoading) {
            return
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const ip = ipData.data.ip;
        localStorage.setItem('@solomon:_ip', ip);

        const handleViewContent = async () => {
            const ip = localStorage.getItem('@solomon:_ip');
   
            const test = window.location.hostname !== "analytics.solomon.com.br" || ip ===  "200.210.101.90"

            const eventCtx = await analyticsWeb.track('signup-pageview', {
                user_agent: navigator.userAgent,
                ip: ip,
                fbp: getCookieByDomain("_fbp", ".solomon.com.br") || urlParams.get('fbp'),
                fbc: getCookieByDomain("_fbc", ".solomon.com.br") || urlParams.get('fbc'),
                utm_source: getCookieByDomain("utm_source", ".solomon.com.br") || urlParams.get("utm_source"),
                utm_medium: getCookieByDomain("utm_medium", ".solomon.com.br") || urlParams.get("utm_medium"),
                utm_campaign: getCookieByDomain("utm_campaign", ".solomon.com.br") || urlParams.get("utm_campaign"),
                utm_content: getCookieByDomain("utm_content", ".solomon.com.br") || urlParams.get("utm_content"),
                email: getCookieByDomain("sl_email", ".solomon.com.br") || urlParams.get("email"),
                phone: getCookieByDomain("sl_phone", ".solomon.com.br") || urlParams.get("phone"),
                first_name: getCookieByDomain("sl_first_name", ".solomon.com.br") || urlParams.get("first_name"),
                last_name: getCookieByDomain("sl_last_name", ".solomon.com.br") || urlParams.get("last_name"),
                url: window.location.href,
                test: test
            });
            
            if (!test) {
                fbq('track', 'ViewContent', {}, {eventID: eventCtx.event.messageId});
            }
        }

        if (!pageviewSent && new URLSearchParams(window.location.search).get('signup') == '1' && guest === '') {
            handleViewContent();
            setPageviewSent(true);
        }
    }, [ipData])

    useEffect(() => {
        // Assumindo que você está usando window.location.search para obter a query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const utm_source = getCookieByDomain("utm_source", ".solomon.com.br") || urlParams.get("utm_source") || '';
        const utm_medium = getCookieByDomain("utm_medium", ".solomon.com.br") || urlParams.get("utm_medium") || '';
        const utm_campaign = getCookieByDomain("utm_campaign", ".solomon.com.br") || urlParams.get("utm_campaign") || '';
        const utm_term = urlParams.get('utm_term') || '';
        const utm_content = getCookieByDomain("utm_content", ".solomon.com.br") || urlParams.get("utm_content") || '';
        const fbp = getCookieByDomain("_fbp", ".solomon.com.br") || urlParams.get('fbp') || '';
        const fbc = getCookieByDomain("_fbc", ".solomon.com.br") || urlParams.get('fbc') || '';

        // Atualiza o estado com os valores das UTMs
        setUserForSignUp(prevState => ({
          ...prevState,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          fbp,
          fbc
        }));
      }, []); // O array vazio garante que o efeito seja executado apenas uma vez após o componente montar

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const navigate = useNavigate();
    const recaptchaKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;
   

    function saveAndUpdateUserAndToken(user: userDTO, { access_token, session_token }: StorageAuthTokenProps) {
        setUserContext(user);
        setTokenContext({ access_token, session_token });
        storageAuthTokenSave({ access_token, session_token });
        storageUserSave(user);
        setCurrentCompanyContext(Object.keys(user.companies)[0]);
        storageCurrentUserSave(Object.keys(user.companies)[0]);
    }

    const handleRecaptchaChange = (value: any) => {
        setRecaptchaValue(value);
    };

    const { mutate: handleCreateUser, isLoading } = useCreateUser();

    const handleSignupEvent = async (user: userDTO) => {
        const ip = localStorage.getItem('@solomon:_ip');

        const nameParts = user.name.trim().split(/\s+/);
        const first_name = nameParts[0]
        const last_name = nameParts.slice(1).join(' ');

        const test = window.location.hostname !== "analytics.solomon.com.br" || user.credentials.email.includes("teste") || user.credentials.email.includes("asaas") || ip ===  "200.210.101.90"

        const eventCtx = await analyticsWeb.track('complete-registration', {
            user_agent: navigator.userAgent,
            ip: ip,
            fbp: user.facebook?.fbp || getCookieByDomain('_fbp', '.solomon.com.br'),
            fbc: user.facebook?.fbc || getCookieByDomain('_fbc', '.solomon.com.br'),
            utm_source: user.utms.utm_source || getCookieByDomain('utm_source', '.solomon.com.br'),
            utm_medium: user.utms.utm_medium || getCookieByDomain('utm_medium', '.solomon.com.br'),
            utm_campaign: user.utms.utm_campaign || getCookieByDomain('utm_campaign', '.solomon.com.br'),
            utm_content: user.utms.utm_content || getCookieByDomain('utm_content', '.solomon.com.br'),
            userId: user.user_id,
            first_name: first_name,
            last_name: last_name,
            email: user.credentials.email,
            phone: `55${user.phone.replace(/\D/g, '')}`,
            url: window.location.href,
            test: test
        });
        
        if (!test) {
            fbq('init', '776084714023352', { 
                em: user.credentials.email, 
                fn: first_name, 
                ln: last_name, 
                ph: `55${user.phone.replace(/\D/g, '')}`, 
                external_id: user.user_id 
            })
            
            fbq('track', 'CompleteRegistration', {}, {eventID: eventCtx.event.messageId});
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
       
        const body = { ...userForSignUp};

        // Verificações mais robustas para o formato do email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const email = userForSignUp.email;
        if (!emailRegex.test(email)) {
            showAlert('Por favor, insira um e-mail válido.', 'error', 5000, true);
            return;
        }

        // Validando o telefone
        const phone = userForSignUp.phone;
        // Expressão regular para validar o formato (XX) XXXXX-XXXX
        if (!phone.match(/^\(\d{2}\) \d{5}-\d{4}$/)) {
            showAlert('Por favor, insira um número de telefone válido.', 'error', 5000, true);
            return;
        }

         // Validando a senha
        const password = userForSignUp.password;
        if (password.length < 6) {
            showAlert('Sua senha deve conter pelo menos 7 caracteres.', 'error', 5000, true);
            return;
        }

        //console.log('external_id', storageShExternalIdGet());

        const isInformationValid = signUpSchema.safeParse(body);

        if (!isInformationValid.success) {
            return;
        }

        if (!recaptchaValue) {
            showAlert('Por favor, complete o captcha e tente novamente.', 'error', 5000, true);
            return;
        }
        
        handleCreateUser(body, {
            onSuccess: ({ data }) => {
                const user = data.data.user;
                saveAndUpdateUserAndToken(user, {
                    access_token: data.data.data.access_token.access_token,
                    session_token: data.data.data.cache.session_token,
                });
                const sh_external_id = new URLSearchParams(window.location.search).get('sh_external_id');
                if(sh_external_id && sh_external_id !== ''){
                    navigate('/shopify/payment');
                }else if (Object.keys(user.companies ?? {}).length > 0) {
                    navigate('/lojas');
                }else{
                    navigate(`/payment?selected_plan=${localStorage.getItem(`selectedPlanSignUp`) || 'Plano 1'}`);
                }
                
                //console.log("invite", body.is_guest)
                if (!body.is_guest) {
                    handleSignupEvent(user);
                }
            },
            onError: (error: any) => {
                
                if (error?.message === 'Email already exists') {
                    showAlert('Este e-mail já está cadastrado.', 'error', 5000, true);
                }
                setShowBackgroundImage(true);
            },
        });
        
    };
    
    
    if (isLoading) {
        setShowBackgroundImage(false);
        return <Loading />;
    }

    // Quando entrar na pagina, verificar se o usuario que foi logado já está criado, se tiver jogar pra pagina de pagar (step 2)
    return (
        <S.Container showSignUp={showSignUp}>
        <S.SidesContainer>
            <S.RightColumn>
            <S.MainTitle>
                <img src="https://storage.googleapis.com/static-images-source/horizontal-logo.png" alt="" />
                <S.Title>Cadastre-se</S.Title>
                <S.SubtitleSection>
                    <S.Subtitle isMobile={isMobile}>Faça seu cadastro gratuitamente, não leva nem 1 minuto!</S.Subtitle>
                </S.SubtitleSection>
            </S.MainTitle>

             <form onSubmit={handleSubmit}>
                 <S.InputSection>
                     <InputLogin
                        inputInformation={userName ?? userForSignUp as userCreateDTO}
                        inputId="nameSignUp"
                        inputName="name"
                        inputLabel="Nome"
                        inputType="text"
                        width={isMobile ? '36vw' : '12.875rem'}
                        setInputInformation={setUserForSignUp}
                     />
                     <InputLogin
                        inputInformation={userForSignUp as userCreateDTO}
                        inputId="celularSignUp"
                        inputName="phone"
                        inputLabel="Celular"
                        inputType="whatsapp"
                        width={isMobile ? '36vw' : '12.875rem'}
                        setInputInformation={setUserForSignUp}
                    />
                 </S.InputSection>
                 <InputLogin
                    inputInformation={userForSignUp as userCreateDTO}
                    inputId="emailSignUp"
                    inputName="email"
                    inputLabel="E-mail"
                    inputType="text"
                    width={isMobile ? '80vw' : '27.8125rem'}
                    setInputInformation={setUserForSignUp}
                 />
                 <InputLogin
                    inputId="passwordSignUp"
                    inputName="password"
                    inputLabel="Senha"
                    inputType="password"
                    width={isMobile ? '80vw' : '27.8125rem'}
                    setInputInformation={setUserForSignUp}
                    inputInformation={userForSignUp as userCreateDTO}
                    signup={true}
                 />
                 <S.RecapchaSection>
                     <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                 </S.RecapchaSection>
                 <S.ButtonSection>
                     <S.Button type="submit">
                        Criar conta
                    </S.Button>
                 </S.ButtonSection>
             </form>
            <S.FinalText>
                Ao prosseguir você concorda com os <span>Termos de uso</span> e <span>Política de privacidade</span>
            </S.FinalText>
            <S.AlreadyHaveAccount>
                <S.FooterLine />
                <span onClick={() => setShowLogin(true)}>Já tenho uma conta</span>
                <S.FooterLine />
            </S.AlreadyHaveAccount>
            </S.RightColumn>
        </S.SidesContainer>
           
        </S.Container>
    );
}
