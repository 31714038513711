// import { FourthHeaderMenuItem } from './style';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
`;

export const Section = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-top: 3.5rem;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
`;

export const Table = styled.div`
padding-bottom: 3rem`;


export const InputCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    border-radius: 5px;
    background-color: var(--gray-112);
    padding: 1rem 1rem;
    font-size: 0.6875rem;
    font-weight: 700;
    line-height: 1rem;

    p {
        color: var(--black);
        font-size: 0.85rem;
        font-weight: 600;}
`;


export const OptionsSection = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 1rem;

`

export const OptionLeft = styled.div`
// border: 1px solid #ccc;
border-radius: 7px;
`
export const OptionRight = styled.div`

`

export const HeaderMenu = styled.div`
 display: flex;
    // width: 67.7%;
    justify-content: start;
    height: 25px;
    gap: 50px;
    margin-top: 3rem;
    border-bottom: 1px solid #ccc;
    align-items: center;
`
type HeaderMenuItemProps ={
    active: boolean
}

export const HeaderMenuItem = styled.div<HeaderMenuItemProps>`
    // position: absolute;
    font-family: 1.1rem;
    font-weight: 550;
    color: var(--gray-100);
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    // left: 50%; /* Centraliza horizontalmente */
    // transform: translateX(-50%); /* Ajusta para centralizar corretamente */
    &:hover {
        color: var(--green);
        transition: ease-in-out 0.2s;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--green);
        margin-left: 0%;
        transition: width 0.3s ease-in-out;
    }
    ${(props) =>
        props.active &&
        `
        color: var(--green);
        transition: ease-in-out 0.2s;
        &::after {
            width: 100%;
            margin-left: 0%;
            color: var(--green);
        }
    `}
    &:hover::after {
        width: 100%;
        margin-left: 0%;
        color: var(--green);
    }

`

export const FirstHeaderMenuItem = styled(HeaderMenuItem)`
// top: 0.8rem;
//     left: calc(50% - 18rem);
`

export const SecondHeaderMenuItem = styled(HeaderMenuItem)`
// top: 0.8rem;
    // left: calc(50% - 6rem);
`

export const ThirdHeaderMenuItem = styled(HeaderMenuItem)`
// top: 0.8rem;
    // left: calc(50% + 6rem);
`
type FourthHeaderMenuItemProps ={
    disableHover: any;
}

export const FourthHeaderMenuItem = styled(HeaderMenuItem)<FourthHeaderMenuItemProps>`
    top: 0.8rem;
    left: calc(50% + 18rem);
    cursor: ${props => props.disableHover ? 'not-allowed' : 'pointer'};
    
    &:hover {
        color: ${props => props.disableHover ? 'var(--gray-100)' : 'var(--green)'};
    }
    &::after {
        background-color: ${props => props.disableHover ? 'transparent' : 'var(--green)'};
    }
`

export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`


type TabButtonProps = {
    showHover: boolean;
    platformDisconnected?: boolean;
};

export const TabButton = styled.button<TabButtonProps>`
    background-color: transparent;
    color: #9ca3ab;
    padding: 0px 7px;
    text-wrap: nowrap;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 550;
    transition: all 250ms ease-in-out;
    transform 250ms ease-in-out;


    ${(props) =>
        props.showHover
            ? `
                color: #19c27e !important;
               
            `
            : `
            `}

`;


export const TabArea = styled.div<TabButtonProps>`
    padding: 3px 8px 12px 8px;
    display: flex;
    gap: 8px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    margin-bottom: 8px;

        ${(props) => props.platformDisconnected ? `filter: blur(2.5px);` : ``}


       ${(props) =>
        props.showHover
            ? `
                border-bottom: 3px solid #19c27e;
                transition: all 250ms ease-in-out;
                transform: translateY(-3px);
            `
            : `
            `}

              &:hover {
        color: #19c27e;
        border-color: #19c27e;
        transition: all 250ms ease-in-out;
        transform: translateY(-3px);
    }
;`

export const SelectionFilterSection = styled.div<{disabled: boolean}>`
    background-color: ${(props) => (props.disabled ? '#e9e9e9' : '#f3f3f3')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    padding: 15px;
    margin-top: -1px;
    height: 6px;
    color: ${(props) => (props.disabled ? '#6f7e77' : 'var(--black)')};
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;
    gap: 0.5rem;

    svg {
        fill: ${(props) => (props.disabled ? '#6f7e77' : 'var(--black)')};
        width: 1.1rem;
    }

    &:hover {
        cursor:${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '-var(--gray-150)' : '#e9e9e9')};
        transition: background-color 0.2s;
    }

    span {
        padding: 1px 10px;
        border-radius: 50px;
        font-weight: 550;
        color: var(--white);
        background-color: #6f7e77;
    }
`;