//import { useGetSales } from '../../../services/hooks/trackingHooks'
import { useState, useEffect, useRef } from 'react'
import { Search } from '../../../components/Search'
import { LineOrdersModal } from './TableOrdersModal/LineOrdersModal'
import * as S from './style'
import Modal from '../../../components/Modal'
import { ClientSalesModal } from './ClientSalesModal'
import { getSalesByClientDTO, getSalesDTO } from '../../../@types'
import { useGetSales, useGetProductSales, useGetCatalogSales } from '../../../services/hooks/trackingHooks'
import { toBrazilianFormat } from '../../../utils'
// import { LineSalesModalLoading } from './TableSalesModal/LineSalesModalLoading'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import useVisibility from '../../../hooks/useVisibilityChange';
import { useRefreshContext } from '../../../contexts/RefeshContext';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Tooltip from '@mui/material/Tooltip';
import { useCurrencyType } from '../../../hooks/useCurrencyType';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { TableSalesModal } from './TableOrdersModal'
import { TableProductsModal } from './TableProductsModal'
import { TableChannelCatalogModal } from './TableChannelCatalogModal'
import { Button, useMediaQuery } from '@mui/material';
import MoneyOffRoundedIcon from '@mui/icons-material/MoneyOffRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { ButtonApp } from '../../../components/ButtonApp'
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';

export function SalesModal({handleOpenEditColumn, setColumnSolomon, columnSolomon, salesInfo, metricsBySource, ordersSolomon, revenueSolomon, source, date, onClose, isCatalogCampaign, isCatalogChannel }: {handleOpenEditColumn?: () => void,  setColumnSolomon?: (string) => void, columnSolomon?: any, salesInfo: getSalesDTO, metricsBySource: any, ordersSolomon?: any, revenueSolomon?: any,   source: string, date: string[], onClose: () => void, isCatalogCampaign?: boolean, isCatalogChannel?: boolean}) {
    const isSmallScreen = useMediaQuery('(max-width:1100px)');
    // const isMediumScreen = useMediaQuery('(max-width:1700px)');

    // //console.log('all params', columnSolomon, salesInfo, metricsBySource, ordersSolomon, revenueSolomon, source, isCatalogCampaign)

    const { data, isLoading, refetch } = useGetSales(salesInfo);
    const { data: dataProducts, isLoading: isLoadingProductsSales, refetch: refetchProducts } = useGetProductSales(salesInfo);
    const { data: dataCatalog, isLoading: isLoadingCatalogs, refetch: refetchCatalogs } = useGetCatalogSales(salesInfo);


    console.log('is campaign', isCatalogCampaign, 'is channel', isCatalogChannel);

    const { refreshCounter } = useRefreshContext();
    const { currencySymbol } = useCurrencyType();

    const [tabSelected, setTabSelected] = useState('productsTable');

    useEffect(() => {
        // Define o filtro de status com base no valor de columnSolomon
        let status = '';
        switch (columnSolomon) {
            case 'approvedSolomon':
                status = 'approved';
                break;
            case 'pendingSolomon':
                status = 'pending';
                break;
            case 'cancelledSolomon':
                status = 'cancelled';
                break;
            case 'totalSolomon':
                status = '';  // Caso totalSolomon não defina um filtro específico
                break;
            default:
                status = '';
        }

        setSelectedStatusFilter(status);  // Atualiza o estado do filtro de status
        setTriggerApplyFilter(true);      // Dispara a aplicação do filtro
    }, [columnSolomon, isLoading]);


    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetch();
        }
    }, [refreshCounter]);

    const { isVisible } = useVisibility();

    const [filter, setFilter] = useState('')
    const [showInfo, setShowInfo] = useState(true)
    const [openClientSalesModal, setOpenClientSalesModal] = useState(false)
    const [clientInfo, setClientInfo] = useState({} as getSalesByClientDTO)

    function openClientModal(value: getSalesByClientDTO) {
        setClientInfo(value)
        setOpenClientSalesModal(true)
    }

    useEffect(() => {
        if (isSmallScreen) {
            setShowInfo(false)
        } else {
            setShowInfo(true)
        }
    }, [isSmallScreen])

    const orders = data?.data?.data

    let productsCatalog;
    let products
    let isLoadingProductsCatalog;
    let isLoadingProducts;

    if (isCatalogCampaign) {
        products = dataCatalog?.data?.data
        isLoadingProducts = isLoadingCatalogs

    } else if (isCatalogChannel) {
        
        products = dataProducts?.data?.data
        isLoadingProducts = isLoadingProductsSales

        productsCatalog = dataCatalog?.data?.data
        isLoadingProductsCatalog = isLoadingCatalogs
    
    } else { 
        products = dataProducts?.data?.data
        isLoadingProducts = isLoadingProductsSales
    }


    // //console.log('products', products)

    const [ordersFiltered, setOrdersFiltered] = useState(orders);
    const [productsFiltered, setProductsFiltered] = useState(products);
    const [productsCatalogFiltered, setProductsCatalogFiltered] = useState(productsCatalog);

    useEffect(() => {
        setOrdersFiltered(orders);
    }, [orders]);

    useEffect(() => {
        setProductsFiltered(products);
    }, [products]);

    useEffect(() => {
        setProductsCatalogFiltered(productsCatalog);
    }, [productsCatalog]);

    const safeNumber = (value, defaultValue = 0) => {
        return isNaN(value) || !isFinite(value) ? defaultValue : value;
    };


    // aqui eu tô mudando

    const sumProductPrice = orders?.reduce((acumulador: number, objeto: any) => {
        return acumulador + objeto?.product_price;
    }, 0);

    const sumTax = orders?.reduce((acumulador: number, objeto: any) => {
        return acumulador + objeto?.gateway_fee + objeto?.checkout_fee;

    }, 0);

    const customerIdsSet = new Set();

    orders?.forEach((objeto: any) => {
        customerIdsSet?.add(objeto.customer_id);
    });

    const TotalClients = customerIdsSet?.size;
    const TotalOrders = orders?.length || 0;

    let totalSales = 0;
    let totalSalesCatalog = 0;

    products?.forEach((objeto: any) => {
        totalSales += safeNumber(Number(objeto?.number_sales)) + safeNumber(Number(objeto?.pending_number_sales)) + safeNumber(Number(objeto?.cancelled_number_sales));
    });

    productsCatalog?.forEach((objeto: any) => {
        totalSalesCatalog += safeNumber(Number(objeto?.number_sales)) + safeNumber(Number(objeto?.pending_number_sales)) + safeNumber(Number(objeto?.cancelled_number_sales));
    });

    //console.log('totalSales', totalSales)



    const dontHaveOrders = !isLoading && ordersFiltered?.length === 0;

    const dontHaveProducts = !isLoading && productsFiltered?.length === 0;

    const dontHaveProductsCatalog = !isLoading && productsCatalogFiltered?.length === 0;

    const [showFilter, setShowFilter] = useState(false);
    const prevFilter = useRef('');

    const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
    const [selectedMpagFilter, setSelectedMpagFilter] = useState('');
    const [selectedStatusFilter2, setSelectedStatusFilter2] = useState('');
    const [selectedMpagFilter2, setSelectedMpagFilter2] = useState('');

    const urlLinkBoleto = 'https://storage.googleapis.com/static-images-source/billet-icon.png';
    const urlLinkCartao = 'https://storage.googleapis.com/static-images-source/mastercard-icon.png';
    const urlLinkPix = 'https://storage.googleapis.com/static-images-source/pix-icon.png';

    let url = '';
    let name = '';

    switch (parseInt(selectedMpagFilter)) {
        case 0:
            url = '';
            name = 'Desconhecido';
            break;
        case 1:
            url = urlLinkCartao;
            name = 'Cartão de crédito';
            break;
        case 3:
            url = urlLinkBoleto;
            name = 'Boleto';
            break;
        case 4:
            url = urlLinkPix;
            name = 'Pix';
            break;
    }

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatusFilter(event.target.value);
        
    };

    const handleMpagFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMpagFilter(event.target.value);
    };

    const [triggerApplyFilter, setTriggerApplyFilter] = useState(false);

    useEffect(() => {
        if (triggerApplyFilter || filter !== prevFilter.current) {
            handleApplyFilter();
            setTriggerApplyFilter(false);
        }
        prevFilter.current = filter;
    }, [triggerApplyFilter, filter, selectedStatusFilter]);


    const handleApplyFilter = () => {
        const statusFilter = selectedStatusFilter;
        const mpagFilter = parseInt(selectedMpagFilter);
    
        let filteredOrders = Array.isArray(orders) ? [...orders] : [];
        let filteredProducts = Array.isArray(products) ? [...products] : [];
        let filteredProductsCatalog = Array.isArray(productsCatalog) ? [...productsCatalog] : [];
    
        if (filter !== '') {
            const normalizedFilter = filter.replace(/#/g, '').toLowerCase(); // Remove o '#' e converte para minúsculas
    
            filteredOrders = orders?.filter((order: any) => {
                return order?.order_name.toLowerCase().includes(normalizedFilter) ||
                       order?.first_name.toLowerCase().includes(normalizedFilter);
            });
    
            filteredProducts = products?.filter((product: any) => {
                return (product?.product_name && product.product_name.toLowerCase().includes(normalizedFilter)) ||
                       (product?.product_id && product.product_id.toString().includes(normalizedFilter));
            });
    
            filteredProductsCatalog = productsCatalog?.filter((productCatalog: any) => {
                return (productCatalog?.product_name && productCatalog.product_name.toLowerCase().includes(normalizedFilter)) ||
                       (productCatalog?.product_id && productCatalog.product_id.toString().includes(normalizedFilter));
            });
        }
    
        if (statusFilter !== "" && !isNaN(mpagFilter)) {
            filteredOrders = filteredOrders?.filter((order: any) => order?.status_name === statusFilter && order?.payment_method === mpagFilter);
            // filteredProducts = filteredProducts.filter((product: any) => product?.status_name === statusFilter && product?.payment_method === mpagFilter);
        } else if (statusFilter !== "") {
            filteredOrders = filteredOrders?.filter((order: any) => order?.status_name === statusFilter);
            // filteredProducts = filteredProducts.filter((product: any) => product?.status_name === statusFilter);
        } else if (!isNaN(mpagFilter)) {
            filteredOrders = filteredOrders?.filter((order: any) => order?.payment_method === mpagFilter);
            // filteredProducts = filteredProducts.filter((product: any) => product?.payment_method === mpagFilter);
        }
    
        // Update state with filtered orders and products
        setOrdersFiltered(filteredOrders);
        setProductsFiltered(filteredProducts);
        setProductsCatalogFiltered(filteredProductsCatalog);
        setSelectedMpagFilter2(selectedMpagFilter);
        setSelectedStatusFilter2(selectedStatusFilter);

        if (selectedStatusFilter === 'approved') {
            setColumnSolomon('approvedSolomon');
        } else if (selectedStatusFilter === 'pending') {
            setColumnSolomon('pendingSolomon');
        } else if (selectedStatusFilter === 'cancelled') {
            setColumnSolomon('cancelledSolomon');
        } else { 
            setColumnSolomon('totalSolomon');
        }
    };
    

    const filterContainerRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterContainerRef.current && !filterContainerRef.current.contains(event.target as Node) && filterRef.current && !filterRef.current.contains(event.target as Node)) {
                setShowFilter(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterContainerRef, filterRef]);


    const platformIcons: { [key: string]: string } = {
        facebook: 'https://storage.googleapis.com/static-images-source/facebook-icon.webp',
        tiktok: 'https://storage.googleapis.com/static-images-source/tiktok-icon.png',
        google: 'https://storage.googleapis.com/static-images-source/google-logo-icon.png',
        // Adicione mais plataformas e seus ícones conforme necessário
    };

    const sourceRoot = salesInfo?.source.toLowerCase();
    const iconUrl = platformIcons[sourceRoot] || 'https://storage.googleapis.com/static-images-source/other-source-icon.png';


    return (
        <S.Container>
            <div style={{display: 'flex', gap: '1.5rem'}}>
            <S.Icon onClick={onClose}>
                <ArrowBackIcon />
            </S.Icon>
            <div  style={{marginTop: '-0.5rem' }}>
            <S.Title>
                Produtos e Pedidos por <span>{source === 'channel' ? 'Canal' : source === 'campaign' ? 'Campanhas' : source === 'adsSet' ? 'Conjunto de Anúncios' : source === 'ads' ? 'Anúncios' : ''}</span>
            </S.Title>
            <S.Subtitle>
                Veja quais foram os pedidos e produtos provenientes {source === 'channel' ? 'desse canal' : source === 'campaign' ? 'dessa campanha' : source === 'adsSet' ? 'desse conjunto de anúncios' : source === 'ads' ? 'desse anúncio' : ''}
            </S.Subtitle>
            </div>
            </div>
            <S.Line />
            {showInfo && <S.Section>
                <S.SecondSection>
                    <S.SecondSectionSubtitle>
                        <span>Nome</span>
                    </S.SecondSectionSubtitle>
                    <S.SecondSectionTitle>
                            <p>
                                {isVisible || salesInfo?.name === undefined
                                    ? salesInfo?.name
                                        ? salesInfo?.name
                                        : salesInfo?.source
                                    : '* * * * * * * * * * * *'
                                }
                            </p>
                    </S.SecondSectionTitle>

                    {sourceRoot !== 'facebook' && sourceRoot !== 'google' && sourceRoot !== 'tiktok' ? (
                        <span></span>
                    ) : (
                        <>
                        </>
                    )}
                </S.SecondSection>
                {/* <S.VLine /> */}
                <S.FirstSection>
                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                            <S.SquareIcon>
                                <MoneyOffRoundedIcon />
                            </S.SquareIcon>
                            <S.FirstSectionTitle>
                                <div>
                                    <Tooltip
                                        title={`Gasto proveniente ${source === 'channel' ? 'do canal selecionado.' : source === 'campaign' ? 'da campanha selecionada.' : source === 'adsSet' ? 'do conjunto de anúncios selecionado.' : source === 'ads' ? 'do anúncio selecionado.' : ''}`}
                                        placement="right"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--black)',
                                                    fontWeight: 'semibold',
                                                    fontSize: '13px',
                                                    fontFamily: 'Montserrat',
                                                    borderRadius: '7px',
                                                    padding: '10px',
                                                    paddingRight: '30px',
                                                    color: 'white',
                                                    // width: '400px',
                                                    textWrap: 'wrap',
                                                    zIndex: 999999,
                                                }
                                            },
                                        }}
                                    >
                                        <img src={iconUrl} alt="" />
                                    </Tooltip>
                                    <span style={{ color: '#666' }}>Gasto</span>
                                </div>
                            </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                            {currencySymbol} <span>{metricsBySource?.spendBySource ? toBrazilianFormat(metricsBySource?.spendBySource) : toBrazilianFormat(0)}</span>
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>
                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                            <S.SquareIcon>
                                <MoneyIcon />
                            </S.SquareIcon>
                            <S.FirstSectionTitle>
                                <div>
                                    <Tooltip
                                        title="Receita proveniente do traqueamento Solomon."
                                        placement="right"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--black)',
                                                    fontWeight: 'semibold',
                                                    fontSize: '13px',
                                                    fontFamily: 'Montserrat',
                                                    borderRadius: '7px',
                                                    padding: '10px',
                                                    paddingRight: '30px',
                                                    color: 'white',
                                                    width: '400px',
                                                    textWrap: 'nowrap',
                                                    zIndex: 999999,
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://storage.googleapis.com/static-images-source/favicon.png"></img>
                                    </Tooltip>
                                    Receita total
                                </div>
                            </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                        <p>{currencySymbol} {!isLoading ? toBrazilianFormat((sumProductPrice)) : 0}</p>
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>

                   

                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                            <S.SquareIcon>
                                <GroupIcon />
                            </S.SquareIcon>
                            <S.FirstSectionTitle>
                                <div>
                                    <Tooltip
                                        title="Clientes provenientes do traqueamento Solomon."
                                        placement="right"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--black)',
                                                    fontWeight: 'semibold',
                                                    fontSize: '13px',
                                                    fontFamily: 'Montserrat',
                                                    borderRadius: '7px',
                                                    padding: '10px',
                                                    paddingRight: '38px',
                                                    color: 'white',
                                                    width: '400px',
                                                    textWrap: 'nowrap',
                                                    zIndex: 999999,
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://storage.googleapis.com/static-images-source/favicon.png"></img>
                                    </Tooltip>
                                    Clientes totais
                                </div>
                            </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                            <p>{TotalClients}</p>
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>

                </S.FirstSection>
            </S.Section>}

            <S.SearchSection>
                
                <S.Tabs>
                    <Search width="18rem" minWidth="18rem" height="2.5rem" placeholder={tabSelected === 'ordersTable' ? 'Pesquisar por número de pedido ou cliente' : 'Pesquisar por ID ou nome do produto'} value={filter} setValue={setFilter} />
                    <S.Tab onClick={() => setTabSelected('productsTable')} selected={tabSelected === 'productsTable'}> <CategoryOutlinedIcon /> Produtos: {totalSales}</S.Tab>
                    <S.Tab onClick={() => setTabSelected('ordersTable')} selected={tabSelected === 'ordersTable'}> <OrdersIcon /> Pedidos: {TotalOrders}</S.Tab>

                    {isCatalogChannel &&  (  
                        <S.Tab onClick={() => setTabSelected('productsCatalogChannelTable')} selected={tabSelected === 'productsCatalogChannelTable'}> <ViewModuleRoundedIcon /> Catálogo: {totalSalesCatalog}</S.Tab>
                    )}

                </S.Tabs>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>      

                           
                    <S.Filters>
                        <S.Status>
                            {selectedStatusFilter2 === 'approved' && (
                                <S.Approved>
                                    <S.Image><CheckCircleOutlineIcon sx={{ width: '18px', height: '18px' }} /></S.Image>
                                    {/* Aprovado */}
                                    <S.CloseButton onClick={() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '16px', height: '16px' }} /></S.CloseButton>
                                </S.Approved>
                            )}
                            {selectedStatusFilter2 === 'pending' && (
                                <S.Pending>
                                    <S.Image><PendingOutlinedIcon sx={{ width: '18px', height: '18px' }} /></S.Image>
                                    {/* Pendente */}
                                    <S.CloseButton onClick={() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '16px', height: '16px' }} /></S.CloseButton>
                                </S.Pending>
                            )}
                            {selectedStatusFilter2 === 'cancelled' && (
                                <S.Cancelled>
                                    <S.Image><CancelOutlinedIcon sx={{ width: '18px', height: '18px' }} /></S.Image>
                                    {/* Cancelado */}
                                    <S.CloseButton onClick={() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '16px', height: '16px' }} /></S.CloseButton>
                                </S.Cancelled>
                            )}
                        </S.Status>
                        {selectedMpagFilter2 !== "" && (
                            <S.Payment  style={tabSelected === 'productsTable' ? {display: 'none'} : {}}>
                                <img style={name === 'Pix' ? { width: '1rem' } : {width: '1.3rem'}} src={url} /> 
                                {/* {name} */}
                                <S.CloseButton onClick={() => { setSelectedMpagFilter(""); setTriggerApplyFilter(true); }} ><CloseIcon sx={{ width: '16px', height: '16px', fontWeight: '550' }} /></S.CloseButton>
                            </S.Payment>
                        )}
                    </S.Filters>

                    <S.Filter onClick={() => setShowFilter(prev => !prev)} ref={filterRef}>
                        <FilterAltOutlinedIcon sx={{ width: '24px', height: '24px' }} /> Filtros
                    </S.Filter>

                    {((isCatalogCampaign && tabSelected === 'productsTable') || (isCatalogChannel && tabSelected === 'productsCatalogChannelTable')) && (
                       <Tooltip title="Editar colunas" placement="top" componentsProps={{
                        tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: '9999' } },
                    }}>
                        <S.EditColumnArea onClick={handleOpenEditColumn}>
                            <ViewWeekRoundedIcon />
                        </S.EditColumnArea>
                    </Tooltip>
                    )
                    }

                    <Tooltip title="Expandir tabela" placement="top" componentsProps={{
                                            tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: '999999999999 !important' } },
                                        }}>
                        <S.Expand onClick={() => setShowInfo(prev => !prev)}>
                            {showInfo ? <MinimizeIcon /> : <ExpandIcon />}
                        </S.Expand> 
                    </Tooltip>
                    </div>
                    
                {showFilter && (
                    <S.FilterContainer showFilter={showFilter} ref={filterContainerRef}>
                        <S.HeaderFilter>
                            <FilterAltOutlinedIcon sx={{ width: '16px', height: '16px' }} /> Filtros
                            <S.CloseButton onClick={() => setShowFilter(false)}><CloseIcon sx={{ width: '18px', height: '18px' }} /></S.CloseButton>
                        </S.HeaderFilter>
                        <S.LinesFilter></S.LinesFilter>
                        <S.FilterSection>
                            <S.FlexTitleReset>
                                Status
                                <S.ResetButton onClick={() => setSelectedStatusFilter('')}>Resetar</S.ResetButton>
                            </S.FlexTitleReset>
                            <S.Select id="statusFilter" value={selectedStatusFilter} onChange={handleStatusFilterChange}>
                                <option value="">Todos os Status</option>
                                <option value="approved">Aprovado</option>
                                <option value="cancelled">Cancelado</option>
                                <option value="pending">Pendente</option>
                            </S.Select>
                        </S.FilterSection>
                        <S.LinesFilter></S.LinesFilter>
                        <S.FilterSection style={tabSelected === 'productsTable' ? {display: 'none'} : {}}>
                            <S.FlexTitleReset>
                                Método de Pagamento
                                <S.ResetButton onClick={() => setSelectedMpagFilter('')}>Resetar</S.ResetButton>
                            </S.FlexTitleReset>
                            <S.Select id="mpagFilter" value={selectedMpagFilter} onChange={handleMpagFilterChange}>
                                <option value="">Todos os Métodos</option>
                                <option value="1">Cartão de crédito</option>
                                <option value="3">Boleto</option>
                                <option value="4">Pix</option>
                            </S.Select>
                        </S.FilterSection>
                        <S.LinesFilter></S.LinesFilter>
                        <S.ApplyButton onClick={() => (handleApplyFilter(), setShowFilter(false))}>Aplicar filtro</S.ApplyButton>
                    </S.FilterContainer>
                )}

              
            </S.SearchSection>


            <S.InnerContainer expandedTable={!showInfo}>
                {tabSelected === 'ordersTable' && 
                    <TableSalesModal ordersFiltered={ordersFiltered} isLoading={isLoading} openClientModal={openClientModal} date={date} dontHaveOrders={dontHaveOrders} />
                }
                
                {(!isCatalogCampaign && tabSelected === 'productsTable') &&
                    <TableProductsModal productsFiltered={productsFiltered} isLoading={isLoadingProducts} openClientModal={openClientModal} date={date} dontHaveProducts={dontHaveProducts} />
                }

                {(isCatalogCampaign && tabSelected === 'productsTable') && (
                    <TableProductsModal ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} metricsBySource={metricsBySource} columnSolomon={columnSolomon} isCatalogCampaign={isCatalogCampaign} productsFiltered={productsFiltered} isLoading={isLoadingProducts} openClientModal={openClientModal} date={date} dontHaveProducts={dontHaveProducts}  />
                )}

                {(isCatalogChannel && tabSelected === 'productsCatalogChannelTable') && (

                    <TableChannelCatalogModal ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} metricsBySource={metricsBySource} columnSolomon={columnSolomon} isCatalogCampaign={isCatalogChannel} productsFiltered={productsCatalogFiltered} isLoading={isLoadingProductsCatalog} openClientModal={openClientModal} date={date} dontHaveProducts={dontHaveProductsCatalog}  />

                )}

            </S.InnerContainer>

            {openClientSalesModal && <Modal onClose={() => setOpenClientSalesModal(false)}>
                <ClientSalesModal orders={orders} clientInfo={clientInfo} onClose={() => setOpenClientSalesModal(false)} />
            </Modal>}


        </S.Container>
    )
}


function ArrowBackIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
        </svg>
    )
}

function MoneyIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M441-120v-86q-53-12-91.5-46T293-348l74-30q15 48 44.5 73t77.5 25q41 0 69.5-18.5T587-356q0-35-22-55.5T463-458q-86-27-118-64.5T313-614q0-65 42-101t86-41v-84h80v84q50 8 82.5 36.5T651-650l-74 32q-12-32-34-48t-60-16q-44 0-67 19.5T393-614q0 33 30 52t104 40q69 20 104.5 63.5T667-358q0 71-42 108t-104 46v84h-80Z" />
        </svg>
    )
}

function ExpandIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180ZM358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z" />
        </svg>
    )
}

function MinimizeIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m356-160-56-56 180-180 180 180-56 56-124-124-124 124Zm124-404L300-744l56-56 124 124 124-124 56 56-180 180Z" />
        </svg>
    )
}

function OrderAproveIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m691-150 139-138-42-42-97 95-39-39-42 43 81 81ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-19-9-39-15t-41-9v-243H200v562h243q5 31 15.5 59T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Zm120-200h203q3-21 9-41t15-39H240v80Zm0-160h284q38-37 88.5-58.5T720-520H240v80Zm-40 242v-562 562Z" />
        </svg>
    )
}

function GroupIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />\
        </svg>
    )
}

function OrdersIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M160-160v-516L82-846l72-34 94 202h464l94-202 72 34-78 170v516H160Zm240-280h160q17 0 28.5-11.5T600-480q0-17-11.5-28.5T560-520H400q-17 0-28.5 11.5T360-480q0 17 11.5 28.5T400-440ZM240-240h480v-358H240v358Zm0 0v-358 358Z" />
        </svg>
    )
}