import { useRef, MutableRefObject } from 'react';

export function reduceString(str: string, comprimentoMaximo: number) {
    if (str?.length <= comprimentoMaximo) {
        return str;
    } else {
        return str?.substring(0, comprimentoMaximo - 3) + '...';
    }
}

export function sumArrays(...vetores: any) {
    if (vetores?.length < 2) {
        return [];
    }

    const tamanhoReferencia = vetores[0]?.length;
    const todosIguais = vetores?.every(function (vetor: any) {
        return vetor?.length === tamanhoReferencia;
    });

    if (!todosIguais) {
        return [];
    }

    const resultado = [];
    for (var i = 0; i < tamanhoReferencia; i++) {
        const soma = vetores?.reduce(function (acumulador: any, vetor: any) {
            return acumulador + vetor[i];
        }, 0);

        resultado.push(soma);
    }

    return resultado;
}

export function subArrays(vetor1: any, vetor2: any) {
    if (vetor1?.length !== vetor2?.length) {
        return [];
    }

    const resultado = [];
    for (let i = 0; i < vetor1?.length; i++) {
        resultado.push(vetor1[i] - vetor2[i]);
    }

    return resultado;
}

export function calculateTaxValueChart(calculation_rule: string, aliquote_rate: number, fat: number, totalProductCost: number, totalMarketingCost: number, xAxis?: any) {
    // Verificar se os parâmetros são números válidos
    if (isNaN(aliquote_rate) || isNaN(fat) || isNaN(totalProductCost) || isNaN(totalMarketingCost)) {
        console.error("Um ou mais parâmetros não são números válidos.");
        return 0; // Retorna 0 ou outro valor padrão em caso de erro
    }

    let tax_value = 0;
    let profit = 0;
    // console.log("calculation2", calculation_rule);

    switch (calculation_rule) {
        case 'custo_nenhum':
            profit = fat;
            break;
        case 'custo_producao':
            profit = fat - totalProductCost;
            break;
        case 'custo_marketing':
            profit = fat - totalMarketingCost;
            break;
        case 'custo_producao_marketing':
            profit = fat - totalProductCost - totalMarketingCost;
            break;
        default:
            // console.error("Regra de cálculo inválida.");
            return 0; // Retorna 0 em caso de regra inválida
    }

    // Calcular o valor do imposto
    tax_value = profit * (aliquote_rate / 100);

    // Garantir que o valor do imposto não seja negativo
    if (tax_value < 0) {
        tax_value = 0;
    }

    return tax_value;
}

export function calculateTaxValue(calculation_rule: string, aliquote_rate: number, fat: number, totalProductCost: number, totalMarketingCost: number) {
    // Verificar se os parâmetros são números válidos
    if (isNaN(aliquote_rate) || isNaN(fat) || isNaN(totalProductCost) || isNaN(totalMarketingCost)) {
        console.error("Um ou mais parâmetros não são números válidos.");
        return 0; // Retorna 0 ou outro valor padrão em caso de erro
    }

    let tax_value = 0;
    let profit = 0;
    console.log("calculation2", calculation_rule);

    switch (calculation_rule) {
        case 'custo_nenhum':
            profit = fat;
            break;
        case 'custo_producao':
            profit = fat - totalProductCost;
            break;
        case 'custo_marketing':
            profit = fat - totalMarketingCost;
            break;
        case 'custo_producao_marketing':
            profit = fat - totalProductCost - totalMarketingCost;
            break;
        default:
            // console.error("Regra de cálculo inválida.");
            return 0; // Retorna 0 em caso de regra inválida
    }

    // Calcular o valor do imposto
    tax_value = profit * (aliquote_rate / 100);

    // Garantir que o valor do imposto não seja negativo
    if (tax_value < 0) {
        tax_value = 0;
    }

    return tax_value;
}


export function toBrazilianFormat(value: number | null, formatInteger = true) {
    if (!value && formatInteger){

        return '0,00';
    }

    if (!value && !formatInteger) {
        return '0'
    }

    if (Number.isInteger(value) && !formatInteger) {
        return value 
    }

    return value?.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
}

export function divideVectors(vector1: any[], vector2: any[]) {
    if (vector1.length !== vector2.length) {
        throw new Error('Vectors must have the same length');
    }
    const result = [];

    for (let i = 0; i < vector1.length; i++) {
        if (vector2[i] === 0) {
            result.push(0);
        } else {
            const division = vector1[i] / vector2[i];
            result.push(division);
        }
    }

    return result;
}

export const uuidv4 = (): string => {
    return (
      ([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11
    ).replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (c / 4)).toString(16)
    );
  };
  

export function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0 em JavaScript
    const year = date.getFullYear();
  
    // se for null, retorna uma string vazia
    if (!dateString) {
      return '?';
    }

    return `${day}/${month}/${year}`;
  }

  export function formatValue(value: number) {
    const formattedValue = value?.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
  
    return formattedValue?.replace('.', ',');
  }

  export function formatPercentageInput(e) {
    let input = e.target.value;
    // Substitui qualquer caractere que não seja dígito ou vírgula
    let value = input.replace(/[^\d,]/g, "");

    // Remove zeros à esquerda, exceto se for o único dígito ou se estiver após uma vírgula
    if (!value.includes(',')) {
        value = value.replace(/^0+/, '0');
    } else {
        const parts = value.split(',');
        parts[0] = parts[0].replace(/^0+/, '0');
        value = parts.join(',');
    }

    // Conta o número de vírgulas no valor
    const commaCount = (value.match(/,/g) || []).length;
    if (commaCount > 1) {
        // Deixa apenas a primeira vírgula e remove as outras
        let parts = value.split(',');
        value = parts.shift() + ',' + parts.join('');
    }

    // Se tiver uma vírgula como separador decimal e números após ela, mantém a formatação
    if (/^\d*,\d*$/.test(value) && value.length > 1) {
        value = value.replace(/^(\d*),(\d*)$/, '$1,$2');
    }

    e.target.value = value;

    // Retorna o valor formatado
    return value;
}

export function formatPercentage(value) {
    if (isNaN(value) || !isFinite(value)) {
        return '0'; // Retorna '0' para casos inválidos
    }
    
    const proportion = value.toFixed(2).replace('.', ','); // Formata para duas casas decimais
    return proportion;
}


export function formatCurrencyInput(e: any) {
    let input = e.target.value;
    // Substitui qualquer caractere que não seja dígito, vírgula ou ponto
    let value = input.replace(/[^\d,.]/g, "");

    let newValue = input?.replace(/\D/g, '');
    newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
    return newValue;
}


type SetValue = (value: string) => void;

export function usePercentageInput(handler: SetValue): [MutableRefObject<HTMLInputElement>, (e: React.KeyboardEvent<HTMLInputElement>) => void] {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value;
        if (e.key === 'Backspace' && inputRef.current?.selectionStart === value.length) {
            e.preventDefault();
            const percentIndex = value.lastIndexOf('%');
            const newPosition = percentIndex - 1; // Posição antes do símbolo de porcentagem

            if (newPosition >= 0) {
                const newValue = value.substring(0, newPosition) + value.substring(percentIndex);
                handler(newValue);
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.setSelectionRange(newPosition, newPosition);
                    }
                }, 0);
            }
        }
    };

    return [inputRef, handleKeyDown];
}

export function isNull(value?: string) {
    return value === null || value === '';
}

