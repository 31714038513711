import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
import { trackEvent } from '../../services/segment/sendEvent'

const BASE_URL = import.meta.env.VITE_API_URL;

export async function getData(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(
        `${BASE_URL}/client-services/data-pipeline/logging/notices?company_id=${company_id}&context=onboarding,refresh&integration_id=facebook_ads_insights,tiktok_ads_insights,shopify_orders,yampi_checkout,cartpanda_checkout,google_ads,yever_checkout`
    );
    return data;
}

export async function getRefreshProgress(body: DTO.RefreshProgressDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/data-pipeline/logging/progress?company_id=${body.company_id}&context=refresh&group_id=${body.group_id}`);
    return data;
}

export async function getLastUpdated(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/data-pipeline/logging/last-updated?company_id=${company_id}`);
    return data;
}

export async function getRefreshAllData(body: DTO.RefreshAllDataDTO): Promise<AxiosResponse> {
    const { company_id, group_id, user } = body;
    const newBody = {
        company_id,
        group_id,
        integrations: ['facebook_ads', 'shopify', 'yampi', 'cartpanda', 'google_ads', 'tiktok_ads', 'yever'],
    };

    await trackEvent('sync', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: company_id,
        company_name: user?.companies[company_id]?.company,
        group_id: group_id
    });

    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/data-pipeline/initialize`, newBody);
    return data;
}