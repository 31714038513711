import styled, { keyframes } from 'styled-components';

const floating = keyframes`
                0%,
                100% {
                    transform: translateY(0px);
                }
                50% {
                    transform: translateY(-5px);
                }`;

type ContainerGoogleProps = {
    showPopup?: boolean;
};

export const ContainerGoogle = styled.div<ContainerGoogleProps>`
    position: absolute;
    bottom: 100%;
    display: flex;
    ${(props) =>
        props.showPopup
            ? `

    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;

    `
            : `

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    height: 6rem;
    width: 20.5625rem;
    bottom: 9.50rem;
    left: 233px;
    padding: 0.625rem;
    background-color: #0e1819;
    color: white;
    text-align: center;
    border-radius: 11px;
    z-index: 1;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;
    animation: ${floating} 4s ease-in-out infinite;

    @media (max-width: 1100px) {
            margin-left: -15rem;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -0.3125rem;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
        // animation: ${floating} 4s ease-in-out i  nfinite;
    }
`;

export const ButtonGoogle = styled.button`
    padding: 0;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin-top: 0.75rem;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 1rem auto 0;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }
`;

export const GoogleTitle = styled.div`
    font-size: 0.8125rem;
    color: var(--white);
    margin: 0 auto;
    font-weight: 550;
    margin-bottom: 0.5rem;
`;

export const GoogleSubtitle = styled.div`
    font-size: 0.6875rem;
    font-weight: 550;
    color: #989c97;
    margin-bottom: 0.2rem;
`;

type ContainerFacebookProps = {
    showPopup?: boolean;
};

export const ContainerFacebook = styled.div<ContainerFacebookProps>`
    position: absolute;
    display: flex;
    bottom: 100%;

    ${(props) =>
        props.showPopup
            ? `

    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;
    
    `
            : `

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    height: 4.75rem;
    width: 16.5rem;
    bottom: 9.50rem;
    @media (max-width: 1100px) {
    bottom: 14rem;
    margin-left: 5rem;
    left: 0;
    }
 
    left: 8px;
    padding: 0;
    background-color: #0e1819;
    color: #0e1819;
    text-align: center;
    border-radius: 11px;
    z-index: 1;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;
    animation: ${floating} 4s ease-in-out infinite;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
        // animation: ${floating} 4s ease-in-out infinite;
    }
`;

export const MainButtonFacebook = styled.button`
    padding: 0;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 0.75rem auto 0.4rem;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }
`;

export const SecondaryButtonFacebook = styled.button`
    padding: 0;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    color: #989c96;
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    margin: 0.75rem auto 0.4rem;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #989c96;
        transform: translateX(2px);
    }
`;
