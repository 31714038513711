import * as S from './style';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SwitchAccount from '../../../../../../components/SwitchAccount';
import { useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

export function CardAccount({ tiktokStatusInactive, setTiktokStatusInactive, setSwitchInfos, setSelectedAccountId, setSelectedProfileId, setShowConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupProfile, tiktokInfo, isLoadingOutside }: any) {
    const [showMoreAccounts, setShowMoreAccounts] = useState(true);

    return (
        <S.Container>
            {isLoadingOutside && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.AvatarContainerLoading>
                    <Skeleton variant="circular" sx={{ width: '2rem', height: '2rem', marginLeft: '-1.5rem' }} />
                </S.AvatarContainerLoading>
                <Skeleton sx={{ width: '16rem', height: '2rem', fontSize: '1rem', marginLeft: '-1rem' }} />

            </S.InnerContainer>}
            {!isLoadingOutside && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.FlexTitle>
                    <S.AvatarContainer>
                        <PersonIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} />
                    </S.AvatarContainer>
                    <p>{tiktokInfo?.assets?.profile_name}</p><span>- {tiktokInfo?.profile_id}</span>
                </S.FlexTitle>
                <S.ActionAreaProfile>

                    <Tooltip title="Excluir perfil" placement="top" componentsProps={{
                        tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '10px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: 999999 } },
                    }}>
                        <DeleteForeverRoundedIcon onClick={() => {
                            setShowConfirmDisconnectPopupProfile(true)
                            setSelectedProfileId(tiktokInfo?.profile_id)
                        }} />
                    </Tooltip>
                    <S.SelectButton>
                        {showMoreAccounts && <KeyboardArrowUpIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(false)} />}
                        {!showMoreAccounts && <KeyboardArrowDownIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(true)} />}
                    </S.SelectButton>
                </S.ActionAreaProfile>

            </S.InnerContainer>}
            {showMoreAccounts &&
                tiktokInfo?.accounts?.map((account: any) => {
                    return (
                        <S.Account key={account?.account_id}>
                            <div>
                                <S.AccountTitle>NOME DA CONTA</S.AccountTitle>
                                <S.AccountName>
                                <p>{account?.assets?.advertiser_name}</p> <span>- {account?.account_id}</span>
                                </S.AccountName>
                            </div>

                            <S.ActionArea>
                                <Tooltip title="Ativar/Desativar conta" placement="top" componentsProps={{
                                    tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '10px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: 999999 } },
                                }}>
                                    <div>
                                        <SwitchAccount setTiktokStatusInactive={setTiktokStatusInactive} tiktokStatusInactive={tiktokStatusInactive} account={account} profileId={tiktokInfo?.profile_id} type='tiktok' />
                                    </div>
                                </Tooltip>

                                <Tooltip title="Excluir dados da conta" placement="top" componentsProps={{
                                    tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '10px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: 999999 } },
                                }}>
                                    <DeleteForeverRoundedIcon onClick={() => {
                                        setShowConfirmDisconnectPopupAccount(true)
                                        setSelectedAccountId(account?.account_id)
                                        setSelectedProfileId(tiktokInfo?.profile_id)
                                        setSwitchInfos({ account: account, profileId: tiktokInfo?.profile_id })
                                    }} />
                                </Tooltip>
                            </S.ActionArea>
                        </S.Account>
                    );
                })}
        </S.Container>
    );
}
