import { useQuery, useMutation } from 'react-query';
import { getData, getLastUpdated, getRefreshAllData, getRefreshProgress } from '../api/api-getdata';

export function useGetData(company_id: string, getDataIsEnabled: boolean) {
    return useQuery(['getData', company_id], () => getData(company_id), {
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 3, // 5 seconds
        enabled: getDataIsEnabled,
        onSuccess: () => console.log('Data fetched with success'),
    });
}

export function useGetRefreshProgress(company_id: string, group_id: string, getRefreshProgressIsEnabled: boolean) {
    return useQuery(['getRefreshProgress', company_id, group_id], () => getRefreshProgress({ company_id, group_id }), {
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 3, // 5 seconds
        enabled: getRefreshProgressIsEnabled && group_id.length > 0,
        onSuccess: () => console.log('Refresh progress fetched with success'),
    });
}

export function useGetRefreshAllData() {
    return useMutation(getRefreshAllData);
}

export function useGetLastUpdated(company_id: string, getLastUpdatedIsEnabled: boolean) {
    return useQuery(['getLastUpdated', company_id], () => getLastUpdated(company_id), {
        refetchOnWindowFocus: false,
        cacheTime: Infinity,
        enabled: getLastUpdatedIsEnabled,
        onSuccess: () => console.log('Last updated fetched with success'),
    });
}