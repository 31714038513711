import styled from 'styled-components';

export const Container = styled.div`
    width: 37.5rem;
    // height: 30rem;
    border-radius: 20px;
    padding: 1.2rem;
    > svg {
        fill: var(--green);
    }
`;
export const Title = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 0.8rem;
    > svg {
        fill: var(--green);
    }
`;

export const ContainerCreateInvite = styled.div`
    width: 37.5rem;
    height: 18rem;
    border-radius: 20px;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
        fill: var(--green);
        width: 2rem;
        height: 2rem;
    }

    input {
        margin-top: 1.5rem;
        height: 2.5rem;
        width: 34rem;
        margin-bottom: 0.5rem;
        padding: 0 2.375rem 0 1rem;
        border-radius: 8px;
        border: 1px solid var(--gray-120);
        background-color: #f9f9f9;
        color: var(--black);
        font-weight: 500;
        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
        }
    }
`;

export const VerifiedIcon = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    > svg {
        fill: var(--green);
    }
`;

export const VerifiedTitle = styled.div`
    font-size: 1.5rem;
    color: #1e1e1e;
    font-weight: 600;
    line-height: 1.8rem;
    margin-top: 1rem;
    span {
        color: var(--green);
    }
`;

export const VerifiedText = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    color: #525754;
    span {
        font-weight: 550;
    }
`;

export const SectionInput = styled.div`
    position: relative;
    margin-top: 1.5rem;
    > svg {
        cursor: pointer;
        position: absolute;
        right: 0.8rem;
        bottom: 1.2rem;

        &:hover {
            opacity: 0.8;
        }
    }
`;

type CopyButtonProps = {
    show: boolean;
};


export const CopiedPopup = styled.div<CopyButtonProps>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 20px; /* Rounded border */
    font-size: 14px;
    z-index: 9999;
    opacity: ${props => props.show ? 1 : 0}; /* Start with opacity 0 */
    transition: opacity 0.5s, transform 0.5s ease-out; /* Transition for opacity and transform */
    transform: translateY(${props => props.show ? 0 : 20}px); /* Start from bottom, then move up */
`;

export const Subtitle = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #989c97;
    margin-top: 0.2rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
`;

export const Text = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: var(--black);
`;

export const Subtext = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #989c97;
    margin-top: 0.2rem;
`;

export const ButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
`;


export const ButtonModalMember = styled.button`
    background-color: #19c273;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    padding: 0 1rem;
    height: 2.25rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    svg {
        fill: #fff;
        width: 0.875rem;
        height: 0.875rem;
    }
    &:disabled{
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export const OptionSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.3rem;
    max-height: 10rem;
    height: auto;
    overflow-y: auto;
`;

export const Checkbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    width: 12px;
    height: 12px;
    margin-right: 0.5rem;
`;

export const Option = styled.div`
    background-color: #e7e7e7;
    min-height: 2.1875rem;

    border-radius: 4px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: #465d54;
`;

export const ContainerMemberAlreadyExists = styled.div`
    width: 36rem;
    height: 8rem;
    border-radius: 20px;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
        fill: var(--green);
        width: 2rem;
        height: 2rem;
    }
`;

export const InnerContainer = styled.div`
    // margin-top: 3.5rem;
    height: 9.6875rem;
    width: 28.6875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    overflow-y: auto;
`;


