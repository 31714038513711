import React, { useState, useEffect } from 'react';
import Switch from '../../../components/Switch';
import { postFacebookStatus } from '../../../services/api/api-tracking';
import useAlert from '../../../hooks/useAlert';

const StatusToggle = ({ data, currentCompany, user, objectType, objectId, initialStateData }) => {

    const { showAlert } = useAlert();

    const initialSwitchState = initialStateData === 'ACTIVE'; // Verifica se o status da campanha é 'ACTIVE'
    
    // //console.log('body:', objectType, objectId, data);

    // //console.log('initialSwitchState:', initialStateData);

    const [isChecked, setIsChecked] = useState(() => {
        const storedValue = localStorage.getItem(`isStatusChecked_${currentCompany}_${objectId}`);
        // Se houver um valor armazenado, usa esse valor; caso contrário, usa o estado inicial baseado no campaignStatus
        return storedValue !== null ? JSON.parse(storedValue) : initialSwitchState;
    });

    const facebookInfo = user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles;

    // Filtrar os perfis que contêm o mesmo accountId da campanha clicada
    const matchingProfiles = facebookInfo?.filter(profile => {
        return profile?.accounts?.some(account => account?.account_id === data?.accountId);
    });

    // Obter os profileIds desses perfis
    const profileIds = matchingProfiles?.map(profile => profile?.profile_id);

    useEffect(() => {
        const newInitialSwitchState = initialStateData === 'ACTIVE';
        setIsChecked(newInitialSwitchState);
        localStorage.setItem(`isStatusChecked_${currentCompany}_${objectId}`, JSON.stringify(newInitialSwitchState));
    }, [initialStateData, initialSwitchState, currentCompany, objectId]);

    const handleToggle = async () => {
        const previousCheckedState = isChecked; // Salva o estado atual do switch
        const newCheckedState = !isChecked; // Novo estado do switch

        setIsChecked(newCheckedState); // Atualiza o estado do switch

        const statusMessage = newCheckedState ? 'ativado' : 'desativado';
        const statusAlert = newCheckedState ? 'success' : 'neutral';

        if (objectType === 'campaigns' && objectId === data?.campaignId) {
            showAlert(`Status da campanha ${data?.campaignName} ${statusMessage} com sucesso!`, `${statusAlert}`, 5000); // Exibe um alerta de sucesso
        } else if (objectType === 'adsets' && objectId === data?.adsSetId) {
            showAlert(`Status do conjunto de anúncios ${data?.adsSetName} ${statusMessage} com sucesso!`, `${statusAlert}`, 5000); // Exibe um alerta de sucesso
        } else if (objectType === 'ads' && objectId === data?.adInfo) {
            showAlert(`Status do anúncio ${data?.adName} ${statusMessage} com sucesso!`, `${statusAlert}`, 5000); // Exibe um alerta de sucesso
        }
    
        try {
            const body = {
                company_id: currentCompany,
                profile_id: profileIds.toString(),
                account_id: data?.accountId,
                object_type: objectType,
                object_id: objectId,
                action: newCheckedState ? 'activate' : 'pause', // Define a ação com base no novo estado do switch
            };
    
            // Chama a função do hook para realizar a requisição POST
            await postFacebookStatus(body);
    
    
        } catch (error) {
            // Trata o erro se a requisição falhar
            showAlert('Erro ao atualizar o status. Tente novamente.', 'error', 5000); // Exibe um alerta de erro
            console.error('Erro:', error);
    
            setIsChecked(previousCheckedState); // Reverte o estado do switch para o anterior
        }
    };


    return (
        <Switch
            id={objectId}
            value={isChecked}
            setValue={handleToggle}
        />
    );
};

export default StatusToggle;
