import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%; // Isso faz com que o contêiner ocupe toda a altura da tela
`;

export const Spinner = styled.div` 
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--green);
    border-radius: 50%;
    width: 46px;
    height: 46px;
    animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg) scaleX(-1); }
    100% { transform: rotate(360deg scaleX(-1)); }
  }
`;
