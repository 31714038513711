import { ModalFacebookProps } from '../../../../@types';
import { TemplateModal } from '../index';
import ShareIcon from '@mui/icons-material/Share';
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import * as S from './styles';
import { ButtonApp } from '../../../../components/ButtonApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function ModalFacebook({ url }: ModalFacebookProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
    const baseColor = '#1977f3';

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
    };

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Facebook'}
                title={'Facebook Ads'}
                subtitle={'Promova seu dropshipping através de anúncios criativos na plataforma Facebook Ads.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />
            <S.Line />
            <S.InnerContainer>
                <S.TitleInnerContainer>Integre seu facebook seguindo os passos abaixo:</S.TitleInnerContainer>
                <S.BulletPoints>
                    <CircleSharpIcon sx={{ width: '8px', height: '8px', color: '#19c27e' }} />
                    Copie o link abaixo
                </S.BulletPoints>
                <S.BulletPoints>
                    <CircleSharpIcon sx={{ width: '8px', height: '8px', color: '#19c27e' }} />
                    Cole no navegador onde o perfil que deseja conectar está logado
                </S.BulletPoints>
                <S.BulletPoints>
                    <CircleSharpIcon sx={{ width: '8px', height: '8px', color: '#19c27e' }} />
                    Integre o perfil que deseja conectar
                </S.BulletPoints>

                <S.SectionInput>
                    <input type="text" value={url} readOnly />
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ width: '16px', height: '16px' }} />
                </S.SectionInput>

                <S.InformationMessage>Após realizar a integração com o Facebook por meio do link acima, clique no botão abaixo para realizar a sincronização.</S.InformationMessage>
                <S.FinalLine></S.FinalLine>
                <S.FinalSection>
                    <S.FinalText>Realizar sincronização:</S.FinalText>
                    <S.FinalButton>
                        <ButtonApp
                            border=''
                            width="10rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={() => window.open('/facebook_ads/authorized?context=onboarding', '_blank')}
                            text="Sincronizar Contas"
                        />
                    </S.FinalButton>
                </S.FinalSection>
            </S.InnerContainer>
        </S.Container>
    );
}
