import * as S from './style';
import { LinesTableConfigurationUtm } from './LinesTableConfigurationUtm';
import { LinesTableConfigurationUtmLoading } from './LinesTableConfigurationUtmLoading';
import { TableConfigurationUtmProps } from '../../../@types';
import { getWrongUtms } from '../utils';

export function TableConfigurationUtm ({ tabSelected, data, isLoading }: TableConfigurationUtmProps) {

    const metricsByAds = getWrongUtms( data, tabSelected.toLowerCase());
    
    return (
        <S.Container>
            { tabSelected === 'Facebook' && (
                <h3>
                    Anúncios do <S.TextF>{tabSelected}</S.TextF> com problemas:
                </h3>
            )}

            { tabSelected === 'Google' && (
                <h3>
                    Anúncios do <S.TextG>{tabSelected}</S.TextG> com problemas:
                </h3>
            )}

            { tabSelected === 'Tiktok' && (
                <h3>
                    Anúncios do <S.TextT>{tabSelected}</S.TextT> com problemas:
                </h3>
            )}
            
                { tabSelected === 'Facebook' && (
                    <S.ContainerTable>
                            <S.TableHeader>
                                <S.DefaultColumnF>Conta de Anúncios</S.DefaultColumnF>
                                <S.DefaultColumnF>Anúncio</S.DefaultColumnF>
                                <S.DefaultColumnF>Conjunto</S.DefaultColumnF>
                                <S.DefaultColumnF>Campanha</S.DefaultColumnF>
                                <S.DefaultColumnF>Visualizar UTMs</S.DefaultColumnF>
                            </S.TableHeader>
                            <S.Lines>
                                {!isLoading && (
                                        metricsByAds.map((metric, index) =>(
                                        <LinesTableConfigurationUtm 
                                            key={index}
                                            tabSelected={tabSelected}
                                            metric={metric}
                                        />
                                    )))}
                                {isLoading && Array.from({ length: 10 }).map((_, index) => <LinesTableConfigurationUtmLoading key={index} tabSelected={tabSelected} />)}
                            </S.Lines>
                    </S.ContainerTable>
                        )}

                { tabSelected === 'Google' && (
                    <S.ContainerTable>
                            <S.TableHeader>
                                <S.DefaultColumnG>Conjunto</S.DefaultColumnG>
                                <S.DefaultColumnG>Conjunto</S.DefaultColumnG>
                                <S.DefaultColumnG>Campanha</S.DefaultColumnG>
                                <S.DefaultColumnG>Visualizar UTMs</S.DefaultColumnG>
                            </S.TableHeader>
                            <S.Lines>
                                {!isLoading && (
                                    metricsByAds.map((metric, index) =>(
                                    <LinesTableConfigurationUtm 
                                        key={index}
                                        tabSelected={tabSelected}
                                        metric={metric}
                                    />
                                )))}
                                {isLoading && Array.from({ length: 10 }).map((_, index) => <LinesTableConfigurationUtmLoading key={index} tabSelected={tabSelected} />)}
                            </S.Lines>
                    </S.ContainerTable>
                        )}

                { tabSelected === 'Tiktok' && (
                    <S.ContainerTable>
                            <S.TableHeader>
                                <S.DefaultColumnG>Conjunto</S.DefaultColumnG>
                                <S.DefaultColumnG>Conjunto</S.DefaultColumnG>
                                <S.DefaultColumnG>Campanha</S.DefaultColumnG>
                                <S.DefaultColumnG>Visualizar UTMs</S.DefaultColumnG>
                            </S.TableHeader>
                            <S.Lines>
                                {!isLoading && (
                                    metricsByAds.map((metric, index) =>(
                                    <LinesTableConfigurationUtm 
                                        key={index}
                                        tabSelected={tabSelected}
                                        metric={metric}
                                    />
                                )))}
                                {isLoading && Array.from({ length: 10 }).map((_, index) => <LinesTableConfigurationUtmLoading key={index} tabSelected={tabSelected} />)}
                            </S.Lines>
                    </S.ContainerTable>
                        )}
        </S.Container>
    );
}
