import * as S from './style';
import useAuth from '../../../hooks/useAuth';
import { trackEvent } from '../../../services/segment/sendEvent';
import { storagePlaceholderCompanySave } from '../../../storage/storageCompanyToOnboard';
import { useNavigate } from 'react-router-dom';
import { SideBarPaymentProps, StorageAuthTokenProps, userDTO } from '../../../@types';
import { PlanCardPayment } from './PlanCardPayment';
import { useEffect, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { getPlan } from '../../../services/api/api-subscription';
import { set } from 'date-fns';
import { storageOnboardingRemoveAll } from '../../../storage/storageOnboarding';


export function SideBarPayment({updatePlan, isLoadingPlans, setStoragePlans, contextRenew, plans, setPlans, calculatedPlanPrice, setCouponError, couponError, setSubmitCoupon, addStore, handleFirstInteraction, subscriptionCoupon, setSubscriptionCoupon }: SideBarPaymentProps) {
    const { user, currentCompany, setUserContext, setTokenContext, setCurrentCompanyContext } = useAuth();
    const navigate = useNavigate();

    const [selectedPlan, setSelectedPlan] = useState(() => {
        return localStorage.getItem(`selectedPlan_${user?.user_id}`);
    });

    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            ////console.log(ctx.event);
        }).catch((err) => {
            ////console.log(err);
        });

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        storageOnboardingRemoveAll();
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        navigate('/login');
    }

    useEffect(() => {
        // Assumindo que você está usando window.location.search para obter a query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
    
        const basicPlanUrl = urlParams.has('basic');
        const advancedPlanUrl = urlParams.has('advanced');
        const ultraPlanUrl = urlParams.has('ultra');

        if (basicPlanUrl) {
            setSelectedPlan('basic')
        } else if (advancedPlanUrl) {
            setSelectedPlan('advanced')
        } else if (ultraPlanUrl) {
            setSelectedPlan('ultra')
        }
      }, []); // O array vazio garante que o efeito seja executado apenas uma vez após o componente montar

    useEffect(() => {
        if (user?.user_id == undefined) {
            return;
        }

        localStorage.setItem(`selectedPlan_${user?.user_id}`, selectedPlan);
    }, [ selectedPlan]);


    const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 1024);
    
    const handleClickLojas = () => {    
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon__${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`);
        localStorage.removeItem(`selectedPlanSignUp`);
        localStorage.removeItem(`currentStep`);
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        storageOnboardingRemoveAll();
        navigate(`/lojas?context=renew`);
    }

    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
    };

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }
    }, []);

    return (
        <S.SideBar isMobile={isMobile}>
            <S.Container isMobile={isMobile}> 
                <S.ActionArea isMobile={isMobile}>
                    <S.LogoutButton onClick={signOut}>    
                        <S.LogoutIconStyled />
                        Sair
                    </S.LogoutButton>    
                    {addStore == 'true' && (
                    <S.LogoutButton isStore={true} onClick={handleClickLojas}>    
                        <StoreIcon fontSize='small' />
                        Minhas lojas
                    </S.LogoutButton>     
                    )}               
                </S.ActionArea>
                <h2>Escolha o plano ideal para sua loja<br></br></h2>
                <p style={isMobile ? { fontSize: '0.9rem', color: '#5d7375', textAlign: 'center', width: '101%'} : { fontSize: '0.9rem', color: '#5d7375', textAlign: 'start'}}>Aproveite benefícios exclusivos e suporte dedicado!</p>
                <S.LeftColumn>
                    <PlanCardPayment
                        disabled={false}
                        updatePlan={updatePlan}
                        addStore={addStore}
                        isLoadingPlans={isLoadingPlans}
                        setStoragePlans={setStoragePlans}
                        setPlans={setPlans}
                        contextRenew={contextRenew}
                        setCouponError={setCouponError}
                        couponError={couponError}
                        isMobile={isMobile}
                        plans={plans}
                        onSelect={() => {}}
                        isSelected={true}
                        handleFirstInteraction={handleFirstInteraction}
                        subscriptionCoupon={subscriptionCoupon}
                        setSubscriptionCoupon={setSubscriptionCoupon}
                        setSubmitCoupon={setSubmitCoupon}
                    />
                </S.LeftColumn>
            </S.Container>  
        </S.SideBar>
    );
}