import { STORE_ONBOARDING_STORAGE } from './storageConfig';
// import useAuth from '../hooks/useAuth';
import { storageUserGet } from './storageUser';

const { user_id } = storageUserGet();


export function storageOnboardingNameSave(onboarding: string) {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-name`, onboarding);
}

export function storageOnboardingNameGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-name`) ?? '';
    return storage;
}

export function storageOnboardingNameRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-name`);
}

//

export function storageOnboardingStepSave(step: number) {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-step`, step.toString());
}

export function storageOnboardingStepGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-step`) ?? '';
    if (storage === '') {
        return 1;
    } else {
        return parseInt(storage);
    }
}

export function storageOnboardingStepRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-step`);
}

//

export function storageOnboardingIntegrationShopifySave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integration-shopify`, true.toString());
}

export function storageOnboardingIntegrationShopifyGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integration-shopify`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateShopifyRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integration-shopify`);
}

//

// Kiwify

export function storageOnboardingIntegrationKiwifySave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integration-kiwify`, true.toString());
}

export function storageOnboardingIntegrationKiwifyGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integration-kiwify`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateKiwifyRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integration-kiwify`);
}

//

export function storageOnboardingIntegrationFacebookAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integration-facebook-ads`, true.toString());
}

export function storageOnboardingIntegrationFacebookAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integration-facebook-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingUtmFacebookAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-utm-facebook-ads`, true.toString());
}

export function storageOnboardingUtmFacebookAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-utm-facebook-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingUtmGoogleAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-utm-google-ads`, true.toString());
}

export function storageOnboardingUtmGoogleAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-utm-google-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingUtmTiktokAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-utm-tiktok-ads`, true.toString());
}

export function storageOnboardingUtmTiktokAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-utm-tiktok-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingUtmTiktokAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-utm-tiktok-ads`);
}

export function storageOnboardingUtmFacebookAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-utm-facebook-ads`);
}

export function storageOnboardingUtmGoogleAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-utm-google-ads`);
}

//

export function storageOnboardingIntegrateFacebookAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integration-facebook-ads`);
}

//

export function storageOnboardingIntegrationTiktokAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integration-tiktok-ads`, true.toString());
}

export function storageOnboardingIntegrationTiktokAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integration-tiktok-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateTiktokAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integration-tiktok-ads`);
}

//

export function storageOnboardingIntegrationGoogleAdsSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integration-google-ads`, true.toString());
}

export function storageOnboardingIntegrationGoogleAdsGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integration-google-ads`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateGoogleAdsRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integration-google-ads`);
}

//


export function storageOnboardingIntegrateAdooreiSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-adoorei`, true.toString());
}

export function storageOnboardingIntegrateAdooreiGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-adoorei`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateAdooreiRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-adoorei`);
}


//

//


export function storageOnboardingIntegrateUnicoPagSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-unicopag`, true.toString());
}

export function storageOnboardingIntegrateUnicoPagGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-unicopag`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateUnicoPagRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-unicopag`);
}

//

//

export function manualCheckoutGeneralLoadingSet(currentCompany: string) {
    localStorage.setItem(`manualCheckoutLoading_${currentCompany}`, true.toString());
}

export function manualCheckoutGeneralLoadingRemove(currentCompany: string) {
    localStorage.removeItem(`manualCheckoutLoading_${currentCompany}`);
}

export function manualCheckoutGeneralLoadingGet(currentCompany: string) {
    const storage = localStorage.getItem(`manualCheckoutLoading_${currentCompany}`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateShopifyCheckoutSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-shopify_checkout`, true.toString());
}

export function storageOnboardingIntegrateShopifyCheckoutGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-shopify_checkout`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateShopifyCheckoutRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-shopify_checkout`);
}


//

export function storageOnboardingIntegrateCartpandaSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-cartpanda`, true.toString());
}

export function storageOnboardingIntegrateCartpandaGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-cartpanda`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateCartpandaRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-cartpanda`);
}

//

export function storageOnboardingIntegrateYampiSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-yampi`, true.toString());
}

export function storageOnboardingIntegrateYampiGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-yampi`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateYampiRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-yampi`);
}

//

export function storageOnboardingIntegrateAppmaxSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-appmax`, true.toString());
}

export function storageOnboardingIntegrateAppmaxGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-appmax`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateAppmaxRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-appmax`);
}

//

export function storageOnboardingIntegrateYeverSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever`, true.toString());
}

export function storageOnboardingIntegrateYeverGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateYeverRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever`);
}

//

// export function storageOnboardingIntegrateDomSave() {
//     localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom`, true.toString());
// }

export function storageOnboardingIntegrateDomGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateDomRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom`);
}

//

export function storageOnboardingCurrentCompanySave(company: string) {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-current-company`, company);
}

export function storageOnboardingCurrentCompanyGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-current-company`) ?? '';
    return storage;
}

export function storageOnboardingCurrentCompanyRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-current-company`);
}

//

export function storageOnboardingIntegrateDomSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom-pagamentos`, true.toString());
}

export function storageOnboardingIntegrateDomPagamentosGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom-pagamentos`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}


export function storageOnboardingIntegrateYeverGatewaySave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever-gateway`, true.toString());
}

export function storageOnboardingIntegrateYeverGatewayGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever-gateway`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateOutrosGatewaySave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-outros-gateway`, true.toString());
}

export function storageOnboardingIntegrateOutrosGatewayGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-outros-gateway`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateMercadoPagoGatewaySave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-mercado-pago-gateway`, true.toString());
}

export function storageOnboardingIntegrateMercadoPagoGatewayGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-mercado-pago-gateway`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateLpFormSave() {
    localStorage.setItem(`${STORE_ONBOARDING_STORAGE}-integrate-lp-form`, true.toString());
}

export function storageOnboardingIntegrateLpFormGet() {
    const storage = localStorage.getItem(`${STORE_ONBOARDING_STORAGE}-integrate-lp-form`) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageOnboardingIntegrateDomPagamentosRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-dom-pagamentos`);
}
export function storageOnboardingIntegrateLpFormRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-lp-form`)
}
export function storageOnboardingIntegrateOutrosGatewayRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-outros-gateway`);
}
export function storageOnboardingIntegrateMercadoPagoGatewayRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-mercado-pago-gateway`);
}
export function storageOnboardingIntegrateYeverGatewayRemove() {
    localStorage.removeItem(`${STORE_ONBOARDING_STORAGE}-integrate-yever-gateway`);
}

export function storageOnboardingRemoveAll(){
    storageOnboardingNameRemove()
    storageOnboardingStepRemove()
    storageOnboardingIntegrateShopifyRemove()
    storageOnboardingIntegrateFacebookAdsRemove()
    storageOnboardingIntegrateTiktokAdsRemove()
    storageOnboardingIntegrateGoogleAdsRemove()
    storageOnboardingIntegrateUnicoPagRemove()
    storageOnboardingIntegrateAdooreiRemove()
    storageOnboardingIntegrateCartpandaRemove()
    storageOnboardingIntegrateShopifyCheckoutRemove()
    storageOnboardingUtmFacebookAdsRemove()
    storageOnboardingUtmGoogleAdsRemove()
    storageOnboardingUtmTiktokAdsRemove()
    storageOnboardingIntegrateYampiRemove()
    storageOnboardingIntegrateAppmaxRemove()
    storageOnboardingIntegrateYeverRemove()
    storageOnboardingIntegrateLpFormRemove()
    storageOnboardingIntegrateMercadoPagoGatewayRemove()
    storageOnboardingIntegrateOutrosGatewayRemove()
    storageOnboardingIntegrateYeverGatewayRemove()
    storageOnboardingCurrentCompanyRemove()
    storageOnboardingIntegrateDomPagamentosRemove()

    storageOnboardingIntegrateUnicoPagRemove()
    storageOnboardingIntegrateAdooreiRemove()
    storageOnboardingIntegrateShopifyCheckoutRemove()
}