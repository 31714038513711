import { TemplateModal } from '../../index';
import * as S from './styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../../components/ButtonApp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useState } from 'react';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { ModalYeverProps, userDTO } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import { useCreateIntegrationYever, useDeleteIntegrationYever } from '../../../../../services/hooks/integrationHooks';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';

export function ModalYever({ setShowPopupYever }: ModalYeverProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/yever%20checkout.png';
    const baseColor = '#ffda57';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();
    const [showPopupYeverGateway, setShowPopupYeverGateway] = useState(false);

    const yeverInformation = user?.companies?.[currentCompany]?.integrations?.yever?.accounts[user?.companies?.[currentCompany]?.integrations?.yever?.accounts?.length - 1];

    const hasYeverInfo = yeverInformation?.account_id !== 'default-account';

    const { showAlert } = useAlert();
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);

    const { mutate: handleCreateIntegrationYever, isLoading: isLoadingCreateIntegration, isError } = useCreateIntegrationYever();
    const { mutate: handleDeleteIntegrationYever, isLoading: isLoadingDeleteIntegration } = useDeleteIntegrationYever();

    const [yeverInfo, setYeverInfo] = useState({
        accessTokenYever: yeverInformation?.account_id  !== 'default-account' ? yeverInformation?.account_id : '',
    });

    
    function handleChangeYeverInfo(e: any) {
        setYeverInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    function handleIntegrate() {
        setShowPopupYeverGateway(true);
        const body = {
            token: yeverInfo.accessTokenYever,
            company_id: currentCompany,
            user: user
        };

        handleCreateIntegrationYever(body, {
            onSuccess: () => {
                setShowPopupYever(false);
                const active = {
                    account_id: yeverInfo.accessTokenYever,
                    assets:{token: yeverInfo.accessTokenYever},
                    last_update: '',
                    status: 'active',
                };
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            integrations: {
                                ...user?.companies?.[currentCompany]?.integrations,
                                yever: {
                                    ...user?.companies?.[currentCompany]?.integrations?.yever,
                                    accounts: [active],
                                },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    function handleClosePopup() {
        if(showPopupYeverGateway){
            setShowPopupYeverGateway(false);}
    }


    function handleDesintegrate() {
        const body = {
            account_id: yeverInfo.accessTokenYever,
            company_id: currentCompany,
            truncate: true,
            user: user
        };

        showAlert('Desconectando Yever... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);        
        const inactive = {
            account_id: 'default-account',
            assets: {},
            last_update: '',
            status: 'inactive',
        };
        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    integrations: {
                        ...user?.companies?.[currentCompany]?.integrations,
                        yever: {
                            ...user?.companies?.[currentCompany]?.integrations?.yever,
                            accounts: [inactive],
                        },
                    },
                },
            },
        };
        setUserContext(newUser as userDTO);
        updateUser();
        setShowPopupYever(false);
        setShowConfirmDisconnectPopup(false);
        handleDeleteIntegrationYever(body, {
            onSuccess: () => {
            },
        });
    }

    return (
        <S.Container onClick={handleClosePopup}>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Yever'}
                title={'Yever'}
                subtitle={'Agência digital especializada em soluções de e-commerce e marketing online.'}
                statusConnection={yeverInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration
                    showPassword={false}
                    value={yeverInfo.accessTokenYever}
                    setValue={handleChangeYeverInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenYever"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se seu <span>token de acesso</span> está correto e tente novamente.</S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>

            <S.DisconnectContainer>
            {hasYeverInfo && (
                        <>
                            Desconectar Integração:{' '}
                            <ButtonApp 
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#F41B19"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowConfirmDisconnectPopup(true)}
                                text="Desconectar"
                            />
                        </>
                    )}
                    {!hasYeverInfo && (
                        <>
                            Salvar configurações:{' '}
                            <ButtonApp
                                border=''
                                width="7.75rem"
                                height="2.25rem"
                                color="#fff"
                                background="#19c380"
                                icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                                onClick={() => setShowPopupYeverGateway(true)}
                                text="Integrar"
                                isLoading={isLoadingCreateIntegration}
                                disabled={yeverInfo?.accessTokenYever?.length === 0}
                            />
                        </>
                    )}
                    <S.ContainerYeverGateway showPopupYeverGateway={showPopupYeverGateway}>
                    <S.YeverGatewayTitle>Importante</S.YeverGatewayTitle>
                    <S.YeverGatewaySubtitle>Não se esqueça de adiconar as taxas na integração do gateway.</S.YeverGatewaySubtitle>
                    <S.ButtonYeverGateway onClick={handleIntegrate}>
                        Prosseguir <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                    </S.ButtonYeverGateway>
                </S.ContainerYeverGateway>
            </S.DisconnectContainer>

            {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDesintegrate}
                title="Confirmação necessária"
                isLoading={isLoadingDeleteIntegration}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )}
            
        </S.Container>
    );
}
