import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    height: 2.5rem;
    position: sticky;
    z-index: 10;
    margin-left: 1rem;
    margin-right: 1rem;
    border-bottom: 1px solid #ddd;
`;

export const DefaultColumn = styled.div`
    font-size: 12px;
    color: black;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; 
    max-width: 40%; 
    overflow: hidden; 
    text-align: center; 
`;


export const DefaultColumnCheck = styled.div`
    font-size: 12px;
    color: black;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; 
    max-width: 20%; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap; 
`;

export const DefaultColumnSync = styled.div`
    font-size: 12px;
    color: #989c97;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Each column takes up equal space */
    max-width: 20%;

    input {
        cursor: not-allowed;
    }
`;
