import { z } from 'zod';

export const createCostSchema = z.object({
    name: z.string(),
    type: z.string(),
    value: z.number(),
    frequency: z.string(),
    company_id: z.string(),
    date: z.string().min(10).max(10),
    status: z.string(),
});
