import styled from 'styled-components';

export const ContainerPopup = styled.div`
    width: 40rem;
    height: auto;
    padding: 1.5rem;
    border-radius: 20px;
    background-color: var(--white);
`;

export const IconPopup = styled.div`
    color: #F41B19;
    font-size: 30px;
    margin-bottom: 1.5rem;
    span {
        color: #19c380;
    }
`;

export const TitlePopup = styled.div`
    color: #1e1e1e;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 0.5rem;

    span {
        color: #19c380;
    }
`;

export const SubtitlePopup = styled.div`
    color: var(--gray, #7c7c7c);
    font-size: 16px;
    width: 102%;
    font-family: Montserrat;
    font-weight: 500;
`;

export const Line = styled.div`
    background-color: #d9dbd9;
    height: 1px;
    margin: 1.5rem 0;
    display: flex;
    flex: 1;
    
`;

export const SubsubtitlePopup = styled.div`
    font-size: 0.7rem;
    line-height: 1.125rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
`;

export const ConfirmDisconnection = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    margin: 0 auto;
    font-size: 13px;
    left: 0;
    color: gray;
    background: none;
    border: none;
    border-radius: 7px;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        color: #0c2216;
    }
`;

export const CancelButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9em;
    width: fit-content;
    font-size: 0.9rem;
    left: 0;
    color: gray;
    background: none;
    border: none;
    border-radius: 7px;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #0c2216;
    }

`;
export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
`;