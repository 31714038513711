import styled from 'styled-components';
import VerifiedIcon from '@mui/icons-material/Verified';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding-top: 1.25rem;
    padding-left: 1rem;
`;

export const GatewayArea = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const Image = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 1.5rem;
        height: 1.625rem;
    }
`;

type LoadingProps = {
    percentage: number;
};

export const Loading = styled.div<LoadingProps>`
    background-color: var(--green);
    height: 9px;
    width: ${({ percentage }) => `${percentage}%`};
    border-radius: 50px;
    transition: width 100s ease-in-out;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Text = styled.div`
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #5d7375;
`;

export const Line = styled.div`
    background-color: var(--gray-145);
    width: 12.5rem;
    height: 9px;
    border-radius: 50px;
`;

export const CustomVerifiedIcon = styled(VerifiedIcon)`
  color: #19c27e; 
  width: 0.7em !important; 
`;

