import styled from 'styled-components';

type ContainerProps = {
    type: 'active' | 'inactive';
};

export const Container = styled.div<ContainerProps>`
    width: 40%;
    min-width: 26rem;
    max-width: 35rem;
    height: 6.25rem;
    border: 1px solid ${(props) => (props.type === 'active' ? 'var(--green)' : 'var(--gray-700)')};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.5rem;
    gap: 0.8rem;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    position: relative;
`;

type ImageProps = {
    source: string;
};

export const Image = styled.div<ImageProps>`
    img {
        ${(props) =>
            props.source === 'yever' || 'dom'
                ? `
        width: 4rem;
        `
                : `
        width: 4rem;
        height: 4rem;
        `}
        margin-right: 0.5rem;
        margin-left: 0.3rem;
        border-radius: 10px;
    }
`;
export const Info = styled.div``;

export const Title = styled.div`
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.8rem;
    color: var(--black);
    svg {
        margin-left: 0.5rem;
    }
`;

export const Subtitle = styled.div`
    font-size: 0.7rem;
    width: 20rem;
    line-height: 0.9rem;
    font-weight: 500;
    color: var(--gray-700);
`;

export const DetailsButton = styled.div`
    color: var(--gray-700);
    border: 1px solid var(--gray-700);
    font-size: 0.625rem;
    font-weight: 550;
    width: 5.625rem;
    height: 1.2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 1rem;
    right: 1rem;
    &:hover {
        cursor: pointer;
    }
`;
