import * as S from './style';
import PaidIcon from '@mui/icons-material/Paid';
import VideoChatRoundedIcon from '@mui/icons-material/VideoChatRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GraphProps, GraphData } from '../../../../@types';
import { getMetrics, getMetricsProducts, getSubMetrics, getMetricsShipping } from '../../utils';
import { useCurrencyType } from './../../../../hooks/useCurrencyType';
import { useState } from 'react';
import GraphModal from '../../../../components/GraphModal'; // Ajuste o caminho conforme necessário
import MarketingCostModal from '../MarketingCostModal';
import Tooltip from '@mui/material/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';


export function CardsDefault({ isLoading, data, taxes, shipping, type, dataSeeMore, orders, setCostOperationDate, costMarketingValue }: GraphProps) {
    const XAxisChartData = dataSeeMore?.approved_revenue_chart?.approved['x_axis'];
    // Se YAxisChartData for usado em um ponto que possa não ter sido atribuído, 
    // você precisa garantir que só seja usado depois da atribuição.
    let YAxisChartData: number[] = []; // Inicializando com um array vazio para evitar o erro
    let YAxisChartDataMkt: any;

    const { currencySymbol } = useCurrencyType();

    let intervalData: number;
    let cardName;
    let IconName = PaidIcon;
    let subMetricData = 0;
    let metricData = 0;
    let redirectButtonTooltip = '';
    let redirectButtonOnClick = () => { };
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showMarketingModal, setShowMarketingModal] = useState<boolean>(false);
    const navigate = useNavigate();

    // Estado para os dados do gráfico
    const [graphData, setGraphData] = useState<GraphData>({
        xAxisData: [],
        yAxisData: [],
        intervalData: 0
    });


    const maxPointsToShow = 13;

    const { totalMarketingCostChart, faceMarketingCostChart, tiktokMarketingCostChart, googleMarketingCostChart, manualMarketingCostChart, taxAndFeesRealChart, gatewayValueChart ,checkoutValueChart, taxSeriesChart } = getMetrics(dataSeeMore, taxes);

    const { subMetricsMarketingCost, subMetricsTaxAndFees, metricTaxAndFees, metricTax, metricCheckoutCost, metricGatewayCost } = getSubMetrics(data, taxes, orders, shipping);

    // //console.log("gateway", metricGatewayCost)

    const { products } = getMetricsProducts(data);

    const { shipping_cost, shipping_revenue } = getMetricsShipping(orders, shipping);

    // //console.log('shipping_cost', shipping_cost);

    if (XAxisChartData?.length > maxPointsToShow) {
        intervalData = 2;
    } else {
        intervalData = 0;
    }

    switch (type) {
        case 'approvedRevenue':
            YAxisChartData = dataSeeMore?.approved_revenue_chart?.approved['y_axis'];
            cardName = 'Receita Aprovada';
            IconName = PaidIcon;
            subMetricData = Number(data?.approved_revenue_sub_metrics?.percentual.approved) * 100;
            metricData = Number(data?.approved_revenue_metric?.approved);
            break;

        case 'marketingCost':
            YAxisChartDataMkt = [
                { name: 'Total', data: totalMarketingCostChart },
                { name: 'Facebook', data: faceMarketingCostChart },
                { name: 'Google', data: googleMarketingCostChart },
                { name: 'TikTok', data: tiktokMarketingCostChart },
                { name: 'Manual', data: manualMarketingCostChart },
            ];
            // YAxisChartData = totalMarketingCostChart;
            cardName = 'Custo Marketing';
            IconName = VideoChatRoundedIcon;
            subMetricData = Number(subMetricsMarketingCost);
            metricData = Number(data?.marketing_cost_metric?.total) + (costMarketingValue !== undefined ? costMarketingValue : 0);
            redirectButtonTooltip = 'Adicionar Custo de Marketing';
            redirectButtonOnClick = () => {
                setShowMarketingModal(true);
            };

            break;

        case 'productsCost':
            YAxisChartData = dataSeeMore?.products_cost_chart['y_axis'];
            cardName = 'Custo Produtos';
            IconName = CategoryRoundedIcon;
            subMetricData = Number(data?.products_cost_sub_metrics?.percentual) * 100;
            metricData = Number(data?.products_cost_metric + shipping_cost);
            redirectButtonTooltip = 'Adicionar Custo Produtos';
            redirectButtonOnClick = () => {
                navigate('/custo');
            };
            break;

        case 'operationCost':
            YAxisChartData = dataSeeMore?.additional_cost_chart['y_axis'];
            cardName = 'Custo Operação';
            IconName = StorefrontRoundedIcon;
            subMetricData = Number(data?.additional_cost_sub_metrics?.percentual);
            metricData = Number(data?.additional_cost_metric);
            redirectButtonTooltip = 'Adicionar Custo Operacional';
            redirectButtonOnClick = () => {
                navigate('/adicionais');
            };
            break;

        case 'taxChart':
            // YAxisChartData = taxAndFeesRealChart;
            YAxisChartDataMkt = [
                { name: 'Total', data: taxAndFeesRealChart},
                { name: 'Gateway', data: gatewayValueChart},
                { name: 'Checkout', data: checkoutValueChart},
                { name: 'Impostos', data: taxSeriesChart},
            ];
            cardName = 'Taxas e Impostos';
            IconName = GavelRoundedIcon;
            subMetricData = Number(subMetricsTaxAndFees);
            metricData = Number(metricTaxAndFees);
            redirectButtonTooltip = 'Adicionar Taxas e Impostos';
            redirectButtonOnClick = () => {
                navigate('/integracao/tax');
            };
            break;
    }

    // const sumAxisY = YAxisChartData?.reduce((acumulador: any, elemento: any) => acumulador + elemento, 0);
    const showUp = subMetricData >= 0;

    // itera por produtos e verifica se algum produto tem custo === 0
    const showWarning = cardName === 'Custo Produtos' && products.some((product) => product.productCost === 0);
    const hasAddButton = (type === 'marketingCost' || type === 'operationCost' || type === 'taxChart')

    const showTax = (cardName === 'Taxas e Impostos') || (cardName === 'Custo Produtos') || (cardName === 'Receita Aprovada') || (cardName === 'Custo Marketing');

    const [tooltipPosition, setTooltipPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });
    const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const cardRect = e.currentTarget.getBoundingClientRect(); // Get the position of CardsDefault
        const offsetX = e.clientX - cardRect.left; // Calculate relative X position
        const offsetY = e.clientY - cardRect.top; // Calculate relative Y position

        let tooltipContent;

        if (cardName === 'Taxas e Impostos') {
            tooltipContent = (
                <S.DataDivisionsArea>
                    <S.DataDivision>
                        <div>
                            <ShoppingCartCheckoutRoundedIcon style={{ fill: 'purple' }} />
                             <p >Checkout:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(metricCheckoutCost)}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                        <PercentRoundedIcon style={{ fill: '#F54F' }} />
                        <p >Gateway:</p>

                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(metricGatewayCost)}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <GavelRoundedIcon style={{ fill: '#2D5557' }} /> <p >Imposto:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(metricTax)}</span>
                    </S.DataDivision>
                </S.DataDivisionsArea>
            );
        } else if (cardName === 'Custo Produtos') {
            tooltipContent = (
                <S.DataDivisionsArea>
                    <S.DataDivision>
                        <div>
                            <InventoryRoundedIcon style={{fill: 'red'}}/> <p style={{ fontSize: '12px' }}>Custo de Produto:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(data?.products_cost_metric)}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <LocalShippingRoundedIcon style={{fill: '#2D5557'}}/>  <p style={{ fontSize: '12px' }}>Frete:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(shipping_cost)}</span>
                    </S.DataDivision>
                </S.DataDivisionsArea>
            );
        } else if (cardName === 'Receita Aprovada') {
            tooltipContent = (
                <S.DataDivisionsArea>
                    <S.DataDivision>
                        <div>
                            <CheckCircleRoundedIcon style={{ fill: 'var(--green)' }} /> <p>Receita de Produto:</p>
                        </div>

                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(data?.approved_revenue_metric?.approved - shipping_revenue)}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <LocalShippingRoundedIcon style={{ fill: '#2D5557' }} /> <p>Receita de Frete:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(shipping_revenue)}</span>
                    </S.DataDivision>
                </S.DataDivisionsArea>
            );
        } else if (cardName === 'Custo Marketing') {
            tooltipContent = (
                <S.DataDivisionsArea>
                    <S.DataDivision>
                        <div>
                            <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Facebook" />
                            <p >Facebook:</p>
                        </div>

                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(Number(data?.marketing_cost_metric?.facebook_ads))}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Google Ads" />
                            <p >Google:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(Number(data?.marketing_cost_metric?.google_ads))}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Tiktok" />
                            <p>Tiktok:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(Number(data?.marketing_cost_metric?.tiktok_ads))}</span>
                    </S.DataDivision>
                    <S.DataDivision>
                        <div>
                            <SettingsRoundedIcon style={{ fill: 'gray' }} /> <p >Custo Manual:</p>
                        </div>
                        <span style={{ fontWeight: '550' }}>{currencySymbol} {formatValue(Number(data?.marketing_cost_metric?.manual_ads))}</span>
                    </S.DataDivision>
                </S.DataDivisionsArea>
            );
        }
        setTooltipContent(tooltipContent);
        setTooltipPosition({ top: offsetY + 10, left: offsetX + 15 });
    };

    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />
            </S.ContainerLoading>
        );
    }

    // Handler para abrir o modal e configurar os dados do gráfico
    function handleOpenModal() {
        setGraphData({
            xAxisData: XAxisChartData,
            yAxisData: (cardName === 'Custo Marketing' || cardName === 'Taxas e Impostos') ? YAxisChartDataMkt : YAxisChartData,
            intervalData: intervalData
        });

        //console.log('graphData', YAxisChartDataMkt);

        setShowModal(true);
    }

    const formatValue = (value: number | undefined): string => {
        if (typeof value !== 'number' || isNaN(value)) {
            return '0,00';
        }
        return value.toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    };

    // //console.log('subMetricData', subMetricData);


    return (
        <S.Container>
            {showModal && (
                <GraphModal
                    title={cardName ? cardName : ''}
                    subTitle='Visão Evolutiva'
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    xAxisData={graphData.xAxisData}
                    yAxisDatasets={[{ name: cardName ? cardName : '', data: graphData.yAxisData }]}
                    intervalData={graphData.intervalData}
                />
            )}

            {showMarketingModal && (
                <MarketingCostModal
                    onClose={() => setShowMarketingModal(false)}
                    setCostOperationDate={setCostOperationDate}
                />
            )}
            <S.Title showTax={showTax} onMouseMove={handleMouseMove} >
                <IconName sx={{ width: '14px', height: '14px' }} /> {cardName}
                {showWarning && <span>
                    <Tooltip title="Atenção! Você teve vendas de produtos que não possuem custo configurado. Clique para configurar." placement="top" componentsProps={{
                        tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '7px', padding: '10px 10px', color: 'white', textWrap: 'wrap', zIndex: 999999 } },
                    }}>
                        <WarningRoundedIcon style={{ cursor: 'pointer' }} onClick={() => { navigate('/custo') }} />
                    </Tooltip>
                </span>}
                {showTax && (
                    <S.Tooltip top={tooltipPosition.top} left={tooltipPosition.left} showTooltip={showTax} className="tooltip">
                        {tooltipContent}
                    </S.Tooltip>
                )}

                {cardName === 'Receita Aprovada' && (

                    <Tooltip title="Receita proveniente da Shopify." placement="top" componentsProps={{
                        tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '7px', padding: '10px 10px', color: 'white', textWrap: 'wrap', zIndex: 999999 } },
                    }}>
                        <S.ShopifyIcon><img src="https://storage.googleapis.com/static-images-source/shopify-icon.svg" alt="shopify" /></S.ShopifyIcon>
                    </Tooltip>
                )
                }

                {(cardName === 'Custo Produtos' || cardName === 'Taxas e Impostos' || cardName === 'Custo Marketing') && (

                    <InfoRoundedIcon sx={{ width: '32px', marginLeft: '0rem', fill: '#c3cece', position: 'absolute', right: '0' }} />
                )
                }
            </S.Title>
            <S.Value>
                <p>{currencySymbol}</p>{' '}
                <span>{formatValue(metricData)}</span>
            </S.Value>
            <S.Percentage showUp={showUp}>
                <span>{showUp ? <TrendingUpIcon sx={{ width: '16px', height: '16px' }} /> : <TrendingDownIcon sx={{ width: '16px', height: '16px' }} />}</span>
                {formatValue(subMetricData)}%
            </S.Percentage>
            <S.Footer>
                <S.Button onClick={handleOpenModal}>
                    VER MAIS <ArrowForwardIcon sx={{ width: '15px', height: '15px' }} />
                </S.Button>
                {hasAddButton && (
                    <S.RedirectButton onClick={redirectButtonOnClick} title={redirectButtonTooltip} >
                        <AddIcon></AddIcon>
                    </S.RedirectButton>
                )}
            </S.Footer>
        </S.Container>
    );
}


function WarningIcon() {
    const navigate = useNavigate();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => navigate('/custo')} style={{ cursor: 'pointer' }}>
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
        </svg>
    )
}