import * as S from './style';
import { SideBarOnboarding } from './Sidebar';
import { useState, useEffect, useRef } from 'react';
import { InputStoreOnboarding } from './InputStoreOnboarding';
import { ButtonApp } from '../../components/ButtonApp';
import { CardOnboardingFlow } from './CardOnboardingFlow';
import useAuth from '../../hooks/useAuth';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { storagePlaceholderCompanyRemove, storageShExternalIdRemove } from '../../storage/storageCompanyToOnboard';
import { useRenameCompanyOnboarding, useGetUrlFacebokAdsAuthOnboarding, useGetUrlFacebokAdsAuthLink, useGetUrlGoogleAdsAuth, useGetUrlTiktokAdsAuth } from '../../services/hooks/integrationHooks';
import useGetData from '../../hooks/useGetData';
import { redirect, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useConfigureUtm } from '../../services/hooks/trackingHooks';
import CustomDropdown from '../../components/CustomSelect';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { SelectCurrencyType } from './SelectCurrency';
import { SelectNichoType } from './SelectNicho';
import { useSubmitCompanyCurrency } from '../../services/hooks/companyCurrencyHooks';
import { useSubmitCompanyNicho } from '../../services/hooks/companyNichoHooks';

import {
    storageOnboardingCurrentCompanyGet,
    storageOnboardingCurrentCompanySave,
    storageOnboardingIntegrateCartpandaGet,
    storageOnboardingIntegrateDomPagamentosGet,
    storageOnboardingIntegrateAppmaxGet,
    storageOnboardingIntegrateLpFormGet,
    storageOnboardingIntegrateYampiGet,
    storageOnboardingIntegrateYeverGet,
    storageOnboardingIntegrationFacebookAdsGet,
    storageOnboardingIntegrationGoogleAdsGet,
    storageOnboardingIntegrationShopifyGet,
    storageOnboardingIntegrationShopifySave,
    storageOnboardingIntegrationTiktokAdsGet,
    storageOnboardingIntegrateYeverGatewayGet,
    storageOnboardingIntegrateOutrosGatewayGet,
    storageOnboardingIntegrateAdooreiRemove,
    storageOnboardingIntegrateUnicoPagRemove,
    storageOnboardingIntegrateShopifyCheckoutRemove,
    storageOnboardingIntegrateMercadoPagoGatewayGet,
    storageOnboardingUtmGoogleAdsSave,
    storageOnboardingUtmGoogleAdsGet,
    storageOnboardingUtmTiktokAdsSave,
    storageOnboardingNameGet,
    storageOnboardingNameRemove,
    storageOnboardingNameSave,
    storageOnboardingStepGet,
    storageOnboardingStepSave,
    storageOnboardingUtmFacebookAdsSave,
    storageOnboardingIntegrationKiwifyGet,
    storageOnboardingIntegrationKiwifySave,
    storageOnboardingIntegrateKiwifyRemove,
} 

from '../../storage/storageOnboarding';
import Modal from '../../components/Modal';
import { ModalShopify } from './Integrations/Shopify';
import { ModalDomPagamentos } from './Integrations/DomPagamentos';
import { ModalLpForm } from './Integrations/LandingPageForm';
import { ModalYever } from './Integrations/Yever';
import { ModalCartpanda } from './Integrations/Cartpanda';
import { ModalManualCheckout} from './Integrations/ManualCheckout';
import { ModalYampi } from './Integrations/Yampi';
import { ModalFacebook } from './Integrations/Facebook';
import { PoupupIntegration } from './Integrations/PopupIntegration';
import { useIntercom } from 'react-use-intercom';
import { trackEvent } from '../../services/segment/sendEvent'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Checkbox from '@mui/material/Checkbox';
import { ModalGenericGateway } from './Integrations/GenericGateway';
import { ModalKiwify } from './Integrations/Kiwify';

export function OnboardingV2() {
    const navigate = useNavigate();
    const { user, token, currentCompany } = useAuth();
    const [activeStep, setActiveStep] = useState(storageOnboardingStepGet());
    const storageName = storageOnboardingNameGet();
    const [storeName, setStoreName] = useState(storageName);
    const [onbCompany, setOnbCompany] = useState('');
    const { data: dataFromGetData, setGetDataIsEnabled } = useGetData();
    const { mutate: renameCompanyOnboarding } = useRenameCompanyOnboarding();

    const integrateFacebook = storageOnboardingIntegrationFacebookAdsGet();
    const integrateGoogle = storageOnboardingIntegrationGoogleAdsGet();
    const integrateTiktok = storageOnboardingIntegrationTiktokAdsGet();
    const [integrateShopify, setIntegrateShopify] = useState(storageOnboardingIntegrationShopifyGet());
    const [integrateCartpanda, setIntegrateCartpanda] = useState(storageOnboardingIntegrateCartpandaGet());
    const [integrateYampi, setIntegrateYampi] = useState(storageOnboardingIntegrateYampiGet());
    const [integrateYever, setInegrateYever] = useState(storageOnboardingIntegrateYeverGet());
    const [integrateAppmax, setIntegrateAppmax] = useState(storageOnboardingIntegrateAppmaxGet());
    const [integrateDomPagamentos, setIntegrateDomPagamentos] = useState(storageOnboardingIntegrateDomPagamentosGet())
    const [integrateYeverGateway, setIntegrateYeverGateway] = useState(storageOnboardingIntegrateYeverGatewayGet())
    const [integrateOutrosGateway, setIntegrateOutrosGateway] = useState(storageOnboardingIntegrateOutrosGatewayGet())
    const [integrateMercadoPagoGateway, setIntegrateMercadoPagoGateway] = useState(storageOnboardingIntegrateMercadoPagoGatewayGet())
    const integrateLpForm = storageOnboardingIntegrateLpFormGet();

    const [integrateKiwify, setIntegrateKiwify] = useState(storageOnboardingIntegrationKiwifyGet());

    const [integrateUtmTiktok, setIntegrateUtmTiktok] = useState(false);
    const [integrateUtmFacebook, setIntegrateUtmFacebook] = useState(false);
    const integrateUtmGoogle = storageOnboardingUtmGoogleAdsGet();

    const [showPopupYampi, setShowPopupYampi] = useState(false);
    const [showPopupYever, setShowPopupYever] = useState(false);
    const [showPopupAppmax, setShowPopupAppmax] = useState(false);
    const [showPopupShopify, setShowPopupShopify] = useState(false);
    const [showPopupFacebook, setShowPopupFacebook] = useState(false);
    const [showPopupCartpanda, setShowPopupCartpanda] = useState(false);
    const [showPopupInfoGoogle, setShowPopupInfoGoogle] = useState(false);
    const [showPopupInfoFacebook, setShowPopupInfoFacebook] = useState(false);
    const [showPopupDomPagamentos, setShowPopupDomPagamentos] = useState(false)
    const [showPopupYeverGateway, setShowPopupYeverGateway] = useState(false);
    const [showPopupOutrosGateway, setShowPopupOutrosGateway] = useState(false);
    const [showPopupMercadoPagoGateway, setShowPopupMercadoPagoGateway] = useState(false);
    const [showPopupAdoorei, setShowPopupAdoorei] = useState(false);
    const [showPopupUnicoPag, setShowPopupUnicoPag] = useState(false);
    const [showPopupShopifyCheckout, setShowPopupShopifyCheckout] = useState(false);
    const [showPopupKiwify, setShowPopupKiwify] = useState(false);

    const [showPopupLpForm, setShowPopupLpForm] = useState(false)

    const { data: linkTiktok } = useGetUrlTiktokAdsAuth(user?.user_id, storageOnboardingCurrentCompanyGet(), 'onboarding');
    const { data: linkFacebookOnboarding } = useGetUrlFacebokAdsAuthOnboarding(user?.user_id, token?.session_token, storageOnboardingCurrentCompanyGet(), 'onboarding');
    const { data: linkFacebookLink } = useGetUrlFacebokAdsAuthLink(user?.user_id, token?.session_token, storageOnboardingCurrentCompanyGet());
    const { data: linkGoogle } = useGetUrlGoogleAdsAuth(user?.user_id, storageOnboardingCurrentCompanyGet(), 'onboarding');
    
    const { mutate: submitCompanyCurrency } = useSubmitCompanyCurrency();
    const [currencySelected, setCurrencySelected] = useState<string>('BRL');
    //console.log('currencySelected', currencySelected)

    const { mutate: submitCompanyNicho } = useSubmitCompanyNicho();
    const [nichoSelected, setNichoSelected] = useState<string>('drop');


    const isDisabled = storeName.length < 1 || currencySelected === '' || nichoSelected === '' || (activeStep === 2 && !integrateShopify && !integrateKiwify) || (activeStep === 3 && !integrateFacebook && !integrateGoogle && !integrateTiktok) || (activeStep === 4 && !integrateYampi && !integrateCartpanda && !integrateYever && !integrateDomPagamentos && !integrateAppmax && !integrateOutrosGateway && !integrateMercadoPagoGateway && !integrateYeverGateway) || (activeStep === 5 && !integrateUtmFacebook && !integrateUtmGoogle && !integrateUtmTiktok || integrateUtmGoogle && !integrateUtmFacebook && !integrateUtmTiktok || integrateUtmTiktok && !integrateUtmFacebook && !integrateUtmGoogle || integrateUtmFacebook && integrateUtmGoogle && !integrateUtmTiktok || integrateUtmFacebook && integrateUtmTiktok && !integrateUtmGoogle || integrateUtmGoogle && integrateUtmTiktok && !integrateUtmFacebook);
    //console.log('isDisabled', isDisabled)
    //console.log('integrateShopify', integrateShopify)
    //console.log('integrateKiwify', integrateKiwify)

    const { boot, showArticle } = useIntercom();

    useEffect(() => {
        if (user?.companies?.[currentCompany]?.company){
            setStoreName(user?.companies?.[currentCompany]?.company);
        }
    }, [user?.companies?.[currentCompany]?.company])

    const [showConfigurePopup, setShowConfigurePopup] = useState(false);
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const [configuringGoogle, setConfiguringGoogle] = useState(false);

    const handleCopy = (type: string) => {
        if (type === 'facebook') return () => navigator.clipboard.writeText('utm_source={{site_source_name}}&utm_medium={{placement}}&utm_campaign={{campaign.id}}&utm_content={{ad.id}}')
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000);
            })
            .catch(err => console.error('Failed to copy:', err));
        if (type === 'google') return () => navigator.clipboard.writeText('{lpurl}?utm_source=google&utm_content={creative}&utm_campaign={campaignid}')
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000);
            })
            .catch(err => console.error('Failed to copy:', err));
        if (type === 'tiktok') return () => navigator.clipboard.writeText('?utm_source=tiktok&utm_medium=tiktok_ads&utm_campaign=_CAMPAIGN_ID&utm_content=CID_')
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000);
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    useEffect(() => {
        trackEvent('start-onboarding', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: "",
            company_name: ""
        }).then((ctx) => {
            //console.log("success");
        }).catch((err) => {
            //console.log(err);
        });
    }, []);

    useEffect(() => {
        boot({
            name: user.name,
            userId: user.user_id,
            email: user.credentials?.email,
            phone: user.phone,
            createdAt: user.created_at
         });
    }, [boot]);

    useEffect(() => {
        // pega o company_id dos argumentos da url ? company_id = 123
        const urlParams = new URLSearchParams(window.location.search);
        const company_id = urlParams.get('company_id');
        const shopify = urlParams.get('shopify');
        if (!storageOnboardingCurrentCompanyGet()) {
            if (company_id) {
                //console.log('tinha company na url', company_id);
                storageOnboardingCurrentCompanySave(company_id);
                setOnbCompany(company_id);

                //console.log('companu', company_id)

                // se vier do oauth shopify, ja deixa a shopify integrada
                if (shopify === 'true') {
                    storageOnboardingIntegrationShopifySave();
                    setIntegrateShopify(true);
                    //console.log('shopify true?')
                }

            } else if (user?.companies[currentCompany]?.integrations.shopify.accounts.length <= 1) {
                //console.log('nao tinha company, mas so tem uma shopify(default)', currentCompany);
                storageOnboardingCurrentCompanySave(currentCompany);
                setOnbCompany(currentCompany);
            } else {
                //console.log('shopify true?')
                redirect('/dashboard');
            }
        }
    }, []);

    useEffect(() => {
        // Verifica se existe um company_id armazenado indicando que o usuário já passou pelo passo 0
        const company_id = storageOnboardingCurrentCompanyGet();

        if (company_id) {
            setGetDataIsEnabled(true);
        }
    }, []);

    useEffect(() => {
        if (storageOnboardingCurrentCompanyGet() === '') {
            storageOnboardingStepSave(1);
            storageOnboardingNameRemove();
        }
    }, [])


    const [openBottomArea, setOpenBottomArea] = useState<string | null>(null);
    const [iconState, setIconState] = useState<{ [key: string]: boolean }>({
        facebook: false,
        google: false,
        tiktok: false,
    });

    const toggleBottomArea = (area: string) => {
        setOpenBottomArea(area === openBottomArea ? null : area);
        const updatedIconState = { ...iconState };
        // Alterna o estado do ícone da plataforma clicada e mantém as outras inalteradas
        updatedIconState[area] = !updatedIconState[area];
        setIconState(updatedIconState);
    };

    function handleNextStep() {
        if (activeStep === 1 && storeName.length < 1) {
            return;
        }

        if (activeStep === 1 && storeName.length >= 1) {
            setActiveStep((prev) => prev + 1);
            storageOnboardingNameSave(storeName);
            storageOnboardingStepSave(activeStep + 1);

            storageOnboardingIntegrateShopifyCheckoutRemove();
            storageOnboardingIntegrateAdooreiRemove();
            storageOnboardingIntegrateUnicoPagRemove();

            const currencyBody = {
                company_id: storageOnboardingCurrentCompanyGet(),
                currency: currencySelected,
            }
            
            localStorage.setItem('currencyStorage', currencySelected);
            submitCompanyCurrency(currencyBody, {
                onSuccess: (data) => {
                    //console.log('currency submitted', data);
                }
            })

            const nichoBody = {
                company_id: storageOnboardingCurrentCompanyGet(),
                nicho: nichoSelected,
            }
            
            localStorage.setItem('nichoStorage', nichoSelected);
            submitCompanyNicho(nichoBody, {
                onSuccess: (data) => {
                    //console.log('nicho submitted', data);
                }
            })

        } else if (activeStep === 2) {
            setActiveStep((prev) => prev + 1);
            storageOnboardingStepSave(activeStep + 1);
        } else if (activeStep === 3) {
            setActiveStep((prev) => prev + 1);
            storageOnboardingStepSave(activeStep + 1);
        } else if (activeStep === 4) {
            setActiveStep((prev) => prev + 1);
            storageOnboardingStepSave(activeStep + 1);
        } else if (activeStep === 5) {
            setActiveStep((prev) => prev + 1);
            storageOnboardingStepSave(activeStep + 1);
            navigate('/loading');
        }
    }


    function handleCloseAllPopups() {
        if (activeStep !== 3) return;
        setShowPopupInfoFacebook(false);
        setShowPopupInfoGoogle(false);
    }

    const mainTexts = [
        'Complete as informações da sua loja',
        'Complete as informações da sua loja',
        'Conecte suas plataformas de venda',
        'Conecte suas plataformas de marketing',
        'Conecte suas plataformas de pagamento',
        'Adicione a UTM da Solomon'];

    const secondaryTexts = [
        'Complete as informações adicionais para configurar sua loja com sucesso.',
        'Complete as informações adicionais para configurar sua loja com sucesso.',
        'Vincule suas contas para unificar suas métricas em um só lugar.',
        'Nessa etapa pedimos para que conecte pelo menos um perfil de alguma plataforma. Posteriormente você poderá adicionar quantas forem necessárias.',
        'Conecte sua plataforma de checkout e configure os custos do seu gateway de pagamento.'];


    function handleOpenPopupLpForm() {
        setShowPopupLpForm(true);
    }

    const { mutate: configureUtm } = useConfigureUtm();
    const [, setShowSuccessPopup] = useState(false);

    const handleUtmConfiguration = () => {
        configureUtm({ company_id: currentCompany, platform: 'google' }, {
            onSuccess: (data) => {
                setShowSuccessPopup(true);
                //console.log(data);
                storageOnboardingUtmGoogleAdsSave();
                setConfiguringGoogle(false);
            }
        });
        setConfiguringGoogle(true);
        setShowConfigurePopup(false);
        triggerRefreshEvent();

        const startTime = new Date().getTime();
        localStorage.setItem('startConfigTime', startTime.toString());

    };

    function triggerRefreshEvent() {
        const event = new Event('triggerRefresh');
        window.dispatchEvent(event);
        //console.log('Evento disparado');
    }

    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setShowConfigurePopup(false);
            }
        }
        if (showConfigurePopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showConfigurePopup]);
true
    const [isSimDisabled, setSimDisabled] = useState(true);
    const [isNaoDisabled, setNaoDisabled] = useState(true);

    const handleNaoClick = () => {
        setSimDisabled(true);
        setNaoDisabled(false)
    };

    const handleSimClick = () => {
        setNaoDisabled(true)
        setSimDisabled(false);
        handleOpenPopupLpForm();
    };


    function handleCheckbox(platform) {
        if (platform === 'facebook') {
            setIntegrateUtmFacebook(!integrateUtmFacebook);
            storageOnboardingUtmFacebookAdsSave();
        } else if (platform === 'google') {
            // setIntegrateUtmFacebook(!integrateUtmGoogle);
            storageOnboardingUtmGoogleAdsSave();
        } else if (platform === 'tiktok') {
            setIntegrateUtmTiktok(!integrateUtmTiktok);
            storageOnboardingUtmTiktokAdsSave();
        }
    }

    const optionsCheckout = [
        { id: 'yampi', label: 'Yampi', image: 'https://www.yampi.com.br/blog/wp-content/uploads/2022/06/cropped-favicon.png', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'cartpanda', label: 'Cartpanda', image: 'https://storage.googleapis.com/static-images-source/cartpanda%20icon.png', imgWidth: '1.15rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'shopify_checkout', label: 'Shopify Checkout', image: 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg', imgWidth: '1rem', imgHeight: '1rem', textColor: 'var(--black)' },
        { id: 'yever', label: 'Yever Checkout', image: 'https://storage.googleapis.com/static-images-source/yever%20checkout.png', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'adoorei', label: 'Adoorei', image: 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'unicopag', label: 'UnicoPag', image: 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png', imgWidth: '1rem', imgHeight: '1rem', textColor: 'var(--black)' },
    ];

    const optionsGateway = [
        { id: 'appmaxGateway', label: 'Appmax', image: 'https://framerusercontent.com/images/ZvKI0Bp6qyMy3u5Ei06pQe9nYA.png', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'yeverGateway', label: 'Yever Gateway', image: 'https://storage.googleapis.com/static-images-source/yever%20icon%202.png', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'dom_pagamentos', label: 'Dom Pagamentos', image: 'https://storage.googleapis.com/static-images-source/dom%20icon.png', imgWidth: '1.2rem', imgHeight: '1.2rem', textColor: 'var(--black)' },
        { id: 'mercadoPagoGateway', label: 'Mercado Pago', image: 'https://storage.googleapis.com/static-images-source/mercadopago%20icon.png', imgWidth: '1.5em', imgHeight: '1rem', textColor: 'var(--black)' },
        { id: 'outrosGateway', label: 'Outro', image: 'https://storage.googleapis.com/static-images-source/gateway-icon.png', imgWidth: '1.4rem', imgHeight: '1.5em', textColor: 'var(--black)' },
    ];

    const popupSettersCheckout = {
        yampi: setShowPopupYampi,
        yever: setShowPopupYever,
        cartpanda: setShowPopupCartpanda,
        adoorei: setShowPopupAdoorei,
        unicopag: setShowPopupUnicoPag,
        shopify_checkout: setShowPopupShopifyCheckout,
    };

    const popupSettersGateway = {
        dom_pagamentos: setShowPopupDomPagamentos,  
        appmaxGateway: setShowPopupAppmax,
        yeverGateway: setShowPopupYeverGateway,
        outrosGateway: setShowPopupOutrosGateway,
        mercadoPagoGateway: setShowPopupMercadoPagoGateway,
    }; 


    return (
        <S.Container onClick={handleCloseAllPopups}>
            <SideBarOnboarding step={activeStep} getData={dataFromGetData} />

            <S.ContainerContent>
                <S.StepContent>
                    <S.MainTitle>
                        {openBottomArea == 'facebook' || openBottomArea == 'google' || openBottomArea == 'tiktok' ? (
                            <img style={{opacity: '0'}} src="" alt="" />
                        ): (
                        <img src="https://storage.googleapis.com/static-images-source/solomonAnimationGif.gif" alt="" />

                        )}
                        <h1>{mainTexts[activeStep]}</h1>
                        <p>{secondaryTexts[activeStep]}</p>

                        {activeStep === 5 && (
                        
                            <p><span style={{fontWeight:'550'}}>Esse passo pode ser feito posteriormente dentro da plataforma</span>, porém é extremamente necessário para que a Solomon consiga rastrear suas vendas com assertividade.</p>
                        )   
                            }
                    </S.MainTitle>

                    {activeStep === 1 && (
                        <S.ChangeContentArea>
                            <InputStoreOnboarding
                                inputId="nameOnboarding"
                                inputType="text"
                                inputName="nome"
                                inputLabel="Nome da loja"
                                width="28.4375rem"
                                // height="2.5rem" 
                                setInputInformation={setStoreName}
                                inputInformation={storeName}
                                required={true}
                                disabled={storageOnboardingNameGet()?.length > 0 || true}
                            />

                            <S.CurrencyArea>
                                <p>Selecione a moeda padrão da sua loja.</p>
                                <SelectCurrencyType onCurrencySelected={(currencySelected) => setCurrencySelected(currencySelected)} />
                            </S.CurrencyArea>

                            {/* <S.CurrencyArea>
                                <p>Selecione o seu estilo de negócio.</p>
                                <SelectNichoType onCurrencySelected={(nichoSelected) => setNichoSelected(nichoSelected)} />
                            </S.CurrencyArea> */}

                            <S.InputArea>
                                <ButtonApp  fontSize='1rem' fontWeight='500' borderRadius='500px' width="11rem" height="2.5rem" color="#fff" background="#18C37F" text="Próximo" border={''} onClick={handleNextStep} />
                            </S.InputArea>

                            
                        </S.ChangeContentArea>
                    )}

                    {activeStep === 2 && (
                        <S.ChangeContentArea>
                            <S.SalesContainer>
                                {nichoSelected =='drop' ? (
                                    <CardOnboardingFlow isIntegrate={integrateShopify} setShowPopup={setShowPopupShopify} type="shopify" />
                                ) : (
                                    <CardOnboardingFlow isIntegrate={integrateKiwify} setShowPopup={setShowPopupKiwify} type="kiwify" />
                                )}
                                
                                <S.QuestionContainer>
                                    <p>Vende por meio de <span style={{ fontWeight: '550' }}>Landing Page</span> além da Shopify?</p> 

                                    {integrateLpForm ? (
                                        <S.ButtonsContainer>
                                            <ButtonApp icon={<AddCircleOutlineRoundedIcon />} fontSize='0.9rem' borderRadius='7px' width="17.25rem" height="2.5rem" background='transparent' color="#19c27e" border="1px solid #18C37F" text="Adicionar outra Landing Page" onClick={handleSimClick} />
                                        </S.ButtonsContainer>
                                    ) : (
                                        <S.ButtonsContainer>
                                            <ButtonApp borderRadius='500px' width="5.25rem" height="1.8rem" background='transparent' color={isSimDisabled ? "#ccc" : "#219b7d"} border={isSimDisabled ? "1px solid #ccc" : "1px solid #219b7d"} text="Sim" onClick={handleSimClick} />
                                            <ButtonApp borderRadius='500px' width="5.25rem" height="1.8rem" background='transparent' color={isNaoDisabled ? "#ccc" : "#b60001"} border={isNaoDisabled ? "1px solid #ccc" : "1px solid #b60001"} text="Não" onClick={handleNaoClick} />
                                        </S.ButtonsContainer>
                                    )
                                    }

                                </S.QuestionContainer>
                            </S.SalesContainer>
                            <ButtonApp disabled={isDisabled} fontSize='1rem' fontWeight='500' borderRadius='500px' width="11rem" height="2.5rem" color="#fff" background="#18C37F" text="Próximo" border={''} onClick={handleNextStep} />
                        </S.ChangeContentArea>
                    )}

                    {activeStep === 3 && (
                        <S.ChangeContentArea>
                            <S.SalesContainer>
                                <S.CardsContainer>
                                    <CardOnboardingFlow isIntegrate={integrateFacebook} showPopup={showPopupInfoFacebook} setShowPopup={setShowPopupInfoFacebook} type="facebook" />
                                    <PoupupIntegration showPopup={showPopupInfoFacebook} urlOnboarding={linkFacebookOnboarding?.data} type="facebook" setShowPopup={setShowPopupFacebook} />

                                    <CardOnboardingFlow showPopup={showPopupInfoGoogle} isIntegrate={integrateGoogle} setShowPopup={setShowPopupInfoGoogle} type="google" />
                                    <PoupupIntegration showPopup={showPopupInfoGoogle} urlOnboarding={linkGoogle?.data} setShowPopup={() => { }} type="google" />

                                    <CardOnboardingFlow url={(linkTiktok?.data as unknown as string) ?? ''} isIntegrate={integrateTiktok} setShowPopup={setShowPopupShopify} type="tiktok" />

                                </S.CardsContainer>
                            </S.SalesContainer>
                            <S.ButtonContainer style={{ marginLeft: '20px' }}>
                                <S.BackButton>

                                </S.BackButton >

                                <ButtonApp disabled={isDisabled} fontSize='1rem' fontWeight='500' borderRadius='500px' width="11rem" height="2.5rem" color="#fff" background="#18C37F" text="Próximo" border={''} onClick={handleNextStep} />

                                <S.NextButton
                                    onClick={() => { setActiveStep((prev) => prev + 1); }}

                                >
                                    <ArrowForwardIcon />
                                    Pular
                                </S.NextButton >

                            </S.ButtonContainer>
                        </S.ChangeContentArea>
                    )}

                    {activeStep === 4 && (

                        <S.ChangeContentArea>
                            <S.PaymentContainer >
                                <S.CardsContainerPayment>
                                    <p>CHECKOUT</p>
                                    <CustomDropdown
                                        options={optionsCheckout}
                                        defaultOptionId='yampi'
                                        onOptionSelected={(optionId) => console.log(':', optionId)}
                                        popupSetters={popupSettersCheckout}
                                    />

                                </S.CardsContainerPayment>
                                <S.CardsContainerPayment>
                                    <p>GATEWAY</p>

                                    <CustomDropdown
                                        options={optionsGateway}
                                        defaultOptionId='appmaxGateway'
                                        onOptionSelected={(optionId) => console.log(':', optionId)}
                                        popupSetters={popupSettersGateway}
                                    />

                                </S.CardsContainerPayment>
                            </S.PaymentContainer>
                            <S.ButtonContainer>
                                <S.BackButton
                                    onClick={() => { setActiveStep((prev) => prev - 1) }}>
                                    <ArrowBackIcon />
                                    Voltar
                                </S.BackButton >

                                <ButtonApp disabled={isDisabled} fontSize='1rem' fontWeight='500' borderRadius='500px' width="11rem" height="2.5rem" color="#fff" background="#18C37F" text="Próximo" border={''} onClick={handleNextStep} />

                                <S.NextButton
                                    onClick={() => { setActiveStep((prev) => prev + 1); }}

                                >
                                    <ArrowForwardIcon />
                                    Pular
                                </S.NextButton >

                            </S.ButtonContainer>
                        </S.ChangeContentArea>
                    )}

                    {activeStep === 5 && (

                        <S.ChangeContentArea style={{ width: '65%' }}>
                            <S.UtmInstallContainer>
                                <S.DropDownUtmContainer isPlatformIntegrated={integrateFacebook} isIntegrated={integrateUtmFacebook} isActive={openBottomArea === 'facebook'}>
                                    <S.TopArea onClick={() => toggleBottomArea('facebook')}>
                                        <S.LeftArea>
                                            <img src="https://storage.googleapis.com/static-images-source/facebook-icon.png" alt="" />
                                            <S.TitleFlex>
                                                <p>Facebook Ads</p>
                                                <span>Adicione suas UTMs</span>
                                            </S.TitleFlex>
                                        </S.LeftArea>

                                        {iconState['facebook'] ? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}

                                    </S.TopArea>
                                    {openBottomArea === 'facebook' && (
                                        <S.BottomArea>
                                            <S.TutorialContainer>
                                                <p>Tutorial: </p><span onClick={() => showArticle(9062698)}>Veja como configurar suas UTMs clicando aqui.</span>
                                            </S.TutorialContainer>
                                            <S.SectionInstructionsAutomatic>
                                                <h5>Copie o código abaixo para configurar manualmente suas UTMs do Facebook Ads:</h5>
                                            </S.SectionInstructionsAutomatic>
                                            <S.SectionInputContainer>
                                                <S.SectionInput>
                                                    <input disabled value='utm_source={{site_source_name}}&utm_medium={{placement}}&utm_campaign={{campaign.id}}&utm_content={{ad.id}}' />
                                                    <ContentCopyIcon onClick={handleCopy('facebook')} />
                                                    {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}
                                                </S.SectionInput>
                                                <ButtonApp borderRadius='7px' fontSize="0.9rem" width="7rem" height="2.5rem" background='transparent' color="#1e1e1e" border="1px solid #1e1e1e" text="Copiar" onClick={handleCopy('facebook')} />
                                            </S.SectionInputContainer>

                                            <S.SectionInstructionsUtmContainer>
                                                <p>Utilizando nossos parâmetros recomendados cada visitante do seu site estará informando nossa tecnologia de rastreamento exatamente qual anúncio eles acabaram de clicar.</p>
                                            </S.SectionInstructionsUtmContainer>

                                            <S.CheckboxSection onClick={() => { handleCheckbox('facebook') }}>
                                                <Checkbox
                                                    checked={integrateUtmFacebook}
                                                    size="small"
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#19c273',
                                                        },
                                                    }}
                                                />
                                                <span>Configurei minhas UTMs!</span>
                                            </S.CheckboxSection>
                                        </S.BottomArea>
                                    )}
                                </S.DropDownUtmContainer>


                                <S.DropDownUtmContainer isPlatformIntegrated={integrateGoogle} isIntegrated={integrateUtmGoogle} isActive={openBottomArea === 'google'}>
                                    <S.TopArea onClick={() => toggleBottomArea('google')}>
                                        <S.LeftArea>
                                            <img src="https://storage.googleapis.com/static-images-source/google-icon.png" alt="" />
                                            <S.TitleFlex>
                                                <p>Google Ads</p>
                                                <span>Adicione suas UTMs</span>
                                            </S.TitleFlex>
                                        </S.LeftArea>
                                        {iconState['google'] ? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
                                    </S.TopArea>
                                    {openBottomArea === 'google' && (
                                        <S.BottomArea>
                                              <S.TutorialContainer>
                                                <p>Tutorial: </p><span onClick={() => showArticle(9062698)}>Veja como configurar suas UTMs clicando aqui.</span>
                                            </S.TutorialContainer>
                                            <S.SectionInstructionsAutomatic>
                                                <S.ContainerPopup showConfigurePopup={showConfigurePopup} ref={popupRef}>
                                                    <S.PopupTitle>Importante</S.PopupTitle>
                                                    <S.PopupSubtitle>Esta configuração será aplicada para todas as contas de anúncios ativas do Google ads.</S.PopupSubtitle>
                                                    <S.ButtonPopup onClick={handleUtmConfiguration}>
                                                        Prosseguir <ArrowForwardSharpIcon style={{ width: '16px', height: '16px', fill: '#19c27e' }} />
                                                    </S.ButtonPopup>
                                                </S.ContainerPopup>

                                                <h5>Você pode adicionar as UTMs do Google Ads automaticamente clicando no botão abaixo:</h5>
                                                <ButtonApp icon={!configuringGoogle ? <SettingsRoundedIcon style={{ fill: 'white' }} /> : <S.RotatingSettingsIcon style={{ cursor: 'not-allowed' }} />} borderRadius='7px' fontSize="0.9rem" fontWeight='500' width="16rem" height="2rem" background='var(--black)' border='' color="var(--white)" text={!configuringGoogle ? "Configurar automaticamente" : "Configurando UTMs..."} disabled={integrateUtmGoogle || configuringGoogle} onClick={() => { if (!configuringGoogle) setShowConfigurePopup(true) }} />
                                                <h5>Ou se preferir, pode configurar manualmente usando o código abaixo:</h5>
                                            </S.SectionInstructionsAutomatic>
                                            <S.SectionInputContainer>
                                                <S.SectionInput>
                                                    <input disabled value='{lpurl}?utm_source=google&utm_content={creative}&utm_campaign={campaignid}' />
                                                    <ContentCopyIcon onClick={handleCopy('google')} />
                                                    {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}
                                                </S.SectionInput>
                                                <ButtonApp borderRadius='7px' fontSize="0.9rem" width="7rem" height="2.5rem" background='transparent' color="#1e1e1e" border="1px solid #1e1e1e" text="Copiar" onClick={handleCopy('google')} />
                                            </S.SectionInputContainer>

                                            <S.SectionInstructionsUtmContainer>
                                                <p>Utilizando nossos parâmetros recomendados cada visitante do seu site estará informando nossa tecnologia de rastreamento exatamente qual anúncio eles acabaram de clicar.</p>
                                            </S.SectionInstructionsUtmContainer>

                                            <S.CheckboxSection onClick={() => { handleCheckbox('google') }}>
                                                <Checkbox
                                                    checked={integrateUtmGoogle}
                                                    size="small"
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#19c273',
                                                        },
                                                    }}
                                                />
                                                <span>Configurei minhas UTMs!</span>
                                            </S.CheckboxSection>
                                        </S.BottomArea>
                                    )}
                                </S.DropDownUtmContainer>


                                <S.DropDownUtmContainer isPlatformIntegrated={integrateTiktok} isIntegrated={integrateUtmTiktok} isActive={openBottomArea === 'tiktok'}>

                                    <S.TopArea onClick={() => toggleBottomArea('tiktok')}>
                                        <S.LeftArea>
                                            <img style={{ width: '1.5rem' }} src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="" />
                                            <S.TitleFlex>
                                                <p>Tiktok Ads</p>
                                                <span>Adicione suas UTMs</span>
                                            </S.TitleFlex>
                                        </S.LeftArea>

                                        {iconState['tiktok'] ? <RemoveCircleOutlineRoundedIcon /> : <AddCircleOutlineRoundedIcon />}
                                    </S.TopArea>
                                    {openBottomArea === 'tiktok' && (
                                        <S.BottomArea>
                                             <S.TutorialContainer>
                                                <p>Tutorial: </p><span onClick={() => showArticle(9062698)}>Veja como configurar suas UTMs clicando aqui.</span>
                                            </S.TutorialContainer>
                                             <S.SectionInstructionsAutomatic>
                                                <h5>Copie o código abaixo para configurar manualmente suas UTMs do Tiktok Ads:</h5>
                                            </S.SectionInstructionsAutomatic>
                                            <S.SectionInputContainer>
                                                <S.SectionInput>
                                                    <input disabled value='?utm_source=tiktok&utm_medium=tiktok_ads&utm_campaign=_CAMPAIGN_ID&utm_content=CID_' />
                                                    <ContentCopyIcon onClick={handleCopy('tiktok')} />
                                                    {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}
                                                </S.SectionInput>
                                                <ButtonApp borderRadius='7px' fontSize="0.9rem" width="7rem" height="2.5rem" background='transparent' color="#1e1e1e" border="1px solid #1e1e1e" text="Copiar" onClick={handleCopy('tiktok')} />
                                            </S.SectionInputContainer>

                                            <S.SectionInstructionsUtmContainer>
                                                <p>Utilizando nossos parâmetros recomendados cada visitante do seu site estará informando nossa tecnologia de rastreamento exatamente qual anúncio eles acabaram de clicar.</p>
                                                {/* <span onClick={() => showArticle(9062698)}>Veja como configurar suas UTMs clicando aqui.</span> */}
                                            </S.SectionInstructionsUtmContainer>
                                            <S.CheckboxSection onClick={() => { handleCheckbox('tiktok') }}>
                                                <Checkbox
                                                    checked={integrateUtmTiktok}
                                                    size="small"
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#19c273',
                                                        },
                                                    }}
                                                />
                                                <span>Configurei minhas UTMs!</span>
                                            </S.CheckboxSection>

                                        </S.BottomArea>

                                    )}
                                </S.DropDownUtmContainer>
                            </S.UtmInstallContainer>

                            <S.ButtonContainer>
                                <S.BackButton
                                    onClick={() => { setActiveStep((prev) => prev - 1) }}>
                                    <ArrowBackIcon />
                                    Voltar
                                </S.BackButton >

                                <ButtonApp disabled={isDisabled} fontSize='1rem' fontWeight='500' borderRadius='50px' width="11rem" height="2.5rem" color="#fff" background="#18C37F" text="Finalizar" border={''} onClick={handleNextStep} />

                                <S.NextButton
                                    onClick={() => { navigate('/loading') }}

                                >
                                    <ArrowForwardIcon />
                                    Pular
                                </S.NextButton >

                            </S.ButtonContainer>

                        </S.ChangeContentArea>
                    )}

                    {activeStep === 6 && (
                        <span></span>
                    )}

                </S.StepContent>

                {showPopupShopify && (
                    <Modal onClose={() => setShowPopupShopify(false)}>
                        <S.ContainerModal>
                            <ModalShopify setShowPopupShopify={setShowPopupShopify} setIntegrateShopify={setIntegrateShopify} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupKiwify && (
                    <Modal onClose={() => setShowPopupKiwify(false)}>
                        <S.ContainerModal>
                            <ModalKiwify setShowPopupKiwify={setShowPopupKiwify} setIntegrateKiwify={setIntegrateKiwify} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupFacebook && (
                    <Modal onClose={() => setShowPopupFacebook(false)}>
                        <S.ContainerModal>
                            <ModalFacebook url={linkFacebookLink?.data} setShowPopupFacebook={setShowPopupFacebook} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupYampi && (
                    <Modal onClose={() => setShowPopupYampi(false)}>
                        <S.ContainerModal>
                            <ModalYampi setIntegrateYampi={setIntegrateYampi} setShowPopupYampi={setShowPopupYampi} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupCartpanda && (
                    <Modal onClose={() => setShowPopupCartpanda(false)}>
                        <S.ContainerModal>
                            <ModalCartpanda setIntegrateCartpanda={setIntegrateCartpanda} setShowPopupCartpanda={setShowPopupCartpanda} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupYever && (
                    <Modal onClose={() => setShowPopupYever(false)}>
                        <S.ContainerModal>
                            <ModalYever setIntegrateYever={setInegrateYever} setShowPopupYever={setShowPopupYever} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupAppmax && (
                    <Modal onClose={() => setShowPopupAppmax(false)}>
                        <S.ContainerModal>
                            <ModalGenericGateway setIntegrate={setIntegrateAppmax} setShowPopup={setShowPopupAppmax} platform='appmaxGateway' />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupDomPagamentos && (
                    <Modal onClose={() => setShowPopupDomPagamentos(false)}>
                        <S.ContainerModal>
                            <ModalDomPagamentos setIntegrateDomPagamentos={setIntegrateDomPagamentos} setShowPopupDomPagamentos={setShowPopupDomPagamentos} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupYeverGateway && (
                    <Modal onClose={() => setShowPopupYeverGateway(false)}>
                        <S.ContainerModal>
                            <ModalGenericGateway setIntegrate={setIntegrateYeverGateway} setShowPopup={setShowPopupYeverGateway} platform='yeverGateway' />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupOutrosGateway && (
                    <Modal onClose={() => setShowPopupOutrosGateway(false)}>
                        <S.ContainerModal>
                            <ModalGenericGateway setIntegrate={setIntegrateOutrosGateway} setShowPopup={setShowPopupOutrosGateway} platform='outrosGateway' />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupMercadoPagoGateway && (
                    <Modal onClose={() => setShowPopupMercadoPagoGateway(false)}>
                        <S.ContainerModal>
                            <ModalGenericGateway setIntegrate={setIntegrateMercadoPagoGateway} setShowPopup={setShowPopupMercadoPagoGateway} platform='mercadoPagoGateway' />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupLpForm && (
                    <Modal onClose={() => setShowPopupLpForm(false)}>
                        <S.ContainerModal>
                            <ModalLpForm setShowPopupLpForm={setShowPopupLpForm} />
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupAdoorei && (
                    <Modal onClose={() => setShowPopupAdoorei(false)}>
                        <S.ContainerModal>
                            <ModalManualCheckout setShowPopup={setShowPopupAdoorei} platform={'Adoorei'}/>
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupUnicoPag && (
                    <Modal onClose={() => setShowPopupUnicoPag(false)}>
                        <S.ContainerModal>
                            <ModalManualCheckout setShowPopup={setShowPopupUnicoPag} platform={'UnicoPag'}/>
                        </S.ContainerModal>
                    </Modal>
                )}

                {showPopupShopifyCheckout && (
                    <Modal onClose={() => setShowPopupShopifyCheckout(false)}>
                        <S.ContainerModal>
                            <ModalManualCheckout setShowPopup={setShowPopupShopifyCheckout} platform={'Shopify Checkout'}/>
                        </S.ContainerModal>
                    </Modal>
                )}
            </S.ContainerContent>
        </S.Container>
    );
}
