import * as S from './style';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

export function SelectSolomon({
    showSelectSolomon,
    setShowSelectSolomon,
    setColumnSolomon,
    columnSolomon,
}: {
    showSelectSolomon: boolean;
    setShowSelectSolomon: (value: any) => void;
    setColumnSolomon: (value: string) => void;
    columnSolomon: string;
}) {
    function handleSelect(value: string) {
        setColumnSolomon(value);
        setShowSelectSolomon(false);
    }

    return (
        <S.Container onClick={() => setShowSelectSolomon((prev: any) => !prev)}>
            <S.Select>
                <S.FlexSelectTwo>
                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" />
                    {' '}
                    {columnSolomon === 'totalSolomon'
                        ? 'Vendas Totais'
                        : columnSolomon === 'approvedSolomon'
                        ? 'Vendas Aprovadas'
                        : columnSolomon === 'pendingSolomon'
                            ? 'Vendas Pendentes'
                            : 'Vendas Canceladas'}
                </S.FlexSelectTwo>
                <ExpandMoreRoundedIcon style={{ transform: showSelectSolomon ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>

                <S.OptionsContainer showSelectSolomon={showSelectSolomon} onClick={(e) => e.stopPropagation()}>
                    <S.Option onClick={() => handleSelect('totalSolomon')}>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" /> Vendas Totais
                    </S.Option>
                    <S.Option onClick={() => handleSelect('approvedSolomon')}>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" /> Vendas Aprovadas
                    </S.Option>
                    <S.Option onClick={() => handleSelect('pendingSolomon')}>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" /> Vendas Pendentes
                    </S.Option>
                    <S.Option onClick={() => handleSelect('cancelledSolomon')}>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" /> Vendas Canceladas
                    </S.Option>
                </S.OptionsContainer>
            </S.Select>
        </S.Container>
    );
}
