import { LineProductsCosts } from './LineProductsCosts';
import * as S from './style';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import { ProductsCostDTO } from '../../../@types';
import { LineProductLoading } from './LineProductLoading';
import { useState, useEffect } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

export function TableProductsCosts({setEditingProductsCost, currentPage, setCurrentPage, isLoading, filtred, dataProductCosts, isEditingPerProduct, editedVariants, setEditedVariants, products}: {setEditingProductsCost: (arg0: boolean) => void, currentPage: number, setCurrentPage: (page: number) => void, isLoading: any, products: any, filtred: string,dataProductCosts: ProductsCostDTO, isEditingPerProduct: boolean, editedVariants: any, setEditedVariants: any}) {
    const filteredProducts = products.filter((product: any) => product?.product_title?.toLowerCase().includes(filtred.toLowerCase()));
    const totalElements = filteredProducts?.length;
    const itemPorPage = 6;
    const totalPages = Math.ceil(totalElements / itemPorPage);
    const startIndex = (currentPage - 1) * itemPorPage;
    const endIndex = Math.min(startIndex + itemPorPage - 1, totalElements - 1);
   
    const currentItems = filteredProducts.slice(startIndex, endIndex + 1);

    const [editingCostsMap, setEditingCostsMap] = useState<{ [key: number]: string[] }>({});
    const [currencyMap, setCurrencyMap] = useState<{[key: number]: string[]}>({});

    useEffect(() => {
        if (!editingCostsMap[totalPages]) {
            setEditingCostsMap(prev => ({
                ...prev,
                [totalPages]: Array(currentItems.length).fill('0,00')
            }));
        }
    }, [currentPage, currentItems.length]);


    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    //console.log('editing costs before', editingCostsMap )

    return (
        <S.Container>
            <S.TableHeader>
                <S.Image></S.Image>
                <S.ProductName>Produto</S.ProductName>
                <S.Price>Preço</S.Price>
                <S.Cost>Custo 
                <Tooltip title="Caso configure os custos deste produto individualmente por variante na aba 'Edição por variante' exibiremos a média dos valores de custo de todas as variantes." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                <InfoRoundedIcon sx={{width: '15px', marginLeft: '8px', fill: '#7b9898'}}/>
                            </Tooltip>
                </S.Cost>
                <S.LastSale>Última venda em:</S.LastSale>
                <S.Historic>Histórico</S.Historic>
            </S.TableHeader>
            <S.Lines>
            {!isLoading && currentItems.length > 0 && (
             currentItems.map((product: any, index: any) => (
                <LineProductsCosts
                    setEditingProductsCost={setEditingProductsCost} 
                    key={index}
                    product={product} 
                    isEditingPerProduct={isEditingPerProduct} 
                    editedVariants={editedVariants} 
                    setEditedVariants={setEditedVariants} 
                    dataProductCosts={dataProductCosts}
                    editingCost={
                        editingCostsMap[currentPage] && editingCostsMap[currentPage][index]
                            ? editingCostsMap[currentPage][index]
                            : '0,00'
                    }
                    setEditingCost={(value) => {
                        setEditingCostsMap((prev) => ({
                            ...prev,
                            [currentPage]: [
                                ...(prev[currentPage] || []).slice(0, index),
                                value,
                                ...(prev[currentPage] || []).slice(index + 1),
                            ],
                        }));
                    }}
                    currency={
                        currencyMap[currentPage] && currencyMap[currentPage][index]
                            ? currencyMap[currentPage][index]
                            : 'BRL'
                    }
                    setCurrency={(value) => {
                        setCurrencyMap((prev) => ({
                            ...prev,
                            [currentPage]: [
                                ...(prev[currentPage] || []).slice(0, index),
                                value,
                                ...(prev[currentPage] || []).slice(index + 1),
                            ],
                        }));
                    }}
                    currentPage={currentPage}
                />
            ))
        )}
        {isLoading && Array.from({ length: 8 }).map((_, index) => <LineProductLoading key={index} />)}

        </S.Lines>

            <S.ContainerPagination>
                <Stack spacing={2}>
                    <Pagination
                        renderItem={(item) => <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />}
                        color="primary"
                        count={totalPages}
                        defaultPage={1}
                        page={currentPage}
                        onChange={handleChange}
                    />
                </Stack>
            </S.ContainerPagination>
        </S.Container>
    );
}
