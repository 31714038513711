import React, { useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import * as S from './style';

interface ModalProps {
    isOpen?: boolean;
    onClose?: () => void;
    children: ReactNode;
}

const CloseableModal: React.FC<ModalProps> = ({ children, onClose }) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget && onClose) onClose();
    };

    useEffect(() => {
        const handleKeyup = (event: any) => {
            if (event.keyCode === 27 && onClose) onClose();
        };

        window.addEventListener('keyup', handleKeyup);
        return () => window.removeEventListener('keyup', handleKeyup);
    }, [onClose]);

    return createPortal(
        <S.ModalWindow onClick={handleClick}>
            <S.ModalOverlay>{children}</S.ModalOverlay>
        </S.ModalWindow>,
        document.body
    );
};

const PlainModal: React.FC<ModalProps> = ({ children }) =>
    createPortal(
        <S.ModalWindow>
            <S.ModalOverlay>{children}</S.ModalOverlay>
        </S.ModalWindow>,
        document.body
    );

const Modal: React.FC<ModalProps> = (props) => {
    if (typeof props.onClose === 'function') return <CloseableModal {...props} />;
    else return <PlainModal {...props} />;
};

export default Modal;
