import styled from 'styled-components';


export const Container = styled.div`
    padding: 1rem 2rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Title = styled.div`
    font-size: 1.625rem;
    color: var(--black);
    line-height: 1.95rem;
    font-weight: 500;
    height: 2rem;
    margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div<{ special?: boolean }>`
    font-size: 1rem;
    line-height: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1rem;
    color: var(--gray-300);

    ${({ special }) => special && `
        font-size: 0.9rem;
    `}
    `;

export const Header = styled.div`
    width: 40rem;
`;

    
export const PlanArea = styled.div<{ special?: boolean }>`
    display: flex;
    padding: 1.5rem;
    width: 50%;
    max-width: 50%;
    align-items: center;
    margin-top: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 7px;
    justify-content: space-between;

    ${({ special }) => special && `
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
`}
`;

export const PlanStatus = styled.div<{ special?: boolean }>`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--green800);
    background-color: var(--green-100);
    padding: 0.2rem 0.5rem;
    font-size: 0.85rem;
    border-radius: 50px;

    ${({ special }) => special && `
        color: var(--red-800);
        background-color: var(--red-100);
        font-size: 1rem;
        margin-bottom: -8px;
        font-weight: 500;
    `}  
`;

export const PlanName = styled.div<{ special?: boolean }>`
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    ${({ special }) => special && `
        margin-top: 0.8rem;
    `}
    `;

export const ActivePlanArea = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 1rem;
    flex-direction: column; 
`;

export const PlanPrice = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

export const PriceValue = styled.div`
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--black);

    span {
        font-size: 0.85rem;
    }
`;

export const PlanSubtitle = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0;
    color: var(--gray-300);
`;


export const PlanDate = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--gray-300);
`;

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

export const ButtonText = styled.div<{ special?: boolean }>`
background-color: transparent;
    border: none;
    border-radius: 312px;
    padding: 9px 16px 9px 16px;
    font-weight: 500;
    margin-left: -14px;
    font-size: 12px;
    color: #bf1f1f;
    cursor: pointer;
    transition: ease-in-out 250ms all;

    &:hover {
        background-color: #bf1f1f;
        color: white;
        transform: translateY(3px);
        transition: ease-in-out 250ms all;
    }

    ${({ special }) => special && `
        color: var(--gray-600);
        margin-top: 1rem;
        font-size: 0.9rem;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0;
        cursor: pointer;

        &:hover {
            color: var(--black-800);
            background-color: transparent;
            transform: translateY(3);
            transition: ease-in-out 250ms all;
        }
    `}
    `;

export const PlanInfoArea = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--gray-200);
`;  

export const VerticalLine = styled.div`
    height: 100px;
    width: 1px;
    background-color: var(--gray-200);
`;

export const ModalContent = styled.div`
background-color: white;
padding: 32px;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;

 h2 {
    font-size: 24px;
    color: #0e1819;
    margin-bottom: 0px;
    font-weight: 500;
 }

 p {
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 32px;
 }

 
`;
