import * as S from './style';
import { LineTableTrackingCampaign } from './LineTableTrackingCampaign';
import { toBrazilianFormat } from '../../../utils';
import { calculateFinalOrders, calculateFinalRevenue, getAccountCampaignData, getTotalLineByCampaign } from '../utils';
import { useState, useMemo } from 'react';
import { getSalesDTO } from '../../../@types';
import { useCheckboxTracking } from '../../../hooks/useCheckboxTracking';
import { useColumnsTracking } from '../../../hooks/useColumnsTracking';
import { useResizableColumn } from '../../../hooks/useResizableColumn';
import React, {useEffect} from 'react';
import { useIntercom } from 'react-use-intercom';
import { useCurrencyType } from '../../../hooks/useCurrencyType';
import ColumnItem from '../ColumnItem';
import TotalRowComponent from '../TotalRowComponent';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import { useSorting } from '../../../contexts/SortingContext';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export function TableTrackingCampaign({
    channelSelect,
    paymentMethod,
    type,
    accountId,
    columnSolomon,
    handleSelectGraphicData,
    handleScrolltoGraphic,
    dataMetrics,
    filtred,
    openSalesModal,
    date,
    otherSource,
    filteredBySelect,
    dataCatalogList,
    loadingCatalog
}: {
    filteredBySelect?: any;
    handleSelectGraphicData: any;
    paymentMethod: any;
    handleScrolltoGraphic: () => void;
    channelSelect: string;
    type: string;
    accountId: string;
    columnSolomon: string;
    dataMetrics: any;
    filtred: string;
    openSalesModal: (value: getSalesDTO, metrics: any, source: string, ordersSolomon?: any, revenueSolomon?: any, catalogCampaign?: boolean) => void;
    date: string[];
    otherSource?: string;
    dataCatalogList: any;
    loadingCatalog: any;
}) {

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);
    const { typeFiltred, setTypeFiltred, isStatusAsc, setIsStatusAsc } = useSorting();

    // console.log('filtered by', filteredBySelect)

    useEffect(() => {
        if (paymentMethod) {
    
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect

    const {currencySymbol} = useCurrencyType();

    const { showArticle } = useIntercom();

    const { columnWidths, startResizing } = useResizableColumn();

    const { selectedPlatforms, selectedPlatformsOthers } = useCheckboxTracking();

    const { columns } = useColumnsTracking();

    // Preparar um array das plataformas selecionadas para passar para a função
    const activePlatforms = Object.entries(selectedPlatforms)
        .filter(([_, isActive]) => isActive)
        .map(([platform]) => platform);

    const activePlatformsOthers = Object.entries(selectedPlatformsOthers)
        .filter(([_, isActive]) => isActive)
        .map(([platform]) => platform);

    const filtredAccountCampaignData = useMemo(() => getAccountCampaignData(dataMetrics, type, accountId, channelSelect, activePlatforms, activePlatformsOthers, dataCatalogList), [dataMetrics, type, accountId, channelSelect, activePlatforms, activePlatformsOthers, dataCatalogList]);

    // const [typeFiltred, setTypeFiltred] = useState('spendBySource');
    const [filterDec, setFilterDec] = useState(true);


    const newFiltredAccountCampaignData = useMemo(() => {
        const filteredData = filtredAccountCampaignData
            ?.filter((campaign: any) => 
                campaign?.campaignName?.toLowerCase().includes(filtred.toLowerCase()) &&
                (filteredBySelect.length > 0 ? filteredBySelect.includes(campaign?.campaignId) : true)
            );
    
        // Ordenar pelo status
        if (typeFiltred === 'status') {
            filteredData.sort((a: any, b: any) => {
                if (isStatusAsc) {
                    return a?.campaignStatus === 'ACTIVE' ? -1 : 1;
                } else {
                    return a?.campaignStatus === 'ACTIVE' ? 1 : -1;
                }
            });
        } else {
            filteredData.sort((a: any, b: any) => {
                if (filterDec) {
                    return b?.[typeFiltred] - a?.[typeFiltred];
                } else {
                    return a?.[typeFiltred] - b?.[typeFiltred];
                }
            });
        }
    
        return filteredData;
    }, [filtredAccountCampaignData, filtred, filteredBySelect, typeFiltred, filterDec, isStatusAsc]);
    
    const totalLine = useMemo(() => getTotalLineByCampaign(newFiltredAccountCampaignData, type), [newFiltredAccountCampaignData, type]);

    const revenueSolomon = calculateFinalRevenue(columnSolomon, totalLine, activePaymentMethods);
    const ordersSolomon = calculateFinalOrders(columnSolomon, totalLine, activePaymentMethods);

    function getUniquePlatforms(newFiltredAccountCampaignData) {
    
        const uniquePlatforms = new Set();
        
        // Supondo que 'newFiltredAccountCampaignData' seja sua lista de campanhas
        newFiltredAccountCampaignData.forEach(campaign => {
            if (campaign && campaign.sourceType) {
                uniquePlatforms.add(campaign?.sourceType); // Adiciona a chave normalizada ao Set
            }
        });
        const uniquePlatformsArray = Array.from(uniquePlatforms);

        return uniquePlatformsArray;
    }
    const uniquePlatformsArray = getUniquePlatforms(newFiltredAccountCampaignData);

    console.log('campanhas', newFiltredAccountCampaignData)

    return (
        <S.Container>
            <S.TableHeader>
                <S.MainColumn style={{ width: `${columnWidths}px` }}>
                    <S.CheckboxColumn></S.CheckboxColumn>
                <S.StatusColumn onClick={() => {
                    setTypeFiltred('status');
                    setIsStatusAsc(!isStatusAsc);
                }}>
                    {typeFiltred === 'status' && (
                        isStatusAsc ? (
                            <ArrowUpwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        ) : (
                            <ArrowDownwardIcon sx={{ cursor: 'pointer', width: '14px', marginLeft: '4px', fill: '#7b9898' }} />
                        )
                    )}
                    <>
                    <Tooltip title="Recomendamos que priorize atualizar o status pela Solomon para uma otimização mais precisa. Caso a campanha seja ativada/desativada pelo gerenciador do Facebook, precisaremos de alguns minutos para atualizá-la na Solomon." placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '10px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } } }}>
                    <span>Status</span>
                    </Tooltip>
                    </>
                </S.StatusColumn>

               
                    <p>Nome da campanha</p>
                    <S.DragDiv
                        style={{
                            cursor: 'col-resize',
                            backgroundColor: '#ccc',
                            width: '7px',
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}
                        onMouseDown={startResizing}
                    />
                </S.MainColumn>

                {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
                    <React.Fragment key={column.id}>
                        <ColumnItem column={column} setFilterDec={setFilterDec} setTypeFiltred={setTypeFiltred} typeFiltred={typeFiltred} filterDec={filterDec} showArticle={showArticle} columnSolomon={columnSolomon} uniquePlatformsArray={uniquePlatformsArray}/>
                    </React.Fragment>
                ))}


            </S.TableHeader>

            <S.Lines>
                {channelSelect.length > 0 &&
                    newFiltredAccountCampaignData?.length > 0 &&
                    newFiltredAccountCampaignData?.map((campaign: any) => {
                        return (
                            <LineTableTrackingCampaign
                                handleSelectGraphicData={handleSelectGraphicData}
                                handleScrolltoGraphic={handleScrolltoGraphic}
                                columnSolomon={columnSolomon}
                                dataMetrics={dataMetrics}
                                key={campaign.campaignId}
                                paymentMethod={paymentMethod}
                                openSalesModal={openSalesModal}
                                date={date}
                                campaign={campaign}
                                columnWidths={columnWidths} // Adicionar isso aqui
                                type={type === 'allSources' ? [type, campaign.sourceType, campaign.campaignSourceIcon] : type}
                                otherSource={otherSource}
                                dataCatalogList={dataCatalogList}
                                loadingCatalog={loadingCatalog}
                            />
                        );
                    })}
            </S.Lines>

            <TotalRowComponent columnWidths={columnWidths} name={'Campanhas'} quantity={channelSelect.length > 0 && type !== 'tiktok' ? newFiltredAccountCampaignData?.length : '0'} columns={columns} currencySymbol={currencySymbol}  validator={channelSelect.length} totalLine={totalLine} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon}/>
        </S.Container>
    );
}
