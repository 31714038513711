import styled from 'styled-components';

type ContainerProps = {
    width?: string;
};

export const Container = styled.div<ContainerProps>`
    width: ${(props) => (props.width ? props.width : '100%')};
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 4.4rem;

    label {
        font-size: 0.875rem;
        font-family: inherit;
        font-weight: 600;
        line-height: 1.05rem;
        color: var(--gray-600);
    }

    input {
        color: var(--gray-600);
        padding: 0.8125rem;
        height: 1.5rem;
        border: 1px solid var(--gray-150);
        color: var(--black);
        font-weight: 525;
        border-radius: 5px;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    input:disabled {
        cursor: not-allowed;
        background-color: transparent;
    }
`;
