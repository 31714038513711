import React, { useEffect, useState } from 'react';
import useAlert from '../../../hooks/useAlert';
import * as S from './style';
// Definição de tipo para as props
type TutorialCheckboxProps = {
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const TutorialCheckbox: React.FC<TutorialCheckboxProps> = ({ isChecked, setIsChecked }) => {    
    const { showAlert } = useAlert();
    
    const handleCheckboxChange = () => {
        const newCheckedStatus = !isChecked;
        setIsChecked(newCheckedStatus);
        const today = new Date().toISOString().slice(0, 10);
        if (newCheckedStatus){
            localStorage.setItem('completionDate', today);
            showAlert('Tutorial concluído!', 'success', 5000, true)
        } else {
            localStorage.removeItem('completionDate');
        }
    };
  

    return (
        <S.CheckboxContainer>           
            <S.StyledCheckbox
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}

            />
        <S.CheckboxLabel>Completei o tutorial</S.CheckboxLabel>
        </S.CheckboxContainer>
    );
};

export default TutorialCheckbox;
