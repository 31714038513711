import styled from 'styled-components';

type Props = {
    hasPayment: boolean;
    isMobile: boolean;

}

type MobileProps = {
    isMobile: boolean;
}

export const Container = styled.div<Props>`
    height: 3rem;
    width: 100vw;
    background-color: ${props => props.isMobile ? 'var(--gray-112)' : 'white'};
    padding: ${props => props.isMobile ? '0.5rem' : '0'};
    border-bottom: 1px solid var(--gray-140);
    display: flex;
    justify-content: space-between;
    ${props => props.hasPayment ? `` : `
    position: fixed;
    top: 0rem;
    `}
`;

export const Refresh = styled.div`
    margin-left: 4rem;
`

    export const LogoContainer = styled.div<{isMobile: boolean}>`
    img {
        width: ${props => props.isMobile ? '1.5rem' : '7.5rem'};
    }
    height: 100%;
    width: 10rem;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    transition: all ease-in-out 0.2s;

        &:hover {
            transform: translateX(0.3rem);
            cursor: pointer;
            transition: all ease-in-out 0.2s;
        }
    `;

export const User = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 3rem;
    width: 100%;

    > *:not(:last-child) {
        margin-right: 0.8rem;
    }

    @media (max-width: 1050px) {
        margin-right: 1rem;
        > *:not(:last-child) {
            margin-right: 0;
        }
    }

    > svg:hover {
        color: var(--gray-800);
        cursor: pointer;
    }
`;

export const UserContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.3rem;
    width: 11rem;
    border-radius: 10px;
    height: 2rem;
    position: relative;
    transition: all ease-in-out 0.2s;
    &:hover {
        cursor: pointer;
        background-color: var(--gray-140);
        transition: all ease-in-out 0.2s;
    }
`;

export const UserIcon = styled.div`
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
`;

type UserNameProps = {
    showUserName: boolean;
};

export const UserName = styled.div<UserNameProps>`
    font-size: 0.8125rem;
    font-weight: 600;
    color: var(--green900);
    word-spacing: 0;

    ${(props) =>
        props.showUserName
            ? `
            `
            : `
            filter: blur(3px);
    `}
`;

export const Stepper = styled.div`
    display: flex;
    flex: 1;
    max-width: 80rem;
    height: 100%;
    cursor: pointer;
`;

export const Information = styled.div`
    z-index: 5;
    width: 32rem;
    height: 100%;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > svg:hover {
        color: var(--gray-800);
        cursor: pointer;
    }
`;

export const AlertsContainer = styled.div<MobileProps>`
    display: ${props => props.isMobile ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 0rem;
    > svg:hover {
        color: var(--gray-800);
        cursor: pointer;
    }
`;


export const UserContainerMobile = styled.div`
  display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.4rem 1rem;
    width: 12rem;
    border-radius: 10px;
    height: 2rem;
    position: relative;
    &:hover {
        cursor: pointer;
        background-color: var(--gray-140);
    }
`;

export const FlexUser = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
`

export const RefreshMobile = styled.div`
    margin-left: 0;
    height: 21px;
`


export const MobileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100vw;

    @media (max-width: 1050px) {
        display: flex;
        // padding: 1rem 2rem 1rem 0;
        background-color: white;
        align-items: center;
        justify-content: space-between;
        width: 95vw;
        border-radius: 50px;
    }
`;
