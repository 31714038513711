import styled from 'styled-components';



export const DefaultColumn = styled.div`
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;

export const MetricsButton = styled.div<{loadingCatalog?: boolean, HasCatalog?: boolean}>`
    width: ${props => props.HasCatalog ? '1.7rem' : '1.6875rem'}; ;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: ${props => props.HasCatalog ? 'var(--green)' : 'var(--white)'};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 0.5rem;
    color: white;
    font-size: 0.7rem;
    font-weight: 550;
    gap: 0.3rem;
    cursor: ${props => props.loadingCatalog ? 'not-allowed' : 'pointer'};
    // pointer-events: ${props => props.loadingCatalog ? 'none' : 'auto'};
    
    &:hover {
        outline: none;
        border-color:${props => props.loadingCatalog ? 'none' : 'rgba(76, 234, 134, 0.4)'};
        box-shadow: :${props => props.loadingCatalog ? 'none' : '0 0 0 2px rgba(76, 234, 152, 0.1)'} ;
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: ${props => props.HasCatalog ? 'var(--white)' : 'var(--green)'};
    }
`
