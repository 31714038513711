import { submitManualCheckoutDTO } from './../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getManualCheckout(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/manual-checkouts?company_id=${company_id}`);
    return data;
}

export async function submitManualCheckout(body: DTO.submitManualCheckoutDTO): Promise<AxiosResponse> {
    try {
        console.log('Iniciando a chamada API');
        const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/manual-checkouts`, body);
        console.log('Resposta da API:', data);
        return data;
    } catch (error) {
        console.error('Erro na chamada API:', error);
        throw error;
    }
}


export async function deleteManualCheckout(body: DTO.deleteManualCheckoutDTO): Promise<AxiosResponse> {
    try {
        const { company_id, checkout } = body;

        const newBody = { company_id, checkout };
        
        const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/io-handlers/manual/manual-checkouts`, {data: newBody});
        
        return data;
    } catch (error) {

        throw error;
    }
}