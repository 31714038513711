import styled from 'styled-components';

type ContainerProps = {
    background: string;
    border: string;
    height: string;
    width: string;
    minWidth?: string;
};

function getWidthString(value: string) {
    if (!value) return;

    const width = value.replace('rem', '');
    const widthNumber = Number(width);
    return widthNumber + 3 + 'rem';
}

export const ClearIcon = styled.div`
    cursor: pointer;
    svg {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 49%;
        right: 1rem;
        transform: translate(-50%, -50%);
    }
`;

export const Container = styled.div<ContainerProps>`
    background-color: ${(props) => props.background ? props.background : 'var(--gray-130)'};
    width: ${(props) => getWidthString(props.width)};
    min-width: ${(props) => getWidthString(props.minWidth)};
    height: ${(props) => props.height};
    border: ${(props) => props.border ? props.border : '1px solid var(--gray-120)'};
    border-radius: 16px;
    position: relative;

    svg {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 49%;
        left: 1rem;
        transform: translate(-50%, -50%);
    }

    input {
        width: calc(100% - 3rem);
        height: 100%;
        border: 1px solid red;
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: 16px;
        padding-left: 2rem;
        margin-top: 0px;
        padding-right: 1rem;
        font-size: 0.8rem;
        line-height: 1.75rem;
        color: var(--black);
        font-weight: 550;

        &::placeholder {
            font-size: 0.8rem;
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
        }
    }
`;

export const Input = styled.input``;
