import styled from 'styled-components';
import seta from './../../../../assets/images/seta.png';

export const Container = styled.div`
    padding: 1rem;
`;

export const SelectArea = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InnerContainer = styled.div`
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--gray-800);
        margin-left: 0.3rem;
    }
    select {
        height: 2.5rem;
        width: 13rem;
        padding: 0 1rem;
        border-radius: 8px;
        // margin-left: 0.8rem;
        // margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background: url(${seta}) no-repeat center right #fff;
        background-color: #fff;
        color: var(--black);

        font-weight: 600;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
            background-size: 1rem;
        background-position: 11rem 0.75rem;

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            /* border: 1px solid var(--green); */
            /* outline: none; */
            /* border-color: rgba(76, 234, 134, 0.4); */
            /* background-color: #fff; */
            /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
            /* border: 1px solid var(--green); */
        }
    }
`;

export const CheckboxSection = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    height: 5rem;
    width: 100%;
    // margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 2rem auto;
    cursor: pointer;
`;

export const ContentPaymentInformation = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem
`;

export const TypePaymentInformation = styled.div`
    // border: 1px solid #ccc;
    width: 13.75rem;
    height: 5rem;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const TypePaymentInformation2 = styled.div`
    // border: 1px solid #ccc;
    width: 20rem;
    height: 3rem;
    border-radius: 30px;
    align-items: center;
    justify-content: flex-start;
`;

export const ErrorContainer = styled.div`
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
    margin-top: 1rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0;
    span {
        font-weight: 550;
    }
`;


export const HeaderTYpePaymentInformation = styled.div`
    display: flex;
    align-items: center;
    min-width: 7rem;
    font-size: 1rem;
    font-weight: 600;
    color: #1e1e1e;
    fon
    border-radius: 32px;
    background-color: #f7f7f7;
    padding-left: 1rem;
    svg {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
        background-color: white; 
        border-radius: 50%; 
        padding: 0.5rem; 
        box-sizing: border-box; 
    }
`;
export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;

export const Line = styled.div`
    width: 99%;
    height: 1px;
    background-color: #ccc;
    margin: 0 auto;
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 2rem;
    background-color: #e7e7e7;
    margin: auto 1.5rem;
`;


export const ContentTypePaymentInformation = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 4rem;
    margin-left: 8rem;

    div {
        margin-bottom: 0.5rem; // Espaçamento entre os campos
        margin-top: 0.5rem; // Espaçamento entre os campos
    }

    label {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--gray-700);
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: 500;
        background-color: white;
        width: 5rem;
        height: 2rem;
        border-radius: 8px;
        padding: 0rem 1rem; // Ajustar o padding interno
        transition: all ease-in-out 250ms;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus,
        &:hover {
            background-color: #f5f5f5;
            outline: none;
            transition: all ease-in-out 250ms;
        }
    }
`;

export const ContentTypePaymentInformation2 = styled.div`
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    label {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--gray-700);
        margin-left: 1.3rem;
    }
    input {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: 500;
        margin-left: 1rem;
        background-color: white;
        /* border: 1px solid #a2a2a2; */
        width: 8rem;
        height: 1.8rem;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 0.8rem;
        padding: 0.3rem;
        transition: all ease-in-out 250ms;

        &:focus,
        &:hover {
            background-color: #f5f5f5;
            outline: none;
            transition: all ease-in-out 250ms;
        }
    }
`;

export const ButtonContentTypePaymentInformation = styled.div`
    display: flex;  
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--black);
    height: 1rem;
    padding-left: 14rem;
    transition: all ease-in-out 250ms;
`;

export const ContainerPopup = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

export const ContainerPopup2 = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
`;

export const LineContainer = styled.div`
    border-radius: 8px;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7f7;
    margin-left: 1rem;
    height: 4rem;
`;

export const ContainerInformation = styled.div`
    display: flex;
    flex-direction: column;
    div {
        margin-bottom: 0.5rem; // Espaçamento entre os campos
        margin-top: 0.5rem; // Espaçamento entre os campos
    }
`;


export const HeaderAbsorve = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: #19c273;
    font-weight: 500;
    margin-left: 1rem;
    background-color: white;
    border: 1px solid #19c273;
    height: 1.5rem;
    border-radius: 15px;
    transition: all ease-in-out 250ms;
    gap: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative; 

    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }

    .tooltip {
        visibility: hidden;
        width: 20rem;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%; 
        left: 54%;
        margin-left: -100px; 
        opacity: 0;
        transition: opacity 0.3s;
        font-weight: 400;
        
        &::after {
            content: '';
            position: absolute;
            top: 100%; 
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }

    svg {
        &:hover {
            color: #000;
        }
    }
`;

export const HeaderAbsorve2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: #19c273;
    font-weight: 500;
    margin-left: 13rem;
    background-color: white;
    border: 1px solid #19c273;
    width: 10rem;
    height: 1.2rem;
    border-radius: 15px;
    transition: all ease-in-out 250ms;
`;

export const NumberAbsorve = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 0.7rem;
    margin-right: 3rem;
    margin-left: 8rem;
    align-items: center;
    justify-content: flex-start;
    select {
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        font-size: 1.2rem;
        font-weight: 600;
        display: flex;
        width: 4rem;
        border-radius: 8px;
    }
`;

export const ScrollableContainer = styled.div`
    height: 10rem; 
    overflow-y: auto;
    margin-left: 1rem; // ajuste conforme necessário para alinhamento
    width: 90%; 
    padding: 1rem;
    border-radius: 8px;
`;

export const HeaderScrollableContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    margin-left: 5.5rem;
    margin-right: 5rem;
    align-items: center;
    justify-content: space-between;

    div {
    color: #666;}
`;


export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    div {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: 550;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--black);
        background-color: #f5f5f5;
        width: 5rem;
        height: 2rem;
        border-radius: 8px;
        padding: 0rem 1rem; // Ajustar o padding interno
        transition: all ease-in-out 250ms;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus,
        &:hover {
            background-color: #f5f5f5;
            outline: none;
            transition: all ease-in-out 250ms;
        }
    }
`;