import Tooltip from '@mui/material/Tooltip';
import * as S from './style';
import { platformIcons } from '../utils';

const columnConfig = {
    'spend': { id: 'spend', label: 'Gasto', filterType: 'spendBySource' },
    'profit': { id: 'profit', label: 'Lucro', filterType: 'profitByUtmApproved'},
    'productCostUtm': { id: 'productCostUtm', label: 'Custo de produto', filterType: 'productCostByUtmApproved'},
    'margin': { id: 'margin', label: 'Margem', filterType: 'marginSolomon'},
    'sales': { id: 'sales', label: 'Vendas', filterType: 'ordersBySource' },
    'salesUtm': { id: 'salesUtm', label: 'Vendas', filterType: 'ordersSolomon' },
    'revenue': { id: 'revenue', label: 'Receita', filterType: 'revenueBySource' },
    'revenueUtm': { id: 'revenueUtm', label: 'Receita', filterType: 'revenueSolomon' },
    'roas': { id: 'roas', label: 'ROAS', filterType: 'roasBySource' },
    'roasUtm': { id: 'roasUtm', label: 'ROAS', filterType: 'roasSolomon' },
    'cpa': { id: 'cpa', label: 'CPA', filterType: 'cpaBySource' },
    'cpaUtm': { id: 'cpaUtm', label: 'CPA', filterType: 'cpaSolomon' },
    'cpc': { id: 'cpc', label: 'CPC', filterType: 'cpcBySource' },
    'cpm': { id: 'cpm', label: 'CPM', filterType: 'cpmBySource' },
    'clicks': { id: 'clicks', label: 'Clicks', filterType: 'clicksBySource' },
    'impressions': { id: 'impressions', label: 'Impressões', filterType: 'impressionsBySource' },
    'addToCart': { id: 'addToCart', label: 'Adicionou ao carrinho', filterType: 'addToCartsBySource' },
    'viewContent': { id: 'viewContent', label: 'Viu Conteúdo', filterType: 'pageViewsBySource' },
    'initiateCheckout': { id: 'initiateCheckout', label: 'Iniciou Checkout', filterType: 'checkoutsBySource' },
    'ticket': { id: 'ticket', label: 'Ticket Médio', filterType: 'ticketBySource' },
    'ticketUtm': { id: 'ticketUtm', label: 'Ticket Médio', filterType: 'ticketSolomon' },
};

// ColumnItem.jsx
const ColumnItem = ({ column,  typeFiltred, filterDec, showArticle, columnSolomon, setFilterDec, setTypeFiltred, uniquePlatformsArray }) => {


    let platformInfo = null;
    if (uniquePlatformsArray.length === 1) {
        const uniquePlatformKey: any = uniquePlatformsArray?.[0]?.split('_')?.[0];        
        platformInfo = platformIcons[uniquePlatformKey] || {
            platformName: 'Desconhecido',
            platformImg: 'URL para ícone padrão se desconhecido'
        };
    } else {
        platformInfo = {
            platformName: '',
            platformImg: ''
        };
    }
    const platformImages = [];
    uniquePlatformsArray.forEach(platform => {
        const normalizedPlatform: any = platform?.split('_')?.[0]; // Normalizar o nome da chave
        const platformInfo = platformIcons[normalizedPlatform];
        if (platformInfo) {
            platformImages.push({ imgSrc: platformInfo.platformImg, name: platformInfo.platformName });
        } else {
            // Adiciona um placeholder se a informação da plataforma não for encontrada
            platformImages.push({ imgSrc: 'URL para ícone padrão se desconhecido', name: 'Desconhecido' });
        }
    });

    function handleSelectTypeFiltred(type: string) {
        let newType = '';

        if (type === 'ordersSolomon') {
            newType =
                columnSolomon === 'totalSolomon' ? 'totalOrders' : columnSolomon === 'approvedSolomon' ? 'approvedOrders' : columnSolomon === 'pendingSolomon' ? 'pendingOrders' : 'cancelledOrders';
        } else if (type === 'revenueSolomon') {
            newType =
                columnSolomon === 'totalSolomon'
                    ? 'revenueTotal'
                    : columnSolomon === 'approvedSolomon'
                        ? 'revenueApproved'
                        : columnSolomon === 'pendingSolomon'
                            ? 'revenuePending'
                            : 'revenueCancelled';
        } else if (type === 'roasSolomon') {
            newType = columnSolomon === 'totalSolomon' ? 'roasTotal' : columnSolomon === 'approvedSolomon' ? 'roasApproved' : columnSolomon === 'pendingSolomon' ? 'roasPending' : 'roasCancelled';
        } else if (type === 'cpaSolomon') {
            newType = columnSolomon === 'totalSolomon' ? 'cpaTotal' : columnSolomon === 'approvedSolomon' ? 'cpaApproved' : columnSolomon === 'pendingSolomon' ? 'cpaPending' : 'cpaCancelled';
        }  else {
            newType = type;
        }
                
        if (typeFiltred === newType) {
            setFilterDec(!filterDec);
        } else {
            setTypeFiltred(newType);
            setFilterDec(true);
        }
    }

    console.log('column', column)


    const first = column.id === 'spend' || column.id === 'sales' || column.id === 'revenue' || column.id === 'ticket' || column.id === 'roas' || column.id === 'cpa' || column.id === 'cpc' || column.id === 'cpm' || column.id === 'impressions' || column.id === 'clicks' || column.id === 'viewContent' || column.id === 'addToCart' || column.id === 'initiateCheckout'
    let content = null
    if (first ){
        content = <S.DefaultColumn>
            <div>
                {columnConfig?.[column.id]?.label}
                {platformImages.length > 1 ? (
                    <span>
                        {platformImages.map((platform, index) => (
                            <Tooltip title={platform.name} key={index} placement='top'>
                                <span key={index}>
                                    <img src={platform.imgSrc} alt={`Platform Icon ${index}`} />
                                </span>
                            </Tooltip>
                        ))}
                    </span>
                ) : (
                <Tooltip title={platformInfo.platformName} placement='top'>
                    <span>
                        <img src={platformInfo.platformImg} alt={''} />
                        {/* <p>{platformInfo.platformName}</p> */}
                    </span>
                </Tooltip>
                )}
            </div>
            <S.ArrowIcon onClick={() => handleSelectTypeFiltred(columnConfig?.[column.id]?.filterType)} isActive={typeFiltred === columnConfig?.[column.id]?.filterType} isDesc={filterDec}>
                <ArrowDown />
            </S.ArrowIcon>
        </S.DefaultColumn>
    }else if (column.id === 'profit' ) {
        content = <S.DefaultColumnSolomon>
            <div onClick={() => { showArticle(9062698) }}>
                {columnConfig?.[column.id]?.label}
                <span>
                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                    Aprovado
                </span>
            </div>
            <S.ArrowIcon onClick={() => handleSelectTypeFiltred(columnConfig?.[column.id]?.filterType)} isActive={typeFiltred === columnConfig?.[column.id]?.filterType} isDesc={filterDec}>
                <ArrowDown />
            </S.ArrowIcon>
        </S.DefaultColumnSolomon>
    } else if (column.id === 'productCostUtm') {
        content = <S.DefaultColumnSolomon>
        <div onClick={() => { showArticle(9062698) }}>
            {columnConfig?.[column.id]?.label}
            <span>
                <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                Aprovado
            </span>
        </div>
        <S.ArrowIcon onClick={() => handleSelectTypeFiltred(columnConfig?.[column.id]?.filterType)} isActive={typeFiltred === columnConfig?.[column.id]?.filterType} isDesc={filterDec}>
            <ArrowDown />
        </S.ArrowIcon>
    </S.DefaultColumnSolomon>
    } 
    
    else if (column.id === 'margin') {
        content = <S.DefaultColumnSolomon>
        <div onClick={() => { showArticle(9062698) }}>
            {columnConfig?.[column.id]?.label}
            <span>
                <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                {`${columnSolomon === 'totalSolomon' ? 'Total' : columnSolomon === 'approvedSolomon' ? 'Aprovada' : columnSolomon === 'pendingSolomon' ? 'Pendente' : 'Cancelada'}`}
            </span>
        </div>
        <S.ArrowIcon onClick={() => handleSelectTypeFiltred(columnConfig?.[column.id]?.filterType)} isActive={typeFiltred === columnConfig?.[column.id]?.filterType} isDesc={filterDec}>
            <ArrowDown />
        </S.ArrowIcon>
    </S.DefaultColumnSolomon>
    }
    else if (column.id === 'salesUtm' || column.id === 'revenueUtm' || column.id === 'ticketUtm' || column.id === 'roasUtm' || column.id === 'cpaUtm' || column.id === 'cpc' || column.id === 'cpm' || column.id === 'impressions' || column.id === 'clicks' || column.id === 'viewContent' || column.id === 'addToCart' || column.id === 'initiateCheckout') {
        content = <S.DefaultColumnSolomon>
            <div onClick={() => { showArticle(9062698) }}>
                {columnConfig?.[column.id]?.label}
                <span>
                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                    {`${columnSolomon === 'totalSolomon' ? 'Totais' : columnSolomon === 'approvedSolomon' ? 'Aprovadas' : columnSolomon === 'pendingSolomon' ? 'Pendentes' : 'Canceladas'}`}
                </span>
            </div>
            <S.ArrowIcon
                onClick={() => handleSelectTypeFiltred(columnConfig?.[column.id]?.filterType)}
                isActive={
                    typeFiltred ===
                    (columnSolomon === 'totalSolomon'
                        ? 'totalOrders'
                        : columnSolomon === 'approvedSolomon'
                            ? 'approvedOrders'
                            : columnSolomon === 'pendingSolomon'
                                ? 'pendingOrders'
                                : 'cancelledOrders')
                }
                isDesc={filterDec}
            >
                <ArrowDown />
            </S.ArrowIcon>
        </S.DefaultColumnSolomon>
    }

    return (
        <>
            {content}
        </>
    );
};

export default ColumnItem;

function ArrowDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
        </svg>
    );
}
