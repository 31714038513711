import { useMutation, useQuery } from 'react-query';
import { getOrders, updateOrder } from '../api/api-orders';
import  useAlert  from '../../hooks/useAlert';

export function useOrders(company_id: string, start_date: string, end_date: string) {
    return useQuery(['orders', company_id, start_date, end_date], () => getOrders(company_id, start_date, end_date), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function updateOrdersCost() {
    const { showAlert } = useAlert();
    return useMutation(updateOrder, {
        onSuccess: () => {
          showAlert('Atualização bem-sucedida!', 'success', 5000);
        },
        // onError: () => {
        //   showAlert('Ocorreu um erro na atualização.', 'error', 0, true);
        // },
        onMutate: () => { 
          showAlert('Atualizando pedidos... pode demorar um pouco', 'loading', 0, true);
        }
      });
}
