import * as S from './style';

export function AccessDenied() {
    return (
        <S.Container>
            <S.Title>Acesso Negado</S.Title>
            <S.Subtitle>Desculpe, você não tem permissão para acessar esta página</S.Subtitle>
        </S.Container>
    );
}
