import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 36.4375rem;
    height: 14.5rem;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const Graphic = styled.div`
    margin-top: 2rem;
`;

export const ButtonSection = styled.div`
    position: absolute;
    z-index: 1;
    top: 0.8rem;
    right: 0.9rem;
    display: flex;
    gap: 1rem;
`;

export const DayButton = styled.div`
    width: 9.75rem;
    height: 1.6875rem;
    border: 1px solid #cdcdcd;
    &:hover {
        border: 1px solid var(--green);
    }
    font-size: 0.75rem;
    line-height: 1.05rem;
    color: var(--black);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-left: 0.25rem;
    }
`;

export const HourButton = styled.div`
    width: 6.9375rem;
    height: 1.6875rem;
    border: 1px solid #cdcdcd;
    &:hover {
        border: 1px solid var(--green);
    }
    font-size: 0.75rem;
    line-height: 1.05rem;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    svg {
        margin-left: 0.25rem;
    }
`;
