import { reactivateSubscription, submitSubscription, getSubscription, getLimitOrders , getTransactions, getCoupon, getExtraTransactions, deleteSubscription, refundSubscription, upgradeSubscription } from '../api/api-subscription';
import { useQuery, useMutation } from 'react-query';

export function useGetCoupon(cupom: string) {
    return useQuery(['getCoupon', cupom], () => getCoupon(cupom), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useSubmitSubscription() {
    return useMutation(submitSubscription);
}

export function useUpgradeSubscription() {
    // console.log('upgradeSubscriptionHook');
    return useMutation(upgradeSubscription);
}

export function useReactivateSubscription() {
    // console.log('upgradeSubscriptionHook');
    return useMutation(reactivateSubscription);
}

export function useGetSubscription(company_id: string) {
    return useQuery(['getSubscription', company_id], () => getSubscription(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useDeleteSubscription(company_id: string) {
    return useMutation(() => deleteSubscription(company_id));
}

export function useRefundSubscription(company_id: string) {
    return useMutation(() => refundSubscription(company_id));
}

export function useGetLimitOrders(company_id: string) {
    return useQuery(['getLimitOrders', company_id], () => getLimitOrders(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetTransactions(company_id: string) {
    return useQuery(['getTransactions', company_id], () => getTransactions(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetExtraTransactions(company_id: string) {
    return useQuery(['getExtraTransactions', company_id], () => getExtraTransactions(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}


