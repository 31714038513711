import styled from 'styled-components';

export const Container = styled.div`
    width: 70rem;
    height: auto;
    background: #fff;
    border-radius: 10px;
`;

export const Header = styled.div`
    font-size: 0.8125rem;
    font-weight: 650;
    line-height: 1.21875rem;
    color: #989c97;
    display: flex;
`;
export const InnerContainer = styled.div`
`;

export const InputSection = styled.div`
    margin-left: 0.6rem;
    margin-top: -0.2rem;
    margin-bottom: 1rem;
`;

export const Title = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #898989;
`;

export const ProductName = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;
    margin-bottom: 1rem;
    color: var(--black);
`;

export const TitleSection = styled.div`
    margin-top: 1.5rem;
    margin-left: 1.25rem;
`;

export const Line = styled.div`
    width: 95%;
    margin: 0 auto;
    height: 1px;
    background-color: #e5e5e5;
`;

export const InputCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    gap: 0.1rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: #5a5a5a;

    label {
        display: block;
        position: relative;
        padding-left: 30px; /* Espacio a la izquierda para el diseño del checkbox */
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        span {
            position: absolute;
            top: 2px;
            left: 8px;
            height: 12px;
            width: 12px;
            border: 2px solid #555; /* Borde del checkbox */
            border-radius: 4px; /* Bordes redondeados */
        }
    }

    input:checked + span {
        background-color: var(--green); /* Color de fondo del checkbox seleccionado */
        border: 1px solid var(--green); /* Borde del checkbox seleccionado */
    }

    span:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked + span:after {
        display: block;
        text-align: center;
        width: 5px;
        height: 8px;
        margin-left: -5px;
        margin-top: -3px;
    }

    span:after {
        left: 7px;
        top: 3px;
        width: 2px;
        height: 5px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
`;

export const Image = styled.div`
    width: 8rem;
    margin-left: 1rem;
`;

export const ProductVariant = styled.div`
    width: 30rem;
    margin-left: 1rem;
`;

export const ProductPrice = styled.div`
    width: 8rem;
    margin-left: 1rem;
`;

export const IndividualCost = styled.div`
    width: 8.75rem;
    margin-left: 1rem;
`;

export const Action = styled.div`
    width: 8.75rem;
    margin-left: 1rem;
`;

export const Lines = styled.div`
max-height: 40vh; /* 50% da altura da janela de visualização */
margin-top: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    overflow-y: auto;

    > div:nth-child(2n + 1) {
        background-color: var(--gray-115);
    }

    > div:last-child {
        margin-bottom: 0.5rem;
    }
`;

export const SectionSaveChanges = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    // margin-bottom: 1rem;
`;

export const SaveChanges = styled.div`
    width: 23rem;
    height: 2.375rem;
    border-radius: 5px;
    background-color: #cdcdcd;
    color: #878787;
    font-size: 0.75rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: not-allowed;
    }
`;

