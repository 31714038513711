// import { BackButton } from './../Terms/style';
import styled, { keyframes } from 'styled-components';

type SelectProps = {
    isIntegrateProps: any;
    isIntegrateGatewayProps: any;
};

export const Select = styled.div<SelectProps>`
position: relative;
border-radius: 10px;
display: flex;
align-items: center;
font-size: 1rem;
height: 3.2rem;
width: 15rem;
padding: 0 1rem;
border-radius: 12px;
margin-right: -1rem;
color: var(--black);
border: ${(props) => props.isIntegrateProps || props.isIntegrateGatewayProps ? '1px solid var(--green)' : '1px solid #f5f5f5'};
font-weight: 600;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
// background: url(/src/assets/images/seta.png) no-repeat center right white;
// background-size: 1rem;
// background-position: 15rem 1.1rem;
transition: all ease 250ms;
top: 0; 

    svg {
        margin-left: auto;
        color: var(--gray-700);
    }

    &::placeholder {
        color: var(--gray-700);
        font-weight: 500;
    }

    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        // background-color: #fcfcfc;
        // top: 0.1rem;
        transition: all ease 250ms;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
    }
    img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    }

    span {
        font-size: 1rem;
        font-weight: 550;
        color: #9b6afa;
    }
`;

    
export const IntegrationCircle = styled.div<SelectProps>`
padding: 0.25rem;
position: absolute;
right: 1rem;
background-color: ${(props) => props.isIntegrateProps || props.isIntegrateGatewayProps ? 'var(--green)' : '#f1f1f1'};;
border-radius: 50%;
`


type OptionsContainerProps = {
    showSelectDropdown: boolean;
};

export const OptionsContainer = styled.div<OptionsContainerProps>`
    position: absolute;
    background-color: #fff;
    z-index: 10;
    display: flex;
    
    ${(props) =>
        props.showSelectDropdown
            ? `

    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;

    `
            : `

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    flex-direction: column;
    left: 0;
    top: 3.5rem;
    width: 16.8rem;
    height: 13rem;
    // overflow-y: scroll;
    border-radius: 8px;
    gap: 0.1rem;
    border: 1px solid var(--gray-120);
`;

export const Option = styled.div`
    padding: 0 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 2.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    &:hover {
        cursor: pointer;
        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        background-color: #f5f6f7;
    }
   
`;

export const OptionImage = styled.img`

    margin-left: 0.3rem;
    width: 1.3rem;
    height: 2rem;
`;