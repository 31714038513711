import { resetPasswordDTO } from '../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function submitResetPassword(body: DTO.resetPasswordDTO): Promise<AxiosResponse> {
    const { email } = body;

    console.log('body', body);

    const newBody = { email };
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/password-reset`, newBody);
    
    return data;
}
