import styled from 'styled-components';

export const DropdownContainer = styled.div`
    // position: relative;
    display: inline-block;
`;

export const InfoArea = styled.div`
    display: flex;
`

export const DropdownButton = styled.button`
    padding: 10px;
    background-color: white;
    border: 1px solid #f1f1f1;
    font-weight: 550;
    cursor: pointer;
    display: flex;
    border-radius: 7px;
    align-items: center;
    width: 200px; // Defina uma largura fixa ou ajuste conforme necessário
    justify-content: space-between;
    transition: all ease 250ms;

    svg {
        fill: var(--gray-700);
    }

    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        // background-color: #fcfcfc;
        // top: 0.1rem;
        transition: all ease 250ms;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
    }
`;

export const DropdownList = styled.div`
    position: absolute;
    font-weight: 550;
    font-size: 0.8rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 7px;
    margin-top: 5px;
    width: 12.3rem;
    z-index: 1;

    height: 150px;
    overflow-y: scroll;
   
`;

export const DropdownItem = styled.div`
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    
    &:hover {
        background-color: #f1f1f1;
    }
`;

export const Icon = styled.img`
    width: 20px;
    margin-right: 10px;
    margin-top: -2.5px;
`;
