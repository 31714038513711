import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    padding: 0 1rem 0;
    background-color: #fff; // Cor de fundo padrão

    &:nth-child(odd) {
        background-color: #fff; // Linhas ímpares em branco
    }
    &:nth-child(even) {
        background-color: var(--gray-110); // Linhas pares em cinza claro
    }
`;

export const DefaultColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    border-radius: 5px;
    display: flex;
    flex: 1;
    text-align: center;
    color: var(--black);
    svg {
        margin-left: 0.5rem;
        cursor: pointer;
    }
`;
