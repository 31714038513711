// GraphModal.tsx
import React from 'react';
import Modal from '../../../../components/Modal/index.tsx';
import * as CS from '../../../CostOperation/style.ts'; 
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import { createCostSchema } from '../../../../schemas/costSchema';
import { useCreateCost } from '../../../../services/hooks/costHooks';
import {ButtonApp} from '../../../../components/ButtonApp';
import { uuidv4 } from '../../../../utils';
import useAlert from '../../../../hooks/useAlert';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';

interface MarketingModalProps {
    onClose: () => void;
    setCostOperationDate?: (value: any) => void;
}

const MarketingCostModal : React.FC<MarketingModalProps> = ({ onClose, setCostOperationDate }) => {
    
    const { currentCompany } = useAuth();
    const { mutate: createCost, isLoading } = useCreateCost();
    const { showAlert } = useAlert();
    const { currencySymbol } = useCurrencyType();
    const [costOperation, setCostOperation] = useState({
        name: '',
        type: 'marketing',
        value: '',
        frequency: 'monthly',
        date: '',
    });

    function formatToNumber(value: string) {
        value = value.replace(`${currencySymbol}`, '');
        value = value.replace('.', '');
        value = value.replace(',', '.');
        return parseFloat(value);
    }

    function formatValue(value: string) {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        newValue = `${currencySymbol} ` + newValue;
        setCostOperation({ ...costOperation, value: newValue });
    }

    function handleCreateCost() {
        const body = {
            name: costOperation.name,
            value: formatToNumber(costOperation.value),
            type: costOperation.type,
            frequency: costOperation.frequency,
            date: costOperation.date,
            company_id: currentCompany,
            status: 'active',
            additional_cost_id: uuidv4().substring(0, 10),
        };

        if (setCostOperationDate) {
            setCostOperationDate((prevState: any) => {
                return {
                    ...prevState!,
                    value: formatToNumber(costOperation.value),
                    frequency: costOperation.frequency,
                    date: costOperation.date,
                };
            });
        }
        

        const isValidInformation = createCostSchema.safeParse(body);

        if (!isValidInformation?.success) {
            return;
        }
        
        onClose(); // Por exemplo, fechar o modal
        showAlert('Custo adicionado com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 5000, true);
        createCost(body, {
            onSuccess: () => {
                // Aqui você coloca o código que deseja executar após o sucesso da criação
                onClose(); // Por exemplo, fechar o modal
                // showAlert('Custo de marketing atualizado com sucesso! Recarregue a página para atualizar.', 'success', 5000, true);
                // Você pode também chamar alguma função para atualizar a lista de custos, se necessário
            },
            onError: (error) => { // Você também pode definir um tratamento de erro aqui
                // showAlert('Não foi possível adicionar seu custo de marketing, tente novamente!', 'error', 5000, true);
                //console.log("erro ao criar custo", error);
            },
        });
    }


    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage
                    src="https://storage.googleapis.com/static-images-source/management-cost.png"
                    alt="Shopify"
                />
                <S.MarketingTitle>
                    Custo manual de <S.MarketingHighlight>Marketing</S.MarketingHighlight> 
                </S.MarketingTitle>
                <br />
                <S.MarketingLine/>

                <CS.InputContainer>
                    <label>Nome</label>
                    <input type="text" value={costOperation.name} onChange={(e) => setCostOperation({ ...costOperation, name: e.target.value })} placeholder="Aluguel do espaço" />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Tipo de Custo</label>
                    <input
                        type="text"
                        readOnly
                        value= 'Marketing' // Exibe o valor "Marketing"
                        style={{ backgroundColor: '#e0e0e0', color: 'black' }} // Estiliza com fundo cinza e texto preto
                    />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Valor</label>
                    <input value={costOperation.value} placeholder={`${currencySymbol} 10,00`} type="text" onChange={(e) => formatValue(e.target.value)} />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Frequência</label>
                    <select value={costOperation.frequency} onChange={(e) => setCostOperation({ ...costOperation, frequency: e.target.value })}>
                        <option value="monthly">Mensal</option>
                        <option value="weekly">Semanal</option>
                        <option value="daily">Diário</option>
                        <option value="specific">Pontual</option>
                    </select>
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Data</label>
                    <input type="date" value={costOperation.date} onChange={(e) => setCostOperation({ ...costOperation, date: e.target.value })} />
                </CS.InputContainer>
                <CS.ButtonContainer style={{marginTop: 24}}>
                <ButtonApp 
                border=''
                width="10rem"
                height="3rem"
                color="#fff"
                background="#19c380"
                icon={<AddCircleRoundedIcon style={{ fontSize: 20, marginRight: 10 }}/> }
                text="Adicionar custo"
                isLoading={isLoading}
                onClick={handleCreateCost}/>
                </CS.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default MarketingCostModal;
