import { TemplateModal } from '../index';
import * as S from './style';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import { useState } from 'react';
import { ModalDomPagamentosProps, userDTO } from '../../../../@types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAuth from '../../../../hooks/useAuth';
import { useCreateIntegrationDom } from '../../../../services/hooks/integrationHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateDomSave } from '../../../../storage/storageOnboarding';

export function ModalDomPagamentos({ setShowPopupDomPagamentos, setIntegrateDomPagamentos }: ModalDomPagamentosProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/dom%20icon.png';
    const baseColor = '#64B32F';

    const { user, setUserContext, updateUser } = useAuth();

    const currentCompany = storageOnboardingCurrentCompanyGet();

    const domInformation = user?.companies?.[currentCompany]?.integrations?.dom?.accounts[user?.companies?.[currentCompany]?.integrations?.dom?.accounts?.length - 1];

    const { mutate: handleCreateIntegrationDom, isLoading: isLoadingCreateIntegration, isError } = useCreateIntegrationDom();

    const [domInfo, setDomInfo] = useState({
        accessTokenDom: domInformation?.account_id  !== 'default-account' ? domInformation?.account_id : '',
    });


    function handleChangeDomInfo(e: any) {
        setDomInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    function handleIntegrate() {
        const body = {
            token: domInfo.accessTokenDom,
            company_id: storageOnboardingCurrentCompanyGet(),
            user: user
        };

        handleCreateIntegrationDom(body, {
            onSuccess: () => {
                storageOnboardingIntegrateDomSave();
                if(setIntegrateDomPagamentos){
                    setIntegrateDomPagamentos(true);
                }
                setShowPopupDomPagamentos(false);
                const active = {
                    account_id: domInfo.accessTokenDom,
                    assets:{token: domInfo.accessTokenDom},
                    last_update: '',
                    status: 'active',
                };
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            integrations: {
                                ...user?.companies?.[currentCompany]?.integrations,
                                dom: {
                                    ...user?.companies?.[currentCompany]?.integrations?.dom,
                                    accounts: [active],
                                },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();
            },
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Dom Pagamentos'}
                title={'Dom Pagamentos'}
                subtitle={'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration
                    value={domInfo.accessTokenDom}
                    setValue={handleChangeDomInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenDom"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
            </S.InnerContainer>
            {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            <S.InfoContainer>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meu domínio e token de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp border='' width="7.75rem" height="2.25rem" color="#fff" background="#19c380" icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />} onClick={handleIntegrate} text="Integrar" isLoading={isLoadingCreateIntegration} />
            </S.DisconnectContainer>
        </S.Container>
    );
}