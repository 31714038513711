import * as S from './style';
import { LineProductsCostsProps } from '../../../../@types';
import { toBrazilianFormat } from '../../../../utils';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import ModalProductHistoryCosts  from '../../ModalProductHistoryCosts';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { ButtonApp } from '../../../../components/ButtonApp';
import { HistoryIconStyled } from './style';
import { InputWithTypeMoney } from '../../../../components/InputWithTypeMoney';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';    

export function LineProductsCosts({product, isEditingPerProduct, editedVariants, setEditedVariants, dataProductCosts, editingCost, setEditingCost, currentPage, currency, setCurrency  }: LineProductsCostsProps) {

    const { isVisible} = useVisibility();
    const {currencySymbol} = useCurrencyType();

    const [isEditingMap, setIsEditingMap] = useState<{ [key: number]: boolean }>({
        [currentPage]: false
    });

    if(product === undefined){
        return(
            <S.Container>
            <S.Image></S.Image>
            <S.ProductName></S.ProductName>
            <S.Price></S.Price>
            <S.Cost></S.Cost>
            <S.LastSale></S.LastSale>
            <S.Historic>

            </S.Historic>
        </S.Container>
        )
    }

    // se isEditingPerProduct for false, isEditing será false
    useEffect(() => {
        if (!isEditingPerProduct) {
            setEditingCost(`${toBrazilianFormat(meanProductCost)}`);
            setCurrency(product?.variants[0]?.currency || 'BRL');   
            
            const updatedIsEditingMap = { ...isEditingMap };
            Object.keys(updatedIsEditingMap).forEach(page => {
                updatedIsEditingMap[page] = false;
            });
            setIsEditingMap(updatedIsEditingMap);
        }
    }, [isEditingPerProduct]);


    // Aplica o valor editado a todas as variantes quando o usuário sai do campo de entrada (onBlur)
    const applyCostToAllVariants = (newCostInput: string, newCurrency: string) => {
        const newCost = parseFloat(newCostInput.replace('.', '').replace(',', '.'));
        if (!isNaN(newCost)) {
            const newEditedVariants = { ...editedVariants };
        
            product.variants.forEach((variant: any) => {
                newEditedVariants[variant.variant_id] = {
                    ...(newEditedVariants[variant.variant_id] || {}),
                    variant_cost: newCost ?? variant.cost,
                    product_id: product.product_id,
                    variant_id: variant.variant_id,
                    currency: newCurrency ?? variant.currency,
                    store_id: product.store_id,
                    account_id:  product.account_id,
                };
            });
            setEditedVariants(newEditedVariants);
        }
    };

    const [showModal, setShowModal] = useState(false);

    /*const meanProductPrice = (product?.variants?.reduce((acc: number, object: any) => {
        return acc + Number(object?.price);
    },0))/product?.variants?.length;

    const meanProductCost = (product?.variants?.reduce((acc: number, object: any) => {
        return acc + Number(object?.cost);
    },0))/product?.variants?.length;*/

    const meanNonZero = (values: number[]) => {
        const nonZeroValues = values.filter(value => value !== 0);
        if (nonZeroValues.length === 0) return 0; // Handle the case where there are no non-zero values
        const sum = nonZeroValues.reduce((acc, val) => acc + val, 0);
        return sum / nonZeroValues.length;
    };
    
    const productPrices = product?.variants?.map((variant: any) => Number(variant?.price)) || [];
    const productCosts = product?.variants?.map((variant: any) => Number(variant?.cost)) || [];
    
    const meanProductPrice = meanNonZero(productPrices);
    const meanProductCost = meanNonZero(productCosts);

    useEffect(() => {
        // Inicialize o estado com os dados do produto quando o componente for montado ou quando os props mudarem
        if (editingCost === undefined || editingCost === '' || editingCost === '0,00') {
            if (meanProductCost === 0) {
                setEditingCost('0,00');
            } else {
                setEditingCost(`${toBrazilianFormat(meanProductCost)}` || ''); // Ensure two decimal places
            }
        }
        if (currency === undefined || currency === '' || currency === 'BRL') {
            setCurrency(product?.variants[0]?.currency || 'BRL');
        }
    }, [product]);

    useEffect(() => {
        if (!isEditingPerProduct || !isEditingMap[currentPage]) {
            // Set editingCost to meanProductCost when not in editing mode and isEditingMap is false
            setEditingCost(`${toBrazilianFormat(meanProductCost)}`);
            setCurrency(product?.variants[0]?.currency || 'BRL');
        }
    }, [isEditingPerProduct, isEditingMap, currentPage, meanProductCost, product]);

    

    function handleInput(value: string, variant_id?: string, currency?: string) {
        let formattedValue = value.replace(/[^\d,]/g, '');
        formattedValue = formattedValue.replace(/^0+(?=\d)/, '');
        const commaIndex = formattedValue.indexOf(',');
        if (commaIndex !== -1) {
            formattedValue = formattedValue.substring(0, commaIndex + 1) + formattedValue.substring(commaIndex + 1).replace(/,/g, '');
        }
        formattedValue = formattedValue.replace(/(\d*),(\d{3})\d*/, '$1,$2');
        if (formattedValue === '' || formattedValue === ',') {
            formattedValue = '0,00';
        }
    
        //console.log(formattedValue, currency, variant_id);
        setEditingCost(formattedValue);
        setCurrency(currency ?? 'BRL');
        applyCostToAllVariants(formattedValue, currency ?? 'BRL');
        setIsEditingMap(prev => ({
            ...prev,
            [currentPage]: true
        }));
    }

    const lastDate = !!product?.latest_order_date ? format(new Date(product?.latest_order_date), 'dd/MM/yyyy') : '-';

    //console.log("currency", product?.variants[0]?.currency)

    let currencyTypeVariants = 'R$';

    if (product?.variants[0]?.currency === 'USD') {
        currencyTypeVariants = '$';
    } else if (product?.variants[0]?.currency === 'BRL'){
        currencyTypeVariants = 'R$';
    }

    const handleImageError = (event) => {
        event.target.src = 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png';
    };
    
    return (
        <S.Container>
            {showModal && <ModalProductHistoryCosts variantIds={ product?.variants.map((variant:any) => variant.variant_id)} onClose={() => setShowModal(false)} product={product} dataVariantCosts={dataProductCosts}/>}
            <S.Image>{product?.product_image_src?.length > 0 && <img src={ isVisible ? product?.product_image_src : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt="product_image" onError={handleImageError}/>}</S.Image>
            <S.ProductName>
                <Tooltip title={isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * * * *'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'nowrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <p>{isVisible ? product?.product_title && product?.product_title : '* * * * * * * * * * * * *'}</p>
                </Tooltip>
            </S.ProductName>
            <S.Price>{meanProductPrice === 0 ? 'R$ 0,00' : `${currencySymbol} ${toBrazilianFormat(meanProductPrice)}`}</S.Price>
            <S.Cost>
            {isEditingPerProduct ? (
                <InputWithTypeMoney
                    key = {product.product_id}
                    disabled={false}
                    defaultMoney='BRL'
                    value={editingCost}
                    setValue={handleInput}
                    width='11.25rem'
                    currency={currency}
                />
                ) : (
                    // Mostra o custo médio se não estiver no modo de edição
                    currencyTypeVariants + `${toBrazilianFormat(meanProductCost)}`
                )}
            {isEditingMap[currentPage] && (
                <S.NotSaved>
                    <Tooltip 
                        title="Custo não salvo" 
                        placement="top"
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: '#E90E0E',
                                    fontWeight: 'semibold',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                    borderRadius: '5px',
                                    border: 1,
                                    color: 'common.white',
                                }
                            },
                        }}
                    >
    <ErrorOutlineRoundedIcon fontSize='small' />
</Tooltip>


                </S.NotSaved>
            )}
            </S.Cost>
            <S.LastSale>{product?.product_title?.length > 0 ? lastDate : ''}</S.LastSale>
            <S.Historic>
                <ButtonApp border='' width='130px' height='30px' background='var(--black)' color='white' icon={<HistoryIconStyled />} text='Histórico'  onClick={() => setShowModal(true)} />
            </S.Historic>
        </S.Container>
    );
}