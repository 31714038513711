import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import { Banner } from '../../components/Banner';
import { Search } from '../../components/Search';
import * as S from './style';
import { TableProductsCosts } from './TableProductsCosts';
import { ButtonApp } from '../../components/ButtonApp';
import { useEffect, useState } from 'react';
import { TableProductsCostsInMass } from './TableEditProductInMass';
import { TableEditManualProduct } from './TableEditManualProduct';
import { TableEditByVariant } from './TableEditByVariant';
import ModalSaveCosts  from './ModalSaveCosts';
import { useGetProductCosts, useProducts } from '../../services/hooks/productsHooks';
import useAuth from '../../hooks/useAuth';
import { useIntercom } from 'react-use-intercom';
import { groupProductsByProductId } from './utils';
import { useCreateCost, useGetCosts } from '../../services/hooks/costHooks';
import { useParams } from 'react-router-dom';
import { ProductsCostDTO } from '../../@types';
import { trackEvent } from '../../services/segment/sendEvent'
import useAlert from '../../hooks/useAlert';
import Tooltip from '@mui/material/Tooltip';
import { useRefreshContext } from '../../contexts/RefeshContext';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';

interface VariantType {
    company_id: string,
    product_id: string,
    variant_id: string,
    product_title: string,
    price:  number,
    bef_cost:  number,
    variant_cost: number,
    currency: string,
    collect_timestamp: string,
    store_id: string,
    account_id: string,
}

export function ProductCosts() {

    const [tabSelected, setTabSelected] = useState('editByProduct');
    const [filtred, setFiltred] = useState('')
    const [showSelectInMass, setShowSelectInMass] = useState(false)
    const [isEditingPerProduct, setIsEditingPerProduct] = useState(false);
    const [editedVariantsPerProduct, setEditedVariantsPerProduct] = useState<Record<string,VariantType>>({});
    const [editedVariantsPerVariant, setEditedVariantsPerVariant] = useState<Record<string,VariantType>>({});
    const [editedVariantsInMass, setEditedVariantsInMass] = useState<Record<string,VariantType>>({});
    const [openedProduct, setOpenedProduct] = useState('');
    const [selectAll, setSelectAll] = useState(false); // Adicione este estado
    const [selected, setSelected] = useState<string[]>([]);

    const { showArticle } = useIntercom();
    const { showAlert } = useAlert();
    const [openSaveCostsModal, setOpenSaveCostsModal] = useState(false); 
    const [checkCost, setCheckCost] = useState(false);

    const { user, currentCompany } = useAuth();
    const { data, refetch: refetchGetProductCosts } = useGetProductCosts(currentCompany);
    const [dataProductCosts, setDataProductCosts] = useState<ProductsCostDTO>({'data': {'data':[]}} as ProductsCostDTO);

    const [currentPageEditByProduct, setCurrentPageEditByProduct] = useState(1);
    const [currentPageEditInMass, setCurrentPageEditInMass] = useState(1);
    const [currentPageEditByVariant, setCurrentPageEditByVariant] = useState(1);
    const [isEditingProductsCost, setEditingProductsCost] = useState(false);


    const { data:reqProducts, refetch: refetchProducts, isLoading: isLoadingProducts } = useProducts(currentCompany);
    const [products, setProducts] = useState<any[]>([]);
    const [productsWithFilter, setProductsWithFilter] = useState<any[]>(products);

    const { refreshCounter } = useRefreshContext();

    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetchGetProductCosts();
            refetchProducts();
        }
      }, [refreshCounter]);


    const { product_id } = useParams();

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'products_costs'
        });
      }, [])
    
    useEffect(() => {
        // Resetar a paginação da tabela ativa ao filtrar
        switch(tabSelected) {
            case 'editByProduct':
                setCurrentPageEditByProduct(1);
                break;
            case 'editInMass':
                setCurrentPageEditInMass(1);
                break;
            case 'manualEdit':
                break;
            case 'editByVariant':
                setCurrentPageEditByVariant(1);
                break;
        }
    }, [filtred, tabSelected]);
    


    useEffect(() => {
        if (product_id) {
            setTabSelected('editByVariant'); // Altera para a aba 'editByVariant'
            setOpenedProduct(product_id); // Abre o produto
        }
    }, [product_id]);


    useEffect(() => {
        checkCost ? setProductsWithFilter(products.filter((product: any) => product?.variants.some((variant: any) => variant.cost === '0.00'))) : setProductsWithFilter(products);
    }, [products, checkCost]);  

    const { data: currentDataCost, isLoading: isLoadingCurrentData } = useGetCosts(currentCompany);
    const { mutate: createCost, isLoading } = useCreateCost();

    useEffect(() => {
        const groupedProducts = groupProductsByProductId(reqProducts?.data?.data ?? []);
        setProducts(groupedProducts);
    }, [reqProducts]);

    useEffect(() => {
        setDataProductCosts(data ? data : {'data': {'data':[]}});
        // //console.log('data', data);
    }, [data]);


    const [selectedProducts, setSelectedProducts] = useState<string[]>([]); 

    const handleSelectedChange = (newSelected: string[]) => {
        // //console.log('newSelected', newSelected);
        setSelectedProducts(newSelected);
    };

    function handleShowSelectInMass(){
        if (selectedProducts && selectedProducts.length > 0) {
            setShowSelectInMass(true)
        }
        else {
            showAlert('Selecione pelo menos um produto para editar', 'error', 5000, true);
        }
    }

    const toggleEditPerProduct = () => {
        // Alterna o estado de edição
        setIsEditingPerProduct(!isEditingPerProduct);
        setEditedVariantsPerProduct({});
        // Lógica para editar custos
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleSelectionClick = () => {
        setIsChecked(!isChecked);
        setCheckCost(!isChecked);  // Essa função simula o mesmo comportamento do checkbox comentado
    };

    return (
        <S.Container>
            
            <S.Title><h2>Custos de produtos</h2>
                <p>Atualize e gerencie facilmente os custos dos seus produtos.</p>
            </S.Title>

            <ButtonApp  
                border=''
                width="18.5rem"
                height="1.5rem"
                borderRadius='50px'
                iconWidth='14px'
                color="#1B626A"
                background="#CDDDDF"
                icon={<HelpRoundedIcon style={{width: '16px'}}/>}
                onClick={() => {showArticle(8726302)}}
                text="Como funcionam os Custos de Produtos?"

            />  
           
            <S.HeaderMenu>
                <S.TabArea onClick={() => (setTabSelected('editByProduct'))} showHover={tabSelected === 'editByProduct'}>
                    <S.TabButton showHover={tabSelected === 'editByProduct'}>Edição por produto</S.TabButton>
                </S.TabArea>
                <S.TabArea onClick={() => (setTabSelected('editByVariant'))} showHover={tabSelected === 'editByVariant'}>
                    <S.TabButton showHover={tabSelected === 'editByVariant'}>Edição por variante</S.TabButton>
                </S.TabArea>
                <S.TabArea onClick={() => (setTabSelected('editInMass'))} showHover={tabSelected === 'editInMass'}>
                    <S.TabButton showHover={tabSelected === 'editInMass'}>Edição em massa</S.TabButton>
                </S.TabArea>
                <>
                <Tooltip 
                    title={isLoadingCurrentData ? "Aguarde o carregamento dos dados..." : ""} 
                    placement="top"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#1e1e1e',
                                fontWeight: 'semibold',
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                borderRadius: '5px',
                                border: 1,
                                color: 'common.white',
                            }
                        },
                    }}
                >
                <S.TabArea style={{ cursor: isLoadingCurrentData ? 'not-allowed' : 'pointer', opacity: isLoading ? 0.5 : 1 }} onClick={() => {!isLoadingCurrentData && setTabSelected('manualEdit')}} showHover={tabSelected === 'manualEdit' }>
                
                    <S.TabButton style={{ cursor: isLoadingCurrentData ? 'not-allowed' : 'pointer', opacity: isLoading ? 0.5 : 1 }} showHover={tabSelected === 'manualEdit'}>Lançamento manual</S.TabButton>
                </S.TabArea>
                </Tooltip>
            </>
            </S.HeaderMenu>
            {openSaveCostsModal &&
                (
                (tabSelected === 'editByProduct' && <ModalSaveCosts setSelected={setSelected} setSelectAll={setSelectAll} isEditingProductsCost={isEditingProductsCost} useStorage={true} toggleEditPerProduct={toggleEditPerProduct} products={products} setProducts={setProducts} onClose={() => setOpenSaveCostsModal(false)} editedVariants={editedVariantsPerProduct} setEditedVariants={setEditedVariantsPerProduct} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} />)  
                ||
                (tabSelected === 'editInMass' && <ModalSaveCosts setSelected={setSelected} setSelectAll={setSelectAll} isEditingProductsCost={isEditingProductsCost} useStorage={true} toggleEditPerProduct={toggleEditPerProduct} products={products} setProducts={setProducts} onClose={() => setOpenSaveCostsModal(false)} editedVariants={editedVariantsInMass} setEditedVariants={setEditedVariantsInMass} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts} />)  
                )
            }
            
       
            {tabSelected !== 'manualEdit' && <S.Section>
                <span>
                <Search width="18.75rem" placeholder="Buscar produto..." value={filtred} setValue={setFiltred}/>
            
                {/* <span> */}
        <S.SelectionFilterSection 
            disabled={false} 
            onClick={handleSelectionClick} 
            style={{
                cursor: 'pointer',
                backgroundColor: isChecked ? '#e0e0e0' : '', // Muda a cor de fundo quando está selecionado
            }}
        >
            {isChecked ? (
                <DisabledByDefaultRoundedIcon style={{fill: '#E90E0E'}} />
            ) : (
                <FactCheckRoundedIcon style={{fill: 'var(--black)'}} />
            )}
            
            <p>{isChecked ? 'Limpar filtro de produtos sem custos' : 'Filtrar produtos sem custos configurados'}</p>
            
            {isChecked && (
                            <span>{productsWithFilter?.length}</span>

            )}

        </S.SelectionFilterSection>
    </span>
                
            </S.Section>}
            {tabSelected === 'editByProduct' && <S.OptionsSection>
                <S.OptionLeft>
                {isEditingPerProduct ? (
                    <ButtonApp
                        border='1px solid var(--gray-120)'
                        width="13rem"
                        height="3rem"
                        color="#000"
                        background="#F8F8F8"
                        icon={<UndoIcon />}
                        onClick={toggleEditPerProduct}
                        text="Desfazer alterações" />
                ) : (
                    <ButtonApp
                        border='1px solid var(--gray-120)'
                        width="13rem"
                        height="3rem"
                        color="#000"
                        background="#F8F8F8"
                        icon={<EditIcon />}
                        onClick={toggleEditPerProduct}
                        text="Editar Custos"
                        disabled={isLoadingCurrentData || isLoadingProducts} 
                    />
                )}
                </S.OptionLeft>
                <S.OptionRight>
                    <ButtonApp
                    border=''
                    width="13rem"
                    height="3rem"
                    color="#fff"
                    background="#19c380"
                    icon={<CheckIcon />}
                    onClick={() => { setOpenSaveCostsModal(true)}}
                    text="Salvar edições"
                    disabled={!isEditingPerProduct} />
                </S.OptionRight>
            </S.OptionsSection>}
            {tabSelected === 'editInMass' && <S.OptionsSection>
                <S.OptionLeft>
                    <ButtonApp
                    border='1px solid var(--gray-120)'
                    width="13rem"
                    height="3rem"
                    color="#000"
                    background="#F8F8F8"
                    icon={<EditIcon />}
                    onClick={handleShowSelectInMass}
                    text="Edição em Massa" 
                    
                    />
                </S.OptionLeft>
                </S.OptionsSection>}
            <S.Table>
                {tabSelected === 'editByProduct' && <TableProductsCosts setEditingProductsCost={setEditingProductsCost} currentPage={currentPageEditByProduct} setCurrentPage={setCurrentPageEditByProduct} isLoading={isLoadingCurrentData || isLoadingProducts } products={productsWithFilter} filtred={filtred} dataProductCosts={dataProductCosts} isEditingPerProduct={isEditingPerProduct} editedVariants={editedVariantsPerProduct} setEditedVariants={setEditedVariantsPerProduct}/>}
                {tabSelected === 'editInMass' && <TableProductsCostsInMass selected={selected} setSelected={setSelected} setSelectAll={setSelectAll} selectAll={selectAll} currentPage={currentPageEditInMass} setCurrentPage={setCurrentPageEditInMass} isLoading={isLoadingCurrentData || isLoadingProducts } onSelectedChange={handleSelectedChange} products={productsWithFilter} filtred={filtred} dataProductCosts={dataProductCosts} showSelectInMass={showSelectInMass} setShowSelectInMass={setShowSelectInMass}  editedVariants={editedVariantsInMass} setEditedVariants={setEditedVariantsInMass} setOpenSaveCostsModal={setOpenSaveCostsModal}/>}
                {tabSelected === 'manualEdit' && <TableEditManualProduct  currentDataCost={currentDataCost} isLoadingCurrentData={isLoadingCurrentData} createCost={createCost} isLoading={isLoading}/>}
                {tabSelected === 'editByVariant' && <TableEditByVariant currentPage={currentPageEditByVariant} setCurrentPage={setCurrentPageEditByVariant} isLoading={isLoadingCurrentData || isLoadingProducts} openedProduct={openedProduct} setOpenedProduct={setOpenedProduct} products={productsWithFilter} setProducts={setProducts} filtred={filtred} editedVariants={editedVariantsPerVariant} setEditedVariants={setEditedVariantsPerVariant} dataProductCosts={dataProductCosts} setDataProductCosts={setDataProductCosts}/>}
            </S.Table>
        </S.Container>
    );
}


function UndoIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z"/>
    </svg>
    )
}

function CheckIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
    )
}

function EditIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
        </svg>
    )
}

