import React, {useState, useEffect} from 'react';
import * as S from './style';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import { InputPayment } from '../../InputPayment';
import { PlanCardPaymentProps } from '../../../../@types';
import { set } from 'date-fns';
import { formatValue } from '../../../../utils';
import useAuth from '../../../../hooks/useAuth';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Skeleton from '@mui/material/Skeleton';

export function PlanCardPayment({disabled, updatePlan, isLoadingPlans, setStoragePlans, setPlans, contextRenew, setCouponError, couponError, handleFirstInteraction, subscriptionCoupon, setSubscriptionCoupon, isMobile, plans, onSelect, isSelected, setSubmitCoupon }: PlanCardPaymentProps) {
  const { user } = useAuth();

  // //console.log('plans card payment', plans)

  const [selectedPlan, setSelectedPlan] = useState(() => {
    const savedPlanUpdated = localStorage.getItem(`selectedPlan_${user?.user_id}`);
    const savedPlan = savedPlanUpdated ? savedPlanUpdated : localStorage.getItem(`selectedPlanSignUp`);
    ////console.log('selectedPlan44444', savedPlan ?? 'basic')
    return plans[savedPlan] || plans['basic'];  // Inicia com o plano do localStorage ou o plano básico
  });

  useEffect(() => {
    const savedPlanUpdated = localStorage.getItem(`selectedPlan_${user?.user_id}`);
    const savedPlan = savedPlanUpdated ? savedPlanUpdated : localStorage.getItem(`selectedPlanSignUp`);
    setSelectedPlan(plans[savedPlan] || plans['basic']);
    localStorage.setItem(`selectedPlan_${user?.user_id}`, savedPlan ?? 'basic'); 
    ////console.log('selectedPlan33333', savedPlan ?? 'basic')

  }, [plans]); // Atualiza quando a lista de planos muda
  
  useEffect(() => {
    if (couponError) {
      setOpenCoupon(true)
    }
  }, [couponError])

  const [showSelect, setShowSelect] = useState(false);  // Controla a visibilidade do dropdown
  const [OpenCoupon, setOpenCoupon] = useState(false);
  const [savedCouponPlanCard, setSavedCouponPlanCard] = useState('');

  useEffect(() => {
    const planFromStorage = localStorage.getItem(`selectedPlan_${user?.user_id}`);
    if (planFromStorage && plans[planFromStorage]) {
      setSelectedPlan(plans[planFromStorage]);
    }
  }, [plans]); // Atualiza quando a lista de planos muda

  const handleSelect = (planKey) => {
    setSelectedPlan(plans[planKey]);
    if (user?.user_id == undefined) {
      return;
    }

    console.log('planKey', planKey)
    localStorage.setItem(`selectedPlan_${user?.user_id}`, planKey); // Salva a escolha no localStorage
    setShowSelect(false);  // Fecha o dropdown após a seleção
    onSelect(planKey);  // Chama a função onSelect passada como prop

  };
  
  function handleOpenCoupon(resetCoupon?: boolean) {
    if (resetCoupon) {
      ////console.log('entrou reset');
      // const defaultPlans = JSON.parse(localStorage.getItem('plansArraySignUp'));
      localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
      setStoragePlans(false)
      setSubmitCoupon(false)
      // setPlans(plans);
      setSubscriptionCoupon('')
      localStorage.removeItem(`savedCoupon_${user?.user_id}`);
      setCouponError(true)
    }
    setOpenCoupon(true)
  }

  function handleSaveCoupon() {
    setOpenCoupon(false)
    setSavedCouponPlanCard(subscriptionCoupon)
    setSubmitCoupon(true)
    setCouponError(false)
    localStorage.setItem(`savedCoupon_${user?.user_id}`, subscriptionCoupon); // Salva o cupom no localStorage
  }

  useEffect(() => {
    const savedCoupon = localStorage.getItem(`savedCoupon_${user?.user_id}`);
    if (savedCoupon) {
      setSavedCouponPlanCard(savedCoupon);
      setSubscriptionCoupon(savedCoupon); // Opcional, se você também quiser definir o estado do input com o cupom salvo
    }
  }, [user?.user_id]);
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveCoupon();  // Chama a função quando Enter é pressionado
    }
  };

  return (
    <S.CardContainer isMobile={isMobile} selected={isSelected}>
        <S.InnerContainer onClick={() => setShowSelect(!showSelect)}>
        {isLoadingPlans ? <Skeleton variant="rounded" width={'100%'} height={60} /> : (
            <S.Select>
                <S.LeftSide>
                    <S.PlanName>{selectedPlan?.planName}<CheckRoundedIcon/></S.PlanName>
                  
                    <p>{selectedPlan?.description}</p>
                </S.LeftSide>
                <S.RightSide>
                    <S.Price><span>R$</span>{formatValue(selectedPlan?.price)}</S.Price>
                    <S.Description>
                    {selectedPlan?.maxOrders == 0 ? 'p/ mês, pedidos ilimitados' : `p/ mês até ${selectedPlan?.maxOrders} pedidos`}
                    </S.Description>
                </S.RightSide>
                <ExpandMoreRoundedIcon style={{ transform: showSelect ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>
                {showSelect && (
                    <S.OptionsContainer isMobile={isMobile}>
                        {Object.keys(plans).map(key => (
                            <S.Option style={plans[key].planName === 'Plano Platinum' ? {border: '0.5px solid #c0fad5', borderRadius: '0 0 7px 7px'} : {}} key={key} onClick={() => handleSelect(key)}>
                                <S.LeftSide>
                                    <S.PlanNameOption style={plans[key].planName === 'Plano Platinum' ? {color: '#19c27e', fontWeight: '550', fontSize: '1rem'} : {}}>{plans[key].planName}</S.PlanNameOption>
                                    {plans[key].planName == 'Plano Platinum' && (
                                      
                                        <S.PixelContainer >
                                        <StarsRoundedIcon/>
                                        <p>Solomon Pixel</p>
                                        </S.PixelContainer>
                                    )}
                              
                                </S.LeftSide>
                                <S.RightSide>
                                    <S.PriceOption style={plans[key].planName === 'Plano Platinum' ? {fontSize: '2rem'} : {}}><span>R$</span>{formatValue(plans[key].price)}</S.PriceOption>
                                    <S.DescriptionOption>
                                    {plans[key].maxOrders == 0 ? 'p/ mês, pedidos ilimitados' : `p/ mês até ${plans[key].maxOrders} pedidos`}
                                    </S.DescriptionOption>
                                </S.RightSide>
                            </S.Option>
                        ))}
                    </S.OptionsContainer>
                )}
            </S.Select>
        )}
        </S.InnerContainer>

        {!(updatePlan === 'true' || contextRenew === 'renew') && (
          OpenCoupon ? (
            <div onKeyPress={handleKeyPress} style={{display: 'flex', justifyContent: 'center'}}>
            <S.InputArea  style={{ border: 'none' }} isMobile={isMobile}>
              <InputPayment
                onFocus={handleFirstInteraction}
                inputInformation={subscriptionCoupon}
                inputId="coupon"
                inputName="coupon"
                inputLabel="Adicionar cupom"
                inputType="text"
                width={isMobile ? '80vw' : '16rem'}
                setInputInformation={setSubscriptionCoupon}
              />
              <S.Button isMobile={isMobile} onClick={handleSaveCoupon}>
                <CheckCircleRoundedIcon />
                <p>Enviar</p>
              </S.Button>
            </S.InputArea>
            </div>
          ) : (
            <S.TopBottomArea
              savedCoupon={savedCouponPlanCard}
              onClick={() => {handleOpenCoupon(false)}}
              isMobile={isMobile}
            >
              <span>
                <LocalOfferRoundedIcon />
                {savedCouponPlanCard ? savedCouponPlanCard : 'Adicionar cupom'}
              </span>
              {savedCouponPlanCard ? <CloseRoundedIcon onClick={() => {handleOpenCoupon(true)}} /> : null}
            </S.TopBottomArea>
          )
        )}


      <S.Savings isMobile={isMobile} onClick={onSelect} selected={isSelected}>
        <S.BottomArea isMobile={isMobile} onClick={onSelect} selected={isSelected}>
            <h1>Vantagens</h1>
          <S.AdvantageColumnArea>
          <S.AdvantageColumn>
              <S.Advantage><CheckCircleRoundedIcon/>
              {selectedPlan?.planName == 'Plano Essencial' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook)</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook, Google)</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook, Google, Tiktok)</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Platinum' && (
                <p>Tracking 
                  <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> (Facebook, Google, Tiktok)</strong>
                </p>
              )}
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/>
                {selectedPlan?.planName == 'Plano Essencial' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 3</strong> Contas de anúncio Facebook
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 10</strong> Contas de anúncio Facebook
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Contas de anúncio Facebook <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitadas</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Platinum' && (
                <p>Contas de anúncio Facebook <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitadas</strong>
                </p>
              )}
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/>
              {selectedPlan?.planName == 'Plano Essencial' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 2</strong> Usuários
                </p>
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <p><strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> 3</strong> Usuários
                </p>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <p>Usuários <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitados</strong>
                </p>
              )}
              {selectedPlan?.planName == 'Plano Platinum' && (
                <p>Usuários <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Ilimitados</strong>
                </p>
              )}
              
              </S.Advantage>
              <S.Advantage><CheckCircleRoundedIcon/><p>Dashboard Financeiro</p></S.Advantage>
            </S.AdvantageColumn>
            <S.AdvantageColumn>
              <S.Advantage><CheckCircleRoundedIcon/><p>Análise de produtos</p></S.Advantage>
              <S.Advantage>
              {selectedPlan?.planName == 'Plano Essencial' && (
                null
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
               null
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                <>
                  <CheckCircleRoundedIcon/>
                  <p>Análise de clientes
                  </p>
                </>
              )}
              {selectedPlan?.planName == 'Plano Platinum' && (
                <>
                  <CheckCircleRoundedIcon/>
                  <p>Análise de clientes
                  </p>
                </>
              )}
              </S.Advantage>
              <S.Advantage>
              {selectedPlan?.planName == 'Plano Essencial' && (
                null
              )}
              {selectedPlan?.planName == 'Plano Premium' && (
                <>
                <CheckCircleRoundedIcon/>
                <p>Suporte <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}>VIP</strong>
                </p>
                </>
              )}
              {selectedPlan?.planName == 'Plano Avançado' && (
                  <>
                  <CheckCircleRoundedIcon/>
                  <p>Suporte <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Exclusivo</strong>
                  </p>
                  </>
              )}
              {selectedPlan?.planName == 'Plano Platinum' && (
                  <>
                  <CheckCircleRoundedIcon/>
                  <p>Suporte <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '0.8rem'}}> Exclusivo</strong>
                  </p>
                  </>
              )}
              </S.Advantage>
              <S.Advantage>
              {selectedPlan?.planName == 'Plano Platinum' && (
                  <>
                    <S.PixelContainer style={{boxShadow: '0 0 10px 10px rgba(25, 194, 126, 0.03)', marginTop: '-1px'}} >
                                        <StarsRoundedIcon/>
                                        <p>Solomon Pixel</p>
                                        </S.PixelContainer>
                  {/* <StarsRoundedIcon style={{width: '1.1rem'}}/>
                  <p style={{color: '#19c27e', fontSize: '1rem'}}> <strong style={{fontWeight: '550', color: '#19c27e', fontSize: '1em'}}>Solomon Pixel</strong>
                  </p> */}
                  </>
              )}
              </S.Advantage>
              
            </S.AdvantageColumn>
          </S.AdvantageColumnArea>
        </S.BottomArea>
        {/* <S.ExtraContainer>
          <PaidRoundedIcon/>
          {selectedPlan?.pricePerExtraOrder == 0 ? 'Pedidos extras gratuitos' : `R$${formatValue(selectedPlan?.pricePerExtraOrder)} por pedido extra`}
        </S.ExtraContainer> */}

      </S.Savings>

    </S.CardContainer>
  );
};
