import { LineTableTransactionsProps } from '../../../../@types';
import * as S from './style';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import { parseISO, format } from 'date-fns';

export function LineTableOrders({ key, transactionsFiltered }: LineTableTransactionsProps) {

    function formatDate(dateString: string) {
      // Parse a string date in ISO format without converting to local time
      const date = parseISO(dateString);
      return format(date, 'dd/MM/yyyy');
    }

    return (
        <S.Container>
      <S.Date>{formatDate(transactionsFiltered?.date)}</S.Date>
      <S.Payment>
        {(transactionsFiltered.status === 'CONFIRMED' || transactionsFiltered.status === 'RECEIVED') && (
          <S.SuccessTag>Aprovado</S.SuccessTag>
        )}
        {(transactionsFiltered.status === 'Pendente' || transactionsFiltered.status === 'PENDING')  && (
            <S.PendingTag>Pendente</S.PendingTag>
        )}
        {transactionsFiltered.status === 'REFUND' && (
            <S.RefundTag>Reembolso</S.RefundTag>
        )}
        {transactionsFiltered.isExtra && (
          <S.ExtraTag><ControlPointDuplicateRoundedIcon/>Transação Extra</S.ExtraTag>
        )}
        
      </S.Payment>
      <S.Value>
        R$ 
        {transactionsFiltered?.value?.toLocaleString('pt-Br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
      </S.Value>
    </S.Container>
    );
}
