import * as S from './style'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useColumnsTracking } from '../../../hooks/useColumnsTracking';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { ButtonApp } from '../../../components/ButtonApp';


export function EditColumnModal({onClose}: {onClose: () => void}) {
    const { columns, setColumns } = useColumnsTracking();
    const { currentCompany } = useAuth();

    // Estado temporário para armazenar alterações
    const [tempColumns, setTempColumns] = useState(columns);

    useEffect(() => {
        const savedColumns = localStorage.getItem(`columns_${currentCompany}`);
        if (savedColumns) {
            setColumns(JSON.parse(savedColumns));
        }
    }, []);

    // Atualizar o estado temporário ao arrastar e soltar
    function handleOnDragEnd(result: any) {
        if (!result.destination) return;
    
        const items = Array.from(tempColumns);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const updatedColumns = items.map((item, index) => ({ ...item, order: index + 1 }));
        setTempColumns(updatedColumns);
    }

    // Atualizar o estado temporário ao alternar visibilidade
    function toggleColumnVisibility(columnId: string) {
        const updatedColumns = tempColumns.map(column => {
            if (column.id === columnId) {
                return { ...column, visible: !column.visible };
            }
            return column;
        });
        setTempColumns(updatedColumns);
    }

    // Salvar alterações
    function handleSave() {
        setColumns(tempColumns);
        localStorage.setItem(`columns_${currentCompany}`, JSON.stringify(tempColumns));
        onClose(); // Fechar modal após salvar
    }
    return(
        <S.Container>
            <div style={{display: 'flex', gap: '1.5rem', alignItems: 'center'}}>
                <S.Icon onClick={onClose}>
                    <ArrowBackIcon />
                </S.Icon>
                <S.Title>
                    Editar colunas
                </S.Title>
            </div>
            {/* <S.Subtitle>
            Oculte e reorganize as métricas do seu gerenciador e as métricas Solomon.
            </S.Subtitle> */}
            <S.Line />

            <S.DragContainer>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="columns" direction='vertical'>
                        {(provided) => (
                        <S.ColumnItemArea className="columns" {...provided.droppableProps} ref={provided.innerRef}>
                            {tempColumns.map(({id, name, visible}, index) => (
                                <Draggable key={id} draggableId={id} index={index}>
                                    {(provided) => (
                                        <S.ColumnItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isVisible={visible}>
                                            <DragIndicatorRoundedIcon />
                                                {(id === 'salesUtm' || id === 'revenueUtm' || id === 'ticketUtm' || id === 'roasUtm' || id === 'cpaUtm' || id === 'profit' || id === 'productCostUtm' || id === 'margin'  || id === 'productCostUtm') && (
                                                    <S.UtmTitleArea>
                                                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="" /> 
                                                    <p>{name} Solomon</p>
                                                    
                                                    <S.UtmImgArea>
                                                    
                                                    </S.UtmImgArea>
                                                    </S.UtmTitleArea>
                                                )} 

                                                {(id !== 'salesUtm' && id !== 'revenueUtm' && id !== 'ticketUtm' && id !== 'roasUtm' && id !== 'cpaUtm' && id !== 'profit' && id !== 'margin' && id !== 'productCostUtm') && (
                                                <>
                                                <p>{name}</p>
                                                </>
                                                )} 
                                            <S.VisibilityIconArea onClick={() => toggleColumnVisibility(id)}>
                                                {visible ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                                            </S.VisibilityIconArea>
                                        </S.ColumnItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </S.ColumnItemArea>
                        )}
                    </Droppable>
                </DragDropContext>
            </S.DragContainer>
            <S.SaveButtonArea>
            <ButtonApp border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#999"
                    background="transparent"
                    // icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={onClose}
                    text="Cancelar"
                />

                <ButtonApp border=''
                    width="7.75rem"
                    height="2rem"
                    color="#fff"
                    background="#19c380"
                    // icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleSave}
                    text="Salvar"
                />
            </S.SaveButtonArea>
            
        </S.Container>
    )
}


function ArrowBackIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
    </svg>
    )
}
