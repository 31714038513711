import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getClientsInformation(company_id: string): Promise<DTO.clientsInformationDTO> {
    const data: DTO.clientsInformationDTO = await api.get(`${BASE_URL}/client-services/dashboard/customers/segmentation?company_id=${company_id}`);
    return data;
}

export async function getClientsInformationDownload(company_id: string , segmentation: string, filetype: string) {
    // Specify the URL for the request
    const url = `${BASE_URL}/client-services/io-handlers/customers/segmentation/download/${company_id}-${segmentation}-${filetype}.csv`;

    try {
        const response = await api.get(url, {
            responseType: 'blob', 
        });

        const date = new Date().toISOString().split('T')[0];

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.setAttribute('download', `${filetype.toUpperCase()} - ${segmentation} - ${date}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } catch (error) {
        console.error('Failed to download file:', error);
        throw error;
    }
}
