import  { forwardRef } from 'react';
import * as S from './style';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import CloseIcon from '@mui/icons-material/Close';
import { useCurrencyType } from '../../../hooks/useCurrencyType';

interface GraphicProps {
    graphicData: any; 
    setShowGraphic: (value: boolean) => void;
  };

const Graphic = forwardRef<HTMLDivElement, GraphicProps>(({ graphicData, setShowGraphic }, ref) => {
    const selectedData: { [key: string]: boolean } = {
        Gasto: true,
        'Lucro Aprovado': true,
        Vendas: true,
        'Vendas Solomon': true,
        Receita: true,
        'Receita Solomon': true,
        ROAS: true,
        'ROAS Solomon': true,
        CPA: true,
        'CPA Solomon': true,
        CPC: true,
        CPM: true,
        'Impressões': true,
        'Clicks': true,
        'Viu conteúdo': true,
        'Adicionou ao carrinho': true,
        'Iniciou checkout': true,
    };

    function handleSelectChanged(params: any, echart: any) {
        if (params?.name === 'Ver todos' && params?.selected['Ver todos'] === false) {  
            Object.keys(selectedData)?.forEach((key: string) => {
                selectedData[key] = false;
            });

            echart?.setOption({
                legend: {
                    selected: selectedData,
                },
            });
        } else if (params?.name === 'Ver todos' && params?.selected['Ver todos'] === true) {
            Object.keys(selectedData)?.forEach((key: string) => {
                selectedData[key] = true;
            });

            echart?.setOption({
                legend: {
                    selected: selectedData,
                },
            });
        } else if (params?.name !== 'Ver todos' && params?.selected['Ver todos'] === true) {
            selectedData['Ver todos'] = false;
            selectedData[params?.name] = false;
            echart?.setOption({
                legend: {
                    selected: selectedData,
                },
            });
        }
    }

    return (
        <S.Container ref={ref}>
            <S.Title>
                {graphicData?.name} <CloseIcon onClick={() => setShowGraphic(false)} sx={{ width: '15px', height: '15px', cursor: 'pointer' }} />
            </S.Title>
            <ReactEcharts
                option={getOption(graphicData)}
                onEvents={{
                    legendselectchanged: handleSelectChanged,
                }}
                style={{ height: '450px' }} // Defina a altura desejada aqui
            />
        </S.Container>
    );
});


export default Graphic;

const getOption = ({ xAxis, spendBySource, profitByUtmApproved, ordersBySource, ordersSolomon, revenueBySource, revenueSolomon, roasBySource, roasSolomon, cpaBySource, cpaSolomon, cpcBySource, cpmBySource, impressionsBySource, checkoutsBySource, pageViewsBySource, clicksBySource, addToCartsBySource }: any) => {
    
    const { currencySymbol } = useCurrencyType();

    const subtractArrays = (profitArray, spendArray) => {
        return profitArray.map((profit, index) => {
          const spend = spendArray[index] || 0; // Garante que usaremos 0 se spendArray não tiver um valor neste índice
          return profit - spend;
        });
      };
    
      let currencyTypeGraph = 'R$';

      if (currencySymbol === '$') {
        currencyTypeGraph = 'USD';
    } else if (currencySymbol === 'R$'){
        currencyTypeGraph = 'BRL';
    } else if (currencySymbol === '€') {
        currencyTypeGraph = 'EUR';
    } else if (currencySymbol === 'AU$') {
        currencyTypeGraph = 'AUD';
    } else if (currencySymbol === '£') {
        currencyTypeGraph = 'GBP';
    } else if (currencySymbol === 'COP') {
        currencyTypeGraph = 'COP';
    } else if (currencySymbol === 'ARS') {
        currencyTypeGraph = 'ARS';
    } else if (currencySymbol === 'C$') {
        currencyTypeGraph = 'CAD';
    } else if (currencySymbol === '¥') {
        currencyTypeGraph = 'JPY';
    } else if (currencySymbol === '¥') {
        currencyTypeGraph = 'JPY';
    }

      

    const calculateProfitByUtm = subtractArrays(profitByUtmApproved, spendBySource);
    
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
            formatter: function (params) {
                let result = params[0].axisValueLabel + '<br/>';
                params.forEach(param => {
                    let valueFormatted;
                    // Checa se a série é de um tipo que requer formatação monetária
                    if (['Receita', 'Lucro Aprovado', 'Gasto', 'CPA', 'CPC', 'CPM', 'Receita Solomon', 'CPA Solomon'].includes(param.seriesName)) {
                        valueFormatted = param.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: currencyTypeGraph
                        });
                    } else {
                        // Para outros tipos, como 'Impressões' ou 'Clicks', usa formatação normal
                        valueFormatted = param.value.toLocaleString('pt-BR');
                    }
                    result += `${param.marker}${param.seriesName}: ${valueFormatted}<br/>`;
                });
                return result;
            }
        },
        
        legend: {
            data: ['Ver todos', 'Gasto', 'Lucro Aprovado', 'Vendas', 'Vendas Solomon', 'Receita', 'Receita Solomon', 'ROAS', 'ROAS Solomon', 'CPA', 'CPA Solomon', 'CPC', 'CPM', 'Impressões', 'Clicks', 'Viu conteúdo', 'Adicionou ao carrinho', 'Iniciou checkout'],
            icon: 'roundRect',
            itemWidth: 10,
            itemGap: 20,
            itemHeight: 10,
            textStyle: {
                padding: [10, 0, 0, 0],
                font: 'Montserrat',
                color: 'gray',
                fontSize: 13.5,
                fontWeight: 540,
            },
            selected: {
                Gasto: true,
                'Lucro Aprovado': true,
                Vendas: true,
                'Vendas Solomon': true,
                Receita: true,
                'Receita Solomon': true,
                ROAS: true,
                'ROAS Solomon': true,
                CPA: true,
                'CPA Solomon': true,
                CPC: true,
                CPM: true,
                Impressões: true,
                Clicks: true,
                'Viu conteúdo': true,
                'Adicionou ao carrinho': true,
                'Iniciou checkout': true,
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            top: '30%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
            axisLine: {
                lineStyle: {
                    color: '#ccc',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                name: 'Monetário',
                position: 'left',
                axisLine: {
                    lineStyle: {
                        color: '#ccc',
                    },
                },
                nameTextStyle: {
                    color: '#999', // Define a cor do nome do eixo y
                    textWeight: 'bold',
                },
                axisLabel: {
                    formatter: 'R$ {value}', // Adiciona 'R$' antes dos valores
                    color: '#000', // Define a cor dos rótulos do eixo y para preto
                },
            },
            {
                type: 'value',
                name: 'Não monetário',
                position: 'right',
                axisLine: {
                    lineStyle: {
                        color: '#ccc',
                    },
                },
                nameTextStyle: {
                    color: '#999', // Define a cor do nome do eixo y
                    textWeight: 'bold',
                },
                axisLabel: {
                    color: '#000', // Define a cor dos rótulos do eixo y para preto
                },
                splitLine: {
                    show: false // Isso pode ajudar a tornar o gráfico menos poluído
                }
            }
        ],
        
        series: [
            {
                name: 'Ver todos',
                type: 'line',
                smooth: true,
                data: '',
                itemStyle: {
                    color: '#1e1e1e',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#1e1e1e',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'Gasto',
                type: 'line',
                smooth: true,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                data: spendBySource,
                itemStyle: {
                    color: '#c9302c',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#c9302c',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Lucro Aprovado',
                type: 'line',
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                smooth: true,
                data: calculateProfitByUtm,
                itemStyle: {
                    color: '#19c27e',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#19c27e',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'Vendas',
                type: 'line',
                smooth: true,
                yAxisIndex: 1, // Usando o primeiro eixo Y (Monetário)
                data: ordersBySource,
                itemStyle: {
                    color: '#d95',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#d95',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'Vendas Solomon',
                type: 'line',
                smooth: true,
                data: ordersSolomon,
                yAxisIndex: 1, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#ffda56',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#ffda56',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'Receita',
                type: 'line',
                smooth: true,
                data: revenueBySource,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#317d9c',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#317d9c',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'Receita Solomon',
                type: 'line',
                smooth: true,
                data:revenueSolomon,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#1f5a7a',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#1f5a7a',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'ROAS',
                type: 'line',
                smooth: true,
                data: roasBySource,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#84B398',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#84B398',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'ROAS Solomon',
                type: 'line',
                smooth: true,
                data: roasSolomon,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#6E8075',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#6E8075',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'CPA',
                type: 'line',
                smooth: true,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                data: cpaBySource,
                itemStyle: {
                    color: '#324D3E',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#324D3E ',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'CPA Solomon',
                type: 'line',
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                smooth: true,
                data: cpaSolomon,
                itemStyle: {
                    color: '#2B4D41',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#2B4D41',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'CPC',
                type: 'line',
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                smooth: true,
                data: cpcBySource,
                itemStyle: {
                    color: '#1aa58c',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#1aa58c',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },

            {
                name: 'CPM',
                type: 'line',
                smooth: true,
                data: cpmBySource,
                yAxisIndex: 0, // Usando o primeiro eixo Y (Monetário)
                itemStyle: {
                    color: '#1dd3c1',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#1dd3c1',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Impressões',
                type: 'line',
                yAxisIndex: 1, // Usando o segundo eixo Y (Contagens)
                smooth: true,
                data: impressionsBySource,
                itemStyle: {
                    color: '#FF6B6B',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#FF6B6B',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Clicks',
                type: 'line',
                smooth: true,
                yAxisIndex: 1, // Usando o segundo eixo Y (Contagens)
                data: clicksBySource,
                itemStyle: {
                    color: '#2C3E50',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#2C3E50',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Viu conteúdo',
                type: 'line',
                yAxisIndex: 1, // Usando o segundo eixo Y (Contagens)
                smooth: true,
                data: pageViewsBySource,
                itemStyle: {
                    color: '#9B59B6',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#9B59B6',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Adicionou ao carrinho',
                type: 'line',
                yAxisIndex: 1, // Usando o segundo eixo Y (Contagens)
                smooth: true,
                data: addToCartsBySource,
                itemStyle: {
                    color: '#A3E1D4',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#A3E1D4',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
            {
                name: 'Iniciou checkout',
                type: 'line',
                yAxisIndex: 1, // Usando o segundo eixo Y (Contagens)
                smooth: true,
                data: checkoutsBySource,
                itemStyle: {
                    color: '#000',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#000',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ]),
                },
            },
        ],
    };
};