import { divideVectors } from '../../../utils';

export function getMetricsTrackingByData(data: any, type: string) {
    const revenueBySource = parseFloat(
        (type === 'facebook'
            ? data?.facebook_campaigns_metrics?.revenue?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.revenue?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.revenue?.per_source?.metric
                : 0
        )?.toFixed(2)
    );

    const ordersBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.purchases?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.purchases?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.purchases?.per_source?.metric
                : 0;

    const spendBySource = parseFloat(
        (type === 'facebook'
            ? data?.facebook_campaigns_metrics?.spend?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.spend?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.spend?.per_source?.metric
                : 0
        )?.toFixed(2)
    );

    const clicksBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.clicks?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.clicks?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.clicks?.per_source?.metric
                : 0;

    const impressionsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.impressions?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.impressions?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.impressions?.per_source?.metric
                : 0;

    const revenueTotal = parseFloat((data?.utm_metrics?.utm_metrics?.revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenueApproved = parseFloat((data?.utm_metrics?.utm_metrics?.approved_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenuePending = parseFloat((data?.utm_metrics?.utm_metrics?.pending_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenueCancelled = parseFloat((data?.utm_metrics?.utm_metrics?.cancelled_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));

    const ordersTotal = data?.utm_metrics?.utm_metrics?.orders?.source?.[type]?.metrics ?? 0;
    const ordersApproved = data?.utm_metrics?.utm_metrics?.approved_orders?.source?.[type]?.metrics ?? 0;
    const ordersPending = data?.utm_metrics?.utm_metrics?.pending_orders?.source?.[type]?.metrics ?? 0;
    const ordersCancelled = data?.utm_metrics?.utm_metrics?.cancelled_orders?.source?.[type]?.metrics ?? 0;

    return {
        spendBySource,
        ordersTotal,
        ordersApproved,
        ordersPending,
        ordersCancelled,
        ordersBySource,
        revenueTotal,
        revenueApproved,
        revenuePending,
        revenueCancelled,
        revenueBySource,
        clicksBySource,
        impressionsBySource,
    };
}

export function getNonListedSourcesMetrics(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google', 'Whatsapp', 'Email/SMS', 'sem_atribuicao'];
    const nonListedSourcesMetrics: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    nonListedSourcesMetrics[sourceKey] = nonListedSourcesMetrics?.[sourceKey] || {};
                    nonListedSourcesMetrics[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    const organizedNonListedSourcesMetrics = Object.keys(nonListedSourcesMetrics).map((source: any) => {
        return { ...nonListedSourcesMetrics[source], sourceName: source };
    });

    return { nonListedSourcesMetrics, organizedNonListedSourcesMetrics };
}

export function getNonListedSourcesChart(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google', 'Whatsapp', 'Email/SMS', 'sem_atribuicao'];
    const nonListedSourcesChart: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    nonListedSourcesChart[sourceKey] = nonListedSourcesChart?.[sourceKey] || {};
                    nonListedSourcesChart[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    const organizedNonListedSourcesChart = Object.keys(nonListedSourcesChart).map((source: any) => {
        return { ...nonListedSourcesChart[source], sourceName: source };
    });

    return { nonListedSourcesChart, organizedNonListedSourcesChart };
}

export function getMetricsByAdsAccountFacebook(data: any) {
    const metricsByFacebookAdsAccount = [];
    const sumMetricsFacebook = {
        spendBySource: 0,
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        ordersBySource: 0,
        revenueBySource: 0,
        revenueTotal: 0,
        revenueApproved: 0,
        revenuePending: 0,
        revenueCancelled: 0,
        clicksBySource: 0,
        impressionsBySource: 0,
    };

    for (const accountId in data?.facebook_ad_accounts_metrics?.spend?.per_account?.metric) {
        const accountMetrics = data?.facebook_ad_accounts_metrics;
        const accountName = data?.facebook_ad_accounts_metrics?.aux?.accounts_maps?.[accountId];
        const spendBySource = parseFloat((accountMetrics?.spend?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const totalOrders = accountMetrics?.utm_purchases?.per_account?.metric?.[accountId] || 0;
        const approvedOrders = accountMetrics?.utm_approved_purchases?.per_account?.metric?.[accountId] || 0;
        const pendingOrders = accountMetrics?.utm_pending_purchases?.per_account?.metric?.[accountId] || 0;
        const cancelledOrders = accountMetrics?.utm_cancelled_purchases?.per_account?.metric?.[accountId] || 0;
        const ordersBySource = accountMetrics?.purchases?.per_account?.metric?.[accountId] || 0;
        const revenueBySource = parseFloat((accountMetrics?.revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueTotal = parseFloat((accountMetrics?.utm_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueApproved = parseFloat((accountMetrics?.utm_approved_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenuePending = parseFloat((accountMetrics?.utm_pending_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueCancelled = parseFloat((accountMetrics?.utm_cancelled_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const clicksBySource = accountMetrics?.clicks?.per_account?.metric?.[accountId] || 0;
        const impressionsBySource = accountMetrics?.impressions?.per_account?.metric?.[accountId] || 0;

        sumMetricsFacebook.spendBySource += spendBySource;
        sumMetricsFacebook.totalOrders += totalOrders;
        sumMetricsFacebook.approvedOrders += approvedOrders;
        sumMetricsFacebook.pendingOrders += pendingOrders;
        sumMetricsFacebook.cancelledOrders += cancelledOrders;
        sumMetricsFacebook.ordersBySource += ordersBySource;
        sumMetricsFacebook.revenueBySource += revenueBySource;
        sumMetricsFacebook.revenueTotal += revenueTotal;
        sumMetricsFacebook.revenueApproved += revenueApproved;
        sumMetricsFacebook.revenuePending += revenuePending;
        sumMetricsFacebook.revenueCancelled += revenueCancelled;
        sumMetricsFacebook.clicksBySource += clicksBySource;
        sumMetricsFacebook.impressionsBySource += impressionsBySource;

        metricsByFacebookAdsAccount.push({
            accountId,
            accountName,
            spendBySource,
            totalOrders,
            approvedOrders,
            pendingOrders,
            cancelledOrders,
            ordersBySource,
            revenueBySource,
            revenueTotal,
            revenueApproved,
            revenuePending,
            revenueCancelled,
            clicksBySource,
            impressionsBySource,
        });
    }

    return {
        metricsByFacebookAdsAccount,
        sumMetricsFacebook,
    };
}

export function getOthersMetrics(data: any) {
    const otherMetrics = {
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        totalRevenue: 0,
        approvedRevenue: 0,
        pendingRevenue: 0,
        cancelledRevenue: 0,
    };

    const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);

    Object.keys(nonListedSourcesMetrics)?.map((source: string) => {
        const { orders, approved_orders, pending_orders, cancelled_orders, revenue, approved_revenue, pending_revenue, cancelled_revenue } = nonListedSourcesMetrics?.[source];
        otherMetrics.totalOrders += orders?.metrics;
        otherMetrics.approvedOrders += approved_orders?.metrics;
        otherMetrics.pendingOrders += pending_orders?.metrics;
        otherMetrics.cancelledOrders += cancelled_orders?.metrics;

        otherMetrics.totalRevenue += revenue?.metrics;
        otherMetrics.approvedRevenue += approved_revenue?.metrics;
        otherMetrics.pendingRevenue += pending_revenue?.metrics;
        otherMetrics.cancelledRevenue += cancelled_revenue?.metrics;
    });

    return { otherMetrics };
}

export function getTotalLineByChannel(data: any) {
    const totalLine = {
        spendBySource: 0,
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        ordersBySource: 0,
        revenueBySource: 0,
        revenueTotal: 0,
        revenueApproved: 0,
        revenuePending: 0,
        revenueCancelled: 0,
        clicksBySource: 0,
        impressionsBySource: 0,
    };

    const types = ['facebook', 'tiktok', 'google', 'Whatsapp', 'Email/SMS', 'sem_atribuicao'];

    for (const type of types) {
        const metrics = getMetricsTrackingByData(data, type);
        totalLine.spendBySource += metrics.spendBySource;
        totalLine.totalOrders += metrics.ordersTotal;
        totalLine.approvedOrders += metrics.ordersApproved;
        totalLine.pendingOrders += metrics.ordersPending;
        totalLine.cancelledOrders += metrics.ordersCancelled;
        totalLine.ordersBySource += metrics.ordersBySource;
        totalLine.revenueBySource += metrics.revenueBySource;
        totalLine.revenueTotal += metrics.revenueTotal;
        totalLine.revenueApproved += metrics.revenueApproved;
        totalLine.revenuePending += metrics.revenuePending;
        totalLine.revenueCancelled += metrics.revenueCancelled;
        totalLine.clicksBySource += metrics.clicksBySource;
        totalLine.impressionsBySource += metrics.impressionsBySource;
    }

    const { otherMetrics } = getOthersMetrics(data);

    totalLine.totalOrders += otherMetrics.totalOrders;
    totalLine.approvedOrders += otherMetrics.approvedOrders;
    totalLine.pendingOrders += otherMetrics.pendingOrders;
    totalLine.cancelledOrders += otherMetrics.cancelledOrders;
    totalLine.revenueTotal += otherMetrics.totalRevenue;
    totalLine.revenueApproved += otherMetrics.approvedRevenue;
    totalLine.revenuePending += otherMetrics.pendingRevenue;
    totalLine.revenueCancelled += otherMetrics.cancelledRevenue;

    return totalLine;
}

export function getTotalLineByCampaign(dataFiltred: any, type: string) {
    const totalLine = {
        spendBySource: 0,
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        ordersBySource: 0,
        revenueBySource: 0,
        revenueTotal: 0,
        revenueApproved: 0,
        revenuePending: 0,
        revenueCancelled: 0,
        clicksBySource: 0,
        impressionsBySource: 0,
    };

    const types = ['Whatsapp', 'Email/SMS', 'sem_atribuicao', 'outros'];

    if (types.includes(type)) {
        for (const campaign of dataFiltred) {
            totalLine.spendBySource += 0;
            totalLine.totalOrders += campaign?.orders?.metrics;
            totalLine.approvedOrders += campaign?.approved_orders?.metrics;
            totalLine.pendingOrders += campaign?.pending_orders?.metrics;
            totalLine.cancelledOrders += campaign?.cancelled_orders?.metrics;
            totalLine.ordersBySource += 0;
            totalLine.revenueBySource += 0;
            totalLine.revenueTotal += campaign?.revenue?.metrics;
            totalLine.revenueApproved += campaign?.approved_revenue?.metrics;
            totalLine.revenuePending += campaign?.pending_revenue?.metrics;
            totalLine.revenueCancelled += campaign?.cancelled_revenue?.metrics;
            totalLine.clicksBySource += 0;
            totalLine.impressionsBySource += 0;
        }

        return totalLine;
    }

    for (const campaign of dataFiltred) {
        totalLine.spendBySource += campaign?.spendBySource;
        totalLine.totalOrders += campaign?.totalOrders;
        totalLine.approvedOrders += campaign?.approvedOrders;
        totalLine.pendingOrders += campaign?.pendingOrders;
        totalLine.cancelledOrders += campaign?.cancelledOrders;
        totalLine.ordersBySource += campaign?.ordersBySource;
        totalLine.revenueBySource += campaign?.revenueBySource;
        totalLine.revenueTotal += campaign?.revenueTotal;
        totalLine.revenueApproved += campaign?.revenueApproved;
        totalLine.revenuePending += campaign?.revenuePending;
        totalLine.revenueCancelled += campaign?.revenueCancelled;
        totalLine.clicksBySource += campaign?.clicksBySource;
        totalLine.impressionsBySource += campaign?.impressionsBySource;
    }

    return totalLine;
}

export function getMetricsByChannelFacebook(data: any) {
    const { metricsByFacebookAdsAccount, sumMetricsFacebook } = getMetricsByAdsAccountFacebook(data);
    const metrics = getMetricsTrackingByData(data, 'facebook');

    if (sumMetricsFacebook.totalOrders < metrics.ordersTotal) {
        metricsByFacebookAdsAccount.push({
            accountId: 'sem_atribuicao',
            accountName: 'Sem atribuição',
            spendBySource: metrics.spendBySource - sumMetricsFacebook.spendBySource,
            totalOrders: metrics.ordersTotal - sumMetricsFacebook.totalOrders,
            approvedOrders: metrics.ordersApproved - sumMetricsFacebook.approvedOrders,
            pendingOrders: metrics.ordersPending - sumMetricsFacebook.pendingOrders,
            cancelledOrders: metrics.ordersCancelled - sumMetricsFacebook.cancelledOrders,
            ordersBySource: metrics.ordersBySource - sumMetricsFacebook.ordersBySource,
            revenueBySource: metrics.revenueBySource - sumMetricsFacebook.revenueBySource,
            revenueTotal: metrics.revenueTotal - sumMetricsFacebook.revenueTotal,
            revenueApproved: metrics.revenueApproved - sumMetricsFacebook.revenueApproved,
            revenuePending: metrics.revenuePending - sumMetricsFacebook.revenuePending,
            revenueCancelled: metrics.revenueCancelled - sumMetricsFacebook.revenueCancelled,
            clicksBySource: metrics.clicksBySource - sumMetricsFacebook.clicksBySource,
            impressionsBySource: metrics.impressionsBySource - sumMetricsFacebook.impressionsBySource,
        });
    }

    return {
        metricsByFacebookAdsAccount,
    };
}

export function getMetricsCampaignsByChannel(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google'];
    const getDataSourcesMetrics: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    getDataSourcesMetrics[sourceKey] = getDataSourcesMetrics?.[sourceKey] || {};
                    getDataSourcesMetrics[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    return { getDataSourcesMetrics };
}

export function organizeDataByCampaign(dataMetrics: any): any {
    const types: any = {};

    for (const data in dataMetrics) {
        if (dataMetrics.hasOwnProperty(data)) {
            types[data] = {};
            const campaigns = dataMetrics[data];
            for (const campanhaKey in campaigns) {
                if (campaigns.hasOwnProperty(campanhaKey)) {
                    const campanha = campaigns[campanhaKey];
                    for (const campaignKey in campanha) {
                        if (campanha.hasOwnProperty(campaignKey)) {
                            const campaignData = campanha[campaignKey];
                            for (const metricKey in campaignData) {
                                if (campaignData.hasOwnProperty(metricKey)) {
                                    if (!types[data][metricKey]) {
                                        types[data][metricKey] = {};
                                    }
                                    types[data][metricKey][campanhaKey] = campaignData[metricKey];
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return types;
}

export function getAccountCampaignData(data: any, type: string, accountId: string, channelSelect: string) {
    const types = ['Whatsapp', 'Email/SMS', 'sem_atribuicao'];

    if (types.includes(type)) {
        const { getDataSourcesMetrics } = getMetricsCampaignsByChannel(data);

        const organizedData = organizeDataByCampaign(getDataSourcesMetrics)?.[type] ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData)
            ?.filter((key) => key !== 'y_axis')
            .map((key) => {
                return { ...organizedData[key], campaignName: key };
            });

        const newFilteredAccountCampaignData = filtredAccountCampaignData.map((campaign: any) => {
            return {
                ...campaign,
                roasBySource: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueBySource / campaign?.spendBySource).toFixed(2)) : 0,
                roasTotal: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueTotal / campaign?.spendBySource).toFixed(2)) : 0,
                roasApproved: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueApproved / campaign?.spendBySource).toFixed(2)) : 0,
                roasPending: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenuePending / campaign?.spendBySource).toFixed(2)) : 0,
                roasCancelled: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueCancelled / campaign?.spendBySource).toFixed(2)) : 0,

                cpaBySource: campaign?.ordersBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.ordersBySource).toFixed(2)) : 0,
                cpaTotal: campaign?.totalOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.totalOrders).toFixed(2)) : 0,
                cpaApproved: campaign?.approvedOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.approvedOrders).toFixed(2)) : 0,
                cpaPending: campaign?.pendingOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.pendingOrders).toFixed(2)) : 0,
                cpaCancelled: campaign?.cancelledOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.cancelledOrders).toFixed(2)) : 0,

                cpcBySource: campaign?.clicksBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.clicksBySource).toFixed(2)) : 0,
                cpmBySource: campaign?.impressionsBySource !== 0 ? parseFloat(((1000 * campaign?.spendBySource) / campaign?.impressionsBySource).toFixed(2)) : 0,
            };
        });

        return newFilteredAccountCampaignData;
    } else if (type === 'outros') {
        const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);

        const organizedData = organizeDataByCampaign(nonListedSourcesMetrics) ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData?.[channelSelect])
            ?.filter((key) => key !== 'y_axis' && key !== 'x_axis')
            .map((key) => {
                return { ...organizedData?.[channelSelect]?.[key], campaignName: key };
            });
        const newFilteredAccountCampaignData = filtredAccountCampaignData.map((campaign: any) => {
            return {
                ...campaign,
                roasBySource: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueBySource / campaign?.spendBySource).toFixed(2)) : 0,
                roasTotal: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueTotal / campaign?.spendBySource).toFixed(2)) : 0,
                roasApproved: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueApproved / campaign?.spendBySource).toFixed(2)) : 0,
                roasPending: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenuePending / campaign?.spendBySource).toFixed(2)) : 0,
                roasCancelled: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueCancelled / campaign?.spendBySource).toFixed(2)) : 0,

                cpaBySource: campaign?.ordersBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.ordersBySource).toFixed(2)) : 0,
                cpaTotal: campaign?.totalOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.totalOrders).toFixed(2)) : 0,
                cpaApproved: campaign?.approvedOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.approvedOrders).toFixed(2)) : 0,
                cpaPending: campaign?.pendingOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.pendingOrders).toFixed(2)) : 0,
                cpaCancelled: campaign?.cancelledOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.cancelledOrders).toFixed(2)) : 0,

                cpcBySource: campaign?.clicksBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.clicksBySource).toFixed(2)) : 0,
                cpmBySource: campaign?.impressionsBySource !== 0 ? parseFloat(((1000 * campaign?.spendBySource) / campaign?.impressionsBySource).toFixed(2)) : 0,
            };
        });

        return newFilteredAccountCampaignData;
    } else {
        const accountCampaignsData: any = {};
        const campaignToAccountMap =
            type === 'facebook'
                ? data?.facebook_campaigns_metrics?.aux?.campaigns_accounts
                : type === 'tiktok'
                  ? data?.tiktok_campaigns_metrics?.aux?.campaigns_accounts
                  : type === 'google'
                    ? data?.google_campaigns_metrics?.aux?.campaigns_accounts
                    : {};

        for (const campaignId in campaignToAccountMap) {
            const accountId = campaignToAccountMap?.[campaignId];

            const campaignMetrics =
                type === 'facebook' ? data?.facebook_campaigns_metrics : type === 'tiktok' ? data?.tiktok_campaigns_metrics : type === 'google' ? data?.google_campaigns_metrics : {};

            const spendBySource = campaignMetrics?.spend?.per_campaign?.metric?.[campaignId];
            const totalOrders = campaignMetrics?.utm_purchases?.per_campaign?.metric?.[campaignId];
            const approvedOrders = campaignMetrics?.utm_approved_purchases?.per_campaign?.metric?.[campaignId];
            const pendingOrders = campaignMetrics?.utm_pending_purchases?.per_campaign?.metric?.[campaignId];
            const cancelledOrders = campaignMetrics?.utm_cancelled_purchases?.per_campaign?.metric?.[campaignId];
            const ordersBySource = campaignMetrics?.purchases?.per_campaign?.metric?.[campaignId];
            const revenueBySource = campaignMetrics?.revenue?.per_campaign?.metric?.[campaignId];
            const revenueTotal = campaignMetrics?.utm_revenue?.per_campaign?.metric?.[campaignId];
            const revenueApproved = campaignMetrics?.utm_approved_revenue?.per_campaign?.metric?.[campaignId];
            const revenuePending = campaignMetrics?.utm_pending_revenue?.per_campaign?.metric?.[campaignId];
            const revenueCancelled = campaignMetrics?.utm_cancelled_revenue?.per_campaign?.metric?.[campaignId];
            const clicksBySource = campaignMetrics?.clicks?.per_campaign?.metric?.[campaignId];
            const impressionsBySource = campaignMetrics?.impressions?.per_campaign?.metric?.[campaignId];

            if (!accountCampaignsData?.[accountId]) {
                accountCampaignsData[accountId] = {
                    campaigns: [],
                };
            }

            accountCampaignsData?.[accountId]?.campaigns?.push({
                campaignId,
                campaignName: campaignMetrics?.aux?.campaigns_names?.[campaignId],
                spendBySource,
                totalOrders,
                approvedOrders,
                pendingOrders,
                cancelledOrders,
                ordersBySource,
                revenueBySource,
                revenueTotal,
                revenueApproved,
                revenuePending,
                revenueCancelled,
                clicksBySource,
                impressionsBySource,
            });
        }

        const filtredAccountCampaignDataFacebookWithoutAccountId: any = [];

        const isFacebookWithoutAccountId = type === 'facebook' && accountId === '';

        if (isFacebookWithoutAccountId) {
            Object.keys(accountCampaignsData).map((key) => {
                accountCampaignsData[key].campaigns.map((campaign: any) => {
                    filtredAccountCampaignDataFacebookWithoutAccountId.push(campaign);
                });
            });
        }

        const filtredAccountCampaignData = isFacebookWithoutAccountId ? filtredAccountCampaignDataFacebookWithoutAccountId : accountCampaignsData?.[accountId]?.campaigns ?? [];

        const newFilteredAccountCampaignData = filtredAccountCampaignData.map((campaign: any) => {
            return {
                ...campaign,
                roasBySource: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueBySource / campaign?.spendBySource).toFixed(2)) : 0,
                roasTotal: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueTotal / campaign?.spendBySource).toFixed(2)) : 0,
                roasApproved: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueApproved / campaign?.spendBySource).toFixed(2)) : 0,
                roasPending: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenuePending / campaign?.spendBySource).toFixed(2)) : 0,
                roasCancelled: campaign?.spendBySource !== 0 ? parseFloat((campaign?.revenueCancelled / campaign?.spendBySource).toFixed(2)) : 0,

                cpaBySource: campaign?.ordersBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.ordersBySource).toFixed(2)) : 0,
                cpaTotal: campaign?.totalOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.totalOrders).toFixed(2)) : 0,
                cpaApproved: campaign?.approvedOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.approvedOrders).toFixed(2)) : 0,
                cpaPending: campaign?.pendingOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.pendingOrders).toFixed(2)) : 0,
                cpaCancelled: campaign?.cancelledOrders !== 0 ? parseFloat((campaign?.spendBySource / campaign?.cancelledOrders).toFixed(2)) : 0,

                cpcBySource: campaign?.clicksBySource !== 0 ? parseFloat((campaign?.spendBySource / campaign?.clicksBySource).toFixed(2)) : 0,
                cpmBySource: campaign?.impressionsBySource !== 0 ? parseFloat(((1000 * campaign?.spendBySource) / campaign?.impressionsBySource).toFixed(2)) : 0,
            };
        });

        return newFilteredAccountCampaignData;
    }
}

export function getAdsSetData(data: any, type: string, campaignId: string) {
    const adsSetData: any = {};
    const adsSetMap =
        type === 'facebook'
            ? data?.facebook_adgroups_metrics?.aux?.adgroups_campaigns
            : type === 'tiktok'
              ? data?.tiktok_adgroups_metrics?.aux?.adgroups_campaigns
              : type === 'google'
                ? data?.google_adgroups_metrics?.aux?.adgroups_campaigns
                : {};

    for (const adsSetId in adsSetMap) {
        const adsSet = adsSetMap?.[adsSetId];
        const adsSetMetrics = type === 'facebook' ? data?.facebook_adgroups_metrics : type === 'tiktok' ? data?.tiktok_adgroups_metrics : type === 'google' ? data?.google_adgroups_metrics : {};

        const spendBySource = adsSetMetrics?.spend?.per_adgroup?.metric?.[adsSetId];
        const totalOrders = adsSetMetrics?.utm_purchases?.per_adgroup?.metric?.[adsSetId];
        const approvedOrders = adsSetMetrics?.utm_approved_purchases?.per_adgroup?.metric?.[adsSetId];
        const pendingOrders = adsSetMetrics?.utm_pending_purchases?.per_adgroup?.metric?.[adsSetId];
        const cancelledOrders = adsSetMetrics?.utm_cancelled_purchases?.per_adgroup?.metric?.[adsSetId];
        const ordersBySource = adsSetMetrics?.purchases?.per_adgroup?.metric?.[adsSetId];
        const revenueBySource = adsSetMetrics?.revenue?.per_adgroup?.metric?.[adsSetId];
        const revenueTotal = adsSetMetrics?.utm_revenue?.per_adgroup?.metric?.[adsSetId];
        const revenueApproved = adsSetMetrics?.utm_approved_revenue?.per_adgroup?.metric?.[adsSetId];
        const revenuePending = adsSetMetrics?.utm_pending_revenue?.per_adgroup?.metric?.[adsSetId];
        const revenueCancelled = adsSetMetrics?.utm_cancelled_revenue?.per_adgroup?.metric?.[adsSetId];
        const clicksBySource = adsSetMetrics?.clicks?.per_adgroup?.metric?.[adsSetId];
        const impressionsBySource = adsSetMetrics?.impressions?.per_adgroup?.metric?.[adsSetId];

        if (!adsSetData?.[adsSet]) {
            adsSetData[adsSet] = {
                adsSet: [],
            };
        }

        adsSetData?.[adsSet]?.adsSet?.push({
            adsSetId,
            adsSetName: adsSetMetrics?.aux?.adgroups_names?.[adsSetId],
            spendBySource,
            totalOrders,
            approvedOrders,
            pendingOrders,
            cancelledOrders,
            ordersBySource,
            revenueBySource,
            revenueTotal,
            revenueApproved,
            revenuePending,
            revenueCancelled,
            clicksBySource,
            impressionsBySource,
        });
    }

    const filtredAdsSetData = adsSetData?.[campaignId]?.adsSet ?? [];

    const newFiltredAdsSetData = filtredAdsSetData?.map((adsSet: any) => {
        return {
            ...adsSet,
            roasBySource: adsSet?.spendBySource !== 0 ? parseFloat((adsSet?.revenueBySource / adsSet?.spendBySource).toFixed(2)) : 0,
            roasTotal: adsSet?.spendBySource !== 0 ? parseFloat((adsSet?.revenueTotal / adsSet?.spendBySource).toFixed(2)) : 0,
            roasApproved: adsSet?.spendBySource !== 0 ? parseFloat((adsSet?.revenueApproved / adsSet?.spendBySource).toFixed(2)) : 0,
            roasPending: adsSet?.spendBySource !== 0 ? parseFloat((adsSet?.revenuePending / adsSet?.spendBySource).toFixed(2)) : 0,
            roasCancelled: adsSet?.spendBySource !== 0 ? parseFloat((adsSet?.revenueCancelled / adsSet?.spendBySource).toFixed(2)) : 0,

            cpaBySource: adsSet?.ordersBySource !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.ordersBySource).toFixed(2)) : 0,
            cpaTotal: adsSet?.totalOrders !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.totalOrders).toFixed(2)) : 0,
            cpaApproved: adsSet?.approvedOrders !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.approvedOrders).toFixed(2)) : 0,
            cpaPending: adsSet?.pendingOrders !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.pendingOrders).toFixed(2)) : 0,
            cpaCancelled: adsSet?.cancelledOrders !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.cancelledOrders).toFixed(2)) : 0,

            cpcBySource: adsSet?.clicksBySource !== 0 ? parseFloat((adsSet?.spendBySource / adsSet?.clicksBySource).toFixed(2)) : 0,
            cpmBySource: adsSet?.impressionsBySource !== 0 ? parseFloat(((1000 * adsSet?.spendBySource) / adsSet?.impressionsBySource).toFixed(2)) : 0,
        };
    });

    return newFiltredAdsSetData;
}

export function getTotalLineByAdsSet(dataFiltred: any, type: string) {
    const totalLine = {
        spendBySource: 0,
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        ordersBySource: 0,
        revenueBySource: 0,
        revenueTotal: 0,
        revenueApproved: 0,
        revenuePending: 0,
        revenueCancelled: 0,
        clicksBySource: 0,
        impressionsBySource: 0,
    };

    const types = ['Whatsapp', 'Email/SMS', 'sem_atribuicao', 'outros'];

    if (types.includes(type)) {
        return totalLine;
    }

    for (const adsSet of dataFiltred) {
        totalLine.spendBySource += adsSet?.spendBySource;
        totalLine.totalOrders += adsSet?.totalOrders;
        totalLine.approvedOrders += adsSet?.approvedOrders;
        totalLine.pendingOrders += adsSet?.pendingOrders;
        totalLine.cancelledOrders += adsSet?.cancelledOrders;
        totalLine.ordersBySource += adsSet?.ordersBySource;
        totalLine.revenueBySource += adsSet?.revenueBySource;
        totalLine.revenueTotal += adsSet?.revenueTotal;
        totalLine.revenueApproved += adsSet?.revenueApproved;
        totalLine.revenuePending += adsSet?.revenuePending;
        totalLine.revenueCancelled += adsSet?.revenueCancelled;
        totalLine.clicksBySource += adsSet?.clicksBySource;
        totalLine.impressionsBySource += adsSet?.impressionsBySource;
    }

    return totalLine;
}

export function getAdsData(data: any, type: string, adsSetId: string) {
    const adsData: any = {};
    const adsMap =
        type === 'facebook'
            ? data?.facebook_ads_metrics?.aux?.ads_objects
            : type === 'tiktok'
              ? data?.tiktok_ads_metrics?.aux?.ads_objects
              : type === 'google'
                ? data?.google_ads_metrics?.aux?.ads_objects
                : {};

    for (const adInfo in adsMap) {
        const ad = adsMap?.[adInfo]?.adgroup;

        const adsMetrics = type === 'facebook' ? data?.facebook_ads_metrics : type === 'tiktok' ? data?.tiktok_ads_metrics : type === 'google' ? data?.google_ads_metrics : {};

        const spendBySource = adsMetrics?.spend?.per_ad?.metric?.[adInfo];
        const totalOrders = adsMetrics?.utm_purchases?.per_ad?.metric?.[adInfo];
        const approvedOrders = adsMetrics?.utm_approved_purchases?.per_ad?.metric?.[adInfo];
        const pendingOrders = adsMetrics?.utm_pending_purchases?.per_ad?.metric?.[adInfo];
        const cancelledOrders = adsMetrics?.utm_cancelled_purchases?.per_ad?.metric?.[adInfo];
        const ordersBySource = adsMetrics?.purchases?.per_ad?.metric?.[adInfo];
        const revenueBySource = adsMetrics?.revenue?.per_ad?.metric?.[adInfo];
        const revenueTotal = adsMetrics?.utm_revenue?.per_ad?.metric?.[adInfo];
        const revenueApproved = adsMetrics?.utm_approved_revenue?.per_ad?.metric?.[adInfo];
        const revenuePending = adsMetrics?.utm_pending_revenue?.per_ad?.metric?.[adInfo];
        const revenueCancelled = adsMetrics?.utm_cancelled_revenue?.per_ad?.metric?.[adInfo];
        const clicksBySource = adsMetrics?.clicks?.per_ad?.metric?.[adInfo];
        const impressionsBySource = adsMetrics?.impressions?.per_ad?.metric?.[adInfo];

        if (!adsData?.[ad]) {
            adsData[ad] = {
                ads: [],
            };
        }

        adsData?.[ad]?.ads?.push({
            adInfo,
            adName: adsMetrics?.aux?.ads_names?.[adInfo],
            spendBySource,
            totalOrders,
            approvedOrders,
            pendingOrders,
            cancelledOrders,
            ordersBySource,
            revenueBySource,
            revenueTotal,
            revenueApproved,
            revenuePending,
            revenueCancelled,
            clicksBySource,
            impressionsBySource,
        });
    }

    const filtredAdsData = adsData?.[adsSetId]?.ads ?? [];

    const newFiltredAdsData = filtredAdsData?.map((ads: any) => {
        return {
            ...ads,
            roasBySource: ads?.spendBySource !== 0 ? parseFloat((ads?.revenueBySource / ads?.spendBySource).toFixed(2)) : 0,
            roasTotal: ads?.spendBySource !== 0 ? parseFloat((ads?.revenueTotal / ads?.spendBySource).toFixed(2)) : 0,
            roasApproved: ads?.spendBySource !== 0 ? parseFloat((ads?.revenueApproved / ads?.spendBySource).toFixed(2)) : 0,
            roasPending: ads?.spendBySource !== 0 ? parseFloat((ads?.revenuePending / ads?.spendBySource).toFixed(2)) : 0,
            roasCancelled: ads?.spendBySource !== 0 ? parseFloat((ads?.revenueCancelled / ads?.spendBySource).toFixed(2)) : 0,

            cpaBySource: ads?.ordersBySource !== 0 ? parseFloat((ads?.spendBySource / ads?.ordersBySource).toFixed(2)) : 0,
            cpaTotal: ads?.totalOrders !== 0 ? parseFloat((ads?.spendBySource / ads?.totalOrders).toFixed(2)) : 0,
            cpaApproved: ads?.approvedOrders !== 0 ? parseFloat((ads?.spendBySource / ads?.approvedOrders).toFixed(2)) : 0,
            cpaPending: ads?.pendingOrders !== 0 ? parseFloat((ads?.spendBySource / ads?.pendingOrders).toFixed(2)) : 0,
            cpaCancelled: ads?.cancelledOrders !== 0 ? parseFloat((ads?.spendBySource / ads?.cancelledOrders).toFixed(2)) : 0,

            cpcBySource: ads?.clicksBySource !== 0 ? parseFloat((ads?.spendBySource / ads?.clicksBySource).toFixed(2)) : 0,
            cpmBySource: ads?.impressionsBySource !== 0 ? parseFloat(((1000 * ads?.spendBySource) / ads?.impressionsBySource).toFixed(2)) : 0,
        };
    });

    return newFiltredAdsData;
}

export function getTotalLineAds(dataFiltred: any, type: string) {
    const totalLine = {
        spendBySource: 0,
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        ordersBySource: 0,
        revenueBySource: 0,
        revenueTotal: 0,
        revenueApproved: 0,
        revenuePending: 0,
        revenueCancelled: 0,
        clicksBySource: 0,
        impressionsBySource: 0,
    };

    const types = ['Whatsapp', 'Email/SMS', 'sem_atribuicao', 'outros'];

    if (types.includes(type)) {
        return totalLine;
    }

    for (const ad of dataFiltred) {
        totalLine.spendBySource += ad?.spendBySource;
        totalLine.totalOrders += ad?.totalOrders;
        totalLine.approvedOrders += ad?.approvedOrders;
        totalLine.pendingOrders += ad?.pendingOrders;
        totalLine.cancelledOrders += ad?.cancelledOrders;
        totalLine.ordersBySource += ad?.ordersBySource;
        totalLine.revenueBySource += ad?.revenueBySource;
        totalLine.revenueTotal += ad?.revenueTotal;
        totalLine.revenueApproved += ad?.revenueApproved;
        totalLine.revenuePending += ad?.revenuePending;
        totalLine.revenueCancelled += ad?.revenueCancelled;
        totalLine.clicksBySource += ad?.clicksBySource;
        totalLine.impressionsBySource += ad?.impressionsBySource;
    }

    return totalLine;
}

export function getChartsTrackignByData(data: any, type: string) {
    const types = ['facebook', 'google', 'tiktok'];

    if (!types.includes(type)) {
        return {};
    }

    const revenueBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.revenue?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.revenue?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.revenue?.per_source?.chart
                : [];

    const ordersBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.purchases?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.purchases?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.purchases?.per_source?.chart
                : [];

    const spendBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.spend?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.spend?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.spend?.per_source?.chart
                : [];

    const clicksBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.clicks?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.clicks?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.clicks?.per_source?.chart
                : [];

    const impressionsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.impressions?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.impressions?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.impressions?.per_source?.chart
                : [];

    const revenueTotal = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok' ? data?.tiktok_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis : (type = 'google' ? data?.google_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis : [])));

    const revenueApproved = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis : [])));

    const revenuePending = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis : [])));

    const revenueCancelled = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis : [])));

    const ordersTotal = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis : [])));

    const ordersApproved = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis : [])));

    const ordersPending = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis : [])));

    const ordersCancelled = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis : [])));

    return {
        name: type === 'facebook' ? 'Facebook' : type === 'tiktok' ? 'Tiktok' : 'Google',
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: ordersTotal,
        ordersApproved: ordersApproved,
        ordersPending: ordersPending,
        ordersCancelled: ordersCancelled,
        ordersBySource: ordersBySource?.y_axis,
        revenueTotal: revenueTotal,
        revenueApproved: revenueApproved,
        revenuePending: revenuePending,
        revenueCancelled: revenueCancelled,
        revenueBySource: revenueBySource?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
    };
}

export function getChartsCampaignsByChannel(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google'];
    const getDataSourcesChart: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    getDataSourcesChart[sourceKey] = getDataSourcesChart?.[sourceKey] || {};
                    getDataSourcesChart[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    return { getDataSourcesChart };
}

export function getAdsSetChart(data: any, type: string, paramterId: string) {
    const adsSetMetrics = type === 'facebook' ? data?.facebook_adgroups_metrics : type === 'tiktok' ? data?.tiktok_adgroups_metrics : type === 'google' ? data?.google_adgroups_metrics : {};

    const spendBySource = adsSetMetrics?.spend?.per_adgroup?.chart?.[paramterId];
    const totalOrders = adsSetMetrics?.utm_purchases?.per_adgroup?.chart?.[paramterId];
    const approvedOrders = adsSetMetrics?.utm_approved_purchases?.per_adgroup?.chart?.[paramterId];
    const pendingOrders = adsSetMetrics?.utm_pending_purchases?.per_adgroup?.chart?.[paramterId];
    const cancelledOrders = adsSetMetrics?.utm_cancelled_purchases?.per_adgroup?.chart?.[paramterId];
    const ordersBySource = adsSetMetrics?.purchases?.per_adgroup?.chart?.[paramterId];
    const revenueBySource = adsSetMetrics?.revenue?.per_adgroup?.chart?.[paramterId];
    const revenueTotal = adsSetMetrics?.utm_revenue?.per_adgroup?.chart?.[paramterId];
    const revenueApproved = adsSetMetrics?.utm_approved_revenue?.per_adgroup?.chart?.[paramterId];
    const revenuePending = adsSetMetrics?.utm_pending_revenue?.per_adgroup?.chart?.[paramterId];
    const revenueCancelled = adsSetMetrics?.utm_cancelled_revenue?.per_adgroup?.chart?.[paramterId];
    const clicksBySource = adsSetMetrics?.clicks?.per_adgroup?.chart?.[paramterId];
    const impressionsBySource = adsSetMetrics?.impressions?.per_adgroup?.chart?.[paramterId];

    return {
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: totalOrders?.y_axis,
        ordersApproved: approvedOrders?.y_axis,
        ordersPending: pendingOrders?.y_axis,
        ordersCancelled: cancelledOrders?.y_axis,
        ordersBySource: ordersBySource?.y_axis,
        revenueTotal: revenueTotal?.y_axis,
        revenueApproved: revenueApproved?.y_axis,
        revenuePending: revenuePending?.y_axis,
        revenueCancelled: revenueCancelled?.y_axis,
        revenueBySource: revenueBySource?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
    };
}

export function getAdsChart(data: any, type: string, paramterId: string) {
    const adsMetrics = type === 'facebook' ? data?.facebook_ads_metrics : type === 'tiktok' ? data?.tiktok_ads_metrics : type === 'google' ? data?.google_ads_metrics : {};

    const spendBySource = adsMetrics?.spend?.per_ad?.chart?.[paramterId];
    const totalOrders = adsMetrics?.utm_purchases?.per_ad?.chart?.[paramterId];
    const approvedOrders = adsMetrics?.utm_approved_purchases?.per_ad?.chart?.[paramterId];
    const pendingOrders = adsMetrics?.utm_pending_purchases?.per_ad?.chart?.[paramterId];
    const cancelledOrders = adsMetrics?.utm_cancelled_purchases?.per_ad?.chart?.[paramterId];
    const ordersBySource = adsMetrics?.purchases?.per_ad?.chart?.[paramterId];
    const revenueBySource = adsMetrics?.revenue?.per_ad?.chart?.[paramterId];
    const revenueTotal = adsMetrics?.utm_revenue?.per_ad?.chart?.[paramterId];
    const revenueApproved = adsMetrics?.utm_approved_revenue?.per_ad?.chart?.[paramterId];
    const revenuePending = adsMetrics?.utm_pending_revenue?.per_ad?.chart?.[paramterId];
    const revenueCancelled = adsMetrics?.utm_cancelled_revenue?.per_ad?.chart?.[paramterId];
    const clicksBySource = adsMetrics?.clicks?.per_ad?.chart?.[paramterId];
    const impressionsBySource = adsMetrics?.impressions?.per_ad?.chart?.[paramterId];

    return {
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: totalOrders?.y_axis,
        ordersApproved: approvedOrders?.y_axis,
        ordersPending: pendingOrders?.y_axis,
        ordersCancelled: cancelledOrders?.y_axis,
        ordersBySource: ordersBySource?.y_axis,
        revenueTotal: revenueTotal?.y_axis,
        revenueApproved: revenueApproved?.y_axis,
        revenuePending: revenuePending?.y_axis,
        revenueCancelled: revenueCancelled?.y_axis,
        revenueBySource: revenueBySource?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
    };
}

export function selectGraphicData(data: any, tab: string, type: string, paramterId: string, secondaryParmaterId: string) {
    if (tab === 'channel') {
        const types = ['facebook', 'tiktok', 'google'];

        if (!types.includes(type)) {
            return [];
        }

        return getChartsTrackignByData(data, type);
    } else if (tab === 'channelFacebook') {
        return getChartsByAdsAccountFacebook(data, paramterId);
    } else if (tab === 'campaign') {
        return getChartsAccountCampaigns(data, type, paramterId, secondaryParmaterId);
    } else if (tab === 'adsSet') {
        return getAdsSetChart(data, type, paramterId);
    } else if (tab === 'ads') {
        return getAdsChart(data, type, paramterId);
    }
}

export function getChartsByAdsAccountFacebook(data: any, paramterId: string) {
    const accountMetrics = data?.facebook_ad_accounts_metrics || [];
    const spendBySource = accountMetrics?.spend?.per_account?.chart?.[paramterId] || [];
    const totalOrders = accountMetrics?.utm_purchases?.per_account?.chart?.[paramterId] || [];
    const approvedOrders = accountMetrics?.utm_approved_purchases?.per_account?.chart?.[paramterId] || [];
    const pendingOrders = accountMetrics?.utm_pending_purchases?.per_account?.chart?.[paramterId] || [];
    const cancelledOrders = accountMetrics?.utm_cancelled_purchases?.per_account?.chart?.[paramterId] || [];
    const ordersBySource = accountMetrics?.purchases?.per_account?.chart?.[paramterId] || [];
    const revenueBySource = accountMetrics?.revenue?.per_account?.chart?.[paramterId] || [];
    const revenueTotal = accountMetrics?.utm_revenue?.per_account?.chart?.[paramterId] || [];
    const revenueApproved = accountMetrics?.utm_approved_revenue?.per_account?.chart?.[paramterId] || [];
    const revenuePending = accountMetrics?.utm_pending_revenue?.per_account?.chart?.[paramterId] || [];
    const revenueCancelled = accountMetrics?.utm_cancelled_revenue?.per_account?.chart?.[paramterId] || [];
    const clicksBySource = accountMetrics?.clicks?.per_account?.chart?.[paramterId] || [];
    const impressionsBySource = accountMetrics?.impressions?.per_account?.chart?.[paramterId] || [];

    const chartsByFacebookAdsAccount = {
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: totalOrders?.y_axis,
        ordersApproved: approvedOrders?.y_axis,
        ordersPending: pendingOrders?.y_axis,
        ordersCancelled: cancelledOrders?.y_axis,
        ordersBySource: ordersBySource?.y_axis,
        revenueBySource: revenueBySource?.y_axis,
        revenueTotal: revenueTotal?.y_axis,
        revenueApproved: revenueApproved?.y_axis,
        revenuePending: revenuePending?.y_axis,
        revenueCancelled: revenueCancelled?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
    };

    return chartsByFacebookAdsAccount;
}

export function getChartsAccountCampaigns(data: any, type: string, paramterId: string, secondaryParmaterId: string) {
    const types = ['Whatsapp', 'Email/SMS', 'sem_atribuicao'];

    if (types.includes(type)) {
        const { getDataSourcesChart } = getChartsCampaignsByChannel(data);

        const organizedData = organizeDataByCampaign(getDataSourcesChart)?.[type] ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData)
            ?.filter((item: any) => item !== 'y_axis' && item !== 'x_axis')
            ?.map((key) => {
                return { ...organizedData[key], campaignName: key };
            });

        const filtredAccountCampaign = filtredAccountCampaignData?.filter((item: any) => item.campaignName === paramterId)[0];

        return {
            xAxis: filtredAccountCampaign?.orders?.chart?.x_axis,
            spendBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            ordersTotal: filtredAccountCampaign?.orders?.chart?.y_axis,
            ordersApproved: filtredAccountCampaign?.approved_orders?.chart?.y_axis,
            ordersPending: filtredAccountCampaign?.pending_orders?.chart?.y_axis,
            ordersCancelled: filtredAccountCampaign?.cancelled_orders?.chart?.y_axis,
            ordersBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            revenueTotal: filtredAccountCampaign?.revenue?.chart?.y_axis,
            revenueApproved: filtredAccountCampaign?.approved_revenue?.chart?.y_axis,
            revenuePending: filtredAccountCampaign?.pending_revenue?.chart?.y_axis,
            revenueCancelled: filtredAccountCampaign?.cancelled_revenue?.chart?.y_axis,
            revenueBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            clicksBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            impressionsBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
        };
    } else if (type === 'outros') {
        const { nonListedSourcesChart } = getNonListedSourcesChart(data);

        const organizedData = organizeDataByCampaign(nonListedSourcesChart) ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData?.[paramterId])
            ?.filter((item: any) => item !== 'y_axis' && item !== 'x_axis')
            ?.map((key) => {
                return { ...organizedData?.[paramterId]?.[key], campaignName: key };
            });

        const filtredAccounts = filtredAccountCampaignData?.filter((item: any) => item.campaignName === secondaryParmaterId)[0];

        return {
            xAxis: filtredAccounts?.orders?.chart?.x_axis,
            spendBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            ordersTotal: filtredAccounts?.orders?.chart?.y_axis,
            ordersApproved: filtredAccounts?.approved_orders?.chart?.y_axis,
            ordersPending: filtredAccounts?.pending_orders?.chart?.y_axis,
            ordersCancelled: filtredAccounts?.cancelled_orders?.chart?.y_axis,
            ordersBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            revenueTotal: filtredAccounts?.revenue?.chart?.y_axis,
            revenueApproved: filtredAccounts?.approved_revenue?.chart?.y_axis,
            revenuePending: filtredAccounts?.pending_revenue?.chart?.y_axis,
            revenueCancelled: filtredAccounts?.cancelled_revenue?.chart?.y_axis,
            revenueBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            clicksBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            impressionsBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
        };
    } else {
        const campaignMetrics = type === 'facebook' ? data?.facebook_campaigns_metrics : type === 'tiktok' ? data?.tiktok_campaigns_metrics : type === 'google' ? data?.google_campaigns_metrics : {};

        const spendBySource = campaignMetrics?.spend?.per_campaign?.chart?.[paramterId];
        const totalOrders = campaignMetrics?.utm_purchases?.per_campaign?.chart?.[paramterId];
        const approvedOrders = campaignMetrics?.utm_approved_purchases?.per_campaign?.chart?.[paramterId];
        const pendingOrders = campaignMetrics?.utm_pending_purchases?.per_campaign?.chart?.[paramterId];
        const cancelledOrders = campaignMetrics?.utm_cancelled_purchases?.per_campaign?.chart?.[paramterId];
        const ordersBySource = campaignMetrics?.purchases?.per_campaign?.chart?.[paramterId];
        const revenueBySource = campaignMetrics?.revenue?.per_campaign?.chart?.[paramterId];
        const revenueTotal = campaignMetrics?.utm_revenue?.per_campaign?.chart?.[paramterId];
        const revenueApproved = campaignMetrics?.utm_approved_revenue?.per_campaign?.chart?.[paramterId];
        const revenuePending = campaignMetrics?.utm_pending_revenue?.per_campaign?.chart?.[paramterId];
        const revenueCancelled = campaignMetrics?.utm_cancelled_revenue?.per_campaign?.chart?.[paramterId];
        const clicksBySource = campaignMetrics?.clicks?.per_campaign?.chart?.[paramterId];
        const impressionsBySource = campaignMetrics?.impressions?.per_campaign?.chart?.[paramterId];

        const accountCampaignsData = {
            xAxis: spendBySource?.x_axis,
            spendBySource: spendBySource?.y_axis,
            ordersTotal: totalOrders?.y_axis,
            ordersApproved: approvedOrders?.y_axis,
            ordersPending: pendingOrders?.y_axis,
            ordersCancelled: cancelledOrders?.y_axis,
            ordersBySource: ordersBySource?.y_axis,
            revenueBySource: revenueBySource?.y_axis,
            revenueTotal: revenueTotal?.y_axis,
            revenueApproved: revenueApproved?.y_axis,
            revenuePending: revenuePending?.y_axis,
            revenueCancelled: revenueCancelled?.y_axis,
            clicksBySource: clicksBySource?.y_axis,
            impressionsBySource: impressionsBySource?.y_axis,
        };

        return accountCampaignsData;
    }
}

export function getChartTransformed(data: any) {
    const roasBySource = divideVectors(data?.revenueBySource, data?.spendBySource);
    const roasSolomonTotal = divideVectors(data?.revenueTotal, data?.spendBySource);
    const roasSolomonApproved = divideVectors(data?.revenueApproved, data?.spendBySource);
    const roasSolomonPending = divideVectors(data?.revenuePending, data?.spendBySource);
    const roasSolomonCancelled = divideVectors(data?.revenueCancelled, data?.spendBySource);

    const cpaBySource = divideVectors(data?.spendBySource, data?.ordersBySource);
    const cpaSolomonTotal = divideVectors(data?.spendBySource, data?.ordersTotal);
    const cpaSolomonApproved = divideVectors(data?.spendBySource, data?.ordersApproved);
    const cpaSolomonPending = divideVectors(data?.spendBySource, data?.ordersPending);
    const cpaSolomonCancelled = divideVectors(data?.spendBySource, data?.ordersCancelled);

    const cpmBySource = divideVectors(data?.spendBySource, data?.impressionsBySource).map((value: any) => value * 1000);
    const cpcBySource = divideVectors(data?.spendBySource, data?.clicksBySource);

    const chartTransformed = {
        xAxis: data?.xAxis,
        spendBySource: data?.spendBySource,

        ordersBySource: data?.ordersBySource,
        ordersSolomonTotal: data?.ordersTotal,
        ordersSolomonApproved: data?.ordersApproved,
        ordersSolomonPending: data?.ordersPending,
        ordersSolomonCancelled: data?.ordersCancelled,

        revenueBySource: data?.revenueBySource,
        revenueTotal: data?.revenueTotal,
        revenueApproved: data?.revenueApproved,
        revenuePending: data?.revenuePending,
        revenueCancelled: data?.revenueCancelled,

        roasBySource,
        roasSolomonTotal,
        roasSolomonApproved,
        roasSolomonPending,
        roasSolomonCancelled,

        cpaBySource,
        cpaSolomonTotal,
        cpaSolomonApproved,
        cpaSolomonPending,
        cpaSolomonCancelled,

        cpcBySource,
        cpmBySource,
    };

    return chartTransformed;
}


export function getAdsIdByCampaingId(data: any, campaignId: string, type: string) {

    const ads = []

       const adsSets = getAdsSetData(data, type, campaignId);
         for (const adsSet of adsSets) {
              const adsData = getAdsData(data, type, adsSet?.adsSetId);
                for (const ad of adsData) {
                    ads.push(ad?.adInfo);
                }
         }
   
   let adString = ''
   
   for (const ad of ads) {
         adString += ad + ','
   }

   adString = adString.slice(0, -1)

   return adString
}

export function getAdsIdByAdsSetId(data: any, adsSetId: string, type: string) {
    const adsData = getAdsData(data, type, adsSetId);
    let adString = ''
    for (const ad of adsData) {
        adString += ad?.adInfo + ','
    }   
    adString = adString.slice(0, -1)
    return adString
}


export function getAdsDataWarning(data: any, type: string) {
    const adsMap =
        type === 'facebook'
            ? data?.facebook_ads_metrics?.aux?.ads_objects
            : type === 'tiktok'
              ? data?.tiktok_ads_metrics?.aux?.ads_objects
              : type === 'google'
                ? data?.google_ads_metrics?.aux?.ads_objects
                : {};
    return adsMap;
}

export function getAdsIdByAllChannelId(data: any, type: string) {
    let keysAsStrings: string[] = [];
    let matchedKeys: string[] = [];

    const adsMap =
        type === 'facebook'
            ? data?.facebook_ad_accounts_metrics?.aux?.accounts_maps
            : type === 'tiktok'
              ? data?.tiktok_ad_accounts_metrics?.aux?.accounts_maps
              : type === 'google'
                ? data?.google_ad_accounts_metrics?.aux?.accounts_maps
                : {};

    const adsMapCampaign =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.aux?.campaigns_accounts
            : type === 'tiktok'
                ? data?.tiktok_campaigns_metrics?.aux?.campaigns_accounts
                : type === 'google'
                ? data?.google_campaigns_metrics?.aux?.campaigns_accounts
                : {};

    if (type === 'facebook') {
        if (adsMap && typeof adsMap === 'object') {
            keysAsStrings = Object.keys(adsMap).map(function(key) {
                return String(key);
            });
        }

        if (adsMapCampaign && typeof adsMapCampaign === 'object') {
            Object.keys(adsMapCampaign).forEach(key => {
                if (keysAsStrings.includes(adsMapCampaign[key])) {
                    matchedKeys.push(key);
                }
            });
        }
    } else {
        if (adsMapCampaign && typeof adsMapCampaign === 'object') {
            matchedKeys = Object.keys(adsMapCampaign).map(function(key) {
                return String(key);
            });
        }
    }

    let combinedAdsString = '';

    for (const matchedKey of matchedKeys) {
        const adsString = getAdsIdByCampaingId(data, matchedKey, type);
        combinedAdsString += adsString + ',';
    }

    combinedAdsString = combinedAdsString.slice(0, -1);

    return combinedAdsString;
}

export function getWrongUtms (data: any, type: string) {
    
    const metricsByAds = [];

    if (type === "facebook") {
        
        for (const Ad in data?.facebook_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            if (!Ad){
                continue;
            }
            const ads_objects = data?.facebook_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.facebook_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.facebook_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.facebook_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account = data?.facebook_campaigns_metrics?.aux?.campaigns_accounts?.[Campaign];
            const Account_name = data?.facebook_ad_accounts_metrics?.aux?.accounts_maps?.[Account];

            if (utm_source !== "\{\{site_source_name\}\}" || utm_medium !== "\{\{placement\}\}" || utm_campaign !== "\{\{campaign.id\}\}" || utm_content !== "\{\{ad.id\}\}") {
            if (utm_source !== "\{\{site_source_name\}\}" || utm_medium !== "\{\{placement\}\}" || utm_campaign !== "\{\{campaign.id\}\}" || utm_content !== "\{\{ad.id\}\}") {
                metricsByAds.push({
                    Account_name,
                    Ad_name,
                    AdGroup_name,
                    Campaign_name,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    Account,
                    Campaign,
                    AdGroup,
                    Ad,
                });
            } 
            } 

        }
    }

    if (type === "google") {

        for (const Ad in data?.google_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            if (!Ad){
                continue;
            }
            const ads_objects = data?.google_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.google_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.google_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.google_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account_name = 'nulo';

            if (utm_source !== "google" || utm_campaign !== "\{campaignid\}" || utm_content !== "\{creative\}") {
                metricsByAds.push({
                    Account_name,
                    Ad_name,
                    AdGroup_name,
                    Campaign_name,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                });
            } 

        }

    }

    if (type === "tiktok") {        
        for (const Ad in data?.tiktok_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            if (!Ad){
                continue;
            }
            const ads_objects = data?.tiktok_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.tiktok_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.tiktok_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.tiktok_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account_name = 'nulo';

            if (utm_source !== "tiktok" || utm_medium !== "tiktok_ads" || utm_campaign !== "__CAMPAIGN_ID__" || utm_content !== "__CID__") {
            
            metricsByAds.push({
                Account_name,
                Ad_name,
                AdGroup_name,
                Campaign_name,
                utm_campaign,
                utm_content,
                utm_medium,
                utm_source,
                Campaign,
                AdGroup,
                Ad,
            });
            }

        }
    }

    return metricsByAds;
}