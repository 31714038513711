import { submitBillingAccountDTO } from '../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getBillingAccount(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/billing/accounts?company_id=${company_id}`);
    return data;
}

export async function submitBillingAccount(body: DTO.submitBillingAccountDTO): Promise<AxiosResponse> {
    const { name, email, mobilePhone } = body;

    console.log('body', body);

    const newBody = { name, email, mobilePhone };
    const data: AxiosResponse = await api.post(`${BASE_URL}/billing/accounts`, newBody);
    
    return data;
}
