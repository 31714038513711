import { ButtonApp } from '../../../components/ButtonApp';
import { LineTableEditManualProduct } from './LineTableEditManualProduct';
import * as S from './style';
import useAuth from '../../../hooks/useAuth';
import { useMemo, useState } from 'react';
import {  getCurrentCostArray } from '../../CostOperation/utils';
import { LineCostOperationProps } from '../../../@types';
import Graph from '../../../components/Graph';
import { uuidv4 } from '../../../utils';
import { TablePlaceholder } from '../../../components/TablePlaceholder';
import CircularProgress from '@mui/material/CircularProgress';
import { useCurrencyType } from '../../../hooks/useCurrencyType';

export function TableEditManualProduct({currentDataCost, isLoadingCurrentData, createCost, isLoading}: {currentDataCost: any, isLoadingCurrentData: boolean, createCost: any, isLoading: boolean}) {
    const { currentCompany } = useAuth();
    const { currencySymbol } = useCurrencyType();

    const [costOperation, setCostOperation] = useState({
        name: '',
        type: 'product',
        value: '',
        frequency: 'specific',
        date: '',
    });       

    const isLoadingGetData =  isLoadingCurrentData;

    const currentDataCostArray = currentDataCost?.data?.data;


    const [currentCostArray, setCurrentCostArray] = useState<LineCostOperationProps[]>(getCurrentCostArray(currentDataCostArray).filter((cost: LineCostOperationProps) => cost.type === 'product' && cost.frequency === 'Pontual'));

    //console.log('currentDataCost', currentDataCost);

    function formatValue(value: string) {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        newValue = `${currencySymbol} ` + newValue;
        setCostOperation({ ...costOperation, value: newValue });
    }
    function formatToNumber(value: string) {
        value = value.replace(`${currencySymbol}`, '');
        value = value.replace('.', '');
        value = value.replace(',', '.');
        return parseFloat(value);
    }

    function handleCreateCost() {
        if (!isLoading) {
            const body = {
                name: costOperation.name,
                value: formatToNumber(costOperation.value),
                type: costOperation.type,
                frequency: costOperation.frequency,
                date: costOperation.date,
                company_id: currentCompany,
                status: 'active',
                additional_cost_id: uuidv4().substring(0, 10),
            };

            // const isValidInformation = createCostSchema.safeParse(body);

            const { name, value, type, frequency, date, additional_cost_id } = body;
            setCurrentCostArray([{ name, value, type, frequency, status:'active',  date: date, cost_id: additional_cost_id }, ...currentCostArray]);
            createCost(body);
            setCostOperation({
                name: '',
                type: 'product',
                value: '',
                frequency: 'specific',
                date: '',
            });
        }
    }

    function compareDates(a:any, b:any, key = 'date') {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        return +dateB - +dateA; // Ordenação decrescente
    }

    const {xAxisData, yAxisDatasets} = useMemo(() => getGraphData(currentCostArray), [currentCostArray]);

    //console.log("xAxisData const", yAxisDatasets);

    //console.log("current cost const", currentCostArray);

    if (currentCostArray.length === 0) {
        return (
            <S.Container>
            <S.MainSection>

                <S.FirstSection>
                    <S.InputSection>
                        <label>Nome</label>
                        <input type="text" value={costOperation.name} onChange={(e) => setCostOperation({ ...costOperation, name: e.target.value })} placeholder="Aluguel do espaço" />
                    </S.InputSection>
                    <S.InputSection>
                        <label>Data</label>
                        <input type="date" value={costOperation.date} onChange={(e) => setCostOperation({ ...costOperation, date: e.target.value })} />
                    </S.InputSection>
                    <S.InputSection>
                        <label>Valor</label>
                        <input value={costOperation.value} placeholder={`${currencySymbol} 10,00`} type="text" onChange={(e) => formatValue(e.target.value)} />
                    </S.InputSection>
                    <S.ButtonSection>
                    <ButtonApp 
                        border=''
                        width="9rem"
                        height="2.5rem"
                        color="#fff"
                        background="#19c380"
                        icon={<></>}
                        onClick={() => {handleCreateCost()}}
                        text="Salvar"/>
                    </S.ButtonSection>
                </S.FirstSection>

                <S.VLine />

                <S.SecondSection>
                {isLoadingGetData ? (
                    <CircularProgress sx={{color: '#19c27e'}} />
                ) : (
                    <TablePlaceholder size='medium' showSubtitle={false}/>
                )}
                </S.SecondSection>
            </S.MainSection>
            <S.Header>
                <S.Column>Nome do Custo</S.Column>
                <S.Column>Data de Referência</S.Column>
                <S.Column>Valor</S.Column>
                <S.Column>Ações</S.Column>
            </S.Header>
            <S.EmptyLines>
                <TablePlaceholder size='medium' showSubtitle={false} />
            </S.EmptyLines>
        </S.Container>
        );
    }

    return(
        <S.Container>
            <S.MainSection>

                <S.FirstSection>
                    <S.InputSection>
                        <label>Nome</label>
                        <input type="text" value={costOperation.name} onChange={(e) => setCostOperation({ ...costOperation, name: e.target.value })} placeholder="Aluguel do espaço" />
                    </S.InputSection>
                    <S.InputSection>
                        <label>Data</label>
                        <input type="date" value={costOperation.date} onChange={(e) => setCostOperation({ ...costOperation, date: e.target.value })} />
                    </S.InputSection>
                    <S.InputSection>
                        <label>Valor</label>
                        <input value={costOperation.value} placeholder={`${currencySymbol} 10,00`} type="text" onChange={(e) => formatValue(e.target.value)} />
                    </S.InputSection>
                    <S.ButtonSection>
                    <ButtonApp 
                        border=''
                        width="9rem"
                        height="2.5rem"
                        color="#fff"
                        background="#19c380"
                        icon={<></>}
                        onClick={() => {handleCreateCost()}}
                        text="Salvar"/>
                    </S.ButtonSection>
                </S.FirstSection>

                <S.VLine />

                <S.SecondSection>
                    <Graph graphType='bar' xAxisData={xAxisData} yAxisDatasets={yAxisDatasets} intervalData={5}/>
                </S.SecondSection>
            </S.MainSection>
            <S.Header>
                <S.Column>Nome do Custo</S.Column>
                <S.Column>Data de Referência</S.Column>
                <S.Column>Valor</S.Column>
                <S.Column>Ações</S.Column>
            </S.Header>
            <S.Lines>
                {currentCostArray &&
                    currentCostArray?.sort((a, b) => compareDates(a, b)).map((currentCost: LineCostOperationProps, _: number) => {
                        return (
                            <LineTableEditManualProduct
                                currentCostArray={currentCostArray}
                                setCurrentCostArray={setCurrentCostArray}
                                cost_id={currentCost?.cost_id}
                                date={currentCost?.date}
                                name={currentCost?.name}
                                value={currentCost?.value}
                            />
                        );
                })}
            </S.Lines>
        </S.Container>
    )
}


function getGraphData(currentCostArray) {
    // Objeto para acumular os custos por data
    const costsByDate = {};

    // Processa todos os custos, sem filtragem de datas
    currentCostArray.forEach(cost => {
        const costDate = new Date(cost.date);
        costDate.setHours(0, 0, 0, 0); // Normalizar a hora

        const dateKey = costDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        const costValue = parseFloat(cost.value);
        if (!isNaN(costValue)) { // Garantir que o valor é numérico
            costsByDate[dateKey] = (costsByDate[dateKey] || 0) + costValue; // Acumular valor
        }
    });

    // Ordenar as chaves (datas) e preparar os dados para o gráfico
    const xAxisData = Object.keys(costsByDate).sort();
    const yAxisData = xAxisData.map(date => costsByDate[date]); // Mapear valores acumulados para as datas correspondentes

    const yAxisDatasets = [{ name: 'Custos', data: yAxisData }];

    //console.log('xAxisData', xAxisData);
    //console.log('yAxisDatasets', yAxisDatasets);

    return { xAxisData, yAxisDatasets }; 
}
