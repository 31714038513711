import styled from 'styled-components';
import seta from '../../../assets/images/seta.png';

export const Container = styled.div`
    border-radius: 10px;
    display: flex;
    align-items: center;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #f5f6f7;
`;

export const FlexSelectTwo = styled.div`
    display: flex;
    align-items: center;
`

export const Select = styled.div`
    background-color: #f3f3f3;
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px;
margin-top: -1px;
height: 6px;
color: var(--black);   
font-size: 0.8rem;
font-weight: 550;
border-radius: 10px;
transition: background-color 0.2s;
width: 13rem;

svg {
    margin-right: 0.5rem;
    width: 1.2rem;
}

&:hover {
    cursor: pointer;
    background-color: #eee;
    transition: background-color 0.2s;

}

img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}
`;

type OptionsContainerProps = {
    showSelectSolomon: boolean;
};

export const OptionsContainer = styled.div<OptionsContainerProps>`
    position: absolute;
    background-color: #fff;
    padding: 0.5rem;
    z-index: 80;
    cursor: default;
    display: flex;
    ${(props) =>
        props.showSelectSolomon
            ? `

    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;

    `
            : `

   
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    flex-direction: column;
    // left: 0;
    top: 6rem;
    margin-left: -1rem;
    width: 14.9rem;
    height: max-content;
    border-radius: 8px;
    gap: 0.4rem;
    border: 1px solid #e7e7e7;
`;

export const Option = styled.div`
    padding: 0 1rem;
    border-radius: 8px;
    display: flex;
    color: #999;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 2.3rem;
    &:hover {
        cursor: pointer;
        color: var(--black);
        background-color: #f5f6f7;
    }
    img {
        width: 1rem;
        height: 1rem;
    }
`;
