import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *, input, label {
        margin: 0;
        padding: 0;
        border: 0;
        -webkit-font-smoothing: antialiased;
        font-family: 'Montserrat', sans-serif;
        vertical-align: baseline;
    }
    
    body{
        overflow: hidden;
        background: var(--white);
        color: var(--black);
        font-family: 'Montserrat', sans-serif;
        overflow: hidden;
        
    // ::-webkit-scrollbar {
    //     width: 5px;
    //     height: 10px;
    // }

   
    ::-webkit-scrollbar-thumb {
        background: #cacaca; 
        border-radius: 5px;
    }

    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #a4a4a4;
    }
    }
    :root{
        font-size: 16px;
        --success: #36C74D;
        --fail: #F82B2B;
        --white: white;
        --black: #0c1612;
        --gray-50: #f8f9fb;
        --gray-100: #cccccc;
        --gray-110: #f1f1f1;
        --gray-112: #f2f2f2;
        --gray-115: #f8f8f8;
        --gray-120: #d9dbd9;
        --gray-125: #f3f3f3;
        --gray-130: #fcfcfc;
        --gray-140: #f2f2f2;
        --gray-145: #e9e9e9;
        --gray-150: #e1e1e1;
        --gray-175: #eaeaea;
        --gray-180: #eeeeee;
        --gray-190: #d9d9d9;
        --gray-200: #e7e7e7;
        --gray-225: #dadcde;
        --gray-250: #b4b4b4;
        --gray-300: #bfbfbf;
        --gray-350: #e5e9e7;
        --gray-600: #7b7b7b;
        --gray-700: #989c97;
        --gray-800: #656565;
        --gray-850: #8d8d8d;
        --gray-900: #979797;
        --green100: #b4edd6;
        --red-100: #edb4b4;
        --red-500: #F41B19;
        --red-800: #7c0808;
        --purple-100: #faecff;
        --purple-800: #8b5ea7;
        --green: #19c27e;
        --green-800: #087c4d;
        --green-900: #648474;
        --green-100: #b4edd6;
        --green-125: #ddf5ea;
        --blue: #008ccf;
        
    }
`;
