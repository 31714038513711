import React, {useEffect, useState} from 'react';
import * as S from './style.ts';
import { redirect, useNavigate } from 'react-router-dom';
import {InputPayment} from '../InputPayment'; // Ajuste o caminho conforme necessário
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetSubscription } from '../../../services/hooks/subscriptionHooks.ts';
import useAuth from '../../../hooks/useAuth.tsx';
import CreditCardItem from './CreditCardItem'
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { storageOnboardingCurrentCompanyGet } from '../../../storage/storageOnboarding.ts';
import { useSubmitSubscription } from '../../../services/hooks/subscriptionHooks';

interface Step2Props {
  companyCreating?: boolean;
  setSubmitButtonEnabled: (value: boolean) => void;
  isLoadingPayment: any;
  setCardToken: (value: any) => void;
  setIsLoading: (value: any) => void;
  subscriptionPostalCode: any;
  setSubscriptionPostalCode: (value: any) => void;
  setSelectedCard: (value: boolean) => void;
  handleFirstInteraction: () => void;
  isMobile: boolean;
  subscriptionCardNumber: string;
  setSubscriptionCardNumber: React.Dispatch<React.SetStateAction<string>>;
  subscriptionName: string;
  setSubscriptionName: React.Dispatch<React.SetStateAction<string>>;
  subscriptionExpirationDate: string;
  setSubscriptionExpirationDate: React.Dispatch<React.SetStateAction<string>>;
  subscriptionCcv: string;
  setSubscriptionCcv: React.Dispatch<React.SetStateAction<string>>;
  isSubmitButtonEnabled: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  addStore: any;
  setAddStore: (value: any) => void;
  updatePlan: any;
  billingCpfCnpj: any;
  setBillingCpfCnpj: (value: any) => void;
}

export default function Step2({setSubmitButtonEnabled, companyCreating, billingCpfCnpj, setBillingCpfCnpj, setCardToken, isLoadingPayment, setIsLoading, subscriptionPostalCode, setSubscriptionPostalCode, setSelectedCard, handleFirstInteraction, isMobile, subscriptionCardNumber, setSubscriptionCardNumber, subscriptionName, setSubscriptionName, subscriptionExpirationDate, setSubscriptionExpirationDate, subscriptionCcv, setSubscriptionCcv, isSubmitButtonEnabled, handleSubmit, setCurrentStep, addStore, setAddStore, updatePlan } : Step2Props) {
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [showSelectCard, setShowSelectCard] = useState(false);
  const navigate = useNavigate();
  const { mutate: submitSubscription } = useSubmitSubscription();
  const [isLoadingDefaultCard, setIsLoadingDefaultCard] = useState(false)
  const [creditCardsState, setCreditCards ] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [showCreditCardsFirst, setShowCreditCardsFirst] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const companies = user.companies;
    ////console.log('companies', companies)
    if (companies && Object.keys(companies).length > 0) {
        const matchingCompany = Object.values(companies).find(company => 
        company.user.id && company.user.id === user.user_id);
          
            ////console.log('matchingcompanies', matchingCompany)
        
          if (matchingCompany && matchingCompany.billing_account.creditCard) {
              const creditCards = matchingCompany.billing_account.creditCard;
              if (creditCards.length > 0) {
                ////console.log('creditCards', creditCards)
                setCreditCards(creditCards); // Ajuste conforme a necessidade de seleção de cartão
                setShowCreditCardsFirst(true)
                handleDefaultCard()
            } else {
                ////console.log('No credit cards available in the matched company');
            }
          }
    }
  }, [user]); // Dependência no user

  
  function handleAddCard() {
    setIsSelected(false);
    setShowSelectCard(false)
    setSelectedCard(false);
  }
  
  function handleDefaultCard() {
    setShowSelectCard(true)
    setAddStore('true');
  }
  
  let activeCompanyIds = [];
  if (user && user.companies) {
    // Percorrer todas as empresas no objeto user.companies
    Object.keys(user.companies).forEach(companyId => {
      const company = user.companies[companyId];
  
      // Verificar se a empresa tem um objeto billing e se o status é 'active'
      if (company.billing && company.billing.status === 'active' || company.billing.status === 'ACTIVE') {
        // Se sim, adicionar o company_id ao array activeCompanyIds
        activeCompanyIds.push(companyId);
      }
    });
    // ////console.log('Company IDs das empresas ativas:', activeCompanyIds);
  } else {
    ////console.log('Nenhuma empresa encontrada ou objeto user não definido.');
  }


  function handleSelectDefaultCard(subscriptionData) {
    ////console.log('subscriptiondata secondstep', subscriptionData)
    setSelectedCardData(subscriptionData);

    ////console.log('Cartão selecionado:', subscriptionData);
    setSelectedCard(true);
    const cardToken = subscriptionData.creditCardToken;
    ////console.log('cardtoken step 2', cardToken)
    setCardToken(cardToken)
  };

  return (

    <form onSubmit={handleSubmit}>
    <S.StepsContainer>
      <p>Passo 2 de 2</p>
      <S.Steps>
        <S.Step active={true}></S.Step>
        <S.Step active={true}></S.Step>
      </S.Steps>
    </S.StepsContainer>
    {addStore == 'true' && showSelectCard ? (
      <>
      <S.CardsContainer>
      <S.CardOverlay isMobile={isMobile} />

      {creditCardsState.map((card, index) => (
        <CreditCardItem
          setIsSelected={setIsSelected}
          isSelected={isSelected}
          isMobile={isMobile}
          key={card.id || index}  // Usando card.id como chave; se não disponível, usa o index como fallback
          card={card}             // Passando o objeto do cartão inteiro, assumindo que contém informações necessárias
          // selectedCardId={selectedCardId} // Presumindo que você tem um estado para gerenciar o ID do cartão selecionado
          setSelectDefaultCard={handleSelectDefaultCard}
          setSelectedCardData={setSelectedCardData}
          />
        ))}
     
      </S.CardsContainer>
       <S.PaymentMethodArea isMobile={isMobile} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <S.ButtonText onClick={handleAddCard} style={{ backgroundColor: 'transparent', color: '#19c27e' }} isEditing={false}>
          <AddCircleOutlineRoundedIcon />
          Adicionar novo cartão
        </S.ButtonText>
      </S.PaymentMethodArea>
    </>
    ) : (
      <>
      {addStore == 'true' && !showSelectCard ? (
        <S.ButtonText onClick={handleDefaultCard} style={{ color: 'white' }} isEditing={true}>Selecionar cartão padrão</S.ButtonText>
      ) : (
        null
      ) }
        <S.InputSection>
        <InputPayment
            onFocus={handleFirstInteraction}
            inputInformation={billingCpfCnpj}
            inputId="cpfCnpj"
            inputName="cpfCnpj"
            inputLabel="CPF/CNPJ"
            inputType="cpfCnpj"
            width={isMobile ? '78vw' : '27.8125rem'}
            setInputInformation={setBillingCpfCnpj}
          />
        </S.InputSection>
        <S.InputSection>
          <InputPayment
            onFocus={handleFirstInteraction}
            inputInformation={subscriptionCardNumber}
            inputId="cardNumber"
            inputName="cardNumber"
            inputLabel="Número do cartão"
            inputType="cardNumber"
            width={isMobile ? '78vw' : '27.8125rem'}
            setInputInformation={setSubscriptionCardNumber}
          />
        </S.InputSection>
        <S.InputSection>
          <InputPayment
            onFocus={handleFirstInteraction}
            inputInformation={subscriptionName}
            inputId="name"
            inputName="name"
            inputLabel="Nome impresso no cartão"
            inputType="text"
            width={isMobile ? '78vw' : '27.8125rem'}
            setInputInformation={setSubscriptionName}
          />
        </S.InputSection>
        <S.InputSection>
          <InputPayment
            onFocus={handleFirstInteraction}
            inputInformation={subscriptionExpirationDate}
            inputId="expirationDate"
            inputName="expirationDate"
            inputLabel="Data de expiração"
            inputType="expirationDate"
            width={isMobile ? '36vw' : '12.875rem'}
            setInputInformation={setSubscriptionExpirationDate}
          />
          <InputPayment
            onFocus={handleFirstInteraction}
            inputInformation={subscriptionCcv}
            inputId="cvv"
            inputName="cvv"
            inputLabel="CVV"
            inputType="cvv"
            width={isMobile ? '36vw' : '12.875rem'}
            setInputInformation={setSubscriptionCcv}
          />
        </S.InputSection>

      </>
    )}
    
      <S.ButtonSection>
      <S.BackButton onClick={() => {
        setCurrentStep(1);
        localStorage.setItem('currentStep', JSON.stringify(1))
        setIsSelected(false);
        setSelectedCard(false);
        setSubmitButtonEnabled(false);
        }}>
          <ArrowBackIcon />
          Voltar
        </S.BackButton>
        <S.Button
          type="submit"
          disabled={!isSubmitButtonEnabled || isLoadingPayment || companyCreating}
          style={!isSubmitButtonEnabled || isLoadingPayment || companyCreating? { cursor: 'not-allowed', backgroundColor: '#ccc' }: {}}>
            <CircularProgress thickness={7} size={15} sx={ isLoadingPayment || companyCreating ? {display: 'flex', color: 'white'} : {display: 'none'}}/>
            {isLoadingPayment || companyCreating ? 'Carregando...' : 'Cadastrar'}
        </S.Button>
    </S.ButtonSection>
    
  
    
  </form>
  )
};
