import styled from 'styled-components';

export const Container = styled.div`
    overflow-x: hidden;
    min-width: 95.25rem;
`;

export const TableHeader = styled.div`
    display: flex;
    color: var(--gray-700);
    background-color: var(--gray-115);
    height: 3.5rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    padding-right: 0.3rem;
    width: 99.70%;
    > span {
        width: 1.375rem;
        /* display: flex;
        width: 3rem;
        margin-left: -4rem;
        margin-right: 1rem; */
    }
`;

export const Image = styled.div`
    width: 6.5rem;
`;

export const ProductName = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20rem;
    padding-right: 0rem;
    padding-left: 1rem;
    flex: 1;
`;

export const DefaultColumn = styled.div`
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 0.5rem;
    width: 12%;
    text-wrap: nowrap;
    /* padding-left: 1rem; */
    flex: 1;
`;

export const DefaultColumnLucro = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
    gap: 0.5rem;
    text-wrap: nowrap;
    /* padding-left: 1rem; */
    flex: 1;
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    /* padding-left: 1rem; */
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    /* padding-left: 1rem; */
`;

export const Lines = styled.div`
    > div:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    > div:nth-child(2n) {
        background-color: var(--gray-110);
    }

    max-height: calc(100vh - 23rem);    overflow-y: auto;
`;

type ArrowIconProps = {
    isActive?: boolean;
    isDesc?: boolean;
};

export const ArrowIcon = styled.div<ArrowIconProps>`
    svg:hover path {
        fill: var(--green);
    }
    svg {
        ${(props) =>
            props.isActive
                ? `
  
    fill: var(--green);
    ${props.isDesc ? '  transform: scaleY(-1);' : 'transform: scaleY(1);'}
    `
                : `
    transform: scaleY(1);
    `}

        margin-top: 0.2rem;
        cursor: pointer;
        height: 0.8125rem;
        width: auto;
        color: red;
        margin-right: 1rem;
    }
`;
