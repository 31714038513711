import * as S from './style';
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CardIntegrationProps } from '../../../@types';

export function CardIntegration({ type = 'inactive', source, handleModal }: CardIntegrationProps) {
    let url = '';
    let name = '';
    let subtitle = '';

    //console.log('type', source, type);

    switch (source) {
        case 'shopify':
            url = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';
            name = 'Shopify';
            subtitle = 'Plataforma para criação e gerenciamento de lojas virtuais personalizadas.';
            break;
        case 'facebook':
            url = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
            name = 'Facebook Ads';
            subtitle = 'Impulsione seu dropshipping com anúncios direcionados nas redes sociais.';
            break;
        case 'google':
            url = 'https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png';
            name = 'Google Ads';
            subtitle = 'Google Ads é um serviço de publicidade online onde anunciantes pagam por cliques em seus anúncios.';
            break;
        case 'tiktok':
            url = 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';
            name = 'TikTok Ads';
            subtitle = 'Promova seu dropshipping através de anúncios criativos na plataforma TikTok.';
            break;
        case 'gateway':
            url = 'https://storage.googleapis.com/static-images-source/gateway-icon.png';
            name = 'Gateway';
            subtitle = 'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.';
            break;
        case 'yampi':
            url = 'https://i.ibb.co/vB7Hhnk/yampi.png';
            name = 'Yampi';
            subtitle = 'Agência digital especializada em soluções de e-commerce e marketing online.';
            break;
        case 'kiwify':
            url = 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR9FPN3qxkbO5lZD8ZgkjT1AxVqm_uVssGOGj5LLS0aHe7EXlvr';
            name = 'Kiwify';
            subtitle = 'Plataforma para venda de infoprodutos que permite que criadores de conteúdo digital vendam produtos.';
            break;
        case 'cartpanda':
            url = 'https://storage.googleapis.com/static-images-source/cartpanda-icon.png';
            name = 'CartPanda';
            subtitle = 'Agência digital especializada em soluções de e-commerce e marketing online.';
            break;
        case 'yever':
            url = 'https://storage.googleapis.com/static-images-source/yever%20checkout.png';
            name = 'Yever';
            subtitle = 'Agência digital especializada em soluções de e-commerce e marketing online.';
            break;
        case 'dom':
            url = 'https://storage.googleapis.com/static-images-source/dom%20icon.png';
            name = 'Dom Pagamentos';
            subtitle = 'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.';
            break;
        case 'unicopag':
            url = 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png';
            name = 'Unico Pag';
            subtitle = 'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.';
            break;
        case 'shopify_checkout':
            url = 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg';
            name = 'Shopify Checkout';
            subtitle = 'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.';
            break;
        case 'adoorei':
            url = 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg';
            name = 'Adoorei';
            subtitle = 'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.';
            break;
        case 'tax':
            url = 'https://i.ibb.co/wrV5sPw/martin.png';
            name = 'Impostos';
            subtitle = 'Defina e personalize as taxas de imposto para ajustar à sua operação.';
            break;
        case 'shipping':
            url = 'https://storage.googleapis.com/static-images-source/frete%20icon.png';
            name = 'Frete';
            subtitle = 'Defina e personalize os custos de frete para ajustar à sua operação.';
            break;
    }

    return (
        <S.Container type={type}>
            <S.Image source={source ?? ''}>
                <img src={url} alt="Logo Image" />
            </S.Image>
            <S.Info>
                <S.Title>
                    {name} {type === 'inactive' && <FiberManualRecordIcon sx={{ width: '12px', height: '12px', color: '#d9dbd9' }} />}
                    {type === 'active' && <FiberManualRecordIcon sx={{ width: '12px', height: '12px', color: '#18C37F' }} />}
                </S.Title>
                <S.Subtitle>{subtitle}</S.Subtitle>
            </S.Info>
            <S.DetailsButton onClick={handleModal}>
                <ShareSharpIcon sx={{ width: '12px', height: '12px', color: '#989c97' }} />
                Detalhes
            </S.DetailsButton>
        </S.Container>
    );
}
