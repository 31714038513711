import * as S from './style';
import { FormsLogin } from './FormsLogin';
import { FormsSignUp } from './FormsSignUp';
import { FormsSignUpV2 } from './FormsSignUpV2';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { storageShExternalIdSave, storageShopSave } from '../../storage/storageCompanyToOnboard';
import LocationSearchingRoundedIcon from '@mui/icons-material/LocationSearchingRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import Groups3RoundedIcon from '@mui/icons-material/Groups3Rounded';
import { PlanCardSignUp } from './FormsSignUpV2/PlanCardSignUp';
import { getPlan } from '../../services/api/api-subscription';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export function Login() {
    const location = useLocation();
    const [showBackgroundImage, setShowBackgroundImage] = useState(true);
    const guest = new URLSearchParams(location.search).get('guest');
    const signup = new URLSearchParams(location.search).get('signup');
    const invite = new URLSearchParams(location.search).get('invite');
    const token = new URLSearchParams(location.search).get('token');
    const sh_external_id = new URLSearchParams(location.search).get('sh_external_id');
    const shop = new URLSearchParams(location.search).get('shop');
    
    const [showLogin, setShowLogin] = useState((guest !== null ? false : true) && (signup !== null ? false : true));
    
    const { shutdown } = useIntercom();

    useEffect(() => {
        if (sh_external_id) {
            storageShExternalIdSave(sh_external_id);
            if (shop){
                storageShopSave(shop);
            }            
        }
    }, [sh_external_id]);

    useEffect(() => {
        shutdown();
      }, [shutdown]);
    
    //console.log(guest);

    // SIGNUP LOGICS

    const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 1024);

    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
    };

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }
    }, []);

    const [selectedPlan, setSelectedPlan] = useState(() => {
        //console.log('selectedPlanSignUp', localStorage.getItem(`selectedPlanSignUp`));
        return localStorage.getItem(`selectedPlanSignUp`) ? localStorage.getItem(`selectedPlanSignUp`) : 'basic';
    });

    //console.log('selectedPlan', selectedPlan);

    useEffect(() => {
        // Assumindo que você está usando window.location.search para obter a query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
    
        const basicPlanUrl = urlParams.has('basic');
        const advancedPlanUrl = urlParams.has('advanced');
        const ultraPlanUrl = urlParams.has('ultra');

        if (basicPlanUrl) {
            //console.log('basicPlanUrl', basicPlanUrl);
            setSelectedPlan('basic')
        } else if (advancedPlanUrl) {
            //console.log('advanced', advancedPlanUrl);
            setSelectedPlan('advanced')
        } else if (ultraPlanUrl) {
            setSelectedPlan('ultra')
        }
      }, [selectedPlan]); // O array vazio garante que o efeito seja executado apenas uma vez após o componente montar
    
      useEffect(() => {
        //console.log('selectedPlanSet', selectedPlan)
        localStorage.setItem(`selectedPlanSignUp`, selectedPlan);
    }, [ selectedPlan ]);

    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [selectedPlanPrice, setSelectedPlanPrice] = useState('');
    const [selectedPlanMaxOrders, setSelectedPlanMaxOrders] = useState('');
    const [selectedPlanPricePerExtraOrder, setSelectedPlanPricePerExtraOrder] = useState('');

    const [plansData, setPlansData] = useState({
        Plano1: { price: '', limit_orders: '', var_price: '' },
        Plano2: { price: '', limit_orders: '', var_price: '' },
        Plano3: { price: '', limit_orders: '', var_price: '' },
    });

    useEffect(() => {
    const fetchPlans = async () => {
        const planIds = ['Plano 1', 'Plano 2', 'Plano 3'];
        try {
        setIsLoadingPlans(true);
        const fetchedPlans = await Promise.all(planIds.map(planId => getPlan(planId)));
        const plansData = fetchedPlans.reduce((acc, planData, index) => {
            acc[`Plano${index + 1}`] = {
            price: planData.price,
            limit_orders: planData.limit_orders,
            var_price: planData.var_price,
            };
            return acc;
        }, {});
        setPlansData(plansData);
        } catch (err) {
        console.error('Error fetching plans:', err);
        } finally {
        setIsLoadingPlans(false);
        }
    };
    
    fetchPlans();
    }, []);
    
    const plans = {
        basic: {
          id: 'Plano 1',
          planName: 'Plano Essencial',
          price: plansData.Plano1.price,
          maxOrders: plansData.Plano1.limit_orders,
          pricePerExtraOrder: plansData.Plano1.var_price,
          savings: '',
          description: '',
        },
        advanced: {
          id: 'Plano 2',
          planName: 'Plano Premium',
          price: plansData.Plano2.price,
          maxOrders: plansData.Plano2.limit_orders,
          pricePerExtraOrder: plansData.Plano2.var_price,
          savings: '',
          description: '',
        },
        ultra: {
          id: 'Plano 3',
          planName: 'Plano Avançado',
          price: plansData.Plano3.price,
          maxOrders: '', 
          pricePerExtraOrder: plansData.Plano3.var_price,
          savings: '',
          description: '',
        },
      };

    localStorage.setItem(`plansArraySignUp`, JSON.stringify(plans));
        
    const currentPlan = plans[selectedPlan];

    return (
        <S.Container isMobile={isMobile}>
            {showBackgroundImage && showLogin && (
            <S.LeftColumn isMobile={isMobile}>
                {/* <S.MainTitle style={{marginBottom: '-1rem'}}>
                <S.Title>Todos seus dados na palma da sua mão</S.Title>
                <S.SubtitleSection>
                    <S.Subtitle>Tecnologia e sabedoria aplicada ao seu dropshipping.</S.Subtitle>
                </S.SubtitleSection>

                <S.CardsContainer>
                    <S.Card>
                        <LocationSearchingRoundedIcon/>
                        Traqueamento
                    </S.Card>
                    <S.Card>
                        <QueryStatsRoundedIcon/>
                        Analytics
                    </S.Card>
                    <S.Card>
                        <Groups3RoundedIcon/>
                        Segmentação
                    </S.Card>
                </S.CardsContainer>
            </S.MainTitle> */}
            </S.LeftColumn>)}
            {showBackgroundImage && !showLogin && (

                    <S.LeftColumnSignup isMobile={isMobile} style={{backgroundImage: 'url(https://storage.googleapis.com/static-images-source/login-bg-3.png)', justifyContent: 'center', alignItems: 'center'}}>
                    <S.MainTitle isMobile={isMobile} style={{marginBottom: '-1rem'}}>
                        {/* <S.Title isMobile={isMobile}>Escale com segurança.</S.Title>
                        <S.SubtitleSection>
                            <S.Subtitle isMobile={isMobile}>Alcance um novo patamar de lucratividade, aumentando seu lucro em até 30%</S.Subtitle>
                        </S.SubtitleSection> */}
                        {/* <S.BulletList>
                            <S.BulletItem><CheckCircleRoundedIcon/>Tenha marcação correta e saiba exatamente onde escalar
                            </S.BulletItem>
                            <S.BulletItem><CheckCircleRoundedIcon/>Acompanhe o Lucro de sua operação de forma 100% automatizada
                            </S.BulletItem>
                            <S.BulletItem><CheckCircleRoundedIcon/> Saiba exatamente o cliente/pedido por anúncio com nosso gerenciador de anúncios turbinado</S.BulletItem>
                        </S.BulletList>

                        <S.BulletLastItem>E muito mais!</S.BulletLastItem> */}
                    </S.MainTitle>
    
    
                    <PlanCardSignUp
                        disabled={true}
                        updatePlan={setSelectedPlan}
                        addStore={true}
                        isLoadingPlans={isLoadingPlans}
                        isMobile={isMobile}
                        plans={plans}
                        onSelect={() => {}}
                        isSelected={true}
                        savings={currentPlan?.savings}
                    />
                    </S.LeftColumnSignup>)}
            <S.RightColumn>
                <FormsLogin showLogin={showLogin} setShowBackgroundImage={setShowBackgroundImage} />
                <FormsSignUpV2 isMobile={isMobile} guest={guest ?? ''} invite={invite ?? ''} token={token ?? ''} setShowBackgroundImage={setShowBackgroundImage} showSignUp={!showLogin} setShowLogin={setShowLogin} />
            </S.RightColumn>
        </S.Container>
    );
}
