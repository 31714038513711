import React from 'react';
import * as S from './style';
import { toBrazilianFormat } from '../../../utils';
import {formatPercentage} from '../../../utils';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { trackEvent  } from '../../../services/segment/sendEvent';

const LinesItem = ({HasCatalog, handleCatalogClick, columns, currencySymbol, ads, ordersSolomon, revenueSolomon, type, openSalesModal,  currentCompany, date, adsString, source, user, loadingCatalog}) => {
    let openSalesModalFunction = () => {};
    if (type === 'ads'){
        openSalesModalFunction =() => openSalesModal({
            source: source,
            utm_campaign: '',
            company_id: currentCompany,
            level: 'ad',
            start_date: date[0],
            end_date: date[1],
            ads_ids: adsString ?? '',
            name: ads?.adName
        },
            {
                revenueBySource: ads?.revenueBySource,
                spendBySource: ads?.spendBySource
            },
            type);
    }else if (type === 'adsSet'){
        openSalesModalFunction = () => openSalesModal({
            source: source,
            utm_campaign: '',
            company_id: currentCompany,
            level: 'adgroup',
            start_date: date[0],
            end_date: date[1],
            ads_ids: adsString ?? '',
            name: ads?.adsSetName
        },
            {
                revenueBySource: ads?.revenueBySource,
                spendBySource: ads?.spendBySource
            }, type);
    } else if (type === 'campaign_other'){
        if (ads?.campaignName === "Google Shopping") {

            openSalesModalFunction = () => openSalesModal({
                source: "",
                company_id: currentCompany,
                level: 'utm_campaign',
                start_date: date[0],
                end_date: date[1],
                name: ads?.campaignName,
                utm_campaign: ads?.campaignName
            },
            {
                revenueBySource: '',
                spendBySource: ''
            },
            'campaign');
        
        } else {
            openSalesModalFunction = () => openSalesModal({
                source: source,
                company_id: currentCompany,
                level: 'utm_campaign',
                start_date: date[0],
                end_date: date[1],
                name: ads?.campaignName,
                utm_campaign: ads?.campaignName
            },
            {
                revenueBySource: '',
                spendBySource: ''
            },
            'campaign');
        }


    }else if (type === 'campaign_normal'){
        openSalesModalFunction =  () => openSalesModal({
            source: source,
            company_id: currentCompany,
            ads_ids: adsString,
            level: 'campaign',
            start_date: date[0],
            end_date: date[1],
            name: ads?.campaignName
        },{
            spendBySource: ads?.spendBySource,
            revenueBySource: ads.revenueBySource,
        },
        'campaign')
    }else if (type === 'channel'){
        openSalesModalFunction = () => openSalesModal({
            source: source,
            utm_campaign: '',
            company_id: currentCompany,
            level: 'source',
            start_date: date[0],
            end_date: date[1]
        }, {
            spendBySource: ads.spendBySource,
            revenueBySource: ads.revenueBySource,
        },
            'channel')
    }else if (type === 'moreDetailOthers'){
        openSalesModalFunction = () => openSalesModal({
            source,
            utm_campaign: '',
            company_id: currentCompany,
            level: 'source',
            start_date: date[0],
            end_date: date[1]
        }, {
            spendBySource: '',
            revenueBySource: '',
        },
            'channel')
    }
    if (type === 'account'){
        // //console.log('ads?.spendBySource', ads?.spendBySource);
    }

    function handleModalSales() {

        if (loadingCatalog) {
            return;
        }

        if (HasCatalog) {
            trackEvent('open-catalog', {
                userId: user.user_id,
                company_id: currentCompany,
                company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
                timestamp: new Date().toISOString()
            })
            handleCatalogClick();
        } else {
            trackEvent('open-sales', {
                userId: user.user_id,
                company_id: currentCompany,
                company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
                timestamp: new Date().toISOString()
            })
            openSalesModalFunction();
        }
    }

    if (!type && !source) {
        return null;
    }
    
    
    return (
        <>
        {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
            <React.Fragment key={column.id}>

                {column.id == 'spend' && (
                    <S.DefaultColumn>{ currencySymbol }  {toBrazilianFormat(ads?.spendBySource)}</S.DefaultColumn>
                )}
                {column.id == 'profit' && (
                  <S.DefaultColumn
                  style={{ color: ads?.profitByUtmApproved < 0 ? 'red' : 'var(--green)' }} // Vermelho para negativo, Verde para positivo
                >
                  { currencySymbol } 
                  
                  {type === 'others' ? toBrazilianFormat(ads?.profitOthers) : (type === 'campaign_other' ? toBrazilianFormat(ads?.profit.metrics) : toBrazilianFormat(ads?.profitByUtmApproved))}
                </S.DefaultColumn>
                )}
                {column.id == 'productCostUtm' && (
                  <S.DefaultColumn>
                  { currencySymbol } 
                  
                  {type === 'others' ? toBrazilianFormat(ads?.productCostOthers) : (type === 'campaign_other' ? toBrazilianFormat(ads?.product_cost.metrics) : toBrazilianFormat(ads?.productCostByUtmApproved))}
                </S.DefaultColumn>
                )}
                {column.id == 'margin' && (
                  <S.DefaultColumn
                  style={{ color: ads?.profitByUtmApproved / revenueSolomon < 0 ? 'red' : 'var(--green)' }}>
                    {ads && revenueSolomon !== 0 ? formatPercentage((ads?.profitByUtmApproved / revenueSolomon) * 100) : '0'}%
                </S.DefaultColumn>
                )}
                {column.id == 'sales' && (
                    <S.DefaultColumn>{toBrazilianFormat(ads?.ordersBySource, false) ?? 0}</S.DefaultColumn>
                )}
               {column.id === 'salesUtm' && (
                    <S.DefaultColumn>
                        {ordersSolomon}
                        {(type === 'ads' || type === 'adsSet' || type === 'campaign_other' || type === 'campaign_normal' || type === 'channel' || type === 'moreDetailOthers') && (
                            <S.MetricsButton loadingCatalog={loadingCatalog} HasCatalog={HasCatalog} onClick={handleModalSales}>
                                <>
                                    {HasCatalog ? (
                                        <Tooltip 
                                            title={loadingCatalog ? "Carregando produtos de catálogo" : "Ver produtos do catálogo." }
                                            placement="top" 
                                            arrow  
                                            componentsProps={{
                                                arrow: { sx: { color: 'var(--black)' } }, 
                                                tooltip: { 
                                                    sx: { 
                                                        bgcolor: 'var(--black)',
                                                        fontWeight: 'semibold',
                                                        fontSize: '13px',
                                                        fontFamily: 'Montserrat',
                                                        borderRadius: '9px',
                                                        padding: '7px',
                                                        color: 'white',
                                                        textWrap: 'wrap',
                                                        zIndex: '0'
                                                    }
                                                },
                                            }}
                                        >
                                            {loadingCatalog ? (
                                                <CircularProgress thickness={7} size={16} sx={{display: 'flex', alignItems:'center', justifyContent: 'center', color: '#e7e7e7'}} />
                                            ) : (
                                                <ViewModuleRoundedIcon />
                                            )}
                                        </Tooltip>
                                    ) : (
                                        <>
                                        {loadingCatalog ? (
                                                <CircularProgress thickness={7} size={16} sx={{display: 'flex', alignItems:'center', justifyContent: 'center', color: '#CCC'}} />
                                            ) : (
                                            <FrameInspectIcon />
                                        )}
                                        </>
                                    )}
                                </>
                            </S.MetricsButton>
                        )}

                    </S.DefaultColumn>
                )}

                {column.id == 'revenue' && (
                    <S.DefaultColumn>{ currencySymbol }  {toBrazilianFormat(ads?.revenueBySource)}</S.DefaultColumn>
                )}
                {column.id == 'revenueUtm' && (
                    <S.DefaultColumn>{ currencySymbol }  {toBrazilianFormat(revenueSolomon)}</S.DefaultColumn>
                )}

                {column.id == 'ticket' && (
                    <S.DefaultColumn>{ currencySymbol }  {ads?.revenueBySource !== 0 ? toBrazilianFormat(ads?.revenueBySource / ads?.ordersBySource) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'ticketUtm' && (
                    <S.DefaultColumn>{ currencySymbol }  {revenueSolomon && ordersSolomon !== 0 ? toBrazilianFormat(revenueSolomon / ordersSolomon) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'roas' && (
                    <S.DefaultColumn>{ads?.spendBySource !== 0 ? toBrazilianFormat(ads?.revenueBySource / ads?.spendBySource) : '0,00'}x</S.DefaultColumn>
                )}
                {column.id == 'roasUtm' && (
                    <S.DefaultColumn>{ads?.spendBySource !== 0 ? toBrazilianFormat(revenueSolomon / ads?.spendBySource) : '0,00'}x</S.DefaultColumn>
                )}
                {column.id == 'cpa' && (
                    <S.DefaultColumn>{ currencySymbol }  {ads && ads?.ordersBySource !== 0 ? toBrazilianFormat(ads?.spendBySource / ads?.ordersBySource) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'cpaUtm' && (
                    <S.DefaultColumn>{ currencySymbol }  {ads && ordersSolomon !== 0 ? toBrazilianFormat(ads?.spendBySource / ordersSolomon) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'cpc' && (
                    <S.DefaultColumn>{ currencySymbol }  {ads?.clicksBySource !== 0 ? toBrazilianFormat(ads?.spendBySource / ads?.clicksBySource) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'cpm' && (
                    <S.DefaultColumn>{ currencySymbol }  {ads?.impressionsBySource !== 0 ? toBrazilianFormat((1000 * ads?.spendBySource) / ads?.impressionsBySource) : '0,00'}</S.DefaultColumn>
                )}
                {column.id == 'impressions' && (
                    <S.DefaultColumn>{ads?.impressionsBySource ? Number(ads?.impressionsBySource).toLocaleString('pt-BR') : '0'}</S.DefaultColumn>
                )}
                {column.id == 'clicks' && (
                    <S.DefaultColumn>{ads?.clicksBySource ? Number(ads?.clicksBySource).toLocaleString('pt-BR') : '0'}</S.DefaultColumn>
                )}
                {column.id == 'viewContent' && (
                    <S.DefaultColumn>{ads?.pageViewsBySource ? Number(ads?.pageViewsBySource).toLocaleString('pt-BR') : '0'}</S.DefaultColumn>
                )}
                {column.id == 'addToCart' && (
                    <S.DefaultColumn>{ads?.addToCartsBySource ? Number(ads?.addToCartsBySource).toLocaleString('pt-BR') : '0'}</S.DefaultColumn>
                )}
                {column.id == 'initiateCheckout' && (
                    <S.DefaultColumn>{ads?.checkoutsBySource ? Number(ads?.checkoutsBySource).toLocaleString('pt-BR') : '0'}</S.DefaultColumn>
                )}
            </React.Fragment>
        ))}
        </>
    )
}



export default LinesItem;

function FrameInspectIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M450-420q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm193 160L538-365q-20 13-42.5 19t-45.5 6q-71 0-120.5-49.5T280-510q0-71 49.5-120.5T450-680q71 0 120.5 49.5T620-510q0 23-6.5 45.5T594-422l106 106-57 56ZM200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80Z" />
        </svg>
    )
}

