import { useEffect, useState } from 'react';
import * as S from './style';
import Modal from '../../../components/Modal';
import { InputWithBorder } from '../../../components/InputWithBorder';
import { toBrazilianFormat } from '../../../utils';
import { parseISO } from 'date-fns';
import { storageCurrentUserMetaGet, storageCurrentUserMetaSave } from '../../../storage/storageCurrentUser';
import useAuth from '../../../hooks/useAuth';
import { useCurrencyType } from '../../../hooks/useCurrencyType';

export function Meta({ isLoading, profit, approvedRevenue }: { isLoading: boolean; profit: number; approvedRevenue: number }) {
    const { currentCompany } = useAuth();
    const { currencySymbol } = useCurrencyType();


    const [showModal, setShowModal] = useState(false);
    const [meta, setMeta] = useState({
        value: `${currencySymbol} 0,00`,
        type: 'lucro',
        start: '',
        end: '',
    });

    const [savedMeta, setSavedMeta] = useState(
        storageCurrentUserMetaGet(currentCompany).type?.length > 0
            ? storageCurrentUserMetaGet(currentCompany)
            : {
                  value: `${currencySymbol} 0,00`,
                  type: 'lucro',
                  start: '',
                  end: '',
              }
    );

    function handleChangeMeta(e: any) {
        const { name, value } = e.target;
    
        if (name === 'value') {  // Apenas aplica formatação quando o campo é o valor
            let newValue = value.replace(/\D/g, '');  // Remove todos os não-numéricos
    
            // Tratar o caso de valores grandes
            if (newValue.length > 6) {
                newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');  // Últimos dois dígitos como centavos
                newValue = newValue.replace(/(\d)(?=(\d{3})+(\D))\B/g, '$1.');  // Formatação de milhar e milhão
            } else {
                newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
                newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
            }
    
            newValue = `${currencySymbol} ${newValue}`;
            setMeta({ ...meta, [name]: newValue });
        } else {
            // Para os outros campos que não são 'value', apenas atualiza o valor normalmente
            setMeta({ ...meta, [name]: value });
        }
    }
    

    function handleSubmitMeta(e: any) {
        e.preventDefault();
    
        let value = meta.value.replace(`${currencySymbol} `, '').replace(/\./g, '').replace(',', '.');  // Substitui '.' por '', e ',' por '.'
        value = `${currencySymbol} ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        
        setSavedMeta({ ...meta, value: value });
        storageCurrentUserMetaSave({ ...meta, value: value }, currentCompany);
        setShowModal(false);
    }
    

    const newValue = typeof savedMeta?.value === 'string'
    ? parseFloat(savedMeta?.value.replace(`${currencySymbol} `, '').replace(/\./g, '').replace(',', '.'))
    : savedMeta?.value || 0;


    const newProfit = profit;
    const newApprovedRevenue = approvedRevenue;
    const percentage =
        newValue !== 0
            ? savedMeta?.type === 'lucro'
                ? newProfit >= 0
                    ? (newProfit / newValue) * 100
                    : -((newValue - newProfit) / newValue) * 100
                : savedMeta?.type === 'receita'
                  ? newApprovedRevenue >= 0
                      ? (newApprovedRevenue / newValue) * 100
                      : -((newValue - newApprovedRevenue) / newValue) * 100
                  : 0
            : 0;

    const newPercentage = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const isDisabled =
            meta.value === `${currencySymbol} 0,00` || meta.value === '' || meta.start === '' || meta.end === '' || parseISO(meta.end) < new Date() || parseISO(meta.start) > parseISO(meta.end);
        setIsDisabled(isDisabled);
    }, [meta]);

        
    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />
            </S.ContainerLoading>
        );
    }

    function formatNumber(value: number | null, formatInteger = true) {
        if (!value && formatInteger){
    
            return '0,00';
        }
    
        if (!value && !formatInteger) {
            return '0'
        }
    
        if (Number.isInteger(value) && !formatInteger) {
            return value 
        }
    
        return value
    }

    return (
        <S.Container>
            <S.TopSection>
                <span>
                    <S.Percentage>{toBrazilianFormat(newPercentage)}%</S.Percentage>
                    <div> 
                        <S.Status>STATUS DA META:</S.Status>
                        <S.Information>{savedMeta?.type === 'lucro' ? 'Lucro Líquido' : savedMeta?.type === 'receita' ? 'Receita Aprovada' : 'Sem metas criadas'}</S.Information>
                    </div>
                </span>
                <S.MetaSection>
                <span>
                    {savedMeta?.end !== '' && <S.Days>{ (parseISO(savedMeta?.end).getTime() - new Date().getTime()) > 0 ? `Acaba em ${Math.floor(Math.abs((parseISO(savedMeta?.end).getTime() - new Date().getTime()) / (1000 * 3600 * 24)))} dias` : 'O prazo da meta chegou ao fim.'}</S.Days>}
                    <S.EditIcon onClick={() => setShowModal(true)}>
                        <EditIcon />
                    </S.EditIcon>
                </span>
                <S.Meta>
                 META: <span>{savedMeta?.value !== `${currencySymbol} 0,00` ? `${formatNumber(savedMeta?.value)}` : `${currencySymbol} 0,00`}</span>
                </S.Meta>
            </S.MetaSection>
            </S.TopSection>

            

            <S.LineSection>
                <S.Line>
                    <S.Progress percentage={newPercentage} />
                </S.Line>
            </S.LineSection>

            {showModal && (
                <Modal onClose={() => setShowModal(false)}>
                    <S.ContainerModal>
                        <Flag />
                        <S.TitleModal>
                            Criar uma nova <span>meta</span>
                        </S.TitleModal>
                        <S.SubtitleModal>Personalize suas metas de desempenho e acompanhe seu progresso</S.SubtitleModal>
                        <S.LineModal />
                        <form onSubmit={handleSubmitMeta}>
                            <InputWithBorder inputId="value" inputName="Nome do grupo:" placeholder="Insira um valor" value={meta.value} setValue={handleChangeMeta} />

                            <S.InputSelect>
                                <label>Tipo de métrica</label>
                                <select name="type" onChange={handleChangeMeta}>
                                    <option value="lucro">Lucro Líquido</option>
                                    <option value="receita">Receita Aprovada</option>
                                </select>
                            </S.InputSelect>

                            <S.InputDate>
                                <label>Começar em:</label>
                                <input onChange={handleChangeMeta} value={meta.start} name="start" type="date" />
                            </S.InputDate>
                            <S.InputDate>
                                <label>Terminar em:</label>
                                <input onChange={handleChangeMeta} value={meta.end} name="end" type="date" />
                            </S.InputDate>
                            <S.ButtonSubmitModal disabled={isDisabled} type="submit">
                                Salvar meta
                            </S.ButtonSubmitModal>
                        </form>
                    </S.ContainerModal>
                </Modal>
            )}
        </S.Container>
    );
}

function EditIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" />
        </svg>
    );
}

function Flag() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M200-120v-680h360l16 80h224v400H520l-16-80H280v280h-80Zm300-440Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z" />
        </svg>
    );
}
