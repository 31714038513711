import styled from 'styled-components';

export const Container = styled.div`
    min-width: 220rem;
    display: flex;
    position: relative;
`;

type MainColumnProps = {
    type: string;
    isMainSource: boolean;
    isWithoutAttribution?: boolean;
};

export const MetricsButton = styled.div`
position: absolute;
right: 5rem;
  width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--green);
    }
`

export const Edit = styled.div`
display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 10%;
`

export const CheckBoxArea = styled.div`
    min-width: 2.6875rem;
    display: flex;
    // height: 4.2rem;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dedede;
    cursor: pointer;
    margin-left: -3.5rem;
    margin-right: 1.5rem;
    padding-right: 8px;
`;

export const CheckBoxAreaOthers = styled.div`
    min-width: 2.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #dedede;
    cursor: pointer;
    margin-left: -3.5rem;
    margin-right: 2.5rem;
    padding-right: 8px;
`;


export const CheckBoxAreaAccount = styled.div`
    min-width: 3.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #dedede;
    cursor: pointer;
    padding-right: 0.6rem;
    margin-left: -1.3rem;
    margin-right: 0.6rem;
`;

export const AnalyticsButton = styled.div`
    min-width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const MainColumn = styled.div<MainColumnProps>`
    font-size: 0.875rem;
    font-weight: 500;
    will-change: width;
    color: var(--black);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4rem;
    padding-right: 10.5rem;
    width: 29.125rem;
    height: 3.3rem;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    gap: 0.5rem;

    img {
            width: 0.950rem;
            height: 0.9rem;
            ${(props) => !props.isMainSource && 'margin-left: 0.450rem; width: 0.950rem; height: 0.9rem;'}    
        }
            
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    span {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-wrap: nowrap;
        font-weight: 550;
        ${(props) =>
            props.isWithoutAttribution
                ? `

        
        `
                : `
        &:hover{
            cursor: pointer;
            text-decoration: underline;
            color: var(--green);
        }
        
        `}
    }
`;

export const DefaultColumn = styled.div`
    font-size: 0.835rem;
    font-weight: 500;
    position: relative;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;

export const ContainerAccount = styled(Container)`

`;

export const MainColumnAccount = styled(MainColumn)`
    width: 29.825rem;

    // img {
    //     height: 1rem;
    //     width: auto;
    //     margin-left: 3rem;
    // }
   
`;

export const UrlLinkClick = styled.span`
    display:flex;
    align-items: center;
    gap: 0.5rem;

`;

export const AnalyticsButtonAccount = styled.div`
    min-width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    // margin-left: 1.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const AdsAccountsTitle = styled.div`
    background-color: #e6e6e6;
    color: #527566;
    min-width: 8rem;
    width: 8rem;
    height: 0.96875rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    font-weight: 600;
`;


export const ExpandContainer = styled.div`
    display: flex;
    color: #19c27e;
    background-color: #f8f8f8;
    border-radius: 6px;
    // border: 1px solid #ccc;
`

export const ProductNameContainer = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.05rem;
    color: #081b16;
    position: absolute;
    right: 0;
    padding: 15px;
    background: inherit;
    width: fit-content;

    svg {
        width: 1rem;
        height: 1rem;
        fill: #E90E0E;
        margin-left: 0.25rem;
    }
`;

export const ProductNameContainerPlaceholder = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.05rem;
    color: #081b16;
    position: absolute;
    right: 0;
    padding: 15px;
    background: inherit;
    width: 20px;

    svg {
        width: 1rem;
        height: 1rem;
        fill: #E90E0E;
        margin-left: 0.25rem;
    }
`;

export const ActionArea = styled.div`
margin-left: 0.5rem;
width: fit-content;
position: absolute;
display: flex;
align-items: center;
z-index: 10;
right: 0;
padding: 15px;
padding-left: 28px;
padding-right: 6px;
background: inherit;

>svg{
    cursor: pointer;
    fill: #B0AFAF;
    width: fit-content;
    padding-right: 6px;
    &:hover {
        fill: #1C343A;
    }
}
`