import * as S from './style';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import useAuth from '../../../../hooks/useAuth';
import { reduceString } from '../../../../utils';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { SelectHeader } from './SelectHeader';
import { useNavigate } from 'react-router-dom';
import { Refresh } from './Refresh';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { Notification } from './Notification';
import useIsMobile from '../../../../hooks/useIsMobile';
import CustomerIcon from '../../../../components/CustomerIcon';
// import Survey from '../../../../components/Survey';
// import Modal from '../../../../components/Modal';
// import { useEffect, useState } from 'react';
// import { differenceInDays, parse } from 'date-fns';

export function Header({ showSelect, setShowSelect, showPopup, setShowPopup, hasPayment }: { showSelect: boolean; setShowSelect: (showSelect: any) => void; showPopup: boolean; setShowPopup: (showPopup: any) => void, hasPayment: boolean }) {
    const { user, currentCompany } = useAuth();
    const navigate = useNavigate();
    
    const companyName = reduceString(user?.companies?.[currentCompany]?.company === undefined ? 'Carregando': user?.companies?.[currentCompany]?.company, 20);
    // const [showUserName, setShowUserName] = useState(true);
    const { isVisible, toggleVisibility } = useVisibility();

    const isMobile = useIsMobile();

    const handleNavigate = () => {
        if (!isMobile){
            navigate('/dashboard');
        } else if (isMobile){
            navigate('/dashboardMobile');
        }
    }

    // const [showSurvey, setShowSurvey] = useState(false);
    
    // const onSubmit = () => {
    //     setShowSurvey(false);
    //     localStorage.setItem('surveySubmittedTwo', 'true');
    // };

    // useEffect(() => {
    //     const surveySubmitted = localStorage.getItem('surveySubmittedTwo');
        // const createdAt = user.created_at;
        // const createdAtDate = parse(createdAt, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
        // const today = new Date();
        // const daysSinceCreation = differenceInDays(today, createdAtDate);
        // //console.log('daysSinceCreation', daysSinceCreation);

    //     if (!surveySubmitted) {
    //         setShowSurvey(true);
    //     }
    // }, [user]);


    return (
        <S.Container isMobile={isMobile} hasPayment={hasPayment}>
            {/* {showSurvey && (
                <Modal>
                    <Survey surveyName='Talk_to_users_1' onSubmit={onSubmit} />
                </Modal>
            )} */}
            <S.MobileContainer>
                <S.LogoContainer isMobile={isMobile} onClick={() => handleNavigate()}>
                    <img src={ isMobile ? 'https://storage.googleapis.com/static-images-source/Solomon-logo-icon.png' : 'https://solomon.com.br/wp-content/uploads/2023/04/Ativo-7Logo-Solomon-Green-Horizas2.png'} alt="Logo Solomon" />
                </S.LogoContainer>
                {/* <S.Stepper>
                    <Stepper />
                </S.Stepper> */}
                <S.Information>
                    {!isMobile && (
                        <S.Refresh>
                            <Refresh />
                        </S.Refresh>
                    )}
                    <S.AlertsContainer isMobile={isMobile} onClick={() => setShowPopup((prev: any) => !prev)} >
                        <NotificationsNoneRoundedIcon sx={{ width: '24px', height: '24px', color: '#999', marginLeft: '0.5rem;', marginRight: '0.5rem;' }} />
                        <Notification setShowSelect={setShowPopup} showSelect={showPopup} />
                    </S.AlertsContainer>
                    <S.User>
                    {!isMobile && (
                            <>
                            {isVisible ? (
                                <VisibilityOutlinedIcon
                                sx={{
                                    width: '19px',
                                    height: '19px',
                                    color: '#999',
                                    marginLeft: '0.7rem',
                                }}
                                onClick={toggleVisibility}
                                />
                            ) : (
                                <VisibilityOffOutlinedIcon
                                sx={{
                                    width: '19px',
                                    height: '19px',
                                    color: 'var(--black)',
                                    marginLeft: '0.7rem',
                                }}
                                onClick={toggleVisibility}
                                />
                            )}
                            </>
                    )} 

                    {isMobile ? (

                    <S.UserContainerMobile onClick={() => setShowSelect((prev: any) => !prev)}>
                            <S.FlexUser>
                                <S.UserName showUserName={isVisible} >{companyName !== undefined ?  companyName : 'Carregando'}</S.UserName>
                                <S.RefreshMobile>
                                    <Refresh />
                                </S.RefreshMobile>
                            </S.FlexUser>
                            <S.UserIcon style={{marginLeft: '0.5rem'}}>
                            <CustomerIcon fontColor={'white'} fontSize={'0.8rem'} name={companyName} />
                            </S.UserIcon>
                            <SelectHeader isMobile={isMobile} setShowSelect={setShowSelect} showSelect={showSelect} />
                        </S.UserContainerMobile>

                    ) : (

                        <S.UserContainer onClick={() => setShowSelect((prev: any) => !prev)}>
                            <S.UserIcon>
                                <CustomerIcon fontColor={'white'} fontSize={'0.7rem'} name={companyName} />
                            </S.UserIcon>
                            <S.UserName showUserName={isVisible} >{companyName !== undefined ?  companyName : 'Carregando'}</S.UserName>
                            <SelectHeader isMobile={isMobile} setShowSelect={setShowSelect} showSelect={showSelect} />
                        </S.UserContainer>

                    )}

                        
                    </S.User>
                </S.Information>
            </S.MobileContainer>
        </S.Container>
    );
}
