import { GraphProps } from '../../../../@types';
import { getMetricsProducts } from '../../utils';
import { CardProducts } from './CardProducts';
import { TablePlaceholder } from '../../../../components/TablePlaceholder';
import * as S from './style';

import { useNavigate } from 'react-router-dom';

export function Products({ isLoading, data }: GraphProps) {
    const { products } = getMetricsProducts(data);

    const navigate = useNavigate();

    if (isLoading) {
        return (
            <S.Container>
                <S.LoadingContainer />
            </S.Container>
        );
    }

    if (products.length === 0) {
        return (
            <S.Container>
                <S.Title>Produtos</S.Title>
                <S.InnerContainer>
                    <TablePlaceholder size='small' />
                </S.InnerContainer>
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Title>Produtos</S.Title>
            <S.Button onClick={() => navigate('/produtos')}>Ver mais</S.Button>
            <S.InnerContainer>
                {products?.map((product) => {
                    return <CardProducts key={product.id} productCost={product.productCost} name={product.name} quantity={product.quantity} revenue={product.revenue} image={product.image} productId={product.id} />;
                })}
            </S.InnerContainer>
        </S.Container>
    );
}
