import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 29.6875rem;
    height: 12.625rem;
    border-radius: 12px;

    padding-top: 3rem;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const ContainerLoading = styled.div`
    background-color: var(--white);
    width: 29.6875rem;
    height: 15.625rem;
    border-radius: 12px;
    position: relative;
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
`;

export const Value = styled.div`
    // width: 10.5rem;
    height: 1.5625rem;
    border-radius: 30px;
    // background-color: #f4f4f4;
    font-size: 0.875rem;
    font-weight: 550;
    line-height: 1.3125rem;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem;
    span {
        font-weight: 550;
        font-size: 0.85rem;
        margin-top: -3px;   
        color: var(--green);
    }
`;

export const Bar = styled.div`
    width: 6.0536875rem;
    height: 10px;
    border-radius: 50px;
    background-color: #e0e0e0;
    margin: auto 0;
    position: relative;
`;

type BarValueProps = {
    percentage: string;
    color?: string;
};

function getWidth(percentage: string) {
    const newPercentage = Number(percentage) / 100;
    const newWidth = 9.21875 * newPercentage;
    return `width: ${newWidth}rem;`;
}

export const BarValue = styled.div<BarValueProps>`
    max-width: 6.21875rem;
    ${(props) => getWidth(props.percentage)}
    height: 10px;
    border-radius: 50px;
    ${(props) => props.color && `background-color: ${props.color}`};
    ${(props) => !props.color && 'background: linear-gradient( 94deg, #26322d -2.89%, #607376 88.32%)'};
    margin: auto 0;
    position: absolute;
`;

export const Line = styled.div`
    height: 1px;
    width: 95%;
    background-color: #ebebeb;
    margin: 0.7rem auto;
`;

export const RevenueType = styled.div`
    font-size: 0.75rem;
    font-weight: 550;
    color: var(--black);
    display: flex;
    align-items: center;
    width: 6.5rem;
    gap: 0.25rem;
`;

export const RevenueContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
    padding: 0 0.75rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
`;


export const Column = styled.div`
    font-size: 0.8rem;
    font-weight: 550;
    color: #585858;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ColumnLines = styled.div`
    font-size: 0.9rem;
    font-weight: 550;
    // color: #585858;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerLines = styled.div`
    display: flex;
    width: 98%;
    margin-top: -0.25rem;

    &:first-child {
        margin-top: 0;
    }
`;


export const MainColumn = styled.div`
    font-size: 0.8125rem;
    font-weight: 550;
    color: var(--black);
    width: 100%;
    text-wrap: nowrap;
    height: 2.78125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 0.5rem;
    // margin-right: -0.5rem;
//    margin-right: 0.75rem;

    p {
        font-size: 0.8125rem;
        fon-weight: 550;
    }

    svg{
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.25rem;
    }
`;


