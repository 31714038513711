import { Outlet, Navigate } from 'react-router-dom';
import * as S from './style';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { useEffect, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { storageUserGet } from '../../../storage/storageUser';
import { storageAuthTokenGet } from '../../../storage/storageAuthToken';
import { storageCurrentUserGet } from '../../../storage/storageCurrentUser';
import { signInCurrentUserSchemaLocalStorage, signInTokenSchemaLocalStorage, signInUserSchemaLocalStorage } from '../../../schemas/authSchema';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../../../hooks/useAuth';
import { Loading } from '../../../components/Loading';
import { AccessDenied } from '../../../components/AccessDenied';
import { Payment } from '../../../components/Payment';
import { useLocation } from 'react-router-dom';
import  Modal from '../../../components/Modal';
import { ButtonApp } from '../../../components/ButtonApp';
import { ModalFacebookUrl } from '../../Integration/ModalIntegration/TypesModalIntegration/Facebook/ModalFacebookUrl';
import { useGetUrlFacebokAdsAuthRevalidate, useRemoveFacebookAdsProfile } from '../../../services/hooks/integrationHooks';
import { useRemoveFacebookAdsAccount } from '../../../services/hooks/integrationHooks';
import { userDTO } from '../../../@types';
import useIsMobile from '../../../hooks/useIsMobile';

export function AppLayout(grayBackground?: any ) {
    const [showSideBar, setShowSideBar] = useState(true);
    const [authenticated, setAuthenticated] = useState(true);
    const [hasPayment] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [showSelect, setShowSelect] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const {user, token, currentCompany,setUserContext, setTokenContext, setCurrentCompanyContext } = useAuth();
    const [showModalFacebook, setShowModalFacebook] = useState(false);
    const [hasRefreshTokenFailed, setHasRefreshTokenFailed] = useState(false);
    const { data: urlFacebookData, isLoading: isLoadingGetUrlFacebook } = useGetUrlFacebokAdsAuthRevalidate(user?.user_id, token?.session_token, currentCompany, showModalFacebook);
    const {mutate: handleRemoveFacebookProfile, isLoading: isLoadingRemoveFacebookAccount} = useRemoveFacebookAdsProfile();
    const isLoadingHttp = isLoadingGetUrlFacebook || isLoadingRemoveFacebookAccount;
    const location = useLocation();

    const [showPopup, setShowPopup] = useState(false);
    const isMobile = useIsMobile();

    const keys: {[key: string]: string} = {
        'tracking': 'marketing',
        'dashboard': 'summary',
        'clientes': 'customers',
        'integracao': 'integrations',
        'produtos': 'products',
        'custo': 'products_costs',
        'adicionais': 'additional_costs',
        'pedidos': 'orders',
        'equipe': 'administration',
    }

    function handleCloseSelect() {
        if (showSelect) {
            setShowSelect(false);
        }
        if (showPopup) {
            setShowPopup(false);
        }
    }

    const filtred = user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.filter((profile: any) => profile?.assets?.refresh_status === 'failed')

    useEffect(() => {

        const newFiltred = filtred?.length > 0 ? [...filtred] : []

        if (newFiltred?.length > 0) {
            setHasRefreshTokenFailed(true);
        }

    }, [currentCompany, location.pathname]);


    useEffect(() => {
        const localUser = storageUserGet();
        const localToken = storageAuthTokenGet();
        const localCurrentUser = storageCurrentUserGet();

        const isValidLocalUser = signInUserSchemaLocalStorage.safeParse(localUser);
        const isValidLocalToken = signInTokenSchemaLocalStorage.safeParse(localToken);
        const isValidLocalCurrentUser = signInCurrentUserSchemaLocalStorage.safeParse(localCurrentUser);

        if (isValidLocalCurrentUser?.success && isValidLocalToken?.success && isValidLocalUser?.success) {
            try {
                jwtDecode(localToken.session_token);
                setUserContext(localUser);
                setTokenContext(localToken);
                setCurrentCompanyContext(localCurrentUser ?? '');
                setAuthenticated(true);
            } catch (error) {
                console.error(error);
                localStorage.removeItem('user');
                localStorage.removeItem('authToken');
                localStorage.removeItem('currentCompany');
                setAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        } else {
            setAuthenticated(false);
            setIsLoading(false);
        }
    }, []);

    function openFacebookAuthModal(){
        const body = {
            profile_id: filtred[0].profile_id,
            company_id: currentCompany,
            truncate: false,
        }
        handleRemoveFacebookProfile(body,{
            onSuccess: () => {

                const newUser = {...user,companies: {
                    ...user?.companies,
                    [currentCompany]: {
                        ...user?.companies?.[currentCompany],
                        integrations: {
                            ...user?.companies?.[currentCompany]?.integrations,
                            facebook_ads: {
                                ...user?.companies?.[currentCompany]?.integrations?.facebook_ads,
                                profiles: user?.companies?.[currentCompany]?.integrations?.facebook_ads?.profiles?.filter((profile: any) => filtred.map((profile: any) => !profile?.profile_id).includes(profile?.profile_id))
                            }
                        }
                    }
                }
            }
    
            setUserContext(newUser as userDTO);
            setShowModalFacebook(true);
            setHasRefreshTokenFailed(false);
            }
        });
        
    }

    useEffect(() => {
        setIsLoading(true)
        for(const key in keys){
            if(location.pathname.includes(key)){
                if(user?.user_id && user?.companies?.[currentCompany]?.access_control){
                    if(user?.user_id in user?.companies?.[currentCompany]?.access_control){
                        const scope_id = user?.companies?.[currentCompany]?.access_control?.[user?.user_id];
                        const scopes = user?.companies?.[currentCompany]?.access_groups?.[scope_id]?.scopes;
                        if(scopes?.includes(keys[key])){
                            setHasAccess(true);
                            setIsLoading(false)
                            break
                        }else{
                            setHasAccess(false);
                            setIsLoading(false)
                            break
                        }
                    }
                }   
            }
        }
        setIsLoading(false)
    },[location.pathname, user, currentCompany])

    if (isLoading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return authenticated ? (
        <S.Container>
            {(hasRefreshTokenFailed || (isLoadingHttp && showModalFacebook))  && <Modal onClose={() => setHasRefreshTokenFailed(false)}>
                <S.ModalContainer>
                    <S.TopModal>
                    <S.ModalIcon>
                        <ShareOffIcon />
                    </S.ModalIcon>
                    <S.GoBackModal onClick={() => setHasRefreshTokenFailed(false)}>
                        <ArrowIcon /> Voltar
                    </S.GoBackModal>
                    </S.TopModal>
                    <S.TitleModal>
                        Seu token de acesso expirou!
                    </S.TitleModal>
                    <S.SubTitleModal>
                    Seu token de acesso de alguma(s) conta(s) do Facebook Ads expirou, o que impede a sincronização e o acesso às informações atualizadas da sua integração.
                    Para garantir a continuidade do serviço e a segurança dos seus dados, é necessário renovar seu acesso.
                    </S.SubTitleModal>
                    <S.AccountsModal>
                    Conta(s) afetada(s):
                    {filtred?.length > 0 && <S.Accounts>
                        {filtred?.map((profile: any) => {
                            return (
                            <S.Account key={profile?.id}>
                                {profile?.assets?.profile_name}
                                <span>({profile?.profile_id})</span>
                            </S.Account>)
                        })}
                        </S.Accounts>}
                    </S.AccountsModal>
                    <S.HelpModal>
                    Se precisar de ajuda adicional, entre em contato com nosso <span>suporte</span>.
                    </S.HelpModal>
                    <S.LineModal />
                    <S.ButtonContainer>
                    <ButtonApp border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={openFacebookAuthModal}
                            text="Reintegrar"
                            isLoading={isLoadingHttp}
                        />
                    </S.ButtonContainer>
                </S.ModalContainer>
            </Modal>}

            {!isLoadingHttp && showModalFacebook && <Modal onClose={() => setShowModalFacebook(false)}>
                    <ModalFacebookUrl setShowPopupFacebook={setShowModalFacebook} url={urlFacebookData?.data}/>
                </Modal>}


            <Header hasPayment={hasPayment} setShowSelect={setShowSelect} showSelect={showSelect} setShowPopup={setShowPopup} showPopup={showPopup}/>
            <S.InnerContainer grayBackground={grayBackground?.grayBackground}>
                {hasPayment ? 
                <>
                    <S.Sidebar isMobile={isMobile}>
                        <Sidebar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
                    </S.Sidebar>
                        <S.Content isMobile={isMobile} hasPayment={hasPayment} onClick={handleCloseSelect}>{hasAccess ? <Outlet /> : <AccessDenied />}
                    </S.Content>
                </> : 
                <>
                    <Payment />
                </>}
            </S.InnerContainer>
        </S.Container>
    ) : (
        <Navigate to="/login" replace={true} />
    );
}

function ShareOffIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M720-720q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720Zm0 560q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600Zm0 560ZM240-40q-83 0-141.5-58.5T40-240q0-83 58.5-141.5T240-440q83 0 141.5 58.5T440-240q0 83-58.5 141.5T240-40ZM121-493q5-45 38.5-76t80.5-31q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q1 4 1.5 7.5t1.5 7.5q-27-13-57-20t-62-7q-32 0-62 7t-57 20ZM720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5l-87-50q-4-27-12.5-52T483-378l155 90q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35ZM240-212l70 71 29-28-71-71 71-71-28-28-71 71-71-71-28 28 71 71-71 71 28 28 71-71Z"/>
        </svg>
    )
}

function ArrowIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
        </svg>
    )
}