import * as S from './style';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SwitchAccount from '../../../../../../components/SwitchAccount';
import { useEffect, useState } from 'react';
import { useSyncGoogleAdsAccounts } from '../../../../../../services/hooks/integrationHooks';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Skeleton from '@mui/material/Skeleton';
import { Tooltip } from '@mui/material';

export function CardAccount({googleStatusInactive, setGoogleStatusInactive, setSwitchInfos, setSelectedAccountId, setSelectedProfileId, setShowConfirmDisconnectPopupAccount, setShowConfirmDisconnectPopupProfile, googleAdsInfo, isLoadingOutside }: any) {
    const [showMoreAccounts, setShowMoreAccounts] = useState(true);
    const {  isLoading: isLoadingInside} = useSyncGoogleAdsAccounts()
    const [isLoading, setIsLoading] = useState(false); // Estado inicial de isLoading

    // Observa as mudanças em isLoadingOutside e isLoadingInside
    useEffect(() => {
        // Atualiza isLoading para true se qualquer uma das condições for verdadeira
        //console.log('isLoadingOutside', isLoadingOutside);
        if (isLoadingOutside || isLoadingInside) {            
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [isLoadingOutside, isLoadingInside]); // Dependências do efeito

    

    return (
        <S.Container>          
            {!isLoading && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.FlexTitle>
                <S.AvatarContainer>
                    <PersonIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} />
                </S.AvatarContainer>
                    {googleAdsInfo?.profile_id}
                </S.FlexTitle>
                <S.ActionAreaProfile>
                <Tooltip title="Excluir perfil" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                    }}>
                    <DeleteForeverRoundedIcon onClick={()=> {
                        setShowConfirmDisconnectPopupProfile(true)
                        setSelectedProfileId(googleAdsInfo?.profile_id)
                    }}/>
                            
                    </Tooltip>
                    <S.SelectButton>
                        {showMoreAccounts && <KeyboardArrowUpIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(false)} />}
                        {!showMoreAccounts && <KeyboardArrowDownIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(true)} />}
                    </S.SelectButton>
                </S.ActionAreaProfile>
            </S.InnerContainer>}

            {isLoading && <S.InnerContainer showMoreAccounts={showMoreAccounts}>
                <S.AvatarContainerLoading>
                    <Skeleton variant="circular" sx={{ width: '2rem', height: '2rem', marginLeft: '-1.5rem'}} />
                </S.AvatarContainerLoading>
                <Skeleton sx={{ width: '16rem', height: '2rem', fontSize: '1rem', marginLeft: '-1rem' }}  />
                
                </S.InnerContainer>}

            {!isLoading && showMoreAccounts &&
                googleAdsInfo?.accounts?.map((account: any) => {
                    return (
                        <S.Account key={account?.account_id}>
                            <div>
                                <S.AccountTitle>ID DA CONTA</S.AccountTitle>
                                <S.AccountName>{account?.account_id}</S.AccountName>
                            </div>

                            <S.ActionArea>
                                <Tooltip title="Ativar/Desativar conta" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <div>
                                        <SwitchAccount setGoogleStatusInactive={setGoogleStatusInactive} googleStatusInactive={googleStatusInactive} account={account} profileId={googleAdsInfo?.profile_id} type='google'/>
                                    </div>
                                </Tooltip>

                                <Tooltip title="Excluir dados da conta" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '10px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'nowrap',zIndex: 999999}},
                                    }}>
                                    <DeleteForeverRoundedIcon onClick={()=> {
                                        setShowConfirmDisconnectPopupAccount(true)
                                        setSelectedAccountId(account?.account_id)
                                        setSelectedProfileId(googleAdsInfo?.profile_id)
                                        setSwitchInfos({account: account, profileId: googleAdsInfo?.profile_id})
                                    }}/>
                                </Tooltip>
                            </S.ActionArea>

                        </S.Account>
                    );
                })}
        </S.Container>
    );
}

