import { LineTableTrackingLoading } from './LineTableTrackingLoading';
import Skeleton from '@mui/material/Skeleton';
import * as S from './style';
import { useResizableColumn } from '../../../hooks/useResizableColumn';

export function TableTrackingLoading() {

    const { columnWidths } = useResizableColumn();

    return (
        <S.Container>
            <S.TableHeader>
                <S.MainColumn style={{ width: `${columnWidths}px` }}>
                <div
                    style={{
                        backgroundColor: '#ccc',
                        width: '3px',
                        height: '100%',
                        position: 'absolute',
                        right: 0,
                        top: 0
                    }}
                    />
                    Nome da plataforma</S.MainColumn>
                <S.DefaultColumnSolomon>Gasto
                <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                {/* <S.DefaultColumnSolomon>Lucro
                <span>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                        Aprovado
                    </span>
                </S.DefaultColumnSolomon> */}
                <S.DefaultColumnSolomon>Vendas

                <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>
                    Vendas
                    <span>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                        Totais
                    </span>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>Receita
                <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>
                    Receita
                    <span>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                        Total
                    </span>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>ROAS
                     <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>
                    ROAS
                    <span>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                        Total
                    </span>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>CPA
                     <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>
                    CPA
                    <span>
                        <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                        Total
                    </span>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>CPC
                     <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
                <S.DefaultColumnSolomon>CPM
                     <div style={{ display: 'flex', gap: '0.2rem'}}>   
                    <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />
                    <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 
                </div>
                </S.DefaultColumnSolomon>
            </S.TableHeader>

            <S.Lines>
                <LineTableTrackingLoading columnWidths={columnWidths} type="facebook" />
                <LineTableTrackingLoading columnWidths={columnWidths} type="google" />
                <LineTableTrackingLoading columnWidths={columnWidths} type="tiktok" />
                {/* <LineTableTrackingLoading columnWidths={columnWidths} type="outros" /> */}
                {/* <LineTableTrackingLoading type="sem_atribuicao" /> */}
            </S.Lines>

            <S.TotalRow>
                <S.TotalMainColumn style={{ width: `${columnWidths}px` }}>Total</S.TotalMainColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
                <S.TotalDefaultColumn>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.TotalDefaultColumn>
            </S.TotalRow>
        </S.Container>
    );
}
