import styled, { css } from 'styled-components';

export const Container = styled.div`
    padding: 1rem;
    
`;

export const Logo = styled.div`
    img {
        width: 3rem;
        height: 3rem;
    }
`;

type TitleProps = {
    baseColor: string;
};

export const Title = styled.div<TitleProps>`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--black);
    margin-top: 2rem;
    span {
        color: ${(props) => props.baseColor};
    }
`;

export const Subtitle = styled.div`
    margin-top: 0.4rem;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.125rem;
    color: var(--gray-700);
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    margin-top: 1.5rem;
    background-color: #ccc;
`;

export const SelectTitle = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    color: #1e1e1e;
    margin-top: 1.5rem;
`;

export const SelectType = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SelectType2 = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5rem;
`;

export const SelectOption = styled.div<{selected: boolean}>`
    margin-top: 0.5rem;
    height: 3.5rem;
    width: 97%;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    border-radius: 8px;
    background-color: ${props => props.selected ? '#e7e7e7' : '#f2f2f2'};
    border: ${props => props.selected ? '1px solid var(--green)' : '1px solid #f2f2f2'};
    color: var(--black);
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.25s;

    &:hover {
        margin-left: 0.7rem;
        background-color: #e7e7e7;
        transition: all ease 0.25s;
    }
`;

export const CheckCircle = styled.div<{ selected: boolean }>`
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 1px solid lightgray;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.selected ? 'var(--white)' : 'var(--white)')};
    
    ${props =>
        props.selected &&
        css`
            &:after {
                content: '';
                display: block;
                height: 0.5rem;
                width: 0.5rem;
                border-radius: 50%;
                background-color: var(--green);
            }
        `}
`;

export const SelectText = styled.span`
    flex: 1;
`;

export const InputValue = styled.div`
    position: relative;
    margin-bottom: -0rem;
    margin-right: 35rem;

    display: flex; /* Add flex display to align items horizontally */
    align-items: center; /* Center align items vertically */

    input {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 4rem;
        margin-bottom: 0.5rem;
        padding-left: 3.5rem;
        padding-right: 2rem;
        border-radius: 8px;
        border: 1px solid var(--green);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: 1px solid var(--green);
        }
    }
    p {
        padding-left: 1rem;
        padding-right: 1rem;
        color: gray;
    }
`;

export const InputIcon = styled.div`
position: absolute;
top: 0.9rem;
left: 0.5rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.875rem;
color: var(--green);
width: max-content;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
`;

export const InputValue2 = styled.div`
    position: relative;
    margin-bottom: 1.5rem;

    display: flex; /* Add flex display to align items horizontally */
    align-items: center; /* Center align items vertically */

    input {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 4rem;
        margin-bottom: 0.5rem;
        padding-left: 3.5rem;
        padding-right: 2rem;
        border-radius: 8px;
        border: 1px solid var(--green);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: 1px solid var(--green);
        }
    }
    p {
        padding-left: 1rem;
        padding-right: 1rem;
        color: gray;
    }
`;

export const InputIcon2 = styled.div`
position: absolute;
top: 0.9rem;
left: 16rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.875rem;
color: var(--green);
width: max-content;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
`;

export const InputValue3 = styled.div`
    position: relative;
    margin-bottom: 1.5rem;

    display: flex; /* Add flex display to align items horizontally */
    align-items: center; /* Center align items vertically */

    input {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 4rem;
        margin-bottom: 0.5rem;
        padding-left: 3.5rem;
        padding-right: 2rem;
        border-radius: 8px;
        border: 1px solid var(--green);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: 1px solid var(--green);
        }
    }
    p {
        padding-left: 1rem;
        padding-right: 1rem;
        color: gray;
    }
`;

export const InputIcon3 = styled.div`
position: absolute;
top: 0.9rem;
left: 6rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.875rem;
color: var(--green);
width: max-content;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
`;

export const FinalContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 1rem;
margin-top: 1rem;
`;

export const FinalText = styled.div`
font-size: 0.75rem;
font-weight: 600;
line-height: 1.125rem;
color: #656565;
`;

export const FinalButton = styled.div`
`;

export const InfoContainer = styled.div`
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-left: 0.8rem;
    width: fit-content;
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        }
`;


export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;

export const ButtonArea = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
`