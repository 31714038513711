import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
    margin-top: -20px;
    width: 90%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1000;
`;

export const Button = styled.button`
    margin: 5px auto; /* Center horizontally */
    padding: 10px 20px;
    background-color: #2c2c2c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block; /* Make it a block element to apply margin auto */
    &:hover {
        background-color: #4b4b52;
    }
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export const TextArea = styled.textarea`
    width: 95%;
    height: 40px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: vertical;
`;

export const ChoiceContainer = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid ${({ selected }) => (selected ? '#2c2c2c' : '#ccc')};
    border-radius: 5px;
    background-color: ${({ selected }) => (selected ? '#e0e2e8' : '#fff')};
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const RatingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
`;

export const RatingChoice = styled.div<{ selected: boolean }>`
    padding: 10px;
    border: 1px solid ${({ selected }) => (selected ? '#2c2c2c' : '#ccc')};
    border-radius: 5px;
    background-color: ${({ selected }) => (selected ? '#e0e2e8' : '#fff')};
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`;
