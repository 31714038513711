import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const InnerContainer = styled.div<{showMoreAccounts: boolean}>`
    margin-top: 1rem;
    // width: 33rem;
    justify-content: space-between;
    width: 100%;
    display: flex;
    height: 4rem;
    border-radius: ${props => props.showMoreAccounts ? '10px 10px 0px 0px' : '10px'};
    border: 1px solid var(--gray-120);
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--black);
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    padding-left: 1rem;
    position: relative;
    gap: 1rem;
    
    span {
        font-size: 0.6875rem;
        font-weight: 500;
        color: #989c97;
        margin-left: -0.7rem;
        margin-top: 0.2rem;
    }

    p {
        width: fit-content;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 15.5rem;
        text-wrap: nowrap;
    }
`;

export const AvatarContainerLoading = styled.div`
padding-left: 1.5rem;
margin-right: 1rem;
`

export const AvatarContainer = styled.div`
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: var(--gray-175);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SelectButton = styled.div`
    // position: absolute;
    // top: calc(2rem - 12px);
    // right: 1rem;
    cursor: pointer;
    svg {
        &:hover {
            color: var(--green800) !important;
        }
    }
`;

export const Account = styled.div`
    background-color: var(--gray-115);
    width: 100%;
    height: 3.5rem;
    border: 1px solid var(--gray-120);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
`;

export const AccountName = styled.div`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    display: flex;
    color: var(--black);
    margin-left: 4rem;
    span {
        font-size: 0.6875rem;
        font-weight: 500;
        color: #989c97;
    }

    p {
        width: fit-content;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 13rem;}
`;

export const AccountTitle = styled.div`
    font-size: 0.6875rem;
    margin-left: 4rem;
    font-weight: 500;
    color: var(--gray-700);
    margin-top: 0.5rem;
`;

export const Switch = styled.div``;

const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(-1);
    }
    to {
        transform: rotate(360deg) scaleX(-1);
    }
`;

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;

export const SyncContainer = styled.button`
background-color: #ECECEC;
border-radius: 50px;
display: flex;
align-items: center;
gap: 0.1rem;
justify-content: center;
transition: 0.3s;
cursor: pointer;
&:hover{
    background-color: #212529;
    transform: translateY(2px);
    color: #fff;
    svg{
        transition: 0.3s;
        fill: #fff;
    }
}
width: 11.9rem;
height: 1.875rem;
&:disabled{
    svg{
        ${animationStyles}
        fill: var(--gray-120);
    }
    
}
`

export const SyncIcon = styled.div`
svg{
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: -0.2rem;
}
`

export const SyncText = styled.div`
font-size: 0.875rem;
font-weight: 500;
line-height: 1.3125rem;
`

export const ActionArea = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    

    svg {

        color: var(--gray-700);
        cursor: pointer;
        width: 1.7rem;
        height: 1.7rem;
        transition: 0.2s;

        &:hover {
            color:#F41B19;
            transition: 0.2s;
        }

    }
`

export const ActionAreaProfile = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;   
    margin-right: 1rem; 


    svg {

        color: var(--gray-700);
        cursor: pointer;
        width: 1.7rem;
        height: 1.7rem;
        transition: 0.2s;

        &:hover {
            color:#F41B19;
            transition: 0.2s;
        }

    }
`

export const FlexTitle = styled.div`
    display: flex;
    gap: 1.1rem;
    align-items: center;

`