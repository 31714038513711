import styled from 'styled-components';
import { keyframes, css } from 'styled-components';

type ContainerGoogleGatewayProps = {
    showPopupGoogleGateway: boolean
}

export const Container = styled.div<ContainerGoogleGatewayProps>`
    ${props => !props.showPopupGoogleGateway ? `
    padding: 1rem 1rem 0; `:
    `padding: 1rem;`}

     ::-webkit-scrollbar {
        width: 8px !important; /* Altura da barra de rolagem */
    }
`;

export const InnerContainer = styled.div`
    max-height: 50vh;
    height: auto;
    margin-top: 4rem;
    padding: 0 1rem 0;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
`;

export const ButtonContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const ContainerGoogleGateway = styled.div<ContainerGoogleGatewayProps>`
    display: none;
    bottom: 100%;
    

    ${(props) =>
        props.showPopupGoogleGateway
            ? `
    margin: 1rem auto 0;
    display: flex;
    opacity: 1;
    height: 5.75rem;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;
    
    `
            : `
    height: 0;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.5s;

    `}
    
    width: 17.5rem;
    padding: 0;
    background-color: #0e1819;
    color: #0e1819;
    text-align: center;
    border-radius: 11px;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: #0e1819 transparent transparent transparent;
        box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    }
`;

export const GoogleGatewayTitle = styled.div`
    font-size: 0.7125rem;
    color: var(--white);
    margin: 0 auto;
    font-weight: 550;
    margin-top: 0.3rem;
`;


export const SyncArea = styled.div`
    // margin-left: -0.5rem;
    position: absolute;
    display: flex;
    width: 34rem;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    margin-top: -2.8rem;
`

export const GoogleGatewaySubtitle = styled.div`
    font-size: 0.6rem;
    font-weight: 550;
    color: #989c97;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;

export const ButtonGoogleGateway = styled.button`
    padding: 0;
    font-size: 0.7rem;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin-top: 0.75rem;
    color: var(--green);
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    margin: 0 auto;
    &:hover {
        background-color: none;
        box-shadow: none;
        color: #19c27e;
        transform: translateX(2px);
    }
`;


const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(-1);
    }
    to {
        transform: rotate(360deg) scaleX(-1);
    }
`;

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;
export const SyncContainer = styled.button`
background-color: #ECECEC;
border-radius: 50px;
display: flex;
align-items: center;
gap: 0.1rem;
justify-content: center;
transition: 0.3s;
cursor: pointer;
&:hover{
    background-color: #212529;
    transform: translateY(2px);
    color: #fff;
    svg{
        transition: 0.3s;
        fill: #fff;
    }
}
width: 11.9rem;
height: 1.875rem;
&:disabled{
    svg{
        ${animationStyles}
    }
    
}
`

export const SyncIcon = styled.div`
svg{
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: -0.2rem;
}
`

export const SyncText = styled.div`
font-size: 0.875rem;
font-weight: 500;
line-height: 1.3125rem;


`

export const PlaceholderArea = styled.div`
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 2rem 0;
`