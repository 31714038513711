import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useDashboardData, useTaxesRate, useGetShipping } from '../../services/hooks/dashHooks';
import { useOrders } from '../../services/hooks/ordersHooks'; 
import { CardsDefault } from './Graphics/CardsDefault';
import { Profit } from './Graphics/Profit';
import * as S from './style';
import { format, subDays } from 'date-fns';
import { Meta } from './Meta';
import { storageCurrentUserMetaGet } from '../../storage/storageCurrentUser';
import { getMetrics, calculateDateToSeeMore } from '../Dashboard/utils';
import Calendar from '../../components/Calendar';
import { useIntercom } from 'react-use-intercom';
import { useProductsAnalyticsList } from '../../services/hooks/productsHooks';

import { calculateValueOccurrences } from './utils';

export function DashboardMobile() {
    const { currentCompany, user} = useAuth();

    const shopifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length || 0;
    const kiwifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length || 0;

    const { boot } = useIntercom();

    useEffect(() => {
        boot({
            name: user.name,
            userId: user.user_id,
            email: user.credentials?.email,
            phone: user.phone,
            createdAt: user.created_at
         });
      }, [boot]);
    
    // pega a data do datepicker 
    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);

    //pega a data do datepicker (a mesma data do de cima) e analisa o calculateDateToSeeMore
    const [dateToSeeMore, setDateToSeeMore] = useState(calculateDateToSeeMore(date[0], date[1]));
    useEffect(() => {
        setDateToSeeMore(calculateDateToSeeMore(date[0], date[1]));
        setCostOperationDate((prevState: any) => {
            return {
                ...prevState!,
                startDate: date[0],
                endDate: date[1],
            };
        });
    }, [date]);

    const { data: dataProductList, isLoading: isLoadingProductList, refetch: refetchProductList } = useProductsAnalyticsList(currentCompany);
    const [productIds, setProductIds] = useState([]);

    useEffect(() => {
        if (!isLoadingProductList && dataProductList && dataProductList.data && dataProductList.data.data) {
            const productsData = dataProductList.data.data.map(item => ({
                product_id: item.product_id
            }));
            const ids = productsData.map(item => item.product_id);
            setProductIds(ids);

            if (shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1){
                setProductIds([]);
            }
        }
    }, [isLoadingProductList, dataProductList]);

    const { data: dataDashboard, isLoading: isLoadingDashboard } = useDashboardData(date[0], date[1], currentCompany, productIds);

    const {data: dataGraphics, isLoading: isLoadingGraphics} = useDashboardData( format(subDays(new Date(date[1]), 5),'yyyy-MM-dd'), date[1], currentCompany, productIds);
    const { data: dataTaxesRate, isLoading: isLoadingTaxesRate } = useTaxesRate(currentCompany);
    // //console.log("taxes rate", dataTaxesRate.data.data);
    const { data: dataShippingCost, isLoading: isLoadingShippingCost, refetch: refetchShippingCostData } = useGetShipping(currentCompany);
    const { isLoading: ordersLoading, data: ordersData, refetch: refetchOrders } = useOrders(date[0], date[1], currentCompany);
    const { isLoading: ordersLoadingGraphics, data: ordersGraphics, refetch: refetchOrdersGraphics } = useOrders(format(subDays(new Date(date[1]), 5),'yyyy-MM-dd'), date[1], currentCompany);

    const {data: dataSeeMore} = useDashboardData(dateToSeeMore[0], dateToSeeMore[1], currentCompany, productIds);

    const meta = storageCurrentUserMetaGet(currentCompany);
    const { data: dataDashboardMeta, isLoading: isLoadingDashboardMeta } = useDashboardData(meta.start = date[0], meta.end = date[1], currentCompany, productIds);

    const { profitLiquidChart } = getMetrics(dataDashboardMeta?.data, dataTaxesRate?.data?.data);
    const profit = profitLiquidChart?.reduce((acumulador, elemento) => acumulador + elemento, 0);

    const approvedRevenueChart = dataDashboardMeta?.data?.approved_revenue_chart.approved['y_axis'];
    const approvedRevenue = approvedRevenueChart?.reduce((acumulador: any, elemento: any) => acumulador + elemento, 0);

    const isLoading = isLoadingDashboard || isLoadingTaxesRate || isLoadingDashboardMeta || isLoadingGraphics || Object.keys(user?.companies).includes(currentCompany) === false;

    const [costOperationDate, setCostOperationDate] = useState({
        value: '',
        frequency: 'monthly',
        date: '',
        startDate: date[0],
        endDate: date[1],
    });
    
    let costMarketingValue = calculateValueOccurrences(costOperationDate?.startDate, costOperationDate?.endDate, costOperationDate?.frequency, costOperationDate?.date, costOperationDate?.value);

    return (
        <S.Container>
            <S.InnerContent>
                <S.Section>
                    <S.MetaSection>
                        <Meta isLoading={isLoading} profit={profit} approvedRevenue={approvedRevenue} />
                    </S.MetaSection>
                    <S.CalendarContainer>
                        <span></span>
                        <Calendar date={date} setDate={setDate} dashboard={true}/>
                    </S.CalendarContainer>
                </S.Section>
                <S.ContentSection>
                    <Profit date={date} isLoading={isLoading} data={dataDashboard?.data} dataGraphic={dataGraphics?.data} taxes={dataTaxesRate?.data?.data} shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} ordersGraphics={ordersGraphics?.data?.data}   />
                </S.ContentSection>
                <S.ContentSection>
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data}  shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="approvedRevenue" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data}  shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} setCostOperationDate={setCostOperationDate} costMarketingValue={costMarketingValue} type="marketingCost" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data}  shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="productsCost" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data}  shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="taxChart" />
                    <CardsDefault isLoading={isLoading} data={dataDashboard?.data} taxes={dataTaxesRate?.data?.data} dataSeeMore={dataSeeMore?.data}  shipping={dataShippingCost?.data?.data} orders={ordersData?.data?.data} type="operationCost" />
                </S.ContentSection>
            </S.InnerContent>
        </S.Container>
    );
}
