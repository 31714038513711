import styled from 'styled-components';

type ContainerProps = {
    showLogin: boolean;
};

type MobileProps = {
    isMobile: boolean;
};

export const Container = styled.div<ContainerProps>`
    ${(props) => (props.showLogin ? 
        `
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        height: 500px;
        padding-top: calc(50vh - 250px);
        `
        : 
        'display: none;'
    )}
    
    form {
        display: flex;
        flex-direction: column;
        max-width: 1050px;
    }

    form > * {
        margin-bottom: 1rem;
    }

    form > *:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 1050px) {
        align-items: center;
        width: 90%;
    }
`;

export const SectionError = styled.div`
    text-align: center;
    margin-top: 1rem;
    margin-bottom: -1rem;
    font-size: 0.75rem;
    color: #ff0000;
    font-weight: 500;
    line-height: 0.825rem;
`;

export const Logo = styled.div`
img {
    width: 5rem;
    margin-bottom: 0.5rem;
}
    margin: 0 auto;
`;

export const Title = styled.div`
    font-size: 1.75rem;
    line-height: 1.925rem;
    font-weight: 550;
    color: #1e1e1e;
    margin-bottom: 0.5rem;
    max-width: 100%;

    @media (max-width: 1050px) {
        font-size: 1.5rem; 
    }
`;

export const Subtitle = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #5d7375;
    max-width: 100%;
`;

export const SubtitleSection = styled.div`
    padding-bottom: 2.5rem;
    max-width: 100%;

    @media (max-width: 1050px) {
        padding-bottom: 1.5rem; // Less padding on smaller screens
    }
`;

export const ForgotPassword = styled.div`
    width: fit-content;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #5c7274;
    display: flex;
    justify-content: flex-end;
    margin-left: 12rem;
    cursor: pointer;
    &:hover {
        color: var(--green);
    }
`;

export const ButtonSection = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
`;

export const Button = styled.button`
    width: 100%; // Use the full width of the parent
    min-width: 20rem; // But don't exceed 20rem
    height: 2.8275rem;
    background-color: var(--green);
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    line-height: 1.428125rem;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-weight: 450;
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
`;

export const ButtonEmail = styled.button`
    width: 70%; // Use the full width of the parent
    min-width: 14rem; // But don't exceed 20rem
    height: 2.4rem;
    background-color: var(--green);
    border-radius: 50px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.428125rem;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
`;

export const FinalText = styled.div<MobileProps>`
    display: ${props => props.isMobile ? 'none' : ''};
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #5d7375;
    font-weight: 500;
    text-align: center;
    margin-top: 2rem;
`;

export const SignUpNow = styled.div<MobileProps>`
    display: ${props => props.isMobile ? 'none' : ''};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: var(--green);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const ModalContent = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        svg {
            color: #19c27e;
            fill: #19c27e;
            height: 3rem;
            width: 3rem;
            margin-bottom: 21px;
        }

        h2 {
            font-size: 1.5rem;
            color: #1e1e1e;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 0.875rem;
            color: #5d7375;
            font-weight: 500;
            text-align: center;
            margin-bottom: 1rem;
        }
    `

    export const InputSection = styled.div`
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 1rem;
        `;
    