// GraphModal.tsx
import React from 'react';
import Modal from '../../../components/Modal/index.tsx'
import * as CS from '../../CostOperation/style.ts'; 
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { createCostSchema } from '../../../schemas/costSchema';
import { useCreateCost } from '../../../services/hooks/costHooks';
import {ButtonApp} from '../../.././components/ButtonApp';
import { uuidv4 } from '../../.././utils';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import useAlert from '../../../hooks/useAlert.tsx';
import { trackEvent } from '../../../services/segment/sendEvent.ts';
import { LineCostOperationProps } from '../../../@types/types-app.ts';
import { useCurrencyType } from '../../../hooks/useCurrencyType';

interface OperationCostsModalProps {
    onClose: () => void;
    setCostOperationDate?: (value: any) => void;
    currentCostArray: LineCostOperationProps[]; // A variável de estado para o array atual
    historicalCostArray: LineCostOperationProps[]; // A variável de estado para o array histórico
    setCurrentCostArray: (costArray: LineCostOperationProps[]) => void; // Setter para atualizar o array atual
    setHistoricalCostArray: (costArray: LineCostOperationProps[]) => void; // Setter para atualizar o array histórico
}


const OperationCostModal : React.FC<OperationCostsModalProps> = ({ onClose, setCostOperationDate, setCurrentCostArray, setHistoricalCostArray, currentCostArray, historicalCostArray }) => {
    const { currentCompany, user } = useAuth();
    const { mutate: createCost, isLoading } = useCreateCost();
    const { showAlert } = useAlert();
    const { currencySymbol } = useCurrencyType();

    const [costOperation, setCostOperation] = useState({
        name: '',
        type: 'aluguel',
        value: '',
        frequency: 'monthly',
        date: '',
    });
    const frequencyInitialTranslation: { [key: string]: string } = {
        monthly: 'Mensal',
        weekly: 'Semanal',
        daily: 'Diária',
        specific: 'Pontual',
    };

    // 

    function formatToNumber(value: string) {
        value = value.replace(`${currencySymbol}`, '');
        value = value.replace('.', '');
        value = value.replace(',', '.');
        return parseFloat(value);
    }

    function formatValue(value: string) {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        newValue = `${currencySymbol}` + newValue;
        setCostOperation({ ...costOperation, value: newValue });
    }

    function handleCreateCost() {
        //console.log("handleCreateCost");
        if (!isLoading) {
            //console.log("handleCreateCost - !isLoading");
            const body = {
                name: costOperation?.name,
                value: formatToNumber(costOperation?.value),
                type: costOperation?.type,
                frequency: costOperation?.frequency,
                date: costOperation?.date,
                company_id: currentCompany,
                status: 'active',
                additional_cost_id: uuidv4().substring(0, 10),
            };
            //console.log("handleCreateCost - body1", body);

            const isValidInformation = createCostSchema.safeParse(body);
            //console.log("handleCreateCost - parse");
            if (!isValidInformation?.success) {
                return;
            }
            //console.log("handleCreateCost - isValidInformation2");

            const { name, value, type, frequency, date, additional_cost_id:cost_id, status } = body;
            //console.log("handleCreateCost - body2", body);
            setCurrentCostArray([{ name, value, type, frequency: frequencyInitialTranslation[frequency], date, cost_id, status  }, ...currentCostArray]);
            //console.log("handleCreateCost - currentCostArray", currentCostArray);
            setHistoricalCostArray([{ name, value, type, frequency: frequencyInitialTranslation[frequency], date, cost_id, status }, ...historicalCostArray]);
            //console.log("handleCreateCost - historicalCostArray", historicalCostArray);
            showAlert('Custo adicionado com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 5000, true);
''
            createCost(body, {
                onSuccess: () => {
                    setCostOperation({
                        name: '',
                        type: 'aluguel',
                        value: '',
                        frequency: 'monthly',
                        date: '',
                    });         
                },
                onError: (error) => { // Você também pode definir um tratamento de erro aqui
                    showAlert('Não foi possível adicionar seu custo, tente novamente!', 'error', 5000, true);
                    //console.log("erro ao criar custo", error);
                },
            });
            
            trackEvent('add-cost', {
                userId: user.user_id,
                timestamp: new Date().toISOString(),
                company_id: currentCompany,
                company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
                date: date,
                type: type,
                value: value,
                frequency: frequency
            }).then((ctx) => {
                //console.log("success")
            }).catch((err) => {
                //console.log(err)
            })
        }
    }


    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage
                    src="https://storage.googleapis.com/static-images-source/management-cost.png"
                    alt="Shopify"
                />
                <S.MarketingTitle>
                    Adicionar um <S.MarketingHighlight>Custo operacional</S.MarketingHighlight> 
                </S.MarketingTitle>
                <br />
                <S.MarketingLine/>

                <CS.InputContainer>
                    <label>Nome</label>
                    <input type="text" value={costOperation.name} onChange={(e) => setCostOperation({ ...costOperation, name: e.target.value })} placeholder="Aluguel do espaço" />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Tipo de Custo</label>
                    <select value={costOperation.type} onChange={(e) => setCostOperation({ ...costOperation, type: e.target.value })}>
                            <option value="aluguel">Aluguel</option>
                            <option value="marketing">Marketing</option>
                            <option value="plataformas">Software e Aplicativos</option>
                            <option value="contabilidade">Serviços de Contabilidade</option>
                            <option value="funcionario">Salários de Funcionários</option>
                            <option value="retirada">Distribuição de Lucros para Sócios</option>
                            <option value="trafego">Despesas com Tráfego pago</option>
                            <option value="saque">Operações de Saque Financeiro</option>
                            <option value="alimentacao">Despesas com Alimentação</option>
                            <option value="outros">Outros</option>
                        </select>
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Valor</label>
                    <input value={costOperation.value} placeholder={`${currencySymbol} 10,00`} type="text" onChange={(e) => formatValue(e.target.value)} />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Frequência</label>
                    <select value={costOperation.frequency} onChange={(e) => setCostOperation({ ...costOperation, frequency: e.target.value })}>
                        <option value="monthly">Mensal</option>
                        <option value="weekly">Semanal</option>
                        <option value="daily">Diário</option>
                        <option value="specific">Pontual</option>
                    </select>
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Data</label>
                    <input type="date" value={costOperation.date} onChange={(e) => setCostOperation({ ...costOperation, date: e.target.value })} />
                </CS.InputContainer>
                <CS.ButtonContainer style={{marginTop: 24}}>
                <ButtonApp 
                border=''
                width="10rem"
                height="3rem"
                color="#fff"
                background="#19c380"
                icon={<AddCircleRoundedIcon style={{ fontSize: 20, marginRight: 10 }}/> }
                text="Adicionar custo"
                isLoading={isLoading}
                onClick={handleCreateCost}/>
                </CS.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default OperationCostModal;
