
export function getLineProducts (data: any) {

    // //console.log('data', data)
    
    const metricsByAds = [];

    for (const product in data) {
        const product_id = data?.[product]?.product_id;
        const totalTax = Number((Number(data?.[product]?.checkout_fee) + Number(data?.[product]?.gateway_fee)).toFixed(2));        
        const gatewayFee = Number(data?.[product]?.gateway_fee);
        const checkoutFee = Number(data?.[product]?.checkout_fee);
        const totalNumberSales = Number(data?.[product]?.number_sales) + Number(data?.[product]?.pending_number_sales) + Number(data?.[product]?.cancelled_number_sales);

        const revenue = Number(data?.[product]?.revenue) + Number(data?.[product]?.approved_shipping_fee);
        
        const number_sales = data?.[product]?.number_sales;
        const productCost = data?.[product]?.cost;
        const pending_revenue = Number(data?.[product]?.pending_revenue) + Number(data?.[product]?.pending_shipping_fee);
        const pending_number_sales = data?.[product]?.pending_number_sales;
        const cancelled_revenue = Number(data?.[product]?.cancelled_revenue) + Number(data?.[product]?.cancelled_shipping_fee);
        const cancelled_number_sales = data?.[product]?.cancelled_number_sales;
        const mkt_additional_cost = data?.[product]?.mkt_additional_cost;
        const realProfit = revenue - totalTax - mkt_additional_cost - productCost;
        const MarginProfit = realProfit/revenue *100;
        const cost = data?.[product]?.cost;
        const product_image = data?.[product]?.product_image;
        const product_name = data?.[product]?.product_name;
        const totalRevenue = revenue + pending_revenue + cancelled_revenue;

        if (realProfit < 0){
            const formattedMarginProfit = Math.round(0) + '%';
            const color = false;
            metricsByAds.push({
                realProfit,
                formattedMarginProfit,
                totalRevenue,
                revenue,
                number_sales,
                pending_revenue,
                pending_number_sales,
                cancelled_revenue,
                cancelled_number_sales,
                mkt_additional_cost,
                cost,
                totalTax,
                gatewayFee,
                checkoutFee,
                totalNumberSales,
                product_image,
                product_name,
                color,
                product_id,
            });
        } else {
            const formattedMarginProfit = Math.round(MarginProfit) + '%';
            const color = true;
            metricsByAds.push({
                realProfit,
                formattedMarginProfit,
                totalRevenue,
                revenue,
                number_sales,
                pending_revenue,
                pending_number_sales,
                cancelled_revenue,
                cancelled_number_sales,
                mkt_additional_cost,
                cost,
                totalTax,
                gatewayFee,
                checkoutFee,
                totalNumberSales,
                product_image,
                product_name,
                color,
                product_id,
            });
        }

    }
    return metricsByAds;
}

export function createMiddayDate(dateString: any) {
    var parts = dateString.split("-");
    // Atenção: o construtor Date no JavaScript usa 0-index para os meses, então subtraia 1 do mês
    var date = new Date(
        Date.UTC(parts[0], parts[1] - 1, parts[2], 12, 0, 0)
    );
    return date;
}
  
export function calculateValueOccurrences( startDate: any, endDate: any, frequency: any, costStartDate: any, value: any) {
        var occurrences = 0;
        var day = 1000 * 60 * 60 * 24; // milliseconds in a day
        startDate = createMiddayDate(startDate);
        endDate = createMiddayDate(endDate);
        costStartDate = createMiddayDate(costStartDate);

        switch (frequency) {
            case "daily":
                if (costStartDate < startDate) {
                    occurrences = Math.ceil((endDate - startDate) / day) + 1;
                } else if (
                    costStartDate >= startDate &&
                    costStartDate <= endDate
                ) {
                    occurrences =
                        Math.ceil((endDate - costStartDate) / day) + 1;
                } else {
                    occurrences = 0;
                }
                

                break;
            case "weekly":
                // One week after cost start date
                var nextDate = costStartDate;

                while (nextDate <= endDate) {
                    if (nextDate >= startDate) {
                        occurrences++;
                    }
                    // Add a week to the next date
                    nextDate = new Date(nextDate.getTime() + 7 * day);
                }
                break;
            case "monthly":
                // One month after cost start date
                nextDate = costStartDate;
                // ////console.log(startDate);
                // ////console.log(endDate);
                while (nextDate <= endDate) {
                    if (nextDate >= startDate) {
                        occurrences++;
                    }
                    // Add a month to the next date
                    nextDate = new Date(
                        nextDate.setMonth(nextDate.getMonth() + 1)
                    );
                }
                break;
            case "specific":
            if (costStartDate >= startDate && costStartDate <= endDate) {
                occurrences = 1;
            } else {
                occurrences = 0;
            }
            break;
        }

    return occurrences*value;
}