import styled, {keyframes} from 'styled-components';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CircularProgress from '@mui/material/CircularProgress';

// Definição da animação
export const moveGradient = keyframes`
0% { stroke-dashoffset: 0; }
100% { stroke-dashoffset: 100; }
  }
`;

// Componente personalizado com animação de degradê
export const AnimatedProgress = styled(CircularProgress)`
    color: #19c27e !important;  // Definindo um degradê
  `

export const Container = styled.div`
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    border-radius: 10px;
`;

export const LoadIntegrationContainer = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
    }
`

export const CustomCheckCircleOutlineRoundedIcon = styled(CheckCircleOutlineRoundedIcon)`
  color: #19c27e; 
  width: 0.7em !important; 
`;

