import styled from 'styled-components';

type ContainerProps = {
    isError?: boolean;
};


export const Container = styled.div<ContainerProps>`
    padding: 1rem;
    width: 36rem;
    ${props => props.isError && `min-height: 32rem;`}

`;

export const InnerContainer = styled.div`
    max-height: 20rem;
    margin-top: 1rem;
    padding: 0 1rem 0;
    height: auto;
`;

export const Line = styled.div`
    width: 99%;
    height: 1px;
    background-color: #989c97;
    margin: 0 auto;
`;

export const TitleInnerContainer = styled.div`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    color: var(--black);
`;

export const SectionInput = styled.div`
    position: relative;
    margin-bottom: 1rem;
    input {
        width: 29.7625rem;
        padding: 0.1rem 2.5rem 0 1rem;
        height: 2.39375rem;
        border-radius: 7px;
        border: 1px solid #ccc;
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 1.21875rem;
    }

    svg {
        position: absolute;
        right: 1.5rem;
        top: 0.9rem;
        cursor: pointer;
    }
`;

export const BulletPoints = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #252129;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    svg {
        margin-right: 0.8rem;
    }
`;

export const ErrorContainer = styled.div`
    width: 33.375rem;
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
   margin-top: 0.5rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0.5rem auto 0;
    span {
        font-weight: 550;
    }
`;


export const InformationMessage = styled.div`
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.21875rem;
    color: #707273;
`;

export const FinalLine = styled.div`
    height: 1px;
    background-color: #e9ecef;
    width: 99%;
    margin: 0 auto;
    margin-top: 1rem;
`;

export const FinalSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

export const FinalText = styled.div`
    font-size: 0.8125rem;
    font-weight: 525;
    line-height: 1.21875rem;
    color: #707273;
`;

export const FinalButton = styled.div``;
