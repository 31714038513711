import { LineTableOrdersProps } from '../../../../@types';
import { useEffect } from 'react';
import * as S from './style';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Create from '@mui/icons-material/Create';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { format } from 'date-fns';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Modal from '../../../../components/Modal';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';

const getLinkFromSource = (source: string): string => {
    const uppers = source.toUpperCase();
    switch (uppers) {
        case 'FACEBOOK':
            return 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
        
        case 'GOOGLE':
            return 'https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png';
        
        case 'TIKTOK':
            return 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';

        default:
            return 'https://storage.googleapis.com/static-images-source/other-source-tracking.png';
    }
};

const getMsgFromSource = (source: string): string => {
    const uppers = source.toUpperCase();
    switch (uppers) {
        case 'FACEBOOK':
            return 'Facebook';

        case 'GOOGLE':
            return 'Google';

        case 'TIKTOK':
            return 'TikTok';

        case 'ORGANIC_AND_SOCIAL':
            return 'Orgânico / Social';

        default:
            const maxLength = 17; 
            return source.slice(0, maxLength);
    }
};

export function LineTableOrders({ order, canSave, setCanSave, changeOrders }: LineTableOrdersProps) {
    const urlLinkBoleto = 'https://storage.googleapis.com/static-images-source/billet-icon.png';
    const urlLinkCartao = 'https://storage.googleapis.com/static-images-source/mastercard-icon.png';
    const urlLinkPix = 'https://storage.googleapis.com/static-images-source/pix-icon.png';
    const { currencySymbol } = useCurrencyType();

    let url = '';
    let name = '';

    function formatDate(date: Date) {
        return format(date, 'dd/MM/yyyy, HH:mm:ss');
    }

    function formatSet (valueInput: string) {
        let newValue = valueInput?.replace('.', ',');
        return newValue;
    }

    function formatInitial(valueInput: string) {
        let newValue = valueInput.replace(/\D/g, ''); 
        newValue = newValue.replace(/^0+(?=\d)/, ''); 
        if (newValue.length === 0) newValue = '000'; 
        newValue = newValue.padStart(3, '0'); 
        newValue = newValue.replace(/(\d{1,})(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return newValue;
    }

    function formatValue(valueInput: string) {
        let newValue = formatInitial(valueInput);
        // setValue(newValue, variantId, type);
        setNewProductPrice(newValue);
        return newValue;
    }    

    function formatValueCost(valueInput: string) {
        let newValue = formatInitial(valueInput);
        // setValue(newValue, variantId, type);
        setNewProductCost(newValue);
        return newValue;
    }


    switch (order?.payment_method) {
        case 0:
            url = '';
            name = 'Desconhecido';
            break;
        case 1:
            url = urlLinkCartao;
            name = 'Cartão de crédito';
            break;
        case 3:
            url = urlLinkBoleto;
            name = 'Boleto';
            break;
        case 4:
            url = urlLinkPix;
            name = 'Pix';
            break;
    }

    const orderId: string = order?.order_name?.replace('Pedido ', '');

    useEffect(() => {
        // Check if there are saved values in localStorage
        const savedOrderData = localStorage.getItem(orderId);
        if (savedOrderData) {
            const parsedData = JSON.parse(savedOrderData);
            setNewProductPrice(formatSet(parsedData.product_price.toString()));
            setNewProductCost(formatSet(parsedData.product_cost.toString()));
        } else {
            setNewProductPrice(formatSet(order.product_price.toString()));
            setNewProductCost(order?.product_cost ? formatSet(order.product_cost.toString()) : '0,00');
        }
    }, [orderId, order.product_price, order.product_cost]);

    const tax = order?.gateway_fee + order?.checkout_fee;
    const gateway = order?.gateway_fee;
    const checkout = order?.checkout_fee;
    const date = new Date(order?.date);

    const source = order?.source;

    var sourceLink = 'https://storage.googleapis.com/static-images-source/other-source-tracking.png';
    var sourceMsg = 'Direto';

    if (source) {
        sourceLink = getLinkFromSource(source);
        sourceMsg = getMsgFromSource(source);
    }

    const [showPopup, setShowPopup] = useState(false);
    const [newProductPrice, setNewProductPrice] = useState(formatSet(order.product_price.toString()));
    const [newProductCost, setNewProductCost] = useState(order?.product_cost ? formatSet(order.product_cost.toString()) : '0,00');

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleKeepEditClick = () => {
        setShowPopup(false);
        let order_product_price = Number(newProductPrice.replace('.', '').replace(',', '.'));
        let order_product_cost = Number(newProductCost.replace('.', '').replace(',', '.'));
        if (order.product_price !== order_product_price || order.product_cost !== order_product_cost) {
            order.product_price = order_product_price;
            order.product_cost = order_product_cost;

            const updatedOrderData = {
                product_price: order_product_price,
                product_cost: order_product_cost,
            };
            localStorage.setItem(orderId, JSON.stringify(updatedOrderData));

            setCanSave([...canSave, orderId]);  
            changeOrders(orderId);          
        }
    }

    

    return (
        <S.Container>
            <S.OrderId>
                #{orderId}
                {canSave.includes(orderId) ? <S.notSave>

                    <Tooltip 
                    title="Custo não salvo" 
                    placement="top"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#E90E0E',
                                fontWeight: 'semibold',
                                fontSize: '12px',
                                fontFamily: 'Montserrat',
                                borderRadius: '5px',
                                border: 1,
                                color: 'common.white',
                            }
                        },
                    }}
                >   
                <ErrorOutlineRoundedIcon fontSize='small' />
                </Tooltip>

                </S.notSave> : null}
                <span>
                    <S.Plus onClick={togglePopup}><Create sx={{ width: '14px', height: '14px' }} /> Editar</S.Plus>
                    {showPopup && (
                        <Modal onClose={() => setShowPopup(false)}>
                            <S.ContainerPopup>
                                <S.ImagePopup type={name}>
                                    <img src="https://storage.googleapis.com/static-images-source/management-cost.png"/>
                                </S.ImagePopup>
                                <S.TitlePopup>Editar Pedido #<span>{orderId}</span></S.TitlePopup>
                                <S.SubtitlePopup>Configure as informações do pedido selecionado.</S.SubtitlePopup>
                                <S.Line/>
                                <S.SubsubtitlePopup>Valor</S.SubsubtitlePopup>
                                <S.Label>
                                <S.ContainerInput>
                                <input 
                                    type="text" // Use "number" se quiser restringir a entrada a apenas números
                                    disabled={false}
                                    style={{ width: '30rem' }} // Definindo a largura diretamente via style
                                    value={newProductPrice}
                                    onChange={(e) => formatValue(e.target.value)}
                                />            
                                </S.ContainerInput>                        
                                </S.Label>
                                <S.SubsubtitlePopup>Custo do Produto</S.SubsubtitlePopup>
                                <S.Label>
                                <S.ContainerInput>
                                <input 
                                    type="text" // Use "number" se quiser restringir a entrada a apenas números
                                    disabled={false}
                                    style={{ width: '30rem' }} // Definindo a largura diretamente via style
                                    value={newProductCost}
                                    onChange={(e) => formatValueCost(e.target.value)}
                                />    
                                </S.ContainerInput>                        

                                </S.Label>
                                <S.KeepEdit onClick={ handleKeepEditClick}>Continue Editando</S.KeepEdit>
                            </S.ContainerPopup>
                        </Modal>
                    )}
                </span>
            </S.OrderId>
            <S.Status>
                {order?.status_name === 'approved' && (
                    <S.Approved>
                        <CheckCircleOutlineIcon sx={{ width: '14px', height: '14px' }} /> Aprovado
                    </S.Approved>
                )}
                {order?.status_name === 'pending' && (
                    <S.Pending>
                        <PendingOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Pendente
                    </S.Pending>
                )}
                {order?.status_name === 'cancelled' && (
                    <S.Cancelled>
                        <CancelOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Cancelado
                    </S.Cancelled>
                )}
            </S.Status>
            <S.Date>{formatDate(date)}</S.Date>
            <S.Payment>
                <img src={url} /> {name}
            </S.Payment>
            <S.Value>
                {currencySymbol}{' '}
                {parseFloat(newProductPrice.replace('.', '').replace(',', '.')).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </S.Value>
            <S.Costs>
                {currencySymbol}{' '}
                {parseFloat(newProductCost.replace('.', '').replace(',', '.')).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}
            </S.Costs>
            <S.Tax>
                {currencySymbol}{' '}
                {tax
                    ? tax?.toLocaleString('pt-Br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      })
                    : '0,00'}
            </S.Tax>
            <S.Tax>
                {currencySymbol}{' '}
                {tax
                    ? gateway?.toLocaleString('pt-Br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      })
                    : '0,00'}
            </S.Tax>
            <S.Tax>
                {currencySymbol}{' '}
                {tax
                    ? checkout?.toLocaleString('pt-Br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      })
                    : '0,00'}
            </S.Tax>
            <S.Source>
                <img src={sourceLink} />
                <Tooltip title={source}>
                    <div>
                        {sourceMsg}
                    </div>
                </Tooltip> 
            </S.Source>
        </S.Container>
    );
}
