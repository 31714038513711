
import { useNavigate } from 'react-router-dom';
import * as S from './style';
import { Sidebar } from '../../pages/_layouts/AppLayout/Sidebar';

export function Payment() {

    const navigate = useNavigate();

    return(
        <S.Container>
            <Sidebar showSideBar={true} setShowSideBar={() => {}}  />
            
            <S.InnerContainer>
                <S.Content>
                    <S.LogoSolomon>
                        <img src="https://storage.googleapis.com/static-images-source/horizontal-logo.png" alt='Logo Solomon' />
                    </S.LogoSolomon>
                    <S.Image>
                        <img src='https://storage.googleapis.com/static-images-source/hourglass.png' />
                    </S.Image>
                    <S.Title>
                    Seu período de <span>Avaliação gratuita</span> terminou.
                    </S.Title>
                    <S.Subtitle>
                    Esperamos que tenha conhecido mais da nossa ferramenta e explorado todas as funcionalidades.
                    </S.Subtitle>
                    <S.Subtitle>
                    Clique no botão abaixo para <span>liberar seu acesso</span>!
                    </S.Subtitle>
                    <S.Button onClick={() => navigate('/planos')}>
                        <Icon /> Liberar acesso
                    </S.Button>
                    <S.ButtonText>
                        Falar com suporte
                    </S.ButtonText>
                </S.Content>
                
            </S.InnerContainer>
    </S.Container>
    )
}

function Icon(){
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70q66 0 121 33t87 87h432v240h-80v120H600v-120H488q-32 54-87 87t-121 33Zm0-80q66 0 106-40.5t48-79.5h246v120h80v-120h80v-80H434q-8-39-48-79.5T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q33 0 56.5-23.5T360-480q0-33-23.5-56.5T280-560q-33 0-56.5 23.5T200-480q0 33 23.5 56.5T280-400Zm0-80Z"/></svg>
    )
}