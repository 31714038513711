import styled from 'styled-components';

export const FilterPaymentContainer = styled.div`
    position: relative; /* Para que o Dropdown seja posicionado em relação a este contêiner */
`;

export const FilterPaymentArea = styled.div`
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-top: -1px;
    height: 6px;
    color: var(--black);   
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;

    svg {
        margin-right: 0.5rem;
        width: 1.2rem;
    }

    &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;
    }
`;

export const FlexSelect = styled.div`
    display: flex;
    align-items: center;
`;

export const Dropdown = styled.div<{show: boolean}>`
    position: absolute; /* Atualizado para absolute */
    background-color: white;
    border: 1px solid #D4D4D4;
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 5px;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 0.3rem;
    width: 15.5rem;
    overflow: hidden;
    z-index: 100;
    transition: all 0.2s;
    top: 100%; /* Para posicionar abaixo do FilterPaymentArea */
    left: 0;
`;

export const DropdownItem = styled.div`
    width: 95%;
    border-radius: 8px;
    gap: 0.5rem;
    display: flex;
    padding: 2px 6px;
    color: #999;
    align-items: center;

    svg {
        width: 1.3rem;
    }

    &:hover {
        cursor: pointer;
        color: var(--black);
        background-color: var(--gray-140);
    }
`;

export const Icons = styled.div`
    svg {
        width: 1.2rem;
        margin-top: 3px;
    }
`;

export const MethodSelected = styled.div`
    font-size: 0.8rem;
    padding: 0.05rem 0.5rem;
    margin-right: 0.5rem;
    max-width: 4rem;
    text-align: center;
    min-width: 2.8rem;
    margin-left: 0.5rem;
    background-color: #6f7e77;
    color: white;
    border-radius: 50px;
`;
