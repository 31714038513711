import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
// width: 65.375rem;
padding: 1rem;
height: auto;
// min-height: 10rem;
// max-height: 45rem;
// max-height: 100vh;
background-color: #fff;
border-radius: 8px;
z-index: 999999;

::-webkit-scrollbar {
    height: 8px; /* Altura da barra de rolagem */
}


`

export const Icon = styled.div`
cursor: pointer;
width: fit-content;
margin-bottom: 0.5rem;
`

export const Title = styled.div`
font-weight: 600;
line-height: 1.5rem;
color: #1e1e1e;
span{
    color: var(--green)
}
`

export const SearchSection = styled.div`
margin-top: -1rem;
margin-bottom: 1rem;
display: flex;
justify-content: space-between;
`

export const Section = styled.div`
display: flex;
gap: 1.5rem;
flex-direction: column;
margin-bottom: 2rem;
`

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
`;

export const ContainerImage = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    width: max-content;
    padding-bottom: 1rem;
    margin: 1.5rem auto 0;
    animation: ${floating} 2s ease-in-out infinite;
    
    img {
        width: 10.75rem;
        height: auto;
    }
`;

export const TitleImage = styled.div`
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: var(--green);
`;

export const SubtitleImage = styled.div`
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
`;


export const FirstSectionItem = styled.div`

`

export const FirstSectionHeader = styled.div`
display: flex;
`

export const FirstSectionValue = styled.div`
font-size: 1.2rem;
line-height: 1.35rem;
font-weight: 550;
margin-left: 1.5rem;
color: #000;
    text-wrap: nowrap;

p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 12.2rem;
    
}
`

export const FirstSectionTitle = styled.div`
font-size: 0.9rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
display: flex;
gap: 0.5rem;



div {
    display: flex;
    gap: 0.3rem;
        text-wrap: nowrap;
    align-items: center;
    color: var(--green);
    font-weight: 550;
    img {
        width: 0.75rem;
    }
}
`

export const SquareIcon = styled.div`
width: 1rem;
height: 1rem;
border-radius: 0.25rem;
background-color: #ededed;
display: flex;
align-items: center;
justify-content: center;
margin-right: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`
export const SquareIconHelp = styled.div`
width: 1rem;
height: 1rem;
display: flex;
align-items: center;
justify-content: center;
margin-left: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`


export const FirstSection = styled.div`
display: flex;
flex-direction: row;
gap: 2rem;
margin-bottom: 1rem;
`

export const VLine = styled.div`
    height: 1px;
    width: 10rem;
    background-color: rgb(0,0,0,0.1);
    margin: auto 0;
`

export const SecondSection = styled.div`
    margin-top: -0.5rem;
`

export const SecondSectionTitle = styled.div`
font-size: 1.1rem;
font-weight: 500;
color: #000;
// margin-bottom: 0.7rem;

p {
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 50.2rem;

    }
`

export const SecondSectionSubtitle = styled.div`
font-size: 0.8rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;


`

export const Expand = styled.div`
border-radius: 50%;
width: 2.7rem;
min-width: 2.7rem;
height: 2.6rem;
// margin-left: 1rem;
// border: 1px solid rgb(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
background-color: #f3f3f3;
// margin-left: 170px;
   &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;

    }
`


export const SecondSectionItem = styled.div`
display: flex;
margin-bottom: 0.5rem;

img {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 1px;
    margin-right: 0.5rem;
}
`

export const SecondSectionItemText = styled.div`
font-size: 0.8rem;
line-height: 1.125rem;
font-weight: 500;
color: #989c97;
span{
    color: var(--black);
}
`

export const Subtitle = styled.div`
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
font-size: 0.75rem;
`

export const Line = styled.div`
height: 1px;
width: 100%;
margin-top: 0.5rem;
background-color: rgb(0,0,0,0.1);
margin-bottom: 2rem;
`

export const Header = styled.thead`
display: flex;
background-color: #f7f7f7;
width: 89.4375rem;
height: 3.3rem;
position: sticky;
top: 0;
z-index: 10;
`
export const InnerContainer = styled.div<{expandedTable?: boolean}>`
// width: 65.375rem;
overflow-x: auto;
max-height: 55vh;
min-height: ${props => props.expandedTable ? '75vh' : 'auto'};
height: auto;
position: relative;
`

export const MainColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 6rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 1rem;
position: sticky;
left: 0;
top: 0;
z-index: 10;
background-color: inherit;
border-right: 1px solid #eee;
`

export const DefaultColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 9.25rem;
display: flex;
align-items: center;
justify-content: flex-start;
margin-left: 1rem;
`;

type LinesProps = {
    dontHaveOrders: boolean
}

export const Lines = styled.div<LinesProps>`
max-height: 20rem;
position: relative;
// ${props => props.dontHaveOrders && 'padding-bottom: 15rem;'}

    > div:nth-child(odd) {
        background-color: var(--white);
    }

    > div:nth-child(even) {
        background-color: var(--gray-112);
    }
`;

export const Filter = styled.div`
    display: flex;
    font-size: 14px;
    margin-right: 1rem;
    font-weight: 500;
    justify-content: center;
    width: 6rem !important;
    min-width: 6rem !important;
    box-sizing: border-box;
    width: var(--button-diameter);
    height: 2.7rem;
    border-radius: var(--button-border-radius);
    color: var(--black);
    font-weight: 550;
    border: none;
    background: #f3f3f3;
    // border: 1px solid #d9dbd9;
    cursor: pointer;
    position: relative;
    outline: none;
    --button-bg: #353434;
    --button-hover-bg: #464646;
    --button-text-color: #cccccc;
    --button-hover-text-color: #8bb9fe;
    --button-border-radius: 8px;
    --button-diameter: 45px;
    margin-left: 15px;
    --button-outline-width: 1px;
    --button-outline-color: rgb(141, 141, 141);
    align-items: center;
    transition: all 200ms ease;
     &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;

    }
`;

type FilterProps = {
    showFilter: boolean;
};

export const FilterContainer = styled.div<FilterProps>`
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
    padding: 16px;
    width: 240px; /* Ajuste a largura conforme necessário */
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0; /* Inicia com opacidade zero */
    max-height: 0;
    position: absolute;
    z-index: 100 !important;


    ${(props) =>
        props.showFilter
            ? `
                max-height: 500px;
                position: absolute;
                z-index: 50;
                right: 0;
                // margin-left: 777px;
                margin-top: 54px;
                // top: 450px;
                // left: 880px;
                opacity: 1;
            `
            : `
            `}
`;


export const HeaderFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
`;

export const CloseButton = styled.i`
    display: flex;
    color: #989c97;
    // margin-right: 16px;
    cursor: pointer;

    &:hover {
        position: relative;
        color: #192324;
        border-radius: 3px;
        cursor: pointer;
    }
`;

export const LinesFilter = styled.span`
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 16px;
`;

export const FilterSection = styled.div`
    margin-bottom: 20px;
`;

export const FlexTitleReset = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    font-size: 12px;
    color: #979797;
    font-weight: 500;
`;

export const ResetButton = styled.button`
    background: none;
    border: none;
    color: #19c27e;
    cursor: pointer;
    font-size: 11px;
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    color: #555353;
    font-weight: 550;
`;


export const ApplyButton = styled.button`
    display: block;
    width: 100%;
    background-color: #19c27e;
    color: white;
    padding: 10px;
    font-size: 14px;
    font-weight: 550;
    border-radius: 7px;
    cursor: pointer;
    transition: transform 200ms ease;
    &:hover {
        transform: translateY(2px);
    }
`;

export const Filters = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: -1.5rem;
    align-items: center;
    gap: 1rem;
`;

export const Payment = styled.div`
    width: fit-content;
    height: 2rem;
    color: #448f71;
    background-color: #f3f3f3;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    // margin-top: 0.3rem;
    // margin-left: 1rem;
    padding: 0 0.5rem;
    img {
        // margin-left: 1rem;
        width: 1.3rem;
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-left: 1rem;
    width: 9.825rem;
`;

export const Approved = styled.div`
     width: fit-content;
    height: 2rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    // margin-top: 0.3rem;
    // margin-left: 1rem;
    padding: 0 0.5rem;
`;

export const Pending = styled.div`
     width: fit-content;
    height: 2rem;
    color: #8f8544;
    background-color:  #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    // margin-top: 0.3rem;
    // margin-left: 1rem;
    padding: 0 0.5rem;

`;

export const Cancelled = styled.div`
     width: fit-content;
    height: 2rem;
    color: #8f4444;
    background-color:  #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    // margin-top: 0.3rem;
    // margin-left: 1rem;
    padding: 0 0.5rem;

`;

export const Image = styled.div`
    display: flex;
    // margin-left: 1rem;
`;

export const Tabs = styled.div`
    display: flex;
    // margin-right: 2rem;
    gap: 0.5rem;
    
    // width: 100%;
`;

export const Tab = styled.div<{selected: boolean}>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    text-wrap: nowrap;
    // background-color: #f7f7f7;
    // width: 15rem;
    border-radius: 8px;
    padding: 0 18px;
    font-weight: 550;
    // transform:  ${(props) => props.selected ? 'translateY(0px)' : 'translateY(0px)'};
    color:  ${(props) => props.selected ? 'var(--green)' : '#88A09c'};
    background-color:  ${(props) => props.selected ? '#efefef' : 'white'};
    cursor: pointer;
    // border: 1px solid #E6E6E6;
    border-bottom:none;
    transition: all 0.3s ease;

    svg {
        fill: #88A09c;
        width: 1rem;

        ${(props) => props.selected && `
            fill: var(--green);
        `}

    }

    &:hover{
        // transform: translateY(8px);
        color: var(--green);
    }
`;


export const EditColumnArea = styled.div`
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 12px;
    margin-right: 1rem;
    color: var(--black);   
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;

    svg {
        width: 1.2rem;
    }

    &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;

    }
`;
