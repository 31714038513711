import styled from 'styled-components';

type CardProps = {
    isMobile?: boolean;
    onClick?: any;	
    selected?: boolean;
};

type TopProps = {
    isMobile?: boolean;
    savedCoupon?: string;
};

export const CardContainer = styled.div<CardProps>`
  background-color: white;
  display: flex;
  flex-direction: column;
  border: ${props => props.isMobile ? '1px solid #19c27e' : '1px solid #e7e7e7'};
  box-shadow: 0 0 18px rgba(70, 115, 80, 0.1);
  border-radius: 8px;
  padding: 16px;
  z-index: 1;
  margin-bottom: ${props => props.isMobile ? '0rem' : 'none'} ;
  width: 100%;  // Ajuste conforme necessário
  transition: all ease 250ms;

  &:hover {
    // border: 1px solid #19c380;
    // cursor: pointer;
    transition: all ease 250ms;
}
`;


export const Select = styled.div`
    position: relative;
    border-radius: 10px;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    margin-bottom: 1rem;
    height: 4.5rem;
    width: 100%;
    border: 1px solid #19c27e;
    border-radius: 8px;
    background-color: #fff;
    color: var(--black);
    font-weight: 600;

    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
    }

`;


export const OptionsContainer = styled.div<{isMobile: boolean}>`
    position: absolute;
    background-color: #fff;
    z-index: 10;
    display: flex;
    width: 100%;
    visibility: visible;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.5s;
    flex-direction: column;
    left: 0;
    top: 5rem;
    border-radius: 8px;
    // gap: 0.5rem;
    border: 1px solid var(--gray-120);
    box-shadow: 0 0 18px rgba(70, 115, 80, 0.1);
`;

export const Option = styled.div`
    padding: 0.3rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    height: 3.5rem;
    font-size: 0.4rem !important;

    &:hover {
        cursor: pointer;
        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        background-color: #f5f6f7;
    }
  
`;

export const Button = styled.button<{isMobile: boolean}>`
  background: transparent;
  align-items: center;
  padding: 0rem 0.5rem;
  background-color: var(--gray-700);
  position: relative;
  display: flex;
  bottom: 32px;
  left: ${props => props.isMobile ? '70%' : '185px'};
  width: ${props => props.isMobile ? '5rem' : '5rem'};
  cursor: pointer;
  gap: 0.3rem;  
  border-radius: 50px;

  p {
    color: white;
    font-weight: 500;
    }
  svg {
    width: 16px;
    fill: white;
    transition: all ease 250ms;
  }

  &:hover {
  background-color: var(--green);
    svg {
      // fill: var(--green);
      // transform: scale(1.1);
      }
      transition: all ease 250ms;
      }
      transition: all ease 250ms;
`

export const InputArea = styled.div<CardProps>`
    margin: 0 auto;
    height: 40px;
    input {
      border-radius: 50px !important;
      border: 1px solid #19c27e !important;
    }
`

export const TopBottomArea = styled.div<TopProps>`
color:${props => props.savedCoupon ? 'white' : '#19c27e'} ;
font-size: 11px;
font-weight: 550;
border: 1px solid #19c27e;
width: fit-content;
border-radius: 50px;
gap: 6px;
margin: 0 auto;
background: ${props => props.savedCoupon ? '#19c27e' : 'transparent'};
display: flex;
cursor:  ${props => props.savedCoupon ? 'not-allowed' : 'pointer'};
pointer-events:  ${props => props.savedCoupon ? 'none' : 'auto'};
padding: 0px 12px;
align-items:  center;
justify-content: center;
margin-top: 0px;
transition: all ease 250ms;
  
&:hover {
  background: #19c27e;
  transform: translateY(3px);
  color: white;
  transition: all ease 250ms;
   svg {
   
    fill: white;
   }
}


svg {
  width: 16px;
  fill: white;
  cursor: pointer;
  pointer-events: auto;
}

span {
  display: flex;
  align-items: center;

  svg {
      margin-right: 5px;
      width: 16px;
      fill: ${props => props.savedCoupon ? 'white' : '#19c27e'};
  }
}

`

export const Advantage = styled.div`
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
    p{
        color: #666;  // Cor cinza para o texto de descrição
        font-size: 0.75rem;
    }
    svg {
        width: 14px;
        fill: var(--green) !important;
    }
`

export const BottomArea = styled.div<CardProps>`
display:  ${props => props.isMobile ? 'none' : 'flex'};
flex-direction: column;
border-top: 1px solid #e7e7e7;
margin: 0 auto;
margin-top: 10px;
width: 90%;
padding: 12px 16px 12px 16px;
h1 {
    font-weight: 550;
    color: var(--black);
    margin-bottom: 2px;
    // border-bottom: 1px solid #e7e7e7;
}
`

export const ExtraContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 80%;
margin: 0 auto;
align-content: center;
justify-content: center;
border-top: 1px solid #e7e7e7;
padding: 12px 16px 16px 16px;
font-size: 12px;
text-align: center;

p {
    color: #666;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
}

svg {
    fill: #19c27e !important;
    width: 16px;
  }
`

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    p {
        margin-top: -8px;
        color: #666;  // Cor cinza para o texto de descrição
        font-size: 10px;
      }
`;

export const InnerContainer = styled.div`
    display: flex;
    align-items: center;  
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 2px;
      svg {
        fill: #19c27e;
      }
    `;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const PlanName = styled.div`
  color: var(--green);  // Cor verde para o ícone de verificação e texto
  font-size: 14px;
  font-weight: 550;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 2x;

  svg {
    width: 16px;
    fill: var(--green);
    margin-left: 4px;
  }

`;

export const PlanNameOption = styled.div`
  color: var(--black);  // Cor verde para o ícone de verificação e texto
  font-size: 12px;
  font-weight: 550;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 2x;

  svg {
    width: 16px;
    fill: var(--green);
    margin-left: 4px;
  }

`;

export const Price = styled.div`
  color: #000;
  font-size: 36px;
  font-weight: 550;
  text-align: right;

  span {
    color: #666;
    font-weight: 500;
    font-size: 18px;

  }
`;

export const PriceOption = styled.div`
  color: #000;
  font-size: 26px;
  font-weight: 550;
  text-align: right;
  span {
    color: #666;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const Description = styled.div`
  color: #666;  // Cor cinza para o texto de descrição
  font-size: 11px;
  margin-bottom: 4px;
  text-align: right;
  font-weight: 500;
  span {
    font-weight: 550;
    color: #19c27e;  // Cor cinza para o texto de descrição
  }
`;

export const DescriptionOption = styled.div`
  color: #666;  // Cor cinza para o texto de descrição
  font-size: 9px;
  margin-bottom: 4px;
  text-align: right;
  font-weight: 500;
  span {
    font-weight: 550;
    color: #19c27e;  // Cor cinza para o texto de descrição
  }
`;

export const Savings = styled.div<CardProps>`
  padding-top: 8px;
  color: #666;
  font-size: 8px;
  font-weight: 500;
  width: 100%;
  flex-direction: column;
  height: ${props => props.selected ? '250px' : '16px'};
  display: ${props => props.isMobile ? 'none' : 'flex'};
  align-items:  flex-start;
  transition: all ease 250ms;
  span {
    display: flex;
    align-items: center;

    svg {
        width: 16px;
        fill: var(--green);
    }
  }
  svg {
    margin-right: 4px;
    fill: #666;
    }
`;

export const AdvantageColumn = styled.div`
    
`
export const AdvantageColumnArea = styled.div`
  display: flex;
  gap: 2.5rem;
  margin-top: 0.2rem;
`

export const PixelContainer = styled.div`
  margin-top: 0.2rem;
  display: flex;
  padding: 0 0.5rem;
  // background-color: #e7e7e7;
  border: 1px solid #19c27e;
  width: fit-content;
  border-radius: 50px;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
  height: 1.2rem;
  // opacity: 0.8;
  
  svg {
    width: 15px;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
    color: #19c27e;
  }
`