import * as S from './style';
import { useState, useEffect, useRef } from 'react';
import { LinesTableConfigurationUtmProps } from '../../../../@types';
import Modal from '../../../../components/Modal';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useVisibility from '../../../../hooks/useVisibilityChange';

export function LinesTableConfigurationUtm ({ tabSelected, metric }: LinesTableConfigurationUtmProps) {

    const [showUTM, setShowUTM] = useState(false);
    const [adUrl, setAdUrl] = useState('');
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const { isVisible } = useVisibility();

    useEffect(() => {
        if (tabSelected === 'Facebook') {
            setAdUrl(`https://adsmanager.facebook.com/adsmanager/manage/ads/edit?act=${metric.Account}&nav_entry_point=am_local_scope_selector&selected_campaign_ids=${metric.Campaign}&selected_adset_ids=${metric.AdGroup}&selected_ad_ids=${metric.Ad}&is_reload_from_account_change&breakdown_regrouping=1&nav_source=no_referrer`);
        } 
    }, [metric]);

    const handleCopy = () => {
        navigator.clipboard.writeText(adUrl)
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000); // Hide popup after 2 seconds
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setShowPopup(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleOpenInNewWindow = () => {
        window.open(adUrl, '_blank');
    };

    const handlePopupClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.stopPropagation(); 
    };

    if (tabSelected === 'Facebook') {
        return (
            <>
                <S.Container>
                    <S.MainColumn>{ isVisible ? metric?.Account_name : '* * * * * * * * * * *'}</S.MainColumn>
                    <S.MainColumnClick>
                        <p onClick={(event) => { setShowPopup((prev) => !prev); handlePopupClick(event); }}>{ isVisible ? metric?.Ad_name : '* * * * * * * *'}</p>
                        {showPopup && (
                            <S.ContainerWithPosition>
                                <S.ConteinerPopup ref={popupRef}>
                                    <S.ConteinerPopupButton onClick={handleOpenInNewWindow}>Abrir neste navegador <ArrowForwardIcon/></S.ConteinerPopupButton>
                                    <S.ConteinerPopupButton onClick={handleCopy}><h1>Abrir em outro navegador</h1><ContentCopyIcon/></S.ConteinerPopupButton>
                                </S.ConteinerPopup>
                            </S.ContainerWithPosition>
                        )}
                        <S.CopyButton onClick={handleCopy}><ContentCopyIcon/></S.CopyButton>
                        {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Link copiado!</S.CopiedPopup>}
                    </S.MainColumnClick>
                    <S.DefaultColumnF>{isVisible ? metric?.AdGroup_name : '* * * * * * * * * * *'}</S.DefaultColumnF>
                    <S.DefaultColumnF>{isVisible ? metric?.Campaign_name : '* * * * * * * * * * *'}</S.DefaultColumnF>
                    <S.DefaultColumnF> <RemoveRedEyeOutlinedIcon onClick={() => setShowUTM((prev) => !prev)}/> </S.DefaultColumnF>
                    {showUTM && (
                        <Modal onClose={() => setShowUTM(false)}>
                            <S.ModalContainer>
                                <S.ModalContentHeader>UTMS</S.ModalContentHeader>
                                <S.Line></S.Line>
                                <S.ModalContentP>utm_source: {isVisible ? metric?.utm_source : '* * * * * * * *  *'}</S.ModalContentP>
                                <S.ModalContentP>utm_medium: {isVisible ? metric?.utm_medium : '* * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_campaign: {isVisible ? metric?.utm_campaign : '* * * * * *  * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_content: {isVisible ? metric?.utm_content : '* * * * * * * *'}</S.ModalContentP>
                                <S.ModalButton onClick={() => setShowUTM((prev) => !prev)}>Fechar</S.ModalButton>
                            </S.ModalContainer>
                        </Modal>
                    )}
                </S.Container>
            </>
        );
    } else if (tabSelected === 'Google') {
        return (
            <>
                <S.Container>
                    <S.MainColumnG>{isVisible ? metric?.Ad_name : '* * * * * * * * * * *'}</S.MainColumnG>
                    <S.DefaultColumnG>{isVisible ? metric?.AdGroup_name : '* * * * * * * * * * *'}</S.DefaultColumnG>
                    <S.DefaultColumnG>{isVisible ? metric?.Campaign_name : '* * * * * * * * * * *'}</S.DefaultColumnG>
                    <S.DefaultColumnG> <RemoveRedEyeOutlinedIcon onClick={() => setShowUTM((prev) => !prev)}/> </S.DefaultColumnG>
                    {showUTM && (
                        <Modal onClose={() => setShowUTM(false)}>
                            <S.ModalContainer>
                                <S.ModalContentHeader>UTMS</S.ModalContentHeader>
                                <S.Line></S.Line>
                                <S.ModalContentP>utm_source: {isVisible ? metric?.utm_source : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_campaign: {isVisible ? metric?.utm_campaign : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_content: {isVisible ? metric?.utm_content : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalButton onClick={() => setShowUTM((prev) => !prev)}>Fechar</S.ModalButton>
                            </S.ModalContainer>
                        </Modal>
                    )}
                </S.Container>
            </>
        );
    } else if (tabSelected === 'Tiktok') {
        return (
            <>
                <S.Container>
                    <S.MainColumnG>{isVisible ? metric?.Ad_name : '* * * * * * * * * * *'}</S.MainColumnG>
                    <S.DefaultColumnG>{isVisible ? metric?.AdGroup_name : '* * * * * * * * * * *'}</S.DefaultColumnG>
                    <S.DefaultColumnG>{isVisible ? metric?.Campaign_name : '* * * * * * * * * * *'}</S.DefaultColumnG>
                    <S.DefaultColumnG> <RemoveRedEyeOutlinedIcon onClick={() => setShowUTM((prev) => !prev)}/> </S.DefaultColumnG>
                    {showUTM && (
                        <Modal onClose={() => setShowUTM(false)}>
                            <S.ModalContainer>
                                <S.ModalContentHeader>UTMS</S.ModalContentHeader>
                                <S.Line></S.Line>
                                <S.ModalContentP>utm_source: {isVisible ? metric?.utm_source : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_medium: {isVisible ? metric?.utm_medium : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_campaign: {isVisible ? metric?.utm_campaign : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalContentP>utm_content: {isVisible ? metric?.utm_content : '* * * * * * * * * * *'}</S.ModalContentP>
                                <S.ModalButton onClick={() => setShowUTM((prev) => !prev)}>Fechar</S.ModalButton>
                            </S.ModalContainer>
                        </Modal>
                    )}
                </S.Container>
            </>
        );
    }
}
