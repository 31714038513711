import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import { trackEvent } from '../segment/sendEvent'

import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getTaxes(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/taxes?company_id=${company_id}`);
    return data;
}

export async function changeTaxes(body: DTO.changeTaxesDTO): Promise<AxiosResponse> {
    const { company_id, calculation_rule, aliquote_rate, user } = body;

    await trackEvent('set-tax', {
        userId: user?.user_id,
        timestamp: new Date().toISOString(),
        company_id: body.company_id,
        company_name: user?.companies[body.company_id]?.company,
        aliquote_rate: body.aliquote_rate,
        calculation_rule: body.calculation_rule
    });

    const newBody = { company_id, calculation_rule, aliquote_rate };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/taxes`, newBody);
    
    return data;
}
