import React, { useState } from 'react';
import * as S from './style';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';

interface SelectCurrencyProps {
    onCurrencySelected: (currencySelected: string) => void}

export function SelectCurrencyType({onCurrencySelected}: SelectCurrencyProps) {

const [isOpen, setIsOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState(null);

const options = [
    { value: 'USD', label: 'USD', icon: 'https://cdn-icons-png.flaticon.com/512/197/197484.png' },
    { value: 'BRL', label: 'BRL', icon: 'https://cdn-icons-png.flaticon.com/512/9906/9906449.png' },
    { value: 'EUR', label: 'EUR', icon: 'https://cdn-icons-png.flaticon.com/512/12339/12339494.png' },
    { value: 'AUD', label: 'AUD', icon: 'https://cdn-icons-png.flaticon.com/512/9906/9906443.png' },
    { value: 'GBP', label: 'GBP', icon: 'https://cdn-icons-png.flaticon.com/512/733/733355.png' },
    { value: 'COP', label: 'COP', icon: 'https://cdn-icons-png.flaticon.com/512/16022/16022037.png' },
    { value: 'ARS', label: 'ARS', icon: 'https://cdn-icons-png.flaticon.com/512/197/197573.png' },
    { value: 'CAD', label: 'CAD', icon: 'https://cdn-icons-png.flaticon.com/512/197/197430.png' },
];

const toggleDropdown = () => setIsOpen(!isOpen);
const handleOptionClick = (currencySelected) => {
    setSelectedOption(currencySelected);
    onCurrencySelected(currencySelected.value);
    setIsOpen(false);
};

    return (
        <S.DropdownContainer>
            <S.DropdownButton onClick={toggleDropdown}>
                {selectedOption ? (
                    <>
                        <S.InfoArea>
                            <S.Icon src={selectedOption.icon} alt={selectedOption.label} />
                            {selectedOption.label}
                        </S.InfoArea>
                        <ExpandMoreRounded />
                    </>
                ) : 
                    <>
                        <S.InfoArea>
                            <S.Icon src='https://cdn-icons-png.flaticon.com/512/9906/9906449.png' alt='https://cdn-icons-png.flaticon.com/512/9906/9906449.png' />
                            BRL
                        </S.InfoArea>
                        <ExpandMoreRounded />
                    </>}
            </S.DropdownButton>
            {isOpen && (
                <S.DropdownList>
                    {options.map(option => (
                        <S.DropdownItem key={option.value} onClick={() => handleOptionClick(option)}>
                            <S.Icon src={option.icon} alt={option.label} />
                            {option.label}
                        </S.DropdownItem>
                    ))}
                </S.DropdownList>
            )}
        </S.DropdownContainer>
    )
}