import { z } from 'zod';

export const signInSchema = z.object({
    email: z.string().email(),
    password: z.string(),
});

export const signUpSchema = z.object({
    email: z.string().email(),
    password: z.string(),
    name: z.string(),
    phone: z.string().min(1),

    is_guest: z.boolean(),
    invite_token: z.string().optional(),
    invite_id: z.string().optional(),
});

export const signInUserSchemaLocalStorage = z.object({
    user_id: z.string(),
    credentials: z.object({
        email: z.string().email(),
    }),
    name: z.string(),
    mask_id: z.string(),
});

export const signInTokenSchemaLocalStorage = z.object({
    access_token: z.string(),
    session_token: z.string(),
});

export const signInCurrentUserSchemaLocalStorage = z.string();
