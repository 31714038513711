import * as S from './style';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

export function PoupupIntegration({ type, setShowPopup, urlOnboarding, showPopup }: { showPopup?: boolean; type: string; setShowPopup: (value: boolean) => void; urlOnboarding: string }) {
    function openIntegrationOnboarding() {
        window.open(urlOnboarding, '_blank');
    }

    if (type === 'facebook') {
        return (
            <S.ContainerFacebook showPopup={showPopup} onClick={(e) => e.stopPropagation()}>
                <S.MainButtonFacebook onClick={openIntegrationOnboarding}>
                    Conectar neste navegador <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                </S.MainButtonFacebook>
                <S.SecondaryButtonFacebook onClick={() => setShowPopup(true)}>
                    Conectar em outro navegador
                    <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#989c96' }} />
                </S.SecondaryButtonFacebook>
            </S.ContainerFacebook>
        );
    }

    if (type === 'google') {
        return (
            <S.ContainerGoogle showPopup={showPopup} onClick={(e) => e.stopPropagation()}>
                <S.GoogleTitle>Importante</S.GoogleTitle>
                <S.GoogleSubtitle>Não se esqueça de ativar todas as permissões para que</S.GoogleSubtitle>
                <S.GoogleSubtitle>a integração com o Google Ads funcione corretamente</S.GoogleSubtitle>
                <S.ButtonGoogle onClick={openIntegrationOnboarding}>
                    Prosseguir <ArrowForwardSharpIcon sx={{ width: '12px', height: '12px', color: '#19c27e' }} />
                </S.ButtonGoogle>
            </S.ContainerGoogle>
        );
    }
}
