import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getAccessGroups(company_id: string){
    const data: AxiosResponse = await api.get(`${BASE_URL}/auth/permissions/${company_id}/access_groups`);
    return data;
}

export async function createAccessGroup(body: DTO.accessGroupDTO){
    const {name, company_id, scopes} = body;
    const newBody = {name, scopes}
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/permissions/${company_id}/access_groups`, newBody);
    return data;
}


export async function deleteAccessControl(body: DTO.deleteAccessDTO) {
    const { guest_id, company_id } = body;
    const newBody = { guest_id };
    const data: AxiosResponse = await api.delete(`${BASE_URL}/auth/permissions/${company_id}/access_control`, { data: body } );

    return data;
}

export async function getAccessControl(company_id: string){
    const data: AxiosResponse = await api.get(`${BASE_URL}/auth/permissions/${company_id}/access_control`);
    return data;
}

export async function createInvite( body: DTO.inviteDTO){
    const { email, group_id, company_id} = body;
    const newBody = {email, group_id}
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/permissions/${company_id}/invites`, newBody);
    return data;
}