import styled from 'styled-components';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

export const HistoryContent = styled.div`
  display: flex;
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
`;


export const HistoryTable = styled.table`
  flex: 1; /* Ocupar metade da largura disponível */
  width: 100%; /* Ou a proporção desejada */
  border-collapse: collapse;

  
  tr:nth-child(even) {
    background-color: #f2f2f2; // Cor para as linhas pares
  }

  tr:nth-child(odd) {
    background-color: #ffffff; // Cor para as linhas ímpares
  }
  


  th, td {
    text-align: center;
    padding: 16px;
    font-size: 15px;
    text-wrap: nowrap;
    font-weight: 500;
  }

  th {
    background-color: var(--gray-110);
    text-wrap: nowrap;
    padding: 18px;
  }

  /* Aqui incluímos os estilos da barra de rolagem */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 0px 10px 10px 0px;
  }
`;

export const DeleteForeverRoundedIconStyled = styled(DeleteForeverRoundedIcon)`
width: 0.7em !important;
fill: var(--black);
`;