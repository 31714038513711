import styled, { keyframes } from 'styled-components';


export const Container = styled.div`
    background-color: var(--gray-112);
    padding: 0rem 2rem 1rem 0;
    min-height: 94vh;
    display: flex;
    justify-content: center;
    width: 100vw;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Section = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin-bottom: 0.8rem;
        flex-direction: column;
    span {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    @media (max-width: 1400px) {
        justify-content: center;
        > *:not(:last-child) {
         margin-bottom: 1.5rem;
        display: flex;
        flex: 1;
        max-width: 30rem;
        width: 90%;
        
        justify-content: center;
            // margin-right: 1vw;
            margin-bottom: 1.5rem;
        }
        flex-wrap: wrap;
    }
    
`;

export const InnerContent = styled.div`
    min-width: 75.5625rem;
    max-width: 75.5625rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
`;

export const ContentSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.0625rem;
    width: 85vw;

    @media (max-width: 1400px) {
        justify-content: center;
        gap: 0.5rem;
        > * {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
`;

export const MetaSection = styled.div`
    
`;

export const CalendarContainer = styled.div`
    .flatpickr-input {
        background-color: rgb(255, 255, 255) !important;
    }, 
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const Title = styled.div`
    width: 100%;
    gap: 1rem;
    display: flex;
    align-items: center;
    
    h3 {
        font-weight: 550;
        font-size: 1.3rem;
    }

    `

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--gray-100);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`
