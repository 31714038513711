import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    height: 2.71875rem;
    width: 7.125rem;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: 550;
    line-height: 1.21875rem;
    color: #081b16;
`;

export const MainColumn = styled.div`
    display: flex;
    height: 2.71875rem;
    width: 7.125rem;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    color: var(--green);
`;
