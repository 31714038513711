import styled from 'styled-components';

export const Container = styled.div`
width: 37.5rem;
max-height: 26rem;
padding-bottom: 1rem;

`
export const Title = styled.div`
font-size: 1.25rem;
line-height: 1.5rem;
font-weight: 500;
color: #000;
margin-left: 1rem;
margin-top: 1rem;
`

export const Line = styled.div`
width: 100%;
height: 1px;
background-color: rgb(0,0,0,0.1);
margin-top: 1rem;
box-shadow: 6.5227px 6.5227px 17px -2.1605px rgba(71, 118, 99, 0.05);
`

export const Content = styled.div`


`

export const ButtonSection = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 1rem;
`

export const ContentTitle = styled.div`
font-size: 1rem;
line-height: 1.2rem;
color: #8d8c8c;
margin-left: 1rem;
margin-top: 1rem;
margin-bottom: 0.5rem;
`

export const Lines = styled.div`
max-height: 8rem;
overflow-y: auto;
`

export const FinalSection = styled.div`
display: flex;
justify-content: space-around;
margin-top: 1rem;
`

export const Option = styled.div`

`


export const OptionName = styled.div`
font-size: 0.8125rem;
font-weight: 500;
line-height: 1.2rem;
color: #000;
`

function getInputWidth(value: string) {
    const newValue = value?.replace('rem', '');
    const newValueNumber = parseFloat(newValue);
    const width = newValueNumber - 3.45;
    return `${width}rem`;
}

export const OptionInput = styled.div`
    position: relative;
    width: 9.25rem;
    height: 2.3rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 3rem;
    background-color: #f8f8f8;

    input {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.125rem;
        color: var(--black);
        background-color: #f8f8f8;
        height: 2.3rem;
        width: ${getInputWidth('11.25rem')};
        border: none;
        outline: none;
        &::placeholder {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.125rem;

        }
    }
        &:focus,
        &:hover {   
                    outline: none;
                    border-color: rgba(76, 234, 134, 0.4);
                    box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
                }

`;


export const InputIcon = styled.div`
position: absolute;
top: 0.2rem;
left: 0.6rem;
font-size: 1.25rem;
font-weight: 500;
line-height: 1.875rem;
color: var(--green);
width: max-content;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
`