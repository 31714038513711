import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';

import App from './App.tsx';
import { ToggleThemeProvider } from './contexts/ToggleThemeContext';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { queryClient } from './services/queryClient';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <ToggleThemeProvider>
            <AuthProvider>
                <App />
            </AuthProvider>
        </ToggleThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
