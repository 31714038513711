import React from 'react';
import Modal from '../../../components/Modal';
import * as S from './style.ts';
import { ButtonApp } from '../../../components/ButtonApp';
import { Check } from '@mui/icons-material';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/l10n/pt.js';
import { useState } from 'react';
import { format } from 'date-fns';
import { useUpdateProductCost } from '../../../services/hooks/productsHooks.ts';
import useAuth from '../../../hooks/useAuth.tsx';
import { ProductsCostDTO } from '../../../@types/types-api.ts';
import useAlert from '../../../hooks/useAlert.tsx';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

interface ModalSaveCostsProps {
    setSelected?: (value: any[]) => void;
    setSelectAll?: (value: boolean) => void;
    isEditingProductsCost?: boolean;
    useStorage?: boolean; // Adicionado aqui
    toggleEditPerProduct?: () => void;
    onClose: () => void;
    editedVariants: any;
    setEditedVariants: any;
    products: any[];
    setProducts: (value: any[]) => void;
    dataProductCosts: ProductsCostDTO;
    setDataProductCosts: (value: ProductsCostDTO) => void;
}

interface Variant {
    company_id: string;
    account_id: string;
    store_id: string;
    product_id: string;
    variant_id: string;
    variant_cost: number;
    currency: string;
}

interface Changes {
    company_id?: string;
    platform?: string;
    account_id?: string;
    store_id?: string;
    variants: Array<{
        period_start: string;
        period_end: string;
        product_id: string;
        variant_id: string;
        variant_cost: number;
        currency: string;
    }>;
}



const ModalSaveCosts : React.FC<ModalSaveCostsProps> = ({setSelected, setSelectAll, toggleEditPerProduct, onClose, editedVariants, setEditedVariants, products, setProducts, dataProductCosts, setDataProductCosts }) => {

    const { showAlert } = useAlert();
    const [costApplication, setCostApplication] = React.useState('next-orders');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    const { mutate: handleUpdateProductCost } = useUpdateProductCost();
    const {currentCompany} = useAuth();
    // const [editedVariantsFinal, setEditedVariantsFinal] = useState({} as any); // Adicionado aqui

    const handleCostApplicationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCostApplication(e.target.value);
    };

    const handleSave = () => {
        let periodStart = format(new Date(), 'yyyy-MM-dd') +  'T12:00:00';
        let periodEnd : string = ''; // Por padrão, não definido

        if (costApplication === 'define-period') {
            periodStart = format(startDate, 'yyyy-MM-dd') + 'T12:00:00';
            if (endDate){
                periodEnd = format(endDate, 'yyyy-MM-dd') + 'T12:00:00';
            }
        }

        const changes: Changes = { variants: [] };
        let first = true;

        // //console.log("edited variants normal antes de redefinir", editedVariants);

        let editedVariantsFinal = editedVariants;
        
        // const editedVariantsFinal = useStorage ? loadEditedVariants() : editedVariants;
        
        for (const product of Object.values(editedVariantsFinal) as Variant[]) {
            //console.log('product', product);
            if (product && first) {
                changes.company_id = currentCompany;
                changes.platform = 'shopify'; // Substitua pelo valor correto da plataforma
                changes.account_id = product.account_id;
                changes.store_id = product.store_id;
                first = false;
            }

            if (product) {
                changes.variants.push({
                    period_start: periodStart,
                    period_end: periodEnd,
                    product_id: product.product_id,
                    variant_id: product.variant_id,
                    variant_cost: product.variant_cost,
                    currency: product.currency
                });
            }
        }

        handleUpdateProductCost(changes);
        // Atualiza a lista de produtos
        setProducts(products.map((product) => {
            const newProduct = { ...product };
            newProduct.variants = newProduct.variants.map((variant: any) => {
                const newVariant = { ...variant };
                if (editedVariantsFinal[variant.variant_id]) {
                    newVariant.cost = editedVariantsFinal[variant.variant_id].variant_cost;
                    newVariant.currency = editedVariantsFinal[variant.variant_id].currency;
                }
                return newVariant;
            });
            return newProduct;
        }));

        const updatedCostAt = new Date().toISOString();
        const newLines = Object.keys(editedVariantsFinal).map(variantId => {
            const existingProduct = dataProductCosts.data.data.find(product => product.variant_id === variantId);
            if (!existingProduct) {
                return null; 
            }
            return {
                ...existingProduct, 
                variant_cost: editedVariantsFinal[variantId].variant_cost as string,
                currency: editedVariantsFinal[variantId].currency as string, 
                period_start: periodStart, 
                period_end: periodEnd, 
                updated_cost_at: updatedCostAt, 
                id: '',
            };
        }).filter(line => line !== null);

        const newDataProductCosts = {
            data: {
                data: [
                    ...dataProductCosts.data.data,
                    ...newLines 
                ]
            }
        };

        setDataProductCosts(newDataProductCosts as ProductsCostDTO);

        setEditedVariants({});
        showAlert('Alterações salvas com sucesso! Por favor, aguarde alguns segundos enquanto atualizamos os dados...', 'success' , 10000, true);
      
        if (setSelectAll) {
            setSelectAll(false);
        }

        if (setSelected) {
            setSelected([]);
        }

        onClose();
        
        if (toggleEditPerProduct){
            toggleEditPerProduct();
        }
    };
    
    //console.log("edited variants fora de handlesave", editedVariants);
    return (
        <S.Container>
            <Modal onClose={onClose}> 
                <S.MyMoreTime/>
                <S.Title>Defina o período para que os custos sejam aplicados.</S.Title>
                <S.OptionContainer selected={costApplication === 'next-orders'}>
                    <S.RadioInput
                        type="radio"
                        id="apply-next-orders"
                        name="cost-application"
                        value="next-orders"
                        checked={costApplication === 'next-orders'}
                        onChange={handleCostApplicationChange}
                    />
                    <S.Label style={{cursor: 'pointer'}} htmlFor="apply-next-orders">Aplicar apenas para os próximos pedidos</S.Label>
                </S.OptionContainer>

                <S.OptionContainer selected={costApplication !== 'next-orders'}>
                    <S.RadioInput
                        type="radio"
                        id="define-period"
                        name="cost-application"
                        value="define-period"
                        onChange={handleCostApplicationChange}
                        checked={costApplication !== 'next-orders'}
                    />
                    <S.Label style={{cursor: 'pointer'}} htmlFor="define-period">Definir período</S.Label>
                    {/* Tooltip (se necessário, pode ser um componente separado) */}
                </S.OptionContainer>

                {costApplication === 'define-period' && (
                    <S.DateSelector>
                        <S.Label >Início:</S.Label>
                        <S.DatePickerWrapper>
                            <Flatpickr
                                value={startDate}
                                onChange={([date]) => setStartDate(date)}
                                options={{ locale: 'pt', dateFormat: 'Y-m-d' }} 
                            />
                        </S.DatePickerWrapper>

                        <S.Label >Fim: <Tooltip title="Caso queira que o custo se propague indefinidamente, deixe este campo vazio." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <InfoRoundedIcon sx={{width: '14px', marginLeft: '8px', fill: '#7b9898'}}/>
                </Tooltip></S.Label>
                        <S.DatePickerWrapper>
                            <Flatpickr
                                placeholder='Propagar indefinidamente'
                                value={endDate}
                                onChange={([date]) => setEndDate(date)}
                                options={{ locale: 'pt', dateFormat: 'Y-m-d' }} 
                            />
                        </S.DatePickerWrapper>
                    </S.DateSelector>
                )}
            <S.ButtonArea>
                <ButtonApp
                border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<Check sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleSave}
                    text="Salvar"
                />
            </S.ButtonArea>

            </Modal>
        </S.Container>
        
    );
}

export default ModalSaveCosts;