import { InputStoreOnboardingFlowProps } from '../../../@types';
import * as S from './style';

export function InputStoreOnboarding({ inputId, inputName, inputType, required = false, width, inputLabel, setInputInformation, inputInformation, disabled }: InputStoreOnboardingFlowProps) {
    return (
        <S.Container width={width} disabled={disabled ?? false}>
            <input
                id={inputId}
                type={inputType}
                name={inputName}
                autoComplete="off"
                placeholder=" "
                onChange={(e) => setInputInformation(e.target.value)}
                value={inputInformation}
                autoFocus={false}
                required={required}
                disabled={disabled}
            />
            <label htmlFor={inputId}>{inputLabel}</label>
        </S.Container>
    );
}
