import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 29.6875rem;
    height: 13.25rem;
    border-radius: 12px;
    display: flex;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

type ProfitValueProps = {
    isNegative: boolean;
};

export const ProfitValue = styled.span<ProfitValueProps>`
font-size: 2.1rem;
display: flex;
font-weight: 600;
align-items: center;

p {
    font-weight: 600;
    line-height: 2.7rem;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 12.5rem;
}

color: ${(props) => props.isNegative ? 'var(--red-500)' : 'var(--green)'}; // Vermelho para negativo, preto para positivo
`;


export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
`;

export const Value = styled.div`
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 2.7rem;
    color: var(--green);
`;

export const Percentage = styled.div`
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-weight: 400;
    span {
        color: var(--green);
    }
`;

export const Analytics = styled.div`
    width: 10rem;
    padding: 0.1rem 0.4rem;
    height: 1.424375rem;
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #919191;
    margin-top: 0.7rem;
    // box-shadow: 10.286px 13.091px 35.16px 0px rgba(0, 0, 0, 0.02);
    span {
        font-size: 0.875rem;
        font-weight: 550;
        line-height: 1.05rem;
        color: var(--black);
        margin-right: 0.25rem;
    }
`;

export const AnalyticsContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    margin-left: 0.25rem;
`;

export const Content = styled.div`
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 15rem;
    align-items: space-between;
    justify-content: space-evenly;
    margin-top: 0.3rem;
    margin-left: 0.4rem;
`;

export const Graphic = styled.div`
    width: 15rem;
    
`;
