import { TableProductsProps } from '../../../@types';
import { LineProduct } from './LineProduct';
import { LineProductLoading } from './LineProductLoading';
import { useState, useEffect } from 'react';
import * as S from './style';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { getLineProducts } from '../utils';
import { Tooltip } from '@mui/material';

export function TableProducts({ data, isLoading, costMarketingValue, setCostOperationDate  }: TableProductsProps) {

    const [typeFiltred, setTypeFiltred] = useState('realProfit');
    const [filterDec, setFilterDec] = useState(false);

    const [metrisByProducts, setMetrisByProducts] = useState(getLineProducts(data));

    useEffect(() => {
        setMetrisByProducts(getLineProducts(data));
    }, [data]);

    // const metrisByProducts = getLineProducts(data);

    function handleSelectTypeFiltred(type: string) {

        if (typeFiltred === type) {
            setFilterDec(!filterDec);
        } else {
            setTypeFiltred(type);
            setFilterDec(true);
        }

    }

    const sortedProducts = metrisByProducts?.sort((a: any, b: any) => {
        const aValue = parseFloat(a[typeFiltred]) || 0;
        const bValue = parseFloat(b[typeFiltred]) || 0;
    
        return filterDec ? aValue - bValue : bValue - aValue;
    });

    return (
        <S.Container>
            <S.TableHeader>
                <S.Image></S.Image>
                <S.ProductName>Produto</S.ProductName>
                <S.DefaultColumnLucro>
                    Lucro | Margem 
                    {/* <Tooltip title="Lucro aprovado (Receita aprovada - gastos)." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                        <InfoRoundedIcon sx={{width: '15px', marginLeft: '0px', fill: '#7b9898'}}/>
                    </Tooltip> */}
                      
                    <S.ArrowIcon onClick={() => handleSelectTypeFiltred('realProfit')} isActive={typeFiltred === 'realProfit'} isDesc={!filterDec}>
                        <ArrowDown />
                    </S.ArrowIcon>
                </S.DefaultColumnLucro>
                <S.DefaultColumn>
                    Receita Total
                    <S.ArrowIcon onClick={() => handleSelectTypeFiltred('totalRevenue')} isActive={typeFiltred === 'totalRevenue'} isDesc={!filterDec}>
                        <ArrowDown />
                    </S.ArrowIcon>
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Receita Aprovada
                    <S.ArrowIcon onClick={() => handleSelectTypeFiltred('revenue')} isActive={typeFiltred === 'revenue'} isDesc={!filterDec}>
                        <ArrowDown />
                    </S.ArrowIcon>
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Receita Pendente
                    <S.ArrowIcon onClick={() => handleSelectTypeFiltred('pending_revenue')} isActive={typeFiltred === 'pending_revenue'} isDesc={!filterDec}>
                        <ArrowDown />
                    </S.ArrowIcon>
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Receita Cancelada
                    <S.ArrowIcon onClick={() => handleSelectTypeFiltred('cancelled_revenue')} isActive={typeFiltred === 'cancelled_revenue'} isDesc={!filterDec}>
                        <ArrowDown />
                    </S.ArrowIcon>
                </S.DefaultColumn>
                <S.Costs>Custo Mídia</S.Costs>
                <span></span>
                <S.Costs>Custo Produto</S.Costs>
                <S.Tax>Taxas</S.Tax>
                <S.Tax>Gateway</S.Tax>
                <S.Tax>Checkout</S.Tax>
            </S.TableHeader>
            <S.Lines>
                {!isLoading &&
                    sortedProducts?.length > 0 &&
                    sortedProducts?.map((product, index) => {
                        return <LineProduct key={index} product={product} costMarketingValue={costMarketingValue} setCostOperationDate={setCostOperationDate} />;
                    })}
                {isLoading && Array.from({ length: 8 }).map((_, index) => <LineProductLoading key={index} />)}
            </S.Lines>
        </S.Container>
    );
}

function ArrowDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
        </svg>
    );
}