import { AxiosResponse } from 'axios';

import { api } from '../api-confg';
import * as DTO from '../../@types';

const BASE_URL = import.meta.env.VITE_API_URL;

export async function getProductsAnalytics(start_date: string, end_date: string, company_id: string): Promise<DTO.ProductsAnalyticsDTO> {
    const data: DTO.ProductsAnalyticsDTO = await api.get(`${BASE_URL}/client-services/dashboard/products?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
    return data;
}

export async function updateProductCost(body: DTO.ProductCostUpdateDTO): Promise<AxiosResponse> {
    console.log("body", body);
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/products`, body);
    return data;
}

export async function getProducts(company_id: string): Promise<DTO.ProductsDTO> {
    const platform = 'shopify';
    const data: DTO.ProductsDTO = await api.get(`${BASE_URL}/client-services/io-handlers/products?company_id=${company_id}&platform=${platform}`);
    return data;
}

export async function getProductsAnalyticsList(company_id: string): Promise<DTO.ProductsAnalyticsDTO> {
    const data: DTO.ProductsAnalyticsDTO = await api.get(`${BASE_URL}/client-services/dashboard/products/list?company_id=${company_id}`);
    return data;
}

export async function getProductsCost(company_id: string): Promise<DTO.ProductsCostDTO> {
    const platform = 'shopify';
    const data: DTO.ProductsCostDTO = await api.get(`${BASE_URL}/client-services/io-handlers/products/costs?company_id=${company_id}&platform=${platform}`);
    return data;
}

export async function deleteProductCost(body: DTO.deleteProductCostDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/io-handlers/products/costs`, { data: body });
    return data;
}

export async function updateProductMarketingCost(body: DTO.ProductMarketingCostUpdateDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/products/marketing-costs`, body);
    return data;
}

export async function updateProductTag(body: DTO.ProductTagDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/products/tags`, body);
    return data;
}

export async function getProductsSyncCampaign(company_id: string): Promise<DTO.ProductsDTO> {
    const data: DTO.ProductsDTO = await api.get(`${BASE_URL}/client-services/io-handlers/products/campaigns?company_id=${company_id}`);
    return data;
}

export async function updateProductSyncCampaign(body: DTO.ProductSyncCampaignDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/products/campaigns`, body);
    return data;
}

// Ads

export async function getProductsSyncAd(company_id: string): Promise<DTO.ProductsDTO> {
    const data: DTO.ProductsDTO = await api.get(`${BASE_URL}/client-services/io-handlers/products/ads?company_id=${company_id}`);
    return data;
}

export async function updateProductSyncAd(body: DTO.ProductSyncAdDTO): Promise<DTO.ProductsDTO> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/io-handlers/products/ads`, body);
    return data;
}
