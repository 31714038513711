import { useEffect, useState } from 'react';
import * as S from './style';
import { ButtonApp } from '../../../../../components/ButtonApp';
import ShareIcon from '@mui/icons-material/Share';
import { formatPercentageInput } from '../../../../../utils';
import { useChangeTaxes } from '../../../../../services/hooks/taxHooks';
import useAuth from '../../../../../hooks/useAuth';
import { ModalTaxProps, userDTO } from '../../../../../@types';
import { TemplateModal } from '../../index';
import { InputIntegration } from '../../../../../components/InputIntegration';
import useAlert from '../../../../../hooks/useAlert';

export function ModalTax({setHasTaxConnected, hasTaxConnected, setShowPopupTax} : ModalTaxProps) {
    const urlLogo = 'https://i.ibb.co/wrV5sPw/martin.png';
    const baseColor = '#00c191';

    const { currentCompany, user, setUserContext } = useAuth();
    const [calculationRule, setCalculationRule] = useState('custo_nenhum');
    const [aliquoteRate, setAliquoteRate] = useState('0');
    const { mutate: changeTax, isLoading } = useChangeTaxes();
    const {showAlert} = useAlert();

    useEffect(() => {
        // acha o checkout manual  na lista de checkouts manuais
        const aliquoteRate = user?.companies?.[currentCompany]?.manual_inputs?.tax?.aliquote_rate; 
        const calculationRule = user?.companies?.[currentCompany]?.manual_inputs?.tax?.calculation_rule;

        if (calculationRule && aliquoteRate) {
            setCalculationRule(calculationRule);
            setAliquoteRate(aliquoteRate?.toString());
        }

    }, [user?.companies?.[currentCompany]?.manual_inputs?.tax]);


    const handleFormatValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatPercentageInput(event);
        setAliquoteRate(formattedValue);
    };

    
    const handleIntegrate = () => {
        const formattedAliquoteRate = parseFloat(aliquoteRate.replace(',', '.'));        
    
        const body = {
            calculation_rule: calculationRule,
            aliquote_rate: formattedAliquoteRate,
            company_id: currentCompany
        };

        const newTax = {aliquote_rate: formattedAliquoteRate, calculation_rule: calculationRule};

        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    manual_inputs: {
                        ...user?.companies?.[currentCompany]?.manual_inputs,
                        tax: newTax,
                    },
                },
            },
        };

        setUserContext(newUser as userDTO);
        changeTax(body, {
            onSuccess: () => {
            },
            onError: (error) => {
                // Ação após erro, e.g., mostrar mensagem de erro
                console.error(error);
            }
        });
        showAlert('Imposto configurado! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setHasTaxConnected(true);
        setShowPopupTax(false);
    };

    const handleDesintegrate = () => {
        const body = {
            aliquote_rate: 0,
            calculation_rule: '',
            company_id: currentCompany
        };

        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    manual_inputs: {
                        ...user?.companies?.[currentCompany]?.manual_inputs,
                        tax: {aliquote_rate: 0, calculation_rule: ''},
                    },
                },
            },
        };

        setUserContext(newUser as userDTO);
        changeTax(body, {
            onSuccess: () => {
            },
            onError: (error) => {
                // Ação após erro, e.g., mostrar mensagem de erro
                console.error(error);
            }
        });
        showAlert('Imposto desconectado! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 60000, true);
        setHasTaxConnected(false);
        setShowPopupTax(false);
    };

    const isIntegrate = hasTaxConnected;
    return (
        <S.Container>
              <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Tax'}
                title={'Impostos'}
                subtitle={'Defina e personalize as taxas de imposto para ajustar à sua operação.'}
                statusConnection={isIntegrate}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
            <S.SelectTitle>
                Incidência sobre:
            </S.SelectTitle>
            <S.SelectType>
                <select value={calculationRule} onChange={e => setCalculationRule(e.target.value)}>
                    <option value="custo_nenhum">Receita Líquida</option>
                    <option value="custo_marketing">Receita Líquida - Custo de Marketing</option>
                    <option value="custo_producao">Receita Líquida - Custo de Produto</option>
                    <option value="custo_producao_marketing">Receita Líquida - Custo de Produto - Custo de Marketing</option>
                </select>
            </S.SelectType>
            <InputIntegration
                    value={aliquoteRate}
                    setValue={handleFormatValue}
                    inputName="Taxa de aliquota"
                    inputId="percentualTax"
                    placeholder="0,00"
                    percentage={true}
                />      
            </S.InnerContainer>

            <S.DisconnectContainer>
                {!isIntegrate && (
                    <>
                        Salvar configurações:{' '}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleIntegrate}
                            text="Salvar"
                        />
                    </>
                )}
                {isIntegrate && (
                    <>
                        Limpar taxas de imposto:{' '}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#F41B19"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleDesintegrate}
                            text="Desconectar"
                        />
                    </>
                )}
            </S.DisconnectContainer>
        </S.Container>
    );
}