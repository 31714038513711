import React, { useEffect } from 'react';
import * as S from './style.ts';

import { LineAdModal } from './LineAdModal/index.tsx';
import { LineAdModalLoading } from './LineAdModalLoading/index.tsx';
import { AdLineInfo } from './LineAdModal/index.tsx';
import { OrderedMap } from 'immutable';


interface AdModalTableProps {
    ads: OrderedMap<string, AdLineInfo>;
    isLoading: boolean;
    setSyncAd: (checked: boolean, ad_id: any) => void;
}

const AdModal : React.FC<AdModalTableProps> = ({ ads, isLoading, setSyncAd }) => {

    return (
        <S.InnerContainer>
            <S.Header>
                <S.DefaultColumnCheck>
                    Selecionar
                </S.DefaultColumnCheck>
                <S.DefaultColumnCheck>
                    Nome do Canal
                </S.DefaultColumnCheck>
                <S.DefaultColumn>
                    Campanha
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Conjunto
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Anúncio
                </S.DefaultColumn>
                <S.DefaultColumnCheck>
                    Sincronização
                </S.DefaultColumnCheck>
            </S.Header>

            <S.Lines>
                {
                    ads.valueSeq().sortBy(ad => !ad.sync).map((ad, index) => {
                        return <LineAdModal 
                                    key={index}
                                    ad={ad}
                                    setSyncAd={setSyncAd}
                                />;
                    })
                }
                {isLoading && Array.from({ length: 5 }).map((_, index) => <LineAdModalLoading key={index}/> )}
            </S.Lines>

        </S.InnerContainer>
    );
};

export default AdModal;
