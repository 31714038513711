import * as S from './style';
import PieChartIcon from '@mui/icons-material/PieChart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GroupIcon from '@mui/icons-material/Group';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { GraphProps } from '../../../../@types';
import { getMetrics, getMetricsCosts, getMetricsOrdersAndRevenue, getMetricsShipping, getSubMetrics } from '../../../Dashboard/utils'
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import { toBrazilianFormat } from '../../../../utils';

export function Profit({ date, isLoading, data, taxes, dataGraphic, shipping, orders, ordersGraphics}: GraphProps) {
    const { currencySymbol } = useCurrencyType();
    const { metricTax, metricTaxAndFees } = getSubMetrics(data, taxes, orders, shipping);
    const { shipping_cost } = getMetricsShipping(orders, shipping);  // Calcula o custo de frete
    
    const approvedRevenueMetric = data?.approved_revenue_metric?.approved;
    const marketingCostMetric = data?.marketing_cost_metric?.total;
    const productsCostMetric = data?.products_cost_metric + shipping_cost;
    const operationCostMetric = data?.additional_cost_metric;
    const taxesAndFeesMetric = metricTaxAndFees;

    const realProfit = approvedRevenueMetric - (marketingCostMetric + productsCostMetric + operationCostMetric + taxesAndFeesMetric);

    const { approvedRevenue } = getMetricsOrdersAndRevenue(data);

    const profitMarginValue = approvedRevenue == 0 ? '0,00' : (realProfit / Number(approvedRevenue)) * 100;

    const { totalOrders, approvedOrders } = getMetricsOrdersAndRevenue(data);

    const { totalMarketingCost } = getMetricsCosts(data);

    const cpaValue = totalOrders == 0 ? '0,00' : (totalMarketingCost / approvedOrders).toFixed(2);

    const roiValue = totalMarketingCost == 0 ? '0,00' : approvedRevenue / totalMarketingCost;

    const metrics = getMetrics(dataGraphic, taxes, ordersGraphics, shipping, metricTax);

    // //console.log('data comparation graphic', dataGraphic, 'data normal', data)

    const newXAxis = metrics.xAxis;

    const newProfitLiquidChart = metrics.profitLiquidChart;


    if (isLoading) {
        return (
            <S.Container>
                <S.LoadingContainer />
            </S.Container>
        );
    }

    const formatValue = (value: number | undefined): string => {
        if (typeof value !== 'number' || isNaN(value)) {
            return '0,00';
        }
        return value.toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    };

    return (
        <S.Container>
            <S.Content>
                <div>
                    <S.Title>Lucro Líquido</S.Title>
                    <S.ProfitValue isNegative={realProfit < 0}>
                        <span style={{ fontSize: '0.6em' }}>{currencySymbol} </span>
                        {toBrazilianFormat(realProfit)}
                    </S.ProfitValue>
                </div>
                <div style={{display: 'flex'}}>
                <S.Analytics>
                    <S.AnalyticsContent>
                        <PieChartIcon sx={{ width: '10px', height: '10px' }} /> Margem
                    </S.AnalyticsContent>{' '}
                    <span>
                        {profitMarginValue === '0,00'
                            ? '0,00'
                            : profitMarginValue.toLocaleString('pt-BR', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                              })}
                        %
                    </span>
                </S.Analytics>
                <S.Analytics>
                    <S.AnalyticsContent>
                        <CurrencyExchangeIcon sx={{ width: '10px', height: '10px' }} />
                        ROI
                    </S.AnalyticsContent>

                    <span>{roiValue === '0,00' ? '0,00' : roiValue.toFixed(2)}x</span>
                </S.Analytics>
                <S.Analytics>
                    <S.AnalyticsContent>
                        <GroupIcon sx={{ width: '10px', height: '10px' }} />
                        CPA
                    </S.AnalyticsContent>

                    <span>
                        {currencySymbol}{' '}
                        {formatValue(Number(cpaValue))}
                    </span>
                </S.Analytics>
                </div>
            </S.Content>
            <S.Graphic>
            <ReactEcharts
                    style={{ height: '17rem', width: '100%', flex: '1' }}
                    option={getOption(newXAxis, newProfitLiquidChart)}
                />
            </S.Graphic>
        </S.Container>
    );
}


const getOption = (xAxis: number[], yAxis: number[]) => {
    const {currencySymbol} = useCurrencyType();

    return {
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
            formatter: function (params: any) {
                let result = params[0].name + '<br/>';
                
                let currencyTypeGraph = 'R$';

                if (currencySymbol === '$') {
                    currencyTypeGraph = 'USD';
                } else if (currencySymbol === 'R$'){
                    currencyTypeGraph = 'BRL';
                } else if (currencySymbol === '€') {
                    currencyTypeGraph = 'EUR';
                } else if (currencySymbol === 'AU$') {
                    currencyTypeGraph = 'AUD';
                } else if (currencySymbol === '£') {
                    currencyTypeGraph = 'GBP';
                } else if (currencySymbol === 'COP') {
                    currencyTypeGraph = 'COP';
                } else if (currencySymbol === 'ARS') {
                    currencyTypeGraph = 'ARS';
                } else if (currencySymbol === 'C$') {
                    currencyTypeGraph = 'CAD';
                } else if (currencySymbol === '¥') {
                    currencyTypeGraph = 'JPY';
                } else if (currencySymbol === '¥') {
                    currencyTypeGraph = 'JPY';
                }

                // const currencyTypeGraph = currencySymbol === 'R$' ? 'BRL' : 'USD';

                params.forEach(function (item) {
                    const color = item.value < 0 ? '#E90E0E' : '#19c27e'; // Define cor baseado no valor
                    result +=
                        '<span style="display:inline-block;margin-right:5px;border-radius:3px;width:10px;height:10px;background-color:' +
                        color +
                        ';"></span>' +
                        item.seriesName +
                        ': <span style="color:' + color + '; font-weight: 550;">' +
                        item.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: currencyTypeGraph,
                        }) +
                        '</span><br/>';
                });
                return result;
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                name: 'Lucro Líquido',
                data: yAxis,
                type: 'line',
                smooth: false,
                symbol: 'none',
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            0.8,
                            [
                                {
                                    offset: 1,
                                    color: 'white',
                                },
                                {
                                    offset: 0.5,
                                    color: '#19c27e',
                                },
                            ],
                            false
                        ),
                        shadowColor: 'rgba(0, 0, 0, 0.1)',
                        shadowBlur: 10,
                    },
                },
                itemStyle: {
                    normal: {
                        color: '#19c27e',
                        borderColor: 'rgba(25, 194, 126, 0.2)',
                        borderWidth: 12,
                    },
                },
            },
        ],
    };
};
