import { submitBillingAccount, getBillingAccount } from '../api/api-billing-account';
import { useQuery, useMutation } from 'react-query';

export function useSubmitBillingAccount() {
    return useMutation(submitBillingAccount);
}

export function useGetBillingAccount(company_id: string) {
    return useQuery(['getBillingAccount', company_id], () => getBillingAccount(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
