import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
    margin-bottom: 4rem;
`;

export const CostInformation = styled.div`
    width: 18.75rem;
    height: 28.125rem;
    border: 1px solid #e7e7e7;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const ContainerPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
`;

export const CostHistoric = styled.div`
    overflow-y: auto;
    height: 27.6rem;
    width: 100%;
`;

export const HeaderTable = styled.div`
display: flex;
position: relative;
gap: 0.5rem;
top: -1rem;
/* padding: 20px; */
/* right: 23px; */
justify-content: space-between;
padding: 0.4rem 0.6rem;

`;


export const CostEntry = styled.div`
    width: 99.5%;
`;

export const CostContainer = styled.div`
    width: 18.75rem;
    
    height: 35rem;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    flex: 1;
    position: relative;
    padding-top: 1.5rem;
`;
export const InnerContainer = styled.div`
    display: flex;
    margin-top: 2rem;
`;

type TabProps = {
    active: boolean;
};

const Tab = styled.div<TabProps>`
    font-family: 1.125rem;
    font-weight: 650;
    line-height: 1.6875rem;
    position: absolute;
    color: #c0c0c0;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    &:hover {
        color: #19c380;
        transform: translateY(-5px);
        transition: ease-in-out 0.2s;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #19c380;
        margin-left: 25%;
        transition: width 0.3s ease-in-out;
    }
    ${(props) =>
        props.active &&
        `

        color: #19c380;
        transform: translateY(-5px);
        transition: ease-in-out 0.2s;

        &::after {
            width: 50%;
            margin-left: 25%;
            color: #19c380;
        }

    `}

    &:hover::after {
        width: 50%;
        margin-left: 25%;
        color: #19c380;
    }
`;

export const EntryTab = styled(Tab)`
    left: 2.25;
    top: 1.2rem;`;

export const HistoricTab = styled(Tab)`
    left: 7.25rem;
    top: 1.2rem;`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 5rem;
    input {
        font-size: 0.75rem;
        border: 1px solid #ccc;
        height: 18.5rem;
        margin: 0 1rem;
        line-height: 1.125rem;
        padding: 0 0.5rem;
        border-radius: 10px;
        color: var(--black);
        &::placeholder {
            color: #757575;
        }
    }

    input[type='date'] {
        padding: 0 0.75rem;
    }

    select {
        font-size: 0.75rem;
        border: 1px solid #ccc;
        height: 2.5rem;
        height: 14.5rem;
        margin: 0 1rem;
        padding: 0 0.5rem;
        line-height: 1.125rem;
        border-radius: 10px;
        color: var(--black);
       
    }

    label {
        font-size: 0.8rem;
        color: gray;
        line-height: 1.03125rem;
        font-weight: 550;
        margin: 0.5rem;
    }
`;

export const ButtonContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 3.5rem;

`;


export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`


