import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 2rem;
  cursor: pointer;
  color: var(--black);
`;

export const StyledCheckbox = styled.input`
  width: 1rem;
  height: 1.1rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;

  &:checked {
    background: var(--black);
    border-color: var(--black);
  }

  &:checked::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 5px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const CheckboxLabel = styled.p`
  font-size: 1rem !important;
  font-weight: 500;
  color: var(--black);
  margin: 0;
`;