import styled from 'styled-components';

export const Container = styled.div`
    min-width: 220rem;
    display: flex;
    position: relative;
`;

type MainColumnProps = {
    type: string | any[];
    isWithoutAttribution?: boolean;
};

export const AnalyticsButton = styled.div`
    min-width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const CampaignTitleContainer = styled.div` 
    display: flex;
    width: 100%;
    flex-direction: column;
`
export const ChannelSelectedContainer = styled.div<{hasCatalog?: boolean}>` 
display: flex;
font-size: 13px;
color: #9e9e9e;
align-items: center;
// margin-top: 0.3rem;
flex-direction: row;

p {
    text-wrap: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: ${(props) => props.hasCatalog ? '6rem' : 'none'};
}
`

export const UrlLinkClick = styled.span`
    span {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: var(--green);
        }
    }
`;

export const CampaignSourceIcon = styled.div `
`


export const CheckBoxArea = styled.div`
    min-width: 1.1rem;
    max-width: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
     border-right: 1px solid #dedede;
    // cursor: pointer;
    height: 4.2rem;
    margin-left: -2.85rem;
    margin-right: 0.5rem;
    padding-right: 19px;
    // padding-left: 8px;
`;

export const StatusArea = styled.div`
    min-width: 2.6rem;
    max-width: 2.6rem;
    display: flex;
    padding-right: 0.9rem;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dedede;
    height: 4.2rem;
    // cursor: pointer;
    // padding-left: 2.5px;
    // padding-right: 19.1px;
`;

export const ActionArea = styled.div`
margin-left: 0.5rem;
width: fit-content;
position: absolute;
display: flex;
align-items: center;
z-index: 10;
right: 0;
padding: 15px;
padding-left: 28px;
padding-right: 6px;
background: inherit;

>svg{
    cursor: pointer;
    fill: #B0AFAF;
    width: fit-content;
    padding-right: 6px;
    &:hover {
        fill: #1C343A;
    }
}
`

export const MainColumn = styled.div<MainColumnProps>`
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--black);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4rem;
    padding-right: 10.5rem;
    // width: 29.125rem;
    height: 3.3rem;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    gap: 0.5rem;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    img {
            padding-left: 0.5rem;
        width: 1.150rem;
        height: 1.1rem;
        
    }
    span {
        font-size: 0.8rem;
        font-weight: 550;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: var(--green);
        }
    }
`;

export const DefaultColumn = styled.div`
    font-size: 0.835rem;
    font-weight: 500;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;

export const ContainerAccount = styled(Container)``;

export const MetricsButton = styled.div`
  width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--green);
    }
`

export const MainColumnAccount = styled(MainColumn)`
    padding-left: 4rem;
    padding-right: 10.5rem;
    width: 29.125rem;

    img {
        height: 1rem;
        width: auto;
        margin-left: 3rem;
    }
    span {
        height: 100%;
        width: 1px;
        background-color: var(--gray-150);
        margin-left: -2rem;
        ${(props) => props.isWithoutAttribution && 'margin-right: 1.8rem;'}
    }
`;

export const AnalyticsButtonAccount = styled.div`
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: 1.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        color: var(--green);
        line-height: 1.6875rem;
    }
`;

export const AdsAccountsTitle = styled.div`
    background-color: #e6e6e6;
    color: #527566;
    width: 6.8rem;
    height: 0.96875rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    font-weight: 600;
`;

export const ProductNameContainer = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.05rem;
    color: #081b16;
    position: relative;
    width: 16px;
    padding-right: 0.5rem;

    svg {
        width: 1rem;
        height: 1rem;
        fill: #E90E0E;
        margin-top: 0.4rem;
        padding-right: 14px;
        margin-left: 0.25rem;
    }
`;

type CopyButtonProps = {
    show: boolean;
};

export const CopiedPopup = styled.div<CopyButtonProps>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 20px; /* Rounded border */
    font-size: 14px;
    z-index: 999; 
    opacity: ${props => props.show ? 1 : 0}; /* Start with opacity 0 */
    transition: opacity 0.5s, transform 0.5s ease-out; /* Transition for opacity and transform */
    transform: translateY(${props => props.show ? 0 : 20}px); /* Start from bottom, then move up */
`;


export const CatalogIcon = styled.div`
    padding: 0 0.5rem;
    display: flex;
    gap: 0.3rem;
    margin-left: 0.5rem;
    height: 1.2rem;
    background-color: #d2e3d3;
    color: #647c67;
    font-weight: 550;
    border-radius: 50px;
    font-size: 0.7rem;
    align-items: center;
    justify-content: space-between;
    transition: all ease-in-out 0.2s;

     &:hover {
    background-color: #c1d4c2;
    cursor: pointer;
        transition: all ease-in-out 0.2s;

    }

    svg {
    width: 0.8rem;}
`