import { ButtonProps } from '../../@types';
import * as S from './style';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';

export function ButtonApp({
    border,
    iconWidth,
    borderRadius,
    width,
    height,
    color,
    background,
    text,
    icon,
    onClick,
    disabled = false,
    fontSize = '0.75rem',
    lineHeight = 'normal',
    fontWeight = '550',
    isLoading = false,
    hoverBackground,
}: ButtonProps) {
    if (isLoading) {
        return (
            <S.Container border={border} borderRadius={borderRadius} width={width} height={height} background={background} disabled={disabled || isLoading} isLoading={isLoading} hoverBackground={hoverBackground}> 
                <S.Content color={color} iconWidth={iconWidth} isLoading={isLoading}>
                    <CachedSharpIcon />
                    <S.Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight}>
                        Carregando...
                    </S.Text>
                </S.Content>
            </S.Container>
        );
    }

    return (
        <S.Container borderRadius={borderRadius} hoverBackground={hoverBackground} border={border} width={width} height={height} background={background} disabled={disabled || isLoading} onClick={onClick}>
            <S.Content iconWidth={iconWidth} color={color}>
                {icon ? icon : null}
                <S.Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight}>
                    {text}
                </S.Text>
            </S.Content>
        </S.Container>
    );
}
