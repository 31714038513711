import { LineRegionAnalysisProps } from '../../../../../@types';
import * as S from './style';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';

export function LineRegionAnalysis({ name, revenue, orders, share }: LineRegionAnalysisProps) {
    const { currencySymbol } = useCurrencyType();

    return (
        <S.Container>
            <S.MainColumn>
                <FmdGoodOutlinedIcon sx={{ height: '14px', width: '14px', color: '#19c27e' }} /> {name}
            </S.MainColumn>
            <S.Column>
                {currencySymbol}{' '}
                {revenue?.toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </S.Column>
            <S.Column>{orders}</S.Column>
            <S.Column>{share}%</S.Column>
        </S.Container>
    );
}
