import styled, { keyframes } from 'styled-components';
import React, { useState } from 'react';



export const Container = styled.div`
    background-color: var(--white);
    width: 12.6875rem;
    height: 6.875rem;
    border-radius: 10px;
    padding: 1rem 0.8125rem;
`;

export const ContainerLoading = styled.div`
    width: 14.3125rem;
    height: 8.875rem;
    border-radius: 10px;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

type TitleProps = {
    showTax: boolean;
};

export const Title = styled.div<TitleProps>`
    position: relative; 
    font-size: 0.8125rem;
    color: #676767;
    font-weight: 500;
    line-height: 1.21875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;

    ${(props) => props.showTax && `
        // cursor: pointer;
        &:hover .tooltip {
            display: block;
        }
    `}

    span {
        svg {
            width: 0.875rem;
            height: 0.875rem;
            fill: #E90E0E;
            margin-left: 0.2rem;
            margin-bottom: -0.2rem;
        }

        // &:hover::after {
        //     content: "Atenção! Você teve vendas de produtos que não possuem custo configurado. Clique para configurar.";
        //     position: absolute;
        //     bottom: 100%; 
        //     left: 50%; 
        //     transform: translateX(-50%); 
        //     background-color: white;
        //     color: #1e1e1e;
        //     padding: 0rem 0rem 0.5rem 0.5rem;
        //     width: 15rem;   
        //     height: 3rem;
        //     border: 1px solid #e3e3e3;
        //     border-radius: 6px;
        //     font-size: 0.7125rem;
        //     font-weight: 550 !important;
        //     font-family: 'Montserrat';
        //     z-index: 1; 
        //     pointer-events: none;
        // }
    }
`;

export const Tooltip = styled.div<{ top: number; left: number; showTooltip }>`
    position: absolute;
    display: none;
    padding: 10px;
    z-index: 999999;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    color: #666;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
    font-family: "Microsoft YaHei";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    
    transition-behavior: normal, normal, normal;
    transition-delay: 0s, 0s, 0s;
    transition-duration: 0.2s, 0.2s, 0.4s;
    transition-property: opacity, visibility, transform;
    trnasition-timing-function: cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1), cubic-bezier(0.23, 1, 0.32, 1);
`;

export const CircleIndicator = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    margin-right: 0.5rem;

    svg {
        width: 10px;
        height: 10px;
    }

    img {
        width: 10px;}
`;

export const Value = styled.div`
    font-weight: 550;
    align-items: baseline;
    display: flex;
    line-height: 2.025rem;
    color: var(--black);
    margin-top: 0.3rem;
    gap: 0.2rem;

    span {
        font-size: 1.6rem;
        font-weight: 550;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 12rem;
        }

    p {
        font-size: 0.9rem;
        font-weight: 550;
        line-height: 1.3125rem;
        }
`;

type PercentageProps = {
    showUp: boolean;
};

export const Percentage = styled.div<PercentageProps>`
    font-size: 0.875rem;
    color: #6f7e77;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-weight: 400;
    span {
        ${(props) => (props.showUp ? 'color: var(--green);' : 'color: #dc143c;')}
    }
`;

export const Button = styled.div`
    width: 5.75rem;
    height: 1.4375rem;
    border-radius: 50px;
    color: #cecece;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    font-weight: 500;
    margin-top: 0.65rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
        margin-left: 0.25rem;
    }
    border: 1px solid #cecece;
    &:hover {
        transform: translateX(3px);
        transition: all 0.2s ease-in-out;
        color: var(--black);
        border: 1px solid transparent;
    }
`;

// Coloca o ícone antes do texto add
export const RedirectButton = styled.button`
    border: none;
    padding: 6px;
    display: flex;
    border-radius: 7px;
    color: #6c7d77;
    background-color: #f8faf9;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 250ms;
    cursor: pointer;

    &:hover {
        background-color: #e9efed;
        color: #495752;
        transform: translateY(-3px); // Usei translateY(-3px) porque parece ser um efeito de "levantar" o botão
    }

    svg {
        font-size: 16px;
    }

`;

export const Footer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

export const ShopifyIcon = styled.div`
    padding: 0.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50px;
    background-color: #f8f8f8;
    // border: 1px solid #cecece;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    justify-content: center;

    img {
        width: 1rem;
        height: 1rem;}
`

export const DataDivisionsArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

export const DataDivision = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    // padding-bottom: 0.5rem;
    // border-bottom: 1px solid #f8f8f8;

    svg {
        width: 1.1rem;
        margin-right: 0.5rem;
        height: 1.1rem;
        }

    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        }

    div {
        display: flex;
        align-items: center;
        
        p {
            font-size: 0.8rem;
            font-weight: 550;
        } 
    }
`;