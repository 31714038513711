import { useMutation } from 'react-query';
import { createUser, getToken, loginUser, resetPasswordUser, revalidateUser} from './../api/api-auth';

export function useCreateUser() {
    return useMutation(createUser);
}

export function useLoginUser() {
    return useMutation(loginUser);
}

export function useResetPassword() {
    return useMutation(resetPasswordUser);
}

export function useUpdateUser() {
    return useMutation(revalidateUser);
}

export function useGetToken() {
    return useMutation(getToken);
}