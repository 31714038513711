import * as S from './style';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { GraphProps } from '../../../../@types';
import { getMetricsOrdersAndRevenue } from '../../utils';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import { Tooltip } from '@mui/material';

export function Revenue({ isLoading, data }: GraphProps) {
    const { totalRevenue, approvedRevenue, pendingRevenue, cancelledRevenue, totalOrders, approvedOrders, pendingOrders, cancelledOrders } = getMetricsOrdersAndRevenue(data);

    const percentualApprovedRevenue = totalRevenue == 0 ? '0.00' : Number((approvedRevenue / totalRevenue) * 100).toFixed(2);
    const percentualPendingRevenue = totalRevenue == 0 ? '0.00' : Number((pendingRevenue / totalRevenue) * 100).toFixed(2);
    const percentualCancelledRevenue = totalRevenue == 0 ? '0.00' : Number((cancelledRevenue / totalRevenue) * 100).toFixed(2);
    const { currencySymbol } = useCurrencyType();

    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />
            </S.ContainerLoading>
        );
    }

    const formatValue = (value: number | undefined): string => {
        if (typeof value !== 'number' || isNaN(value)) {
            return '0,00';
        }
        return value.toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    };


    return (
        <S.Container>
            <S.Title>Receitas e Vendas</S.Title>
            <S.Header>
                    <S.Column>Tipo</S.Column>
                    <S.Column>Receita</S.Column>
                    <S.Column>Vendas</S.Column>
                    <S.Column>% Vendas</S.Column>
            </S.Header>

            <S.Line/>

            <S.ContainerLines>
            <S.MainColumn>
                <AttachMoneyIcon sx={{ width: '12px', height: '12px', color: '#b3b3b3' }} />
                <p>Total</p>
            </S.MainColumn> 
            <S.ColumnLines>
               
                        <span style={{color: '#999', fontSize: '0.7rem', marginRight: '0.2rem'}}>{currencySymbol}{' '}</span>
                        {formatValue(totalRevenue)}
                   
            </S.ColumnLines>
            
            <S.ColumnLines>
                <Tooltip title="Vendas totais" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <span>{isNaN(totalOrders) ? "0" : totalOrders }</span>
                </Tooltip>
            </S.ColumnLines>
            <S.ColumnLines>
            <Tooltip title={100+ '%'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                <S.Bar>
                    <S.BarValue percentage={'100'} />
                </S.Bar>
            </Tooltip>
            </S.ColumnLines>
            </S.ContainerLines>
            <S.ContainerLines>
            <S.MainColumn>
                <CheckIcon sx={{ width: '12px', height: '12px', color: '#27b927' }} />
                <p>Aprovada</p>
            </S.MainColumn>
            <S.ColumnLines>
               
                        <span style={{color: '#999', fontSize: '0.7rem', marginRight: '0.2rem'}}>{currencySymbol}{' '}</span>
                        {formatValue(approvedRevenue)}
                   
            </S.ColumnLines>
            
            <S.ColumnLines>
                <Tooltip title="Vendas aprovadas" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <span>{isNaN(approvedOrders) ? "0" : approvedOrders }</span>
                </Tooltip>
            </S.ColumnLines>
            <S.ColumnLines>
            <Tooltip title={percentualApprovedRevenue + '%'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>

                <S.Bar>
                    <S.BarValue percentage={percentualApprovedRevenue} color="#27b927"  />
                </S.Bar>
            </Tooltip>
            </S.ColumnLines>
            </S.ContainerLines>
            
            <S.ContainerLines>
            <S.MainColumn>
                <PendingOutlinedIcon sx={{ width: '12px', height: '12px', color: '#f8c030' }} />
                <p>Pendente</p>
            </S.MainColumn>
            <S.ColumnLines>
               
                        <span style={{color: '#999', fontSize: '0.7rem', marginRight: '0.2rem'}}>{currencySymbol}{' '}</span>
                        {formatValue(pendingRevenue)}
                   
            </S.ColumnLines>
            
            <S.ColumnLines>
                <Tooltip title="Vendas totais" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <span>{isNaN(pendingOrders) ? "0" : pendingOrders }</span>
                </Tooltip>
            </S.ColumnLines>
            <S.ColumnLines>
            <Tooltip title={percentualPendingRevenue + '%'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>

                <S.Bar>
                    <S.BarValue percentage={percentualPendingRevenue} color="#f8c030" />
                </S.Bar>
            </Tooltip>
            </S.ColumnLines>
            </S.ContainerLines>
            <S.ContainerLines>
            <S.MainColumn>
                <CloseOutlinedIcon sx={{ width: '12px', height: '12px', color: '#f35757' }} />
                <p>Cancelada</p>
            </S.MainColumn>
            <S.ColumnLines>
               
                        <span style={{color: '#999', fontSize: '0.7rem', marginRight: '0.2rem'}}>{currencySymbol}{' '}</span>
                        {formatValue(cancelledRevenue)}
                   
            </S.ColumnLines>
            
            <S.ColumnLines>
                    <span>{isNaN(cancelledOrders) ? "0" : cancelledOrders }</span>
            </S.ColumnLines>
            <S.ColumnLines>
            <Tooltip title={percentualCancelledRevenue + '%'} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                <S.Bar>
                    <S.BarValue percentage={percentualCancelledRevenue} color="#f35757" />
                </S.Bar>
            </Tooltip>
            </S.ColumnLines>
            </S.ContainerLines>
        </S.Container>
    );
}
