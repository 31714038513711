import { createContext, useEffect, useState } from 'react';
import { AuthContextDataProps, AuthContextProviderProps, StorageAuthTokenProps, userDTO } from '../@types';
import { storageAuthTokenGet, storageAuthTokenRemove, storageAuthTokenSave } from '../storage/storageAuthToken';
import { storageUserGet, storageUserRemove, storageUserSave } from '../storage/storageUser';
import { api } from '../services/api-confg';
import { storageCurrentUserGet, storageCurrentUserRemove, storageCurrentUserSave } from '../storage/storageCurrentUser';
import { useUpdateUser } from '../services/hooks/authHooks';
import { storagePlaceholderCompanySave } from '../storage/storageCompanyToOnboard';
import { trackEvent  } from '../services/segment/sendEvent';

export const AuthContext = createContext<AuthContextDataProps>({} as AuthContextDataProps);

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
    const [user, setUser] = useState<userDTO>({} as userDTO);
    const [token, setToken] = useState<StorageAuthTokenProps>({} as StorageAuthTokenProps);
    const [currentCompany, setCurrentCompany] = useState<string>('');

    const { mutate: handleUpdateUser } = useUpdateUser()

    function updateUser() {

        handleUpdateUser(void 0, {
            onSuccess: ({data}) => {
                setUserContext(data?.data?.user)
                setTokenContext({
                    access_token: data?.data?.data?.access_token?.access_token,
                    session_token: data?.data?.data?.cache?.session_token,
                })
            },
        })

    }
    

    function setUserContext(user: userDTO) {
        setUser({...user});
        storageUserSave(user);
        api.defaults.headers.common['User-Id'] = user.user_id;
    }

    function setTokenContext({ access_token, session_token }: StorageAuthTokenProps) {
        setToken({ access_token, session_token });
        storageAuthTokenSave({ access_token, session_token });
        api.defaults.headers.common['Authorization'] = access_token;
    }

    function setCurrentCompanyContext(currentCompany: string) {
        setCurrentCompany(currentCompany);
        storageCurrentUserSave(currentCompany);
    }

    function signOut() {
        trackEvent('logout', {
            userId: user.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            console.log("success")
        }).catch((err) => {
            console.log(err)
        })

        window.location.href = '/login';
        setUser({} as userDTO);
        setToken({} as StorageAuthTokenProps);
        storageAuthTokenRemove();
        storageUserRemove();
        setCurrentCompany('');
        storagePlaceholderCompanySave('');
        storageCurrentUserRemove();
        api.defaults.headers.common['Authorization'] = '';
        api.defaults.headers.common['User-Id'] = '';
    }

    function loadAndUpdateTokenAndUser() {
        const localToken = storageAuthTokenGet();
        const localUser = storageUserGet();
        const localCurrentUser = storageCurrentUserGet();
        if (localToken) {
            setToken(localToken);
            api.defaults.headers.common['Authorization'] = localToken?.access_token;
        }
        if (localUser) {
            setUser(localUser);
            api.defaults.headers.common['User-Id'] = localUser.user_id;
        }
        if (localCurrentUser) setCurrentCompany(localCurrentUser);
    }

    useEffect(() => {
        loadAndUpdateTokenAndUser();
    }, []);

    useEffect(() => {
        const subscribe = api.registerInterceptTokenManager(signOut);

        return () => {
            subscribe();
        };
    }, [signOut]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUserContext,
                token,
                setTokenContext,
                currentCompany,
                setCurrentCompanyContext,
                signOut,
                updateUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
