import * as S from './style';
import Skeleton from '@mui/material/Skeleton';

export function LineTableOrdersLoading() {
    return (
        <S.Container>
            <S.Date>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Date>
            <S.Payment>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Payment>
            <S.Value>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Value>
        </S.Container>
    );
}
