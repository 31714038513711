import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 0.25rem 0;
    padding: 0 2rem 0;
    position: relative;
`;

export const Title = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 5px;
    line-height: 1.125rem;
    color: var(--green);
    margin-bottom: 0.25rem;
`;

export const Text = styled.div`
    font-size: 1.09375rem;
    line-height: 1.3125rem;
    color: var(--white);
    padding-bottom: 1rem;
`;

type LineProps = {
    isActive: boolean;
};

export const Line = styled.div<LineProps>`
    height: 3px;
    width: 7.4375rem;

    ${(props) =>
        props.isActive
            ? `
background: linear-gradient( 90deg, #19c27e 15.07%, #fff 117.59% );
opacity: 1;
`
            : `
background: var(--gray-190);

opacity: 0.3;
`}
`;

export const LineSection = styled.div`
    display: flex;
    gap: 1rem;
`;

const Circle = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    background: transparent;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const CircleLeft = styled(Circle)`
    position: absolute;
    left: 0;
    top: 2rem;
`;

export const CircleRight = styled(Circle)`
    position: absolute;
    right: 0;
    top: 2rem;
`;
