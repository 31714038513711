import styled from 'styled-components';

export const ModalWindow = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    background-color: rgba(63, 63, 63, 0.66);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(4px);
        /* backdrop-filter: blur(4px); */
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

export const ModalOverlay = styled.div`
    margin: 0.5rem;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1100px) {
        margin-top: -2.7rem;
        transform: scale(0.8);
    }

    @media (max-width: 585px) {
        margin: 0;
        width: 100%;
        margin-left: -0.8rem;
    }

    // @media (min-width: 1600px) {
    //     transform: scale(1.3)
    // }

    // @media (min-width: 2000px) {
    //     transform: scale(1.6)
    // }
`;
