import { format } from 'date-fns';
import * as S from './style'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { reduceString, toBrazilianFormat } from '../../../../../utils';
import useVisibility from '../../../../../hooks/useVisibilityChange';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';

export function LineClientSalesModal({order}: {order: any}) {
    const {currencySymbol} = useCurrencyType();

    const { isVisible } = useVisibility();
    const urlLinkBoleto = 'https://storage.googleapis.com/static-images-source/billet-icon.png';
    const urlLinkCartao = 'https://storage.googleapis.com/static-images-source/mastercard-icon.png';
    const urlLinkPix = 'https://storage.googleapis.com/static-images-source/pix-icon.png';
    
    let url = '';
    let name = '';

    switch (order?.payment_method) {
        case 0:
            url = '';
            name = 'Desconhecido';
            break;
        case 1:
            url = urlLinkCartao;
            name = 'Cartão de crédito';
            break;
        case 3:
            url = urlLinkBoleto;
            name = 'Boleto';
            break;
        case 4:
            url = urlLinkPix;
            name = 'Pix';
            break;
    }

    const ClientName = isVisible ? order?.first_name : '* * * * * * *';
    const DateOrder = format(order?.date, 'dd/MM/yyyy HH:mm')
    const OrderId = '#' + order?.order_name
    const ProductPrice = toBrazilianFormat(order?.product_price)
    const Tax = toBrazilianFormat(order?.checkout_fee + order?.gateway_fee)
    const Gateway = toBrazilianFormat(order?.gateway_fee)
    const Checkout = toBrazilianFormat(order?.checkout_fee)
    const ProductCost = toBrazilianFormat(order?.product_cost)
    

    return(
        <S.Container>
            <S.MainColumn>{OrderId}</S.MainColumn>
            <S.DefaultColumn>
            {order?.status_name === 'approved' && (
                    <S.Approved>
                        <CheckCircleOutlineIcon sx={{ width: '14px', height: '14px' }} /> Aprovado
                    </S.Approved>
                )}
                {order?.status_name === 'pending' && (
                    <S.Pending>
                        <PendingOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Pendente
                    </S.Pending>
                )}
                {order?.status_name === 'cancelled' && (
                    <S.Cancelled>
                        <CancelOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Cancelado
                    </S.Cancelled>
                )}
            </S.DefaultColumn>
            <S.DefaultColumn>{DateOrder}</S.DefaultColumn>
            
            <S.DefaultColumn>{reduceString(ClientName, 16)}

            </S.DefaultColumn>
            <S.DefaultColumn>

            <img src={url} /> {name}
            
            </S.DefaultColumn>
            <S.DefaultColumn>{currencySymbol} {ProductPrice}</S.DefaultColumn>
            <S.DefaultColumn>{currencySymbol} {ProductCost}</S.DefaultColumn>
            <S.DefaultColumn>{currencySymbol} {Tax}</S.DefaultColumn>
            <S.DefaultColumn>{currencySymbol} {Gateway}</S.DefaultColumn>
            <S.DefaultColumn>{currencySymbol} {Checkout}</S.DefaultColumn>

        


        </S.Container>
    )
}