import useAlert from '../../hooks/useAlert';
import useAuth from '../../hooks/useAuth';
import { changeShipping, getShipping } from '../api/api-shipping';
import { useQuery, useMutation } from 'react-query';

export function useChangeShipping(calculationType: string) {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(changeShipping, {
        onSuccess: () => {
                if (calculationType === 'custo_nenhum') {
                    showAlert('Os custos de frete foram limpos com sucesso!', 'success', 5000, true);
                } else {
                    showAlert('Frete atualizado com sucesso!', 'success', 5000, true);
                }
                updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
                showAlert('Falha ao atualizar o frete. Tente novamente.', 'error', 5000, true);
                updateUser();
        },
    });
}


export function useGetShipping(company_id: string) {
    return useQuery(['getShipping', company_id], () => getShipping(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
