import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    height: 3.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.83rem;
    font-weight: 500;
    color: var(--black);
    &:hover {
        background-color: #f1f1f1;
    }
`;

type MainLineProps = {
    type: string;
};

function getFilter(type: string) {
    switch (type) {
        case 'Fiéis':
            return '';
        case 'Novatos':
            return 'filter: saturate(0)';
        case 'Em risco':
            return 'filter: saturate(0.5) hue-rotate(334deg);';
    }
}

export const MainLine = styled.div<MainLineProps>`
    img {
        width: 0.9375rem;
        height: 0.9375rem;
        ${(props) => getFilter(props.type)};
    }
    font-weight: 500;
    color: var(--green);
    font-size: 0.875rem;
    display: flex;
    gap: 1rem;
    height: 3.75rem;
    align-items: center;
    justify-content: flex-start;
    width: 33.33%;
    padding-left: 1rem;
    cursor: pointer;
`;

export const SecondaryLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 16.66%;
    height: 3.75rem;
    padding-left: 1rem;
    border-left: 1px solid var(--gray-225);
`;

const slideright =  keyframes`
    from {
        transform: translateX(-5px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const Button = styled.button`
    width: 2.9375rem;
    height: 2.3125rem;
    border: 1px solid var(--gray-225);
    border-radius: 10px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: rgb(230, 230, 230);
        transition-duration: 0.2s;
        animation: ${slideright} 0.6s ease-out both;
    }
`;

type OptionProps = {
    showOption: boolean;
};

export const Option = styled.div<OptionProps>`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
    width: 240px;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0; 
    max-height: 0;
    position: absolute;
    z-index: 9999;
    margin-top: 8rem;

    ${(props) =>
        props.showOption
            ? `
                max-height: 500px;
                position: absolute;
                z-index: 50;
                opacity: 1;
            `
            : `
            `}
`;

export const OptionBtn = styled.div`
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    border-radius: 5px; 

    &:hover {
        border-radius: 5px;
        background-color: #e7e7e7;
    }
`;


