import { LineTableTrackingCampaignProps } from '../../../../@types';
import * as S from './style';
import { reduceString, toBrazilianFormat } from '../../../../utils';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAuth from '../../../../hooks/useAuth';
import { calculateFinalOrders, calculateFinalRevenue, checkUtm, getAdsIdByCampaingId } from '../../utils';
import { useState, useEffect, useMemo } from 'react';
import { getAdsDataWarning } from '../../utils';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { getIconForPlatform } from '../../utils';
import { Checkbox } from '@mui/material';
import { useCheckboxTracking } from '../../../../hooks/useCheckboxTracking';
import { useColumnsTracking } from '../../../../hooks/useColumnsTracking';
import Tooltip from '@mui/material/Tooltip';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import LinesItem from '../../LinesItem';
import StatusToggle from '../../StatusToggle';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';

export function LineTableTrackingCampaign({ campaign, paymentMethod, type, columnSolomon, handleSelectGraphicData, handleScrolltoGraphic, openSalesModal, date, dataMetrics, columnWidths, dataCatalogList, loadingCatalog }: LineTableTrackingCampaignProps) {
   
    const {currencySymbol} = useCurrencyType();

    const { columns } = useColumnsTracking();

    const { currentCompany, user } = useAuth();

    const isMainSource = ['facebook', 'tiktok', 'google', 'sem_atribuicao','allSources'].includes(Array.isArray(type) ? type[0] : type);

    const isTypeArray = Array.isArray(type);

    const [_, sourceName, sourceUrl] = type;

    const { selectedCampaignIds, toggleCampaignId, selectedPlatformsOthers } = useCheckboxTracking();

    const activePlatformsOthers = Object.entries(selectedPlatformsOthers)
        .filter(([_, isActive]) => isActive)
        .map(([platform]) => platform);

    const otherSource = activePlatformsOthers.toString();

    const sourceUrlIcon = useMemo(() => getIconForPlatform(isTypeArray ? sourceName : type), [isTypeArray ? sourceName : type]);

    const { isVisible } = useVisibility();

    const isADiferentSource = ['sem_atribuicao', 'outros'].includes(isTypeArray ? sourceName : type);

    const adsString = useMemo(() => getAdsIdByCampaingId(dataMetrics, campaign?.campaignId, isTypeArray ? sourceName : type, dataCatalogList), [dataMetrics, campaign?.campaignId, isTypeArray ? sourceName : type, dataCatalogList]);

    const filteredAdsData = useMemo(() => getAdsDataWarning(dataMetrics, isTypeArray ? sourceName : type), [dataMetrics, isTypeArray ? sourceName : type]);

    const [showWarning, setShowWarning] = useState({
        alert: false,
        popup: false
    });

    useEffect(() => {
        utm_is_right(isTypeArray ? sourceName : type, filteredAdsData, adsString);
    }, [adsString, filteredAdsData, type]);

    function utm_is_right(type: string, filteredAdsData: any, adsString: string) {
        type = type.split('_')[0];
        const adsStrings = adsString.split(',').map(str => str.trim());
        
        adsStrings.forEach(adString => {
            let AdsData = filteredAdsData[adString];

            if (!AdsData) {
                return;  // Continuar para o próximo adString, não interrompe a função inteira
            }
            if (!checkUtm(type, AdsData.utm_source, AdsData.utm_medium, AdsData.utm_campaign, AdsData.utm_content)) {
                setShowWarning(prevState => ({
                    ...prevState,
                    alert: true
                }));
            }  
        });
    }

    const [showCopiedPopup, setShowCopiedPopup] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(campaign?.campaignName)
            .then(() => {
                setShowCopiedPopup(true);
                setTimeout(() => setShowCopiedPopup(false), 2000); // Hide popup after 2 seconds
            })
            .catch(err => console.error('Failed to copy:', err));
    };

    if (campaign?.campaignName === '') {
        return <></>;
    }

    const [activePaymentMethods, setActivePaymentMethods] = useState([]);

    useEffect(() => {
        if (paymentMethod) {
            // console.log('entrou em paymentMethod');
    
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect    


    const revenueSolomon = calculateFinalRevenue(columnSolomon, campaign, activePaymentMethods, isADiferentSource);
    const ordersSolomon = calculateFinalOrders(columnSolomon, campaign, activePaymentMethods, isADiferentSource);

    const campaignNameHasCatalogPlaceholder = campaign?.isCatalog === true;

    const handleCatalogClick = () => {
        openSalesModal({
            source: 'facebook',
            company_id: currentCompany,
            ads_ids: adsString,
            level: 'campaign',
            start_date: date[0],
            end_date: date[1],
            name: campaign?.campaignName
        }, campaign, 'campaign', true, ordersSolomon, revenueSolomon )
    };


    return (
        <>
            <S.Container>
                <S.MainColumn style={{ width: `${columnWidths}px` }} type={type}>
                    <S.CheckBoxArea>
                        {type !== 'outros' && sourceName !== 'outros' && (
                            <Checkbox
                                checked={selectedCampaignIds.some(item =>
                                    item.id === campaign?.campaignId &&
                                    item.type === (isTypeArray ? sourceName : type)
                                )}
                                onChange={() => toggleCampaignId(campaign?.campaignId, isTypeArray ? sourceName : type)}
                                size="small"
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#19c273',
                                    },
                                }}
                            />
                        )}
                    </S.CheckBoxArea>
                    <S.StatusArea>

                        {campaign?.sourceType === 'facebook_campaigns_metrics' && (

                            <StatusToggle
                                initialStateData={campaign?.campaignStatus}
                                objectId={campaign?.campaignId}
                                objectType={'campaigns'}
                                data={campaign}
                                currentCompany={currentCompany}
                                user={user}
                            />

                        )}
                    </S.StatusArea>

                    <S.CampaignSourceIcon>
                        <img src={Array.isArray(type) ? sourceUrl : sourceUrlIcon} alt={""} />
                    </S.CampaignSourceIcon>

                    {!isADiferentSource && (
                    <S.CampaignTitleContainer>
                        <S.UrlLinkClick onClick={() => { toggleCampaignId(campaign?.campaignId, isTypeArray ? sourceName : type, 'adsSet') }}>{isVisible ? campaign?.campaignName : '* * * * * * * * * * * *'} </S.UrlLinkClick>
                        <S.ChannelSelectedContainer><p>{isVisible ? sourceName === 'outros' ? 'Orgânico / Outros' : campaign?.channelSelected : '* * * * * * *'}</p>
                            {campaignNameHasCatalogPlaceholder && (
                                <S.CatalogIcon onClick={handleCatalogClick}> <ViewModuleRoundedIcon /> Catálogo</S.CatalogIcon>
                            )}
                        </S.ChannelSelectedContainer>
                    
                    </S.CampaignTitleContainer>)}

                    {isADiferentSource && ( 
                        <S.CampaignTitleContainer>
                            <span>{isVisible ? campaign?.campaignName : '* * * * * * * * ' }</span>
                            <S.ChannelSelectedContainer>
                                <p>
                            {isVisible ? (isTypeArray ? sourceName : type) === 'outros' ? 'Orgânico / Outros' : campaign?.channelSelected : '* * * * * * * * '}
                            </p>

                                </S.ChannelSelectedContainer>
                        </S.CampaignTitleContainer>
                    )}

                    <S.ActionArea>
                    <ContentCopyIcon onClick={copyToClipboard} sx={{ width: '16px', height: '16px' }} />
                   {showWarning.alert ?
                    <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                borderColor: '#E90E0E',
                                fontWeight: '550',
                                fontSize: '13px',
                                fontFamily: 'Montserrat',
                                borderRadius: '7px',
                                border: 1,
                                padding: '10px',
                                zIndex: 0,
                                color: '#E90E0E',
                            }
                        },
                    }}
                    title={'Existem anúncios no período selecionado com UTMs incorretas'} placement="right">
                        <S.ProductNameContainer><WarningIcon /></S.ProductNameContainer>
                    </Tooltip> : 
                    null

                    }
                    </S.ActionArea>
                </S.MainColumn>
                {showCopiedPopup && <S.CopiedPopup show={showCopiedPopup}>Copiado</S.CopiedPopup>}

                <LinesItem HasCatalog={campaignNameHasCatalogPlaceholder} handleCatalogClick={handleCatalogClick} columns={columns} currencySymbol={currencySymbol} ads={campaign} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} type={'campaign' + '_' + (isADiferentSource ? 'other' : 'normal')} openSalesModal={openSalesModal}  currentCompany={currentCompany} date={date} adsString={adsString}
                            source={isADiferentSource ? otherSource : (Array.isArray(type) ? sourceName : type)} user={user} loadingCatalog={loadingCatalog}/>
            </S.Container>
        </>
    );
}

function WarningIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
        </svg>
    )
}

