import styled from 'styled-components';


export const MarketingTitle  = styled.h1`  
    color: #1e1e1e;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1rem;
`;

export const MarketingHighlight = styled.span`
    color: #19c380
`;

export const MarketingParagraph  = styled.p`  
    margin-top: 10px;
    color: var(--cinza, #989c97);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
`;

export const MarketingHeader2  = styled.h2`  
    margin-top: 10px;
    color: #909090;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 600;
`;


export const MarketingImage = styled.img`
    width: 7%;
    margin-bottom: 21px;
    margin-left: 1rem;
    margin-top: 1rem;
    flex-shrink: 0;
`;

export const ModalContent = styled.div`
    box-shadow: none !important;
    border: none !important;
    height: 20rem;
    width: 32rem;

    p {
        color: var(--cinza, #989c97);
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 500;
        text-align: start;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 2.5rem;
    input {
        font-size: 0.75rem;
        border: 1px solid #ccc;
        height: 2.5rem;
        height: 14.5rem;
        margin: 0 1rem;
        line-height: 1.125rem;
        padding: 0 0.5rem;
        border-radius: 5px;
        color: var(--black);
        &::placeholder {
            color: #757575;
        }
    }

    input[type='date'] {
        padding: 0 0.75rem;
    }

    select {
        font-size: 0.75rem;
        border: 1px solid #ccc;
        height: 2.5rem;
        height: 14.5rem;
        margin: 0 1rem;
        padding: 0 0.5rem;
        line-height: 1.125rem;
        border-radius: 5px;
        color: var(--black);
    }

    label {
        font-size: 0.6875rem;
        line-height: 1.03125rem;
        font-weight: 550;
        color: var(--black);
        margin: 0.25rem 1rem 0.25rem;
    }
`;

export const ButtonContainer = styled.div`
    background-color: var(--green);
    color: var(--white);
    border-radius: 10px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    height: 2.5rem;
    margin: 1rem 1rem 0.25rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border: 1px solid var(--green);
    &:hover {
        background-color: #f8f8f8;
        color: var(--green);
    }
`;


