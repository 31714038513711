
import React from 'react';
import * as S from './style';
import { toBrazilianFormat } from '../../../utils';
import {formatPercentage} from '../../../utils';

const TotalRowComponent = ({columnWidths, name, quantity, columns, currencySymbol,  validator, totalLine, ordersSolomon, revenueSolomon}) => {
        
    return (
        <S.TotalRow>
            <S.TotalMainColumn style={{ width: `${columnWidths}px` }}>
                {(name != "Canal") ? (`Total (${quantity} ${name})`) : `Total`}
            </S.TotalMainColumn> 

            {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
                <React.Fragment key={column.id}>

                    {column.id == 'spend' && (
                        <S.TotalDefaultColumn>{ currencySymbol }  {validator > 0 ? toBrazilianFormat(totalLine?.spendBySource) : '0,00'}</S.TotalDefaultColumn>)}
                    {column.id == 'profit' && (
                        <S.TotalDefaultColumn style={{ color: totalLine?.profitByUtmApproved < 0 ? 'red' : 'var(--green)' }}>{ currencySymbol } {toBrazilianFormat(totalLine?.profitByUtmApproved || 0)}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'productCostUtm' && (
                        <S.TotalDefaultColumn >{ currencySymbol } {toBrazilianFormat(totalLine?.productCostByUtmApproved || 0)}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'margin' && (
                        <S.TotalDefaultColumn style={{ color: totalLine?.profitByUtmApproved / revenueSolomon < 0 ? 'red' : 'var(--green)' }}>{validator > 0 && totalLine?.profitByUtmApproved !== 0 && revenueSolomon !== 0 && revenueSolomon !== undefined ? formatPercentage((totalLine?.profitByUtmApproved / revenueSolomon) * 100) : '0'}%</S.TotalDefaultColumn>
                    )}
                    {column.id == 'sales' && (
                        <S.TotalDefaultColumn>{validator > 0 ? toBrazilianFormat(totalLine?.ordersBySource, false) : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'salesUtm' && (
                        <S.TotalDefaultColumn>{validator > 0 ? ordersSolomon : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'revenue' && (
                        <S.TotalDefaultColumn>{ currencySymbol }  {validator > 0 ? toBrazilianFormat(totalLine?.revenueBySource) : '0,00'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'revenueUtm' && (
                        <S.TotalDefaultColumn>{ currencySymbol }  {validator > 0 ? toBrazilianFormat(revenueSolomon) : '0,00'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'ticket' && (
                        <S.TotalDefaultColumn>{ currencySymbol }  {validator > 0 ? toBrazilianFormat(totalLine?.revenueBySource) : '0,00'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'ticketUtm' && (
                        <S.TotalDefaultColumn>{ currencySymbol }  {validator > 0 ? toBrazilianFormat(revenueSolomon) : '0,00'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'roas' && (
                        <S.TotalDefaultColumn>
                            {validator > 0 && totalLine?.spendBySource !== 0 ? toBrazilianFormat(totalLine?.revenueBySource / totalLine?.spendBySource) : '0,00'}x
                        </S.TotalDefaultColumn>
                    )}
                    {column.id == 'roasUtm' && (
                        <S.TotalDefaultColumn>{validator > 0 && totalLine?.spendBySource !== 0 ? toBrazilianFormat(revenueSolomon / totalLine?.spendBySource) : '0,00'}x</S.TotalDefaultColumn>
                    )}
                    {column.id == 'cpa' && (
                        <S.TotalDefaultColumn>
                            { currencySymbol }  {validator > 0 && totalLine?.spendBySource !== 0 ? toBrazilianFormat( totalLine?.spendBySource / totalLine?.ordersBySource) : '0,00'}
                        </S.TotalDefaultColumn>
                    )}
                    {column.id == 'cpaUtm' && (
                        <S.TotalDefaultColumn>{currencySymbol} {validator > 0 && totalLine?.spendBySource !== 0 && ordersSolomon !== 0 && ordersSolomon !== undefined ? toBrazilianFormat(totalLine?.spendBySource / ordersSolomon) : '0,00'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'cpc' && (
                        <S.TotalDefaultColumn>
                            { currencySymbol }  {validator > 0 && totalLine?.clicksBySource !== 0 ? toBrazilianFormat(totalLine?.spendBySource / totalLine?.clicksBySource) : '0,00'}
                        </S.TotalDefaultColumn>
                    )}
                    {column.id == 'cpm' && (
                        <S.TotalDefaultColumn>
                            { currencySymbol }  {validator > 0 && totalLine?.impressionsBySource !== 0 ? toBrazilianFormat((1000 * totalLine?.spendBySource) / totalLine?.impressionsBySource) : '0,00'}
                        </S.TotalDefaultColumn>
                    )}
                    {column.id == 'impressions' && (
                        <S.TotalDefaultColumn>{totalLine?.impressionsBySource ? Number(totalLine?.impressionsBySource).toLocaleString('pt-BR') : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'clicks' && (
                        <S.TotalDefaultColumn>{totalLine?.clicksBySource ? Number(totalLine?.clicksBySource).toLocaleString('pt-BR') : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'viewContent' && (
                        <S.TotalDefaultColumn>{totalLine?.pageViewsBySource ? Number(totalLine?.pageViewsBySource).toLocaleString('pt-BR') : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'addToCart' && (
                        <S.TotalDefaultColumn>{totalLine?.addToCartsBySource ? Number(totalLine?.addToCartsBySource).toLocaleString('pt-BR') : '0'}</S.TotalDefaultColumn>
                    )}
                    {column.id == 'initiateCheckout' && (
                        <S.TotalDefaultColumn>{totalLine?.checkoutsBySource ? Number(totalLine?.checkoutsBySource).toLocaleString('pt-BR') : '0'}</S.TotalDefaultColumn>
                    )}

                </React.Fragment>
            ))}
        </S.TotalRow>
    )
}


export default TotalRowComponent;