import styled from 'styled-components';

type ContainerProps = {
    disabled?: boolean;
    width: string;
};

function getInputWidth(value: string) {
    const newValue = value?.replace('rem', '');
    const newValueNumber = parseFloat(newValue);
    const width = newValueNumber - 3.45;
    return `${width}rem`;
}

export const Container = styled.div<ContainerProps>`
    position: relative;
    border: 1px solid red;
    margin-left:1rem;
    width: ${(props) => props.width};
    height: 2.3rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    ${(props) =>
        props.disabled &&
        `
&:hover{
    cursor: not-allowed;
}
`}
    padding-left: 0.7rem;
    background-color: #f8f8f8;

    ${(props) =>
        !props.disabled &&
        `
&:hover{
    background-color: #fff;
    input{
        background-color: #fff;
    }
}
`}

    input {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.125rem;
        color: var(--black);
        background-color: #f8f8f8;
        height: 2.3rem;
        width: ${(props) => getInputWidth(props.width)};
        border: none;
        outline: none;
        &::placeholder {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.125rem;
            ${(props) =>
        props.disabled
            ? `
    color: #a1a1a1;
    `
            : 'color: var(--allblack);'}
        }
        ${(props) =>
        props.disabled &&
        `
    &:hover{
    cursor: not-allowed;
}
`}
    }
    ${(props) =>
        !props.disabled &&
        `
&:focus,
&:hover {   
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
        }
`}
`;
type MoneyProps = {
    disabled: boolean;
};

export const Money = styled.div<MoneyProps>`
    font-size: 0.75rem;
    ${(props) =>
        props.disabled
            ? `
&:hover{
    cursor: not-allowed;
}
`
            : 'cursor: pointer;'}

    font-weight: 600;
    line-height: 1.125rem;
    ${(props) =>
        props.disabled
            ? `
    color: #a1a1a1;
    `
            : 'color: var(--black);'}
    display: flex;
    span {
        margin-left: 0.5rem;
        scale: 0.7;
    }
    margin-right: 0.5rem;
`;

export const SelectMoney = styled.div``;

export type MoneyTypesProps = {
    showOptions: boolean;
};

export const MoneyTypes = styled.div<MoneyTypesProps>`
    position: absolute;
    top: 2.7rem;
    left: 0;
    cursor: pointer;

    border: 1px solid #ccc;
    background-color: #f8f8f8;
    border-radius: 5px;
    z-index: 1000;
    ${(props) => (props.showOptions ? 'display: block;' : 'display: none;')}
`;

export const MoneyTypesOption = styled.div`
    font-size: 0.625rem;
    width: 3.3125rem;
    height: 2.1875rem;
    font-weight: 500;
    line-height: 0.9375rem;
    color: var(--black);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #fff;
    }
`;
