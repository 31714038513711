import { TableClientsProps } from '../../../@types';
import { LineClients } from './LineClients';
import * as S from './style';

export function TableClients({ data, setDataPopup, setShowPopup, isLoading }: TableClientsProps) {
    return (
        <S.Container>
            <S.Header>
                <S.MainHeader>Audiências RFM</S.MainHeader>
                <S.SecondaryHeader>Base</S.SecondaryHeader>
                <S.SecondaryHeader>Pedidos</S.SecondaryHeader>
                <S.SecondaryHeader>Receita</S.SecondaryHeader>
                <S.SecondaryHeader>Baixar</S.SecondaryHeader>
            </S.Header>

            <S.Line>
                <LineClients isLoading={isLoading} setShowPopup={setShowPopup} setDataPopup={setDataPopup} type="Fiéis" data={data?.data?.audiences?.all_loyals} segmentation='all_loyals'/>
                <LineClients isLoading={isLoading} setShowPopup={setShowPopup} setDataPopup={setDataPopup} type="Novatos" data={data?.data?.audiences?.all_noobs} segmentation='all_noobs' />
                <LineClients isLoading={isLoading} setShowPopup={setShowPopup} setDataPopup={setDataPopup} type="Em risco" data={data?.data?.audiences?.all_inrisk} segmentation='all_inrisk' />
                <LineClients isLoading={isLoading} setShowPopup={setShowPopup} setDataPopup={setDataPopup} type="Perdidos" data={data?.data?.audiences?.all_churned} segmentation='all_churned' />
            </S.Line>
        </S.Container>
    );
}
