import { submitSubscriptionDTO, upgradeSubscriptionDTO } from '../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getPlan(plan: string): Promise<any> {
    console.log('plan api', api);
    try {
        const { data } = await api.get(`${BASE_URL}/asaas/plan/${plan}`);
        return data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
}

export async function getCoupon(cupom: string): Promise<any> {
    try {
        const { data } = await api.get(`${BASE_URL}/asaas/cupons/${cupom}`);
        return data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
}

export async function getLimitOrders(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/asaas/limit_orders/${company_id}`);
    return data;
}

export async function getTransactions(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/asaas/transactions/${company_id}`);
    return data;
}

export async function getExtraTransactions(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/asaas/extratransactions/${company_id}`);
    return data;
}

export async function getSubscription(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/asaas/subscriptions/${company_id}`);
    return data;
}

export async function deleteSubscription(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/asaas/cancelamento/${company_id}`);
    return data;
}

export async function refundSubscription(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/asaas/reembolso/${company_id}`);
    return data;
}

export async function reactivateSubscription(body: DTO.upgradeSubscriptionDTO): Promise<AxiosResponse> {
    const { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, company_id, creditCardToken, cupom } = body;

    const newBody = { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, creditCardToken, cupom };
    const data: AxiosResponse = await api.put(`${BASE_URL}/asaas/subscriptions/reactivate/${company_id}`, newBody);
    
    return data;
}

export async function upgradeSubscription(body: DTO.upgradeSubscriptionDTO): Promise<AxiosResponse> {
    const { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, company_id, creditCardToken, cupom } = body;

    const newBody = { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, creditCardToken, cupom };
    const data: AxiosResponse = await api.put(`${BASE_URL}/asaas/subscriptions/upgrade/${company_id}`, newBody);
    
    return data;
}

export async function submitSubscription(body: DTO.submitSubscriptionDTO): Promise<AxiosResponse> {
    const { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, company_id, creditCardToken, cupom } = body;

    console.log('body', body);

    const newBody = { billingType, cycle, creditCard, creditCardHolderInfo, value, nextDueDate, remoteIp, description, creditCardToken, cupom };
    const data: AxiosResponse = await api.post(`${BASE_URL}/asaas/subscriptions/${company_id}`, newBody);
    
    return data;
}
