import { LineProductsModal } from "./LineProductsModal"
import { LineSalesModalLoading } from "./LineSalesModalLoading"
import * as S from './style'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { useColumnsTracking } from '../../../../hooks/useColumnsTracking';
import CpfCnpj from "../../../Payment/InputPayment/CpfCnpj";
import { Tax } from "../../../Subscription/TableOrders/style";

export function TableProductsModal({ordersSolomon, revenueSolomon, metricsBySource, columnSolomon, isCatalogCampaign, productsFiltered, isLoading, dontHaveProducts}: any) {


    const { columns } = useColumnsTracking();
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [sortCriteria, setSortCriteria] = useState<'totalRevenue' | 'approvedRevenue' | 'revenueUtm'>('totalRevenue');

    const metricsMapping = {
        approvedRevenue: (product) => Number(product.revenue),
        totalRevenue: (product) => Number(product.revenue) + Number(product.pending_revenue) + Number(product.cancelled_revenue),
        revenue: (product) => Number(product.revenue) + Number(product.pending_revenue) + Number(product.cancelled_revenue),
        spend: (product) => Number(product.midia_spend),
        profit: (product) => Number(product.profit),
        sales: (product) => Number(product.number_sales) + Number(product.pending_number_sales) + Number(product.cancelled_number_sales),
        
        salesUtm: (product) => columnSolomon === 'totalSolomon' ? Number(product.number_sales) + Number(product.pending_number_sales) + Number(product.cancelled_number_sales) :
                        columnSolomon === 'approvedSolomon' ? Number(product.number_sales) :
                        columnSolomon === 'pendingSolomon' ? Number(product.pending_number_sales) :
                        columnSolomon === 'cancelledSolomon' ? Number(product.cancelled_number_sales) : 0,

        revenueUtm: (product) => columnSolomon === 'totalSolomon' ? Number(product.revenue) + Number(product.pending_revenue) + Number(product.cancelled_revenue) :
                     columnSolomon === 'approvedSolomon' ? Number(product.revenue) :
                     columnSolomon === 'pendingSolomon' ? Number(product.pending_revenue) :
                     columnSolomon === 'cancelledSolomon' ? Number(product.cancelled_revenue) : 0,

        roas: (product) => Number(product.midia_spend) !== 0 ? Number(product.revenue) / Number(product.midia_spend) : 0,
        roasUtm: (product) => {
            const revenue = columnSolomon === 'totalSolomon' ? 
                Number(product.revenue) + Number(product.pending_revenue) + Number(product.cancelled_revenue) :
                columnSolomon === 'approvedSolomon' ? Number(product.revenue) :
                columnSolomon === 'pendingSolomon' ? Number(product.pending_revenue) :
                columnSolomon === 'cancelledSolomon' ? Number(product.cancelled_revenue) : 0;
        
            return Number(product.midia_spend) !== 0 ? revenue / Number(product.midia_spend) : 0;
        },        
        cpa: (product) => Number(product.number_sales) !== 0 ? Number(product.midia_spend) / Number(product.number_sales) : 0,
        cpaUtm: (product) => Number(product.number_sales) !== 0 ? columnSolomon === 'totalSolomon' ? Number(product.midia_spend) / (Number(product.number_sales) + Number(product.pending_number_sales) + Number(product.cancelled_number_sales)) :
                     columnSolomon === 'approvedSolomon' ? Number(product.midia_spend) / Number(product.number_sales) :
                     columnSolomon === 'pendingSolomon' ? Number(product.midia_spend) / Number(product.pending_number_sales) :
                     columnSolomon === 'cancelledSolomon' ? Number(product.midia_spend) / Number(product.cancelled_number_sales) : 0 : 0,
        cpc: (product) => Number(product.link_clicks) !== 0 ? Number(product.midia_spend) / Number(product.link_clicks) : 0,
        cpm: (product) => Number(product.impressions) !== 0 ? Number(product.midia_spend) / (Number(product.impressions) / 1000) : 0,
        impressions: (product) => Number(product.impressions),
        clicks: (product) => Number(product.link_clicks),
        margin: (product) =>  Number(product.profit) / Number(product.revenue) * 100,
        productCostUtm: (product) => Number(product.cost),
        ticket: (product) => Number(product.revenue) / (Number(product.number_sales) + Number(product.pending_number_sales) + Number(product.cancelled_number_sales)) || 0,

        ticketUtm: (product) => {
            const revenue = columnSolomon === 'totalSolomon' ? 
                Number(product.revenue) + Number(product.pending_revenue) + Number(product.cancelled_revenue) :
                columnSolomon === 'approvedSolomon' ? Number(product.revenue) :
                columnSolomon === 'pendingSolomon' ? Number(product.pending_revenue) :
                columnSolomon === 'cancelledSolomon' ? Number(product.cancelled_revenue) : 0;
    
            const sales = columnSolomon === 'totalSolomon' ? 
                Number(product.number_sales) + Number(product.pending_number_sales) + Number(product.cancelled_number_sales) :
                columnSolomon === 'approvedSolomon' ? Number(product.number_sales) :
                columnSolomon === 'pendingSolomon' ? Number(product.pending_number_sales) :
                columnSolomon === 'cancelledSolomon' ? Number(product.cancelled_number_sales) : 0;
    
            return sales !== 0 ? revenue / sales : 0;
        },
        
        // Adicione outras métricas conforme necessário
    };

    const sortedProducts = productsFiltered?.slice().sort((a, b) => {
        const valueA = metricsMapping[sortCriteria](a);
        const valueB = metricsMapping[sortCriteria](b);
    
        if (sortOrder === 'asc') {
            return valueA - valueB;
        } else {
            return valueB - valueA;
        }
    });
    

    const handleSortClick = (criteria) => {
        if (sortCriteria === criteria) {
            setSortOrder(prevSortOrder => prevSortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortOrder('asc'); // Reinicie a ordem de classificação para 'asc' ao mudar o critério
        }
    };
    

    let campaignName;

    if (metricsBySource?.adsSetName) {
        campaignName = metricsBySource?.adsSetName;
    } else if (metricsBySource?.campaignName && !metricsBySource?.adsSetName) {
        campaignName = metricsBySource?.campaignName;
    } else if (metricsBySource?.adName) {
        campaignName = metricsBySource?.adName;
    }

    const fixedRowData = {
        salesQtd: metricsBySource?.ordersBySource,
        totalRevenue: metricsBySource?.revenueTotal,
        approvedRevenue: metricsBySource?.revenueApproved,
        pendingRevenue: metricsBySource?.revenuePending,
        cancelledRevenue: metricsBySource?.revenueCancelled,
        productCostRoot: metricsBySource?.productCostByUtmApproved,
        Tax: 0,
        spendBySource: metricsBySource?.spendBySource,
        Gateway: metricsBySource?.spendBySource, // Or any other relevant metric
        Checkout: metricsBySource?.spendBySource, // Or any other relevant metric
        campaignName: campaignName, // Or any other relevant metric
        ProductPrice: 0,
        accountId: metricsBySource?.accountId,
        addToCartsBySource: metricsBySource?.addToCartsBySource,
        approved_orders_boleto: metricsBySource?.approved_orders_boleto,
        approved_orders_cc: metricsBySource?.approved_orders_cc,
        approved_orders_pix: metricsBySource?.approved_orders_pix,
        approved_revenue_boleto: metricsBySource?.approved_revenue_boleto,
        approved_revenue_cc: metricsBySource?.approved_revenue_cc,
        approved_revenue_pix: metricsBySource?.approved_revenue_pix,
        campaignId: metricsBySource?.campaignId,
        campaignSourceIcon: metricsBySource?.campaignSourceIcon,
        cancelledOrders: metricsBySource?.cancelledOrders,
        cancelled_orders_boleto: metricsBySource?.cancelled_orders_boleto,
        cancelled_orders_cc: metricsBySource?.cancelled_orders_cc,
        cancelled_orders_pix: metricsBySource?.cancelled_orders_pix,
        cancelled_revenue_boleto: metricsBySource?.cancelled_revenue_boleto,
        cancelled_revenue_cc: metricsBySource?.cancelled_revenue_cc,
        cancelled_revenue_pix: metricsBySource?.cancelled_revenue_pix,
        channelSelected: metricsBySource?.channelSelected,
        checkoutsBySource: metricsBySource?.checkoutsBySource,
        clicksBySource: metricsBySource?.clicksBySource,
        cpaApproved: metricsBySource?.cpaApproved,
        cpaPending: metricsBySource?.cpaPending,
        cpaTotal: metricsBySource?.cpaTotal,
        cpaUtm: columnSolomon === 'totalSolomon' ? metricsBySource?.cpaTotal : columnSolomon === 'approvedSolomon' ? metricsBySource?.cpaApproved : columnSolomon === 'pendingSolomon' ? metricsBySource?.cpaPending : metricsBySource?.cpaCancelled,
        cpcBySource: metricsBySource?.cpcBySource,
        cpaCancelled: metricsBySource?.cpaCancelled,
        cpmBySource: metricsBySource?.cpmBySource,
        impressionsBySource: metricsBySource?.impressionsBySource,
        ordersBySource: metricsBySource?.ordersBySource,
        orders_boleto: metricsBySource?.orders_boleto,
        orders_cc: metricsBySource?.orders_cc,
        orders_pix: metricsBySource?.orders_pix,
        pageViewsBySource: metricsBySource?.pageViewsBySource,
        pendingOrders: metricsBySource?.pendingOrders,
        pending_orders_boleto: metricsBySource?.pending_orders_boleto,
        pending_orders_cc: metricsBySource?.pending_orders_cc,
        pending_orders_pix: metricsBySource?.pending_orders_pix,
        pending_revenue_boleto: metricsBySource?.pending_revenue_boleto,
        pending_revenue_cc: metricsBySource?.pending_revenue_cc,
        pending_revenue_pix: metricsBySource?.pending_revenue_pix,
        productCostByUtmApproved: metricsBySource?.productCostByUtmApproved,
        profit: metricsBySource?.profitByUtmApproved,
        margin: (metricsBySource?.profitByUtmApproved / metricsBySource?.revenueTotal) * 100,
        revenueApproved: metricsBySource?.revenueApproved,
        revenueCancelled: metricsBySource?.revenueCancelled,
        revenuePending: metricsBySource?.revenuePending,
        revenueTotal: metricsBySource?.revenueTotal,
        revenue_boleto: metricsBySource?.revenue_boleto,
        revenue_cc: metricsBySource?.revenue_cc,
        revenue_pix: metricsBySource?.revenue_pix,
        roasBySource: metricsBySource?.roasBySource,
        roasApproved: metricsBySource?.roasApproved,
        roasPending: metricsBySource?.roasPending,
        roasTotal: metricsBySource?.roasTotal,
        roasCancelled: metricsBySource?.roasCancelled,
        totalOrders: metricsBySource?.totalOrders,
        ticket: metricsBySource?.revenueBySource / metricsBySource?.ordersBySource,
        ticketUtm: columnSolomon === 'totalSolomon' ? metricsBySource?.revenueTotal / metricsBySource?.totalOrders : columnSolomon === 'approvedSolomon' ? (metricsBySource?.revenueApproved / metricsBySource?.approvedOrders ) : columnSolomon === 'pendingSolomon' ? (metricsBySource?.revenuePending / metricsBySource?.pendingOrders ) : columnSolomon === 'cancelledSolomon' ? (metricsBySource?.revenueCancelled / metricsBySource?.cancelledOrders ) : 0,
        productImg: 'https://storage.googleapis.com/static-images-source/facebook-icon.webp',
        salesUtm: columnSolomon === 'totalSolomon' ? metricsBySource?.totalOrders: columnSolomon === 'approvedSolomon' ? metricsBySource?.approvedOrders : columnSolomon === 'pendingSolomon' ? metricsBySource?.pendingOrders : columnSolomon === 'cancelledSolomon' ? metricsBySource?.cancelledOrders : 0,
        revenueUtm: columnSolomon === 'totalSolomon' ? metricsBySource?.revenueTotal: columnSolomon === 'approvedSolomon' ? metricsBySource?.revenueApproved : columnSolomon === 'pendingSolomon' ? metricsBySource?.revenuePending : columnSolomon === 'cancelledSolomon' ? metricsBySource?.revenueCancelled : 0,
        revenue: metricsBySource?.revenueBySource,
        roasUtm: columnSolomon === 'totalSolomon' ? metricsBySource?.roasTotal : columnSolomon === 'approvedSolomon' ? metricsBySource?.roasApproved : columnSolomon === 'pendingSolomon' ? metricsBySource?.roasPending : metricsBySource?.roasCancelled,
    };

    const safeNumber = (value, defaultValue = 0) => {
        return isNaN(value) || !isFinite(value) ? defaultValue : value;
    };
    
    const calculateTotals = (products, fixedData, columnSolomon) => {
        if (!products) {
            return;
        }
    
        const totals = products?.reduce((acc, product) => {
            const spendTotal =  safeNumber(Number(product?.shipping_fee)) + safeNumber(Number(product?.gateway_fee)) + safeNumber(Number(product?.checkout_fee));
            const totalRevenue = safeNumber(Number(product?.revenue)) + safeNumber(Number(product?.pending_revenue)) + safeNumber(Number(product?.cancelled_revenue));
            const approvedRevenue = safeNumber(Number(product?.revenue));
            const pendingRevenue = safeNumber(Number(product?.pending_revenue));
            const cancelledRevenue = safeNumber(Number(product?.cancelled_revenue));
            const salesQtd = safeNumber(Number(product?.number_sales)) + safeNumber(Number(product?.pending_number_sales)) + safeNumber(Number(product?.cancelled_number_sales));
            const revenueUtm = columnSolomon === 'totalSolomon' ? totalRevenue : columnSolomon === 'approvedSolomon' ? approvedRevenue : columnSolomon === 'pendingSolomon' ? pendingRevenue : columnSolomon === 'cancelledSolomon' ? cancelledRevenue : 0;
            const ticketUtm = columnSolomon === 'totalSolomon' ? totalRevenue / salesQtd : columnSolomon === 'approvedSolomon' ? approvedRevenue / safeNumber(Number(product?.number_sales)) : columnSolomon === 'pendingSolomon' ? pendingRevenue / safeNumber(Number(product?.pending_number_sales)) : columnSolomon === 'cancelledSolomon' ? cancelledRevenue / safeNumber(Number(product?.cancelled_number_sales)) : 0;
            const ticket = totalRevenue / salesQtd;
            const salesUtm = columnSolomon === 'totalSolomon' ? salesQtd : columnSolomon === 'approvedSolomon' ? safeNumber(Number(product?.number_sales)) : columnSolomon === 'pendingSolomon' ? safeNumber(Number(product?.pending_number_sales)) : columnSolomon === 'cancelledSolomon' ? safeNumber(Number(product?.cancelled_number_sales)) : 0;
            const productCostByUtmApproved = safeNumber(Number(product?.cost));
            const profit = safeNumber(Number(product?.profit)) - spendTotal;
            const spendBySource = safeNumber(Number(product?.midia_spend));
            const clicksBySource = safeNumber(Number(product?.link_clicks));
            const impressionsBySource = safeNumber(Number(product?.impressions));
            const productCostRoot = safeNumber(Number(product?.cost));
            const totalTax = safeNumber(Number(product?.gateway_fee)) + safeNumber(Number(product?.checkout_fee));

            acc.totalRevenue += totalRevenue;
            acc.productCostRoot += productCostRoot;
            acc.approvedRevenue += approvedRevenue;
            acc.pendingRevenue += pendingRevenue;
            acc.cancelledRevenue += cancelledRevenue;
            acc.ticketUtm += ticketUtm;
            acc.ticket += ticket;
            acc.revenueUtm += revenueUtm;
            acc.salesUtm += salesUtm;
            acc.salesNotCatalog += salesQtd;
            acc.profit += profit;
            acc.spendBySource += spendBySource;
            acc.productCostByUtmApproved += productCostByUtmApproved;
            acc.Tax += totalTax;
            acc.impressionsBySource += impressionsBySource;
            acc.clicksBySource += clicksBySource;
            acc.Checkout += safeNumber(Number(product?.checkout_fee));
            acc.Gateway += safeNumber(Number(product?.gateway_fee));
    
            return acc;
        }, {
            ticket: 0,
            ticketUtm: 0,
            totalRevenue: 0,
            Tax: 0,
            Gateway: 0,
            Checkout: 0,
            productCostRoot: 0,
            approvedRevenue: 0,
            pendingRevenue: 0,
            cancelledRevenue: 0,
            salesUtm: 0,
            revenue: '-',
            salesQtd: '-',
            salesNotCatalog: 0,
            margin: '-',
            revenueUtm: 0,
            profit: 0,
            spendBySource: 0,
            productCostByUtmApproved: 0,
            impressionsBySource: 0,
            clicksBySource: 0,
            pageViewsBySource: '-',
            addToCartsBySource: '-',
            checkoutsBySource: '-',
            cpmBySource: 0,
            cpcBySource: 0,
            cpaTotal: '-',
            cpaUtm: 0,
            roasBySource: '-',
        });
        // Cálculos das métricas derivadas
        totals.roasUtm = totals.spendBySource !== 0 ? safeNumber(totals.revenueUtm / totals.spendBySource) : 0;
        totals.cpaTotal = '-';
        totals.cpaUtm = totals.salesUtm !== 0 ? safeNumber(totals.spendBySource / totals.salesUtm) : 0;
        totals.cpcBySource = totals.clicksBySource !== 0 ? safeNumber(totals.spendBySource / totals.clicksBySource) : 0;
        totals.cpmBySource = totals.impressionsBySource !== 0 ? safeNumber(totals.spendBySource / (totals.impressionsBySource )) : 0;
    
        return totals;
    };
    
    const totalMetrics = calculateTotals(productsFiltered, fixedRowData, columnSolomon);
    
    // //console.log('totalmetrics', totalMetrics);
    
    if (isCatalogCampaign) {
        return (
             <>
            <S.Header style={{minWidth: '234rem'}}>
                <S.MainColumn style={{ display: 'flex', gap: '0.5rem' }}>
                    Produtos
                    <div style={{ color: '#19c27e' }}>({productsFiltered?.length})</div>
                </S.MainColumn>
                {columns.map((column) => (
                    <>
                    {column.id === 'initiateCheckout' || column.id === 'addToCart' || column.id === 'viewContent' ? (
                       
                    <S.DefaultColumnCatalog key={column.id}>
                         
                         <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
                       
                       
                            {column.name}
                        </div>

                    </S.DefaultColumnCatalog>

                    ) : (
                    <S.DefaultColumnCatalog key={column.id}  style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                       
                        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            {(column.id.includes('Utm') || column.id.includes('profit')) ? (
                                <S.SolomonColumn>
                                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                                    {column.id === 'profit' || column.id === 'productCostUtm' ? 
                                    (

                                        <p>Aprovado</p>
                                        
                                    ) : (

                                        <p>{columnSolomon === 'totalSolomon' ? 'Total' : columnSolomon === 'approvedSolomon' ? 'Aprovadas' : columnSolomon === 'cancelledSolomon' ? 'Canceladas' : columnSolomon === 'pendingSolomon' ? 'Pendentes' : ''}</p>

                                    )}

                                </S.SolomonColumn>
                            )
                            : (
                                <S.SolomonColumn>
                                        <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Facebook ads" />
                                        <p style={{fontWeight: '550', color: '#7e7e7e'}}>Facebook Ads</p>
                                    </S.SolomonColumn>
                            )
                            }
                            
                            {column.name}
                        </div>
                        <div onClick={() => handleSortClick(column.id)}>
                        {sortOrder === 'asc' && sortCriteria === column.id ? <SortIcon style={{ transform: 'rotate(180deg) scaleX(-1)' }} /> : <SortIcon />}
                        </div>
                    </S.DefaultColumnCatalog>
                    )}
                   </>    
                ))}
            </S.Header>


            <S.Lines dontHaveProducts={dontHaveProducts}>
                {!isLoading && (
                    <LineProductsModal
                        metricsBySource={fixedRowData}
                        columnSolomon={columnSolomon}
                        product={null}
                        isCatalogCampaign={isCatalogCampaign}
                        isFixedRow={true}
                    />
                )}
                {!isLoading && sortedProducts?.map((product: any) => (
                    <LineProductsModal
                        key={product.product_id}
                        metricsBySource={metricsBySource}
                        columnSolomon={columnSolomon}
                        product={product}
                        isCatalogCampaign={isCatalogCampaign}
                    />
                ))}

                {isLoading && Array.from({ length: 5 }).map((_, index) => (
                    <LineSalesModalLoading key={index} />
                ))}
                
            </S.Lines>
    </>
            

        )
    }

    return (
        <>
            <S.Header>
                <S.MainColumn style={{ display: 'flex', gap: '0.5rem' }}>
                    Produtos
                    <div style={{ color: '#19c27e' }}>({productsFiltered?.length})</div>
                </S.MainColumn>
                <S.DefaultColumn>
                    Número de vendas
                </S.DefaultColumn>
                <S.DefaultColumn onClick={() => handleSortClick('totalRevenue')} style={{ display: 'flex', alignItems: 'center' }}>
                    Receita Total
                    {sortOrder === 'asc' && sortCriteria === 'totalRevenue' ? <SortIcon style={{ transform: 'rotate(180deg) scaleX(-1)' }} /> : <SortIcon />}
                </S.DefaultColumn>
                <S.DefaultColumn onClick={() => handleSortClick('approvedRevenue')} style={{ display: 'flex', alignItems: 'center' }}>
                    Receita Aprovada
                    {sortOrder === 'asc' && sortCriteria === 'approvedRevenue' ? <SortIcon style={{ transform: 'rotate(180deg) scaleX(-1)' }} /> : <SortIcon />}
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Receita Pendente
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Receita Cancelada
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Custo de Produto
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Taxas totais
                    <Tooltip title="Gateway + Checkout" placement="top" arrow componentsProps={{ arrow: { sx: { color: 'var(--black)' } }, tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '11px', fontFamily: 'Montserrat', borderRadius: '9px', padding: '11px', color: 'white', textWrap: 'wrap', zIndex: 999999 } } }}>
                        <InfoRoundedIcon sx={{ width: '15px !important', cursor: 'default !important', marginLeft: '0.5rem', fill: '#7b9898 !important' }} />
                    </Tooltip>
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Gateway
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Checkout
                </S.DefaultColumn>
            </S.Header>
    
            <S.Lines dontHaveProducts={dontHaveProducts} notCatalog={true}>
                {!isLoading && sortedProducts?.map((product: any) => {
                    return <LineProductsModal key={product.product_id} product={product} />
                })}
    
                {!isLoading && (
                    <LineProductsModal
                        metricsBySource={totalMetrics}
                        totalRow={true}
                        columnSolomon={columnSolomon}
                        product={null}
                        isCatalogCampaign={isCatalogCampaign}
                        isFixedRow={true}
                    />
                )}
    
                {isLoading && Array.from({ length: 5 }).map((_, index) => {
                    return <LineSalesModalLoading key={index} />
                })}
    
                {(!isLoading && dontHaveProducts) && (
                    <S.ContainerImage>
                        <img src="https://solomon.com.br/wp-content/uploads/2023/10/vazio.psd.png" alt="Nenhum produto encontrado" />
                        <S.TitleImage>Oops!</S.TitleImage>
                        <S.SubtitleImage>Sem dados no período selecionado.</S.SubtitleImage>
                        <S.SubtitleImage>Por favor, escolha um novo período.</S.SubtitleImage>
                    </S.ContainerImage>
                )}
            </S.Lines>
        </>
    );}


function ArrowDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
        </svg>
    );
}