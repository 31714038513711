import * as S from './style';
import { LinesTableConfigurationUtmLoadingProps } from '../../../../@types';
import Skeleton from '@mui/material/Skeleton';

export function LinesTableConfigurationUtmLoading ({ tabSelected }: LinesTableConfigurationUtmLoadingProps) {

    if (tabSelected === 'Facebook') {
        return (
            <>
                <S.Container>
                    <S.MainColumn>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.MainColumn>
                    <S.MainColumnClick>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.MainColumnClick>
                    <S.DefaultColumnF>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnF>
                    <S.DefaultColumnF>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnF>
                    <S.DefaultColumnF> 
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnF>
                </S.Container>
            </>
        );
    } else if (tabSelected === 'Google') {
        return (
            <>
                <S.Container>
                    <S.MainColumnG>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.MainColumnG>
                    <S.DefaultColumnG>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnG>
                    <S.DefaultColumnG>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnG>
                    <S.DefaultColumnG>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                    </S.DefaultColumnG>
                </S.Container>
            </>
        );
    }
}
