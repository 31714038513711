import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 14.3125rem;
    height: 11.925rem;
    border-radius: 12px;
    position: relative;
    padding-top: 3.7rem;
`;

export const ContainerLoading = styled.div`
    width: 14.3125rem;
    height: 15.625rem;
    border-radius: 12px;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const Text = styled.div`
    position: absolute;
    border: 1px solid var(--gray-200);
    width: max-content;
    display: none;
    padding: 0.3rem 0.5rem;
    background-color: var(--white);
    bottom: 1rem;
    left: 6.5rem;
    z-index: 3;
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--black);
`;

export const Info = styled.div`
    &:hover {
        cursor: pointer;
        ${Text} {
            display: block;
        }
    }
`;

export const TypePaymentsContainer = styled.div`
    margin-left: 1rem;
`;

type TypePaymentsValueProps = {
    color: string;
};

export const TypePayments = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: #034141;
    display: flex;
    align-items: center;
    svg {
        margin-right: 0.5rem;
        color: ${(props) => props.color};
    }
`;

export const PaymentsValue = styled.div<TypePaymentsValueProps>`
    color: ${(props) => props.color};
    span {
        font-size: 1.625rem;
        font-weight: 500;
    }
`;

type LineProps = {
    color: string;
};

export const Line = styled.div<LineProps>`
    width: 2.1rem;
    height: 1px;
    background-color: ${(props) => props.color};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BarContainer = styled.div`
    height: 14.1875rem;
    width: 1.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #d3d3d3;
    border-radius: 50px;
    margin-right: 1rem;
    margin-top: -3rem;
`;

type BarProps = {
    percentage: string;
    totalApprovedOrders: string;
    color: string;
};

function getHeight(percentage: string, totalApprovedOrders: string) {
    if (Number(totalApprovedOrders) === 0) {
        return '4.475625rem';
    }
    const height = (Number(percentage) * 13.5625) / 100;
    return `${height}rem`;
}

function getColorByPercentage(percentage: string, color: string) {
    if (percentage == '0') {
        return '#ccc';
    } else {
        return color;
    }
}

export const Bar = styled.div<BarProps>`
    width: 0.6875rem;
    height: ${(props) => getHeight(props.percentage, props.totalApprovedOrders)};
    background-color: ${(props) => getColorByPercentage(props.percentage, props.color)};
    &:last-child {
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
    }
    &:first-child {
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
    }
`;
