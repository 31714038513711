import * as S from './style';
import congratsGift from '../../assets/images/congrats.json';
import Lottie from 'lottie-react';
import { ButtonApp } from '../../components/ButtonApp';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { storageOnboardingCurrentCompanyGet, storageOnboardingRemoveAll } from '../../storage/storageOnboarding';
import useGetData from '../../hooks/useGetData';
import { useEffect } from 'react';


export function Congratulation({type}: {type?: string}) {

    // const navigate = useNavigate()
    // const {setCurrentCompanyContext, updateUser, user} = useAuth()
    // function handleDashboad(){
    //     setCurrentCompanyContext(storageOnboardingCurrentCompanyGet())
    //     updateUser()
    //     storageOnboardingRemoveAll()
    //     navigate('/dashboard')
    // }
    
    // useEffect(() => {
    //     localStorage.removeItem('subscriptionPostalCode');
    //     localStorage.removeItem(`plansArray_${user.user_id}`);
    //     localStorage.removeItem(`savedCoupon_${user.user_id}`);
    //     localStorage.removeItem(`selectedPlan_${user.user_id}`);
    //     // localStorage.removeItem(`currentStep_${user.user_id}`);
    //     localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
    //     localStorage.removeItem(`selectedPlanSignUp`)
    //     localStorage.removeItem(`currentStep`)    
    //     localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);

    // },[])


    return (
        <S.Container>
            <S.ContainerImage>
                <img src='https://solomon.com.br/wp-content/uploads/2023/04/Ativo-7Logo-Solomon-Green-Horizas2.png' />
               
            </S.ContainerImage>
            
            <S.ContainerInformation>
                <S.StarIconUp>
                    <StarIcon />
                </S.StarIconUp>
                <S.StarIconDown>
                    <StarIcon />
                </S.StarIconDown>
                <VerifiedIcon />
                <S.Fireworks>
                    <Lottie animationData={congratsGift} />
                </S.Fireworks>
                <S.Title>
                    <span>Tudo pronto!</span>
                    Sua integração com o Facebook foi concluída.
                </S.Title>
                <S.Subtitle>
                    Agora você já pode voltar para o site da solomon
                </S.Subtitle>
                <S.Subtitle>
                    e ativar as contas de anúncio que deseja
                </S.Subtitle>
            </S.ContainerInformation>

        </S.Container>
    );
}

function VerifiedIcon() {
    return (  
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm94-278 226-226-56-58-170 170-86-84-56 56 142 142Z"/>
        </svg>
    );
}

function StarIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z"/></svg>
    )
}

function DashboardIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Z"/>
    </svg>
    )
}