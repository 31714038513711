import * as S from './style';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LineAccounts } from './LineAccounts';
import { useState } from 'react';

export function Accounts({ information, hasLoading, accounts, setHasSomeAccountChanged, typeAccount, context, filterLower }: any) {
    const [showMoreAccounts, setShowMoreAccounts] = useState(true);

    // Filtrar contas dentro da informação com base no filtro aplicado
    const filteredAccounts = accounts?.filter(account => {
        const accountId = account?.account_id ? account?.account_id.toString() : '';
        const accountName = account?.assets ? account?.assets?.account_name?.toLowerCase() : '';

        return accountId.includes(filterLower) || accountName.includes(filterLower);
    });


    return (
        <S.Container>
            <S.HeaderAccount>
                <AccountCircleIcon sx={{ color: '#cccccc', height: '40px', width: '40px' }} />
                <S.TitleAccount>{typeAccount === 'google_ads' ? information?.profile_id : information?.assets?.profile_name}</S.TitleAccount>
                {showMoreAccounts && <KeyboardArrowUpIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(false)} />}
                {!showMoreAccounts && <KeyboardArrowDownIcon sx={{ height: '24px', width: '24px' }} onClick={() => setShowMoreAccounts(true)} />}
            </S.HeaderAccount>
            <S.Lines>
                 {!hasLoading &&
                    showMoreAccounts &&
                    filteredAccounts.map((account: any) => {
                        return (
                            <LineAccounts
                                context={context}
                                key={account?.account_id}
                                typeAccount={typeAccount}
                                account={account}
                                setHasSomeAccountChanged={setHasSomeAccountChanged}
                                profileId={information?.profile_id}
                            />
                        );
                    })}
            </S.Lines>
        </S.Container>
    );
}
