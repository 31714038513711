import { userDTO } from '../@types';
import { USER_STORAGE } from './storageConfig';

export function storageUserSave(user: userDTO) {
    localStorage.setItem(USER_STORAGE, JSON.stringify(user));
}

export function storageUserGet() {
    const storage = localStorage.getItem(USER_STORAGE);

    const user: userDTO = storage ? JSON.parse(storage) : {};

    return {...user};
}

export function storageUserRemove() {
    localStorage.removeItem(USER_STORAGE);
}
