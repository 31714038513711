import styled from 'styled-components';

export const Container = styled.div`
    height: 3.09375rem;
    display: flex;
    color: var(--black);
    font-size: 0.83125rem;
    font-weight: 500;
`;

export const OrderId = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    width: 23.625rem;
    border-right: 1px solid var(--gray-180);
    color: var(--green);
    font-weight: 500;
    font-size: 0.875rem;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Date = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Payment = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
    gap: 0.5rem;
    img {
        width: 0.75rem;
    }
`;

export const Value = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Approved = styled.div`
    height: 1.3rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Pending = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;

export const Cancelled = styled.div`
    width: 8rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.8125rem;
`;
