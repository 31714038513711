import axios, { AxiosInstance, AxiosError } from 'axios';
import { storageAuthTokenGet, storageAuthTokenSave } from '../storage/storageAuthToken';
import { storageUserGet } from '../storage/storageUser';

const { user_id } = storageUserGet();

type SignOut = () => void;


type APIInstanceProps = AxiosInstance & {
    registerInterceptTokenManager: (signOut: SignOut) => () => void;
};

type PromiseType = {
    onSuccess: (token: string) => void;
    onFailure: (error: AxiosError) => void;
};

const BASE_URL = import.meta.env.VITE_API_URL;

const api = axios.create({}) as APIInstanceProps;

let failedQueue: Array<PromiseType> = [];
let isRefreshing = false;

api.registerInterceptTokenManager = (signOut: SignOut) => {
    const registerInterceptTokenManager = api.interceptors.response.use(
        (response) => {
            // Imprime o response em caso de sucesso
            console.log('Response:', response);
            return response;
        },
        (requestError) => {
            console.log('Request error', requestError);
            if (requestError?.response?.status === 498) {

                if (requestError.response.data.message === 'Cache not found') {
                    signOut();
                    return Promise.reject(requestError);
                }
                const { session_token } = storageAuthTokenGet();
                const { user_id } = storageUserGet();
                if (!session_token) {
                    // signOut(); Esse
                    return Promise.reject(requestError);
                }

                const originalRequestConfig = requestError.config;

                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({
                            onSuccess: (token: string) => {
                                originalRequestConfig.headers['Authorization'] = token;
                                resolve(api(originalRequestConfig));
                            },
                            onFailure: (error: AxiosError) => {
                                reject(error);
                            },
                        });
                    });
                }

                isRefreshing = true;

                return new Promise(async (resolve, reject) => {
                    try {
                        const body = {
                            service: 'default',
                            session_token: session_token,
                            user_id: user_id,
                        };

                        const { data } = await api.post(`${BASE_URL}/auth/client/token`, body);
                        storageAuthTokenSave({
                            session_token,
                            access_token: data.access_token,
                        });

                        if (originalRequestConfig.data) {
                            originalRequestConfig.data = JSON.parse(originalRequestConfig.data);
                        }

                        originalRequestConfig.headers['Authorization'] = data.access_token;
                        api.defaults.headers.common['Authorization'] = data.access_token;

                        failedQueue.forEach((promise) => promise.onSuccess(data.access_token));

                        resolve(api(originalRequestConfig));
                    } catch (error: any) {
                        failedQueue.forEach((promise) => promise.onFailure(error));
                        // signOut(); Esse
                        reject(error);
                    } finally {
                        isRefreshing = false;
                        failedQueue = [];
                    }
                });
                // }
                // signOut();
            }

         if (requestError.response?.status === 402) {    
    console.log('Error 402:', requestError.response.data);
    // usuário ou company não tem plano ativo
    if (requestError.response.data.message === 'An inactive company can\'t access this kind of resource') {

        if (window.location.pathname !== '/onboarding') {
            
            window.location.href = '/payment?context=renew&add_store=true';
        } else {
            console.log('No redirect because the path is /onboarding');
        }
    }
}

            if (requestError.response?.status === 401) {
                console.log('Error 401:', requestError.response.data);
                // usuário ou company não tem plano ativo
                if (requestError.response.data.message === 'Invalid session token provided') {
                    signOut();
                    return Promise.reject(requestError);
                }
            }


            if (requestError.response && requestError.response.data) {
                return Promise.reject(requestError.response.data);
            } else {
                return Promise.reject(requestError);
            }
        }
    );

    return () => {
        api.interceptors.response.eject(registerInterceptTokenManager);
    };
};

// export async function loginUser(body: DTO.userLoginDTO): Promise<DTO.userLoginResponse> {
//     const data: DTO.userLoginResponse = await api.post(`${BASE_URL}/auth/login/password`, body);
//     return data;
// }

// export async function createUser(body: DTO.userCreateDTO): Promise<DTO.userCreateResponse> {
//     const data: DTO.userCreateResponse = await api.post(`${BASE_URL}/auth/users`, body);
//     return data;
// }

// export async function resetPasswordUser(body: DTO.userResetPasswordDTO): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.post(`${BASE_URL}/auth/password-reset`, body);
//     return data;
// }

// export async function getUser(): Promise<DTO.userDTO> {
//     const data: DTO.userDTO = await api.get(`${BASE_URL}/auth/users/`);
//     return data;
// }

// export async function getDashboardData(start_date: string, end_date: string, company_id: string): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/dashboard/general?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
//     return data;
// }

// export async function getTaxesRate(company_id: string): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/taxes?company_id=${company_id}`);
//     return data;
// }

// export async function getUrlChangePassword(body: DTO.userChangePasswordDTO): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.post(`${BASE_URL}/auth/change-password`, body);
//     return data;
// }

// export async function getClientsInformation(company_id: string): Promise<DTO.clientsInformationDTO> {
//     const data: DTO.clientsInformationDTO = await api.get(`${BASE_URL}/client-services/dashboard/customers/segmentation?company_id=${company_id}`);
//     return data;
// }

// export async function getProductsAnalytics(start_date: string, end_date: string, company_id: string): Promise<DTO.ProductsAnalyticsDTO> {
//     const data: DTO.ProductsAnalyticsDTO = await api.get(`${BASE_URL}/client-services/dashboard/products?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
//     return data;
// }

// export async function updateProductCost(company_id: string): Promise<AxiosResponse> {
//     const body = {
//         company_id: company_id,
//         status: 'active',
//     }
//     const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/products/marketing-cost`, body);
//     return data;
// }

// export async function getProducts(company_id: string): Promise<DTO.ProductsDTO> {
//     const platform = 'shopify'
//     const data: DTO.ProductsDTO = await api.get(`${BASE_URL}/client-services/io-handlers/products?company_id=${company_id}&platform=${platform}`);
//     return data;
// }

// export async function getProductsCost(company_id: string): Promise<DTO.ProductsCostDTO> {
//     const platform = 'shopify'
//     const data: DTO.ProductsCostDTO = await api.get(`${BASE_URL}/client-services/io-handlers/manual/products/cost?company_id=${company_id}&platform=${platform}`);
//     return data;
// }

// export async function getOrders( start_date: string, end_date: string, company_id: string): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/orders?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
//     return data;
// }

// export async function updateOrder(body: DTO.updateOrderDTO): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/orders`, body);
//     return data;
// }

// export async function getCosts(company_id: string): Promise<AxiosResponse>{
//     const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/additional-costs?company_id=${company_id}`);
//     return data;
// }

// export async function createCost(body: DTO.createCostDTO): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/additional-costs`, body);
//     return data;
// }

// export async function deleteCost(body: DTO.deleteCostDTO): Promise<AxiosResponse> {
//     //@ts-ignore
//     const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/io-handlers/manual/additional-costs`, body);
//     return data;
// }

// export async function getHistoryCost(company_id: string): Promise<AxiosResponse> {
//     const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/additional-costs/historical?company_id=${company_id}`);
//     return data;
// }

export { api };

// export async function modelFunction(token: string): Promise<AxiosResponse> {
//     const config = createConfig(token)
//     const data : AxiosResponse = await axiosInstance.get(`${BASE_URL}/`, config)
//     // const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/`, config)
//     // const data: AxiosResponse = await axios.get('https://pokeapi.co/api/v2/pokemon/ditto');
//     return data;
// }

// export async function getModel(): Promise<AxiosResponse> {
//     const data: AxiosResponse = await axios.get(`https://jsonplaceholder.typicode.com/posts`)
//     return data;

// }

// function createConfig2() {
//     return {
//       headers: {
//         'Content-type': 'application/json; charset=UTF-8',
//       }
//     }

// }

// export async function postModel(body: object): Promise<AxiosResponse> {
//     const data: AxiosResponse = await axios.post(`https://jsonplaceholder.typicode.com/posts`, body, createConfig2())
//     return data;
// }

// export async function deleteModel(): Promise<AxiosResponse> {
//     const data: AxiosResponse = await axios.delete(`https://jsonplaceholder.typicode.com/posts/1`)
//     return data;
// }

// export async function updateProductsCosts(company_id: string, body: DTO.ProductsCostDTO): Promise<AxiosResponse> {
//     const body = {
//         company_id: company_id,
//         platform: 'shopify',
//         account_id: '1',
//         store_id: '',
//         variants: []
//     }
//     const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/products/cost`, body);
//     return data;
// }
