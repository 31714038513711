import { LineCostOperationProps } from '../../../@types';
import Switch from '../../../components/Switch';
import * as S from './style';
import { parse, format } from 'date-fns';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useAuth from '../../../hooks/useAuth';
import { useDeleteCost, useUpdateCost } from '../../../services/hooks/costHooks';
import { useState } from 'react';
import useVisibility from '../../../hooks/useVisibilityChange';
import {useCurrencyType} from '../../../hooks/useCurrencyType';

export function LineCostOperation({ date, frequency, name, type, value, tab, cost_id, status, onDelete, onUpdate }: LineCostOperationProps) {
    const { currentCompany } = useAuth();
    const [isChecked, setIsChecked] = useState(status === 'active');
    const { isVisible } = useVisibility();
    const { currencySymbol } = useCurrencyType();


    const { mutate: deleteCost } = useDeleteCost();
    const { mutate: updateCost} = useUpdateCost();

    const frequencyTranslation: { [key: string]: string } = {
        Mensal: 'monthly',
        Semanal: 'weekly',
        Diária: 'daily',
        Pontual: 'specific',
    };

    function handleDeleteCost() {
        if (onDelete) {
            onDelete(cost_id ?? '');
        }
        deleteCost({
            company_id: currentCompany,
            additional_cost_id: cost_id ?? '',
            frequency: frequencyTranslation[frequency],
        });
    }

    function formatDate(dateString: string) {
        const haveFormat = dateString.includes('GMT');

        if (tab === 'Entrada' || !haveFormat){
            const [year, month, day] = dateString.split('-');
            return `${day}/${month}/${year}`;
        }
        else {            
            const dateObj = parse(dateString, 'EEE, dd MMM yyyy HH:mm:ss \'GMT\'', new Date());
            const formattedDate = format(dateObj, 'dd/MM/yyyy');
            return formattedDate;
        }
        
    }
    const handleActivateAccount = () => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);
        const body = {date, frequency: frequencyTranslation[frequency], name, type, value, status: newIsChecked ? 'active' : 'inactive', company_id: currentCompany, additional_cost_id: cost_id};
        updateCost(body);

        const updatedCost : LineCostOperationProps  = {date, frequency, name, type, value, status: newIsChecked ? 'active' : 'inactive', cost_id};
        if (onUpdate) {
            onUpdate(updatedCost);
        }     
    }



    let newType = '';
    switch (type) {
        case 'aluguel':
            newType = 'Aluguel';
            break;
        case 'marketing':
            newType = 'Marketing';
            break;
        case 'retirada':
            newType = 'Distribuição de Lucros para Sócios';
            break;
        case 'plataformas':
            newType = 'Software e Aplicativos';
            break;
        case 'trafego':
            newType = 'Despesas com Tráfego pago';
            break;
        case 'saque':
            newType = 'Operações de Saque Financeiro';
            break;
        case 'alimentacao':
            newType = 'Despesas com Alimentação';
            break;
        case 'contabilidade':
            newType = 'Serviços de Contabilidade';
            break;
        case 'funcionario':
            newType = 'Salários de Funcionários';
            break;
        case 'outros':
            newType = 'Outros';
            break;
            
    }

    return (
        <S.Container>
            <S.DefaultColumn>{isVisible ? name : '* * * * * * * * * *'}</S.DefaultColumn>
            <S.DefaultColumn>{newType}</S.DefaultColumn>
            <S.DefaultColumn>
                {currencySymbol}{' '}
                {value.toLocaleString('pt-Br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}
            </S.DefaultColumn>
            <S.DefaultColumn>{frequency}</S.DefaultColumn>
            <S.DefaultColumn>{formatDate(date)}</S.DefaultColumn>
            {tab === 'Entrada' && (
                <S.DefaultColumn>
                    <Switch value={isChecked} setValue={handleActivateAccount} /> <DeleteForeverOutlinedIcon onClick={handleDeleteCost} sx={{ color: '#ea1a1a', width: '24px', height: '24px' }} />
                </S.DefaultColumn>
            )}
        </S.Container>
    );
}
