import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
    // min-height: 100vh;
    background-color: #f6f6f6;
    
    // ::-webkit-scrollbar {
    // width: 8px; /* Altura da barra de rolagem */
    // }

`;

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    span {
        display: flex;
        align-items: center;
        
        gap: 1rem;
    }
`;

export const SectionWarnings = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    span {
        display: flex;
        align-items: center;
        
        gap: 1rem;
    }
`;

const shine = keyframes`
    to{
        background-position: 200% center;
    }
`;

export const RightSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-left: 1rem;
    z-index: 100;
`;

export const Text = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    font-weight: 550;
    background: linear-gradient(to right, #0e9fb5 0%, #1f8463 100%);
    background-size: 200% auto;
    cursor: pointer;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s linear infinite;
    font-size: 0.6875rem;
    line-height: 1.03125rem;
    margin-bottom: 0.25rem;
`;

export const TrackingButton = styled.div`
    width: 14.8rem;
    height: 2.8125rem;
    border: 1px solid var(--green);
    border-radius: 5px;
    color: var(--green);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
`;

export const LeftContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    gap: 1rem;
`;

export const Table = styled.div`
    min-width: 68rem;
    border: 1px solid transparent;
    position: relative;
    margin-bottom: 2rem;
    margin-top: 1rem;
`;

type TabProps = {
    isSelected: boolean;
};

export const TableTopArea = styled.div`
background-color: var(--white);
display: flex;
border: 1px solid #E6E6E6;
border-top: none;
width: 99.8%;
height: 3.5rem;
margin-top: 21px;
margin-bottom: -82px;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const TabSection = styled.div`
display: flex;
gap: 0.8rem;
margin-bottom: -22px;
width: 100%;
`;


const Tab = styled.div<TabProps>`
    width: inherit;
    height: 2.5rem;
    border-top-right-radius: 9.5px;
    border-top-left-radius: 9.5px;
    border: 1px solid #E6E6E6;
    border-bottom: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 500;
    padding-left: 0.6rem;
    gap: 0.5rem;
    z-index: 0;
    transition: all 0.2s;

    ${(props) =>
        props.isSelected
            ? `
    background-color: var(--white);
    color: var(--green);
    transition: all 0.2s;
    `
    : `
    
    background-color: #fafafa;
    color: #88A09c;
    `};

    cursor: pointer;
    &:hover {
        margin-top: -1px;
        transition: all 0.2s;
        background-color: #fefefe;
    }
    span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;

export const TabChannel = styled(Tab)`
    z-index: 0;
        transition: all 0.2s;

`;

export const TabChannelLoading = styled(TabChannel)`
    cursor: not-allowed;
        transition: all 0.2s;

`;

export const TabCampaign = styled(Tab)`
    z-index: 0;
        transition: all 0.2s;

`;

export const TabCampaignLoading = styled(TabCampaign)`
    cursor: not-allowed;
        transition: all 0.2s;

`;

export const TabAdsSet = styled(Tab)`
    width: inherit;
    z-index: 0;
        transition: all 0.2s;

`;

export const TabAdsSetLoading = styled(TabAdsSet)`
    cursor: not-allowed;
        transition: all 0.2s;

`;

export const TabAd = styled(Tab)`
    width: inherit;
    z-index: 0;
        transition: all 0.2s;

`;

export const ActionButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-right: 1rem;    
    width: 100%;
`;

export const FilterPaymentArea = styled.div`
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: -1px;
    height: 6px;
    color: var(--black);   
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;

    svg {
        margin-right: 0.5rem;
        width: 1.2rem;
    }

    &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;

    }
`;

export const FilterPayment = styled.div`

`;

export const EditColumnArea = styled.div`
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: -1px;
    height: 6px;
    color: var(--black);   
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;

    svg {
        width: 1.2rem;
    }

    &:hover {
        cursor: pointer;
        background-color: #eee;
        transition: background-color 0.2s;

    }
`;

export const SelectionFilterSection = styled.div<{disabled: boolean}>`
    background-color: ${(props) => (props.disabled ? '#e9e9e9' : '#f3f3f3')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    padding: 15px;
    margin-top: -1px;
    height: 6px;
    color: ${(props) => (props.disabled ? '#6f7e77' : 'var(--black)')};
    font-size: 0.8rem;
    font-weight: 550;
    border-radius: 10px;
    transition: background-color 0.2s;
    gap: 0.5rem;

    svg {
        fill: ${(props) => (props.disabled ? '#6f7e77' : 'var(--black)')};
        width: 1.1rem;
    }

    &:hover {
        cursor:${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '-var(--gray-150)' : '#e9e9e9')};
        transition: background-color 0.2s;
    }

    span {
        padding: 1px 10px;
        border-radius: 50px;
        font-weight: 550;
        color: var(--white);
        background-color: #6f7e77;
    }
`;

export const EditColumn = styled.div`

`;

export const TabAdLoading = styled(TabAd)`
    cursor: not-allowed;
`;


export const SelectedOption = styled.div`
    width: fit-content;
    height: 1.03125;
    border-radius: 7px;
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.6875rem;
    line-height: 1.03125rem;
    font-weight: 600;
    color: var(--white);
    margin-right: 0.5rem;
    padding: 0.2rem 0;
    padding-right: 0.4rem;
    svg {
        margin-left: 0.4rem;
        margin-right: 0.4rem;
    }
`;

const shake = keyframes`
    0%, 20% { /* 0.8s de 4s = 20% */
    transform: translateX(0);
    }

    2.5%, 7.5%, 12.5%, 17.5% { /* Intervalos durante os 0.8s de atividade */
    transform: translateX(-1.8px);
    }

    5%, 10%, 15% { /* Intervalos também durante a atividade */
    transform: translateX(1.8px);
    }
    21%, 100% {
    transform: translateX(0);
    }
`;

const glow = keyframes`
    0%, 100% {
        box-shadow: 0 0 5px 5px rgba(233, 14, 14, 0);
    }
    50% {
        box-shadow: 0 0 16px -2px red;
    }
`;

export const UtmLoaded = styled.div`
    animation: ${shake} 4s infinite ease-in-out, ${glow} 4s infinite ease-in-out; /* Apply the shake animation */

    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #E90E0E; 
    background-color: #E90E0E;
    border-radius: 50px;
    padding: 5px;
    // width: fit-content;
    color: #E90E0E;
    margin-top: 15px;
    margin-left: rem;
    

    // h2 {
    //     margin-left: 6px;
    //     font-size: 13px;
    //     font-weight: 400;
    // }

    svg {
        // font-size: 18px;
        width: 18px;
        height: 18px;
        margin-top: -1px;
        fill: white;
    }

    span {
        margin-left: 6px;
        font-size: 12px;
        text-wrap: nowrap;
        font-weight: 550;
    }

    cursor: pointer;
    &:hover {
        transform: translateY(-3px);
        transition: transform ease 200ms;
        color: #E90E0E;
        cursor: pointer;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
`

export const RightActionButtons = styled.div`
display: flex;
gap: 1rem;
margin-right: 1rem; 
align-items: center;
`

export const LeftActionButtons = styled.div`
display: flex;
gap: 1rem;
margin-left: 1rem; 
align-items: center;
`

export const Circle = styled.div`
    // animation: ${glow} 8s infinite ease-in-out;
    background-color: #E90E0E;
    border-radius: 50%;
    border: 2.5px solid white;
    width: 0.55rem;
    height: 0.55rem;
    position: absolute;
    top: -5px;
    right: -5px;
`

export const Backdrop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    background-color: rgba(63, 63, 63, 0.66);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 80vw;
    min-width: 80vw;
    height: fit-content;
    position: relative;
    z-index: 999999;
    transform: scale(1);
    transition: transform 0.3s ease;

    @media (max-width: 1600px) {
        transform: scale(0.8);
        max-width: 90vw;
        }
        
    @media (max-width: 1200px) {
        transform: scale(0.8);
        max-width: 100vw;
    }
`;
