

export function getCurrentCostArray(currentDataCostArray: any) {
    const frequencyArray = ['daily', 'monthly', 'specific', 'weekly'];
    const frequencyTranslation: { [key: string]: string } = {
        monthly: 'Mensal',
        weekly: 'Semanal',
        daily: 'Diária',
        specific: 'Pontual',
    };

    const currentCostArray = frequencyArray.reduce((acc: any, frequency) => {
        for (const costId in currentDataCostArray?.[frequency]) {
            if (costId !== '0') {
                const newCost = { ...currentDataCostArray?.[frequency]?.[costId] };
                newCost['frequency'] = frequencyTranslation?.[frequency];
                newCost['cost_id'] = costId;
                //@ts-ignore
                acc.push(newCost);
            }
        }
        return acc;
    }, []);

    return currentCostArray;
}

export function getHistoricalCostArray(historicalDataCostArray: any) {
    const historicalCostArray = historicalDataCostArray?.map((cost: any) => {
        let frequency = '';
        switch (cost.frequency) {
            case 1:
                frequency = 'Diário';
                break;
            case 2:
                frequency = 'Semanal';
                break;
            case 3:
                frequency = 'Mensal';
                break;
            case 0:
                frequency = 'Pontual';
                break;
        }

        return { ...cost, frequency };
    });

    return historicalCostArray;
}
