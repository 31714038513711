import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
`;

export const DefaultColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 650;
    line-height: 1.125rem;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    color: var(--gray-700);
    background-color: var(--gray-115);
    border-radius: 5px;
    display: flex;
    flex: 1;



`;
