import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin: 0 2rem 1.5rem;
`;

export const Title = styled.div`
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
`;

export const Button = styled.button`
    background-color: var(--green);
    height: 2.1875rem;
    width: max-content;
    border-radius: 7px;
    font-size: 0.875rem;
    font-weight: 550;
    line-height: 1.3125rem;
    color: #fff;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    svg {
        fill: #fff;
        width: 0.9375rem;
        height: 0.9375rem;
    }
    &:hover {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
        transition: 0.3s;
    }
`;

export const Header = styled.div`
    height: 4.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
`;

export const Line = styled.div`
    height: 1px;
    background-color: #e5e5e5;
    width: 100%;
    margin: 0.2rem 0;
`;

export const List = styled.div<{groupName: string}>`
    min-height: 2rem;
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.groupName === 'Grupos' ? '0' : '0.5rem')};
    margin: ${(props) => (props.groupName === 'Grupos' ? '1rem 0' : '1.5rem 0rem')};
    overflow-y: ${(props) => (props.groupName === 'Grupos' ? 'auto' : 'none')};
    height: 'auto';
    max-height: ${(props) => (props.groupName === 'Grupos' ? '15rem' : 'auto')};
`;

export const Member = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    width: 25rem;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 1rem;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    svg {
        fill: #b8b9b7;
    
        &:hover {
            fill: #ea1a1a;
            cursor: pointer;
        }
    }
`;

export const GroupName = styled.div`
    width: max-content;
    padding: 0 0.7rem;
    height: 1.4rem;
    border-radius: 50px;
    background-color: #1e1e1edd;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: start;
`;

export const ModalContent = styled.div`
background-color: white;
padding: 20px 20px 14px 20px;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;

 h2 {
    font-size: 24px;
    color: #0e1819;
    margin-bottom: 0px;
    font-weight: 500;
 }

 p {
    font-size: 16px;
    color: #666;
    font-weight: 500;
    margin-bottom: 32px;
 }

 
`;


export const ButtonText = styled.div<{ isDefault?: boolean, isEditing?: boolean }>`
    background-color: ${(props) => (props.isEditing ? '#19c27e' : 'transparent')};
    border: none;
    border-radius: 312px;
    color: ${(props) => (props.isEditing ? 'white' : (props.isDefault ? 'var(--gray-700)' : '#bf1f1f'))};
    padding: 9px 16px 9px 16px;
    font-weight: 500;
    margin-left: -14px;
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out 250ms all;

    &:hover {
        background-color: ${(props) => (props.isEditing ? '#19c27e' : (props.isDefault ? 'transparent' : '#bf1f1f'))};
        // background-color: ${(props) => (props.isEditing ? '#19c27e' : '#bf1f1f')};
        color: ${(props) => (props.isDefault ? '#bf1f1f' : 'white')};
        transform: translateY(3px);
        transition: ease-in-out 250ms all;
    }
`;