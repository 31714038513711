import { useState, useCallback, useEffect } from 'react';

export const useResizableColumn = () => {
    const [columnWidths, setColumnWidth] = useState(() => {
        // Recuperar a largura do LocalStorage ou usar valor padrão
        const savedWidth = localStorage.getItem('columnWidth');
        const width = savedWidth ? parseInt(savedWidth, 10) : 500;
        return width > 0 ? width : 10;
    });

    // Salvar a largura no LocalStorage sempre que ela mudar
    useEffect(() => {
        localStorage.setItem('columnWidth', columnWidths.toString());
    }, [columnWidths]);

    const startResizing = useCallback((mouseDownEvent: any) => {
        const startX = mouseDownEvent.pageX;
        const startWidth = columnWidths;

        const handleMouseMove = (mouseMoveEvent: any) => {
            const currentX = mouseMoveEvent.pageX;
            const newWidth = startWidth + (currentX - startX);

            // Use requestAnimationFrame para suavizar a atualização do estado
            requestAnimationFrame(() => {
                setColumnWidth(newWidth);
            });
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [columnWidths]); // Dependência do estado atual da largura

    return { columnWidths, startResizing };
};
