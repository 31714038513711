import styled from 'styled-components';

type ContainerProps = {
    showSignUp: boolean;
};

export const SidesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    // height: 100vh;
    overflow: hidden;
    padding: 1rem;
    // background-color: #fbfbfb;
    // border:1px solid #f1f1f1;   
    transition: all ease 250ms;
    border-radius: 30px;
    // width: 70rem;

    @media (max-width: 1024px) {
        width: fit-content;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1024px) {
            justify-content: center;
            margin-top: -2rem;
            width: fit-content;
        }
    }

    form > * {
        margin-bottom: 1rem;
    }

    form > *:last-child {
        margin-bottom: 0;
    }
`

export const PaymentOptionContainer = styled.div`
    display: flex;
    padding: 0.5rem;
    height: 26px;
    border-radius: 6px;
    width: 350px;
    align-items: center;
    font-weight: 500;
    color: #5d7375;
    justify-content: space-between;
    background-color: #f0f0f0;
    font-size: 0.75rem;

    @media (max-width: 1024px) {
        display: none;
    }
  
    `

export const InnerOption = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 18px;  
        margin-top: 0.1rem;
        fill: #19c27e;
    }

    img {
        margin-right: 0.5rem;
        display: flex;
        width: 20px;
    }
`

export const StepsContainer = styled.div`
width: 100%;
margin-top: 1.5rem;
margin-bottom: 1rem;
display: flex;
flex-direction: column;
justify-content: center;
gap: 0.5rem;
align-items: center;

p {
    font-size: 0.80rem;
    color: #5d7375;
}
`

export const Steps = styled.div`
 display: flex;
 flex-direction: row;
gap: 1rem;
width: 100%;
`

interface StepProps {
    active: boolean;
}

export const Step = styled.div<StepProps>`
    height: 4px;
    background-color: ${props => props.active ? '#19c27e' : '#e7e7e7'};
    border-radius: 50px;
    width: 90%;
`

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // padding: 2rem 0rem 1rem 4rem;
    min-height: 100vh;
    height: auto;

    @media (max-width: 1024px) {
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        overflow: scroll;
    }
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    // overflow-y: auto;
    // overflow-x: hidden;
`;


export const MainTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0 0 0;

    @media (max-width: 1024px) {
        padding: 1rem 0 0 0;
        transform: scale(0.8);
        // margin-left: 2.5rem;
    }
`

export const Logo = styled.div`
    img {
        width: 2rem;
        height: 2rem;
    }
    margin-bottom: 0.5rem;
`;

export const Title = styled.div`
    font-size: 1.50rem;
    line-height: 1.925rem;
    font-weight: 550;
    color: #1e1e1e;
    // margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
    font-size: 0.875rem;
    // line-height: 1.25rem;
    color: #5d7375;
    max-width: 100%;
`;

export const SubtitleSection = styled.div`
    // padding-bottom: 2.5rem;
    max-width: 100%;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex: 1;
    // max-height: 620px;


    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const VerticalLine = styled.div`
    height: 80%;
    width: 1px;
    background-color: #e7e7e7;
`;

export const LeftColumn = styled.div`
    // background: #1e1e1e;
    // width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all ease 250ms;

    align-items: center;
    justify-content: center;
    // border: 14px solid var(--white);
    padding: 1rem;
    // background-position: 50%  30%;
    gap: 1rem;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const InputSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    > *:not(:last-child) {
        margin-right: 1rem;
    }

    @media (max-width: 1024px) {
        > *:not(:last-child) {
            margin-right: 1vw; /* Adjusts the margin in responsive design */
        }
    }
`;

export const BackButton = styled.div`

    font-size: 0.7rem;
    margin-right: 1rem;
    margin-top: 1rem;
    font-weight: 550;
    flex-direction: column;
    justify-content: center;
    color: var(--gray-700);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    svg {
        width: 1rem;
    }

    &:hover {
        text-decoration: underline;
        color: var(--green);
    }
    // @media(max-width: 1240px){
    //     padding-left: 5rem;
    // }
`;

export const ButtonSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`;

export const Button = styled.button`
    width: 15rem;
    height: 2.5rem;
    background-color: var(--green);
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    line-height: 1.428125rem;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin-top: 1rem;
    font-weight: 450;
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
`;

export const RecapchaSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-top: 1.6rem;
`;

export const FinalText = styled.div`
    width: 20rem;
    margin: 1.5rem auto 0rem;
    font-size: 0.65rem;
    font-weight: 500;
    text-align: center;
    color: #989c97;
    span {
        color: #4f6060;
        font-weight: 550;
    }
`;

export const AlreadyHaveAccount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.07rem;
    color: var(--green);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const FooterLine = styled.div`
    background-color: #eeeeee;
    width: 6rem;
    height: 1px;
`;


export const PaymentMethodArea = styled.div<{isMobile: boolean, isSelected: boolean}>`
    display: flex;
    width: ${props => props.isMobile ? '20rem' : '27.8125rem' };
    margin-top: 0.5rem;
    align-items: center;
    padding: 1rem;
    border: ${props => props.isSelected ? '1px solid #19c27e' : '1px solid var(--gray-200)'};
    box-shadow: ${props => props.isSelected ? '#d4dad4 0px 0px 25px -9px' : 'none'};
    border-radius: 12px;
    justify-content: space-between;
    height: 2.5rem;
    gap:1rem;
    flex-direction: row; 
`;

export const PaymentInfoText = styled.div<{isMobile: boolean}>`
    font-size: ${props => props.isMobile ? ' 0.8rem' : ' 0.9rem'};
    color: var(--black);
    font-weight: 550;
    margin-bottom: 0.3rem;
    `

export const PaymentMethodInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    h2 {
        font-size: 0.8rem;
        color: var(--black);
        font-weight: 500;
    }

    p {
        font-size: 1rem;
        color: var(--gray-800);
    }
    
    span {
        background-color: var(--black);
        padding: 0.05rem 0.8rem;
        color: white;
        font-size: 0.6rem;
        border-radius: 50px;
    }
`

export const PaymentMethodIcon = styled.div`
    padding: 0.6rem;
    border-radius: 8px;
    border: 1px solid var(--gray-200);
    
    img {
        width: 1.5rem;
    
    }   
`;

export const PaymentMethodInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0rem;
    
`;

export const ButtonText = styled.div<{ isDefault?: boolean, isEditing?: boolean }>`
    background-color: ${(props) => (props.isEditing ? '#19c27e' : 'transparent')};
    border: none;
    border-radius: 312px;
    color: ${(props) => (props.isEditing ? 'white' : (props.isDefault ? 'var(--gray-700)' : '#bf1f1f'))};
    padding: 5px 8px;
    font-weight: 550;
    margin-left: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => (props.isEditing ? '11px' : '14px')};
    cursor: pointer;
    transition: ease-in-out 250ms all;

    svg {
        width: 3rem;
        margin-right: 5px;
    
    }

    &:hover {
        background-color: ${(props) => (props.isEditing ? '#19c27e' : (props.isDefault ? 'transparent' : '#bf1f1f'))};
        // background-color: ${(props) => (props.isEditing ? '#19c27e' : '#bf1f1f')};
        color: ${(props) => (props.isDefault ? '#bf1f1f' : 'white')};
        transform: translateY(3px);
        transition: ease-in-out 250ms all;
    }
    `;
