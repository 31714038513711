import styled from 'styled-components';

// Dentro do seu arquivo de estilos do Styled Components
export const ChartModal = styled.div`
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 70%;
    // height: 290px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1050px) {
        max-width: 100vw;
        width: 100vw;
        padding: 5px;
        border-radius: 5px;
    }
`;

export const FlexHeaderModal = styled.div`
    /* Adicione os estilos necessários aqui */
`;

export const MainChartContainer = styled.div`
    width: 100%;
    // margin-left: -16px;
    height: 350px;
    margin-top: -7px;

    @media (max-width: 1050px) {
        height: 200px;
    }
`;

export const ChartTitle = styled.h1`
    color: #081b16;
    font-size: 18px;
    /* Outros estilos necessários */
`;

export const ChartSubTitle = styled.p`
    color: #8d8d8d;
    font-size: 13px;
    /* Outros estilos necessários */
`;

export const FooterContent = styled.div`
    /* Adicione os estilos necessários aqui */
    display: flex;
    justify-content: center;
    /* colocar uma linha entre o footer e o gráfico */
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
`;
