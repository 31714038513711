import * as S from './style';
import Skeleton from '@mui/material/Skeleton';

export function LineCostOperationLoading({ tab }: { tab: string }) {
    return (
        <S.Container>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.DefaultColumn>
            {tab === 'Entrada' && (
                <S.DefaultColumn>
                    <Skeleton
                        sx={{
                            fontSize: '1rem',
                            width: '4rem',
                            height: '2rem',
                            marginRight: '0.5rem',
                        }}
                    />
                    <Skeleton sx={{ fontSize: '1rem', width: '1.5rem', height: '2rem' }} />
                </S.DefaultColumn>
            )}
        </S.Container>
    );
}
