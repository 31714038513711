import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
`;

export const InnerContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    width: 36.25rem;
    height: auto;
    max-height: 100vh;
    padding: 0 0.5rem 1rem;
`;

export const AccountsSection = styled.div`
    max-height: 50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
`;

export const SectionImages = styled.div`
    margin-top: 1.5rem;
    margin-left: 1.1rem;
    img {
        max-width: 2rem;
        max-height: 2rem;
    }

    svg {
        rotate: 90deg;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
`;

export type TitleProps = {
    typeAccount: string;
};

export const Title = styled.div<TitleProps>`
    font-size: 1.4375rem;
    font-weight: 600;
    line-height: 1.725rem;
    color: #1e1e1e;
    margin-top: 1.6rem;
    margin-left: 1.1rem;
    span {
        ${(props) => props.typeAccount === 'facebook_ads' && 'color: #3668f5;'}
        ${(props) => props.typeAccount === 'tiktok_ads' && 'color: #fe2c55;'}
${(props) => props.typeAccount === 'google_ads' && 'color: #f5a623;'}
    }
`;

export const SubTitle = styled.div`
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.21875rem;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    color: #989c97;
    margin-top: 0.4rem;
    margin-bottom: 0.7rem;
`;

export const HeaderAccount = styled.div`
    svg {
        cursor: pointer;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    display: flex;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    height: 4rem;
    border: 1px solid #e8e7e7;
    border-radius: 7px;
    align-items: center;
`;

export const TitleAccount = styled.div`
    width: 100%;
    font-size: 0.9375rem;
    color: var(--black);
    font-weight: 600;
    line-height: 1.125rem;
`;

export const ErrorContainer = styled.div`
    width: 33.375rem;
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
    margin: 0 1rem 1rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0;
    span {
        font-weight: 550;
    }
`;

export const Lines = styled.div`
    max-height: 14rem;
    overflow-y: auto;
`;

export const FinalSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
`;

const pulse = keyframes`

                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
    
`;

export const LoadingSection = styled.div`
    display: flex;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    height: 4rem;
    border: 1px solid #e8e7e7;
    background: #dddddd;
    border-radius: 7px;
    align-items: center;
    animation: ${pulse} 1.5s infinite ease-in-out;
`;
