import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;


export async function getOrders(start_date: string, end_date: string, company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/orders?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
    return data;
}

export async function updateOrder(body: DTO.updateOrderDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/io-handlers/orders`, body);
    return data;
}
