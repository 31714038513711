import { GraphProps } from '../../../../@types';
import { getMetricsByPlatform } from '../../utils';
import { InfoTooltip } from '../InfoTooltip';
import { LineOverviewChannel } from './LineOverviewChannel';
import * as S from './style';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import Tooltip from '@mui/material/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export function OverviewChannel({ isLoading, data }: GraphProps) {
    const { platformsData, totalSales } = getMetricsByPlatform(data);
    const navigate = useNavigate();    

    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />
            </S.ContainerLoading>
        );
    }

    return (
        <S.Container>
            <S.Title>Overview Canal
            <Tooltip title={generateTextForTooltip()} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                <InfoRoundedIcon sx={{width: '15px', marginLeft: '0px', fill: '#7b9898'}}/>
            </Tooltip>
            </S.Title>            
            <S.Button onClick={() => navigate('/tracking')}>Ver mais</S.Button>
            <S.InnerContainer>
                <S.Header>
                    <S.Column>Canal</S.Column>
                    <S.Column>Receita</S.Column>
                    <S.Column>Gasto</S.Column>  
                    <S.Column>CPA</S.Column>
                    <S.Column>Vendas</S.Column>
                    <S.Column>% Vendas</S.Column>
                </S.Header>
                <S.Line></S.Line>
                <S.Lines>
                    {platformsData?.map((platform) => {
                        return (
                            <LineOverviewChannel
                                key={platform.platformName}
                                type={platform.platformName}
                                spend={platform.spend}
                                revenue={platform.revenue}
                                cpa={platform.cpa}
                                sales={platform.sales}
                                totalSales={totalSales}
                            />
                        );
                    })}
                </S.Lines>
            </S.InnerContainer>
        </S.Container>
    );
}

// Corrigido: Agora retorna JSX diretamente
function generateTextForTooltip() {
    const { showArticle } = useIntercom();
    return (
        <p>Para aproveitar <span style={{ fontWeight: 550 }}>ao máximo esta visualização</span>, é importante ter suas <span style={{ fontWeight: 550 }}>UTMs configuradas</span>.
            <br />
            <br />
            As UTMs ajudam a<span style={{ fontWeight: 550 }}> rastrear com precisão o tráfego e as conversões</span>. 
            <br />
            <br />
            Precisa de ajuda com UTMs? <span style={{ fontWeight: 550 }}>Confira nosso guia</span>
            <br />
            
            <a style={{ color: '#21a4e6', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => showArticle(9062698)}>[Como configurar minhas UTMs?]</a>.
        </p>
    );
}
