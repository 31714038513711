import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getCompanyNicho(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/nicho?company_id=${company_id}`);
    return data;
}

export async function submitCompanyNicho(body: DTO.submitCompanyNichoDTO): Promise<AxiosResponse> {
    const { company_id, nicho } = body;

    console.log('nicho post', body);

    const newBody = { company_id, nicho  };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/nicho`, newBody);
    
    return data;
}
