import styled from 'styled-components';
import seta from './../../../../../assets/images/seta.png'

export const Container = styled.div`
    padding: 1rem;
    
`;

export const Logo = styled.div`
    img {
        width: 1.4375rem;
        height: 1.4375rem;
    }
`;

type TitleProps = {
    baseColor: string;
};

export const Title = styled.div<TitleProps>`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--black);
    margin-top: 2rem;
    span {
        color: ${(props) => props.baseColor};
    }
`;

export const Subtitle = styled.div`
    margin-top: 0.4rem;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.125rem;
    color: var(--gray-700);
    margin-bottom: 1.5rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #ccc;
`;

export const SelectTitle = styled.div`
font-size: 0.9rem;
font-weight: 500;
line-height: 1.2rem;
color: #1e1e1e;
margin-top: 0.5rem;
margin-bottom: -0.5rem;
margin-left: 0.8rem;
`

export const SelectType = styled.div`
select {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        border-radius: 8px;
        border: 1px solid var(--green);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(${seta}) no-repeat center right #fff;
        background-size: 1rem;
        background-position: 31rem 0.75rem;

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border-color: rgba(76, 234, 134, 0.4);
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: 1px solid var(--green);
        }
    }
`

// export const InputValue = styled.div`
// position: relative;
// margin-bottom: 1.5rem;
// // input {
// //         margin-top: 0.5rem;
// //         height: 2.5rem;
// //         width: 29rem;
// //         margin-bottom: 0.5rem;
// //         padding-left: 3rem;
// //         padding-right: 1rem;
// //         border-radius: 8px;
// //         border: 1px solid var(--green);
// //         background-color: #fff;
// //         color: var(--black);
// //         font-weight: 500;

// //         &::placeholder {
// //             color: var(--gray-700);
// //             font-weight: 500;
// //         }

// //         &:focus,
// //         &:hover {
// //             outline: none;
// //             border-color: rgba(76, 234, 134, 0.4);
// //             background-color: #fff;
// //             box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
// //             border: 1px solid var(--green);
// //         }
// //     }
// `

// export const InputIcon = styled.div`
// position: absolute;
// top: 0.9rem;
// left: 0.6rem;
// font-size: 1.25rem;
// font-weight: 500;
// line-height: 1.875rem;
// color: var(--green);
// width: max-content;
// display: flex;
// align-items: center;
// justify-content: center;
// gap: 0.5rem;
// `

export const FinalContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 1rem;
margin-top: 1rem;
`

export const FinalText = styled.div`
font-size: 0.75rem;
font-weight: 600;
line-height: 1.125rem;
color: #656565;
`

export const FinalButton = styled.div`

`

export const InnerContainer = styled.div`
    margin-top: 2rem;
    border: 1px solid var(--gray-120);
    border-radius: 10px;
    width: 100%;
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0.2rem;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }

    select {
        height: 2.5rem;
        width: 126%;
        padding: 0 1rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(${seta}) no-repeat center right #fff;
        background-size: 1rem;
        background-position: 32rem 0.75rem;

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border: 1px solid var(--green);
            /* outline: none; */
            /* border-color: rgba(76, 234, 134, 0.4); */
            /* background-color: #fff; */
            /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
            /* border: 1px solid var(--green); */
        }
    }
`;

export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;