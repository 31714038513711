import { submitLandingPage, getLandingPage } from '../api/api-landing-page';
import { useQuery, useMutation } from 'react-query';

export function useSubmitLandingPage() {
    return useMutation(submitLandingPage);
}

export function useGetLandingPage(company_id: string) {
    return useQuery(['getLandingPage', company_id], () => getLandingPage(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
