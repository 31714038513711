import styled from 'styled-components';

export const Container = styled.div`
    width: 9rem;
`;

export const Graphic = styled.div`
    svg {
        transform: scale(0.8) rotate(-90deg);
    }
    position: relative;
    width: 7.5rem;
    height: 7.5rem;
    margin: auto;
`;

export const Information = styled.div`
    font-size: 0.6rem;
    line-height: 0.8125rem;
    color: #034141;
    display: flex;
    width: 9.2rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.6rem;
    svg {
        margin-right: 0.1rem;
    }
    > span {
        width: 3.5rem;
    }
`;

export const ValueInformation = styled.div`
    height: 1.09375rem;
    width: 12rem;
    background-color: #f4f4f4;
    color: #034141;
    font-size: 0.65rem;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin-left: 0.15rem;
    
    p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    }

    span {
    color: #4f6060;
    white-space: nowrap;
    font-size: 0.6rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    }
`;

export const TypePaymentsContainer = styled.div`
    margin-left: 1rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: -0.8rem;
`;

type TypePaymentsValueProps = {
    color: string;
};

export const TypePayments = styled.div`
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: #999;
    display: flex;
    align-items: center;
    svg {
        margin-right: 0.2rem;
        color: #999;
        transform: scale(1) rotate(0deg);
    }
`;

export const PaymentsValue = styled.div<TypePaymentsValueProps>`
    font-size: 0.75rem;
    font-weight: 500;
    color: ${(props) => props.color};
    span {
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 500;
    }
`;
