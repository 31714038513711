import { CardConversions } from './CardConversions';
import * as S from './style';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PixOutlinedIcon from '@mui/icons-material/PixOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { GraphProps } from '../../../../@types';
import { getMetricsOrdersByPayments, getMetricsRevenueByPayments } from '../../utils';
import { InfoTooltip } from '../InfoTooltip';
import Tooltip from '@mui/material/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export function Conversions({ isLoading, data }: GraphProps) {
    const {
        cardPaymentApprovedOrders,
        cardPaymentPendingOrders,
        cardPaymentCancelledOrders,
        pixPaymentApprovedOrders,
        pixPaymentCancelledOrders,
        pixPaymentPendingOrders,
        billetPaymentApprovedOrders,
        billetPaymentCancelledOrders,
        billetPaymentPendingOrders,
    } = getMetricsOrdersByPayments(data);
    const {
        cardPaymentApprovedRevenue,
        cardPaymentCancelledRevenue,
        cardPaymentPendingRevenue,
        pixPaymentApprovedRevenue,
        pixPaymentCancelledRevenue,
        pixPaymentPendingRevenue,
        billetPaymentApprovedRevenue,
        billetPaymentCancelledRevenue,
        billetPaymentPendingRevenue,
    } = getMetricsRevenueByPayments(data);

    if (isLoading) {
        return (
            <S.Container>
                <S.LoadingContainer />
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Title>
                Conversões
                <Tooltip title="Conversões aprovadas" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <InfoRoundedIcon sx={{width: '15px', marginLeft: '0px', fill: '#7b9898'}}/>
                </Tooltip>
            </S.Title>

            <S.InnerContainer>
                <S.Content>
                    <CardConversions
                        type="Cartão"
                        approvedOrders={cardPaymentApprovedOrders}
                        pendingOrders={cardPaymentPendingOrders}
                        cancelledOrders={cardPaymentCancelledOrders}
                        pendingRevenue={cardPaymentPendingRevenue}
                        approvedRevenue={cardPaymentApprovedRevenue}
                        cancelledRevenue={cardPaymentCancelledRevenue}
                        color="#c24ce0"
                        Icon={CreditCardOutlinedIcon}
                    />
                </S.Content>

                <S.Content>
                    <CardConversions
                        type="Pix"
                        approvedOrders={pixPaymentApprovedOrders}
                        pendingOrders={pixPaymentPendingOrders}
                        cancelledOrders={pixPaymentCancelledOrders}
                        pendingRevenue={pixPaymentPendingRevenue}
                        approvedRevenue={pixPaymentApprovedRevenue}
                        cancelledRevenue={pixPaymentCancelledRevenue}
                        color="#19c27e"
                        Icon={PixOutlinedIcon}
                    />
                </S.Content>

                <S.Content>
                    <CardConversions
                        type="Boleto"
                        approvedOrders={billetPaymentApprovedOrders}
                        pendingOrders={billetPaymentPendingOrders}
                        cancelledOrders={billetPaymentCancelledOrders}
                        pendingRevenue={billetPaymentPendingRevenue}
                        approvedRevenue={billetPaymentApprovedRevenue}
                        cancelledRevenue={billetPaymentCancelledRevenue}
                        color="#195dc2"
                        Icon={ReceiptOutlinedIcon}
                    />
                </S.Content>
            </S.InnerContainer>
        </S.Container>
    );
}
