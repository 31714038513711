import { submitCompanyCurrency, getCompanyCurrency } from '../api/api-currency';
import { useQuery, useMutation } from 'react-query';

export function useSubmitCompanyCurrency() {
    // console.log('useSubmitCompanyCurrency');
    return useMutation(submitCompanyCurrency);
}

export function useGetCompanyCurrency(company_id: string, enabled: boolean) {
    return useQuery(['getCompanyCurrency', company_id], () => getCompanyCurrency(company_id), {
        enabled, // Passando o parâmetro enabled aqui
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
