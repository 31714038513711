import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(0deg, #FFFFFF 15.74%, #A5E3CB 698.7%);
`;


export const ButtonContainer = styled.div`
margin-top: 2rem;
`

export const ContainerImage = styled.div`
img{
  width: 9.375rem;
}
`

const floating = keyframes`
0%, 100% {
    transform: translateY(0px);
}
50% {
    transform: translateY(-10px);
}
`

const bounce = keyframes`
0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
}
10% {
    transform: translateY(-30px);
}
30% {
    transform: translateY(-15px);
}
`

export const ContainerInformation = styled.div`
display: flex;
flex-direction: column;
align-items: center;
position: relative;
>svg{
  animation: ${bounce} 4s, ${floating} 3s ease-in-out infinite 2s;
  width: 5.125rem;
  height: 5.125rem;
  fill: #19C27E;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}
`

export const StarIconUp = styled.span`
    position: absolute;
    /* left: 0; */
    /* top: 0; */
    margin-left: 27rem;
    transform: rotate(5deg);
    margin-top: -5rem;
svg{
  width: 4.125rem;
  height: 4.125rem;
  fill: #19C27E;
  animation: ${floating} 3s ease-in-out infinite;
}
`

export const Fireworks = styled.span`
width: 15rem;
height: 15rem;
position: absolute;

>svg{
  animation: ${bounce} 4s, ${floating} 3s ease-in-out infinite 2s;
  width: 5.125rem;
  height: 5.125rem;
  fill: #19C27E;
//   margin-top: 4rem;
//   margin-bottom: 1.5rem;
}
`

export const StarIconDown = styled.span`
    position: absolute;
    margin-top: -22rem;
    margin-left: -26rem;
    transform: scale(-1) rotate(-9deg);
svg{
  width: 3.5rem;
  height: 3.5rem;
  fill: #19C27E;
  animation: ${floating} 3s ease-in-out infinite;
}
`

export const Title = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: 1.75rem;
font-weight: 500;
width: 100%;
text-align: center;
color: #666;
// margin-bottom: 0.8rem;
span{
    color: #19C27E;
    font-weight: 550;
    font-size: 1.75rem;
    margin-bottom: 0.2rem;
}
`

export const Subtitle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
color: #7e7e7e;
width: 90%;
text-align: center;
font-size: 1.2rem;
font-weight: 400;
`

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    width: 45%;
    min-width: 30rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 2rem;
`;

const animateBackground = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

export const TopBanner = styled.div`
    width: 100%;
    border-radius: 16px;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg, #f5f5f5, #e8ecea, rgb(0, 193, 145, 0.05));
    background-size: 600% 600%;
    animation: ${animateBackground} 20s ease infinite;
    margin-bottom: 1rem;
    
    svg {
      width: 6rem;
        height: 6rem;
          animation: ${bounce} 4s, ${floating} 3s ease-in-out infinite 2s;

        position: absolute;
        fill: #19C27E;
        
        }
`;

export const ProgressBar = styled.div`
    height: 8px;
    background-color: #e9e9e9;
    // margin-top: 20px;
    width: 100%;
    border-radius: 50px;
    transition: width 0.5s ease;
`