import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 29.6875rem;
    height: 15.625rem;
    border-radius: 12px;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const Text = styled.div`
    position: absolute;
    border: 1px solid var(--gray-200);
    width: max-content;
    display: none;
    padding: 0.3rem 0.5rem;
    background-color: var(--white);
    bottom: 1rem;
    left: 6.5rem;
    z-index: 3;
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--black);
`;

export const Info = styled.div`
    &:hover {
        cursor: pointer;
        ${Text} {
            display: block;
        }
    }
`;

export const InnerContainer = styled.div`
    display: flex;
    margin-top: 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

export const Content = styled.div``;
