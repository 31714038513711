import { StorageAuthTokenProps } from '../@types';
import { AUTH_STORAGE } from '../storage/storageConfig';

export function storageAuthTokenSave({ access_token, session_token }: StorageAuthTokenProps) {
    localStorage.setItem(AUTH_STORAGE, JSON.stringify({ access_token, session_token }));
}

export function storageAuthTokenGet() {
    const response = localStorage.getItem(AUTH_STORAGE);

    const { access_token, session_token }: StorageAuthTokenProps = response ? JSON.parse(response) : {};

    return { access_token, session_token };
}

export function storageAuthTokenRemove() {
    localStorage.removeItem(AUTH_STORAGE);
}
