import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
`;

export const CardsContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
`;


export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`



export const UpdateButtonContainer = styled.button`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: var(--white);
`;

export const ContainerPopup = styled.div`
    width: 37.5rem;
    height: 23.75rem;
    border-radius: 20px;
    background-color: var(--white);
`;

function getFilter(type: string) {
    switch (type) {
        case 'Fiéis':
            return '';
        case 'Novatos':
            return 'filter: saturate(0)';
        case 'Em risco':
            return 'filter: saturate(0.5) hue-rotate(334deg);';
    }
}

type ImagePopupProps = {
    type: string;
};

export const ImagePopup = styled.div<ImagePopupProps>`
    img {
        margin-top: 1.2rem;
        margin-left: 1.2rem;
        width: 1rem;
        ${(props) => getFilter(props.type)};
    }
`;

export const TitlePopup = styled.div`
    font-size: 1.25rem;
    font-weight: 650;
    line-height: 1.5rem;
    color: var(--green);
    margin-left: 1.2rem;
    margin-top: 2rem;
`;

export const SubtitlePopup = styled.div`
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 500;
    color: #989c97;
    margin: 0 1.2rem;
    margin-top: 0.5rem;
`;

export const InformartionContainerPopup = styled.div``;

export const InformationInnerContainerPopup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
    margin-top: 1.5rem;
    gap: 1.5rem;
`;

export const InformationContainerTitlePopup = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin: 0 1.2rem;
    margin-top: 2rem;
`;

export const InformationSectionPopup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 4.2rem;
`;

export const InformationOptionPopup = styled.div`
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #656565;
`;

export const InformationValuePopup = styled.div`
    font-size: 0.65625rem;
    font-weight: 600;
    line-height: 0.984375rem;
    color: #5fa779;
    background-color: #ecffef;
    width: fit-content;
    padding: 0.1rem 0.5rem;
    border-radius: 50px;
`;

export const Line = styled.div`
    background-color: #d9dbd9;
    height: 1px;
    display: flex;
    flex: 1;
    margin-left: 1rem;
`;
