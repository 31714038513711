import styled, { keyframes } from 'styled-components';


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const moveLogo = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
        

`;


export const Container = styled.div`
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: linear-gradient(274deg, rgba(238,238,241,0.7) 0%, #f8f8f8 59%);
    background-size: 200% 200%;  // Torna o gradiente largo o suficiente para suportar o movimento
    animation: ${gradientAnimation} 3s ease infinite;  // Aplica a animaçãodisplay: flex;
`;

export const Text = styled.div`
    color: #ccc;
    font-family: 'Montserrat';
    // opacity: 0;
    font-size: 1rem;
    margin-top: 0.5rem;
    animation: ${moveLogo} 5s infinite;
`;
