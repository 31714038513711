import * as S from './style';
import { LineTableTrackingChannel } from './LineTableTrackingChannel';
import { calculateFinalOrders, calculateFinalRevenue, getTotalLineByChannel } from '../utils';
import { TableTrackingChannelProps } from '../../../@types';
import { useMemo } from 'react';
import { useColumnsTracking } from '../../../hooks/useColumnsTracking';
import React, {useEffect, useState} from 'react';
import { useResizableColumn } from '../../../hooks/useResizableColumn';
import { useIntercom } from 'react-use-intercom';
import Tooltip from '@mui/material/Tooltip';
import { useCurrencyType } from '../../../hooks/useCurrencyType';
import TotalRowComponent from '../TotalRowComponent';

export function TableTrackingChannel({ columnSolomon, paymentMethod, isCatalogChannel, handleSelectGraphicData, handleScrolltoGraphic, dataMetrics, filtred, openSalesModal, date, loadingCatalog }: TableTrackingChannelProps) {
    const [activePaymentMethods, setActivePaymentMethods] = useState([]);

    useEffect(() => {
        if (paymentMethod) {
    
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect

    const totalLine = useMemo(() => getTotalLineByChannel(dataMetrics, activePaymentMethods), [dataMetrics, activePaymentMethods]);

    const {currencySymbol} = useCurrencyType();
    
    const { columnWidths, startResizing } = useResizableColumn();

    const { showArticle } = useIntercom();	

    const { columns } = useColumnsTracking();
    
    const revenueSolomon = calculateFinalRevenue(columnSolomon, totalLine, activePaymentMethods);
    const ordersSolomon = calculateFinalOrders(columnSolomon, totalLine, activePaymentMethods);

    return (
        <S.Container>
            <S.TableHeader>

                <S.MainColumn style={{ width: `${columnWidths}px` }}>
                    Nome da plataforma
                    <div
                        style={{
                            cursor: 'col-resize',
                            backgroundColor: '#ccc',
                            width: '7px',
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}
                        onMouseDown={startResizing}
                    />
                </S.MainColumn>

                {columns.sort((a, b) => a.order - b.order).map(column => column.visible && (
                    <React.Fragment key={column.id}>
                        {column.id !== 'salesUtm' && column.id !== 'revenueUtm' && column.id !== 'roasUtm' && column.id !== 'ticketUtm' && column.id !== 'cpaUtm' && column.id !== 'profit' && column.id !== 'margin' && column.id !== 'productCostUtm' && (
                            <S.DefaultColumn>
                                <span>
                                {column.name}
                                </span>
                                <div>
                                <Tooltip title="Facebook Ads" placement="top">
                        
                                <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" alt="Solomon" />
                                </Tooltip>

                                <Tooltip title="Tiktok Ads" placement="top">

                                <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" alt="Solomon" />
                                </Tooltip>

                                <Tooltip title="Google Ads" placement="top">

                                <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" alt="Solomon" />

                                </Tooltip>

                                <Tooltip title="Orgânico / Outros" placement="top">

                                <img src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" alt="Solomon" /> 

                                </Tooltip>
                                </div>
                            </S.DefaultColumn>
                        )}
                        {(column.id === 'profit' || column.id === 'productCostUtm') &&(
                            <S.DefaultColumnSolomon onClick={() => {showArticle(9062698)}}>
                                {column.name}
                                <span>
                                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                                    Aprovado
                                </span>
                            </S.DefaultColumnSolomon>
                        )}
                        {column.id === 'margin' &&(
                            <S.DefaultColumnSolomon onClick={() => {showArticle(9062698)}}>
                                {column.name}
                                <span>
                                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                                    {`${columnSolomon === 'totalSolomon' ? 'Total' : columnSolomon === 'approvedSolomon' ? 'Aprovada' : columnSolomon === 'pendingSolomon' ? 'Pendente' : 'Cancelada'}`}
                                </span>
                            </S.DefaultColumnSolomon>
                        )}
                        {(column.id === 'salesUtm' || column.id === 'revenueUtm' || column.id === 'roasUtm' || column.id === 'cpaUtm' || column.id === 'ticketUtm') && (
                            <S.DefaultColumnSolomon onClick={() => {showArticle(9062698)}}>
                                {column.name}
                                <span>
                                    <img src="https://storage.googleapis.com/static-images-source/favicon.png" alt="Solomon" />
                                    {`${columnSolomon === 'totalSolomon' ? 'Total' : columnSolomon === 'approvedSolomon' ? 'Aprovadas' : columnSolomon === 'pendingSolomon' ? 'Pendente' : 'Cancelada'}`}
                                </span>
                            </S.DefaultColumnSolomon>
                        )}
                    </React.Fragment>
                ))}
            </S.TableHeader>

            <S.Lines>
                {['Facebook Ads', 'Google Ads', 'Tiktok Ads', 'Outros']
                    .filter((item) => item.toLowerCase().includes(filtred.toLowerCase()))
                    .map((item, index) => {
                        const newType =
                            item === 'Facebook Ads'
                                ? 'facebook'
                                : item === 'Google Ads'
                                    ? 'google'
                                : item === 'Tiktok Ads'
                                    ? 'tiktok'
                                        : item === 'Outros'
                                                ? 'outros'
                                                    : '';
                        return (
                            <LineTableTrackingChannel
                                handleSelectGraphicData={handleSelectGraphicData}
                                columnSolomon={columnSolomon}
                                paymentMethod={paymentMethod}
                                type={newType}
                                data={dataMetrics}
                                handleScrolltoGraphic={handleScrolltoGraphic}
                                openSalesModal={openSalesModal}
                                date={date}
                                isCatalogChannel={isCatalogChannel}
                                key={index}
                                columnWidths={columnWidths} // Adicionar isso aqui
                                loadingCatalog={loadingCatalog}
                            />
                        );
                    })}
            </S.Lines>
            <TotalRowComponent columnWidths={columnWidths} name={'Canal'} quantity={'0'} columns={columns} currencySymbol={currencySymbol}  validator={1} totalLine={totalLine} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon}/>
            
        </S.Container>
    );
}
