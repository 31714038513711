import { useQuery, useMutation } from 'react-query';
import { createCost, deleteCost, getCosts, getHistoryCost, updateCost } from '../api/api-cost';
import useAlert from '../../hooks/useAlert';


export function useCreateCost() {
    const { showAlert } = useAlert();

    return useMutation(createCost, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Custo atualizado com sucesso!', 'success', 5000, true);
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao atualizar o custo. Tente novamente.', 'error', 5000, true);
        },
    });
}

export function useDeleteCost() {
    return useMutation(deleteCost);
}

export function useGetCosts(company_id: string) {
    return useQuery(['costs', company_id], () => getCosts(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetHistoricalCosts(company_id: string) {
    return useQuery(['historical_costs', company_id], () => getHistoryCost(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useUpdateCost() {
    return useMutation(updateCost);
}
