import styled from 'styled-components';

export const Container = styled.div`
    // border-radius: 10px;
    margin-top: 5rem;
    position: relative;
    border-right: 1px solid var(--gray-150);
    // border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-x: auto;
    height: auto;
    max-height: 32.3rem;
    border-collapse: collapse;
    &::-webkit-scrollbar {
    height: 10px; /* Altura da barra de rolagem */
}
`;

export const TableHeader = styled.div`
    display: flex;
    // border-top-right-radius: 10px;
    // // border-top-left-radius: 10px;
    border-top: 1px solid var(--gray-150);
    min-width: 220rem;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-150);
`;

export const Lines = styled.div`
    > div:nth-child(odd) {
        background-color: var(--white) !important;
    }

    > div:nth-child(even) {
        background-color: #f5f6f7 !important;
    }
`;

export const MainColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--black);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    // border-top-left-radius: 9.5px;
    padding-left: 1rem;
    padding-right: 3rem;
    width: 29.125rem;
    height: 3.4375rem;
    position: sticky;
    left: 0;
    z-index: 5;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    // border-top: 1px solid var(--gray-150);
    background-color: inherit;
    
`;

export const DefaultColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 10.25rem;
    // border-top: 1px solid var(--gray-150);
    border-right: 1px solid var(--gray-150);
    &:last-child {
        border-right: none;
    }
`;

export const DefaultColumnSolomon = styled(DefaultColumn)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    img {
        width: 0.75rem;
        height: 0.75rem;
    }
    span {
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        font-size: 0.7rem;
        font-weight: 700;
        color: #399875;
    }
`;

export const TotalRow = styled.div`
    display: flex;
    min-width: 220rem;
    border-top: 1px solid var(--gray-150);
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-150);

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
    font-size: 0.875rem;
`;

export const TotalMainColumn = styled.div`
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 3rem;
    width: 29.125rem;
    height: 3.3rem;
    border-right: 1px solid var(--gray-150);
    border-left: 1px solid var(--gray-150);
    // border-bottom: 1px solid var(--gray-150);
    border-bottom-left-radius: 10px;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: inherit;
    background-color: var(--white);
`;

export const TotalDefaultColumn = styled.div`
    color: var(--black);
    // border-bottom: 1px solid var(--gray-150);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.3rem;
    width: 10.25rem;
    border-left: 1px solid transparent;
`;
