import { useEffect, useState } from 'react';
import * as S from './styles';
import { useUpdateFacebookAdsAccount, useUpdateGoogleAdsAccount, useUpdateTiktokAdsAccount } from '../../services/hooks/integrationHooks';
import useAuth from '../../hooks/useAuth';
import { userDTO } from '../../@types';


const SwitchAccount = ({facebookStatusInactive, setFacebookStatusInactive, googleStatusInactive, setGoogleStatusInactive, setTiktokStatusInactive, tiktokStatusInactive, account, profileId, type }: {facebookStatusInactive?: { inactive: boolean, account_id: string }, setFacebookStatusInactive?:any, setGoogleStatusInactive?:any, setTiktokStatusInactive?:any, googleStatusInactive?: { inactive: boolean, account_id: string }, tiktokStatusInactive?: { inactive: boolean, account_id: string }, account: any; profileId: string, type:string }) => {
    const [value, setValue] = useState(account?.status === 'active');
    
    const { user, currentCompany, updateUser, setUserContext } = useAuth();
    
    const { mutate: handleUpdateFacebookAccount } = useUpdateFacebookAdsAccount();
    const { mutate: handleUpdateGoogleAccount } = useUpdateGoogleAdsAccount();
    const { mutate: handleUpdateTikTokAccount } = useUpdateTiktokAdsAccount();
    
   
    useEffect(() => {
        // Garanta que só atualize quando o account_id coincidir com o especificado para inativar
        if (facebookStatusInactive?.inactive === true && type === 'facebook' && account?.status !== 'inactive' && account?.account_id === facebookStatusInactive?.account_id) {
            handleUpdateStatus('inactive', facebookStatusInactive?.account_id);
        }
        if (googleStatusInactive?.inactive === true && type === 'google' && account?.status !== 'inactive' && account?.account_id === googleStatusInactive?.account_id) {
            console.log('googlestatus', googleStatusInactive?.account_id)
            handleUpdateStatus('inactive', googleStatusInactive?.account_id);
        }
        if (tiktokStatusInactive?.inactive === true && type === 'tiktok' && account?.status !== 'inactive' && account?.account_id === tiktokStatusInactive?.account_id) {
            handleUpdateStatus('inactive', tiktokStatusInactive?.account_id);
        }
    }, [facebookStatusInactive, googleStatusInactive, tiktokStatusInactive]);

    const handleUpdateStatus = (newStatus, newAccountId) => {
        const newValue = newStatus === 'active';
        const accountId = newAccountId || account?.account_id;
        
        const body = {
            account_id: accountId,
            company_id: currentCompany,
            status: newStatus,
            profile_id: profileId,
        };
    
        setValue(newValue);

        const updateIntegrationStatus = (integrationType, updateFunction) => {
            const newUser = {
                ...user,
                companies: {
                    ...user?.companies,
                    [currentCompany]: {
                        ...user?.companies[currentCompany],
                        integrations: {
                            ...user?.companies[currentCompany]?.integrations,
                            [integrationType]: {
                                ...user?.companies[currentCompany]?.integrations[integrationType],
                                profiles: user?.companies[currentCompany]?.integrations[integrationType]?.profiles?.map((profile: any) => ({
                                    ...profile,
                                    accounts: profile?.accounts?.map((account_: any) =>
                                        account_?.account_id === accountId ? { ...account_, status: newStatus } : account_)
                                }))
                            }
                        }
                    }
                },
            };

            setUserContext(newUser as userDTO);

            updateFunction(body, {
                onSuccess: () => {
                    updateUser();

                    if (integrationType === 'facebook_ads' && setFacebookStatusInactive) {
                        setFacebookStatusInactive({inactive: false, account_id: ''});
                    }

                    if (integrationType === 'google_ads' && setGoogleStatusInactive) {
                        setGoogleStatusInactive({inactive: false, account_id: ''});
                    }

                    if (integrationType === 'tiktok_ads' && setTiktokStatusInactive) {
                        setTiktokStatusInactive({inactive: false, account_id: ''});
                    }
                },
                onError: () => {
                    setUserContext(user as userDTO);
                    setValue(!newValue);
                },
            });
        };

        if (type === 'facebook') {
            updateIntegrationStatus('facebook_ads', handleUpdateFacebookAccount);
        } else if (type === 'google') {
            updateIntegrationStatus('google_ads', handleUpdateGoogleAccount);
        } else if (type === 'tiktok') {
            updateIntegrationStatus('tiktok_ads', handleUpdateTikTokAccount);
        }
    };

    const handleToggle = () => {
        const newValue = !value;
        handleUpdateStatus(newValue ? 'active' : 'inactive', account?.account_id);
    };


    return (
        <S.SwitchContainer>
            <S.SwitchLabel>
                <S.SwitchInput type="checkbox" onChange={handleToggle} />
                <S.Slider isChecked={value} />
                <S.SliderBall isChecked={value} />
            </S.SwitchLabel>
        </S.SwitchContainer>
    );
};

export default SwitchAccount;
