import { LineOrdersModal } from "./LineOrdersModal"
import { LineSalesModalLoadingOrders } from "./LineSalesModalLoading"
import * as S from './style'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

export function TableSalesModal({ordersFiltered, isLoading, openClientModal, date, dontHaveOrders}: any) {

    const calculateTotals = (orders) => {
        return orders.reduce((acc, order) => {
            acc.totalValue += Number(order?.product_price) || 0;
            acc.totalProductCost += Number(order?.product_cost) || 0;
            acc.totalTax += (Number(order?.checkout_fee) + Number(order?.gateway_fee)) || 0;
            acc.totalGateway += Number(order?.gateway_fee) || 0;
            acc.totalCheckout += Number(order?.checkout_fee) || 0;
            return acc;
        }, {
            totalValue: 0,
            totalProductCost: 0,
            totalTax: 0,
            totalGateway: 0,
            totalCheckout: 0,
        });
    };
    
    const totals = calculateTotals(ordersFiltered);
    

 return (
    <>
<S.Header>
    <S.MainColumn>
        Pedido
    </S.MainColumn>
    <S.DefaultColumn>
        Status
    </S.DefaultColumn>
    <S.DefaultColumn>
        Data/hora
    </S.DefaultColumn>
    <S.DefaultColumn>
        Cliente
    </S.DefaultColumn>
    <S.DefaultColumn>
        Meio de Pagamento
    </S.DefaultColumn>
    <S.DefaultColumn>
        Valor
    </S.DefaultColumn>
    <S.DefaultColumn>
        Custo de Produto
    </S.DefaultColumn>
    <S.DefaultColumn>
        Taxas totais
        <Tooltip title="Gateway + Checkout" placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '9px',padding: '11px',color: 'white',textWrap: 'wrap',zIndex: 999999}},
        }}>
            <InfoRoundedIcon sx={{width: '15px', marginLeft: '0.5rem', fill: '#7b9898'}}/>

        </Tooltip>
    </S.DefaultColumn>
    <S.DefaultColumn>
        Gateway
    </S.DefaultColumn>
    <S.DefaultColumn>
        Checkout
    </S.DefaultColumn>
</S.Header>

<S.Lines dontHaveOrders={dontHaveOrders}>

    {!isLoading && ordersFiltered?.map((order: any) => {
        return<LineOrdersModal order={order} openClientModal={openClientModal} date={date}/>
    })}

    {!isLoading && (
        <LineOrdersModal
            order={null}
            openClientModal={openClientModal}
            date={date}
            totals={totals}
            isTotalRow={true}
        />
    )}
    
    {isLoading && Array.from({length: 5}).map(() => {
    return <LineSalesModalLoadingOrders />
})}
{(!isLoading && dontHaveOrders) && (
    <S.ContainerImage>
        <img src="https://solomon.com.br/wp-content/uploads/2023/10/vazio.psd.png" alt="Nenhum produto encontrado" />
        <S.TitleImage>Oops!</S.TitleImage>
        <S.SubtitleImage>Sem dados no período selecionado.</S.SubtitleImage>
        <S.SubtitleImage>Por favor, escolha um novo período.</S.SubtitleImage>
    </S.ContainerImage>
)}
</S.Lines>
</>
)}