// RefreshContext.js
import { createContext, useContext, ReactNode, useState } from 'react';

interface RefreshContextType {
  refreshData: () => void;
  refreshCounter: number;
}

const RefreshContext = createContext<RefreshContextType | undefined>(undefined);

export const useRefreshContext = () => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error('useRefreshContext must be used within a RefreshProvider');
  }
  return context;
};

interface RefreshProviderProps {
  children: ReactNode;
}

export const RefreshProvider = ({ children }: RefreshProviderProps) => {
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshData = () => {
    // Increment the refreshCounter to trigger useEffect in Dashboard
    setRefreshCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <RefreshContext.Provider value={{ refreshData, refreshCounter }}>
      {children}
    </RefreshContext.Provider>
  );
};
