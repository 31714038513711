import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
width: 57.375rem;
padding: 1rem;
height: auto;
min-height: 10rem;
max-height: 100vh;
background-color: #fff;
border-radius: 8px;
z-index: 999999;

::-webkit-scrollbar {
    height: 6px; /* Altura da barra de rolagem */
}

`

export const Icon = styled.div`
cursor: pointer;
`

export const Title = styled.div`
font-weight: 600;
line-height: 1.5rem;
color: #1e1e1e;
span{
    color: var(--green)
}
`

export const SearchSection = styled.div`
margin-top: -1rem;
margin-bottom: 1rem;
display: flex;
justify-content: flex-start;
`

export const Section = styled.div`
display: flex;
gap: 3rem;
flex-direction: row ;
margin-bottom: 2.5rem;
`

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
`;

export const ContainerImage = styled.div`
    display: flex;
    position: fixed;
    background-color: #fff !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    width: max-content;
    padding-bottom: 1rem;
    margin: 1.5rem auto 0;
    animation: ${floating} 2s ease-in-out infinite;
    
    img {
        width: 10.75rem;
        height: auto;
    }
`;

export const TitleImage = styled.div`
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: var(--green);
`;

export const SubtitleImage = styled.div`
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
`;


export const FirstSectionItem = styled.div`

`

export const FirstSectionHeader = styled.div`
display: flex;
`

export const FirstSectionValue = styled.div`
font-size: 1.2rem;
line-height: 1.35rem;
font-weight: 550;
margin-left: 1.5rem;
color: #000;
`

export const FirstSectionTitle = styled.div`
font-size: 0.9rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
display: flex;
gap: 0.5rem;

div {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    color: var(--green);
    font-weight: 550;
    img {
        width: 0.75rem;
    }
}
`

export const SquareIcon = styled.div`
width: 1rem;
height: 1rem;
border-radius: 0.25rem;
background-color: #ededed;
display: flex;
align-items: center;
justify-content: center;
margin-right: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`
export const SquareIconHelp = styled.div`
width: 1rem;
height: 1rem;
display: flex;
align-items: center;
justify-content: center;
margin-left: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`


export const FirstSection = styled.div`
display: flex;
flex-direction: row;
gap: 2rem;
`

export const VLine = styled.div`
    height: 4rem;
    width: 1px;
    background-color: rgb(0,0,0,0.1);
    margin: auto 0;
`

export const SecondSection = styled.div`

`

export const SecondSectionTitle = styled.div`
font-size: 1.1rem;
// line-height: 1.2rem;
font-weight: 450;
color: #000;
margin-bottom: 0.7rem;
text-transform: capitalize;
`

export const SecondSectionSubtitle = styled.div`
font-size: 0.8rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
`

export const Expand = styled.div`
border-radius: 50%;
width: 2.5rem;
height: 2.5rem;
border: 1px solid rgb(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
margin-left: 170px;
&:hover{
    outline: none;
    border-color: rgba(76, 234, 134, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
}
`


export const SecondSectionItem = styled.div`
display: flex;
margin-bottom: 0.5rem;

img {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 1px;
    margin-right: 0.5rem;
}
`

export const SecondSectionItemText = styled.div`
font-size: 0.8rem;
line-height: 1.125rem;
font-weight: 500;
color: #989c97;
span{
    color: var(--black);
}
`

export const Subtitle = styled.div`
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
font-size: 0.75rem;
`

export const Line = styled.div`
height: 1px;
width: 59.375rem;
margin-left: -1rem;
margin-top: 0.5rem;
background-color: rgb(0,0,0,0.1);
margin-bottom: 2rem;
`

export const Header = styled.thead`
display: flex;
background-color: #f7f7f7;
width: 122rem;
height: 3.3rem;

position: sticky;
border: 1px solid #eee;
border-bottom: none;
top: 0;
z-index: 10;
`
export const InnerContainer = styled.div`
width: 57.375rem;
overflow-x: auto;
height: auto;
position: relative;
`

export const MainColumn = styled.div`
font-size: 0.75rem;
    color: #0e1819;
    font-weight: 700;
    max-width: 5.9rem;
    min-width: 5.9rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 11.8rem;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: inherit;
    border-right: 1px solid #eee;
`

export const DefaultColumn = styled.div`
    font-size: 0.75rem;
    color: #0e1819;
    font-weight: 700;
    max-width: 10.25rem;
    min-width: 10.25rem;
    text-wrap: nowrap;
    display: flex;
    text-align: center;
    flex-direction: center;
    align-items: center;
    justify-content: center;
    // margin-left: 2.5rem;
    // padding-left: 1rem;

    svg {
    margin-left: 0.5rem;
    fill: #19c27e;
    width: 1.4rem;

    &:hover {
        fill: #ccc;
        cursor: pointer;
    }
    
    }
`;

export const DefaultColumnCatalog = styled.div`
    font-size: 0.75rem;
    color: #0e1819;
    font-weight: 700;
    max-width: 10.25rem;
    min-width: 10.25rem;
    text-wrap: nowrap;
    display: flex;
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    // margin-left: 2.5rem;
    // padding-left: 1rem;
    // border: 1px solid transparent;
    transition: all 0.3s ease;
    // background: white;


    svg {
        margin-left: 0.5rem;
        fill: #19c27e;
        width: 1.4rem;
        transition: all 0.3s ease;

    &:hover {
        fill: var(--black);
        cursor: pointer;
        transition: all 0.3s ease;
    }
    
    }
`;

type LinesProps = {
    dontHaveProducts: boolean;
    notCatalog?: boolean;
}

export const Lines = styled.div<LinesProps>`
  // max-height: 20rem;
  position: relative;
  padding-bottom: ${props => props.dontHaveProducts && props.notCatalog ? '15rem' : '0rem'};

    > div:nth-child(odd) {
        background-color: var(--white);
    }

    > div:nth-child(even) {
        background-color: var(--gray-112);
    }
`;

export const Filter = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    width: 6rem !important;
    box-sizing: border-box;
    width: var(--button-diameter);
    height: var(--button-diameter);
    border-radius: var(--button-border-radius);
    color: #555353;
    border: none;
    background: #fcfcfc;
    border: 1px solid #d9dbd9;
    cursor: pointer;
    position: relative;
    outline: none;
    --button-bg: #353434;
    --button-hover-bg: #464646;
    --button-text-color: #cccccc;
    --button-hover-text-color: #8bb9fe;
    --button-border-radius: 8px;
    --button-diameter: 45px;
    margin-left: 15px;
    --button-outline-width: 1px;
    --button-outline-color: rgb(141, 141, 141);
    align-items: center;
    transition: all 200ms ease;
    &:hover {
        outline: none !important;
        border-color: rgba(76, 234, 134, 0.4) !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1) !important;
        transition: all 200ms ease;
    }
`;

type FilterProps = {
    showFilter: boolean;
};

export const FilterContainer = styled.div<FilterProps>`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
    padding: 16px;
    width: 240px; /* Ajuste a largura conforme necessário */
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0; /* Inicia com opacidade zero */
    max-height: 0;
    position: absolute;
    z-index: 50;


    ${(props) =>
        props.showFilter
            ? `
                max-height: 500px;
                position: absolute;
                z-index: 50;
                margin-left: 385px;
                margin-top: 54px;
                // top: 450px;
                // left: 880px;
                opacity: 1;
            `
            : `
            `}
`;


export const HeaderFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
`;

export const CloseButton = styled.i`
    display: flex;
    color: #989c97;
    margin-right: 16px;
    cursor: pointer;

    &:hover {
        position: relative;
        color: #192324;
        border-radius: 3px;
        cursor: pointer;
    }
`;

export const LinesFilter = styled.span`
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 16px;
`;

export const FilterSection = styled.div`
    margin-bottom: 20px;
`;

export const FlexTitleReset = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    font-size: 11px;
    color: #979797;
    font-weight: 500;
`;

export const ResetButton = styled.button`
    background: none;
    border: none;
    color: #19c27e;
    cursor: pointer;
    font-size: 11px;
`;

export const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: center;
    font-size: 11px;
    color: #555353;
    font-weight: 500;
`;


export const ApplyButton = styled.button`
    display: block;
    width: 100%;
    background-color: #19c27e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 200ms ease;
    &:hover {
        transform: translateY(2px);
    }
`;

export const Filters = styled.div`
    displey: flex;
`;

export const Payment = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #e5e5e5;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    // margin-top: 0.3rem;
    margin-left: 1rem;
    img {
        margin-left: 1rem;
        width: 0.75rem;
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Approved = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Pending = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Cancelled = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Image = styled.div`
    display: flex;
    margin-left: 1rem;
`;

export const Tabs = styled.div`
    display: flex;
    margin-top: 2rem;
    gap: 1.5rem;
    width: 100%;
`;

export const Tab = styled.div<{selected: boolean}>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    background-color: #f7f7f7;
    width: 15rem;
    border-radius: 8px 8px 0 0;
    padding: 7px 10px 16px 10px;
    font-weight: 500;
    transform:  ${(props) => props.selected ? 'translateY(8px)' : 'translateY(12px)'};
    color:  ${(props) => props.selected ? 'var(--green)' : '#88A09c'};
    cursor: pointer;
    border: 1px solid #E6E6E6;
    border-bottom:none;
    transition: all 0.3s ease;

    svg {
        fill: #88A09c;
        width: 1.1rem;

        ${(props) => props.selected && `
            fill: var(--green);
        `}

    }

    &:hover{
        transform: translateY(8px);
        color: var(--green);
    }
`;

export const SolomonColumn = styled.div`
    color: #19c27e;
    font-weight: 550;
    display: flex;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
    margin-top: 0.2rem;
    img {
    width: 0.8rem;}
`;
