import styled from "styled-components";

export const Container = styled.div`
    overflow-y: auto;
    height: 100vh;
`

export const Header = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-top: 2rem;
gap: 2rem;
font-size: 0.875rem;
font-weight: 500;
color: #A4A4A4;

span{
    transition: color 0.3s, fill 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg{
        margin-right: 0.5rem;
        fill: #A4A4A4;
    }
    &:hover{
        color: #0e1819;
        svg{
            fill: #0e1819;
        }
    }
    &:last-child{
        transition: color 0.3s, fill 0.3s;
        &:hover{
        color: red;
        svg{
            fill: red;
        }
    }
    }
}
`

export const Title = styled.div`
text-align: center;
margin-bottom: 3rem;
font-size: 2.25rem;
background: linear-gradient(to right, #11744c, #19c27e);
-webkit-background-clip: text;
background-clip: text;
font-weight: 700;
color: transparent;
margin-top: 3rem;

`

export const InnerContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 3rem;
width: 100%;

`
