import { useMutation, useQuery } from 'react-query';
import * as API from '../api/api-integration';
import useAlert from '../../hooks/useAlert';
import useAuth from '../../hooks/useAuth';

//Company

export function useCreateCompanyOnboarding() {
    return useMutation(API.createCompanyOnboarding, {
        retry: false,
    });
}

export function useRenameCompanyOnboarding() {
    return useMutation(API.renameCompanyOnboarding, {
        retry: false,
    });
}

export function useGetBillingAccount(getBillingAccount: boolean){
    return useQuery('getBillingAccount', () => API.getBillingAccount,{
        refetchOnWindowFocus: false,
        enabled: !!getBillingAccount,
        retry: false,
    })
}

export function useCreateBillingAccount() {
    return useMutation(API.createBillingAccount)
}



//Billing

export function useGetCheckoutURL(company_id: string, plan: string, enabled: boolean = true, context?: string) {
    return useQuery(['getCheckoutURL', company_id, plan], () => API.getCheckoutURL(company_id, plan, context), {
        enabled,
        retry: false,
    });
}
//Shopify

export function useCreateIntegrationShopify() {
    return useMutation(API.createIntegrationShopify);
}

export function useUpdateIntegrationShopify() {
    return useMutation(API.updateIntegrationShopify);
}

export function useDeleteIntegrationShopify() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationShopify, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Shopify foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Shopify, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

//ShopifyBeta

export function useCreateIntegrationShopifyBeta() {
    return useMutation(API.createIntegrationShopifyBeta);
}

//NuvemShop

export function useCreateIntegrationNuvemShop() {
    return useMutation(API.createIntegrationNuvemShop);
}

//Tiktok

export function useCreateIntegrationTiktokAdsAccount() {
    return useMutation(API.createIntegrationTiktokAdsAccount);
}

export function useCreateIntegrationTiktokAdsProfiles() {
    return useMutation(API.createIntegrationTiktokAdsProfiles);
}

export function useUpdateTiktokAdsAccount() {
    return useMutation(API.updateAccountsTiktokAds);
}

export function useGetUrlTiktokAdsAuth(user_id: string, company_id: string, context: string) {
    return useQuery('tiktokAdsOnboarding', () => API.getUrlTiktokAdsAuth(user_id, company_id, context), {
        refetchOnWindowFocus: false,
        enabled: !!user_id,
        retry: false,
    });
}

export function useGetAccountsTiktokAds(company_id: string, typeAccount: string, activeGetAccount: boolean, setActiveGetAccount: any) {
    return useQuery('tiktokAccountsOnboarding', () => API.getAccountsTiktokAds(company_id), {
        refetchOnWindowFocus: false,
        enabled: typeAccount === 'tiktok_ads' && !!company_id && activeGetAccount,
        retry: 3,
        onSuccess: () => {
            setActiveGetAccount(false)
        }
    });
}

export function useSyncTiktokAdsAccounts() {
    return useMutation(API.syncTiktokAdsAccounts);
}


export function useRemoveTiktokAdsAccount() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteAccountIntegrationTiktokAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua conta do Tiktok Ads foi desconectado com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua conta do Tiktok Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

export function useRemoveTiktokAdsProfile() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteProfileIntegrationTiktokAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Seu perfil do Tiktok Ads foi desconectado com sucesso! Clique em "Sincronizar contas".', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar seu perfil do Tiktok Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

//Facebook

export function useGetAccountsFacebookAds(company_id: string, typeAccount: string, activeGetAccount: boolean, setActiveGetAccount: any) {
    return useQuery('facebookAccountsOnboarding', () => API.getAccountsFacebookAds(company_id), {
        refetchOnWindowFocus: false,
        enabled: typeAccount === 'facebook_ads' && !!company_id && activeGetAccount,
        retry: 3,
        onSuccess: () => {
            setActiveGetAccount(false)
        }
    });
}

export function useCreateIntegrationFacebookAdsAccount() {
    return useMutation(API.createIntegrationFacebookAdsAccount);
}

export function useCreateIntegrationFacebookAdsProfiles() {
    return useMutation(API.createIntegrationFacebookAdsProfiles);
}

export function useUpdateFacebookAdsAccount() {
    return useMutation(API.updateAccountsFacebookAds);
}

export function useGetUrlFacebokAdsAuthRevalidate(user_id: string, token: string, company_id: string, showModalFacebook: boolean ) {
    return useQuery('facebookAdsOnboardingRevalidate', () => API.getUrlFacebookAdsAuthRevalidate(user_id, token, company_id), {
        refetchOnWindowFocus: false,
        enabled: !!user_id && !!token && !!company_id && showModalFacebook,
        retry: false,
    });
}

export function useGetUrlFacebokAdsAuthOnboarding(user_id: string, token: string, company_id: string, context: string) {
    return useQuery('facebookAdsOnboarding', () => API.getUrlFacebookAdsAuthOnboarding(user_id, token, company_id, context), {
        refetchOnWindowFocus: true,
        enabled: !!user_id && !!token && company_id?.length > 0,
        retry: false,
    });
}

export function useGetUrlFacebokAdsAuthLink(user_id: string, token: string, company_id: string) {
    return useQuery('facebookAdsLink', () => API.getUrlFacebookAdsAuthLink(user_id, token, company_id), {
        refetchOnWindowFocus: true,
        enabled: !!user_id && !!token && !!company_id,
        retry: false,
    });
}


export function useSyncFacebookAdsAccounts() {
    return useMutation(API.syncFacebookAdsAccounts);
}

export function useRemoveFacebookAdsAccount() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteAccountIntegrationFacebookAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua conta do Facebook Ads foi desconectado com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua conta do Facebook Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

export function useRemoveFacebookAdsProfile() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteProfileIntegrationFacebookAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Seu perfil do Facebook Ads foi desconectado com sucesso! Clique em "Sincronizar contas".', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar seu perfil do Facebook Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

//Google Analytics

export function useCreateIntegrationGoogleAnalyticsAccount() {
    return useMutation(API.createIntegrationGoogleAnalyticsAccount);
}

export function useCreateIntegrationGoogleAnalyticsProfiles() {
    return useMutation(API.createIntegrationGoogleAnalyticsProfiles);
}

//Google Ads

export function useCreateIntegrationGoogleAdsAccount() {
    return useMutation(API.createIntegrationGoogleAdsAccount);
}

export function useCreateIntegrationGoogleAdsProfiles() {
    return useMutation(API.createIntegrationGoogleAdsProfiles);
}

export function useUpdateGoogleAdsAccount() {
    return useMutation(API.updateAccountsGoogleAds);
}

export function useGetAccountsGoogleAds(company_id: string, typeAccount: string, activeGetAccount: boolean, setActiveGetAccount: any) {
    return useQuery('googleAccountsOnboarding', () => API.getAccountsGoogleAds(company_id), {
        refetchOnWindowFocus: false,
        enabled: typeAccount === 'google_ads' && !!company_id && activeGetAccount,
        retry: 3,
        onSuccess: () => {
            setActiveGetAccount(false)
        }
    });
}

export function useGetUrlGoogleAdsAuth(user_id: string, company_id: string, context: string) {
    return useQuery('googleAdsOnboarding', () => API.getUrlGoogleAdsAuth(user_id, company_id, context), {
        refetchOnWindowFocus: true,
        enabled: !!user_id,
        retry: true,
    });
}

export function useSyncGoogleAdsAccounts() {
    return useMutation(API.syncGoogleAdsAccounts);
}

export function useRemoveGoogleAdsAccount() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteAccountIntegrationGoogleAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua conta do Google Ads foi desconectado com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua conta do Google Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

export function useRemoveGoogleAdsProfile() {

    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteProfileIntegrationGoogleAds, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Seu perfil do Google Ads foi desconectado com sucesso! Clique em "Sincronizar contas".', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar seu perfil do Google Ads, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}

//Cartpanda

export function useCreateIntegrationCartpanda() {
    return useMutation(API.createIntegrationCartpanda);
}

export function useDeleteIntegrationCarpanda(){
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationCartpanda, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Cartpanda foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Cartpanda, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
    return useMutation(API.deleteIntegrationCartpanda);
}

//Aliexpress

export function useCreateIntegrationAliexpress() {
    return useMutation(API.createIntegrationAliexpress);
}

//Dsers

export function useCreateIntegrationDsers() {
    return useMutation(API.createIntegrationDsers);
}

//Yampi

export function useCreateIntegrationYampi() {
    return useMutation(API.createIntegrationYampi);
}

export function useDeleteIntegrationYampi() {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationYampi, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Yampi foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Yampi, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
    // return useMutation(API.deleteIntegrationYampi);
}

//Kiwify

export function useCreateIntegrationKiwify() {
    return useMutation(API.createIntegrationKiwify);
}

export function useDeleteIntegrationKiwify() {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationKiwify, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Yampi foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Yampi, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
    // return useMutation(API.deleteIntegrationYampi);
}

//Gateway

export function useCreateIntegrationGateway() {
    return useMutation(API.createIntegrationGateway);
}

export function useGetIntegrationGateway(company_id: string) {
    return useQuery(['getIntegrationGateway', company_id], () => API.getIntegrationGateway(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}


//Yever

export function useCreateIntegrationYever() {
    return useMutation(API.createIntegrationYever);
}

export function useDeleteIntegrationYever(){
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationYever, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Yever foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Yever, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
    // return useMutation(API.deleteIntegrationYever);
}

// Dom

export function useCreateIntegrationDom() {
    return useMutation(API.createIntegrationDom);
}

export function useDeleteIntegrationDom(){
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();

    return useMutation(API.deleteIntegrationDom, {
        onSuccess: () => {
            // Este alerta será mostrado apenas quando a mutação for bem-sucedida
            showAlert('Sua integração com a Dom Pagamentos foi desconectada com sucesso!', 'success', 5000, true);
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
            // Tratar erro
            showAlert('Falha ao desconectar sua integração com a Dom Pagamentos, tente novamente ou entre em contato com o suporte.', 'error', 5000, true);
        },
    });
}