import styled from 'styled-components';

export const Container = styled.div`
    width: 37.5rem;
    height: 30rem;
    border-radius: 20px;
    padding: 1.2rem;
    > svg {
        fill: var(--green);
    }
`;

export const Title = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 0.8rem;
    span {
        color: var(--green);
    }
`;

export const Subtitle = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #989c97;
    margin-top: 0.2rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
`;

export const Text = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: var(--black);
`;

export const Checkbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    width: 12px;
    height: 12px;
    margin-right: 0.5rem;
`;

export const Subtext = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #989c97;
    margin-top: 0.2rem;
`;

export const OptionSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1.8rem;
`;

export const Option = styled.div`
    height: 1.9375rem;
    width: max-content;
    padding: 0 1rem;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
    color: #465d54;
    border-radius: 4px;
`;

export const ButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
`;


export const ButtonModalNewGroup = styled.button`
    background-color: #19c273;
    cursor: pointer;   
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    padding: 0 1rem;
    height: 2.25rem;
    color: #fff;
    &:disabled {
        cursor: not-allowed;
        background-color: #ccc;
    }
`;
