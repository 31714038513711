import { useQuery } from 'react-query';
import { getClientsInformation } from '../api/api-clients';


export function useClientsInformation(company_id: string) {
    return useQuery(['clients', company_id], () => getClientsInformation(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
