import * as S from './style';
import ReactEcharts from 'echarts-for-react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GraphProps } from '../../../../@types';
import { getMetricsByHourOrWeek } from '../../utils';
import { useState } from 'react';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';

export function TimeAnalysis({ isLoading, data }: GraphProps) {
    const { xAxisDay, yAxisDay, intervalHour, xAxisHour, yAxisHour, intervalDay } = getMetricsByHourOrWeek(data);

    const [showAnalyisByHour, setShowAnalysisByHour] = useState(true);

    if (isLoading) {
        return (
            <S.Container>
                <S.LoadingContainer />
            </S.Container>
        );
    }
    return (
        <S.Container>
            <S.Title>Análise temporal</S.Title>
            <S.ButtonSection>
                <S.DayButton onClick={() => setShowAnalysisByHour(false)}>
                    Dia da semana
                    <ArrowForwardIcon sx={{ width: '11px', height: '11px' }} />
                </S.DayButton>
                <S.HourButton onClick={() => setShowAnalysisByHour(true)}>
                    Horário
                    <ArrowForwardIcon sx={{ width: '11px', height: '11px' }} />
                </S.HourButton>
            </S.ButtonSection>
            <S.Graphic>
                <ReactEcharts style={{ height: '232px', width: '624px' }} option={getOption(xAxisHour, yAxisHour, intervalHour, xAxisDay, yAxisDay, intervalDay, showAnalyisByHour)} />
            </S.Graphic>
        </S.Container>
    );
}
// Função para formatar os valores de receita conforme o símbolo da moeda
function formatCurrency(value, currencySymbol) {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'USD', // Use 'USD' aqui ou qualquer outra moeda de sua escolha
    }).format(value).replace('US$', currencySymbol);
}

function getOption(xAxisHour, yAxisHour, intervalHour, xAxisDay, yAxisDay, intervalDay, showAnalyisByHour) {
    const { currencySymbol } = useCurrencyType();

    const optionTemplate = {
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
            formatter: function (params) {
                let result = `${params[0].name}<br/>`;
                params.forEach(item => {
                    const valueFormatted = formatCurrency(item.value, currencySymbol);
                    result += `${item.marker}${item.seriesName}: ${valueFormatted}<br/>`;
                });
                return result;
            },
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: false },
                saveAsImage: { show: false },
            },
        },
        legend: {
            show: true,
            data: ['Receita'],
            textStyle: {
                fontSize: 11,
            },
        },
        yAxis: [
            {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    show: true,
                    formatter: function (value) {
                        return formatCurrency(value, currencySymbol);
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: '#ddd',
                    },
                },
                splitNumber: 2,
            },
        ],
    };

    if (showAnalyisByHour) {
        return {
            ...optionTemplate,
            xAxis: [
                {
                    type: 'category',
                    data: xAxisHour,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        show: true,
                        interval: intervalHour,
                    },
                    splitLine: { show: false },
                },
            ],
            series: [
                {
                    name: 'Receita Aprovada',
                    type: 'bar',
                    data: yAxisHour,
                    itemStyle: {
                        barWidth: '10px',
                        barBorderRadius: [5, 5, 0, 0],
                        color: '#19c27e',
                    },
                },
            ],
        };
    } else {
        return {
            ...optionTemplate,
            xAxis: [
                {
                    type: 'category',
                    data: xAxisDay,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        show: true,
                        interval: intervalDay,
                    },
                    splitLine: { show: false },
                },
            ],
            series: [
                {
                    name: 'Receita Aprovada',
                    type: 'bar',
                    data: yAxisDay,
                    itemStyle: {
                        barWidth: '10px',
                        barBorderRadius: [5, 5, 0, 0],
                        color: '#19c27e',
                    },
                },
            ],
        };
    }
}
