import { useQuery } from 'react-query';
import { getDashboardData, getTaxesRate, getShipping } from '../api/api-dashboard';

export function useDashboardData(start_date: string, end_date: string, company_id: string, productIds: string[]) {
    return useQuery(['dashboard', start_date, end_date, company_id, productIds], () => getDashboardData(start_date, end_date, company_id, productIds), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useTaxesRate(company_id: string) {
    return useQuery(['getTaxes', company_id], () => getTaxesRate(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetShipping(company_id: string) {
    console.log("chegou aq? get shipping", company_id);
    return useQuery(['getShipping', company_id], () => getShipping(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}