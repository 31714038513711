import { IntegrationLoadingOnboardingProps } from '../../../@types';
import { CustomVerifiedIcon } from './style';
import { useEffect, useState } from 'react';

import * as S from './style';
import { Tooltip } from '@mui/material';

export function IntegrationLoadingOnboarding({ type, percentage }: IntegrationLoadingOnboardingProps) {
    const [animationDuration, setAnimationDuration] = useState(percentage === 100 ? '2s' : '50s');
    useEffect(() => {
        if (percentage === 100) {
            setAnimationDuration('2s'); // Acelera a animação para a conclusão
        } else {
            setAnimationDuration('50s'); // Duração padrão da animação
        }
    }, [percentage]);

    const calculateWidth = (percentage: any) => {
        if (percentage <= 45) {
            // Mapeia de 0-45 para 0-90 (por exemplo)
            return (percentage / 45) * 90 + '%';
        } else {
            // Mapeia de 45-100 para 90-100
            return 90 + ((percentage - 45) / 55) * 10 + '%';
        }
    };

    let url = '';

    switch (type) {
        case 'Shopify':
            url = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';
            break;

        case 'Facebook':
            url = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
            break;

        case 'Tiktok':
            url = 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';
            break;

        case 'Google':
            url = 'https://storage.googleapis.com/static-images-source/google-icon.png';
            break;

        case 'Appmax (Gateway)':
            url = 'https://framerusercontent.com/images/ZvKI0Bp6qyMy3u5Ei06pQe9nYA.png';
            break;

        case 'Dom Pagamentos (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/dom%20icon.png';
            break;
                
        case 'Yampi (Checkout)':
            url = 'https://i.ibb.co/vB7Hhnk/yampi.png';
            break;

        case 'Cartpanda (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/cartpanda-icon.png';
            break;
      
        case 'Yever (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/yever%20icon%202.png';
            break;
      
        case 'Yever (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/yever%20checkout.png';
            break;
      
        case 'Outro (Gateway)':
            url = 'https://storage.googleapis.com/static-images-source/gateway-icon.png';
            break;
            
        case 'Adoorei (Checkout)':
            url = 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg';
            break;
        
        case 'Unico Pag (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png';
            break;
        
        case 'Shopify (Checkout)':
            url = 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg';
            break;
    }

    return (
        <S.Container>
              <Tooltip title={type} placement="top">
            <S.Image>
                <img src={url} alt="Logo"/>
            </S.Image>
            </Tooltip>
           <S.Content>
                {percentage === 100 ? (
                    <S.GatewayArea>
                        <S.CustomVerifiedIcon />
                        <S.Text>Integração feita!</S.Text>
                    </S.GatewayArea>
                ) : (
                    <>
                        <S.Text>Dados {type}...</S.Text>
                        <S.Line>
                            <S.Loading percentage={percentage} style={{ width: calculateWidth(percentage), transitionDuration: animationDuration }} />
                        </S.Line>
                    </>
                )}
            </S.Content>
        </S.Container>
    );
}
