
import React, { useEffect, useState } from 'react';
import { ProductsCostDTO } from '../../../../../@types';
import { format } from 'date-fns';
import { ButtonApp } from '../../../../../components/ButtonApp';
import * as S from './style'; // Seus estilos importados
import { DeleteForeverRoundedIconStyled } from './style';
import { useDeleteProductCost } from '../../../../../services/hooks/productsHooks';
import useAuth from '../../../../../hooks/useAuth';
import { TablePlaceholder } from '../../../../../components/TablePlaceholder';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

interface VariantCostHistoryTableProps {
    dataVariantCosts: ProductsCostDTO;
    variant: any;
    setDataProductCosts: (value: ProductsCostDTO) => void;
}

const VariantCostHistoryTable: React.FC<VariantCostHistoryTableProps> = ({ dataVariantCosts, variant, setDataProductCosts }) => {
    
    const { currentCompany } = useAuth();
    const { mutate: deleteProductCost } = useDeleteProductCost();

    let thisVariantCosts = dataVariantCosts?.data?.data.filter((item: any) =>
        item.variant_id === variant.variant_id
    );

    if (!thisVariantCosts) {
        thisVariantCosts = [];
    }
    const formatDate = (dateString:string) => {
        return format(new Date(dateString), 'dd/MM/yyyy');
    };


    const [variantCosts, setVariantCosts] = useState<any[]>([]);

    useEffect(() => {
        let initialVariantCosts = dataVariantCosts?.data?.data.filter((item: any) =>
            item.variant_id === variant.variant_id
        );

        if (!initialVariantCosts) {
            initialVariantCosts = [];
        }

        setVariantCosts(initialVariantCosts);
    }, [dataVariantCosts, variant]);

    const handleDelete = (costId : string) => {
        // Sua lógica para remover o custo do servidor e atualizar o estado local
        const updatedVariantCosts = variantCosts.filter(cost => cost.id !== costId);
        setVariantCosts(updatedVariantCosts);

        deleteProductCost({
            id: costId?? '',
            company_id: currentCompany,
            platform: 'shopify'
        });

        setDataProductCosts({
            data: {
                data: dataVariantCosts.data.data.filter((item: any) => item.id !== costId)
            }
        });
    };

    // //console.log('variantCosts', variantCosts)
    const updatedVariantCosts = variantCosts.filter(cost => cost.period_start !== 'Fri, 01 Jan 2010 00:00:00 GMT');

    if (variantCosts.length === 1 && variantCosts[0].period_start === 'Fri, 01 Jan 2010 00:00:00 GMT' || updatedVariantCosts.length === 0) {
        return (
                <S.HistoryTable>
                    <S.EmptyContainer>
                        <TablePlaceholder subtitleText='Nenhum custo encontrado para este produto.' size='medium' showSubtitle={true} />
                    </S.EmptyContainer>
                </S.HistoryTable>
        );
    }

    return (
        <S.HistoryTable>
            <thead>
                <tr>
                    <th>Data de Atualização</th>
                    <th>Início</th>
                    <th>Fim</th>
                    <th>Custo</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                {updatedVariantCosts.map(cost => (
                    <tr id={cost.id} key={cost.id}>
                        <td>{formatDate(cost.updated_cost_at)}</td>
                        <td>{cost.period_start ? formatDate(cost.period_start) : ''}</td>
                        <td>{cost.period_end ? formatDate(cost.period_end) : ''}</td>
                        <td>{cost.variant_cost}</td>
                        <td>
                        <Tooltip
                            title="Atualizando custo, aguarde um momento."
                            placement="left"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#1e1e1e',
                                        fontWeight: 'semibold',
                                        fontSize: '14px',
                                        fontFamily: 'Montserrat',
                                        borderRadius: '5px',
                                        border: 1,
                                        color: 'common.white',
                                    }
                                },
                            }}
                        >
                            <ButtonApp disabled={!cost.id} border='' width='130px' height='30px' background='var(--red-500)' color='white' icon={!cost.id ? <CircularProgress size={'small'} sx={{color: 'white', width: '12px'}} /> : <DeleteForeverRoundedIconStyled />} text={!cost.id ? 'Atualizando...' : 'Excluir'}  onClick={() => handleDelete(cost.id)} />
                        </Tooltip>
                        </td>
                    </tr>
                ))}
            </tbody>
        </S.HistoryTable>
    );
};

export default VariantCostHistoryTable;