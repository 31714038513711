import { InputApp } from '../../components/InputApp';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as S from './style';
import { useGetUrlChangePassword } from '../../services/hooks/userHooks';
import { useEffect } from 'react';
import { trackEvent } from '../../services/segment/sendEvent'
import useAuth from '../../hooks/useAuth';
import useVisibility from '../../hooks/useVisibilityChange';
import useIsMobile from '../../hooks/useIsMobile';

export function AccountDetails() {
    const { data: urlChangePassword, mutate: handleGetUrl,  isLoading } = useGetUrlChangePassword();

    const { isVisible } = useVisibility();
    const { user, currentCompany } = useAuth();

    const nameStore = user?.companies?.[currentCompany]?.company;
    const email = user?.credentials?.email;
    const user_id = user?.user_id;

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'account'
        });
    }, [])

    useEffect(() => {
        const body = {
            email: email,
            user_id: user_id
        }
        handleGetUrl(body)
    },[])

    const isMobile = useIsMobile();

    function handleRedirectToChangePassword() {
        window.open(urlChangePassword?.data?.data, '_blank');
    }

    return (
        <S.Container isMobile={isMobile}>
            <S.Header>
                {isLoading && <></>}
                <S.Title>Detalhes da conta</S.Title>
                <S.Subtitle>Veja suas informações pessoais.</S.Subtitle>
            </S.Header>
            <S.Line></S.Line>
            <S.InputArea>
                <InputApp
                    inputId="nameStoreAccount"
                    width={isMobile ? '80vw' : '15.5rem'}
                    inputLabel=""
                    required={true}
                    value={isVisible ? nameStore : '* * * * * * * * * * * '}
                    disabled
                    inputType="text"
                    inputName="Nome da Loja"
                    setInputInformation={() => {}}
                />
                <InputApp inputId="idStoreAccount" inputType="text" inputName="Usuário" width={isMobile ? '80vw' : '23.5rem'} required={true} disabled setInputInformation={() => {}} value={isVisible ? user.name : '* * * * * * * * *'} inputLabel="" />
            </S.InputArea>
            <S.InputArea>
                <InputApp inputId="idStoreAccount" inputType="text" inputName="ID Loja" width={isMobile ? '80vw' : '15.5rem'} required={true} disabled setInputInformation={() => {}} value={isVisible ? currentCompany : '* * * * * * * * *'} inputLabel="" />
                <InputApp inputId="emailAccount" inputType="text" inputName="Email" width={isMobile ? '80vw' : '23.5rem'} required={true} disabled inputLabel="" value={isVisible ? email : '* * * * * * * * * * * * * * *'} setInputInformation={() => {}} />
            </S.InputArea>
            <S.ButtonArea>
                <S.Button onClick={handleRedirectToChangePassword}>
                    <LockOutlinedIcon sx={{ width: '20px', height: '20px' }} /> Alterar senha
                </S.Button>
            </S.ButtonArea>
        </S.Container>
    );
}
