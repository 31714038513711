import { useEffect, useRef, useState } from 'react';
import * as S from './style';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/l10n/pt.js';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { format, parseISO } from 'date-fns';
import useAuth from '../../hooks/useAuth';
import useIsMobile from '../../hooks/useIsMobile';

const Calendar = ({ setDate, date, dashboard }: any) => {
    const isMobile = useIsMobile();
    const { currentCompany } = useAuth();
    const [state, setState] = useState({
        initialDate: parseISO(date[0]),
        finalDate: parseISO(date[1]),
        selected: 'today',
        showOptions: false,
    });
    const wrapperRef = useRef(null);
    const flatpickerRef = useRef(null);
    const iconRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        // Load saved dates and selected period from localStorage
        const savedDates = JSON.parse(localStorage.getItem(`selectedDates_${currentCompany}`));
        const savedSelected = localStorage.getItem(`selectedPeriod_${currentCompany}`);
        if (savedDates) {
            const [initialDate, finalDate] = savedDates;
            setState((prev) => ({
                ...prev,
                initialDate: parseISO(initialDate),
                finalDate: parseISO(finalDate),
                selected: savedSelected || 'custom',
            }));
            setDate(savedDates);
        }
    }, [setDate]);

    

    useEffect(() => {
        function handleClickOutside(event: any) {
            // @ts-ignore
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                const flatpickrCalendar = document.querySelector('.flatpickr-calendar');
                if (flatpickrCalendar && !flatpickrCalendar.contains(event.target)) {
                    setState((prev) => ({ ...prev, showOptions: false }));
                }
            }

            // const icon = document.querySelector('.iconCalendarFlatPicker');
            // // @ts-ignore
            // icon?.addEventListener('click', () => {
            //   if (flatpickerRef && flatpickerRef.current) {
            //     // @ts-ignore
            //     flatpickerRef.current.flatpickr.open();
            //   }
            // });
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            // @ts-ignore
            // icon.removeEventListener('click');
        };
    }, [wrapperRef, flatpickerRef]);

    useEffect(() => {
        function handleClickIcon(event: any) {
            // @ts-ignore
            if (iconRef.current && iconRef.current.contains(event.target)) {
                if (flatpickerRef && flatpickerRef.current) {
                    // @ts-ignore
                    if (!flatpickerRef.current.flatpickr.isOpen) {
                        // @ts-ignore
                        flatpickerRef.current.flatpickr.open();
                    }
                }
            }
        }

        document.addEventListener('click', handleClickIcon);
        return () => {
            document.removeEventListener('click', handleClickIcon);
        };
    }, [iconRef, flatpickerRef]);

    useEffect(() => {
        function handleClickText(event: any) {
            // @ts-ignore
            if (textRef.current && textRef.current.contains(event.target)) {
                if (flatpickerRef && flatpickerRef.current) {
                    // @ts-ignore
                    if (!flatpickerRef.current.flatpickr.isOpen) {
                        // @ts-ignore
                        flatpickerRef.current.flatpickr.open();
                    }
                }
            }
        }

        document.addEventListener('click', handleClickText);
        return () => {
            document.removeEventListener('click', handleClickText);
        };
    }, [textRef, flatpickerRef]);

    const handleSetDate = (type: string) => {
        const today = new Date();
        // @ts-ignore
        let initialDate, finalDate, selected;

        switch (type) {
            case 'today':
                initialDate = today;
                finalDate = today;
                selected = 'today';
                break;
            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                initialDate = yesterday;
                finalDate = yesterday;
                selected = 'yesterday';
                break;
            case '7days':
                const sevenDaysAgo = new Date(today);
                sevenDaysAgo.setDate(today.getDate() - 7);
                const yesterdayy = new Date(today);
                yesterdayy.setDate(today.getDate() - 1);
                initialDate = sevenDaysAgo;
                finalDate = yesterdayy;
                selected = '7days';
                break;
            case '14days':
                const fourteenDaysAgo = new Date(today);
                fourteenDaysAgo.setDate(today.getDate() - 14);
                const yesterdayyy = new Date(today);
                yesterdayyy.setDate(today.getDate() - 1);
                initialDate = fourteenDaysAgo;
                finalDate = yesterdayyy;
                selected = '14days';
                break;
            case 'thismonth':
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                initialDate = firstDayOfMonth;
                finalDate = today;
                selected = 'thismonth';
                break;
            case 'lastmonth':
                const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                initialDate = firstDayOfLastMonth;
                finalDate = lastDayOfLastMonth;
                selected = 'lastmonth';
                break;
        }
        // @ts-ignore
        setState((prev) => ({ ...prev, initialDate, finalDate, selected, showOptions: false }));
        // @ts-ignore
        setDate([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]);
    
        localStorage.setItem(`selectedDates_${currentCompany}`, JSON.stringify([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]));
        localStorage.setItem(`selectedPeriod_${currentCompany}`, selected);
    };

    const { initialDate, finalDate, selected, showOptions } = state;
    // @ts-ignore
    const Portuguese = Object.assign({}, flatpickr.l10ns.pt, {
        rangeSeparator: ' a ',
    });


    // CALENDARIO MOBILE ////

    if (isMobile) {
        return (
            <S.MobileContainer ref={wrapperRef}>
                {/* Implementação específica para mobile */}

                <S.MobileCalendarOptions showCalendarOptions={showOptions} dashboard={dashboard}>
                    <S.Title onClick={() => setState((prev) => ({ ...prev, showOptions: false }))}>Predefinições de data</S.Title>
                    <S.Option thisOptionIsSelected={selected === 'today'} onClick={() => handleSetDate('today')}>
                        Hoje
                    </S.Option>
                    <S.Option thisOptionIsSelected={selected === 'yesterday'} onClick={() => handleSetDate('yesterday')}>
                        Ontem
                    </S.Option>
                    <S.Option thisOptionIsSelected={selected === '7days'} onClick={() => handleSetDate('7days')}>
                       7 dias
                    </S.Option>
                    <S.Option thisOptionIsSelected={selected === 'thismonth'} onClick={() => handleSetDate('thismonth')}>
                        Este mês
                    </S.Option>
                </S.MobileCalendarOptions>
                {/* <S.MobileCalendarIcon ref={iconRef}> */}
                {/* </S.MobileCalendarIcon> */}
                    <EventOutlinedIcon sx={{ width: '26px', height: '26px' }} />
                <div>
                <Flatpickr
                    ref={flatpickerRef}
                    style={{ width: '1.5rem', height: '1.4rem', backgroundColor: '#F3F3F3', opacity: '0' }}
                    options={{
                        mode: 'range',
                        dateFormat: 'd/m/Y',
                        locale: Portuguese,
                        static: true,
                    }}
                    value={[initialDate, finalDate]}
                    onChange={([initialDate, finalDate]) => {
                        if (initialDate && finalDate) {
                            setState((prev) => ({
                                ...prev,
                                initialDate,
                                finalDate,
                                selected: 'custom',
                                showOptions: false,
                            }));
                            setDate([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]);
                            localStorage.setItem(`selectedDates_${currentCompany}`, JSON.stringify([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]));
                            localStorage.setItem(`selectedPeriod_${currentCompany}`, 'custom');
                        } else {
                            setState((prev) => ({
                                ...prev,
                                initialDate,
                                finalDate,
                                selected: 'custom',
                            }));
                        }
                    }}
                />
                </div>
            </S.MobileContainer>
        );
    }


    return (
        <S.Container ref={wrapperRef}>
            <S.CalendarChoice ref={textRef} >
                {state.selected === 'today'
                    ? 'Hoje'
                    : state.selected === 'yesterday'
                      ? 'Ontem'
                      : state.selected === '7days'
                        ? 'Últimos 7 dias'
                        : state.selected === '14days'
                          ? 'Últimos 14 dias'
                          : state.selected === 'thismonth'
                            ? 'Este mês'
                            : state.selected === 'lastmonth'
                              ? 'Mês passado'
                              : 'Customizado'}
            </S.CalendarChoice>
            <S.CalendarIcon ref={iconRef}>
                <EventOutlinedIcon sx={{ width: '16px', height: '16px' }} />
            </S.CalendarIcon>

            <S.CalendarOptions showCalendarOptions={showOptions} dashboard={dashboard}>
                <S.Title onClick={() => setState((prev) => ({ ...prev, showOptions: false }))}>Predefinições de data</S.Title>
                <S.Option thisOptionIsSelected={selected === 'today'} onClick={() => handleSetDate('today')}>
                    Hoje
                </S.Option>
                <S.Option thisOptionIsSelected={selected === 'yesterday'} onClick={() => handleSetDate('yesterday')}>
                    Ontem
                </S.Option>
                <S.Option thisOptionIsSelected={selected === '7days'} onClick={() => handleSetDate('7days')}>
                    Últimos 7 dias
                </S.Option>
                <S.Option thisOptionIsSelected={selected === '14days'} onClick={() => handleSetDate('14days')}>
                    Últimos 14 dias
                </S.Option>
                <S.Option thisOptionIsSelected={selected === 'thismonth'} onClick={() => handleSetDate('thismonth')}>
                    Este mês
                </S.Option>
                <S.Option thisOptionIsSelected={selected === 'lastmonth'} onClick={() => handleSetDate('lastmonth')}>
                    Mês passado
                </S.Option>
            </S.CalendarOptions>
            <Flatpickr
                ref={flatpickerRef}
                style={{ width: '12rem', height: '1.4rem', backgroundColor: '#F3F3F3' }}
                options={{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                    locale: Portuguese,
                    static: true,
                }}
                value={[initialDate, finalDate]}
                placeholder="Selecione um período"
                onChange={([initialDate, finalDate]) => {
                    if (initialDate && finalDate) {
                        setState((prev) => ({
                            ...prev,
                            initialDate,
                            finalDate,
                            selected: 'custom',
                            showOptions: false,
                        }));
                        setDate([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]);
                        // Save dates and selected period to localStorage
                        localStorage.setItem(`selectedDates_${currentCompany}`, JSON.stringify([format(initialDate, 'yyyy-MM-dd'), format(finalDate, 'yyyy-MM-dd')]));
                        localStorage.setItem(`selectedPeriod_${currentCompany}`, 'custom');
                    } else {
                        setState((prev) => ({
                            ...prev,
                            initialDate,
                            finalDate,
                            selected: 'custom',
                        }));
                    }
                }}
                onOpen={(_selectedDates, _dateStr, instance) => {
                    const calendarContainer = instance.calendarContainer;
                    if (calendarContainer) {
                        calendarContainer.style.fontSize = '12px';
                        calendarContainer.style.marginInline = '0';
                        calendarContainer.style.left = '-6rem';
                        calendarContainer.style.top = '2rem';
                    }
                    
                    // setTimeout(() => {
                    //     const calendarContainer = instance.calendarContainer;
                    //     if (calendarContainer && window.innerWidth < 600) {
                    //         const leftValue = (window.innerWidth * 0.5 - 50) + 'px';
                    //         calendarContainer.style.cssText += `;left: ${leftValue} !important;`;
                    //     }
                    // }, 1);
                    setState((prev) => ({ ...prev, showOptions: true }));
                }}
            />
        </S.Container>
    );
};

export default Calendar;
