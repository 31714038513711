import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: space-between;
font-size: 0.875rem;
font-weight: 500;
line-height: 1.3125rem;
color: #000;

`

export const Button = styled.button`
background-color: var(--gray-110);
border-radius: 7px;
padding: 0.2rem;
border: 1px solid var(--gray-100);
cursor: pointer;
transition: all 250ms;

&:hover{
    transform: translateY(3px);
    background-color: var(--gray-100);
    transition: all 250ms;
}
svg {
    fill: var(--black);
    width: 1.3rem;
    height: 1.2rem;
}
`;

export const Column = styled.div`
width: 25%;
height: 3.5rem;
&:last-child{
    svg{
    fill: #ea1a1a;
    width: 1.5rem;
    height: 1.5rem;
}
}

display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
&:first-child{
    justify-content: flex-start;
    padding-left: 1rem;
}
&:last-child{
    justify-content: flex-end;
    padding-right: 7.5rem;
}
`

export const InputContainer = styled.div`
display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    input {
        font-size: 0.75rem;
        border: 1px solid #ccc;
        height: 32px;
        margin: 0 1rem;
        line-height: 1.125rem;
        padding: 0 0.5rem;
        border-radius: 5px;
        color: var(--black);
        &::placeholder {
            color: #757575;
        }
    }

`
