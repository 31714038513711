import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
`;


export const Title = styled.div`
width: 100%;
    h2 {font-size: 1.6rem;
    color: var(--black);
    font-weight: 500;
    height: 2rem;
    margin-top: 1.4rem;
    // margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        // margin-top: -0.5rem;
        color: var(--gray-700);
    }   
`



export const Subtitle = styled.div`
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 1rem;
    width: 30rem;
    color: var(--gray-700);
    margin-top: 0.5rem;
`;

export const SearchContainer = styled.div`
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    align-items: center;
`;

export const Line = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    width: 95%;
    background-color: var(--gray-200);
`;

export const LineContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    margin-top: 2rem;
`;

export const Section = styled.div`
    font-size: 1.25rem;
    font-weight: 550;
    line-height: 1.5rem;
    color: var(--gray-700);
`;

export const InnerContent = styled.div`
    display: flex;
    margin-top: 1rem;
    gap: 6rem;
    margin-bottom: 3rem;
    width: 100%;
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12.5rem;
    height: 2.8125rem;
    border: 1px solid var(--gray-120);
    border-radius: 8px;
`;

type FilterOptionProps = {
    isActive: boolean;
    type: string;
};

function getBackgroundColor({ isActive, type }: FilterOptionProps) {
    if (isActive) {
        if (type === 'Ativos') {
            return `
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: 1px solid var(--green);
            color: var(--green);
            svg{
                color: var(--green);
            }
            `;
        } else {
            return `
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid var(--green);
            color: var(--green);
            svg{
                color: var(--green);
            }
            `;
        }
    } else {
        if (type === 'Ativos') {
            return `
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: 1px solid var(--gray-120);
            color: var(--gray-120);
            `;
        } else {
            return `
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid var(--gray-120);
            color: var(--gray-120);
            `;
        }
    }
}

export const FilterOption = styled.div<FilterOptionProps>`
    width: 6.75rem;
    height: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ isActive, type }) => getBackgroundColor({ isActive, type })}
    font-size: 0.875rem;
    line-height: 1.3125rem;

    &:hover {
        cursor: pointer;
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
        color: var(--gray-120);
        svg {
            color: var(--gray-120);
        }
    }
`;
