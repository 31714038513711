import { LineTableTrackingChannelProps } from '../../../../@types';
import * as S from './style';
import { calculateFinalOrders, calculateFinalRevenue, checkUtm, getAdsIdByAllChannelId, getMetricsByChannelFacebook, getMetricsTrackingByData, getNonListedSourcesMetrics, getOthersMetrics, platformIcons } from '../../utils';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useState, useEffect, useMemo } from 'react';
import useAuth from '../../../../hooks/useAuth';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { Checkbox } from '@mui/material';
import { getAdsDataWarning } from '../../utils';
import { getAlertAccount } from '../../utils';
import { useCheckboxTracking } from '../../../../hooks/useCheckboxTracking';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useColumnsTracking } from '../../../../hooks/useColumnsTracking';
import Tooltip from '@mui/material/Tooltip';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import LinesItem from '../../LinesItem';

export function LineTableTrackingChannel({ type, isCatalogChannel, data, columnSolomon, paymentMethod, handleSelectGraphicData, handleScrolltoGraphic, openSalesModal, date, columnWidths, loadingCatalog }: LineTableTrackingChannelProps) {
    const [showMoreDetailsFacebook, setShowMoreDetailsFacebook] = useState(false);
    // const [showMoreDetailsOthers, setShowMoreDetailsOthers] = useState(true);
    const { isVisible } = useVisibility();
    const { selectedPlatforms, togglePlatform, selectedPlatformsOthers, togglePlatformIfOthers } = useCheckboxTracking();
    const { currentCompany, user } = useAuth()
    const [showWarnings, setShowWarnings] = useState({
        facebook: false,
        google: false,
        popup: false
    });
    const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);
    const { columns } = useColumnsTracking();
    const {currencySymbol} = useCurrencyType();
    const filteredAdsData = useMemo(() => getAdsDataWarning(data, type), [data, type]);
    const adsString = useMemo(() => getAdsIdByAllChannelId(data, type.toString()), [data, type]);
    const [activePaymentMethods, setActivePaymentMethods] = useState([]);

    useEffect(() => {
        utm_is_right(type.toString(), filteredAdsData, adsString);
    }, [filteredAdsData, adsString, type]);

    function utm_is_right(type: string, filteredAdsData: any, adsString: string) {
        const adsStrings = adsString.split(',').map(str => str.trim());

        adsStrings.forEach(adString => {
            let AdsData = filteredAdsData[adString];
            if (!adString || !AdsData) {
                return;
            }
            if (!checkUtm(type, AdsData.utm_source, AdsData.utm_medium, AdsData.utm_campaign, AdsData.utm_content)) {
                setShowWarnings(prevState => ({
                    ...prevState,
                    [type]: true
                }));
            }
        });
    }
    const isMainSource = ['facebook', 'tiktok', 'google'].includes(type.toString());

    useEffect(() => {
        if (paymentMethod) {
            let methods = [];
            if (paymentMethod['PIX']) methods.push('PIX');
            if (paymentMethod['Boleto']) methods.push('Boleto');
            if (paymentMethod['Cartão de Crédito']) methods.push('Cartão de Crédito');
            setActivePaymentMethods(methods);
        }
    }, [paymentMethod]);  // Dependências para controlar a execução do useEffect
    
    const { otherMetrics } = getOthersMetrics(data);
    // //console.log('othermetrics', otherMetrics)
    const metrics = useMemo(() => getMetricsTrackingByData(data, type.toString(), activePaymentMethods), [data, type, activePaymentMethods]);

    // Inicializa a variável que indica a presença de dados significativos
    let hasSignificantData = false;

    // Função para verificar se há dados não vazios
    const checkIfDataIsNotEmpty = (metricsObject: any) => {
        return Object.values(metricsObject).some(metric =>
            typeof metric === 'object' &&
            metric !== null &&
            Object.keys(metric).length > 0 &&
            Object.values(metric).some(value => value !== 0)
        );
    };

    // Itera sobre os objetos de métricas para verificar se há dados
    Object.values(nonListedSourcesMetrics).forEach(sourceMetrics => {
        if (checkIfDataIsNotEmpty(sourceMetrics)) {
            hasSignificantData = true;
        }
    });

    let ordersSolomon = 0;
    let revenueSolomon = 0;
    
    const { metricsByFacebookAdsAccount } = getMetricsByChannelFacebook(data, activePaymentMethods);

    const metricAlertAccount = getAlertAccount(data);
    
    let LinesItemAds;
    let LinesItemType;
    let LinesItemSource;

  if (type === 'facebook' || type === 'tiktok' || type === 'google') {        
        revenueSolomon = calculateFinalRevenue(columnSolomon, metrics, activePaymentMethods);
        ordersSolomon = calculateFinalOrders(columnSolomon, metrics, activePaymentMethods, false,  true);
        LinesItemAds = metrics;
        LinesItemType = 'channel';
        LinesItemSource = type;

        // //console.log('metrics platforms', metrics, 'type', type)
    }

    // const campaignNameHasCatalogPlaceholder = campaign?.isCatalog === true;
    console.log('isCatalogChannel', isCatalogChannel);
    const channelHasCatalogPlaceholder = isCatalogChannel;

    console.log('linesItemAds', LinesItemAds);

    const handleCatalogClick = () => {
        openSalesModal({
            source: 'facebook',
            utm_campaign: '',
            company_id: currentCompany,
            level: 'source',
            start_date: date[0],
            end_date: date[1],
            name: LinesItemAds?.platformName
        }, LinesItemAds, 'channel', false, true, ordersSolomon, revenueSolomon)
    };

    return (
        <>
            <S.Container>
                <S.MainColumn style={type === 'outros' ? {width: `${columnWidths}`, display: 'none'} : { width: `${columnWidths}px` }} type={type.toString()} isMainSource={isMainSource}>
                    <S.CheckBoxArea>                                
                            {(type === 'facebook' && metricsByFacebookAdsAccount?.length >= 2) ?
                                (<Checkbox
                                    checked={metricsByFacebookAdsAccount?.every(account => selectedPlatforms[account?.accountId])}
                                    onChange={() => {
                                        metricsByFacebookAdsAccount.forEach(account => {
                                            togglePlatform(account?.accountId); // Inverte o estado atual
                                        });
                                    }}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#19c273',
                                        },
                                    }}
                                />):
                                (
                                    <Checkbox
                                        checked={selectedPlatforms[type.toString()] || false}
                                        onChange={() => togglePlatform(type.toString())}
                                        size="small"
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#19c273',
                                            },
                                        }}
                                    />
                                )
                            }
                    </S.CheckBoxArea>
                        <img src={platformIcons[type.toString()]?.platformImg} alt="Logo" /> 
                        {(metricsByFacebookAdsAccount?.length >= 2 && type === 'facebook')  ? (
                                <S.UrlLinkClick onClick={() => setShowMoreDetailsFacebook(prev => !prev)}>
                                {platformIcons[type]?.platformName}
                                <S.ExpandContainer>
                                    {showMoreDetailsFacebook ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                                </S.ExpandContainer>
                            </S.UrlLinkClick>
                        ):
                        (<S.UrlLinkClick onClick={() => togglePlatform(type.toString(), 'campaign')}>{platformIcons[type.toString()]?.platformName}</S.UrlLinkClick>)
                        }                                    
                        
                        <S.ActionArea>
                        { typeof(type) === 'string' && showWarnings[type] && (
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            borderColor: '#E90E0E',
                                            fontWeight: '550',
                                            fontSize: '13px',
                                            fontFamily: 'Montserrat',
                                            borderRadius: '7px',
                                            border: 1,
                                            padding: '10px',
                                            zIndex: 0,
                                            color: '#E90E0E',
                                        }
                                    },
                                }}
                                title={'Existem anúncios no período selecionado com UTMs incorretas'} placement="right">
                                <S.ProductNameContainer> <WarningIcon /></S.ProductNameContainer>
                            </Tooltip>)
                        }
                        </S.ActionArea>
                        <S.ProductNameContainerPlaceholder></S.ProductNameContainerPlaceholder>
                </S.MainColumn>

                <LinesItem HasCatalog={type === 'facebook' ? channelHasCatalogPlaceholder : false} handleCatalogClick={type === 'facebook' ? handleCatalogClick : ''} columns={columns} currencySymbol={currencySymbol} ads={LinesItemAds} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} type={LinesItemType} openSalesModal={openSalesModal} currentCompany={currentCompany} date={date} adsString={adsString}

                        source={LinesItemSource} user={user} loadingCatalog={type === 'facebook' ? loadingCatalog : false}/>


            </S.Container>
            {type === 'outros' && hasSignificantData && Object.keys(nonListedSourcesMetrics).length > 0 &&
                Object.keys(nonListedSourcesMetrics)?.map((source: string) => {
                    const ordersSolomon = calculateFinalOrders(columnSolomon, nonListedSourcesMetrics?.[source], activePaymentMethods, false, false, true);
                    const revenueSolomon = calculateFinalRevenue(columnSolomon, nonListedSourcesMetrics?.[source], activePaymentMethods, false, true );
                    const thisMetrics = {
                        'profitByUtmApproved': nonListedSourcesMetrics?.[source].profit.metrics
                    }

                    return (
                        <S.ContainerAccount key={source}>
                            <S.MainColumnAccount style={{ width: `${columnWidths}px` }} type={type} isMainSource={isMainSource}>
                                <S.CheckBoxAreaOthers style={{ marginLeft: '-56px' }} >
                                    <Checkbox
                                        checked={selectedPlatformsOthers[source] || false}
                                        onChange={() => togglePlatformIfOthers(source)}
                                        size="small"
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#19c273',
                                            },
                                        }}
                                    />  
                                </S.CheckBoxAreaOthers>
                                {' '}
                                <span /> 
                                
                                <img style={{ marginLeft: '-26px' }} src="https://storage.googleapis.com/static-images-source/other-source-tracking.png" />{' '}

                                <S.UrlLinkClick onClick={() => togglePlatformIfOthers(source, 'campaign')}>{isVisible ? (source !== 'organic_and_social') ? source : 'Orgânico / Social' : '* * * * * * * * '}</S.UrlLinkClick>

                            </S.MainColumnAccount>

                            <LinesItem HasCatalog={false} handleCatalogClick={false} columns={columns} currencySymbol={currencySymbol} ads={thisMetrics} ordersSolomon={ordersSolomon} 

                            revenueSolomon={revenueSolomon} type={'moreDetailOthers'} openSalesModal={openSalesModal} currentCompany={currentCompany} date={date} adsString={adsString} source={source} user={user} loadingCatalog={false} />

                        </S.ContainerAccount>
                    );
                })}

                {type === 'facebook' && showMoreDetailsFacebook && 

                    metricsByFacebookAdsAccount?.map((account: any) => {         

                        const revenueSolomon = calculateFinalRevenue(columnSolomon, account, activePaymentMethods);
                        const ordersSolomon = calculateFinalOrders(columnSolomon, account, activePaymentMethods);

                        let showAlertIcon = false;

                        for (const alert of metricAlertAccount) {
                            if (alert.Account === account?.accountId && alert.showAlert) {
                                showAlertIcon = true;
                                break;
                            }
                        }

                        // //console.log('account', account)

                        return (
                            <S.ContainerAccount className={showMoreDetailsFacebook ? 'expanded' : ''} key={account?.accountId}>
                                <S.MainColumnAccount style={{ width: `${columnWidths}px` }} type={type} isMainSource={isMainSource}>
                                    <S.CheckBoxAreaAccount>
                                        <Checkbox
                                            checked={selectedPlatforms[account?.accountId] || false}
                                            onChange={() => togglePlatform(account?.accountId)}
                                            size="small"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#19c273',
                                                },
                                            }}
                                        />

                                    </S.CheckBoxAreaAccount>
                                    {' '}
                                    <span />{' '}
                                    {/* <S.AnalyticsButtonAccount onClick={() => handleSelectGraphicData('channelFacebook', account?.accountName, type, account?.accountId)}>
                                        <QueryStatsIcon sx={{ width: '16px', height: '16px' }} />
                                    </S.AnalyticsButtonAccount> */}
                                    <img src={platformIcons[type.toString()]?.platformImg} alt="Logo" />
                                    <S.UrlLinkClick onClick={() => (togglePlatform(account?.accountId, 'campaign'))}>{isVisible ? account?.accountName : '* * * * * * * * '}</S.UrlLinkClick>
                                    {account?.accountId !== 'sem_atribuicao' && (
                                        <S.AdsAccountsTitle>CONTA DE ANÚNCIOS</S.AdsAccountsTitle>
                                    )}
                                    <S.ActionArea>
                                    {showAlertIcon && (
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'white',
                                                    borderColor: '#E90E0E',
                                                    fontWeight: '550',
                                                    fontSize: '13px',
                                                    fontFamily: 'Montserrat',
                                                    borderRadius: '7px',
                                                    border: 1,
                                                    padding: '10px',
                                                    zIndex: 0,
                                                    color: '#E90E0E',
                                                }
                                            },
                                        }}
                                        title={'Existem anúncios no período selecionado com UTMs incorretas'} placement="right">
                                        <S.ProductNameContainer> <WarningIcon /> </S.ProductNameContainer>
                                    </Tooltip>)}
                                    </S.ActionArea>
                                    <S.ProductNameContainerPlaceholder></S.ProductNameContainerPlaceholder>
                                </S.MainColumnAccount>

                                <LinesItem HasCatalog={false} handleCatalogClick={false} columns={columns} currencySymbol={currencySymbol} ads={account} ordersSolomon={ordersSolomon} revenueSolomon={revenueSolomon} type={'account'} openSalesModal={openSalesModal}  currentCompany={currentCompany} date={date} adsString={adsString} source={'account'} user={user} loadingCatalog={false}/>

                            </S.ContainerAccount>
                        );
                    })}
        </>
    );
}

function WarningIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
        </svg>
    )
}
