import Graph from '../../../../components/Graph';
import { format, subDays } from 'date-fns';
import { ProductsCostDTO } from '../../../../@types';
import * as S from './style';
import Modal from '../../../../components/Modal';
import VariantCostHistoryTable from './VariantCostHistoryTable';


interface ModalVariantHistoryCostsProps {
    onClose: () => void;
    variant : any;
    dataVariantCosts: ProductsCostDTO;
    setDataProductCosts: (value: ProductsCostDTO) => void;
}


export function  ModalVariantHistoryCosts ({ onClose, variant, dataVariantCosts, setDataProductCosts }: ModalVariantHistoryCostsProps) {    

    const {xAxisData, yAxisDatasets} = getGraphData((dataVariantCosts ? dataVariantCosts.data?.data : []), variant);

    //console.log("data variants", dataVariantCosts)



    return (
        <Modal onClose={onClose}>
            <S.ChartModal>
                <S.FlexHeaderModal>
                    <S.ChartTitle>{'Histórico de Custos'}</S.ChartTitle>
                </S.FlexHeaderModal>
                <S.HistoryContent>
                    <S.HistoryTableContainer>
                    <VariantCostHistoryTable variant={variant} dataVariantCosts={dataVariantCosts} setDataProductCosts={setDataProductCosts} /> 
                    </S.HistoryTableContainer>
                    <S.MainChartContainer>                      
                        <Graph xAxisData={xAxisData} yAxisDatasets={yAxisDatasets} intervalData={5} />
                    </S.MainChartContainer>
                </S.HistoryContent>
            </S.ChartModal>         
        </Modal>
    );

};


function getGraphData(data: any, variant: any) {

    //console.log('variant', data)
    
    const numDays = 30;
    const today = new Date();
    const xAxisData: string[] = [];
    const yAxisDatasets: { name: string; data: number[]; currency: string; }[] = [{
        name: variant.variant_title,
        data: [],
        currency: variant.currency || 'BRL' // Assume USD como padrão
    }];

    for (let i = 0; i < numDays; i++) {
        const date = subDays(today, i);
        xAxisData.unshift(format(date, 'yyyy-MM-dd')); // Adiciona data formatada ao início do array para ordem cronológica

        const variantId = variant.variant_id;
        const costsForVariant = data.filter((item: any) =>
            item.variant_id === variantId &&
            new Date(item.period_start) <= date &&
            (!item.period_end || new Date(item.period_end) >= date)
        );

        // Escolhe o custo mais recente se houver múltiplos
        const mostRecentCost = costsForVariant.reduce((mostRecent: any, item: any) => {
            if (!mostRecent) return item;
            return new Date(item.updated_cost_at) > new Date(mostRecent.updated_cost_at) ? item : mostRecent;
        }, null);

        yAxisDatasets[0].data.unshift(mostRecentCost ? parseFloat(mostRecentCost.variant_cost) : 0); // Assume 0 se nenhum custo for encontrado
        
    }

    return { xAxisData, yAxisDatasets }; 
}

