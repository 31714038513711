import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import { trackEvent, identify } from '../segment/sendEvent'
;
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function loginUser(body: DTO.userLoginDTO): Promise<DTO.userLoginResponse> {
    console.log('vou enviar requisição de login para ' + BASE_URL + "/auth/login/password");
    const data: DTO.userLoginResponse = await api.post(`${BASE_URL}/auth/login/password`, body);
    const user = data.data.data.user

    await identify(user.user_id, {
        name: user.name,
        createdAt: user.created_at,
        email: user.credentials.email,
        phone: user.phone,
        status: user.status
    })

    await trackEvent('login', {
        userId: user.user_id,
        timestamp: new Date().toISOString(),
        company_id: "",
        company_name: ""
    })

    return data;
}

export async function createUser(body: DTO.userCreateDTO): Promise<DTO.userCreateResponse> {
    const data: DTO.userCreateResponse = await api.post(`${BASE_URL}/auth/users`, body);
    const user = data.data.data.user

    await identify(user.user_id, {
        name: user.name,
        createdAt: user.created_at,
        email: user.credentials.email,
        phone: user.phone,
        status: user.status
    })

    await trackEvent('signup', {
        userId: user.user_id,
        timestamp: new Date().toISOString(),
        name: user.name,
        phone: user.phone,
        email: user.credentials.email,
        company_id: "",
        company_name: ""
    })

    return data;
}

export async function revalidateUser(): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/revalidate`);
    return data;
}

export async function resetPasswordUser(body: DTO.userResetPasswordDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/password-reset`, body);
    return data;
}

export async function getToken(body: DTO.userTokenDTO): Promise<AxiosResponse> {
    const {session_token, user_id} = body;
    const newBody = { session_token, user_id, service: 'default' }
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/client/token`, newBody);
    return data;
}

