import { useNavigate } from 'react-router-dom';
import { CardPlan } from './CardPlan';
import * as S from './style';
import useAuth from '../../hooks/useAuth';
import { StorageAuthTokenProps, userDTO } from '../../@types';
import { storagePlaceholderCompanyGet, storagePlaceholderCompanySave } from '../../storage/storageCompanyToOnboard';
import { useCreateBillingAccount, useCreateCompanyOnboarding } from '../../services/hooks/integrationHooks';
import { useEffect, useState } from 'react';
import { trackEvent } from '../../services/segment/sendEvent';


export function Plans() {

    const navigate = useNavigate();
    const {user,  setUserContext, setTokenContext, setCurrentCompanyContext, currentCompany } = useAuth();
    const { mutate: handleCreateBillingAccount} = useCreateBillingAccount()
    const { mutate: createCompanyOnboarding } = useCreateCompanyOnboarding();
    const [placeholderExist, setPlaceholderExist] = useState(false);
    const [createBillingAccount, setCreateBillingAccount] = useState(false);
    const [createCompany, setCreateCompany] = useState(false);
    const [planContext, setPlanContext] = useState('');

    const bodyCompany = {
        email: user?.credentials?.email,
        company: 'placeholder',
        mask_id: user?.mask_id,
        referred: false,
        user_id: user?.user_id,
    };
    let verifyingCompany = '';
    
    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success")
        }).catch((err) => {
            //console.log(err)
        })

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        navigate('/login');
    }
    
    // verifica se o usuário ja tem uma company com nome placeholder
    useEffect(() => {
        // se o context=renew nos parametros da url ele nao cria uma nova company
        const urlParams = new URLSearchParams(window.location.search);
        const context = urlParams.get('context');
        setPlanContext(context ?? '');
        if (context && context === 'renew') {
            if (user?.companies && user?.companies[currentCompany]?.billing?.manager === 'shopify') {
                navigate('/shopify/payment?context=renew');
            }
            return;
        }

        if (verifyingCompany == currentCompany || placeholderExist || (!currentCompany && user?.companies && Object.keys(user.companies).length > 0)) {
            return;
        }    

        verifyingCompany = currentCompany;       
        let placeholderId = Object.entries(user?.companies ?? {}).find(([_, { company }]) => company === "placeholder")?.[0];
        if (placeholderId && user?.companies?.[placeholderId]?.integrations?.shopify?.accounts?.length === 1) {
            setPlaceholderExist(true);
            storagePlaceholderCompanySave(placeholderId);
        }
        else {
            placeholderId = storagePlaceholderCompanyGet();
            if (!placeholderId || placeholderId === 'undefined' || placeholderId === undefined) {      
                if(currentCompany === undefined || currentCompany === '' || currentCompany === "undefined"){
                    setCreateBillingAccount(true);                
                }
                else{
                    // vai em companies e olha se tem alguma com billing_account.owner_id = user.user_id
                    if ( ! Object.values(user?.companies ?? {}).find(({ billing_account }) => billing_account?.owner_id === user?.user_id)) {
                        setCreateBillingAccount(true);  
                    }else{
                        setCreateCompany(true);
                    }
                }
            }
            else {
                setPlaceholderExist(true);        
            }
        }
    }, [currentCompany]);
    
    useEffect(() => {
        if (createBillingAccount) {
            handleCreateBillingAccount(void 0, {
                onSuccess: () => {
                    createCompanyOnboarding(bodyCompany, {
                        onSuccess: (data) => {                                    
                            storagePlaceholderCompanySave(data.data.data.company_id);
                            setPlaceholderExist(true);
                        },
                    });
                }
            })
        }
    }, [createBillingAccount])

    useEffect(() => {
        if (createCompany) {
            createCompanyOnboarding(bodyCompany, {
                onSuccess: (data) => {                          
                    storagePlaceholderCompanySave(data.data.data.company_id);
                    setPlaceholderExist(true);
                },
            });
        }
    }, [createCompany])

    return(
        <S.Container>
            <S.Header>
                <span onClick={() => navigate('/lojas')}><StoreIcon />Minhas Lojas</span>
                <span><CreditCardIcon />Assinatura</span>
                <span onClick={() => window.open('/terms', '_blank')}><TermIcon />Termos</span>
                <span onClick={signOut}><LogoutIcon/> Sair</span>
            </S.Header>
            <S.Title>
                Planos disponíveis
            </S.Title>
            <S.InnerContainer>
                <CardPlan type='mensal' placeholderExist={placeholderExist} planContext={planContext}/>
                <CardPlan type='semestral' placeholderExist={placeholderExist} planContext={planContext}/>
            </S.InnerContainer>
        </S.Container>
    )
}

function StoreIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-720v-80h640v80H160Zm0 560v-240h-40v-80l40-200h640l40 200v80h-40v240h-80v-240H560v240H160Zm80-80h240v-160H240v160Z"/></svg>
    )
}

function CreditCardIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/></svg>
    )
}

function TermIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-160q-33 0-56.5-23.5T240-240v-120h120v-90q-35-2-66.5-15.5T236-506v-44h-46L60-680q36-46 89-65t107-19q27 0 52.5 4t51.5 15v-55h480v520q0 50-35 85t-85 35H320Zm120-200h240v80q0 17 11.5 28.5T720-240q17 0 28.5-11.5T760-280v-440H440v24l240 240v56h-56L510-514l-8 8q-14 14-29.5 25T440-464v104ZM224-630h92v86q12 8 25 11t27 3q23 0 41.5-7t36.5-25l8-8-56-56q-29-29-65-43.5T256-684q-20 0-38 3t-36 9l42 42Zm376 350H320v40h286q-3-9-4.5-19t-1.5-21Zm-280 40v-40 40Z"/></svg>
    )
}

function LogoutIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"/></svg>
    )
}