import { useState } from 'react';
import * as S from './styles';
import { SwitchLineAccountsFacebookProps } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import { useUpdateFacebookAdsAccount, useUpdateGoogleAdsAccount, useUpdateTiktokAdsAccount } from '../../../../../services/hooks/integrationHooks';
import { storageOnboardingIntegrationFacebookAdsSave, storageOnboardingIntegrationGoogleAdsSave, storageOnboardingIntegrationTiktokAdsSave } from '../../../../../storage/storageOnboarding';
import { storageOnboardingCurrentCompanyGet } from '../../../../../storage/storageOnboarding';

export function Switch({ isActive, profileId, accountId, setHasSomeAccountChanged, typeAccount, context }: SwitchLineAccountsFacebookProps) {
    const [isChecked, setIsChecked] = useState(isActive);
    const { currentCompany } = useAuth();
    const { mutate: updateAccountFacebook } = useUpdateFacebookAdsAccount();
    const { mutate: updateAccountTiktok } = useUpdateTiktokAdsAccount();
    const { mutate: updateAccountGoogle } = useUpdateGoogleAdsAccount();
  
    const handleToggle = () => {
        const body = {
            account_id: accountId,
            profile_id: profileId,
            company_id: (context === 'onboarding') ? storageOnboardingCurrentCompanyGet() : currentCompany,
            status: isChecked ? (typeAccount === 'tiktok_ads' ? 'inactive' : 'inative') : 'active',
        };
        if (typeAccount === 'facebook_ads') {
            updateAccountFacebook(body);
            if (context === 'onboarding'){
                storageOnboardingIntegrationFacebookAdsSave();
            }
        } else if (typeAccount === 'tiktok_ads') {
            updateAccountTiktok(body);
            if (context === 'onboarding'){
                storageOnboardingIntegrationTiktokAdsSave();
            }
        }else if (typeAccount === 'google_ads') {
            updateAccountGoogle(body);
            if (context === 'onboarding'){
                storageOnboardingIntegrationGoogleAdsSave();
            }
        }
        setIsChecked((prev) => !prev);
        setHasSomeAccountChanged(true);
    };

    return (
        <S.SwitchContainer>
            <S.SwitchLabel>
                <S.SwitchInput type="checkbox" onClick={handleToggle} />
                <S.Slider isChecked={isChecked} />
                <S.SliderBall isChecked={isChecked} />
            </S.SwitchLabel>
        </S.SwitchContainer>
    );
}
