//import { useGetSales } from '../../../services/hooks/trackingHooks'
import { useState } from 'react'
import { Search } from '../../../../components/Search'
import { LineClientSalesModal } from './LineClientSalesModal'
import * as S from './style'
import { useGetSalesByClient } from '../../../../services/hooks/trackingHooks'
import { toBrazilianFormat } from '../../../../utils'
import { LineSalesModalLoadingOrders } from '../TableOrdersModal/LineSalesModalLoading'
import { Skeleton } from '@mui/material'
import useVisibility from '../../../../hooks/useVisibilityChange'
import { useNavigate } from 'react-router-dom';
import {useCurrencyType} from '../../../../hooks/useCurrencyType'
import { truncate } from 'fs/promises'

export function ClientSalesModal({clientInfo, orders, onClose}: { clientInfo: any, orders: any, onClose: () => any }) {

    const {data, isLoading} = useGetSalesByClient(clientInfo)
    const [filtred, setFiltred] = useState('')
    const [showInfo, setShowInfo] = useState(true)
    const { isVisible } = useVisibility();
    const navigate = useNavigate();
    const { currencySymbol } = useCurrencyType();


    const Client = data?.data?.data

    const ordersThisClient = orders?.filter((order: any) => order?.customer_id === Client?.customer_id)

    const filtredOrders = ordersThisClient?.filter((order: any) => order?.order_name?.includes(filtred));

    const sumProductPrice = filtredOrders?.reduce((acumulador: number, objeto: any) => {
        return acumulador + objeto?.product_price;
      }, 0);


      
    return(
        <S.Container>
            <S.Icon onClick={onClose}>
                <ArrowBackIcon/>
            </S.Icon>
            <S.Title>
                Informações de <span>Clientes</span>
            </S.Title>
            <S.Subtitle>
                Veja as informações de um cliente específico
            </S.Subtitle>
            <S.Line />
            {showInfo && <S.Section>
                <S.FirstSection>
                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                        <S.SquareIcon>
                            <ShoppingBagIcon />
                        </S.SquareIcon>
                        <S.FirstSectionTitle>
                            Total em compras
                        </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                            {currencySymbol} {toBrazilianFormat(sumProductPrice)}
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>

                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                        <S.SquareIcon>
                            <OrderAproveIcon />
                        </S.SquareIcon>
                        <S.FirstSectionTitle>
                            Pedidos
                        </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                            {filtredOrders?.length ?? 0}
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>

                    <S.FirstSectionItem>
                        <S.FirstSectionHeader>
                        <S.SquareIcon>
                            <RewardedIcon />
                        </S.SquareIcon>
                        <S.FirstSectionTitle>
                            Tipo de Cliente
                        </S.FirstSectionTitle>
                        </S.FirstSectionHeader>
                        <S.FirstSectionValue>
                            {Client?.type === 'noob' ? 'Novato' : Client?.type === 'loyal' ? 'Fiéis' : Client?.type === 'inrisk' ? 'Em risco' : Client?.type === 'churned' ? 'Perdidos' : <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />} 
                        <S.MetricsButton onClick={() => navigate('/clientes')}>
                            <FrameInspectIcon />
                        </S.MetricsButton>
                        </S.FirstSectionValue>
                    </S.FirstSectionItem>

                </S.FirstSection>
                <S.VLine />
                <S.SecondSection>
                    <S.SecondSectionSubtitle>
                        Cliente
                    </S.SecondSectionSubtitle>
                    <S.SecondSectionTitle>
                        {(isVisible ? Client?.name : '* * * * * * * *') ?? <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />}
                    </S.SecondSectionTitle>


                    <S.SecondSectionSubtitle>
                        Informações de contato
                    </S.SecondSectionSubtitle>
                    <S.SecondSectionFirstTitle>
                        {(isVisible ? Client?.email : '* * * * * * * *') ?? <Skeleton sx={{ fontSize: '1rem', width: '8rem', height: '2rem' }} />}
                    </S.SecondSectionFirstTitle>
                    <S.SecondSectionTitle>
                        {isVisible ? Client?.phone : '* * * * * * * *'}
                    </S.SecondSectionTitle>
                </S.SecondSection>

                <S.SecondSection>
                <S.SecondSectionSubtitle>
                        Endereço
                    </S.SecondSectionSubtitle>
                    <S.SecondSectionTitle>
                        {(isVisible ? Client?.address : '* * * * * * * *' )?? <Skeleton sx={{ fontSize: '1rem', width: '10rem', height: '2rem' }} />}
                    </S.SecondSectionTitle>
                </S.SecondSection>
            </S.Section>}
            <S.SearchSection>
                <Search width="20rem" height="2.5rem" placeholder="Pesquisar por número" value={filtred} setValue={setFiltred} />
                <S.Expand onClick={() => setShowInfo(prev => !prev)}>
                    {showInfo ? <MinimizeIcon /> : <ExpandIcon />}
                </S.Expand>
            </S.SearchSection>

            <S.InnerContainer>
            <S.Header>
                <S.MainColumn>
                    Pedido
                </S.MainColumn>
                <S.DefaultColumn>
                    Status
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Data/hora
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Cliente
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Meio de Pagamento
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Valor
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Custo de Produto
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Taxas
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Gateway
                </S.DefaultColumn>
                <S.DefaultColumn>
                    Checkout
                </S.DefaultColumn>
            </S.Header>

            <S.Lines>
                {!isLoading && filtredOrders?.map((order: any) => {
                    return<LineClientSalesModal order={order} />
                })}
                {isLoading && Array.from({length: 2}).map((_, index) => {
                return <LineSalesModalLoadingOrders isCustomers={true} key={index}/>
            })}
            </S.Lines>

            </S.InnerContainer>
            
        </S.Container>
    )
}


function ArrowBackIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
    </svg>
    )
}


function ExpandIcon () {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180ZM358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z"/>
        </svg>
    )
}

function MinimizeIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="m356-160-56-56 180-180 180 180-56 56-124-124-124 124Zm124-404L300-744l56-56 124 124 124-124 56 56-180 180Z"/>
    </svg>
    )
}

function FrameInspectIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M450-420q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm193 160L538-365q-20 13-42.5 19t-45.5 6q-71 0-120.5-49.5T280-510q0-71 49.5-120.5T450-680q71 0 120.5 49.5T620-510q0 23-6.5 45.5T594-422l106 106-57 56ZM200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80Z"/>
        </svg>
    )
}

function ShoppingBagIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M240-80q-33 0-56.5-23.5T160-160v-480q0-33 23.5-56.5T240-720h80q0-66 47-113t113-47q66 0 113 47t47 113h80q33 0 56.5 23.5T800-640v480q0 33-23.5 56.5T720-80H240Zm0-80h480v-480h-80v80q0 17-11.5 28.5T600-520q-17 0-28.5-11.5T560-560v-80H400v80q0 17-11.5 28.5T360-520q-17 0-28.5-11.5T320-560v-80h-80v480Zm160-560h160q0-33-23.5-56.5T480-800q-33 0-56.5 23.5T400-720ZM240-160v-480 480Z"/>
    </svg>
    )
}

function OrderAproveIcon(){
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="m691-150 139-138-42-42-97 95-39-39-42 43 81 81ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-19-9-39-15t-41-9v-243H200v562h243q5 31 15.5 59T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Zm120-200h203q3-21 9-41t15-39H240v80Zm0-160h284q38-37 88.5-58.5T720-520H240v80Zm-40 242v-562 562Z"/>
    </svg>
    )
}

function RewardedIcon(){
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M480-520q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM280-120v-80h160v-124q-49-11-87.5-41.5T296-442q-75-9-125.5-65.5T120-640v-40q0-33 23.5-56.5T200-760h80v-80h400v80h80q33 0 56.5 23.5T840-680v40q0 76-50.5 132.5T664-442q-18 46-56.5 76.5T520-324v124h160v80H280Zm0-408v-152h-80v40q0 38 22 68.5t58 43.5Zm200 128q50 0 85-35t35-85v-240H360v240q0 50 35 85t85 35Zm200-128q36-13 58-43.5t22-68.5v-40h-80v152Zm-200-52Z"/>
    </svg>
    )
}