import { LineAccountsProps } from '../../../../@types';
import { Switch } from './Switch';
import * as S from './style';

export function LineAccounts({ account, profileId, setHasSomeAccountChanged, typeAccount, context }: LineAccountsProps) {

    const toggleAccountStatus = () => {
        // Atualize o status aqui, isso pode envolver chamar uma API ou atualizar um estado global/contexto
        setHasSomeAccountChanged(true);
        // Supondo que 'account' é parte de um estado maior que pode ser atualizado
        // const newStatus = account.status === 'active' ? 'inactive' : 'active';
    };

    return (
        <S.Container>
            <S.AccountId>
                <S.AccountIdText>ID DA CONTA</S.AccountIdText>
                <S.AccountIdValue>{account?.account_id}</S.AccountIdValue>
            </S.AccountId>
            <S.AccountName>
                <S.AccountNameText>NOME DA CONTA</S.AccountNameText>
                {typeAccount === 'facebook_ads' && <S.AccountNameValue>{account?.assets?.account_name}</S.AccountNameValue>}
                {typeAccount === 'tiktok_ads' && <S.AccountNameValue>{account?.assets?.advertiser_name}</S.AccountNameValue>}
            </S.AccountName>
            {/* <S.Status isActive={account?.status === 'active'}>
                <S.StatusValue>{account?.status === 'active' ? 'Ativo' : 'Inativo'}</S.StatusValue>
            </S.Status> */}
            <S.Switch>
                <S.SwitchValue>
                    <Switch
                        context={context}
                        typeAccount={typeAccount}
                        accountId={account?.account_id}
                        isActive={account?.status === 'active'}
                        setHasSomeAccountChanged={setHasSomeAccountChanged}
                        profileId={profileId}
                
                    />
                </S.SwitchValue>
            </S.Switch>
        </S.Container>
    );
}
