import { GraphProps } from '../../../../@types';
import { sumArrays } from '../../../../utils';
import { getChartMetricsCosts, getMetrics, getSubMetrics } from '../../utils';
import * as S from './style';
import ReactEcharts from 'echarts-for-react';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';

export function FinancialMetrics({ isLoading, data, taxes, dataGraphic, orders, shipping, ordersGraphics }: GraphProps) {
    const { metricTax } = getSubMetrics(data, taxes, orders, shipping);

    const { xAxis, taxSeriesChart, profitLiquidChart, totalSpendChartData } = getMetrics(dataGraphic, taxes, ordersGraphics, shipping, metricTax);

    //console.log('totalSpend Financial', totalSpendChartData);

    const totalApprovedRevenueChartData = dataGraphic?.approved_revenue_chart.approved['y_axis'];

    // //console.log('totalSpend (index financialMetrics)', sumArrays(totalSpendChartData, taxSeriesChart));

    if (isLoading) {
        return (
            <S.Container>
                <S.LoadingContainer />
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Title>Métricas Financeiras</S.Title>
            <S.Graphic>
                <ReactEcharts
                    style={{ height: '232px', width: '684px' }}
                    option={getOption(xAxis ?? [], profitLiquidChart ?? [], totalApprovedRevenueChartData ?? [], totalSpendChartData ?? [])}
                />
            </S.Graphic>
        </S.Container>
    );
}

function getOption(xAxis, yAxisProfit, yAxisRevenue, yAxisSpend) {
    const maxValue = Math.max(...yAxisProfit, ...yAxisRevenue, ...yAxisSpend);
    const minValue = Math.min(...yAxisProfit, ...yAxisRevenue, ...yAxisSpend);
    const symetricalLimit = Math.max(Math.abs(maxValue), Math.abs(minValue));
    const { currencySymbol } = useCurrencyType();

    return {
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
            formatter: function (params) {
                let result = params[0]?.name + '<br/>';

                let currencyTypeGraph = 'R$';
                if (currencySymbol === '$') {
                    currencyTypeGraph = 'USD';
                } else if (currencySymbol === 'R$'){
                    currencyTypeGraph = 'BRL';
                } else if (currencySymbol === '€') {
                    currencyTypeGraph = 'EUR';
                } else if (currencySymbol === 'AU$') {
                    currencyTypeGraph = 'AUD';
                } else if (currencySymbol === '£') {
                    currencyTypeGraph = 'GBP';
                } else if (currencySymbol === 'COP') {
                    currencyTypeGraph = 'COP';
                } else if (currencySymbol === 'ARS') {
                    currencyTypeGraph = 'ARS';
                } else if (currencySymbol === 'C$') {
                    currencyTypeGraph = 'CAD';
                } else if (currencySymbol === '¥') {
                    currencyTypeGraph = 'JPY';
                } else if (currencySymbol === '¥') {
                    currencyTypeGraph = 'JPY';
                }


                params?.forEach(function (item) {
                    const color = item.value < 0 ? '#E90E0E' : '#19c27e'; // Color for negative values
                    result +=
                        '<span style="display:inline-block;margin-right:5px;border-radius:3px;width:10px;height:10px;background-color:' +
                        color +
                        ';"></span>' +
                        item.seriesName +
                        ': <span style="color:' + color + '; font-weight: 550;">' +
                        item?.value?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: currencyTypeGraph,
                        }) +
                        '</span><br/>';
                });
                return result;
            },
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: false },
                saveAsImage: { show: false },
            },
        },
        legend: {
            show: true,
            data: ['Receita', 'Gasto', 'Lucro'],
            textStyle: {
                fontSize: 11,
                color: '#23262f',
            },
        },
        xAxis: [
            {
                type: 'category',
                data: xAxis,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    show: true,
                    interval: 0,
                    formatter: function (value) {
                        const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                        const parts = value.split('-');
                        const day = parts[2];
                        const month = monthNames[parseInt(parts[1]) - 1];
                        return `${day}/${month}`;
                    },
                },
                splitLine: { show: false },
            },
        ],
        yAxis: [
            {
                type: 'value',
                min: -symetricalLimit,
                max: symetricalLimit,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: '#ddd',
                    },
                },
                splitNumber: 1,
            },
        ],
        series: [
            {
                name: 'Lucro',
                type: 'line',
                data: yAxisProfit,
                lineStyle: {
                    color: '#2D5557',
                },
                itemStyle: {
                    color: '#2D5557',
                },
            },
            {
                name: 'Gasto',
                type: 'bar',
                stack: 'Total',
                label: {
                    show: false,
                    position: 'bottom',
                },
                itemStyle: {
                    color: '#F54F4F',
                    barBorderRadius: [0, 0, 5, 5],
                },
                data: yAxisSpend.map((value) => -value),
            },
            {
                name: 'Receita',
                type: 'bar',
                stack: 'Total',
                label: {
                    show: false,
                    position: 'top',
                },
                itemStyle: {
                    color: '#19c27e',
                    barBorderRadius: [5, 5, 0, 0],
                },
                data: yAxisRevenue,
            },
        ],
    };
}
