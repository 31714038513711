import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
    padding: 1rem;
    padding-bottom: 0rem;

    ::-webkit-scrollbar {
        width: 8px !important; /* Altura da barra de rolagem */
    }
    `;

export const InnerContainer = styled.div`
    max-height: 50vh;
    height: auto;
    margin-top: 5rem;
    padding: 0 1rem 0;
    overflow-y: auto;

`;

export const ButtonContainer = styled.div`
    width: fit-content;
    margin: 2rem auto 0rem auto;
    display: flex;
    justify-content: center;
`;

const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(-1);
    }
    to {
        transform: rotate(360deg) scaleX(-1);
    }
`;

export const SyncArea = styled.div`
    // margin-left: 10.5rem;
    display: flex;
    width: 38rem;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 99;
    margin-top: -3.8rem;
`

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;

export const SyncContainer = styled.button`
background-color: #ECECEC;
border-radius: 50px;
display: flex;
align-items: center;
gap: 0.1rem;
justify-content: center;
transition: 0.3s;
margin: 0 auto;
cursor: pointer;
&:hover{
    background-color: #212529;
    transform: translateY(2px);
    color: #fff;
    svg{
        transition: 0.3s;
        fill: #fff;
    }
}
width: 11.9rem;
height: 1.875rem;
&:disabled{
    svg{
        ${animationStyles}
        fill: var(--gray-120);
    }
    
}
`

export const SyncIcon = styled.div`
svg{
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: -0.2rem;
}
`

export const SyncText = styled.div`
font-size: 0.875rem;
font-weight: 500;
line-height: 1.3125rem;


`

export const PlaceholderArea = styled.div`
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 2rem 0;
`