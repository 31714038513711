import { createContext, useState } from 'react';
import { ProviderProps, VisibilityChangeContextProps } from '../@types';

// Cria um Context
const VisibilityChangeContext = createContext<VisibilityChangeContextProps>({
    isVisible: true,
    toggleVisibility: () => {},
  });

// Cria um Provider que usa esse Context
export const VisibilityChangeProvider = ({ children }: ProviderProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <VisibilityChangeContext.Provider value={{ isVisible, toggleVisibility }}>
      {children}
    </VisibilityChangeContext.Provider>
  );
}

export default VisibilityChangeContext;