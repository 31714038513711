import styled from 'styled-components';


export const MarketingTitle  = styled.h1`  
    color: #1e1e1e;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1rem;
`;

export const MarketingHighlight = styled.span`
    color: #19c380
`;

export const MarketingParagraph  = styled.p`  
    margin-top: 10px;
    color: var(--cinza, #989c97);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
`;

export const MarketingImage = styled.img`
    width: 4%;
    margin-bottom: 10px;
    margin-left: 1rem;
    margin-top: 0.5rem;
    flex-shrink: 0;
`;

export const MarketingLine = styled.hr`
    margin: 1rem 0rem 1rem 0rem;
    width: 99%;
    background-color: var(--gray-150);
    height: 2px;
    font-size: 12px;
`;

export const ModalContent = styled.div`
    box-shadow: none !important;
    border: none !important;
    height: 40rem;
    width: 60rem;

    p {
        color: var(--cinza, #989c97);
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 500;
        text-align: start;
    }
`;

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
    z-index: 1000;
`;

export const MarketingHeader  = styled.h1`  
    color: #19c380
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1rem;
`;

export const DefaultColumn = styled.div`
    font-size: 12px;
    color: #989c97;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Each column takes up equal space */
    max-width: 40%; /* Maximum width of 25% */
    overflow: hidden; /* Prevents content from overflowing */
    text-overflow: ellipsis; /* Ellipsis for overflowed content */
    white-space: nowrap; /* Prevents wrapping */
`;

export const DefaultColumnCheck = styled.div`
    font-size: 12px;
    color: #989c97;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Each column takes up equal space */
    max-width: 15%; /* Maximum width of 25% */
    overflow: hidden; /* Prevents content from overflowing */
    text-overflow: ellipsis; /* Ellipsis for overflowed content */
    white-space: nowrap; /* Prevents wrapping */
`;

export const Header = styled.thead`
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    height: 2.5rem;
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 10px; /* Rounded upper-left corner */
    border-top-right-radius: 10px; /* Rounded upper-right corner */
`;

export const InnerContainer = styled.div`
    overflow: auto;
    height: 20rem;
    position: relative;
    margin: 1rem 1rem -1rem 1rem;
`;

export const Lines = styled.div`

    > div:nth-child(odd) {
        background-color: #ffffff; !important;
    }

    > div:nth-child(even) {
        background-color: #ffffff; !important;
    }
`;

export const ButtonContainer = styled.div`
    background-color: var(--green);
    color: var(--white);
    border-radius: 10px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    height: 2.5rem;
    margin: 1rem 1rem 0.25rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border: 1px solid var(--green);
    &:hover {
        background-color: #f8f8f8;
        color: var(--green);
    }
`;