import { submitLandingPageDTO } from './../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getLandingPage(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/landing-page?company_id=${company_id}`);
    return data;
}

export async function submitLandingPage(body: DTO.submitLandingPageDTO): Promise<AxiosResponse> {
    const { company_id, have_lp, lp_platform, lp_url } = body;

    console.log('body', body);

    const newBody = { company_id, have_lp, lp_platform, lp_url };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/landing-page`, newBody);
    
    return data;
}
