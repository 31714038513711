import { TemplateModal } from './../../index';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import * as S from './style';
import { ButtonApp } from '../../../../../components/ButtonApp';
import { InputIntegration } from '../../../../../components/InputIntegration';
import { useState } from 'react';
import { ModalCartpandaProps, userDTO } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateIntegrationCartpanda, useDeleteIntegrationCarpanda } from '../../../../../services/hooks/integrationHooks';
import { useIntercom } from 'react-use-intercom';
import useAlert from '../../../../../hooks/useAlert';
import ConfirmDisconnectModal from '../../../../../components/DisconnectModal';


export function ModalCartpanda({ setShowPopupCartpanda}: ModalCartpandaProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/cartpanda-icon.png';
    const baseColor = '#1e1e1e';

    const { user, currentCompany, setUserContext, updateUser } = useAuth();
    const { showAlert } = useAlert();
    const [showConfirmDisconnectPopup, setShowConfirmDisconnectPopup] = useState(false);

    const cartpandaInformation = user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts[user?.companies?.[currentCompany]?.integrations?.cartpanda?.accounts?.length - 1];

    const { showArticle } = useIntercom();

    const hasCartpandaInfo = cartpandaInformation?.account_id !== 'default-account';

    const { mutate: handleCreateIntegrationCartpanda, isLoading: isLoadingCreateIntegration, isError } = useCreateIntegrationCartpanda();
    const { mutate: handleDeleteIntegrationCartpanda, isLoading: isLoadingDeleteIntegration} = useDeleteIntegrationCarpanda()

    const [cartpandaInfo, setCartpandaInfo] = useState({
        aliasCartpanda: hasCartpandaInfo ? cartpandaInformation?.account_id : '',
        accessTokenCartpanda: cartpandaInformation?.assets?.token || '',
        planCartpanda: cartpandaInformation?.assets?.plan || 'light',
    });

    function handleIntegrate() {
        const body = {
            account_id: cartpandaInfo.aliasCartpanda,
            auth_token: cartpandaInfo.accessTokenCartpanda,
            plan: cartpandaInfo.planCartpanda,
            company_id: currentCompany,
            user: user
        };

        handleCreateIntegrationCartpanda(body, {
            onSuccess: () => {
                setShowPopupCartpanda(false);
                const active = {
                    account_id: cartpandaInfo.aliasCartpanda,
                    assets: {
                        token: cartpandaInfo.accessTokenCartpanda,
                        plan: cartpandaInfo.planCartpanda,
                    },
                    last_update: "",
                    status: "active"
                }
                const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, cartpanda: { ...user?.companies?.[currentCompany]?.integrations?.cartpanda, accounts: [active] } } } } };
                setUserContext(newUser as userDTO);
                updateUser()
            },
        });
    }

    function handleDesintegrate(){
        const body = {
            account_id: cartpandaInfo.aliasCartpanda,
            company_id: currentCompany,
            truncate: true,
            user: user
        };
        
        showAlert('Desconectando Cartpanda... Por favor, aguarde enquanto atualizamos os dados.', 'loading' , 60000, true);
        const inactive = {
            account_id: "default-account",
            assets: {},
            last_update: "",
            status: "inactive"
        }
        const newUser = { ...user, companies: { ...user?.companies, [currentCompany]: { ...user?.companies?.[currentCompany], integrations: { ...user?.companies?.[currentCompany]?.integrations, cartpanda: { ...user?.companies?.[currentCompany]?.integrations?.cartpanda, accounts: [inactive] } } } } };
        setUserContext(newUser as userDTO);
        updateUser();
        setShowPopupCartpanda(false);
        setShowConfirmDisconnectPopup(false);


        handleDeleteIntegrationCartpanda(body, {
            onSuccess: () => {

            },
        });
    }

    function handleChangeCartpandaInfo(e: any) {
        setCartpandaInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }


    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Cartpanda'}
                title={'Cartpanda'}
                subtitle={'Integre sua loja com a CartPanda e automatize seu processo de dropshipping.'}
                statusConnection={cartpandaInformation?.status === 'active' ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
                <InputIntegration value={cartpandaInfo.aliasCartpanda} setValue={handleChangeCartpandaInfo} inputName="Alias:" inputId="aliasCartpanda" placeholder="Solomon" />
                <InputIntegration
                    value={cartpandaInfo.accessTokenCartpanda}
                    setValue={handleChangeCartpandaInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenCartpanda"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                <label>Selecione o plano</label>
                <select id="planCartpanda" onChange={handleChangeCartpandaInfo}>
                    <option value="light">Light</option>
                    <option value="starter">Starter</option>
                    <option value="premium">Premium</option>
                    <option value="enterprise">Enterprise</option>
                </select>
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => {showArticle(8726307)}}>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>
            <S.DisconnectContainer>
                {!hasCartpandaInfo && (
                    <>
                        Salvar configurações:{' '}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleIntegrate}
                            text="Integrar"
                            isLoading={isLoadingCreateIntegration}
                        />
                    </>
                )}
                {hasCartpandaInfo && (
                    <>
                        Desconectar Integração:{' '}
                        <ButtonApp
                            border=''
                            width="7.75rem"
                            height="2.25rem"
                            color="#fff"
                            background="#F41B19"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={() => setShowConfirmDisconnectPopup(true)}
                            text="Desconectar"
                            disabled={cartpandaInfo.aliasCartpanda?.length === 0 || cartpandaInfo.accessTokenCartpanda?.length === 0}
                        />
                    </>
                )}
            </S.DisconnectContainer>

            {showConfirmDisconnectPopup && (
                <ConfirmDisconnectModal
                isOpen={showConfirmDisconnectPopup}
                onClose={() => setShowConfirmDisconnectPopup(false)}
                onConfirm={handleDesintegrate}
                title="Confirmação necessária"
                isLoading={isLoadingDeleteIntegration}
                subtitle={`Essa ação irá excluir permanentemente todos os dados coletados anteriormente por esta integração na Solomon. Tem certeza que deseja continuar?`}
              />
            )}
        </S.Container>
    );
}
