import styled from 'styled-components';


export const MarketingTitle  = styled.h1`  
    color: #1e1e1e;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: 1rem;
`;

export const MarketingHighlight = styled.span`
    color: #19c380
`;

export const MarketingImage = styled.img`
    width: 7%;
    margin-bottom: 21px;
    margin-left: 1rem;
    margin-top: 1rem;
    flex-shrink: 0;
`;

export const MarketingLine = styled.hr`
    margin: 2px;
    width: 99%;
    background-color: var(--gray-100);
    height: 0px;
    font-size: 12px;

`;

export const ModalContent = styled.div`
    box-shadow: none !important;
    border: none !important;
    height: 33rem;
    width: 29rem;

    p {
        color: var(--cinza, #989c97);
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 500;
        text-align: start;
    }
`;

