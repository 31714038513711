import useVisibility from '../../../../../hooks/useVisibilityChange';
import { reduceString } from '../../../../../utils';
import * as S from './style';

export function LineModalEditProductInMass({product}: {product: any}) {
    const { isVisible } = useVisibility();

    return(
        <S.Container>
            <S.ProductImage>
                {product?.product_image_src && <img src={isVisible ? product?.product_image_src : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt="product" />}
            </S.ProductImage>
            <S.ProductTitle>
                {isVisible ? reduceString(product?.product_title, 40) && reduceString(product?.product_title, 40) : '* * * * * * * * * * *'}
            </S.ProductTitle>
        </S.Container>
    )
}