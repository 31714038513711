import { InputLoginProps } from '../../@types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputMask from 'react-input-mask';
import * as S from './style';
import { useState } from 'react';

export function InputLogin({ inputId, inputName, inputType, required = false, width, inputLabel, setInputInformation, signup = false, inputInformation, emailSuggestions }: InputLoginProps) {
    const canShowPowerPassword = inputType === 'password' && signup;

    const [showText, setShowText] = useState(false);

    const [powerPassword, setPowerPassword] = useState('default');

    const handleInputChange = (e: any) => {
        if (canShowPowerPassword && inputInformation) {
            const password = inputInformation?.password;

            if (password?.length < 6) {
                setPowerPassword('weak');
            } else if (password?.match(/[a-z]/g) && password.match(/[0-9]/g) && password?.length > 8) {
                setPowerPassword('strong');
            } else if (password?.length > 7){
                setPowerPassword('medium');
            }
        }

        // @ts-ignore
        setInputInformation((prev: userCreateDTO) => {
            return {
                ...prev,
                [inputName]: e.target.value ?? '',
            };
        });
    };



    return (
        <S.Container width={width} canShowPowerPassword={canShowPowerPassword}>
            {inputType !== 'password' && inputType !== 'whatsapp' && inputName !== 'email' && (
                <input id={inputId} type={inputType} name={inputName} autoComplete="off" placeholder=" " onChange={handleInputChange} autoFocus={false} required={required} />
            )}
            {inputName === 'email' && (
                <>
                <input
                    list="email-suggestions"
                    id={inputId}
                    type={inputType}
                    name={inputName}
                    autoComplete="off"
                    placeholder=" "
                    value={inputInformation?.email}
                    onChange={handleInputChange}
                    autoFocus={false}
                    required={required}
                />
                <datalist id="email-suggestions">
                    {emailSuggestions?.map((email, index) => (
                    <option key={index} value={email} />
                    ))}
                </datalist>
                </>
            )}
            {inputType === 'password' && (
                <input
                    id={inputId}
                    type={showText ? 'text' : 'password'}
                    name={inputName}
                    value={inputInformation?.password}
                    autoComplete="off"
                    placeholder=""
                    onChange={handleInputChange}
                    autoFocus={false}
                    required={required}
                />
            )}
            {inputType === 'whatsapp' && (
                <InputMask mask="(99) 99999-9999" maskChar="_" placeholder=" " id={inputId} name={inputName} onChange={handleInputChange} autoFocus={false} required={required} />
            )}
            <label htmlFor={inputId}>{inputLabel}</label>
            {inputType === 'password' && (
                <S.VisibilityButton onClick={() => setShowText((prev) => !prev)}>
                    {!showText ? <VisibilityOffOutlinedIcon sx={{ width: '16px', height: '16px' }} /> : <VisibilityOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
                </S.VisibilityButton>
            )}
            {canShowPowerPassword && (
                <S.Line>
                    <S.PowerPassword powerPassword={powerPassword} />
                    <S.DefaultPowerPassword powerPassword={powerPassword} />
                </S.Line>
            )}
        </S.Container>
    );
}
