import { useMutation, useQuery } from 'react-query';
import { getUser, getUrlChangePassword } from '../api/api-account';

export function useGetUser(user_id: string) {
    return useQuery(['user', user_id], getUser, {});
}

export function useGetUrlChangePassword() {
    return useMutation(getUrlChangePassword);
}



// export function usePrefetchUser(){
//     return queryClient.prefetchQuery("dashboard", modelFunction,{
//         staleTime: 1000 * 60 * 5
//     })
// }
