import { SearchProps } from '../../@types';
import * as S from './style';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export function Search({ minWidth, width, placeholder, height = '2.8125rem', border, background, value, setValue }: SearchProps) {
    
    const clearSearch = () => {
        setValue(''); // Limpa o valor da pesquisa
    };

    return (
        <S.Container minWidth={minWidth} height={height} width={width} border={border} background={background}>
            
            {!value && (
                <SearchIcon />
            )}
            <S.Input type="text" onChange={(e) => setValue(e.target.value)} placeholder={placeholder} value={value} />
            {value && (
                <S.ClearIcon onClick={clearSearch}>
                    <CloseRoundedIcon />
                </S.ClearIcon>
            )}
        </S.Container>
    );
}

function SearchIcon() {
    return (
        <svg aria-hidden="true" viewBox="0 0 24 24">
            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
        </svg>
    );
}

