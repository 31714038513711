import styled from 'styled-components';


type ContainerProps = {
    percentage: boolean;
};


export const Container = styled.div<ContainerProps>`
    position: relative;
    border: 1px solid transparent;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }

    input {
        height: 2.5rem;
        width: ${(props) => props.percentage ? '28.1rem !important' : '30.5rem !important'};
        padding: ${(props) => props.percentage ? '0 2rem 0 3.5rem' : '0 2rem 0 1rem'};
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            border: 1px solid var(--green);
            outline: none;
            border-color: rgba(76, 234, 134, 0.4); 
            background-color: #fff; 
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); 
            border: 1px solid var(--green); 
        }
    }
`;

export const VisibilityButton = styled.div<ContainerProps>`
    color: var(--gray-900);
    position: absolute;
    top: 2.2rem;
    right: 1.6rem;
    cursor: pointer;
`;



export const InputIcon = styled.div`
position: absolute;
top: 1.7rem;
left: 1.8rem;
font-size: 1.25rem;
font-weight: 500;
line-height: 1.875rem;
color: var(--green);
width: max-content;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
`
