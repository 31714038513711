export default {
    title: 'dark',
    colors: {
        primary: '#DCDCDC',
        secondary: '#FFFFFF',
        tertiary: '#F5F5F5',

        // background: 'red',
        // text: '#000',

        // quaternary: '#000000',
        // quinary: '#000000',
        // senary: '#000000',

        // white: '#FFFFFF',
        // black: '#000000',
        // green: '#19c27e',
        // gray100: '#cccccc',
        // gray200: '#e7e7e7',
        // gray300: '#BFBFBF',
        // gray600: '#7b7b7b',
        // success: '#03BB85',
        // info: '#3B5998',
        // warning: '#FF6961',
    },
};
