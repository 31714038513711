import * as S from './style';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { IntegrationLoadingOnboardingV2 } from '../IntegrationLoadingOnboardingV2';
import {
    storageOnboardingCurrentCompanyGet,
    storageOnboardingCurrentCompanySave,
    storageOnboardingIntegrateCartpandaGet,
    storageOnboardingIntegrateDomPagamentosGet,
    storageOnboardingIntegrateAppmaxGet,
    storageOnboardingIntegrateOutrosGatewayGet,
    storageOnboardingIntegrateLpFormGet,
    storageOnboardingIntegrateYampiGet,
    storageOnboardingIntegrateYeverGet,
    storageOnboardingIntegrateAdooreiGet,
    storageOnboardingIntegrateUnicoPagGet,
    storageOnboardingIntegrateShopifyCheckoutGet,
    storageOnboardingIntegrateYeverGatewayGet,
    storageOnboardingIntegrationFacebookAdsGet,
    storageOnboardingIntegrationGoogleAdsGet,
    storageOnboardingIntegrationShopifyGet,
    storageOnboardingIntegrateMercadoPagoGatewayGet,
    storageOnboardingIntegrationShopifySave,
    storageOnboardingIntegrationTiktokAdsGet,
    storageOnboardingNameGet,
    storageOnboardingNameRemove,
    storageOnboardingNameSave,
    storageOnboardingStepGet,
    storageOnboardingStepSave,
    storageOnboardingUtmGoogleAdsGet,
    storageOnboardingUtmFacebookAdsGet,
    storageOnboardingUtmTiktokAdsGet,
} from '../../../storage/storageOnboarding';
import { useEffect, useState } from 'react';
import { get } from 'http';

export function StepperOnboarding({ step, getData }: { step: number, getData: any }) {
    const progressPercent = (step) * (100 / 6);

    const integrateFacebook = storageOnboardingIntegrationFacebookAdsGet();
    const integrateGoogle = storageOnboardingIntegrationGoogleAdsGet();
    const integrateTiktok = storageOnboardingIntegrationTiktokAdsGet();
    const integrateShopify = storageOnboardingIntegrationShopifyGet();
    const integrateCartpanda = storageOnboardingIntegrateCartpandaGet();
    const integrateYampi = storageOnboardingIntegrateYampiGet();
    const integrateYever = storageOnboardingIntegrateYeverGet();
    const integrateAdoorei = storageOnboardingIntegrateAdooreiGet();
    const integrateUnicoPag = storageOnboardingIntegrateUnicoPagGet();
    const integrateShopifyCheckout = storageOnboardingIntegrateShopifyCheckoutGet();
    const integrateYeverGateway = storageOnboardingIntegrateYeverGatewayGet();
    const integrateAppmaxGateway = storageOnboardingIntegrateAppmaxGet();
    const integrateOutrosGateway = storageOnboardingIntegrateOutrosGatewayGet();
    const integrateMercadoPagoGateway = storageOnboardingIntegrateMercadoPagoGatewayGet();
    const integrateDomPagamentosGateway = storageOnboardingIntegrateDomPagamentosGet();
    const integrateUtmGoogle = storageOnboardingUtmGoogleAdsGet();
    const integrateUtmFacebook = storageOnboardingUtmFacebookAdsGet();
    const integrateUtmTiktok = storageOnboardingUtmTiktokAdsGet();

    const [gatewayPlatformSelected, setGatewayPlatformSelected] = useState('');
    
    const gatewayStoredUpdate = localStorage.getItem(`info_gateway_${storageOnboardingCurrentCompanyGet()}`);
    const gatewayStoredParsedUpdate = gatewayStoredUpdate ? JSON.parse(gatewayStoredUpdate) : {};
    const gatewayInfoPlatformUpdate = gatewayStoredParsedUpdate?.platform;
    
    useEffect(() => {
        const gatewayStoredInfo = localStorage.getItem(`info_gateway_${storageOnboardingCurrentCompanyGet()}`);
        if (gatewayStoredInfo) {
            setGatewayPlatformSelected(JSON.parse(gatewayStoredInfo).platform);
        }
    }, [gatewayInfoPlatformUpdate]);

    const allDoneMarketing = getData.facebook_ads.done === true || getData.google.done === true || getData.tiktok_ads.done === true;
    const allDoneCheckout = getData.yampi.done === true || getData.cartpanda.done === true || getData.yever.done === true || getData.dom.done === true;; 

    return (
        <>
            <S.StepperContainer>
                <div>
                    <p>Processo de onboarding</p>
                    <span>{step}/6</span>
                </div>
                <S.Stepper>
                    <S.StepLoading style={{ width: `${progressPercent}%` }} />
                </S.Stepper>
            </S.StepperContainer>

            <S.StepCardContainer>
                <S.StepCard isActive={step >= 1}>
                    <S.LeftSideCard>
                        <S.CardStep isActive={step >= 1}>

                            {step >= 2 ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>1</span>}

                        </S.CardStep>

                        <S.CardInfos>
                            <h3>Informações da loja</h3>

                            {step >= 2 ? <span style={{ color: '#19c27e', fontWeight: '500' }}>Loja criada!</span> : <span>Detalhes importante</span>}

                        </S.CardInfos>
                    </S.LeftSideCard>
                </S.StepCard>

                <S.StepCard isActive={step >= 2}>

                    <S.LeftSideCard>
                        <S.CardStep isActive={step >= 2}>
                            {step >= 3  || integrateShopify ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>2</span>}

                        </S.CardStep>
                        <S.CardInfos>
                            <h3>Vendas</h3>
                            {getData?.shopify?.done === true ? (
                            <span style={{ color: '#19c27e', fontWeight: '500' }}>Vendas agregadas!</span>
                            ) : step >= 3 || integrateShopify ? (
                                <span style={{ color: '#19c27e', fontWeight: '500' }}>Agregando vendas...</span>
                            ) : (
                                <span>Vincule sua loja</span>
                            )}
                        </S.CardInfos>
                    </S.LeftSideCard>
                    <S.LoadIntegrationArea>
                        {integrateShopify && <IntegrationLoadingOnboardingV2 percentage={getData?.shopify?.percentage} type="Shopify" />}
                    </S.LoadIntegrationArea>
                </S.StepCard>


                <S.StepCard isActive={step >= 3}>
                    <S.LeftSideCard>

                        <S.CardStep isActive={step >= 3}>
                            { integrateFacebook || integrateGoogle || integrateTiktok ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>3</span>}
                        </S.CardStep>

                        <S.CardInfos>
                            <h3>Marketing</h3>
                            {allDoneMarketing ? (
                            <span style={{ color: '#19c27e', fontWeight: '500' }}>Plataformas integradas!</span>
                        ) : integrateFacebook || integrateGoogle || integrateTiktok ? (
                                <span style={{ color: '#19c27e', fontWeight: '500' }}>Analisando métricas...</span>
                            ) : (
                                <span>Integre suas plataformas</span>
                            )}

                            {/* { integrateFacebook || integrateGoogle || integrateTiktok ? <span style={{ color: '#19c27e', fontWeight: '500' }}>Analisando métricas...</span> : <span>Integre suas plataformas</span>} */}
                        </S.CardInfos>
                    </S.LeftSideCard>
                    <S.LoadIntegrationArea>

                        {integrateFacebook && <IntegrationLoadingOnboardingV2 percentage={getData?.facebook_ads?.percentage} type="Facebook" />}
                        {integrateGoogle && <IntegrationLoadingOnboardingV2 percentage={getData?.gooogle?.percentage} type="Google" />}
                        {integrateTiktok && <IntegrationLoadingOnboardingV2 percentage={getData?.tiktok_ads?.percentage} type="Tiktok" />}
                    </S.LoadIntegrationArea>

                </S.StepCard>


                <S.StepCard isActive={step >= 4}>
                    <S.LeftSideCard>
                        <S.CardStep isActive={step >= 4}>
                            { integrateYampi || integrateCartpanda || integrateYever || integrateAdoorei || integrateUnicoPag || integrateShopifyCheckout || integrateYeverGateway || integrateAppmaxGateway || integrateDomPagamentosGateway || integrateOutrosGateway || integrateMercadoPagoGateway ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>4</span>}
                        </S.CardStep>

                        <S.CardInfos>
                            <h3>Pagamentos</h3>

                            {allDoneCheckout ? (
                            <span style={{ color: '#19c27e', fontWeight: '500' }}>Taxas sincronizadas!</span>
                        ) : integrateYampi || integrateCartpanda || integrateYever || integrateAdoorei || integrateUnicoPag || integrateShopifyCheckout ||  integrateYeverGateway || integrateAppmaxGateway || integrateDomPagamentosGateway || integrateOutrosGateway || integrateMercadoPagoGateway? (
                                <span style={{ color: '#19c27e', fontWeight: '500' }}>Sincronizando taxas...</span>
                            ) : (
                                <span>Configure seu checkout e gateway</span>
                            )}
                            {/* {  integrateYampi || integrateCartpanda || integrateYever || integrateAdoorei || integrateUnicoPag || integrateYeverGateway || integrateAppmaxGateway || integrateDomPagamentosGateway || integrateOutrosGateway || integrateMercadoPagoGateway ? <span style={{ color: '#19c27e', fontWeight: '500' }}>Sincronizando taxas...</span> : <span>Configure seu checkout e gateway</span>} */}
                        </S.CardInfos>
                    </S.LeftSideCard>
                    <S.LoadIntegrationArea>

                        {integrateYampi && <IntegrationLoadingOnboardingV2 percentage={getData?.yampi?.percentage} type="Yampi (Checkout)" />}
                        {integrateCartpanda && <IntegrationLoadingOnboardingV2 percentage={getData?.cartpanda?.percentage} type="Cartpanda (Checkout)" />}
                        {integrateYever && <IntegrationLoadingOnboardingV2 percentage={getData?.yever?.percentage} type="Yever (Checkout)" />}
                        {integrateAdoorei && <IntegrationLoadingOnboardingV2 percentage={100} type="Adoorei (Checkout)" />}
                        {integrateUnicoPag && <IntegrationLoadingOnboardingV2 percentage={100} type="Unico Pag (Checkout)" />}
                        {integrateShopifyCheckout && <IntegrationLoadingOnboardingV2 percentage={100} type="Shopify (Checkout)" />}

                        <S.VLine/>
                        {integrateAppmaxGateway && gatewayPlatformSelected === 'appmaxGateway' && <IntegrationLoadingOnboardingV2 percentage={100} type="Appmax (Gateway)" />}
                        {integrateOutrosGateway && gatewayPlatformSelected === 'outrosGateway' && <IntegrationLoadingOnboardingV2 percentage={100} type="Outro (Gateway)" />}
                        {integrateMercadoPagoGateway && gatewayPlatformSelected === 'mercadoPagoGateway' && <IntegrationLoadingOnboardingV2 percentage={100} type="Mercado Pago (Gateway)" />}
                        {integrateYeverGateway && gatewayPlatformSelected === 'yeverGateway' && <IntegrationLoadingOnboardingV2 percentage={100} type="Yever (Gateway)" />}
                        {integrateDomPagamentosGateway && <IntegrationLoadingOnboardingV2 percentage={70} type="Dom Pagamentos (Gateway)" />}
                    </S.LoadIntegrationArea>

                </S.StepCard>


                <S.StepCard isActive={step >= 5}>
                    <S.LeftSideCard>
                        <S.CardStep isActive={step >= 5}>
                            {integrateUtmGoogle || integrateUtmFacebook || integrateUtmTiktok ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>5</span>}
                        </S.CardStep>

                        <S.CardInfos>
                            <h3>UTMs</h3>
                            {integrateUtmGoogle || integrateUtmFacebook || integrateUtmTiktok ? <span style={{ color: '#19c27e', fontWeight: '500' }}>UTMs Sincronizadas</span> : <span>Vincule as tags UTM</span>}
                        </S.CardInfos>
                    </S.LeftSideCard>
                    <S.LoadIntegrationArea>
                        {integrateUtmFacebook && <IntegrationLoadingOnboardingV2 percentage={100} type="Facebook" />}
                        {integrateUtmGoogle && <IntegrationLoadingOnboardingV2  percentage={100} type="Google" />}
                        {integrateUtmTiktok && <IntegrationLoadingOnboardingV2 percentage={100} type="Tiktok" />}
                    </S.LoadIntegrationArea>
                </S.StepCard>


                <S.StepCard style={{marginBottom: '2.5rem'}} isActive={step >= 6}>
                    <S.LeftSideCard>
                        <S.CardStep isActive={step >= 6}>
                            {step >= 6 ? <CheckRoundedIcon sx={{ width: '16px', height: '16px', color: '#19c27e' }} /> : <span>6</span>}
                        </S.CardStep>

                        <S.CardInfos>
                            {step >= 6 ? <h3 style={{ color: '#19c27e', fontWeight: '550' }}>Concluído!</h3> : <h3>Concluído</h3>}
                            {step >= 6 ? <span style={{ color: '#19c27e', fontWeight: '500' }}>Etapas concluídas!</span> : <span>Pronto para começar</span>}
                        </S.CardInfos>
                    </S.LeftSideCard>
                </S.StepCard>

            </S.StepCardContainer>
        </>
    );
}