import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import React, { useState, useRef, useEffect } from 'react';
import * as S from './style';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PixIcon from '@mui/icons-material/Pix';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { PaymentMethodSelectorProps } from '../../../@types';

function PaymentMethodSelector({ setPaymentMethod }: PaymentMethodSelectorProps) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedPayments, setSelectedPayments] = useState({});
    const ref = useRef<HTMLDivElement>(null);  // Add type assertion to ref

    function handleSelect(method) {
        setSelectedPayments(prev => {
            const newState = { ...prev, [method]: !prev[method] };
            setPaymentMethod(newState); // Atualizar o estado externo
            return newState; // Atualizar o estado interno
        });
    }


    // Função para contar quantos métodos estão selecionados
    const countSelectedMethods = () => {
        return Object.values(selectedPayments).filter(Boolean).length;
    }

    // Determinando o que mostrar no S.MethodSelected
    const methodSummary = () => {
        const count = countSelectedMethods();
        if (count === 1) {
            // Encontrar o método que está selecionado
            const selectedMethod = Object.keys(selectedPayments).find(method => selectedPayments[method]);
            if (selectedMethod === 'Cartão de Crédito') {
                return 'Cartão';
            }
            return selectedMethod;
        } else if (count > 1) {
            return `${count}`;
        } else {
            return 'Todos';
        }
    };
    
    
    useEffect(() => {
            function handleClickOutside(event: MouseEvent) {  // Add type annotation to event
                if (ref.current && !ref.current.contains(event.target as Node)) {  // Add type assertion to event.target
                    setShowDropdown(false);
                }
            }
    
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]); // Adicionar ref como dependência para garantir que o useEffect correto seja chamado
    
    const paymentIcons = {
        'PIX': <PixIcon />,
        'Boleto': <StickyNote2Icon />,
        'Cartão de Crédito': <CreditScoreIcon />
    };
          
    return (
        <div ref={ref}>
        <S.FilterPaymentArea onClick={() => setShowDropdown(!showDropdown)}>
            <S.FlexSelect>
                <PaymentRoundedIcon />
                <span>Método de pagamento</span>
            </S.FlexSelect>
                <S.MethodSelected>{methodSummary()}</S.MethodSelected>
          <ExpandMoreRoundedIcon style={{ transform: showDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all ease 250ms' }}/>
        </S.FilterPaymentArea>
        <S.Dropdown show={showDropdown}>
            {['PIX', 'Boleto', 'Cartão de Crédito'].map(method => (
                <S.DropdownItem key={method} onClick={() => handleSelect(method)}>
                {selectedPayments[method] ? <CheckBoxIcon style={{color: '#19c27e'}} /> : <CheckBoxOutlineBlankIcon  style={{color: '#999'}} />}
                <span style={{ marginLeft: '10px' }}>
                {selectedPayments[method] ? <S.Icons style={{color: '#19c27e'}}>{paymentIcons[method]}</S.Icons> : <S.Icons style={{color: '#999'}}>{paymentIcons[method]}</S.Icons>}
                </span>
                <span style={{ marginLeft: '10px' }}>{method}</span>
                </S.DropdownItem>
            ))}
            </S.Dropdown>
      </div>
    );
  }
  
  export default PaymentMethodSelector;