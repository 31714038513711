import * as S from './style';
import Skeleton from '@mui/material/Skeleton';

export function LineAdModalLoading() {
    return (
        <S.Container>
            <S.DefaultColumnCheck>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />
            </S.DefaultColumnCheck>
            <S.DefaultColumnCheck>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />
            </S.DefaultColumnCheck>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />   
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />   
            </S.DefaultColumn>
            <S.DefaultColumn>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />   
            </S.DefaultColumn>
            <S.DefaultColumnSync>
                <Skeleton sx={{ fontSize: '1rem', width: '4rem', height: '2rem' }} />
            </S.DefaultColumnSync>
        </S.Container>
    );
}
