import * as S from './style';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StoreIcon from '@mui/icons-material/Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LogoutIcon from '@mui/icons-material/Logout';
import { SelectHeaderProps, StorageAuthTokenProps, userDTO } from '../../../../../@types';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import { storagePlaceholderCompanySave } from '../../../../../storage/storageCompanyToOnboard';
import { trackEvent } from '../../../../../services/segment/sendEvent'

export function SelectHeader({ showSelect, isMobile }: SelectHeaderProps) {
    const navigate = useNavigate();
    const { setUserContext, setTokenContext, setCurrentCompanyContext, user, currentCompany } = useAuth();    

    const companies = user?.companies;
    if (!companies) return null;

    const manager = user?.companies[currentCompany]?.billing?.manager;

    function signOut() {
        trackEvent('logout', {
            userId: user.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success")
        }).catch((err) => {
            //console.log(err)
        })

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        navigate('/login');
    }

    
    return (
        <S.Container isMobile={isMobile} showSelect={showSelect} onClick={(e) => e.stopPropagation()}>
            <S.Option onClick={() => navigate('/conta')}>
                <ManageAccountsIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Conta
            </S.Option>
            <S.Option onClick={() => navigate('/lojas')}>
                <StoreIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Minhas Lojas
            </S.Option>

            {manager === 'shopify' && (
            <S.Option onClick={() => navigate('/shopify/subscription')}>
                <CreditCardIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Assinatura
            </S.Option>
            ) }
            {(manager === 'guru' || manager == 'asaas') &&  (
                //console.log('manager', manager),
            <S.Option onClick={() => navigate('/subscription')}>
                <CreditCardIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Assinatura
            </S.Option>
            )}

            <S.Line />
            <S.Option onClick={() => navigate('/terms')}>
                <HistoryEduIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Termos
            </S.Option>
            <S.OptionLogout onClick={signOut}>
                <LogoutIcon sx={{ width: '20px', height: '20px', color: '#A4A4A4' }} /> Sair
            </S.OptionLogout>
        </S.Container>
    );
}
