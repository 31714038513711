import { getSalesDTO, getSalesByClientDTO, configureUtmDTO, getCatalogListDTO } from '../../@types';
import { api } from '../api-confg';
const BASE_URL = import.meta.env.VITE_API_URL;
import { AxiosResponse } from 'axios';

export async function getTrackingMetrics(company_id: string, start_date: string, end_date: string, productIds: string[], attribution: string) {
    const params = new URLSearchParams({
        start_date,
        end_date,
        company_id,
        products: productIds.join(','), // Converte a lista de IDs em uma string separada por vírgulas
        attribution,
    }).toString();
    const data = await api.get(`${BASE_URL}/client-services/dashboard/marketing?${params}`);
    return data;
}

export async function getSales(body: getSalesDTO){
    const params = new URLSearchParams();
    console.log("body source", body.source)
    
    params.append('company_id', body.company_id);
    params.append('start_date', body.start_date);
    params.append('end_date', body.end_date);
    params.append('source', body.source);
    body.utm_campaign && params.append('utm_campaign', body.utm_campaign);
    body.attribution && params.append('attribution', body.attribution);
    params.append('level', body.level);
    if (body.ads_ids) {
        const adsIdsArray = body.ads_ids.split(',');
        adsIdsArray.forEach(id => params.append('ads_ids', id));
    }
    const url = `${BASE_URL}/client-services/io-handlers/marketing/orders/${body.level}?${params.toString()}`;
    const data = await api.get(url);
    return data
}

export async function getCatalogSales(body: getSalesDTO){
    const params = new URLSearchParams();
    console.log("body source", body)
    
    params.append('company_id', body.company_id);
    params.append('start_date', body.start_date);
    params.append('end_date', body.end_date);
    params.append('source', body.source);
    body.utm_campaign && params.append('utm_campaign', body.utm_campaign);
    body.attribution && params.append('attribution', body.attribution);
    params.append('level', body.level);
    if (body.ads_ids) {
        const adsIdsArray = body.ads_ids.split(',');
        adsIdsArray.forEach(id => params.append('ads_ids', id));
    }
    const url = `${BASE_URL}/client-services/io-handlers/marketing/catalogo/${body.level}?${params.toString()}`;
    const data = await api.get(url);
    return data
}

export async function getProductSales(body: getSalesDTO){
    const params = new URLSearchParams();
    console.log("body source", body.source)
    
    params.append('company_id', body.company_id);
    params.append('start_date', body.start_date);
    params.append('end_date', body.end_date);
    params.append('source', body.source);
    body.utm_campaign && params.append('utm_campaign', body.utm_campaign);
    body.attribution && params.append('attribution', body.attribution);
    params.append('level', body.level);
    if (body.ads_ids) {
        const adsIdsArray = body.ads_ids.split(',');
        adsIdsArray.forEach(id => params.append('ads_ids', id));
    }
    const url = `${BASE_URL}/client-services/io-handlers/marketing/products/${body.level}?${params.toString()}`;
    const data = await api.get(url);
    return data
}

export async function getSalesByClient(body: getSalesByClientDTO){
    const data = await api.get(`${BASE_URL}/client-services/io-handlers/customers/${body.customer_id}?company_id=${body.company_id}&start_date=${body.start_date}&end_date=${body.end_date}&customer_id=${body.customer_id}`)
    return data
}

export async function getCatalogList(company_id: string, start_date: string, end_date: string){
    const params = new URLSearchParams();
    params.append('company_id', company_id);
    params.append('start_date', start_date);
    params.append('end_date', end_date);

    const data = await api.get(`${BASE_URL}/client-services/marketing/catalogo?${params.toString()}`)
    return data
}

export async function configureUtm(body: configureUtmDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/integrations/marketing/utm`, body);
    return data;
}

export async function postFacebookStatus(body: any) {
    console.log("body", body)

    const bodyPost = {
        company_id: body.company_id,
        profile_id: body.profile_id,
        account_id: body.account_id,
    }

    const url = `${BASE_URL}/client-services/assets-management/facebook-ads/${body.object_type}/${body.object_id}/${body.action}`;
    const data = await api.post(url, bodyPost);

    return data;
}