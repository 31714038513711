import * as S from './styles';

const Switch = ({ id, value, setValue }: { id?: string; value: boolean; setValue: any }) => {
    console.log('', id);
    const handleToggle = () => {
        setValue((prev: boolean) => !prev);
    };

    return (
        <S.SwitchContainer>
            <S.SwitchLabel>
                <S.SwitchInput type="checkbox" onClick={handleToggle} />
                <S.Slider isChecked={value} />
                <S.SliderBall isChecked={value} />
            </S.SwitchLabel>
        </S.SwitchContainer>
    );
};

export default Switch;
