import { CardOnboardingFlowProps } from '../../../@types';
import * as S from './style';

export function CardOnboardingFlow({ type, setShowPopup, isIntegrate, url = '', showPopup }: CardOnboardingFlowProps) {
    const urlShopify = 'https://storage.googleapis.com/static-images-source/shopify-logo-horizontal.png';
    const urlFacebook = 'https://storage.googleapis.com/static-images-source/facebook-logo-horizontal.svg';
    const urlTiktok = 'https://storage.googleapis.com/static-images-source/tiktok-logo-horizontal.png';
    const urlGoogle = 'https://storage.googleapis.com/static-images-source/google-logo.png';
    const urlYampi = 'https://storage.googleapis.com/static-images-source/yampi-logo-horizontal.png';
    const urlCartpanda = 'https://storage.googleapis.com/static-images-source/cartpanda-logo-horizontal.png';
    const urlGateway = 'https://storage.googleapis.com/static-images-source/gateway-icon.png';
    const urlYever = 'https://storage.googleapis.com/static-images-source/yever.png';
    const urlDomPagamentos = 'https://storage.googleapis.com/static-images-source/dom%20pagamentos-icon.png'
    const urlKiwify = 'https://storage.googleapis.com/static-images-source/kiwify-logo.png'

    function openIntegration(type: string) {
        if (type === 'tiktok') {
            window.open(url, '_blank');
        }
    }

    function handleOpenPopup(event: React.MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
        if (type === 'shopify' && isIntegrate) {
            return;            
        }
        setShowPopup(!showPopup);
    }

    const images = {
        shopify: urlShopify,
        facebook: urlFacebook,
        tiktok: urlTiktok,
        google: urlGoogle,
        yampi: urlYampi,
        yever: urlYever,
        cartpanda: urlCartpanda,
        gateway: urlGateway,
        dom_pagamentos: urlDomPagamentos,
        kiwify: urlKiwify
    };

    if (type === 'tiktok') {
        return (
            <S.Container isIntegrate={isIntegrate} onClick={() => openIntegration(type)}>
                <img src={images[type]} alt={type} />
            </S.Container>
        );
    }

    return (
        <S.Container type={type} isIntegrate={isIntegrate} onClick={(e) => handleOpenPopup(e)}>
            <img src={images[type]} alt={type} /> <span>{type === 'gateway' && 'Gateway'}</span>
        </S.Container>
    );
}
