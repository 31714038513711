
import * as S from './style';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { LineProductsCostsInMass } from './LineProductsCostsInMass';
import { ModalEditProductInMass } from './ModalEditProductInMass';
import Modal from '../../../components/Modal';
import { ProductsCostDTO } from '../../../@types';
import { Checkbox } from '@mui/material';
import { useEffect } from 'react';
import { LineProductLoading } from '../TableProductsCosts/LineProductLoading';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';

export function TableProductsCostsInMass({selected, setSelected, selectAll, setSelectAll, currentPage, setCurrentPage, isLoading, onSelectedChange, filtred, products, showSelectInMass, setShowSelectInMass, dataProductCosts, editedVariants, setEditedVariants, setOpenSaveCostsModal}: {selected: any[], setSelected: (value: any[]) => void, selectAll: any, setSelectAll: (value: boolean) => void, currentPage: number, setCurrentPage: (page: number) => void, isLoading: any, onSelectedChange: (newSelected: string[]) => void , filtred: string, products: any, showSelectInMass: boolean, setShowSelectInMass: (show: boolean) => void, dataProductCosts: ProductsCostDTO, editedVariants: any, setEditedVariants: any, setOpenSaveCostsModal:any}) {
    // const [selected, setSelected] = useState<string[]>([]);
    // const [selectAll, setSelectAll] = useState(false); // Adicione este estado

    //console.log("selected items", selected);
    useEffect(() => {
        onSelectedChange(selected);
      }, [selected, onSelectedChange]);


    const filteredProducts = products.filter((product: any) => product?.product_title?.toLowerCase().includes(filtred.toLowerCase()));
    const totalElements = filteredProducts?.length;
    const itemPorPage = 6;
    const totalPages = Math.ceil(totalElements / itemPorPage);
    const startIndex = (currentPage - 1) * itemPorPage;
    const endIndex = Math.min(startIndex + itemPorPage - 1, totalElements - 1);
    const currentItems = filteredProducts.slice(startIndex, endIndex + 1);

    //@ts-ignore
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelected([]);
        } else {
            setSelected(filteredProducts.map(product => product.product_id));
        }
        setSelectAll(!selectAll);
    };

    return (
        <S.Container>
            <S.TableHeader>
            <S.Edit>
                    <Checkbox
                        size="small"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        sx={{
                            '&.Mui-checked': {
                                color: '#19c273',
                            },
                        }}
                    />
                </S.Edit>                
                <S.Image></S.Image>
                <S.ProductName>Produto</S.ProductName>
                <S.Price>Preço</S.Price>
                <S.Cost>Custo
                    <Tooltip title="Caso configure os custos deste produto individualmente por variante na aba 'Edição por variante' exibiremos a média dos valores de custo de todas as variantes." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                        <InfoRoundedIcon sx={{width: '15px', marginLeft: '8px', fill: '#7b9898'}}/>
                    </Tooltip>
                </S.Cost>
                <S.LastSale>Última venda em:</S.LastSale>
                <S.Historic>Histórico</S.Historic>
            </S.TableHeader>
            <S.Lines>
                
            {!isLoading && currentItems.length > 0 && (
             currentItems.map((product: any, index: any) => (
                <LineProductsCostsInMass 
                    key={index}
                    product={product} 
                    selected={selected} 
                    setSelected={setSelected} 
                    dataProductCosts={dataProductCosts}
                />
            ))
        )}
            {isLoading && Array.from({ length: 8 }).map((_, index) => <LineProductLoading key={index} />)}
            </S.Lines>

            <S.ContainerPagination>
                <Stack spacing={2}>
                    <Pagination
                        renderItem={(item) => <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />}
                        color="primary"
                        count={totalPages}
                        defaultPage={1}
                        page={currentPage}
                        onChange={handleChange}
                    />
                </Stack>
            </S.ContainerPagination>
            {showSelectInMass && <Modal onClose={() => setShowSelectInMass(false)}>
                    <ModalEditProductInMass products={products} selected={selected} editedVariants={editedVariants} setEditedVariants={setEditedVariants} setOpenSaveCostsModal={setOpenSaveCostsModal} setShowSelectInMass={setShowSelectInMass}/>
                </Modal>}
        </S.Container>
    );
}
