import styled, { keyframes } from 'styled-components';
import seta from './../../../assets/images/seta.png';

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;

`;

export const ContainerLoading = styled.div`
    width: 20rem;
    height: 3.2rem;
    border-radius: 8px;

    @media (max-width: 1050px) {
        width: 25rem;
        height: 3.6rem;
        border-radius: 8px;  
    }
`;

export const Container = styled.div`
    width: 30rem;
    height: 3.2rem;
    border-radius: 8px;
    padding: 0.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    
    @media (max-width: 1050px) {
        // width: 21rem;
    }
`;

export const TopSection = styled.div`
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
    // margin-bottom: 0.4rem;
    justify-content: space-between;
    width: 100%;
    > span {
        display: flex;
    }
`;

export const MetaSection = styled.div`
    // width: 100%;
    display: flex;
    justify-content: space-between ;
    align-items: center;
`;

export const LineSection = styled.div`
    width: 100%;
`;

export const Days = styled.div`
    font-size: 0.6rem;
    line-height: 1.125rem;
    color: #676767;
    margin-right: 0.5rem;
`;

export const InputSelect = styled.div`
    position: relative;
    border: 1px solid transparent;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
    }

    select {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        border-radius: 8px;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(${seta}) no-repeat center right #fff;
        background-size: 1rem;
        background-position: 23.5rem 0.75rem;

        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }

        &:focus,
        &:hover {
            /* border: 1px solid var(--green); */
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4); */
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border-bottom: 1px solid var(--green);
        }
    }
`;

export const InputDate = styled.div`
    position: relative;
    border: 1px solid transparent;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
    }

    input {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: calc(100% - 2rem);
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        border-radius: 8px;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;

        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }

        &:focus,
        &:hover {
            /* border: 1px solid var(--green); */
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4); */
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border-bottom: 1px solid var(--green);
        }
    }
`;

export const ButtonSubmitModal = styled.button`
    height: 2.1875rem;
    width: 18.75rem;
    border-radius: 7px;
    cursor: pointer;
    background-color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 600;
    margin: 1rem auto 0;
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export const Percentage = styled.div`
    font-size: 1.4rem;
    font-weight: 550;
    line-height: 1.65rem;
    color: #081b16;
`;

export const Status = styled.div`
    font-size: 0.5rem;
    line-height: 0.7rem;
    color: #666;
    margin-bottom: -0.2rem;
`;

export const Information = styled.div`
    font-size: 0.9rem;
    font-weight: 550;
    color: #081b16;
`;

export const EditIcon = styled.div`
    cursor: pointer;
    &:hover {
        svg {
            fill: #343a40;
        }
    }
    svg {
        width: 1rem;
        height: 1rem;
        fill: #989c96;
    }

    @media (max-width: 1050px) {
        display: none;
    }
`;

export const Meta = styled.div`
    font-size: 0.5rem;
    display: flex;
    // margin-top: 10px;
    /* gap: 0px; */
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    color: #7a958d;
    font-weight: 500;
    align-items: center;
    span {
        font-size: 0.75rem;
        font-weight: 550;
        color: #343a40;
    }
`;

export const Line = styled.div`
    height: 0.25rem;
    width: 100%;
    // margin-top: 0.25rem;

    background-color: #dde1e1;
    border-radius: 50px;
`;

type ProgressProps = {
    percentage: number;
};

export const Progress = styled.div<ProgressProps>`
    width: ${(props: ProgressProps) => props.percentage}%;
    height: 0.25rem;
    background-color: #091b16;
    border-radius: 50px;
`;

export const ContainerModal = styled.div`
    padding: 1rem;
    width: 25.5rem;
    height: 32.75rem;
    border-radius: 10px;
    background-color: #fff;
    > svg {
        height: 1.75rem;
        width: auto;
        fill: var(--green);
    }
`;

export const TitleModal = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #1e1e1e;
    margin-top: 0.5rem;
    span {
        color: var(--green);
    }
`;

export const SubtitleModal = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    color: #989c97;
    margin-bottom: 1rem;
`;

export const LineModal = styled.div`
    height: 1px;
    width: 100%;
    background-color: #c1c6c5;
    margin-bottom: 1rem;
`;
