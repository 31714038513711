import styled from 'styled-components';

type ContainerProps = {
    width: string;
    disabled: boolean;
};

export const Container = styled.div<ContainerProps>`
    position: relative;

    input {
        height: 2.7rem;
        border: 1.5px solid #989c96;
        border-radius: 8px;
        background: none;
        padding: 0.25rem 1rem 0.25rem !important;
        font-size: 1rem;
        // width: 36rem;
        width: ${(props) => props.width};
        // @media(max-width: 1600px) {
        //     width: calc(100% - 2rem);
        // }
        transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.875rem;
        line-height: 1.0625rem;
        ${(props) => (props.disabled ? 'background-color: #f8f8f8;' : 'background-color: #fff;')}

        &:not(:placeholder-shown) ~ label {
            transform: translateY(-50%) scale(0.8);
            background-color: #fff;

            padding: 0 0.2rem;
            ${(props) => (props.disabled ? 'color #989C96;' : 'color: var(--green);')}
        }

        &:not(:placeholder-shown) {
            ${(props) => (props.disabled ? 'border: 1.5px solid #989C96;' : 'border: 1.5px solid var(--green);')}
        }
    }

    label {
        position: absolute;
        left: 15px;
        bottom: 2.5rem;
        color: #989c96;
        pointer-events: none;
        transform: translateY(1.4rem);
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.75rem;
        line-height: 1.0625rem;
        font-weight: 550;
    }

    input:focus {
        outline: none;
        ${(props) => (props.disabled ? 'border: 1.5px solid #989C96;' : 'border: 1.5px solid var(--green);')}
    }

    input:focus ~ label {
        transform: translateY(-50%) scale(0.8);
        background-color: #fff;
        padding: 0 0.2rem;
        ${(props) => (props.disabled ? 'color #989C96;' : 'color: var(--green);')}
    }
`;
