import styled, { keyframes }from "styled-components";

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px);
        }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1rem;
    width: max-content;
    animation: ${floating} 2s ease-in-out infinite;
    img {
        width: 18.75rem;
        height: auto;
    }
`;

export const Title = styled.div`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: -1rem;
    line-height: 1.8rem;
    color: var(--green);
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    width: 58%;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
    white-space: wrap;
`;

