import { useQuery, useMutation } from 'react-query';
import { getSales, getProductSales, getCatalogSales, getSalesByClient, getTrackingMetrics, configureUtm, getCatalogList } from '../api/api-tracking';
import { getSalesDTO } from '../../@types';

export function useTrackingMetrics(company_id: string, start_date: string, end_date: string, productIds: string[], attribution: string) {
    return useQuery(['trackingMetrics', company_id, start_date, end_date, productIds, attribution], () => getTrackingMetrics(company_id, start_date, end_date, productIds, attribution), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetCatalogList(company_id: string, start_date: string, end_date: string) {
    return useQuery(['getCatalogList', company_id, start_date, end_date], () => getCatalogList(company_id, start_date, end_date), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export function useGetSales(body: getSalesDTO, options = {}){
    return useQuery(['getSales', body.start_date, body.end_date, body.source, body.company_id, body.level, body.ads_ids, body.name, body.utm_campaign, body.attribution], () => getSales(body), {
        ...options,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    })
}

export function useGetCatalogSales(body: getSalesDTO, options = {}){
    return useQuery(['getCatalogSales', body.start_date, body.end_date, body.source, body.company_id, body.level, body.ads_ids, body.name, body.utm_campaign, body.attribution], () => getCatalogSales(body), {
        ...options,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    })
}

export function useGetProductSales(body: getSalesDTO, options = {}){
    return useQuery(['getProductSales', body.start_date, body.end_date, body.source, body.company_id, body.level, body.ads_ids, body.name, body.utm_campaign, body.attribution], () => getProductSales(body), {
        ...options,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    })
}

export function useGetSalesByClient(clientInfo: any){
    return useQuery(['getSalesByClient', clientInfo?.customer_id, clientInfo?.start_date, clientInfo?.end_date], () => getSalesByClient(clientInfo), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    })
}

export function useConfigureUtm() {
    return useMutation(configureUtm);
}