import { reduceString, toBrazilianFormat } from '../../../../../utils';
import * as S from './style'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { format } from 'date-fns';
import useAuth from '../../../../../hooks/useAuth';
import useVisibility from '../../../../../hooks/useVisibilityChange';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';

export function LineOrdersModal({ openClientModal, order, date, totals, isTotalRow }: { openClientModal: any, order: any, date: string[], totals?: any, isTotalRow?: boolean }) {
    const { currencySymbol } = useCurrencyType();

    const { isVisible } = useVisibility();
    const urlLinkBoleto = 'https://storage.googleapis.com/static-images-source/billet-icon.png';
    const urlLinkCartao = 'https://storage.googleapis.com/static-images-source/mastercard-icon.png';
    const urlLinkPix = 'https://storage.googleapis.com/static-images-source/pix-icon.png';

    const { currentCompany } = useAuth();

    let url = '';
    let name = '';

    if (!isTotalRow) {
        switch (order?.payment_method) {
            case 0:
                url = '';
                name = 'Desconhecido';
                break;
            case 1:
                url = urlLinkCartao;
                name = 'Cartão de crédito';
                break;
            case 3:
                url = urlLinkBoleto;
                name = 'Boleto';
                break;
            case 4:
                url = urlLinkPix;
                name = 'Pix';
                break;
        }
    }

    if (isTotalRow) {
        url = '';
        name = '';
    }

    const ClientName = isTotalRow ? '-' : isVisible ? order?.first_name : '* * * * * * *';
    const DateOrder = isTotalRow ? '-' : format(order?.date, 'dd/MM/yyyy HH:mm');
    const OrderId = isTotalRow ? '-' : '#' + order?.order_name;
    const ProductPrice = isTotalRow ? '-' : toBrazilianFormat(order?.product_price);
    const Tax = isTotalRow ? '-' : toBrazilianFormat(order?.checkout_fee + order?.gateway_fee);
    const Gateway =  isTotalRow ? '-' :toBrazilianFormat(order?.gateway_fee);
    const Checkout = isTotalRow ? '-' : toBrazilianFormat(order?.checkout_fee);
    const ProductCost = isTotalRow ? '-' : toBrazilianFormat(order?.product_cost);

    return (
        <S.Container isTotalRow={isTotalRow}>
            <S.MainColumn style={isTotalRow ? { position: 'sticky', bottom: '0', fontWeight: '550', fontSize: '0.8rem',  color: 'black' } : {}}>{isTotalRow ? 'Total' : OrderId}</S.MainColumn>
            <S.DefaultColumn>
            {order?.status_name === 'approved' && (
                    <S.Approved>
                        <CheckCircleOutlineIcon sx={{ width: '14px', height: '14px' }} /> Aprovado
                    </S.Approved>
                )}
                {order?.status_name === 'pending' && (
                    <S.Pending>
                        <PendingOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Pendente
                    </S.Pending>
                )}
                {order?.status_name === 'cancelled' && (
                    <S.Cancelled>
                        <CancelOutlinedIcon sx={{ width: '14px', height: '14px' }} />
                        Cancelado
                    </S.Cancelled>
                )}
            </S.DefaultColumn>
            <S.DefaultColumn>{isTotalRow ? '-' : DateOrder}</S.DefaultColumn>
            <S.DefaultColumn>{isTotalRow ? '-' : reduceString(ClientName, 16)}
                {!isTotalRow && (
                    <S.MetricsButton onClick={() => openClientModal({
                        customer_id: order?.customer_id,
                        start_date: date[0],
                        end_date: date[1],
                        company_id: currentCompany,
                    })}>
                        <FrameInspectIcon />
                    </S.MetricsButton>
                )}
            </S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}} >{isTotalRow ? '-' : <><img src={url} /> {name}</>}</S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}}>{currencySymbol} {isTotalRow ? toBrazilianFormat(totals.totalValue) : ProductPrice}</S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}}>{currencySymbol} {isTotalRow ? toBrazilianFormat(totals.totalProductCost) : ProductCost}</S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}}>{currencySymbol} {isTotalRow ? toBrazilianFormat(totals.totalTax) : Tax}</S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}}>{currencySymbol} {isTotalRow ? toBrazilianFormat(totals.totalGateway) : Gateway}</S.DefaultColumn>
            <S.DefaultColumn style={isTotalRow ? {fontWeight: '550', fontSize: '0.9rem'} : {}}>{currencySymbol} {isTotalRow ? toBrazilianFormat(totals.totalCheckout) : Checkout}</S.DefaultColumn>
        </S.Container>
    );
}


function FrameInspectIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M450-420q38 0 64-26t26-64q0-38-26-64t-64-26q-38 0-64 26t-26 64q0 38 26 64t64 26Zm193 160L538-365q-20 13-42.5 19t-45.5 6q-71 0-120.5-49.5T280-510q0-71 49.5-120.5T450-680q71 0 120.5 49.5T620-510q0 23-6.5 45.5T594-422l106 106-57 56ZM200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80Z"/>
        </svg>
    )
}