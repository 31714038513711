import styled from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';

export const Container = styled.div`
    display: flex;
`;

export const LogoutIconStyled = styled(LogoutIcon)`
    width: 0.7em !important;
`;

export const LogoutButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    margin: 1rem;
    padding: 0rem 0.5rem;
    color: var(--white);
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 250ms;
    svg {
        margin-right: 0.5rem;
    }
    &:hover {
        border-radius: 50rem;
        transition: all 250ms;
        transform: translateX(0.3rem);
        background-color: var(--white);
        color: var(--red-500);
    }
`;


export const LeftContainer = styled.div`
    background-image: url(https://storage.googleapis.com/static-images-source/loading%20bg.png);
    background-size: cover;
    height: calc(100vh - 2rem - 12px);
    border: 6px solid white;
    display: flex;
    border-radius: 13px;
    width: 37.3rem;
    padding: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    background-position: center;

    @media (max-width: 1280px) {
        width: 25rem;
    }
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (max-width: 1280px) {
        transform: scale(0.7) !important;
        margin-top: -3rem;
    }

 
`;

export const Image = styled.div`
    margin-bottom: 1rem;
    img {
        width: 9.375rem;
        height: 9.375rem;
    }
`;
export const Title = styled.div`
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.65rem;
    color: var(--black);
`;

export const Subtitle = styled.div`
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #5d7375;
    span {
        font-weight: 550;
    }
`;

export const ButtonSection = styled.div`
    display: flex;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    gap: 1rem;
`;

export const SkeletonSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 55rem;
    @media (max-width: 1575px) {
        max-width: 45rem;
    }
    @media (max-width: 1290px) {
        width: 35rem;
    }
`;

export const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 55rem;
    @media (max-width: 1575px) {
        max-width: 45rem;
    }
    @media (max-width: 1290px) {
        width: 35rem;
    }
`;

export const Baseboard = styled.div`
    margin-top: 2rem;
`;

export const BaseboardText = styled.div`
    font-size: 0.75rem;
    color: #5d7375;
    line-height: 1.125rem;
    text-align: center;
    a {
        text-decoration: underline;
        color: #43a1dc;
        cursor: pointer;
    }
`;
