// style.ts
import styled, { css, keyframes } from 'styled-components';
import { AlertType } from '../../@types';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import Check from '@mui/icons-material/Check';
import Error from '@mui/icons-material/Error';


interface AlertContainerProps {
  alertType: AlertType ;
}

export const fadeIn = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(10px); /* Inicia um pouco abaixo da posição final */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move para a posição final */
  }
`;



const AlertColors = {
  success: 'var(--success)', // Verde para sucesso
  error: 'var(--fail)', // Vermelho para erro
  loading: 'var(--black)', // Preto para carregamento
  neutral: 'var(--black)', // Cinza para neutro
};

export const AlertContainer = styled.div<AlertContainerProps>`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  border-radius: 8px;
  gap: 10px;
  color: white;
  padding: 12px 20px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-width: 300px;
  animation: ${fadeIn} 0.5s ease-out;

  
  ${(props) =>
    css`
      background-color: ${AlertColors[props.alertType]};
    `}
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 26px;
  transition: all 250ms;

  &:hover {
    transform: translateY(1px);
    transition: all 250ms;
  }
`;

export const Message = styled.span`
  // margin-right: 76px;
  font-weight: 500;
  font-size: 15px;
`;


const rotateAntiClockwise = keyframes`
  from {
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    transform: rotate(360deg) scaleX(-1);
  }
`;


export const LoadingIcon = styled(CachedRoundedIcon)`
  font-size: 20px !Important;
  animation: ${rotateAntiClockwise} 1s linear infinite;
`;

export const SuccessIcon = styled(Check)`
font-size: 20px !Important;
  color: white;
`;

export const ErrorIcon = styled(Error)`
font-size: 20px !Important;
color: white;
`;