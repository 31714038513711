import { useEffect, useState } from 'react';

const isMobileDevice = () => {
  const hasTouchScreen = navigator.maxTouchPoints > 0;
  const narrowViewport = window.innerWidth <= 1400;
  const userAgent = navigator.userAgent;
  const isMobileUA = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  
  return (hasTouchScreen && narrowViewport) && isMobileUA;
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => 
    typeof window !== 'undefined' && (window.innerWidth <= 1400 && isMobileDevice())
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1400 && isMobileDevice());
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return isMobile;
}

export default useIsMobile;
