import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: calc(100vh - 3.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h1`
    font-weight: 600;
    margin-bottom: 1.25rem;
    margin-top: -5rem;
`;

export const Subtitle = styled.p`
    font-weight: 300;
    margin-bottom: 1.875rem;
    font-size: 1rem;
`;
