import { useState, useRef, useEffect } from 'react';
import { Banner } from '../../components/Banner';
import { Search } from '../../components/Search';
import useAuth from '../../hooks/useAuth';
import { useOrders } from '../../services/hooks/ordersHooks';
import { TableOrders } from './TableOrders';
import * as S from './style';
import Calendar from '../../components/Calendar';
import { format } from 'date-fns';
import { trackEvent } from '../../services/segment/sendEvent'

import Modal from '../../components/Modal';
import Check from '@mui/icons-material/Check';
import FmdBad from '@mui/icons-material/FmdBad';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { updateOrdersCost } from '../../services/hooks/ordersHooks';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useRefreshContext } from '../../contexts/RefeshContext';

export function Orders() {
    const { user, currentCompany } = useAuth();
    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);
    const { isLoading, data, refetch: refetchOrders } = useOrders(date[0], date[1], currentCompany);

    const [filter, setFilter] = useState('');
    const prevFilter = useRef('');

    const orders = data?.data?.data;

    //console.log('orders', orders)
    const [ordersFiltered, setOrdersFiltered] = useState(orders);

    const { refreshCounter } = useRefreshContext();

    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetchOrders();
        }
      }, [refreshCounter]);

    useEffect(() => {
        trackEvent('pageview', {
            userId: user.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'orders'
        });
      }, [])
    

    useEffect(() => {
        setOrdersFiltered(orders);
    }, [orders]);

    const [showPopup, setShowPopup] = useState(false);
    const [canSave, setCanSave] = useState<string[]>([]);
    const { mutate: updateOrderMutate } = updateOrdersCost();
    const [modifiedOrders, setModifiedOrders] = useState<string[]>([]);

    const [showFilter, setShowFilter] = useState(false);

    const togglePopup = () => { 
        setShowPopup(!showPopup);
        
    };

    function changeOrders(order_id: string){
        if (!modifiedOrders.includes(order_id)) {
            setModifiedOrders([...modifiedOrders, order_id]);
            //console.log('Pedido adicionado:', order_id);
        }
    }

    const handleSaveChanges = async () => {
        try {
            const modifiedOrdersArray = orders.filter((order: any) => modifiedOrders.includes(order.order_name));
            const canSaveArray = modifiedOrdersArray.map((order: any) => ({
                order_id: order.order_id,
                total_price: order.product_price, 
                order_product_cost: order.product_cost
            }));            

            updateOrderMutate({
                company_id: currentCompany, orders:canSaveArray
            });

            // limpa o modified orders
            setModifiedOrders([]);
        } catch (error) {
            console.error('Erro ao atualizar pedidos:', error);
        }

    };

    const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
    const [selectedMpagFilter, setSelectedMpagFilter] = useState('');
    const [selectedStatusFilter2, setSelectedStatusFilter2] = useState('');
    const [selectedMpagFilter2, setSelectedMpagFilter2] = useState('');

    const urlLinkBoleto = 'https://storage.googleapis.com/static-images-source/billet-icon.png';
    const urlLinkCartao = 'https://storage.googleapis.com/static-images-source/mastercard-icon.png';
    const urlLinkPix = 'https://storage.googleapis.com/static-images-source/pix-icon.png';

    let url = '';
    let name = '';

    switch (parseInt(selectedMpagFilter)) {
        case 0:
            url = '';
            name = 'Desconhecido';
            break;
        case 1:
            url = urlLinkCartao;
            name = 'Cartão de crédito';
            break;
        case 3:
            url = urlLinkBoleto;
            name = 'Boleto';
            break;
        case 4:
            url = urlLinkPix;
            name = 'Pix';
            break;
    }


    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatusFilter(event.target.value);
    };
    
    const handleMpagFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMpagFilter(event.target.value);
    };

    const [triggerApplyFilter, setTriggerApplyFilter] = useState(false);
    
    useEffect(() => {
        if (triggerApplyFilter || filter !== prevFilter.current) {
            handleApplyFilter();
            setTriggerApplyFilter(false);
        }
        prevFilter.current = filter;
    }, [triggerApplyFilter, filter]);

    const handleApplyFilter = () => {
        const statusFilter = selectedStatusFilter;
        const mpagFilter = parseInt(selectedMpagFilter);
    
        let filteredOrders = [...orders];
    
        if (filter !== '') {
            filteredOrders = orders.filter((order: any) => {
                // Remove o primeiro caractere se for "#" e converte para lowercase para comparação insensível a maiúsculas e minúsculas
                const orderName = order.order_name.startsWith('#') ? order.order_name.substring(1).toLowerCase() : order.order_name.toLowerCase();
                // Verifica se o nome do pedido inclui o texto do filtro (também em lowercase)
                return orderName.includes(filter.toLowerCase());
            });
        }
    
        if (statusFilter !== "" && !isNaN(mpagFilter)) {
            filteredOrders = filteredOrders.filter((order: any) => order.status_name === statusFilter && order.payment_method === mpagFilter);
        } else if (statusFilter !== "") {
            filteredOrders = filteredOrders.filter((order: any) => order.status_name === statusFilter);
        } else if (!isNaN(mpagFilter)) {
            filteredOrders = filteredOrders.filter((order: any) => order.payment_method === mpagFilter);
        }
    
        // Atualiza o estado com os pedidos filtrados
        setOrdersFiltered(filteredOrders);
        setSelectedMpagFilter2(selectedMpagFilter);
        setSelectedStatusFilter2(selectedStatusFilter);
    }
    

    const filterContainerRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterContainerRef.current && !filterContainerRef.current.contains(event.target as Node) && filterRef.current && !filterRef.current.contains(event.target as Node) ) {
                setShowFilter(false);
            }
        };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [filterContainerRef, filterRef]);

    
    
    return (
        <S.Container >
            {/* <Banner title="Pedidos" subtitle={['Monitoramento em tempo real dos seus pedidos:', 'Uma visão detalhada sobre seus pedidos.']} hasButton={false} /> */}
            <S.Title><h2>Pedidos</h2>
                <p>Monitoramento em tempo real dos seus pedidos.</p>
            </S.Title>
            <S.Section>
                <S.SectionFS>
                    <Search width="18.75rem" placeholder="Pesquisar número do pedido" value={filter} setValue={setFilter} />
                   <S.FilterOutContainer>
                    <S.Filter onClick={() => setShowFilter(prev => !prev)} ref={filterRef}>
                        <FilterAltOutlinedIcon sx={{ width: '24px', height: '24px'}}/> Filtros
                    </S.Filter>
                    <S.Filters>
                        <S.Status>
                            {selectedStatusFilter2 === 'approved' && (
                                <S.Approved>
                                    <S.Image><CheckCircleOutlineIcon sx={{ width: '14px', height: '14px' }} /></S.Image>
                                    Aprovado
                                    <S.CloseButton onClick = {() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '14px', height: '14px' }}/></S.CloseButton>
                                </S.Approved>
                            )}
                            {selectedStatusFilter2 === 'pending' && (
                                <S.Pending>
                                    <S.Image><PendingOutlinedIcon sx={{ width: '14px', height: '14px' }} /></S.Image>
                                    Pendente
                                    <S.CloseButton onClick = {() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '14px', height: '14px' }}/></S.CloseButton>
                                </S.Pending>
                            )}
                            {selectedStatusFilter2 === 'cancelled' && (
                                <S.Cancelled>
                                    <S.Image><CancelOutlinedIcon sx={{ width: '14px', height: '14px' }} /></S.Image>
                                    Cancelado
                                    <S.CloseButton onClick = {() => (setSelectedStatusFilter(""), setTriggerApplyFilter(true))} ><CloseIcon sx={{ width: '14px', height: '14px' }}/></S.CloseButton>
                                </S.Cancelled>
                            )}
                        </S.Status>
                        {selectedMpagFilter2 !== "" && (
                            <S.Payment>
                                <img src={url} /> {name}
                                <S.CloseButton onClick={() => { setSelectedMpagFilter(""); setTriggerApplyFilter(true); }} ><CloseIcon sx={{ width: '14px', height: '14px' }}/></S.CloseButton>
                            </S.Payment> 
                        )}
                    </S.Filters>
                    {showFilter && (
                    <S.FilterContentFixed>
                        <S.FilterContainer showFilter={showFilter} ref={filterContainerRef}>
                            <S.HeaderFilter>
                                <FilterAltOutlinedIcon sx={{ width: '16px', height: '16px' }}/> Filtros
                                <S.CloseButton onClick={() => setShowFilter(false)}><CloseIcon sx={{ width: '18px', height: '18px' }}/></S.CloseButton>
                            </S.HeaderFilter>
                            <S.Lines></S.Lines>
                            <S.FilterSection>
                                <S.FlexTitleReset>
                                    Status
                                    <S.ResetButton onClick = {() => setSelectedStatusFilter('')}>Resetar</S.ResetButton>
                                </S.FlexTitleReset>
                                    <S.Select id="statusFilter" value={selectedStatusFilter} onChange={handleStatusFilterChange}>
                                        <option value="">Todos os Status</option>
                                        <option value="approved">Aprovado</option>
                                        <option value="cancelled">Cancelado</option>
                                        <option value="pending">Pendente</option>
                                    </S.Select>
                            </S.FilterSection>
                            <S.Lines></S.Lines>
                            <S.FilterSection>
                                <S.FlexTitleReset>
                                    Método de Pagamento
                                    <S.ResetButton onClick = {() => setSelectedMpagFilter('')}>Resetar</S.ResetButton>
                                </S.FlexTitleReset>
                                <S.Select id="mpagFilter" value={selectedMpagFilter} onChange={handleMpagFilterChange}>
                                    <option value="">Todos os Métodos</option>
                                    <option value="1">Cartão de crédito</option>
                                    <option value="3">Boleto</option>
                                    <option value="4">Pix</option>
                                </S.Select>
                            </S.FilterSection>
                            <S.Lines></S.Lines>
                            <S.ApplyButton onClick={() => (handleApplyFilter(), setShowFilter(false))}>Aplicar filtro</S.ApplyButton>
                        </S.FilterContainer>
                    </S.FilterContentFixed>
                    )}
                    </S.FilterOutContainer>
                </S.SectionFS>
                <span>
                    <Calendar date={date} setDate={setDate} dashboard={false}/>
                </span>
            </S.Section>
            <S.saveEditOrder onClick={canSave.length === 0 ? undefined : togglePopup} disabled={!(canSave.length === 0 ? false : true)}><Check sx={{ width: '14px', height: '14px' }}/>Salvar Edições</S.saveEditOrder>
            {showPopup && (
                <Modal onClose={() => setShowPopup(false)}>
                    <S.ContainerPopup>
                        <S.IconPopup><FmdBad sx={{ width: '35px', height: '35px' }}/></S.IconPopup>
                        <S.TitlePopup>Você tem certeza?</S.TitlePopup>
                        <S.SubtitlePopup>Se preferir, você ainda pode continuar editando outros pedidos antes de salvar.</S.SubtitlePopup>
                        <S.Line/>
                        <S.ButtonsContainer>
                            <S.KeepEdit onClick={() => setShowPopup(false)}><KeyboardBackspaceIcon sx={{ width: '18px', height: '18px' }}/>  Continuar editando</S.KeepEdit>
                            <S.Save onClick={() => {setCanSave([]); setShowPopup(false); handleSaveChanges()}}><Check sx={{ width: '18px', height: '18px' }}/>  Salvar</S.Save>
                        </S.ButtonsContainer>
                    </S.ContainerPopup>
                </Modal>
            )}

            <S.Table>
                <TableOrders orders={ordersFiltered} isLoading={isLoading} canSave={canSave} setCanSave={setCanSave} changeOrders={changeOrders}/>
            </S.Table>

            {!isLoading && ordersFiltered?.length === 0 && (
                <S.ContainerImage>
                    <img src="https://solomon.com.br/wp-content/uploads/2023/10/vazio.psd.png" alt="Nenhum produto encontrado" />
                    <S.Title2>Oops!</S.Title2>
                    <S.Subtitle>Sem dados no período selecionado.</S.Subtitle>
                    <S.Subtitle>Por favor, escolha um novo período.</S.Subtitle>
                </S.ContainerImage>
            )}

            {/* {isLoadingUpdateOrders && (
                <S.UpdatePopupContainer>
                    <S.RotatingIcon sx={{ width: '16px', height: '16px' }}/> Os dados dos pedidos estão sendo atualizados, aguarde um momento.
                </S.UpdatePopupContainer>
            )} */}
        </S.Container>
    );
}
