import styled from 'styled-components'

export const Container = styled.div`
width: 57.375rem;
padding: 1rem;
height: auto;
min-height: 10rem;
max-height: 45rem;
background-color: #fff;
border-radius: 8px;


::-webkit-scrollbar {
    height: 6px; /* Altura da barra de rolagem */
}

&r::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do botão de rolagem */
    border-radius: 6px; /* Raio do botão de rolagem */
}
`

export const Icon = styled.div`
cursor: pointer;
width: fit-content;
margin-bottom: 1rem;

`

export const Title = styled.div`
font-weight: 600;
line-height: 1.5rem;
color: #1e1e1e;
span{
    color: var(--green)
}
`

export const SearchSection = styled.div`
margin-top: -1rem;
margin-bottom: 1rem;
display: flex;
justify-content: space-between;
`

export const Section = styled.div`
display: flex;
gap: 2rem;
margin-bottom: 2rem;
`

export const FirstSectionItem = styled.div`

`

export const FirstSectionHeader = styled.div`
display: flex;
`

export const MetricsButton = styled.div`
position: absolute;
left: 7.5rem;
top: 0;
  width: 1.5875rem;
    height: 1.5875rem;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    &:hover {
        outline: none;
        border-color: rgba(76, 234, 134, 0.4);
        background-color: #fff;
        box-shadow: 0 0 0 2px rgba(76, 234, 152, 0.1);
    }
    svg {
        line-height: 1.6875rem;
        width: 1.1rem;
        height: 1.1rem;
        fill: var(--green);
    }
`

export const FirstSectionValue = styled.div`
font-size: 1rem;
line-height: 1.35rem;
font-weight: 550;
margin-top: 0.3rem;
margin-left: 1.5rem;
position: relative;
color: #000;
`
export const ContainerIcon = styled.div`
margin: 1rem;
`

export const FirstSectionTitle = styled.div`
font-size: 0.75rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
`

export const SquareIcon = styled.div`
width: 1rem;
height: 1rem;
border-radius: 0.25rem;
background-color: #ededed;
display: flex;
align-items: center;
justify-content: center;
margin-right: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`


export const FirstSection = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
`

export const VLine = styled.div`
    height: 4rem;
    width: 1px;
    background-color: rgb(0,0,0,0.1);
    margin: auto 0;
`

export const SecondSection = styled.div`

`

export const SecondSectionFirstTitle = styled.div`
font-size: 0.8rem;
line-height: 1.2rem;
font-weight: 450;
color: #000;
`

export const SecondSectionTitle = styled.div`
font-size: 0.8rem;
line-height: 1.2rem;
font-weight: 450;
color: #000;
margin-bottom: 0.7rem;
`

export const SecondSectionSubtitle = styled.div`
font-size: 0.75rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
`

export const Expand = styled.div`
border-radius: 50%;
width: 2.5rem;
height: 2.5rem;
border: 1px solid rgb(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
&:hover{
    outline: none;
    border-color: rgba(76, 234, 134, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
}
`


export const SecondSectionItem = styled.div`
display: flex;
margin-bottom: 0.5rem;
`

export const SecondSectionItemText = styled.div`
font-size: 0.75rem;
line-height: 1.125rem;
font-weight: 500;
color: #989c97;
span{
    color: var(--green);
}
`

export const Subtitle = styled.div`
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
font-size: 0.75rem;
`

export const Line = styled.div`
height: 1px;
width: 59.375rem;
margin-left: -1rem;
margin-top: 0.5rem;
background-color: rgb(0,0,0,0.1);
margin-bottom: 2rem;
`

export const Header = styled.thead`
display: flex;
background-color: #f7f7f7;
width: 89.4375rem;
height: 3.3rem;

position: sticky;
top: 0;
z-index: 10;
`
export const InnerContainer = styled.div`
width: 57.375rem;
overflow-x: auto;
height: auto;
position: relative;
`

export const MainColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 16.6875rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 1rem;
position: sticky;
left: 0;
top: 0;
z-index: 10;
background-color: inherit;
border-right: 1px solid #eee;
`

export const DefaultColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 9.25rem;
display: flex;
align-items: center;
justify-content: flex-start;
margin-left: 1rem;
`
export const Lines = styled.div`
max-height: 20rem;
position: relative;
    > div:nth-child(odd) {
        background-color: var(--white);
    }

    > div:nth-child(even) {
        background-color: var(--gray-112);
    }
`
