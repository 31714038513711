import * as S from './style';
import { SwapCalls } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useCreateIntegrationFacebookAdsProfiles, useCreateIntegrationGoogleAdsProfiles, useCreateIntegrationTiktokAdsProfiles, useGetAccountsFacebookAds, useGetAccountsGoogleAds, useGetAccountsTiktokAds } from '../../services/hooks/integrationHooks';
import { ButtonApp } from '../../components/ButtonApp';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Accounts } from './Accounts';
import { jwtDecode } from 'jwt-decode';
import { jwtDecodeDTO } from '../../@types';
import { Congratulation } from '../Congratulation';
import { storageGroupIdSave } from '../../storage/storageGetData';
import { useGetToken } from '../../services/hooks/authHooks';
import { useGetUser } from '../../services/hooks/userHooks';
import { storageOnboardingCurrentCompanyGet } from '../../storage/storageOnboarding';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as DTO from '../../@types'
import useAuth from '../../hooks/useAuth';
import { Search } from '../../components/Search';
import { TablePlaceholder } from '../../components/TablePlaceholder';
import useAlert from '../../hooks/useAlert';


export function IntegrateAccount() {
    const [hasSomeAccountChanged, setHasSomeAccountChanged] = useState(false);
    const [activeGetAccount, setActiveGetAccount] = useState(false)
    const [activeGetAccountData, setActiveGetAccountData] = useState(false);
    const [inProcess , setInProcess] = useState(true);
    const [filter, setFilter] = useState('');

    const { typeAccount } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const cantAccess = !['facebook_ads', 'tiktok_ads', 'google_ads'].includes(typeAccount ?? '');

    if (cantAccess) {
        navigate('/login');
    }

    const state = new URLSearchParams(location.search).get('state') ?? '';
    const code = new URLSearchParams(location.search).get('code');
    const context = new URLSearchParams(location.search).get('context');
    let decoded: jwtDecodeDTO = {} as jwtDecodeDTO;
    if (state !== null && code !== null) {
        decoded = jwtDecode(state);
    }
    
    
    const { mutate: getToken } = useGetToken()

    const { data: dataFacebook, isLoading: isLoadingFacebook, mutate: handleIntegrateFacebook } = useCreateIntegrationFacebookAdsProfiles();
    const { data: dataTiktok, isLoading: isLoadingTiktok, mutate: handleIntegrateTiktok } = useCreateIntegrationTiktokAdsProfiles();
    const { data: dataGoogle, isLoading: isLoadingGoogle, mutate: handleIntegrateGoogle } = useCreateIntegrationGoogleAdsProfiles();
    
    const { data: accountsFacebook, isLoading: isLoadingFacebookGetAccount } = useGetAccountsFacebookAds(decoded?.company_id ?? storageOnboardingCurrentCompanyGet(), typeAccount ?? '', activeGetAccount, setActiveGetAccount);
    const { data: accountsTiktok, isLoading: isLoadingTiktokGetAccount} = useGetAccountsTiktokAds(decoded?.company_id ?? storageOnboardingCurrentCompanyGet(), typeAccount ?? '', activeGetAccount, setActiveGetAccount);
    const { data: accountsGoogle, isLoading: isLoadingGoogleGetAccount } = useGetAccountsGoogleAds(decoded?.company_id ?? storageOnboardingCurrentCompanyGet(), typeAccount ?? '', activeGetAccount, setActiveGetAccount);

    const hasLoading = isLoadingFacebook || isLoadingTiktok || isLoadingGoogle || isLoadingFacebookGetAccount || isLoadingTiktokGetAccount || isLoadingGoogleGetAccount || inProcess;

    const { data: userFromGetUser } = useGetUser(decoded.user_id);
    const { showAlert } = useAlert();

    let user = userFromGetUser;

    if (userFromGetUser === undefined || userFromGetUser === {} as DTO.userDTO) {
        const ctx = useAuth();
        user = ctx.user
    };
    
    useEffect(() => {

        if (state !== null && code !== null) {

            if(typeAccount === 'facebook_ads'){
                //console.log('token e user id', decoded.token, decoded.user_id)
                getToken({
                    session_token: decoded.token,
                    user_id: decoded.user_id
                }, {
                    onSuccess: (data) => {
                        //console.log('deu bao', data)
                        const body = {
                            auth_code: code ?? '',
                            company_id: decoded?.company_id !== 'undefined' ? decoded?.company_id : storageOnboardingCurrentCompanyGet(),
                            user: user,
                            'User-Id': decoded.user_id,
                            Authorization: data?.data?.access_token
                        };
                        
                            handleIntegrateFacebook(body, {
                                onSettled: () => {
                                    setInProcess(false);
                                    //console.log('deu +-')
                                },
                                onError: () => {
                                    //console.log('deu ruim')
                                    setActiveGetAccount(true);
                                    setActiveGetAccountData(true)
                                }
                            });
                    },
                    onError : () => {
                        setInProcess(false);
                    }
                })                
            }

            else if(typeAccount === 'tiktok_ads'){
                const body = {
                    auth_code: code ?? '',
                    company_id: (decoded?.company_id && decoded?.company_id !== 'undefined' && decoded?.company_id !== '') ? decoded?.company_id : storageOnboardingCurrentCompanyGet(),
                    user: user
                };

                handleIntegrateTiktok(body, {
                    onError: () => {
                        setActiveGetAccount(true);
                        setActiveGetAccountData(true)
                    },
                    onSettled: () => {
                        setInProcess(false);
                    }
                });
            }

            else if (typeAccount === 'google_ads' ){
                //console.log('era google', storageOnboardingCurrentCompanyGet())
                //console.log('decoded', decoded)

                const body = {
                    auth_code: code ?? '',
                    company_id: decoded?.company_id !== 'undefined' ? decoded?.company_id : storageOnboardingCurrentCompanyGet(),
                    user: user
                };

                //console.log('body1', body);

                handleIntegrateGoogle(body, {
                    onError: (error: any) => {
                        if (error.message === 'Profile already connected') {
                            showAlert("Este perfil do Google já está conectado.", 'error', 5000)
                        }

                        if (error.message === 'Failed to retrieve ads accounts') {
                            showAlert("Não foi possível obter as contas de anúncio no momento. Entre em contato com o suporte ou tente clicar para sincronizar contas mais tarde.", 'error', 5000)
                        }
                        setActiveGetAccount(true);
                        setActiveGetAccountData(true)
                    },
                    onSettled: () => {
                        setInProcess(false);
                    }                    
                });
            }
            }else if(state === null || code === null){
                if(storageOnboardingCurrentCompanyGet() !== 'undefined' && storageOnboardingCurrentCompanyGet()?.length > 0){
                    setActiveGetAccount(true)
                    setActiveGetAccountData(true)
                    setInProcess(false);
                }
            }
            
            
        
    }, [storageOnboardingCurrentCompanyGet()]);

    const newAccounts: any[] = (activeGetAccountData ? 
    (
        typeAccount === 'facebook_ads' ? accountsFacebook?.data?.data :
        typeAccount === 'tiktok_ads' ? accountsTiktok?.data?.data :
        typeAccount === 'google_ads' ? accountsGoogle?.data?.data : []
        
    ) : (

        typeAccount === 'facebook_ads' ? dataFacebook?.data?.data?.profiles :
        typeAccount === 'tiktok_ads' ? dataTiktok?.data?.data?.profiles :
        typeAccount === 'google_ads' ? dataGoogle?.data?.data?.profiles : []

    ));


    const accounts = newAccounts?.length > 0 ? newAccounts?.filter((account: any) => account?.profile_id !== 'default-profile') : newAccounts;

    if (state !== null && code !== null) {
        if(decoded.context === 'link'){

            storageGroupIdSave(code)

            return(
                <Congratulation />
            )
        }
    }

    const handleConnectButton = () => {
        if(typeAccount === 'google_ads'){
            window.opener.postMessage({
                type: 'GoogleSyncComplete',
            }, '*'); 
        }else if(typeAccount === 'tiktok_ads'){
            window.opener.postMessage({
                type: 'TiktokSyncComplete',
            }, '*');
        }
        window.close();
    }


    let filterLower;
    const filteredAccounts = accounts?.filter(information => 
        information?.accounts?.some(account => {
            const profileName = information?.assets ? information?.assets?.profile_name?.toLowerCase() : '';
            const profileId = information?.profile_id ? information?.profile_id?.toString() : '';
            const accountId = account?.account_id ? account?.account_id?.toString() : '';
            const accountName = account?.assets ? account?.assets?.account_name?.toLowerCase() : '';
            // const advertiserName = account?.assets ? account?.assets?.advertiser_name?.toLowerCase() : '';

            filterLower = filter.toLowerCase();
            return profileName?.includes(filterLower) || profileId?.includes(filterLower) || accountId?.includes(filterLower) || accountName?.includes(filterLower) ;
        })
    );

    return (
        <S.Container>
            <S.InnerContainer>
                <S.SectionImages>
                    <img src="https://storage.googleapis.com/static-images-source/Solomon-logo-icon.png" />
                    <SwapCalls sx={{ width: '24px', height: '24px', color: '#b9b9b9' }} />
                    {typeAccount === 'facebook_ads' && <img src="https://storage.googleapis.com/static-images-source/facebook-icon.webp" />}
                    {typeAccount === 'tiktok_ads' && <img src="https://storage.googleapis.com/static-images-source/tiktok-icon.png" />}
                    {typeAccount === 'google_ads' && <img src="https://storage.googleapis.com/static-images-source/google-logo-icon.png" />}
                </S.SectionImages>

                <S.Title typeAccount={typeAccount ?? ''}>
                    Integração <span>{typeAccount === 'facebook_ads' ? 'Facebook Ads' : typeAccount === 'tiktok_ads' ? 'Tiktok Ads' : 'Google Ads'}</span>
                </S.Title>
                <S.SubTitle>
                    Pedimos para que conecte sua conta do {typeAccount === 'facebook_ads' ? 'Facebook Ads' : typeAccount === 'tiktok_ads' ? 'Tiktok Ads' : 'Google Ads'} em que você utiliza para gestão
                    de anúncios.
                </S.SubTitle>

                <div style={{marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem'}}>
                        <Search placeholder="Pesquisar contas de anúncios." width="14rem" height='2.5rem' value={filter} setValue={setFilter} />
                    </div>
                {hasLoading && <S.LoadingSection />}
                    {!hasLoading && (
                        <S.AccountsSection>
                            {filteredAccounts && filteredAccounts.length > 0 ? filteredAccounts.map(information => (
                                <Accounts
                                    typeAccount={typeAccount}
                                    hasLoading={hasLoading}
                                    accounts={information?.accounts}
                                    information={information}
                                    setHasSomeAccountChanged={setHasSomeAccountChanged}
                                    context={context ?? decoded?.context}
                                    filterLower={filterLower}
                                />
                            )) : 
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '10rem', margin: '2rem 0'}}>
                                <TablePlaceholder size='small' subtitleText='Nenhuma conta de anúncios encontrada.'/>
                            </div>
                            }
                        </S.AccountsSection>
                    )}
                {!hasLoading && accounts?.length === 0 && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Infelizmente não há contas do {typeAccount === 'facebook_ads' ? 'Facebook Ads' : typeAccount === 'tiktok_ads' ? 'Tiktok Ads' : 'Google Ads'} disponíveis para conectar.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}

            <S.FinalSection>
                    {!hasLoading && !hasSomeAccountChanged && (
                        <ButtonApp
                            border=''
                            width="8.3rem"
                            height="2.25rem"
                            color="#fff"
                            background="#989C96"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={() => {}}
                            text="Conectar conta"
                            disabled={true}
                        />
                    )}

                    {!hasLoading && hasSomeAccountChanged && (
                        <ButtonApp
                        border=''
                            width="8.3rem"
                            height="2.25rem"
                            color="#fff"
                            background="#19c380"
                            icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={() => {handleConnectButton()}}                    
                            text="Conectar conta"
                        />
                    )}
                </S.FinalSection>
            </S.InnerContainer>
        </S.Container>
    );
}
