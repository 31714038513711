import styled from "styled-components";

export const Container = styled.div`
width: 100%;
min-height: 100vh;
height: auto;
background-color: #F7F7F7;
display: flex;
align-items: center;
justify-content: center;
`

export const InnerContainer = styled.div`
width: 36.25rem;
height: auto;
background-color: #fff;
border-radius: 8px;
padding: 1rem;
`

export const IconContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
svg{
    width: 3.25rem;
    height: 3.25rem;
    fill: #f82b2b;
}
`

export const Title = styled.div`
text-align: center;
font-size: 1.375rem;
font-weight: 500;
margin-top: 0.5rem;
line-height: 1.65rem;
color: #1e1e1e;
`

export const Subtitle = styled.div`
font-size: 0.875rem;
line-height: 1.3125rem;
color: #989c97;
padding: 0 4rem;
text-align: center;
`

export const Image = styled.div`
display: flex;
justify-content: center;
margin-top: 0.5rem;
img{
    width: 25rem;
    height: auto;
}

`

export const Button = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: min-content;
margin: -1rem auto 0;
transition: 0.3s;
&:hover{
    transform: translateY(3px);
}
`