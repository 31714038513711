// GraphModal.tsx
import React from 'react';
import Modal from '../../../../../components/Modal/index.tsx';
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../../../hooks/useAuth.tsx';

import { useUpdateProductTag } from '../../../../../services/hooks/productsHooks.ts';
import useAlert from '../../../../../hooks/useAlert';

interface MarketingModalProps {
    onClose: () => void;
    product_id: string;
    product_name: string;
    setShowTag: (value: boolean) => void;
}

const TagModal : React.FC<MarketingModalProps> = ({ onClose, setShowTag, product_id, product_name }) => {

    const { mutate: updateProductTag } = useUpdateProductTag();
    const { showAlert } = useAlert();

    const { currentCompany } = useAuth();
    const [costOperation, setCostOperation] = useState({
        tag: '',
    });

    function handleSaveTag() {
        try {
            if (costOperation.tag !== '') {
                updateProductTag({
                    company_id: currentCompany,
                    product_id: product_id,
                    product_name: product_name,
                    tag: costOperation.tag,
                    target: 'ads',
                });
                //console.log('Custo atualizado com sucesso!');
                showAlert('TAG adicionada com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading', 30000, true);
            }
            setShowTag(false);
        } catch (error) {

            console.error('Erro ao atualizar custo:', error);
            setShowTag(false);
        }
    }

    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage src="https://i.ibb.co/kmpGNDH/link-249110.png" alt="Vincular Campanhas" />
                <S.MarketingTitle>
                    <S.MarketingHighlight>Vincular Anúncios ao Produto</S.MarketingHighlight> 
                    <S.MarketingParagraph>Para vincular seus anúncios de forma mais eficiente, basta configurar uma TAG com um nome em comum ao nome das campanhas que deseja sincronizar, exemplo:</S.MarketingParagraph>
                    <S.MarketingHeader2>Campanha: [ABO][LOOKALIKE][CAMISETA PRETA] → Tag: CAMISETA PRETA</S.MarketingHeader2>
                    <S.MarketingParagraph>Insira a tag abaixo e clique em "Salvar" para concluir o processo.</S.MarketingParagraph>
                </S.MarketingTitle>
                <br />
                <S.InputContainer>
                    <input type="text" value={costOperation.tag} onChange={(e) => setCostOperation({ ...costOperation, tag: e.target.value })} placeholder="Insira a tag aqui" />
                </S.InputContainer>
                <S.ButtonContainer onClick={handleSaveTag}>Salvar alterações</S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default TagModal;
