import * as S from './style';
import { Sidebar } from '../../pages/_layouts/AppLayout/Sidebar';
import { useGetCheckoutURL } from '../../services/hooks/integrationHooks';
import { useEffect, useState } from 'react';
import { trackEvent } from '../../services/segment/sendEvent';
import useAuth from '../../hooks/useAuth';

import {  storagePlaceholderCompanySave, storageShExternalIdGet, storageShopGet } from '../../storage/storageCompanyToOnboard';
import { useCreateBillingAccount, useCreateCompanyOnboarding } from '../../services/hooks/integrationHooks';
import { useNavigate } from 'react-router-dom';
import { StorageAuthTokenProps, userDTO } from '../../@types';


export function ShopifyPayment() {

    const [placeholderExist, setPlaceholderExist] = useState(false);
    const [createBillingAccount, setCreateBillingAccount] = useState(false);
    const [createCompany, setCreateCompany] = useState(false);
    const [planContext, setPlanContext] = useState('');
    const [redirect, setRedirect] = useState(false);
    const {user, currentCompany, setUserContext, setTokenContext, setCurrentCompanyContext, updateUser} = useAuth();
    const { mutate: handleCreateBillingAccount} = useCreateBillingAccount();
    const { mutate: createCompanyOnboarding } = useCreateCompanyOnboarding();

    const navigate = useNavigate();

    const [shopifyCompany, setShopifyCompany] = useState('');



    const [bodyCompany, setBodyCompany] = useState({ email: '',
        company: 'placeholder',
        mask_id: '',
        referred: false,
        user_id: '',
        shopify_id: 'teste-solomon' })

    let verifyingCompany = '';

    const {
        data,
        error,
        isLoading,
    } = useGetCheckoutURL(shopifyCompany, 'monthly', placeholderExist);

    const {
        data: dataRenew,
        error: errorRenew,
        isLoading: isLoadingRenew,
    } = useGetCheckoutURL(currentCompany, 'monthly', (planContext === 'renew'), 'renew');

    // verifica se o usuário ja tem uma company com nome placeholder
    useEffect(() => {
        // se o context=renew nos parametros da url ele nao cria uma nova company
        const urlParams = new URLSearchParams(window.location.search);
        const context = urlParams.get('context');
        setPlanContext(context ?? '');
        if (context && context === 'renew') {
            return;
        }
        const sh_external_id = storageShExternalIdGet();
        const shop = storageShopGet();

        if (shop){
            // verifica se o usuário ja tem uma company cujo account_id da shopify é igual ao shop
            //console.log('shop', shop)
            if (user?.companies) {
                //console.log('user.companies', user.companies)
                let returnFlag = false;
                Object.keys(user.companies).forEach((company_id) => {
                    const company = user.companies[company_id];
                    // itera sobre as accounts da shopify da company  e verifica se tem uma com o account_id igual ao shop
                    company.integrations.shopify.accounts.forEach((account) => {
                        //console.log('account.account_id', account.account_id, shop, account.account_id === shop);
                        if (account.account_id === shop) {
                            // se tiver, redireciona para a loja
                            setPlaceholderExist(true);
                            setShopifyCompany(company_id);
                            returnFlag = true;
                        }
                    });
                });
                if(returnFlag){
                    //console.log('returnFlag', returnFlag);
                    return;
                }else{
                    //console.log('returnFlag', returnFlag);
                }
            }
        }
        //console.log('lalalalala');

        if (sh_external_id) {
            //console.log('sh_external_id_company', sh_external_id);
            setBodyCompany({email: user?.credentials?.email,
                company: shop ?? 'placeholder',
                mask_id: user?.mask_id,
                referred: false,
                user_id: user?.user_id,
                shopify_id: sh_external_id});
        }else{
            return;
        }
       
        if (verifyingCompany == currentCompany || placeholderExist || (!currentCompany && user?.companies && Object.keys(user.companies).length > 0)) {
            return;
        }        
        verifyingCompany = currentCompany;   

        if(currentCompany === undefined || currentCompany === '' || currentCompany === "undefined"){
            setCreateBillingAccount(true);               
        }
        else{
            setCreateCompany(true);
        }
    }, [currentCompany]);
    
    useEffect(() => {
        if (createBillingAccount) {
            handleCreateBillingAccount(void 0, {
                onSuccess: () => {
                    createCompanyOnboarding(bodyCompany, {
                        onSuccess: (data) => {     
                            //console.log('data', data);                               
                            setShopifyCompany(data.data.data.company_id);
                            setPlaceholderExist(true);
                            // atualiza o user com a nova company
                            updateUser();
                        },
                    });
                }
            })
        }
    }, [createBillingAccount])

    useEffect(() => {
        if (createCompany) {
            //console.log('bodyCompany', bodyCompany);
            createCompanyOnboarding(bodyCompany, {
                onSuccess: (data) => {             
                    //console.log('data', data);             
                    setShopifyCompany(data.data.data.company_id);
                    setPlaceholderExist(true);
                    // atualiza o user com a nova company
                    updateUser();
                },
            });
        }
    }, [createCompany])
   

    useEffect(() => {
        if (redirect && !isLoading && !error  && data && (planContext !== 'renew')) {
            //console.log(data?.data?.data)
            window.location.href =  data?.data?.data
        }else if (redirect && !isLoadingRenew && !errorRenew  && dataRenew ) {
            //console.log(dataRenew?.data?.data)
            window.location.href =  dataRenew?.data?.data
        }           
        
    }, [redirect, data, dataRenew])

    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success")
        }).catch((err) => {
            //console.log(err)
        })

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        navigate('/login');
    }

    return(
        <S.Container>
            <Sidebar showSideBar={true} setShowSideBar={() => {}}  />         
            <S.InnerContainer>
                
                <S.Content>
                    <S.Header>
                        <span onClick={() => navigate('/lojas')}><StoreIcon />Minhas Lojas</span>
                        <span onClick={signOut}><LogoutIcon/> Sair</span>
                    </S.Header>
                    <S.LogoSolomon>
                        <img src="https://storage.googleapis.com/static-images-source/horizontal-logo.png" alt='Logo Solomon' />
                    </S.LogoSolomon>
                    <S.Image>
                        <img src='https://storage.googleapis.com/static-images-source/hourglass.png' />
                    </S.Image>
                    <S.Title>
                    Ative já seu <span>plano</span>!
                    </S.Title>
                    <S.Subtitle>
                    E desbloqueie todo o potencial da plataforma.
                    </S.Subtitle>
                    <S.Subtitle>
                    Clique no botão abaixo para <span>liberar seu acesso</span>!
                    </S.Subtitle>
                    <S.Button onClick={() => {setRedirect(true)}}>
                        <Icon />{redirect ?  'Redirecionando...' : 'Liberar acesso'}                        
                    </S.Button>
                    <S.ButtonText>
                        Falar com suporte
                    </S.ButtonText>
                </S.Content>
                
            </S.InnerContainer>
    </S.Container>
    )
}

function Icon(){
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70q66 0 121 33t87 87h432v240h-80v120H600v-120H488q-32 54-87 87t-121 33Zm0-80q66 0 106-40.5t48-79.5h246v120h80v-120h80v-80H434q-8-39-48-79.5T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q33 0 56.5-23.5T360-480q0-33-23.5-56.5T280-560q-33 0-56.5 23.5T200-480q0 33 23.5 56.5T280-400Zm0-80Z"/></svg>
    )
}

function StoreIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-720v-80h640v80H160Zm0 560v-240h-40v-80l40-200h640l40 200v80h-40v240h-80v-240H560v240H160Zm80-80h240v-160H240v160Z"/></svg>
    )
}

function LogoutIcon(){
    return(

<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"/></svg>
    )
}