import * as S from './style';
import Skeleton from '@mui/material/Skeleton';

export function LineTableOrdersLoading() {
    return (
        <S.Container>
            <S.OrderId>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.OrderId>
            <S.Status>
                <S.Approved>
                    <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
                </S.Approved>
            </S.Status>
            <S.Date>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Date>
            <S.Payment>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Payment>
            <S.Value>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Value>
            <S.Costs>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Costs>
            <S.Tax>
                <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '2rem' }} />
            </S.Tax>
        </S.Container>
    );
}
