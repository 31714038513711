import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
width: 40.375rem;
padding: 1rem 1rem 0rem 1rem;
height: auto;
min-height: 10rem;
max-height: 100vw;
background-color: #fff;
border-radius: 8px;
z-index: 999999;

::-webkit-scrollbar {
    height: 6px; /* Altura da barra de rolagem */
}
`

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px; // Ajuste conforme necessário
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


`;


export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
`;

export const ColumnItemArea = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    max-height: 30vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem 0rem 1rem;
    margin-left: -1rem;

`;

export const UtmTitleArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--green);
    gap: 0.5rem;

    span {
        font-size: 0.8rem;
        color: #19c27e;
        font-weight: 550;
    }

    img {
        width: 1.5rem;
        height: 1.5rem;}
`

export const UtmImgArea = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
`

export type ColumnItemProps = {
    isVisible: boolean;
};

export const ColumnItem = styled.div<ColumnItemProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    cursor: grab;
    background-color: #f3f3f3;
    border: 1px solid #f0f0f0;
    width: 95%;
    height: 1rem;
    padding: 0.8rem;
    border-radius: 7px;
    transition: all 0.3s;

    ${(props) => (props.isVisible ? '' : 'opacity: 0.5;')}

    img {
        width: 18px;
        height: 18px;
        margin-left: 0.5rem;
    }

    &:hover {
        background-color: #f0f0f0;
        border: 1px solid  #90debf;
        transition: all 0.3s;
    }

    &:active {
        cursor: grabbing;
        background-color: #f0f0f0;
        border: 1px solid  #19c27e;
        transition: all 0.3s;

    }

`;

export const VisibilityIconArea = styled.div`
  cursor: pointer;

  svg {
  width: 18px;
    &:hover {
        color: #363434;
    }
  }
`;

export const SaveButtonArea = styled.button`
width: 100%;
display: flex;
margin-top: 1rem;
justify-content: center;
background-color: transparent;
align-items: center;
`;


export const Icon = styled.div`
cursor: pointer;
`

export const Title = styled.div`
font-weight: 600;
line-height: 1.5rem;
font-size: 1.3rem;
// margin-top: 1rem;
// margin-bottom: 0.5rem;
color: #1e1e1e;
span{
    color: var(--green)
}
`

export const SearchSection = styled.div`
margin-top: -1rem;
margin-bottom: 1rem;
display: flex;
justify-content: flex-start;
`

export const Section = styled.div`
display: flex;
gap: 2rem;
margin-bottom: 2rem;
`

const floating = keyframes`
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
`;

export const ContainerImage = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    width: max-content;
    padding-bottom: 1rem;
    margin: 1.5rem auto 0;
    animation: ${floating} 2s ease-in-out infinite;
    
    img {
        width: 10.75rem;
        height: auto;
    }
`;

export const TitleImage = styled.div`
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: var(--green);
`;

export const SubtitleImage = styled.div`
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #a2a2a2;
    text-align: center;
`;


export const FirstSectionItem = styled.div`

`

export const FirstSectionHeader = styled.div`
display: flex;
`

export const FirstSectionValue = styled.div`
font-size: 1rem;
line-height: 1.35rem;
font-weight: 550;
margin-left: 1.5rem;
color: #000;
`

export const FirstSectionTitle = styled.div`
font-size: 0.75rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
`

export const SquareIcon = styled.div`
width: 1rem;
height: 1rem;
border-radius: 0.25rem;
background-color: #ededed;
display: flex;
align-items: center;
justify-content: center;
margin-right: 0.5rem;
svg{
    fill: #647a6f;
    width: 0.8rem;
    height: 0.8rem;
}
`


export const FirstSection = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
`

export const VLine = styled.div`
    height: 4rem;
    width: 1px;
    background-color: rgb(0,0,0,0.1);
    margin: auto 0;
`

export const SecondSection = styled.div`

`

export const SecondSectionTitle = styled.div`
font-size: 1rem;
line-height: 1.2rem;
font-weight: 450;
color: #000;
margin-bottom: 0.7rem;
text-transform: capitalize;
`

export const SecondSectionSubtitle = styled.div`
font-size: 0.75rem;
font-weight: 500;
line-height: 1.125rem;
color: #989c97;
`

export const Expand = styled.div`
border-radius: 50%;
width: 2.5rem;
height: 2.5rem;
border: 1px solid rgb(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
margin-left: 170px;
&:hover{
    outline: none;
    border-color: rgba(76, 234, 134, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
}
`


export const SecondSectionItem = styled.div`
display: flex;
margin-bottom: 0.5rem;
`

export const SecondSectionItemText = styled.div`
font-size: 0.75rem;
line-height: 1.125rem;
font-weight: 500;
color: #989c97;
span{
    color: var(--green);
}
`

export const Subtitle = styled.div`
font-weight: 500;
color: #989c97;
font-size: 0.9rem;
`

export const Line = styled.div`
// height: 1px;
// width: 100%;
// margin-top: 1.5rem;
// background-color: rgb(0,0,0,0.1);
// margin-bottom: 2rem;
`

export const Header = styled.thead`
display: flex;
background-color: #f7f7f7;
width: 89.4375rem;
height: 3.3rem;
position: sticky;
top: 0;
z-index: 10;
`
export const InnerContainer = styled.div`
width: 57.375rem;
overflow-x: auto;
height: auto;
position: relative;
`

export const MainColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 6rem;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 1rem;
position: sticky;
left: 0;
top: 0;
z-index: 10;
background-color: inherit;
border-right: 1px solid #eee;
`

export const DefaultColumn = styled.div`
font-size: 0.75rem;
color: #0e1819;
font-weight: 700;
width: 9.25rem;
display: flex;
align-items: center;
justify-content: flex-start;
margin-left: 1rem;
`;

type LinesProps = {
    dontHaveOrders: boolean
}

export const Lines = styled.div<LinesProps>`
max-height: 20rem;
position: relative;
// ${props => props.dontHaveOrders && 'padding-bottom: 15rem;'}

    > div:nth-child(odd) {
        background-color: var(--white);
    }

    > div:nth-child(even) {
        background-color: var(--gray-112);
    }
`;

export const Filter = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    width: 6rem !important;
    box-sizing: border-box;
    width: var(--button-diameter);
    height: var(--button-diameter);
    border-radius: var(--button-border-radius);
    color: #555353;
    border: none;
    background: #fcfcfc;
    border: 1px solid #d9dbd9;
    cursor: pointer;
    position: relative;
    outline: none;
    --button-bg: #353434;
    --button-hover-bg: #464646;
    --button-text-color: #cccccc;
    --button-hover-text-color: #8bb9fe;
    --button-border-radius: 8px;
    --button-diameter: 45px;
    margin-left: 15px;
    --button-outline-width: 1px;
    --button-outline-color: rgb(141, 141, 141);
    align-items: center;
    transition: all 200ms ease;
    &:hover {
        outline: none !important;
        border-color: rgba(76, 234, 134, 0.4) !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1) !important;
        transition: all 200ms ease;
    }
`;

type FilterProps = {
    showFilter: boolean;
};

export const FilterContainer = styled.div<FilterProps>`
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
    padding: 16px;
    width: 240px; /* Ajuste a largura conforme necessário */
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0; /* Inicia com opacidade zero */
    max-height: 0;
    position: absolute;
    z-index: 50;

    ${(props) =>
        props.showFilter
            ? `
                max-height: 500px;
                position: absolute;
                z-index: 50;
                top: 450px;
                left: 880px;
                opacity: 1;
            `
            : `
            `}
`;

export const HeaderFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
`;

export const CloseButton = styled.i`
    display: flex;
    color: #989c97;
    margin-right: 16px;
    cursor: pointer;

    &:hover {
        position: relative;
        color: #192324;
        border-radius: 3px;
        cursor: pointer;
    }
`;

export const LinesFilter = styled.span`
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 16px;
`;

export const FilterSection = styled.div`
    margin-bottom: 20px;
`;

export const FlexTitleReset = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    font-size: 11px;
    color: #979797;
    font-weight: 500;
`;

export const ResetButton = styled.button`
    background: none;
    border: none;
    color: #19c27e;
    cursor: pointer;
    font-size: 11px;
`;

export const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: center;
    font-size: 11px;
    color: #555353;
    font-weight: 500;
`;


export const ApplyButton = styled.button`
    display: block;
    width: 100%;
    background-color: #19c27e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 200ms ease;
    &:hover {
        transform: translateY(2px);
    }
`;

export const Filters = styled.div`
    displey: flex;
`;

export const Payment = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #e5e5e5;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-top: 0.3rem;
    margin-left: 1rem;
    img {
        margin-left: 1rem;
        width: 0.75rem;
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    width: 11.825rem;
`;

export const Approved = styled.div`
    width: 11.825rem;
    height: 1rem;
    color: #448f71;
    background-color: #b4edd661;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Pending = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f8544;
    background-color: #ebedb461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Cancelled = styled.div`
    width: 11.825rem;
    height: 1.3rem;
    color: #8f4444;
    background-color: #edb4b461;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.7rem;
    margin-botton: 0.3rem;
`;

export const Image = styled.div`
    display: flex;
    margin-left: 1rem;
`;

export const DragContainer = styled.div`
        
`