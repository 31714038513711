import { createBrowserRouter } from 'react-router-dom';
import { Login } from './pages/Login';
import { AccountDetails } from './pages/AccountDetails';
import { ResetPassword } from './pages/ResetPassword';
import { ShopifySubscription } from './pages/ShopifySubscription';
import { Subscription } from './pages/Subscription';
import { MyStores } from './pages/MyStores';
import { Clients } from './pages/Clients';
import { Integration } from './pages/Integration';
import { AppLayout } from './pages/_layouts/AppLayout';
import { Products } from './pages/Products';
import { ProductCosts } from './pages/ProductsCosts';
import { Orders } from './pages/Orders';
import { LoadingOnboarding } from './pages/LoadingOnboarding';
import { Tracking } from './pages/Tracking';
import { CostOperation } from './pages/CostOperation';
import { OnboardingV2 } from './pages/OnboardingV2'; 
import { Dashboard } from './pages/Dashboard';
import { IntegrateAccount } from './pages/IntegrationAccount';
import NewCalendar from './components/Calendar';
import { Permission } from './pages/Permission';
import { Plans } from './pages/Plans';
import { Congratulation } from './pages/Congratulation';
import { TryAgainGoogle } from './components/TryAgainGoogle';
import { Terms } from './pages/Terms';
import { Configuration } from './pages/Configuration';
import { ShopifyInstall } from './pages/ShopifyInstall';
import { ShopifyPayment } from './pages/ShopifyPayment';
import { DashboardMobile } from './pages/DashboardMobile';
import { Payment } from './pages/Payment';

export const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/shopify/install', element: <ShopifyInstall /> },
    { path: '/shopify/payment', element: <ShopifyPayment />},
    { path: '/payment', element: <Payment />},
    { path: '/lojas', element: <MyStores /> },
    { path: '/loading', element: <LoadingOnboarding /> },
    { path: '/tracking', element: <Tracking /> },
    { path: '/onboarding', element: <OnboardingV2 /> },
    { path: '/:typeAccount/authorized', element: <IntegrateAccount /> },
    { path: '/terms', element: <Terms /> },
    {
        path: '/tracking',
        element: <AppLayout grayBackground={true} />,
        children: [{ path: '/tracking', element: <Tracking /> }],
    },
    {
        path: '/dashboard',
        element: <AppLayout grayBackground={true} />,
        children: [{ path: '/dashboard', element: <Dashboard /> }],
    },
    {
        path: '/conta',
        element: <AppLayout />,
        children: [{ path: '/conta', element: <AccountDetails /> }],
    },
    {
        path: '/redefinir',
        element: <AppLayout />,
        children: [{ path: '/redefinir', element: <ResetPassword /> }],
    },
    {
        path: '/subscription',
        element: <AppLayout />,
        children: [{ path: '/subscription', element: <Subscription /> }],
    },
    {
        path: '/shopify/subscription',
        element: <AppLayout />,
        children: [{ path: '/shopify/subscription', element: <ShopifySubscription /> }],
    },
    {
        path: '/clientes',
        element: <AppLayout />,
        children: [{ path: '/clientes', element: <Clients /> }],
    },
    {
        path: '/integracao',
        element: <AppLayout />,
        children: [{ path: '/integracao/:source', element: <Integration /> },{ path: '/integracao', element: <Integration /> }],
    },
    {
        path: '/produtos',
        element: <AppLayout grayBackground={false} />,
        children: [{ path: '/produtos', element: <Products /> }],
    },
    {
        path: '/custo',
        element: <AppLayout />,
        children: [
            { path: '/custo', element: <ProductCosts /> }, // Rota base
            { path: 'variantes/:product_id', element: <ProductCosts /> }, // Rota para edição de variantes
        ],
    },
    {
        path: '/adicionais',
        element: <AppLayout />,
        children: [{ path: '/adicionais', element: <CostOperation /> }],
    },
    {
        path: '/pedidos',
        element: <AppLayout />,
        children: [{ path: '/pedidos', element: <Orders /> }],
    },
    {
        path: '/equipe',
        element: <AppLayout />,
        children: [{ path: '/equipe', element: <Permission /> }],
    },
    {
        path: '/planos',
        element: <Plans />,
    },
    {
        path: '/pagamento',
        element: <Payment />,
    },

    {
        path: '/congratulations',
        element: <Congratulation type='dashboard' />,
    },
    {
        path: '/calendar',
        element: <NewCalendar />,
    },
    {
        path: '/teste',
        element: <TryAgainGoogle />,
    },
    {
        path: '/utm_config',
        element: <AppLayout />,
        children: [{ path: '/utm_config', element: <Configuration /> }],
    },
    {
        path: '/dashboardMobile',
        element: <AppLayout />,
        children: [{ path: '/dashboardMobile', element: <DashboardMobile /> }],
    }
]);
