import styled, { keyframes } from 'styled-components';


export const StepperContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0rem;
margin-bottom: 1rem;
    div {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            color: #989c97;
        }

        span {
            font-size: 0.9rem;
            color: #19c27e;
        }
    }
`;

export const Stepper = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 50px;
    background-color: #D9D9D9;
`;

export const StepLoading = styled.div`
    height: 4px;
    border-radius: 50px;
    background-color: var(--green);

    transition: width 450ms;
`;


export const StepCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
`;


type StepCardProps = {
    isActive: boolean;
};

export const StepCard = styled.div<StepCardProps>`
    display: flex;
    gap: 0rem;
    padding: 0.4rem;
    border-radius: 8px;
    transition: all 250ms;
    align-items: center;
    justify-content: space-between;

    @media(min-width: 1800px){
        padding: 0.8rem;
    }

    h3 {
        font-size: 0.9rem;
        font-weight: 500;
        color: #618083;
        margin-bottom: 0rem;
        @media(min-width: 1800px){
            font-size: 1.1rem;
        }
    }

    span {
        @media(min-width: 1800px){
            font-size: 0.8rem;
        }
        font-size: 0.7rem;
        color: #989c97;
    }
    
    
    &:hover {
        background-color: #FBFBFB;
        transition: all 250ms;

    }

    ${(props) =>
        props.isActive
            ? `
    background-color: #FBFBFB;
    h3{
        color: var(--black);
    }
    `
                : `

    `}
`;

export const LeftSideCard = styled.div`
   display: flex;
   gap: 1rem;
   align-items: center;
`
export const LoadIntegrationArea = styled.div`
   display: flex;
   align-items: center;
`

export const LoadIntegrationContainer = styled.div`
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        position: absolute;
        width: 0.8rem;
    }
`

export const VLine = styled.div`
height: 1rem;
width: 1px;
background-color: #ccc;
margin-left: 0.4rem;
margin-right: 0.4rem;
`;

export const CardStep = styled.div<StepCardProps>`
display: flex;
align-items: center;
justify-content: center;
span {
    font-size: 0.9rem;
    font-weight: 500;
    color: #999;
}
width: 0.5rem;
height: 0.5rem;
padding: 0.9rem;
border-radius: 7px;
border: 1px solid #E3E7E5;
display: flex;

@media(min-width: 1800px){
   padding: 1.2rem;
}

${(props) =>
    props.isActive
        ? `
    border: 1px solid var(--green);
    span{
        color: var(--black);
    }
`
            : `

`}

`

export const CardInfos = styled.div`
    display: flex;
    flex-direction: column;
`

export const MainText = styled.div`
    font-size: 2.1875rem;
    font-weight: 450;
    line-height: 2.625rem;
    margin-top: 3rem;
    color: #989c97;
`;

export const SecondaryText = styled.div`
    font-size: 2.1875rem;
    line-height: 2.625rem;

    font-weight: 600;
    color: var(--black);
`;

export const ContainerCardsSection = styled.div`
    position: relative;
    display: flex;
    margin-right: 4rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media(max-width: 1225px){
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;
