import styled from 'styled-components';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

export const Container = styled.div`
    display: flex;
    color: var(--black);
    height: 5.375rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    > span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
`;
export const Image = styled.div`
    // width: 26%;
    img {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 5px;
        margin-top: 1.2rem;
        margin-left: 1.5rem;
    }
`;

export const ProductName = styled.div`
    display: flex;
    justify-content: center;
    width: 26%;
    margin-left: 1rem;
    flex-direction: column;

    p {
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 9rem;}
`;

export const Price = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Cost = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const EditVariants = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`

export const Action = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 26%;
`;

export const LastSale = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 22%;
`;

export const EditIconStyled = styled(EditRoundedIcon)`
    width: 0.7em !important;
    fill: var(--black);
`;