export function getIntervalString(date: string): string {

    const updatedDate = new Date(date);
  
    if(!updatedDate.getDate()){
      return 'Atualizado recentemente'
    }
    const intervalInSeconds = Math.floor((new Date().getTime() - updatedDate.getTime()) / 1000);
    if(intervalInSeconds < 60){
      return 'Atualizado recentemente'
    }
    const intervalInMinutes = Math.floor(intervalInSeconds/60)
  
    if(intervalInMinutes < 60){
      if(intervalInMinutes === 1){
        return `Atualizado há 1 minuto`
      }
      return `Atualizado há ${intervalInMinutes} minutos`
    }
  
    const intervalInHours = Math.floor(intervalInMinutes / 60)
  
    if(intervalInHours < 24){
      if(intervalInHours === 1){
        return 'Atualizado há 1 hora'
      }
      return `Atualizado há ${intervalInHours} horas`
    }
  
    const intervalInDays = Math.floor(intervalInHours / 24)
  
    if(intervalInDays === 1){
      return 'Atualizado há 1 dia'
    }
  
    return `Atualizado há ${intervalInDays} dias`
  
  }
