import React, { useEffect, useState } from 'react';
import * as S from './style.ts';
import { ButtonApp } from '../../../components/ButtonApp';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import congratsGift from '../../../assets/images/congrats.json';
import Lottie from 'lottie-react';

function ModalCongratulations({ onClose }) {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress > 0) return prevProgress - (100 / 10);  // Decrementa em partes iguais durante 15 segundos
                clearInterval(interval);
                onClose();  // Fecha o modal quando o tempo acabar
                return 0;
            });
        }, 1000);  // Atualiza a cada segundo

        return () => clearInterval(interval);  // Limpa o intervalo quando o componente desmonta
    }, [onClose]);

    return (
        <S.Modal>
            <S.ModalContent>

            <S.ProgressBar><div style={{ width: `${progress}%`, height: '8px', backgroundColor: '#19c27e', borderRadius: '50px', transition: 'width 1.25s linear' }}></div></S.ProgressBar>

                <S.StarIconDown>
                <StarIcon />
                </S.StarIconDown>
                <S.StarIconUp>
                <StarIcon />
                </S.StarIconUp>
                <S.TopBanner>

                    <VerifiedIcon />
                    <S.Fireworks>
                        <Lottie animationData={congratsGift} />
                    </S.Fireworks>
                </S.TopBanner>

                <S.Title>
                    <span>Tudo pronto!</span>
                    Nossa plataforma está pronta para uso.
                </S.Title>
                <S.Subtitle>
                    Agora você já pode ter acesso a todas as informações do seu negócio
                    de forma centralizada e automatizada
                </S.Subtitle>

                <S.ButtonContainer>
                <ButtonApp 
                    border=''
                    width="13rem"
                    height="3rem"
                    color="#fff"
                    background="#19c380"
                    icon={<DashboardIcon />}
                    onClick={() => onClose()}
                    text="Acessar Dashboard"
                />
                </S.ButtonContainer>
              
            </S.ModalContent>
        </S.Modal>
    );
}

export default ModalCongratulations;



function VerifiedIcon() {
    return (  
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm94-278 226-226-56-58-170 170-86-84-56 56 142 142Z"/>
        </svg>
    );
}

function StarIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z"/></svg>
    )
}

function DashboardIcon() {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Z"/>
    </svg>
    )
}