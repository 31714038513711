import styled, { keyframes } from 'styled-components';



export const Container = styled.div`
    background-color: var(--white);
    width: 12.6875rem;
    height: fit-content;
    border-radius: 10px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.8125rem;
    margin-bottom: 0.6875rem;

    @media (max-width: 1050px) { 
        width: 455px;
        > *:not(:last-child) {
            margin-bottom: 1vh; 
            margin-right: 1vw; 
        }
    }

    @media (max-width: 610px) {
        width: 85vw;    
        > *:not(:last-child) {
            margin-bottom: 1vh; 
            margin-right: 1vw; 
        }
    }
`;

export const ContainerLoading = styled.div`
    width: 100%;
    max-width: 30rem;
    height: 4.875rem;
    border-radius: 10px;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;


export const Title = styled.div`
    position: relative; 
    font-size: 0.8125rem;
    color: #676767;
    font-weight: 500;
    line-height: 1.21875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    span {
        svg {
            width: 0.875rem;
            height: 0.875rem;
            fill: #E90E0E;
            margin-left: 0.2rem;
            margin-bottom: -0.2rem;
        }

        &:hover::after {
            content: "Atenção! Você teve vendas de produtos que não possuem custo configurado. Clique para configurar.";
            position: absolute;
            bottom: 100%; 
            left: 50%; 
            transform: translateX(-50%); 
            background-color: white;
            color: #1e1e1e;
            padding: 0.5rem;
            width: 15rem;   
            height: 3rem;
            border: 1px solid #e3e3e3;
            border-radius: 6px;
            font-size: 0.7125rem;
            font-weight: 550 !important;
            font-family: 'Montserrat';
            z-index: 1; 
            pointer-events: none;
        }
    }
`;

export const Value = styled.div`
    font-size: 1.6875rem;
    font-weight: 550;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    line-height: 2.025rem;
    color: var(--black);
    margin-top: 0.3rem;
`;

type PercentageProps = {
    showUp: boolean;
};

export const Percentage = styled.div<PercentageProps>`
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.25rem;
    gap: 0.5rem;
    font-weight: 400;
    span {
        ${(props) => (props.showUp ? 'color: var(--green);' : 'color: #dc143c;')}
    }
`;

export const Button = styled.div`
    width: 4.5rem;
    height: 1.4375rem;
    border-radius: 50px;
    color: #919191;;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    line-height: 0.9375rem;
    margin-top: 0.65rem;
    cursor: pointer;
    svg {
        margin-left: 0.25rem;
    }
    border: 1px solid #cecece;
    &:hover {
        color: var(--black);
        border: 1px solid var(--green);
    }
`;

// Coloca o ícone antes do texto add
export const RedirectButton = styled.button`
    border: none;
    padding: 6px;
    display: flex;
    border-radius: 7px;
    color: #6c7d77;
    background-color: #f8faf9;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 250ms;
    cursor: pointer;

    &:hover {
        background-color: #e9efed;
        color: #495752;
        transform: translateY(-3px); // Usei translateY(-3px) porque parece ser um efeito de "levantar" o botão
    }

    svg {
        font-size: 16px;
    }

    @media (max-width: 1400px) {
        display: none;
    }

`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TooltipArea = styled.div`
   display: flex;
    position: relative;
    border-top: 1px solid #d8d8d8;
    gap: 0.5rem;
    margin-top: 0.5rem;
    padding: 1rem 0 0rem 0;
    flex-direction: column;
`

export const CircleIndicator = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const ShopifyIcon = styled.div`
    padding: 0.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50px;
    background-color: #f8f8f8;
    // border: 1px solid #cecece;
    display: flex;
    align-items: center;
    position: absolute;
    right: -2rem;
    justify-content: center;

    img {
        width: 1rem;
        height: 1rem;}
`


export const DataDivisionsArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

export const DataDivision = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    // padding-bottom: 0.5rem;
    // border-bottom: 1px solid #f8f8f8;

    svg {
        width: 1.1rem;
        margin-right: 0.5rem;
        height: 1.1rem;
        }

    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        }

    div {
        display: flex;
        align-items: center;
        
        p {
            font-size: 0.8rem;
            font-weight: 550;
        } 
    }
`;