import { PermissionListProps } from '../../../@types';
import { LinePermissionList } from './LinePermissionList';
import { ModalNewMember } from '../ModalNewMember';
import { ModalNewGroup } from '../ModalNewGroup';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useState } from 'react';
import Modal from '../../../components/Modal';
import * as S from './style';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Skeleton } from '@mui/material';
import useVisibility from '../../../hooks/useVisibilityChange';
import { useDeleteAccessControl } from '../../../services/hooks/accessHooks';
import useAuth from '../../../hooks/useAuth';
import { ButtonApp } from '../../../components/ButtonApp';
import { ArrowBack } from '@mui/icons-material';
import useAlert from '../../../hooks/useAlert';

export function PermissionList({refetchDataAccessControl, groups, members, groupName, buttonText, isLoadingSkeleton }: PermissionListProps) {
    const { user, currentCompany } = useAuth();
    const { mutate: deleteMember, isLoading, isError, error } = useDeleteAccessControl();
    const { showAlert } = useAlert();
    const [showModalNewGroup, setShowModalNewGroup] = useState(false);
    const [showModalNewMember, setShowModalNewMember] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteGuestId, setDeleteGuestId] = useState('');
    const [memberEmail, setMemberEmail] = useState('');

    const { isVisible } = useVisibility();
    function handleSelectModal() {
        if (groupName === 'Grupos') {
            setShowModalNewGroup(true);
        } else {
            setShowModalNewMember(true);
        }
    }

    function handleAddGroups(name: string, scopes: string[], group_id: string) {
        groups?.push({
            group_id: group_id,
            name: name,
            scopes: scopes
        })
    }

    function handleDeleteMember(guest_id: string) {
        showAlert('Removendo acesso do membro à sua loja, aguarde um momento.', 'loading', 8000)
        handleCloseModal();

        deleteMember(
            {
                company_id: currentCompany,
                guest_id: guest_id ?? '',
            },
            {
                onSuccess: (data) => {
                    setDeleteGuestId('');
                    refetchDataAccessControl();
                    //console.log('veio aqui success?')
                },
                onError: (error) => {
                    setDeleteGuestId('');
                    //console.log('veio aqui?')
                }
            }
        );
    }
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setDeleteGuestId('');
      };

      const handleOpenModal = (guest_id: string, guest_email: string) => {
        setIsModalOpen(true);
        setDeleteGuestId(guest_id);
        setMemberEmail(guest_email);
      };

    return (
        <S.Container>

    {isModalOpen && (
        <Modal onClose={handleCloseModal}>
        <S.ModalContent>

            <h2>Remover acesso do membro</h2>
                <p>Tem certeza que deseja remover o acesso de <strong>{memberEmail}</strong> ?</p>
                <ButtonApp
                  border=''
                  borderRadius='10px'
                  text="Sim, remover acesso"
                  width="12rem"
                  fontSize='14px'
                  height="2.5rem"
                  background='#bf1f1f'
                  icon=""
                  color="white"
                  isLoading={isLoading}
                  onClick={() => handleDeleteMember(deleteGuestId ?? '')}
                />
             
            <S.ButtonText style={{background: 'transparent', color: '#999', display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '0.5rem'}} onClick={handleCloseModal}><ArrowBack fontSize='small'/>Não, voltar</S.ButtonText>
        </S.ModalContent>
    </Modal>
      )}

            <S.Header>
                <S.Title>{groupName}</S.Title>
                <S.Button onClick={handleSelectModal}>
                    {groupName === 'Grupos' ? <AdGroupIcon /> : <GroupAddIcon />}
                    {buttonText}
                </S.Button>
            </S.Header>
            <S.Line />
            <S.List groupName={groupName}>

                {isLoadingSkeleton && groupName === 'Grupos' && Array.from({ length: 3 }).map((_, index) => 
                {   
                    return(
                        <LinePermissionList key={index} isLoading={isLoadingSkeleton} group={[]} />
                    )
                })}

                {isLoadingSkeleton && groupName === 'Membros' && Array.from({ length: 2 }).map((_, index) => {
                   return(
                    <S.Member key={index}>
                         <PersonOutlineOutlinedIcon sx={{ width: '16px', height: '16px' }} /> <Skeleton sx={{ fontSize: '1rem', width: '12rem', height: '1.5rem' }} />
                        <S.GroupName> <Skeleton sx={{ fontSize: '1rem', width: '3rem', height: '1.5rem' }} /></S.GroupName>
                    </S.Member>
                   )
                })}

                {!isLoadingSkeleton && groupName === 'Grupos' &&
                    groups?.map((group) => {
                        return (
                            <>
                                <LinePermissionList key={group?.group_id} group={group} />
                            </>
                        );
                    })}
                {!isLoadingSkeleton && groupName === 'Membros' &&
                    members?.map((member) => {
                        return (
                            <>
                                <S.Member key={member?.guest_id}>
                                    <PersonOutlineOutlinedIcon sx={{ width: '16px', height: '16px' }} /> {isVisible ? member?.guest_email : '* * * * * * * * * * * *'}
                                    <div style={{display: 'flex', gap: '0.5rem'}}>
                                    <S.GroupName><p>{isVisible ? member?.group_name : ' * * * *'}</p></S.GroupName>
                                    <DeleteForeverRoundedIcon onClick={() => handleOpenModal(member?.guest_id, member?.guest_email)}/>
                                    </div>
                                </S.Member>
                            </>
                        );
                    })}
            </S.List>

            {showModalNewGroup && (
                <Modal onClose={() => setShowModalNewGroup(false)}>
                    <ModalNewGroup setShowModalNewGroup={setShowModalNewGroup} handleAddGroups={handleAddGroups}/>
                </Modal>
            )}

            {showModalNewMember && (
                <Modal onClose={() => setShowModalNewMember(false)}>
                    <ModalNewMember refetchDataAccessControl={refetchDataAccessControl} groups={groups} setShowModalNewMember={setShowModalNewMember}/>
                </Modal>
            )}
        </S.Container>
    );
}

function AdGroupIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M320-320h480v-400H320v400Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
        </svg>
    );
}

function GroupAddIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Zm320-400q33 0 56.5-23.5T400-640q0-33-23.5-56.5T320-720q-33 0-56.5 23.5T240-640q0 33 23.5 56.5T320-560ZM80-240h480v-32q0-11-5.5-20T540-306q-54-27-109-40.5T320-360q-56 0-111 13.5T100-306q-9 5-14.5 14T80-272v32Zm240-400Zm0 400Z" />
        </svg>
    );
}
