import styled from 'styled-components';

export const Container = styled.div`
    height: auto;
`;

export const Title = styled.div`
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.95rem;
    color: #1e1e1e;
    margin: 3rem 2rem 0.4rem;
`;

export const Subtitle = styled.div`
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #a7a7a7;
    margin: 0 2rem 2rem;
`;
