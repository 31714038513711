import { Banner } from '../../components/Banner';
import { Search } from '../../components/Search';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import * as S from './style';
import { TableTrackingChannel } from './TableTrackingChannel';
import { useState, useEffect, useRef } from 'react';
import { TableTrackingCampaign } from './TableTrackingCampaign';
import { TableTrackingAdsSet } from './TableTrackingAdsSet';
import { TableTrackingAds } from './TableTrackingAds';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Tooltip } from '@mui/material';
import { reduceString } from '../../utils';
import Graphic from './Graphic';
import { GraphicDataTrackingProps, getSalesDTO } from '../../@types';
import { getChartTransformed, selectGraphicData } from './utils';
import { useTrackingMetrics,useGetCatalogList } from '../../services/hooks/trackingHooks';
import useAuth from '../../hooks/useAuth';
import { TableTrackingLoading } from './TrableTrackingLoading';
import { SelectSolomon } from './SelectSolomon';
import { SelectAttribution } from './SelectAttribution';
import Calendar from '../../components/Calendar';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { format } from 'date-fns';
import { SalesModal } from './SalesModal';
import { EditColumnModal } from './EditColumnModal';
import Modal from '../../components/Modal';
import { getWrongUtms } from './utils';
import useVisibility from '../../hooks/useVisibilityChange';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useCheckboxTracking } from '../../hooks/useCheckboxTracking';
import { trackEvent } from '../../services/segment/sendEvent'
import { useMemo } from 'react';
import { useRefreshContext } from '../../contexts/RefeshContext';
import { ViewWeekOutlined } from '@mui/icons-material';
import { EditColumnsProvider } from '../../contexts/EditColumnsTrackingContext';
import { SortingProvider } from '../../contexts/SortingContext';
import { ButtonApp } from '../../components/ButtonApp';
import PaymentMethodSelector from './PaymentFilter';
import ProductSelector from './ProductFilter';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { useProductsAnalyticsList } from '../../services/hooks/productsHooks';

export function Tracking() {

    const { refreshCounter } = useRefreshContext();
    const { user, currentCompany } = useAuth();

    const shopifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length || 0;
    const kiwifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length || 0;

    useEffect(() => {
        // Refetch data when refreshCounter changes
        if (refreshCounter > 0) {
            refetchTrackingMetricsToday();
            refetchTrackingMetrics();
        }
    }, [refreshCounter]);

    const { data: dataProductList, isLoading: isLoadingProductList } = useProductsAnalyticsList(currentCompany);

    const [productIds, setProductIds] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [tempSelectedProducts, setTempSelectedProducts] = useState({});
    const [update, setUpdate] = useState(false);

    const [columnAttribution, setColumnAttribution] = useState('last_click');
    const [showSelectAttribution, setShowSelectAttribution] = useState(false);

    useEffect(() => {
        //console.log('10')
        if (!isLoadingProductList && dataProductList?.data?.data) {
            const productsData = dataProductList.data.data.map(item => ({
                product_id: item.product_id,
                product_name: item.product_name,
            }));
            setProducts(productsData);
            const ids = productsData.map(item => item.product_id);
            setProductIds(ids);
            setUpdate(true);
            if (shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1){
                setProductIds([]);
            }
        }
    }, [isLoadingProductList, dataProductList]);


    const { selectedPlatforms, selectedPlatformsOthers, selectedCampaignIds, selectedAdSetIds, selectedAdsIds, clearAllPlatforms, clearAllCampaigns, clearAllAdSets, clearAllAds, setSelectedTab, selectedTab } = useCheckboxTracking();

    const [date, setDate] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);
    const [dateToday, setDateToday] = useState([format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')]);
    const { isLoading, data: dataMetrics, refetch: refetchTrackingMetrics } = useTrackingMetrics(currentCompany, date[0], date[1], productIds, columnAttribution);
    const { isLoading: isLoadingCatalogList, data: dataCatalogList, refetch: refetchCatalogList } = useGetCatalogList(currentCompany, date[0], date[1]);    

    const { data: dataMetricsToday, refetch: refetchTrackingMetricsToday } = useTrackingMetrics(currentCompany, dateToday[0], dateToday[1], productIds, columnAttribution);
    const { showArticle } = useIntercom();
    const { isVisible } = useVisibility();
    const [channelSelect, setChannelSelect] = useState('');
    const [typeSelect, setTypeSelect] = useState('allSources');
    const [otherType,] = useState('' as string);
    const [accountId,] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [showGraphic, setShowGraphic] = useState(false);
    const [showSelectSolomon, setShowSelectSolomon] = useState(false);
    const [columnSolomon, setColumnSolomon] = useState('totalSolomon');
    const [paymentMethod, setPaymentMethod] = useState('PIX');
    const [openSalesModal, setOpenSalesModal] = useState(false)
    const [openEditColumnModal, setOpenEditColumnModal] = useState(false)
    const [filtred, setFiltred] = useState('');
    const [salesInfo, setSalesInfo] = useState({} as getSalesDTO)
    const [isCatalogCampaign, setCatalogCampaign] = useState(false);
    const [isCatalogChannel, setCatalogChannel] = useState(false);
    const [isSalesCatalogChannel, setSalesCatalogChannel] = useState(false);

    useEffect(() => {
        if (dataCatalogList?.data?.data?.campaign_ids.length > 0) {
            setCatalogChannel(true);
        } else {
            setCatalogChannel(false);
        }
    }, [selectedTab, dataCatalogList, dataCatalogList?.data?.data?.campaign_ids, date, openSalesModal]);

    const [ordersSolomonSales, setOrdersSolomon] = useState({});
    const [revenueSolomonSales, setRevenueSolomon] = useState({});
    const [metricsBySource, setMetricsBySource] = useState({})
    const [source, setSource] = useState('')
    const [adsSetId, setAdsSetId] = useState('');
    const [adId, setAdId] = useState('');
    const [showSagWarning, setShowSagWarning] = useState(false);
    const [campaignSelect, setCampaignSelect] = useState('');
    const [adsSetSelect, setAdsSetSelect] = useState('');
    const [adsSelect, setAdsSelect] = useState('');
    const [graphicData, setGraphicData] = useState<GraphicDataTrackingProps>({
        name: '',
        xAxis: [],
        spendBySource: [],
        ordersBySource: [],
        ordersSolomon: [],
        revenueBySource: [],
        revenueSolomon: [],
        roasBySource: [],
        roasSolomon: [],
        cpaBySource: [],
        cpaSolomon: [],
        cpcBySource: [],
        cpmBySource: [],
        impressionsBySource: [],
        clicksBySource: [],
        checkoutsBySource: [],
        addToCartsBySource: [],
        pageViewsBySource: [],
        profitByUtmApproved: [],
    });

    const hasTrueKeys = Object.keys(selectedPlatforms).some(key => selectedPlatforms[key] === true);
    const anyOthersSelected = Object.keys(selectedPlatformsOthers).some(key => selectedPlatformsOthers[key] === true);

    let onlyOthersPlatformsChecked;

    if (anyOthersSelected && hasTrueKeys) {
        onlyOthersPlatformsChecked = false;
    }

    if (anyOthersSelected && !hasTrueKeys) {
        onlyOthersPlatformsChecked = true;
    }


    function handleUnselectChannel() {
        clearAllPlatforms();
        clearAllCampaigns();
        clearAllAdSets();
        clearAllAds();
        setShowGraphic(false);
        setChannelSelect('');
        setTypeSelect('allSources');
        setCampaignSelect('');
        setCampaignId('');
        setAdsSetId('');
        setAdsSetSelect('');
    }

    function handleUnselectCampaign() {
        clearAllCampaigns();
        setShowGraphic(false);
        setCampaignSelect('');
        setCampaignId('');
        setTypeSelect('allSources');
        setAdsSetSelect('');
        setAdsSetId('');
        setAdsSelect('');
        setAdId('');
        clearAllAds();
        clearAllAdSets();
    }

    function handleUnselectAdsSet() {
        clearAllAdSets();
        clearAllAds();
        setShowGraphic(false);
        setAdsSetSelect('');
        setAdsSetId('');
    }

    function handleUnselectAds() {
        clearAllAds();
        setShowGraphic(false);
        setAdsSelect('');
        setAdId('');
    }

    function handleSelectGraphicData(tab: string, name: string, type: any, parameterId: string, secondaryParamterId: string = '') {

        if (type === 'facebook_campaigns_metrics') {
            type = 'facebook';
        } else if (type === 'google_campaigns_metrics') {
            type = 'google';
        } else if (type === 'tiktok_campaigns_metrics') {
            type = 'tiktok';
        }

        const chart = selectGraphicData(dataMetrics?.data?.data, tab, type, parameterId, secondaryParamterId);

        const chartTransformed = getChartTransformed(chart);

        const graphicData = {
            name: isVisible ? name : '* * * * * * * * * *',
            xAxis: chartTransformed.xAxis,
            spendBySource: chartTransformed.spendBySource,
            profitByUtmApproved: chartTransformed.profitByUtmApproved,

            ordersBySource: chartTransformed.ordersBySource,
            ordersSolomon: chartTransformed.ordersSolomonTotal,

            revenueBySource: chartTransformed.revenueBySource,
            revenueSolomon: chartTransformed.revenueTotal,

            roasBySource: chartTransformed.roasBySource,
            roasSolomon: chartTransformed.roasSolomonTotal,

            cpaBySource: chartTransformed.cpaBySource,
            cpaSolomon: chartTransformed.cpaSolomonTotal,

            cpcBySource: chartTransformed.cpcBySource,
            cpmBySource: chartTransformed.cpmBySource,

            impressionsBySource: chartTransformed.impressionsBySource,
            clicksBySource: chartTransformed.clicksBySource,
            checkoutsBySource: chartTransformed.checkoutsBySource,
            addToCartsBySource: chartTransformed.addToCartsBySource,
            pageViewsBySource: chartTransformed.pageViewsBySource,
        };

        setShowGraphic(true);

        setGraphicData(graphicData);
    }

    function handleOpenSalesModal(salesInfo: getSalesDTO, metrics: any, source: string, catalogCampaign?: boolean, catalogChannel?: boolean, ordersSolomon?: any, revenueSolomon?: any) {

        if (salesInfo.source === 'facebook_campaigns_metrics') {
            salesInfo.source = 'facebook';
        } else if (salesInfo.source === 'google_campaigns_metrics') {
            salesInfo.source = 'google';
        } else if (salesInfo.source === 'tiktok_campaigns_metrics') {
            salesInfo.source = 'tiktok';
        }

        salesInfo.attribution = columnAttribution;
        
        if (catalogChannel === true ) {
            setSalesCatalogChannel(true)
            setOrdersSolomon(ordersSolomon)
            setRevenueSolomon(revenueSolomon)
        } else {
            setSalesCatalogChannel(false)
        }

        if (catalogCampaign === true) {
            setCatalogCampaign(true)
            setOrdersSolomon(ordersSolomon)
            setRevenueSolomon(revenueSolomon)
        } else {
            setCatalogCampaign(false)
        }

        setSalesInfo(salesInfo)
        setMetricsBySource(metrics)
        setSource(source)
        setOpenSalesModal(true)
    }

    function handleOpenEditColumnModal() {
        setOpenEditColumnModal(true);
    }

    function handleClosePopup() {
        if (showSelectSolomon) {
            setShowSelectSolomon(false);
        }
    }

    const [, setSelectedPlatformCount] = useState('');
    const [, setSelectedCampaignCount] = useState('');
    const [, setSelectedAdsSetCount] = useState('');
    const [, setSelectedAdsCount] = useState('');

    useEffect(() => {
        const countTruePlatforms: any = Object.values(selectedPlatforms).filter(value => value).length + Object.values(selectedPlatformsOthers).filter(value => value).length;
        // Verifica se a contagem é maior que zero para enviar o valor, caso contrário envia uma string vazia
        // onCountChange(countTruePlatforms > 0 ? countTruePlatforms : '');
        setChannelSelect(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');
        setSelectedPlatformCount(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');

    }, [selectedPlatforms, selectedPlatformsOthers]);


    useEffect(() => {
        const countTruePlatforms = selectedCampaignIds.length;
        // //console.log("Número de plataformas selecionadas:", countTruePlatforms);

        setCampaignSelect(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');
        setSelectedCampaignCount(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');

    }, [selectedCampaignIds]);

    useEffect(() => {
        const countTruePlatforms = selectedAdSetIds.length;
        // //console.log("Número de plataformas selecionadas:", countTruePlatforms);

        setAdsSetSelect(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');
        setSelectedAdsSetCount(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');

    }, [selectedAdSetIds]);

    useEffect(() => {
        const countTruePlatforms = selectedAdsIds.length;
        // //console.log("Número de plataformas selecionadas:", countTruePlatforms);

        setAdsSelect(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');
        setSelectedAdsCount(countTruePlatforms > 0 ? countTruePlatforms.toString() : '');

    }, [selectedAdsIds]);

    useEffect(() => {
        trackEvent('pageview', {
            userId: user?.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            page: 'tracking'
        });
    }, [])

    // //console.log(dataMetricsToday?.data?.data)
    const metricsFacebook = useMemo(() => getWrongUtms(dataMetricsToday?.data?.data, 'facebook'), [dataMetricsToday?.data?.data, 'facebook']);
    const metricsGoogle = useMemo(() => getWrongUtms(dataMetricsToday?.data?.data, 'google'), [dataMetricsToday?.data?.data, 'google']);
    const metricsTiktok = useMemo(() => getWrongUtms(dataMetricsToday?.data?.data, 'tiktok'), [dataMetricsToday?.data?.data, 'tiktok']);
    const [showWarning, setShowWarning] = useState(false);
    const navigate = useNavigate();
    const today = format(new Date(), 'yyyy-MM-dd');

    useEffect(() => {
        setDateToday([today, today]);
    }, [today]);

    useEffect(() => {
        if (Object.keys(metricsFacebook).length !== 0 || Object.keys(metricsGoogle).length !== 0 || Object.keys(metricsTiktok).length !== 0) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [metricsFacebook, metricsGoogle]);

    const endOfPageRef = useRef<HTMLDivElement>(null);

    const handleScrolltoGraphic = () => {
        endOfPageRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (showGraphic) {
            handleScrolltoGraphic();
        }
    }, [showGraphic]);

    useEffect(() => {
        const completionDate = localStorage.getItem('completionDate');
        const today = new Date().toISOString().slice(0, 10);
        if (dataMetricsToday?.data?.data?.utm_metrics?.utm_metrics?.orders?.source?.google?.campaign?.sag_organic) {
            if (completionDate !== today) {
                setShowSagWarning(true);
            }
        }
    }, [dataMetricsToday]);

    const [filteredBySelect, setFilteredBySelect] = useState([]);
    const [filteredByAdSetsSelect, setFilteredByAdSetsSelect] = useState([]);
    const [filteredByAdSelect, setFilteredByAdSelect] = useState([]);

    const [disabledFilterCampaign, setDisabledFilterCampaign] = useState(true);
    const [disabledFilterAdsSet, setDisabledFilterAdsSet] = useState(true);
    const [disabledFilterAds, setDisabledFilterAds] = useState(true);

    useEffect(() => {
        setDisabledFilterCampaign(selectedCampaignIds.length === 0);
        setDisabledFilterAdsSet(selectedAdSetIds.length === 0);
        setDisabledFilterAds(selectedAdsIds.length === 0);
    }, [selectedCampaignIds, selectedAdSetIds, selectedAdsIds]);


    const handleFilterBySelection = (action: string) => {
        if (action === 'resetFilterCampaign') {
            setFilteredBySelect([]);
        } else if (action === 'filterCampaign') {
            const selectedCampaignIdsCollected = selectedCampaignIds.map(campaign => campaign.id);
            setFilteredBySelect(selectedCampaignIdsCollected);

        } else if (action === 'resetFilterAdsSet') {
            setFilteredByAdSetsSelect([]);
        } else if (action === 'filterAdsSet') {
            const selectedAdSetIdsCollected = selectedAdSetIds.map(adset => adset.id);
            setFilteredByAdSetsSelect(selectedAdSetIdsCollected);
        } else if (action === 'resetFilterAd') {
            setFilteredByAdSelect([]);
        } else if (action === 'filterAd') {
            const selectedAdsIdsCollected = selectedAdsIds.map(ad => ad.id);
            setFilteredByAdSelect(selectedAdsIdsCollected);
            // //console.log('filtered ads', selectedAdsIdsCollected)
        }
    }

    const [openTooltip, setOpenTooltip] = useState(true);
    const [hoverOpenTooltip, setHoverOpenTooltip] = useState(false);

    useEffect(() => {
        let timer;
        if (!isLoading) {
            timer = setTimeout(() => {
                setOpenTooltip(false);
            }, 4000); // 30 seconds delay
        }

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [isLoading]);

    const handleTooltipClose = () => {
        setHoverOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setHoverOpenTooltip(true);
    };


    const handleMouseEnter = () => {
        setOpenTooltip(false); // Close the tooltip immediately when mouse enters
        setHoverOpenTooltip(false);
    };

    const [filtredChannel, setFiltredChannel] = useState('');
    const [filtredCampaign, setFiltredCampaign] = useState('');
    const [filtredAdsSet, setFiltredAdsSet] = useState('');
    const [filtredAd, setFiltredAd] = useState('');

    const handleSearchChange = (value) => {
        switch (selectedTab) {
            case 'channel':
                setFiltredChannel(value);
                break;
            case 'campaign':
                setFiltredCampaign(value);
                break;
            case 'adsSet':
                setFiltredAdsSet(value);
                break;
            case 'ad':
                setFiltredAd(value);
                break;
            default:
                break;
        }
    };

    return (
    <EditColumnsProvider>
        <SortingProvider>
            <S.Container onClick={handleClosePopup}>
            
                <S.Section>
                    <S.LeftContainer>
                        <div style={{ width: '100%', gap: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                            <S.ButtonsContainer>
                                <ButtonApp
                                    border=''
                                    width="16rem"
                                    height="1.5rem"
                                    borderRadius='50px'
                                    color="#1B626A"
                                    iconWidth='14px'
                                    background="#CDDDDF"
                                    icon={<HelpRoundedIcon style={{ width: '16px' }} />}
                                    onClick={() => window.open('https://youtu.be/qsvFFXQZbck?feature=shared', '_blank')}
                                    text="Como otimizar minhas campanhas"

                                />
                                <ButtonApp
                                    border=''
                                    width="14.5rem"
                                    height="1.5rem"
                                    borderRadius='50px'
                                    iconWidth='14px'
                                    color="#1B626A"
                                    background="#CDDDDF"
                                    icon={<HelpRoundedIcon style={{ width: '16px' }} />}
                                    onClick={() => { showArticle(9062698) }}
                                    text="Como configurar minhas UTMS"

                                />
                            </S.ButtonsContainer>

                            <Search
                                width="90%"
                                background='white'
                                border='none'
                                placeholder="Pesquisar pelo nome da campanha / conjunto / anúncio"
                                value={
                                    selectedTab === 'channel'
                                    ? filtredChannel
                                    : selectedTab === 'campaign'
                                    ? filtredCampaign
                                    : selectedTab === 'adsSet'
                                    ? filtredAdsSet
                                    : filtredAd
                                    }
                                setValue={handleSearchChange}
                                />

                        </div>
                    </S.LeftContainer>
                    <span>
                        <S.RightSection>
                            <Calendar date={date} setDate={setDate} dashboard={false} />
                        </S.RightSection>
                    </span>
                </S.Section>

                <S.Table>
                    {!isLoading && (
                        <>
                            <S.TabSection>
                                <S.TabChannel onClick={() => setSelectedTab('channel')} isSelected={selectedTab === 'channel'}>
                                    {' '}
                                    <span>
                                        <CategoryOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} />
                                        Canal
                                    </span>
                                    {channelSelect && (
                                        <S.SelectedOption>
                                            <CancelOutlinedIcon onClick={handleUnselectChannel} sx={{ width: '15px', height: '12px', color: 'white' }} />
                                            {typeof channelSelect === 'string' ? reduceString(channelSelect, 12) : channelSelect}
                                        </S.SelectedOption>
                                    )}

                                </S.TabChannel>
                                <S.TabCampaign onClick={() => setSelectedTab('campaign')} isSelected={selectedTab === 'campaign'}>
                                    {' '}
                                    <span>
                                        <FolderOpenOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} />
                                        Campanhas
                                    </span>
                                    {campaignSelect && (
                                        <S.SelectedOption>
                                            <CancelOutlinedIcon onClick={handleUnselectCampaign} sx={{ width: '15px', height: '12px', color: 'white' }} />
                                            {typeof campaignSelect === 'string' ? reduceString(campaignSelect, 12) : campaignSelect}
                                        </S.SelectedOption>
                                    )}
                                </S.TabCampaign>
                                <S.TabAdsSet onClick={() => {
                                    if (!onlyOthersPlatformsChecked) {
                                        setSelectedTab('adsSet');
                                    }
                                }} isSelected={selectedTab === 'adsSet'}

                                    style={{
                                        cursor: onlyOthersPlatformsChecked ? 'not-allowed' : 'pointer',
                                        opacity: onlyOthersPlatformsChecked ? 0.5 : 1
                                    }}
                                >
                                    {' '}
                                    <span>
                                        <InterestsOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} />
                                        Conjuntos de anúncios
                                    </span>
                                    {adsSetSelect && (
                                        <S.SelectedOption>
                                            <CancelOutlinedIcon onClick={handleUnselectAdsSet} sx={{ width: '15px', height: '12px', color: 'white' }} />
                                            {typeof adsSetSelect === 'string' ? reduceString(adsSetSelect, 12) : adsSetSelect}
                                        </S.SelectedOption>
                                    )}
                                </S.TabAdsSet>
                                <S.TabAd onClick={() => {
                                    if (!onlyOthersPlatformsChecked) {
                                        setSelectedTab('ad');
                                    }
                                }} isSelected={selectedTab === 'ad'}

                                    style={{
                                        cursor: onlyOthersPlatformsChecked ? 'not-allowed' : 'pointer',
                                        opacity: onlyOthersPlatformsChecked ? 0.5 : 1
                                    }}>
                                    <span>
                                        <VideoLibraryOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} />
                                        Anúncios
                                    </span>
                                    {adsSelect && (
                                        <S.SelectedOption>
                                            <CancelOutlinedIcon onClick={handleUnselectAds} sx={{ width: '15px', height: '12px', color: 'white' }} />
                                            {typeof adsSelect === 'string' ? reduceString(adsSelect, 12) : adsSelect}
                                        </S.SelectedOption>
                                    )}
                                </S.TabAd>
                            </S.TabSection>

                            <S.TableTopArea>

                                <S.ActionButtonsArea>
                                    <S.LeftActionButtons>
                                        {selectedTab === 'campaign' &&
                                            (
                                                <>
                                                    {filteredBySelect.length > 0 ? (
                                                        <S.SelectionFilterSection style={{background: '#e8e8e8'}}  disabled={false} onClick={() => handleFilterBySelection('resetFilterCampaign')}>
                                                            <DisabledByDefaultRoundedIcon style={{fill: '#E90E0E'}} />
                                                            <p>Limpar filtro de seleção</p>
                                                            <span>{filteredBySelect.length}</span>
                                                        </S.SelectionFilterSection>
                                                    ) : (
                                                        <S.SelectionFilterSection disabled={disabledFilterCampaign} onClick={() => handleFilterBySelection('filterCampaign')}>
                                                            <FactCheckRoundedIcon />
                                                            <p>Filtrar por seleção</p>
                                                        </S.SelectionFilterSection>
                                                    )}
                                                </>
                                            )
                                        }
                                        {selectedTab === 'adsSet' &&
                                            (
                                                <>
                                                    {filteredByAdSetsSelect.length > 0 ? (
                                                        <S.SelectionFilterSection style={{background: '#e8e8e8'}} disabled={false} onClick={() => handleFilterBySelection('resetFilterAdsSet')}>
                                                            <DisabledByDefaultRoundedIcon style={{fill: '#E90E0E'}} />
                                                            <p>Limpar filtro de seleção</p>
                                                            <span>{filteredByAdSetsSelect.length}</span>
                                                        </S.SelectionFilterSection>
                                                    ) : (
                                                        <S.SelectionFilterSection disabled={disabledFilterAdsSet} onClick={() => handleFilterBySelection('filterAdsSet')}>
                                                            <FactCheckRoundedIcon />
                                                            <p>Filtrar por seleção</p>
                                                        </S.SelectionFilterSection>
                                                    )}
                                                </>
                                            )
                                        }
                                        {selectedTab === 'ad' &&
                                            (
                                                <>
                                                    {filteredByAdSelect.length > 0 ? (
                                                        <S.SelectionFilterSection style={{background: '#e8e8e8'}} disabled={false} onClick={() => handleFilterBySelection('resetFilterAd')}>
                                                            <DisabledByDefaultRoundedIcon style={{fill: '#E90E0E'}} />
                                                            <p>Limpar filtro de seleção</p>
                                                            <span>{filteredByAdSelect.length}</span>
                                                        </S.SelectionFilterSection>
                                                    ) : (
                                                        <S.SelectionFilterSection disabled={disabledFilterAds} onClick={() => handleFilterBySelection('filterAd')}>
                                                            <FactCheckRoundedIcon />
                                                            <p>Filtrar por seleção</p>
                                                        </S.SelectionFilterSection>
                                                    )}
                                                </>
                                            )
                                        }


                                        <S.SectionWarnings>
                                            {showWarning && showSagWarning ? (
                                                <Tooltip
                                                    onMouseEnter={handleMouseEnter}
                                                    open={openTooltip || hoverOpenTooltip}
                                                    onClose={handleTooltipClose}
                                                    onOpen={handleTooltipOpen}
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: 'white',
                                                                borderColor: '#E90E0E',
                                                                fontWeight: '550',
                                                                fontSize: '14px',
                                                                fontFamily: 'Montserrat',
                                                                borderRadius: '50px',
                                                                border: 1,
                                                                textWrap: 'nowrap',
                                                                padding: '8px 16px',
                                                                zIndex: '0',
                                                                width: 'fit-content',
                                                                color: '#E90E0E',
                                                                maxWidth: '35rem', // Adiciona um maxWidth para controlar o tamanho máximo

                                                            }
                                                        },
                                                    }}
                                                    title={'Existem múltiplas pendências, incluindo UTMs e configurações do Merchant.'} placement="right">
                                                    <S.UtmLoaded onClick={() => navigate('/utm_config#combined')}>
                                                        <S.Circle />
                                                        <WarningRoundedIcon />
                                                        {/* <span>Existem múltiplas pendências, incluindo UTMs e configurações do Merchant</span> */}
                                                    </S.UtmLoaded>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    {showWarning && (
                                                        <Tooltip
                                                            onMouseEnter={handleMouseEnter}
                                                            open={openTooltip || hoverOpenTooltip}
                                                            onClose={handleTooltipClose}
                                                            onOpen={handleTooltipOpen}
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: 'white',
                                                                        borderColor: '#E90E0E',
                                                                        fontWeight: '550',
                                                                        fontSize: '14px',
                                                                        fontFamily: 'Montserrat',
                                                                        borderRadius: '50px',
                                                                        border: 1,
                                                                        textWrap: 'nowrap',
                                                                        padding: '8px 16px',
                                                                        zIndex: '0',
                                                                        width: 'fit-content',
                                                                        color: '#E90E0E',
                                                                        maxWidth: '35rem', // Adiciona um maxWidth para controlar o tamanho máximo

                                                                    }
                                                                },
                                                            }}
                                                            title={'Você possui anúncios sem UTMs configuradas no dia de hoje.'} placement="right">
                                                            <S.UtmLoaded onClick={() => navigate('/utm_config')}>
                                                                <S.Circle />

                                                                <WarningRoundedIcon />
                                                                {/* <span>Você possui anúncios sem UTMs configuradas no dia de hoje.</span> */}
                                                            </S.UtmLoaded>
                                                        </Tooltip>
                                                    )}

                                                    {showSagWarning && (
                                                        <Tooltip
                                                            onMouseEnter={handleMouseEnter}
                                                            open={openTooltip || hoverOpenTooltip}
                                                            onClose={handleTooltipClose}
                                                            onOpen={handleTooltipOpen}
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: 'white',
                                                                        borderColor: '#E90E0E',
                                                                        fontWeight: '550',
                                                                        fontSize: '14px',
                                                                        fontFamily: 'Montserrat',
                                                                        borderRadius: '50px',
                                                                        border: 1,
                                                                        textWrap: 'nowrap',
                                                                        padding: '8px 16px',
                                                                        zIndex: '0',
                                                                        width: 'fit-content',
                                                                        color: '#E90E0E',
                                                                        maxWidth: '35rem', // Adiciona um maxWidth para controlar o tamanho máximo

                                                                    }
                                                                },
                                                            }}
                                                            title={'Configuração incompleta do Merchant, verifique as configurações.'} placement="right">
                                                            <S.UtmLoaded onClick={() => navigate('/utm_config#Google')}>
                                                                <S.Circle />
                                                                <WarningRoundedIcon />
                                                                {/* <span>Configuração incompleta do Merchant, verifique as configurações.</span> */}

                                                            </S.UtmLoaded>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </S.SectionWarnings>
                                    </S.LeftActionButtons>

                                    <S.RightActionButtons>
                                        <SelectAttribution showSelectAttribution={showSelectAttribution} setShowSelectAttribution={setShowSelectAttribution} setColumnAttribution={setColumnAttribution} columnAttribution={columnAttribution} />
                                        <SelectSolomon showSelectSolomon={showSelectSolomon} setShowSelectSolomon={setShowSelectSolomon} setColumnSolomon={setColumnSolomon} columnSolomon={columnSolomon} />
                                        <PaymentMethodSelector setPaymentMethod={setPaymentMethod} />
                                        {(shopifyIntegratedAccounts <= 1 && kiwifyIntegratedAccounts > 1) && (
                                            <ProductSelector products={products} selectedProducts={selectedProducts} tempSelectedProducts={tempSelectedProducts} update={update} setProductIds={setProductIds} setSelectedProducts={setSelectedProducts} setTempSelectedProducts={setTempSelectedProducts} setUpdate={setUpdate} />
                                        )}
                                        <Tooltip title="Editar colunas" placement="top" componentsProps={{
                                            tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '50px', padding: '5px 10px', color: 'white', textWrap: 'nowrap', zIndex: '9999' } },
                                        }}>
                                            <S.EditColumnArea onClick={() => handleOpenEditColumnModal()}>
                                                <ViewWeekRoundedIcon />
                                            </S.EditColumnArea>
                                        </Tooltip>
                                    </S.RightActionButtons>
                                </S.ActionButtonsArea>
                            </S.TableTopArea>
                        </>
                    )}

                    {isLoading && (
                        <>
                            <S.TabSection>
                                <S.TabChannelLoading isSelected={selectedTab === 'channel'}>
                                    {' '}
                                    <span>
                                        {' '}
                                        <CategoryOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} /> Canal{' '}
                                    </span>
                                </S.TabChannelLoading>
                                <S.TabCampaignLoading isSelected={selectedTab === 'campaign'}>
                                    {' '}
                                    <span>
                                        {' '}
                                        <FolderOpenOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} /> Campanhas{' '}
                                    </span>
                                </S.TabCampaignLoading>
                                <S.TabAdsSetLoading isSelected={selectedTab === 'adsSet'}>
                                    {' '}
                                    <span>
                                        <InterestsOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} />
                                        Conjuntos de anúncios{' '}
                                    </span>
                                </S.TabAdsSetLoading>
                                <S.TabAdLoading isSelected={selectedTab === 'ad'}>
                                    {' '}
                                    <span>
                                        <VideoLibraryOutlinedIcon sx={{ width: '15.9px', height: '15.9px' }} /> Anúncios{' '}
                                    </span>{' '}
                                </S.TabAdLoading>
                            </S.TabSection>

                            <S.TableTopArea>

                                <S.ActionButtonsArea style={{ opacity: 0.5, cursor: 'not-allowed', pointerEvents: 'none', position: 'relative', right: '0', width: '100%' }}>
                                    <S.LeftActionButtons>

                                    </S.LeftActionButtons>

                                    <S.RightActionButtons>
                                        <SelectAttribution showSelectAttribution={showSelectAttribution} setShowSelectAttribution={setShowSelectAttribution} setColumnAttribution={setColumnAttribution} columnAttribution={columnAttribution} />
                                        <SelectSolomon showSelectSolomon={showSelectSolomon} setShowSelectSolomon={setShowSelectSolomon} setColumnSolomon={setColumnSolomon} columnSolomon={columnSolomon} />
                                        <PaymentMethodSelector setPaymentMethod={setPaymentMethod} />
                                        {(shopifyIntegratedAccounts <= 1 && kiwifyIntegratedAccounts > 1) && (
                                            <ProductSelector products={products} selectedProducts={selectedProducts} tempSelectedProducts={tempSelectedProducts} update={update} setProductIds={() => {}} setSelectedProducts={() => {}} setTempSelectedProducts={() => {}} setUpdate={() => {}}/>
                                        )}
                                        <S.EditColumnArea>
                                            <ViewWeekRoundedIcon />
                                        </S.EditColumnArea>

                                    </S.RightActionButtons>
                                </S.ActionButtonsArea>
                            </S.TableTopArea>
                        </>
                    )}

                    {!isLoading && selectedTab === 'channel' && (
                        <TableTrackingChannel
                            filtred={filtredChannel}
                            dataMetrics={dataMetrics?.data?.data}
                            handleSelectGraphicData={handleSelectGraphicData}
                            handleScrolltoGraphic={handleScrolltoGraphic}
                            columnSolomon={columnSolomon}
                            paymentMethod={paymentMethod}
                            openSalesModal={handleOpenSalesModal}
                            date={date}
                            isCatalogChannel={isCatalogChannel}
                            loadingCatalog={isLoadingCatalogList}
                        />
                    )}
                    {!isLoading && selectedTab === 'campaign' && (
                        <TableTrackingCampaign
                            filteredBySelect={filteredBySelect}
                            filtred={filtredCampaign}
                            dataMetrics={dataMetrics?.data?.data}
                            dataCatalogList={dataCatalogList?.data?.data?.campaign_ids}
                            handleSelectGraphicData={handleSelectGraphicData}
                            handleScrolltoGraphic={handleScrolltoGraphic}
                            columnSolomon={columnSolomon}
                            paymentMethod={paymentMethod}
                            type={typeSelect}
                            channelSelect={typeSelect}
                            accountId={accountId}
                            openSalesModal={handleOpenSalesModal}
                            date={date}
                            otherSource={otherType}
                            loadingCatalog={isLoadingCatalogList}
                        />
                    )}
                    {!isLoading && selectedTab === 'adsSet' && (
                        <TableTrackingAdsSet
                            filteredBySelect={filteredByAdSetsSelect}
                            filtred={filtredAdsSet}
                            dataMetrics={dataMetrics?.data?.data}
                            dataCatalogList={dataCatalogList?.data?.data?.adset_ids}
                            handleSelectGraphicData={handleSelectGraphicData}
                            handleScrolltoGraphic={handleScrolltoGraphic}
                            columnSolomon={columnSolomon}
                            paymentMethod={paymentMethod}
                            campaignSelect={campaignSelect} // Sempre passa campaignSelect
                            campaignId={campaignId}         // Sempre passa campaignId
                            type={typeSelect}
                            openSalesModal={handleOpenSalesModal}
                            date={date}
                            loadingCatalog={isLoadingCatalogList}

                        />
                    )}

                    {!isLoading && selectedTab === 'ad' && (
                        <TableTrackingAds
                            filteredBySelect={filteredByAdSelect}
                            filtred={filtredAd}
                            dataMetrics={dataMetrics?.data?.data}
                            dataCatalogList={dataCatalogList?.data?.data?.ad_ids}
                            handleSelectGraphicData={handleSelectGraphicData}
                            handleScrolltoGraphic={handleScrolltoGraphic}
                            columnSolomon={columnSolomon}
                            paymentMethod={paymentMethod}
                            adsSetId={adsSetId}
                            type={typeSelect}
                            openSalesModal={handleOpenSalesModal}
                            date={date}
                            loadingCatalog={isLoadingCatalogList}

                        />
                    )}
                    {isLoading && <TableTrackingLoading />}
                </S.Table>

                {showGraphic && <Graphic ref={endOfPageRef} setShowGraphic={setShowGraphic} graphicData={graphicData} />}
               
                {openSalesModal && 
                // <Modal onClose={() => setOpenSalesModal(false)}>

                <S.Backdrop onClick={() => setOpenSalesModal(false)}>
                    <S.Modal onClick={(e) => e.stopPropagation()}>
                        <SalesModal 
                            handleOpenEditColumn={handleOpenEditColumnModal}
                            setColumnSolomon={setColumnSolomon} 
                            columnSolomon={columnSolomon} 
                            salesInfo={salesInfo} 
                            metricsBySource={metricsBySource}  
                            ordersSolomon={ordersSolomonSales} 
                            revenueSolomon={revenueSolomonSales} 
                            source={source} 
                            date={date} 
                            onClose={() => setOpenSalesModal(false)} 
                            isCatalogCampaign={isCatalogCampaign} 
                            isCatalogChannel={isSalesCatalogChannel}
                        />
                    </S.Modal>
                </S.Backdrop>
                }

                {openEditColumnModal && <Modal onClose={() => setOpenEditColumnModal(false)}>
                    <EditColumnModal onClose={() => setOpenEditColumnModal(false)} />
                </Modal>}
            </S.Container>
        </SortingProvider>
    </EditColumnsProvider>
    );
}
