import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 99%;
`;

export const Column = styled.div`
    font-size: 0.8125rem;
    font-weight: 550;
    line-height: 1.21875rem;
    color: var(--black);
    width: 7.125rem;
    height: 2.78125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.2rem;
    margin-right: -0.2rem;
    img {
        width: 14px;
        height: 14px;
        margin-right: 0.25rem;
    }
`;

export const MainColumn = styled.div`
    font-size: 0.8125rem;
    font-weight: 550;
    color: var(--black);
    width: 7.125rem;
    height: 2.78125rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0.5rem;
    margin-right: -0.5rem;
    img {
        width: 14px;
        height: 14px;
        margin-right: 0.75rem;
    }

    p {
        font-size: 0.8125rem;
        fon-weight: 550;
    }

    svg{
        width: 0.875rem;
        height: 0.875rem;
        fill: #E90E0E;
        margin-left: 0.25rem;
    }
`;

export const Bar = styled.div`
    width: 6.0536875rem;
    height: 10px;
    border-radius: 50px;
    background-color: #e0e0e0;
    margin: auto 0;
    position: relative;
`;

type BarValueProps = {
    percentage: string;
    color?: string;
};

function getWidth(percentage: string) {
    const newPercentage = Number(percentage) / 100;
    const newWidth = 6.0536875 * newPercentage;
    return `width: ${newWidth}rem;`;
}

export const BarValue = styled.div<BarValueProps>`
    ${(props) => getWidth(props.percentage)}
    height: 10px;
    border-radius: 50px;
    ${(props) => props.color && `background-color: ${props.color}`};
    ${(props) => !props.color && 'background: linear-gradient( 94deg, #26322d -2.89%, #607376 88.32%)'};
    margin: auto 0;
    position: absolute;
`;
