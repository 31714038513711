import React, { createContext, useContext, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const SortingContext = createContext({
    typeFiltred: 'status',
    setTypeFiltred: (value: string) => {},
    isStatusAsc: true,
    setIsStatusAsc: (value: boolean) => {},
}); 

export const SortingProvider = ({ children }) => {
    const { currentCompany } = useAuth();
    const [typeFiltred, setTypeFiltred] = useState(() => {
        const savedType = localStorage.getItem(`typeFiltred_${currentCompany}`);
        return savedType ? savedType : 'status';
    });

    const [isStatusAsc, setIsStatusAsc] = useState(() => {
        const savedStatusOrder = localStorage.getItem(`isStatusAsc_${currentCompany}`);
        return savedStatusOrder !== null ? JSON.parse(savedStatusOrder) : true;
    });

    useEffect(() => {
        localStorage.setItem(`typeFiltred_${currentCompany}`, typeFiltred);
    }, [typeFiltred]);

    useEffect(() => {
        localStorage.setItem(`isStatusAsc_${currentCompany}`, JSON.stringify(isStatusAsc));
    }, [isStatusAsc]);

    return (
        <SortingContext.Provider value={{ typeFiltred, setTypeFiltred, isStatusAsc, setIsStatusAsc }}>
            {children}
        </SortingContext.Provider>
    );
};

export const useSorting = () => useContext(SortingContext);
