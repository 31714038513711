import styled from 'styled-components';

export const Container = styled.div<{isMobile?: boolean}>`
    display: flex;
    margin: 0;
    width: 100%;
    overflow-y: auto;
    flex-direction: ${props => props.isMobile ? 'column' : 'unset'};

    @media (max-width: 1050px) {
        height: 100vh;
    }

`;

export const LeftColumnSignup = styled.div<{isMobile?: boolean}>`
    background-image: ${props => props.isMobile ? 'none !important' : 'url(https://storage.googleapis.com/static-images-source/login-bg-3.png)'};
    background-size: cover;
    height: ${props => props.isMobile ? 'auto' : 'calc(100vh - 42px - 1rem)'} ;
    width: ${props => props.isMobile ? '' : '50%'};
    // border:${props => props.isMobile ? 'none': '1px solid var(--green)'} ;
    border-radius: 32px;
    justify-content: start !important;
    padding: 1rem;
    margin: 14px;
    display: ${props => props.isMobile ? 'none' : 'flex'} ;
    background-position: 50% 30%;
    flex-direction: column;
    gap: 2rem;
    
`;
export const LeftColumn = styled.div<{isMobile?: boolean}>`
    background-image: ${props => props.isMobile ? 'none !important' : 'url(https://storage.googleapis.com/static-images-source/login-bg-3.png)'};
    background-size: cover;
    height: calc(100vh - 42px - 1rem);
    width: ${props => props.isMobile ? '' : '50%'};
    // border:${props => props.isMobile ? 'none': '1px solid var(--green)'} ;
    border-radius: 32px;
    padding: 1rem;
    margin: 14px;
    display: ${props => props.isMobile ? 'none' : 'flex'};
    background-position: 50% 30%;
    flex-direction: column;
    gap: 2rem;
    
`;

export const MainTitle = styled.div<{isMobile?: boolean}>`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: center;
    flex-direction: column;
    padding: ${props => props.isMobile ? '0' : ' 0.5rem 0 1rem 0'};

    img {
        width: 5rem;
        margin-bottom: 0.5rem;
    }
`

export const Title = styled.div<{isMobile?: boolean}>`
    font-size:${props => props.isMobile ? '1.26rem' : ' 2.7rem'};
    text-align: center;
    width: 100%;
    // text-wrap: nowrap;
    background: #1A3732;
    background: linear-gradient(to right, #1A3732 2%, #19C27E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    font-weight: 550;
    color: #1e1e1e;
    // margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div<{isMobile?: boolean}>`
    font-size: ${props => props.isMobile ? '0.8rem' : '1.3rem'};
    margin-top: 0.5rem;
    // line-height: 1.25rem;
    color: #5d7375;
    max-width: 100%;
    text-align: ${props => props.isMobile ? 'center' : 'center'};
`;

export const SubtitleSection = styled.div`
    // padding-bottom: 2.5rem;
    width: 90%;
`;

export const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    width: 100%;
`;

export const Card = styled.div`
    padding: 0.1rem 1rem;
    border: 1px solid var(--black);
    border-radius: 50px;
    font-size: 0.9rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    svg {
        fill: var(--black);
        width: 1rem;
    }
`

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    // max-height: 620px;


    @media (max-width: 1050px) {
        width: 100%;
    }
`;


export const BulletList = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 1rem;
    margin-top: 3rem;

`

export const BulletItem = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: #265b49  ;
    svg {
        fill: var(--green);
        width: 1.2rem;
    }
`

export const BulletLastItem = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.1rem;
    width: 80%;
    background: #19C27E;
    background: linear-gradient(to right, #1A3732 28%, #19c27e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 1.3rem;
    font-weight: 550;
    color: var(--black);
    text-decoration: underline;
    svg {
        fill: var(--green);
        width: 1.2rem;
    }
`

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    img {
        width: 100%;
    }
`
export const Image = styled.div`
    width: 100%;
`