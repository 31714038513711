import * as S from './style'
import { LineTableEditManualProductProps } from '../../../../@types'
import useAuth from '../../../../hooks/useAuth';
import { useDeleteCost } from '../../../../services/hooks/costHooks';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useEffect, useState } from 'react';
import { updateCost } from '../../../../services/api/api-cost';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';

export function LineTableEditManualProduct({cost_id,  date, name, value, currentCostArray, setCurrentCostArray}: LineTableEditManualProductProps)  {
    const { currentCompany } = useAuth();
    const { mutate: deleteCost } = useDeleteCost();
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);
    const { isVisible } = useVisibility();
    const { currencySymbol } = useCurrencyType();


    // Atualiza editedValue sempre que a prop value mudar
    useEffect(() => {
        setEditedValue(value);
    }, [value]);


    //console.log(cost_id, date, name, value, editedValue);

    function handleDeleteCost() {

        deleteCost({
            company_id: currentCompany,
            additional_cost_id: cost_id ?? '',
            frequency: 'specific',
        });
        setCurrentCostArray(currentCostArray.filter((cost:any) => cost.cost_id !== cost_id));
    }


    function handleSaveEdit() {
        const isoDate = new Date(date).toISOString().split('T')[0]; // Converte a data para o formato ISO
        updateCost({
            name: name,
            value: editedValue,
            date: isoDate,
            frequency: 'specific',
            type: 'product',
            company_id: currentCompany,
            additional_cost_id: cost_id ?? '',
            status: 'active',
        });
        setIsEditing(false);
        setCurrentCostArray(currentCostArray.map(cost => 
            cost.cost_id === cost_id ? { ...cost, value: editedValue } : cost
        ));
    }

    function formatDate(dateString: string) {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    }

    return(
        <S.Container>
            <S.Column>{isVisible ? name : '* * * * * * * *'}</S.Column>
            <S.Column>{formatDate(date)}</S.Column>
            <S.Column>
                {isEditing ? (
                    <S.InputContainer>
                        <input 
                            value={editedValue} 
                            onChange={(e) => {
                                const newValue = e.target.value;
                                // Se newValue não for vazio, tenta converter para float. Caso contrário, define como 0 ou mantém como string vazia.
                                setEditedValue(newValue ? parseFloat(newValue) : 0);
                            }} 
                        />
                    <S.Button>
                        <CheckRoundedIcon onClick={handleSaveEdit} />
                        </S.Button>
                    </S.InputContainer>
                ) : (
                    <>
                        {currencySymbol}{' '}
                        {editedValue.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        <EditIcon onClick={() => setIsEditing(true)} />
                    </>
                )}
        </S.Column>
            <S.Column>
                
                <DeleteIcon onClick={() => {handleDeleteCost()}}/>
                
                </S.Column>
        </S.Container>
    )
}

function DeleteIcon({ onClick }: { onClick: () => void }) {
    return(
        <S.Button onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
        </S.Button>
    )
}

function EditIcon({ onClick }: { onClick: () => void } ){
    return (
        <S.Button onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
        </S.Button>
    );
}
