import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(0deg, #FFFFFF 15.74%, #A5E3CB 698.7%);
`;


export const ButtonContainer = styled.div`
margin-top: 2rem;
`

export const ContainerImage = styled.div`
img{
  width: 9.375rem;
}
`

const floating = keyframes`
0%, 100% {
    transform: translateY(0px);
}
50% {
    transform: translateY(-10px);
}
`

const bounce = keyframes`
0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
}
10% {
    transform: translateY(-30px);
}
30% {
    transform: translateY(-15px);
}
`

export const ContainerInformation = styled.div`
display: flex;
flex-direction: column;
align-items: center;
position: relative;
>svg{
  animation: ${bounce} 4s, ${floating} 3s ease-in-out infinite 2s;
  width: 5.125rem;
  height: 5.125rem;
  fill: #19C27E;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}
`

export const StarIconUp = styled.span`
position: absolute;
left: 0;
top: 0;
svg{
  width: 5.125rem;
  height: 5.125rem;
  fill: #19C27E;
  animation: ${floating} 3s ease-in-out infinite;
}
`

export const Fireworks = styled.span`
width: 10rem;
height: auto;
position: absolute;
`

export const StarIconDown = styled.span`
position: absolute;
right: 0;
bottom: -2rem;
svg{
  width: 3.5rem;
  height: 3.5rem;
  fill: #19C27E;
  animation: ${floating} 3s ease-in-out infinite;
}
`

export const Title = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: 1.75rem;
font-weight: 600;
line-height: 1.2rem;
margin-bottom: 2rem;
span{
    color: #19C27E;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.2rem;
    margin-bottom: 0.8rem;
}
`

export const Subtitle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
color: #989C97;
font-size: 1.125rem;
line-height: 1.5rem;
font-weight: 400;
`