import styled from "styled-components";

type ContainerProps = {
    type: string;
}

export const Ballon = styled.div`
position: absolute;
top: -1rem;
left: 0;
right: 0;
margin: auto;
background: linear-gradient(to right, #11744c, #19c27e);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
width: max-content;
padding: 0 1rem;
height: 2rem;
border-radius: 15px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-weight: 500;
`

export const Container = styled.div<ContainerProps>`
position: relative;
width: 21.875rem;
height: 38.25rem;
border-radius: 15px;
border: 2px solid ${props => props.type === 'trimestral' ? 'var(--green);': '#d4d4d4;'};
background-color:#f8f8f8;
flex-direction: column;
align-items: center;
justify-content: flex-start;
transition: transform 0.3s;
&:hover{
    transform: translateY(-5px);
    
}
`

export const Title = styled.div`
text-align: center;
font-size: 1.5rem;
font-weight: 500;
color: #9b9b9b;
margin-top: 3rem;
`

export const Price = styled.div`
font-size: 1.5rem;
font-weight: 500;
text-align: center;
color: #747474;
span{
    font-size: 3.5rem;
    font-weight: 550;
    color: #0e1819;
}
`

export const Item = styled.div`
margin-left: 1.7rem;
>svg{
    width: 0.5rem;
    height: 0.5rem;
    fill: #19c27e;
    margin-right: 0.5rem;
}
`

export const Button = styled.button`
width: calc(100% - 4rem);
margin-top: 4rem;
margin-left: 2rem;
height: 2.5rem;
border-radius: 15px;
background: linear-gradient(to right, #11744c, #19c27e);
transition: ease-in-out 250ms all;
color: #fff;
font-size: 1.2rem;
font-weight: 500;
cursor: pointer;
&:hover{
    transform: translateY(3px);
    transition: 0.3s;
}
`

export const PriceDescount = styled.div`
margin-left: 2rem;
margin-top: 1rem;
margin-bottom: -1rem;
font-size: 1rem;
font-weight: 600;
>svg{
    fill: var(--green);
    width: 1rem;
    margin-top: 0.3rem;
    height: 1rem;
    margin-right: 0.5rem;
    }
span{
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.5rem;
}
`

type DescriptionProps = {
    type: string;
}

export const Description = styled.div<DescriptionProps>`
font-size: 1rem;
font-weight: 500;
line-height: 1.59375rem;
color: #0e1819;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin-left: 2rem;
margin-right: 0.5rem;
gap: 0.5rem;
${props => props.type === 'mensal' ? 'margin-top: 3rem;' : 'margin-top: 1.5rem;'}


span{
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    >svg{
    fill: var(--green);
    width: 1rem;
    margin-top: 0.3rem;
    height: 1rem;
}
}
`

