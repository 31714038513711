import { api } from '../api-confg';
const BASE_URL = import.meta.env.VITE_API_URL;

export type AddressData = {
    bairro: string,
    cep: string,
    complemento: string,
    ddd: string,
    gia: string,
    ibge: string,
    localidade: string,
    logradouro: string,
    siafi: string,
    uf: string
}

export async function getIpAddress() {
    const data = await api.get(`${BASE_URL}/search-ip`)
    return data
}

export async function getAddressInfo(zip: string) {
    try {
        const response = await api.get(`${BASE_URL}/search-zipcode?zipcode=${zip}`);
        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error fetching address:', error);
        return { success: false, message: 'Failed to fetch address', error };
    }
}