import { TemplateModal } from '../index';
import ShareIcon from '@mui/icons-material/Share';
import * as S from './style';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import { useEffect, useState } from 'react';
import {  ModalManualCheckoutProps } from '../../../../@types';
import { useSubmitOnboardingManualCheckout } from '../../../../services/hooks/manualCheckoutsHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateAdooreiSave, storageOnboardingIntegrateUnicoPagSave, storageOnboardingIntegrateShopifyCheckoutSave } from '../../../../storage/storageOnboarding';
import useAuth from '../../../../hooks/useAuth';
import { formatPercentageInput } from '../../../../utils';

const infoDict = {
    "Adoorei": { url: 'https://www.adoorei.com.br/img/logo.dfb5eb16.svg', color: '#ff0080', integrateFunction:() => storageOnboardingIntegrateAdooreiSave() },
    "UnicoPag": { url: 'https://storage.googleapis.com/static-images-source/unicopag%20icon.png', color: '#b60001', integrateFunction:() => storageOnboardingIntegrateUnicoPagSave() },
    "Shopify Checkout": { url: 'https://storage.googleapis.com/static-images-source/shopify%20checkout.svg', color: '#19c27e', integrateFunction:() => storageOnboardingIntegrateShopifyCheckoutSave() },
}

export function ModalManualCheckout({ setShowPopup, setIntegrate, platform }: ModalManualCheckoutProps) {
    const urlLogo = infoDict?.[platform]?.url;
    const baseColor = infoDict?.[platform]?.color;

    const { mutate: SubmitManualCheckout, isLoading } = useSubmitOnboardingManualCheckout();
    const { user } = useAuth();

    let currencySymbol = 'R$';


    useEffect(() => {
        const storedInfo = localStorage.getItem(`${platform}CheckoutInfo_${storageOnboardingCurrentCompanyGet()}`);
        if (storedInfo) {
            setInfo(JSON.parse(storedInfo));
        }
    }, []);

    const [info, setInfo] = useState({
        flatTax: `${currencySymbol} 0,00`,
        percentualTax: '0,00',
        checkout: platform,
    });

    function handleIntegrate() {
        const body = {
            company_id: storageOnboardingCurrentCompanyGet(),
            checkout: platform,
            flatTax: formatStringToNumber(info.flatTax),
            percentualTax: formatStringToNumber(info.percentualTax),
            user: user,
            onboarding: true,
        };

        localStorage.setItem(`${platform}CheckoutInfo_${storageOnboardingCurrentCompanyGet()}`, JSON.stringify(info));

        infoDict?.[platform]?.integrateFunction();
        setShowPopup(false);

        SubmitManualCheckout(body, {
            onSuccess: () => {
            },
            onError: (error) => {
                console.error(error);
            }
        });
    }

    function formatStringToNumber(text: string) {
        text = text.replace(/\$|R\$|€|%/g, '');
        text = text.replace(/R\$/g, '').replace(/ {2}/g, '').replace(/%/g, '');
        text = text.replace(/\./g, '');
        text = text.replace(/,/g, '.');
        return Number(text);
    }
    
    function formatValue(valueInput: string) {
        let newValue = valueInput?.replace(/\D/g, '');
        newValue = newValue?.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue?.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return `${currencySymbol} ` + newValue;
    }

    function handleChangeInfo(e: any) {
        setInfo((prev: any) => {
            let value = e.target.value;
            if (e.target.id.includes('flatTax')) {
                value = formatValue(value);
            } else if (e.target.id.includes('percentualTax')) {
                value = formatPercentageInput(e);
            }
            return {
                ...prev,
                [e.target.id]: value,
            };
        });
    }

    const [alreadyConnected, setAlreadyConnected] = useState(false);

    const manualCheckouts = user?.companies?.[storageOnboardingCurrentCompanyGet()]?.manual_inputs?.manual_checkouts?.checkouts; 

    useEffect(() => {
        const connectedCheckout = manualCheckouts?.find((checkout: any) => checkout.checkout);
        console.log('connectedCheckout', connectedCheckout?.checkout, manualCheckouts);
        const alreadyConnected = connectedCheckout?.checkout === undefined ? false : true;

    setAlreadyConnected(alreadyConnected);

    }, [user, manualCheckouts]);

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={platform}
                title={platform}
                subtitle={'Integre sua loja com a ' + platform + ' e automatize seu processo de dropshipping.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
                <InputIntegration value={info.flatTax} setValue={handleChangeInfo} inputName="Taxa fixa mensal:" inputId="flatTax" placeholder="0,00" />
                <InputIntegration
                    value={info.percentualTax}
                    setValue={handleChangeInfo}
                    inputName="Taxa percentual:"
                    inputId="percentualTax"
                    placeholder="0,00"
                    percentage={true}
                />                
            </S.InnerContainer>

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    isLoading={isLoading}
                    disabled={alreadyConnected}
                    text="Integrar"
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
