import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 29.6875rem;
    height: 14.5rem;
    border-radius: 12px;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const Button = styled.button`
    font-size: 0.625rem;
    line-height: 0.9375rem;
    color: #989c97;
    border: 1px solid #cdcdcd;
    width: 4.82375rem;
    height: 1.25rem;
    border-radius: 50px;
    position: absolute;
    top: 0.8rem;
    right: 0.9rem;
    background-color: var(--white);
    cursor: pointer;
    &:hover {
        border: 1px solid var(--green);
    }
`;

export const InnerContainer = styled.div`
    margin-top: 3.5rem;
    height: 9.6875rem;
    width: 28.6875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    overflow-y: auto;
`;
