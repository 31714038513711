import styled from 'styled-components';
import seta from '../../../../assets/images/seta.png';

export const Container = styled.div`
    padding: 1rem;
`;

export const ErrorContainer = styled.div`
    width: 33.375rem;
    height: 3.625rem;
    border-radius: 7px;
    border: 1px solid #eb3333;
    display: flex;
    margin: 0 1rem 1rem;
    svg {
        margin: auto 1rem;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.75rem;
    color: #c03737;
    font-weight: 500;
    line-height: 1.125rem;
    margin: auto 0;
    span {
        font-weight: 550;
    }
`;

export const InnerContainer = styled.div`
    margin-top: 2rem;
    border: 1px solid var(--gray-120);
    border-radius: 10px;
    width: 100%;
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0.2rem;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
        margin-left: 0.8rem;
    }

    input {
        height: 2.5rem;
        width: 30.7rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);
        &:focus,
        &:hover {
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4);
            background-color: #f9f9f9;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            border: var(--green); */
            border-bottom: 1px solid var(--green);
        }
    }

    select {
        height: 2.5rem;
        width: 13rem;
        padding: 0 1rem;
        border-radius: 8px;
        margin-left: 0.8rem;
        margin-bottom: 1rem;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(${seta}) no-repeat center right #fff;
        background-size: 1rem;
        background-position: 11rem 0.75rem;

        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            outline: none;
            border: 1px solid var(--green);
            /* outline: none; */
            /* border-color: rgba(76, 234, 134, 0.4); */
            /* background-color: #fff; */
            /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
            /* border: 1px solid var(--green); */
        }
    }
`;

export const VisibilityButtonDomain = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 2.8rem;
    right: 2rem;
    cursor: pointer;
`;

export const VisibilityButtonToken = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 7.7rem;
    right: 2rem;
    cursor: pointer;
`;
export const InfoContainer = styled.div`
    font-weight: 500;
    font-size: 0.75rem;
    cursor: pointer;
    line-height: 1.125rem;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: fit-content;
    margin-left: 0.8rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--gray-700);
`;

export const DisconnectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--gray-800);
    margin: 1rem 1rem 0;
`;
