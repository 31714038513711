import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    padding: 0 1rem 0;
`;

export const DefaultColumn = styled.div`
font-size: 0.75rem;
font-weight: 600;
line-height: 1.125rem;
align-items: center;
justify-content: center;
height: 4.5rem;
border-radius: 5px;
display: flex;
flex: 1;
text-align: center;
color: var(--black);
svg {
    margin-left: 0.5rem;
    cursor: pointer;
}
    
    &:last-child {
        border-right: none;
    }

    img {
        margin-top: 0.2rem;
        width: 0.75rem;
        height: 0.75rem;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        font-size: 0.7rem;
        font-weight: 700;
        color: var(--black);
    }
`;

