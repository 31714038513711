import { usePostHog } from 'posthog-js/react';
import { useState, useEffect } from 'react';
import * as S from './style.ts';

interface CaptureData {
  $survey_id: any;
  [key: string]: any;  // Allows dynamic keys
}

interface Responses {
  [key: string]: string;
}

export default function Survey({ surveyName, onSubmit }: { surveyName: string, onSubmit: () => void }) {
  const posthog = usePostHog();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState<Responses>({});
  const [openChoice, setOpenChoice] = useState<Responses>({});

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        posthog.getActiveMatchingSurveys((surveys) => {
          //console.log('getActiveMatchingSurveys result:', surveys);

          if (Array.isArray(surveys)) {
            if (surveys.length > 0) {
              //console.log('Surveys array is not empty:', surveys);
              const firstSurvey = surveys.filter(survey => survey.name === surveyName)[0];
              //console.log('First survey filtered:', firstSurvey);

              if (firstSurvey) {
                setSurvey(firstSurvey);
                posthog.capture('survey shown', {
                  $survey_id: firstSurvey.id,
                  $survey_name: firstSurvey.name,
                });
              } else {
                //console.log('No survey of type "api" found.');
              }
            } else {
              //console.log('Surveys array is empty.');
            }
          } else {
            //console.log('No surveys returned or surveys is not an array.');
          }
        });
      } catch (error) {
        console.error('Error fetching surveys:', error);
      }
    };

    fetchSurveys();
  }, [posthog]);

  const handleSelect = (index) => (event) => {
    const value = event.target.value;
    setResponses(prev => ({ ...prev, [`response${index + 1}`]: value }));
  };

  const handleChoiceSelect = (index, choice) => {
    setResponses(prev => ({ ...prev, [`response${index + 1}`]: choice }));
  };

  const handleOpenChoiceInput = (index) => (event) => {
    const value = event.target.value;
    setOpenChoice(prev => ({ ...prev, [`response${index + 1}`]: value }));
  };

  const handleSubmit = () => {
    onSubmit();
    if (survey) {
      const captureData: CaptureData = {
        $survey_id: survey.id,
      };

      survey.questions.forEach((_, idx) => {
        const responseKey = `response${idx + 1}`;
        const responseValue = responses[responseKey] === 'Outro' ? openChoice[responseKey] : responses[responseKey] || '';

        if (idx === 0) {
          captureData.$survey_response = responseValue;
        } else {
          captureData[`$survey_response_${idx}`] = responseValue;
        }
      });

      //console.log('Submitting responses to PostHog:', captureData);
      posthog.capture('survey sent', captureData);
      setResponses({});
      setOpenChoice({});
    }
  };

  const allQuestionsAnswered = () => {
    return survey?.questions.every((question, index) => {
      const response = responses[`response${index + 1}`];
      if (!response) {
        return false;
      }
      if (response === 'Outro' && !openChoice[`response${index + 1}`]) {
        return false;
      }
      return true;
    }) || false;
  };

  return (
    <S.Container>
      {survey && (
        <div>
          {survey.questions.map((question, index) => (
            <div key={index}>
              <h4>{question.question}</h4>
              {question.type === 'open' && (
                <S.TextArea
                  value={responses[`response${index + 1}`] || ''}
                  onChange={handleSelect(index)}
                ></S.TextArea>
              )}
              {question.type === 'single_choice' && (
                <div>
                  {question.choices.map((choice, idx) => (
                    <S.ChoiceContainer
                      key={idx}
                      selected={responses[`response${index + 1}`] === choice}
                      onClick={() => handleChoiceSelect(index, choice)}
                    >
                      <input
                        type="radio"
                        value={choice}
                        checked={responses[`response${index + 1}`] === choice}
                        onChange={() => handleChoiceSelect(index, choice)}
                        style={{ display: 'none' }}
                      />
                      {choice}
                    </S.ChoiceContainer>
                  ))}
                  {question.hasOpenChoice && responses[`response${index + 1}`] === 'Outro' && (
                    <S.TextArea
                      placeholder="Outro"
                      value={openChoice[`response${index + 1}`] || ''}
                      onChange={handleOpenChoiceInput(index)}
                    ></S.TextArea>
                  )}
                </div>
              )}
              {question.type === 'rating' && (
                <S.RatingContainer>
                  {Array.from({ length: question.scale }, (_, i) => i + 1).map((rating) => (
                    <S.RatingChoice
                      key={rating}
                      selected={responses[`response${index + 1}`] == rating.toString()}
                      onClick={() => handleChoiceSelect(index, rating.toString())}
                    >
                      {rating}
                    </S.RatingChoice>
                  ))}
                </S.RatingContainer>
              )}
            </div>
          ))}
          <br />
          <S.Button onClick={handleSubmit} disabled={!allQuestionsAnswered()}>Submit</S.Button>
        </div>
      )}
    </S.Container>
  );
}
