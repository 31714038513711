import * as S from './style';

import { TemplateModalProps } from '../../../@types';

export function TemplateModal({ urlLogo, baseColor, type, title, subtitle }: TemplateModalProps) {
    return (
        <>
            <S.Logo type={type}>
                <img src={urlLogo} alt={`Logo do ${type}`} style={type === "Mercado Pago" ? {width: '1.5rem', height: '1.1rem'} : {}} />
            </S.Logo>
            <S.Title baseColor={baseColor}>
                Autenticar <span>{title}</span>
            </S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
        </>
    );
}
