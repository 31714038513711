import { InputProps } from '../../@types';
import * as S from './style';

export function InputApp({ inputId, inputType, inputName, width, value, required = false, disabled = false }: InputProps) {
    return (
        <S.Container width={width}>
            <label htmlFor={inputId}>{inputName}</label>
            <input id={inputId} name={inputId} type={inputType} value={value} autoComplete="off" required={required} disabled={disabled} />
        </S.Container>
    );
}
