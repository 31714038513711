import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 50px; 
    ::-webkit-scrollbar {
        width: 8px;
    }
    h3 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 400;
    }
`;

export const TextF = styled.span`
    color: #3572b7;
    font-weight: 550;
`;

export const TextG = styled.span`
    color: red;
    font-weight: 550;
`;

export const TextT = styled.span`
    color: black;
    font-weight: 550;
`;

export const ContainerTable = styled.div`
    height: 100%;
    width: 98%;
    max-height: 490px;
    overflow-y: scroll;
    overflow-x: hidden; 
`;

export const FacebookTable = styled.div`
    width: 100%; 
`;

export const Lines = styled.div`

    > div:nth-child(odd) {
        background-color: #ffffff; !important;
    }

    > div:nth-child(even) {
        background-color: #f8f8f8; !important;
    }
`;

export const TableHeader = styled.div`
    display: flex;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    background-color: var(--gray-150);
    border-bottom: 1px solid var(--gray-150);
    position: sticky;
    top: 0;
    z-index: 20;
`;

export const DefaultColumnF = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 20%;
    &:last-child {
        border-right: none;
    }
`;

export const DefaultColumnG = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 25%;
    &:last-child {
        border-right: none;
    }
`;