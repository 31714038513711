
import { api } from '../api-confg';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getShopifyAuthorizationUrl(shop: string, reauth?: boolean) {
    // Corrigido para usar & para adicionar o segundo parâmetro na URL
    const url = `${BASE_URL}/shopify/auth?shop=${shop}${reauth ? '&reauth=true' : ''}`;
    const response = await api.get(url);
    return response.data.data; // Assumindo que a URL está em response.data.url
}

