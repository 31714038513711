// GraphModal.tsx
import React from 'react';
import Modal from '../../../../../components/Modal/index.tsx';
import * as CS from '../../../../CostOperation/style.ts'; 
import * as S from './style.ts';
import { useState } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import { useUpdateProductMarketingCost } from '../../../../../services/hooks/productsHooks.ts';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {ButtonApp} from '../../../../../components/ButtonApp';
import useAlert from '../../../../../hooks/useAlert';


interface MarketingModalProps {
    onClose: () => void;
    product_id: string;
    setShowManualCost: (value: boolean) => void;
    setCostOperationDate: (value: any) => void;
}

const MarketingCostModal : React.FC<MarketingModalProps> = ({ onClose, product_id, setShowManualCost, setCostOperationDate }) => {


    const { mutate: updateProductMarketingCostMutate } = useUpdateProductMarketingCost();
    const { showAlert } = useAlert();
    const {currencySymbol} = useCurrencyType();
    const { currentCompany } = useAuth();
    const [costOperation, setCostOperation] = useState({
        date: '',
        frequency: 'monthly',
        name: '',
        type: 'marketing',
        value: '',
    });

    function formatValue(value: string) {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
        newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
        newValue = `${currencySymbol} ` + newValue;
        setCostOperation({ ...costOperation, value: newValue });
    }
    function formatToNumber(value: string) {
        value = value.replace(`${currencySymbol}`, '');
        value = value.replace('.', '');
        value = value.replace(',', '.');
        return parseFloat(value);
    }

    function handleSaveCost() {
        if (setCostOperationDate) {
            setCostOperationDate((prevState: any) => {
                return {
                    ...prevState!,
                    value: formatToNumber(costOperation.value),
                    frequency: costOperation.frequency,
                    date: costOperation.date,
                    product_id: product_id,
                };
            });
        }

        try {
            if (costOperation.date !== '' && costOperation.name !== '' && costOperation.value !== '') {
                updateProductMarketingCostMutate({
                    company_id: currentCompany, date: costOperation.date, status: 'active', frequency: costOperation.frequency, name: costOperation.name, product_id: product_id , type: costOperation.type, value: formatToNumber(costOperation.value) 
                });
                //console.log('Custo atualizado com sucesso!');
                showAlert('Custo adicionado com sucesso! Por favor, aguarde enquanto atualizamos os dados...', 'loading' , 30000, true);
            }
            setShowManualCost(false);
        } catch (error) {
            console.error('Erro ao atualizar custos:', error);
            setShowManualCost(false);
        }
    }


    return (
        <Modal onClose={onClose}>
            <S.ModalContent>
                <S.MarketingImage
                    src="https://storage.googleapis.com/static-images-source/management-cost.png"
                    alt="Shopify"
                />
                <S.MarketingTitle>
                    Custo manual de <S.MarketingHighlight>Marketing</S.MarketingHighlight> 
                    <S.MarketingParagraph>Configure o custo de marketing do produto selecionado.</S.MarketingParagraph>
                </S.MarketingTitle>
                <br />
                <S.MarketingLine/>

                <CS.InputContainer>
                    <label>Nome</label>
                    <input type="text" value={costOperation.name} onChange={(e) => setCostOperation({ ...costOperation, name: e.target.value })} placeholder="Aluguel do espaço" />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Tipo de Custo</label>
                    <input
                        type="text"
                        readOnly
                        value="Marketing" // Exibe o valor "Marketing"
                        style={{ backgroundColor: '#e0e0e0', color: 'black' }} // Estiliza com fundo cinza e texto preto
                    />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Valor</label>
                    <input value={costOperation.value} placeholder={`${currencySymbol} 10,00`} type="text" onChange={(e) => formatValue(e.target.value)} />
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Frequência</label>
                    <select value={costOperation.frequency} onChange={(e) => setCostOperation({ ...costOperation, frequency: e.target.value })}>
                        <option value="monthly">Mensal</option>
                        <option value="weekly">Semanal</option>
                        <option value="daily">Diário</option>
                        <option value="specific">Pontual</option>
                    </select>
                </CS.InputContainer>
                <CS.InputContainer>
                    <label>Data</label>
                    <input type="date" value={costOperation.date} onChange={(e) => setCostOperation({ ...costOperation, date: e.target.value })} />
                </CS.InputContainer>
                <CS.ButtonContainer style={{marginTop: 24}}>
                <ButtonApp 
                border=''
                width="10rem"
                height="3rem"
                color="#fff"
                background="#19c380"
                icon={<AddCircleRoundedIcon style={{ fontSize: 20, marginRight: 10 }}/> }
                text="Adicionar custo"
                isLoading={false}
                onClick={handleSaveCost}/>
                </CS.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default MarketingCostModal;
