import styled from 'styled-components';





// Dentro do seu arquivo de estilos do Styled Components
export const ChartModal = styled.div`
    background-color: #fff;
    margin: 0 auto;
    padding: 20px;
    max-width: 80%;
    width: 100%;
    height: 455px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const FlexHeaderModal = styled.div`
    /* Adicione os estilos necessários aqui */
`;

export const MainChartContainer = styled.div`
width: 100%;
max-width: 50%;
    height: 460px;
    margin-top: -50px;
`;

export const HistoryTableContainer = styled.div`
    width: 100%;
    height: 386px;
    margin-left: -18px;
    overflow: auto;
    border-right: 1px solid var(--gray-100);
`;

export const ChartTitle = styled.h1`
margin-bottom: 30px;
color: #081b16;
font-size: 18px;
font-weight: 550;
`;

export const ChartSubTitle = styled.p`
    color: #8d8d8d;
    font-size: 13px;
    /* Outros estilos necessários */
`;

export const HistoryContent = styled.div`
  display: flex; 
  
  flex-direction: row;
`;