import styled, { css } from 'styled-components';

type ContainerProps = {
    isBottomOption?: boolean;
    showSideBar: boolean;
    isThisPage?: boolean;
    hideOnSmallScreen?: boolean;
};

type OptionsProps = {
    showSideBar: boolean;
};

type RotateProps = {
    rotate: boolean;
};

export const Container = styled.div<ContainerProps>`
    height: calc(100vh - 3.5rem);
    background-color: #fff;
    border-right: 1px solid var(--gray-140);
    position: relative;
    ${(props) =>
        props.showSideBar
            ? `
    width: 13.125rem;
    transition: width 0.3s;
    `
            : `
    width: 4.125rem;
    transition: width 0.3s;
    `}
`;

export const Button = styled.button`
    border-radius: 50%;
    border: 1px solid var(--gray-140);
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        transform: rotate(45deg);
    }
    position: absolute;
    right: -1rem;
    top: 0.5rem;
    &:hover {
        border: 1px solid var(--green);
    }
`;

export const InnerContainer = styled.div`
    margin-left: 1.5rem;
`;

export const TopContainer = styled.div`
    padding-top: 1.5rem;
`;

export const BottomContainer = styled.div`
    position: absolute;
    bottom: 1rem;
`;

export const Text = styled.div`
    position: absolute;
    border: 1px solid var(--gray-200);
    width: max-content;
    display: none;
    padding: 0.2rem 0.5rem;
    background-color: var(--white);
    bottom: 0;
    border-radius: 4px;
    left: 2rem;
    z-index: 3;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--black);
    svg {
        font-size: 0.85rem;
        fill: black;
    }

`;

export const SpecialText = styled.div`
    position: absolute;
    border: 1px solid var(--gray-200);
    width: max-content;
    display: none;
    padding: 0.2rem 0.5rem;
    background-color: var(--white);
    bottom: 0;
    border-radius: 4px;
    left: 2rem;
    z-index: 3;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--black);
    svg {
        font-size: 0.85rem;
        fill: black;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3rem;
        justify-content: center;
    }

    span {
        color: var(--green);
        font-weight: 700;
        margin-bottom: -0.2rem;
        font-size: 0.5rem;
        
    }
`;

export const Option = styled.div<ContainerProps>`
    img {
        width: 19px;
        height: 22px;
    }
    padding-left: 0.2rem;
    width: 10.5rem;
    height: 2rem;
    margin-top: 0.4rem;
    font-size: 0.8rem;
    z-index: 50;
    line-height: 1.21875rem;
    color: var(--black);
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    cursor: pointer;
    position: relative;
    transition: transform 250ms;
            transition:all ease-in-out 150ms;

    svg{
        width: 22px;
        height: 22px;
    
    }

    ${(props) =>
        props.isBottomOption &&
        `
        font-size: 0.7rem;
        height: 1.7rem;

        background-color: transparent;
        &:hover {
            background-color: transparent !important;
            color: var(--green-900);
            transition:all ease-in-out 250ms;

        }
        svg {
            width: 19px;}
    `}

    ${(props) =>
        props.isThisPage &&
        `
        background-color: var(--gray-140);
        border-radius: 0.25rem;
        cursor: pointer;
    
    `}

    ${(props) =>
        props.showSideBar
            ? `
        &:hover{
            background-color: var(--gray-140);
            border-radius: 0.25rem;
        }`
            : `
        width: 22px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        &:hover{
        background-color: var(--gray-140);
        border-radius: 0.25rem;
        cursor: pointer;
        
    ${Text}{
        display: block;
    }
}

`}
    &:hover {
        transform: translateX(3px);
        transition: transform 250ms;
    }

    ${(props) => props.hideOnSmallScreen && `
        @media (max-width: 1250px) {
            display: none;
        }
    `}
`;

type OptionProps = {
    showOption: boolean;
    showSideBar: boolean;
    isThisPage?: boolean;
};

export const OptionOpen = styled.div<OptionProps>`
    img {
        width: 22px;
        height: 22px;
    }
    padding-left: 0.2rem;
    width: 10.5rem;
    height: 2rem;
    margin: 0.5rem 0;
    font-size: 0.75rem;
    color: gray;
    z-index: 50;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    cursor: pointer;
    position: relative;
    transition: all 250ms;
    svg{
        width: 20px;
        height: 22px;

    }

    ${(props) =>
        props.showOption
            ? `

    visibility: visible;
    transition: all 250ms;
    height: 2rem;
    margin-bottom: -0.1rem;
    `
            : `

    opacity: 0;
    visibility: hidden;
    height: 0;
    margin-top: -0.7rem;
    `}

    ${(props) =>
        props.isThisPage &&
        `
        background-color: var(--gray-140);
        border-radius: 0.25rem;
        cursor: pointer;
    
    `}

    ${(props) =>
        props.showSideBar
            ? `
        &:hover{
            background-color: var(--gray-140);
            border-radius: 0.25rem;
        }`
            : `
        width: 22px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        &:hover{
        background-color: var(--gray-140);
        border-radius: 0.25rem;
        cursor: pointer;
    ${SpecialText}{
        display: block;
    }
}
`}
    &:hover {
        transform: translateX(3px);
        transition: transform 0.3s;
    }
`;

export const OptionSolomon = styled(Option)<OptionsProps>`
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: ${(props) => (props.showSideBar ? 'flex' : 'none')};
    ${(props) =>
        props.showSideBar
            ? `

    align-items: center;
    justify-content: flex-start;
    

    &:hover{
        background: linear-gradient(225deg, rgba(25,194,126,0.17130602240896353) 0%, rgba(255,255,255,1) 58%);
        border-radius: 5px;
        transition: transform 0.2s ease-in-out;}

    `
            : `

    width: 22px;
    padding-left: 0.1rem;
    padding-right: 0.1rem;

    &:hover{
        background: linear-gradient(225deg, rgba(25,194,126,0.17130602240896353) 0%, rgba(255,255,255,1) 58%);
        border-radius: 5px;
        transition: transform 0.2s ease-in-out;
    }

`}
`;

export const TextOption = styled.div<RotateProps>`
    position: flex;
    width: 70%;
    display: flex;
    padding: 0.3rem 0.2rem;
    bottom: 0.5rem;
    left: 2rem;
    z-index: 3;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--black);
    justify-content: space-between;
    svg {
        font-size: 0.85rem;
        fill: gray;
        ${(props) =>
            props.rotate &&
            css`
                transform: rotateX(180deg);
            `}
    }
`;

export const OptionProduct = styled(Option)<OptionsProps>`
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
        padding-left: 0.1rem;
    display: ${(props) => (props.showSideBar ? 'flex' : 'none')};

    ${(props) =>
        props.showSideBar
            ? `
        &:hover{
            background-color: var(--gray-140);
            border-radius: 0.25rem;
        }`
            : `
        width: 22px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        &:hover{
        background-color: var(--gray-140);
        border-radius: 0.25rem;
        cursor: pointer;
    ${SpecialText}{
        display: block;
    }
}
`}
    &:hover {
        transform: translateX(3px);
        transition: transform 0.3s;
    }
`;

