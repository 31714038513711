import styled from 'styled-components';

export const Container = styled.div``;

export const InnerContainer = styled.div<{ grayBackground?: boolean }>`
    height: calc(100vh - 3.5rem);
    display: flex;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${props => props.grayBackground ? '#f6f6f6' : 'white'};

    //  ::-webkit-scrollbar {
    // width: 8px; /* Altura da barra de rolagem */
    // }

     ::-webkit-scrollbar {
        width:  ${props => props.grayBackground ? '' : '5px'};
        height: ${props => props.grayBackground ? '' : '10px'};
    }
`;

type ContentProps = {
    hasPayment: boolean;
    isMobile: boolean;
}

type MobileProps = {
    isMobile: boolean;
}


export const Content = styled.div<ContentProps>`
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
    overflow-x: ${props => props.isMobile ? 'hidden' : 'auto'};
    width: 100%;
    ${props => !props.hasPayment && 'margin-top: 4.5rem;'}
`;

export const Sidebar = styled.div<MobileProps>`
    display: ${props => props.isMobile ? 'none' : ''};
`;


export const ButtonContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 1.3rem;
`

export const ModalContainer = styled.div`
width: 35.5rem;
min-height: 21.375rem;
height: auto;
border-radius: 20px;
padding: 1rem;
`

export const ModalIcon = styled.div`
svg{
    width: 2rem;
    height: 2rem;
    fill: #e13131;
}
`

export const Accounts = styled.div`
margin-top: 0.25rem;
margin-bottom: -0.5rem;
`

export const Account = styled.div`
font-size: 1rem;
font-weight: 500;
line-height: 1.2rem;
color: #992a2a;
display: flex;
flex-direction: column;
justify-content: center;
gap: 0.1rem;
align-items: flex-start;
span{
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.75rem;
    color: #727272;
    margin-bottom: 0.2rem;
}
`

export const TopModal = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const GoBackModal = styled.div`
font-size: 0.8125rem;
display: flex;
align-items: center;
gap: 0.5rem;
cursor: pointer;
font-weight: 500;
line-height: 1.21875rem;
color: #9e9ea0;
svg{
    width: 1.125rem;
    height: 1.125rem;
    fill: #e13131;
}
`

export const TitleModal = styled.div`
font-size: 1.5rem;
font-weight: 600;
line-height: 1.8rem;
color: #1e1e1e;
margin-top: 1rem;
`

export const SubTitleModal = styled.div`
font-size: 0.8125rem;
font-weight: 500;
line-height: 1.21875rem;
color: #9e9ea0;
margin-top: 1rem;
`

export const AccountsModal = styled.div`
font-size: 0.75rem;
line-height: 1.125rem;
color: #727272;
margin-top: 1.5rem;
`

export const HelpModal = styled.div`
font-size: 0.8125rem;
font-weight: 500;
line-height: 1.21875rem;
color: #0c1612;
margin-top: 1.5rem;
span{
    color: var(--green);
    text-decoration: underline;
}
`

export const LineModal = styled.div`
width: calc(100% - 1rem);
margin: 1rem auto 0;
background-color: rgba(0, 0, 0, 0.1);
height: 1px;
`

export const ButtonModal = styled.div`

`