import { useState, useEffect, useMemo, useRef } from 'react';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useAuth from '../../hooks/useAuth.tsx';
import { useShopifySubscriptionData, useDeleteSubscriptionShopify } from '../../services/hooks/shopifySubscriptionHooks.ts';
import { trackEvent, showSurvey } from '../../services/segment/sendEvent.ts'
import * as S from './style.ts';
import Modal from '../../components/Modal/index.tsx'; // Ajuste o caminho de importação conforme necessário
import {ButtonApp} from '../../components/ButtonApp/index.tsx'; // Ajuste o caminho de importação conforme necessário
import  SpinnerLoading from '../../components/SpinnerLoading/index.tsx';
import {  formatValue } from '../../utils/index.tsx';
import { getCheckoutURL } from '../../services/api/api-integration.ts';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { TableTransactions } from './TableOrders';
import { useOrders } from '../../services/hooks/ordersHooks';
import { differenceInDays, format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useGetExtraTransactions, useGetSubscription, useGetTransactions } from '../../services/hooks/subscriptionHooks.ts';
import { getMetricsTrackingOrdersByData } from './utils/index.tsx';
import { useTrackingMetrics } from '../../services/hooks/trackingHooks.ts';
import { useDeleteSubscription, useRefundSubscription } from '../../services/hooks/subscriptionHooks.ts';
import Skeleton from '@mui/material/Skeleton';
import { useGetLimitOrders } from '../../services/hooks/subscriptionHooks.ts';
import useAlert from '../../hooks/useAlert.tsx';
import { usePostHog } from 'posthog-js/react';
import Survey from './Survey/index.tsx';

export function Subscription() {
  const [activeTab, setActiveTab] = useState('assinatura');  // Pode ser 'assinatura' ou 'historico'
  const showAssinatura = () => setActiveTab('assinatura');
  const showHistorico = () => setActiveTab('historico');
  const navigate = useNavigate();
  const { user, currentCompany, updateUser } = useAuth();
  const [showWarning, setShowWarning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSurvey, setShowSurvey] = useState(true);
  const { showAlert } = useAlert();

  const today = format(new Date(), 'yyyy-MM-dd');
  const dateCreated = user?.companies[currentCompany]?.billing?.dateCreated;

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowSurvey(true);
  };

  const onSubmit = () => {
    setShowSurvey(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowSurvey(true);
  };

  const deleteSubscription = useDeleteSubscription(currentCompany);

  const handleDeleteSubscription = () => {
    setIsDeleting(true);
    deleteSubscription.mutate(null, {
      onSuccess: () => {
        //console.log('Assinatura cancelada');
        setIsDeleting(false);
        handleCloseModal();
        showAlert('Sua assinatura foi cancelada', 'success', 5000, true);
        updateUser();
      },
      onError: (error) => {
        setIsDeleting(false);
        console.error('Erro ao cancelar assinatura', error);
        showAlert('Erro ao cancelar assinatura, entre em contato com o suporte', 'error', 5000, true);
      },
    });
  };

  const refundSubscription = useRefundSubscription(currentCompany);

  const handleRefundSubscription = () => {
    setIsDeleting(true);
    refundSubscription.mutate(null, {
      onSuccess: () => {
        //console.log('Assinatura cancelada');
        setIsDeleting(false);
        handleCloseModal();
        showAlert('Seu reembolso está sendo processado e sua assinatura foi cancelada', 'success', 5000, true);
        updateUser();
      },
      onError: (error) => {
        setIsDeleting(false);
        console.error('Erro ao cancelar assinatura', error);
        showAlert('Erro ao cancelar assinatura, entre em contato com o suporte', 'error', 5000, true);
      },
    });
  };

  const handleRefundOrDeleteSubscription = () => {
    if (dateCreated) {
      const parsedToday = parseISO(today);
      const parsedDateCreated = parseISO(dateCreated);
      const diffDays = differenceInDays(parsedToday, parsedDateCreated);

      if (diffDays > 7) {
        handleDeleteSubscription();
      } else {
        handleRefundSubscription();
      }
    } else {
      //console.log('Data de criação não disponível');
      handleDeleteSubscription();
    }
  };

  function formatDate(dateString: string) {
    if (!dateString) return 'xxxx/xx/xx';
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yyyy');
  }
  
  const formatLimitOrders = (limitOrders) => {
    return limitOrders === 0 ? "ILIMITADO" : limitOrders;
  };

  const { data: limitOrdersRootData, refetch: refetchLimitOrdersData } = useGetLimitOrders(currentCompany);

  const startDate = limitOrdersRootData?.data.start_date;
  const endDate = limitOrdersRootData?.data.end_date;
  
  const limitOrders = formatLimitOrders(limitOrdersRootData?.data?.limit_orders);

  const { data: subscriptionRootData } = useGetSubscription(currentCompany);

  const { data: transactionsRootData } = useGetTransactions(currentCompany);
  const transactionsData = transactionsRootData?.data?.data?.data;

  const { data: extraTransactionsRootData } = useGetExtraTransactions(currentCompany);
  const extraTransactionsData = extraTransactionsRootData?.data?.data?.data;

  const transactionsFiltered = [
    ...(transactionsData?.map(transaction => ({
      id: transaction.invoiceNumber,
      date: transaction.originalDueDate,
      value: transaction.value,
      status: transaction.status,
      isExtra: false
    })) || []),
    ...(extraTransactionsData?.map(transaction => ({
      id: transaction.invoiceNumber,
      date: transaction.originalDueDate,
      value: transaction.value,
      status: transaction.status,
      isExtra: true
    })) || [])
  ];

  const subscriptionData = subscriptionRootData?.data?.data;

  const [approvedOrders, setApprovedOrders] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const { isLoading, data: dataMetrics } = useOrders(startDate, endDate, currentCompany);

  useEffect(() => {
    const nestedData = dataMetrics?.data?.data; // Acessa a array aninhada
    if (Array.isArray(nestedData)) {
      const filteredApprovedOrders: any = nestedData.filter(order => order && order.status_name === "approved");
      setApprovedOrders(filteredApprovedOrders.length);
    } else {
      setApprovedOrders(0);
    }
  }, [dataMetrics, isLoading]);
    
  const totalOrders = limitOrders;

    useEffect(() => {

      if (totalOrders === 'ILIMITADO') {
        setProgressPercentage(100); // Se há approvedOrders, considere 100%, caso contrário, 0%
      } else {
        const calculateProgressPercentage = (approvedOrders / limitOrders) * 100;
        setProgressPercentage(calculateProgressPercentage ?? 0);
      }

  }, [approvedOrders, totalOrders]);


  useEffect(() => {
    if (approvedOrders > totalOrders) {
        setShowWarning(true);
        // //console.log('approvedOrders', approvedOrders, totalOrders)
    } else {
      setShowWarning(false);
    }
  }, [approvedOrders, totalOrders, user]);


  function formatCycle(text: string) {
    if (!text) return 'mês';
    switch (text) {
    case 'MONTHLY':
      return 'mês';
    case 'ANNUAL':
      return 'ano';
    case 'QUARTERLY':
      return 'trimestre';
    case 'BIANNUAL':
      return 'semestre';
    }
  }

  function formatPlanDescription(text: string) {
    if (!text) return 'Plano não informado';
    text = text.split('|')[0];
    switch (text) {
    case 'Plano 1':
      return 'Plano Essencial';
    case 'Plano 2':
      return 'Plano Premium';
    case 'Plano 3':
      return 'Plano Avançado';
    case '':
      return 'Plano não informado';
    default:
      return 'Plano Avançado';
    }
  }

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function getCreditCardLogo(brand) {
    switch (brand?.toUpperCase()) {
      case 'MASTERCARD':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png';
      case 'VISA':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2000px-Visa_Inc._logo.svg.png';
      case 'AMERICAN EXPRESS':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/American_Express_logo_%282018%29.svg/1920px-American_Express_logo_%282018%29.svg.png';
      case 'DISCOVER':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Discover_Card_logo.svg/1920px-Discover_Card_logo.svg.png';
      default:
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Generic_Credit_Card_Logo.svg/2048px-Generic_Credit_Card_Logo.svg.png'; // Logo genérica
    }
  }
  
  const creditCardBrand = subscriptionData?.creditCard?.creditCardBrand;
  const creditCardNumber = subscriptionData?.creditCard?.creditCardNumber;

  return (
    <S.Container>
      <S.Header>
        <S.Title>Assinatura</S.Title>
        <S.Subtitle>Veja detalhes e gerencie sua assinatura.</S.Subtitle>

        <S.TabsContainer>
          <S.TabButton onClick={showAssinatura} active={activeTab === 'assinatura'}>
            Assinatura
          </S.TabButton>
          <S.TabButton onClick={showHistorico} active={activeTab === 'historico'}>
            Histórico de transações
          </S.TabButton>
        </S.TabsContainer>

        {activeTab === 'assinatura' && (
          <>
            <S.ContentArea>
              <S.TitleArea>
                <p>Plano atual</p>
                <span>Detalhes do plano de assinatura.</span>
              </S.TitleArea>
              <S.ActivePlanArea>
                <S.TopPlanArea>
                  {isLoading ? (
                    <Skeleton variant="rounded" width={200} height={38} />
                  ) : (
                    <S.PlanTitleArea>
                      <S.FlexTitle>
                        <p>{formatPlanDescription(subscriptionData?.description) || '0'}</p>
                        <CheckRoundedIcon />
                      </S.FlexTitle>
                    </S.PlanTitleArea>
                  )}
                  <S.PlanPriceArea>
                    {isLoading ? (
                      <Skeleton variant="rounded" width={240} height={60} />
                    ) : (
                      <>
                        <p>
                          <span style={{ color: '#999', fontSize: '1.1rem' }}>R$</span>
                          {formatValue(subscriptionData?.value) || '0,00'}
                        </p>
                        <span>/{formatCycle(subscriptionData?.cycle) || 'mês'}</span>
                      </>
                    )}
                  </S.PlanPriceArea>
                </S.TopPlanArea>
                <S.OrdersProgress>
                  {isLoading ? (
                    <Skeleton variant="rounded" width={310} height={28} />
                  ) : (
                    <S.OrdersTop>
                      {showWarning ? (
                        <>
                          <S.OrdersTitle style={{ borderColor: 'var(--red-500)' }}>
                            <p
                              style={{
                                color: 'var(--red-500)',
                                fontWeight: '550',
                                fontSize: '0.85rem',
                              }}
                            >
                              Número máximo de pedidos atingido, considere fazer um{' '}
                              <u
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    `/payment?company_id=${currentCompany}&update_plan=true&add_store=true`
                                  )
                                }
                              >
                                upgrade de plano
                              </u>
                              .
                            </p>
                          </S.OrdersTitle>
                          <S.OrdersCount>
                            <p>
                              {approvedOrders || '0'}
                              <span>/{totalOrders || '0'}</span>
                            </p>
                          </S.OrdersCount>
                        </>
                      ) : (
                        <>
                          <S.OrdersTitle>
                            <p>Pedidos aprovados na loja:</p>
                          </S.OrdersTitle>
                          <S.OrdersCount>
                            <p>
                              {approvedOrders}
                              <span>/{totalOrders}</span>
                            </p>
                          </S.OrdersCount>
                        </>
                      )}
                    </S.OrdersTop>
                  )}
                  <S.OrdersProgressBar>
                    <S.OrdersProgressBarFill showWarning={showWarning} percentage={progressPercentage} />
                  </S.OrdersProgressBar>
                </S.OrdersProgress>
                <S.Line />

                <S.BottomArea>
                  {user?.companies[currentCompany]?.billing?.manager === 'asaas' &&
                    (isLoading ? (
                      <Skeleton variant="rounded" width={200} height={16} />
                    ) : (
                      <>
                        <p>
                          Próxima cobrança: <span>{formatDate(endDate) || 'xxxx/xx/xx'}</span>
                        </p>
                      </>
                    ))}
                  {user?.companies[currentCompany]?.billing?.manager === 'guru' &&
                    (isLoading ? (
                      <Skeleton variant="rounded" width={200} height={16} />
                    ) : (
                      <>
                        <p>
                          Fim do plano: <span>{formatDate(endDate) || 'xxxx/xx/xx'}</span>
                        </p>
                      </>
                    ))}
                  {user?.companies[currentCompany]?.billing?.manager === 'asaas' &&
                    (isLoading ? (
                      <Skeleton variant="rounded" width={120} height={46} />
                    ) : (
                      <S.ButtonsArea>
                        <S.ButtonTextCancelamento
                          style={{ marginRight: '20px' }}
                          onClick={handleOpenModal}
                          isEditing={true}
                        >
                          Cancelar assinatura
                        </S.ButtonTextCancelamento>
                        <S.ButtonText
                          onClick={() =>
                            navigate(
                              `/payment?company_id=${currentCompany}&update_plan=true&add_store=true`
                            )
                          }
                          isEditing={true}
                        >
                          Alterar plano
                        </S.ButtonText>
                      </S.ButtonsArea>
                    ))}
                  {(user?.companies[currentCompany]?.billing?.manager === 'guru' && user?.companies[currentCompany]?.billing?.subscription !== '') &&
                    (isLoading ? (
                      <Skeleton variant="rounded" width={280} height={46} />
                    ) : (
                      <S.ButtonsArea>
                        <S.ButtonText
                          onClick={() =>
                            navigate(`/payment?company_id=${currentCompany}&context=renew&add_store=true`)
                          }
                          isEditing={true}
                        >
                          Reativar plano
                        </S.ButtonText>
                      </S.ButtonsArea>
                    ))}
                </S.BottomArea>
              </S.ActivePlanArea>
            </S.ContentArea>

            <S.ContentArea>
              <S.TitleArea>
                <p>Método de pagamento</p>
                <span>Detalhes do seu método de pagamento.</span>
              </S.TitleArea>
              <S.PaymentMethodArea>
                {isLoading ? (
                  <Skeleton variant="rounded" width={80} height={64} />
                ) : (
                  <S.PaymentMethodIcon>
                    <img
                      src={getCreditCardLogo(creditCardBrand)}
                      alt={creditCardBrand || 'Credit Card'}
                    />
                  </S.PaymentMethodIcon>
                )}
                {isLoading ? (
                  <Skeleton variant="rounded" width={250} height={32} />
                ) : (
                  <S.PaymentMethodInfo>
                    <S.PaymentInfoText>
                      {capitalizeFirstLetter(creditCardBrand) || 'Cartão'} final em{' '}
                      {creditCardNumber || 'XXXX'}
                    </S.PaymentInfoText>
                    <S.PaymentMethodInner>
                      <p></p>
                      <span>PADRÃO</span>
                    </S.PaymentMethodInner>
                  </S.PaymentMethodInfo>
                )}
                <S.ButtonText
                  style={{
                    backgroundColor: 'transparent',
                    color: '#19c27e',
                    opacity: '0',
                    pointerEvents: 'none',
                  }}
                  isEditing={true}
                >
                  Alterar forma de pagamento
                </S.ButtonText>
              </S.PaymentMethodArea>
            </S.ContentArea>
          </>
        )}
        {activeTab === 'historico' && (
          <>
            <S.ContentArea>
              <S.TransactionsInner>
                {user?.companies[currentCompany]?.billing?.manager === 'asaas' &&
                  (isLoading ? (
                    <Skeleton variant="rounded" width={250} height={36} />
                  ) : (
                    <S.MainText>
                      <p>
                        Próxima cobrança: <span>{formatDate(endDate) || 'xxxx/xx/xx'}</span>
                      </p>
                      <span>Veja seus históricos de cobranças</span>
                    </S.MainText>
                  ))}
                {user?.companies[currentCompany]?.billing?.manager === 'guru' &&
                  (isLoading ? (
                    <Skeleton variant="rounded" width={250} height={36} />
                  ) : (
                    <S.MainText>
                      <p>
                        Fim do Plano: <span>{formatDate(endDate) || 'xxxx/xx/xx'}</span>
                      </p>
                      <span>Veja seus históricos de cobranças</span>
                    </S.MainText>
                  ))}
                <S.Table>
                  <TableTransactions transactionsFiltered={transactionsFiltered} isLoading={isLoading} />
                </S.Table>
              </S.TransactionsInner>
            </S.ContentArea>
          </>
        )}
      </S.Header>

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
        <S.ModalContent>
            <h2>Cancelar assinatura</h2>
            {showSurvey && (
              <>
                <p>Por favor, responda a pesquisa antes de cancelar sua assinatura.</p>
                <Survey surveyName='cancelamento' onSubmit={onSubmit} />
              </>
            )}
            {!showSurvey && (
              <>
                <p>Obrigado por responder a pesquisa, tem certeza que quer cancelar?</p>
                <ButtonApp
                  border=''
                  borderRadius='10px'
                  text="Sim, cancelar assinatura"
                  width="10rem"
                  height="2.5rem"
                  background='#bf1f1f'
                  icon=""
                  color="white"
                  onClick={handleRefundOrDeleteSubscription}
                  isLoading={isDeleting}
                  disabled={showSurvey}
                />
              </>
            )}
            <S.ButtonText style={{background: 'transparent', color: '#999', display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '0.5rem'}} onClick={handleCloseModal}><ArrowBack fontSize='small'/>Não, voltar</S.ButtonText>
        </S.ModalContent>
    </Modal>
      )}


    </S.Container>
  );
}