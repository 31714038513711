import React, { useEffect, useState } from 'react';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import * as S from './style';
import { useGetSubscription } from '../../../../services/hooks/subscriptionHooks';
import Skeleton from '@mui/material/Skeleton';
import useAuth from '../../../../hooks/useAuth';

const CreditCardItem = ({setIsSelected, isSelected, isMobile, card, setSelectDefaultCard, setSelectedCardData}) => {
  const { user } = useAuth();
  const [selectedCard, setSelectedCard] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const companies = user.companies;
    ////console.log('companies', companies)
    if (companies && Object.keys(companies).length > 0) {
        const matchingCompany = Object.values(companies).find(company => 
        company.user.id && company.user.id === user.user_id);
          
            ////console.log('matchingcompanies', matchingCompany)
        
          if (matchingCompany && matchingCompany.billing_account.creditCard) {
              const creditCards = matchingCompany.billing_account.creditCard;
              if (creditCards.length > 0) {
                ////console.log('creditCards', creditCards)
                setSelectedCard(creditCards[0]); // Ajuste conforme a necessidade de seleção de cartão
            } else {
                ////console.log('No credit cards available in the matched company');
            }
          }
    }
  }, [user]); // Dependência no user

  const handleSelectDefaultCard = () => {
    setIsSelected(true);
    setSelectDefaultCard(selectedCard);
  };

  function getCreditCardLogo(brand) {
    switch (brand?.toUpperCase()) {
      case 'MASTERCARD':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png';
      case 'VISA':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2000px-Visa_Inc._logo.svg.png';
      case 'AMERICAN EXPRESS':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/American_Express_logo_%282018%29.svg/1920px-American_Express_logo_%282018%29.svg.png';
      case 'DISCOVER':
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Discover_Card_logo.svg/1920px-Discover_Card_logo.svg.png';
      default:
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Generic_Credit_Card_Logo.svg/2048px-Generic_Credit_Card_Logo.svg.png'; // Logo genérica
    }
  }

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  // ////console.log('selected?', selectedCard, card)

  
  
  return (
    <>
      <S.PaymentMethodArea isMobile={isMobile} isSelected={isSelected}>
        {!selectedCard ? <Skeleton variant="rounded" width={40} height={40} /> : 
          <S.PaymentMethodIcon>
            <img src={getCreditCardLogo(selectedCard.creditCardBrand)} alt={selectedCard.creditCardBrand || 'Credit Card'} />
          </S.PaymentMethodIcon>
        }
        { !selectedCard ? <Skeleton variant="rounded" width={250} height={32} /> : 
          <S.PaymentMethodInfo>
            <S.PaymentInfoText isMobile={isMobile}>{capitalizeFirstLetter(selectedCard.creditCardBrand) || 'Cartão'} final em {selectedCard.creditCardNumber || 'XXXX'}</S.PaymentInfoText>
            <S.PaymentMethodInner><p></p><span>PADRÃO</span></S.PaymentMethodInner>
          </S.PaymentMethodInfo>
        }
         {!selectedCard ? <Skeleton variant="rounded" width={80} height={26} /> :
          <S.ButtonText onClick={handleSelectDefaultCard} style={{ color: 'white' }} isEditing={true}>
            Selecionar
          </S.ButtonText>
        }
      </S.PaymentMethodArea>
    </>
  );
};

export default CreditCardItem;
