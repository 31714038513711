import styled from 'styled-components';

type SelectedTab = {
    selected: boolean;
};

export const Container = styled.div`
    font-family: Montserrat;
    padding: 16px;
    line-height: 1.6;
    height: 100vh;
    overflow-y: auto;
`;

export const Logos = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0.5rem 3rem 1.5rem;
`;

export const BackButton = styled.div`
    color: #5e5e5e99;
    border: none;
    padding: 0;
    margin: 0;
    width: 100px;
    font-size: 10px;
    font-weight: 500;
    &:hover {
        color: #1e1e1e;
        cursor: pointer;
    }
`;

export const LogoImg = styled.img`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
    width: 50px;
`;

export const FlexContainer = styled.div`
    display: flex;
    gap: 46px;
    transform: scale(0.7);
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -58px;
    flex-direction: row;
`;

export const Button = styled.button<SelectedTab>`
    margin-bottom: 32px;
    padding: 8px 24px;
    height: 4.5rem;
    color: ${({ selected }) => (selected ? '#19c380' : '#5e5e5e99')};
    background: none;
    outline: none;
    border: 1px ${({ selected }) => (selected ? '#19c380' : '#ccc')} solid;
    border-radius: 50px;
    &:hover {
        color: #19c380;
        cursor: pointer;
    }
`;

export const TermsPolicy = styled.div`
    max-width: 800px;
    margin: auto;
    overflow-y: auto;
`;

export const Title = styled.h1`
    font-weight: 500;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
`;

export const SectionCont = styled.p`
    text-align: flex-start;
    margin-bottom: 20px;
`;

export const Section = styled.section`
    margin-bottom: 20px;
`;

export const SectionTitle = styled.h2`
    color: #444;
    margin-bottom: 20px;
`;

export const SectionContent = styled.p`
    text-align: flex-start;
    color: grey;
    text-indent: 30px;
`;

export const End = styled.p`
    max-width: 800px;
    margin: 3.5rem auto 2rem ;
    text-align: center;
    font-size: 12px;
    overflow-x: hidden;
`;