import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getCosts(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/additional-costs?company_id=${company_id}`);
    return data;
}

export async function createCost(body: DTO.createCostDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/additional-costs`, body);
    return data;
}

export async function deleteCost(body: DTO.deleteCostDTO): Promise<AxiosResponse> {
    //@ts-ignore
    const data: AxiosResponse = await api.delete(`${BASE_URL}/client-services/io-handlers/manual/additional-costs`, { data: body });
    return data;
}

export async function getHistoryCost(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/additional-costs/historical?company_id=${company_id}`);
    return data;
}

export async function updateCost(body: DTO.updateCostDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.put(`${BASE_URL}/client-services/io-handlers/manual/additional-costs`, body);
    return data;
}
