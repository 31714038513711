import * as S from './style';
import Modal from '../../../components/Modal';
import { ModalIntegrationProps } from '../../../@types';
import { ModalShopify } from './TypesModalIntegration/Shopify';
import { ModalFacebook } from './TypesModalIntegration/Facebook';
import { ModalTiktok } from './TypesModalIntegration/Tiktok';
import { ModalGoogleAds } from './TypesModalIntegration/Google';
import { ModalGateway } from './TypesModalIntegration/Gateway';
import { ModalYampi } from './TypesModalIntegration/Yampi';
import { ModalKiwify } from './TypesModalIntegration/Kiwify';
import { ModalCartpanda } from './TypesModalIntegration/Cartpanda';
import { ModalDom } from './TypesModalIntegration/Dom';
import { ModalTax } from './TypesModalIntegration/Tax';
import { ModalShipping } from './TypesModalIntegration/Shipping';
import { TemplateModalProps } from '../../../@types';
import { ModalYever } from './TypesModalIntegration/Yever';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ModalManualCheckout } from './TypesModalIntegration/ManualCheckout';
// import { ModalUnicoPag } from './TypesModalIntegration/UnicoPag';

export function ModalIntegration({hasShippingTax, setHasShippingTax, hasTaxConnected, setHasTaxConnected, hasAdooreiCheckout, hasUnicoPagCheckout, hasShopifyCheckout, setHasAdooreiCheckout, setHasUnicoPagCheckout, setHasShopifyCheckout, setShowModal, type }: ModalIntegrationProps) {
    function handleType(type: string) {
        switch (type) {
            case 'shopify':
                return <ModalShopify setShowPopupShopify={() => setShowModal(false)} />;
            case 'facebook':
                return <ModalFacebook setShowPopupFacebook={() => setShowModal(false)}/>;
            case 'tiktok':
                return <ModalTiktok />;
            case 'google':
                return <ModalGoogleAds />;
            case 'gateway':
                return <ModalGateway setShowPopupGateway={() => setShowModal(false)} />;
            case 'yampi':
                return <ModalYampi setShowPopupYampi={() => setShowModal(false)} />;
            case 'kiwify':
                return <ModalKiwify setShowPopupKiwify={() => setShowModal(false)} />;
            case 'cartpanda':
                return <ModalCartpanda setShowPopupCartpanda={() => setShowModal(false)} />;
            case 'yever':
                return <ModalYever setShowPopupYever={() => setShowModal(false)} />;
            case 'dom':
                return <ModalDom setShowPopupDom={() => setShowModal(false)} />;
            case 'tax':
                return <ModalTax hasTaxConnected={hasTaxConnected} setHasTaxConnected={setHasTaxConnected} setShowPopupTax={() => setShowModal(false)} />;
            case 'shipping':
                return <ModalShipping hasShippingTax={hasShippingTax} setHasShippingTax={setHasShippingTax} setShowPopupShipping={() => setShowModal(false)} />;
            case 'adoorei':
                return <ModalManualCheckout hasUnicoPagCheckout={hasUnicoPagCheckout} hasAdooreiCheckout={hasAdooreiCheckout} setHasAdooreiCheckout={setHasAdooreiCheckout} setHasUnicoPagCheckout={setHasUnicoPagCheckout} setShowPopup={() => setShowModal(false)} platform='Adoorei'/>;
            case 'unicopag':
                return <ModalManualCheckout hasUnicoPagCheckout={hasUnicoPagCheckout} hasAdooreiCheckout={hasAdooreiCheckout} setHasUnicoPagCheckout={setHasUnicoPagCheckout} setHasAdooreiCheckout={setHasAdooreiCheckout} setShowPopup={() => setShowModal(false)} platform='UnicoPag'/>;
            case 'shopify_checkout':
                return <ModalManualCheckout hasUnicoPagCheckout={hasUnicoPagCheckout} hasShopifyCheckout={hasShopifyCheckout} hasAdooreiCheckout={hasAdooreiCheckout} setHasUnicoPagCheckout={setHasUnicoPagCheckout} setHasShopifyCheckout={setHasShopifyCheckout} setHasAdooreiCheckout={setHasAdooreiCheckout} setShowPopup={() => setShowModal(false)} platform='Shopify Checkout'/>;
            default:
                return <></>;
        }
    }

    return (
        <Modal onClose={() => setShowModal(false)}>
            <S.Container type={type}>{handleType(type)}</S.Container>
        </Modal>
    );
}



export function TemplateModal({ urlLogo, baseColor, type, title, subtitle, statusConnection }: TemplateModalProps) {
    const isConnected = statusConnection === 'active' || statusConnection === true;
    const isTaxType = type === 'Tax' ? 'Configurar' : 'Integração';
    return (
        <>
            <S.Logo>
                <img src={urlLogo} alt={`Logo do ${type}`} />
            </S.Logo>
            <S.Title baseColor={baseColor}>
                <div>
                {isTaxType} <span>{title}</span>
                </div>
                <S.ConnectionContainer>
                <S.StatusConnection isConnected={isConnected}>
                    {/* Status da conexão:  */}
                    <span>{isConnected ? 'Conectado' : 'Desconectado'}</span>
                </S.StatusConnection>
                {/* <S.LastConnection hasLastUpdated={hasLastUpdated}>
                    Última atualização: <span>{lastConnection}</span>
                </S.LastConnection> */}
            </S.ConnectionContainer>
            </S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
            {/* <S.Line></S.Line> */}
           
            <S.Line/>
        </>
    );
}

export function TemplateModal2({ urlLogo, baseColor, type, title, subtitle }: TemplateModalProps) {

    return (
        <>
            <S.Logo2>
                <CreditCardIcon sx={{ color: 'white', width: '24px', height: '24px' }} />
            </S.Logo2>
            <S.Title2 baseColor={baseColor}>
                Configurações de <span> {title}</span>
            </S.Title2>
            <S.Subtitle>{subtitle}</S.Subtitle>
            <S.Line2></S.Line2>
        </>
    );
}
