import { GraphProps } from '../../../../@types';
import { getMetricsByRegion } from '../../utils';
import { LineRegionAnalysis } from './LineRegionAnalysis';
import * as S from './style';
import { TablePlaceholder } from '../../../../components/TablePlaceholder';

export function RegionAnalysis({ isLoading, data }: GraphProps) {
    const { provincesData } = getMetricsByRegion(data);

    if (isLoading) {
        return (
            <S.ContainerLoading>
                <S.LoadingContainer />
            </S.ContainerLoading>
        );
    }

    if (provincesData.length === 0) {
        return (
           <S.Container>
            <S.InnerContainer>
                <TablePlaceholder size="small" showTitle={true} showSubtitle={true} showImage={true} />
                </S.InnerContainer>
           </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Title>Análise regional</S.Title>
            <S.InnerContainer>
                <S.Header>
                    <S.Column>Estado</S.Column>
                    <S.Column>Receita</S.Column>
                    <S.Column>Vendas</S.Column>
                    <S.Column>Share</S.Column>
                </S.Header>
                <S.Line></S.Line>
                <S.Lines>
                    {provincesData?.map((province: any) => {
                        return <LineRegionAnalysis key={province.name} name={province.name} revenue={province.revenue} orders={province.orders} share={province.share} />;
                    })}
                </S.Lines>
            </S.InnerContainer>
        </S.Container>
    );
}
