import { InputPermissionProps } from '../../../@types';
import * as S from './style';

export function InputPermission({ inputId, inputName, required = false, disabled = false, placeholder, value, setValue }: InputPermissionProps) {
    return (
        <S.Container>
            <label htmlFor={inputId}>{inputName}</label>
            <input
                id={inputId}
                name={inputId}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                type="text"
                onChange={(e) => setValue(e)}
                value={value}
                autoComplete="off"
            />
        </S.Container>
    );
}
