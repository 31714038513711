import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    color: var(--black);
    height: 5.375rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    > span {
        display: flex;
        /* width: 4rem; */
        align-items: center;
        justify-content: center;
        /* margin-left: -4rem; */
    }
`;

export const Image = styled.div`
    width: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 3.125rem;
        height: 3.125rem;
    }
`;

export const ProductName = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15rem;
    padding-right: 3rem;
    padding-left: 1rem;
    flex: 1;
`;

export const DefaultColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.5rem;
    /* padding-left: 1rem; */
    span {
        margin-left: 0.5rem;
        color: var(--green);
    }
`;

export const Costs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.25rem;
    /* padding-left: 1rem; */
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    /* padding-left: 1rem; */
`;

export const Plus = styled.div`
    width: 1.375rem;
    height: 1.375rem;
    background-color: #b3cbc1;
    border-radius: 50%;
    color: var(--white);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
