import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getUrlChangePassword(body: DTO.userChangePasswordDTO): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.post(`${BASE_URL}/auth/change-password`, body);
    console.log('data', data);
    return data;
}

export async function getUser(): Promise<DTO.userDTO> {
    const data: DTO.userDTO = await api.get(`${BASE_URL}/auth/users`);
    return data;
}
