import { RouterProvider } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from "@sentry/react";

import { ThemeProvider } from 'styled-components';
import { router } from './routes';
import GlobalStyle from './styles/global';
import useToggleTheme from './hooks/useToggleTheme';
import { GetDataProvider } from './contexts/GetDataContext';
import { VisibilityChangeProvider } from './contexts/VisibilityChangeContext';
import { AlertProvider } from './contexts/AlertContext';
import { CheckboxPlatformProvider } from './contexts/CheckboxTrackingContext';
import { RefreshProvider } from './contexts/RefeshContext';
import useAuth from './hooks/useAuth';
import { AlertComponent } from './components/AlertComponent.tsx';
import { CurrencyTypeProvider } from './contexts/CurrencyTypeContext';


Sentry.init({
    dsn: "https://8415667bb09da277427dfb577511ac12@o4507947674435584.ingest.us.sentry.io/4507964690530304",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
    const { theme } = useToggleTheme();
    const { user, currentCompany } = useAuth();

    const name = user.name; // Substitua pelo nome do usuário
    return (
        <>
            <RefreshProvider>
                <CurrencyTypeProvider>
                    <GetDataProvider>
                        <VisibilityChangeProvider>
                            <ThemeProvider theme={theme}>
                                <AlertProvider>
                                    <CheckboxPlatformProvider initialData={[]}>
                                        <AlertComponent />
                                        <IntercomProvider appId="yotf41a2" autoBoot autoBootProps={{
                                            name: name, // Substitua pelo nome do usuário
                                        }}>
                                            <GlobalStyle />
                                            <HelmetProvider>
                                                <Helmet titleTemplate="%s | Solomon App" />
                                                <RouterProvider router={router} />
                                            </HelmetProvider>
                                        </IntercomProvider>
                                    </CheckboxPlatformProvider>
                                </AlertProvider>
                            </ThemeProvider>
                        </VisibilityChangeProvider>
                    </GetDataProvider>
                </CurrencyTypeProvider>
            </RefreshProvider>
        </>
    );
}

export default Sentry.withProfiler(App);
