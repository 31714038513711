import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    background-color: #f4f4f4;
    width: 26.4375rem;
    padding: 0.25rem;
    border-radius: 9px;
`;

export const ImageContainer = styled.div`
    margin-left: 0.5rem;
    img {
        margin-top: 0.2rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 7px;
    }
`;

export const ProductNameContainer = styled.div`
    position: relative;
    font-size: 0.875rem;
    font-weight: 500;
    gap: 0.25rem;
    line-height: 1.05rem;
    display: flex;
    color: #081b16;
    // width: 13.9rem;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11rem;
    }

    span {
        cursor: pointer;
        svg {
            position: relative; 
            width: 0.875rem;
            height: 0.875rem;
            fill: #E90E0E;
            margin-left: 0.25rem;
        }

        // &:hover::after {
        //     content: "Configure o custo deste produto.";
        //     position: absolute;
        //     top: -50%; 
        //     left: 100%; 
        //     transform: translateX(-40%); 
        //     background-color: white;
        //     color: #1e1e1e;
        //     padding: 0.5rem;
        //     width: 12rem;
        //     height: 1.3rem;
        //     border: 1px solid #e3e3e3;
        //     border-radius: 6px;
        //     font-size: 0.7125rem;
        //     font-weight: 550 !important;
        //     font-family: 'Montserrat';
        //     white-space: nowrap;
        //     z-index: 1000; 
        //     pointer-events: none; 
        // }
    }
`;

export const ProductRevenueContainer = styled.div`
    font-size: 0.7rem;
    color: #081b16;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
`;

export const ProductRevenue = styled.div`
    font-size: 1rem;
    // width: 6.5rem;
    font-weight: 550;
    line-height: 1.2rem;
    margin-left: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
    
        color: var(--green);
    }
`;

export const FlexArea = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    `;

