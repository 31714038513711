import styled from 'styled-components';
import HistoryIcon from '@mui/icons-material/History';

export const Container = styled.div`
    display: flex;
    color: var(--black);
    height: 4rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
`;

export const Image = styled.div`
    img {
        width: 3rem;
        height: 3rem;
        border-radius: 5px;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
    }
    width: 8rem;
    margin-left: 1rem;
`;

export const ProductVariant = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: flex-start;
    justify-content: center;
    width: 30rem;
    margin-left: 1rem;
    flex-direction: column;
    span {
        font-size: 14px;
        color: #9e9e9e;

        /* Propriedades adicionais para o text overflow */
        overflow: hidden;       /* Esconde qualquer conteúdo que ultrapasse o tamanho do elemento */
        text-overflow: ellipsis; /* Adiciona os três pontinhos quando o texto é cortado */
        white-space: nowrap;    /* Impede que o texto seja quebrado em várias linhas */
    }
`;

export const ProductPrice = styled.div`
    display: flex;
    margin-left: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 8rem;
    margin-left: 1rem;
`;

export const IndividualCost = styled.div`
    width: 8.75rem;
    margin-left: 1rem;
    margin: auto 0;
`;

export const Action = styled.div`
    width: 8.75rem;

`;

export const Historic = styled.div`
    display: flex;
    margin-left: 2rem;
    align-items: center;
    justify-content: flex-start;
`
export const HistoricButton = styled.button`
    width: 7rem;
    height: 1.5rem;
    color: #979797;
    background-color: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 5px;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    svg{
        fill: #979797;
        width: 0.75rem;
        height: 0.75rem;
    }
`
export const HistoryIconStyled = styled(HistoryIcon)`
    width: 0.7em !important;
    fill: var(--black);
`;