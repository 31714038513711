import styled from 'styled-components';
import { MoreTime} from '@mui/icons-material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--black);
    height: 5.375rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.21875rem;
    > span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 1.25rem;
    border-top: 1px solid var(--gray-110);
`;

export const Title = styled.div`
    font-size: 1.25rem;
    align-self: start;
    font-weight: 600;
    // line-height: 1.875rem;
    margin-bottom: 1.25rem;
`;

export const OptionContainer = styled.div<{selected: any}>`
    display: flex;
    align-items: center;
    width: 650px;
    background-color: ${props => props.selected ? 'var(--gray-110)' : 'var(--gray-50)'};
    margin-bottom: 1.25rem;
    padding: 16px 16px 16px 16px;
    border-radius: 0.625rem;
    cursor: pointer;
    transition: all 250ms;

    &:hover {
        background-color: var(--gray-110);
        
        transition: all 250ms;
`;

export const Label = styled.label`
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    // cursor: pointer;
    margin-left: 0.625rem;
    display: flex;
`;

export const RadioInput = styled.input`
    margin-right: 0.625rem;
    cursor: pointer;
`;

export const DateSelector = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 24px;
    align-items: center;
    gap: 1rem;
    
    > label {
        font-weight: 500;
        color: #4f6060;
    }

    > div {
        background-color: var(--gray-50);
        border-radius: 8px;
        padding: 6px;
        border : 1px solid var(--gray-110);
    }

    > div > input {
        width: 186px;
        text-align: center;
        font-weight: 600;
        outline: none;
        padding: 6px;
        background-color: var(--gray-50);
    }
`;

export const MyMoreTime = styled(MoreTime)`
    color: #19c27e;
    font-size: 20px;
    align-self: start;
    margin-bottom: 28px;
`;

export const DatePickerWrapper = styled.div`
  .flatpickr-calendar.open {
      z-index: 1000000; /* Ajuste este valor conforme necessário */
  }
`;
