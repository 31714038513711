import { useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { LineProductProps } from '../../../../@types';
import * as S from './style';
import useVisibility from '../../../../hooks/useVisibilityChange';
import { useCurrencyType } from '../../../../hooks/useCurrencyType';
import Modal from '../../../../components/Modal';
import MarketingCostModal from './MarketingCostModal';
import CampaignModal from './CampaignModal';
import AdModal from './AdModal';
import TagForCampaignsModal from './TagForCampaignsModal';
import TagForAdsModal from './TagForAdsModal';

export function LineProduct({ product, costMarketingValue, setCostOperationDate }: LineProductProps) {
    const { isVisible } = useVisibility();
    const [showOption, setShowOption] = useState(false);
    const [showManualCost, setShowManualCost] = useState(false);
    const [showCampaign, setShowCampaign] = useState(false);
    const [showAd, setShowAd] = useState(false);
    const [showTagForCampaigns, setShowTagForCampaigns] = useState(false);
    const [showTagForAds, setShowTagForAds] = useState(false);
    const [costMarketing, setCostMarketing] = useState<number>();
    const { currencySymbol } = useCurrencyType();

    useEffect(() => {
        if (costMarketingValue[1] === product.product_id) { 
            setCostMarketing(costMarketingValue[0]);
        } else {
            setCostMarketing(0);
        }
    }, [costMarketingValue]);

    //console.log('produts', product);

    const handleImageError = (event) => {
        event.target.src = 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png';
    };

    return (
        <S.Container>
            <S.Image>
                <img src={ isVisible ? (product.product_image != '' ? product.product_image : 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png' ) : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} alt={product.product_name} onError={handleImageError} />
            </S.Image>
            <S.ProductName>
            <Tooltip title={isVisible ? product?.product_name :'* * * * * * * * * * * *'} placement="top" arrow  componentsProps={{arrow: {sx: {color: 'var(--black)'}}, tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '11px',fontFamily: 'Montserrat',borderRadius: '50px',padding: '5px 10px',color: 'white',textWrap: 'wrap',zIndex: 999999}},
                    }}>
                <p>{isVisible ? product?.product_name :'* * * * * * * * * * * *'}</p>
            </Tooltip>
            </S.ProductName>
            <S.DefaultColumnLucro color={product?.color}>
                <span>
                    {currencySymbol}{' '}
                    {!product?.realProfit
                        ? '0,00'
                        : product?.realProfit.toLocaleString('pt-BR', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                </span>
                <span >({ !isNaN(parseInt(product?.formattedMarginProfit)) ? product?.formattedMarginProfit : 0 })</span>
            </S.DefaultColumnLucro>
            <S.DefaultColumn color={true}>
                {currencySymbol}{' '}
                {!product?.totalRevenue
                    ? '0,00'
                    : Number(product?.totalRevenue)?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}{' '}

                <span>
                    
                    ({product?.totalNumberSales ?? 0})

                   
                </span>
            </S.DefaultColumn>
            <S.DefaultColumn color={true}>
                {currencySymbol}{' '}
                {!product?.revenue
                    ? '0,00'
                    : Number(product?.revenue)?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}{' '}
                <span style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}>({product?.number_sales ?? 0})

                <Tooltip title="Quantidade de produtos vendidos" placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>   
                        <InfoRoundedIcon sx={{width: '15px', marginLeft: '0px', fill: '#7b9898'}}/>
                    </Tooltip>
                </span>
            </S.DefaultColumn>
            <S.DefaultColumn color={true}>
                {currencySymbol}{' '}
                {!product.pending_revenue
                    ? '0,00'
                    : Number(product.pending_revenue).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}{' '}
                <span>({product?.pending_number_sales})</span>
            </S.DefaultColumn>
            <S.DefaultColumn color={true}>
                {currencySymbol}{' '}
                {!product.cancelled_revenue
                    ? '0,00'
                    : Number(product.cancelled_revenue).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}{' '}
                <span>({product?.cancelled_number_sales})</span>
            </S.DefaultColumn>
            <S.Costs>
                {currencySymbol}{' '}
                {!(product.mkt_additional_cost+ (costMarketing !== undefined ? costMarketing : 0))
                    ? '0,00'
                    : (Number(product.mkt_additional_cost)+(costMarketing !== undefined ? costMarketing : 0)).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}
            </S.Costs>
            <span>
                <S.Plus onClick = {() => setShowOption((prev) => !prev)}>+</S.Plus>
                {showOption && (
                    <Modal onClose={() => setShowOption(false)}> 
                        <S.OptionBody>
                            <S.OptionContent>
                                <S.OptionHeader>Escolha uma opção:</S.OptionHeader>
                                <S.OptionButton onClick = {() => (setShowManualCost((prev) => !prev), setShowOption(false))}>Custo Manual</S.OptionButton>
                                <S.OptionButton onClick = {() => (setShowCampaign((prev) => !prev), setShowOption(false))}>Sincronizar Campanhas Específicas</S.OptionButton>
                                <S.OptionButton onClick = {() => (setShowAd((prev) => !prev), setShowOption(false))}>Sincronizar Anúncios Específicos</S.OptionButton>
                                <S.OptionButton onClick = {() => (setShowTagForAds((prev) => !prev), setShowOption(false))}>Sincronizar Anúncios Com Uma Tag</S.OptionButton>
                                <S.OptionButton onClick = {() => (setShowTagForCampaigns((prev) => !prev), setShowOption(false))}>Sincronizar Campanha Com Uma Tag</S.OptionButton>
                            </S.OptionContent>
                        </S.OptionBody>
                    </Modal>
                )}
                {showManualCost && (
                    <MarketingCostModal onClose={() => setShowManualCost(false)} product_id={product.product_id} setShowManualCost={setShowManualCost} setCostOperationDate={setCostOperationDate}></MarketingCostModal>
                )}
                {showCampaign && (
                    <CampaignModal onClose={() => setShowCampaign(false)} setShowCampaign={setShowCampaign} product_id={product.product_id} product_name={product.product_name}></CampaignModal>
                )}
                {showAd && (
                    <AdModal onClose={() => setShowAd(false)} setShowAd={setShowAd} product_id={product.product_id} product_name={product.product_name}></AdModal>
                )}
                {showTagForCampaigns && (
                    <TagForCampaignsModal onClose={() => setShowTagForCampaigns(false)} setShowTag={setShowTagForCampaigns} product_id={product.product_id} product_name={product.product_name}></TagForCampaignsModal>
                )}
                {showTagForAds && (
                    <TagForAdsModal onClose={() => setShowTagForAds(false)} setShowTag={setShowTagForAds} product_id={product.product_id} product_name={product.product_name}></TagForAdsModal>
                )}
            </span>
            <S.Costs>
                {currencySymbol}{' '}
                {!product.cost
                    ? '0,00'
                    : Number(product.cost).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}
            </S.Costs>
            <S.Tax>
                {currencySymbol}{' '}
                {!product?.totalTax
                    ? '0,00'
                    : Number(product?.totalTax).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}
            </S.Tax>
            <S.Tax>
                {currencySymbol}{' '}
                {!product?.gatewayFee
                    ? '0,00'
                    : Number(product?.gatewayFee).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}
            </S.Tax>
            <S.Tax>
                {currencySymbol}{' '}
                {!product?.checkoutFee
                    ? '0,00'
                    : Number(product?.checkoutFee).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                      })}
            </S.Tax>
        </S.Container>
    );
}
