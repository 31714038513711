import { useEffect, useState } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import { useGetLastUpdated } from '../../../../../services/hooks/getData';
import { storageLastUpdatedGet } from '../../../../../storage/storageGetData';
import * as S from './style';
import { getIntervalString } from './utils';
import { useLocation } from 'react-router-dom';
import useIsMobile from '../../../../../hooks/useIsMobile';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';

export function Refresh() {

    const { user, currentCompany } = useAuth();
    const [activeGetLastUpdated, setActiveGetLastUpdated] = useState(true);
    const [dataLastUpdated] = useState(storageLastUpdatedGet());
    const [intervalString, setIntervalString] = useState(getIntervalString(dataLastUpdated));

    const location = useLocation();

    const { isLoading: isLoadingGetLastUpdated, refetch: refetchLastUpdated} = useGetLastUpdated(currentCompany, activeGetLastUpdated); 
    const isMobile = useIsMobile();


    useEffect(() => {
        refetchLastUpdated().then((data) => {
            //console.log(data?.data?.data?.data);
            setIntervalString(getIntervalString(data?.data?.data?.data));
        });

    },[location.pathname])

    const isLoading = (isLoadingGetLastUpdated ) ;

    return (
        <S.Container2 isMobile={isMobile} disabled={isLoading}>
            <UpdateRoundedIcon />
            <S.Subtile2>{isLoading ? 'Atualizando...' : intervalString}</S.Subtile2>
        </S.Container2>    
    );
}
