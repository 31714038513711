import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 2rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Title = styled.div`
    font-size: 1.625rem;
    color: var(--black);
    line-height: 1.95rem;
    font-weight: 500;
    height: 2rem;
    margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1rem;
    color: var(--gray-300);
`;
export const Header = styled.div`
    width: 40rem;
`;

export const InputArea = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
`;

export const Button = styled.button`
    border: 1px solid var(--gray-150);
    background-color: transparent;
    border-radius: 5px;
    font-weight: 500;
    line-height: 1.1875rem;
    width: 11.625rem;
    height: 2.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        cursor: pointer;
        transform: matrix(1, 0, 0, 1, 0, -2);
        border: 1px solid var(--green);
        box-shadow: 0 15px 20px 0 rgba(29, 142, 97, 0.05);
    }
`;

export const Line = styled.div`
    height: 1px;
    margin-top: 2rem;
    width: 95%;
    background-color: var(--gray-200);
`;

export const ButtonArea = styled.div`
    width: 40rem;
    margin-top: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonText = styled.div`
    font-size: 0.90rem;
    line-height: 1.5rem;
    color: var(--gray-300);
    &:hover {
        cursor: pointer;
        color: var(--green);
        text-decoration: underline;
    }
    
`;