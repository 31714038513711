// import { BackButton } from './../Terms/style';
import styled, { keyframes } from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';

export const Container = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 1rem;


    @media (min-width: 1800px) {
        transform: scale(1.16);
        margin-left: 1.2rem;
        margin-top: -8rem;
    }

    @media (min-width: 2500px) {
        transform: scale(1);
        margin-left: 0;
        margin-top: 0;
    }

    @media(max-width: 1024px){
        transform: scale(0.8);
        margin-top: 0;
        // margin-left: -4.5rem;
        width: 100%;
    }
`;

export const LogoutIconStyled = styled(LogoutIcon)`
    width: 0.7em !important;
`;

export const LeftColumn = styled.div`
    // background: #1e1e1e;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all ease 250ms;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    // border: 14px solid var(--white);
    // padding: 1rem;
    // background-position: 50%  30%;
    gap: 1rem;

    @media (max-width: 1024px) {
        margin-top: 0.5rem;        
    }
`;

export const ActionArea = styled.div<{isMobile: boolean;}>`
    display: flex;
    gap: 1.5rem;
    margin-top: ${props => props.isMobile ? '-0.5rem' : '0rem'};
    margin-bottom: ${props => props.isMobile ? '-0.5rem' : '0rem'};
`

export const LogoutButton = styled.div<{isStore?: boolean}>`
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: start;
    // position: absolute;
    // left: 0.6rem;
    // top: 0;
    margin-left: -0.4rem;
    margin-top: -0.5rem;
    padding: 0rem 0.5rem;
    color: var(--gray-700);
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 250ms;

    @media (max-width: 1024px) {
        margin: 0 auto;
    }
    svg {
        margin-right: 0.5rem;
    }
    &:hover {
        border-radius: 50rem;
        transition: all 250ms;
        transform: translateX(0.3rem);
        background-color: var(--white);
        color: ${props => props.isStore ? 'var(--black)' : 'var(--red-500)'};
    }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;


export const SideBar = styled.div<{isMobile: any}>`
background: ${props => props.isMobile ? 'none' : 'linear-gradient(274deg, rgba(238,238,241,0.7) 0%, #fbfbfb 59%)'};
margin-top: ${props => props.isMobile ? '0' : '-2rem'};

background-size: ${props => props.isMobile ? 'none' : '200% 200%'} ;  // Torna o gradiente largo o suficiente para suportar o movimento
animation: ${gradientAnimation} 3s ease infinite;  // Aplica a animaçãodisplay: flex;
flex-direction: column;
display: flex;
justify-content: center;
height:  ${props => props.isMobile ? 'unset' : '100vh'} ;
gap: 1rem;
width:  ${props => props.isMobile ? '104%' : '40%'};
padding: ${props => props.isMobile ? 'unset' : '2rem'};
z-index: 10;
h2 {
    text-align: ${props => props.isMobile ? 'center' : 'left'};
    text-wrap: ${props => props.isMobile ? 'nowrap' : 'wrap'};
    width: ${props => props.isMobile ? '124%' : 'auto'};
    
    @media(min-width: 1800px){
    font-size: 1.8rem;
}

font-size: 1.6rem;
font-weight: 550;
margin-left: ${props => props.isMobile ? '-2.5rem' : '0rem'};
margin-bottom: -1rem;
text-wrap: ${props => props.isMobile ? 'wrap' : 'wrap'};

}

@media(min-width: 1800px){
    width: 43%;
    padding: 6rem;
}
`;


export const VLine = styled.div`
height: 10rem;
width: 1px;
background-color: #989c97;
margin-top: 5rem;
@media(max-width: 1280px){
    display: none;
}
`;

export const InnerInfoContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
@media(max-width: 1240px){
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

`

export const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.6rem;
    font-weight: 450;
    color: #989c97;
    margin-bottom: 5rem;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: flex-end;
    transition: 0.3s;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 56.4375rem;
    @media(max-width: 1600px){
        width: 70%;
    }
    @media(max-width: 1280px){
        width: 50%;
    }
`;

export const Button = styled.button`
    width: 17.875rem;
    height: 3.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 450;
    color: whitesmoke;
    background-color: var(--green);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #989c97;
        &:hover {
            box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
            transform: translateY(-7px);
        }
    }
`;

const floating = keyframes`
0%, 100% {
    transform: translateY(0px);
}
50% {
    transform: translateY(-10px);
}
`

export const ImageContainer = styled.div`
    position: relative;
    animation: ${floating} 4s ease-in-out infinite;;
    width: 16.625rem;
    img {
        width: 16.625rem;
        height: 37rem;
    }
`;

export const ContentSection = styled.div`
    width: 56.4375rem;
    @media(max-width: 1600px){
        width: 100%;
    }
`;

export const ContainerModal = styled.div`
    width: 37.5rem;
    min-height: 20rem;
    height: auto;

    background-color: #fff;
    border-radius: 20px;
`;

export const ContainerIntegrationLoadingOnboarding = styled.div`
    position: absolute;
    top: 5.5rem;
    left: 1.5rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media(max-width: 1240px){
        flex-direction: column;
        align-items: center;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media(max-width: 1240px){
         width: 100%;
         &:last-child{
            margin-top: 1rem;
         }
    }
   
`;

export const InfoContainerText = styled.div`
    font-size: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    color: var(--green);
    text-align: center;
    @media(max-width: 1240px){
        text-align: left;
        padding-left: 5rem;
    }
`;

export const BackButton = styled.div`

    font-size: 0.9rem;
    margin-right: 1rem;
    font-weight: 550;
    line-height: 1.5rem;
    flex-direction: column;
    justify-content: center;
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        text-decoration: underline;
    }
    @media(max-width: 1240px){
        padding-left: 5rem;
    }
`;

export const NextButton = styled.div`

    font-size: 0.9rem;
    margin-left: 1rem;
    font-weight: 550;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        text-decoration: underline;
    }
    @media(max-width: 1240px){
        padding-left: 5rem;
    }
`;


