import { CardProductsProps } from '../../../../../@types';
import * as S from './style';
import useVisibility from '../../../../../hooks/useVisibilityChange';
import { useNavigate } from 'react-router-dom';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import { Tooltip } from '@mui/material';


export function CardProducts({ name, image, revenue, quantity, productCost, productId }: CardProductsProps) {

    const { isVisible } = useVisibility();
    const showWarning = Number(productCost) === 0 
    const navigate = useNavigate();
    const { currencySymbol } = useCurrencyType();

    const handleRedirectVariant = () => {
        // //console.log(productId)
        navigate(`/custo/variantes/${productId}`);
    }


    return (
        <S.Container>
            <S.FlexArea>
                <S.ImageContainer>
                    <img src={ isVisible ? (image != '' ? image : 'https://storage.googleapis.com/static-images-source/Image%20Placeholder%20Solomon.png' ) : 'https://icons.veryicon.com/png/o/miscellaneous/personal-icon/visibility-off-3.png'} />
                </S.ImageContainer>
                <S.ProductNameContainer>
                    <Tooltip title={isVisible ? name : '* * * * * * * * * * * * * '} placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--black)',fontWeight: 'semibold',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'white',textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <p>{isVisible ? name : '* * * * * * * * * * * * * '}</p>
                    </Tooltip>
                    <Tooltip title="Configure o custo deste produto." placement="top"  componentsProps={{tooltip: {sx: {bgcolor: 'var(--white)',fontWeight: '550',fontSize: '12px',fontFamily: 'Montserrat',borderRadius: '8px',padding: '10px',color: 'var(--red-500)', border: '1px solid var(--red-500)', textWrap: 'wrap',zIndex: 999999, lineHeight: '17px'}},}}>
                    <span onClick={()=>{handleRedirectVariant()}}>{showWarning && <WarningIcon />}</span>
                    </Tooltip>
                </S.ProductNameContainer>
            </S.FlexArea>
            <S.ProductRevenueContainer>
                <div style={{color: '#999'}}>{currencySymbol}</div>
                <S.ProductRevenue>
                    {Number(revenue).toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{' '}
                    <span>({quantity})</span>
                </S.ProductRevenue>
            </S.ProductRevenueContainer>
        </S.Container>
    );
}

function WarningIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/>
        </svg>
    )
}