import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    border: 1px solid transparent;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3125rem;
        color: var(--black);
    }

    input {
        margin-top: 0.5rem;
        height: 2.5rem;
        width: 35.375rem;
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        border-radius: 8px;
        border: 1px solid var(--gray-120);
        background-color: #fff;
        color: var(--black);
        font-weight: 500;
        &::placeholder {
            color: var(--gray-700);
            font-weight: 500;
        }

        &:focus,
        &:hover {
            /* border: 1px solid var(--green); */
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4); */
            background-color: #fff;
            /* box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1); */
            border-bottom: 1px solid var(--green);
        }
    }
`;
