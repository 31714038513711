import useAlert from '../../hooks/useAlert';
import useAuth from '../../hooks/useAuth';
import { changeTaxes, getTaxes } from '../api/api-tax';
import { useQuery, useMutation } from 'react-query';

export function useChangeTaxes() {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();
    
    return useMutation(changeTaxes, {
        onSuccess: () => {
                showAlert('Taxa atualizada com sucesso!', 'success', 5000, true);
            
            updateUser();
        },
        // Você pode adicionar aqui outros handlers como onError para tratar erros
        onError: () => {
                showAlert('Falha ao atualizar a taxa. Tente novamente.', 'error', 5000, true);
            
            updateUser();
        },
    });
}

export function useGetTaxes(company_id: string) {
    return useQuery(['getTaxes', company_id], () => getTaxes(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}