// GraphModal.tsx
import React from 'react';
import Modal from '../Modal/index.tsx';

import * as S from './style.ts'; 
import Graph from '../Graph/index.tsx';
import { useCurrencyType } from '../../hooks/useCurrencyType.tsx';
import MarketingGraph from './MarketingGraph.tsx';


interface GraphData {
    title: string;
    subTitle: string;
    xAxisData: string[];
    yAxisDatasets: { name: string; data: number[] }[]; // Suporta múltiplas linhas de dados
    intervalData: number;
    footerContent?: React.ReactNode; // Conteúdo opcional do rodapé
    isMobile?: boolean;
}


interface GraphModalProps extends GraphData {
  isOpen: boolean;
  onClose: () => void;
}

const GraphModal: React.FC<GraphModalProps> = ({   
    isOpen,
    onClose,
    xAxisData,
    yAxisDatasets,
    intervalData,
    title,
    subTitle,
    footerContent, 
    isMobile,
}) => {


    if (!isOpen) return null;

    return (
        <Modal onClose={onClose}>
            <S.ChartModal>
                <S.FlexHeaderModal>
                    <S.ChartTitle>{title}</S.ChartTitle>
                    <S.ChartSubTitle>{subTitle}</S.ChartSubTitle>
                </S.FlexHeaderModal>
                <S.MainChartContainer>
                    {(title === 'Custo Marketing' || title === 'Taxas e Impostos') ? (
                        <MarketingGraph xAxisData={xAxisData} yAxisDatasets={yAxisDatasets} intervalData={intervalData} isMobile={isMobile} />
                    ) : (

                       <Graph isMobile={isMobile} xAxisData={xAxisData} yAxisDatasets={yAxisDatasets} intervalData={intervalData} />
                    )}

                </S.MainChartContainer>
                <S.FooterContent>
                {footerContent ? footerContent : null} 
                </S.FooterContent> 
            </S.ChartModal>          
        </Modal>
    );
};

export default GraphModal;
