import { platform } from 'os';
import useAlert from '../../hooks/useAlert';
import useAuth from '../../hooks/useAuth';
import { submitManualCheckout, deleteManualCheckout, getManualCheckout } from '../api/api-manual-checkouts';
import { useQuery, useMutation } from 'react-query';
import { userDTO } from '../../@types/types-api';
import { storageOnboardingIntegrateAdooreiRemove, storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateUnicoPagRemove, storageOnboardingIntegrateShopifyCheckoutRemove, manualCheckoutGeneralLoadingRemove, manualCheckoutGeneralLoadingSet } from '../../storage/storageOnboarding';
import { storageUserGet } from '../../storage/storageUser';

export function useSubmitOnboardingManualCheckout() {
    const { showAlert } = useAlert();
    const { updateUser, user } = useAuth();

    return useMutation(submitManualCheckout, 
        {
        onMutate: (body) => { // O `body` é passado para `onMutate`
            updateUser();
        },
        onSuccess: (data, variables) => {
            showAlert('Taxa atualizada com sucesso!', 'success', 5000, true);            
            updateUser();
        },
        onError: (error, variables) => {
            const manualCheckouts = user?.companies?.[storageOnboardingCurrentCompanyGet()]?.manual_inputs?.manual_checkouts?.checkouts; 
            const connectedCheckout = manualCheckouts?.find((checkout: any) => checkout.checkout);
            
            showAlert('Falha ao atualizar a taxa. Tente novamente.', 'error', 5000, true);

            if (typeof error === 'object' && error !== null && 'message' in error) {
            if ((error as any).message === "A checkout was already configured for this company. Please remove it before trying to configure a new checkout.") {
                showAlert('Já existe uma taxa de checkout configurada para essa empresa.', 'error', 5000, true);
            }
            } else {
                showAlert(`Falha ao atualizar as taxas do checkout ${variables?.checkout}. Tente novamente.`, 'error', 5000, true);

            }

            if (variables?.checkout === 'Adoorei') {
                if (connectedCheckout.checkout === 'Adoorei') {
                } else {
                    storageOnboardingIntegrateAdooreiRemove();

                }
            } else if (variables?.checkout === 'UnicoPag') {
                if (connectedCheckout.checkout === 'UnicoPag') {
                } else {
                    storageOnboardingIntegrateUnicoPagRemove();

                }
            } else if (variables?.checkout === 'Shopify Checkout') {
                if (connectedCheckout.checkout === 'Shopify Checkout') {
                } else {
                    storageOnboardingIntegrateShopifyCheckoutRemove();

                }
            }

            localStorage.removeItem(`${variables.checkout}CheckoutInfo_${storageOnboardingCurrentCompanyGet()}`);
            updateUser();
        },
    });
}

export function useSubmitManualCheckout(
    platform?: string,
    setHasUnicoPagCheckout?: (value?: boolean) => void,
    setHasAdooreiCheckout?: (value?: boolean) => void,
    setHasShopifyCheckout?: (value?: boolean) => void,
    user?: any,
    currentCompany?: any,
    setUserContext?: any,
) {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();


    return useMutation(
        submitManualCheckout,
        {
            onMutate: (body) => { // O `body` é passado para `onMutate`
                manualCheckoutGeneralLoadingSet(currentCompany);
                showAlert(`Atualizando taxas do checkout ${body?.checkout}. Por favor, aguarde enquanto atualizamos os dados...`, 'loading', 1000000, true);

                if (platform === 'Adoorei') {
                    setHasAdooreiCheckout(true);
                } else if (platform === 'UnicoPag') {
                    setHasUnicoPagCheckout(true);
                } else if (platform === 'Shopify Checkout') {
                    setHasShopifyCheckout(true);
                }

                let newManualCheckouts: { flatTax: number; percentualTax: number; checkout: string; }[] = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts || [];

                const index = newManualCheckouts?.findIndex((checkout: any) => checkout.checkout === platform);
                if (index === -1) {
                    newManualCheckouts.push({ checkout: body?.checkout, flatTax: body?.flatTax, percentualTax: body?.percentualTax });
                } else {
                    newManualCheckouts[index] = { checkout: body?.checkout, flatTax: body?.flatTax, percentualTax: body?.percentualTax };
                }

                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            manual_inputs: {
                                ...user?.companies?.[currentCompany]?.manual_inputs,
                                manual_checkouts: { checkouts: newManualCheckouts },
                            },
                        },
                    },
                };

                setUserContext(newUser as userDTO);

                updateUser();
            },
            onSuccess: (data, variables) => { // `variables` contém o `body` que foi passado

                updateUser();

                manualCheckoutGeneralLoadingRemove(currentCompany);
                showAlert(`Taxas do checkout ${variables?.checkout} atualizadas com sucesso!`, 'success', 5000, true);

            },
            onError: (error, variables) => {
                console.log('submit manual checkout', error);

                if (platform === 'Adoorei') {
                    setHasAdooreiCheckout(false);
                } else if (platform === 'UnicoPag') {
                    setHasUnicoPagCheckout(false);
                } else if (platform === 'Shopify Checkout') {
                    setHasShopifyCheckout(false);
                }

                let newManualCheckouts: { flatTax: number; percentualTax: number; checkout: string; }[] = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts || [];
                newManualCheckouts = newManualCheckouts.filter((checkout: any) => checkout.checkout !== platform);
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            manual_inputs: {
                                ...user?.companies?.[currentCompany]?.manual_inputs,
                                manual_checkouts: { checkouts: newManualCheckouts },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();

                if (typeof error === 'object' && error !== null && 'message' in error) {
                    if ((error as any).message === "A checkout was already configured for this company. Please remove it before trying to configure a new checkout.") {
                        manualCheckoutGeneralLoadingRemove(currentCompany);
                        showAlert('Já existe uma taxa de checkout configurada para essa empresa. Por favor, remova-a antes de tentar configurar uma nova taxa.', 'error', 5000, true);
                    }
                } else {
                    manualCheckoutGeneralLoadingRemove(currentCompany);

                    if (platform === 'Adoorei') {
                        setHasAdooreiCheckout(false);
                    } else if (platform === 'UnicoPag') {
                        setHasUnicoPagCheckout(false);
                    } else if (platform === 'Shopify Checkout') {
                        setHasShopifyCheckout(false);
                    }

                    showAlert(`Falha ao atualizar as taxas do checkout ${variables?.checkout}. Tente novamente.`, 'error', 5000, true);

                }
                manualCheckoutGeneralLoadingRemove(currentCompany);
                updateUser();
            },
        }
    );
}

export function useDeleteManualCheckout(platform: string, setHasUnicoPagCheckout: (value: boolean) => void, setHasAdooreiCheckout: (value: boolean) => void, setHasShopifyCheckout: (value: boolean) => void, user: any, currentCompany: any, setUserContext: any) {
    const { showAlert } = useAlert();
    const { updateUser } = useAuth();
    return useMutation(deleteManualCheckout, {
        onMutate: (body) => {
            const manualCheckouts = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts;

            manualCheckoutGeneralLoadingSet(currentCompany);
            showAlert(`Deletando taxas do checkout ${body?.checkout}. Por favor, aguarde enquanto atualizamos os dados...`, 'loading', 1000000, true);
            if (platform === 'Adoorei') {
                storageOnboardingIntegrateAdooreiRemove();
                setHasAdooreiCheckout(false);
            } else if (platform === 'UnicoPag') {
                storageOnboardingIntegrateUnicoPagRemove();
                setHasUnicoPagCheckout(false);
            } else if (platform === 'Shopify Checkout') {
                storageOnboardingIntegrateShopifyCheckoutRemove();
                setHasShopifyCheckout(false);
            }

            if (manualCheckouts?.length < 2) {
                let newManualCheckouts: { flatTax: number; percentualTax: number; checkout: string; }[] = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts || [];
                newManualCheckouts = newManualCheckouts.filter((checkout: any) => checkout.checkout !== platform);
                const newUser = {
                    ...user,
                    companies: {
                        ...user?.companies,
                        [currentCompany]: {
                            ...user?.companies?.[currentCompany],
                            manual_inputs: {
                                ...user?.companies?.[currentCompany]?.manual_inputs,
                                manual_checkouts: { checkouts: newManualCheckouts },
                            },
                        },
                    },
                };
                setUserContext(newUser as userDTO);
                updateUser();
            }
        },

        onSuccess: (data, variables) => {
            let newManualCheckouts: { flatTax: number; percentualTax: number; checkout: string; }[] = user?.companies?.[currentCompany]?.manual_inputs?.manual_checkouts?.checkouts || [];
            newManualCheckouts = newManualCheckouts.filter((checkout: any) => checkout.checkout !== platform);
            const newUser = {
                ...user,
                companies: {
                    ...user?.companies,
                    [currentCompany]: {
                        ...user?.companies?.[currentCompany],
                        manual_inputs: {
                            ...user?.companies?.[currentCompany]?.manual_inputs,
                            manual_checkouts: { checkouts: newManualCheckouts },
                        },
                    },
                },
            };
            setUserContext(newUser as userDTO);
            updateUser();
            manualCheckoutGeneralLoadingRemove(currentCompany);

            showAlert(`Taxas do checkout ${variables?.checkout} deletadas com sucesso!`, 'success', 5000, true);
        },
        onError: (error, variables) => {
            if (platform === 'Adoorei') {
                setHasAdooreiCheckout(true);
            } else if (platform === 'UnicoPag') {
                setHasUnicoPagCheckout(true);
            } else if (platform === 'Shopify Checkout') {
                setHasShopifyCheckout(true);
            }
            console.log('delete manual checkout', error);
            showAlert("Falha ao excluir taxa de checkout. Tente novamente ou entre em contato com o suporte.", 'error', 5000, true);
            manualCheckoutGeneralLoadingRemove(currentCompany);
            updateUser();
        }
    })
}

export function useGetManualCheckout(company_id: string) {
    return useQuery(['getManualCheckout', company_id], () => getManualCheckout(company_id), {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });
}
