import styled from 'styled-components';

type ContainerProps = {
    width: string;
};

export const Container = styled.div<ContainerProps>`
    position: relative;
    max-width: 100vw;

    input {
        height: 2.2rem;
        border: 1px solid #e7e7e7;
        border-radius: 7px;
        background: none;
        padding: 0 0.5rem 0.25rem;
        font-size: 1rem;
        width: ${(props) => props.width};
        // max-width: 35rem;
        transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.75rem;
        line-height: 1.0625rem;

        &:not(:placeholder-shown) ~ label {
            transform: translateY(-50%) scale(0.8);
            background-color: #fff;
            padding: 0 0.2rem;
            color: var(--green);
        }
        

    }

    label {
        position: absolute;
        left: 15px;
        color: #989c96;
        pointer-events: none;
        transform: translateY(0.9rem);
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 0.65rem;
        font-weight: 500;
    }

    input:focus {
        outline: none;
  border: 1.5px solid var(--green);
    }

    input:focus ~ label {
        transform: translateY(-50%) scale(0.8);
        background-color: #fff;
        padding: 0 0.2rem;
        color: var(--green);
    }
`;

export const VisibilityButton = styled.div`
    color: var(--gray-900);
    position: absolute;
    top: 0.8rem;
    right: 1.2rem;
    cursor: pointer;
`;

export const Line = styled.div``;
