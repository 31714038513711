
export function getSyncCampaign (data: any, product_id: string) {
    
    const metricsByAds = [];
        
    for (const campaign in data) {
        const campaign_id = data?.[campaign]?.campaign_id;
        const campaign_name = data?.[campaign]?.campaign_name;
        const channel = data?.[campaign]?.channel;
        const company_id = data?.[campaign]?.company_id;
        const linked_product_id = data?.[campaign]?.linked_product_id;
        const linked_product_name = data?.[campaign]?.linked_product_name;
        const rightCheck = (data?.[campaign]?.linked_product_id !== null && data?.[campaign]?.linked_product_name !== null) && (data?.[campaign]?.linked_product_id !== '' && data?.[campaign]?.linked_product_name !== '') && data?.[campaign]?.linked_product_id !== product_id 
        
        if ((data?.[campaign]?.linked_product_id !== null && data?.[campaign]?.linked_product_name !== null) && (data?.[campaign]?.linked_product_id !== '' && data?.[campaign]?.linked_product_name !== '') && data?.[campaign]?.linked_product_id !== product_id ) {
            const leftCheck = true;
            metricsByAds.push({
                campaign_id,
                campaign_name,
                channel,
                company_id,
                linked_product_id,
                linked_product_name,
                leftCheck,
                rightCheck,
            });
        } else {
            const leftCheck = false;
            metricsByAds.push({
                campaign_id,
                campaign_name,
                channel,
                company_id,
                linked_product_id,
                linked_product_name,
                leftCheck,
                rightCheck,
            });
        }
    }
    
    return metricsByAds;
}