import { STORAGE_GET_DATA } from '../storage/storageConfig';

export function storageGetDataSave() {
    localStorage.setItem(STORAGE_GET_DATA, true.toString());
}

export function storageGetDataGet() {
    const storage = localStorage.getItem(STORAGE_GET_DATA) ?? '';
    if (storage === 'true') {
        return true;
    } else {
        return false;
    }
}

export function storageGetDataRemove() {
    localStorage.removeItem(STORAGE_GET_DATA);
}


export function storageGroupIdSave(groupId: string) {
    localStorage.setItem(`${STORAGE_GET_DATA}-groupId`, groupId);
}

export function storageGroupIdGet() {
    const storage = localStorage.getItem(`${STORAGE_GET_DATA}-groupId`);
    return storage ?? '';
}

export function storageGroupIdRemove() {
    localStorage.removeItem(`${STORAGE_GET_DATA}-groupId`);
}


export function storageLastUpdatedSave(date: string) {
    localStorage.setItem(`${STORAGE_GET_DATA}-lastUpdated`, date);
}

export function storageLastUpdatedGet() {
    const storage = localStorage.getItem(`${STORAGE_GET_DATA}-lastUpdated`);
    return storage ?? '';
}

export function storageLastUpdatedRemove() {
    localStorage.removeItem(`${STORAGE_GET_DATA}-lastUpdated`);
}

export function storageInitRefreshAllGetDataSave(){
    localStorage.setItem(`${STORAGE_GET_DATA}-initRefreshAllGetData`, true.toString());
}

export function storageInitRefreshAllGetDataGet(){
    const storage = localStorage.getItem(`${STORAGE_GET_DATA}-initRefreshAllGetData`);
    if(storage === 'true'){
        return true;
    }else{
        return false;
    }
}

export function storageInitRefreshAllGetDataRemove(){
    localStorage.removeItem(`${STORAGE_GET_DATA}-initRefreshAllGetData`);
}

export function storageLastSyncSave(date: string) {
    localStorage.setItem(`${STORAGE_GET_DATA}-lastSync`, date);
}

export function storageLastSyncGet() {
    const storage = localStorage.getItem(`${STORAGE_GET_DATA}-lastSync`);
    return storage ?? '';
}