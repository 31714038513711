import * as S from './style.ts';
import { useState, useEffect } from 'react';
import useAuth from '../../../../../../hooks/useAuth.tsx';

import { LineCampaignModalProps } from '../../../../../../@types';

export function LineCampaignModal({ syncCampaign, product_id, product_name, syncPost, setSyncPost }: LineCampaignModalProps) {
    const { currentCompany } = useAuth();

    // Determinar se o checkbox está marcado com base no estado do syncPost
    const isChecked = syncPost.some((post: any) => post.campaign_id === syncCampaign?.campaign_id);

    const handleCheckboxChange = () => {
        if (isChecked) {
            // Se já estiver marcada, removemos do syncPost
            setSyncPost((prevSyncPost: any) =>
                prevSyncPost.filter((post: any) => post.campaign_id !== syncCampaign?.campaign_id)
            );
        } else {
            // Se não estiver marcada, adicionamos ao syncPost
            setSyncPost((prevSyncPost: any) => [
                ...prevSyncPost,
                {
                    campaign_id: syncCampaign?.campaign_id,
                    campaign_name: syncCampaign?.campaign_name,
                    product_name: product_name,
                    product_id: product_id,
                    company_id: currentCompany,
                }
            ]);
        }
    };

    return (
        <S.Container>
            <S.DefaultColumnCheck>
                <input 
                    type="checkbox" 
                    checked={isChecked} 
                    disabled={syncCampaign?.leftCheck}
                    onChange={handleCheckboxChange} 
                />
            </S.DefaultColumnCheck>
            <S.DefaultColumnCheck>
                {syncCampaign?.channel.charAt(0).toUpperCase() + syncCampaign?.channel.slice(1)}
            </S.DefaultColumnCheck>
            <S.DefaultColumn>
                {syncCampaign?.campaign_name}
            </S.DefaultColumn>
            <S.DefaultColumnSync>
                <input 
                    type="checkbox"
                    checked={syncCampaign?.rightCheck}
                    disabled={!syncCampaign?.rightCheck}
                />
            </S.DefaultColumnSync>
        </S.Container>
    );
}
