import { useState } from 'react';
import { InputPermission } from '../InputPermission';
import * as S from './style';
import Checkbox from '@mui/material/Checkbox';
import { useCreateAccessGroup } from '../../../services/hooks/accessHooks';
import useAuth from '../../../hooks/useAuth';
import { queryClient } from '../../../services/queryClient';
import useVisibility from '../../../hooks/useVisibilityChange';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { ButtonApp } from '../../../components/ButtonApp';
import useAlert from '../../../hooks/useAlert';


export function ModalNewGroup({setShowModalNewGroup, handleAddGroups}:{setShowModalNewGroup: (value: boolean) => void, handleAddGroups: any}) {
    const [groupName, setGroupName] = useState('');
    const { showAlert } = useAlert();
    const [optionsChecked, setOptionsChecked] = useState([
        {
            name: 'Dashboard',
            id: 'summary',
            checked: false,
        },
        {
            name: 'Tracking',
            id: 'marketing',
            checked: false,
        },
        {
            name: 'Clientes',
            id: 'customers',
            checked: false,
        },
        {
            name: 'Pedidos',
            id: 'orders',
            checked: false,
        },
        {
            name: 'Produtos',
            id: 'products',
            checked: false,
        },
        {
            name: 'Custos de produtos',
            id: 'products_costs',
            checked: false,
        },
        {
            name: 'Custos adicionais',
            id: 'additional_costs',
            checked: false,
        },
        {
            name: 'Integrações',
            id: 'integrations',
            checked: false,
        },
        {
            name: 'Editar permissões',
            id: 'administration',
            checked: false,
        },
    ]);
    const { currentCompany } = useAuth();

    function handleSetValue(e: any) {
        setGroupName(e.target.value);
    }
    // const permissions = optionsChecked.filter((item) => item.checked).map((item) => item.id);

    const { isVisible } = useVisibility();
    const { mutate:handleCreateAccessGroup, isLoading} = useCreateAccessGroup()

    function handleCreateGroup() {
        const permissions = optionsChecked.filter((item) => item.checked).map((item) => item.id);
        const body = {
            name: groupName,
            scopes: permissions,
            company_id: currentCompany,
        }
        handleCreateAccessGroup(body, {
            onSuccess: (data) => {
                queryClient.invalidateQueries('accessGroups');
                handleAddGroups(data?.data?.data?.name, data?.data?.data?.scopes, data?.data?.data?.group_id);
                setShowModalNewGroup(false);
                showAlert('Grupo de permissões criado com sucesso!', 'success', 5000, true);
            },
            onError: (error) => {
                setShowModalNewGroup(false);
                console.error(error);
                showAlert('Você precisa selecionar uma ou mais permissões.', 'error', 5000, true);
            }
        })
    } 

    return (
        <S.Container>
            <AdGroupIcon />
            <S.Title>
                Criar <span>novo grupo</span>
            </S.Title>
            <S.Subtitle>Configure o nome e as permissões para criar um novo grupo.</S.Subtitle>
            <S.Line />
            <InputPermission inputId="name" inputName="Nome do grupo:" placeholder="Gerenciador de anúncios" value={groupName} setValue={handleSetValue} />
            <S.Line />
            <S.Text>Permissões de grupo</S.Text>
            <S.Subtext>Selecione abaixo quais serão as permissões/acessos que este grupo irá ter.</S.Subtext>
            <S.OptionSection>
                {optionsChecked?.map((item, index) => {
                    return (
                        <S.Option key={item.name}>
                            <S.Checkbox>
                                <Checkbox
                                    onClick={() => {
                                        const updatedOptions = [...optionsChecked];
                                        updatedOptions[index] = { ...item, checked: !item.checked };
                                        setOptionsChecked(updatedOptions);;
                                    }}
                                    size="small"
                                    checked={item.checked}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#19c273',
                                        },
                                    }}
                                />
                            </S.Checkbox>
                            {isVisible ? item.name : '* * * * * *'}
                        </S.Option>
                    );
                })}
            </S.OptionSection>
            <S.ButtonSection>
            <ButtonApp
                border=''
                width="13rem"
                height="2.5rem"
                color="#fff"
                background="#19c380"
                text='Criar novo grupo' 
                icon={<AddCircleOutlineRoundedIcon />} 
                isLoading={isLoading} onClick={handleCreateGroup} 
                disabled={groupName?.length === 0}
             />
                {/* <S.ButtonModalNewGroup onClick={handleCreateGroup} disabled={isLoading || groupName?.length === 0}>{!isLoading ? `+ Criar novo grupo` : 'Criando grupo'}</S.ButtonModalNewGroup> */}
            </S.ButtonSection>
        </S.Container>
    );
}

function AdGroupIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M320-320h480v-400H320v400Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
        </svg>
    );
}
