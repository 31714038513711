import { useState } from 'react';
import * as S from './style';
import Radio from '@mui/material/Radio';
import { InputWithTypeMoney } from '../../../../components/InputWithTypeMoney';
import { ButtonApp } from '../../../../components/ButtonApp';
import { LineModalEditProductInMass } from './LineModalEditProductInMass';
import {formatPercentageInput} from '../../../../utils';
import useAlert from '../../../../hooks/useAlert';

export function ModalEditProductInMass({products, selected, editedVariants, setEditedVariants, setOpenSaveCostsModal, setShowSelectInMass}: {products: any[], selected: any[], editedVariants: any, setEditedVariants: any, setOpenSaveCostsModal: any, setShowSelectInMass:any}) {
    const { showAlert } = useAlert();
    const [selectedValue, setSelectedValue] = useState('fixedValue');
    const [valuePercentage, setValuePercentage] = useState('');
    const [valueFixed, setValueFixed] = useState('');
    const [currency, setCurrency] = useState('BRL');

    const productsSelected = products.filter((product: any) => selected.includes(product.product_id));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleInput = (value: string, variant_id?: string, currency?: string ) => {
        //console.log("variant_id", variant_id);
        setValueFixed(value);
        setCurrency(currency || 'BRL');
    }

    const handleFormatValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = formatPercentageInput(event);
        setValuePercentage(newValue);
    };

    const controlProps = (item: string) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });

    
    const handleSaveChanges = () => {
        const newEditedVariants = {...editedVariants};
        productsSelected.forEach((product: any) => {
            product.variants.forEach((variant: any) => {
                newEditedVariants[variant.variant_id] = {
                    ...(newEditedVariants[variant.variant_id] || {}),
                    variant_cost: selectedValue === 'fixedValue' ? parseFloat(valueFixed.replace('.', '').replace(',', '.')) : (variant.price * parseFloat(valuePercentage.replace('%', '')) / 100),
                    product_id: product.product_id,
                    variant_id: variant.variant_id,
                    currency: selectedValue === 'fixedValue' ? (currency ?? variant.currency) : variant.currency,
                    store_id: product.store_id,
                    account_id: product.account_id,
                };
            })
        })

        // Aqui salvamos no localStorage
        localStorage.setItem('editedVariants', JSON.stringify(newEditedVariants));

        setEditedVariants(newEditedVariants);
        setOpenSaveCostsModal(true);
        setShowSelectInMass(false);
    }

    return(
        <S.Container>
            <S.Title>Edição em Massa de Custos</S.Title>
            <S.Line />
            <S.Content>
                <S.ContentTitle>Lista de Produtos</S.ContentTitle>
                <S.Lines>
                    {productsSelected?.length > 0 && productsSelected.map((product: any) => {
                        return(
                            <LineModalEditProductInMass key={product?.product_id} product={product}/>
                        )
                    })}
                </S.Lines>
            </S.Content>
            <S.Line />
                <S.FinalSection>
                    <S.Option>
                        <S.OptionName>
                            <Radio
                            {...controlProps('fixedValue')}
                            size="small"
                            sx={{
                            color: '#19c273',
                            '&.Mui-checked': {
                                color: '#19c273',
                            },
                            }}/>
                            Valor Fixo
                        </S.OptionName>
                            <InputWithTypeMoney disabled={false} value={valueFixed} setValue={handleInput}/>
                    </S.Option>
                    <S.Option>
                        <S.OptionName>
                    <Radio
                        {...controlProps('percentageValue')}
                        size="small"
                        sx={{
                        color: '#19c273',
                        '&.Mui-checked': {
                            color: '#19c273',
                        },
                        }}/>
                        Valor Percentual
                        </S.OptionName>
                        <S.OptionInput>
                            <input type="text" placeholder='0,00%' value={valuePercentage} onChange={handleFormatValue}/>
                            <S.InputIcon>
                                <span>%</span><span>|</span>
                            </S.InputIcon>
                        </S.OptionInput>
                    </S.Option>
                </S.FinalSection>
                <S.Line />
                <S.ButtonSection>
                    <ButtonApp 
                        border=''
                         width="11rem"
                         height="2.5rem"
                         color="#fff"
                         background="#19c380"
                         icon={<CheckIcon />}
                         onClick={() => {handleSaveChanges()}}
                         text="Salvar alterações" />
                </S.ButtonSection>
        </S.Container>
    )
}

function CheckIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
    )
}