import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 2rem;
    border: 1px solid var(--gray-225);
    border-radius: 10px;
`;

export const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid var(--gray-225);
    height: 3rem;
    display: flex;
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--black);
`;

export const MainHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 33.33%;
    padding-left: 1rem;
`;

export const SecondaryHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 16.66%;
    padding-left: 1rem;
    border-left: 1px solid var(--gray-225);
`;

export const Line = styled.div`
    width: 100%;
    > div:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    > div:nth-child(2n) {
        background-color: #f5f6f7;
    }
`;
