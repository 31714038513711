import { divideVectors } from '../../../utils';
import { useCheckboxTracking } from '../../../hooks/useCheckboxTracking';



export const platformIcons = {
    facebook: {
        platformName: 'Facebook Ads',
        platformImg: 'https://storage.googleapis.com/static-images-source/facebook-icon.webp'
    },
    tiktok: {
        platformName: 'TikTok Ads',
        platformImg: 'https://storage.googleapis.com/static-images-source/tiktok-icon.png'
    },
    google: {
        platformName: 'Google Ads',
        platformImg: 'https://storage.googleapis.com/static-images-source/google-logo-icon.png'
    },
    outros: {
        platformName: 'Orgânico / Outros',
        platformImg: 'https://storage.googleapis.com/static-images-source/other-source-icon.png'
    },
    // Adicione mais plataformas e seus ícones conforme necessário
};

export function calculateFinalOrders (columnSolomon: string, ads : any, activePaymentMethods: any, isADiferentSource?: boolean, utmChannel?: boolean, moreDetailsOthers?: boolean) {
    switch (columnSolomon) {
        case 'totalSolomon':
            return calculateSumOfOrders('total', ads, activePaymentMethods, isADiferentSource, utmChannel, moreDetailsOthers);
        case 'approvedSolomon':
            return calculateSumOfOrders('approved', ads, activePaymentMethods, isADiferentSource, utmChannel, moreDetailsOthers);
        case 'pendingSolomon':
            return calculateSumOfOrders('pending', ads, activePaymentMethods, isADiferentSource, utmChannel, moreDetailsOthers);
        case 'cancelledSolomon':
            return calculateSumOfOrders('cancelled', ads, activePaymentMethods, isADiferentSource, utmChannel, moreDetailsOthers);
        default:
            return 0;
    }
}

export function calculateFinalRevenue (columnSolomon: string, ads : any, activePaymentMethods: any, isADiferentSource?: boolean, moreDetailsOthers?: boolean, isOthers?: boolean) {
    switch (columnSolomon) {
        case 'totalSolomon':
            return calculateSumOfRevenue('total', ads, activePaymentMethods, isADiferentSource, moreDetailsOthers, isOthers);
        case 'approvedSolomon':
            return calculateSumOfRevenue('approved', ads, activePaymentMethods, isADiferentSource, moreDetailsOthers, isOthers);
        case 'pendingSolomon':
            return calculateSumOfRevenue('pending', ads, activePaymentMethods, isADiferentSource, moreDetailsOthers, isOthers);
        case 'cancelledSolomon':
            return calculateSumOfRevenue('cancelled', ads, activePaymentMethods, isADiferentSource, moreDetailsOthers, isOthers);
        default:
            return 0;
    }
}

export function calculateSumOfRevenue (columnType: string, ads : any, activePaymentMethods: any, isADiferentSource?: boolean, moreDetailsOthers?: boolean, isOthers?: boolean)  {
    let sum = 0; // Inicializa a soma como 0
    let anyMethodActive = false; // Variável para rastrear se algum método de pagamento está ativo
    
    const getMetricKey = (metricType, paymentMethod) => {
        return columnType === 'total' ? `${metricType}_${paymentMethod}` : `${columnType}_${metricType}_${paymentMethod}`;
    };

    if (isPaymentMethodActive('PIX', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('revenue', 'pix')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('revenue', 'pix')] || 0;
        }
        
        anyMethodActive = true;

    }
    if (isPaymentMethodActive('Boleto', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('revenue', 'boleto')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('revenue', 'boleto')] || 0;
        }
        anyMethodActive = true;

    }
    if (isPaymentMethodActive('Cartão de Crédito', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('revenue', 'cc')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('revenue', 'cc')] || 0;
        }
        anyMethodActive = true;
    }

    if(isADiferentSource){
        if (columnType === 'total') {
            return anyMethodActive || sum > 0 ? sum : ads?.['revenue'].metrics || 0; // Retorna a soma se maior que zero, senão 'orders.metrics'
        } else {
            return anyMethodActive || sum > 0 ? sum : ads?.[`${columnType}_revenue`].metrics || 0; // Retorna a soma se maior que zero, senão a métrica padrão
        }
    }   
    if (isOthers){
        return anyMethodActive || sum > 0 ? sum : ads?.[`${columnType}Revenue`] || 0;
    }
    if (moreDetailsOthers){
        if (columnType === 'total') {
            return anyMethodActive || sum > 0 ? sum : ads['revenue'].metrics || 0; // Retorna a soma se maior que zero, senão 'orders.metrics'
        } else {
            const capitalizedColumnType = columnType;
            return anyMethodActive || sum > 0 ? sum : ads[`${capitalizedColumnType}_revenue`].metrics || 0; // Retorna a soma se maior que zero, senão a métrica padrão
        }
    }
    
    const capitalizedColumnType = capitalizeFirstLetter(columnType);
    return anyMethodActive || sum > 0 ? sum : ads?.[`revenue${capitalizedColumnType}`] || 0; // Retorna a soma se maior que zero, senão a métrica padrão
}

export function isPaymentMethodActive (method:string, activePaymentMethods: any) { 
    return activePaymentMethods.includes(method);
}  

// Utilizando a função auxiliar para obter a soma correta com base no tipo de coluna

export function calculateSumOfOrders (columnType: string, ads : any, activePaymentMethods: any, isADiferentSource?: boolean, utmChannel?: boolean, moreDetailsOthers?: boolean)  {
    let sum = 0; // Inicializa a soma como 0
    let anyMethodActive = false; // Variável para rastrear se algum método de pagamento está ativo
    // Função auxiliar para obter a chave correta
    const getMetricKey = (metricType, paymentMethod) => {
        return columnType === 'total' ? `${metricType}_${paymentMethod}` : `${columnType}_${metricType}_${paymentMethod}`;
    };
    
    if (isPaymentMethodActive('PIX', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('orders', 'pix')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('orders', 'pix')] || 0;
        }
        anyMethodActive = true;
    }
    if (isPaymentMethodActive('Boleto', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('orders', 'boleto')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('orders', 'boleto')] || 0;
        }
        anyMethodActive = true;
    }
    if (isPaymentMethodActive('Cartão de Crédito', activePaymentMethods)) {
        if (moreDetailsOthers){
            sum += ads?.[getMetricKey('orders', 'cc')]?.metrics || 0;
        }else{
            sum += ads?.[getMetricKey('orders', 'cc')] || 0;
        }
        anyMethodActive = true;
    }
    if (isADiferentSource){
        if (columnType === 'total') {
            return anyMethodActive || sum > 0 ? sum : ads?.['orders'].metrics || 0; // Retorna a soma se maior que zero, senão 'orders.metrics'
        } else {
        return anyMethodActive || sum > 0 ? sum : ads?.[`${columnType}_orders`].metrics || 0; // Retorna a soma se maior que zero, senão a métrica padrão
        }
    }
    if(utmChannel){
        const capitalizedColumnType = columnType === 'total' ? 'Total' : capitalizeFirstLetter(columnType);
        return anyMethodActive || sum > 0 ? sum : ads[`orders${capitalizedColumnType}`] || 0;
    }
    if(moreDetailsOthers){
        if (columnType === 'total') {
            return anyMethodActive || sum > 0 ? sum : ads['orders'].metrics || 0; // Retorna a soma se maior que zero, senão 'orders.metrics'
        } else {
            const capitalizedColumnType = columnType;
            return anyMethodActive || sum > 0 ? sum : ads[`${capitalizedColumnType}_orders`].metrics || 0; // Retorna a soma se maior que zero, senão a métrica padrão
        }
    }
    
    
    return anyMethodActive || sum > 0 ? sum : ads?.[`${columnType}Orders`] || 0; // Retorna a soma se maior que zero, senão a métrica padrão
};

export function  capitalizeFirstLetter (word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
};
export function checkUtm (type: string, utm_source: string, utm_medium: string, utm_campaign: string, utm_content: string) {
    if (type === "facebook") {
        if (utm_source !== "\{\{site_source_name\}\}" || utm_medium !== "\{\{placement\}\}" || utm_campaign !== "\{\{campaign.id\}\}" || utm_content !== "\{\{ad.id\}\}") {
            return false;
        } 
    }
    if (type === "google") {
        if (utm_source !== "google" || utm_campaign !== "\{campaignid\}" || utm_content !== "\{creative\}") {
            return false;
        }
    }
    if (type === "tiktok") {
        if (utm_source !== "tiktok" || utm_medium !== "tiktok_ads" || utm_campaign !== "__CAMPAIGN_ID__" || utm_content !== "__CID__") {
            return false;
        }
    }
    return true;
}
export function getMetricsTrackingByData(data: any, type: string, activePaymentMethods?: any) {

    const revenueBySource = parseFloat(
        (type === 'facebook'
            ? data?.facebook_campaigns_metrics?.revenue?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.revenue?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.revenue?.per_source?.metric
                : 0
        )?.toFixed(2)
    );

    const ordersBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.purchases?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.purchases?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.purchases?.per_source?.metric
                : 0;

    const spendBySource = parseFloat(
        (type === 'facebook'
            ? data?.facebook_campaigns_metrics?.spend?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.spend?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.spend?.per_source?.metric
                : 0
        )?.toFixed(2)
    );

    // //console.log('google profit',data, data?.utm_metrics.utm_metrics.profit.source.gZoogle.metrics - data?.google_campaigns_metrics?.spend?.per_source?.metric)

    const profitByUtmApproved = parseFloat(
        (type === 'facebook'
            ? data?.utm_metrics?.utm_metrics?.profit?.source?.facebook?.metrics - data?.facebook_campaigns_metrics?.spend?.per_source?.metric
            : type === 'tiktok'
              ?  data?.tiktok_campaigns_metrics?.utm_profit?.per_source?.metric - data?.tiktok_campaigns_metrics?.spend?.per_source?.metric
              : type === 'google'
                ?  data?.utm_metrics?.utm_metrics?.profit?.source?.google?.metrics - data?.google_campaigns_metrics?.spend?.per_source?.metric
                : 0
        )?.toFixed(2)
    );

    const productCostByUtmApproved = parseFloat(
        (type === 'facebook'
            ? data?.utm_metrics?.utm_metrics?.product_cost?.source?.facebook?.metrics
            : type === 'tiktok'
              ?  data?.tiktok_campaigns_metrics?.utm_product_cost?.per_source?.metric
              : type === 'google'
                ?  data?.utm_metrics?.utm_metrics?.product_cost?.source?.google?.metrics
                : 0
        )?.toFixed(2)
    );

    const clicksBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.clicks?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.clicks?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.clicks?.per_source?.metric
                : 0;

    const impressionsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.impressions?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.impressions?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.impressions?.per_source?.metric
                : 0;
 
    const checkoutsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.checkouts?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.checkouts?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.checkouts?.per_source?.metric
                : 0;


    const pageViewsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.pageviews?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.pageviews?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.pageviews?.per_source?.metric
                : 0;
  
    const addToCartsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.add_to_carts?.per_source?.metric
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.add_to_carts?.per_source?.metric
              : type === 'google'
                ? data?.google_campaigns_metrics?.add_to_carts?.per_source?.metric
                : 0;


    const revenueTotal = parseFloat((data?.utm_metrics?.utm_metrics?.revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenueApproved = parseFloat((data?.utm_metrics?.utm_metrics?.approved_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenuePending = parseFloat((data?.utm_metrics?.utm_metrics?.pending_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    const revenueCancelled = parseFloat((data?.utm_metrics?.utm_metrics?.cancelled_revenue?.source?.[type]?.metrics ?? 0)?.toFixed(2));
    
    const ordersTotal = data?.utm_metrics?.utm_metrics?.orders?.source?.[type]?.metrics ?? 0;
    const ordersApproved = data?.utm_metrics?.utm_metrics?.approved_orders?.source?.[type]?.metrics ?? 0;
    const ordersPending = data?.utm_metrics?.utm_metrics?.pending_orders?.source?.[type]?.metrics ?? 0;
    const ordersCancelled = data?.utm_metrics?.utm_metrics?.cancelled_orders?.source?.[type]?.metrics ?? 0;


    let paymentMethodMetrics; 
    if (activePaymentMethods && activePaymentMethods?.length > 0) {
        paymentMethodMetrics = getMetricsByPaymentMethod(data, type, activePaymentMethods);
    }

    return {
        spendBySource,
        profitByUtmApproved,
        productCostByUtmApproved,
        ordersTotal,
        ordersApproved,
        ordersPending,
        ordersCancelled,
        ordersBySource,
        revenueTotal,
        revenueApproved,
        revenuePending,
        revenueCancelled,
        revenueBySource,
        clicksBySource,
        checkoutsBySource,
        impressionsBySource,
        pageViewsBySource,
        addToCartsBySource,
        ...paymentMethodMetrics
    };
}

function getMetricsByPaymentMethod(data, type, paymentMethods) {
    const results = {};
    const metricTypes = [
        'revenue', 'orders', 'approved_orders', 'cancelled_orders', 'pending_orders',
        'approved_revenue', 'cancelled_revenue', 'pending_revenue'
    ];

    // Processar cada métrica básica
    metricTypes.forEach(metricType => {
        const metricKey = metricType; // chave para a métrica básica
        results[metricKey] = parseFloat((data?.utm_metrics?.utm_metrics[metricKey]?.source?.[type]?.metrics ?? 0).toFixed(2));

        // Processar métricas específicas de cada método de pagamento
        paymentMethods.forEach(method => {
            let methodKey = method.toLowerCase(); // chave correspondente ao método de pagamento

            if (methodKey === 'cartão de crédito') {
                methodKey = 'cc';
            }
            
            const specificMetricKey = `${metricType}_${methodKey}`; // chave para métrica específica do método de pagamento

            // Se existir essa métrica específica no objeto de dados, adiciona ao resultado
            if (data?.utm_metrics?.utm_metrics.hasOwnProperty(specificMetricKey)) {
                results[specificMetricKey] = parseFloat((data?.utm_metrics?.utm_metrics[specificMetricKey]?.source?.[type]?.metrics ?? 0).toFixed(2));
            }
        });
    });

    return results;
}

export function getNonListedSourcesMetrics(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google', 'sem_atribuicao', ''];
    const nonListedSourcesMetrics: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    nonListedSourcesMetrics[sourceKey] = nonListedSourcesMetrics?.[sourceKey] || {};
                    nonListedSourcesMetrics[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    const organizedNonListedSourcesMetrics = Object.keys(nonListedSourcesMetrics).map((source: any) => {
        return { ...nonListedSourcesMetrics[source], sourceName: source };
    });


    return { nonListedSourcesMetrics, organizedNonListedSourcesMetrics };
}

export function getNonListedSourcesChart(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google', 'sem_atribuicao'];
    const nonListedSourcesChart: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    nonListedSourcesChart[sourceKey] = nonListedSourcesChart?.[sourceKey] || {};
                    nonListedSourcesChart[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    const organizedNonListedSourcesChart = Object.keys(nonListedSourcesChart).map((source: any) => {
        return { ...nonListedSourcesChart[source], sourceName: source };
    });

    return { nonListedSourcesChart, organizedNonListedSourcesChart };
}

export function getMetricsByAdsAccountFacebook(data: any) {
    const metricsByFacebookAdsAccount = [];
    const sumMetricsFacebook = defaultTotalLine();

    for (const accountId in data?.facebook_ad_accounts_metrics?.spend?.per_account?.metric) {

        const accountMetrics = data?.facebook_ad_accounts_metrics;
        const accountName = data?.facebook_ad_accounts_metrics?.aux?.accounts_maps?.[accountId];
        const spendBySource = parseFloat((accountMetrics?.spend?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const profitByUtmApproved = parseFloat((accountMetrics?.utm_profit?.per_account?.metric?.[accountId] || 0)?.toFixed(2)) - parseFloat((accountMetrics?.spend?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const productCostByUtmApproved = accountMetrics?.utm_product_cost?.per_account?.metric?.[accountId] || 0;
        const totalOrders = accountMetrics?.utm_purchases?.per_account?.metric?.[accountId] || 0;
        const approvedOrders = accountMetrics?.utm_approved_purchases?.per_account?.metric?.[accountId] || 0;
        const pendingOrders = accountMetrics?.utm_pending_purchases?.per_account?.metric?.[accountId] || 0;
        const cancelledOrders = accountMetrics?.utm_cancelled_purchases?.per_account?.metric?.[accountId] || 0;
        const ordersBySource = accountMetrics?.purchases?.per_account?.metric?.[accountId] || 0;
        const revenueBySource = parseFloat((accountMetrics?.revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueTotal = parseFloat((accountMetrics?.utm_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueApproved = parseFloat((accountMetrics?.utm_approved_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenuePending = parseFloat((accountMetrics?.utm_pending_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const revenueCancelled = parseFloat((accountMetrics?.utm_cancelled_revenue?.per_account?.metric?.[accountId] || 0)?.toFixed(2));
        const clicksBySource = accountMetrics?.clicks?.per_account?.metric?.[accountId] || 0;
        const impressionsBySource = accountMetrics?.impressions?.per_account?.metric?.[accountId] || 0;
        const addToCartsBySource = accountMetrics?.add_to_carts?.per_account?.metric?.[accountId] || 0;
        const pageViewsBySource = accountMetrics?.pageviews?.per_account?.metric?.[accountId] || 0;
        const checkoutsBySource = accountMetrics?.checkouts?.per_account?.metric?.[accountId] || 0;


        const approved_orders_pix = accountMetrics?.utm_approved_purchases_pix?.per_account?.metric?.[accountId] || 0;
        const pending_orders_pix = accountMetrics?.utm_pending_purchases_pix?.per_account?.metric?.[accountId] || 0;
        const cancelled_orders_pix = accountMetrics?.utm_cancelled_purchases_pix?.per_account?.metric?.[accountId] || 0;
        const approved_orders_boleto = accountMetrics?.utm_approved_purchases_boleto?.per_account?.metric?.[accountId] || 0;
        const pending_orders_boleto = accountMetrics?.utm_pending_purchases_boleto?.per_account?.metric?.[accountId] || 0;
        const cancelled_orders_boleto = accountMetrics?.utm_cancelled_purchases_boleto?.per_account?.metric?.[accountId] || 0;
        const approved_orders_cc = accountMetrics?.utm_approved_purchases_cc?.per_account?.metric?.[accountId] || 0;
        const pending_orders_cc = accountMetrics?.utm_pending_purchases_cc?.per_account?.metric?.[accountId] || 0;
        const cancelled_orders_cc = accountMetrics?.utm_cancelled_purchases_cc?.per_account?.metric?.[accountId] || 0;
        
        const orders_pix = accountMetrics?.utm_purchases_pix?.per_account?.metric?.[accountId] || 0;
        const orders_boleto = accountMetrics?.utm_purchases_boleto?.per_account?.metric?.[accountId] || 0;
        const orders_cc = accountMetrics?.utm_purchases_cc?.per_account?.metric?.[accountId] || 0;

        const approved_revenue_pix = accountMetrics?.utm_approved_revenue_pix?.per_account?.metric?.[accountId] || 0;
        const pending_revenue_pix = accountMetrics?.utm_pending_revenue_boleto?.per_account?.metric?.[accountId] || 0;
        const cancelled_revenue_pix = accountMetrics?.utm_cancelled_revenue_cc?.per_account?.metric?.[accountId] || 0;
        const approved_revenue_boleto = accountMetrics?.utm_approved_revenue_pix?.per_account?.metric?.[accountId] || 0;
        const pending_revenue_boleto = accountMetrics?.utm_pending_revenue_boleto?.per_account?.metric?.[accountId] || 0;
        const cancelled_revenue_boleto = accountMetrics?.utm_cancelled_revenue_cc?.per_account?.metric?.[accountId] || 0;
        const approved_revenue_cc = accountMetrics?.utm_approved_revenue_pix?.per_account?.metric?.[accountId] || 0;
        const pending_revenue_cc = accountMetrics?.utm_pending_revenue_boleto?.per_account?.metric?.[accountId] || 0;
        const cancelled_revenue_cc = accountMetrics?.utm_cancelled_revenue_cc?.per_account?.metric?.[accountId] || 0;

        const revenue_pix = accountMetrics?.utm_revenue_pix?.per_account?.metric?.[accountId] || 0;
        const revenue_boleto = accountMetrics?.utm_revenue_boleto?.per_account?.metric?.[accountId] || 0;
        const revenue_cc = accountMetrics?.utm_revenue_cc?.per_account?.metric?.[accountId] || 0;

        const roasBySource = accountMetrics?.spend?.per_account?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.revenue?.per_account?.metric?.[accountId] / accountMetrics.spend?.per_account?.metric?.[accountId]).toFixed(2)) : 0;
        const roasTotal = accountMetrics.spend?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.utm_revenue?.per_adgroup?.metric?.[accountId] / accountMetrics.spend?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const roasPending = accountMetrics.spend?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.utm_pending_revenue?.per_adgroup?.metric?.[accountId] / accountMetrics.spend?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const roasApproved = accountMetrics.spend?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.utm_approved_revenue?.per_adgroup?.metric?.[accountId] / accountMetrics.spend?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const roasCancelled = accountMetrics.spend?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.utm_cancelled_revenue?.per_adgroup?.metric?.[accountId] / accountMetrics.spend?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
    
        const cpaBySource = accountMetrics.purchases?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.purchases?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const cpaTotal = accountMetrics.purchases?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.utm_purchases?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const cpaApproved = accountMetrics.purchases?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.utm_approved_purchases?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const cpaPending = accountMetrics.purchases?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.utm_pending_purchases?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const cpaCancelled = accountMetrics.purchases?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.utm_cancelled_purchases?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;

        const cpcBySource = accountMetrics.clicks?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.clicks?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;
        const cpmBySource = accountMetrics.impressions?.per_adgroup?.metric?.[accountId] !== 0 ? parseFloat((accountMetrics.spend?.per_adgroup?.metric?.[accountId] / accountMetrics.impressions?.per_adgroup?.metric?.[accountId]).toFixed(2)) : 0;


        sumMetricsFacebook.spendBySource += spendBySource;
        sumMetricsFacebook.profitByUtmApproved += profitByUtmApproved;
        sumMetricsFacebook.productCostByUtmApproved += productCostByUtmApproved;
        sumMetricsFacebook.totalOrders += totalOrders;
        sumMetricsFacebook.approvedOrders += approvedOrders;
        sumMetricsFacebook.pendingOrders += pendingOrders;
        sumMetricsFacebook.cancelledOrders += cancelledOrders;
        sumMetricsFacebook.ordersBySource += ordersBySource;
        sumMetricsFacebook.revenueBySource += revenueBySource;
        sumMetricsFacebook.revenueTotal += revenueTotal;
        sumMetricsFacebook.revenueApproved += revenueApproved;
        sumMetricsFacebook.revenuePending += revenuePending;
        sumMetricsFacebook.revenueCancelled += revenueCancelled;
        sumMetricsFacebook.clicksBySource += clicksBySource;
        sumMetricsFacebook.impressionsBySource += impressionsBySource;
        sumMetricsFacebook.addToCartsBySource += addToCartsBySource;
        sumMetricsFacebook.pageViewsBySource += pageViewsBySource;
        sumMetricsFacebook.checkoutsBySource += checkoutsBySource;

        sumMetricsFacebook.approved_orders_pix += approved_orders_pix;
        sumMetricsFacebook.pending_orders_pix += pending_orders_pix;
        sumMetricsFacebook.cancelled_orders_pix += cancelled_orders_pix;
        sumMetricsFacebook.approved_orders_boleto += approved_orders_boleto;
        sumMetricsFacebook.pending_orders_boleto += pending_orders_boleto;
        sumMetricsFacebook.cancelled_orders_boleto += cancelled_orders_boleto;
        sumMetricsFacebook.approved_orders_cc += approved_orders_cc;
        sumMetricsFacebook.pending_orders_cc += pending_orders_cc;
        sumMetricsFacebook.cancelled_orders_cc += cancelled_orders_cc;
        sumMetricsFacebook.orders_pix += orders_pix;
        sumMetricsFacebook.orders_boleto += orders_boleto;
        sumMetricsFacebook.orders_cc += orders_cc;
        sumMetricsFacebook.approved_revenue_pix += approved_revenue_pix;
        sumMetricsFacebook.pending_revenue_pix += pending_revenue_pix;
        sumMetricsFacebook.cancelled_revenue_pix += cancelled_revenue_pix;
        sumMetricsFacebook.approved_revenue_boleto += approved_revenue_boleto;
        sumMetricsFacebook.pending_revenue_boleto += pending_revenue_boleto;
        sumMetricsFacebook.cancelled_revenue_boleto += cancelled_revenue_boleto;
        sumMetricsFacebook.approved_revenue_cc += approved_revenue_cc;
        sumMetricsFacebook.pending_revenue_cc += pending_revenue_cc;
        sumMetricsFacebook.cancelled_revenue_cc += cancelled_revenue_cc;
        sumMetricsFacebook.revenue_pix += revenue_pix;
        sumMetricsFacebook.revenue_boleto += revenue_boleto;
        sumMetricsFacebook.revenue_cc += revenue_cc;

        sumMetricsFacebook.roasBySource += roasBySource;
        sumMetricsFacebook.roasTotal += roasTotal;
        sumMetricsFacebook.roasPending += roasPending;
        sumMetricsFacebook.roasApproved += roasApproved;
        sumMetricsFacebook.roasCancelled += roasCancelled;
        sumMetricsFacebook.cpaBySource += cpaBySource;
        sumMetricsFacebook.cpaTotal += cpaTotal;
        sumMetricsFacebook.cpaApproved += cpaApproved;
        sumMetricsFacebook.cpaPending += cpaPending;
        sumMetricsFacebook.cpaCancelled += cpaCancelled;
        sumMetricsFacebook.cpcBySource += cpcBySource;
        sumMetricsFacebook.cpmBySource += cpmBySource;
        
        

        metricsByFacebookAdsAccount.push({
            accountId,
            accountName,
            spendBySource,
            profitByUtmApproved,
            productCostByUtmApproved,
            totalOrders,
            approvedOrders,
            pendingOrders,
            cancelledOrders,
            ordersBySource,
            revenueBySource,
            revenueTotal,
            revenueApproved,
            revenuePending,
            revenueCancelled,
            clicksBySource,
            impressionsBySource,
            addToCartsBySource,
            pageViewsBySource,
            checkoutsBySource,
            approved_orders_pix,
            pending_orders_pix,
            cancelled_orders_pix,
            approved_orders_boleto,
            pending_orders_boleto,
            cancelled_orders_boleto,
            approved_orders_cc,
            pending_orders_cc,
            cancelled_orders_cc,
            orders_pix,
            orders_boleto,
            orders_cc,
            approved_revenue_pix,
            pending_revenue_pix,
            cancelled_revenue_pix,
            approved_revenue_boleto,
            pending_revenue_boleto,
            cancelled_revenue_boleto,
            approved_revenue_cc,
            pending_revenue_cc,
            cancelled_revenue_cc,
            revenue_pix,
            revenue_boleto,
            revenue_cc,
            roasBySource,
            roasTotal,
            roasPending,
            roasApproved,
            roasCancelled,
            cpaBySource,
            cpaTotal,
            cpaApproved,
            cpaPending,
            cpaCancelled,
            cpcBySource,
            cpmBySource,
        });
    }

    return {
        metricsByFacebookAdsAccount,
        sumMetricsFacebook,
    };
}

export function getOthersMetrics(data: any) {


    const otherMetrics = {
        totalOrders: 0,
        approvedOrders: 0,
        pendingOrders: 0,
        cancelledOrders: 0,
        totalRevenue: 0,
        approvedRevenue: 0,
        pendingRevenue: 0,
        cancelledRevenue: 0,
        profitOthers: 0,

        approved_orders_pix: 0,
        pending_orders_pix: 0,
        cancelled_orders_pix: 0,
        approved_orders_boleto: 0,
        pending_orders_boleto: 0,
        cancelled_orders_boleto: 0,
        approved_orders_cc: 0,
        pending_orders_cc: 0,
        cancelled_orders_cc: 0,
        
        orders_pix: 0,
        orders_boleto: 0,
        orders_cc: 0,

        approved_revenue_pix: 0,
        pending_revenue_pix: 0,
        cancelled_revenue_pix: 0,
        approved_revenue_boleto: 0,
        pending_revenue_boleto: 0,
        cancelled_revenue_boleto: 0,
        approved_revenue_cc: 0,
        pending_revenue_cc: 0,
        cancelled_revenue_cc: 0,

        revenue_pix: 0,
        revenue_boleto: 0,
        revenue_cc: 0,

    };

    const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);


    Object.keys(nonListedSourcesMetrics)?.map((source: string) => {
        const { profit, orders, approved_orders, pending_orders, cancelled_orders, revenue, approved_revenue, pending_revenue, cancelled_revenue, approved_orders_pix, pending_orders_pix, cancelled_orders_pix, approved_orders_boleto, pending_orders_boleto, cancelled_orders_boleto, approved_orders_cc, pending_orders_cc, cancelled_orders_cc, orders_pix, orders_cc, orders_boleto, approved_revenue_pix, pending_revenue_pix, cancelled_revenue_pix, approved_revenue_boleto, pending_revenue_boleto, cancelled_revenue_boleto, approved_revenue_cc, pending_revenue_cc, cancelled_revenue_cc, revenue_pix, revenue_cc, revenue_boleto } = nonListedSourcesMetrics?.[source];
        otherMetrics.totalOrders += orders?.metrics;
        otherMetrics.approvedOrders += approved_orders?.metrics;
        otherMetrics.pendingOrders += pending_orders?.metrics;
        otherMetrics.cancelledOrders += cancelled_orders?.metrics;

        otherMetrics.totalRevenue += revenue?.metrics;
        otherMetrics.approvedRevenue += approved_revenue?.metrics;
        otherMetrics.pendingRevenue += pending_revenue?.metrics;
        otherMetrics.cancelledRevenue += cancelled_revenue?.metrics;
        
        otherMetrics.profitOthers += profit?.metrics;

        otherMetrics.approved_orders_pix += approved_orders_pix?.metrics;
        otherMetrics.pending_orders_pix += pending_orders_pix?.metrics;
        otherMetrics.cancelled_orders_pix += cancelled_orders_pix?.metrics;
        otherMetrics.approved_orders_boleto += approved_orders_boleto?.metrics;
        otherMetrics.pending_orders_boleto += pending_orders_boleto?.metrics;
        otherMetrics.cancelled_orders_boleto += cancelled_orders_boleto?.metrics;
        otherMetrics.approved_orders_cc += approved_orders_cc?.metrics;
        otherMetrics.pending_orders_cc += pending_orders_cc?.metrics;
        otherMetrics.cancelled_orders_cc += cancelled_orders_cc?.metrics;
        
        otherMetrics.orders_pix += orders_pix?.metrics;
        otherMetrics.orders_boleto += orders_boleto?.metrics;
        otherMetrics.orders_cc += orders_cc?.metrics;

        otherMetrics.approved_revenue_pix += approved_revenue_pix?.metrics;
        otherMetrics.pending_revenue_pix += pending_revenue_pix?.metrics;
        otherMetrics.cancelled_revenue_pix += cancelled_revenue_pix?.metrics;
        otherMetrics.approved_revenue_boleto += approved_revenue_boleto?.metrics;
        otherMetrics.pending_revenue_boleto += pending_revenue_boleto?.metrics;
        otherMetrics.cancelled_revenue_boleto += cancelled_revenue_boleto?.metrics;
        otherMetrics.approved_revenue_cc += approved_revenue_cc?.metrics;
        otherMetrics.pending_revenue_cc += pending_revenue_cc?.metrics;
        otherMetrics.cancelled_revenue_cc += cancelled_revenue_cc?.metrics;

        otherMetrics.revenue_pix += revenue_pix?.metrics;
        otherMetrics.revenue_boleto += revenue_boleto?.metrics;
        otherMetrics.revenue_cc += revenue_cc?.metrics;
    });

    return { otherMetrics };
}

export function getTotalLineByChannel(data: any, activePaymentMethods?: any) {
    const totalLine = defaultTotalLine();

    const types = ['facebook', 'tiktok', 'google'];

    for (const type of types) {
        const metrics = getMetricsTrackingByData(data, type, activePaymentMethods);
        
        totalLine.spendBySource += metrics?.spendBySource;
        totalLine.profitByUtmApproved += metrics?.profitByUtmApproved;
        totalLine.productCostByUtmApproved += metrics?.productCostByUtmApproved;
        totalLine.totalOrders += metrics?.ordersTotal;
        totalLine.approvedOrders += metrics?.ordersApproved;
        totalLine.pendingOrders += metrics?.ordersPending;
        totalLine.cancelledOrders += metrics?.ordersCancelled;
        totalLine.ordersBySource += metrics?.ordersBySource;
        totalLine.revenueBySource += metrics?.revenueBySource;
        totalLine.revenueTotal += metrics?.revenueTotal;
        totalLine.revenueApproved += metrics?.revenueApproved;
        totalLine.revenuePending += metrics?.revenuePending;
        totalLine.revenueCancelled += metrics?.revenueCancelled;
        totalLine.clicksBySource += metrics?.clicksBySource;
        totalLine.impressionsBySource += metrics?.impressionsBySource;
        totalLine.pageViewsBySource += metrics?.pageViewsBySource;
        totalLine.checkoutsBySource += metrics?.checkoutsBySource;
        totalLine.addToCartsBySource += metrics?.addToCartsBySource;

        totalLine.approved_orders_pix += metrics?.approved_orders_pix;
        totalLine.pending_orders_pix += metrics?.pending_orders_pix;
        totalLine.cancelled_orders_pix += metrics?.cancelled_orders_pix;
        totalLine.approved_orders_boleto += metrics?.approved_orders_boleto;
        totalLine.pending_orders_boleto += metrics?.pending_orders_boleto;
        totalLine.cancelled_orders_boleto += metrics?.cancelled_orders_boleto;
        totalLine.approved_orders_cc += metrics?.approved_orders_cc;
        totalLine.pending_orders_cc += metrics?.pending_orders_cc;
        totalLine.cancelled_orders_cc += metrics?.cancelled_orders_cc;
        totalLine.orders_pix += metrics?.orders_pix;
        totalLine.orders_boleto += metrics?.orders_boleto;
        totalLine.orders_cc += metrics?.orders_cc;
        totalLine.approved_revenue_pix += metrics?.approved_revenue_pix;
        totalLine.pending_revenue_pix += metrics?.pending_revenue_pix;
        totalLine.cancelled_revenue_pix += metrics?.cancelled_revenue_pix;
        totalLine.approved_revenue_boleto += metrics?.approved_revenue_boleto;
        totalLine.pending_revenue_boleto += metrics?.pending_revenue_boleto;
        totalLine.cancelled_revenue_boleto += metrics?.cancelled_revenue_boleto;
        totalLine.approved_revenue_cc += metrics?.approved_revenue_cc;
        totalLine.pending_revenue_cc += metrics?.pending_revenue_cc;
        totalLine.cancelled_revenue_cc += metrics?.cancelled_revenue_cc;
        totalLine.revenue_pix += metrics?.revenue_pix;
        totalLine.revenue_boleto += metrics?.revenue_boleto;
        totalLine.revenue_cc += metrics?.revenue_cc;
    }

        const { otherMetrics } = getOthersMetrics(data);

        
        totalLine.totalOrders += otherMetrics?.totalOrders;
        totalLine.profitByUtmApproved += otherMetrics?.profitOthers;
        totalLine.productCostByUtmApproved += otherMetrics?.profitOthers;
        totalLine.approvedOrders += otherMetrics?.approvedOrders;
        totalLine.pendingOrders += otherMetrics?.pendingOrders;
        totalLine.cancelledOrders += otherMetrics?.cancelledOrders;
        totalLine.revenueTotal += otherMetrics?.totalRevenue;
        totalLine.revenueApproved += otherMetrics?.approvedRevenue;
        totalLine.revenuePending += otherMetrics?.pendingRevenue;
        totalLine.revenueCancelled += otherMetrics?.cancelledRevenue;

        totalLine.approved_orders_pix += otherMetrics?.approved_orders_pix;
        totalLine.pending_orders_pix += otherMetrics?.pending_orders_pix;
        totalLine.cancelled_orders_pix += otherMetrics?.cancelled_orders_pix;
        totalLine.approved_orders_boleto += otherMetrics?.approved_orders_boleto;
        totalLine.pending_orders_boleto += otherMetrics?.pending_orders_boleto;
        totalLine.cancelled_orders_boleto += otherMetrics?.cancelled_orders_boleto;
        totalLine.approved_orders_cc += otherMetrics?.approved_orders_cc;
        totalLine.pending_orders_cc += otherMetrics?.pending_orders_cc;
        totalLine.cancelled_orders_cc += otherMetrics?.cancelled_orders_cc;
        totalLine.orders_pix += otherMetrics?.orders_pix;
        totalLine.orders_boleto += otherMetrics?.orders_boleto;
        totalLine.orders_cc += otherMetrics?.orders_cc;
        totalLine.approved_revenue_pix += otherMetrics?.approved_revenue_pix;
        totalLine.pending_revenue_pix += otherMetrics?.pending_revenue_pix;
        totalLine.cancelled_revenue_pix += otherMetrics?.cancelled_revenue_pix;
        totalLine.approved_revenue_boleto += otherMetrics?.approved_revenue_boleto;
        totalLine.pending_revenue_boleto += otherMetrics?.pending_revenue_boleto;
        totalLine.cancelled_revenue_boleto += otherMetrics?.cancelled_revenue_boleto;
        totalLine.approved_revenue_cc += otherMetrics?.approved_revenue_cc;
        totalLine.pending_revenue_cc += otherMetrics?.pending_revenue_cc;
        totalLine.cancelled_revenue_cc += otherMetrics?.cancelled_revenue_cc;
        totalLine.revenue_pix += otherMetrics?.revenue_pix;
        totalLine.revenue_boleto += otherMetrics?.revenue_boleto;
        totalLine.revenue_cc += otherMetrics?.revenue_cc;

        return totalLine;
    }

export function getTotalLineByCampaign(dataFiltred: any, defaultType: string) {

    const totalLine = defaultTotalLine();

    dataFiltred.forEach((campaign: any) => {
        let type = campaign.sourceType === 'outros' ? 'outros' : defaultType;

        if (type === 'outros' || type === 'sem_atribuicao') {
            totalLine.totalOrders += campaign?.orders?.metrics || 0;
            totalLine.approvedOrders += campaign?.approved_orders?.metrics || 0;
            totalLine.pendingOrders += campaign?.pending_orders?.metrics || 0;
            totalLine.cancelledOrders += campaign?.cancelled_orders?.metrics || 0;
            totalLine.revenueTotal += campaign?.revenue?.metrics || 0;
            totalLine.revenueApproved += campaign?.approved_revenue?.metrics || 0;
            totalLine.revenuePending += campaign?.pending_revenue?.metrics || 0;
            totalLine.revenueCancelled += campaign?.cancelled_revenue?.metrics || 0;
            totalLine.profitByUtmApproved += campaign?.profit?.metrics || 0;
            totalLine.productCostByUtmApproved += campaign?.product_cost?.metrics || 0;

            totalLine.approved_orders_pix += campaign?.approved_orders_pix?.metrics || 0;
            totalLine.pending_orders_pix += campaign?.pending_orders_pix?.metrics || 0;
            totalLine.cancelled_orders_pix += campaign?.cancelled_orders_pix?.metrics || 0;
            totalLine.approved_orders_boleto += campaign?.approved_orders_boleto?.metrics || 0;
            totalLine.pending_orders_boleto += campaign?.pending_orders_boleto?.metrics || 0;
            totalLine.cancelled_orders_boleto += campaign?.cancelled_orders_boleto?.metrics || 0;
            totalLine.approved_orders_cc += campaign?.approved_orders_cc?.metrics || 0;
            totalLine.pending_orders_cc += campaign?.pending_orders_cc?.metrics || 0;
            totalLine.cancelled_orders_cc += campaign?.cancelled_orders_cc?.metrics || 0;
            totalLine.orders_pix += campaign?.orders_pix?.metrics || 0;
            totalLine.orders_boleto += campaign?.orders_boleto?.metrics || 0;
            totalLine.orders_cc += campaign?.orders_cc?.metrics || 0;
            totalLine.approved_revenue_pix += campaign?.approved_revenue_pix?.metrics || 0;
            totalLine.pending_revenue_pix += campaign?.pending_revenue_pix?.metrics || 0;
            totalLine.cancelled_revenue_pix += campaign?.cancelled_revenue_pix?.metrics || 0;
            totalLine.approved_revenue_boleto += campaign?.approved_revenue_boleto?.metrics || 0;
            totalLine.pending_revenue_boleto += campaign?.pending_revenue_boleto?.metrics || 0;
            totalLine.cancelled_revenue_boleto += campaign?.cancelled_revenue_boleto?.metrics || 0;
            totalLine.approved_revenue_cc += campaign?.approved_revenue_cc?.metrics || 0;
            totalLine.pending_revenue_cc += campaign?.pending_revenue_cc?.metrics || 0;
            totalLine.cancelled_revenue_cc += campaign?.cancelled_revenue_cc?.metrics || 0;
            totalLine.revenue_pix += campaign?.revenue_pix?.metrics || 0;
            totalLine.revenue_boleto += campaign?.revenue_boleto?.metrics || 0;
            totalLine.revenue_cc += campaign?.revenue_cc?.metrics || 0;
        } else {
            totalLine.spendBySource += campaign?.spendBySource || 0;
            totalLine.profitByUtmApproved += campaign?.profitByUtmApproved || 0;
            totalLine.productCostByUtmApproved += campaign?.productCostByUtmApproved || 0;
            totalLine.totalOrders += campaign?.totalOrders || 0;
            totalLine.approvedOrders += campaign?.approvedOrders || 0;
            totalLine.pendingOrders += campaign?.pendingOrders || 0;
            totalLine.cancelledOrders += campaign?.cancelledOrders || 0;
            totalLine.ordersBySource += campaign?.ordersBySource || 0;
            totalLine.revenueBySource += campaign?.revenueBySource || 0;
            totalLine.revenueTotal += campaign?.revenueTotal || 0;
            totalLine.revenueApproved += campaign?.revenueApproved || 0;
            totalLine.revenuePending += campaign?.revenuePending || 0;
            totalLine.revenueCancelled += campaign?.revenueCancelled || 0;
            totalLine.clicksBySource += campaign?.clicksBySource || 0;
            totalLine.impressionsBySource += campaign?.impressionsBySource || 0;
            totalLine.checkoutsBySource += campaign?.checkoutsBySource || 0;
            totalLine.pageViewsBySource += campaign?.pageViewsBySource || 0;
            totalLine.addToCartsBySource += campaign?.addToCartsBySource || 0;

            totalLine.approved_orders_pix += campaign?.approved_orders_pix  || 0;
            totalLine.pending_orders_pix += campaign?.pending_orders_pix || 0;
            totalLine.cancelled_orders_pix += campaign?.cancelled_orders_pix || 0;
            totalLine.approved_orders_boleto += campaign?.approved_orders_boleto || 0;
            totalLine.pending_orders_boleto += campaign?.pending_orders_boleto || 0;
            totalLine.cancelled_orders_boleto += campaign?.cancelled_orders_boleto || 0;
            totalLine.approved_orders_cc += campaign?.approved_orders_cc || 0;
            totalLine.pending_orders_cc += campaign?.pending_orders_cc || 0;
            totalLine.cancelled_orders_cc += campaign?.cancelled_orders_cc || 0;
            totalLine.orders_pix += campaign?.orders_pix || 0;
            totalLine.orders_boleto += campaign?.orders_boleto || 0;
            totalLine.orders_cc += campaign?.orders_cc || 0;
            totalLine.approved_revenue_pix += campaign?.approved_revenue_pix || 0;
            totalLine.pending_revenue_pix += campaign?.pending_revenue_pix || 0;
            totalLine.cancelled_revenue_pix += campaign?.cancelled_revenue_pix || 0;
            totalLine.approved_revenue_boleto += campaign?.approved_revenue_boleto || 0;
            totalLine.pending_revenue_boleto += campaign?.pending_revenue_boleto || 0;
            totalLine.cancelled_revenue_boleto += campaign?.cancelled_revenue_boleto || 0;
            totalLine.approved_revenue_cc += campaign?.approved_revenue_cc || 0;
            totalLine.pending_revenue_cc += campaign?.pending_revenue_cc || 0;
            totalLine.cancelled_revenue_cc += campaign?.cancelled_revenue_cc || 0;
            totalLine.revenue_pix += campaign?.revenue_pix || 0;
            totalLine.revenue_boleto += campaign?.revenue_boleto || 0;
            totalLine.revenue_cc += campaign?.revenue_cc || 0;
        }
    });

    return totalLine;
}

export function getMetricsByChannelFacebook(data: any, activePaymentMethods?: any) {
    const { metricsByFacebookAdsAccount, sumMetricsFacebook } = getMetricsByAdsAccountFacebook(data);

    const metrics = getMetricsTrackingByData(data, 'facebook', activePaymentMethods);

    if (sumMetricsFacebook.totalOrders < metrics.ordersTotal) {
        metricsByFacebookAdsAccount.push({
            accountId: 'sem_atribuicao',
            accountName: 'Sem atribuição',
            spendBySource: 0,
            totalOrders: metrics.ordersTotal - sumMetricsFacebook.totalOrders,
            approvedOrders: metrics.ordersApproved - sumMetricsFacebook.approvedOrders,
            pendingOrders: metrics.ordersPending - sumMetricsFacebook.pendingOrders,
            cancelledOrders: metrics.ordersCancelled - sumMetricsFacebook.cancelledOrders,
            ordersBySource: metrics.ordersBySource - sumMetricsFacebook.ordersBySource,
            revenueBySource: metrics.revenueBySource - sumMetricsFacebook.revenueBySource,
            revenueTotal: metrics.revenueTotal - sumMetricsFacebook.revenueTotal,
            revenueApproved: metrics.revenueApproved - sumMetricsFacebook.revenueApproved,
            revenuePending: metrics.revenuePending - sumMetricsFacebook.revenuePending,
            revenueCancelled: metrics.revenueCancelled - sumMetricsFacebook.revenueCancelled,
            clicksBySource: metrics.clicksBySource - sumMetricsFacebook.clicksBySource,
            impressionsBySource: metrics.impressionsBySource - sumMetricsFacebook.impressionsBySource,
            checkoutsBySource: metrics.checkoutsBySource - sumMetricsFacebook.checkoutsBySource,
            pageViewsBySource: metrics.pageViewsBySource - sumMetricsFacebook.pageViewsBySource,
            addToCartsBySource: metrics.addToCartsBySource - sumMetricsFacebook.addToCartsBySource,   
            profitByUtmApproved: metrics.profitByUtmApproved - sumMetricsFacebook.profitByUtmApproved,
            productCostByUtmApproved: metrics.productCostByUtmApproved - sumMetricsFacebook.productCostByUtmApproved,
            
            approved_orders_pix: metrics.approved_orders_pix - sumMetricsFacebook.approved_orders_pix,
            pending_orders_pix: metrics.pending_orders_pix - sumMetricsFacebook.pending_orders_pix,
            cancelled_orders_pix: metrics.cancelled_orders_pix - sumMetricsFacebook.cancelled_orders_pix,
            approved_orders_boleto: metrics.approved_orders_boleto - sumMetricsFacebook.approved_orders_boleto,
            pending_orders_boleto: metrics.pending_orders_boleto - sumMetricsFacebook.pending_orders_boleto,
            cancelled_orders_boleto: metrics.cancelled_orders_boleto - sumMetricsFacebook.cancelled_orders_boleto,
            approved_orders_cc: metrics.approved_orders_cc - sumMetricsFacebook.approved_orders_cc,
            pending_orders_cc: metrics.pending_orders_cc - sumMetricsFacebook.pending_orders_cc,
            cancelled_orders_cc: metrics.cancelled_orders_cc - sumMetricsFacebook.cancelled_orders_cc,
            orders_pix: metrics.orders_pix - sumMetricsFacebook.orders_pix,
            orders_boleto: metrics.orders_boleto - sumMetricsFacebook.orders_boleto,
            orders_cc: metrics.orders_cc - sumMetricsFacebook.orders_cc,
            approved_revenue_pix: metrics.approved_revenue_pix - sumMetricsFacebook.approved_revenue_pix,
            pending_revenue_pix: metrics.pending_revenue_pix - sumMetricsFacebook.pending_revenue_pix,
            cancelled_revenue_pix: metrics.cancelled_revenue_pix - sumMetricsFacebook.cancelled_revenue_pix,
            approved_revenue_boleto: metrics.approved_revenue_boleto - sumMetricsFacebook.approved_revenue_boleto,
            pending_revenue_boleto: metrics.pending_revenue_boleto - sumMetricsFacebook.pending_revenue_boleto,
            cancelled_revenue_boleto: metrics.cancelled_revenue_boleto - sumMetricsFacebook.cancelled_revenue_boleto,
            approved_revenue_cc: metrics.approved_revenue_cc - sumMetricsFacebook.approved_revenue_cc,
            pending_revenue_cc: metrics.pending_revenue_cc - sumMetricsFacebook.pending_revenue_cc,
            cancelled_revenue_cc: metrics.cancelled_revenue_cc - sumMetricsFacebook.cancelled_revenue_cc,
            revenue_pix: metrics.revenue_pix - sumMetricsFacebook.revenue_pix,
            revenue_boleto: metrics.revenue_boleto - sumMetricsFacebook.revenue_boleto,
            revenue_cc: metrics.revenue_cc - sumMetricsFacebook.revenue_cc,

        });
    }
    return {
        metricsByFacebookAdsAccount,
    };
}

export function getMetricsCampaignsByChannel(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google'];
    const getDataSourcesMetrics: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    getDataSourcesMetrics[sourceKey] = getDataSourcesMetrics?.[sourceKey] || {};
                    getDataSourcesMetrics[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    return { getDataSourcesMetrics };
}

export function organizeDataByCampaign(dataMetrics: any): any {
    const types: any = {};
    
    for (const data in dataMetrics) {
        if (dataMetrics.hasOwnProperty(data)) {
            types[data] = {};
            const campaigns = dataMetrics[data];
            for (const campanhaKey in campaigns) {
                if (campaigns.hasOwnProperty(campanhaKey)) {
                    const campanha = campaigns[campanhaKey];
                    for (const campaignKey in campanha) {
                        if (campanha.hasOwnProperty(campaignKey)) {
                            const campaignData = campanha[campaignKey];
                            for (const metricKey in campaignData) {
                                if (campaignData.hasOwnProperty(metricKey)) {
                                    if (!types[data][metricKey]) {
                                        types[data][metricKey] = {};
                                    }
                                    types[data][metricKey][campanhaKey] = campaignData[metricKey];
                                }
                            }
                        }
                    }
                }
            }
        }
    }



    return types;
}

export function getIconForPlatform(platformKey: string) {
    switch (platformKey) {
        case 'facebook_campaigns_metrics':
        case 'facebook':
        case 'facebook_ads_metrics':
            return 'https://storage.googleapis.com/static-images-source/facebook-icon-tracking.webp';
        case 'google_campaigns_metrics':
        case 'google':
        case 'google_ads_metrics':
            return 'https://storage.googleapis.com/static-images-source/google-icon.png';
        case 'tiktok_campaigns_metrics':
        case 'tiktok':
        case 'tiktok_ads_metrics':
            return 'https://storage.googleapis.com/static-images-source/tiktok-icon-tracking.png';
        case 'outros':
            return 'https://storage.googleapis.com/static-images-source/other-source-tracking.png';
        default:
            return 'path/to/default/icon';
    }
}

function extractMetrics(platformData:any, campaignId:any) {

    // //console.log('platformData', platformData)
    
    return {
        spendBySource: platformData.spend?.per_campaign?.metric?.[campaignId],
        profitByUtmApproved: platformData.utm_profit?.per_campaign?.metric?.[campaignId] - platformData.spend?.per_campaign?.metric?.[campaignId],
        productCostByUtmApproved: platformData?.utm_product_cost?.per_campaign?.metric?.[campaignId],
        totalOrders: platformData.utm_purchases?.per_campaign?.metric?.[campaignId],
        approvedOrders: platformData.utm_approved_purchases?.per_campaign?.metric?.[campaignId],
        pendingOrders: platformData.utm_pending_purchases?.per_campaign?.metric?.[campaignId],
        cancelledOrders: platformData.utm_cancelled_purchases?.per_campaign?.metric?.[campaignId],
        ordersBySource: platformData.purchases?.per_campaign?.metric?.[campaignId],
        revenueBySource: platformData.revenue?.per_campaign?.metric?.[campaignId],
        revenueTotal: platformData.utm_revenue?.per_campaign?.metric?.[campaignId],
        revenueApproved: platformData.utm_approved_revenue?.per_campaign?.metric?.[campaignId],
        revenuePending: platformData.utm_pending_revenue?.per_campaign?.metric?.[campaignId],
        revenueCancelled: platformData.utm_cancelled_revenue?.per_campaign?.metric?.[campaignId],
        clicksBySource: platformData.clicks?.per_campaign?.metric?.[campaignId],
        impressionsBySource: platformData.impressions?.per_campaign?.metric?.[campaignId],
        checkoutsBySource: platformData.checkouts?.per_campaign?.metric?.[campaignId],
        addToCartsBySource: platformData.add_to_carts?.per_campaign?.metric?.[campaignId],
        pageViewsBySource: platformData.pageviews?.per_campaign?.metric?.[campaignId],

        approved_orders_pix: platformData.utm_approved_purchases_pix?.per_campaign?.metric?.[campaignId],
        pending_orders_pix: platformData.utm_pending_purchases_pix?.per_campaign?.metric?.[campaignId],
        cancelled_orders_pix: platformData.utm_cancelled_purchases_pix?.per_campaign?.metric?.[campaignId],
        approved_orders_boleto: platformData.utm_approved_purchases_boleto?.per_campaign?.metric?.[campaignId],
        pending_orders_boleto: platformData.utm_pending_purchases_boleto?.per_campaign?.metric?.[campaignId],
        cancelled_orders_boleto: platformData.utm_cancelled_purchases_boleto?.per_campaign?.metric?.[campaignId],
        approved_orders_cc: platformData.utm_approved_purchases_cc?.per_campaign?.metric?.[campaignId],
        pending_orders_cc: platformData.utm_pending_purchases_cc?.per_campaign?.metric?.[campaignId],
        cancelled_orders_cc: platformData.utm_cancelled_purchases_cc?.per_campaign?.metric?.[campaignId],
        orders_pix: platformData.utm_purchases_pix?.per_campaign?.metric?.[campaignId],
        orders_boleto: platformData.utm_purchases_boleto?.per_campaign?.metric?.[campaignId],
        orders_cc: platformData.utm_purchases_cc?.per_campaign?.metric?.[campaignId],
        approved_revenue_pix: platformData.utm_approved_revenue_pix?.per_campaign?.metric?.[campaignId],
        pending_revenue_pix: platformData.utm_pending_revenue_pix?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_pix: platformData.utm_cancelled_revenue_pix?.per_campaign?.metric?.[campaignId],
        approved_revenue_boleto: platformData.utm_approved_revenue_boleto?.per_campaign?.metric?.[campaignId],
        pending_revenue_boleto: platformData.utm_pending_revenue_boleto?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_boleto: platformData.utm_cancelled_revenue_boleto?.per_campaign?.metric?.[campaignId],
        approved_revenue_cc: platformData.utm_approved_revenue_cc?.per_campaign?.metric?.[campaignId],
        pending_revenue_cc: platformData.utm_pending_revenue_cc?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_cc: platformData.utm_cancelled_revenue_cc?.per_campaign?.metric?.[campaignId],
        revenue_pix: platformData.utm_revenue_pix?.per_campaign?.metric?.[campaignId],
        revenue_boleto: platformData.utm_revenue_boleto?.per_campaign?.metric?.[campaignId],
        revenue_cc: platformData.utm_revenue_cc?.per_campaign?.metric?.[campaignId],

        roasBySource: platformData.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.revenue?.per_campaign?.metric?.[campaignId] / platformData.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasTotal: platformData.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.utm_revenue?.per_campaign?.metric?.[campaignId] / platformData.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasPending: platformData.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.utm_pending_revenue?.per_campaign?.metric?.[campaignId] / platformData.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasApproved: platformData.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.utm_approved_revenue?.per_campaign?.metric?.[campaignId] / platformData.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasCancelled: platformData.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.utm_cancelled_revenue?.per_campaign?.metric?.[campaignId] / platformData.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
    
        cpaBySource: platformData.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaTotal: platformData.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.utm_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaApproved: platformData.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.utm_approved_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaPending: platformData.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.utm_pending_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaCancelled: platformData.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.utm_cancelled_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,

        cpcBySource: platformData.clicks?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.clicks?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpmBySource: platformData.impressions?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((platformData.spend?.per_campaign?.metric?.[campaignId] / platformData.impressions?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,

    };
}

export function getAccountCampaignData(data: any, type: string, accountId: string, channelSelect: string, selectedPlatforms: string[], selectedPlatformsOthers: string[], dataCatalogList: any) {
    const types = ['sem_atribuicao'];
    let allCampaignsData = [];

    if (selectedPlatformsOthers.length === 0 && selectedPlatforms.length === 0) {
        selectedPlatforms = ['facebook', 'google', 'tiktok', 'outros'];
    }

    // //console.log('data catalog utils', dataCatalogList)    

    if (selectedPlatformsOthers.length > 0) {
        
        const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);
        const organizedData = organizeDataByCampaign(nonListedSourcesMetrics) ?? {};
        
        // //console.log('organizedData', organizedData);
        //roasBySource roasTotal 
    
        // Primeiro, processa as plataformas não listadas (selectedPlatformsOthers)
        Object.keys(organizedData).forEach(accountId => {
            if (selectedPlatformsOthers?.includes(accountId)) {
                let filteredAccountCampaignData = Object.keys(organizedData[accountId])
                    .filter(key => key !== 'y_axis' && key !== 'x_axis')
                    .map(key => {
                        return {
                            ...organizedData[accountId][key],
                            accountId: accountId,
                            campaignName: key,
                            channelSelected: 'outros',
                            sourceType: 'outros',
                            campaignSourceIcon: getIconForPlatform('outros'),
                            approved_orders_pix: organizedData[accountId][key]?.utm_approved_purchases_pix?.metrics,
                        };
                    });
                    allCampaignsData = allCampaignsData.concat(filteredAccountCampaignData);
            }
        });
        return allCampaignsData;
    }

    if (selectedPlatforms.length > 0) {
        let allCampaignsData: any[] = [];

        // Mapeamento de chaves de plataforma para seus respectivos dados
        const platformKeys: any = {
            facebook: 'facebook_campaigns_metrics',
            google: 'google_campaigns_metrics',
            tiktok: 'tiktok_campaigns_metrics',
            outros: 'outros'
        };
    
        selectedPlatforms.forEach(platform => {
        if (!platformKeys[platform]) {
                const platformData = data?.facebook_campaigns_metrics;
        
                // Aqui pegamos o mapeamento de ID da conta para o nome da conta
                const accountNamesMap = data.facebook_ad_accounts_metrics.aux.accounts_maps;
                const campaignNames = platformData.aux?.campaigns_names;
                const campaignsStatus = platformData.aux?.campaigns_status;
                const campaignAccounts = platformData.aux?.campaigns_accounts;
        
        
                for (const campaignId in campaignAccounts) {
                    const accountId = campaignAccounts?.[campaignId];
        
                    // Only process campaigns that match the account ID from selectedPlatforms
                    if (accountId === platform) {
                        const campaignMetrics = platformData;
        
                        const campaignData = getCampaignsDataWithMetrics(campaignMetrics,accountId, campaignId, accountNamesMap);
        
                        allCampaignsData.push({
                            ...campaignData,
                            campaignId,
                            isCatalog: dataCatalogList?.includes(campaignId),
                            accountId,
                            sourceType: 'facebook_campaigns_metrics',
                            campaignSourceIcon: getIconForPlatform('facebook'),
                            campaignName: campaignNames[campaignId],
                            campaignStatus: campaignsStatus[campaignId],
                        });
                }
            }
        } 
        
        if (platform === 'outros') {
            const { nonListedSourcesMetrics } = getNonListedSourcesMetrics(data);
            const organizedData = organizeDataByCampaign(nonListedSourcesMetrics) ?? {};
            
            
            Object.keys(organizedData).forEach(accountId => {
                let filtredAccountCampaignData = Object.keys(organizedData[accountId])
                    .filter(key => key !== 'y_axis' && key !== 'x_axis')
                    .map(key => {
                        return {
                            ...organizedData[accountId][key],
                            campaignName: key,
                            channelSelected: platform,
                            sourceType: 'outros',  // Assumindo um tipo genérico 'others' para não-mapeados
                            campaignSourceIcon: getIconForPlatform('outros')  // Supondo que você tenha ícones para 'outros'
                            
                        };
                    });

                // Combinar dados filtrados de todas as contas
                allCampaignsData = allCampaignsData.concat(filtredAccountCampaignData);
            });

        }
        
        else if (data[platformKeys[platform]]) {
            const platformData = data[platformKeys[platform]];
            const campaignNames = platformData.aux.campaigns_names;
            const campaignsStatus = platformData.aux.campaigns_status;
            const campaignAccounts = platformData.aux.campaigns_accounts;

            Object.keys(campaignNames).forEach(campaignId => {
                const campaignName = campaignNames[campaignId];
                const campaignStatus = campaignsStatus[campaignId];

                allCampaignsData.push({
                    accountId: campaignAccounts[campaignId],
                    channelSelected: platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase(),
                    campaignId: campaignId,
                    isCatalog: dataCatalogList?.includes(campaignId),
                    sourceType: platformKeys[platform],
                    campaignSourceIcon: getIconForPlatform(platform),
                    campaignName: campaignName,
                    campaignStatus: campaignStatus,
                    ...extractMetrics(platformData, campaignId)
                });
            });
        }
        });
    

        return allCampaignsData;
    }
    
}


export function getAdsSetData(data: any, type: string, campaignId?: string, selectedCampaignIds: any[] = [], dataCatalogList?: any) {
    const { selectedPlatforms } = useCheckboxTracking();

    let thisSelectedPlatforms = {...selectedPlatforms};

    const isAnyPlatformSelected = Object.values(thisSelectedPlatforms).some(value => value === true);

     // Verifica se o tipo é 'outros', 'Whatsapp' ou 'Email/SMS' e retorna um array vazio
     if (['outros'].includes(type)) {
        const adsSetData: any[] = [];
        return adsSetData; // Retorna vazio
    }

    const platforms = ['facebook', 'google', 'tiktok'];
    const excludedPlatforms = [ 'outros', 'sem_atribuicao']; // Plataformas para excluir

    let campaignNamesMap: any = {};
    platforms.forEach(platform => {
        const platformCampaignData = data[`${platform}_campaigns_metrics`];
        if (platformCampaignData && platformCampaignData.aux && platformCampaignData.aux.campaigns_names) {
            campaignNamesMap = { ...campaignNamesMap, ...platformCampaignData.aux.campaigns_names };
        }
    });

    const adsSetData: any[] = [];

    if (selectedCampaignIds.length === 0 && !isAnyPlatformSelected){
        platforms.forEach(platform => {
            thisSelectedPlatforms[platform] = true;
        });
    }

    if (Object.values(thisSelectedPlatforms).some(value => value === true) && selectedCampaignIds.length === 0) {
        const adsSetIds = new Set();
        
        const activePlatforms = Object.entries(thisSelectedPlatforms)
            .filter(([key, value]) => value && !excludedPlatforms.includes(key))
            .map(([key]) => key);

        activePlatforms.forEach(platformKey => {
            const isStandardPlatform = platforms?.includes(platformKey);
            const platformDataKey = isStandardPlatform ? platformKey : 'facebook';
            const platformAdgroupsData = data[`${platformDataKey}_adgroups_metrics`];
            const adGroupsNames = platformAdgroupsData.aux.adgroups_names;
            const adGroupsStatus = platformAdgroupsData.aux.adgroups_status;
            const adGroupsCampaigns = platformAdgroupsData.aux.adgroups_campaigns;
            const adGroupsAccounts = platformAdgroupsData.aux.adgroups_accounts;

            if (!isStandardPlatform) {
                processNonStandardPlatform(data, platformKey, platformDataKey, platformAdgroupsData, adsSetData, adsSetIds, adGroupsNames, adGroupsStatus, adGroupsCampaigns, dataCatalogList);
            } else {
    
                Object.keys(adGroupsNames).forEach(adgroupId => {
                    if (adsSetIds.has(adgroupId)) return;
                    adsSetIds.add(adgroupId);

                    const adgroupName = adGroupsNames[adgroupId];
                    const adgroupStatus = adGroupsStatus[adgroupId];
                    const campaignAssociatedId = adGroupsCampaigns[adgroupId];
                    const campaignNameKey = `${platformDataKey}_campaigns_metrics`;
                    const campaignName = data[campaignNameKey]?.aux?.campaigns_names[campaignAssociatedId];
                    const adGroupAccount = adGroupsAccounts[adgroupId];

                    if (!adgroupName || !campaignName) return;

                    adsSetData.push( getAdSetDataWithMetrics(platformAdgroupsData, adgroupId, adgroupName, adgroupStatus, campaignAssociatedId, campaignName, adGroupAccount, platformDataKey, dataCatalogList) );
                });
            }
        });

    }else if (selectedCampaignIds.length > 0) {
        selectedCampaignIds.forEach(({ id, type }) => {
            const platformKey = type.split('_')[0];  // Extrai 'google' de 'google_campaigns_metrics'
            const platformAdgroupsData = data[`${platformKey}_adgroups_metrics`];

            if (platformAdgroupsData) {
                const adGroupsNames = platformAdgroupsData.aux.adgroups_names;
                const adGroupsStatus = platformAdgroupsData.aux.adgroups_status;
                const adGroupsCampaigns = platformAdgroupsData.aux.adgroups_campaigns;
                const adGroupsAccounts = platformAdgroupsData.aux.adgroups_accounts;
                
                Object.keys(adGroupsNames).forEach(adgroupId => {
                    const adgroupName = adGroupsNames[adgroupId];
                    const adgroupStatus = adGroupsStatus[adgroupId];
                    const campaignAssociatedId = adGroupsCampaigns[adgroupId];
                    const adGroupAccount = adGroupsAccounts[adgroupId];

                    // Verifica se o adgroup está associado ao ID de campanha selecionado
                    if (id !== campaignAssociatedId) {
                        return;
                    }

                    const campaignName = data[`${platformKey}_campaigns_metrics`].aux.campaigns_names[campaignAssociatedId];
                    if (!adgroupName || !campaignName) return; // Ignora se o nome do adgroup ou o nome da campanha não estiverem disponíveis
                    adsSetData.push(getAdSetDataWithMetrics(platformAdgroupsData, adgroupId, adgroupName, adgroupStatus, campaignAssociatedId, campaignName, adGroupAccount, platformKey, dataCatalogList));
                });
            }
        });
    }

    return adsSetData;
}

function processNonStandardPlatform(data: any, platformKey: any, platformDataKey: any, platformAdgroupsData: any, adsSetData: any, adsSetIds: any, adGroupsNames: any, adGroupsStatus: any, adGroupsCampaigns: any, dataCatalogList?: any) {
    const adgroupAccounts = platformAdgroupsData.aux.adgroups_accounts;
    const adgroupKeys = Object.keys(adgroupAccounts);
    for (let i = 0; i < adgroupKeys.length; i++) {
        const adgroupId = adgroupKeys[i];
        const accountId = adgroupAccounts[adgroupId];

        if (accountId !== platformKey || adsSetIds.has(adgroupId)) {
            continue;
        }

        adsSetIds.add(adgroupId);

        const adgroupName = adGroupsNames[adgroupId];
        const adgroupStatus = adGroupsStatus[adgroupId];
        const campaignAssociatedId = adGroupsCampaigns[adgroupId];
        const campaignNameKey = `${platformDataKey}_campaigns_metrics`;
        const campaignNames = data[campaignNameKey]?.aux?.campaigns_names;
        const campaignName = campaignNames ? campaignNames[campaignAssociatedId] : undefined;

        if (!adgroupName || !campaignName) {
            continue;
        }

        const adgroupMetrics = platformAdgroupsData.spend.per_adgroup.metric[adgroupId];
        adsSetData.push({
            adsSetId: adgroupId,
            isCatalog: dataCatalogList?.includes(adgroupId),
            adsSetName: adgroupName,
            adsSetStatus: adgroupStatus,
            accountId: accountId,
            campaignId: campaignAssociatedId,
            campaignName: campaignName,
            sourceType: platformDataKey,
            adsSetSourceIcon: getIconForPlatform(platformDataKey),
            profitByUtmApproved:platformAdgroupsData.utm_profit.per_adgroup.metric[adgroupId] - platformAdgroupsData.spend.per_adgroup.metric[adgroupId],
            productCostByUtmApproved:platformAdgroupsData?.utm_product_cost?.per_adgroup.metric[adgroupId],
            spendBySource: adgroupMetrics,
            totalOrders: platformAdgroupsData.utm_purchases.per_adgroup.metric[adgroupId],
            approvedOrders: platformAdgroupsData.utm_approved_purchases.per_adgroup.metric[adgroupId],
            pendingOrders: platformAdgroupsData.utm_pending_purchases.per_adgroup.metric[adgroupId],
            cancelledOrders: platformAdgroupsData.utm_cancelled_purchases.per_adgroup.metric[adgroupId],
            ordersBySource: platformAdgroupsData.purchases.per_adgroup.metric[adgroupId],
            revenueBySource: platformAdgroupsData.revenue.per_adgroup.metric[adgroupId],
            revenueTotal: platformAdgroupsData.utm_revenue.per_adgroup.metric[adgroupId],
            revenueApproved: platformAdgroupsData.utm_approved_revenue.per_adgroup.metric[adgroupId],
            revenuePending: platformAdgroupsData.utm_pending_revenue.per_adgroup.metric[adgroupId],
            revenueCancelled: platformAdgroupsData.utm_cancelled_revenue.per_adgroup.metric[adgroupId],
            clicksBySource: platformAdgroupsData.clicks.per_adgroup.metric[adgroupId],
            impressionsBySource: platformAdgroupsData.impressions.per_adgroup.metric[adgroupId],
            checkoutsBySource: platformAdgroupsData.checkouts.per_adgroup.metric[adgroupId],
            pageViewsBySource: platformAdgroupsData.pageviews.per_adgroup.metric[adgroupId],
            addToCartsBySource: platformAdgroupsData.add_to_carts.per_adgroup.metric[adgroupId],
            approved_orders_pix: platformAdgroupsData.utm_approved_purchases_pix.per_adgroup.metric[adgroupId],
            pending_orders_pix: platformAdgroupsData.utm_pending_purchases_pix.per_adgroup.metric[adgroupId],
            cancelled_orders_pix: platformAdgroupsData.utm_cancelled_purchases_pix.per_adgroup.metric[adgroupId],
            approved_orders_boleto: platformAdgroupsData.utm_approved_purchases_boleto.per_adgroup.metric[adgroupId],
            pending_orders_boleto: platformAdgroupsData.utm_pending_purchases_boleto.per_adgroup.metric[adgroupId],
            cancelled_orders_boleto: platformAdgroupsData.utm_cancelled_purchases_boleto.per_adgroup.metric[adgroupId],
            approved_orders_cc: platformAdgroupsData.utm_approved_purchases_cc.per_adgroup.metric[adgroupId],
            pending_orders_cc: platformAdgroupsData.utm_pending_purchases_cc.per_adgroup.metric[adgroupId],
            cancelled_orders_cc: platformAdgroupsData.utm_cancelled_purchases_cc.per_adgroup.metric[adgroupId],
            orders_pix: platformAdgroupsData.utm_purchases_pix.per_adgroup.metric[adgroupId],
            orders_boleto: platformAdgroupsData.utm_purchases_boleto.per_adgroup.metric[adgroupId],
            orders_cc: platformAdgroupsData.utm_purchases_cc.per_adgroup.metric[adgroupId],
            approved_revenue_pix: platformAdgroupsData.utm_approved_revenue_pix.per_adgroup.metric[adgroupId],
            pending_revenue_pix: platformAdgroupsData.utm_pending_revenue_pix.per_adgroup.metric[adgroupId],
            cancelled_revenue_pix: platformAdgroupsData.utm_cancelled_revenue_pix.per_adgroup.metric[adgroupId],
            approved_revenue_boleto: platformAdgroupsData.utm_approved_revenue_boleto.per_adgroup.metric[adgroupId],
            pending_revenue_boleto: platformAdgroupsData.utm_pending_revenue_boleto.per_adgroup.metric[adgroupId],
            cancelled_revenue_boleto: platformAdgroupsData.utm_cancelled_revenue_boleto.per_adgroup.metric[adgroupId],
            approved_revenue_cc: platformAdgroupsData.utm_approved_revenue_cc.per_adgroup.metric[adgroupId],
            pending_revenue_cc: platformAdgroupsData.utm_pending_revenue_cc.per_adgroup.metric[adgroupId],
            cancelled_revenue_cc: platformAdgroupsData.utm_cancelled_revenue_cc.per_adgroup.metric[adgroupId],
            revenue_pix: platformAdgroupsData.utm_revenue_pix.per_adgroup.metric[adgroupId],
            revenue_boleto: platformAdgroupsData.utm_revenue_boleto.per_adgroup.metric[adgroupId],
            revenue_cc: platformAdgroupsData.utm_revenue_cc.per_adgroup.metric[adgroupId],

            roasBySource: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            roasTotal: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            roasPending: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_pending_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            roasApproved: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_approved_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            roasCancelled: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_cancelled_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        
            cpaBySource: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            cpaTotal: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            cpaApproved: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_approved_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            cpaPending: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_pending_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            cpaCancelled: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_cancelled_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
    
            cpcBySource: platformAdgroupsData.clicks?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.clicks?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
            cpmBySource: platformAdgroupsData.impressions?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.impressions?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        });
    }
}


function getAdsSetDataForUtm(data: any, campaignId?: string, dataCatalogList?: any) {
    const platforms = ['facebook', 'google', 'tiktok'];


    const adsSetData: any[] = [];
    const campaignNamesMap: any = {};

    platforms.forEach(platformKey => {
        const platformCampaignData = data[`${platformKey}_campaigns_metrics`];
        const platformAdgroupsData = data[`${platformKey}_adgroups_metrics`];

        if (platformCampaignData && platformCampaignData.aux && platformCampaignData.aux.campaigns_names) {
            Object.assign(campaignNamesMap, platformCampaignData.aux.campaigns_names);
        }

        if (platformAdgroupsData) {
            const adGroupsNames = platformAdgroupsData.aux.adgroups_names;
            const adGroupsCampaigns = platformAdgroupsData.aux.adgroups_campaigns;

            Object.keys(adGroupsNames).forEach(adgroupId => {
                const adgroupName = adGroupsNames[adgroupId];
                const campaignAssociatedId = adGroupsCampaigns[adgroupId];

                // Se campaignId foi fornecido e não corresponde ao campaignAssociatedId, continue para o próximo
                if (campaignId && campaignId !== campaignAssociatedId) {
                    return;
                }

                const campaignName = campaignNamesMap[campaignAssociatedId];
                if (!adgroupName || !campaignName) return; // Ignora se o nome do adgroup ou o nome da campanha não estiverem disponíveis

                adsSetData.push({
                    isCatalog: dataCatalogList?.includes(adgroupId),
                    adsSetId: adgroupId,
                    adsSetName: adgroupName,
                    campaignId: campaignAssociatedId,
                    campaignName: campaignName,
                    sourceType: platformKey,
                    adsSetSourceIcon: getIconForPlatform(platformKey),
                    spendBySource: platformAdgroupsData.spend.per_adgroup.metric[adgroupId],
                    totalOrders: platformAdgroupsData.utm_purchases.per_adgroup.metric[adgroupId],
                    approvedOrders: platformAdgroupsData.utm_approved_purchases.per_adgroup.metric[adgroupId],
                    pendingOrders: platformAdgroupsData.utm_pending_purchases.per_adgroup.metric[adgroupId],
                    cancelledOrders: platformAdgroupsData.utm_cancelled_purchases.per_adgroup.metric[adgroupId],
                    ordersBySource: platformAdgroupsData.purchases.per_adgroup.metric[adgroupId],
                    revenueBySource: platformAdgroupsData.revenue.per_adgroup.metric[adgroupId],
                    revenueTotal: platformAdgroupsData.utm_revenue.per_adgroup.metric[adgroupId],
                    revenueApproved: platformAdgroupsData.utm_approved_revenue.per_adgroup.metric[adgroupId],
                    revenuePending: platformAdgroupsData.utm_pending_revenue.per_adgroup.metric[adgroupId],
                    revenueCancelled: platformAdgroupsData.utm_cancelled_revenue.per_adgroup.metric[adgroupId],
                    clicksBySource: platformAdgroupsData.clicks.per_adgroup.metric[adgroupId],
                    impressionsBySource: platformAdgroupsData.impressions.per_adgroup.metric[adgroupId],
                    checkoutsBySource: platformAdgroupsData.checkouts.per_adgroup.metric[adgroupId],
                    pageViewsBySource: platformAdgroupsData.pageviews.per_adgroup.metric[adgroupId],
                    addToCartsBySource: platformAdgroupsData.add_to_carts.per_adgroup.metric[adgroupId],
                });
            });
        }
    });

    return adsSetData;
}

export function getAdsData(data: any, type: string, adsSetId?: string, selectedAdSets: any[] = [], dataCatalogList?: any) {
console
    const { selectedPlatforms, selectedCampaignIds } = useCheckboxTracking();
    let thisSelectedPlatforms = {...selectedPlatforms};


    if (['outros'].includes(type)) {
        const adsData: any[] = [];

        return adsData; // Retorna vazio
    }

    const standardPlatforms = ['facebook', 'google', 'tiktok']; // Defina as plataformas padrão
    const excludedPlatforms = ['outros']; // Plataformas para excluir

    if (selectedAdSets.length === 0 && !Object.values(thisSelectedPlatforms).some(value => value === true) && selectedCampaignIds.length === 0) {
        standardPlatforms.forEach(platform => {
            thisSelectedPlatforms[platform] = true;
        });
    }

    // se selectedadsets for 0, isanyplatformselected for true e selectedcampaignids for true, puxar só os ads do type que vem em selectedcampainids
    if (selectedAdSets.length > 0) {
        const adsData: any[] = [];

        selectedAdSets.forEach(({ id, type }) => {
            const platformKey = type.split('_')[0];  // Extrai 'google' de 'google_adgroups_metrics'
            const platformAdsData = data[`${platformKey}_ads_metrics`];

            if (platformAdsData) {
                const adsNames = platformAdsData.aux.ads_names;
                const adsStatus = platformAdsData.aux.ads_status;
                const adsObjects = platformAdsData.aux.ads_objects;
                
                Object.keys(adsObjects).forEach(adId => {
                    const adGroupInfo = adsObjects[adId]; // Informações do grupo de anúncios
                    const adName = adsNames[adId]; // Nome do anúncio
                    const adStatus = adsStatus[adId]; // Nome do anúncio
                    const adGroupId = adGroupInfo.adgroup; // ID do grupo de anúncios
                    const adAccount = platformAdsData.aux.ads_objects[adId].account;

                    // Filtra apenas os anúncios que pertencem ao ad set especificado
                    if (id !== adGroupId) {
                        return;
                    }

                    const adGroupName = data[`${platformKey}_adgroups_metrics`]?.aux?.adgroups_names?.[adGroupId]; // Nome do grupo de anúncios
                    if (!adName || !adGroupName) return; // Ignora se o nome do anúncio ou do grupo não estiver disponível

                    adsData.push(constructAdData(platformKey, platformAdsData, adId, adName, adStatus, adGroupName, adAccount, dataCatalogList));
                });
            }
        });

        return adsData.map(ads => transformAdsData(ads));
    }else if (selectedCampaignIds.length > 0) {
        const adsData: any[] = [];
        const campaignIdSet = new Set(selectedCampaignIds.map(item => item.id)); 
    
        // Filtra e inclui apenas plataformas que não estão na lista de exclusão
        let platformsToProcess = selectedCampaignIds.map(campaign => campaign.type.split('_')[0]);
    
        platformsToProcess.forEach(platformKey => {
            // Verifica se é uma plataforma padrão ou usa fallback para Facebook
            const platformDataKey = standardPlatforms.includes(platformKey) ? platformKey : 'facebook';
            const platformAdsData = data[`${platformDataKey}_ads_metrics`];
    
            if (platformAdsData) {
                const adsObjects = platformAdsData.aux.ads_objects;
                const adsNames = platformAdsData.aux.ads_names;
                const adsStatus = platformAdsData.aux.ads_status;
    
                Object.keys(adsObjects).forEach(adId => {
                    const adGroupInfo = adsObjects[adId]; // Informações do grupo de anúncios
                    const adName = adsNames[adId]; // Nome do anúncio
                    const adStatus = adsStatus[adId]; // Nome do anúncio
                    const adGroupId = adGroupInfo.adgroup; // ID do grupo de anúncios
                    const campaignId = adGroupInfo.campaign; // ID da campanha
                    const adAccount = platformAdsData.aux.ads_objects[adId].account;

                    // Filtra apenas os anúncios que pertencem ao adset especificado se adsSetId for fornecido
                    if (adsSetId && adGroupId !== adsSetId) {
                        return;
                    }
    
                    if (!campaignIdSet.has(campaignId)) return;
    
                    const adGroupName = data[`${platformDataKey}_adgroups_metrics`]?.aux?.adgroups_names[adGroupId];
                    if (!adName || !adGroupName) return; // Ignora se o nome do anúncio ou do grupo não estiver disponível
    
                    adsData.push(constructAdData(platformDataKey, platformAdsData, adId, adName, adStatus, adGroupName, adAccount, dataCatalogList));
                });
            }
        });
    
        return adsData;
    } else {
        const adsData: any[] = [];

        // Filtra e inclui apenas plataformas que não estão na lista de exclusão
        let platformsToProcess = Object.entries(thisSelectedPlatforms)
            .filter(([key, value]) => value && !excludedPlatforms.includes(key))
            .map(([key]) => key);

        platformsToProcess.forEach(platformKey => {
            const isStandardPlatform = standardPlatforms.includes(platformKey);
            const platformDataKey = isStandardPlatform ? platformKey : 'facebook';
            const platformAdsData = data[`${platformDataKey}_ads_metrics`];

            if (platformAdsData) {
                const adsObjects = platformAdsData.aux.ads_objects;
                const adsNames = platformAdsData.aux.ads_names;
                const adsStatus = platformAdsData.aux.ads_status;

                // Se não é uma plataforma padrão, usa lógica especial
                if (!isStandardPlatform) {
                    // Acessando a estrutura nova onde os anúncios estão associados a contas
                    Object.keys(adsObjects).forEach(adId => {
                        const adInfo = adsObjects[adId];
                        const accountId = adInfo.account; // Supondo que cada anúncio tenha um accountId mapeado
                        
                        // Processa apenas anúncios da conta especificada
                        if (accountId !== platformKey) return;

                        const adName = adsNames[adId];
                        const adStatus = adsStatus[adId];
                        const adGroupId = adInfo.adgroup;

                        if (selectedAdSets.length > 0 && !selectedAdSets.some(({ id }) => id === adGroupId)) return;

                        const adGroupName = data[`${platformDataKey}_adgroups_metrics`]?.aux?.adgroups_names[adGroupId];
                        if (!adName || !adGroupName) return; // Ignora se não houver nome disponível

                        adsData.push(constructAdData(platformDataKey, platformAdsData, adId, adName, adGroupName, accountId, '', dataCatalogList));
                    });
                } else {
                    // Lógica padrão para plataformas padrão
                    Object.keys(adsObjects).forEach(adId => {
                        const adGroupInfo = adsObjects[adId]; // Informações do grupo de anúncios
                        const adName = adsNames[adId]; // Nome do anúncio
                        const adStatus = adsStatus[adId]; // Nome do anúncio
                        const adGroupId = adGroupInfo.adgroup; // ID do grupo de anúncios
                        const adAccount = platformAdsData.aux.ads_objects[adId].account;


                        if (selectedAdSets.length > 0 && !selectedAdSets.some(({ id }) => id === adGroupId)) return;

                        const adGroupName = data[`${platformDataKey}_adgroups_metrics`]?.aux?.adgroups_names[adGroupId];
                        if (!adName || !adGroupName) return; // Ignora se não houver nome disponível

                        adsData.push(constructAdData(platformDataKey, platformAdsData, adId, adName, adStatus, adGroupName, adAccount, dataCatalogList));
                    });
                }
            }
        });

        return adsData;
    }
}


export function getAdsDataForUtm(data: any, type: string, adsSetId?: string, dataCatalogList?: any) {

    if (['outros'].includes(type)) {
        const adsData: any[] = [];

        return adsData; // Retorna vazio
    }

    const adsData: any[] = [];

        const adsMap = type === 'facebook' ? data?.facebook_ads_metrics?.aux?.ads_objects
                    : type === 'tiktok' ? data?.tiktok_ads_metrics?.aux?.ads_objects
                    : type === 'google' ? data?.google_ads_metrics?.aux?.ads_objects
                    : {};

        Object.keys(adsMap).forEach(adId => {
            const adGroupInfo = adsMap[adId];
            const adGroupId = adGroupInfo.adgroup;
            const adName = data[`${type}_ads_metrics`]?.aux?.ads_names?.[adId];
            const adStatus = data[`${type}_ads_metrics`]?.aux?.ads_status?.[adId];

            // Filtra apenas os anúncios que pertencem ao ad set especificado
            if (adsSetId && adGroupId !== adsSetId) {
                return;
            }

            adsData.push(constructAdData(type, data[`${type}_ads_metrics`], adId, adName, adStatus, data[`${type}_adgroups_metrics`]?.aux?.adgroups_names?.[adGroupId], '', dataCatalogList));
        });
        return adsData.map(ads => transformAdsData(ads));
}

function constructAdData(platformKey: any, platformAdsData: any, adId: any, adName: any, adStatus: any, adGroupName: any, adAccount?: any, dataCatalogList?: any) {

    //console.log('constructaddata datacatalog', dataCatalogList, 'adid', adId);

    return {
        adInfo: adId,
        isCatalog: dataCatalogList?.includes(adId),
        adStatus: adStatus,
        adName,
        accountId: adAccount,
        sourceType: platformKey,
        adsSourceIcon: getIconForPlatform(platformKey),
        adGroupName,
        spendBySource: platformAdsData.spend.per_ad.metric[adId],
        profitByUtmApproved: platformAdsData.utm_profit.per_ad.metric[adId] - platformAdsData.spend.per_ad.metric[adId],
        productCostByUtmApproved: platformAdsData?.utm_product_cost?.per_ad?.metric[adId],
        totalOrders: platformAdsData.utm_purchases.per_ad.metric[adId],
        approvedOrders: platformAdsData.utm_approved_purchases.per_ad.metric[adId],
        pendingOrders: platformAdsData.utm_pending_purchases.per_ad.metric[adId],
        cancelledOrders: platformAdsData.utm_cancelled_purchases.per_ad.metric[adId],
        ordersBySource: platformAdsData.purchases.per_ad.metric[adId],
        revenueBySource: platformAdsData.revenue.per_ad.metric[adId],
        revenueTotal: platformAdsData.utm_revenue.per_ad.metric[adId],
        revenueApproved: platformAdsData.utm_approved_revenue.per_ad.metric[adId],
        revenuePending: platformAdsData.utm_pending_revenue.per_ad.metric[adId],
        revenueCancelled: platformAdsData.utm_cancelled_revenue.per_ad.metric[adId],
        clicksBySource: platformAdsData.clicks.per_ad.metric[adId],
        impressionsBySource: platformAdsData.impressions.per_ad.metric[adId],
        checkoutsBySource: platformAdsData.checkouts.per_ad.metric[adId],
        pageViewsBySource: platformAdsData.pageviews.per_ad.metric[adId],
        addToCartsBySource: platformAdsData.add_to_carts.per_ad.metric[adId],
        approved_orders_pix: platformAdsData.utm_approved_purchases_pix.per_ad.metric[adId],
        pending_orders_pix: platformAdsData.utm_pending_purchases_pix.per_ad.metric[adId],
        cancelled_orders_pix: platformAdsData.utm_cancelled_purchases_pix.per_ad.metric[adId],
        approved_orders_boleto: platformAdsData.utm_approved_purchases_boleto.per_ad.metric[adId],
        pending_orders_boleto: platformAdsData.utm_pending_purchases_boleto.per_ad.metric[adId],
        cancelled_orders_boleto: platformAdsData.utm_cancelled_purchases_boleto.per_ad.metric[adId],
        approved_orders_cc: platformAdsData.utm_approved_purchases_cc.per_ad.metric[adId],
        pending_orders_cc: platformAdsData.utm_pending_purchases_cc.per_ad.metric[adId],
        cancelled_orders_cc: platformAdsData.utm_cancelled_purchases_cc.per_ad.metric[adId],
        orders_pix: platformAdsData.utm_purchases_pix.per_ad.metric[adId],
        orders_boleto: platformAdsData.utm_purchases_boleto.per_ad.metric[adId],
        orders_cc: platformAdsData.utm_purchases_cc.per_ad.metric[adId],
        approved_revenue_pix: platformAdsData.utm_approved_revenue_pix.per_ad.metric[adId],
        pending_revenue_pix: platformAdsData.utm_pending_revenue_pix.per_ad.metric[adId],
        cancelled_revenue_pix: platformAdsData.utm_cancelled_revenue_pix.per_ad.metric[adId],
        approved_revenue_boleto: platformAdsData.utm_approved_revenue_boleto.per_ad.metric[adId],
        pending_revenue_boleto: platformAdsData.utm_pending_revenue_boleto.per_ad.metric[adId],
        cancelled_revenue_boleto: platformAdsData.utm_cancelled_revenue_boleto.per_ad.metric[adId],
        approved_revenue_cc: platformAdsData.utm_approved_revenue_cc.per_ad.metric[adId],
        pending_revenue_cc: platformAdsData.utm_pending_revenue_cc.per_ad.metric[adId],
        cancelled_revenue_cc: platformAdsData.utm_cancelled_revenue_cc.per_ad.metric[adId],
        revenue_pix: platformAdsData.utm_revenue_pix.per_ad.metric[adId],
        revenue_boleto: platformAdsData.utm_revenue_boleto.per_ad.metric[adId],
        revenue_cc: platformAdsData.utm_revenue_cc.per_ad.metric[adId],

        roasBySource: platformAdsData.spend?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.revenue?.per_ad?.metric?.[adId] / platformAdsData.spend?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        roasTotal: platformAdsData.spend?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.utm_revenue?.per_ad?.metric?.[adId] / platformAdsData.spend?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        roasPending: platformAdsData.spend?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.utm_pending_revenue?.per_ad?.metric?.[adId] / platformAdsData.spend?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        roasApproved: platformAdsData.spend?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.utm_approved_revenue?.per_ad?.metric?.[adId] / platformAdsData.spend?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        roasCancelled: platformAdsData.spend?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.utm_cancelled_revenue?.per_ad?.metric?.[adId] / platformAdsData.spend?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
    
        cpaBySource: platformAdsData.purchases?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.purchases?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        cpaTotal: platformAdsData.purchases?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.utm_purchases?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        cpaApproved: platformAdsData.purchases?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.utm_approved_purchases?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        cpaPending: platformAdsData.purchases?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.utm_pending_purchases?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        cpaCancelled: platformAdsData.purchases?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.utm_cancelled_purchases?.per_ad?.metric?.[adId]).toFixed(2)) : 0,

        cpcBySource: platformAdsData.clicks?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.clicks?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
        cpmBySource: platformAdsData.impressions?.per_ad?.metric?.[adId] !== 0 ? parseFloat((platformAdsData.spend?.per_ad?.metric?.[adId] / platformAdsData.impressions?.per_ad?.metric?.[adId]).toFixed(2)) : 0,
    };
}

function transformAdsData(ad: any) {
    return {
        ...ad,
        roasBySource: ad?.spendBySource !== 0 ? parseFloat((ad?.revenueBySource / ad?.spendBySource).toFixed(2)) : 0,
        roasTotal: ad?.spendBySource !== 0 ? parseFloat((ad?.revenueTotal / ad?.spendBySource).toFixed(2)) : 0,
        roasApproved: ad?.spendBySource !== 0 ? parseFloat((ad?.revenueApproved / ad?.spendBySource).toFixed(2)) : 0,
        roasPending: ad?.spendBySource !== 0 ? parseFloat((ad?.revenuePending / ad?.spendBySource).toFixed(2)) : 0,
        roasCancelled: ad?.spendBySource !== 0 ? parseFloat((ad?.revenueCancelled / ad?.spendBySource).toFixed(2)) : 0,

        cpaBySource: ad?.ordersBySource !== 0 ? parseFloat((ad?.spendBySource / ad?.ordersBySource).toFixed(2)) : 0,
        cpaTotal: ad?.totalOrders !== 0 ? parseFloat((ad?.spendBySource / ad?.totalOrders).toFixed(2)) : 0,
        cpaApproved: ad?.approvedOrders !== 0 ? parseFloat((ad?.spendBySource / ad?.approvedOrders).toFixed(2)) : 0,
        cpaPending: ad?.pendingOrders !== 0 ? parseFloat((ad?.spendBySource / ad?.pendingOrders).toFixed(2)) : 0,
        cpaCancelled: ad?.cancelledOrders !== 0 ? parseFloat((ad?.spendBySource / ad?.cancelledOrders).toFixed(2)) : 0,

        cpcBySource: ad?.clicksBySource !== 0 ? parseFloat((ad?.spendBySource / ad?.clicksBySource).toFixed(2)) : 0,
        cpmBySource: ad?.impressionsBySource !== 0 ? parseFloat(((1000 * ad?.spendBySource) / ad?.impressionsBySource).toFixed(2)) : 0, // outros cálculos conforme necessário
    };
}

export function getTotalLineByAds(dataFiltred: any, type: string) {
    const totalLine = defaultTotalLine();
    const types = ['sem_atribuicao', 'outros'];
    // //console.log(dataFiltred);

    if (types.includes(type)) {
        return totalLine;
    }

    for (const ad of dataFiltred) {
        // //console.log('ad', ad);
        Object.keys(totalLine).forEach(key => {
            totalLine[key] += ad[key] || 0;  // Usa '0' como fallback se ad[key] é undefined
        });
    }

    return totalLine;
}

export function getChartsTrackignByData(data: any, type: string) {
    const types = ['facebook', 'google', 'tiktok'];

    if (!types.includes(type)) {
        return {};
    }

    const revenueBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.revenue?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.revenue?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.revenue?.per_source?.chart
                : [];

    const ordersBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.purchases?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.purchases?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.purchases?.per_source?.chart
                : [];

    const spendBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.spend?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.spend?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.spend?.per_source?.chart
                : [];

    const profitByUtmApproved =
    type === 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_profit?.per_source?.chart
        : type === 'tiktok'
            ? data?.tiktok_campaigns_metrics?.utm_profit?.per_source?.chart
            : type === 'google'
            ? data?.google_campaigns_metrics?.utm_profit?.per_source?.chart
            : [];

    const productCostByUtmApproved =
    type === 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_product_cost?.per_source?.chart
        : type === 'tiktok'
            ? data?.tiktok_campaigns_metrics?.utm_product_cost?.per_source?.chart
            : type === 'google'
            ? data?.google_campaigns_metrics?.utm_product_cost?.per_source?.chart
            : [];

    const clicksBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.clicks?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.clicks?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.clicks?.per_source?.chart
                : [];

    const impressionsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.impressions?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.impressions?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.impressions?.per_source?.chart
                : [];

    const checkoutsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.checkouts?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.checkouts?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.checkouts?.per_source?.chart
                : [];

    const pageViewsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.pageviews?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.pageviews?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.pageviews?.per_source?.chart
                : [];

    const addToCartsBySource =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.add_to_carts?.per_source?.chart
            : type === 'tiktok'
              ? data?.tiktok_campaigns_metrics?.add_to_carts?.per_source?.chart
              : type === 'google'
                ? data?.google_campaigns_metrics?.add_to_carts?.per_source?.chart
                : [];

    const revenueTotal = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok' ? data?.tiktok_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis : (type = 'google' ? data?.google_campaigns_metrics?.utm_revenue?.per_source?.chart?.y_axis : [])));

    const revenueApproved = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_approved_revenue?.per_source?.chart?.y_axis : [])));

    const revenuePending = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_pending_revenue?.per_source?.chart?.y_axis : [])));

    const revenueCancelled = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_cancelled_revenue?.per_source?.chart?.y_axis : [])));

    const ordersTotal = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_purchases?.per_source?.chart?.y_axis : [])));

    const ordersApproved = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_approved_purchases?.per_source?.chart?.y_axis : [])));

    const ordersPending = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_pending_purchases?.per_source?.chart?.y_axis : [])));

    const ordersCancelled = (type = 'facebook'
        ? data?.facebook_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis
        : (type = 'tiktok'
              ? data?.tiktok_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis
              : (type = 'google' ? data?.google_campaigns_metrics?.utm_cancelled_purchases?.per_source?.chart?.y_axis : [])));

    return {
        name: type === 'facebook' ? 'Facebook' : type === 'tiktok' ? 'Tiktok' : 'Google',
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: ordersTotal,
        ordersApproved: ordersApproved,
        ordersPending: ordersPending,
        ordersCancelled: ordersCancelled,
        ordersBySource: ordersBySource?.y_axis,
        revenueTotal: revenueTotal,
        revenueApproved: revenueApproved,
        revenuePending: revenuePending,
        revenueCancelled: revenueCancelled,
        revenueBySource: revenueBySource?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
        checkoutsBySource: checkoutsBySource?.y_axis,
        pageViewsBySource: pageViewsBySource?.y_axis,
        addToCartsBySource: addToCartsBySource?.y_axis,
        profitByUtmApproved: profitByUtmApproved?.y_axis,
    };
}

export function getChartsCampaignsByChannel(data: any) {
    const typeDefaults = ['facebook', 'tiktok', 'google'];
    const getDataSourcesChart: any = {};
    const metrics = data?.utm_metrics?.utm_metrics;

    for (const category in metrics) {
        if (metrics?.hasOwnProperty(category)) {
            const sources = metrics?.[category]?.source;
            for (const sourceKey in sources) {
                if (sources?.hasOwnProperty(sourceKey) && !typeDefaults?.includes(sourceKey)) {
                    getDataSourcesChart[sourceKey] = getDataSourcesChart?.[sourceKey] || {};
                    getDataSourcesChart[sourceKey][category] = sources?.[sourceKey];
                }
            }
        }
    }

    return { getDataSourcesChart };
}

export function getAdsChart(data: any, type: string, paramterId: string, granularity:string  = 'ads') {
    const adsMetrics = type === 'facebook' ? data?.facebook_ads_metrics : type === 'tiktok' ? data?.tiktok_ads_metrics : type === 'google' ? data?.google_ads_metrics : {};
    let path = 'per_ad'
    if (granularity === 'adsets') {
        path = 'per_adgroup'
    }

    const spendBySource = adsMetrics?.spend?.[path]?.chart?.[paramterId];
    const profitByUtmApproved = adsMetrics?.utm_profit?.[path]?.chart?.[paramterId];
    const totalOrders = adsMetrics?.utm_purchases?.[path]?.chart?.[paramterId];
    const approvedOrders = adsMetrics?.utm_approved_purchases?.[path]?.chart?.[paramterId];
    const pendingOrders = adsMetrics?.utm_pending_purchases?.[path]?.chart?.[paramterId];
    const cancelledOrders = adsMetrics?.utm_cancelled_purchases?.[path]?.chart?.[paramterId];
    const ordersBySource = adsMetrics?.purchases?.[path]?.chart?.[paramterId];
    const revenueBySource = adsMetrics?.revenue?.[path]?.chart?.[paramterId];
    const revenueTotal = adsMetrics?.utm_revenue?.[path]?.chart?.[paramterId];
    const revenueApproved = adsMetrics?.utm_approved_revenue?.[path]?.chart?.[paramterId];
    const revenuePending = adsMetrics?.utm_pending_revenue?.[path]?.chart?.[paramterId];
    const revenueCancelled = adsMetrics?.utm_cancelled_revenue?.[path]?.chart?.[paramterId];
    const clicksBySource = adsMetrics?.clicks?.[path]?.chart?.[paramterId];
    const impressionsBySource = adsMetrics?.impressions?.[path]?.chart?.[paramterId];
    const checkoutsBySource = adsMetrics?.checkouts?.[path]?.chart?.[paramterId];
    const pageViewsBySource = adsMetrics?.pageviews?.[path]?.chart?.[paramterId];
    const addToCartsBySource = adsMetrics?.add_to_carts?.[path]?.chart?.[paramterId];

    return {
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: totalOrders?.y_axis,
        ordersApproved: approvedOrders?.y_axis,
        ordersPending: pendingOrders?.y_axis,
        ordersCancelled: cancelledOrders?.y_axis,
        ordersBySource: ordersBySource?.y_axis,
        revenueTotal: revenueTotal?.y_axis,
        revenueApproved: revenueApproved?.y_axis,
        revenuePending: revenuePending?.y_axis,
        revenueCancelled: revenueCancelled?.y_axis,
        revenueBySource: revenueBySource?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
        checkoutsBySource: checkoutsBySource?.y_axis,
        pageViewsBySource: pageViewsBySource?.y_axis,
        addToCartsBySource: addToCartsBySource?.y_axis,
        profitByUtmApproved: profitByUtmApproved?.y_axis,
    };
}

export function selectGraphicData(data: any, tab: string, type: string, paramterId: string, secondaryParmaterId: string) {
    if (tab === 'channel') {
        const types = ['facebook', 'tiktok', 'google'];

        if (!types.includes(type)) {
            return [];
        }

        return getChartsTrackignByData(data, type);
    } else if (tab === 'channelFacebook') {
        return getChartsByAdsAccountFacebook(data, paramterId);
    } else if (tab === 'campaign') {
        return getChartsAccountCampaigns(data, type, paramterId, secondaryParmaterId);
    } else if (tab === 'adsSet') {
        return getAdsChart(data, type, paramterId, 'adsets');
    } else if (tab === 'ads') {
        return getAdsChart(data, type, paramterId);
    }
}

export function getChartsByAdsAccountFacebook(data: any, paramterId: string) {
    const accountMetrics = data?.facebook_ad_accounts_metrics || [];
    const spendBySource = accountMetrics?.spend?.per_account?.chart?.[paramterId] || [];
    const profitByUtmApproved = accountMetrics?.utm_profit?.per_account?.chart?.[paramterId] || [];
    const totalOrders = accountMetrics?.utm_purchases?.per_account?.chart?.[paramterId] || [];
    const approvedOrders = accountMetrics?.utm_approved_purchases?.per_account?.chart?.[paramterId] || [];
    const pendingOrders = accountMetrics?.utm_pending_purchases?.per_account?.chart?.[paramterId] || [];
    const cancelledOrders = accountMetrics?.utm_cancelled_purchases?.per_account?.chart?.[paramterId] || [];
    const ordersBySource = accountMetrics?.purchases?.per_account?.chart?.[paramterId] || [];
    const revenueBySource = accountMetrics?.revenue?.per_account?.chart?.[paramterId] || [];
    const revenueTotal = accountMetrics?.utm_revenue?.per_account?.chart?.[paramterId] || [];
    const revenueApproved = accountMetrics?.utm_approved_revenue?.per_account?.chart?.[paramterId] || [];
    const revenuePending = accountMetrics?.utm_pending_revenue?.per_account?.chart?.[paramterId] || [];
    const revenueCancelled = accountMetrics?.utm_cancelled_revenue?.per_account?.chart?.[paramterId] || [];
    const clicksBySource = accountMetrics?.clicks?.per_account?.chart?.[paramterId] || [];
    const impressionsBySource = accountMetrics?.impressions?.per_account?.chart?.[paramterId] || [];
    const checkoutsBySource = accountMetrics?.checkouts?.per_account?.chart?.[paramterId] || [];
    const pageViewsBySource = accountMetrics?.pageviews?.per_account?.chart?.[paramterId] || [];
    const addToCartsBySource = accountMetrics?.add_to_carts?.per_account?.chart?.[paramterId] || [];

    const chartsByFacebookAdsAccount = {
        xAxis: spendBySource?.x_axis,
        spendBySource: spendBySource?.y_axis,
        ordersTotal: totalOrders?.y_axis,
        ordersApproved: approvedOrders?.y_axis,
        ordersPending: pendingOrders?.y_axis,
        ordersCancelled: cancelledOrders?.y_axis,
        ordersBySource: ordersBySource?.y_axis,
        revenueBySource: revenueBySource?.y_axis,
        revenueTotal: revenueTotal?.y_axis,
        revenueApproved: revenueApproved?.y_axis,
        revenuePending: revenuePending?.y_axis,
        revenueCancelled: revenueCancelled?.y_axis,
        clicksBySource: clicksBySource?.y_axis,
        impressionsBySource: impressionsBySource?.y_axis,
        checkoutsBySource: checkoutsBySource?.y_axis,
        pageViewsBySource: pageViewsBySource?.y_axis,
        addToCartsBySource: addToCartsBySource?.y_axis,
        profitByUtmApproved: profitByUtmApproved?.y_axis,
    };

    return chartsByFacebookAdsAccount;
}

export function getChartsAccountCampaigns(data: any, type: string, paramterId: string, secondaryParmaterId: string) {
    const types = ['sem_atribuicao'];

    if (types.includes(type)) {
        const { getDataSourcesChart } = getChartsCampaignsByChannel(data);

        const organizedData = organizeDataByCampaign(getDataSourcesChart)?.[type] ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData)
            ?.filter((item: any) => item !== 'y_axis' && item !== 'x_axis')
            ?.map((key) => {
                return { ...organizedData[key], campaignName: key };
            });

        const filtredAccountCampaign = filtredAccountCampaignData?.filter((item: any) => item.campaignName === paramterId)[0];

        return {
            xAxis: filtredAccountCampaign?.orders?.chart?.x_axis,
            spendBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            profitByUtmApproved: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            ordersTotal: filtredAccountCampaign?.orders?.chart?.y_axis,
            ordersApproved: filtredAccountCampaign?.approved_orders?.chart?.y_axis,
            ordersPending: filtredAccountCampaign?.pending_orders?.chart?.y_axis,
            ordersCancelled: filtredAccountCampaign?.cancelled_orders?.chart?.y_axis,
            ordersBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            revenueTotal: filtredAccountCampaign?.revenue?.chart?.y_axis,
            revenueApproved: filtredAccountCampaign?.approved_revenue?.chart?.y_axis,
            revenuePending: filtredAccountCampaign?.pending_revenue?.chart?.y_axis,
            revenueCancelled: filtredAccountCampaign?.cancelled_revenue?.chart?.y_axis,
            revenueBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            clicksBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            impressionsBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            checkoutsBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            pageViewsBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
            addToCartsBySource: Array.from({ length: filtredAccountCampaign?.orders?.chart?.x_axis.length }, () => 0),
        };
    } else if (type === 'outros') {
        const { nonListedSourcesChart } = getNonListedSourcesChart(data);

        const organizedData = organizeDataByCampaign(nonListedSourcesChart) ?? [];

        const filtredAccountCampaignData = Object.keys(organizedData?.[paramterId])
            ?.filter((item: any) => item !== 'y_axis' && item !== 'x_axis')
            ?.map((key) => {
                return { ...organizedData?.[paramterId]?.[key], campaignName: key };
            });

        const filtredAccounts = filtredAccountCampaignData?.filter((item: any) => item.campaignName === secondaryParmaterId)[0];

        return {
            xAxis: filtredAccounts?.orders?.chart?.x_axis,
            spendBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            ordersTotal: filtredAccounts?.orders?.chart?.y_axis,
            ordersApproved: filtredAccounts?.approved_orders?.chart?.y_axis,
            ordersPending: filtredAccounts?.pending_orders?.chart?.y_axis,
            ordersCancelled: filtredAccounts?.cancelled_orders?.chart?.y_axis,
            ordersBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            revenueTotal: filtredAccounts?.revenue?.chart?.y_axis,
            revenueApproved: filtredAccounts?.approved_revenue?.chart?.y_axis,
            revenuePending: filtredAccounts?.pending_revenue?.chart?.y_axis,
            revenueCancelled: filtredAccounts?.cancelled_revenue?.chart?.y_axis,
            revenueBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            clicksBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            impressionsBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            checkoutsBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            pageViewsBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
            addToCartsBySource: Array.from({ length: filtredAccounts?.orders?.chart?.x_axis.length }, () => 0),
        };
    } else {
        const campaignMetrics = type === 'facebook' ? data?.facebook_campaigns_metrics : type === 'tiktok' ? data?.tiktok_campaigns_metrics : type === 'google' ? data?.google_campaigns_metrics : {};

        const spendBySource = campaignMetrics?.spend?.per_campaign?.chart?.[paramterId];
        const profitByUtmApproved = campaignMetrics?.utm_profit?.per_campaign?.chart?.[paramterId];
        const totalOrders = campaignMetrics?.utm_purchases?.per_campaign?.chart?.[paramterId];
        const approvedOrders = campaignMetrics?.utm_approved_purchases?.per_campaign?.chart?.[paramterId];
        const pendingOrders = campaignMetrics?.utm_pending_purchases?.per_campaign?.chart?.[paramterId];
        const cancelledOrders = campaignMetrics?.utm_cancelled_purchases?.per_campaign?.chart?.[paramterId];
        const ordersBySource = campaignMetrics?.purchases?.per_campaign?.chart?.[paramterId];
        const revenueBySource = campaignMetrics?.revenue?.per_campaign?.chart?.[paramterId];
        const revenueTotal = campaignMetrics?.utm_revenue?.per_campaign?.chart?.[paramterId];
        const revenueApproved = campaignMetrics?.utm_approved_revenue?.per_campaign?.chart?.[paramterId];
        const revenuePending = campaignMetrics?.utm_pending_revenue?.per_campaign?.chart?.[paramterId];
        const revenueCancelled = campaignMetrics?.utm_cancelled_revenue?.per_campaign?.chart?.[paramterId];
        const clicksBySource = campaignMetrics?.clicks?.per_campaign?.chart?.[paramterId];
        const impressionsBySource = campaignMetrics?.impressions?.per_campaign?.chart?.[paramterId];
        const checkoutsBySource = campaignMetrics?.checkouts?.per_campaign?.chart?.[paramterId];
        const pageViewsBySource = campaignMetrics?.pageviews?.per_campaign?.chart?.[paramterId];
        const addToCartsBySource = campaignMetrics?.add_to_carts?.per_campaign?.chart?.[paramterId];

        const accountCampaignsData = {
            xAxis: spendBySource?.x_axis,
            spendBySource: spendBySource?.y_axis,
            ordersTotal: totalOrders?.y_axis,
            ordersApproved: approvedOrders?.y_axis,
            ordersPending: pendingOrders?.y_axis,
            ordersCancelled: cancelledOrders?.y_axis,
            ordersBySource: ordersBySource?.y_axis,
            revenueBySource: revenueBySource?.y_axis,
            revenueTotal: revenueTotal?.y_axis,
            revenueApproved: revenueApproved?.y_axis,
            revenuePending: revenuePending?.y_axis,
            revenueCancelled: revenueCancelled?.y_axis,
            clicksBySource: clicksBySource?.y_axis,
            impressionsBySource: impressionsBySource?.y_axis,
            checkoutsBySource: checkoutsBySource?.y_axis,
            addToCartsBySource: addToCartsBySource?.y_axis,
            profitByUtmApproved: profitByUtmApproved?.y_axis,
            pageViewsBySource: pageViewsBySource?.y_axis,
        };

        return accountCampaignsData;
    }
}

export function getChartTransformed(data: any) {
    const roasBySource = divideVectors(data?.revenueBySource, data?.spendBySource);
    const roasSolomonTotal = divideVectors(data?.revenueTotal, data?.spendBySource);
    const roasSolomonApproved = divideVectors(data?.revenueApproved, data?.spendBySource);
    const roasSolomonPending = divideVectors(data?.revenuePending, data?.spendBySource);
    const roasSolomonCancelled = divideVectors(data?.revenueCancelled, data?.spendBySource);

    const cpaBySource = divideVectors(data?.spendBySource, data?.ordersBySource);
    const cpaSolomonTotal = divideVectors(data?.spendBySource, data?.ordersTotal);
    const cpaSolomonApproved = divideVectors(data?.spendBySource, data?.ordersApproved);
    const cpaSolomonPending = divideVectors(data?.spendBySource, data?.ordersPending);
    const cpaSolomonCancelled = divideVectors(data?.spendBySource, data?.ordersCancelled);

    const cpmBySource = divideVectors(data?.spendBySource, data?.impressionsBySource).map((value: any) => value * 1000);
    const cpcBySource = divideVectors(data?.spendBySource, data?.clicksBySource);

    const clicksBySource = data?.clicksBySource;
    const impressionsBySource = data?.impressionsBySource;
    const checkoutsBySource = data?.checkoutsBySource;
    const pageViewsBySource = data?.pageViewsBySource;
    const addToCartsBySource = data?.addToCartsBySource;
    
    const profitByUtmApproved = data?.profitByUtmApproved;
    


    const chartTransformed = {
        xAxis: data?.xAxis,
        spendBySource: data?.spendBySource,

        ordersBySource: data?.ordersBySource,
        ordersSolomonTotal: data?.ordersTotal,
        ordersSolomonApproved: data?.ordersApproved,
        ordersSolomonPending: data?.ordersPending,
        ordersSolomonCancelled: data?.ordersCancelled,

        revenueBySource: data?.revenueBySource,
        revenueTotal: data?.revenueTotal,
        revenueApproved: data?.revenueApproved,
        revenuePending: data?.revenuePending,
        revenueCancelled: data?.revenueCancelled,

        roasBySource,
        roasSolomonTotal,
        roasSolomonApproved,
        roasSolomonPending,
        roasSolomonCancelled,

        cpaBySource,
        cpaSolomonTotal,
        cpaSolomonApproved,
        cpaSolomonPending,
        cpaSolomonCancelled,

        cpcBySource,
        cpmBySource,

        clicksBySource,
        impressionsBySource,
        checkoutsBySource,
        pageViewsBySource,
        addToCartsBySource,
        profitByUtmApproved
    };

    return chartTransformed;
}

export function getAdsIdByCampaingId(data: any, campaignId: string, type: string, dataCatalogList?: any) {
    
    if (type === 'facebook_campaigns_metrics') {
        type = 'facebook';
    } else if (type === 'google_campaigns_metrics') {
        type = 'google';
    } else if (type === 'tiktok_campaigns_metrics') {
        type = 'tiktok';
    }

    const ads = []

       const adsSets = getAdsSetDataForUtm(data, campaignId, dataCatalogList);
         for (const adsSet of adsSets) {
              const adsData = getAdsDataForUtm(data, type, adsSet?.adsSetId, dataCatalogList);
                for (const ad of adsData) {
                    ads.push(ad?.adInfo);
                }
         }
   
   let adString = ''
   
   for (const ad of ads) {
         adString += ad + ','
   }

   adString = adString.slice(0, -1)

   return adString
}

export function getAdsIdByAdsSetId(data: any, adsSetId: string, type: string, dataCatalogList: any) {
    const platformMap: { [key: string]: string } = {
        'facebook_campaigns_metrics': 'facebook',
        'google_campaigns_metrics': 'google',
        'tiktok_campaigns_metrics': 'tiktok'
    };

    type = platformMap[type] || type;

    const adsData = getAdsDataForUtm(data, type, adsSetId, dataCatalogList);

    const adString = adsData.map(ad => ad?.adInfo).join(',');

    return adString;
}

export function getAdsDataWarning(data: any, type: any, ) {
    
    if (type === 'facebook_campaigns_metrics') {
        type = 'facebook';
    } else if (type === 'google_campaigns_metrics') {
        type = 'google';
    } else if (type === 'tiktok_campaigns_metrics') {
        type = 'tiktok';
    }
    
    const adsMap =
    type === 'facebook'
    ? data?.facebook_ads_metrics?.aux?.ads_objects
    : type === 'tiktok'
    ? data?.tiktok_ads_metrics?.aux?.ads_objects
    : type === 'google'
    ? data?.google_ads_metrics?.aux?.ads_objects
    : {};
    
    return adsMap;
}

export function getAdsIdByAllChannelId(data: any, type: string) {
    let keysAsStrings: string[] = [];
    let matchedKeys: string[] = [];

    const adsMap =
        type === 'facebook'
            ? data?.facebook_ad_accounts_metrics?.aux?.accounts_maps
            : type === 'tiktok'
              ? data?.tiktok_ad_accounts_metrics?.aux?.accounts_maps
              : type === 'google'
                ? data?.google_ad_accounts_metrics?.aux?.accounts_maps
                : {};

    const adsMapCampaign =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.aux?.campaigns_accounts
            : type === 'tiktok'
                ? data?.tiktok_campaigns_metrics?.aux?.campaigns_accounts
                : type === 'google'
                ? data?.google_campaigns_metrics?.aux?.campaigns_accounts
                : {};

    if (type === 'facebook') {
        if (adsMap && typeof adsMap === 'object') {
            keysAsStrings = Object.keys(adsMap).map(function(key) {
                return String(key);
            });
        }

        if (adsMapCampaign && typeof adsMapCampaign === 'object') {
            Object.keys(adsMapCampaign).forEach(key => {
                if (keysAsStrings.includes(adsMapCampaign[key])) {
                    matchedKeys.push(key);
                }
            });
        }
    } else {
        if (adsMapCampaign && typeof adsMapCampaign === 'object') {
            matchedKeys = Object.keys(adsMapCampaign).map(function(key) {
                return String(key);
            });
        }
    }

    let combinedAdsString = '';

    for (const matchedKey of matchedKeys) {
        const adsString = getAdsIdByCampaingId(data, matchedKey, type);
        combinedAdsString += adsString + ',';
    }

    combinedAdsString = combinedAdsString.slice(0, -1);

    return combinedAdsString;
}

export function getAdsIdByChannelId(data: any, type: string, channelId: string) {
    let matchedKeys: string[] = [];

    const adsMapCampaign =
        type === 'facebook'
            ? data?.facebook_campaigns_metrics?.aux?.campaigns_accounts
                : {};


    if (adsMapCampaign && typeof adsMapCampaign === 'object') {
        Object.keys(adsMapCampaign).forEach(key => {
            if (channelId.includes(adsMapCampaign[key])) {
                matchedKeys.push(key);
            }
        });
    } 

    let combinedAdsString = '';
    let showWarning: boolean = false;

    for (const matchedKey of matchedKeys) {
        const adsString = getAdsIdByCampaingId(data, matchedKey, type);
        combinedAdsString += adsString + ',';
    }

    combinedAdsString = combinedAdsString.slice(0, -1);

    const adsStrings = combinedAdsString.split(',').map(str => str.trim());
    const filteredAdsData = getAdsDataWarning(data, type);
    
    adsStrings.forEach(adString => {
    
        let AdsData = filteredAdsData[adString];


        if (type === "facebook") {

            if (AdsData.utm_source === "\{\{site_source_name\}\}" && AdsData.utm_medium === "\{\{placement\}\}" && AdsData.utm_campaign === "\{\{campaign.id\}\}" && AdsData.utm_content === "\{\{ad.id\}\}") {
                showWarning = false;
            } else {
                showWarning = true;
            }
        }
    });

    return showWarning;
}

export function getWrongUtms (data: any, type: string) {
    
    const metricsByAds = [];

    if (type === "facebook") {
        
        for (const Ad in data?.facebook_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            const ads_objects = data?.facebook_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.facebook_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.facebook_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.facebook_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account = data?.facebook_campaigns_metrics?.aux?.campaigns_accounts?.[Campaign];
            const Account_name = data?.facebook_ad_accounts_metrics?.aux?.accounts_maps?.[Account];

            if (utm_source !== "\{\{site_source_name\}\}" || utm_medium !== "\{\{placement\}\}" || utm_campaign !== "\{\{campaign.id\}\}" || utm_content !== "\{\{ad.id\}\}") {
                metricsByAds.push({
                    Account_name,
                    Ad_name,
                    AdGroup_name,
                    Campaign_name,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    Account,
                    Campaign,
                    AdGroup,
                    Ad,
                });
            } 
        }
    }

    if (type === "google") {

        for (const Ad in data?.google_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            const ads_objects = data?.google_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.google_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.google_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.google_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account_name = 'nulo';

            if (utm_source !== "google" || utm_campaign !== "\{campaignid\}" || utm_content !== "\{creative\}") {
                metricsByAds.push({
                    Account_name,
                    Ad_name,
                    AdGroup_name,
                    Campaign_name,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                });
            } 

        }

    }

    if (type === "tiktok") {

        for (const Ad in data?.tiktok_ads_metrics?.aux?.ads_objects) {
            if (!Ad) {
                continue;
            }
            const ads_objects = data?.tiktok_ads_metrics?.aux?.ads_objects;
            const Ad_name = data?.tiktok_ads_metrics?.aux?.ads_names?.[Ad];
            const AdGroup = ads_objects?.[Ad]?.adgroup;
            const AdGroup_name = data?.tiktok_adgroups_metrics?.aux?.adgroups_names?.[AdGroup];
            const Campaign = ads_objects?.[Ad]?.campaign;
            const Campaign_name = data?.tiktok_campaigns_metrics?.aux?.campaigns_names?.[Campaign];
            const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
            const utm_content = ads_objects?.[Ad]?.utm_content;
            const utm_medium = ads_objects?.[Ad]?.utm_medium;
            const utm_source = ads_objects?.[Ad]?.utm_source;
            const Account_name = 'nulo';

            if (utm_source !== "tiktok" || utm_medium !== "tiktok_ads" || utm_campaign !== "__CAMPAIGN_ID__" || utm_content !== "__CID__") {
                metricsByAds.push({
                    Account_name,
                    Ad_name,
                    AdGroup_name,
                    Campaign_name,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                });
            } 

        }

    }

    return metricsByAds;
}

export function getAlertAccount (data: any) {
    
    const metricsByAds = [];
        
    for (const Ad in data?.facebook_ads_metrics?.aux?.ads_objects) {
        const ads_objects = data?.facebook_ads_metrics?.aux?.ads_objects;
        const Campaign = ads_objects?.[Ad]?.campaign;
        const utm_campaign = ads_objects?.[Ad]?.utm_campaign;
        const utm_content = ads_objects?.[Ad]?.utm_content;
        const utm_medium = ads_objects?.[Ad]?.utm_medium;
        const utm_source = ads_objects?.[Ad]?.utm_source;
        const Account = data?.facebook_campaigns_metrics?.aux?.campaigns_accounts?.[Campaign];

        if (utm_source === "\{\{site_source_name\}\}" && utm_medium === "\{\{placement\}\}" && utm_campaign === "\{\{campaign.id\}\}" && utm_content === "\{\{ad.id\}\}") {
            const showAlert = false;
            metricsByAds.push({
                Account,
                showAlert,
            });
        } else {
            const showAlert = true;
            metricsByAds.push({
                Account,
                showAlert,
            });
        }
    }
    
    return metricsByAds;
}

export function toggleCampaignStatus() {
    const status = 'active';
    return status === 'active' ? 'paused' : 'active';   
}

interface TotalLine {
    spendBySource: number;
    profitByUtmApproved: number;
    productCostByUtmApproved: number;
    totalOrders: number;
    approvedOrders: number;
    pendingOrders: number;
    cancelledOrders: number;
    ordersBySource: number;
    revenueBySource: number;
    revenueTotal: number;
    revenueApproved: number;
    revenuePending: number;
    revenueCancelled: number;
    clicksBySource: number;
    impressionsBySource: number;
    checkoutsBySource: number;
    pageViewsBySource: number;
    addToCartsBySource: number;
    approved_orders_pix: number;
    pending_orders_pix: number;
    cancelled_orders_pix: number;
    approved_orders_boleto: number;
    pending_orders_boleto: number;
    cancelled_orders_boleto: number;
    approved_orders_cc: number;
    pending_orders_cc: number;
    cancelled_orders_cc: number;
    orders_pix: number;
    orders_boleto: number;
    orders_cc: number;
    approved_revenue_pix: number;
    pending_revenue_pix: number;
    cancelled_revenue_pix: number;
    approved_revenue_boleto: number;
    pending_revenue_boleto: number;
    cancelled_revenue_boleto: number;
    approved_revenue_cc: number;
    pending_revenue_cc: number;
    cancelled_revenue_cc: number;
    revenue_pix: number;
    revenue_boleto: number;
    revenue_cc: number;
    roasBySource: number;
    roasTotal: number;
    roasPending: number;
    roasApproved: number;
    roasCancelled: number;
    cpaBySource: number;
    cpaTotal: number;
    cpaApproved: number;
    cpaPending: number;
    cpaCancelled: number;
    cpcBySource: number;
    cpmBySource: number;
}

const defaultTotalLine = (): TotalLine => ({
    spendBySource: 0,
    profitByUtmApproved: 0,
    productCostByUtmApproved: 0,
    totalOrders: 0,
    approvedOrders: 0,
    pendingOrders: 0,
    cancelledOrders: 0,
    ordersBySource: 0,
    revenueBySource: 0,
    revenueTotal: 0,
    revenueApproved: 0,
    revenuePending: 0,
    revenueCancelled: 0,
    clicksBySource: 0,
    impressionsBySource: 0,
    checkoutsBySource: 0,
    pageViewsBySource: 0,
    addToCartsBySource: 0,
    approved_orders_pix: 0,
    pending_orders_pix: 0,
    cancelled_orders_pix: 0,
    approved_orders_boleto: 0,
    pending_orders_boleto: 0,
    cancelled_orders_boleto: 0,
    approved_orders_cc: 0,
    pending_orders_cc: 0,
    cancelled_orders_cc: 0,
    orders_pix: 0,
    orders_boleto: 0,
    orders_cc: 0,
    approved_revenue_pix: 0,
    pending_revenue_pix: 0,
    cancelled_revenue_pix: 0,
    approved_revenue_boleto: 0,
    pending_revenue_boleto: 0,
    cancelled_revenue_boleto: 0,
    approved_revenue_cc: 0,
    pending_revenue_cc: 0,
    cancelled_revenue_cc: 0,
    revenue_pix: 0,
    revenue_boleto: 0,
    revenue_cc: 0,
    roasBySource: 0,
    roasTotal: 0,
    roasPending: 0,
    roasApproved: 0,
    roasCancelled: 0,
    cpaBySource: 0,
    cpaTotal: 0,
    cpaApproved: 0,
    cpaPending: 0,
    cpaCancelled: 0,
    cpcBySource: 0,
    cpmBySource: 0,
});

function getAdSetDataWithMetrics(platformAdgroupsData, adgroupId, adgroupName, adgroupStatus, campaignAssociatedId, campaignName, adGroupAccount, platformKey, dataCatalogList){
    return {
        adsSetId: adgroupId,
        isCatalog: dataCatalogList?.includes(adgroupId),
        adsSetName: adgroupName,
        adsSetStatus: adgroupStatus,
        accountId: adGroupAccount,
        campaignId: campaignAssociatedId,
        campaignName: campaignName,
        sourceType: platformKey,
        adsSetSourceIcon: getIconForPlatform(platformKey),
        profitByUtmApproved: platformAdgroupsData.utm_profit.per_adgroup.metric[adgroupId] -  platformAdgroupsData.spend.per_adgroup.metric[adgroupId],
        productCostByUtmApproved: platformAdgroupsData?.utm_product_cost?.per_adgroup.metric[adgroupId],
        spendBySource: platformAdgroupsData.spend.per_adgroup.metric[adgroupId],
        totalOrders: platformAdgroupsData.utm_purchases.per_adgroup.metric[adgroupId],
        approvedOrders: platformAdgroupsData.utm_approved_purchases.per_adgroup.metric[adgroupId],
        pendingOrders: platformAdgroupsData.utm_pending_purchases.per_adgroup.metric[adgroupId],
        cancelledOrders: platformAdgroupsData.utm_cancelled_purchases.per_adgroup.metric[adgroupId],
        ordersBySource: platformAdgroupsData.purchases.per_adgroup.metric[adgroupId],
        revenueBySource: platformAdgroupsData.revenue.per_adgroup.metric[adgroupId],
        revenueTotal: platformAdgroupsData.utm_revenue.per_adgroup.metric[adgroupId],
        revenueApproved: platformAdgroupsData.utm_approved_revenue.per_adgroup.metric[adgroupId],
        revenuePending: platformAdgroupsData.utm_pending_revenue.per_adgroup.metric[adgroupId],
        revenueCancelled: platformAdgroupsData.utm_cancelled_revenue.per_adgroup.metric[adgroupId],
        clicksBySource: platformAdgroupsData.clicks.per_adgroup.metric[adgroupId],
        impressionsBySource: platformAdgroupsData.impressions.per_adgroup.metric[adgroupId],
        checkoutsBySource: platformAdgroupsData.checkouts.per_adgroup.metric[adgroupId],
        pageViewsBySource: platformAdgroupsData.pageviews.per_adgroup.metric[adgroupId],
        addToCartsBySource: platformAdgroupsData.add_to_carts.per_adgroup.metric[adgroupId],

        approved_orders_pix: platformAdgroupsData.utm_approved_purchases_pix.per_adgroup.metric[adgroupId],
        pending_orders_pix: platformAdgroupsData.utm_pending_purchases_pix.per_adgroup.metric[adgroupId],
        cancelled_orders_pix: platformAdgroupsData.utm_cancelled_purchases_pix.per_adgroup.metric[adgroupId],
        approved_orders_boleto: platformAdgroupsData.utm_approved_purchases_boleto.per_adgroup.metric[adgroupId],
        pending_orders_boleto: platformAdgroupsData.utm_pending_purchases_boleto.per_adgroup.metric[adgroupId],
        cancelled_orders_boleto: platformAdgroupsData.utm_cancelled_purchases_boleto.per_adgroup.metric[adgroupId],
        approved_orders_cc: platformAdgroupsData.utm_approved_purchases_cc.per_adgroup.metric[adgroupId],
        pending_orders_cc: platformAdgroupsData.utm_pending_purchases_cc.per_adgroup.metric[adgroupId],
        cancelled_orders_cc: platformAdgroupsData.utm_cancelled_purchases_cc.per_adgroup.metric[adgroupId],
        orders_pix: platformAdgroupsData.utm_purchases_pix.per_adgroup.metric[adgroupId],
        orders_boleto: platformAdgroupsData.utm_purchases_boleto.per_adgroup.metric[adgroupId],
        orders_cc: platformAdgroupsData.utm_purchases_cc.per_adgroup.metric[adgroupId],
        approved_revenue_pix: platformAdgroupsData.utm_approved_revenue_pix.per_adgroup.metric[adgroupId],
        pending_revenue_pix: platformAdgroupsData.utm_pending_revenue_pix.per_adgroup.metric[adgroupId],
        cancelled_revenue_pix: platformAdgroupsData.utm_cancelled_revenue_pix.per_adgroup.metric[adgroupId],
        approved_revenue_boleto: platformAdgroupsData.utm_approved_revenue_boleto.per_adgroup.metric[adgroupId],
        pending_revenue_boleto: platformAdgroupsData.utm_pending_revenue_boleto.per_adgroup.metric[adgroupId],
        cancelled_revenue_boleto: platformAdgroupsData.utm_cancelled_revenue_boleto.per_adgroup.metric[adgroupId],
        approved_revenue_cc: platformAdgroupsData.utm_approved_revenue_cc.per_adgroup.metric[adgroupId],
        pending_revenue_cc: platformAdgroupsData.utm_pending_revenue_cc.per_adgroup.metric[adgroupId],
        cancelled_revenue_cc: platformAdgroupsData.utm_cancelled_revenue_cc.per_adgroup.metric[adgroupId],
        revenue_pix: platformAdgroupsData.utm_revenue_pix.per_adgroup.metric[adgroupId],
        revenue_boleto: platformAdgroupsData.utm_revenue_boleto.per_adgroup.metric[adgroupId],
        revenue_cc: platformAdgroupsData.utm_revenue_cc.per_adgroup.metric[adgroupId],

        roasBySource: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        roasTotal: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        roasPending: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_pending_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        roasApproved: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_approved_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        roasCancelled: platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.utm_cancelled_revenue?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
    
        cpaBySource: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        cpaTotal: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        cpaApproved: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_approved_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        cpaPending: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_pending_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        cpaCancelled: platformAdgroupsData.purchases?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.utm_cancelled_purchases?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,

        cpcBySource: platformAdgroupsData.clicks?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.clicks?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,
        cpmBySource: platformAdgroupsData.impressions?.per_adgroup?.metric?.[adgroupId] !== 0 ? parseFloat((platformAdgroupsData.spend?.per_adgroup?.metric?.[adgroupId] / platformAdgroupsData.impressions?.per_adgroup?.metric?.[adgroupId]).toFixed(2)) : 0,

    }
}

function getCampaignsDataWithMetrics(campaignMetrics, accountId, campaignId, accountNamesMap){

    return {
        spendBySource : campaignMetrics?.spend?.per_campaign?.metric?.[campaignId],
        profitByUtmApproved : campaignMetrics?.utm_profit?.per_campaign?.metric?.[campaignId] - campaignMetrics?.spend?.per_campaign?.metric?.[campaignId],
        productCostByUtmApproved : campaignMetrics?.utm_product_cost?.per_campaign?.metric?.[campaignId],
        totalOrders : campaignMetrics?.utm_purchases?.per_campaign?.metric?.[campaignId],
        approvedOrders : campaignMetrics?.utm_approved_purchases?.per_campaign?.metric?.[campaignId],
        pendingOrders : campaignMetrics?.utm_pending_purchases?.per_campaign?.metric?.[campaignId],
        cancelledOrders : campaignMetrics?.utm_cancelled_purchases?.per_campaign?.metric?.[campaignId],
        ordersBySource : campaignMetrics?.purchases?.per_campaign?.metric?.[campaignId],
        revenueBySource : campaignMetrics?.revenue?.per_campaign?.metric?.[campaignId],
        revenueTotal : campaignMetrics?.utm_revenue?.per_campaign?.metric?.[campaignId],
        revenueApproved : campaignMetrics?.utm_approved_revenue?.per_campaign?.metric?.[campaignId],
        revenuePending : campaignMetrics?.utm_pending_revenue?.per_campaign?.metric?.[campaignId],
        revenueCancelled : campaignMetrics?.utm_cancelled_revenue?.per_campaign?.metric?.[campaignId],
        clicksBySource : campaignMetrics?.clicks?.per_campaign?.metric?.[campaignId],
        impressionsBySource : campaignMetrics?.impressions?.per_campaign?.metric?.[campaignId],
        checkoutsBySource : campaignMetrics?.checkouts?.per_campaign?.metric?.[campaignId],
        pageViewsBySource : campaignMetrics?.pageviews?.per_campaign?.metric?.[campaignId],
        addToCartsBySource : campaignMetrics?.add_to_carts?.per_campaign?.metric?.[campaignId],
        approved_orders_pix : campaignMetrics?.utm_approved_purchases_pix?.per_campaign?.metric?.[campaignId],
        pending_orders_pix : campaignMetrics?.utm_pending_purchases_pix?.per_campaign?.metric?.[campaignId],
        cancelled_orders_pix : campaignMetrics?.utm_cancelled_purchases_pix?.per_campaign?.metric?.[campaignId],
        approved_orders_boleto : campaignMetrics?.utm_approved_purchases_boleto?.per_campaign?.metric?.[campaignId],
        pending_orders_boleto : campaignMetrics?.utm_pending_purchases_boleto?.per_campaign?.metric?.[campaignId],
        cancelled_orders_boleto : campaignMetrics?.utm_cancelled_purchases_boleto?.per_campaign?.metric?.[campaignId],
        approved_orders_cc : campaignMetrics?.utm_approved_purchases_cc?.per_campaign?.metric?.[campaignId],
        pending_orders_cc : campaignMetrics?.utm_pending_purchases_cc?.per_campaign?.metric?.[campaignId],
        cancelled_orders_cc : campaignMetrics?.utm_cancelled_purchases_cc?.per_campaign?.metric?.[campaignId],
        orders_pix : campaignMetrics?.utm_purchases_pix?.per_campaign?.metric?.[campaignId],
        orders_boleto : campaignMetrics?.utm_purchases_boleto?.per_campaign?.metric?.[campaignId],
        orders_cc : campaignMetrics?.utm_purchases_cc?.per_campaign?.metric?.[campaignId],
        approved_revenue_pix : campaignMetrics?.utm_approved_revenue_pix?.per_campaign?.metric?.[campaignId],
        pending_revenue_pix : campaignMetrics?.utm_pending_revenue_pix?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_pix : campaignMetrics?.utm_cancelled_revenue_pix?.per_campaign?.metric?.[campaignId],
        approved_revenue_boleto : campaignMetrics?.utm_approved_revenue_boleto?.per_campaign?.metric?.[campaignId],
        pending_revenue_boleto : campaignMetrics?.utm_pending_revenue_boleto?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_boleto : campaignMetrics?.utm_cancelled_revenue_boleto?.per_campaign?.metric?.[campaignId],
        approved_revenue_cc : campaignMetrics?.utm_approved_revenue_cc?.per_campaign?.metric?.[campaignId],
        pending_revenue_cc : campaignMetrics?.utm_pending_revenue_cc?.per_campaign?.metric?.[campaignId],
        cancelled_revenue_cc : campaignMetrics?.utm_cancelled_revenue_cc?.per_campaign?.metric?.[campaignId],
        revenue_pix : campaignMetrics?.utm_revenue_pix?.per_campaign?.metric?.[campaignId],
        revenue_boleto : campaignMetrics?.utm_revenue_boleto?.per_campaign?.metric?.[campaignId],
        revenue_cc : campaignMetrics?.utm_revenue_cc?.per_campaign?.metric?.[campaignId],
        channelSelected : accountNamesMap[accountId],

        roasBySource: campaignMetrics.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.revenue?.per_campaign?.metric?.[campaignId] / campaignMetrics.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasTotal: campaignMetrics.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.utm_revenue?.per_campaign?.metric?.[campaignId] / campaignMetrics.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasPending: campaignMetrics.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.utm_pending_revenue?.per_campaign?.metric?.[campaignId] / campaignMetrics.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasApproved: campaignMetrics.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.utm_approved_revenue?.per_campaign?.metric?.[campaignId] / campaignMetrics.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        roasCancelled: campaignMetrics.spend?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.utm_cancelled_revenue?.per_campaign?.metric?.[campaignId] / campaignMetrics.spend?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
    
        cpaBySource: campaignMetrics.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaTotal: campaignMetrics.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.utm_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaApproved: campaignMetrics.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.utm_approved_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaPending: campaignMetrics.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.utm_pending_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpaCancelled: campaignMetrics.purchases?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.utm_cancelled_purchases?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,

        cpcBySource: campaignMetrics.clicks?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.clicks?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,
        cpmBySource: campaignMetrics.impressions?.per_campaign?.metric?.[campaignId] !== 0 ? parseFloat((campaignMetrics.spend?.per_campaign?.metric?.[campaignId] / campaignMetrics.impressions?.per_campaign?.metric?.[campaignId]).toFixed(2)) : 0,

        
    };
}

