import { TemplateModal } from '../index';
import * as S from './style';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from 'react';
import { useCreateIntegrationShopify } from '../../../../services/hooks/integrationHooks';
import { ModalShopifyOnboarding } from '../../../../@types';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrationShopifySave } from '../../../../storage/storageOnboarding';
import { useIntercom } from 'react-use-intercom';
import useAuth from '../../../../hooks/useAuth';


export function ModalShopify({ setIntegrateShopify, setShowPopupShopify }: ModalShopifyOnboarding) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/shopify-icon.svg';
    const baseColor = '#19c380';

    const [shopifyInfo, setShopifyInfo] = useState({
        dominioShopify: '',
        accessTokenShopify: '',
    });

    const { user } = useAuth();
    const { showArticle } = useIntercom();
    const [ missingScopes, setMissingScopes ] = useState("")

    // const { currentCompany } = useAuth();
    const { mutate: createIntegrationShopify, isError, isLoading } = useCreateIntegrationShopify();

    function handleCreateIntegrationShopify() {
        const body = {
            account_id: shopifyInfo.dominioShopify,
            access_token: shopifyInfo.accessTokenShopify,
            company_id: storageOnboardingCurrentCompanyGet(),
            user: user,
        };
        createIntegrationShopify(body, {
            onSuccess: (data) => {
                if(setIntegrateShopify){
                    setIntegrateShopify(true);
                }
                //console.log("success data", data)
                setShowPopupShopify(false);
                storageOnboardingIntegrationShopifySave();
            },
            onError: (error: any) => {
                if(error === 'Account already connected'){
                    if(setIntegrateShopify){
                        setIntegrateShopify(true);
                    }
                    setShowPopupShopify(false);
                    storageOnboardingIntegrationShopifySave();
                }

                if (error.message.includes('Missing required scopes')) {
                    const regex = /\[(.*?)\]/;
                    const message: string = error.message
                    const match = message.match(regex);

                    if (match) {
                        const items = match[1].split(',').map(item => item.trim().replace(/'/g, ''));
                        setMissingScopes(items.join(", "))
                    } 
                }
            }
        });
    }

    function handleChangeShopifyInfo(e: any) {
        setShopifyInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Shopify'}
                title={'Shopify'}
                subtitle={'Integre sua loja Shopify e acesse todos os seus dados de e-commerce diretamente em nossa plataforma.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration value={shopifyInfo.dominioShopify} setValue={handleChangeShopifyInfo} inputName="Dominio:" inputId="dominioShopify" placeholder="https://nomedaloja.myshopify.com" />
                <InputIntegration
                    value={shopifyInfo.accessTokenShopify}
                    setValue={handleChangeShopifyInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenShopify"
                    placeholder="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"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        {missingScopes != "" ? (
                            <S.ErrorText>
                                Falha na integração, você não garantiu acesso a todos os escopos necessários ao gerar o token. Os seguintes escopos estão faltando: <span>{missingScopes}</span>.
                            </S.ErrorText>
                        ) : (
                            <S.ErrorText>
                                Falha na integração, verifique se seu <span>domínio</span> ou <span>token de acesso</span> estão corretos e tente novamente.
                            </S.ErrorText>
                        )}
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => showArticle(8726295)}>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meu domínio e token de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleCreateIntegrationShopify}
                    text="Integrar"
                    isLoading={isLoading}
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
