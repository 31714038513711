import styled, { keyframes } from 'styled-components';

type ContainerProps = {
    showSignUp: boolean;
};

export const SidesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    transition: all ease 250ms;
    border-radius: 12px;
    padding: 0 0 1rem 0;
    // width: 100vw;
    height: 100%;

    @media (max-width: 1024px) {
        height: auto;
        flex-direction: column;
        margin: 0;
    }

    form {
        display: flex;
        flex-direction: column;
        // align-items: center;
        align-items: stretch;
    }

    form > * {
        margin-bottom: 1rem;
    }

    form > *:last-child {
        margin-bottom: 0;
    }
`

export const PaymentOptionContainer = styled.div`
    display: flex;
    padding: 0.5rem;
    height: 26px;
    border-radius: 6px;
    width: 350px;
    align-items: center;
    font-weight: 500;
    color: #5d7375;
    justify-content: space-between;
    background-color: #f0f0f0;
    font-size: 0.75rem;

  
    `

export const InnerOption = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 18px;  
        margin-top: 0.1rem;
        fill: #19c27e;
    }

    img {
        margin-right: 0.5rem;
        display: flex;
        width: 20px;
    }
`

export const StepsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
gap: 0.5rem;
align-items: center;

p {
    font-size: 0.80rem;
    color: #5d7375;
}
`

export const Steps = styled.div`
 display: flex;
 flex-direction: row;
gap: 1rem;
width: 100%;
`

interface StepProps {
    active: boolean;
}

export const Step = styled.div<StepProps>`
    height: 4px;
    background-color: ${props => props.active ? '#19c27e' : '#e7e7e7'};
    border-radius: 50px;
    width: 90%;
`

const gradientAnimation2 = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;


export const Container = styled.div<ContainerProps>`

// background: linear-gradient(274deg, #eff0f0 0%, white 59%);
// background-size: 200% 200%;  
background: transparent;
animation: ${gradientAnimation2} 3s ease infinite; 
    ${(props) => (props.showSignUp ? 
        `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        // overflow: hidden;
        height: 100vh;
        // padding-top: calc(50vh - 310px);
        `
        : 
        'display: none;'
    )}
     
    @media (max-width: 1024px) {
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
    }
`;

export const MainTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0 1rem 0;

    img {
        width: 5rem;
        margin-bottom: 0.5rem;
    }
`

export const Logo = styled.div`
    img {
        width: 2rem;
        height: 2rem;
    }
    margin-bottom: 0.5rem;
`;

export const Title = styled.div`
    font-size: 1.50rem;
    line-height: 1.925rem;
    font-weight: 550;
    color: #1e1e1e;
    // margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div<{isMobile: boolean}>`
    font-size: 0.875rem;
    // line-height: 1.25rem;
    color: #5d7375;
    max-width: 100%;
    text-align: ${props => props.isMobile ? 'center' : 'left'};
`;

export const SubtitleSection = styled.div`
    // padding-bottom: 2.5rem;
    max-width: 100%;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    // flex: 1;
    // max-height: 620px;


    @media (max-width: 1200px) {
        transform: scale(1);
        padding: 19px;
        // border: 1px solid #e7e7e7;
        // border-radius: 13px;
        margin-top: 2rem;
        // box-shadow: 0 0 18px rgba(70, 115, 80, 0.05);
    }
`;

export const VerticalLine = styled.div`
    height: 80%;
    width: 1px;
    background-color: #e7e7e7;
`;

export const LeftColumn = styled.div`
    background-image: url('https://storage.googleapis.com/static-images-source/Bg%20Signup.png');
    background-size: cover;
    background-position: auto;
    max-width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all ease 250ms;
    // padding: 0rem 6rem;
    width: 100%;
    // border-radius: 12px;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 1200px) {
        transform: scale(0.8);
        background-image: url('');
        // padding: 0rem;
    }

    @media (max-width: 1024px) {
        // padding: 0rem;
        width: 100%;
        z-index: 1;
    }
`;

export const InputSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    > *:not(:last-child) {
        margin-right: 1rem;
    }

    // @media (max-width: 1050px) {
    //     > *:not(:last-child) {
    //         margin-right: 1vw; /* Adjusts the margin in responsive design */
    //     }
    // }
`;

export const ButtonSection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -1rem;
`;

export const Button = styled.button`
    width: 15rem;
    height: 2.5rem;
    background-color: var(--green);
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    line-height: 1.428125rem;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin-top: 1rem;
    font-weight: 450;
    &:hover {
        background-color: #16a86e;
        box-shadow: 0px 15px 20px rgba(29, 142, 97, 0.2);
        transform: translateY(-7px);
    }
`;

export const RecapchaSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-top: 1.6rem;
`;

export const FinalText = styled.div`
    width: 20rem;
    margin: 0.5rem auto 0rem;
    font-size: 0.65rem;
    font-weight: 500;
    text-align: center;
    color: #989c97;
    span {
        cursor: pointer;
        color: #337ab7;
        &:hover {
            color: #23527c;
            text-decoration: underline;
        }
    }
`;

export const AlreadyHaveAccount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    font-size: 0.75rem;
    font-weight: 550;
    line-height: 1.07rem;
    color: var(--green);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const FooterLine = styled.div`
    background-color: #eeeeee;
    width: 6rem;
    height: 1px;
`;
