// AlertContext.tsx
import  { createContext, useState } from 'react';
import { AlertContextProps, AlertType, Alert, ProviderProps } from '../@types';
  
export const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);
  
export const AlertProvider = ({ children } : ProviderProps) => {
    const [alert, setAlert] = useState<Alert>({ open: false, message: '', alertType: 'success' });

    const showAlert = (message: string, alertType: AlertType = 'success', duration: number = 5000, showCloseButton: boolean = false) => {
        setAlert({ open: true, message, alertType, duration, showCloseButton });
        if (duration > 0) {            
            setTimeout(() => {
                setAlert((prev) => ({ ...prev, open: false }));
            }, duration);
        }
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};