import { TableTransactionsProps } from '../../../@types';
import { LineTableOrders } from './LineTableOrders';
import { LineTableOrdersLoading } from './LineTableOrdersLoading';
import { useState } from 'react';

import * as S from './style';

export function TableTransactions({ transactionsFiltered, isLoading }: TableTransactionsProps) {
    
    //console.log('table transactions', transactionsFiltered);
    const [typeFiltred, setTypeFiltred] = useState('order_name');
    const [filterDec, setFilterDec] = useState(true);

    function handleSelectTypeFiltred(type: string) {
        if (typeFiltred === type) {
            setFilterDec(!filterDec);
        } else {
            setTypeFiltred(type);
            setFilterDec(true);
        }

    }
    
    const sortedTransactions = transactionsFiltered?.sort((a: any, b: any) => {
        const aValue = new Date(a[typeFiltred]).getTime();
        const bValue = new Date(b[typeFiltred]).getTime();

        return filterDec ? bValue - aValue : aValue - bValue;
    });

    return (
        <S.Container>
            <S.TableHeader>
                <S.Date>
                    Data/hora
                </S.Date>
                <S.Payment>Status</S.Payment>
                <S.Value>
                    Valor
                </S.Value>
            </S.TableHeader>
            <S.Lines>
                {!isLoading && transactionsFiltered.map((order) => (
                    <LineTableOrders key={order.id} transactionsFiltered={order} />
                ))}
                {isLoading && Array.from({ length: 10 }).map((_, index) => (
                    <LineTableOrdersLoading key={index} />
                ))}
            </S.Lines>
        </S.Container>
    );
}

function ArrowDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 128H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
        </svg>
    );
}
