import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 44.8125rem;
    height: 13.25rem;
    border-radius: 12px;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
`;

export const Graphic = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
