

import { STORE_PLACEHOLDER_STORAGE, SH_EXTERNAL_ID_STORAGE, STORAGE_SHOP } from './storageConfig';

export function storagePlaceholderCompanySave(company: string) {
    localStorage.setItem(`${STORE_PLACEHOLDER_STORAGE}-company`, company);
}

export function storagePlaceholderCompanyGet() {
    const storage = localStorage.getItem(`${STORE_PLACEHOLDER_STORAGE}-company`) ?? '';
    return storage;
}

export function storagePlaceholderCompanyRemove() {
    localStorage.removeItem(`${STORE_PLACEHOLDER_STORAGE}-company`);
}

export function storagePlaceholderShopifyCompanySave(company: string) {
    
    localStorage.setItem(`${STORE_PLACEHOLDER_STORAGE}-company-shopify`, company);
}

export function storagePlaceholderShopifyCompanyGet() {
    const storage = localStorage.getItem(`${STORE_PLACEHOLDER_STORAGE}-company-shopify`) ?? '';
    return storage;
}

export function storagePlaceholderShopifyCompanyRemove() {
    localStorage.removeItem(`${STORE_PLACEHOLDER_STORAGE}-company-shopify`);
}


export function storageShExternalIdSave(company: string) {
    localStorage.setItem(`${SH_EXTERNAL_ID_STORAGE}-company`, company);
}

export function storageShExternalIdGet() {
    const storage = localStorage.getItem(`${SH_EXTERNAL_ID_STORAGE}-company`) ?? '';
    return storage;
}

export function storageShExternalIdRemove() {
    localStorage.removeItem(`${SH_EXTERNAL_ID_STORAGE}-company`);
}

export function storageShopSave(company: string) {
    localStorage.setItem(`${STORAGE_SHOP}-company`, company);
}

export function storageShopGet() {
    const storage = localStorage.getItem(`${STORAGE_SHOP}-company`) ?? '';
    return storage;
}

export function storageShopRemove() {
    localStorage.removeItem(`${STORAGE_SHOP}-company`);
}