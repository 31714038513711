import { SidebarProps } from '../../../../@types';
import * as S from './style';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import GridViewIcon from '@mui/icons-material/GridView';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import InsightsIcon from '@mui/icons-material/Insights';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useState } from 'react';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import useAuth from '../../../../hooks/useAuth';

export function Sidebar({ showSideBar, setShowSideBar }: SidebarProps) {
    const urlSolomonImage = 'https://storage.googleapis.com/static-images-source/tracking2.png';
    const navigate = useNavigate();
    const location = useLocation();
    const { showArticle, show } = useIntercom();

    const [showOptionTracking, setShowOptionTracking] = useState(false);
    const [showOptionProducts, setShowOptionProducts] = useState(false);

    const { currentCompany, user } = useAuth();
    const shopifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.shopify?.accounts?.length || 0;
    const kiwifyIntegratedAccounts = user?.companies?.[currentCompany]?.integrations?.kiwify?.accounts?.length || 0;


    return (
        <S.Container showSideBar={showSideBar}>
            <S.Button onClick={() => setShowSideBar(!showSideBar)}>
                <UnfoldMoreIcon sx={{ width: '20px', height: '20px' }} />
            </S.Button>

            <S.InnerContainer>
                <S.TopContainer>
                    <S.Option isThisPage={location?.pathname?.includes('/dashboard')} onClick={() => navigate('/dashboard')} showSideBar={showSideBar}>
                        <S.Text>Geral</S.Text>
                        <GridViewIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Geral') || ''}`}
                    </S.Option>

                    <S.OptionSolomon showSideBar={showSideBar} onClick={() => (setShowOptionProducts(false), setShowOptionTracking((prev) => !prev))}>
                        {showSideBar && (
                            <>
                                <img src={urlSolomonImage} />
                                <S.TextOption rotate={showOptionTracking}>Tracking <ExpandMoreRoundedIcon/></S.TextOption>
                            </>
                        )}
                    </S.OptionSolomon>

                    <S.OptionOpen showOption={showOptionTracking || !showSideBar} isThisPage={location?.pathname?.includes('/tracking')} showSideBar={showSideBar} onClick={() => navigate('/tracking')}>
                        <S.SpecialText><div><span>TRACKING</span>Gerenciador</div></S.SpecialText>
                        {!showSideBar && (<BackupTableRoundedIcon sx={{ width: '22px', height: '22px', color: 'var(--black)' }} />)}
                        {showSideBar && (<SubdirectoryArrowRight sx={{ width: '22px', height: '22px' }} />)}{' '}
                        {`${(showSideBar && 'Gerenciador') || ''}`}
                    </S.OptionOpen>

                    <S.OptionOpen showOption={showOptionTracking || !showSideBar} isThisPage={location?.pathname?.includes('/utm_config')} showSideBar={showSideBar} onClick={() => navigate('/utm_config')}>
                        <S.SpecialText><div><span>TRACKING</span>Configurações</div></S.SpecialText>
                        {!showSideBar && (<SettingsOutlinedIcon sx={{ width: '22px', height: '22px', color: 'var(--black)' }}/>)}
                        {showSideBar && (<SubdirectoryArrowRight sx={{ width: '22px', height: '22px' }}/>)}{' '}
                        {`${(showSideBar && 'Configurações') || ''}`}
                    </S.OptionOpen>

                    <S.OptionProduct showSideBar={showSideBar} onClick={() => (setShowOptionProducts((prev) => !prev), setShowOptionTracking(false))}>
                        {showSideBar && (
                            <>
                                <CategoryOutlinedIcon sx={{ width: '22px', height: '22px', color: 'var(--black)' }} />
                                <S.TextOption rotate={showOptionProducts}>Produtos <ExpandMoreRoundedIcon/></S.TextOption>
                            </>
                        )}
                    </S.OptionProduct>

                    <S.OptionOpen  showOption={showOptionProducts || !showSideBar} isThisPage={location?.pathname?.includes('/produtos')} showSideBar={showSideBar} onClick={() => navigate('/produtos')}>
                    <S.SpecialText><div><span>PRODUTOS</span>Insights</div></S.SpecialText>
                        {!showSideBar && (<InsightsIcon sx={{ width: '22px', height: '22px', color: 'var(--black)' }} />)}
                        {showSideBar && (<SubdirectoryArrowRight sx={{ width: '22px', height: '22px' }} />)}{' '}
                        {`${(showSideBar && 'Insights') || ''}`}
                    </S.OptionOpen>

                    {(shopifyIntegratedAccounts > 1 && kiwifyIntegratedAccounts <= 1) && (
                        <S.OptionOpen showOption={showOptionProducts || !showSideBar} isThisPage={location?.pathname?.includes('/custo')} showSideBar={showSideBar} onClick={() => navigate('/custo')}>
                        <S.SpecialText><div><span>PRODUTOS</span>Custos</div></S.SpecialText>
                            {!showSideBar && (<ShoppingBagOutlinedIcon sx={{ width: '22px', height: '22px', color: 'var(--black)' }} />)}
                            {showSideBar && (<SubdirectoryArrowRight sx={{ width: '22px', height: '22px' }} />)}{' '}
                            {`${(showSideBar && 'Custos') || ''}`}
                        </S.OptionOpen>
                    )}

                    <S.Option isThisPage={location?.pathname?.includes('/pedidos')} showSideBar={showSideBar} onClick={() => navigate('/pedidos')}>
                        <S.Text>Pedidos</S.Text>
                        <OrdersIcon />{`${(showSideBar && 'Pedidos') || ''}`}
                    </S.Option>
                    <S.Option isThisPage={location?.pathname?.includes('/clientes')} showSideBar={showSideBar} onClick={() => navigate('/clientes')}>
                        <S.Text>Clientes</S.Text>
                        <GroupOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Clientes') || ''}`}
                    </S.Option>
                    <S.Option isThisPage={location?.pathname?.includes('/adicionais')} showSideBar={showSideBar} onClick={() => navigate('/adicionais')}>
                        <S.Text>Custo de operação</S.Text>
                        <CurrencyExchangeOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Custo de operação') || ''}`}
                    </S.Option>
                    <S.Option isThisPage={location?.pathname?.includes('/integracao')} showSideBar={showSideBar} onClick={() => navigate('/integracao')}>
                        <S.Text>Integrações</S.Text>
                        <PowerOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Integrações') || ''}`}
                    </S.Option>
                </S.TopContainer>

                <S.BottomContainer>
                        <S.Option style={{marginBottom: '-0.2rem'}} isBottomOption={true} onClick={() => { window.open('https://api.whatsapp.com/send/?phone=5512981440535&text=Ol%C3%A1%2C+venho+atrav%C3%A9s+da+plataforma+e+gostaria+de+saber+mais+sobre+as+Afilia%C3%A7%C3%B5es.&type=phone_number&app_absent=0', '_blank') }} showSideBar={showSideBar} hideOnSmallScreen={true}>
                            <S.Text>Indique e ganhe</S.Text>
                            <HandshakeOutlinedIcon sx={{ width: '22px', height: '22px', fill: 'var(--green)' }} /> {`${(showSideBar && 'Indique e ganhe') || ''}`}
                        </S.Option>

                        <S.Option isBottomOption={true} showSideBar={showSideBar} onClick={() => navigate('/equipe')}>
                            <S.Text>Gestão de Equipe</S.Text>
                            <Groups3OutlinedIcon />{`${(showSideBar && 'Gestão de Equipe') || ''}`}
                        </S.Option>

                        <S.Option isBottomOption={true} onClick={() => {showArticle(9200858)}} showSideBar={showSideBar} hideOnSmallScreen={true}>
                            <S.Text>App Solomon</S.Text>
                            <AppShortcutOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'App Solomon') || ''}`}
                        </S.Option>

                        <S.Option isBottomOption={true} onClick={() => {show()}} showSideBar={showSideBar} hideOnSmallScreen={true}>
                            <S.Text>Central de ajuda</S.Text>
                            <LiveHelpOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Central de ajuda') || ''}`}
                        </S.Option>

                        <S.Option style={{marginTop: '0.2rem'}}  isBottomOption={true} onClick={() => {showArticle(8814322)}} showSideBar={showSideBar} hideOnSmallScreen={true}>
                            <S.Text>Modo escuro</S.Text>
                            <DarkModeOutlinedIcon sx={{ width: '22px', height: '22px' }} /> {`${(showSideBar && 'Modo escuro') || ''}`}
                        </S.Option>

                    
                </S.BottomContainer>
            </S.InnerContainer>
        </S.Container>
    );
}

function OrdersIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M160-160v-516L82-846l72-34 94 202h464l94-202 72 34-78 170v516H160Zm240-280h160q17 0 28.5-11.5T600-480q0-17-11.5-28.5T560-520H400q-17 0-28.5 11.5T360-480q0 17 11.5 28.5T400-440ZM240-240h480v-358H240v358Zm0 0v-358 358Z"/>
        </svg>
    )
}

function EquipeIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/>
        </svg>
    )
}