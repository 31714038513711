import styled, { keyframes } from 'styled-components';

const pulse = keyframes`

                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
    
`;

export const Container = styled.div`
    display: flex;
    gap: 1rem;
    padding: 0.625rem 1rem;
    width: 10rem;
    border-radius: 5px;
    background: #dddddd;
    align-items: center;
    justify-content: center;
    animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const Image = styled.div`
    img {
        width: 1.5rem;
        height: 1.5rem;
        animation: ${pulse} 800s infinite ease-in-out forwards;

        border-radius: 10px;
        background-color: #ddd;
    }
`;

export const Line = styled.div`
    width: 6.25rem;
    height: 10px;
    border-radius: 5px;
    background-color: #eeeeee;
`;
