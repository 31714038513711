import styled from 'styled-components';

type ContainerProps = {
    isMobile?: boolean;
    showSelect: boolean;
};

export const Container = styled.div<ContainerProps>`
    position: absolute;
    cursor: default;
    top: ${(props) => (props.isMobile ? '3.5rem' : '3rem')}; 
    right: 0.9rem;
    z-index: 500;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.04));
    background-color: #fff;
    padding: 1rem;
    width: ${(props) => (props.isMobile ? '10rem' : '8rem')}; 
    border-radius: 7.5px;
    display: flex;
    transition: all 250ms;

    ${(props) =>
        props.showSelect
            ? `

    visibility: visible;
    transition: all 250ms;

    `
            : `

    opacity: 0;
    visibility: hidden;
    transition: all 250ms;

    `}
    flex-direction: column;
    gap: 0.5rem;
`;

export const Option = styled.div`
    font-size: 0.75rem;
    color: #a4a4a4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    transition: all 250ms;

    &:hover {
        cursor: pointer;
        color: #1e1e1e;
        transform: translateX(1px);
        transition: all 250ms;
        svg {
            color: #1e1e1e;
        }
    }
`;

export const OptionLogout = styled(Option)`
margin-left: 3px;

    &:hover {
        cursor: pointer;
        color: #dc143c;
        svg {
            
            color: #dc143c;
        }
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--gray-200);
`;
