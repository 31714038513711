import { TemplateModal } from '../../index';
import { TemplateModal2 } from '../../index';
import * as S from './style';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ButtonApp } from '../../../../../components/ButtonApp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ShareIcon from '@mui/icons-material/Share';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { ModalGatewayProps } from '../../../../../@types';
import useAuth from '../../../../../hooks/useAuth';
import { useCreateIntegrationGateway, useGetIntegrationGateway } from '../../../../../services/hooks/integrationHooks';
import { format } from 'date-fns';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { userDTO } from'../../../../../@types';
import { useEffect } from 'react';
import PixRoundedIcon from '@mui/icons-material/PixRounded';
import { platform } from 'os';
import useAlert from '../../../../../hooks/useAlert';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import Modal from '../../../../../components/Modal';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

export function ModalGateway({ setShowPopupGateway}: ModalGatewayProps) {
    const { user, currentCompany, setUserContext, updateUser } = useAuth();
    const {currencySymbol} = useCurrencyType();
    const urlLogo = 'https://storage.googleapis.com/static-images-source/gateway-icon.png';
    const baseColor = '#9b6afa';
    const baseColor2 = '#19c380';

    const defaultTax: { [key: string]: any } = {
        appmaxGateway: {
            platform: 'appmaxGateway',
            gateway_credit_card_rate: '4,99%',
            gateway_credit_card_fixed: `${currencySymbol} 0,99`,
            gateway_boleto_fixed: `${currencySymbol} 3,49`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_rate: '0,99%',
            gateway_pix_fixed: `${currencySymbol} 3,49`,
            gateway_credit_card_rate_x2: '0,00%',
            gateway_credit_card_rate_x3: '0,00%',
            gateway_credit_card_rate_x4: '0,00%',
            gateway_credit_card_rate_x5: '0,00%',
            gateway_credit_card_rate_x6: '0,00%',
            gateway_credit_card_rate_x7: '0,00%',
            gateway_credit_card_rate_x8: '0,00%',
            gateway_credit_card_rate_x9: '0,00%',
            gateway_credit_card_rate_x10: '0,00%',
            gateway_credit_card_rate_x11: '0,00%',
            gateway_credit_card_rate_x12: '0,00%',
            gateway_installment_limit: '0',
        },
        yeverGateway: {
            platform: 'yeverGateway',
            gateway_credit_card_rate: '5,99%',
            gateway_credit_card_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_rate: '5,99%',
            gateway_pix_rate: '5,99%',
            gateway_pix_fixed: `${currencySymbol} 0,00`,
            gateway_credit_card_rate_x2: '0,00%',
            gateway_credit_card_rate_x3: '0,00%',
            gateway_credit_card_rate_x4: '0,00%',
            gateway_credit_card_rate_x5: '0,00%',
            gateway_credit_card_rate_x6: '0,00%',
            gateway_credit_card_rate_x7: '0,00%',
            gateway_credit_card_rate_x8: '0,00%',
            gateway_credit_card_rate_x9: '0,00%',
            gateway_credit_card_rate_x10: '0,00%',
            gateway_credit_card_rate_x11: '0,00%',
            gateway_credit_card_rate_x12: '0,00%',
            gateway_installment_limit: '0',
        },
        mercadoPagoGateway: {
            platform: 'mercadoPagoGateway',
            gateway_credit_card_rate: '0,00%',
            gateway_credit_card_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_rate: '0,00%',
            gateway_pix_fixed: `${currencySymbol} 0,00`,
            gateway_credit_card_rate_x2: '0,00%',
            gateway_credit_card_rate_x3: '0,00%',
            gateway_credit_card_rate_x4: '0,00%',
            gateway_credit_card_rate_x5: '0,00%',
            gateway_credit_card_rate_x6: '0,00%',
            gateway_credit_card_rate_x7: '0,00%',
            gateway_credit_card_rate_x8: '0,00%',
            gateway_credit_card_rate_x9: '0,00%',
            gateway_credit_card_rate_x10: '0,00%',
            gateway_credit_card_rate_x11: '0,00%',
            gateway_credit_card_rate_x12: '0,00%',
            gateway_installment_limit: '0',
        },
        outrosGateway: {
            platform: 'outrosGateway',
            gateway_credit_card_rate: '0,00%',
            gateway_credit_card_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_fixed: `${currencySymbol} 0,00`,
            gateway_boleto_rate: '0,00%',
            gateway_pix_fixed: `${currencySymbol} 0,00`,
            gateway_pix_rate: '0,00%',
            gateway_credit_card_rate_x2: '0,00%',
            gateway_credit_card_rate_x3: '0,00%',
            gateway_credit_card_rate_x4: '0,00%',
            gateway_credit_card_rate_x5: '0,00%',
            gateway_credit_card_rate_x6: '0,00%',
            gateway_credit_card_rate_x7: '0,00%',
            gateway_credit_card_rate_x8: '0,00%',
            gateway_credit_card_rate_x9: '0,00%',
            gateway_credit_card_rate_x10: '0,00%',
            gateway_credit_card_rate_x11: '0,00%',
            gateway_credit_card_rate_x12: '0,00%',
            gateway_installment_limit: '0',
        },
    };

    const { mutate: handleCreateIntegrationGateway, isLoading, isError } = useCreateIntegrationGateway();
    // const { data: getGatewayData } = useGetIntegrationGateway(currentCompany);
    const [isChecked, setIsChecked] = useState(true);
    const [gateway, setGateway] = useState('appmaxGateway');
    const [gatewayInfo, setGatewayInfo] = useState(() => defaultTax[gateway]);
    const { showAlert } = useAlert();

    const [activeGateway, setActiveGateway] = useState(gateway ? true : false);

    useEffect(() => {
        const costsRates = user?.companies[currentCompany]?.manual_inputs?.costs_rates;
        
        const manualGatewayData = {
            gateway_credit_card_rate: formatPercentage(costsRates?.gateway_credit_card_rate || '0,00%'),
            gateway_credit_card_fixed: formatValue(costsRates?.gateway_credit_card_fixed || `${currencySymbol} 0,00`),
            gateway_boleto_fixed: formatValue(costsRates?.gateway_boleto_fixed || `${currencySymbol} 0,00`),
            gateway_boleto_rate: formatPercentage(costsRates?.gateway_boleto_rate || '0,00%'),
            gateway_pix_rate: formatPercentage(costsRates?.gateway_pix_rate || '0,00%'),
            gateway_pix_fixed: formatValue(costsRates?.gateway_pix_fixed || `${currencySymbol} 0,00`),
            gateway_credit_card_rate_x2: formatPercentage(costsRates?.gateway_credit_card_rate_x2 || '0,00%'),
            gateway_credit_card_rate_x3: formatPercentage(costsRates?.gateway_credit_card_rate_x3 || '0,00%'),
            gateway_credit_card_rate_x4: formatPercentage(costsRates?.gateway_credit_card_rate_x4 || '0,00%'),
            gateway_credit_card_rate_x5: formatPercentage(costsRates?.gateway_credit_card_rate_x5 || '0,00%'),
            gateway_credit_card_rate_x6: formatPercentage(costsRates?.gateway_credit_card_rate_x6 || '0,00%'),
            gateway_credit_card_rate_x7: formatPercentage(costsRates?.gateway_credit_card_rate_x7 || '0,00%'),
            gateway_credit_card_rate_x8: formatPercentage(costsRates?.gateway_credit_card_rate_x8 || '0,00%'),
            gateway_credit_card_rate_x9: formatPercentage(costsRates?.gateway_credit_card_rate_x9 || '0,00%'),
            gateway_credit_card_rate_x10: formatPercentage(costsRates?.gateway_credit_card_rate_x10 || '0,00%'),
            gateway_credit_card_rate_x11: formatPercentage(costsRates?.gateway_credit_card_rate_x11 || '0,00%'),
            gateway_credit_card_rate_x12: formatPercentage(costsRates?.gateway_credit_card_rate_x12 || '0,00%'),
            gateway_installment_limit: costsRates?.gateway_installment_limit?.toString() || '0',
        };
        setGatewayInfo(manualGatewayData);
        //console.log('realCostsRates', costsRates, 'manual', manualGatewayData)

        if (costsRates?.platform) {
            //console.log('costsRates pegou', costsRates?.platform);
            setGateway(costsRates?.platform);
        }
        
        if (costsRates) {
            setActiveGateway(true);
        } else {
            //console.log("Nenhum gateway conectado");
        }
    }, [user?.companies[currentCompany]?.manual_inputs?.costs_rates, user, currentCompany]);

      function handleIntegrate() {
        const body = {
            credit_card: {
                variable_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate),
                fixed_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_fixed),
                x2_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x2),
                x3_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x3),
                x4_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x4),
                x5_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x5),
                x6_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x6),
                x7_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x7),
                x8_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x8),
                x9_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x9),
                x10_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x10),
                x11_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x11),
                x12_fee: formatStringToNumber(gatewayInfo.gateway_credit_card_rate_x12),
                installment_limit: formatStringToNumber(gatewayInfo.gateway_installment_limit),
            },
            billet: {
                fixed_fee: formatStringToNumber(gatewayInfo.gateway_boleto_fixed),
                variable_fee: formatStringToNumber(gatewayInfo.gateway_boleto_rate),
            },
            pix: {
                variable_fee: formatStringToNumber(gatewayInfo.gateway_pix_rate),
                fixed_fee: formatStringToNumber(gatewayInfo.gateway_pix_fixed),
            },
            company_id: currentCompany,
            source: 'manual',
            platform: gateway,
            last_update: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            user: user
        };

        //console.log('select', gateway);
        
        let newManualGateway = {
            ...gatewayInfo,
            platform: gateway // Incluindo o gateway no objeto newManualGateway
        };

        //console.log('newManual', newManualGateway)
        
        const newUser = {
            ...user,
            companies: {
                ...user?.companies,
                [currentCompany]: {
                    ...user?.companies?.[currentCompany],
                    manual_inputs: {
                        ...user?.companies?.[currentCompany]?.manual_inputs,
                        costs_rates: newManualGateway,
                    },
                },
            },
        };

        setUserContext(newUser as userDTO);
        showAlert('Taxas de gateway atualizadas com sucesso!', 'success', 5000, true);

        handleCreateIntegrationGateway(body, {
            onSuccess: () => {
            },

        });
        updateUser();
        setActiveGateway(true);
        setShowPopupGateway(false);
    }

    function handleChangeGateway(e: any) {
        setGateway(e.target.value);
        setGatewayInfo(defaultTax[e.target.value]);
    }

    function handleCheckbox() {
        if (!isChecked) {
            setGatewayInfo(defaultTax[gateway]);
        }
        setIsChecked((prev) => !prev);
    }

    function handleChangeGatewayInfo(e: any) {
        if (isChecked) {
            setIsChecked(false);
        }

        setGatewayInfo((prev: any) => {
            let value = e.target.value;
            if (e.target.id.includes('fixed')) {
                value = formatValueInput(value);
            } else if (e.target.id.includes('rate')) {
                value = formatPercentageInput(value);
            }
            return {
                ...prev,
                [e.target.id]: value,
            };
        });
    }

    function handleKeyDown(e) {
        const value = e.target.value;
        if (e.key === 'Backspace' && e.target.selectionStart === value.length) {
            e.preventDefault();
            e.target.setSelectionRange(value.length - 1, value.length - 1);
        }
    }

    function formatValueInput(valueInput) {
        let value = valueInput?.toString(); // Convert any input to string
        if (!value) return `${currencySymbol} 0,00`; // Return a default if the input is null or undefined
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d)(\d{2})$/, '$1,$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
        return `${currencySymbol} ` + value;
    }

    function formatValue(valueInput) {
        let value = valueInput?.toString(); // Convert any input to string
        if (!value) return `${currencySymbol} 0,00`; // Return a default if the input is null or undefined
        // Ensure the value is treated as a float first
        value = parseFloat(value.replace(',', '.')).toFixed(2).toString();

        // Remove non-numeric characters
        value = value.replace(/\D/g, '');

        // Add comma before the last two digits
        value = value.replace(/(\d)(\d{2})$/, '$1,$2');
        return `${currencySymbol} ` + value;
    }
    
    function formatPercentageInput(valueInput) {
        let value = valueInput?.toString(); // Convert any input to string
        if (!value) return '0,00%'; // Return a default if the input is null or undefined
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d)(\d{2})$/, '$1,$2');
        return value + '%';
    }
    
    function formatPercentage(valueInput) {
        let value = valueInput?.toString(); // Convert any input to string
        if (!value) return '0,00%'; // Return a default if the input is null or undefined
        
        // Ensure the value is treated as a float first
        value = parseFloat(value.replace(',', '.')).toFixed(2).toString();
        
        // Remove non-numeric characters
        value = value.replace(/\D/g, '');
        
        // Add comma before the last two digits
        value = value.replace(/(\d)(\d{2})$/, '$1,$2');
        
        return value + '%';
    }
    
    function formatStringToNumber(text: string) {
        //console.log(text);
        text = text.replace(/\$|R\$|€|%/g, '');
        text = text.replace(/R\$/g, '').replace(/ {2}/g, '').replace(/%/g, '');
        text = text.replace(/\./g, '');
        text = text.replace(/,/g, '.');
        return Number(text);
    }

    const [showParcelamentoModal, setShowParcelamentoModal] = useState(false);

    function handleModalParcelamento() {
        setShowParcelamentoModal(true)
    }

    function handleCloseModal () {
        setShowParcelamentoModal(false)
    }
    
   
    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Gateway'}
                title={'Gateway'}
                subtitle={'Plataforma intermediária de vendas e pagamentos, facilitando a transação entre você e o site parceiro.'}
                statusConnection={activeGateway ? 'active' : 'inactive'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <S.SelectArea>
                <select value={gateway} onChange={handleChangeGateway}>
                    <option value="appmaxGateway">Appmax</option>
                    <option value="yeverGateway">Yever</option>
                    <option value="mercadoPagoGateway">Mercado Pago</option>
                    <option value="outrosGateway">Outro</option>
                </select>
                </S.SelectArea>
                <S.CheckboxSection onClick={handleCheckbox}>
                <Checkbox
                    size="small"
                    checked={isChecked}
                    sx={{
                        '&.Mui-checked': {
                            color: '#19c273',
                        },
                    }}
                />{' '}
                {gateway === 'outrosGateway' ? 'Zerar taxas' : `Definir como valores padrão  ${gateway === 'appmaxGateway' ? 'Appmax' : gateway === 'yeverGateway' ? 'Yever' : 'Mercado Pago'} `}
            </S.CheckboxSection>
            </S.InnerContainer>
            
            
            <S.ContentPaymentInformation>

                <S.LineContainer>

                <S.TypePaymentInformation> 

                    <S.HeaderTYpePaymentInformation>
                        <ReceiptIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} /> Boleto
                    </S.HeaderTYpePaymentInformation>
                    <S.ContentTypePaymentInformation>
                        <div>
                            <label>Taxa percentual</label>
                            <input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_boleto_rate} onChange={handleChangeGatewayInfo} id="gateway_boleto_rate" type="text" placeholder=" %" />
                        </div>
                        <S.VerticalLine />
                        <div>
                            <label>Taxa fixa</label>
                            <input value={gatewayInfo.gateway_boleto_fixed} onChange={handleChangeGatewayInfo} id="gateway_boleto_fixed" type="text" placeholder={` ${currencySymbol}`} />
                        </div>
                    </S.ContentTypePaymentInformation>
                </S.TypePaymentInformation>
                </S.LineContainer>

                <S.LineContainer>
                    <S.HeaderTYpePaymentInformation>
                        <PixRoundedIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} />Pix
                    </S.HeaderTYpePaymentInformation>
                    <S.ContentTypePaymentInformation>
                        <div>
                            <label>Taxa percentual</label>
                            <input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_pix_rate} onChange={handleChangeGatewayInfo} id="gateway_pix_rate" type="text" placeholder=" %" />
                        </div>
                        <S.VerticalLine />
                        <div>
                            <label>Taxa fixa</label>
                            <input value={gatewayInfo.gateway_pix_fixed} onChange={handleChangeGatewayInfo} id="gateway_pix_fixed" type="text" placeholder={` ${currencySymbol}`} />
                        </div>
                    </S.ContentTypePaymentInformation>
                </S.LineContainer>

                <S.LineContainer>
                    <S.HeaderTYpePaymentInformation>
                        <CreditCardIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} /> Cartão
                    </S.HeaderTYpePaymentInformation> 
                    <S.ButtonContentTypePaymentInformation>
                        <ButtonApp
                            border=''
                            borderRadius='7px'
                            width="10rem"
                            height="1.8rem"
                            color="#fff"
                            background="#19c380"
                            icon={<SettingsRoundedIcon sx={{ width: '16px', height: '16px' }} />}
                            onClick={handleModalParcelamento}
                            text="Configurar Taxas"
                            isLoading={isLoading}
                        />
                    </S.ButtonContentTypePaymentInformation>
                </S.LineContainer>
                {showParcelamentoModal && (
                    <Modal onClose={() => setShowParcelamentoModal(false)}>
                        <S.ContainerPopup>
                            <TemplateModal2
                                baseColor={baseColor2}
                                type={'Parcelamento'}
                                title={'Parcelamento'}
                                subtitle={'Configure as taxas de parcelamento para pagamentos com cartão de crédito.'}
                            />
                            <S.ContainerInformation>
                                <S.LineContainer>
                                    <S.HeaderTYpePaymentInformation>
                                        <CreditScoreRoundedIcon sx={{ color: '#19c273', width: '24px', height: '24px' }} />À vista
                                    </S.HeaderTYpePaymentInformation>
                                    <S.ContentTypePaymentInformation>
                                        <div>
                                            <label>Taxa percentual</label>
                                            <input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate" type="text" placeholder=" %" />
                                        </div>
                                        <S.VerticalLine />
                                        <div>
                                            <label>Taxa fixa</label>
                                            <input value={gatewayInfo.gateway_credit_card_fixed} onChange={handleChangeGatewayInfo} id="gateway_credit_card_fixed" type="text" placeholder={` ${currencySymbol}`} />
                                        </div>
                                    </S.ContentTypePaymentInformation>
                                </S.LineContainer>

                                <S.HeaderAbsorve2 style={{marginTop: '1.5rem', marginBottom: '0rem'}}>Taxa por parcelas</S.HeaderAbsorve2>

                                <S.ScrollableContainer>
                                    <S.HeaderScrollableContainer>
                                        <div>Parcelamento</div>
                                        <div>Taxa percentual</div>
                                    </S.HeaderScrollableContainer>
                                    <S.GridWrapper>
                                        <div>2x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x2} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x2" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>3x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x3} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x3" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>4x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x4} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x4" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>5x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x5} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x5" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>6x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x6} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x6" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>7x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x7} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x7" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>8x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x8} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x8" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>9x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x9} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x9" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>10x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x10} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x10" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>11x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x11} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x11" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                    <S.GridWrapper>
                                        <div>12x</div>
                                        <div><input onKeyDown={handleKeyDown} value={gatewayInfo.gateway_credit_card_rate_x12} onChange={handleChangeGatewayInfo} id="gateway_credit_card_rate_x12" type="text" placeholder=" %" /></div>
                                    </S.GridWrapper>
                                    <S.Line />
                                </S.ScrollableContainer>

                                <S.LineContainer>
                                <S.HeaderAbsorve>
                                    Absorver parcelas? 
                                    <HelpRoundedIcon sx={{ color: 'grey', width: '12px', height: '12px' }} />
                                    <div className="tooltip">Assumir o ônus da taxa ao invés de repassar para o cliente.</div>
                                </S.HeaderAbsorve>
                                    <S.NumberAbsorve>
                                        Quantidade de parcelas:
                                        <select value={gatewayInfo.gateway_installment_limit} id="gateway_installment_limit" onChange={handleChangeGatewayInfo}>
                                            <option value="1">0</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </S.NumberAbsorve>
                                </S.LineContainer>
                            </S.ContainerInformation>
                            <S.Line />
                            <S.DisconnectContainer>
                                Finalizar configurações do cartão:{' '}
                                <ButtonApp
                                    border=''
                                    width="7.75rem"
                                    height="2.25rem"
                                    color="#fff"
                                    background="var(--black)"
                                    icon={<CheckRoundedIcon sx={{ width: '16px', height: '16px' }} />}
                                    onClick={handleCloseModal}
                                    text="Continuar"
                                    isLoading={isLoading}
                                />
                            </S.DisconnectContainer>
                        </S.ContainerPopup>
                    </Modal>
                )}
            </S.ContentPaymentInformation>
            {isError && (
                <S.ErrorContainer>
                    <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                    <S.ErrorText>
                        Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                    </S.ErrorText>
                </S.ErrorContainer>
            )}

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    text="Salvar"
                    isLoading={isLoading}
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
