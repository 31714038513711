import styled from 'styled-components';

export const Line = styled.div`
    margin-top: 20px; 
    margin-bottom: 15px;
    height: 1px;
    width: 95%;
    background-color: var(--gray-100);
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`;

export const MainColumn = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    color: #007bff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 20%;
    &:last-child {
        border-right: none;
    }
`;

export const MainColumnClick = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    color: #007bff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 20%;
    &:last-child {
        border-right: none;
    }

    p {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ContainerWithPosition = styled.div`
    position: relative;
`;

export const ConteinerPopup = styled.div`
    position: absolute;
    bottom: 100%;
    display: flex;
    height: 32px;
    width: 215px;
    gap: 7px;
    font-size: 10px;
    left: 50%;
    padding: 10px;
    background-color: #0e1819;
    color: white;
    text-align: center;
    border-radius: 11px;
    z-index: 500;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.10);
    align-items: center;
    justify-content: center;
`;

export const ConteinerPopupButton = styled.div`
    padding: 0;
    font-size: 10px;
    display: flex;
    gap: 8px;
    font-weight: 550;
    color: #19c27e;
    border: none;
    border-radius: 0;
    box-shadow: none;
    position: initial;
    transition: none;
    cursor: pointer;
    outline: none;
    align-items: center;
    flex-direction: row;
    background: transparent;

    h1 {
        font-size: 11px;
        font-weight: 500;
        color: #989c97;
    }

    svg {
        font-size: 12px;
        font-weight: 600;
        color: #989c97;
    }

    &:hover {
        cbackground-color: none;
        box-shadow: none;
        color: #19C27E;
        transform: translateX(2px);
    }
`;

export const CopyButton = styled.div`
    color: #647870;
    border: none;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
    font-size: 11px !important!
    padding: 10px;
    height: 24px;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: 5px;

    svg {
        font-size: 13px;
        margin-top: 3px;
    }

    &:hover {
        color: #0c1612;
        transition: color 0.3s;
        transform: translateY(3px);
    }
`;

type CopyButtonProps = {
    show: boolean;
};

export const CopiedPopup = styled.div<CopyButtonProps>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 16px;
    border-radius: 20px; /* Rounded border */
    font-size: 14px;
    z-index: 9999;
    opacity: ${props => props.show ? 1 : 0}; /* Start with opacity 0 */
    transition: opacity 0.5s, transform 0.5s ease-out; /* Transition for opacity and transform */
    transform: translateY(${props => props.show ? 0 : 20}px); /* Start from bottom, then move up */
`;

export const DefaultColumnF = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 20%;
    transform 250ms ease-in-out;
    &:last-child {
        border-right: none;
    }
    svg {
        &:hover {
            cursor: pointer;
            transform: translateY(2px);
            color: #0c1612;
            transition: color 0.3s;
            transform 250ms ease-in-out;
        }
    }
`;

export const MainColumnG = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: #007bff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 25%;
    &:last-child {
        border-right: none;
    }
`;

export const DefaultColumnG = styled.div`
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    height: 3.4375rem;
    width: 25%;
    &:last-child {
        border-right: none;
    }
    svg {
        &:hover {
            cursor: pointer;
            transform: translateY(2px);
            color: #0c1612;
            transition: color 0.3s;
            transform 250ms ease-in-out;
        }
    }
`;

export const ModalContainer = styled.div`
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    /* margin-top: 15px; */
    padding: 1.3vw;
    width: 32vw;
    /* max-width: 873px; */
    border-radius: 1vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
`;

export const ModalContent = styled.div`
    box-shadow: none;
    border: none;

    h1 {
        color: #1e1e1e;
        font-size: 1.5vw;
        font-family: Montserrat;
        font-weight: 600;
        margin: 0;
    }

    p {
        color: var(--cinza, #989c97);
        font-size: 1vw;
        font-family: Montserrat;
        font-weight: 500;
        margin-botton: 5px;
    }
`;

export const ModalContentHeader = styled.div`
    color: #1e1e1e;
    font-size: 1.5vw;
    font-family: Montserrat;
    font-weight: 600;
    margin: 0;
`;

export const ModalContentP = styled.div`
    color: var(--cinza, #989c97);
    font-size: 1vw;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const ModalButton = styled.div`
    background-color: #19c27e;
    color: white;
    border: none;
    padding: 0.8vw 2vw;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    font-size: 1vw;
    margin: auto;
    display: flex;
    width: 30%;
    transition: all 250ms ease-in-out;
    justify-content: center;

    &:hover {
        transform: translateY(3px);
        transition: all 250ms ease-in-out;
    }
`;