import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background-color: var(--white);
    width: 38.0625rem;
    height: 11.5rem;
    border-radius: 12px;
    position: relative;
    padding-top: 3rem;
`;

export const ContainerLoading = styled.div`
    width: 38.0625rem;
    height: 14.5rem;
    border-radius: 12px;
    position: relative;
`;

const loading = keyframes`
        100% {
            background-position: 600px 0;
        }
`;

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #d3d3d3 0px, #e0e0e0 40px, #d3d3d3 80px);
    background-size: 600px 100%;
    animation: ${loading} 1.5s infinite linear;
`;

export const Title = styled.div`
    font-size: 0.875rem;
    line-height: 1.05rem;
    font-weight: 500;
    color: var(--all-black);
    position: absolute;
    top: 0.8rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const Column = styled.div`
    font-size: 0.8125rem;
    font-weight: 550;
    line-height: 1.21875rem;
    color: #585858;
    width: 7.125rem;
    height: 2.78125rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95%;
`;

export const Button = styled.button`
    font-size: 0.625rem;
    line-height: 0.9375rem;
    color: #989c97;
    border: 1px solid #cdcdcd;
    width: 4.82375rem;
    height: 1.25rem;
    border-radius: 50px;
    position: absolute;
    top: 0.8rem;
    right: 0.9rem;
    background-color: var(--white);
    cursor: pointer;
    &:hover {
        border: 1px solid var(--green);
    }
`;

export const Lines = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 7rem;
    overflow-y: auto;
    width: 95%;
    margin: 0 auto;

    > div:nth-child(odd) {
        background-color: var(--white);
    }

    > div:nth-child(even) {
        background-color: var(--gray-112);
    }
`;

export const Line = styled.div`
    height: 2px;
    width: 95%;
    background-color: #ebebeb;
    margin: 0 auto;
`;
