import { createContext, useState, useEffect } from 'react';
import { CheckboxTrackingProviderProps, CheckboxTrackingContextProps, FilteredDataItem, PlatformState, CampaignIdState, AdSetIdState, AdsIdsState } from '../@types';

export const CheckboxTrackingContext = createContext<CheckboxTrackingContextProps>({
    selectedPlatforms: {},
    selectedPlatformsOthers: {},
    selectedCampaignIds: [],
    selectedAdSetIds: [],
    selectedAdsIds: [],
    selectedTab: '', // Add an initializer for the selectedTab property
    setSelectedTab: () => {},  // Incluir esta linha para expor setSelectedTab no contexto
    filteredData: [],
    togglePlatform: () => {},
    togglePlatformIfOthers: () => {},
    toggleCampaignId: () => {},
    toggleAdSetId: () => {},
    toggleAdId: () => {},
    clearAllPlatforms: () => {},
    clearAllCampaigns: () => {},
    clearAllAdSets: () => {},
    clearAllAds: () => {}
});

export function CheckboxPlatformProvider({ children, initialData = [] }: CheckboxTrackingProviderProps) {
    const [selectedPlatforms, setSelectedPlatforms] = useState<PlatformState>({});
    const [selectedPlatformsOthers, setSelectedPlatformsOthers] = useState<PlatformState>({});
    const [selectedCampaignIds, setSelectedCampaignIds] = useState<CampaignIdState>([]);
    const [selectedAdSetIds, setSelectedAdSetIds] = useState<AdSetIdState>([]);
    const [selectedAdsIds, setSelectedAdsIds] = useState<AdsIdsState>([]);
    const [selectedTab, setSelectedTab] = useState('channel');
    const [filteredData, setFilteredData] = useState<FilteredDataItem[]>([]);

    useEffect(() => {
        const activePlatforms = Object.keys(selectedPlatforms).filter(platform => selectedPlatforms[platform]);
        const newData = initialData.filter(item => activePlatforms.includes(item.platform));
        setFilteredData(newData);
    }, [selectedPlatforms, initialData]);

    function togglePlatform(platform: string | number, tab?: string) {

        console.log('platform', platform)

        setSelectedPlatforms(prev => {
            const updatedPlatforms = {...prev, [platform]: !prev[platform]};
            if (!updatedPlatforms[platform]) {
                // Quando desmarcar, limpar todos os outros estados relevantes
                clearAllCampaigns();
                clearAllAdSets();
                clearAllAds();
            } else if (tab) {
                // Marcar apenas esta plataforma e desmarcar todas as outras
                Object.keys(updatedPlatforms).forEach(key => {
                    updatedPlatforms[key] = key === platform;
                });
                setSelectedTab(tab);
                setSelectedPlatformsOthers({});
            }
            return updatedPlatforms;
        });
    }

    useEffect(() => {
        const activePlatforms = Object.keys(selectedPlatforms).filter(platform => selectedPlatformsOthers[platform]);
        const newData = initialData.filter(item => activePlatforms.includes(item.platform));
        setFilteredData(newData);
    }, [selectedPlatformsOthers, initialData]);

    function togglePlatformIfOthers(platform: string | number, tab?: string) {
        setSelectedPlatformsOthers(prev => {
            const updatedPlatforms = {...prev, [platform]: !prev[platform]};
            if (!updatedPlatforms[platform]) {
                // Quando desmarcar, limpar todos os outros estados relevantes
                clearAllCampaigns();
                clearAllAdSets();
                clearAllAds();
            } else if (tab) {
                // Marcar apenas esta plataforma e desmarcar todas as outras
                Object.keys(updatedPlatforms).forEach(key => {
                    updatedPlatforms[key] = key === platform;
                });
                setSelectedTab(tab);
                setSelectedPlatforms({});
            }
            return updatedPlatforms;
        });
    }
    

    function toggleCampaignId(campaignId: string, type: string, tab?: string) {
        setSelectedCampaignIds(prev => {
            const index = prev.findIndex(item => item.id === campaignId && item.type === type);
            if (index >= 0) {
                // Campanha está sendo desselecionada, então limpe os AdSets associados
                const remainingAdSetIds = selectedAdSetIds.filter(adSet => adSet.type !== type || adSet.id !== campaignId);
                setSelectedAdSetIds(remainingAdSetIds);
                setSelectedAdSetIds([]);
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            } else {
                // Campanha está sendo selecionada
                return [...prev, { id: campaignId, type: type }];
            }
        });

        if (tab) {
            setSelectedTab(tab);
            // Limpar selectedCampaignIds e usar apenas o último clicado
            setSelectedCampaignIds([{ id: campaignId, type: type }]);
        }
    }

    function toggleAdSetId(adSetId: string, type: string, tab?: string) {
        setSelectedAdSetIds(prev => {
            const index = prev.findIndex(item => item.id === adSetId && item.type === type);
            if (index >= 0) {
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            } else {
                return [...prev, { id: adSetId, type: type }];
            }
        });

        if (tab) {
            setSelectedTab(tab);
            setSelectedAdSetIds([{ id: adSetId, type: type }]);
        }
    }

    function toggleAdId(adId: string, type: string, tab?: string) {
        setSelectedAdsIds(prev => {
            const index = prev.findIndex(item => item.id === adId && item.type === type);
            if (index >= 0) {
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            } else {
                return [...prev, { id: adId, type: type }];
            }
        });

        if (tab) {
            setSelectedTab(tab);
            setSelectedAdsIds([{ id: adId, type: type }]);
        }
    }

    function clearAllPlatforms() {
        setSelectedPlatforms({});
        setSelectedPlatformsOthers({});
    }

    function clearAllCampaigns() {
        setSelectedCampaignIds([]);
    }

    function clearAllAdSets() {
        setSelectedAdSetIds([]);
    }

    function clearAllAds() {
        setSelectedAdsIds([]);
    }

    const value = {
        selectedPlatforms,
        selectedPlatformsOthers,
        selectedCampaignIds,
        selectedAdSetIds,
        selectedAdsIds,
        selectedTab, // Add an initializer for the selectedTab property
        setSelectedTab,  // Incluir esta linha para expor setSelectedTab no contexto
        filteredData,
        togglePlatform,
        togglePlatformIfOthers,
        toggleCampaignId,
        toggleAdSetId,
        toggleAdId,
        clearAllPlatforms,
        clearAllCampaigns,
        clearAllAdSets,
        clearAllAds,
    };

    return (
        <CheckboxTrackingContext.Provider value={value}>
            {children}
        </CheckboxTrackingContext.Provider>
    );
}
