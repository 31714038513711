import styled from 'styled-components';

export const Container = styled.div`
    height: 6.625rem;
    width: 100%;
    max-width: 30rem;
    border: 1px solid var(--gray-200);
    border-radius: 10px;
`;

export const Title = styled.div`
    margin-top: 0.8rem;
    margin-left: 0.8rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const Content = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.8rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--black);
`;

export const ContentInformation = styled.div`
    position: absolute;
    border: 1px solid var(--gray-200);
    width: max-content;
    display: none;
    padding: 0.3rem 0.5rem;
    background-color: var(--white);
    bottom: 0.8rem;
    left: -38rem;
    z-index: 2;
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--black);
`;

export const TitleInformation = styled.div`
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--blue);
    position: relative;
    &:hover {
        ${ContentInformation} {
            display: block;
        }
    }
`;
