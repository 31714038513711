import * as S from './style';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import { ButtonApp } from '../../components/ButtonApp';
import { CardMyStore } from './CardMyStore';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import { useEffect, useState } from 'react';
import { use } from 'echarts';
import CustomerIcon from '../../components/CustomerIcon';
import { Search } from '../../components/Search';

export function MyStores() {
    const { currentCompany, user, setCurrentCompanyContext } = useAuth();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const currentCompanyInfo = user?.companies?.[currentCompany] ? { ...user.companies[currentCompany], id: currentCompany } : null;

    const allCompanyInfo = user?.companies
        ? Object.keys(user.companies).map((companyId) => ({
              ...user.companies[companyId],
              id: companyId,
          }))
        : [];

    const filteredCompanies = allCompanyInfo.filter(store => store.company !== 'placeholder');
    const company_id = new URLSearchParams(window.location.search).get('company_id');

    function handleSelectCompany(company_id: string) {
        const selectedCompany = user?.companies?.[company_id];
        if (selectedCompany) {
            setCurrentCompanyContext(company_id);
            const billingStatus = selectedCompany.billing?.status;

            if (billingStatus === 'inactive') {
                if (user?.companies?.[company_id]?.billing?.subscription !== ''){
                    navigate(`/payment?company_id=${company_id}&context=renew&add_store=true`);
                } else{
                    navigate(`/payment?company_id=${company_id}&add_store=true`);
                }
                
            } else {
                const shopifyIntegratedAccounts = selectedCompany?.integrations?.shopify?.accounts?.length || 0;
                const kiwifyIntegratedAccounts = selectedCompany?.integrations?.kiwify?.accounts?.length || 0;
                if (shopifyIntegratedAccounts <= 1 && kiwifyIntegratedAccounts <= 1) {
                    navigate('/onboarding');
                } else {
                    if (!isMobile) {
                        navigate('/dashboard');
                    } else {
                        navigate('/dashboardMobile');
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (company_id) {
            handleSelectCompany(company_id);
        }
    }, [company_id]);

    useEffect(() => { 
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const contextRenew = urlParams.get('context');
        if (contextRenew == 'renew') {
         
        }
    }, [user]);

    const [filter, setFilter] = useState('');


    return (
        <S.Container>
            <S.Content>

                <S.InfoStore onClick={() => handleSelectCompany(currentCompany)}>
                    <S.UserAvatar>
                        <CustomerIcon fontColor={'white'} fontSize={'1.2rem'} name={currentCompanyInfo?.company} />
                    </S.UserAvatar>
                    <div>
                        <S.DataStore>
                            <S.NameStore>{(currentCompanyInfo?.company !== 'placeholder') ? currentCompanyInfo?.company : ''}</S.NameStore>
                            <S.Status><span>Conectado</span></S.Status>
                        </S.DataStore>
                        <S.EmailUser>{currentCompanyInfo?.user?.email}</S.EmailUser>
                    </div>
                </S.InfoStore>
                <S.InnerContainer>
                    <S.Title>
                        Gerencie suas <span>Lojas</span>
                    </S.Title>
                    <S.Subtitle>Conecte sua loja e acesse todos os seus dados de e-commerce diretamente em nossa plataforma.</S.Subtitle>
                    {filteredCompanies?.length > 5 && (
                    <div style={{marginLeft: '0.5rem', marginBottom: '1rem'}}>
                    <Search placeholder="Pesquisar loja" width="16rem" height='2.5rem' value={filter} setValue={setFilter} />
                    </div>
                   )}

                    {filteredCompanies?.length > 0 && (
                       <S.StoreList>
                            {filteredCompanies
                                ?.filter((store) => store?.company?.toLowerCase().includes(filter.toLowerCase())) // Filtro pelo nome da loja
                                .map((store) => {
                                    return (
                                        <CardMyStore 
                                            key={store.id} 
                                            name={store?.company} 
                                            email={store?.user?.email} 
                                            onClick={() => handleSelectCompany(store.id)} 
                                    />
                                );
                            })}
                        </S.StoreList>
                    )}

                    <S.ContainerButton>
                        <ButtonApp
                            border=''
                            width="8.875rem"
                            height="2rem"
                            background="#19c27e"
                            text="Adicionar Loja"
                            icon={<StoreIcon sx={{ width: '20px', height: '20px' }} />}
                            color="#fff"
                            onClick={() => {navigate(`/payment?add_store=true`)}}
                        />
                    </S.ContainerButton>
                </S.InnerContainer>
            </S.Content>
        </S.Container>
    );
}
