import styled from 'styled-components';

export const Container = styled.div`
    padding-bottom: 3rem;
    height: 100%;
`;

export const Title = styled.div`
    font-weight: 600;
    line-height: 1.2rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2.5rem;
    color: #446055;
    position: relative;
    svg {
        position: absolute;
        right: 4rem;
    }
`;
