import styled from 'styled-components';

export const Container = styled.div`
    margin: 0.3rem 1rem;
    min-height: 2rem;
    border-radius: 5px;
    background-color: #f3f3f3;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
        margin-left: 1rem;
        fill: #1e1e1e;
        width: 0.9375rem;
        height: 0.9375rem;
        margin-right: 0.5rem;
    }
`;

export const LineName = styled.div`
    display: flex;
    align-items: center;
    transition: 0.3s;
    &:hover {
        transform: translateX(4px);
    }
`;
