import { AnalyticsBrowser } from '@segment/analytics-next';
import posthog from 'posthog-js';

const analyticsServer = AnalyticsBrowser.load({ writeKey: 'ATlxD6hF5piznwRJ2wC9jj6W1ZxybxWI' });
export const analyticsWeb = AnalyticsBrowser.load({ writeKey: '6Og0IWShWeUAIGxsGYET1LvltCYNBaPi' });

posthog.init('phc_yBFEfZssZ0tKlDN4voULriAYpy2Om1J1adm3qjDlvm3', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
});

export async function trackEvent(name: string, data: any) {
    await analyticsServer.track(name, data);
    posthog.capture(name, data);
}

export async function identify(userId: string, data: any) {
    await analyticsServer.identify(userId, data);
    posthog.identify(userId, data);
}

export function startRecord() {
    return posthog.startSessionRecording();
}

export function stopRecord() {
    return posthog.stopSessionRecording();
}

export function showSurvey(surveyId: string) {
    posthog.capture('$survey', {
        survey_id: surveyId,
    });
}

export { posthog };