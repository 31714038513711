import styled from "styled-components";

export const Container = styled.div`
    margin-top: 2rem;
`

export const MainSection = styled.div`
display: flex;
gap: 4rem;
`

export const FirstSection = styled.div`
width: 100%;
max-width: 50%;


`

export const ButtonSection = styled.div`
margin-top: 1.5rem;
`

export const InputSection = styled.div`
display: flex;
margin-top: 1rem;
flex-direction: column;
input{
    width: 100%;
    height: 2.6875rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    &:focus,
        &:hover {
            border: 1px solid var(--green);
            outline: none;
            /* border-color: rgba(76, 234, 134, 0.4); */
            background-color: #fff;
            box-shadow: 0 0 0 4px rgba(76, 234, 152, 0.1);
            /* border-bottom: 1px solid var(--green); */
        }
}

label{
    font-size: 0.875rem;
    color: #4F4F4F;
    font-weight: 500;
    line-height: 1.3125rem;
}
`

export const SecondSection = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
max-width: 50%;
`

export const Header = styled.div`
margin-top: 3rem;
height: 3.375rem;
background-color: #ededed;
border-top-right-radius: 8px;
border-top-left-radius: 8px;
display: flex;
align-items: center;
justify-content: space-between;
`

export const EmptyLines = styled.div`
display: flex;
justify-content: center;
padding: 1rem;

`
export const Lines = styled.div`


`
export const VLine = styled.div`
height: 20rem;
width: 1px;
margin-left: 46px;
background-color: rgb(0,0,0,0.1);
`

export const Column = styled.div`
width: 25%;
font-size: 0.8125rem;
font-weight: 600;
color: #989c97;
line-height:1.21875rem;
display: flex;
align-items: center;
justify-content: center;
&:first-child{
    justify-content: flex-start;
    padding-left: 1rem;
}
&:last-child{
    justify-content: flex-end;
    padding-right: 7rem;
}
`