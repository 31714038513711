import { useNavigate } from 'react-router-dom';
import { ButtonApp } from '../../components/ButtonApp';
import useGetData from '../../hooks/useGetData';
import {
    storageOnboardingIntegrateCartpandaGet,
    storageOnboardingIntegrateDomPagamentosGet,
    storageOnboardingIntegrateAppmaxGet,
    storageOnboardingIntegrateYampiGet,
    storageOnboardingIntegrateYeverGet,
    storageOnboardingIntegrateAdooreiGet,
    storageOnboardingIntegrateUnicoPagGet,
    storageOnboardingIntegrateShopifyCheckoutGet,
    storageOnboardingIntegrationFacebookAdsGet,
    storageOnboardingIntegrationGoogleAdsGet,
    storageOnboardingIntegrationShopifyGet,
    storageOnboardingIntegrationTiktokAdsGet,
    storageOnboardingIntegrateOutrosGatewayGet,
    storageOnboardingIntegrateYeverGatewayGet,
    storageOnboardingUtmGoogleAdsGet,
    storageOnboardingUtmFacebookAdsGet,
    storageOnboardingUtmTiktokAdsGet,
    storageOnboardingCurrentCompanyGet,
    storageOnboardingRemoveAll,
} from '../../storage/storageOnboarding';
import { BannerText } from './BannerText';
import { IntegrationLoadingOnboarding } from './IntegrationLoadingOnboarding';
import { SkeletonLoadingOnboarding } from './SkeletonLoadingOnboarding';
import * as S from './style';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import {  StorageAuthTokenProps, userDTO } from '../../@types';
import { useIntercom } from 'react-use-intercom';
import { storagePlaceholderCompanySave } from '../../storage/storageCompanyToOnboard';
import { trackEvent } from '../../services/segment/sendEvent';

export function LoadingOnboarding() {
    const { data: dataFromGetData, setGetDataIsEnabled } = useGetData();
    const { user, updateUser, currentCompany } = useAuth();
    const navigate = useNavigate();
    const { setUserContext, setTokenContext, setCurrentCompanyContext } = useAuth();
    setGetDataIsEnabled(true);

    const { showArticle } = useIntercom();

    useEffect(() => {
        updateUser();
    },[])

    useEffect(() => {
        trackEvent('complete-onboarding', {
            userId: user?.user_id,
            timestamp: new Date().toISOString(),
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : ''
        }).then((ctx) => {
            //console.log("success");
        }).catch((err) => {
            //console.log(err);
        });
    }, []);

    function signOut() {
        trackEvent('logout', {
            userId: user?.user_id,
            company_id: currentCompany,
            company_name: user?.companies ? user?.companies[currentCompany]?.company : '',
            timestamp: new Date().toISOString()
        }).then((ctx) => {
            //console.log("success");
        }).catch((err) => {
            //console.log(err);
        });

        setUserContext({} as userDTO);
        setTokenContext({} as StorageAuthTokenProps);
        setCurrentCompanyContext('');
        storagePlaceholderCompanySave('');
        storageOnboardingRemoveAll();
        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);
        navigate('/login');
    }

    const shopifyRealPercentage = dataFromGetData?.shopify?.percentage;
    let continueBtnUnlock = false

    if (shopifyRealPercentage === 100) {
        continueBtnUnlock = true;
    } else {
        continueBtnUnlock = false;
    }
    
    const [gatewayPlatformSelected, setGatewayPlatformSelected] = useState('');
    
    useEffect(() => {
        const gatewayStoredInfo = localStorage.getItem(`gatewayInfo_gateway_${storageOnboardingCurrentCompanyGet()}`);
        if (gatewayStoredInfo) {
            setGatewayPlatformSelected(JSON.parse(gatewayStoredInfo).platform);
        }
    }, []);

    function handleContinueOnboarding() {
        localStorage.setItem(`onboardingCompleted_${storageOnboardingCurrentCompanyGet()}`, 'true');
        
        setCurrentCompanyContext(storageOnboardingCurrentCompanyGet())
        updateUser()
        storageOnboardingRemoveAll()

        localStorage.removeItem('subscriptionPostalCode');
        localStorage.removeItem(`plansArray_${user.user_id}`);
        localStorage.removeItem(`savedCoupon_${user.user_id}`);
        localStorage.removeItem(`selectedPlan_${user.user_id}`);
        // localStorage.removeItem(`currentStep_${user.user_id}`);
        localStorage.removeItem(`isFirstButtonEnabled_${user.user_id}`)
        localStorage.removeItem(`selectedPlanSignUp`)
        localStorage.removeItem(`currentStep`)    
        localStorage.removeItem(`plansWithCouponArray_${user?.user_id}`);


        setGetDataIsEnabled(false)

        navigate('/dashboard');
    }

    return (
        <S.Container>
            <S.LeftContainer>
            <S.LogoutButton onClick={signOut}>
                    <S.LogoutIconStyled />
                    Sair
                </S.LogoutButton>
                <BannerText />
            </S.LeftContainer>
            <S.RightContainer>
                <S.Image>
                    <img src="https://storage.googleapis.com/static-images-source/solomonAnimationGif.gif" alt="Logo" />
                </S.Image>
                <S.Title>Aguarde um momento,</S.Title>
                <S.Title>estamos sincronizando seus dados</S.Title>
                <S.Subtitle>
                    Enquanto isso, assista ao vídeo <span>demonstração</span> da nossa plataforma
                </S.Subtitle>
                <S.Subtitle>para ficar por dentro de todas nossas features.</S.Subtitle>
                <S.ButtonSection>
                    <ButtonApp
                        border=''
                        width="11.25rem"
                        height="2.5rem"
                        color="#fff"
                        background="#0e1819"
                        text="Visualizar demo"
                        icon={<PlayArrowIcon sx={{ width: '18px', height: '18px' }} />}
                        onClick={() => {showArticle(8726312)}}
                        fontSize="0.9375rem"
                        fontWeight="500"
                    />
                    {(
                        // quero que esse botão só fique habilitado quando continueBtnUnlock for true
                        continueBtnUnlock ? (
                            <ButtonApp
                            border=''
                                width="11.25rem"
                                height="2.5rem"
                                color="#fff"
                                background="#18C37F"
                                text="Continuar"
                                icon={<SkipNextIcon sx={{ width: '18px', height: '18px' }} />}
                                onClick={handleContinueOnboarding}
                                fontSize="0.9375rem"
                                fontWeight="500"
                            />
                        ) : (
                            <ButtonApp
                            border=''
                                width="11.25rem"
                                height="2.5rem"
                                color="var(--gray-600)"
                                background="var(--gray-300)"
                                text="Continuar"
                                icon={<SkipNextIcon sx={{ width: '18px', height: '18px' }} />}
                                onClick={() => {}}
                                fontSize="0.9375rem"
                                fontWeight="500"
                                disabled
                            />
                        )
                    )}
                </S.ButtonSection>
                {false && (
                    <S.SkeletonSection>
                        <SkeletonLoadingOnboarding />
                        <SkeletonLoadingOnboarding />
                        <SkeletonLoadingOnboarding />
                        <SkeletonLoadingOnboarding />
                        <SkeletonLoadingOnboarding />
                    </S.SkeletonSection>
                )}
                <S.Section>
                    {storageOnboardingIntegrationShopifyGet() &&  <IntegrationLoadingOnboarding percentage={dataFromGetData?.shopify?.percentage} type="Shopify" />}
                    {storageOnboardingIntegrationFacebookAdsGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.facebook_ads?.percentage} type="Facebook" />}
                    {storageOnboardingIntegrationGoogleAdsGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.google?.percentage} type="Google" />}
                    {storageOnboardingIntegrationTiktokAdsGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.tiktok_ads?.percentage} type="Tiktok" />}
                    {storageOnboardingIntegrateYampiGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.yampi?.percentage} type="Yampi (Checkout)" />}
                    {storageOnboardingIntegrateCartpandaGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.cartpanda.percentage} type="Cartpanda (Checkout)" />}
                    {storageOnboardingIntegrateYeverGet() && <IntegrationLoadingOnboarding percentage={dataFromGetData?.yever?.percentage} type="Yever (Checkout)" />}
                    {storageOnboardingIntegrateAdooreiGet() && <IntegrationLoadingOnboarding percentage={100} type="Adoorei (Checkout)" />}
                    {storageOnboardingIntegrateUnicoPagGet() && <IntegrationLoadingOnboarding percentage={100} type="Unico Pag (Checkout)" />}
                    {storageOnboardingIntegrateShopifyCheckoutGet() && <IntegrationLoadingOnboarding percentage={100} type="Shopify (Checkout)" />}
                    {storageOnboardingIntegrateYeverGatewayGet() && gatewayPlatformSelected === 'yever_gateway' && <IntegrationLoadingOnboarding percentage={100} type="Yever (Gateway)" />}
                    {storageOnboardingIntegrateOutrosGatewayGet() && gatewayPlatformSelected === 'other' && <IntegrationLoadingOnboarding percentage={100} type="Outro (Gateway)" />}
                    {storageOnboardingIntegrateAppmaxGet() && gatewayPlatformSelected === 'appmax' && <IntegrationLoadingOnboarding percentage={100} type="Appmax (Gateway)" />}
                    {storageOnboardingIntegrateDomPagamentosGet() && <IntegrationLoadingOnboarding percentage={100} type="Dom Pagamentos (Gateway)" />}    
                </S.Section>
               
            </S.RightContainer>
        </S.Container>
    );
}
