import React from 'react';
import * as S from './style';
import {InputPayment} from '../InputPayment';

export default function Step1({disabledStoreInput, isMobile, storeName, setStoreName, subscriptionPostalCode, setSubscriptionPostalCode, addStore, handleFirstInteraction, isFirstButtonEnabled, handleNextStep }) {

  return (
<form onSubmit={handleNextStep}>
    <S.StepsContainer>
      <p>Passo 1 de 2</p>
      <S.Steps>
        <S.Step active={true}></S.Step>
        <S.Step active={false}></S.Step>
      </S.Steps>
    </S.StepsContainer>
    <S.InputSection style={disabledStoreInput ? {pointerEvents: 'none', background: '#e7e7e7', borderRadius: '8px'} : {}}>
      <InputPayment
        onFocus={handleFirstInteraction}
        inputInformation={storeName}
        inputId="name"
        inputName="name"
        inputLabel="Nome da loja"
        inputType="text"
        width={isMobile ? '75vw' : '27.8125rem'}
        setInputInformation={setStoreName}
      />
    </S.InputSection>
    <S.InputSection>
         <InputPayment
         onFocus={handleFirstInteraction}
         inputInformation={subscriptionPostalCode}
         inputId="postalCode"
         inputName="postalCode"
         inputLabel="CEP"
         inputType="postalCode"
         width={isMobile ? '75vw' : '27.8125rem'}
         setInputInformation={setSubscriptionPostalCode}
       />
    </S.InputSection>
    <S.ButtonSection>
      <S.Button onClick={handleNextStep} disabled={!isFirstButtonEnabled} style={!isFirstButtonEnabled ? { cursor: 'not-allowed', backgroundColor: '#ccc' } : {}}>
        Próximo
      </S.Button>
    </S.ButtonSection>
  </form>
  )
};
