import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getDashboardData(start_date: string, end_date: string, company_id: string, productIds: string[]): Promise<AxiosResponse> {
    const params = new URLSearchParams({
        start_date,
        end_date,
        company_id,
        products: productIds.join(','), // Converte a lista de IDs em uma string separada por vírgulas
    }).toString();

    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/dashboard/general?${params}`);
    console.log('url', `${BASE_URL}/client-services/dashboard/general?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`);
    return data;
}

export async function getTaxesRate(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/taxes?company_id=${company_id}`);
    console.log('data tax', data);
    return data;
}

export async function getShipping(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/shipping?company_id=${company_id}`);
    console.log("shipping api da api-dashboard", data);
    return data;
}