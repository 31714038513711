import { TemplateModal } from '../index';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import * as S from './style';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import { useState } from 'react';
import { ModalCartpandaProps } from '../../../../@types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateIntegrationCartpanda } from '../../../../services/hooks/integrationHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateCartpandaSave } from '../../../../storage/storageOnboarding';
import { useIntercom } from 'react-use-intercom';
import useAuth from '../../../../hooks/useAuth';

export function ModalCartpanda({ setShowPopupCartpanda, setIntegrateCartpanda }: ModalCartpandaProps) {
    const urlLogo = 'https://storage.googleapis.com/static-images-source/cartpanda-icon.png';
    const baseColor = '#1e1e1e';

    //const { currentCompany } = useAuth();
    const { mutate: handleCreateIntegrationCartpanda, isLoading, isError } = useCreateIntegrationCartpanda();
    const { showArticle } = useIntercom();
    const { user } = useAuth();

    const [cartpandaInfo, setCartpandaInfo] = useState({
        aliasCartpanda: '',
        accessTokenCartpanda: '',
        planCartpanda: 'light',
    });

    function handleIntegrate() {
        const body = {
            account_id: cartpandaInfo.aliasCartpanda,
            auth_token: cartpandaInfo.accessTokenCartpanda,
            plan: cartpandaInfo.planCartpanda,
            company_id: storageOnboardingCurrentCompanyGet(),
            user: user,
        };

        handleCreateIntegrationCartpanda(body, {
            onSuccess: () => {
                storageOnboardingIntegrateCartpandaSave();
                if (setIntegrateCartpanda) {
                    setIntegrateCartpanda(true);
                }
                setShowPopupCartpanda(false);
            },
        });
    }

    function handleChangeCartpandaInfo(e: any) {
        setCartpandaInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Cartpanda'}
                title={'Cartpanda'}
                subtitle={'Integre sua loja com o Cartpanda e automatize seu processo de dropshipping.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />

            <S.InnerContainer>
                <InputIntegration value={cartpandaInfo.aliasCartpanda} setValue={handleChangeCartpandaInfo} inputName="Alias:" inputId="aliasCartpanda" placeholder="Solomon" />
                <InputIntegration
                    value={cartpandaInfo.accessTokenCartpanda}
                    setValue={handleChangeCartpandaInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenCartpanda"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                <label>Selecione o plano</label>
                <select id="planCartpanda" onChange={handleChangeCartpandaInfo}>
                    <option value="light">Light</option>
                    <option value="starter">Starter</option>
                    <option value="premium">Premium</option>
                    <option value="enterprise">Enterprise</option>
                </select>
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => showArticle(8726307)}>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    text="Integrar"
                    isLoading={isLoading}
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
