import * as S from './style';
import Skeleton from '@mui/material/Skeleton';
import useVisibility from '../../../../hooks/useVisibilityChange';

export function LinePermissionList({ group, isLoading }: any) {

    const { isVisible } = useVisibility();

    if(isLoading){
        return(
            <>
                <S.Container>
                    <S.LineName>
                        <Skeleton sx={{ fontSize: '1rem', width: '5rem', height: '1.5rem', marginLeft: '1rem' }} />
                    </S.LineName>
                </S.Container>
            </>
        )
    }


    return (
        <S.Container>
            <S.LineName>
                {' '}
                <AdGroupIcon /> {isVisible ? group?.name : '* * * * * *'}{' '}
            </S.LineName>
        </S.Container>
    );
}

function AdGroupIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M320-320h480v-400H320v400Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
        </svg>
    );
}
