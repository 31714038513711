import { LineOverviewChannelProps } from '../../../../../@types';
import * as S from './style';
import { useCurrencyType } from '../../../../../hooks/useCurrencyType';
import { Tooltip } from '@mui/material';

export function LineOverviewChannel({ type, revenue, cpa, sales, spend, totalSales }: LineOverviewChannelProps) {
    let url = '';
    const percentage = ((sales / Number(totalSales)) * 100).toFixed(0);
    const { currencySymbol } = useCurrencyType();

    switch (type) {
        case 'Facebook':
            url = 'https://storage.googleapis.com/static-images-source/facebook-icon.webp';
            break;

        case 'Tiktok':
            url = 'https://storage.googleapis.com/static-images-source/tiktok-icon.png';
            break;

        case 'Google':
            url = 'https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png';
            break;

        case 'Outros':
            url = 'https://storage.googleapis.com/static-images-source/other-source-icon.png';
            break;

        // case 'Sem atribuição':
        //     url = 'https://storage.googleapis.com/static-images-source/noatribuittion-icon.png';
        //     break;
    }

    const showWarning = sales === 0 && Number(spend) !== 0;

    return (
        <S.Container>
            <S.MainColumn>
                <img src={url} alt={type} />
                <p>{type}</p>
                {/* {showWarning && <WarningIcon />} */}
            </S.MainColumn>
            <S.Column>
                {currencySymbol}{' '}
                {Number(revenue).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </S.Column>
            <S.Column>
                {currencySymbol}{' '}
                {Number(spend).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </S.Column>
            <S.Column>
                {currencySymbol}{' '}
                {Number(cpa).toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </S.Column>
            <S.Column>{sales}</S.Column>
            <S.Column>
            <Tooltip title={`${percentage}%`} placement="top" componentsProps={{ tooltip: { sx: { bgcolor: 'var(--black)', fontWeight: 'semibold', fontSize: '12px', fontFamily: 'Montserrat', borderRadius: '8px', padding: '10px', color: 'white', textWrap: 'wrap', zIndex: 999999, lineHeight: '17px' } }, }}>
                <S.Bar>
                <S.BarValue percentage={percentage} color="#5f5d9f" />
                </S.Bar>
            </Tooltip>
            </S.Column>
        </S.Container>
    );
}

function WarningIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/>
        </svg>
    )
}