import { submitCompanyCurrencyDTO } from '../../@types/types-api';
import { AxiosResponse } from 'axios';
import { api } from '../api-confg';
import * as DTO from '../../@types';
const BASE_URL = import.meta.env.VITE_API_URL;

export async function getCompanyCurrency(company_id: string): Promise<AxiosResponse> {
    const data: AxiosResponse = await api.get(`${BASE_URL}/client-services/io-handlers/manual/currency?company_id=${company_id}`);
    return data;
}

export async function submitCompanyCurrency(body: DTO.submitCompanyCurrencyDTO): Promise<AxiosResponse> {
    const { company_id, currency } = body;

    console.log('currency post', body);

    const newBody = { company_id, currency  };
    const data: AxiosResponse = await api.post(`${BASE_URL}/client-services/io-handlers/manual/currency`, newBody);
    
    return data;
}
