import styled, { css, keyframes } from "styled-components";

type ContainerProps = {
    isLoading?: boolean;
    disabled?: boolean;
    hasLastUpdated?: boolean;
    isMobile?: boolean;
};


export const Container2 = styled.button<ContainerProps>`
    width: 11.5rem;
    border-radius: 8px;
    position: relative;
    display: flex;
    margin-left: -2rem;
    align-items: center;
    text-wrap: nowrap;
    flex-direction: row;
    // height: 2rem;
    justify-content: flex-end;
    background-color: transparent;

    svg {
        ${(props) => (props.disabled ? animationStyles : '')};
        color: #6f878a;
        width: 0.9rem;
        margin-right: 0.3rem;
    }
    `

    export const Subtile2 = styled.div<ContainerProps>`
    font-size: 0.75rem;
    color: #6f878a;
    // margin-right: 1rem;
    `


export const Popup = styled.div`
    position: absolute;
    display: flex;
    transition: all 250ms;
    opacity: 0;
    visibility: hidden;
    height: 4.75rem;
    width: 20.5rem;
    padding: 0;
    top: 4rem;
    background-color: #0e1819;
    color: #0e1819;
    text-align: center;
    border-radius: 11px;
    z-index: 1;
    flex-direction: column;
    box-shadow: 4px 6px 9px -6px rgba(0, 0, 0, 0.1);
    align-items: center;
`

export const PopupTitle = styled.div`
color: #fff;
text-align: center;
font-size: 0.75rem;
font-weight: 500;
margin-top: 0.5rem;
`

export const PopupSubTitle = styled.div`
color: #ddd;
font-size: 0.625rem;
text-align: center;
margin: 0.25rem 1rem 0;
`

export const Container = styled.button<ContainerProps>`
    width: 10rem;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 2rem;
    cursor: pointer;
    background-color: #192621;
        &:hover {
            background-color: #0c1612;
            transform: translateY(2px);
        }
    &:disabled{
        cursor: not-allowed;
        background-color: #f0f0f0;
        &:hover {
            ${Popup} {
            visibility: visible;
            opacity: 1;
        }
        }
    }
    margin-right: 1rem;
    margin-left: 1rem;
    display: ${props => props.isMobile ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    transition: 0.3s;
`


export const Title = styled.div<ContainerProps>`
font-size: 0.7125rem;
line-height: 1.21875rem;
${props => props.isLoading ? `
color:#696868;
margin-top: -0.2rem;
`: `
${props.hasLastUpdated ? ``: `margin-top: -0.08rem;`}
color: #fff;
`}
font-weight: 500;
margin-bottom: -0.3rem;
`

export const Subtile = styled.div`
font-size: 0.525rem;
line-height: 0.9375rem;
color: #a8bec1;
margin-top: -0.4rem;
`

const rotate = keyframes`
    from {
        transform: rotate(0deg) scaleX(1);
    }
    to {
        transform: rotate(360deg) scaleX(1);
    }
`;

const animationStyles = css`
    animation: ${rotate} 1s linear infinite;
`;

type IconProps = {
    isLoading?: boolean;
    hasLastUpdated?: boolean;
};

export const Icon = styled.div<IconProps>`
svg{
    width: 1rem;
    height: 0.8rem;
    margin-right: 0.3rem;
    ${(props) => (props.isLoading ? animationStyles : '')};
    ${props => props.isLoading ? `
    
    fill: #696868;
    
    `: `
    ${props.hasLastUpdated ? ` margin-bottom: -0.5rem;` : `margin-bottom: -0.1rem;`}
    
    fill: #fff;
    
    `}
}
`

export const Information = styled.div`
`

export const Line = styled.div`
background-color: #b1beb3;
border-radius: 2px;
width: calc(100% - 2rem);
margin-top: -0.4rem;
margin-bottom: 0.2rem;
height: 4px;
`
const moveBackground = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
`

type ProgressProps = {
    progress: number;
}

export const Progress = styled.div<ProgressProps>`
    width: ${props => props.progress}%;
    height: 4px;
    background: linear-gradient(90deg, #19c27e 25%, #088151 50%,#19c27e 75%);    
    border-radius: 5px;
    animation: ${moveBackground} 2s linear infinite;
    background-size: 200% 200%;
    transition: width 0.5s ease-in-out;
`

export const InnerContainer = styled.div`
display: flex;
`