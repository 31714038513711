import { TemplateModal } from '../index';
import * as S from './styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { ButtonApp } from '../../../../components/ButtonApp';
import { InputIntegration } from '../../../../components/InputIntegration';
import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCreateIntegrationYampi } from '../../../../services/hooks/integrationHooks';
import { storageOnboardingCurrentCompanyGet, storageOnboardingIntegrateYampiSave } from '../../../../storage/storageOnboarding';
import { ModalYampiProps } from '../../../../@types';
import { useIntercom } from 'react-use-intercom';
import useAuth from '../../../../hooks/useAuth';

export function ModalYampi({ setShowPopupYampi, setIntegrateYampi }: ModalYampiProps) {
    const urlLogo = 'https://i.ibb.co/vB7Hhnk/yampi.png';
    const baseColor = '#dd2385';

    //const { currentCompany } = useAuth();
    const { mutate: handleCreateIntegrationYampi, isLoading, isError } = useCreateIntegrationYampi();

    const { showArticle } = useIntercom();
    const { user } = useAuth();

    const [yampiInfo, setYampiInfo] = useState({
        aliasYampi: '',
        accessTokenYampi: '',
        secretKeyYampi: '',
    });

    function handleIntegrate() {
        const body = {
            account_id: yampiInfo.aliasYampi,
            token: yampiInfo.accessTokenYampi,
            secret_key: yampiInfo.secretKeyYampi,
            company_id: storageOnboardingCurrentCompanyGet(),
            user: user,
        };

        handleCreateIntegrationYampi(body, {
            onSuccess: () => {
                storageOnboardingIntegrateYampiSave();
                if (setIntegrateYampi){
                    setIntegrateYampi(true);
                }
                setShowPopupYampi(false);
            },
        });
    }

    function handleChangeYampiInfo(e: any) {
        setYampiInfo((prev) => {
            return {
                ...prev,
                [e.target.id]: e.target.value,
            };
        });
    }

    return (
        <S.Container>
            <TemplateModal
                urlLogo={urlLogo}
                baseColor={baseColor}
                type={'Yampi'}
                title={'Yampi'}
                subtitle={'Integre sua loja com a Yampi e automatize seu processo de dropshipping.'}
                statusConnection={'Conectado'}
                lastConnection={'Não há atualizações'}
            />
            <S.InnerContainer>
                <InputIntegration value={yampiInfo.aliasYampi} setValue={handleChangeYampiInfo} inputName="Alias:" inputId="aliasYampi" placeholder="Solomon" />
                <InputIntegration
                    value={yampiInfo.accessTokenYampi}
                    setValue={handleChangeYampiInfo}
                    inputName="Token de acesso:"
                    inputId="accessTokenYampi"
                    placeholder="h2mag9lm228nlka95nwv6ka01ht84msvig5q00lb"
                />
                <InputIntegration
                    value={yampiInfo.secretKeyYampi}
                    setValue={handleChangeYampiInfo}
                    inputName="Chave secreta:"
                    inputId="secretKeyYampi"
                    placeholder="kh_ZjybughBUBYBUNjgygyutBybnbcJI4Gu337JV"
                />
                {isError && (
                    <S.ErrorContainer>
                        <ErrorOutlineIcon sx={{ color: '#c03737', height: '24px', width: '24px' }} />{' '}
                        <S.ErrorText>
                            Falha na integração, verifique se suas <span>credenciais</span> estão corretas e tente novamente.
                        </S.ErrorText>
                    </S.ErrorContainer>
                )}
            </S.InnerContainer>
            <S.InfoContainer onClick={() => showArticle(8726310)}>
                <HelpOutlineOutlinedIcon sx={{ width: '12px', height: '12px' }} /> Onde vejo meus dados de acesso?
            </S.InfoContainer>

            <S.DisconnectContainer>
                Salvar configurações:{' '}
                <ButtonApp
                    border=''
                    width="7.75rem"
                    height="2.25rem"
                    color="#fff"
                    background="#19c380"
                    icon={<ShareIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={handleIntegrate}
                    text="Integrar"
                    isLoading={isLoading}
                />
            </S.DisconnectContainer>
        </S.Container>
    );
}
